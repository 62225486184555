import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import useStyles from "../styles";
import GitHubRepositoryIcon from "assets/svgs-icons/social-github-icon.svg";
import GitLabRepositoryIcon from "assets/svgs-icons/social-gitlab-icon.svg";
import BitbucketRepositoryIcon from "assets/svgs-icons/social-bitbucket-icon.png";
import OpenRepository from "./OpenRepository";
import moment from "moment";
import { useHistory } from "react-router-dom";
import repoProviderUtils from "helpers/repoProviderUtils.js";
import { setRepositoryId } from "redux/_scanner/scanner.actions.js";
import { useDispatch } from "react-redux";
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const CardElementsCardContent = ({ repo, className = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const keepRepositoryIdAndBranchesForDetail = useCallback(() => {
    dispatch(setRepositoryId(repo.id));
  }, [dispatch, repo.id]);

  const handleShowRepositoryDetail = useCallback(() => {
    keepRepositoryIdAndBranchesForDetail();
    history.push("/zen/new-repository-scanner-details/" + repo.id);
  }, [history, keepRepositoryIdAndBranchesForDetail]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    enqueueSnackbar("Repository URL copied to clipboard!", { variant: 'info' });
    setAnchorEl(null);
  };

  const getLastScanSummary = (summary) => {
    if (!summary || Object.keys(summary).length === 0) {
      return (
        <>
          <div className={classes.lastScanOn}>
            {`No summary available, please run your first scan`}
          </div>
          <div className={classes.low23Parent}>
            <div className={classes.leakage}>{`Low: ${0}`}</div>
            <div className={classes.leakage}>{`Middle: ${0}`}</div>
            <div className={classes.leakage}>{`Leakage: ${0}`}</div>
          </div>
        </>
      );
    }

    const {
      created,
      medium_severity,
      low_severity,
      leakage_severity,
      branch,
      link,
    } = summary;

    const currentRepoDate = new Date(created);
    const formattedDate = moment(currentRepoDate).format("YYYY-MM-DD");
    const formattedTime = moment(currentRepoDate).format("HH:mm:ss");

    return (
      <>
        <div className={classes.lastScanOn}>
          {`Last scan in`} <strong>{branch}</strong>
        </div>
        <div className={classes.lastScanOn}>
          {`${formattedDate} at ${formattedTime}`}
        </div>
        <div className={classes.low23Parent}>
          <div className={classes.leakage}>{`Low: ${low_severity}`}</div>
          <div className={classes.leakage}>{`Middle: ${medium_severity}`}</div>
          <div className={classes.leakage}>{`Leakage: ${leakage_severity}`}</div>
        </div>
      </>
    );
  };

  const getRepoIconByProvider = (provider) => {
    switch (provider) {
      case "Github":
        return GitHubRepositoryIcon;
      case "Gitlab":
        return GitLabRepositoryIcon;
      case "Bitbucket":
        return BitbucketRepositoryIcon;
      default:
        console.log(`Sorry, we are out of ${provider}.`);
        return null;
    }
  };

  return (
    <div className={`${classes.cardElementsCardContent4} ${className}`}>
      <div className={classes.cardElementsCardContentInner}>
        <div className={classes.pillParent}>
          <div className={classes.pill}>
            <div className={classes.iconWrapper}>
              <img
                className={classes.icon1}
                loading="lazy"
                alt=""
                src={getRepoIconByProvider(repo.provider)}
                style={{ width: "16px", height: "16px" }}
              />
            </div>
            <div className={classes.githubRepository}>
              {`${repo.provider} Repository`}
            </div>
          </div>
        </div>
        <Tooltip title="More options">
          <IconButton
            onClick={handleMenuClick}
            style={{ position: "relative", right: 0, top: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleCopyLink(repo?.url)}  style={{ color: "#4667cb" }} >
            <FileCopyIcon style={{ marginRight: 8 }} />
            Copy Repository Link
          </MenuItem>
        </Menu>
      </div>
      <div className={classes.nemoBackendParent}>
        <OpenRepository
          onClick={handleShowRepositoryDetail}
          text={repoProviderUtils.getRepositoryShortNameByProvider(
            repo.url,
            repo.provider
          )}
        />
      </div>
      <div className={classes.lastScanOnParent}>
        {getLastScanSummary(repo.last_scan_summary)}
      </div>
    </div>
  );
};

CardElementsCardContent.propTypes = {
  className: PropTypes.string,
  repo: PropTypes.object.isRequired,
};

export default CardElementsCardContent;
