import { useState, useEffect } from "react";

const providerToFaqMap = {
  github: () => require("../FTU/GITHUB-PIPELINE-FAQ.json"),
  bitbucket: () => require("../FTU/BITBUCKET-PIPELINE-FAQ.json"),
  gitlab: () => require("../FTU/GITLAB-PIPELINE-FAQ.json"),
};

const useFirstTimeUseGuide = (values) => {
  const [processedData, setProcessedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const provider = values?.provider?.toLowerCase();
  const url = values?.webhookUrl;
  const loadFaq = providerToFaqMap[provider];
  console.info("URL: " + url);

  useEffect(() => {
    if (!loadFaq) {
      setLoading(false);
      return;
    }

    const fetchFaq = async () => {
      try {
        // Clear require cache for all JSON files
        delete require.cache[
          require.resolve("../FTU/GITHUB-PIPELINE-FAQ.json")
        ];
        delete require.cache[
          require.resolve("../FTU/BITBUCKET-PIPELINE-FAQ.json")
        ];
        delete require.cache[
          require.resolve("../FTU/GITLAB-PIPELINE-FAQ.json")
        ];

        const faq = loadFaq();
        const processed = faq.map((section) => {
          if (section.codeSnippet) {
            console.info(JSON.stringify(section.codeSnippet));
            section.codeSnippet = section.codeSnippet.replace(
              /\${webhookUrl}/g,
              url
            );
          }
          return section;
        });
        setProcessedData(processed);
        setLoading(false);
      } catch (fetchError) {
        setError(fetchError);
        setLoading(false);
      }
    };

    fetchFaq();
  }, [values, loadFaq]);

  return { processedData, loading, error };
};

export default useFirstTimeUseGuide;
