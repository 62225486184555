import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.blue["young"],
    borderRadius: 4,
    color: "white",
    display: "inline-block",
    fontSize: 15,
    fontWeight: 600,
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 3),
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
