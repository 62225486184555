import React from "react";

const SendGrid = () => (
  <svg
    width={2500}
    height={2500}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <path fill="#9DE1F3" d="M85.334 85.333H0V256h170.667V85.333z" />
    <path fill="#27B4E1" d="M85.334 0v170.666H256V0z" />
    <path
      fill="#1A82E2"
      d="M0 256h85.333v-85.333H0zM170.667 85.333H256V0h-85.333z"
    />
    <path fill="#239FD7" d="M85.334 170.667h85.333V85.334H85.334z" />
  </svg>
);

export default SendGrid;
