import LoginLayout from "components/login/LoginLayout";
import Form from "components/SimpleRegister";
import React from "react";

const SignInSide = () => {
  return (
    <LoginLayout
      simple
      title="Welcome"
      message="Complete the following form to sign up."
      form={<Form />}
    />
  );
};

export default SignInSide;
