import React from "react";
import Chart from "./Radialchart";
import SVGContainer from "charts/SVGContainer/SVGContainer";
import Loader from "components/Loader";
import isEmpty from "lodash.isempty";

const Radialchart = (props) => {
  return props.loading || isEmpty(props.data) ? (
    <Loader />
  ) : (
    <SVGContainer geometricPresicion disableScrolling transition={false}>
      <Chart {...props} />
    </SVGContainer>
  );
};

export default Radialchart;
