const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  expandedSections: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    width: "60%",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
