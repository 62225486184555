import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    lineHeight: "24px",
    marginBottom: "0px",
  },
  inputContainer: {
    width: "100%",
  },
  helperText: {
    marginTop: "4px",
    lineHeight: "22px",
    fontSize: "12px",
    color: "#4f4f4f",
    fontWeight: 500,
    marginBottom: "0px",
  },
  textField: {
    backgroundColor: "#f6f9fe",
    borderRadius: "6px",
    height: "37px",
    "&>div": {
      color: `${theme.palette.blue[600]}`,
      fontSize: "15px",
      backgroundColor: "white",
      "&>div": {
        padding: "10px 26px 10px 14px",
      },
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.grey[200],
      margin: "0.5rem 0 0",
    },
    "& fieldset:hover": {
      border: `solid 1px ${theme.palette.blue[600]}`,
    },
    "&::placeholder": {
      fontStyle: "italic",
    },
    "&::-webkit-placeholder": {
      fontStyle: "italic",
    },
  },
  selectMenu: {
    backgroundColor: "#f6f9fe",
    borderRadius: "6px",
    color: theme.palette.blue[600],
    border: "solid 1px #a4bef1",
  },
  menuItem: {
    fontSize: "15px",
  },
}));
