import React from "react";

const MenuDots = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#3F51B5"
      fillRule="evenodd"
      d="M18 4a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-8 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-2-10a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm2-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 20a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm2-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 4a2 2 0 1 0 4 0 2 2 0 0 0-4 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default MenuDots;
