import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import useStyles from "./styles";
import InputContainer from "components/Consents/Collector/Configuration/components/InputContainer";

const PositionInput = (props) => {
  const {
    input: { value, onChange },
    label,
    loading,
    onSubmit,
  } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
    onSubmit(value);
  };
  return (
    <InputContainer label={label} showButton={false}>
      <RadioGroup
        value={value}
        onChange={handleChange}
        className={classes.radioButtonGroup}
      >
        <FormControlLabel
          value="left"
          control={<Radio color="primary" />}
          label="Left"
          labelPlacement="end"
          disabled={loading}
          classes={{
            root: classes.radioButton,
            label: classes.label,
          }}
        />
        <FormControlLabel
          value="right"
          control={<Radio color="primary" />}
          label="Right"
          labelPlacement="end"
          disabled={loading}
          classes={{
            root: classes.radioButton,
            label: classes.label,
          }}
        />
      </RadioGroup>
    </InputContainer>
  );
};

export default PositionInput;
