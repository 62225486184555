import React, { useMemo, useState } from "react";
import { useStyles } from "./styles";
import ReactHtmlParser from "react-html-parser";
import { ReactComponent as Land } from "../../../land.svg";
import { ReactComponent as HighIcon } from "../../../Information_tab.svg";
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import Title from "pages/AppReport/common/Title";
import SectionContent from "pages/AppReport/common/SectionContent";
import ExpandedPanel from "../ExpandedPanel";
import { makeStyles } from "@material-ui/core";
import { SECTION_ID, SECTION_TITLE } from "pages/AppReport/constants";

export const severities = [
  {
    label: "HIGH",
    value: "high",
    icon: <HighIcon />,
  },
  {
    label: "WARNING",
    value: "warning",
    icon: <ReportProblemOutlined style={{ color: "#ED6C02" }} />,
  },
  {
    label: "INFO",
    value: "info",
    icon: <InfoOutlined />,
  },
  {
    label: "SECURE",
    value: "secure",
    icon: <CheckCircleOutlineOutlined />,
  },
  {
    label: "HOTSPOT",
    value: "hotspot",
    icon: <Land />,
  },
];

const SEVERITY_SECTION_NAME = {
  network: SECTION_ID.networkSecurity,
  code: SECTION_ID.codeAnalysis,
  permissions: SECTION_ID.applicationPermissions,
  trackers: SECTION_ID.trackers,
  manifest: SECTION_ID.manifestAnalysis,
  certificate: SECTION_ID.certificateInfo,
};

const SeverityPanels = ({ data, columns, emptyMessage }) => {
  const [expanded, setExpanded] = useState();
  const formattedData = useMemo(() => {
    const groupedData = {
      high: [],
      warning: [],
      info: [],
      secure: [],
      hotspot: [],
    };
    severities.forEach((severity) => {
      data.appsec[severity.value] &&
        data.appsec[severity.value].forEach((item) => {
          const section = item.section;

          if (!groupedData[severity.value][section]) {
            groupedData[severity.value][section] = [];
          }

          groupedData[severity.value][section].push(item);
        });
    });
    return groupedData;
  }, [data]);

  const classes = usePanelStyles();
  return (
    <SectionContent>
      {severities.map((severity) => (
        <ExpandedPanel
          title={
            <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
              {severity.icon}
              <div
                style={{ marginLeft: severity.value === "hotspot" ? -8 : 0 }}
              >
                {severity.label}
              </div>
            </div>
          }
          classes={{
            root: "scrollable-element",
            header: classes[severity.value],
          }}
          simpleTitle
          key={severity.label}
          onChange={() =>
            setExpanded(expanded !== severity.value ? severity.value : "")
          }
          expanded={expanded === severity.value}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Object.entries(formattedData[severity.value]).map((details) => (
              <div>
                <h3>{SECTION_TITLE[SEVERITY_SECTION_NAME[details[0]]]}</h3>
                <ul>
                  {details[1].map((el) => (
                    <li>
                      <b>{el.title}</b>
                      <br />
                      <p>
                        {ReactHtmlParser(
                          el.description.replaceAll("\n", "<br />")
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </ExpandedPanel>
      ))}
    </SectionContent>
  );
};

const usePanelStyles = makeStyles(() => ({
  high: {
    color: "color-mix(in srgb, #000000 60%, #D32F2F)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #D32F2F)",
  },
  warning: {
    color: "color-mix(in srgb, #000000 60%, #ED6C02)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #ED6C02)",
  },
  info: {
    color: "color-mix(in srgb, #000000 60%, #0288D1)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #0288D1)",
  },
  secure: {
    color: "color-mix(in srgb, #000000 60%, #2E7D32)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #2E7D32)",
  },
  hotspot: {
    color: "color-mix(in srgb, #000000 60%, #000000)",
    backgroundColor: "color-mix(in srgb, #ffffff 84%, #000000)",
  },
}));

const FindingSeverityPanels = ({ severities = {}, data = {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SeverityPanels data={data} />
    </div>
  );
};

export default FindingSeverityPanels;
