import React, { useMemo } from "react";
import ProviderBox from "../Providers/ProviderBox";
import { default as useStyles } from "./styles";
import { Checkbox, FormControl, TextField, Typography } from "@material-ui/core";
import { Field } from "react-final-form";
import ListField from "./ListField";

const ProviderForm = ({ provider, onChange }) => {
  const classes = useStyles();

  const formattedSchema = useMemo(() => {
    return provider?.auth?.properties || [];
  }, [provider]);
  return (
    <div className={classes.formContainer}>
      <ProviderBox
        provider={provider}
        actionConfig={{
          label: "Change",
          onClick: () => {
            onChange(null);
          },
        }}
      />
      {provider.documentation_url && (
        <div style={{
          boxShadow: "0px 1px 6.400000095367432px 1px #0000001A",
          padding: 8,
          borderLeft: "5px solid #1a3586",
          color: "#686c78",
        }}>
          Need help filling out these fields? <a href={provider.documentation_url} style={{ color: "#007bff", }} target="_blank">Open docs</a>
        </div>
      )}
      {formattedSchema.length && (
        formattedSchema.map((field) => (
          <FormControl className={classes.fieldContainer}>
            <Field
              defaultValue={field.type === "boolean" ? false : null}
              name={field.key}
              key={field.key}
              render={({
                input: { value, onChange, onBlur },
                meta: { touched, error, submitError },
              }) => (
                <>
                  <div className={classes.leftContainer}>
                    <Typography
                      id="resource-label"
                      className={classes.label}
                    >
                      {field.label}
                    </Typography>
                  </div>
                  {field.type === "list" ? (
                    <ListField
                      field={field}
                      value={value}
                      onChange={onChange}
                      classes={classes}
                      onBlur={onBlur}
                      touched={touched}
                      error={error}
                      submitError={submitError}
                    
                    />
                  ) : field.type === "boolean" ? (
                    <div className={classes.rightContainer}>
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                      />
                    </div>
                  ) : (
                    <div className={classes.rightContainer}>
                      <TextField
                        inputProps={{
                          "aria-labelledby": "resource-label",
                        }}
                        value={value}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          onChange(newValue);
                        }}
                        className={classes.textField}
                        variant="filled"
                        // placeholder="Type the URL here"
                        error={touched && (error || submitError)}
                        onBlur={onBlur}
                        required
                        helperText={
                          touched &&
                          (error || submitError) && (
                            <span style={{ color: "red" }}>{error}</span>
                          )
                        }
                      />
                    </div>
                  )}
                </>
              )}
              // validate={validateSite}
            />
          </FormControl>
        ))
        // </>
      )}
    </div>
  );
};

export default ProviderForm