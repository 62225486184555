import { useEffect } from "react";
import useQuery from "hooks/useQuery";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { connect } from "react-redux";

const WFLoginContainer = ({ dispatch }) => {
  const query = useQuery();
  useEffect(() => {
    const k = query.get("k");
    dispatch(authenticationActinon.webflowLogin(k));
  });
  return null;
};

const WFLogin = connect()(WFLoginContainer);
export default WFLogin;
