import React from "react";
import Switch from "components/Inputs/Switch";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { getFilterScansVisibility } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const ScansVisibility = ({ visibility, setVisibility }) => {
  const classes = useStyles();

  return (
    <Switch
      label="Visibility"
      className={classes.filtersInput}
      value={visibility}
      labelPlacement="start"
      text="Show Scans w/Errors"
      onChange={setVisibility}
    />
  );
};

const mapStateToProps = (state) => ({
  visibility: getFilterScansVisibility(state),
});

const mapDispatchToProps = (dispatch) => ({
  setVisibility: (value) => dispatch(selectData(TYPES.SCANS.VISIBILITY, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScansVisibility);
