import React from "react";
import {
  createPolicy,
  assignPolicy,
} from "redux/_consents/_policies/policies.async.actions";
import {
  getConsentsPoliciesData,
  getPoliciesLoadingState,
} from "redux/_consents/_policies/policies.selectors";
import { connect } from "react-redux";

import CreatableSelect from "components/Inputs/CreatableSelect";

const PurposeCreatableSelect = (props) => <CreatableSelect {...props} />;

const mapStateToProps = (state) => ({
  loading: getPoliciesLoadingState(state),
  options: getConsentsPoliciesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  create: (name) => dispatch(createPolicy({ name })),
  update: (tableId, policyId) => dispatch(assignPolicy(tableId, policyId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurposeCreatableSelect);
