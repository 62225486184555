import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "2px",
    color: theme.palette.grey[300],
    textTransform: "uppercase",
  },
  gridItem: {
    height: 214,
  },
}));
