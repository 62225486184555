import useParentStyles from "./styles.js";
import {
    Card,
    Typography,
  } from "@material-ui/core";

export const AmountTagsCard = ({
    titleAmountRequests,
    amountRequests,
    titleUniqueTags,
    amountUniqueTags,
  }) => {
    const parentClasses = useParentStyles();
  
    return (
        <Card className={parentClasses.card} elevation={16}>
            <Typography className={parentClasses.cardTitle}>
                {titleAmountRequests}
            </Typography>
            <Typography className={parentClasses.cardText}>
                {amountRequests}
            </Typography>
            <Typography className={parentClasses.cardTitle}>
                {titleUniqueTags}
            </Typography>
            <Typography className={parentClasses.cardText}>
                {amountUniqueTags}
            </Typography>
        </Card>
    );
  };