import React from "react";
import useStyles from "./styles";
import { connect } from "react-redux";
import { getCookiePolicies } from "redux/_admin/_company/company.async.actions";
import moment from "moment";
import { purposeToCategory } from "../Cookies";

const Policy = ({ account, cookies }) => {
  const classes = useStyles();

  if (!account.company) return null;

  const name = account.company.name || "_______________";
  const email = account.company.email || account.email || "_______________";
  const address = account.company.address || "_______________";

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Cookie Policy</h1>

      <p className={classes.date}>
        <span className={classes.bold}>Last updated:</span>{" "}
        {moment().format("D MMMM YYYY")}
      </p>
      <p className={classes.brief}>
        This website uses cookies. In this cookie policy, you can find
        information about cookies that we use, their purposes, and your right to
        manage cookies.
      </p>
      <div className={classes.index}>
        <ol>
          <li>Responsible Entity</li>
          <li>About Cookies</li>
          <li>Cookies That We Use</li>
          <li>Cookie Consent</li>
          <li>Disabling Cookies</li>
          <li>Interest-Based Advertising</li>
          <li>Google Analytics</li>
          <li>Contact</li>
        </ol>
      </div>

      <h2 className={classes.subTitle}>1. Responsible Entity</h2>
      <p>
        This website is owned and operated by {name} having an address at{" "}
        {address || "_______________"} (“we,” “us,” or “our”). We are
        responsible for the processing of personal data through this website,
        including placing first-party cookies.
      </p>

      <h2 className={classes.subTitle}>2. About Cookies</h2>
      <p>
        A cookie is a small digital file consisting of letters and numbers. When
        you visit websites, those websites may send cookies to your browser.
        After receiving cookies, your browser may store them on your computing
        device for some time. Different types of cookies have different
        expiration times. Cookies are used to recognize your device, collect
        information on how you use websites and monitor your behavior on the
        Internet. Cookies may help websites to remember your preferences.
      </p>
      <p>There are several types of cookies, namely:</p>
      <ul className={classes.list}>
        <li>Persistent cookies remain valid until deleted by you;</li>
        <li>
          Limited duration cookies remain valid until their expiration date; and
        </li>
        <li>
          Session cookies are stored on a web browser and remain valid until the
          moment the browser is closed.
        </li>
      </ul>
      <p>Depending on their provider, cookies may also be:</p>
      <ul className={classes.list}>
        <li>First-party cookies (set by the website itself); and</li>
        <li>Third-party cookies (placed by third-party websites).</li>
      </ul>

      <h2 className={classes.subTitle}>3. Cookies That We Use</h2>
      <p>We may use different types of cookies on this website including:</p>
      <ul className={classes.list}>
        <li>
          <span className={classes.bold}>Essential technical cookies</span> that
          are strictly necessary to ensure the correct functioning of our
          website and provide the services requested by you;{" "}
        </li>
        <li>
          <span className={classes.bold}>Preference cookies</span> that allow us
          to save and preserve your preferences;
        </li>
        <li>
          <span className={classes.bold}>Marketing cookies</span> that allow us
          to create, implement, and examine our marketing campaigns. Such
          cookies allow us to reach the right customers, analyze the
          productivity of our marketing campaigns, and offer you personalized
          advertisement; and
        </li>
        <li>
          <span className={classes.bold}>Statistics cookies</span> that allow us
          to generate statistical reports about how you use our website.
        </li>
      </ul>
      <p>
        The table below provides an overview of the cookies that we use on our
        website, including their names, providers, expiration times, and
        purposes.
      </p>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>
              Provider
              <br />
              (data sent to)
            </th>
            <th>Expiration</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>
          {cookies &&
            cookies.map(({ name, provider, expiration, purpose }) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{provider}</td>
                <td>{expiration}</td>
                <td>{purposeToCategory[purpose] || purpose}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <h2 className={classes.subTitle}>4. Cookie Consent</h2>
      <p>
        When you visit our website, you may be presented with a cookie consent
        banner that asks you to provide us with your consent to our use of all
        cookies. This banner allows you to either accept all cookies or choose
        certain categories of cookies that you wish to activate. If you do not
        provide your opt-in consent, we will not serve you our non-essential
        (preference, statistics, marketing) cookies. Please note that, if you do
        not enable all cookies, you may not be able to enjoy the best possible
        user experience on our website.
      </p>

      <h2 className={classes.subTitle}>5. Disabling Cookies</h2>
      <p>
        When we ask you to provide your consent to our use of non-essential
        cookies, you have the freedom not to provide such consent. We will
        respect your choice. If you provide your consent and, later, decide to
        refuse our use of non-essential cookies, you can do it at any time by
        declining cookies in your browser or device. For more information, you
        can consult the cookie management instructions of your browser:
      </p>
      <ul className={classes.list}>
        <li>
          <span className={classes.bold}>Apple Safari:</span>{" "}
          https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
        </li>
        <li>
          <span className={classes.bold}>Google Chrome:</span>{" "}
          https://support.google.com/chrome/answer/95647
        </li>
        <li>
          <span className={classes.bold}>Firefox:</span>{" "}
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
        </li>
        <li>
          <span className={classes.bold}>Opera:</span>{" "}
          https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/
        </li>
        <li>
          <span className={classes.bold}>Microsoft Edge:</span>{" "}
          https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
        </li>
      </ul>

      <h2 className={classes.subTitle}>6. Interest-Based Advertising</h2>
      <p>
        After visiting our website, you may encounter targeted interest-based
        advertising based on your use of our website and other websites on the
        Internet. In most cases, such advertising is based on your
        cookie-generated data. You can control how such advertising is shown to
        you or opt-out from targeted advertising by managing your cookies and
        consulting the guide powered by the Digital Advertising Alliance
        available at https://youradchoices.com. For more information on
        opting-out from advertising features on your device, please visit
        https://www.networkadvertising.org .
      </p>

      <h2 className={classes.subTitle}>7. Google Analytics</h2>
      <p>
        We use Google Analytics, a web analytics service provided by Google LLC
        registered at 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (
        <span className={classes.bold}>“Google”</span>). Google generates
        statistical information by means of cookies and creates reports about
        your use of our website. The cookies served by Google are anonymous
        first-party cookies that do not allow us to identify you in any manner.
        The information generated by cookies will be transmitted to and stored
        by Google on servers in the United States. To ensure your privacy, your
        IP address will be anonymized and Google will not combine your IP
        address with other information Google holds about you. Thus, Google will
        not be able to identify you. In certain cases (e.g., when required by
        law or when third parties conduct services on behalf of Google), Google
        may transfer the information to third parties. For more information
        about Google Analytics’ privacy practices, please visit
        https://support.google.com/analytics/answer/6004245. If you would like
        to opt-out from Google Analytics, you can do so by installing a Google
        Analytics opt-out browser add-on available at
        https://tools.google.com/dlpage/gaoptout?hl=en.
      </p>

      <h2 className={classes.subTitle}>7. Google Analytics</h2>
      <p>
        If you have any questions about our cookies, this cookie policy, or data
        protection practices, please contact us by using the following contact
        details:
      </p>
      <div className={classes.contact}>
        <span className={classes.bold}>Our email address:</span> {email}
        <br />
        <span className={classes.bold}>Postal address:</span> {address}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCookies: (values) => dispatch(getCookiePolicies(values)),
});

const mapStateToProps = ({ account, admin }) => ({
  account: account.data,
  cookies: admin.company ? admin.company.cookies : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
