import React, { useEffect, useMemo, useState } from "react";
import isNil from "lodash.isnil";
import { Switch, Route } from "react-router-dom";
import GeoDistribution from "assets/images/requests/analytics-geo-distribution.png";
import Timeline from "assets/images/requests/analytics-timeline.png";
import Spotlight from "assets/images/requests/analytics-spotlight.png";

const RequestsAnalytics = (props) => {
  const { match, location, history } = props;

  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const [value] = useState(pathValue || "geo-distribution");

  useEffect(() => {
    if (isNil(pathValue)) history.push(`/zen/requests/analytics/${value}`);
  });

  useEffect(() => {
    history.push(`/zen/requests/analytics/${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Switch>
      <Route
        path={"/zen/requests/analytics/timeline"}
        render={(props) => <img src={Timeline} alt="" />}
      />
      <Route
        path={"/zen/requests/analytics/geo-distribution"}
        render={(props) => <img src={GeoDistribution} alt="" />}
      />
      <Route
        path={"/zen/requests/analytics/spotlight"}
        render={(props) => <img src={Spotlight} alt="" />}
      />
    </Switch>
  );
};

export default RequestsAnalytics;
