import React, { useState, useRef } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Box, TextField, Button, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { createUser } from "redux/_admin/_users/users.async.actions";
import { getUserCreationLoadingState } from "redux/_admin/_users/users.selectors";

import useStyles from "./styles";

const GroupedInput = ({ createUser, className, loading }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");

  const emailInput = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (!createUser) return;

    if (!email) {
      if (emailInput && emailInput.current) emailInput.current.focus();
      return;
    }

    createUser({ email })
      .then((_) => {
        enqueueSnackbar("Invitation successfully sent!", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorKey = Object.keys(error)[0];
        const errorMessage = Array.isArray(error[errorKey])
          ? error[errorKey][0]
          : error[errorKey];
        enqueueSnackbar(
          `${
            errorMessage[0].toUpperCase() + errorMessage.slice(1) ||
            "An error ocurred, please try later."
          }`,
          { variant: "error" }
        );
      });
    setEmail("");
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  return (
    <Box className={clsx(classes.container, className)}>
      <TextField
        color="primary"
        disabled={loading}
        value={email}
        onChange={handleEmailChange}
        className={classes.inputContainer}
        InputProps={{
          className: classes.defaultHeight,
          classes: {
            input: classes.input,
            notchedOutline: classes.inputOutline,
          },
        }}
        variant="outlined"
        placeholder="Invite someone..."
        inputRef={emailInput}
      />
      <Button
        onClick={handleSubmit}
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={loading}
      >
        Send
        {loading && (
          <CircularProgress
            color="primary"
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  loading: getUserCreationLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedInput);
