import React, { useMemo } from "react";
import clsx from "clsx";
import useStyles from "./styles";

const StatusBubble = ({ status }) => {
  const classes = useStyles();
  const label = useMemo(
    () => status[0].toUpperCase() + status.slice(1, status.length),
    [status]
  );

  return <div className={clsx(classes.bubble, classes[status])}>{label}</div>;
};

export default StatusBubble;
