import React from "react";
import MUITooltip from "@material-ui/core/Tooltip";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2),
    borderRadius: "10px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    minWidth: "205px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  rightTitle: {
    fontSize: "14px",
    textTransform: "uppercase",
    marginLeft: "1rem",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
  },
  decisionStat: {
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    lineHeight: 1.83,
    color: theme.palette.grey[200],
  },
}));

const labels = {
  vf: "VIEWED FORM",
  ac: "ANY CONSENT",
  fc: "FULL CONSENT",
  na: "NO ACTION",
  fu: "FULL UNCONSENT",
};

const Tooltip = ({ data, children }) => {
  const classes = useStyles();
  return (
    <MUITooltip
      classes={{ tooltip: classes.root, touch: classes.touch }}
      placement="right"
      title={
        !data ? (
          ""
        ) : (
          <Box className={classes.container}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" className={classes.title}>
                {data.full_name}
              </Typography>
              <Typography variant="h4" className={classes.rightTitle}>
                {data.tooltipLabel}
              </Typography>
            </Box>

            <Box className={classes.content}>
              <Box
                className={classes.subtitleContainer}
                style={{ paddingBottom: "8px" }}
              >
                <Typography className={classes.subtitle} display="inline">
                  DECISION STATS
                </Typography>
              </Box>
              {["vf", "ac", "fc", "na", "fu"].map((key) =>
                key === data.decisionFilter ? (
                  <Box key={labels[key]} className={classes.subtitleContainer}>
                    <Typography
                      className={classes.decisionStat}
                      display="inline"
                    >
                      {labels[key]}
                    </Typography>
                    <Typography
                      className={classes.decisionStat}
                      display="inline"
                    >
                      {Number(data.value).toFixed(2)}%
                    </Typography>
                  </Box>
                ) : null
              )}
            </Box>
          </Box>
        )
      }
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
