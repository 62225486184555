const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  high: {
    color: "color-mix(in srgb, #000000 60%, #D32F2F)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #D32F2F)",
  },
  medium: {
    color: "color-mix(in srgb, #000000 60%, #ED6C02)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #ED6C02)",
  },
  info: {
    color: "color-mix(in srgb, #000000 60%, #0288D1)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #0288D1)",
  },
  secure: {
    color: "color-mix(in srgb, #000000 60%, #2E7D32)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #2E7D32)",
  },
  headerFilter: {
    marginLeft: "20px",
  },
  select: {
    width: 272,
    height: 49,
    border: "2px #1B3586 solid",
    borderRadius: 4,
  },
  filterSelect: {
    "&.url": {
      "& > .MuiFormControl-marginNormal": {
        "& > .MuiInputBase-formControl": {
          "& > .MuiSelect-selectMenu": {
            backgroundColor: "#1a3586",
            color: "#6bd4cb",
            fontWeight: "bold",
          },
          "& > svg": {
            color: "#6bd4cb",
          },
          "& > fieldset": {
            paddingLeft: "8px",
            borderStyle: "none",
            borderBottom: "solid 1px #37DCCE",
            borderRadius: 0,
          },
        },
      },
    },
    "& > .MuiFormControl-marginNormal": {
      width: 240,
      "& > .MuiInputBase-formControl": {
        borderRadius: "10%",
        "& > .MuiSelect-selectMenu": {
          display: "flex",
        },
      },
    },
  },
}));

export default useStyles;
