import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import Brand from "../../SummaryPage/brand-logo";
import styles from "./styles";

const Footer = ({ logo = "" }) => {
  return (
    <View fixed style={styles.container}>
      <View style={[logo !== null ? styles.customBrandIcon : styles.brand]}>
        {logo !== null && !logo.endsWith("media/LOGO.png") ? (
          <Image src={logo} style={styles.customBrandIcon} />
        ) : (
          <Brand />
        )}
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber }) => `${pageNumber}`}
      />
    </View>
  );
};

export default Footer;
