import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import WebHookBranchRow from "./WebHookBranchRow";
import { useDispatch } from "react-redux";
import { setRepositoryWebhookEnabled } from "redux/_scanner/scanner.actions.js";

const WebHookBranchListDisplay = ({ branches: initialBranches, patchData }) => {
  const [branches, setBranches] = useState([]);
  const [loadingBranchIds, setLoadingBranchIds] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const sortedBranches = [...initialBranches].sort(
      (a, b) => b.webhook_enabled - a.webhook_enabled
    );
    setBranches(sortedBranches);
  }, [initialBranches]);

  useEffect(() => {
    const allDisabled = branches.every((branch) => !branch.webhook_enabled);
    const atLeastOneEnabled = branches.some((branch) => branch.webhook_enabled);

    if (allDisabled) {
      dispatch(setRepositoryWebhookEnabled(false));
    } else if (!allDisabled && atLeastOneEnabled) {
      dispatch(setRepositoryWebhookEnabled(true));
    }
  }, [branches, dispatch]);

  const handleToggle = async (branchId, webhookEnabled) => {
    setLoadingBranchIds((prev) => [...prev, branchId]);
    await patchData(branchId, webhookEnabled);
    setBranches((prev) =>
      prev.map((branch) =>
        branch.id === branchId
          ? { ...branch, webhook_enabled: webhookEnabled }
          : branch
      )
    );
    setLoadingBranchIds((prev) => prev.filter((id) => id !== branchId));
  };

  if (!branches.length) {
    return <Typography>No branches available</Typography>;
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      <Typography>Webhook branches</Typography>
      <Box style={{ background: "#F7F7F7", borderRadius: 4 }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          {branches.map((branch) => (
            <Grid item xs={12} key={branch.id}>
              <WebHookBranchRow
                branch={branch}
                isLoading={loadingBranchIds.includes(branch.id)}
                onToggle={handleToggle}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default WebHookBranchListDisplay;
