import applications from "./applications.svg";
import full_consents from "./full_consents.svg";
import partners from "./partners.svg";
import policies from "./policies.svg";
import tracked from "./tracked.svg";

export default {
  applications,
  full_consents,
  partners,
  policies,
  tracked,
};
