import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";

const HardcodedSecretsSection = ({ goToNextSectionAction, data }) => {
  const formattedData = data.map((secrets) => ({ secrets }));
  return (
    <Page
      key={SECTIONS_CONTENT.hardcodedSecrets.key}
      footerBackgroundColor={SECTIONS_CONTENT.hardcodedSecrets.backgroundColor}
      footerText={
        <div className="privacy-tech">
          {/* Check the test scores in detail */}
        </div>
      }
      sidebar={
        <SideBar
          title={SECTIONS_CONTENT.hardcodedSecrets.title}
          description={SECTIONS_CONTENT.hardcodedSecrets.description}
        />
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Table
        data={formattedData}
        limitPerPage={5}
        columns={[
          {
            key: "secrets",
            label: "Possible Secrets",
            width: "100%",
          },
        ]}
      />
    </Page>
  );
};

export default HardcodedSecretsSection;
