import React from "react";
import { Box } from "@material-ui/core";
import { Field } from "react-final-form";
import SectionWrapper from "../../components/sectionWrapper";
import { FormTextInput } from "../../components/inputs";
import DeleteButton from "components/SettingsSections/components/deleteButton";
import useStyles from "./styles";

const Section1 = (props) => {
  const { loading, onDeleteClick, showDeleteButton } = props;
  const classes = useStyles();
  const identity = (value) => value;
  return (
    <SectionWrapper
      title="Basic Information"
      subtitle="All fields are required."
      leftContent={showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
    >
      <Box style={{ display: "flex" }}>
        <Field
          name="name"
          component={FormTextInput}
          label="Name"
          className={classes.fieldSm}
          helperText="Change env. name"
          disabled={loading}
          parse={identity}
        />
        <Field
          name="description"
          label="Description"
          component={FormTextInput}
          helperText="Short description about the env."
          className={classes.fieldMd}
          disabled={loading}
          parse={identity}
        />
      </Box>
    </SectionWrapper>
  );
};

export default Section1;
