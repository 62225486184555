import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Box, Typography, Hidden } from "@material-ui/core";
import { register } from "redux/_register/_register.async.actions";
import ZenDataLogo from "assets/illustrations/brand.js";
import Form from "./form";
import useStyles from "./styles";
import {
  getRegisterLoadingState,
  getRegisterErrorState,
} from "redux/_register/_register.selectors";
import { getInvitation } from "redux/_admin/_users/users.async.actions";
import registerIllus from "assets/illustrations/illus_register.svg";
import Seo, { pages } from "components/Seo";
import isArray from "lodash.isarray";
import { parseQuery } from "utils/queryString";
// import registerBg from 'assets/illustrations/register-bg.svg';

const Register = ({
  location,
  loading,
  error,
  doRegister,
  retrieveInvitation,
}) => {
  const classes = useStyles();
  const [invitation, setInvitation] = useState();

  const handleRegister = async (values) => {
    try {
      await doRegister({
        ...values,
        username: values.email,
        invitation: invitation && invitation.key,
      });
    } catch (e) {
      return isArray(e) && e.length > 0
        ? { password2: e[0].password, ...e[0] }
        : e;
    }
  };

  const loadInvitation = async (key) => {
    let loadedInvitation;
    try {
      loadedInvitation = await retrieveInvitation(key);
    } catch (e) {
    } finally {
      setInvitation(loadedInvitation);
    }
  };

  useEffect(() => {
    const queryParams = parseQuery(location.search);
    if (queryParams.key) {
      loadInvitation(queryParams.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%" height="100%" minHeight="100vh" display="flex">
      <Seo {...pages.register} />

      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignContent="center"
        py={4}
        px={2}
        className={classes.leftContainer}
      >
        <Box m="auto" width="100%" maxWidth={428}>
          <Box textAlign="center" mb={4}>
            <Link
              exact
              target="_top"
              to={{ pathname: "https://www.zendata.dev" }}
            >
              <ZenDataLogo />
            </Link>
          </Box>

          <Box className={classes.formContainer} p={3} pt={4}>
            <Typography component="h2" className={classes.title}>
              Get started in no time
            </Typography>
            <Typography className={classes.subtitle}>
              You don’t need a credit card to begin.
            </Typography>
            <Form
              onSubmit={handleRegister}
              loading={loading}
              invitation={invitation}
            />
            {error && (
              <Box mt={2} textAlign="center">
                <Typography className={classes.error}>{error}</Typography>
              </Box>
            )}
            <Box textAlign="center" mt={3} className={classes.footer}>
              Already have an account? <Link to="/login">Sign in</Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Hidden smDown>
        <Box
          minWidth="60%"
          flexGrow={1}
          className={classes.imageContainer}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box clone display="block" margin="auto">
            <img src={registerIllus} alt="" />
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  loading: getRegisterLoadingState(state),
  error: getRegisterErrorState(state),
});
const mapDispatchToProps = (dispatch) => ({
  doRegister: (data) => dispatch(register(data)),
  retrieveInvitation: (key) => dispatch(getInvitation(key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Register));
