import React from "react";

const CircledForward = ({ turn, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    style={turn ? { transform: "rotate(180deg)" } : {}}
    {...restProps}
  >
    <path
      fill="#3F51B5"
      fill-rule="evenodd"
      d="M.833 10a9.167 9.167 0 1 0 18.334 0A9.167 9.167 0 0 0 .833 10zM17.5 10a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0zM9.41 7.256l1.18-1.179L14.511 10l-3.923 3.922-1.178-1.178L12.155 10 9.41 7.256zM7.257 6.077L6.077 7.256 8.822 10l-2.745 2.744 1.179 1.178L11.178 10 7.256 6.077z"
      clip-rule="evenodd"
      transform={turn ? "rotate(180deg)" : undefined}
    />
  </svg>
);

export default CircledForward;
