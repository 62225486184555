import { mapSectionNameToId } from "../helpers";
import MainSection from "./MainSection";
import Landing from "./Landing";
import moment from "moment";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTION_ID, SECTIONS_CONTENT, ORDERED_ANCHORS } from "../constants";

export const getSectionComponents = ({
  goToNextSectionAction,
  current,
  data,
}) => {
  const sectionKeys = ORDERED_ANCHORS.map(mapSectionNameToId);
  const COMPONENTS = {
    [SECTION_ID.landing]: (
      <Page
        key={SECTIONS_CONTENT.landing.key}
        footerBackgroundColor={SECTIONS_CONTENT.landing.backgroundColor}
        className="blue-background"
        footerText={
          <div className="privacy-tech">Check the report details</div>
        }
        goToNextSectionAction={goToNextSectionAction}
        trimPaddingTop
      >
        {/* this component calculate the values for testScoresItems */}
        <Landing
          current={current}
          content={{
            title: data?.canonical,
            version: moment(data.scanDate).format("MM-DD-YYYY hh:mm") || "",
            scanned: [
              {
                title: "File name:",
                content: data?.canonical,
                className: "url-scanned",
              },
              data.app_type === "apk"
                ? {
                    title: "Package Name",
                    content: "data.package_name",
                  }
                : {
                    title: "Identifier",
                    content: "data.bundle_id",
                  },
              {
                title: "Scan date:",
                content: moment(data.scanDate).format("MM-DD-YYYY hh:mm"),
                className: "last-scanned",
              },
            ],
            resource: data.resource,
            totalDiff: null,
            data: data,
          }}
        />
      </Page>
    ),
    [SECTION_ID.piiViolations]: (
      <MainSection goToNextSectionAction={goToNextSectionAction} data={data} />
    ),
  };
  return sectionKeys.map((sectionId) => COMPONENTS[sectionId]);
};
