import parseDomain from "parse-domain";

const calculateTermsAndConditions = (
  serviceTerms,
  hasPrivacyLink,
  hasTermsLink,
  personalData
) => {
  const hasServiceTerms =
    serviceTerms &&
    serviceTerms.links &&
    Object.keys(serviceTerms.links).length > 0;

  const hasFirstPartyCollectionUse =
    personalData &&
    personalData.firstPartyCollectionUse &&
    personalData.firstPartyCollectionUse[0] &&
    personalData.firstPartyCollectionUse[0].result &&
    Object.keys(personalData.firstPartyCollectionUse[0].result).length > 0;

  const hasThirdPartySharingCollection =
    personalData &&
    personalData.thirdPartySharingCollection &&
    personalData.thirdPartySharingCollection[0] &&
    personalData.thirdPartySharingCollection[0].result &&
    Object.keys(personalData.thirdPartySharingCollection[0].result).length > 0;

  const hasUserChoiceControl =
    personalData &&
    personalData.userChoiceControl &&
    personalData.userChoiceControl[0] &&
    personalData.userChoiceControl[0].result &&
    Object.keys(personalData.userChoiceControl[0].result).length > 0;

  const hasPrivacyPolicy =
    hasPrivacyLink ||
    (hasFirstPartyCollectionUse && hasThirdPartySharingCollection) ||
    hasServiceTerms;
  const hasTermsAndConditions =
    hasTermsLink || hasUserChoiceControl || hasServiceTerms;

  return { hasPrivacyPolicy, hasServiceTerms, hasTermsAndConditions };
};

const classifyCookies = (records, domain) => {
  const parsedDomain = parseDomain(domain);
  const initial = [0, 0];
  if (!records || !domain) return initial;
  return records
    .reduce((prev, curr) => [...prev, ...curr.cookies], [])
    .reduce((prev, curr) => {
      const parsedCookieDomain = parseDomain(curr.uRL || curr.url);
      if (
        parsedDomain &&
        parsedCookieDomain &&
        parsedDomain.domain === parsedCookieDomain.domain &&
        parsedDomain.tld === parsedCookieDomain.tld
      ) {
        return [prev[0] + 1, prev[1]];
      }
      return [prev[0], prev[1] + 1];
    }, initial);
};

export { calculateTermsAndConditions, classifyCookies };
