import React from "react";
import clsx from "clsx";
import { Form as FinalForm, FormSpy } from "react-final-form";
import SubmitBar from "components/FormComponents/SubmitBar";
import { Backdrop, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

const Form = ({
  children,
  onSubmit,
  initialValues,
  showSubmit,
  submitLabel,
  loading,
  className,
  classes: customClasses = {},
  invalid,
}) => {
  const classes = useStyles();
  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form: { reset } }) => (
        <form
          className={clsx(classes.form, className, customClasses.root)}
          onSubmit={handleSubmit}
        >
          {children}

          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="primary" />
          </Backdrop>

          <FormSpy subscription={{ dirty: true }}>
            {({ dirty }) => (
              <SubmitBar
                className={customClasses.submitBar}
                disabled={invalid}
                classes={{
                  root: customClasses.submitBar,
                  discardButton: customClasses.discardButton,
                  submitButton: customClasses.submitButton,
                }}
                show={showSubmit || dirty}
                reset={reset}
                submitLabel={submitLabel}
              />
            )}
          </FormSpy>
        </form>
      )}
    </FinalForm>
  );
};

export default Form;
