import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import SvgIcon from "components/svg-icon/svg-icon";
import { useSnackbar } from "notistack";
import Tooltip from "components/Tooltip";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.grey[400],
    boxShadow: `0 2px 4px 0 ${theme.palette.blue[200]}`,
    borderRadius: "2px",
    padding: "6px",
  },
}));

const CopyTextButton = (props) => {
  const { text, message, tooltip } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = (e) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(text)
      .then((_) =>
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 2000,
        })
      )
      .catch((_) =>
        enqueueSnackbar("There was an error.", {
          variant: "error",
          autoHideDuration: 2000,
        })
      );
  };

  return (
    <Tooltip placement="top" title={tooltip}>
      <IconButton
        onClick={onCopyClick}
        className={classes.button}
        size="small"
        elevation={2}
      >
        <SvgIcon icon="copyclipboard" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyTextButton;
