import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    maxWidth: 562,
    margin: "auto",
  },
  title: {
    color: theme.palette.blue[200],
    fontFamily: "Aldrich, sans-serif",
    fontSize: "48px",
    fontWeight: "normal",
    margin: theme.spacing(0, 0, 2),
    textAlign: "center",
    lineHeight: 1,
    letterSpacing: "1px",
  },
  description: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.common.white,
    textAlign: "center",
    margin: theme.spacing(2, 0),
    lineHeight: 1.5,
  },
}));
