import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import SvgIcon from "components/svg-icon/svg-icon";
import useStyles from "./styles";

const CustomKPIs = ({ title, kpis = [] }) => {
  const classes = useStyles();
  const parsedKpis = kpis.slice(0, 2);
  return (
    <Grid container className={classes.kpisContainer}>
      {title && <Typography className={classes.kpisTitle}>{title}</Typography>}
      {parsedKpis.map((kpi) => (
        <Grid item xs={12} md={6} style={{ paddingRight: "13px" }}>
          <Box>
            <Box className={classes.kpiTitleContainer}>
              {kpi.icon && (
                <SvgIcon className={classes.kpiIcon} icon={kpi.icon} />
              )}
              <Typography className={classes.itemValue}>{kpi.value}</Typography>
            </Box>
            <Typography className={classes.itemTitle}>{kpi.label}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomKPIs;
