import {
  SCORE_DETAIL_WHETHER_POSITIVE_OR_NEGATIVE,
  SCORE_DETAIL_VALUE,
} from "./constants";

export const getScoreDetailValueFor = (key, rawValue) => {
  const isPositive =
    SCORE_DETAIL_WHETHER_POSITIVE_OR_NEGATIVE[key][rawValue] === "positive"
      ? true
      : false;
  return {
    ...SCORE_DETAIL_VALUE[key][
      SCORE_DETAIL_WHETHER_POSITIVE_OR_NEGATIVE[key][rawValue]
    ],
    isPositive,
  };
};
