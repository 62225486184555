import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  itemContainer: {
    paddingBottom: "16px",
  },
  itemLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grey[300],
  },
  itemValue: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: theme.palette.grey[200],
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: theme.palette.blue[500],
    textTransform: "uppercase",
    paddingBottom: "16px",
  },
  itemValueError: {
    color: "#eb5757",
    fontWeight: 700,
  },
  itemValueOk: {
    color: "#27ae60",
    fontWeight: 700,
  },
  itemValueProgress: {
    color: "#e89300",
    fontWeight: 700,
  },
}));
