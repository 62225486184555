import NoEnvFallback from "components/FallbackPages/NoEnv";
import NoScanFallback from "components/FallbackPages/NoScan";
import Layout from "components/Layout";
import Loader from "components/Loader";
import Seo from "components/Seo";
import Account from "pages/Account";
import AdminPage from "pages/Admin";
import BadgesPage from "pages/Badges";
import PiiAPIPage from "pages/PiiAPI";
import ConsentsAnalytics from "pages/Consents/Analytics";
import ConsentsCollector from "pages/Consents/Collector";
import ConsentsOverview from "pages/Consents/Overview";
import CookiesPage from "pages/Cookies";
import GeoDistribution from "pages/DataMap";
import DataOwners from "pages/DataOwners";
import DataStructure from "pages/DataStructure";
import EmptyCharts from "pages/EmptyCharts";
import FunctionalUses from "pages/FunctionalUses";
import Integrations from "pages/Integrations";
import KeyGenerator from "pages/KeyGenerator";
import NotFoundPage from "pages/NotFound";
import DashboardPage from "pages/Overview";
import PiiCountries from "pages/PiiCountries";
import PiiTrends from "pages/PiiTrends";
import PolicyGenerator from "pages/PolicyGenerator";
import ProfilePage from "pages/Profile";
import RequestsAnalytics from "pages/Requests/Analytics";
import RequestsManage from "pages/Requests/Manage";
import RequestsOverview from "pages/Requests/Overview";
import RequestsWidget from "pages/Requests/Widget";
import ScansPage from "pages/Scans";
import ScansSection from "pages/ScansSection";
import AppScansSection from "pages/AppScansSection";
import Scheduling from "pages/Scheduling";
import SecurityScans from "pages/SecurityScansSection";
// import SecurityScans from "pages/SecurityScans";
import Settings from "pages/Settings";
import EnvironmentPage from "pages/zen/environment";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { getAccountData } from "redux/_account/account.selectors";
import { routePaths } from "../router/paths";
import RepositoryScannerView from "./RepositoryScanner";
import RepositoryScannerView2 from "./RepositoryScanner2.0";
import NewRepositoryScannerView from "./RepositoryScanner2.0/newRepositoryScanner";
import DataSourceScannerView from "./DataSourceScanner";
import FileScannerView from "./FileScanner";
import NewRepositoryScannerDetail from "./RepositoryScanner2.0/newRepositoryScannerDetail";
import NewRepositoryScannerBranchDataFlow from "./RepositoryScanner2.0/newRepositoryScannerBranchFlow";
import PIISharingDetails from "./RepositoryScanner2.0/PIISharingDetail";
import codeSnippet from "./RepositoryScanner2.0/codeSnippet";
import FTU from "./RepositoryScanner2.0/ftu";
import WebHookSettingFlow from "./RepositoryScanner2.0/WebHookSettingFlow";

function ZenPage({ account, match, location }) {
  // TODO: cammelize responses coming from `/account`
  return (
    <Layout>
      <Seo title="Zendata" />
      <Switch location={location}>
        <Route
          path="/zen"
          exact
          component={() => {
            if (!account.product_access) {
              return <Loader />;
            }
            if (account.company.name === "Free User") {
              global.location.replace(routePaths.extension);
              return;
            }
            return <Redirect to={"/zen/scans"} />;
          }}
        />
        <Route path="/zen/database-scanner" component={DashboardPage} />
        <Route path="/zen/empty" component={EmptyCharts} />
        <Route exact path={`/zen/initial-setup`} component={NoEnvFallback} />
        <Route exact path={`/zen/first-scan`} component={NoScanFallback} />
        <Route path="/zen/integrations" component={Integrations} />
        <Route path={"/zen/profile"} component={ProfilePage} />
        <Route
          exact
          path={`${match.path}/environment/:env_id/scans`}
          component={ScansPage}
        />
        <Route exact path={`${match.path}/cookies`} component={CookiesPage} />
        <Route
          exact
          path={`${match.path}/environment/:env_id/scans/:scan_id/data-structure`}
          component={DataStructure}
        />
        <Route
          exact
          path={`${match.path}/environment/:env_id/scans/:scan_id/data-map`}
          component={GeoDistribution}
        />
        <Route
          exact
          path={`${match.path}/environment/:env_id/scans/:scan_id/data-owners`}
          component={DataOwners}
        />
        <Route
          exact
          path={`${match.path}/environment/:env_id/scans/:scan_id/functional-uses`}
          component={FunctionalUses}
        />
        <Route
          path={`${match.path}/environment/:env_id/scans/pii-trends`}
          component={PiiTrends}
        />
        <Route
          path={`${match.path}/environment/:env_id/scans/:scan_id/pii-countries`}
          component={PiiCountries}
        />
        <Route
          path={`${match.path}/consents/overview`}
          component={ConsentsOverview}
        />
        <Route
          path={`${match.path}/consents/analytics`}
          component={ConsentsAnalytics}
        />
        <Route
          path={`${match.path}/consents/collector`}
          component={ConsentsCollector}
        />
        <Route
          path={`${match.path}/consents/policy-generator`}
          component={PolicyGenerator}
        />
        <Route
          path={`${match.path}/requests/overview`}
          component={RequestsOverview}
        />
        <Route
          path={`${match.path}/requests/manage`}
          component={RequestsManage}
        />
        <Route
          path={`${match.path}/requests/analytics`}
          component={RequestsAnalytics}
        />
        <Route
          path={`${match.path}/requests/widget`}
          component={RequestsWidget}
        />
        <Route path={`/zen/settings`} component={Settings} />
        <Route
          exact
          path={`/zen/environment/:env_id`}
          component={EnvironmentPage}
        />
        <Route path={`/zen/account`} component={Account} />
        <Route path={`/zen/keygen`} component={KeyGenerator} />
        <Route path={`/zen/badges`} component={BadgesPage} />
        <Route path={`/zen/pii-api`} component={PiiAPIPage} />
        <Route path={`/zen/security-scans`} component={SecurityScans} />
        <Route path={`/zen/scheduling`} component={Scheduling} />
        <Route path={`/zen/scans`} component={ScansSection} />
        <Route path={`/zen/app-scans`} component={AppScansSection} />
        <Route path={`/zen/admin`} component={AdminPage} />
        <Route
          path={`/zen/repository-scans`}
          component={RepositoryScannerView}
        />
        <Route
          path={`/zen/repository-scans2`}
          component={RepositoryScannerView2}
        />
        <Route
          path={`/zen/new-repository-scanner`}
          component={NewRepositoryScannerView}
        />
        <Route
          path={`/zen/new-repository-scanner-details/:repo_id`}
          component={NewRepositoryScannerDetail}
        />
        <Route
          path={`/zen/new-repository-branch-flow/:scan_id`}
          component={NewRepositoryScannerBranchDataFlow}
        />
        <Route
          path={`/zen/pii-sharing-details/:scan_id/`}
          component={PIISharingDetails}
        />
        <Route
          path={`/zen/repo-scan/webhook-settings/:repo_id`}
          component={WebHookSettingFlow}
        />

        <Route path={`/zen/pii-code-snippet/`} component={codeSnippet} />
        <Route path={`${routePaths.REPO_SCANNER_WEBHOOK_SETTINGS}`} />
        <Route path={`/zen/ftu/:ftu_id`} component={FTU} />
        <Route
          path={`/zen/data-source-scans`}
          component={DataSourceScannerView}
        />
        <Route path={`/zen/file-scans`} component={FileScannerView} />
        <Route exact path={`/zen/not-found`} component={NotFoundPage} />
        <Redirect exact from={`${match.path}/:id`} to="/zen/not-found" />
      </Switch>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

export default connect(mapStateToProps)(ZenPage);
