import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    position: "relative",
    paddingRight: "8px",
  },
  inputContainer: {
    display: "flex",
  },
  input: {
    border: "none",
    background: "none",
    fontSize: "15px",
    fontFamily: "Roboto Condensed",
    width: "100%",
    padding: "0 12px",
    "&::placeholder": {
      fontStyle: "italic",
      color: theme.palette.blue[400],
    },
    "&:focus": {
      outline: "none",
    },
  },
  selectedOption: {
    fontSize: "15px",
    fontFamily: "Roboto Condensed",
    width: "100%",
    padding: "0 12px",
    color: theme.palette.blue[400],
  },
  addButton: {
    width: 24,
    height: 24,
    padding: 0,
    minWidth: "unset",
  },
  optionsList: {
    position: "absolute",
    top: "100%",
    width: "100%",
    left: 0,
    right: 0,
    background: "#fff",
    zIndex: 1000,
    marginTop: "1px",
    overflow: "hidden",
    borderRadius: "4px",
  },
  optionItem: {
    color: theme.palette.blue[300],
    fontSize: "15px",
    padding: "4px 12px",
    userSelect: "none",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(112, 144, 217, .2)",
    },
  },
}));
