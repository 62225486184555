import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import humps from "humps";
import { useLayoutEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import {
  privacyPolicyFallback,
  privacyPolicyRetry,
} from "redux/_reports/reports.service";
import { validateSite } from "utils/scanValidations";
import useStyles from "./styles";
import "./styles.scss";

const generateTheme = (total) => {
  // Originally used 1A3586;
  const baseColorHexCode = "1A3590";
  const colorList = Array(total)
    .fill(baseColorHexCode)
    .map((element, index) => {
      const nextColor = (index * total * 20).toString().padStart(6, "0");
      const newColor = (
        parseInt(element, 16) + parseInt(nextColor, 16)
      ).toString(16);
      return `#${newColor}`;
    });

  return [
    colorList,
    (target) => {
      if (target instanceof am4core.InterfaceColorSet) {
        target.setFor("text", am4core.color("#686C78"));
      }
    },
  ];
};

const PersonalDataUsage = ({
  className,
  data,
  email,
  scanId,
  setPrivacyChartRaw,
}) => {
  const classes = useStyles();
  const chartData = useMemo(() => parseData(data), [data]);

  const [showForm, setShowForm] = useState(true);
  const [submitError, setSubmitError] = useState(null);

  useLayoutEffect(() => {
    if (chartData) {
      const [colorList, theme] = generateTheme(chartData.length);
      am4core.useTheme(theme);
      let chart = am4core.create("personalchartdiv", am4charts.SankeyDiagram);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart.data =
        chartData.map((data, index) => {
          const color =
            index % 1
              ? colorList[index]
              : colorList[colorList.length - index - 1];
          return { ...data, color };
        }) || [];

      const gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color("#70acfc"));
      gradient.addColor(am4core.color("#4abcb1"));
      chart.links.template.colorMode = "solid";
      chart.links.template.fill = gradient;
      chart.links.template.fillOpacity = 0.6;
      chart.links.template.tooltipText =
        "{from} -> {to}: {value} appearances found";

      let hoverState = chart.links.template.states.create("hover");
      hoverState.properties.fill = "#1A3575";
      hoverState.properties.fillOpacity = 0.6;

      chart.dataFields.fromName = "from";
      chart.dataFields.toName = "to";
      chart.dataFields.value = "value";
      chart.dataFields.color = "color";
      chart.nodePadding = 2;

      let nodeTemplate = chart.nodes.template;
      nodeTemplate.draggable = false;
      nodeTemplate.stroke = am4core.color("#fff");
      nodeTemplate.nameLabel.label.truncate = false;
      nodeTemplate.nameLabel.width = undefined;
      nodeTemplate.nameLabel.fontFamily = "Inter";
      nodeTemplate.nameLabel.fontWeight = "bold";
      nodeTemplate.nameLabel.adapter.add(
        "horizontalCenter",
        function (center, target) {
          if (target.parent.level === 1) {
            target.horizontalCenter = "right";
            target.label.horizontalCenter = "right";
            target.label.textAlign = "end";
            return "right";
          }
          return center;
        }
      );
      setPrivacyChartRaw(chart.exporting.getImage("png"));
      return () => {
        chart.dispose();
      };
    }
  }, [chartData]);

  const onSubmit = ({ url, policy: content }) => {
    const showError = () => {
      setSubmitError("An error occurred while submitting the form");
    };
    if (content)
      privacyPolicyFallback({ scan_id: scanId, content })
        .then(() => setShowForm(false))
        .catch(showError);
    else if (url) {
      if (validateSite(url)) {
        setSubmitError(validateSite(url));
      } else
        privacyPolicyRetry({ scan_id: scanId, url })
          .then(() => setShowForm(false))
          .catch(showError);
    }
  };

  return (
    <div className={clsx("personal-data", email && "-logged", className)}>
      {chartData ? (
        <figure>
          <div className="header">
            <span>Information Collected*</span>
            <span>Collection Reasons*</span>
          </div>
          <div className={classes.chart} id="personalchartdiv" />
          <figcaption>
            *Size represents number of occurrences in Privacy Policy
          </figcaption>
        </figure>
      ) : (
        <div className={classes.notFound}>
          {showForm ? (
            <>
              <h3>Sorry!</h3>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    className={classes.reSubmitForm}
                  >
                    <Typography className={classes.paragraph}>
                      We couldn't find your privacy policy link, please enter it
                      manually to re-run our natural language processor engine
                      and give you the complete policy analysis.
                    </Typography>
                    <Field name="url">
                      {({ input, meta }) => (
                        <>
                          <input
                            className={classes.siteUrl}
                            type="text"
                            {...input}
                            placeholder="https://yoursite.com/privacy-policy"
                          />
                          {((meta.touched && meta.error) || submitError) && (
                            <p>{meta.error || submitError}</p>
                          )}
                        </>
                      )}
                    </Field>
                    <Typography className={classes.paragraph}>
                      Or copy and paste the content of your privacy policy in
                      the text area below
                    </Typography>
                    <Field
                      className={classes.reSubmitTarea}
                      name="policy"
                      component="textarea"
                    />
                    <Button type="submit" color="primary" variant="outlined">
                      Submit
                    </Button>
                  </form>
                )}
              />
            </>
          ) : (
            <>
              <h3>Thanks!</h3>
              <Typography className={classes.paragraph}>
                We are processing your privacy policy, we will let you now via
                email when it is ready.
              </Typography>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalDataUsage;

const parseData = (data) => {
  if (!data || Object.keys(data).length === 0) {
    return false;
  }

  let { tpsCrossMapping: dataset } = data;
  const wasPribotUsed = dataset === undefined;
  if (
    wasPribotUsed &&
    data.firstPartyCollectionUse &&
    data.firstPartyCollectionUse.length() > 0 &&
    data.firstPartyCollectionUse[0].result
  ) {
    // Keeping pribot logic if its required
    dataset = data.firstPartyCollectionUse[0].result;
  } else if (Object.keys(dataset || {}).length === 0) {
    return false;
  }

  const res = Object.keys(dataset)
    .reduce((acc, dataType) => {
      const list = Object.keys(dataset[dataType]).map((reason) => ({
        from: dataType,
        to: reason,
        value: !wasPribotUsed
          ? dataset[dataType][reason].count
          : dataset[dataType][reason].segments.length,
      }));
      return [...acc, ...list];
    }, [])
    .map((item) => ({
      ...item,
      from: parseTreeChildName(item.from, wasPribotUsed),
      to: " " + parseTreeChildName(item.to, wasPribotUsed),
    }));

  return res;
};

const parseTreeChildName = (name, wasPribotUsed) => {
  let regExp = "/";
  let replaceChar = " ";

  if (wasPribotUsed) {
    const prefixesToBeRemoved = ["personalInformationType", "purpose"];
    regExp = new RegExp(`${prefixesToBeRemoved.join("|")}`, "g");
    replaceChar = "";
  }

  const camelizedName = name.replace(regExp, replaceChar).trim();
  const cleanedName = humps.decamelize(camelizedName, { separator: " " });
  const title = cleanedName
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.substr(1));
  return title.join(" ");
};
