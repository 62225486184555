import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { formatNumber } from "utils/format";
import useStyles from "./styles";
import {
  getFilterLastScanData,
  getFilterSelectedScanData,
} from "redux/_filters/filters.selectors";

const KPIs = (props) => {
  const classes = useStyles();
  const { scan = {}, lastScan = {}, isFilteringByScan } = props;
  return (
    <Grid container className={classes.kpisContainer}>
      {props.title && (
        <Typography className={classes.kpisTitle}>{props.title}</Typography>
      )}

      <Grid item xs={12} md={6} style={{ paddingRight: "13px" }}>
        <Box>
          <Typography className={classes.itemValue}>
            {formatNumber(
              isFilteringByScan
                ? scan.pii_columns || 0
                : lastScan.pii_columns || 0
            )}
          </Typography>
          <Typography className={classes.itemTitle}>PII Columns</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography className={classes.itemValue}>
            {formatNumber(
              isFilteringByScan
                ? scan.total_unprotected || 0
                : lastScan.total_unprotected || 0
            )}
          </Typography>
          <Typography className={classes.itemTitle}>Unprotected</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  scan: getFilterSelectedScanData(state),
  lastScan: getFilterLastScanData(state),
});

export default connect(mapStateToProps, null)(KPIs);
