import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GitHubRepositoryIcon from "assets/svgs-icons/social-github-icon.svg";
import GitLabRepositoryIcon from "assets/svgs-icons/social-gitlab-icon.svg";
import BitbucketRepositoryIcon from "assets/svgs-icons/social-bitbucket-icon.png";

const useStyles = makeStyles((theme) => ({
  pill: {
    borderRadius: "16px",
    backgroundColor: "#f7f7f7",
    border: "1px solid #d6d6d6",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "2px 9px",
    maxWidth: "fit-content"
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 0",
  },
  iconImage: {
    width: "16px",
    height: "16px",
  },
  iconText: {
    color:"#000",
    position: "relative",
    display: "inline-block",
    minWidth: "103px",
    fontFamily: "Inter",
    fontSize: 12,
    marginLeft: 5
  },
}));

const getProviderIcon = (provider) => {
  switch (provider) {
    case "Github":
      return GitHubRepositoryIcon;
    case "Gitlab":
      return GitLabRepositoryIcon;
    case "Bitbucket":
      return BitbucketRepositoryIcon;
    default:
      return null;
  }
};

const RepositoryIcon = ({ provider }) => {
  const classes = useStyles();
  const providerIcon = getProviderIcon(provider);

  if (!providerIcon) return null;

  return (
    <Box className={classes.pill}>
      <Box className={classes.iconWrapper}>
        <img
          loading="lazy"
          alt={`${provider} Repository`}
          src={providerIcon}
          className={classes.iconImage}
        />
        <Typography className={classes.iconText}>
            {`${provider} Repository`}
        </Typography>
      </Box>
    </Box>
  );
};

export default RepositoryIcon;
