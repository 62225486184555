import React from "react";
import { makeStyles } from "@material-ui/core";
import MUITooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2),
    borderRadius: "10px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    minWidth: "205px",
    minHeight: "320px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
  },
}));

const Tooltip = (props) => {
  const classes = useStyles();
  const { data, title: Title, children } = props;

  return (
    <MUITooltip
      classes={{ tooltip: classes.root, touch: classes.touch }}
      placement="right"
      title={!data ? "" : <Title data={data} />}
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
