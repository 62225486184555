import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 20,
  },
  provider: {},
  loaderContainer: {
    top: "40",
  },
}));

export default useStyles;
