import { StyleSheet, Font } from "@react-pdf/renderer";
import Aldrich from "./Aldrich.ttf";
import Inter from "./Inter.otf";
import InterBold from "./Inter-Medium.ttf";

Font.register({
  family: "Aldrich",
  src: Aldrich,
});

Font.register({
  family: "Inter",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

const blue = "#1a3586";
const gray = "#686c78";
const grayLight = "#dfe2e9";

const styles = StyleSheet.create({
  page: {
    color: gray,
    flexDirection: "column",
    fontFamily: "Inter",
    fontSize: 12,
    paddingBottom: "2cm",
  },
  section: {
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
  },
  ul: {
    marginTop: 10,
  },
  li: {
    flexDirection: "row",
    marginTop: 10,
  },
  liText: {
    paddingLeft: 5,
    marginTop: -3,
  },
  paragraph: {
    marginBottom: 20,
  },
  box50: {
    width: "50%",
  },
  box33: {
    width: "33%",
  },
  title: {
    fontSize: 18,
    color: blue,
    fontFamily: "Aldrich",
    marginBottom: 10,
  },
  subTitle: {
    marginTop: 12,
    marginBottom: 3,
    fontWeight: 600,
  },
  center: {
    alignItems: "center",
  },
  table: {
    display: "table",
    fleFlow: "column nowrap",
    borderWidth: 0,
    marginTop: 20,
  },
  thead: {
    width: "100%",
    backgroundColor: grayLight,
  },
  tbody: {
    width: "100%",
  },
  tr: {
    borderLeft: `1px solid ${grayLight}`,
    borderRight: `1px solid ${grayLight}`,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    margin: "auto",
    width: "100%",
  },
  th: {
    borderBottom: `1px solid ${grayLight}`,
    color: gray,
    flexBasis: 0,
    flexGrow: 1,
    fontSize: 10,
    fontWeight: 700,
    justifyContent: "center",
    margin: 0,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  td: {
    alignItems: "center",
    borderBottom: `1px solid ${grayLight}`,
    color: gray,
    flex: 1,
    flexBasis: 0,
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    wordBreak: "break-all",
    overflowWrap: "anywhere",
    textOverflow: "break-word",
    fontSize: 10,
    paddingBottom: 5,
    paddingLeft: 5,
    wordBreak: "break-all",
    paddingTop: 5,
    paddingRight: 5,
  },
});

export default styles;
