import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "0px 24px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  text: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.grey[300],
  },
  colorShowcase: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.08)",
    flexShrink: 0,
    border: "3px solid #FAFAFA",
    margin: "0px 12px",
    cursor: "pointer",
  },
  inputText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grey[200],
  },
  input: {
    textTransform: "uppercase",
  },
  inputContainer: {
    alignItems: "center",
    height: "100%",
    width: "80px",
    flexShrink: 0,
    padding: 0,
    marginRight: "1rem",
    flexDirection: "row",
    "& >div": {
      width: "100%",
    },
  },
  pickerContainer: {
    position: "absolute",
    zIndex: "2",
  },
  picker: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  colorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 50,
  },
}));
