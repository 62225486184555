import React, { useMemo } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Severity from "pages/AppReport/common/Severety";
import Title from "pages/AppReport/common/Title";
import SectionContent from "pages/AppReport/common/SectionContent";

const CodeAnalysisSection = ({
  goToNextSectionAction,
  data,
  appType = "apk",
}) => {
  const codeAnalysisData = useMemo(() => {
    return Object.entries(data).map((codeAnalysis) => ({
      ...(appType === "apk"
        ? {
            files: Object.entries(codeAnalysis[1].files)
              .map((file) => file[0])
              .join("\n"),
            severity: codeAnalysis[1].metadata.severity,
            standards: codeAnalysis[1].metadata,
            issue: codeAnalysis[1].metadata.description,
          }
        : {
            issue: codeAnalysis[0],
            standards: { ...codeAnalysis[1] },
            ...codeAnalysis[1],
          }),
    }));
  });
  return (
    <Page
      key={SECTIONS_CONTENT.codeAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.codeAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the Domain Malware Check</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.codeAnalysis.description}>
        {appType === "apk"
          ? SECTIONS_CONTENT.codeAnalysis.title
          : "IPA Binary Code Analysis"}
      </Title>
      <SectionContent>
        <Table
          data={codeAnalysisData}
          limitPerPage={5}
          columns={[
            {
              key: "issue",
              label: "Issue",
              width: "30%",
            },
            {
              key: "severity",
              label: "Severity",
              width: "10%",
              render: (severity) => <Severity severity={severity} />,
            },
            {
              key: "standards",
              label: "Standards",
              width: "25%",
              render: (standards) => (
                <div>
                  {standards.cwe && (
                    <>
                      <span>
                        <b>CWE:</b> {standards.cwe}
                      </span>
                      <br />
                    </>
                  )}
                  {standards["owasp-mobile"] && (
                    <>
                      <span>
                        <b>OWASP Top 10:</b> {standards["owasp-mobile"]}
                      </span>
                      <br />
                    </>
                  )}
                  {standards.masvs && (
                    <span>
                      <b>OWASP MASVS:</b> {standards.masvs}
                    </span>
                  )}
                </div>
              ),
            },
            appType === "apk"
              ? {
                  key: "files",
                  label: "Files",
                  width: "35%",
                }
              : {
                  key: "detailed_desc",
                  label: "Details",
                  width: "40%",
                },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default CodeAnalysisSection;
