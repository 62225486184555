import React from "react";

const Email = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9995 7H16.9995V12C16.9995 14.4709 17.189 15 17.9995 15C19.3039 15 19.9995 14.3832 19.9995 12C19.9995 6.71065 17.3466 4 11.9997 4C8.53563 4.00044 5.46541 6.23026 4.3934 9.52426C3.32139 12.8182 4.49117 16.428 7.29145 18.4671C10.0917 20.5062 13.8862 20.5114 16.6921 18.48L17.865 20.1C14.3576 22.6393 9.61449 22.6328 6.11414 20.0839C2.61379 17.5349 1.15157 13.0228 2.49158 8.90532C3.83159 4.78783 7.66937 2.00054 11.9995 2C18.4609 2 21.9995 5.6156 21.9995 12C21.9995 15.5449 20.3584 17 17.9995 17C16.6557 17 15.8866 16.5667 15.4644 15.6048C14.5657 16.4688 13.3446 17 11.9995 17C9.23812 17 6.99955 14.7614 6.99955 12C6.99955 9.23858 9.23812 7 11.9995 7C13.1252 7 14.1639 7.37194 14.9995 7.99963V7ZM14.9995 12C14.9995 13.6569 13.6564 15 11.9995 15C10.3427 15 8.99955 13.6569 8.99955 12C8.99955 10.3431 10.3427 9 11.9995 9C13.6564 9 14.9995 10.3431 14.9995 12Z"
      fill="#3F51B5"
    />
  </svg>
);

export default Email;
