import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    schedules: {},
    tableContainer: {
      width: "100%",
      minHeight: 200,
    },
    table: {
      maxHeight: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    tbody: {},
    th: {
      padding: theme.spacing(0, 2),
    },
    tr: {
      width: "100%",
      display: "flex",
      color: "#868993",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "40px",
      textTransform: "uppercase",
      borderBottom: "1px solid #E0E1E3",
    },
    empty: {
      maxWidth: 420,
      textAlign: "center",
      margin: "auto",
      color: theme.palette.grey[800],
      fontSize: 18,
      fontWeight: 500,
      padding: theme.spacing(8, 4),
    },
    mainCtaButton: {
      maxWidth: 360,
      margin: theme.spacing(4, 0, 6),
    },
  }),
  { name: "ScanList" }
);
