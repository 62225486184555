const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  container: {
    fontSize: "16px",
    fontFamily: "Inter",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  key: {
    // fontWeight: 700,
  },
  value: {
    overflowWrap: "break-word",
  },
}));

export default useStyles;
