import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    maxWidth: 900,
    margin: theme.spacing(0, "auto"),
  },
  brief: {
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[200],
    fontSize: 16,
  },
  form: {
    "& .input-label": {
      width: 177,
      textAlign: "left",
      justifyContent: "flex-start",
      paddingLeft: 15,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "end",
    padding: theme.spacing(1, 0),
  },
  notice: {
    padding: theme.spacing(0, 1, 1, 0),
    color: theme.palette.grey[300],
    fontSize: 14,
    fontWeight: 500,
  },
  addButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    minWidth: 120,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: 1,
    height: 32,
  },
}));

export const formStyles = makeStyles((theme) => ({
  formContainer: {
    width: "100%",
    borderRadius: 4,
    border: `solid 1px ${theme.palette.grey[500]}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
  },
  col: { width: "50%" },
  colRight: { width: "50%", marginLeft: theme.spacing(2) },
  purposeSelect: {
    width: "50%",
    marginLeft: theme.spacing(2),
    "& span": {
      fontSize: 24,
      textTransform: "capitalize",
      color: theme.palette.grey[800],
      marginBottom: 8,
      fontFamily: "inherit",
      letterSpacing: "inherit",
      lineHeight: 1.43,
    },
    "& .MuiInputBase-root": {
      marginTop: -12,
      padding: "37px 0 23px 0",
      "& > div": {
        fontFamily: "inherit",
        fontSize: 16,
      },
    },
    "& .MuiSelect-root": {
      color: theme.palette.grey[800],
    },
    "& fieldset": {
      borderColor: theme.palette.blue[500],
    },
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 0),
    borderTop: `solid 1px ${theme.palette.grey[500]}`,
    borderRadius: 4,
  },
  updateButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    padding: theme.spacing(1, 3),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.4,
  },
}));

export default styles;
