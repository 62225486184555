import React from "react";
import { Svg, Path } from "@react-pdf/renderer";

const Brand = ({ color = "#085cd6", width = "100%", height = "100%" }) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${100} ${100}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M20.0047 2.06543L19.7367 2.47504H16.7297L16.7389 2.46094H0V0H20.0047V2.06543Z"
      fill={color}
    />
    <Path
      d="M16.2039 7.48502H13.1999L0.342565 27.5449H3.33774L16.2039 7.48502Z"
      fill={color}
    />
    <Path
      d="M20.1511 30H0.0878682V27.9346L0.342565 27.5449H8.99772V27.5391H20.1511V30Z"
      fill={color}
    />
    <Path d="M53.4228 30H35.732V27.5391H53.4228V30Z" fill={color} />
    <Path d="M35.732 2.46094V0H53.1299V2.46094H35.732Z" fill={color} />
    <Path
      d="M92.5414 0H89.8468V25.1514H89.8422L89.8761 30H92.5414V0Z"
      fill={color}
    />
    <Path
      d="M77.25 7.63418L89.8446 25.5L89.8761 30L77.1796 12H77.25V7.63418Z"
      fill={color}
    />
    <Path
      d="M71.9218 0.124477L71.8338 0H69.1831V30H71.9217L71.9218 0.124477Z"
      fill={color}
    />
    <Path
      d="M116.474 30C125.496 30 130.636 24.3604 130.65 14.9268C130.65 5.5957 125.583 0 117.002 0H107.688V2.47504H110.426V2.46094H116.841C124.119 2.46094 127.985 7.16309 128 14.9268C128 22.7637 124.046 27.5391 116.313 27.5391H110.426V7.48502H107.688V30H116.474Z"
      fill={color}
    />
    <Path
      d="M144.888 30H142.017L152.928 0H155.857L166.767 30H163.897L160.689 20.9619H160.687L159.37 17.25H159.366L154.495 3.55957H154.29L149.419 17.25H149.417L148.096 20.9619H148.095L144.888 30Z"
      fill={color}
    />
    <Path d="M175.553 0V2.46094H197.3V0H175.553Z" fill={color} />
    <Path d="M185.057 7.48502V30H187.796V7.48502H185.057Z" fill={color} />
    <Path
      d="M208.971 30L212.178 20.9619H212.183L213.766 16.5H213.769L218.373 3.55957H218.578L223.182 16.5H223.189L225.588 23.25H225.584L227.98 30H230.85L219.94 0H217.011L206.1 30H208.971Z"
      fill={color}
    />
    <Path d="M35.732 16.1413V13.6804H45.9578V16.1413H35.732Z" fill={color} />
    <Path d="M35.7 7.48502H38.325V30H35.7V7.48502Z" fill={color} />
  </Svg>
);

export default Brand;
