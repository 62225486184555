export const FLAVORS = {
  free: "free",
  full: "full",
  demo: "demo",
};

export const REPORT_ENV = {
  android: "android",
  ios: "ios",
  web: "web",
};

export const HEADER_HEIGHT = 151;
