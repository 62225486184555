import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Update } from "@material-ui/icons";

import {
  deleteSchedulers,
  listSchedulers,
} from "redux/_reports/reports.async.actions";
import {
  getSchedulers,
  getScheduledReportsMeta,
} from "redux/_reports/reports.selectors";

import ScanList, { ScanListRow, SCAN_TYPE } from "components/ScanList";
import Loader from "components/Loader";

const rowGenerator = (schedules = [], preRemoveSchedule) => (editSchedule) =>
  schedules.map((schedule, index) => (
    <>
      <ScanListRow
        key={`schedule-${index}`}
        {...schedule}
        deviceType={
          schedule.environment === "android" || schedule.environment === "ios"
            ? "mobile"
            : "web"
        }
        scanType={schedule.scanType}
        deleteItem={preRemoveSchedule}
        editItem={editSchedule}
        deleteDialogMessage="Are you sure you want to delete this scheduled report?"
      />
    </>
  ));

const SchedulingList = ({
  selectSchedule,
  goToSection,
  schedules: data,
  schedulesMeta,
  fetchSchedules,
  setIndex,
  removeSchedule,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isDataLoaded = false;
    const timer = setTimeout(() => {
      if (!isDataLoaded) {
        setIsLoading(false);
      }
    }, 2000);
    if (data && data.length > 0) setIsLoading(false);

    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  const editSchedule = (id) => {
    selectSchedule(id);
    goToSection();
    setIndex(1);
  };

  const scanListProps = {
    cta: "Create a new scheduled scan",
    ctaIcon: <Update />,
    emptyNotice: "You don't have any scheduled scans yet.",
    headers: [
      { label: "App Name", className: "appName" },
      { label: "Frequency", className: "frequency" },
      { label: "Actions", className: "actions" },
    ],
    goToSection,
    rowGenerator,
    rowGeneratorArgs: [editSchedule],
    deleteItem: removeSchedule,
    getItems: fetchSchedules,
    data,
    scanType: SCAN_TYPE.scheduled,
    itemsPerPage: 3,
    totalPages: schedulesMeta?.count ? Math.ceil(schedulesMeta.count / 3) : 0, // 3 itemsPerPage
  };

  return isLoading ? <Loader /> : <ScanList {...scanListProps} />;
};

const mapStateToProps = (state) => ({
  schedules: getSchedulers(state),
  schedulesMeta: getScheduledReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeSchedule: (id) => dispatch(deleteSchedulers(id)),
  fetchSchedules: (filters) =>
    dispatch(listSchedulers({ ...filters, environment: "android,ios" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingList);
