import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import PageWrapper from "components/PageWrapper";
import CONSTANTS from "redux/_filters/filters.constants";
import CurrentWidget from "../../components/Scans/CurrentWidget";
import TableHistory from "../../components/Scans/History";
import ScanDetails from "../../components/Scans/ScanDetails";

const { TYPES } = CONSTANTS;

const ScansPage = () => {
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState(null);

  const selectRow = (id) => {
    if (id === selectedRow) {
      setSelectedRow(null);
    } else {
      setSelectedRow(id);
    }
  };

  return (
    <PageWrapper
      title="HOME"
      subtitle="SCANS"
      filters={[TYPES.ENV, TYPES.SCANS.DATE_RANGE, TYPES.SCANS.VISIBILITY]}
      filtersBarContent={<CurrentWidget />}
    >
      <Box className={classes.content}>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={12} md={5}>
            <TableHistory
              selectedRow={selectedRow}
              setSelectedRow={selectRow}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <ScanDetails selectedRow={selectedRow} setSelectedRow={selectRow} />
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default ScansPage;
