import React from "react";
import { Image, Page, Text, View } from "@react-pdf/renderer";
import Brand from "../SummaryPage/brand-logo";
import styles from "./styles";
import moment from "moment";
import zendataLogo from "../commons/zendata-logo.png";
const APP_DETAILS_TITLE_MAX_CHAR = 50;
const FrontPage = ({ url, reportCreatedDate, branding, content }) => {
  const hasCustomLogo =
    branding !== null && !branding.endsWith("media/LOGO.png");

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.backgroundContainer}>
        <View style={styles.background}>
          <View
            style={[styles.circle, styles.filled, styles.small, styles.upper]}
          ></View>
          <View
            style={[styles.circle, styles.filled, styles.small, styles.lower]}
          ></View>
          <View style={[styles.circle, styles.filled, styles.big]}></View>
        </View>
        {!hasCustomLogo && (
          <View style={styles.brand}>
            <Image src={zendataLogo} style={styles.brandSize} />
          </View>
        )}
        <View style={styles.appContentContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Repository Scanner</Text>
            <Text style={styles.subtitle}>Report</Text>
          </View>
          <View style={styles.appDetailsContainer}>
            <View style={styles.detailsContainer}>
              <Text style={styles.appDetailsTitle} src={url}>
                {url.length > APP_DETAILS_TITLE_MAX_CHAR
                  ? `${url.substring(0, APP_DETAILS_TITLE_MAX_CHAR)}...`
                  : url}
                {reportCreatedDate && (
                  <Text style={{ fontSize: "12px" }}>
                    &nbsp;({reportCreatedDate})
                  </Text>
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        fixed
        style={[styles.footer, !hasCustomLogo ? { bottom: "-30" } : {}]}
      >
        <Text style={styles.footerContent}>
          {url} - {moment(reportCreatedDate).format("MMM, YYYY")}
        </Text>
        <View style={styles.logoContainer}>
          {hasCustomLogo ? (
            <Image src={branding} style={styles.customBrandLogo} />
          ) : (
            <View style={styles.logo}>
              <Brand />
            </View>
          )}
        </View>
      </View>
    </Page>
  );
};

export default FrontPage;
