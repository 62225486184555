import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    welcome: {
      width: "80%",
      maxWidth: 640,
      margin: theme.spacing(8, "auto", 0),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    welcomeTitle: {
      color: theme.palette.blue.main,
      margin: theme.spacing(5, 0, 2),
    },
    searchIllustration: {},
  }),
  { name: "ConsumerReport" }
);
