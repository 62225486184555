import React, { useState } from "react";
import useStyles from "./styles";
import SectionContent from "../SectionContent";
import ExpandedPanel from "../ExpandedPanel";
import Table from "pages/AppReport/common/Table";

const ThirdPartyLibraries = ({ data, columns }) => {
  const [expanded, setExpanded] = useState();

  const classes = useStyles();

  return (
    <>
      <div className={classes.headerFilter}></div>
      <SectionContent>
        {data.map((tpd) => (
          <ExpandedPanel
            title={
              <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                {tpd.name}
              </div>
            }
            classes={{
              root: "scrollable-element",
            }}
            simpleTitle
            key={tpd.name}
            onChange={() => setExpanded(expanded !== tpd.name ? tpd.name : "")}
            expanded={expanded === tpd.name}
          >
            {tpd.name.length > 0 ? (
              <Table
                data={tpd.elements}
                limitByPage={10}
                columns={[
                  {
                    key: "keyword",
                    label: "KEYWORD",
                    width: "15%",
                  },
                  {
                    key: "line_number",
                    label: "LINE NUMBER",
                    width: "15%",
                  },
                  {
                    key: "context",
                    label: "CONTEXT",
                    width: "70%",
                  },
                ]}
              />
            ) : (
              <span style={{ color: "#868993" }}>{"emptyMessage"}</span>
            )}
          </ExpandedPanel>
        ))}
      </SectionContent>
    </>
  );
};

export default ThirdPartyLibraries;
