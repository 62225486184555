import CONSTANTS from "./charts.constants";

export const requestStart = () => ({
  type: CONSTANTS.CHARTS_SCANS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.CHARTS_SCANS_REQUEST_END,
});

export const setError = (data) => ({
  type: CONSTANTS.CHARTS_SCANS_SET_ERROR,
  data,
});

export const setTreeMapData = (treeMapData) => ({
  type: CONSTANTS.CHARTS_SCANS_TREEMAP_SET_DATA,
  data: treeMapData,
});

export const setFilterMonth = (monthId) => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_SET_MONTH,
  data: monthId,
});

export const setTreeByCountryData = (treeByCountryData) => ({
  type: CONSTANTS.CHARTS_SCANS_TREE_BY_COUNTRY_SET_DATA,
  data: treeByCountryData,
});

export const setCustomersByCountryData = (customersByCountryData) => ({
  type: CONSTANTS.CHARTS_SCANS_CUSTOMERS_BY_COUNTRY_SET_DATA,
  data: customersByCountryData,
});

export const setOwnersData = (ownersData) => ({
  type: CONSTANTS.CHARTS_SCANS_OWNERS_SET_DATA,
  data: ownersData,
});

export const setFilterScan = (scanId) => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_SET_SCAN,
  data: scanId,
});

export const setFilterCountry = (countryId) => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_SET_COUNTRY,
  data: countryId,
});

export const setPiiGroups = (piiGroups) => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_SET_PII_GROUPS,
  data: piiGroups,
});

export const toggleProblematic = () => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_PROBLEMATIC,
});

export const toggleUnprotected = () => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_UNPROTECTED,
});

export const togglePiiGroup = (groupIndex) => ({
  type: CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_PII_GROUP,
  data: groupIndex,
});

export const setLastScans = (scans) => ({
  type: CONSTANTS.CHARTS_SCANS_TRENDS_SET_LAST,
  data: scans,
});

export const setFunctionalUses = (functionalUses) => ({
  type: CONSTANTS.CHARTS_SCANS_FUNCIONAL_USES,
  data: functionalUses,
});
