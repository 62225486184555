import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  consents: {
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  consent: {
    padding: "40px",
    width: "100%",
    border: "1px solid #DFE2E9",
    boxSizing: "border-box",
    boxShadow: "0px 4.51506px 9.03012px rgba(176, 190, 197, 0.24)",
    borderRadius: "9.03012px",
    fontFamily: "Inter",
    "&:not(&:last-child)": {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignContent: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(33.3333% - ${2 * theme.spacing(2)}px)`,
      "&:nth-child(2)": {
        margin: `0 ${theme.spacing(2)}px`,
      },
      "&:not(&:last-child)": {
        marginBottom: 0,
      },
    },
  },
  consentImage: {
    [theme.breakpoints.up("xs")]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  consentContent: {
    width: "100%",
  },
  consentText: {
    fontWeight: "bold",
    color: "#686C78",
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: "10px",
    minheight: "80px",
  },
  consentLink: {},
}));
