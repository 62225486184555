import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import EntitySelector from "components/SettingsSections/EntitySelector";
import useStyles from "./styles";
import Section1 from "components/SettingsSections/Datasource/sections/section1";
import isNil from "lodash.isnil";
import {
  getDatasourceSettingsData,
  getDatasourceSettingsSelected,
} from "redux/_settings/settings.selectors";
import { saveData, selectData } from "redux/_settings/settings.actions";
import { ENTITIES } from "redux/_settings/settings.constants";
import { connect } from "react-redux";
import {
  getFilteredDatasourcesData,
  getDatasourcesLoadingState,
} from "redux/_datasources/datasources.selectors";
import {
  createDatasource,
  deleteDatasource,
  updateDatasource,
} from "redux/_datasources/datasources.async.actions";
import { getAllCountries } from "redux/_countries/environment.selectors";
import { countriesActions } from "redux/_countries/countries.actions";
import isEmpty from "lodash.isempty";
import Form from "components/FormComponents/Form";

const DatasourceSettings = (props) => {
  const classes = useStyles();

  const {
    dsList,
    selected,
    setSelected,
    match,
    location,
    history,
    setData,
    data,
    update,
    deleteEntity,
    create,
    getCountries,
    countries,
    dsLoading,
  } = props;

  useEffect(() => {
    const ds_id = location.pathname.replace(match.path, "").split("/")[1];
    if (ds_id && selected !== ds_id) setSelected(ds_id);
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(selected)) {
      history.replace(`/zen/settings/datasources/${selected}`);
      setData(dsList.find(({ id }) => parseInt(id) === parseInt(selected)));
    } else {
      history.replace(`/zen/settings/datasources/`);
      setData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onSubmit = async (values) => {
    if (selected) {
      try {
        const newData = await update(values);
        setData(newData);
      } catch (e) {
        return e;
      }
    } else {
      try {
        await create(values);
      } catch (e) {
        return e;
      }
    }
  };

  useEffect(() => {
    if (!isNil(selected) && isEmpty(data))
      setData(dsList.find(({ id }) => parseInt(id) === parseInt(selected)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsList]);

  const onDelete = () =>
    deleteEntity(selected).then((_) => {
      setSelected(null);
    });

  return (
    <Box className={classes.container}>
      <EntitySelector
        entityName="Datasource"
        entities={dsList}
        selected={selected}
        onSelect={setSelected}
        newLabel="New DS"
      />
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        showSubmit={!selected}
        submitLabel={!selected ? "Create" : "Update"}
        loading={dsLoading}
      >
        <Section1
          countries={countries}
          showDeleteButton={!isNil(selected)}
          onDeleteClick={onDelete}
        />
      </Form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dsList: getFilteredDatasourcesData(state),
  selected: getDatasourceSettingsSelected(state),
  loading: getDatasourcesLoadingState(state),
  data: getDatasourceSettingsData(state),
  countries: getAllCountries(state),
  dsLoading: getDatasourcesLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelected: (id) => dispatch(selectData(ENTITIES.DATASOURCE, id)),
  setData: (data) => dispatch(saveData(ENTITIES.DATASOURCE, data)),
  update: (data) => dispatch(updateDatasource(data)),
  deleteEntity: (id) => dispatch(deleteDatasource(id)),
  create: (data) => dispatch(createDatasource(data)),
  getCountries: () => dispatch(countriesActions.getAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatasourceSettings);
