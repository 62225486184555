import {
  getAll,
  create,
  get,
  resend,
  remove,
} from "redux/_admin/_users/users.service";
import {
  requestStart,
  requestEnd,
  saveData,
  removeUser as removeUserAction,
} from "redux/_admin/_users/users.actions";

export const getUsersData = () => (dispatch) => {
  dispatch(requestStart());
  return getAll()
    .then((data) => {
      dispatch(saveData(data));
      dispatch(requestEnd());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const createUser = (data) => (dispatch) => {
  dispatch(requestStart("create"));
  return create(data)
    .then((data) => {
      dispatch(saveData(data));
      dispatch(requestEnd("create"));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd("create"));
      const { data } = error;
      return Promise.reject(data || error);
    });
};

export const getInvitation = (data) => (dispatch) => {
  dispatch(requestStart());
  return get(data)
    .then((data) => {
      dispatch(requestEnd());
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const resendInvitation = (id) => (dispatch) => {
  return resend(id)
    .then((data) => {
      return Promise.resolve();
    })
    .catch((_) => {
      return Promise.reject();
    });
};

export const removeUser = (id) => (dispatch) => {
  dispatch(requestStart("remove"));
  return remove(id)
    .then((_) => {
      dispatch(removeUserAction(id));
      dispatch(requestEnd("remove"));
      return Promise.resolve(id);
    })
    .catch((_) => {
      dispatch(requestEnd("remove"));
      return Promise.reject();
    });
};
