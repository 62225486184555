import ApplicationForm from "components/Consents/Collector/Applications/components/applicationForm";
import CollectorItem from "components/Consents/Collector/components/Item";
import CollectorWrapper from "components/Consents/Collector/components/Wrapper";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createApplication,
  getApplicationsData,
} from "redux/_consents/_applications/applications.async.actions";
import {
  getApplicationsLoadingState,
  getConsentsApplicationsData,
} from "redux/_consents/_applications/applications.selectors";

const creationQueue = {};
const slugify = (x) =>
  x
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[\s\W-]+/g, "-");

const Applications = ({
  applications,
  createApplication,
  foundApps,
  getData,
  loading,
  widgetToken,
}) => {
  useEffect(() => {
    getData(true, widgetToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetToken]);

  useEffect(() => {
    if (loading === true || foundApps.length === 0) return;
    if (applications.length === 0)
      foundApps.forEach(
        ({ owner, domain, description, policy: policy_name }) => {
          const name = slugify(`${owner}-${domain}`);
          if (creationQueue[name]) return;
          creationQueue[name] = true;
          createApplication({
            name,
            description,
            title: domain,
            active_by_default: false,
            mandatory: policy_name === "Strictly Necessary",
            executed_only_once: false,
            widget_token: widgetToken,
            policy_name,
            // privacy_policy_link: privacyPolicy,
            // policy_belong_to_id,
          });
        }
      );
  }, [applications, foundApps, loading, createApplication, widgetToken]);

  const [creating, setCreating] = useState(false);

  return (
    <CollectorWrapper
      primaryText="Let your users know where their data goes by listing each of the applications/services your site uses."
      loading={loading}
      buttonDisabled={creating}
      previewPage="apps"
      previewData={applications}
      onButtonClick={() => setCreating(true)}
    >
      {creating && (
        <ApplicationForm data={{}} onCancel={() => setCreating(false)} />
      )}
      {applications.map(({ id, ...el }) => (
        <CollectorItem
          key={id}
          title={`${el.title} - ${el.policy_belong_to_name}`}
          formComponent={<ApplicationForm data={el} id={id} />}
        />
      ))}
    </CollectorWrapper>
  );
};

const mapStateToProps = (state) => ({
  applications: getConsentsApplicationsData(state),
  loading: getApplicationsLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  createApplication: (data) => dispatch(createApplication(data)),
  getData: (force, widgetToken) =>
    dispatch(getApplicationsData(force, widgetToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
