import React from "react";
import { SECTIONS_CONTENT, SECTION_ID } from "pages/AppReport/constants";
import Page from "components/PrivacyReports/Report/components/Page";
import { useStyles } from "./styles";
import InformationSection from "./InformationSection";

const FindingSeveritySection = ({ goToNextSectionAction, data, appType }) => {
  const classes = useStyles();
  return (
    <Page
      key={SECTIONS_CONTENT[SECTION_ID.findingSeverity].key}
      footerBackgroundColor={
        SECTIONS_CONTENT[SECTION_ID.findingSeverity].backgroundColor
      }
      footerText={
        <div className="privacy-tech">Check the certificate information</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <div className={classes.content}>
        <InformationSection data={data} appType={appType} />
      </div>
    </Page>
  );
};

export default FindingSeveritySection;
