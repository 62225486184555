import CollectorWrapper from "components/Consents/Collector/components/Wrapper";
import BrandingInput from "components/Consents/Collector/Configuration/components/BrandingInput";
import ColorsInput from "components/Consents/Collector/Configuration/components/ColorsInput";
import PositionInput from "components/Consents/Collector/Configuration/components/PositionInput";
import useQuery from "hooks/useQuery";
import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  updateConfig,
  getCollectorLogs,
} from "redux/_consents/_config/config.async.actions";
import {
  getConsentsConfig,
  getConsentsConfigLoadingState,
} from "redux/_consents/_config/config.selectors";
import TextInput from "./components/TextInput";
import useStyles from "./styles";

const Configuration = ({ data, loading, update }) => {
  const query = useQuery();
  const [reportId, setReportId] = useState();
  const classes = useStyles();

  useEffect(() => {
    const r = query.get("r");
    setReportId(r);
  }, [query]);

  const handleUpdate = async (values) => {
    if (!update) return;
    try {
      await update({ ...values, branding: undefined }, reportId);
    } catch (e) {
      return e;
    }
  };

  const identity = (value) => value;

  return (
    <CollectorWrapper
      primaryText="Complete the following fields to start using the Consent Collector."
      secondaryText="All fields are required."
      showAddButton={false}
      loading={loading}
      previewPage="home"
      previewData={data}
    >
      <Form
        onSubmit={handleUpdate}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form>
            <Field
              name="intro_text"
              component={TextInput}
              label="Intro Text"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            <Field
              name="domain"
              component={TextInput}
              label="Domain"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            <ColorsInput
              label="Colors"
              loading={loading}
              onSubmit={handleSubmit}
            />
            <Field
              id={data.id}
              name="branding"
              component={BrandingInput}
              label="Branding"
              loading={loading}
            />
            <Field
              name="position"
              component={PositionInput}
              label="Position"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            {/* <Field
              name="languages"
              component={LanguagesInput}
              label="Languages"
              loading={loading}
            /> */}
          </form>
        )}
      />
      <a
        href="#logs"
        onClick={() => {
          getCollectorLogs(data.token)();
        }}
      >
        Download usage statistics
      </a>
      <br />
      {reportId && (
        <Link
          to={`/privacy-report?r=${reportId}&c=web`}
          className={classes.btn}
        >
          Go Back To Report
        </Link>
      )}
    </CollectorWrapper>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsConfig(state),
  loading: getConsentsConfigLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  update: (data, reportId) => dispatch(updateConfig(data, reportId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
