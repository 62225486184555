import humps from "humps";

import { getJSONUUIDFrom } from "utils/reports";

import { getAccountData } from "redux/_account/account.selectors";

import { DATA_TYPES } from "./reports.constants";
import { getCachedReportJSONs } from "./reports.selectors";
import {
  getReportData,
  getReportsData,
  fetchReportJSON,
  triggerReport,
  removeReport,
  scheduleReport,
  getSchedulersData,
  removeSchedule,
} from "./reports.service";
import {
  requestStart,
  requestEnd,
  saveData,
  saveReportsData,
  saveJSON,
  setMeta,
  saveSchedule,
  saveScheduleList,
  dropSchedule,
  dropSingleRunReport,
} from "./reports.actions";

export const postTriggerReport = (values) => (dispatch, getState) => {
  dispatch(requestStart());
  const { notifier, ...rest } = values;
  const account = getAccountData(getState());
  // TODO: Create a handler for all the requests made from the frontend
  return triggerReport(humps.decamelizeKeys(rest))
    .then((data) => {
      const { flavor, resource, email } = data;
      if (global.gtag)
        global.gtag("event", "scanner", { flavor, resource, email });
      dispatch(requestEnd());
      dispatch(saveReportsData(data));
      notifier("Scan scheduled successfully", { variant: "success" });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      try {
        const errorObj = JSON.parse(error);
        if (account.product_access) {
          notifier(errorObj.title, { variant: "error" });
        }
      } catch (jsonError) {
        notifier("An error has occurred", { variant: "error" });
      }
      return Promise.reject(error);
    });
};

export const getConsumerReport = (id, email, extra) => (dispatch) => {
  dispatch(requestStart());
  return getReportData(id, email, extra)
    .then((data = {}) => {
      dispatch(requestEnd());
      dispatch(saveData(data, DATA_TYPES.consumerReport));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const getReport = (id, email, extra, hideErrorMessages) => (
  dispatch
) => {
  dispatch(requestStart());
  return getReportData(id, email, extra, hideErrorMessages)
    .then((data = {}) => {
      dispatch(requestEnd());
      dispatch(saveData(data, "report"));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const getReportJSON = (url, formatResponse = true ) => (dispatch, getState) => {
  const cachedJSONs = getCachedReportJSONs(getState());
  const uuid = getJSONUUIDFrom(url);
  if (cachedJSONs[uuid]) {
    return cachedJSONs[uuid];
  }

  dispatch(requestStart(DATA_TYPES.jsons));
  return fetchReportJSON(url, formatResponse)
    .then((data = {}) => {
      dispatch(requestEnd());
      dispatch(saveJSON(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd(DATA_TYPES.jsons));
      return Promise.reject(error);
    });
};

export const getReports = (filters = {}) => (dispatch) => {
  dispatch(requestStart());
  return getReportsData(filters)
    .then(([data, meta]) => {
      dispatch(requestEnd());
      dispatch(saveData(data, "reports"));
      dispatch(setMeta(meta, "reports"));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const deleteReport = (id) => (dispatch) => {
  dispatch(requestStart());
  return removeReport(id)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(dropSingleRunReport(id));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const createScheduler = (values) => (dispatch) => {
  dispatch(requestStart());
  const { notifier, ...rest } = values;
  // TODO: Create a handler for all the requests made from the frontend
  return scheduleReport(humps.decamelizeKeys(rest))
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveSchedule(data, values.id));
      notifier("Scan scheduled successfully", { variant: "success" });
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      notifier(error.title, { variant: "error" });
      return Promise.reject(error);
    });
};

export const listSchedulers = (filters = {}) => (dispatch) => {
  dispatch(requestStart());
  return getSchedulersData(filters)
    .then(([data, meta]) => {
      dispatch(requestEnd());
      dispatch(saveScheduleList(data));
      dispatch(setMeta(meta, "scheduled"));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const deleteSchedulers = (id) => (dispatch) => {
  dispatch(requestStart());
  return removeSchedule(id)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(dropSchedule(data));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};
