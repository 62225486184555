import { CONSTANTS, FILTERS_DATA } from "redux/_requests/_data/data.constants";

const { REQUESTS_FILTERS_TYPES: TYPES } = CONSTANTS;

const initialState = {
  loading: false,
  error: false,
  filters: {
    data: FILTERS_DATA,
    selected: {
      [TYPES.SEARCH]: "",
      [TYPES.REGULATION]: "all",
      [TYPES.INCLUDES]: [],
      [TYPES.DAYS_LEFT]: 45,
      [TYPES.STATUS]: "all",
      [TYPES.VERIFIED_CUSTOMER]: "all",
    },
  },
  data: [],
  meta: {},
};

export const data = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.REQUESTS_DATA_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CONSTANTS.REQUESTS_DATA_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.REQUESTS_DATA_SET_ERROR:
      return {
        ...state,
        error: true,
      };
    case CONSTANTS.REQUESTS_DATA_SAVE_DATA:
      return {
        ...state,
        data,
      };
    case CONSTANTS.REQUESTS_DATA_SAVE_META:
      return {
        ...state,
        meta: data,
      };
    case CONSTANTS.REQUESTS_FILTERS_SAVE_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          data: {
            ...state.filters.data,
            [dataType]: data,
          },
        },
      };
    case CONSTANTS.REQUESTS_FILTERS_SELECT_DATA:
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            [dataType]: data,
          },
        },
      };
    default:
      return state;
  }
};
