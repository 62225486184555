export const CONSTANTS = {
  REPORTS_REQUEST_START: "REPORTS_REQUEST_START",
  REPORTS_REQUEST_END: "REPORTS_REQUEST_END",
  REPORTS_SAVE_DATA: "REPORTS_SAVE_DATA",
  REPORTS_SAVE_REPORTS_DATA: "REPORTS_SAVE_REPORTS_DATA",
  REPORTS_SAVE_JSON: "REPORTS_SAVE_JSON",
  REPORTS_SET_META: "REPORTS_SET_META",
  REPORTS_RESET_DATA: "REPORTS_RESET_DATA",
  REPORTS_SCHEDULE_LIST: "REPORTS_SCHEDULE_LIST",
  REPORTS_SCHEDULE_SAVE: "REPORTS_SCHEDULE_SAVE",
  REPORTS_SCHEDULE_DELETE: "REPORTS_SCHEDULE_DELETE",
  REPORTS_SINGLE_RUN_DELETE: "REPORTS_SINGLE_RUN_DELETE",
};

export const DATA_TYPES = {
  jsons: "jsons",
  reports: "reports",
  report: "report",
  scheduled: "scheduled",
  consumerReport: "consumerReport",
};
