import React, { useState } from "react";
import clsx from "clsx";
import { Box, Button, Typography } from "@material-ui/core";

import Dialog from "components/Dialog";

import useStyles from "./styles";

const SettingOption = ({
  name,
  value,
  options,
  classes: customClasses = {},
  popupManager = undefined,
  form = undefined,
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [children, setChildren] = useState();

  const handleClickOpen = (element) => () => {
    const { text, title, component } = element;
    setTitle(title || text);
    setChildren(component);
    if (popupManager) {
      popupManager.setOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (popupManager) {
      popupManager.setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={((popupManager && popupManager.open) || open) && !readOnly}
        handleClose={handleClose}
        title={title}
        form={form}
      >
        {children}
      </Dialog>
      <Box className={clsx(classes.setting, customClasses.setting)}>
        <Typography className={clsx(classes.name, customClasses.name)}>
          {name}
        </Typography>
        <Box className={clsx(classes.valueRelated, customClasses.valueRelated)}>
          {value && (
            <Typography
              className={clsx(
                classes.value,
                customClasses.value,
                readOnly && classes.valueReadOnly
              )}
            >
              {value}
            </Typography>
          )}
          {!readOnly && (
            <Box className={clsx(classes.options, customClasses.options)}>
              {options.map((element) => (
                <Button
                  key={element.text}
                  onClick={handleClickOpen(element)}
                  className={classes.option}
                >
                  {element.text}
                </Button>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SettingOption;
