import { Box, Typography } from "@material-ui/core";
import CopyButton from "components/Consents/Collector/EmbedCode/components/copyButton";
import Loader from "components/Loader";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAccountData } from "redux/_account/account.selectors";
import Badge1 from "./badge1.png";
import Badge2 from "./badge2.png";
import Badge3 from "./badge3.png";
import useStyles from "./styles";

const badges = {
  none: "NONE",
  surface: "SURFACE",
  core: "CORE",
  complete: "COMPLETE",
};
const BadgesPage = ({ account }) => {
  const classes = useStyles();

  const [badge, setBadge] = useState(null);

  useEffect(() => {
    if (!account || !account.badge) return;
    if (account.badge.name === badges.surface) setBadge(Badge1);
    else if (account.badge.name === badges.core) setBadge(Badge2);
    else if (account.badge.name === badges.complete) setBadge(Badge3);
  }, [account]);

  return (
    <PageWrapper title="HOME" subtitle="Privacy Badges">
      <Box className={classes.container}>
        <Box className={classes.content}>
          {account && account.badge && account.badge.name === badges.none ? (
            <Typography className={classes.primaryText}>
              Activate one of the plans to enable <b>Zendata Badges</b>
            </Typography>
          ) : (
            <>
              <Box className={classes.leftContent}>
                <Typography className={classes.primaryText}>
                  To use the <b>Zendata Badges</b> on your website, simply
                  include this code in your website body:
                </Typography>
                <Box className={classes.codeBlockContainer}>
                  {!account || !account.badge ? (
                    <Loader />
                  ) : (
                    <>
                      <Typography className={classes.codeText}>
                        {`<script`} defer type="text/javascript"
                      </Typography>
                      <Typography
                        className={classes.codeText}
                        style={{ marginLeft: "3rem" }}
                      >
                        src="{<b>https://app.zendata.dev/b/zenbadges.js</b>}"
                      </Typography>
                      <Typography
                        className={classes.codeText}
                        style={{ marginLeft: "3rem" }}
                      >
                        data-zen-key="{account.badge.uuid}"{"></script>"}
                      </Typography>
                      <CopyButton
                        text={`<script defer type="text/javascript" data-zen-key="${account.badge.uuid}" src="https://app.zendata.dev/b/zenbadges.js"></script>`}
                      />
                    </>
                  )}
                </Box>
                <Typography className={classes.primaryText}>
                  After that put this code block in the location where you want
                  the privacy badge:
                </Typography>
                <Box className={classes.codeBlockContainer}>
                  <>
                    <Typography className={classes.codeText}>
                      {`<div`} id="_zendatabadge"{`></div>`}
                    </Typography>
                    <CopyButton text={'<div id="_zendatabadge"></div>'} />
                  </>
                </Box>
              </Box>
              <Box className={classes.rightContent}>
                <Typography className={classes.rightTitle}>Preview</Typography>
                {badge && (
                  <img className={classes.badge} src={badge} alt="badge" />
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

export default connect(mapStateToProps)(BadgesPage);
