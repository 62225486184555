import React from "react";
import PageWrapper from "components/PageWrapper";
import Overview from "components/Consents/Overview";

const ConsentsOverview = () => {
  return (
    <PageWrapper title="CONSENTS" subtitle="MANAGER'S OVERVIEW">
      <Overview />
    </PageWrapper>
  );
};

export default ConsentsOverview;
