import React from "react";

const ArrowTrenddownIcon = (props) => {
  const { color = "#4766b6" } = props;
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14699 10H2.84377C2.37265 10 1.99076 9.61811 1.99076 9.14699C1.99076 8.67587 2.37265 8.29398 2.84377 8.29398H7.08762L0.249857 1.45618C-0.0832856 1.12304 -0.0832856 0.582956 0.249857 0.249857C0.582957 -0.0832424 1.12304 -0.0832853 1.45618 0.249857L8.29398 7.08766V2.84381C8.29398 2.3727 8.67587 1.9908 9.14699 1.9908C9.61811 1.9908 10 2.3727 10 2.84381V9.14699C10 9.61124 9.62271 10 9.14699 10Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowTrenddownIcon;
