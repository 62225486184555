import { Text, View } from "@react-pdf/renderer";
import { camelize } from "humps";
import { styles as customStyles } from "./styles";

const ThirdPartyTrackersPage = ({ styles, trackers, domainsMetadata = {} }) => (
  <View style={styles.section} break>
    <View>
      <Text style={styles.title}>Third Party Trackers</Text>
      <Text>
        This module represents all third-party trackers you have on your site,
        the provider they belong to, the functionality they provide, and the
        tag itself for debugging if necessary.
      </Text>
    </View>
    <View style={styles.table}>
      <View style={styles.thead} fixed>
        <View style={styles.tr}>
          <View style={styles.th}>
            <Text>Domain</Text>
          </View>
          <View style={[styles.th, customStyles.providerColumn]}>
            <Text>Provider</Text>
          </View>
          <View style={styles.th}>
            <Text> Categories</Text>
          </View>
        </View>
      </View>
      <View style={styles.tbody}>
        {trackers.map(({ domain, owner, categories, trackers: tags }, i) => (
          <View style={styles.tr} key={i} wrap={false}>
            <View style={[styles.td, customStyles.borderRight]}>
              <Text>{domain}</Text>
            </View>
            <View style={[styles.td, customStyles.borderRight, customStyles.ownerCellContainer]}>
              <View style={customStyles.ownerCell}>
                <View style={customStyles.ownerCellValue}>
                  <Text>{owner}</Text>
                </View>
                {domainsMetadata[camelize(owner)] && (
                  <View style={[styles.table, customStyles.table]}>
                    <View style={styles.thead}>
                      <View style={styles.tr}>
                        <View style={[styles.th, customStyles.fontSizeEight]}>
                          <Text>
                            Data breaches in 12 months
                          </Text>
                        </View>
                        <View style={[styles.th, customStyles.fontSizeEight]}>
                          <Text>
                            Open Ports
                          </Text>
                        </View>
                        <View style={[styles.th, customStyles.fontSizeEight]}>
                          <Text>
                            {`Security \nCertifications`}
                          </Text>
                        </View>
                        <View style={[styles.th, customStyles.fontSizeEight]}>
                          <Text>
                            DNS Health
                          </Text>
                        </View>
                        <View style={[styles.th, customStyles.fontSizeEight]}>
                          <Text>
                            IP Reputation
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.tbody}>
                      <View style={[styles.tr, { border: "none" }]}>
                        <View style={[styles.td, customStyles.fontSizeEight, customStyles.borderRight]}>
                          <Text>{domainsMetadata[camelize(owner)]?.results?.dataBreaches ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.td, customStyles.fontSizeEight, customStyles.borderRight]}>
                          <Text>{domainsMetadata[camelize(owner)]?.results?.openPorts?.count > 0 ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.td, customStyles.fontSizeEight, customStyles.borderRight]}>
                          <Text>{domainsMetadata[camelize(owner)]?.results?.validCertificates ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.td, customStyles.fontSizeEight, customStyles.borderRight]}>
                          <Text>{domainsMetadata[camelize(owner)]?.results?.dnsErrors === "" ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.td, customStyles.fontSizeEight]}>
                          <Text>{domainsMetadata[camelize(owner)]?.results?.domainReputation?.isValid ? "Yes" : "No"}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>
            <View style={[styles.td, { fontSize: 8 }]}>
              {(categories || []).map((category) => (
                <Text key={`${category}${i}`}>{category}</Text>
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  </View>
);

export default ThirdPartyTrackersPage;
