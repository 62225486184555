/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import clsx from "clsx";

import InfoIcon from "@material-ui/icons/Info";
// import WarningIcon from '@material-ui/icons/Warning';
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import Tooltip from "components/Tooltip";

import useStyles from "./styles";

export const Positive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0Z"
      fill="#6FCF97"
    />
    <path
      d="M7.81353 13.1007L4.96873 10.302L4 11.2483L7.81353 15L16 6.94631L15.0381 6L7.81353 13.1007Z"
      fill="white"
    />
  </svg>
);

export const Negative = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0Z"
      fill="#EB5757"
    />
    <path
      d="M15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
      fill="white"
    />
  </svg>
);

const BACKWARDS_COMPATIBILITY_CATEGORIES = {
  behavior: "behaviour",
  other: "others",
};

export const CATEGORIES = {
  highRisk: "High Risk",
  behavior: "Behavior",
  social: "Social",
  advertising: "Advertising",
  analytics: "Analytics",
  payment: "Payment",
  other: "Other",
};

const DESCRIPTION = {
  "High Risk":
    "The website could use some dangerous scripts with obscure ownership. Also we can categorize scripts as high risk if its a potencial malware or similar",
  Behavior:
    "The website could be tracking the way its users interact with it, as the more requested sections, session time, etc.",
  Social:
    "The website could use social media scripts which can be use for identify its users.",
  Advertising:
    "The website could use some scripts which are commonly used for advertising.",
  Analytics:
    "The website could use some scripts for analytics process for its business.",
  Payment:
    "The website could count with scripts which are related with payment services.",
  Other:
    "This category wraps all the harmless scripts which a website can use, like CDNs and others.",
};

const PERSONAL_DATA_PATTERNS = {
  highRisk: ["serviceOperationAndSecurity"],
  behavior: ["personalization/Customization"],
  social: ["additionalService/feature"],
  advertising: ["advertising", "marketing"],
  analytics: ["analytics/Research"],
  payment: ["basicService/feature"],
  other: ["unspecified", "legalRequirement", "merger/Acquisition", "other"],
};

const TRACKER_PATTERNS = {
  highRisk: ["Malware", "Unknown High Risk Behavior", "Obscure Ownership"],
  behavior: ["Session Replay", "Action Pixels"],
  social: [
    "Federated Login",
    "Social Network",
    "Social - Share",
    "Social - Comment",
    "SSO",
  ],
  advertising: ["Advertising", "Ad Motivated Tracking", "Ad Fraud"],
  analytics: [
    "Third-Party Analytics Marketing",
    "Audience Measurement",
    "Analytics",
  ],
  payment: ["Online Payment"],
  other: ["Embedded Content", "Badge", "CDN", "Non-Tracking"],
};

export const SUMMARY_COLUMNS = {
  inPrivacyPolicy: "inPrivacyPolicy",
  ourCheck: "ourCheck",
  status: "status",
  advancedInformation: "advancedInformation",
};

export const categoryKeysToData = (data) => (categoryKey) => {
  // Adding backwards compatibility
  const category =
    CATEGORIES[categoryKey] ||
    CATEGORIES[BACKWARDS_COMPATIBILITY_CATEGORIES[categoryKey]];
  const element = data
    ? data[categoryKey] || data[BACKWARDS_COMPATIBILITY_CATEGORIES[categoryKey]]
    : false;

  return [category, element, categoryKey];
};

const LiveProcesses = ({
  isCurrent,
  data,
  moveToSection,
  personalData,
  trackers = [],
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState();

  const handleClick = (event) => {
    setSelected(event.currentTarget.id);
  };

  const handleClose = (event) => {
    setSelected(null);
  };

  const filterTexts = (id) => {
    const searchedKeys = PERSONAL_DATA_PATTERNS[id];
    const { tpsCrossMapping } = personalData;
    let filtered = [];
    Object.keys(tpsCrossMapping).forEach((collection) => {
      const filteredTexts = Object.keys(tpsCrossMapping[collection])
        .filter((reason) => searchedKeys.includes(reason))
        .map((reason) => tpsCrossMapping[collection][reason].text);

      if (filteredTexts.length > 0) {
        // Its not necessary all the texts for this widget
        filtered = [...filtered, ...filteredTexts[0]];
      }
    });

    return filtered;
  };

  const filterTrackers = (id) => {
    const searchedSubcategories = TRACKER_PATTERNS[id];
    let filtered = [];
    trackers.forEach((tracker) => {
      const filteredCategories = tracker.categories.filter((category) =>
        searchedSubcategories.includes(category)
      );

      if (filteredCategories.length > 0) {
        filtered = [...filtered, ...tracker.trackers];
      }
    });

    return filtered;
  };

  const getIcon = (id, value, header) => {
    if (header !== SUMMARY_COLUMNS.advancedInformation)
      return value ? <Positive /> : <Negative />;

    const open = selected === id;
    const popoverId = open ? "simple-popover" : undefined;

    const moveToPrivacy = () => {
      moveToSection(3, 0);
    };

    const moveToTrackers = () => {
      moveToSection(6, 0);
    };

    const filteredTexts = filterTexts(id);
    const filteredTrackers = filterTrackers(id);

    return (
      <div className={classes.clickable}>
        <InfoIcon id={id} onClick={handleClick} />
        <Popover
          id={popoverId}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 250, left: 1050 }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container classes={{ root: classes.popoverContent }}>
            <Grid item md={6} classes={{ root: "privacy scrollable-element" }}>
              <Typography className={classes.title}>
                What we found on privacy policy
              </Typography>
              <div className="content">
                <div className={clsx(classes.privacy, "scrollable-element")}>
                  {filteredTexts.length > 0
                    ? filteredTexts.map((text, index) => (
                        <li key={`text-${index}`}>{text.trim()}</li>
                      ))
                    : "Not privacy data found."}
                </div>
                <div className="action">
                  <a onClick={moveToPrivacy}>
                    Check personal data for more details
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item md={6} classes={{ root: "scripts" }}>
              <Typography className={classes.title}>
                Found trackers tags
              </Typography>
              <div className="content">
                <ol className={clsx(classes.trackers, "scrollable-element")}>
                  {filteredTrackers.length > 0
                    ? filteredTrackers.map((tracker, index) => (
                        <li key={`tracker-${index}`}>{tracker}</li>
                      ))
                    : "Not trackers found."}
                </ol>
                <div className="action">
                  <a onClick={moveToTrackers}>
                    Check trackers for more details
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (!isCurrent) {
      setSelected(null);
    }
  }, [isCurrent]);

  return (
    <div className={classes.liveProcesses}>
      <h3 className={classes.tableTitle}>Assessment summary</h3>

      <div className={clsx(classes.xsTable)}>
        {Object.keys(CATEGORIES)
          .map(categoryKeysToData(data))
          .map(([category, element, key]) => {
            return (
              <table key={key} className={classes.table}>
                <thead>
                  <tr>
                    <th className={clsx(classes.tableHeaderCell)} colSpan="2">
                      <Tooltip title={DESCRIPTION[category]} placement="right">
                        <span className="tooltip">{category}</span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className={classes.rowHeaderCell}>In privacy policy</th>
                    <td>
                      {key !== "highRisk"
                        ? getIcon(
                            `privacy-${key}`,
                            element.inPrivacyPolicy,
                            SUMMARY_COLUMNS.inPrivacyPolicy
                          )
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.rowHeaderCell}>Reality check</th>
                    <td>
                      {getIcon(
                        `our-check-${key}`,
                        element.ourCheck,
                        SUMMARY_COLUMNS.ourCheck
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.rowHeaderCell}>Status</th>
                    <td>
                      {getIcon(
                        `summary-${key}`,
                        element.summary,
                        SUMMARY_COLUMNS.status
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.rowHeaderCell}>
                      Advanced Information
                    </th>
                    <td className="info">
                      {element.inPrivacyPolicy || element.ourCheck
                        ? getIcon(
                            key,
                            element.ourCheck,
                            SUMMARY_COLUMNS.advancedInformation
                          )
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
      </div>

      <table className={clsx(classes.table, classes.smTable)}>
        <tbody>
          <tr>
            <th className={classes.tableHeaderCell}>Tracking types</th>
            <th className={classes.tableHeaderCell}>In privacy policy</th>
            <th className={classes.tableHeaderCell}>Reality check</th>
            <th className={classes.tableHeaderCell}>Status</th>
            <th className={classes.tableHeaderCell}>Advanced Information</th>
          </tr>
          {Object.keys(CATEGORIES)
            .map(categoryKeysToData(data))
            .map(([category, element, key]) => {
              return (
                <tr key={key}>
                  <th className={classes.rowHeaderCell}>
                    <Tooltip title={DESCRIPTION[category]} placement="right">
                      <span className="tooltip">{category}</span>
                    </Tooltip>
                  </th>
                  <td>
                    {key !== "highRisk"
                      ? getIcon(
                          `privacy-${key}`,
                          element.inPrivacyPolicy,
                          SUMMARY_COLUMNS.inPrivacyPolicy
                        )
                      : "-"}
                  </td>
                  <td>
                    {getIcon(
                      `our-check-${key}`,
                      element.ourCheck,
                      SUMMARY_COLUMNS.ourCheck
                    )}
                  </td>
                  <td>
                    {getIcon(
                      `summary-${key}`,
                      element.summary,
                      SUMMARY_COLUMNS.status
                    )}
                  </td>
                  <td className="info">
                    {element.inPrivacyPolicy || element.ourCheck
                      ? getIcon(
                          key,
                          element.ourCheck,
                          SUMMARY_COLUMNS.advancedInformation
                        )
                      : "-"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default LiveProcesses;
