export const SECTION_ID = {
  landing: "landing",
  findingSeverity: "findingSeverity",
  customURLSchemes: "customURLSchemes",
  storeInfo: "storeInfo",
  applicationPermissions: "applicationPermissions",
  browsableActivies: "browsableActivies",
  domains: "domains",
  networkSecurity: "networkSecurity",
  atsAnalysis: "atsAnalysis",
  manifestAnalysis: "manifestAnalysis",
  binaryAnalysis: "binaryAnalysis",
  ipaBinaryAnalysis: "ipaBinaryAnalysis",
  niapAnalysis: "niapAnalysis",
  certificateInfo: "certificateInfo",
  codeAnalysis: "codeAnalysis",
  apkidAnalysis: "apkidAnalysis",
  emails: "emails",
  trackers: "trackers",
  hardcodedSecrets: "hardcodedSecrets",
  firebaseURL: "firebaseURL",
  opportunities: "opportunities",
  sdks: "sdks",
};

export const SECTION_TITLE = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.storeInfo]: "App store Information",
  [SECTION_ID.findingSeverity]: "Finding severity",
  [SECTION_ID.applicationPermissions]: "Application Permissions",
  [SECTION_ID.domains]: "Domain Malware Check",
  [SECTION_ID.browsableActivies]: "Browsable Activities",
  [SECTION_ID.networkSecurity]: "Network Security",
  [SECTION_ID.binaryAnalysis]: "Shared Library Binary Analysis",
  [SECTION_ID.ipaBinaryAnalysis]: "IPA Binary Analysis",
  [SECTION_ID.niapAnalysis]: "NIAP Analysis v1.3",
  [SECTION_ID.certificateInfo]: "Certificate Information",
  [SECTION_ID.codeAnalysis]: "Code Analysis",
  [SECTION_ID.apkidAnalysis]: "APKID Analysis",
  [SECTION_ID.emails]: "Emails",
  [SECTION_ID.trackers]: "Trackers",
  [SECTION_ID.hardcodedSecrets]: "Hardcoded Secrets",
  [SECTION_ID.manifestAnalysis]: "Manifest Analysis",
  [SECTION_ID.firebaseURL]: "Firebase DataBases",
  [SECTION_ID.customURLSchemes]: "Custom URL Schemes",
  [SECTION_ID.atsAnalysis]: "App Transport security (ATS)",
  [SECTION_ID.opportunities]: "Opportunities",
  [SECTION_ID.sdks]: "SDKS",
};

export const SECTION_NAME = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.findingSeverity]: "FindingSeverity",
  [SECTION_ID.storeInfo]: "StoreInfo",
  [SECTION_ID.applicationPermissions]: "ApplicationPermissions",
  [SECTION_ID.browsableActivies]: "BrowsableActivies",
  [SECTION_ID.domains]: "Domains",
  [SECTION_ID.networkSecurity]: "NetworkSecurity",
  [SECTION_ID.atsAnalysis]: "AtsAnalysis",
  [SECTION_ID.binaryAnalysis]: "BinaryAnalysis",
  [SECTION_ID.ipaBinaryAnalysis]: "IpaBinaryAnalysis",
  [SECTION_ID.niapAnalysis]: "NiapAnalysis",
  [SECTION_ID.certificateInfo]: "CertificateInfo",
  [SECTION_ID.codeAnalysis]: "CodeAnalysis",
  [SECTION_ID.apkidAnalysis]: "ApkidAnalysis",
  [SECTION_ID.emails]: "Emails",
  [SECTION_ID.trackers]: "Trackers",
  [SECTION_ID.hardcodedSecrets]: "HardcodedSecrets",
  [SECTION_ID.firebaseURL]: "FirebaseURL",
  [SECTION_ID.manifestAnalysis]: "ManifestAnalysis",
  [SECTION_ID.customURLSchemes]: "CustomURLSchemes",
  [SECTION_ID.opportunities]: "Opportunities",
  [SECTION_ID.sdks]: "Sdks",
};

export const REPORT_VERSION = {
  apk: "apk",
  ios: "ios",
};

export const ORDERED_ANCHORS = {
  [REPORT_VERSION.apk]: [
    SECTION_NAME.landing,
    SECTION_NAME.applicationPermissions,
    SECTION_NAME.browsableActivies,
    SECTION_NAME.networkSecurity,
    SECTION_NAME.sdks,
    SECTION_NAME.codeAnalysis,
    SECTION_NAME.domains,
    SECTION_NAME.findingSeverity,
    SECTION_NAME.certificateInfo,
    SECTION_NAME.apkidAnalysis,
    SECTION_NAME.binaryAnalysis,
    SECTION_NAME.niapAnalysis,
    SECTION_NAME.manifestAnalysis,
    SECTION_NAME.storeInfo,
    SECTION_NAME.opportunities,
  ],
  [REPORT_VERSION.ios]: [
    SECTION_NAME.landing,
    SECTION_NAME.applicationPermissions,
    SECTION_NAME.sdks,
    SECTION_NAME.codeAnalysis,
    SECTION_NAME.domains,
    SECTION_NAME.findingSeverity,
    SECTION_NAME.customURLSchemes,
    SECTION_NAME.atsAnalysis,
    SECTION_NAME.ipaBinaryAnalysis,
    SECTION_NAME.storeInfo,
    SECTION_NAME.opportunities,
  ],
};

export const SECTIONS_CONTENT = {
  [SECTION_ID.landing]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.landing,
    title: "",
    description: "",
  },
  [SECTION_ID.sdks]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.sdks,
    title: "SDKS",
    description: "",
  },
  [SECTION_ID.storeInfo]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.storeInfo,
    title: "App store Information",
    // description:
    //   "Understand PII being shared with live third-party integrations on your website/app",
  },
  [SECTION_ID.findingSeverity]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.findingSeverity,
    title: "Finding severity",
    // description:
    //   "Understand PII being shared with live third-party integrations on your website/app",
  },
  [SECTION_ID.applicationPermissions]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.applicationPermissions,
    title: "Application Permissions",
    description: (
      <div>
        The goal of analyzing application permissions in APKs and IPAs via
        static code analysis, the goal is to understand:
        <ul>
          <li>What permissions are being requested?</li>
          <li>How these permissions are being used in the code.</li>
          <li>
            Whether there's any potential misuse or overreach of permissions.
          </li>
        </ul>
        This addresses concerns like:
        <ul>
          <li>
            This provides insights into potential vulnerabilities and/or privacy
            concerns.
          </li>
          <li>
            Over-permission: An app might request access to features or data it
            doesn't need.
          </li>
          <li>
            Misuse: Even with appropriate permissions, there might be a risk in
            how data or features are accessed or used.
          </li>
        </ul>
        Some real-world examples:
        <ul>
          <li>
            In 2017, the popular game Pokemon Go was found to be collecting
            users' precise location data even when the app was not in use. This
            data was being sent to Google without users' knowledge or consent.
            The breach was caused by a vulnerability in the app's code that
            allowed the app to access location data even when it was not
            supposed to.
          </li>
          <li>
            In 2018, the dating app Tinder was found to be collecting users'
            Facebook data, including their friends list and contact information,
            without their knowledge or consent. The breach was caused by a
            vulnerability in the app's code that allowed the app to access this
            data without authorization.
          </li>
          <li>
            In 2019, the fitness app Strava was found to be leaking users' GPS
            data, including their workout routes and locations, to the public.
            The breach was caused by a vulnerability in the app's code that
            allowed this data to be accessed by anyone who knew the app's API
            key.
          </li>
        </ul>
      </div>
    ),
  },
  [SECTION_ID.domains]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.domains,
    title: "Domain Malware Check",
    description: (
      <div>
        Domain malware check involves examining the domains the app interacts
        with (e.g., for data transfer, analytics, and third-party services) and
        cross-referencing them with databases of known malicious or suspicious
        domains. Here's how domain malware checks in APKs and IPAs are relevant
        to privacy and data risk:
        <ol type="1">
          <li>
            <b>Data Exfiltration:</b>
            <br />
            Malware-infected apps or apps that interact with malicious domains
            may exfiltrate user data, transmitting personal information to
            unauthorized entities. Identifying suspicious domains helps in
            detecting potential data leakage points.
          </li>
          <li>
            <b>Command and Control Servers:</b>
            <br />
            Many malware strains communicate with command and control (C2)
            servers for instructions or to send stolen data. These servers often
            reside in domains that have been flagged for malicious activities.
            If an app communicates with such domains, it could be an indicator
            of a compromised app or inherent malware.
          </li>
          <li>
            <b>Third-party Libraries and Ad Networks:</b>
            <br />
            Apps often incorporate third-party libraries or ad networks that can
            communicate with several domains. Some of these domains might be
            flagged for serving malicious content, intrusive tracking, or
            violating user privacy. By inspecting the domains these third-party
            components interact with, you can identify potential privacy risks.
          </li>
          <li>
            <b>Unwanted Tracking and Profiling:</b>
            <br />
            Some domains are known to be associated with aggressive user
            tracking, profiling, or invasive advertising. Even if they are not
            classified as "malware," these domains can pose significant privacy
            risks. Identifying such domains can help address potential privacy
            violations.
          </li>
          <li>
            <b>Phishing and Scams:</b>
            <br />
            Some malicious domains are designed to mimic genuine services to
            trick users into providing sensitive information. If an app
            communicates with such phishing domains, users' data and privacy are
            at risk.
          </li>
          <li>
            <b>Embedded Web Content:</b>
            <br />
            If the app uses web views or embedded browsers to display content,
            malicious domains can exploit vulnerabilities, potentially leading
            to data access or other forms of privacy invasion.
          </li>
        </ol>
        Some real-world examples:
        <ul>
          <li>
            In 2020, the popular game Minecraft was found to be infected with a
            domain malware that could have allowed attackers to redirect users
            to malicious websites. The malware was disguised as a legitimate
            update for the game and was downloaded by millions of users.
          </li>
          <li>
            In 2021, the social media app TikTok was found to be infected with a
            domain malware that could have allowed attackers to steal users'
            personal data, including their usernames, passwords, and contact
            information. The malware was disguised as a legitimate notification
            from the app and was clicked on by millions of users.
          </li>
          <li>
            In 2022, the dating app Grindr was found to be infected with a
            domain malware that could have allowed attackers to track users'
            location data. The malware was disguised as a legitimate ad and was
            displayed to users of the app.
          </li>
        </ul>
      </div>
    ),
  },
  [SECTION_ID.browsableActivies]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.browsableActivies,
    title: "Browsable Activities",
    description: (
      <div>
        Browsable activities in APKs (Android apps) and IPAs (iOS apps) refer to
        the application activities (or screens) that can be invoked or triggered
        from an external source, such as a web link. These activities can be an
        important area of interest during static code analysis because they
        expose an interface of the application to external inputs and can,
        therefore, be a potential vector for malicious attacks or unintended
        behavior.
        <ul>
          <li>
            Deep Links and Data Injection: If not properly validated, data
            passed to these activities can be manipulated, leading to malicious
            behavior.
          </li>
          <li>
            Leakage of Information: Poorly implemented browsable activities
            might inadvertently leak sensitive data.
          </li>
          <li>
            Phishing: If an app is poorly designed, an attacker could
            potentially create a malicious link that invokes a browsable
            activity, tricking a user into believing they are interacting with a
            legitimate component.
          </li>
          <li>
            Unintended Exposure: Sometimes developers might inadvertently expose
            critical activities as browsable, making them accessible from
            external sources.
          </li>
        </ul>
        Some real-world examples:
        <ul>
          <li>
            In 2018, the MyFitnessPal app was found to be sending users'
            personal data, including email addresses, passwords, and workout
            data, to a third-party advertising company without their knowledge
            or consent. The breach was caused by a vulnerability in the app's
            code that allowed the third-party company to access the data without
            authorization.
          </li>
          <li>
            In 2019, the Grindr dating app was found to be collecting and
            storing users' HIV status data without their knowledge or consent.
            The breach was caused by a vulnerability in the app's code that
            allowed the data to be accessed by unauthorized third parties.
          </li>
          <li>
            In 2020, the Zoom video conferencing app was found to be vulnerable
            to a data breach that could have allowed attackers to access users'
            personal data, including meeting recordings and chat logs. The
            breach was caused by a vulnerability in the app's code that allowed
            attackers to exploit a security flaw in the app's call encryption.
          </li>
        </ul>
      </div>
    ),
  },
  [SECTION_ID.networkSecurity]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.networkSecurity,
    title: "Network Security",
    description: (
      <div>
        Network security involves ensuring that any data sent or received over
        the network is protected against eavesdropping, tampering, or other
        malicious activities. Static code analysis plays a critical role in
        identifying potential vulnerabilities or misconfigurations related to
        network communications within the app. Some real-world examples:
        <ul>
          <li>
            In 2021, the dating app Bumble was found to be vulnerable to a data
            breach that could have allowed attackers to access users' personal
            data, including their messages and location data. The breach was
            caused by a vulnerability in the app's code that allowed attackers
            to exploit a security flaw in the app's network security.
          </li>
          <li>
            In 2022, the financial app Venmo was found to be vulnerable to a
            data breach that could have allowed attackers to access users'
            personal data, including their financial transactions. The breach
            was caused by a vulnerability in the app's code that allowed
            attackers to exploit a security flaw in the app's network security.
          </li>
          <li>
            In 2018, the messaging app Telegram was found to be vulnerable to a
            data breach that could have allowed attackers to access users'
            personal data, including their chat history and contact information.
            The breach was caused by a vulnerability in the app's code that
            allowed attackers to exploit a security flaw in the app's network
            security.
          </li>
        </ul>
      </div>
    ),
  },
  [SECTION_ID.manifestAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.manifestAnalysis,
    title: "Manifest Analysis",
    description: "",
  },
  [SECTION_ID.binaryAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.binaryAnalysis,
    title: "Shared Library Binary Analysis",
    description: (
      <div>
        When it comes to static code analysis and privacy concerns, here's how
        shared library binaries are relevant:
        <ol type="1">
          <li>
            <b>Third-party Integrations:</b>
            <br />
            Apps often use shared libraries for functionalities that are
            commonly required, such as networking, image processing, data
            storage, etc. If these libraries have vulnerabilities or malicious
            code, it can compromise user data.
          </li>
          <li>
            <b>Unwanted Data Collection:</b>
            <br />
            Some shared libraries, especially those related to analytics,
            advertising, or telemetry, might collect user data. Analyzing these
            binaries can reveal if they access or send data that might not be
            obvious at the application layer, leading to potential privacy
            breaches.
          </li>
          <li>
            <b>Native Code Execution:</b>
            <br />
            Given that these libraries typically contain native code, they can
            execute functions outside the standard app sandbox. If not securely
            implemented, this can lead to potential privacy and security issues,
            such as buffer overflows or unauthorized data access.
          </li>
          <li>
            <b>Data Encryption and Handling:</b>
            <br />
            Libraries used for cryptography or data storage need thorough
            inspection. A weak encryption method or vulnerability in a
            data-handling library can expose user data.
          </li>
          <li>
            <b>Obfuscation and Concealment:</b>
            <br />
            Malicious apps sometimes use shared library binaries to hide
            malicious functionality, assuming that many analysis tools focus
            more on the bytecode or high-level code. By diving into shared
            libraries, hidden behaviors that might compromise privacy can be
            detected.
          </li>
          <li>
            <b>Reverse Engineering:</b>
            <br />
            Although shared libraries are precompiled, tools like IDA Pro,
            Ghidra, or Radare2 can reverse-engineer them to inspect their
            behavior. This allows for a deeper inspection of what the library is
            doing, especially regarding data handling and communication.
          </li>
        </ol>
      </div>
    ),
  },
  [SECTION_ID.ipaBinaryAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.ipaBinaryAnalysis,
    title: "IPA Binary Analysis",
    description: "",
  },
  [SECTION_ID.niapAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.niapAnalysis,
    title: "NIAP Analysis v1.3",
    description: (
      <div>
        The National Information Assurance Partnership (NIAP) is a U.S.
        government initiative that oversees the evaluation of commercial IT
        products for use in national security systems. NIAP operates under the
        Common Criteria Recognition Arrangement (CCRA), which is an
        international agreement for mutual recognition of secure IT products.
        <br />
        When talking about NIAP analysis of APKs (Android applications) and IPAs
        (iOS applications) in the context of static code analysis and privacy,
        it essentially means ensuring the apps meet the security and privacy
        requirements set by the NIAP (often based on Common Criteria or
        Protection Profiles). Here's how it's relevant:
        <ol>
          <li>
            <b>Data Protection:</b>
            <br />
            NIAP analysis ensures that applications appropriately handle and
            protect user data, both at rest and in transit. This includes
            encryption requirements, proper key management, and ensuring
            sensitive data isn't exposed.
          </li>
          <li>
            <b>Access Control:</b>
            <br />
            The apps are evaluated for how they manage access controls, ensuring
            unauthorized entities cannot access sensitive functions or data.
            This includes assessing permissions, authentication mechanisms, and
            session management.
          </li>
          <li>
            <b>Cryptographic Operations:</b>
            <br />
            NIAP requires that cryptographic modules used by applications meet
            specific standards. This ensures that data encryption, signature
            verification, and other cryptographic operations uphold a certain
            level of security.
          </li>
          <li>
            <b>Third-party Libraries:</b>
            <br />
            Given that many apps use third-party libraries, NIAP analysis
            ensures that these libraries don't introduce vulnerabilities that
            could jeopardize security or user privacy.
          </li>
          <li>
            <b>Vulnerability Analysis:</b>
            <br />
            As part of the evaluation, apps undergo vulnerability assessments to
            ensure they are not susceptible to known vulnerabilities that could
            impact user privacy.
          </li>
          <li>
            <b>Platform Integration:</b>
            <br />
            The analysis ensures that applications properly integrate with their
            platform's (Android or iOS) security mechanisms and don't try to
            bypass them.
          </li>
          <li>
            <b>Privacy-focused Features:</b>
            <br />
            Apps are assessed for features that specifically pertain to user
            privacy, such as how they handle personal data, user consent
            mechanisms, and data sharing with third parties.
          </li>
        </ol>
      </div>
    ),
  },
  [SECTION_ID.certificateInfo]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.certificateInfo,
    title: "Certificate Information",
    description: (
      <div>
        <ul>
          <li>
            Certificates confirm the authenticity of the app developer and
            ensure that the app has not been tampered with since its signing. If
            an app's certificate cannot be validated or if it's signed with a
            non-trusted certificate, the app might be a repackaged or malicious
            version that can compromise user data.
          </li>
          <li>
            On Android, apps signed with the same certificate can share data and
            permissions. Knowing which other apps are signed with the same
            certificate, and thus can share data, is vital for understanding
            potential privacy implications.
          </li>
          <li>
            Many apps incorporate third-party libraries for various
            functionalities. These libraries might establish their network
            connections, and it's crucial to ensure they also handle
            certificates securely to protect user data.
          </li>
        </ul>
      </div>
    ),
  },
  [SECTION_ID.codeAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.codeAnalysis,
    title: "Code Analysis",
    description: (
      <div>
        Static code analysis of APKs (Android applications) and IPAs (iOS
        applications) examines an app's codebase without executing it, to give
        insights into its design, functionality, and potential vulnerabilities.
        It can help identify
        <ol type="1">
          <li>
            <b>Security Vulnerabilities:</b>
            <br />
            Codebase Inspection: Potential vulnerabilities, such as hardcoded
            credentials, insecure algorithm implementations, or insecure
            function calls, can be identified.
            <br />
            Third-party Libraries: Detect the usage of outdated or vulnerable
            third-party libraries.
            <br />
            Misconfigurations: Identify insecure configurations, like enabling
            debugging in production builds.
          </li>
          <li>
            <b>Permissions and Privacy:</b>
            <br />
            Over-permission: Check if the app requests more permissions than
            necessary, which could be a potential privacy concern.
            <br />
            Sensitive Data Handling: Identify how the app handles and stores
            sensitive data, like user information or cryptographic keys.
          </li>
          <li>
            <b>Data Storage:</b>
            <br />
            Insecure Data Storage: The way an app stores sensitive data, like
            user details or passwords, can be identified. Using plain text,
            insecure cryptographic methods, or storing data in easily accessible
            locations can be flagged during static analysis.
          </li>
          <li>
            <b>Data Transmission:</b>
            <br />
            Unencrypted Data Transfers: By analyzing the app's networking code,
            it's possible to identify whether data is sent over unencrypted
            channels, which could expose users to man-in-the-middle attacks.
            <br />
            Endpoint Analysis: Static code analysis can identify where the app
            sends data, helping to highlight potential data leaks or transfers
            to undesirable third-party entities.
          </li>
          <li>
            <b>Hardcoded Secrets:</b>
            <br />
            Detecting hardcoded API keys, tokens, or other secrets can be vital
            as they might grant unauthorized access to backend systems or
            third-party services where even more user data could be exposed.
          </li>
        </ol>
      </div>
    ),
  },
  [SECTION_ID.apkidAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.apkidAnalysis,
    title: "APKID Analysis",
    description: "",
  },
  [SECTION_ID.emails]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.emails,
    title: "Emails",
    description: "",
  },
  [SECTION_ID.trackers]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.trackers,
    title: "Trackers",
    description: "",
  },
  [SECTION_ID.hardcodedSecrets]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.hardcodedSecrets,
    title: "Hardcoded Secrets",
    description: "",
  },
  [SECTION_ID.firebaseURL]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.firebaseURL,
    title: "Firebase DataBases",
    description: "",
  },
  [SECTION_ID.customURLSchemes]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.customURLSchemes,
    title: "Custom URL Schemes",
    description: "",
  },
  [SECTION_ID.atsAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.atsAnalysis,
    title: "App Transport security (ATS)",
    description: "",
  },
  [SECTION_ID.opportunities]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.opportunities,
    title: "Opportunities",
    description: "",
  },
};

export const SEVERITY_SECTION_NAME = {
  network: SECTION_ID.networkSecurity,
  code: SECTION_ID.codeAnalysis,
  permissions: SECTION_ID.applicationPermissions,
  trackers: SECTION_ID.trackers,
  manifest: SECTION_ID.manifestAnalysis,
  certificate: SECTION_ID.certificateInfo,
};
