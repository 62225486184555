export const CONSTANTS = {
  REQUESTS_DATA_REQUEST_START: "REQUESTS_DATA_REQUEST_START",
  REQUESTS_DATA_REQUEST_END: "REQUESTS_DATA_REQUEST_END",
  REQUESTS_DATA_SET_ERROR: "REQUESTS_DATA_SET_ERROR",
  REQUESTS_DATA_SAVE_DATA: "REQUESTS_DATA_SAVE_DATA",
  REQUESTS_DATA_SAVE_META: "REQUESTS_DATA_SAVE_META",
  REQUESTS_FILTERS_SELECT_DATA: "REQUESTS_FILTERS_SELECT_DATA",
  REQUESTS_FILTERS_SAVE_DATA: "REQUESTS_FILTERS_SAVE_DATA",
  REQUESTS_FILTERS_TYPES: {
    SEARCH: "SEARCH",
    REGULATION: "REGULATION",
    INCLUDES: "INCLUDES",
    DAYS_LEFT: "DAYS_LEFT",
    STATUS: "STATUS",
    VERIFIED_CUSTOMER: "VERIFIED_CUSTOMER",
  },
};

export const FILTERS_DATA = {
  [CONSTANTS.SEARCH]: "",
  [CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION]: [
    { value: "all", label: "All" },
    { value: 1, label: "GPDR" },
    { value: 2, label: "CCPA" },
  ],
  [CONSTANTS.REQUESTS_FILTERS_TYPES.DAYS_LEFT]: [0, 45],
  [CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS]: [
    { value: "all", label: "All" },
    { value: "open", label: "Open" },
    { value: "progress", label: "In Progress" },
    { value: "denied", label: "Denied" },
    { value: "approved", label: "Approved" },
  ],
  [CONSTANTS.REQUESTS_FILTERS_TYPES.VERIFIED_CUSTOMER]: [
    { value: "all", label: "All" },
  ],
};
