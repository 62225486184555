import React from "react";
import PropTypes from "prop-types";
import useStyles from "../styles"; // adjust the import path as needed
import { Box, Typography } from "@material-ui/core";
export const StatisticsComponent = ({ summary }) => {
  const classes = useStyles();
  const getValueOrDefault = (value) => {
    return value ? value : 0;
  };
  const summarySanitized = {
    Repositories: summary ? getValueOrDefault(summary.repositories) : 0,
    Lekeages: summary ? getValueOrDefault(summary.leakages) : 0,
    "Sharing PII with 3rd parties": summary
      ? getValueOrDefault(summary.sharing_pii_with_3rd_parties)
      : 0,
    "Correct PII handling": summary ? getValueOrDefault(summary.correct_pii_handling) : 0,
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        gap: 24,
      }}
    >
      {Object.entries(summarySanitized).map(([key, value]) => (
        <Box
          key={key}
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 16,
            flex: 1,
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            minWidth: "238px",
            width: "100%",
          }}
        >
          <Typography
            style={{ color: "#1B3586", fontFamily: "Aldrich", fontSize: 36 }}
          >
            {value}
          </Typography>
          <Typography
            style={{ fontFamily: "Inter", fontSize: 16, color: "#000000" }}
          >
            {key}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

StatisticsComponent.propTypes = {
  className: PropTypes.string,
};
