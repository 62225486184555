import CheckmarkIconTurquoiseSmall from "assets/svgs-icons/checkmark-turquoise-sm.svg";
import { makeStyles } from "@material-ui/core";

const subtitleMixin = (theme) => ({
  color: theme.palette.primary.main,
});

export default makeStyles(
  (theme) => ({
    header: {
      textAlign: "center",
    },
    cta: {
      margin: theme.spacing(7, "auto"),
      width: 300, // TODO: refactor into Button
      display: "flex", // TODO: refactor into Button
      "@media (max-height: 820px)": {
        margin: theme.spacing(5, "auto"),
      },
    },
    platformBenefits: {
      margin: "auto",
      marginBottom: theme.spacing(10),
      maxWidth: 650,
      "@media (max-height: 820px)": {
        marginBottom: 40,
      },
    },
    benefitsTitle: {
      ...subtitleMixin(theme),
    },
    benefits: {
      listStyleImage: `url(${CheckmarkIconTurquoiseSmall})`,
    },
    benefit: {
      ...theme.typography.li,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.spacing(2.5),
      },
    },
    demoReports: {
      margin: "auto",
      maxWidth: 1000,
    },
    demoReportsTitle: {
      ...subtitleMixin(theme),
      marginBottom: theme.spacing(4),
    },
  }),
  { name: "FreeReportPitch" }
);
