import { Text, View } from "@react-pdf/renderer";

const SdksPage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>Sdks</Text>
      </View>
      <View>
        <View>
          <View style={styles.table}>
            <View style={styles.thead} fixed>
              <View style={styles.tr}>
                <View style={styles.th}>
                  <Text>Developer</Text>
                </View>
                <View style={styles.th}>
                  <Text>Category</Text>
                </View>
                <View style={styles.th}>
                  <Text>SDK Name</Text>
                </View>
                <View style={styles.th}>
                  <Text>Type</Text>
                </View>
              </View>
            </View>
            {Object.entries(data.sdks).map(([key, value]) => (
              <View style={styles.tr} key={key} wrap={false}>
                <View style={styles.td}>
                  <Text>{value.developer}</Text>
                </View>
                <View style={styles.td}>
                  <Text>{value.category}</Text>
                </View>
                <View style={styles.td}>
                  <Text>{value.name}</Text>
                </View>
                <View style={styles.td}>
                  <Text>{value.type}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

export default SdksPage;
