import React, { useMemo, useState } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Title from "pages/AppReport/common/Title";
import Subtitle from "pages/SecurityReport/common/Subtitle";
import { SECTIONS_CONTENT } from "pages/SecurityReport/constants";
import SectionContent from "pages/AppReport/common/SectionContent";
import ExpandedPanel from "pages/AppReport/Sections/FindingSeverity/ExpandedPanel";
import { BoxDetails } from "pages/AppReport/Sections/FindingSeverity/InformationSection";
import useStyles from "./styles";

const ScanCoverageInformation = ({ goToNextSectionAction, data }) => {
  const [expanded, setExpanded] = useState();
  const classes = useStyles();

  const testsPerformedList = useMemo(
    () =>
      data?.scan_output?.output_json.map((el) => ({
        value: (
          <Subtitle
            status="confirm"
            classes={{
              root: classes.subtitleRoot,
              subtitle: classes.subtitle,
              icon: classes.icon,
            }}
          >
            {el.test_description}
          </Subtitle>
        ),
      })) || [],
    [data]
  );
  return (
    <Page
      key={"TEXT-SETTINGS"}
      footerBackgroundColor={
        SECTIONS_CONTENT.scanCoverageInformation.backgroundColor
      }
      goToNextSectionAction={goToNextSectionAction}
      hasFooter={false}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.scanCoverageInformation.description}>
        {SECTIONS_CONTENT.scanCoverageInformation.title}
      </Title>
      <SectionContent className={classes.content}>
        <ExpandedPanel
          subtitle={`List of tests performed ${data.scan_info?.num_finished_tests}/${data.scan_info?.num_tests}`}
          onChange={() =>
            setExpanded(
              expanded !== "listOfTestsPerformed" ? "listOfTestsPerformed" : ""
            )
          }
          expanded={expanded === "listOfTestsPerformed"}
        >
          <BoxDetails data={testsPerformedList} />
        </ExpandedPanel>
        <ExpandedPanel
          subtitle="Scan parameters"
          onChange={() =>
            setExpanded(expanded !== "scanParameters" ? "scanParameters" : "")
          }
          expanded={expanded === "scanParameters"}
        >
          <BoxDetails
            data={[
              {
                label: "Website URL",
                value: (
                  <a href={data?.website} target="_blanc">
                    {data?.website}
                  </a>
                ),
              },
              {
                label: "Scan type",
                value: "Ptt_engine",
              },
            ]}
          />
        </ExpandedPanel>
      </SectionContent>
    </Page>
  );
};

export default ScanCoverageInformation;
