import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  logo: {
    padding: theme.spacing(4, 0, 4, 0),
    display: "block",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  logoutButton: {
    border: 0,
    backgroundColor: "transparent",
    margin: theme.spacing(2, 0, 0, 0),
    fontSize: "1.2em",
    color: "gray",
  },
  container: {
    maxWidth: 1200,
    margin: "0 auto",
  },
  body: {
    width: "100%",
    textAlign: "center",
  },
  box: {
    "& img": {
      // margin: "60px 0 20px 0",
      margin: theme.spacing(6, 0, 2, 0),
    },
  },
  storeLink: {
    display: "block",
  },
}));
