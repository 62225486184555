import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectEnv } from "redux/_environments/environment.actions";
import {
  getLoading,
  getFilteredLastScansData,
  getError,
} from "redux/_charts/charts.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { getLastScansData } from "redux/_charts/charts.async.actions";
import { getFilters } from "redux/_filters/filters.selectors";
import PageWrapper from "components/PageWrapper";
import CONSTANTS from "redux/_filters/filters.constants";
import StackAreaChart from "charts/StackAreaChart";
import isEmpty from "lodash.isempty";
import { Box } from "@material-ui/core";

import { data as mockedData } from "./mocked.json";

const { TYPES } = CONSTANTS;

const PiiTrends = ({
  match,
  filters,
  loading,
  selectedEnv,
  selectEnvironment,
  getLastScansData,
  lastScansData,
  error = false,
}) => {
  useEffect(() => {
    const { env_id: envId } = match.params;
    if (envId && selectedEnv !== envId) {
      selectEnvironment(envId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLastScansData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <PageWrapper
      error={error}
      retryFn={getLastScansData}
      title="PII TRENDS"
      subtitle="HOME"
      showKpis
      kpisTitle="Last scans"
      filters={[
        TYPES.ENV,
        TYPES.ENTITY_ROLE,
        TYPES.STORAGE,
        TYPES.BUSINESS_UNIT,
        TYPES.PURPOSE,
        TYPES.LAST_SCANS,
        TYPES.COUNTRY,
        TYPES.PII_CATEGORIES,
      ]}
      loading={loading}
      empty={isEmpty(lastScansData)}
    >
      <Box height="100%" display="flex" alignItems="center">
        <StackAreaChart
          height={550}
          data={!isEmpty(lastScansData) ? lastScansData : mockedData}
        />
      </Box>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  lastScansData: getFilteredLastScansData(state),
  selectedEnv: getSelectedEnvId(state),
  loading: getLoading(state),
  filters: getFilters(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectEnvironment: (envId) => dispatch(selectEnv(envId)),
  getLastScansData: () => dispatch(getLastScansData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PiiTrends);
