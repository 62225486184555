import React from "react";

const dynamicIcon = (props) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78039 16.1145C5.41204 17.5093 7.00263 17.4381 7.51007 15.9864L9.92644 9.0731L12.5076 16.8163C13.0371 18.4047 14.7666 18.2744 15.0437 16.6121L17.1747 3.8258L19.5107 8.49766C19.77 9.01632 20.4007 9.22655 20.9194 8.96722C21.438 8.70789 21.6483 8.07719 21.3889 7.55853L18.0801 0.940844C17.3698 -0.479806 15.7244 -0.245834 15.4611 1.33404L13.4942 13.1355L11.2773 6.48512C10.7736 4.97409 9.12962 4.98838 8.60998 6.47508L6.0444 13.8152L5.03978 11.5968C4.45457 10.3046 2.941 10.2081 2.21521 11.4418L0.645158 14.1106C0.35112 14.6104 0.517933 15.2539 1.01774 15.548C1.51756 15.842 2.1611 15.6752 2.45514 15.1754L3.52869 13.3505L4.78039 16.1145Z"
      fill="#3F51B5"
    />
  </svg>
);

export default dynamicIcon;
