import React, { useState } from "react";
import config from "config";
import axios from "axios";
import { authHeader } from "helpers/auth-header";

const useProviderPostHook = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  const fetchData = (requestbody) => {
    setLoading(true);
    axios
      .post(`${config.apiUrl}/repositories/`, requestbody, getHeadersConfig())
      .then((response) => {
        setData(response.data);
        setError(null); // Clear any previous errors on success
      })
      .catch((err) => {
        let errorMessage;
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorData = err.response.data;
          if (errorData && errorData.error && errorData.error.message) {
            errorMessage = `${Object.values(errorData.error.message)
              .flat()
              .join(", ")} - ${err.response.status}`;
          } else {
            errorMessage = `Server responded with status ${err.response.status}`;
          }
        } else if (err.request) {
          // The request was made but no response was received
          errorMessage = "No response received from server.";
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = `Error in setting up request: ${err.message}`;
        }
        setError(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, fetchData };
};

export default useProviderPostHook;
