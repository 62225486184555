import clsx from "clsx";
import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import Footer from "./footer";

const Page = ({
  className = "",
  children,
  sidebar,
  hasFooter = true,
  footerText = "",
  footerBackgroundColor = undefined,
  goToNextSectionAction = undefined,
  fullHeight = false,
  trimPaddingTop = false,
}) => {
  const classes = useStyles();

  return (
    <div className={"section fp-auto-height-responsive " + classes.page}>
      <div
        className={clsx(
          classes.wrapper,
          !hasFooter && classes.noPadding,
          fullHeight && "-full-height"
        )}
      >
        <div
          className={clsx(
            classes.content,
            className,
            trimPaddingTop && "-trim-padding-top"
          )}
        >
          {!!sidebar ? (
            <Grid container spacing={3}>
              <Grid item md={3} classes={{ item: classes.gridItem }}>
                {sidebar}
              </Grid>
              <Grid item md={9} classes={{ item: classes.gridItem }}>
                {children}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item sm={12} classes={{ item: classes.gridItem }}>
                {children}
              </Grid>
            </Grid>
          )}
        </div>
        {hasFooter && (
          <Footer
            className={className}
            title={footerText}
            backgroundColor={footerBackgroundColor}
            goToNextSectionAction={goToNextSectionAction}
          />
        )}
      </div>
    </div>
  );
};

export default Page;
