import React from "react";
import clsx from "clsx";
import { Box, Button } from "@material-ui/core";
import SVGIcon from "components/svg-icon/svg-icon";
import useStyles from "./styles";

const RequestButtons = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.verify)}
        >
          <SVGIcon icon="checkOutlined" />
          Verify
        </Button>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.deny)}
        >
          <SVGIcon icon="errorOutlined" />
          Deny
        </Button>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.delete)}
        >
          <SVGIcon icon="trash" />
          Delete
        </Button>
        <Button className={clsx(classes.button, classes.approve)}>
          Approve
        </Button>
      </Box>
    </>
  );
};

export default RequestButtons;
