import api from "utils/fetch";

export const openChargebeeCheckout = (planId, onSuccess) => {
  global.Chargebee.getInstance().openCheckout({
    hostedPage: () => {
      return api.get(`payments/checkout/?plan=${planId}`);
    },
    success: onSuccess,
  });
};

export const openChargebeePortal = () => {
  const chargebeeInstance = global.Chargebee.getInstance();
  chargebeeInstance.setPortalSession(() => {
    return api.get("payments/session/");
  });
  chargebeeInstance.createChargebeePortal().open();
};
