import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import ReactHtmlParser from "react-html-parser";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import { Typography, makeStyles } from "@material-ui/core";
import Title from "pages/AppReport/common/Title";

const FindingsLabels = {
  anti_disassembly: "Anti Disassembly Code",
  anti_vm: "Anti-VM Code",
  anti_debug: "Anti Debug Code",
  compiler: "Compiler",
};

const useStyles = makeStyles(() => ({
  container: {
    display: "block",
    boxShadow: "0px 4px 4px 1px rgba(14, 14, 14, 0.24)",
    minWidth: "368px",
    height: "auto",
    borderRadius: "4px",
    padding: "16px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1976D2",
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1976D2",
  },
  content: {
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

export const TableCard = ({ data, sections }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        width: "auto",
        gap: "31px",
        overflow: "auto",
        padding: "5px",
      }}
      className="scrollable-element"
    >
      {data.map((element) => (
        <div className={classes.container}>
          <span className={classes.title}>{element[sections.titleKey]}</span>
          <br />
          {element.details.map((el) => (
            <>
              <span className={classes.subtitle}>
                {sections.labelRender
                  ? sections.labelRender(el)
                  : el[sections.labelKey]}
              </span>
              <br />
              <Typography className={classes.content}>
                {sections.valueRender
                  ? sections.valueRender(el.details)
                  : el.details}
              </Typography>
              {/* <br /> */}
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

const APKIDAnalysisSection = ({ goToNextSectionAction, data }) => {
  const formattedData = Object.entries(data).map((apkid) => ({
    file: apkid[0],
    details: Object.entries(apkid[1]).map((details) => ({
      findings: details[0],
      details: details[1],
    })),
  }));
  return (
    <Page
      key={SECTIONS_CONTENT.apkidAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.apkidAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">
          Check the Shared Library Binary Analysis
        </div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title>{SECTIONS_CONTENT.apkidAnalysis.title}</Title>
      <TableCard
        data={formattedData}
        sections={{
          titleKey: "file",
          key: "details",
          labelRender: (el) => (
            <span
              style={{
                color: el.findings === "compiler" ? "#74BDE5" : "#EF7D1F",
              }}
            >
              {FindingsLabels[el.findings]}
            </span>
          ),
          valueRender: (details) => ReactHtmlParser(details.join("<br />")),
        }}
      />
    </Page>
  );
};

export default APKIDAnalysisSection;
