import React, { Component } from "react";
import clsx from "clsx";
import ResizeObserver from "resize-observer-polyfill";
import debounce from "lodash.debounce";
import { withStyles } from "@material-ui/styles";
import SVGIcon from "components/svg-icon/svg-icon";
import Fab from "@material-ui/core/Fab";

import "./styles.scss";

class SVGContainer extends Component {
  container = null;

  constructor(props) {
    super(props);

    this.state = {
      width: "0px",
      height: "0px",
      scrolling: false,
      scrollOffset: 0,
      scrollId: 0,
      scrollLimit: null,
      scrollTop: false,
    };

    this.container = React.createRef();
    this.svgContainer = React.createRef();
    this.observer = new ResizeObserver(
      debounce(this.updateComputedStyles, 300)
    );

    this.body = document.getElementsByTagName("body")[0];
  }

  updateComputedStyles = () => {
    if (this.container && this.container.current) {
      const { width, height } = window.getComputedStyle(this.container.current);
      if (this.state.width !== width || this.state.height !== height) {
        this.setState({ width, height });
      }
    }
  };

  componentDidMount() {
    this.updateComputedStyles();
    if (!this.props.disableScrolling) {
      window.addEventListener("mousewheel", this.onMouseWheel, {
        passive: false,
      });
    }
    this.observer.observe(this.svgContainer.current);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disableScrolling !== this.props.disableScrolling) {
      if (this.props.disableScrolling) {
        window.removeEventListener("mousewheel", this.onMouseWheel);
      } else {
        setTimeout(() => {
          window.addEventListener("mousewheel", this.onMouseWheel, {
            passive: false,
          });
        }, 500); // wait for transition animation in the report page
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousewheel", this.onMouseWheel);
    this.observer.unobserve(this.svgContainer.current);
  }

  onMouseWheel = (e) => {
    const { deltaY } = e;
    const { scrollId, scrollTop } = this.state;
    const newScrollId = scrollId + 1;
    if (this.body) this.body.style.overflow = "hidden";
    this.setState(
      (prevState) => {
        let scrollOffset = scrollTop
          ? -deltaY * 0.2
          : prevState.scrollOffset - deltaY * 0.2;
        if (scrollOffset > 0) scrollOffset = 0;
        if (
          prevState.scrollLimit !== null &&
          scrollOffset < prevState.scrollLimit
        )
          scrollOffset = prevState.scrollLimit;
        return {
          scrolling: true,
          scrollOffset,
          scrollId: prevState.scrollId + 1,
          scrollTop: false,
        };
      },
      () => {
        setTimeout(() => {
          if (this.body) this.body.style.overflow = null;
          if (this.state.scrollId === newScrollId && this.state.scrolling)
            this.setState({
              scrolling: false,
              scrollId: 0,
            });
        }, 100);
      }
    );
  };

  onReportScrollLimit = (scrollLimit) => {
    if (scrollLimit !== this.state.scrollLimit) this.setState({ scrollLimit });
  };

  renderChildren = () => {
    const { width, height, scrollOffset, scrollTop } = this.state;
    if (width && height)
      return React.Children.map(this.props.children, (child) => {
        return React.cloneElement(child, {
          width: parseInt(width.replace("px", "")),
          height: parseInt(height.replace("px", "")),
          scroll: scrollTop ? 0 : scrollOffset,
          onReportScrollLimit: this.onReportScrollLimit,
        });
      });
    return null;
  };

  handleUp = () => this.setState({ scrollTop: true });
  handleBottom = () => this.setState({ scrollTop: false });

  render() {
    const {
      geometricPresicion,
      className = "",
      transition = true,
      classes = {},
    } = this.props;
    const { scrolling, width, height, scrollOffset, scrollTop } = this.state;
    const scroll = scrollTop ? 0 : scrollOffset;
    return (
      <div
        ref={this.svgContainer}
        style={{ height: "100%", width: "100%" }}
        className={classes.chart}
      >
        <svg
          ref={this.container}
          viewBox={`0 ${-scroll} ${parseInt(
            width.replace("px", "")
          )} ${parseInt(height.replace("px", ""))}`}
          style={{ flexGrow: 1 }}
          preserveAspectRatio="none"
          className={clsx(
            "container-chart",
            className,
            classes.chart,
            scrolling ? "scrolling" : "",
            geometricPresicion ? "geometric-precision" : "",
            !transition && "disableTransition"
          )}
        >
          {this.renderChildren()}
        </svg>

        <div className={classes.navContainer}>
          <Fab
            className={classes.button}
            color="primary"
            onClick={this.handleUp}
            disabled={scrollTop || scrollOffset === 0}
            size="small"
            title="Scroll to top"
          >
            <SVGIcon icon="goTop" />
          </Fab>
          <Fab
            className={classes.button}
            color="primary"
            onClick={this.handleBottom}
            disabled={!scrollTop}
            size="small"
            title="Return to previous position"
          >
            <SVGIcon icon="arrowRedo" />
          </Fab>
        </div>
      </div>
    );
  }
}

export default withStyles({
  container: {
    width: "100%",
    height: "100%",
  },
  chart: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  navContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    margin: 12,
  },
  button: {
    margin: 4,
  },
})(SVGContainer);
