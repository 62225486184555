import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

/*
 * @note {1} Deliberately used instead of react-router-dom's Reedirect to
 *           prevent an infinite redirection loop that makes the browser
 *           throw a "Maximum call stack exceded error".
 **/
const selectComponent = ({
  extensionPage,
  component: Component,
  isAuthenticated,
}) => (props) => {
  if (isAuthenticated) {
    return <Component {...props} />;
  } else if (extensionPage) {
    global.location.assign("/extension/login"); // {1}
  } else {
    global.location.assign("/login"); // {1}
  }
};

const PrivateRoute = ({
  dispatch,
  component,
  isAuthenticated,
  extensionPage,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={selectComponent({
        dispatch,
        isAuthenticated,
        component,
        extensionPage,
      })}
    />
  );
};

function mapStateToProps(state) {
  const { isAuthenticated } = state.authentication;
  return { isAuthenticated };
}

export default connect(mapStateToProps)(PrivateRoute);
