import {
  requestStart,
  requestEnd,
  saveData,
  selectData,
} from "redux/_filters/filters.actions";
import {
  getScansList,
  getPiiCategoriesService,
} from "redux/_filters/filters.service";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import {
  getFiltersScansLoading,
  getPiiCategories,
} from "redux/_filters/filters.selectors";
import isEmpty from "lodash.isempty";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

export const getScans = (selectScanId) => (dispatch, getState) => {
  const envId = getSelectedEnvId(getState());
  const loading = getFiltersScansLoading(getState());
  if (envId && !loading) {
    dispatch(requestStart(TYPES.SCAN));
    return getScansList(envId)
      .then((data) => {
        dispatch(requestEnd(TYPES.SCAN));
        dispatch(saveData(TYPES.SCAN, data));
        const scanToSelect = selectScanId ? selectScanId : data[0].id;
        if (!isEmpty(data)) dispatch(selectData(TYPES.SCAN, scanToSelect));
        return Promise.resolve([data, scanToSelect]);
      })
      .catch(() => {
        dispatch(requestEnd(TYPES.SCAN));
        return Promise.reject();
      });
  } else {
    return Promise.resolve([]);
  }
};

export const fetchPiiCategories = () => (dispatch, getState) => {
  const piiCategories = getPiiCategories(getState());
  if (isEmpty(piiCategories)) {
    dispatch(requestStart(TYPES.PII_CATEGORIES));
    return getPiiCategoriesService()
      .then((data) => {
        dispatch(requestEnd(TYPES.PII_CATEGORIES));
        dispatch(saveData(TYPES.PII_CATEGORIES, data));
        dispatch(
          selectData(
            TYPES.PII_CATEGORIES,
            data.map(({ id }) => id)
          )
        );
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(requestEnd(TYPES.PII_CATEGORIES));
        return Promise.reject();
      });
  } else {
    return Promise.resolve(piiCategories);
  }
};
