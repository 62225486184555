import { makeStyles } from "@material-ui/core";

const TAB_WIDTH = "427px";
const ROOT_SIZE = "862px"; // tab x2 + spacing
const TRANSITION = "0.5s cubic-bezier(0.15, 0.3, 0.25, 1)";
// TODO: parametrize colors.
const ACTIVE = {
  borderColor: "#7EC4F6",
  backgroundColor: "#E6F5FF",
};
// const COMPLETED = {
//   borderColor: "#697286",
//   backgroundColor: "#697286",
// };

export default makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: ROOT_SIZE,
    },
  },
  slide: {
    maxWidth: 500,
    paddingBottom: theme.spacing(6),
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&.-with-margin": {
      margin: "20px 0",
    },
    "&.-countries": {
      margin: "20px 0 40px",
    },
  },
  label: {
    width: 130,
  },
  textField: {
    width: 510,
    "& input": {
      paddingTop: 10,
    },
    "&.mini": {
      width: 40,
      marginRight: 10,
    },
    "&.ends": {
      width: 120,
      marginLeft: 40,
    },
    "& > .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  checkbox: {
    margin: "10px 0 10px 118px",
    color: "#666666",
  },
  selectField: {
    "& > .MuiSelect-select": {
      color: "rgba(0, 0, 0, 0.87)",
      paddingTop: 10,
    },
    "&.mini": {
      margin: "0 10px",
    },
    "& svg": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  week: {
    flexDirection: "row",
  },
  day: {
    color: "#666666",
    backgroundColor: "#f0f0f0",
  },
  selectedDay: {
    color: "white",
    backgroundColor: theme.palette.blue[500],
  },
  countries: {
    width: 510,
    height: 70,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#e8e8e8",
      borderBottom: "solid 1px !important",
      color: "rgba(0, 0, 0, 0.87)",
      borderRadius: 3,
    },
    "& p": {
      marginTop: 40,
    },
  },
  flag: {
    width: "1.8em",
    height: "1.2em",
    position: "relative",
    top: "-1px",
    "&.selected": {
      marginRight: "10px",
    },
  },
  submit: {
    width: 510,
    marginLeft: 130,
    backgroundColor: theme.palette.aquamarine[100],
    border: "0 none",
    borderRadius: 6,
    color: theme.palette.blue["young"],
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
    height: "48px",
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
    "& span": {
      width: "45%",
      display: "flex",
      justifyContent: "space-evenly",
    },
  },

  topbarWrapper: {
    paddingTop: theme.spacing(2),
    width: "100%",
    maxWidth: "900px",
    display: "flex",
  },
  topbar: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  itemWrapper: {
    width: "calc(50% - 5px)",
    position: "relative",
  },
  mobileItemWrapper: {
    width: "100%",
    padding: theme.spacing(0, 0.5),
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  mobileItem: {
    height: theme.spacing(0.5),
    position: "relative",
    width: "100%",
    "&::after": {
      position: "absolute",
      content: "''",
      left: 0,
      top: 0,
      bottom: 0,
      width: 0,
      transition: `all ${TRANSITION}`,
      backgroundColor: "#697286",
    },
    transition: `all ${TRANSITION}`,
    backgroundColor: theme.palette.grey[500],
    "&.-active": {
      backgroundColor: ACTIVE.borderColor,
    },
    "&.-completed::after": {
      width: "100%",
    },
  },
  firstItem: {
    zIndex: 2,
  },

  itemContent: {
    display: "flex",
    position: "absolute",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    padding: "5px 24px",
    top: 0,
    flexDirection: "column",
  },

  itemLabel: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C4257",
    transition: "color 0.35s ease-in-out",

    "&.-active": {
      color: "#3D4EAC",
    },
    "&.-completed": {
      color: "white",
    },
  },
  itemDesc: {
    fontSize: "12px",
    lineHeight: "12px",
    color: "#697386",
    transition: "color 0.35s ease-in-out",
    "&.-active": {
      color: "#3D4EAC",
    },
    "&.-completed": {
      color: "white",
    },
  },

  shadowWrapper: {
    overflow: "hidden",
    width: "0px",
    position: "absolute",
    top: 0,
    transition: `width ${TRANSITION}`,
    "&.-visible": {
      width: "455px",
    },
  },

  arrowWrapper: {
    width: TAB_WIDTH,
    height: "56px",
    position: "relative",
  },

  arrow: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #D2D6DC",
    height: "100%",
    overflow: "hidden",
    "&.-active": {
      borderColor: "#7EC4F6",
      backgroundColor: "#E6F5FF",
    },
    "&.-completed": {
      borderColor: "#697286",
      backgroundColor: "#697286",
    },
  },

  fronthex: {
    display: "block",
    filter: "url(#round)",
    transformOrigin: "top right",
    // 2x size and scaled to 50% to preserve the correct border radius
    transform: "scale(0.5)",
    height: "112px",
    width: "112px",
    position: "absolute",
    right: "-16px",
    top: 0,

    "&.-active:before": {
      background: "#7EC4F6",
    },

    "&.-completed:before": {
      background: "#697286",
    },

    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
      background: "#D2D6DC",
      clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%)",
    },
  },
  fronthex2: {
    display: "block",
    filter: "url(#round)",
    transformOrigin: "top right",
    // 2x size and scaled to 50% to preserve the correct border radius
    transform: "scale(0.5)",
    height: "108px",
    width: "108px",
    position: "absolute",
    top: "1px",
    right: "-15px",

    "&.-active:after": {
      backgroundColor: "#E6F5FF",
    },
    "&.-active:before": {
      backgroundColor: "#E6F5FF",
    },

    "&.-completed:after": {
      backgroundColor: "#697286",
    },
    "&.-completed:before": {
      backgroundColor: "#697286",
    },

    "&:after": {
      content: '""',
      display: "block",
      backgroundColor: "white",
      position: "absolute",
      width: "25%",
      height: "100%",
      top: 0,
    },

    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
      background: "white",
      clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%)",
    },
  },

  backWrapper: {
    width: "12px",
    height: "56px",
    position: "absolute",
    left: 0,
    top: 0,
    overflow: "hidden",
  },

  backhex: {
    display: "block",
    width: "112px",
    filter: "url(#round)",
    height: "112px",
    position: "absolute",
    top: 0,
    right: 0,

    transform: "scale(0.5)",
    transformOrigin: "top right",

    "&.-active:before": {
      background: "#7EC4F6",
    },

    "&.-completed:before": {
      background: "#697286",
    },

    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
      background: "#D2D6DC",
      clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%)",
    },
  },

  backhex2: {
    display: "block",
    width: "108px",
    filter: "url(#round)",
    height: "108px",

    position: "absolute",
    top: "1px",
    right: "1px",

    transform: "scale(0.5)",
    transformOrigin: "top right",

    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
      background: "white",
      clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%)",
    },
  },
}));
