import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import { Done } from "@material-ui/icons";
import SVGIcon from "components/svg-icon/svg-icon";

const RequestInformation = ({
  selectedRow: {
    id,
    submit: { first_name: firstName, last_name: lastName, email },
    timestamp,
    status,
    individual_right: {
      regulation: { name: regulationName },
    },
    individual_right_days_left,
  },
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={6} className={classes.itemContainer}>
          <Typography display="inline" className={classes.itemLabel}>
            ID:{" "}
            <Typography
              component="span"
              display="inline"
              className={classes.itemValue}
            >
              {id}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.itemContainer} justify="flex-end">
          <Typography display="inline" className={classes.regulation}>
            <Done className={classes.regulationIcon} />
            {regulationName}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <Typography display="inline" className={classes.itemLabel}>
            From:{" "}
            <Typography
              component="span"
              display="inline"
              className={classes.itemValue}
            >
              {firstName} {lastName}
              <span className={classes.email}>{`<${email}>`}</span>
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.itemContainer}>
          <Typography display="inline" className={classes.itemLabel}>
            Issued:{" "}
            <Typography
              component="span"
              display="inline"
              className={classes.itemValue}
            >
              {moment(timestamp).format("DD/MM/YYYY HH:MM")}
            </Typography>
            <Typography
              component="span"
              display="inline-block"
              className={classes.daysLeft}
            >
              <SVGIcon icon="clock" />
              <span>{`${individual_right_days_left} days left`}</span>
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.itemContainer} justify="flex-end">
          <Typography display="inline" className={classes.itemLabel}>
            Status:{" "}
            <Typography
              component="span"
              display="inline"
              className={classes.itemValue}
            >
              {status.toUpperCase()}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RequestInformation;
