import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 2,
    color: theme.palette.grey[200],
  },
  table: {
    marginTop: 40,
    border: `1px solid ${theme.palette.grey[500]}`,
    width: 500,
    borderTop: "none",
  },
  col1: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: "none",
    borderLeft: "none",
    padding: 5,
    textTransform: "capitalize",
    width: "30%",
  },
  col2: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: "none",
    borderLeft: "none",
    borderRight: "none",
    padding: 5,
    width: "70%",
  },
  userGuide: {
    marginTop: 20,
  },
  paginationContainer: {
    paddingBottom: theme.spacing(5),
  },
  backdrop: {
    position: "absolute",
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  container: {
    overflow: "hidden",
    width: "100%",
  },
  checkbox: {
    margin: theme.spacing(1, 0, 2),
    color: theme.palette.grey[800],
    [theme.breakpoints.up("sm")]: {
      marginLeft: 123,
    },
  },
  dialog: {
    "& > .MuiDialog-scrollPaper": {
      "& > .MuiDialog-paper": {
        width: 370,
        minWidth: "0px !important",
      },
    },
  },
  content: {
    marginBottom: "10px !important",
  },
}));
