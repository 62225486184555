import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: (styles) => ({
    overflow: "auto",
    height: ({ noTitle, hasSubtitle, fullHeight }) =>
      `calc(100vh - 308.5px ${noTitle ? "+ 40px" : ""} ${
        hasSubtitle ? "- 58px" : ""
      } ${fullHeight ? "+ 77px" : ""})`,
    width: "100%",
    ...styles,
    [theme.breakpoints.up("xs")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: ({ noTitle, hasSubtitle, fullHeight }) =>
        `calc(100vh - 308.5px${noTitle ? " + 40px" : ""} ${
          hasSubtitle ? "- 58px" : ""
        } ${fullHeight ? "+ 77px" : ""})`,
    },
  }),
}));

const SectionContent = ({
  children,
  styles,
  className,
  noTitle = false,
  hasSubtitle,
  fullHeight,
}) => {
  const classes = useStyles({ ...styles, noTitle, hasSubtitle, fullHeight });
  return <div className={clsx(classes.container, className)}>{children}</div>;
};

export default SectionContent;
