import { makeStyles } from "@material-ui/core";
import PrincingBackgroud from "assets/illustrations/bg_pricinginapp.svg";

export default makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: theme.spacing(4, 0),
  },
  leftContainer: {
    width: "30%",
    maxWidth: "272px",
    paddingRight: theme.spacing(4),
    borderRight: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(6),
  },
  rightContainer: {
    width: "70%",
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  userProfileWrapper: {
    display: "flex",
    height: "150px",
    flexDirection: "column",
  },
  userProfile: {
    height: "150px",
    padding: theme.spacing(3, 4),
  },
  userProfileInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(1, 0),
  },
  userProfileName: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "1",
    color: theme.palette.blue[700],
    textTransform: "none",
    letterSpacing: 0,
    marginBottom: theme.spacing(0.5),
  },
  userProfilePlan: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1",
    textTransform: "uppercase",
    color: theme.palette.green[200],
  },
  profileWarning: {
    backgroundColor: theme.palette.red[100],
    border: `1px solid ${theme.palette.red[500]}`,
    borderRadius: "4px",
    color: theme.palette.red[500],
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "1.2",
    padding: "0.25rem 0.35rem",
    textAlign: "center",
  },
  inAppPricing: {
    backgroundColor: theme.palette.primary["main"],
    backgroundImage: `url(${PrincingBackgroud})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 200px 1rem 2rem",
    textAlign: "center",
    width: "100%",
    height: "150px",
    "&>h4": {
      fontFamily: "Inter, sans-serif",
      fontSize: 18,
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.44,
      letterSpacing: "normal",
      color: theme.palette.common.white,
      maxWidth: 328,
      textAlign: "left",
      marginRight: theme.spacing(3),
    },
  },
  seePlansBT: {
    backgroundColor: "white",
    color: `${theme.palette.blue["young"]}`,
    fontWeight: "600",
    maxWidth: "130px",
    margin: 0,
    "&:hover": {
      backgroundColor: `${theme.palette.blue["100"]}`,
      color: `${theme.palette.blue[500]}`,
    },
  },
  avatar: {
    width: "auto",
    maxWidth: "100px",
    marginRight: "8px",
    height: "100px",
    boxShadow: "0 10px 10px 0 rgba(8, 20, 78, 0.15)",
    borderRadius: "50%",
  },
}));
