import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    borderBottom: "1px solid #D2D3D6",
    padding: theme.spacing(1.5, 0),
  },
  text: {
    display: "inline-block",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "26px",
    color: theme.palette.grey[800],
    marginRight: ({ margin }) => (margin ? theme.spacing(2) : 4),
  },
  textAfterLink: {
    display: "inline-block",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "26px",
    color: theme.palette.grey[800],
    marginLeft: 4,
  },
  anchor: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "26px",
    textDecorationLine: "underline",
    color: theme.palette.blue["young"],
  },
}));
