import React, { useState } from "react";
import { Slider as MuiSlider } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import isNil from "lodash.isnil";

const SliderInput = ({
  label,
  helperText,
  className = null,
  style = {},
  defaultValue = null,
  options = [],
  value,
  onChange = null,
  onChangeCommitted = () => {},
  ...restProps
}) => {
  const classes = useStyles();

  const [tempValue, setTempValue] = useState(defaultValue || 0);

  const onChangeValue = (_, value) => {
    if (isNil(onChange)) {
      setTempValue(value);
    } else {
      onChange(value);
    }
  };

  return (
    <div className={clsx(classes.inputContainer, className)} style={style}>
      <label className={classes.inputLabel}>{label}</label>
      <MuiSlider
        value={tempValue}
        onChange={onChangeValue}
        onChangeCommitted={(_, v) => onChangeCommitted(tempValue)}
        valueLabelDisplay="auto"
        {...restProps}
      />
      {helperText && <label className={classes.helperText}>{helperText}</label>}
    </div>
  );
};

export default SliderInput;
