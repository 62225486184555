import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import useFetchJson from "hooks/useFetchJson"; // Adjust the path according to your project structure
import { Loading } from "../components/Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#f9f9f9",
    borderRadius: theme.spacing(1),
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "24px",
    color: "#333",
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "18px",
    color: "#555",
  },
  text: {
    fontSize: "16px",
    color: "#666",
    lineHeight: "1.6",
    marginBottom: theme.spacing(1),
  },
  codeContainer: {
    backgroundColor: "#f4f4f4",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: "monospace",
    overflowX: "auto",
  },
  code: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
}));

const FtuView = () => {
  const classes = useStyles();
  const { ftu_id } = useParams();
  const ftuIdToFaqMap = {
    "PII-FAQS": () => import("FTU/PII-FAQS.json"),
    "WEBHOOK-FAQS": () => import("FTU/WEBHOOK-FAQS.json"),
  };

  const importFunction = ftuIdToFaqMap[ftu_id];
  const { data: guideContent, loading, error } = useFetchJson(importFunction);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Typography>Error loading content</Typography>;
  }

  return (
    <Box className={classes.container} style={{ marginTop: 20 }}>
      {guideContent.map((section, index) => (
        <React.Fragment key={index}>
          <Typography className={classes.title}>{section.title}</Typography>
          {section.texts.map((text, i) => (
            <Typography key={i} className={classes.text}>
              {text}
            </Typography>
          ))}
          {section.codeSnippet && (
            <Box className={classes.codeContainer}>
              <Typography className={classes.code}>
                {section.codeSnippet}
              </Typography>
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default FtuView;
