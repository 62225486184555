import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Element from "./element";
import useStyles from "./styles";
import {
  getConsentsOverviewData,
  getOverviewLoadingState,
  getKpis,
} from "redux/_consents/_overview/overview.selectors";
import { connect } from "react-redux";
import { getKpisData } from "redux/_consents/_overview/overview.async.actions";

const KPIs = ({
  data: { policies = [], applications = [], partners = [] },
  loading,
  getKpis,
  kpis: {
    visitors_tracked: visitorsTracked,
    customers_tracked: customersTracked,
    full_consents: fullConsents,
  } = {},
}) => {
  useEffect(() => {
    getKpis();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Element
        name="Visitors Tracked"
        value={visitorsTracked || 0}
        style={{ paddingRight: "32px" }}
        icon="consentsOverview.tracked"
        loading={loading}
      />
      <Element
        name="Customers Tracked"
        value={customersTracked || 0}
        style={{ paddingRight: "32px" }}
        icon="consentsOverview.tracked"
        loading={loading}
      />
      <Element
        name="Full Consents"
        value={fullConsents || 0}
        style={{ paddingRight: "32px" }}
        icon="consentsOverview.full_consents"
        loading={loading}
      />
      <Element
        name="Policies"
        value={policies.length}
        variation={0}
        style={{ paddingRight: "32px" }}
        icon="consentsOverview.policies"
        loading={loading}
      />
      <Element
        name="Applications"
        value={applications.length}
        variation={0}
        style={{ paddingRight: "32px" }}
        icon="consentsOverview.applications"
        loading={loading}
      />
      <Element
        name="Partners"
        value={partners.length}
        variation={0}
        icon="consentsOverview.partners"
        loading={loading}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsOverviewData(state),
  loading: getOverviewLoadingState(state),
  kpis: getKpis(state),
});

const mapDispatchToProps = (dispatch) => ({
  getKpis: () => dispatch(getKpisData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIs);
