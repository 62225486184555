export const services = [
  {
    value: 1,
    label: "Slack",
    id: "slack",
    domain: "slack.com",
  },
  {
    value: 2,
    label: "Salesforce",
    id: "salesforce",
    domain: "salesforce.com",
  },
  {
    value: 3,
    label: "Microsoft Teams",
    id: "micTeams",
    domain: "teams.microsoft.com",
  },
  {
    value: 4,
    label: "Microsoft Exchange",
    id: "micExchange",
    domain: "exchange.microsoft.com",
  },
  {
    value: 5,
    label: "Jira",
    id: "jira",
    domain: "jira.atlassian.com",
  },
  {
    value: 13,
    label: "Zendesk",
    id: "zendesk",
    domain: "zendesk.com",
  },
  {
    value: 14,
    label: "Trello",
    id: "trello",
    domain: "trello.com",
  },
  {
    value: 15,
    label: "Asana",
    id: "asana",
    domain: "asana.com",
  },
  {
    value: 16,
    label: "Monday.com",
    id: "monday",
    domain: "monday.com",
  },
  {
    value: 6,
    label: "Confluence",
    id: "confluence",
    domain: "confluence.atlassian.com",
  },
  {
    value: 7,
    label: "Google Drive",
    id: "googleDrive",
    domain: "drive.google.com",
  },
  {
    value: 8,
    label: "Dropbox",
    id: "dropbox",
    domain: "dropbox.com",
  },
  {
    value: 9,
    label: "Box",
    id: "box",
    domain: "box.com",
  },
  {
    value: 17,
    label: "OneDrive",
    id: "oneDrive",
    domain: "onedrive.com",
  },
  {
    value: 10,
    label: "Intercom",
    id: "intercom",
    domain: "intercom.com",
  },
  {
    value: 20,
    label: "LiveChat",
    id: "livechat",
    domain: "livechatinc.com",
  },
  {
    value: 11,
    label: "MailChimp",
    id: "mailchimp",
    domain: "mailchimp.com",
  },
  {
    value: 12,
    label: "SendGrid",
    id: "sendgrid",
    domain: "sendgrid.com",
  },
  {
    value: 18,
    label: "Bitbucket",
    id: "bitbucket",
    domain: "bitbucket.atlassian.com",
  },
  {
    value: 19,
    label: "GitHub",
    id: "github",
    domain: "github.com",
  },
  {
    value: 23,
    label: "GitLab",
    id: "gitlab",
    domain: "gitlab.com",
  },
  {
    value: 21,
    label: "DocuSign",
    id: "docusign",
    domain: "docusign.com",
  },
  {
    value: 22,
    label: "HelloSign",
    id: "hellosign",
    domain: "hellosign.com",
  },
];
