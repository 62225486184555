import React, { useCallback } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import dateParsingUtils from "helpers/dateParsingUtils";
import repoProviderUtils from "helpers/repoProviderUtils";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import useDownload from "hooks/useDownload";
import LearnMoreLink from "../../ftu/components/LearnMoreLink";
import WebhookBadge from "../../components/detail/WebhookBadge";
import { Directions } from "@material-ui/icons";

const textStyle = {
  fontFamily: "Inter",
  fontSize: 14,
  color: "#000000",
};

export const BranchScanSummary = () => {
  const chartData = useSelector((state) => state.dataFlow.chartFlow)?.chartInfo;
  const {
    downloadFile,
    loading: downloadLoading,
    error: downloadError,
  } = useDownload();

  const handleDownloadCSV = useCallback(() => {
    const fileName = "pii_results.csv";
    const url = chartData?.resultsCSV;
    downloadFile(url, fileName);
  }, [chartData?.resultsCSV, downloadFile]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 4,
        padding: 16,
        alignItems: "center",
        background: "#F0F0F0",
      }}
    >
      <Box
        style={{ display: "flex", flexDirection: "column", gap: 4, flex: 1 }}
      >
        <Box style={{ display: "flex", gap: 8 }}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 900,
              color: "#466DE8",
              fontFamily: "Inter",
            }}
          >
            {dateParsingUtils.getFormatedDateWithYearAndTime(
              chartData?.created
            )}
          </Typography>
          <WebhookBadge triggeredByWebhook={chartData?.triggered_by_webhook} />
        </Box>
        <Typography
          style={{
            color: "#000000",
            fontFamily: "Inter",
            fontWeight: 900,
            fontSize: 16,
          }}
        >
          {chartData?.branchName}
        </Typography>
        <Typography
          style={{
            color: "#000000",
            fontFamily: "Inter",
            fontSize: 16,
          }}
        >
          {repoProviderUtils.getRepositoryShortNameByUrl(chartData?.repository)}
        </Typography>
        <Box
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16,
              alignItems: "center",
            }}
          >
            <Typography
              style={textStyle}
            >{`Low: ${chartData?.lowSeverity}`}</Typography>
            <Typography
              style={textStyle}
            >{`Medium: ${chartData?.mediumSeverity}`}</Typography>
            <Typography
              style={textStyle}
            >{`Leakage: ${chartData?.leakage}`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Button
          onClick={handleDownloadCSV}
          variant="contained"
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "Inter",
            color: "#1B3586",
            backgroundColor: "#6BD4CA",
            fontSize: 14,
            fontWeight: "700",
            paddingTop: 8,
            paddingBottom: 8,
            cursor: "pointer",
          }}
          endIcon={<ArrowDownwardIcon />}
        >
          Download CSV
        </Button>
        <LearnMoreLink />
      </Box>
    </Box>
  );
};
