import React, { useMemo } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Title from "pages/AppReport/common/Title";
import FieldDetail from "pages/SecurityReport/common/FieldDetail";
import { Card, Tabs } from "pages/SecurityReport/common";
import ReactHtmlParser from "react-html-parser";
import Subtitle from "../Subtitle";
import Table from "pages/AppReport/common/Table";
import SectionContent from "pages/AppReport/common/SectionContent";
import useStyles from "./styles";
import clsx from "clsx";

const getFieldValues = (data) => {
  // NEW FORMAT
  // Check if data is in new format
  if (data.headers && data.rows) {
    let resp = [];
    for (let i = 0; i < data.headers.length; i++) {
      let header = `<b>${data.headers[i]}</b>`;
      for (let row of data.rows) {
        resp.push({ id: header, value: row[i] });
      }
    }

    return resp;
  }

  // OLD FORMAT
  if (!data) return [];
  const resp = data[0].map((id, idx) => ({ id, value: data[1][idx] }));
  return resp;
};

const formatTable = (data, classes = {}) => {
  // NEW FORMAT
  // Check if data is in new format
  if (data.headers && data.rows) {
    const keys = data.headers.map((header) =>
      header.toLowerCase().replace(" ", "_")
    );

    return {
      columns: keys.map((key, idx) => ({
        key,
        label: <b>{data.headers[idx]}</b>,
        width: `${100 / keys.length}%`,
      })),
      nextData: data.rows.map((row) => {
        const nextElement = {};
        row.forEach((value, idx) => {
          nextElement[keys[idx]] = (
            <div className={classes.cell}>{ReactHtmlParser(value)}</div>
          );
        });
        return nextElement;
      }),
    };
  }

  // OLD FORMAT
  const [_, ...values] = data;
  const keys = _.map((i) =>
    ReactHtmlParser(i)[0].props?.children[0].toLowerCase().replace(" ", "_")
  );
  console.log({ _, values });

  const resp = {
    columns: keys.map((key, idx) => ({
      key,
      label: ReactHtmlParser(_[idx]),
      width: `${100 / keys.length}%`,
    })),
    nextData: values.map((el) => {
      const nextElement = {};
      el.forEach((i, idx) => {
        nextElement[keys[idx]] = (
          <div className={classes.cell}>{ReactHtmlParser(i)}</div>
        );
      });
      return nextElement;
    }),
  };

  return resp;
};

const FindingSection = ({ goToNextSectionAction, sectionContent, data }) => {
  const classes = useStyles();

  const fieldValues = useMemo(() => getFieldValues(data?.vuln_evidence?.data), [
    data,
  ]);

  const pageId = useMemo(() => data?.name.replace(" ", "_"), [data]);

  const { columns, nextData } = useMemo(
    () => formatTable(data?.vuln_evidence?.data || [[], []], classes),
    [data]
  );

  return (
    <Page
      key={pageId}
      footerBackgroundColor={sectionContent.backgroundColor}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={sectionContent.description}>{sectionContent.title}</Title>
      <Subtitle
        confirmed={data.confirmed}
        status={data.risk_level === "0" ? "confirm" : "warning"}
      >
        {data?.name}
      </Subtitle>
      <SectionContent hasSubtitle>
        {nextData.length > 1 && (
          <Table data={nextData} limitPerPage={10} columns={columns} />
        )}
        {nextData.length === 1 && fieldValues.length && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginBottom: "37px",
            }}
          >
            {fieldValues.map((field) => (
              <FieldDetail
                field={{
                  title: ReactHtmlParser(field.id),
                  value: ReactHtmlParser(field.value),
                }}
              />
            ))}
          </div>
        )}
        <Tabs
          id={`${pageId}-details-info`}
          classes={{
            root: clsx(classes.tabs, "scrollable-element"),
          }}
          tabs={[
            {
              id: "risk",
              label: "RISK",
              hide: !data?.risk_description,
              content: (
                <Card title="Risk description">
                  {ReactHtmlParser(data?.risk_description)}
                </Card>
              ),
            },
            {
              id: "recommendation",
              label: "RECOMMENDATION",
              hide: !data?.recommendation,
              content: (
                <Card title="Recommendation">
                  {ReactHtmlParser(data?.recommendation)}
                </Card>
              ),
            },
            {
              id: "references",
              label: "REFERENCES",
              hide: !data?.vuln_references,
              content: (
                <Card title="References">
                  <ul>
                    {data?.vuln_references?.map((reference) => (
                      <li>
                        {reference.includes("http") ? (
                          <a href={reference} target="_blank">
                            {reference}
                          </a>
                        ) : (
                          reference
                        )}
                      </li>
                    ))}
                  </ul>
                </Card>
              ),
            },
            {
              id: "classification",
              label: "CLASSIFICATION",
              hide:
                (!data?.cwe || data?.cwe === "N/A") &&
                (!data?.owasp2013 || data?.owasp2013 === "N/A") &&
                (!data?.owasp2017 || data?.owasp2017 === "N/A"),
              content: (
                <Card title="Classification">
                  {data?.cwe && (
                    <>
                      CWE:{" "}
                      <a
                        href={`https://cwe.mitre.org/data/definitions/${
                          data.cwe.split("-")[1]
                        }.html`}
                        target="_blank"
                      >
                        {data.cwe}
                      </a>
                      <br />
                    </>
                  )}
                  {data?.owasp2013 && (
                    <>
                      OWASP Top 10 - 2013:{" "}
                      <a
                        href="https://owasp.org/www-pdf-archive/OWASP_Top_10_-_2013.pdf#page=11"
                        target="_blank"
                      >
                        {data.owasp2013}
                      </a>
                      <br />
                    </>
                  )}
                  {data?.owasp2017 && (
                    <>
                      OWASP Top 10 - 2017:{" "}
                      <a
                        href={`https://owasp.org/search/?searchString=${data.owasp2017}%202017`}
                        target="_blank"
                      >
                        {data.owasp2017}
                      </a>
                      <br />
                    </>
                  )}
                </Card>
              ),
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default FindingSection;
