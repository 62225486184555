import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  getConsentsMapData,
  getMapLoadingState,
} from "redux/_consents/_map/map.selectors";
import { getMapChartData } from "redux/_consents/_map/map.async.actions";
import Map from "charts/TreeMap/GeoDistribution";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "charts/Consents/Map/Tooltip";
import {
  getFilterConsentCustomerVisibility,
  getFilterConsentDecision,
} from "redux/_filters/filters.selectors";
import Loader from "components/Loader";
import { data as mockedData } from "./mocked.json";
import isEmpty from "lodash.isempty";
import EmptyOverlay from "components/EmptyOverlay";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: ({ onlyMap }) => (onlyMap ? "100%" : "calc(100% - 82px)!important"),
    marginBottom: ({ onlyMap }) => (onlyMap ? 0 : theme.spacing(4)),
  },
  text: {
    margin: "1rem 0",
  },
}));

const GeoDistribution = ({
  data,
  loading,
  getData,
  customerVisibilityFilter,
  decisionFilter,
  onlyMap,
  disabledFilters = false,
}) => {
  useEffect(() => {
    getData(disabledFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerVisibilityFilter, decisionFilter]);

  const total = useMemo(
    () =>
      isEmpty(data)
        ? mockedData.reduce((prev, curr) => prev + curr.decision, 0)
        : data.reduce((prev, curr) => prev + curr.decision, 0),
    [data]
  );
  const classes = useStyles({ onlyMap });

  if (loading) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    );
  }
  if (!data) return null;
  return (
    <div className={classes.container}>
      {!onlyMap && (
        <Typography className={classes.text}>
          Distribution of Consents across the world.
        </Typography>
      )}
      <EmptyOverlay
        text="No Consents collected."
        link="/zen/consents/collector"
        show={isEmpty(data)}
      >
        <Map
          relative
          data={
            !isEmpty(data)
              ? data.map((d) => ({
                  ...d,
                  full_name: d.name,
                  name: d.id,
                  total: d.decision,
                  value: total ? (d.decision / total) * 100 : 0,
                  tooltipLabel: `${d.decision}/${total}`,
                  decisionFilter,
                }))
              : mockedData.map((d) => ({
                  ...d,
                  full_name: d.name,
                  name: d.id,
                  total: d.decision,
                  value: total ? (d.decision / total) * 100 : 0,
                  tooltipLabel: `${d.decision}/${total}`,
                }))
          }
          colors={["#cfdff8", "#a4bef1", "#7090d9", "#4666b6", "#1a3686"]}
          strokes={false}
          margin={{ right: onlyMap ? 0 : 250 }}
          withChart={!onlyMap}
          CustomTooltip={Tooltip}
        />
      </EmptyOverlay>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsMapData(state),
  loading: getMapLoadingState(state),
  customerVisibilityFilter: getFilterConsentCustomerVisibility(state),
  decisionFilter: getFilterConsentDecision(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (disableFilters) => dispatch(getMapChartData(disableFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoDistribution);
