import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import ScrollSVGContainer from "../SVGContainer/ScrollContainer";
import SVGContainer from "../SVGContainer/SVGContainer";
import ColorReference from "./ColorReference/ColorReference";
import TreeHeader from "./Tree/TreeHeader";
import Tree from "./Tree/TreeImpl";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "calc(100vh - 144px)",
    paddingTop: theme.spacing(3),
  },
  chartContainer: {
    width: "calc(100% - 10px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  chartHeader: {
    height: 65,
  },
}));

const DataStructure = ({ data, colors, disableScrolling, wider }) => {
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  if (!data) return null;

  return (
    <Box className={classes.container}>
      <Box
        className="scrollable-element"
        style={{ width: "100%", height: "100%", display: "flex" }}
      >
        <Box className={classes.chartContainer}>
          {!wider && (
            <SVGContainer
              classes={{ container: classes.chartHeader }}
              geometricPresicion
              disableScrolling
            >
              <TreeHeader data={data} colors={colors} selected={selected} />
              <ColorReference colors={colors} position="top" />
            </SVGContainer>
          )}
          <ScrollSVGContainer
            disableScrolling={disableScrolling}
            className="datamap"
            geometricPresicion
          >
            <Tree
              data={data}
              colors={colors}
              selected={selected}
              onSelect={setSelected}
              wider={wider}
            />
          </ScrollSVGContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default DataStructure;
