import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  dialog: {
    maxHeight: 850,
    overflow: "visible",
    [theme.breakpoints.up("md")]: {
      minWidth: 670,
    },
  },
  closeButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.aquamarine[100],
    width: theme.spacing(5),
    height: theme.spacing(5),
    top: theme.spacing(-2.5),
    right: theme.spacing(-2.5),
    position: "absolute",
    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(3),
    "& h2": {
      lineHeight: 1.2,
      fontFamily: "Aldrich",
      fontSize: 40,
      fontStyle: "normal",
      fontWeight: 400,
      color: theme.palette.blue[500],
      textTransform: "none",
      [theme.breakpoints.up("xs")]: {
        width: "80%",
        margin: "0 auto",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
  },
  content: {
    padding: theme.spacing(1, 6),
    marginBottom: theme.spacing(3),
    textAlign: "center",
    ...theme.typography.body1,
  },
  actions: {
    padding: theme.spacing(3),
  },
}));
