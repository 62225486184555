import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  itemLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grey[200],
    textTransform: "uppercase",
    width: "86px",
    flexShrink: 0,
    paddingRight: theme.spacing(2),
  },
  messageContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
  },
  messageValue: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: theme.palette.grey[200],
    paddingBottom: theme.spacing(2),
  },
  response: {
    display: "flex",
    flexDirection: "column",
  },
}));
