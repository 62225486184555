import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    padding: "24px 0",
    maxWidth: "1100px",
  },
  searchBar: {
    marginBottom: theme.spacing(4),
  },

  componentTitle: {
    color: "#333",
    fontSize: "18px",
    fontWeight: "600",
    letterSpacing: "2px",
    marginBottom: "16px",
    textTransform: "uppercase",
  },
  InfoOutlinedScan: {
    marginLeft: "8px",
    padding: "0",
    "&>span>svg": {
      fill: "#7090d9",
    },
  },
  row: {
    cursor: "pointer",
    "&>td": {
      fontFamily: "Roboto Condensed",
      fontSize: "14px",
      fontColor: theme.palette.grey[800],
    },
  },
  selected: {
    backgroundColor: `${theme.palette.blue[500]} !important`,
    "&:hover": {
      backgroundColor: theme.palette.blue[600],
      "&>td": {
        backgroundColor: theme.palette.blue[500],
      },
    },
    "&>td": {
      color: "white",
      fontWeight: "bold",
    },
  },
  previewContainer: {
    border: "solid 2px #828282;",
    borderRadius: "10px",
  },
  previewText: {
    color: "#686c78",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "14px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  previewSite: {
    color: "#3f51b5",
    fontSize: "20px",
    fontStyle: "normal",
    marginTop: "8px",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "16px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    border: "1px solid #828282",
    borderRadius: "5px",
  },
  tableHeadCell: {
    // background: '#DFDFDF',
  },

  emptyContainer: {
    minHeight: "98px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
