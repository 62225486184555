import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    "&>div": {
      backgroundColor: "rgba(8, 20, 78, 0.5)",
      "&>div": {
        borderRadius: "6px",
      },
    },
  },
  container: {
    backgroundColor: "rgba(207, 223, 248, 0.3)",
    padding: theme.spacing(4),
    width: "100%",
    height: "100%",
  },
  content: {
    width: "60vw",
    minWidth: "100%",
    height: "50vh",
    backgroundColor: "white",
    border: "solid 1px #cfdff8",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5),
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>svg": {
      height: "24px",
    },
  },
  stepperContainer: {
    backgroundColor: "transparent",
  },
  stepLabel: {
    color: "#2e384d",
    fontSize: "14px",
    lineHeight: 1.43,
    fontWeight: 500,
  },
}));

export const useStepStyles = makeStyles((theme) => ({
  container: {
    width: "40px",
    height: "40px",
    borderRadius: "28px",
    backgroundColor: "#cfdff8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>svg": {
      width: "16px",
      height: "auto",
      "&>path": {
        fill: theme.palette.blue["young"],
      },
    },
  },
  active: {
    border: `solid 2px ${theme.palette.blue["young"]}`,
  },
  completed: {
    backgroundColor: theme.palette.blue["young"],
    "&>svg": {
      "&>path": {
        fill: "white",
      },
    },
  },
}));
