import {
  requestStart,
  requestEnd,
  saveData,
  updateData,
  deleteData,
} from "redux/_databases/databases.actions";
import { getDatabasesData } from "redux/_databases/databases.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import {
  createDb,
  updateDb,
  getDbs,
  getAllDbs,
  deleteDb,
} from "redux/_databases/databases.service";
import { getInitialSetupData } from "redux/_settings/settings.async.actions";
import isEmpty from "lodash.isempty";

export const getDatabases = () => (dispatch, getState) => {
  const envId = getSelectedEnvId(getState());
  dispatch(requestStart());
  return getDbs(envId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const getAllDatabases = () => (dispatch, getState) => {
  const databases = getDatabasesData(getState());
  if (isEmpty(databases)) {
    dispatch(requestStart());
    return getAllDbs()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve(data);
      })
      .catch((errors) => {
        dispatch(requestEnd());
        return Promise.reject(errors);
      });
  }
};

export const createDatabase = (data) => (dispatch) => {
  dispatch(requestStart());
  return createDb({ environment_id: null, ...data })
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData([data]));
      dispatch(getInitialSetupData());
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const updateDatabase = (data) => (dispatch) => {
  dispatch(requestStart());
  return updateDb(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(updateData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const deleteDatabase = (id) => (dispatch) => {
  dispatch(requestStart());
  return deleteDb(id)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(deleteData(id));
      dispatch(getInitialSetupData());
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
