import scansConstants from "redux/_scans/scans.constants";
import getProperty from "utils/get-property";
import mapArrayToObject from "utils/map-array-to-object";

const initialState = {};

export const scansOverview = (state = initialState, action) => {
  const { envId } = action;
  const id = !!envId ? envId : "all";
  const envOverview = getProperty(state, `${envId}`) || {};

  switch (action.type) {
    case scansConstants.GET_OVERVIEW_REQUEST:
      return {
        ...state,
        [id]: {
          ...envOverview,
          loading: true,
        },
      };

    case scansConstants.GET_OVERVIEW_SUCCESS:
      const { scans } = action;

      return {
        ...state,
        [id]: {
          loading: false,
          scansHash: {
            ...mapArrayToObject("id", scans),
          },
        },
      };

    case scansConstants.GET_OVERVIEW_FAILURE:
      const { errors } = action;
      return {
        ...state,
        [id]: {
          ...envOverview,
          loading: false,
          errors,
        },
      };
    default:
      return state;
  }
};

// const _extractTimeRange = (scans, query) => {
//   const {from, to} = query;

//   if (!!from && !!to) {
//     return {from, to};
//   }

//   const orderedScans = scans.sort((scan1, scan2) => scan1.ended_at < scan2.ended_at);
//   return {
//     from: orderedScans[orderedScans.length-1].ended_at.toISOString(),
//     to: orderedScans[0].ended_at.toISOString(),
//   }
// }
