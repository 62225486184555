import React from "react";

const CardIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#3F51B5"
      fillRule="evenodd"
      d="M2.818 3h18.364C22.186 3 23 3.895 23 5v12c0 1.105-.814 2-1.818 2H2.818C1.814 19 1 18.105 1 17V5c0-1.105.814-2 1.818-2zM3 5v12h18V5H3zm2 8v2h2v-2H5zm3 2v-2h3v2H8zm9-2v2h2v-2h-2zm-3 2v-2h2v2h-2zM8 8H5v3h3V8z"
      clipRule="evenodd"
    />
  </svg>
);

export default CardIcon;
