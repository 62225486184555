import React from "react";

import Login from "components/login/login.container";
import LoginLayout from "components/login/LoginLayout";

const SignInSide = () => {

  return (
    <LoginLayout
      title="Welcome back"
      message="Complete the following form to log in."
      form={<Login />}
    />
  );
};

export default SignInSide;
