import React, { PureComponent } from "react";
import Tooltip from "./Tooltip";
import PermissionTooltip from "./TableOwnersTooltip";
// import mergePiiGroups from 'utils/mergePiiGroups';

class BurstPath extends PureComponent {
  onClick = () => {
    const { node, onClick } = this.props;
    if (onClick) onClick(node);
  };

  getLabel = () => {
    const { node } = this.props;
    let name;
    try {
      if (node.data.name.length > 9) {
        name = node.data.name.slice(0, 7) + "...";
      } else {
        name = node.data.name;
      }
    } catch (e) {
      name = node.data.name;
    }
    return name;
  };

  render() {
    const { node } = this.props;
    const element = (
      <g
        cursor={"pointer"}
        onClick={this.onClick}
        opacity={node.opacity}
        transform={`rotate(${node.t0})`}
      >
        <path
          key={node.key}
          stroke={node.data.type === "database" ? "#828282" : node.color}
          strokeWidth={1}
          d={node.arc}
          fill={node.selected ? "#fff" : node.color}
          fillOpacity={node.data.type === "database" ? 1 : 0.1}
          strokeOpacity={1}
          style={{ transition: "none" }}
        />
        {node.labelShow && (
          <g transform={`rotate(${node.labelRotation})`}>
            <g
              transform={`translate(${node.labelOffset}, ${0}) ${
                node.labelInvert ? "rotate(180)" : ""
              }`}
            >
              <text
                fontWeight={"bold"}
                fontSize={10}
                fill={node.data.type === "database" ? "#828282" : node.color}
                dominantBaseline="middle"
                textAnchor={node.selected ? "middle" : "start"}
              >
                {this.getLabel()}
              </text>
            </g>
          </g>
        )}
      </g>
    );

    if (node.data.type === "table" && node.data.children) {
      // Uncomment to show grouped pii_groups information tooltip
      // const data = { ...node.data, children: mergePiiGroups(node.data) };
      // return <Tooltip data={data}>{element}</Tooltip>
      return <PermissionTooltip data={node.data}>{element}</PermissionTooltip>;
    } else if (node.data.type === "column" && node.data.children) {
      return <Tooltip data={node.data}>{element}</Tooltip>;
    } else {
      return element;
    }
  }
}

export default BurstPath;
