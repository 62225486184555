import {
  getAll,
  create,
  update,
  deletePolicyData,
  updateTablePolicy,
} from "redux/_consents/_policies/policies.service";
import {
  requestStart,
  requestEnd,
  saveData,
  deleteData,
} from "redux/_consents/_policies/policies.actions";
import { setTablePolicy } from "redux/_settings/settings.actions";
import { getDatabaseSettingsSelected } from "redux/_settings/settings.selectors";
import isEmpty from "lodash.isempty";
import { getConsentsPoliciesData } from "redux/_consents/_policies/policies.selectors";

export const getPoliciesData = (force, widgetToken) => (dispatch, getState) => {
  const policies = getConsentsPoliciesData(getState());
  if (force || isEmpty(policies)) {
    dispatch(requestStart());
    return getAll(widgetToken)
      .then((data) => {
        dispatch(saveData(data));
        dispatch(requestEnd());
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const createPolicy = (data) => (dispatch) => {
  dispatch(requestStart());
  return create(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const updatePolicy = (id, data) => (dispatch) => {
  dispatch(requestStart());
  return update(id, data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const deletePolicy = (id) => (dispatch) => {
  dispatch(requestStart());
  return deletePolicyData(id)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(deleteData(id));
      return Promise.resolve();
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const assignPolicy = (tableId, policyId) => (dispatch, getState) => {
  const dbId = getDatabaseSettingsSelected(getState());
  dispatch(requestStart());
  return updateTablePolicy(dbId, tableId, policyId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(setTablePolicy(tableId, policyId));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
