export default {
  GETSOME_REQUEST: "SCAN_GETSOME_REQUEST",
  GETSOME_SUCCESS: "SCAN_GETSOME_SUCCESS",
  GETSOME_FAILURE: "SCAN_GETSOME_FAILURE",
  GETSOME_DONE: "GETSOME_DONE",

  GETLASTTWO_REQUEST: "GETLASTTWO_REQUEST",
  GET_LAST_TWO_SUCCESS: "GET_LAST_TWO_SUCCESS",
  GET_LAST_TWO_FAILURE: "GET_LAST_TWO_FAILURE",

  GET_OVERVIEW_REQUEST: "GET_OVERVIEW_REQUEST",
  GET_OVERVIEW_SUCCESS: "GET_OVERVIEW_SUCCESS",
  GET_OVERVIEW_FAILURE: "GET_OVERVIEW_FAILURE",

  SCANS_SAVE: "SCANS_SAVE",
  SCANS_REQUEST_START: "SCANS_REQUEST_START",
  SCANS_REQUEST_END: "SCANS_REQUEST_END",
  SCANS_SET_LAST: "SCANS_SET_LAST",
  SCANS_SET_LIST: "SCANS_SET_LIST",
  SCANS_SET_IN_PROGRESS: "SCANS_SET_IN_PROGRESS",

  SCAN_NAMESPACE: "scans",

  SCANS_FAILED_STATUS: "FAILED",
  SCANS_SUCCESS_STATUS: "SUCCESS",
  SCAN_IN_PROGRESS_STATUS: "IN_PROGRESS",
};
