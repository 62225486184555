import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import LineChartScansTrend from "../../charts/LineCharts/ScansTrend";
import moment from "moment";
import BarChartScansTrend from "../../charts/BarCharts/ScansTrend";
import useStyles from "./styles";

const mapScan = (
  {
    started_at,
    _piiPercent,
    _unprotectedPercent,
    total_unprotected,
    total_matches,
    total_rows,
  },
  idx
) => ({
  key: idx,
  piiPercent: _piiPercent,
  unprotected: _unprotectedPercent,
  allPii: total_matches,
  allUnprotected: total_unprotected,
  allRows: total_rows,
  date: moment(started_at).format("DD/MM"),
});

const PiiTrends = (props) => {
  const classes = useStyles();
  const { scans } = props;
  const data = useMemo(() => scans.map(mapScan), [scans]);
  return (
    <Box className={classes.container}>
      <Typography display="inline">
        <Typography variant="h4" display="inline">
          PII TRENDS
        </Typography>
        <Typography display="inline" className={classes.subtitle}>
          % of Snapshot
        </Typography>
      </Typography>
      <Box className={classes.dataContainer}>
        <LineChartScansTrend data={data} />
        <Box className={classes.refsContainer}>
          <Box className={classes.refsDataContainer}>
            <Box className={classes.refContainer}>
              <Box
                style={{ backgroundColor: "#219653" }}
                className={classes.dot}
              />
              <Typography className={classes.refText}>All PIIS</Typography>
            </Box>
            <Box className={classes.refContainer}>
              <Box
                style={{ backgroundColor: "#EB5757" }}
                className={classes.dot}
              />
              <Typography className={classes.refText}>Unprotected</Typography>
            </Box>
            <Box className={classes.refContainer}>
              <Box
                style={{ backgroundColor: "#8c1616" }}
                className={classes.dot}
              />
              <Typography className={classes.refText}>
                % Unprotected threshold
              </Typography>
            </Box>
          </Box>
        </Box>
        <BarChartScansTrend data={data} />
      </Box>
    </Box>
  );
};

export default PiiTrends;
