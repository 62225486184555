import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DeleteButton from "components/Consents/Collector/Policies/components/deleteButton";
import {
  FormCheckInput,
  FormSelectInput,
  FormTextInput,
} from "components/SettingsSections/components/inputs";
import isNil from "lodash.isnil";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import {
  createApplication,
  deleteApplication,
  updateApplication,
} from "redux/_consents/_applications/applications.async.actions";
import { getApplicationsLoadingState } from "redux/_consents/_applications/applications.selectors";
import {
  getConsentsConfig,
  getConsentsLanguages,
} from "redux/_consents/_config/config.selectors";
import { getPoliciesData } from "redux/_consents/_policies/policies.async.actions";
import { getConsentsPoliciesData } from "redux/_consents/_policies/policies.selectors";
import useStyles from "./applicationFormStyles";

const ApplicationForm = (props) => {
  const classes = useStyles();

  const {
    id,
    data,
    languages,
    onCancel,
    create,
    update,
    deletePolicyData,
    policies = [],
    getPolicies,
    loading,
    widgetToken,
  } = props;

  useEffect(() => {
    getPolicies(true, widgetToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [language, setLanguage] = useState(languages[0]);
  const [nameIsEmptyErr, setNameIsEmptyErr] = useState(false);
  const [descriptionIsEmptyErr, setDescriptionIsEmptyErr] = useState(false);
  const [titleIsEmptyErr, setTitleIsEmptyErr] = useState(false);

  const onSubmit = async ({
    name,
    description,
    title,
    policy_belong_to_id,
    active_by_default,
    mandatory,
    executed_only_once,
    privacy_policy_link,
    cookies,
  }) => {
    const values = {
      name,
      description,
      title,
      policy_belong_to_id,
      active_by_default,
      mandatory,
      executed_only_once,
      privacy_policy_link,
      cookies,
      widget_token: widgetToken,
    };
    if (!id) {
      try {
        await create(values).then((_) => onCancel());
      } catch (e) {
        // Check if any of title, description and name inputs are empty, and assign error to that input
        if (!values.title) setTitleIsEmptyErr(true);
        if (!values.description) setDescriptionIsEmptyErr(true);
        if (!values.name) setNameIsEmptyErr(true);

        return e;
      }
    } else {
      try {
        await update(id, values).then((_) => onCancel());
      } catch (e) {
        const errors = e?.error?.message;

        if (errors) {
          // Transform the error structure to be compatible with final-form
          let formattedErrors = {};
          Object.keys(errors).forEach((key) => {
            // Assign the error message to the corresponding field
            formattedErrors[key] = errors[key].join(", "); // Join messages if there are multiple
          });
          return formattedErrors; // This will be passed to the form's error state
        }

        return e;
      }
    }
  };

  const onDeleteClick = () => deletePolicyData(id).then((_) => onCancel());

  // Fix to send empty values instead of undefined on submit
  const identity = (value) => value;

  return (
    <Box className={classes.container}>
      <Box className={classes.boxHeader}>
        <Typography className={classes.requiredText}>
          All fields marked <span className={classes.asterisk}>*</span> are
          required.
        </Typography>
        <IconButton onClick={onCancel} className={classes.closeButton}>
          <Close />
        </IconButton>
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.formContent}>
              <Box className={classes.content}>
                <Box className={classes.leftFields}>
                  <Field
                    name="name"
                    component={FormTextInput}
                    label="Name *"
                    placeholder="Name of the Application"
                    disabled={loading}
                    parse={identity}
                    isEmptyErr={nameIsEmptyErr}
                    setIsEmptyErr={setNameIsEmptyErr}
                  />

                  <Box style={{ position: "relative" }}>
                    <Field
                      name="description"
                      component={FormTextInput}
                      label="Description *"
                      placeholder="Short description"
                      multiline={true}
                      rowsMax="9"
                      rows="9"
                      InputProps={{
                        style: { padding: "8px calc(40px + 1rem) 8px 1rem" },
                      }}
                      disabled={loading}
                      style={{ marginTop: 8 }}
                      parse={identity}
                      isEmptyErr={descriptionIsEmptyErr}
                      setIsEmptyErr={setDescriptionIsEmptyErr}
                    />
                    {languages.length > 0 && (
                      <ToggleButtonGroup
                        value={language}
                        exclusive
                        onChange={(e, val) => {
                          if (!isNil(val)) setLanguage(val);
                        }}
                        className={classes.toggleGroup}
                      >
                        {languages.map((key, idx) => {
                          const isLast = idx + 1 === languages.length;
                          const isFirst = idx === 0;
                          return (
                            <ToggleButton
                              value={key}
                              key={idx}
                              disabled={loading}
                              classes={{
                                root: classes.toggleButton,
                                label: classes.toggleButtonLabel,
                              }}
                              style={{
                                borderTopRightRadius: isFirst ? 4 : 0,
                                borderBottomRightRadius: isLast ? 4 : 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                            >
                              {key}
                            </ToggleButton>
                          );
                        })}
                      </ToggleButtonGroup>
                    )}
                  </Box>
                </Box>
                <Box className={classes.rightField}>
                  <Field
                    name="title"
                    component={FormTextInput}
                    label="Title *"
                    placeholder="Title of the Application"
                    disabled={loading}
                    parse={identity}
                    isEmptyErr={titleIsEmptyErr}
                    setIsEmptyErr={setTitleIsEmptyErr}
                    isTitle={true}
                  />

                  <Field
                    name="policy_belong_to_id"
                    component={FormSelectInput}
                    label="Policy that belongs to"
                    style={{ marginTop: 8 }}
                    placeholder="Select one"
                    options={policies.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    disabled={loading}
                    parse={identity}
                  />

                  <Field
                    name="active_by_default"
                    style={{ marginTop: 8 }}
                    component={FormCheckInput}
                    text="Application is Active by default."
                    disabled={loading}
                    type="checkbox"
                    parse={identity}
                  />

                  <Field
                    name="mandatory"
                    component={FormCheckInput}
                    text="Application is Mandatory and can’t be opted-out."
                    disabled={loading}
                    type="checkbox"
                    parse={identity}
                  />

                  <Field
                    name="executed_only_once"
                    component={FormCheckInput}
                    text="Application will be executed only once."
                    disabled={loading}
                    type="checkbox"
                    parse={identity}
                  />
                </Box>
              </Box>
              <Field
                name="privacy_policy_link"
                component={FormTextInput}
                label="privacy policy link"
                placeholder="Link to the application’s privacy policy"
                disabled={loading}
                parse={identity}
              />
              <Field
                name="cookies"
                component={FormTextInput}
                label="cookies"
                placeholder="Cookie config"
                style={{ marginTop: 8 }}
                disabled={loading}
                parse={identity}
              />
            </Box>
            <Box className={classes.buttonsContainer}>
              {!isNil(id) && (
                <DeleteButton
                  disabled={loading}
                  name={data.title}
                  onClick={onDeleteClick}
                />
              )}
              <Button
                disabled={loading}
                className={classes.updateButton}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  policies: getConsentsPoliciesData(state),
  languages: getConsentsLanguages(state),
  loading: getApplicationsLoadingState(state),
  widgetToken: getConsentsConfig(state).token,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createApplication(data)),
  update: (id, data) => dispatch(updateApplication(id, data)),
  deletePolicyData: (id) => dispatch(deleteApplication(id)),
  getPolicies: (force, widgetToken) =>
    dispatch(getPoliciesData(force, widgetToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
