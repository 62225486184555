import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      border: "0 none",
      borderRadius: theme.spacing(1),
      fontWeight: 700,
      letterSpacing: "0.05em",
      padding: theme.spacing(1, 2),
      "&:hover": {
        backgroundColor: theme.palette.aquamarine[200],
      },
    },
    label: {
      lineHeight: 1,
    },
    main: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.aquamarine[100],
    },
    secondary: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.white[100],
      "&:hover": {
        backgroundColor: theme.palette.white[200],
      },
    },
    s: {
      minHeight: theme.spacing(5),
      fontSize: theme.typography.fontSize,
    },
    m: {
      minHeight: theme.spacing(6),
      fontSize: theme.typography.htmlFontSize,
    },
    disabled: {
      backgroundColor: theme.palette.grey.border,
    },
  }),
  { name: "Button" }
);
