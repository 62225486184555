import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(8, 0, 16),
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    fontFamily: "Aldrich, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 36,
    lineHeight: "48px",
    color: theme.palette.blue[500],
    textAlign: "center",
  },
  description: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "26px",
    color: theme.palette.grey[300],
  },
  mainButton: {
    background: theme.palette.blue["young"],
    borderRadius: 4,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: 1,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
      background: theme.palette.blue[500],
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  secondaryButton: {
    border: `2px solid ${theme.palette.blue["young"]}`,
    borderRadius: 4,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: 1,
    color: theme.palette.blue["young"],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
    },
  },
  textField: {
    borderRadius: 48,
    maxWidth: 320,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  inputBase: {
    height: 48,
    borderRadius: 48,
    backgroundColor: theme.palette.common.white,
  },
  inputOutline: {
    borderRadius: 48,
    borderWidth: 2,
    borderColor: theme.palette.blue["young"],
  },
  inputIcon: {
    width: 24,
    height: 24,
    marginLeft: 2,
    marginRight: theme.spacing(1),
    "& > path": {
      fill: theme.palette.blue["young"],
    },
  },
  input: {
    "&::placeholder": {
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      fontStyle: "italic",
      color: theme.palette.blue[300],
      opacity: 1,
    },
  },
}));
