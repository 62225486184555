export default {
  CHARTS_SCANS_REQUEST_START: "CHARTS_SCANS_REQUEST_START",
  CHARTS_SCANS_REQUEST_END: "CHARTS_SCANS_REQUEST_END",
  CHARTS_SCANS_SET_ERROR: "CHARTS_SCANS_SET_ERROR",

  CHARTS_SCANS_FILTER_SET_MONTH: "CHARTS_SCANS_FILTER_SET_MONTH",
  CHARTS_SCANS_FILTER_SET_SCAN: "CHARTS_SCANS_FILTER_SET_SCAN",
  CHARTS_SCANS_FILTER_SET_COUNTRY: "CHARTS_SCANS_FILTER_SET_COUNTRY",
  CHARTS_SCANS_FILTER_SET_PII_GROUPS: "CHARTS_SCANS_FILTER_SET_PII_GROUPS",
  CHARTS_SCANS_FILTER_TOGGLE_PROBLEMATIC:
    "CHARTS_SCANS_FILTER_TOGGLE_PROBLEMATIC",
  CHARTS_SCANS_FILTER_TOGGLE_UNPROTECTED:
    "CHARTS_SCANS_FILTER_TOGGLE_UNPROTECTED",
  CHARTS_SCANS_FILTER_TOGGLE_PII_GROUP: "CHARTS_SCANS_FILTER_TOGGLE_PII_GROUP",
  CHARTS_SCANS_TREEMAP_SET_DATA: "CHARTS_SCANS_TREEMAP_SET_DATA",
  CHARTS_SCANS_OWNERS_SET_DATA: "CHARTS_SCANS_OWNERS_SET_DATA",
  CHARTS_SCANS_TREE_BY_COUNTRY_SET_DATA:
    "CHARTS_SCANS_TREE_BY_COUNTRY_SET_DATA",
  CHARTS_SCANS_CUSTOMERS_BY_COUNTRY_SET_DATA:
    "CHARTS_SCANS_CUSTOMERS_BY_COUNTRY_SET_DATA",
  CHARTS_SCANS_TRENDS_SET_LAST: "CHARTS_SCANS_TRENDS_SET_LAST",
  CHARTS_SCANS_FUNCIONAL_USES: "CHARTS_SCANS_FUNCIONAL_USES",
};
