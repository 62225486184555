import { useEffect, useState } from "react";
import { getDeviceScannerAssets } from "redux/_settings/settings.service";
import Table from "../Table";
import useStyles from "./styles";

const DeviceScannerGuide = () => {
  const classes = useStyles();
  const [assets, setAssets] = useState({});

  useEffect(() => {
    getDeviceScannerAssets().then((x) => {
      setAssets(x);
    });
  }, []);

  return (
    <div>
      <div className={classes.row}>
        <h3 className={classes.title}>Overview</h3>
      </div>
      <p>
        Zendata PII Device Scanner is an application that scans the files on
        your hard drive for PII sensitive data.
      </p>
      <p>
        Download the application program and the key file below to use the PII
        Device Scanner.
      </p>
      <Table assets={assets} />
    </div>
  );
};

export default DeviceScannerGuide;
