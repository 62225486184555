import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  row: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  fieldSm: {
    width: 208,
    marginRight: theme.spacing(2),
  },
  fieldMd: {
    width: 336,
    marginRight: theme.spacing(2),
  },
}));
