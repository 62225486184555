/*import Button from '@material-ui/core/Button';*/
import { camelize } from "humps";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Flags from "country-flag-icons/react/3x2";
import moment from "moment";
import clsx from "clsx";

import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { getAccountData } from "redux/_account/account.selectors";
import { getData } from "redux/_account/account.async.actions";

import ZenDataLogo from "assets/illustrations/brandapp.js";
import { FormSelectInput } from "components/FormComponents/Inputs";
import { UserMenu } from "components/PublicComponents/Nav";
import { routeForKey } from "router";

import countryList from "../../../../../config/BrowserStackCountries";
import { FLAVORS } from "../../constants";
import GoBackButton from "../GoBackButton";
import { WEB_REPORT_TYPES } from "../../WebReport";
import useStyles from "./styles";

export const DownloadSVG = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6H14L7 13L0 6H4V0H10V6ZM0 17V15H14V17H0Z"
      fill="#3F51B5"
    />
  </svg>
);

const FILTERS = {
  location: "location",
  date: "date",
};

// TODO: decouple props from parent (things that can be imported directly
// should be imported directly).
const Header = ({
  children,
  sidebar,
  hidden,
  onToggleMenu,
  loading,
  url,
  location,
  date,
  availableLocations = [],
  availableDates = [],
  setCountry,
  setDate,
  flavor,
  history,
  isAuthenticated,
  previousRouteKey,
  account,
  getProfileData,
  logout,
  reportType = WEB_REPORT_TYPES.page,
  className,
}) => {
  const classes = useStyles({ isAuthenticated });
  const [defaultDate, setDefaultDate] = useState();
  const [dates, setDates] = useState([]);

  const handleLogout = () => {
    logout();
    history.push("/privacy-scanner");
  };

  useEffect(() => {
    if (isAuthenticated) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // const redirectToScanner = () => {
  //   history.push('/privacy-scanner')
  // }

  // eslint-disable-next-line
  // const onReportsChange = (value) => {
  //   if (typeof value === "function") {
  //     value();
  //   } else {
  //     history.push({
  //       pathname: `/privacy-report/${encodeURIComponent(value)}`,
  //     });
  //   }
  // };

  const onChange = (field) => (value) => {
    if (field === FILTERS.location) {
      setCountry(value);
    } else {
      setDate(value);
    }
  };

  const countries = countryList
    .filter((country) => availableLocations.includes(country.value))
    .map((country) => {
      const Flag = Flags[country.value];
      return {
        ...country,
        icon: <Flag className={classes.flag} />,
      };
    });

  useEffect(() => {
    if (availableDates.length > 0) {
      const reports = availableDates.map((availableDate) => ({
        label: availableDate,
        value: camelize(availableDate),
      }));

      let orderedReports = reports;
      let index = 0;
      if (reportType === WEB_REPORT_TYPES.sitemap) {
        orderedReports = reports.reverse();
        index = orderedReports.length - 1;
      }
      setDates(orderedReports);

      if (availableDates.includes(moment(date).format("YYYY-MM-DD"))) {
        setDefaultDate(camelize(moment(date).format("YYYY-MM-DD")));
      } else {
        setDefaultDate(camelize(availableDates[index]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates]);

  return (
    <div className={className ? className : classes.header + " " + (hidden ? classes.hidden : "")}>
      <div className={classes.upperBar}>
        <div className="container">
          <GoBackButton
            parentReportId={history?.location?.state?.parentReportId}
            parentReportCategory={
              history?.location?.state?.parentReportCategory
            }
            isAuthenticated={isAuthenticated}
            previousRouteKey={previousRouteKey}
          />
          {isAuthenticated && (
            <UserMenu
              hideAvatar
              handleLogout={handleLogout}
              account={account}
              isPublicNav={false}
            />
          )}
        </div>
      </div>
      <div
        className={classes.headerContent}
        style={!url ? { alignItems: "center" } : {}}
      >
        {/* TODO(@jperozo): Should be the function which generates the downloading */}
        <Form onSubmit={() => {}}>
          {({ handleSubmit, values }) => (
            <>
              <div className={classes.logoWrapper}>
                <Link to={routeForKey("privacyScanner")}>
                  <ZenDataLogo className={classes.logo} />
                </Link>
                {url && (
                  <div className={classes.headerSub}>
                    privacy report for:&nbsp;
                    <span className={classes.headerUrl}>{url}</span>
                    {/*isAuthenticated ? (
                      <>
                        <span>
                          privacy report for:
                        </span>
                        <Field
                          name="report"
                          component={FormSelectInput}
                          defaultValue={url}
                          className={clsx(classes.filterSelect, 'url')}
                          placeholder="Select one"
                          options={reports}
                          disabled={loading}
                          onChange={onReportsChange}
                        />
                      </>
                    ) : (
                      <>
                        privacy report for:&nbsp;
                        <span className={classes.headerUrl}>{url}</span>
                      </>
                    )*/}
                  </div>
                )}
              </div>
              <div className={clsx(classes.controllers, flavor)}>
                {!!countries.length && (
                  <div className={classes.headerFilter}>
                    <div className={classes.label}>
                      Available country report
                    </div>
                    {countries && (
                      <Field
                        name="location"
                        component={FormSelectInput}
                        defaultValue={location}
                        className={classes.filterSelect}
                        placeholder="Select one"
                        options={countries}
                        disabled={loading}
                        onChange={onChange("location")}
                      />
                    )}
                  </div>
                )}
                {(flavor === FLAVORS.full ||
                  reportType === WEB_REPORT_TYPES.sitemap) && (
                  <div className={classes.headerFilter}>
                    <div className={classes.label}>Report history</div>
                    {dates && (
                      <Field
                        name="date"
                        component={FormSelectInput}
                        defaultValue={defaultDate}
                        className={classes.filterSelect}
                        placeholder="Select one"
                        options={dates}
                        disabled={loading}
                        onChange={onChange("date")}
                      />
                    )}
                  </div>
                )}
                {/*
                <Button color="primary" className={classes.button} variant="contained">
                  <DownloadSVG className={classes.download} />
                  Download report
                </Button>*/}
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProfileData: () => dispatch(getData()),
  logout: () => dispatch(authenticationActinon.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
