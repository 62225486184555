import { Box, Typography } from "@material-ui/core";
import React from "react";
import SvgIcon from "components/svg-icon/svg-icon";
import { formatNumber } from "utils/format";
import useStyles from "./styles";
import Skeleton from "@material-ui/lab/Skeleton";

// const getColor = variation =>
//   variation === 0 ? '#4766b6' : variation < 0 ? '#dd6666' : '#71b4a7';

// const getBgColor = variation =>
//   variation === 0
//     ? 'rgba(242, 242, 242, 0.2)'
//     : variation < 0
//     ? 'rgba(221, 102, 102, 0.05)'
//     : 'rgba(115, 186, 172, 0.05)';

const Element = ({
  value,
  variation,
  name,
  icon = "database",
  positive = true,
  loading,
  style = {},
}) => {
  // const parsedVariation = positive ? variation : -variation;
  // const color = getColor(parsedVariation);
  // const bgColor = getBgColor(parsedVariation);
  const classes = useStyles();
  // const absoluteVariation = Math.abs(variation);
  // const loading = true;

  // if (loading) return <Loader />;
  return (
    <Box>
      <Box className={classes.itemValueContainer}>
        {loading ? (
          <Skeleton width={150} height={26} className={classes.skeleton1} />
        ) : (
          <>
            <SvgIcon icon={icon} />
            <Typography className={classes.itemValue}>
              {" "}
              {formatNumber(value)}
            </Typography>
            {/* {variation !== 0 && (
            <Box
              className={classes.variationContainer}
              style={{ backgroundColor: bgColor, border: `1px solid ${color}` }}
            >
              <Typography style={{ color: color }} className={classes.variation}>
                {absoluteVariation > 9999 ? formatNumber(absoluteVariation) : absoluteVariation}
              </Typography>
              <SvgIcon
                icon={parsedVariation > 0 ? 'arrowTrendup' : 'arrowTrenddown'}
                color={color}
              />
            </Box>
          )} */}
          </>
        )}
      </Box>
      {!loading ? (
        <Typography className={classes.itemTitle} display="inline" noWrap>
          {" "}
          {name}
        </Typography>
      ) : (
        <Skeleton width={130} height={20} className={classes.skeleton2} />
      )}
    </Box>
  );
};

export default Element;
