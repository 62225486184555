/* eslint-disable jsx-a11y/anchor-is-valid */
import isObject from "lodash.isobject";
import isArray from "lodash.isarray";
import PropTypes from "prop-types";
import React from "react";

import { Box, CircularProgress } from "@material-ui/core";

import Button from "components/Button";
import TextInput from "components/Inputs/Text";

import { ErrorList, noOp } from "./login-form";
import useStyles from "./styles";

const ResetPasswordConfirmForm = ({
  isSending = false,
  password1,
  password2,
  updatePwd = noOp,
  submit = noOp,
  errors = [],
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={submit} className={classes.form}>
      {isArray(errors) && errors.length > 0 && <ErrorList errors={errors} />}
      <TextInput
        value={password1}
        type="password"
        label="New Password"
        placeholder="********"
        onChange={updatePwd("new_password1")}
        style={{ paddingBottom: "1.5rem" }}
        required
      />

      <TextInput
        value={password2}
        type="password"
        label="Repeat New Password"
        placeholder="********"
        onChange={updatePwd("new_password2")}
        style={{ paddingBottom: "1.5rem" }}
        required
      />

      <Button
        type="submit"
        className={classes.submitButton}
        disabled={isSending}
      >
        Reset your password
        {isSending && (
          <CircularProgress
            color="inherit"
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>

      {isObject(errors) && errors.error && (
        <Box color="error.main" textAlign="center">
          {errors.error}
        </Box>
      )}
    </form>
  );
};

ResetPasswordConfirmForm.propTypes = {
  isSending: PropTypes.bool,
  password1: PropTypes.string,
  password2: PropTypes.string,
  updatePwd: PropTypes.func,
  submit: PropTypes.func,
  errors: PropTypes.array,
};

export default ResetPasswordConfirmForm;
