import React, { useState } from "react";
import {
  FlexibleXYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  Hint,
} from "react-vis";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import isEmpty from "lodash.isempty";

import "./styles.scss";
import { formatNumber } from "utils/format";

const styles = {
  line: {
    fill: "none",
  },
  gridLine: {
    stroke: "rgba(119, 119, 119, 0.08)",
    strokeWidth: 0.5,
  },
  axis: {
    line: {
      stroke: "#000",
      strokeWidth: 0.5,
    },
    text: {
      stroke: "none",
      fill: "#4F4F4F",
      lineHeight: "22px",
      fontSize: "14px",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "& >div": {
      "& >div": {
        position: "relative",
      },
    },
  },
  hintContainer: {
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: theme.spacing(),
    borderRadius: "4px",
  },
  refContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    margin: theme.spacing(),
  },
  hintText: {
    fontSize: "12px",
    lineHeight: "22px",
    width: "calc(100% - 26px)",
    color: "white",
    fontWeight: 500,
  },
}));

const palette = [
  "#dd6666",
  "#2d7d75",
  "#73baac",
  "#c8c7aa",
  "#506b82",
  "#a78d79",
  "#d99d4a",
];

const getDates = (data) => {
  let dates = {};
  Object.keys(data).forEach((key) => {
    data[key].forEach(({ x, date }) => {
      if (!Object.keys(dates).includes(x)) dates = { ...dates, [x]: date };
    });
  });
  return dates;
};

const PiiCategories = (props) => {
  const classes = useStyles();
  const { data, totals } = props;
  const dates = !isEmpty(data) ? getDates(data) : {};
  const [values, setValues] = useState({});

  const rememberValues = (val) => {
    if (isEmpty(values) || val.x !== values.x) {
      const parsedData = Object.keys(data).map((key) => ({
        ...data[key].find(({ x }) => x === val.x),
        name: key,
      }));
      setValues({ x: val.x, y: val.y, data: parsedData });
    }
  };

  const forgetValues = async () => setValues({});

  return (
    <Box className={classes.chartContainer}>
      {!isEmpty(data) ? (
        <Box className={classes.content}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            {Object.keys(data).map((key, idx) => (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  padding: "8px",
                }}
              >
                <Box
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#4F4F4F",
                    borderBottom: `2px solid ${palette[idx]}`,
                  }}
                >
                  {key}
                </Box>
                <Box style={{ fontSize: "13px", fontWeight: 500 }}>
                  {formatNumber(totals[key])}
                </Box>
              </Box>
            ))}
          </Box>

          <FlexibleXYPlot
            margin={{ left: 60 }}
            strokeStyle="solid"
            xType="ordinal"
            onMouseLeave={forgetValues}
          >
            {Object.keys(data).map((key, idx) => (
              <LineSeries
                data={data[key]}
                style={styles.line}
                strokeWidth={2}
                color={palette[idx]}
                onNearestXY={rememberValues}
              />
            ))}
            <VerticalGridLines style={styles.gridLine} />
            <HorizontalGridLines style={styles.gridLine} />
            <XAxis
              tickFormat={(val) => (isEmpty(dates) ? "00/00" : dates[val])}
              style={styles.axis}
            />
            <YAxis
              style={styles.axis}
              tickFormat={(val) => formatNumber(val)}
            />
            {!isEmpty(values) && (
              <Hint value={{ x: values.x, y: values.y }}>
                <Box className={classes.hintContainer}>
                  {values.data.map(({ name, y }, idx) => (
                    <Box className={classes.refContainer}>
                      <Box
                        style={{ backgroundColor: palette[idx] }}
                        className={classes.dot}
                      />
                      <Typography className={classes.hintText}>{y}</Typography>
                    </Box>
                  ))}
                </Box>
              </Hint>
            )}
          </FlexibleXYPlot>
        </Box>
      ) : (
        <LinearProgress style={{ width: "75%" }} />
      )}
    </Box>
  );
};

export default PiiCategories;
