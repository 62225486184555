import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTIONS_CONTENT, SECTION_ID } from "pages/AppReport/constants";
import ExpandedPanel from "../FindingSeverity/ExpandedPanel";
import { BoxDetails } from "../FindingSeverity/InformationSection";
import SectionContent from "pages/AppReport/common/SectionContent";
import Table from "pages/AppReport/common/Table";
import Title from "pages/AppReport/common/Title";
import useStyles from "./styles";

const StoreInfo = ({ goToNextSectionAction, data }) => {
  const [expanded, setExpanded] = useState();
  const classes = useStyles();
  return (
    <Page
      key={SECTIONS_CONTENT[SECTION_ID.storeInfo].key}
      footerBackgroundColor={
        SECTIONS_CONTENT[SECTION_ID.storeInfo].backgroundColor
      }
      footerText={<div className="privacy-tech">Check the opportunities</div>}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <SectionContent noTitle>
        <div className={classes.expandedSections}>
          {!!data.firebase_urls.length && (
            <ExpandedPanel
              title="Firebase Databases"
              onChange={() =>
                setExpanded(expanded !== "firebaseDB" ? "firebaseDB" : "")
              }
              expanded={expanded === "firebaseDB"}
            >
              <BoxDetails
                data={[
                  {
                    label: "URL",
                    value: data.firebase_urls.map((firebaseDB) => (
                      <span>
                        <br />
                        <b>{firebaseDB.url}</b>
                        <br />
                        {data.open
                          ? "App talks to a Firebase Database."
                          : "App doesn't talk to a Firebase Database."}
                      </span>
                    )),
                  },
                ]}
              />
            </ExpandedPanel>
          )}
          {!!data.emails.length && (
            <ExpandedPanel
              title="Emails"
              onChange={() =>
                setExpanded(expanded !== "emails" ? "emails" : "")
              }
              expanded={expanded === "emails"}
            >
              <Table
                data={data.emails}
                limitPerPage={5}
                columns={[
                  {
                    key: "emails",
                    label: "Email",
                    width: "50%",
                    render: (emails) => (
                      <div>{ReactHtmlParser(emails.join("\n"))}</div>
                    ),
                  },
                  {
                    key: "path",
                    label: "File",
                    width: "50%",
                  },
                ]}
              />
            </ExpandedPanel>
          )}
          {!!data.trackers?.trackers?.length && (
            <ExpandedPanel
              title="Trackers"
              onChange={() =>
                setExpanded(expanded !== "trackers" ? "trackers" : "")
              }
              expanded={expanded === "trackers"}
            >
              <BoxDetails
                data={[
                  {
                    label: "TRACKER",
                    value: data.trackers.trackers.map((tracker) => (
                      <span>
                        <br />
                        <b>{tracker.name}</b>
                        <br />
                        {tracker.categories && (
                          <>
                            {tracker.categories}
                            <br />
                          </>
                        )}
                        {tracker.url}
                      </span>
                    )),
                  },
                ]}
              />
            </ExpandedPanel>
          )}
          {!!data.secrets.length && (
            <ExpandedPanel
              title="Hardcoded Secrets"
              onChange={() =>
                setExpanded(
                  expanded !== "hardcodedSecrets" ? "hardcodedSecrets" : ""
                )
              }
              expanded={expanded === "hardcodedSecrets"}
            >
              <BoxDetails
                data={[
                  {
                    label: "POSSIBLE SECRETS",
                    value: (
                      <>
                        <br />
                        {data.secrets.map((secrets) => (
                          <span>
                            {secrets}
                            <br />
                          </span>
                        ))}
                      </>
                    ),
                  },
                ]}
              />
            </ExpandedPanel>
          )}
        </div>
        <div className="scrollable-element">
          <Title styles={{ marginTop: "30px" }}>
            {data.app_type === "apk"
              ? "PlayStore Information"
              : "App Store Information"}
          </Title>
          {(data?.playstore_details || data?.appstore_details)
            ?.error ? null : data.app_type === "apk" ? (
            <>
              <span>
                <b>Title:</b>&nbsp;{data.playstore_details.title}
              </span>
              &nbsp;
              <span>
                <b>Score:</b>&nbsp;{data.playstore_details.score}
              </span>
              &nbsp;
              <span>
                <b>Installs:</b>&nbsp;{data.playstore_details.installs}
              </span>
              &nbsp;
              <span>
                <b>Price:</b>&nbsp;{data.playstore_details.price}
              </span>
              &nbsp;
              <span>
                <b>Android Version Support:</b>&nbsp;
                {data.playstore_details.androidVersionText}
              </span>
              &nbsp;
              <span>
                <b>Category:</b>&nbsp;{data.playstore_details.genre}
              </span>
              &nbsp;
              <span>
                <b>Play Store URL:</b>&nbsp;
                <a href={data.playstore_details.url} target="_blank">
                  {data.playstore_details.appId}
                </a>
              </span>
              &nbsp;
              <br />
              <span>
                <b>Developer Details:</b>&nbsp;
                <br />
                {data.playstore_details.developer && (
                  <>
                    <b>Developer:</b>&nbsp;
                    {data.playstore_details.developer}
                    <br />
                  </>
                )}
                {data.playstore_details.developerId && (
                  <>
                    <b>Developer ID:</b>&nbsp;
                    {data.playstore_details.developerId}
                    <br />
                  </>
                )}
                {data.playstore_details.developerAddress && (
                  <>
                    <b>Developer Address:</b>&nbsp;
                    {data.playstore_details.developerAddress}
                    <br />
                  </>
                )}
                {data.playstore_details.developerWebsite && (
                  <>
                    <b>Developer Website:</b>&nbsp;
                    {data.playstore_details.developerWebsite}
                    <br />
                  </>
                )}
                {data.playstore_details.developerEmail && (
                  <>
                    <b>Developer Email:</b>&nbsp;
                    {data.playstore_details.developerEmail}
                    <br />
                  </>
                )}
              </span>
              <br />
              <span>
                <b>Release Date:</b>&nbsp;{data.playstore_details.released}
              </span>
              &nbsp;
              <span>
                <b>Privacy Policy:</b>&nbsp;
                <a href={data.playstore_details.privacyPolicy}>Privacy Link</a>
              </span>
              <br />
              <span>
                <b>Description:</b>&nbsp;{data.playstore_details.description}
              </span>
              <br />
            </>
          ) : (
            <>
              <span>
                <b>Title:</b>&nbsp;{data.appstore_details.title}
              </span>
              &nbsp;
              <span>
                <b>Score:</b>&nbsp;{data.appstore_details.score}
              </span>
              &nbsp;
              <span>
                <b>Price:</b>&nbsp;{data.appstore_details.price}
              </span>
              &nbsp;
              <span>
                <b>Category:</b>&nbsp;
                {data.appstore_details.category.join(", ")}
              </span>
              &nbsp;
              <br />
              <span>
                <b>App Store URL:</b>&nbsp;
                <a href={data.appstore_details.itunes_url} target="_blank">
                  {data.appstore_details.app_id}
                </a>
              </span>
              &nbsp;
              <br />
              <span>
                <b>Developer Details:</b>&nbsp;
                <br />
                {data.appstore_details.developer && (
                  <>
                    <b>Developer:</b>&nbsp;{data.appstore_details.developer}
                    <br />
                  </>
                )}
                {data.appstore_details.developer_id && (
                  <>
                    <b>Developer ID:</b>&nbsp;
                    {data.appstore_details.developer_id}
                    <br />
                  </>
                )}
                {data.appstore_details.developer_website && (
                  <>
                    <b>Developer Website:</b>&nbsp;
                    <a
                      target="_blank"
                      href={data.appstore_details.developer_website}
                    >
                      {data.appstore_details.developer_website}
                    </a>
                    <br />
                  </>
                )}
                {data.appstore_details.developer_url && (
                  <>
                    <b>Developer URL:</b>&nbsp;
                    <a
                      target="_blank"
                      href={data.appstore_details.developer_url}
                    >
                      {data.appstore_details.developer_url}
                    </a>
                  </>
                )}
              </span>
              <br />
              <span>
                <b>Supported Devices:</b>&nbsp;
                {data.appstore_details.supported_devices.join(", ")}
              </span>
              <br />
              <span>
                <b>Description:</b>&nbsp;{data.appstore_details.description}
              </span>
              <br />
            </>
          )}
        </div>
      </SectionContent>
    </Page>
  );
};

export default StoreInfo;
