import React, { useRef, useState, useEffect, useCallback } from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";
import SVGIcon from "components/svg-icon/svg-icon";
import isNil from "lodash.isnil";
import ResizeObserver from "resize-observer-polyfill";
import debounce from "lodash.debounce";
import CustomSelect from "components/SettingsSections/EntitySelector/components/customSelect";

const useStyles = makeStyles((theme) => ({
  viewingLabel: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontSize: 15,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: theme.palette.grey[800],
    textTransform: "uppercase",
    marginLeft: "auto",
  },
  navButton: {
    fontSize: 15,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: "normal",
    color: theme.palette.blue["young"],
    textTransform: "none",
    "&:disabled": {
      color: theme.palette.blue["young"],
      opacity: 0.6,
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
}));

const PaginatedBox = ({
  fixed,
  children,
  rows = 2,
  onSelect,
  value,
  onRowsChange,
  entityName,
  search,
  total,
}) => {
  const [page, setPage] = useState(0);

  const box = useRef();
  const [boxWidth, setBoxWidth] = useState(0);

  const BOX_WIDTH = 80;
  const BOX_BASE_GRID_GAP = 12;
  const BOX_TOLERANCE_GRID_GAP = 16;

  const childrenArr = React.Children.toArray(children);
  const boxesInLine = Math.floor(boxWidth / (BOX_WIDTH + BOX_BASE_GRID_GAP));
  const boxesToShow = boxWidth
    ? Math.min(childrenArr.length, boxesInLine * rows - (fixed ? 1 : 0))
    : 0;

  const handleNext = () => setPage(page + 1);
  const handlePrevious = () => setPage(page - 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const margin = useCallback(
    (boxWidth - BOX_WIDTH * boxesInLine) / (boxesInLine - 1),
    [boxesInLine]
  );

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const updateBoxWidth = () =>
    setBoxWidth(box && box.current ? box.current.offsetWidth : undefined);

  const observer = new ResizeObserver(debounce(updateBoxWidth, 300));

  useEffect(() => {
    updateBoxWidth();
    observer.observe(box.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => observer.unobserve(box.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPagination = childrenArr.length > boxesInLine * 2 - 1;

  // Gap tolerance is true when exist a difference between round()
  // and floor() results greater than 0. In this case, apply a new
  // gap value for <ToggleButtonGroup> in order to avoid mismatching
  // number of boxes per line.
  const gapTolerance =
    Math.round(boxWidth / (BOX_WIDTH + BOX_BASE_GRID_GAP)) -
      Math.floor(boxWidth / (BOX_WIDTH + BOX_BASE_GRID_GAP)) >
    0;

  const paginationRangeShowed = () => {
    const initialRange = boxesToShow * page === 0 ? 1 : boxesToShow * page;
    const finalRange =
      boxesToShow * page + boxesToShow > total
        ? total
        : boxesToShow * page + boxesToShow;

    return `${initialRange} - ${finalRange}`;
  };

  const classes = useStyles();
  return (
    <>
      {(showPagination || !!search) && (
        <Box height={36} display="flex" alignItems="center" mb={2}>
          {showPagination && (
            <CustomSelect value={rows} onChange={onRowsChange} />
          )}

          {boxesToShow > 0 && childrenArr.length > 0 && (
            <Typography className={classes.viewingLabel}>
              You are viewing {paginationRangeShowed()} of{" "}
              {!search ? childrenArr.length : total} {entityName}s
            </Typography>
          )}
        </Box>
      )}
      <Box ref={box} width="100%">
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={(e, val) => {
            if (!isNil(val)) onSelect(val);
          }}
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, ${BOX_WIDTH}px)`,
            columnGap: gapTolerance
              ? BOX_TOLERANCE_GRID_GAP
              : BOX_BASE_GRID_GAP,
            rowGap: margin,
            justifyContent: "space-between",
          }}
        >
          {fixed}
          {childrenArr.slice(boxesToShow * page, boxesToShow * (page + 1))}
        </ToggleButtonGroup>
      </Box>
      {showPagination &&
        !(page <= 0 && boxesToShow * (page + 1) >= childrenArr.length) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              className={classes.navButton}
              disabled={page <= 0}
              onClick={handlePrevious}
            >
              <SVGIcon
                icon="chevronRight"
                style={{ transform: "rotate(180deg)" }}
              />
              Previous
            </Button>
            <Button
              className={classes.navButton}
              disabled={boxesToShow * (page + 1) >= childrenArr.length}
              onClick={handleNext}
            >
              Next
              <SVGIcon icon="chevronRight" />
            </Button>
          </Box>
        )}
    </>
  );
};

export default PaginatedBox;
