import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "./styles";
import Illustration from "assets/illustrations/illus_equilibrium.svg";

const Step1 = (props) => {
  const { nextCallback = () => {}, name } = props;
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <img src={Illustration} alt="" />
      <Typography className={classes.title}>
        {name ? `Welcome ${name}` : "Welcome"}
      </Typography>
      <Typography className={classes.subtitle}>
        To start discovering everything your data has to say, we need to do a
        quick set-up.
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={nextCallback}
      >
        LET’S START
      </Button>
    </Box>
  );
};

export default Step1;
