import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Chip,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from "@material-ui/core";
import { useRunNewScanByBranch } from "hooks/useRunNewScanByBranch";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RunNewScanModal = ({ isOpen, onRequestClose, initialBranchId, onSwitchToScanList }) => {
  const branches = useSelector((state) => state.scanner.branches);
  const [selectedBranches, setSelectedBranches] = useState(initialBranchId);
  const { loading, data, error, fetchData, resetData } = useRunNewScanByBranch();

  useEffect(() => {
    if (initialBranchId) {
      setSelectedBranches(initialBranchId);
    }
  }, [initialBranchId]);

  useEffect(() => {
    if (!isOpen) {
      resetData();
    }
  }, [isOpen, resetData]);

  const handleChange = (event) => {
    setSelectedBranches(event.target.value);
  };

  const onHandleContinue = useCallback(() => {
    fetchData(selectedBranches);
  }, [fetchData, selectedBranches]);

  const handleOkClick = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  const handleGoToScanList = useCallback(() => {
    onRequestClose();
    onSwitchToScanList();
  }, [onRequestClose, onSwitchToScanList]);

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        keepMounted={false}
        onBackdropClick={false}
        onClose={onRequestClose}
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 640,
            backgroundColor: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: 24,
            borderRadius: 8,
            outline: "none",
          }}
        >
          {data ? (
            <>
              <Typography
                id="transition-modal-title"
                style={{ fontFamily: "Inter", fontSize: 20, color: "#000000" }}
              >
                New Scan in progress
              </Typography>
              <Typography
                id="transition-modal-sub-title"
                style={{ fontFamily: "Inter", fontSize: 16, marginTop: 16 }}
              >
                After the scan is completed, you can see details in the Scan List
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 48,
                  justifyContent: "flex-end",
                  gap: 8,
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    fontFamily: "Inter",
                    color: "#1B3586",
                    fontSize: "14px",
                    fontWeight: "700",
                    cursor: "pointer",
                    width: 224,
                    backgroundColor: "transparent",
                  }}
                  onClick={handleGoToScanList}
                >
                  GO TO SCAN LIST
                </Button>
                <Button
                  variant="contained"
                  onClick={handleOkClick}
                  style={{
                    fontFamily: "Inter",
                    color: "#1B3586",
                    backgroundColor: "#6BD4CA",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: 224,
                    cursor: "pointer",
                  }}
                >
                  OK
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography
                id="transition-modal-title"
                style={{
                  fontFamily: "Inter",
                  fontSize: 20,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Are you sure you want to run a new Scan?
              </Typography>
              <Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 24,
                  }}
                >
                  <Typography style={{ width: 184 }}>Branches</Typography>
                  <Box>
                    <FormControl
                      variant="outlined"
                      style={{ width: 244, borderColor: "#0000000" }}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedBranches}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {branches.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 48,
                    justifyContent: "flex-end",
                    gap: 8,
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{
                      fontFamily: "Inter",
                      color: "#1B3586",
                      fontSize: "14px",
                      fontWeight: "700",
                      cursor: "pointer",
                      width: 224,
                      backgroundColor: "transparent",
                    }}
                    onClick={onRequestClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      fontFamily: "Inter",
                      color: "#1B3586",
                      backgroundColor: "#6BD4CA",
                      fontSize: "14px",
                      fontWeight: "700",
                      width: 224,
                      cursor: "pointer",
                    }}
                    onClick={onHandleContinue}
                  >
                    {loading ? (
                      <CircularProgress style={{ height: 22, width: 22 }} />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </Box>
                {error && (
                  <Chip
                    variant="outlined"
                    label={error}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "24px",
                      backgroundColor: "white",
                      border: "1px solid red",
                      borderRadius: 8,
                      fontFamily: "Inter",
                      color: "red",
                      paddingTop: 12,
                      paddingBottom: 12,
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default RunNewScanModal;
