import React from "react";
import { Typography } from "@material-ui/core";

import ConsumerReportLayout from "components/ConsumerReportLayout";

import useStyles from "./styles";
import { ReactComponent as SearchIllustration } from "./assets/illustration.svg";

const ConsumerReportWelcome = () => {
  const classes = useStyles();
  return (
    <div className={classes.welcome}>
      <SearchIllustration className={classes.searchIllustration} />
      <Typography variant="h1" className={classes.welcomeTitle}>
        Claim your privacy
      </Typography>
    </div>
  );
};

const ConsumerReport = () => {
  return (
    <ConsumerReportLayout mainWithBackground withSearchInputText>
      <ConsumerReportWelcome />
    </ConsumerReportLayout>
  );
};

export default ConsumerReport;
