import React, { useEffect, useState } from "react";
import ProviderBox from "./ProviderBox";
import Loader from "components/Loader";
import useStyles from "./styles";
import { getProviders } from "redux/_datasources/datasources.service";

const Providers = ({ onSelectProvider }) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [providerList, setProviderList] = useState([]);

  const getProviderList = async () => {
    setLoading(true)
    const providers = await getProviders();
    setProviderList(providers?.results || []);
    setLoading(false);
  };

  useEffect(() => {
    getProviderList()
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <Loader className={styles.loaderContainer} />
      )
      : providerList.map((provider) => (
        <ProviderBox
          key={provider.id}
          provider={provider}
          actionConfig={{
            onClick: () => onSelectProvider(provider),
            label: "+Add",
          }}
        />
      ))}
    </div>
  );
};

export default Providers;
