/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unreachable */
import { Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import clsx from "clsx";
import Button from "components/Button";
import Link from "components/ScrollTopLink";
import React from "react";
import { routeForKey } from "router";
import useStyles from "./styles";

const Footer = ({ backgroundColor = undefined }) => {
  const classes = useStyles({ backgroundColor });

  return (
    <footer className={classes.footer}>
      <div className="background">
        <span className="circle filled small upper"></span>
        <span className="circle filled small lower"></span>
        <span className="circle filled big"></span>
        <span className="circle empty bigger-1"></span>
        <span className="circle empty bigger-2"></span>
      </div>
      <div className="top"></div>
      <div className="children">
        <div className="container">
          <div className="content">
            <Typography variant="h3" className={classes.title}>
              Powered using the latest Machine learning, Natural Language
              processing techniques and 2000+ device emulators
            </Typography>
            <Link to={routeForKey("privacyScanner")} className={classes.button}>
              <Button size="small">Try Zendata For Free Now</Button>
            </Link>
            <Link to={routeForKey("requestADemo")} className={classes.link}>
              or Get in touch
              <ArrowForwardIcon />
            </Link>
          </div>
          <div className={clsx(classes.footerNav, classes.footerNavSocial)}>
            <a className={classes.navLink} href="https://www.zendata.dev">
              <ZenDataLogo />
            </a>
            <ul className="nav">
              <li>
                <a className={classes.navLink} href="https://www.zendata.dev">
                  Product
                </a>
              </li>
              <li>
                <a
                  className={classes.navLink}
                  href="https://www.zendata.dev/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className={classes.navLink}
                  href="https://www.zendata.dev/pricing"
                >
                  Plans & Pricing
                </a>
              </li>
              <li>
                <a
                  className={classes.navLink}
                  href="https://www.zendata.dev/blog"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  className={classes.navLink}
                  href="https://www.zendata.dev/press"
                >
                  Press
                </a>
              </li>
              <li>
                <a
                  className={classes.navLink}
                  href="https://www.zendata.dev/terms"
                >
                  Terms & Conditions
                </a>
              </li>
            </ul>
            <ul className="social">
              <li className={classes.socialIco}>
                <a
                  className="nav-link"
                  href="https://www.linkedin.com/company/zendata-ai/"
                >
                  <i className={classes.icoLinkedin}>LinkedIn</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
