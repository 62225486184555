import { makeStyles } from "@material-ui/core";

export const settingOptionMixin = (theme) => {
  return {
    setting: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `solid 1px ${theme.palette.grey[500]}`,
      height: theme.spacing(10),
      alignItems: "center",
    },
    valueRelated: {
      display: "flex",
      alignItems: "center",
      maxWidth: "60%",
    },
    valueReadOnly: {
      color: theme.palette.grey[800],
    },
    options: {
      marginLeft: 10,
    },
    option: {
      textDecoration: "underline",
      color: "black",
      fontWeight: "bold",
    },
  };
};

export default makeStyles((theme) => ({
  ...settingOptionMixin(theme),
}));
