import CONSTANTS from "./widget.constants";

const initialState = {
  loading: false,
  apiKey: "",
};

export const widget = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SET_API_KEY:
      return {
        ...state,
        apiKey: data,
      };
    default:
      return state;
  }
};
