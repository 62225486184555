import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  textFieldRoot: {
    paddingRight: ".5rem",
  },
  input: {
    padding: "0 .5rem!important",
    fontSize: "14.4px!important",
  },
}));
