import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  reportPage: {},
  cookiesSection: {
    padding: theme.spacing(8, 0, 0),
  },
  button: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: 1,
    textTransform: "uppercase",
    backgroundColor: theme.palette.blue["young"],
    width: 139,
    height: 40,
    padding: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: 6,
    "&:hover": {
      backgroundColor: theme.palette.blue["500"],
      color: theme.palette.common.white,
    },
    "&:disabled": {
      opacity: 0.6,
    },
  },
  buttonLoader: {
    color: theme.palette.common.white,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    position: "fixed",
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  chartWrapper: {
    minHeight: "300px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  chartTitle: {
    color: "#505050",
    textTransform: "uppercase",
    textAlign: "center",
  },
  riskHigh: {
    color: theme.palette.red["500"],
  },
  riskMedium: {
    color: theme.palette.orange["700"],
  },
  riskLow: {
    color: theme.palette.green["300"],
  },
  complexityScoreList: {
    padding: "0 0 0 18px",
  },
  loaderContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  box: {
    display: "inline-block",
    padding: "10px 30px",
    color: "white",
    fontWeight: 600,
  },
}));
