import { makeStyles } from "@material-ui/core";

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 500,
    paddingBottom: theme.spacing(6),
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    margin: "20px 0",
    alignItems: "flex-start",
    "&.-countries": {
      margin: "20px 0 40px",
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  leftContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
  },
  loaderContainer: {
    top: "40",
  },
  linkButton: {
    paddingTop: "5px",
    color: "#1a3586",
    textTransform: "none",
    textDecoration: "none",
    background: "none",
    border: "none",
    font: "inherit",
    outline: "inherit",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "none",
    },
  },
}));

export default useCustomStyles;
