import * as Sentry from "@sentry/browser";

import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

/**
 * Handles common store related operations when making an API request using
 * a service method.
 *
 * @note {1} It reports to Sentry any error thrown while making the request or
 *     while processing the response. It does NOT report the error to the
 *     user, since this is handled by handleFetchResponse at the moment
 *     (July 2020).
 */
export const callServiceMethod = (dispatch, requestStart, requestEnd) => (
  serviceMethod,
  serviceMethodArgs = [],
  thenCallback,
  thenCallbackArgs = []
) => {
  dispatch(requestStart());
  return serviceMethod(...serviceMethodArgs)
    .then((data) => {
      dispatch(requestEnd());
      if (thenCallback) {
        thenCallback(data, dispatch, ...thenCallbackArgs);
      }
    })
    .catch((error) => {
      dispatch(requestEnd());
      Sentry.captureException(error);
      throw error;
    });
};

const HTTP_METHODS_WITH_BODY = ["PATCH", "POST"];

export const createFetchOptionsUsing = (customOpts) => {
  const opts = {
    ...customOpts,
    headers: {
      ...customOpts.headers,
      ...authHeader(),
    },
  };

  if (HTTP_METHODS_WITH_BODY.includes(customOpts.method)) {
    opts.headers["Content-Type"] = "application/json";
  }

  return opts;
};

export const fetchFromApi = (endpoint, opts, doNotHandleResponses) => {
  const url = `${config.apiUrl}/${endpoint}`;
  return fetch(url, createFetchOptionsUsing(opts))
    .then((response, alreadyLogedOut, hideErrorMessages) => {
      if (!doNotHandleResponses)
        return handleFetchResponse(
          response,
          alreadyLogedOut,
          hideErrorMessages
        );
      return response;
    })
    .then((response) => (response.meta ? response.data : response));
};

export const loadScript = (src, callback) => {
  var s, r, t;
  r = false;
  s = document.createElement("script");
  s.type = "text/javascript";
  s.src = src;
  s.onload = s.onreadystatechange = function () {
    if (!r && (!this.readyState || this.readyState === "complete")) {
      r = true;
      callback();
    }
  };
  t = document.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);
};

class API {
  static get(endpoint, doNotHandleResponses) {
    return fetchFromApi(endpoint, { method: "GET" }, doNotHandleResponses);
  }
  static patch(endpoint, body) {
    return fetchFromApi(endpoint, { method: "PATCH", body });
  }
  static post(endpoint, body) {
    return fetchFromApi(endpoint, { method: "POST", body });
  }
  static delete(endpoint) {
    return fetch(
      `${config.apiUrl}/${endpoint}`,
      createFetchOptionsUsing({
        method: "DELETE",
      })
    );
  }
}

export default API;
