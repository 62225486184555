import { View, Text, Link } from "@react-pdf/renderer";

const StoreInfo = ({ data, styles, appType }) => {
  return (
    <View break>
      {!!data.firebase_urls.length && (
        <View style={{ marginTop: "12px" }}>
          <Text style={styles.title}>Firebase Databases</Text>
          <View style={styles.dottedBox}>
            <Text style={{ fontWeight: "700" }}>URL</Text>
            {data.firebase_urls.map((firebase_url, index) => {
              return (
                <View>
                  <Text style={styles.text}>{firebase_url.url}</Text>
                  <Text style={styles.text}>
                    {data.open
                      ? "App talks to a Firebase Database."
                      : "App doesn't talk to a Firebase Database."}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      )}

      {!!data.emails.length && (
        <View style={{ marginTop: "12px" }}>
          <Text style={styles.title}>Emails</Text>
          <View style={styles.table}>
            <View style={styles.thead}>
              <View style={styles.tr}>
                <View style={styles.th}>
                  <Text>Email</Text>
                </View>
                <View style={styles.th}>
                  <Text>File</Text>
                </View>
              </View>
            </View>
            {data.emails.map((email) => {
              return (
                <View style={styles.tr}>
                  <View style={styles.td}>
                    <Text style={styles.text}>{email.emails.join()}</Text>
                  </View>
                  <View style={styles.td}>
                    <Text style={styles.text}>{email.path}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      )}

      {!!data.secrets?.length && (
        <View style={{ marginTop: "12px" }}>
          <Text style={styles.title}>Trackers</Text>
          <View style={styles.dottedBox}>
            <Text style={styles.subTitle}>TRACKER</Text>
            {data.trackers.trackers.map((tracker) => {
              return (
                <View>
                  <Text style={[styles.text, { fontWeight: 700 }]}>
                    {tracker.name}
                  </Text>
                  <Text style={styles.text}>{tracker.categories}</Text>
                  <Text style={styles.text}>{tracker.url}</Text>
                </View>
              );
            })}
          </View>
        </View>
      )}

      {!!data.secrets?.length && (
        <View style={{ marginTop: "12px" }}>
          <Text style={styles.title}>Hardcoded Secrets</Text>
          <View style={styles.dottedBox}>
            <Text style={styles.subTitle}>POSSIBLE SECRETS</Text>
            {data.secrets.map((secret) => {
              return (
                <View>
                  <Text style={styles.text}>{secret}</Text>
                </View>
              );
            })}
          </View>
        </View>
      )}

      <View style={{ marginTop: "12px" }}>
        <Text style={styles.title}>
          {appType === "apk"
            ? "PlayStore Information"
            : "App Store Information"}
        </Text>
        {appType === "apk" ? (
          <View style={styles.dottedBox}>
            <View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Title:</Text>
                <Text style={styles.text}>{data.playstore_details.title}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Score:</Text>
                <Text style={styles.text}>{data.playstore_details.score}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Installs:</Text>
                <Text style={styles.text}>
                  {data.playstore_details.installs}
                </Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Price:</Text>
                <Text style={styles.text}>{data.playstore_details.price}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>
                  Android Version Support:
                </Text>
                <Text style={styles.text}>
                  {data.playstore_details.androidVersionText}
                </Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Category:</Text>
                <Text style={styles.text}>{data.playstore_details.genre}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Play Store URL:</Text>
                <Text
                  style={styles.text}
                >{`Play Store URL: ${data.playstore_details.url}`}</Text>
              </View>
              <View
                style={[
                  styles.section,
                  { display: "flex", flexDirection: "column", paddingTop: 12 },
                ]}
              >
                <Text style={styles.subTitle}>Developer Details:</Text>
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={styles.text}>
                    {data.playstore_details.developer}
                  </Text>
                  <Text style={styles.text}>
                    {data.playstore_details.developerId}
                  </Text>
                  <Text style={styles.text}>
                    {data.playstore_details.developerAddress}
                  </Text>
                  <Text style={styles.text}>
                    {data.playstore_details.developerWebsite}
                  </Text>
                  <Text style={styles.text}>
                    {data.playstore_details.developerEmail}
                  </Text>
                </View>
              </View>
              <Text
                style={styles.text}
              >{`Release Date: ${data.playstore_details.released}`}</Text>
              <Text
                style={styles.text}
              >{`Privacy Policy: ${data.playstore_details.privacyPolicy}`}</Text>
              <Text
                style={styles.text}
              >{`Description: ${data.playstore_details.description}`}</Text>
            </View>
          </View>
        ) : (
          <View style={styles.dottedBox}>
            <View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Title:</Text>
                <Text style={styles.text}>{data.appstore_details.title}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Score:</Text>
                <Text style={styles.text}>{data.appstore_details.score}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Price:</Text>
                <Text style={styles.text}>{data.appstore_details.price}</Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>Category:</Text>
                <Text style={styles.text}>
                  {data.appstore_details.category.join(", ")}
                </Text>
              </View>
              <View style={styles.infoSection}>
                <Text style={styles.highlightedText}>App Store URL:</Text>
                <Link
                  style={styles.text}
                  src={data.appstore_details.itunes_url}
                >
                  {data.appstore_details.itunes_url}
                </Link>
              </View>
              <View>
                <Text style={styles.highlightedText}>Developer Details:</Text>
                <View style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={styles.text}>
                    {data.appstore_details.developer}
                  </Text>
                  <Text style={styles.text}>
                    {data.appstore_details.developerId}
                  </Text>
                  <Text style={styles.text}>
                    {data.appstore_details.developer_website}
                  </Text>
                  <Text style={styles.text}>
                    {data.appstore_details.developer_url}
                  </Text>
                </View>
              </View>

              <Text style={styles.text}>
                {`Supported Devices: ${data.appstore_details.supported_devices.join(
                  ", "
                )}`}
              </Text>
              <Text
                style={styles.text}
              >{`Description: ${data.appstore_details.description}`}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
export default StoreInfo;
