import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  logo: {
    width: "40%",
    marginBottom: 20,
  },
  screenshot: {
    height: 300,
    marginTop: 20,
  },
  title: {
    marginBottom: 20,
  },
  gauge: {
    height: 70,
  },
  scoreCharts: {
    marginTop: 50,
  },
  dateContainer: {
    marginTop: -3,
  },
});

export default styles;
