import React from "react";

const messageIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.86398 8.3426H9.71181V6.97304H2.86398V8.3426ZM2.86398 5.60344H12.4509V4.23388H2.86398V5.60344ZM13.8208 11.0817H10.3969V14.4364C8.83629 14.1187 7.65778 12.7354 7.65778 11.0817H1.49474V1.49472H13.8208V11.0817ZM13.8207 0.125H1.49457C0.73925 0.125 0.125 0.73925 0.125 1.49457V11.0815C0.125 11.8368 0.73925 12.4511 1.49457 12.4511H6.48731C7.07828 14.4287 8.91418 15.875 11.0815 15.875H11.7663V12.4511H13.8207C14.576 12.4511 15.1902 11.8368 15.1902 11.0815V1.49457C15.1902 0.73925 14.576 0.125 13.8207 0.125Z"
      fill="#3F51B5"
    />
  </svg>
);

export default messageIcon;
