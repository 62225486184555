import React from "react";
import useStyles from "./styles";
import SectionWrapper from "../components/sectionWrapper";
import { Field } from "react-final-form";
import Form from "components/FormComponents/Form";
import { FormCheckInput } from "components/FormComponents/Inputs";

const EmailPreferences = ({ loading }) => {
  const classes = useStyles();
  return (
    <Form onSubmit={() => {}}>
      <SectionWrapper
        title="Email Preferences"
        subtitle="Change your email notifications settings from here."
      >
        <label className={classes.inputLabel}>System Notifications</label>
        <Field
          name="notifyFinishScan"
          component={FormCheckInput}
          text="Notify me when a Scan is finished."
        />
        <Field
          name="notifyThresholdReached"
          component={FormCheckInput}
          text="Notify me when the PIIs Threshold is reached."
        />
        <Field
          name="notify12hours"
          component={FormCheckInput}
          text="Notify me when Zendata hasen’t received info from a database after 12 hours."
        />
        <Field
          name="weeklyEnv"
          component={FormCheckInput}
          text="Enviroments Recap: Weekly"
        />
        <label className={classes.inputLabel}>Newsletter</label>
        <Field
          name="blog"
          component={FormCheckInput}
          text="Zendata Blog (Weekly)"
        />
        <Field
          name="news"
          component={FormCheckInput}
          text="Zendata News (Monthly)"
        />
      </SectionWrapper>
    </Form>
  );
};
export default EmailPreferences;
