import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Map from "./Map/Map";
import SVGContainer from "../SVGContainer/SVGContainer";
import ChartLabel from "charts/Consents/Map/ChartLabel";
import Loader from "components/Loader";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "calc(100% - 10px)",
    height: "100%",
    display: "flex",
  },
}));

const DataStructure = ({
  data,
  colors,
  tooltip,
  relative,
  strokes,
  margin,
  CustomTooltip,
  withChart,
  loading,
}) => {
  const classes = useStyles();

  if (!data) return null;
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {loading ? (
        <Loader />
      ) : (
        <Box className={classes.chartContainer}>
          <SVGContainer disableScrolling>
            <Map
              data={data}
              colors={colors}
              tooltip={tooltip}
              relative={relative}
              strokes={strokes}
              margin={margin}
              CustomTooltip={CustomTooltip}
            />
            <ChartLabel data={data} show={withChart} />
          </SVGContainer>
        </Box>
      )}
    </Box>
  );
};

export default DataStructure;
