import React, { Component } from "react";
import clsx from "clsx";
import ResizeObserver from "resize-observer-polyfill";
import debounce from "lodash.debounce";
import { withStyles } from "@material-ui/core";
import "./styles.scss";

class SVGContainer extends Component {
  container = null;

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };

    this.container = React.createRef();
    this.svgContainer = React.createRef();
    this.observer = new ResizeObserver(
      debounce(this.updateComputedStyles, 300)
    );

    this.body = document.getElementsByTagName("body")[0];
  }

  updateComputedStyles = () => {
    if (this.container && this.container.current) {
      const { width, height } = window.getComputedStyle(this.container.current);
      if (
        this.state.width !== `${width}px` ||
        this.state.height !== `${height}px`
      ) {
        this.setState({
          width: parseInt(width.replace("px", "")),
          height: parseInt(height.replace("px", "")),
        });
      }
    }
  };

  componentDidMount() {
    this.updateComputedStyles();
    this.observer.observe(this.svgContainer.current);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.svgContainer.current);
  }

  renderChildren = () => {
    const { width, height } = this.state;
    if (width && height)
      return React.Children.map(this.props.children, (child) => {
        return React.cloneElement(child, {
          width,
          height,
        });
      });
    return null;
  };

  render() {
    const {
      geometricPresicion,
      className = "",
      transition = true,
      classes = {},
    } = this.props;
    return (
      <div ref={this.svgContainer} className={classes.container}>
        <svg
          ref={this.container}
          className={clsx(
            "container-chart",
            className,
            classes.chart,
            geometricPresicion ? "geometric-precision" : "",
            !transition && "disableTransition"
          )}
        >
          {this.renderChildren()}
        </svg>
      </div>
    );
  }
}

export default withStyles({
  container: {
    width: "100%",
    height: "100%",
  },
  chart: {
    width: "100%",
    height: "100%",
  },
})(SVGContainer);
