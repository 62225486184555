import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    fontFamily: "Aldrich, sans-serif",
    fontSize: 24,
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "48px",
    letterSpacing: "normal",
    color: theme.palette.grey[800],
    "& span": {
      color: theme.palette.blue[400],
    },
  },
  button: {
    marginLeft: "auto",
    fontFamily: "Inter, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "1px",
    color: theme.palette.blue["young"],
    zIndex: 1,
  },
  buttonIcon: {
    marginLeft: theme.spacing(0.5),
  },
  pattern: {
    position: "absolute",
    right: 0,
    top: 0,
  },

  cardContainer: {
    width: "100%",
    position: "relative",
    padding: theme.spacing(7, 2),
    marginTop: theme.spacing(4),
    zIndex: 0,

    "&:before": {
      content: "''",
      position: "absolute",
      backgroundColor: "#f9f9fa",
      right: "100%",
      top: 0,
      bottom: 0,
      width: "100%",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundColor: "#f9f9fa",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: -1,
      borderTopRightRadius: 32,
      borderBottomRightRadius: 32,
    },
  },
  backdrop: {
    position: "absolute",
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  paginationContainer: {
    paddingBottom: theme.spacing(5),
  },
}));
