import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import {
  FormTextInput,
  FormSelectInput,
  FormCheckInput,
} from "../../components/inputs";
import { Field } from "react-final-form";
import SectionWrapper from "../../components/sectionWrapper";
import { useAsync } from "react-use";
import { getDrivers, getProviders } from "redux/_databases/databases.service";
import isNil from "lodash.isnil";
import DeleteButton from "components/SettingsSections/components/deleteButton";
import { storages } from "components/SettingsSections/Datasource/constants";
import useStyles from "./styles";

const Section1 = (props) => {
  const { loading, onDeleteClick, showDeleteButton, countries } = props;

  const { loading: loadingProviders, value: providers } = useAsync(
    getProviders,
    []
  );
  const { loading: loadingDrivers, value: drivers } = useAsync(getDrivers, []);

  const parsedProviders = useMemo(
    () =>
      !isNil(providers)
        ? providers.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        : [],
    [providers]
  );

  const parsedDrivers = useMemo(
    () =>
      !isNil(drivers)
        ? drivers.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        : [],
    [drivers]
  );

  const identity = (value) => value;
  const classes = useStyles();
  return (
    <SectionWrapper
      title="Basic Information"
      subtitle="All fields are required."
      leftContent={showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
    >
      <Box className={classes.row}>
        <Field
          name="name"
          component={FormTextInput}
          label="Name"
          className={classes.fieldSm}
          helperText="Try to be as clear as possible"
          disabled={loading}
          parse={identity}
        />
        <Field
          name="driver_id"
          label="Driver"
          component={FormSelectInput}
          helperText="Choose a type of Datasource"
          className={classes.fieldSm}
          disabled={loadingDrivers}
          options={parsedDrivers}
          parse={identity}
        />
      </Box>
      <Box className={classes.row}>
        <Field
          name="provider_id"
          label="Provider"
          component={FormSelectInput}
          helperText="Choose a Datasource provider"
          className={classes.fieldSm}
          disabled={loadingProviders}
          options={parsedProviders}
          parse={identity}
        />
        <Field
          name="encrypted"
          label="Encrypted"
          component={FormCheckInput}
          text="This is an encrypted Datasource"
          style={{ width: 220 }}
          disabled={loading}
          parse={identity}
          type="checkbox"
        />
      </Box>
      <Box className={classes.row}>
        <Field
          name="host"
          label="Host Ip"
          component={FormTextInput}
          helperText="Datasource’s IP"
          className={classes.fieldSm}
          disabled={loading}
          parse={identity}
        />
        <Field
          name="port"
          label="Port"
          component={FormTextInput}
          helperText="Datasource’s port number"
          className={classes.fieldSm}
          disabled={loading}
          parse={identity}
        />
      </Box>
      <Box className={classes.row}>
        <Field
          name="country_id"
          label="Country"
          component={FormSelectInput}
          helperText="Useful for better segmenting data"
          className={classes.fieldSm}
          disabled={loading}
          options={countries}
          parse={identity}
        />
        <Field
          name="storage"
          label="Storage"
          component={FormSelectInput}
          className={classes.fieldSm}
          disabled={loading}
          options={storages}
          parse={identity}
        />
      </Box>
    </SectionWrapper>
  );
};

export default Section1;
