import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#cfdff8",
    height: "100%",
    borderRadius: "4px",
    padding: "24px",
  },
  inputLabel: {
    color: theme.palette.blue[500],
    display: "block",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  filter: {
    paddingTop: "16px",
  },
}));
