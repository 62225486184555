import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  sites: {
    color: theme.palette.grey[800],
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
    textAlign: "center",
  },
  xsTable: {
    "& th, & td": {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  xsTableTable: {
    border: "1px solid #E0E1E3",
    width: "100%",
    "&:not(&:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  mdTable: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "table",
    },
  },
  th: {
    textTransform: "uppercase",
    textAlign: "center",
    padding: theme.spacing(0, 2),
    position: "relative",
  },
  td: {
    padding: theme.spacing(2),
  },
  title: {},
  tr: {
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "lowercase",
    borderBottom: "1px solid #E0E1E3",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.white[200],
    },
  },
  domain: {
    paddingLeft: 0,
    width: "45%",
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  thirdPartyTrackers: {
    width: "20%",
  },
  fingerprintingScore: {
    width: "20%",
  },
  httpsTraffic: {
    width: "15%",
  },
  infoIcon: {
    position: "absolute",
    top: 8,
    right: 14,
    width: "0.8em",
    opacity: 0.9,
    cursor: "pointer",
  },
  popover: {
    maxWidth: 330,
    padding: 15,
    margin: 0,
  },
  dot: {
    height: 10,
    width: 10,
    marginRight: theme.spacing(1),
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
  },
}));

export default styles;
