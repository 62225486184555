import CONSTANTS from "redux/_scans/scans.constants";
import mapArrayToObject from "utils/map-array-to-object";
import unionBy from "lodash.unionby";
import isEmpty from "lodash.isempty";

const initialState = {
  last: 0,
  loading: false,
  inProgress: {},
  data: [],
  scanList: [],
};

export const scans = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.GETSOME_REQUEST:
      return state;
    case CONSTANTS.GETSOME_SUCCESS:
      return {
        ...state,
        scanList: {
          ...state.scanList,
          ...mapArrayToObject("id", data.scans),
        },
      };
    case CONSTANTS.GETSOME_FAILURE:
      return state;
    case CONSTANTS.GETSOME_DONE:
      return state;
    case CONSTANTS.SCANS_SET_LIST:
      return {
        ...state,
        scanList: data,
      };
    case CONSTANTS.SCANS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.SCANS_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SCANS_SAVE:
      return {
        ...state,
        data: isEmpty(state.data)
          ? unionBy(state.data, data, "id")
          : unionBy(state.data, [data], "id"),
      };
    case CONSTANTS.SCANS_SET_LAST:
      return {
        ...state,
        last: Math.max(state.last, data),
      };
    case CONSTANTS.SCANS_SET_IN_PROGRESS:
      return {
        ...state,
        inProgress: data,
      };
    default:
      return state;
  }
};
