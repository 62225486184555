import React from "react";
import useStyles from "./styles";
import { Box, Typography, Button } from "@material-ui/core";
import Loader from "components/Loader";
import Preview from "components/Consents/Collector/Preview";

const CollectorWrapper = (props) => {
  const classes = useStyles();

  const {
    loading,
    primaryText = "",
    secondaryText = "",
    buttonDisabled,
    onButtonClick,
    children,
    showAddButton = true,
    previewPage,
    previewData,
  } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.leftContent}>
          <Typography className={classes.primaryText}>{primaryText}</Typography>
          <Box className={classes.secondaryTitleContent}>
            <Typography className={classes.secondaryText}>
              {secondaryText}
            </Typography>
            {showAddButton && (
              <Button
                disabled={loading || buttonDisabled}
                onClick={onButtonClick}
                className={classes.addButton}
                variant="contained"
              >
                ADD NEW
              </Button>
            )}
          </Box>
          {children}
          {loading && <Loader style={{ alignItems: "flex-start" }} />}
        </Box>
        <Box className={classes.rightContent}>
          <Typography className={classes.rightTitle}>Preview</Typography>
          <Preview data={previewData} initialPage={previewPage} />
        </Box>
      </Box>
    </Box>
  );
};

export default CollectorWrapper;
