import Popover from "@material-ui/core/Popover";
import React from "react";

const policyModel = {
  advertising: [
    "Advertising",
    <p>
      After visiting our website, you may encounter targeted interest-based
      advertising based on your use of our website and other websites on the
      Internet. In most cases, such advertising is based on your
      cookie-generated data. Therefore, if you wish to limit the use of personal
      data for advertising purposes, please make sure to adjust your cookie
      settings accordingly (e.g., through the cookie consent banner available on
      our website). You can control how such advertising is shown to you by
      consulting the guide powered by the Digital Advertising Alliance available
      at https://youradchoices.com. For more information on opting-out from
      advertising features on your device, please visit
      https://www.networkadvertising.org .
    </p>,
  ],
  analytics: [
    "Analytics",
    <>
      <p>
        When you browse our website, we collect or have access to certain
        technical analytics data collected from you by our analytics service
        providers. Such data includes the following information:
      </p>
      <ul>
        <li>
          Your activity on our website (time of visit, pages visited, products
          viewed, time spent on each page, clicks, scroll depth, interaction
          with widgets);
        </li>
        <li>URL addresses from which you access our website;</li>
        <li>Your browser type and version;</li>
        <li>Your operating system;</li>
        <li>Your device details; and</li>
        <li>Your other online behavior.</li>
      </ul>
      <p>
        We use your analytics data to analyze what kind of users access and use
        our website, see which products are interesting to you, measure your
        engagement, improve our content, develop new products and services, and
        investigate and prevent security issues and abuse. In most cases, such
        analytics data is non-personal and it does not allow us to identify you
        as a natural person. However, some of such data like your IP address,
        unique device ID, or cookie-related data may be considered personal data
        and we will make sure that we have the necessary legal basis for
        processing such data. When we process your analytics data that is
        personal data, we rely on the <i>‘legitimate interest’</i> (i.e., to
        analyze and improve our website) and <i>‘your consent’</i> legal bases.
      </p>
    </>,
  ],
  behavior: [
    "Behavior",
    <>
      <p>
        To monitor your behavior on our website and other websites on the
        Internet, we or our third-party service providers may collect data about
        your online behavior and analytics data. Usually, such data is generated
        by cookies that allow us to create reports about your use of our
        website. Your behavior data includes the following information:
      </p>
      <ul>
        <li>
          Your activity on our website (time of visit, pages visited, products
          viewed, time spent on each page, clicks, scroll depth, interaction
          with widgets);
        </li>
        <li>URL addresses from which you access our website;</li>
        <li>Your browser type and version;</li>
        <li>Your operating system;</li>
        <li>Your device details; and</li>
        <li>Your other online behavior.</li>
      </ul>
      <p>
        We use such data to analyze how users behave with our website, which
        parts of the website you are interested in, and develop our new products
        and services. In most cases, such analytics data is non-personal and it
        does not allow us to identify you as a natural person. However, some of
        such data like your IP address, unique device ID, or cookie-related data
        may be considered personal data and we will make sure that we have the
        necessary legal basis for processing such data. When we process your
        analytics data that is personal data, we rely on the{" "}
        <i>‘legitimate interest’</i> (i.e., to analyze and improve our website)
        and <i>‘your consent’</i>
        legal bases. If possible, to ensure your privacy, your IP address is
        anonymized and not combined with other information that we hold about
        you.
      </p>
      <p>
        Please note that you can control how your cookie-related data is
        processed by adjusting your cookie settings accordingly in our cookie
        consent banner or your browser.
      </p>
    </>,
  ],
  payment: [
    "Payment",
    <p>
      When you make a payment, we or our third-party payment processors collect
      your personal data that is necessary for payment processing purposes,
      namely, your name, company name, billing address, and payment details
      (e.g., credit card number, expiration date, security codes, or PayPal
      details). The types of collected personal data depend on the payment
      method that you choose. In most cases, we do not have access to your full
      payment data; only a part of your personal data is made available to us by
      the payment processor. Your payment data is used to process payments and
      maintain our accountancy records. The legal bases on which we rely are
      <i>‘performing our contractual obligations’</i> and{" "}
      <i>‘pursuing our legitimate interests’</i> (i.e., to administer our
      business). We store such data in our accountancy records for the time
      period prescribed by law.
    </p>,
  ],
  social: [
    "Social",
    <>
      <p>
        If you use our website through your social media account (for example,
        if you choose to sign in by using your social media credentials or share
        website content on your social media), we receive information about you
        from the third-party social media service provider that you use. The
        information that is made available to us depends on your privacy
        settings. In most cases, it includes your full name, username, account
        ID, email address, and profile photo. Some of this personal data may
        become available to other users of our website if you decide to leave a
        public comment or review.
      </p>
      <p>
        We use social media data made available to us to set up and maintain
        your user account, provide you with the requested services, contact you,
        if necessary, and maintain our website. The legal bases on which we rely
        are <i>‘performing our contractual obligations’</i> and{" "}
        <i>‘pursuing our legitimate interests’</i> (i.e., to administer our
        business). We store such data until you delete your user account or
        remove your social media account.
      </p>
      <p>
        Please note that the information you decide to share on social media may
        be visible to a global audience. Therefore, you should only share the
        information on social media that you are comfortable sharing. All
        information that you share on your social media is subject to the social
        media service provider’s individual privacy policies. Our privacy policy
        does not apply to such information.
      </p>
    </>,
  ],
};

export default ({
  classes,
  model,
  policyPopoverOpened,
  setPolicyPopoverOpen,
}) =>
  policyModel[model] ? (
    <Popover
      id="PolicyPopover"
      open={!!policyPopoverOpened}
      onClose={() => {
        setPolicyPopoverOpen({});
      }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 100, left: 1020 }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.policyPopover}>
        <h3 className={classes.policyTitle}>{policyModel[model][0]}</h3>
        <div className={classes.policyParagraph}>{policyModel[model][1]}</div>
      </div>
    </Popover>
  ) : null;
