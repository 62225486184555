import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getDbs = (envId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/environments/${envId}/databases?sort=name&pageSize=1000`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getAllDbs = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/databases?sort=name&pageSize=1000`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const createDb = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  let url = `${config.apiUrl}/databases`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const updateDb = (data) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const { id } = data;

  const url = `${config.apiUrl}/databases/${id}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getDatabasesStatus = (envId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/environments/${envId}/test_connection`;

  return fetch(url, requestOptions).then(handleFetchResponse);
};
export const checkDatabasesStatus = (envId) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/environments/${envId}/test_connection`;

  return fetch(url, requestOptions).then(handleFetchResponse);
};

export const getProviders = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/providers`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
export const getDrivers = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/drivers`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const testConnection = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/databases/test_connection`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const deleteDb = (id) => {
  const resuestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/databases/${id}`;

  return fetch(url, resuestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
