import React, { useState, useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import useStyles from "./styles";
import ChangeEmail from "components/Profile/Account/components/ChangeEmail";
import ChangePassword from "components/Profile/Account/components/ChangePassword";
import { connect } from "react-redux";
import { getData, updateData } from "redux/_account/account.async.actions";
import { useSnackbar } from "notistack";
import SectionWrapper from "../components/sectionWrapper";
import Form from "components/FormComponents/Form";
import { Field } from "react-final-form";
import { FormTextInput } from "components/FormComponents/Inputs";
import TextField from "components/Inputs/Text";

export const updateProfileFunctions = (
  dispatch,
  enqueueSnackbar,
  { setOpenEmail = undefined, setOpenPassword = undefined }
) => {
  const getAccountData = () => {
    dispatch(getData());
  };

  const updateName = ({ first_name, last_name }) => {
    dispatch(updateData({ first_name, last_name }))
      .then(() =>
        enqueueSnackbar("Data successfully updated!", {
          variant: "success",
        })
      )
      .catch();
  };

  const saveEmail = setOpenEmail
    ? (email) => {
        return dispatch(updateData({ email, username: email }))
          .then(() => {
            getAccountData();
            setOpenEmail(false);
            enqueueSnackbar("Email successfully updated!", {
              variant: "success",
            });
          })
          .catch();
      }
    : undefined;

  const changePassword = setOpenPassword
    ? ({ newPassword, oldPassword }) => {
        return dispatch(
          updateData(
            { password: newPassword, old_password: oldPassword },
            () => {
              getAccountData();
              setOpenPassword(false);
              enqueueSnackbar("Password successfully updated!", {
                variant: "success",
              });
            }
          )
        ).catch((x) => {
          if (x.title) {
            enqueueSnackbar(`New Password: ${x.title}`, {
              variant: "error",
            });
          } else if (x.data instanceof Array)
            x.data.forEach((errorMsg) => {
              enqueueSnackbar(errorMsg, {
                variant: "error",
              });
            });
          else
            enqueueSnackbar(x.data, {
              variant: "error",
            });
          return true;
        });
      }
    : undefined;

  const deleteAccount = () => {};

  return {
    getAccountData,
    updateName,
    saveEmail,
    changePassword,
    deleteAccount,
  };
};

const Account = (props) => {
  const classes = useStyles();
  const { data, loading, dispatch } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const {
    getAccountData,
    updateName: onSubmit,
    saveEmail,
    changePassword,
  } = updateProfileFunctions(dispatch, enqueueSnackbar, {
    setOpenEmail: setOpenEmailDialog,
    setOpenPassword: setOpenPasswordDialog,
  });

  useEffect(() => {
    getAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { first_name: firstName, last_name: lastName, plan } = data;

  const name = firstName && lastName ? `${firstName} ${lastName}` : "";

  return (
    <>
      <ChangeEmail
        open={openEmailDialog}
        onClose={() => setOpenEmailDialog(false)}
        onConfirm={saveEmail}
      />
      <ChangePassword
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
        onConfirm={changePassword}
      />
      <Form onSubmit={onSubmit} initialValues={data} loading={loading}>
        <SectionWrapper
          title="Your Data"
          subtitle="This is what we know from you."
          name={name}
          plan={plan}
        >
          <Box display="flex">
            <Field
              name="first_name"
              component={FormTextInput}
              className={classes.fieldSm}
              disabled={loading}
              label="First Name"
              style={{
                width: 208,
                maxWidth: 208,
                paddingBottom: 16,
                marginRight: 16,
              }}
            />
            <Field
              name="last_name"
              component={FormTextInput}
              className={classes.fieldSm}
              disabled={loading}
              label="First Name"
              style={{ width: 208, maxWidth: 208, paddingBottom: 16 }}
            />
          </Box>
          <Box display="flex">
            <TextField
              value={data.email}
              className={classes.fieldSm}
              label="Email"
              InputProps={{ readOnly: true }}
              helperText={
                <Button
                  className={classes.helperButton}
                  color="secondary"
                  onClick={() => setOpenEmailDialog(true)}
                >
                  Change email
                </Button>
              }
              style={{
                width: 208,
                maxWidth: 208,
                paddingBottom: 16,
                marginRight: 16,
              }}
            />

            <TextField
              value="••••••••"
              name="password"
              className={classes.fieldSm}
              label="Password"
              InputProps={{ readOnly: true }}
              helperText={
                <Button
                  className={classes.helperButton}
                  color="secondary"
                  onClick={() => setOpenPasswordDialog(true)}
                >
                  Change password
                </Button>
              }
              style={{ width: 208, maxWidth: 208, paddingBottom: 16 }}
            />
          </Box>
        </SectionWrapper>
      </Form>
    </>
  );
};

const mapStateToProps = ({ account: { data, errors, loading } }) => ({
  data,
  errors,
  loading,
});

export default connect(mapStateToProps)(Account);
