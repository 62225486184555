export const columns = [
  {
    id: "name",
    label: "Name",
    color: "#4f4f4f ",
  },
  {
    id: "full_consent",
    label: "Full Consent",
    align: "right",
    color: "#1a3586",
  },
  {
    id: "opted_in",
    label: "Opted-in",
    align: "right",
    color: "#219653",
  },
  {
    id: "opted_out",
    label: "Opted-out",
    align: "right",
    color: "#eb5757",
  },
];
