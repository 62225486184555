import { Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import DeleteButton from "components/Consents/Collector/Policies/components/deleteButton";
import {
  FormSelectInput,
  FormTextInput,
} from "components/SettingsSections/components/inputs";
import isNil from "lodash.isnil";
import React from "react";
import { Field, Form } from "react-final-form";
import { categories } from ".";
import { formStyles as useStyles } from "./styles";

const CookieForm = ({ id, data, onCancel, onSubmit, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <div className={classes.boxHeader}>
        <Typography className={classes.requiredText}>
          All fields marked <span className={classes.asterisk}>*</span> are
          required.
        </Typography>
        <IconButton onClick={onCancel} className={classes.closeButton}>
          <Close />
        </IconButton>
      </div>
      <Form
        onSubmit={(x) => {
          onSubmit(x);
          onCancel();
        }}
        initialValues={data || {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.row}>
              <Field
                name="name"
                component={FormTextInput}
                label="Name"
                className={classes.col}
                placeholder="Name for the cookie"
              />
              <Field
                name="provider"
                component={FormTextInput}
                className={classes.colRight}
                label="Provider"
                placeholder="Provider for the cookie"
              />
            </div>
            <div className={classes.row}>
              <Field
                name="expiration"
                component={FormTextInput}
                className={classes.col}
                label="Expiration"
                placeholder="Expiration for the cookie"
              />
              <Field
                name="purpose"
                className={classes.purposeSelect}
                component={FormSelectInput}
                label="Purpose"
                placeholder="Purpose of the cookie"
                options={categories}
              />
            </div>
            <div className={classes.buttonsContainer}>
              {!isNil(id) && (
                <DeleteButton
                  name={data.name}
                  onClick={() => {
                    onCancel();
                    onDelete(id);
                  }}
                />
              )}
              <Button
                className={classes.updateButton}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default CookieForm;
