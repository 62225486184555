import { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "../styles";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
} from "@material-ui/core";
import { CardsContainer } from "components/common/containers/containers";
import { RepositoryScannerCard } from "components/Cards/Cards";
import Pagination from "../components/pagination";
import {
  getReportsData,
  getReportsErrorState,
  getReportsMeta,
  getReportLoadingState,
} from "redux/_reports/reports.selectors";
import { getReports, deleteReport } from "redux/_reports/reports.async.actions";
import Dialog from "components/Dialog";
import Loader from "components/Loader";

const pageSize = 6;
const environment = "repository";

const ListRepositoryScans = ({
  data = [],
  meta: { totalRows, pageCount },
  getData,
  loading,
  removeReport,
  size,
}) => {
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const [actions, addAction] = useState(0);
  const [scanToRemove, setScanToRemove] = useState();
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  useEffect(() => {
    getData({
      pageSize,
      page,
      isDemo: false,
      status: "successfully",
      environment: environment,
    });
  }, [actions]);

  const updatePage = async (pageNumber) => {
    await getData({
      pageSize,
      page: pageNumber,
      status: "successfully",
      environment: environment,
    });
    setPage(pageNumber);
  };

  const handleDisplayModal = () => {
    setDisplayDeleteModal(!displayDeleteModal);
  };

  const removeRepositoryScan = async () => {
    handleDisplayModal();
    await removeReport(scanToRemove);
    addAction(actions + 1);
  };

  const handleDeleteSelection = (scan) => {
    setScanToRemove(scan);
    handleDisplayModal();
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
      <section className={clsx(classes.container)}>
        <CardsContainer container>
          {data && data.length > 0 ? (
            data.map((item, i) => {
              return (
                <RepositoryScannerCard
                  name={item.resource}
                  scan={item.pk}
                  deleteOnClick={handleDeleteSelection}
                />
              );
            })
          ) : (
            <Box py={3} textAlign="center" width="100%">
              <Typography>No reports were found.</Typography>
            </Box>
          )}
        </CardsContainer>
        <Box
          textAlign="center"
          className={clsx("container", classes.paginationContainer)}
          mt={7}
        >
          <Pagination
            onPageChange={updatePage}
            totalRows={totalRows}
            pageCount={pageCount}
            page={page}
          />
        </Box>
      </section>

      <Dialog
        title="Delete Report"
        handleClose={handleDisplayModal}
        open={displayDeleteModal}
        customClasses={classes}
      >
        <DialogContent>
          Are you sure you want delete this report? If you remove it, you wouldn
          {"'"}t be able to check these results again.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisplayModal} color="primary">
            No
          </Button>
          <Button onClick={removeRepositoryScan} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getReportsData(state),
  error: getReportsErrorState(state),
  loading: getReportLoadingState(state),
  meta: getReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (filters) => dispatch(getReports(filters)),
  removeReport: (id) => dispatch(deleteReport(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListRepositoryScans);
