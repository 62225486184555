import React from "react";
import { CardContent, CardHeader, Card as MuiCard } from "@material-ui/core";
import useStyles from "./styles";
const Card = ({ title, children }) => {
  const classes = useStyles();
  return (
    <MuiCard
      classes={{
        root: classes.root,
      }}
    >
      <CardHeader
        title={title}
        classes={{
          root: classes.titleRoot,
          title: classes.title,
        }}
      />
      <CardContent classes={{ root: classes.content }}>{children}</CardContent>
    </MuiCard>
  );
};

export default Card;
