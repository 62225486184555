import React from "react";
import ReactDOM from "react-dom";

const HoverableArea = ({
  value,
  width,
  height,
  onMouseOver,
  container,
  position,
}) => {
  const handleMouseOver = () => onMouseOver(value);
  const resetMouseOver = () => onMouseOver(undefined);

  return container && container.current
    ? ReactDOM.createPortal(
        <g transform={`translate(${position})`}>
          <rect
            fill="transparent"
            width={width}
            height={height}
            transform={`translate(${-width / 2})`}
            onMouseOver={handleMouseOver}
            onMouseOut={resetMouseOver}
          />
        </g>,
        container.current
      )
    : null;
};

export default HoverableArea;
