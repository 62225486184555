import { useEffect, useState } from "react";
import { getCodeScannerAssets } from "redux/_settings/settings.service";
import Table from "../Table";
import useStyles from "./styles";

const CodeScannerGuide = () => {
  const classes = useStyles();
  const [assets, setAssets] = useState({});

  useEffect(() => {
    getCodeScannerAssets().then((x) => {
      setAssets(x);
    });
  }, []);

  return (
    <div>
      <div className={classes.row}>
        <h3 className={classes.title}>Overview</h3>
      </div>
      <p>
        Zendata PII Source Code Scanner is a command line application that
        allows you to check if your code respects users' privacy.
      </p>
      <p>
        Download the application program and the key file below to use the PII
        Source Code Scanner.
      </p>
      <Table assets={assets} />
      <a
        href="https://zendata-demo-pages.s3.amazonaws.com/zendata-pii-scs/index.html"
        target="_blank"
        rel="noreferrer"
      >
        Example output
      </a>{" "}
      for the source code in the repository{" "}
      <a
        href="https://github.com/telegramdesktop/tdesktop"
        target="_blank"
        rel="noreferrer"
      >
        https://github.com/telegramdesktop/tdesktop
      </a>{" "}
      taken at 02-10-2023
    </div>
  );
};

export default CodeScannerGuide;
