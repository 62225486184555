import React, { useMemo, useState } from "react";
import useStyles from "./styles";
import SectionContent from "../SectionContent";
import ExpandedPanel from "pages/AppReport/Sections/FindingSeverity/ExpandedPanel";
import Table from "../Table";
import { ReactComponent as HighIcon } from "../../Information_tab.svg";
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";

export const severities = [
  {
    label: "HIGH",
    value: "high",
    icon: <HighIcon />,
  },
  {
    label: "MEDIUM",
    value: "warning",
    icon: <ReportProblemOutlined style={{ color: "#ED6C02" }} />,
  },
  {
    label: "INFO",
    value: "info",
    icon: <InfoOutlined />,
  },
  {
    label: "SECURE",
    value: "secure",
    icon: <CheckCircleOutlineOutlined />,
  },
];

const SeverityPanels = ({ data, columns, emptyMessage }) => {
  const [expanded, setExpanded] = useState();
  const formattedData = useMemo(() => {
    const nextData = {};
    const manifestFindings = data;
    severities.map((severity) => {
      nextData[severity.value] = manifestFindings.filter(
        (el) => el.severity === severity.value
      );
    });
    return nextData;
  }, [data]);
  const classes = useStyles();

  try {
    return (
      <SectionContent>
        {severities.map((severity) => (
          <ExpandedPanel
            title={
              <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                {severity.icon}
                {severity.label}
              </div>
            }
            classes={{
              root: "scrollable-element",
              header: classes[severity.value],
            }}
            simpleTitle
            key={severity.label}
            onChange={() =>
              setExpanded(expanded !== severity.value ? severity.value : "")
            }
            expanded={expanded === severity.value}
          >
            {(formattedData[severity.value]?.length || 0) > 0 ? (
              <Table
                data={formattedData[severity.value] || []}
                limitByPage={5}
                columns={columns}
              />
            ) : (
              <span style={{ color: "#868993" }}>{emptyMessage}</span>
            )}
          </ExpandedPanel>
        ))}
      </SectionContent>
    );
  } catch (error) {
    console.error("Error rendering SeverityPanels component:", error);
    return (
      <span style={{ color: "red" }}>
        There was an error loading the data for this section.
      </span>
    );
  }
};

export default SeverityPanels;
