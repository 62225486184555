import React, { useCallback, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTabView } from "redux/_tabDetail/tab.actions";

const DetailTab = () => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.tab.tabView);
  const handleChange = useCallback(
    (event, newValue) => {
      dispatch(setTabView(newValue));
    },
    [dispatch]
  );

  return (
    <Paper square>
      <Tabs
        value={view}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Overview" />
        <Tab label="Scan list" />
        <Tab label="Webhook Settings" />
      </Tabs>
    </Paper>
  );
};

export default DetailTab;
