import React, { useMemo } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTIONS_CONTENT } from "pages/SecurityReport/constants";
import Title from "pages/AppReport/common/Title";
import useStyles from "./styles";
import { ReportProblemOutlined } from "@material-ui/icons";
import clsx from "clsx";
import FieldDetail from "pages/SecurityReport/common/FieldDetail";
import { Card, Tabs } from "pages/SecurityReport/common";

const StatusList = {
  warning: ReportProblemOutlined,
};

const StatusIcon = ({ type, styles }) => {
  const classes = useStyles();
  const Icon = StatusList[type] || StatusList.warning;
  return <Icon classes={{ root: classes[type] }} style={styles} />;
};
const StatusBubble = ({ status }) => {
  const classes = useStyles();
  const label = useMemo(
    () => status[0].toUpperCase() + status.slice(1, status.length),
    [status]
  );

  return <div className={clsx(classes.bubble, classes[status])}>{label}</div>;
};

const Subtitle = ({ children, status = "warning" }) => {
  const classes = useStyles();
  return (
    <div className={classes.subtitleContainer}>
      {status && (
        <StatusIcon
          type={status}
          styles={{ alignSelf: "center", marginRight: "7px" }}
        />
      )}
      <h3 className={classes.subtitle}>{children}</h3>
      {status && <StatusBubble status={"confirmed"} />}
    </div>
  );
};

const MissingHttpOnlyFlag = ({ goToNextSectionAction }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.insecureCookieSetting.key}
      footerBackgroundColor={
        SECTIONS_CONTENT.insecureCookieSetting.backgroundColor
      }
      footerText={
        <div className="privacy-tech">SEE THE missing Secure flag</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.insecureCookieSetting.description}>
        {SECTIONS_CONTENT.insecureCookieSetting.title}
      </Title>
      <Subtitle>{SECTIONS_CONTENT.insecureCookieSetting.subtitle}</Subtitle>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "37px",
        }}
      >
        <FieldDetail
          field={{
            title: "URL",
            value: "https://aitoolkits.com",
          }}
        />
        <FieldDetail
          field={{
            title: "Cookie name",
            value: "editor.",
          }}
        />
        <FieldDetail
          field={{
            title: "Evidence",
            value:
              "The server responded with Set-Cookie header(s) that does not specify the HttpOnly flag: Set-Cookie: editor=0rm58h2ljq8j5q9uslrkunrq7f",
          }}
        />
      </div>
      <Tabs
        id="tabs-missing-http-only-flag"
        tabs={[
          {
            id: "risk",
            label: "RISK",
            content: (
              <Card title="Risk description">
                A cookie has been set without the HttpOnly flag, which means
                that it can be accessed by the JavaScript code running inside
                the web page. If an attacker manages to inject malicious
                JavaScript code on the page (e.g. by using an XSS attack) then
                the cookie will be accessible and it can be transmitted to
                another site. In case of a session cookie, this could lead to
                session hijacking.
              </Card>
            ),
          },
          {
            id: "recommendation",
            label: "RECOMMENDATION",
            content: (
              <Card title="Recommendation">
                Ensure that the HttpOnly flag is set for all cookies.
              </Card>
            ),
          },
          {
            id: "references",
            label: "REFERENCES",
            content: (
              <Card title="References">
                {"https://owasp.org/www-community/HttpOnly".includes(
                  "https"
                ) ? (
                  <a
                    href="https://owasp.org/www-community/HttpOnly"
                    target="_blank"
                    style={{ color: "#868993" }}
                  >
                    https://owasp.org/www-community/HttpOnly
                  </a>
                ) : (
                  "https://owasp.org/www-community/HttpOnly"
                )}
              </Card>
            ),
          },
          {
            id: "classification",
            label: "CLASSIFICATION",
            content: (
              <Card title="Classification">
                CWE : CWE-1004 OWASP Top 10 - 2013 : A5 - Security
                Misconfiguration OWASP Top 10 - 2017 : A6 - Security
                Misconfiguration
              </Card>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default MissingHttpOnlyFlag;
