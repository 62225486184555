import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    viewContainer: {
        position: "relative",
        zIndex: 0,
        height: "calc(100% - 100px)",
    },
    formTitle: {
        ...theme.typography.title,
        color: "#000000",
        fontSize: '20px',
        maxWidth: 768,
        marginTop: theme.spacing(3)
    },
    formDescription: {
        ...theme.typography.body1,
        color: "#868993",
        fontSize: '16px',
        maxWidth: 768,
        marginBottom: theme.spacing(3),
    },  
    formContainer: {
        flexDirection: "column",
        maxWidth: 450,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 500,
        paddingBottom: theme.spacing(6),
    },
    fieldContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        margin: "20px 0",
        alignItems: "flex-start",
        "&.-countries": {
          margin: "20px 0 40px",
        },
    },
    rightContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
    leftContainer: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: 180,
        },
    },
    loaderContainer: {
        top: "40",
    },
    linkButton: {
        paddingTop: "5px",
        color: "#1a3586",
        textTransform: "none",
        textDecoration: "none",
        background: "none",
        border: "none",
        font: "inherit",
        outline: "inherit",
        "&:hover": {
          textDecoration: "underline",
          backgroundColor: "none",
        },
    },
    indentedTwoSpaces: {
        marginLeft: theme.spacing(2),
    },
    listItems: {
        p: 2,
        border: "1px dashed grey",
    },
    instructionsContainer: {
        marginTop: theme.spacing(4),
    },
    label: {
        ...theme.typography.body1,
        minWidth: 184,
        color: "#000000",
        fontSize: '16px',
        lineHeight: "42px",
        [theme.breakpoints.up("sm")]: {
          lineHeight: "inherit",
          padding: theme.spacing(1, 2, 0, 0),
        },
    },
    textField: {
        "& input": {
          paddingTop: 10,
        },
        "&.mini": {
          width: 40,
          marginRight: 10,
        },
        "&.ends": {
          width: 120,
          marginLeft: 40,
        },
        "& > .MuiFormHelperText-contained": {
          marginLeft: 0,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#466DE8",
          },
          "&:hover fieldset": {
            borderColor: "#466DE8",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#466DE8",
          },
        },
        "& .MuiRadio-colorPrimary.Mui-checked": {
            color: "#466DE8",
        },
    },
    tokenField: {
        "& input": {
          paddingTop: 10,
        },
        "&.mini": {
          width: 40,
          marginRight: 10,
        },
        "&.ends": {
          width: 120,
          marginLeft: 40,
        },
        "& > .MuiFormHelperText-contained": {
          marginLeft: 0,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#466DE8",
          },
          "&:hover fieldset": {
            borderColor: "#466DE8",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#466DE8",
          },
        },
        "& .MuiRadio-colorPrimary.Mui-checked": {
            color: "#466DE8",
        },
    },
    radio: {
        "& .MuiRadio-colorPrimary.Mui-checked": {
            color: "#466DE8",
        },
        color: "#466DE8",
      },
    checked: {},
    submitContainer: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    submit: {
        width: '224px',
        marginTop: theme.spacing(4),
    },
    checkBox: {
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: "#466DE8",
        },
    },
    selectInput: {
        color: "#000000",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    selectDisplay: {
        display: "flex",
        paddingLeft: theme.spacing(1.5),
        alignItems: "center",
        width: "100%",
        height: "54px",
        paddingRight: theme.spacing(2),
      }
}));

export default useStyles;
