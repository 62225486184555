import React from "react";
import {
  FlexibleXYPlot,
  LineSeries,
  VerticalBarSeries,
  XAxis,
} from "react-vis";
import { Typography, Box } from "@material-ui/core";
import Loader from "components/Loader";

const styles = {
  line: {
    fill: "none",
  },
  gridLine: {
    stroke: "rgba(119, 119, 119, 0.08)",
    strokeWidth: 0.5,
  },
  axis: {
    line: {
      stroke: "#000",
      strokeWidth: 0.5,
    },
  },
};

const ScansTrend = (props) => {
  const { data } = props;

  const isLoading = !data.length;

  const totalPII = !isLoading
    ? data.reduce((acum, { allPii }) => acum + allPii, 0)
    : 0;
  const totalRows = !isLoading
    ? data.reduce((acum, { allRows }) => acum + allRows, 0)
    : 0;
  const totalUnprotected = !isLoading
    ? data.reduce((acum, { allUnprotected }) => acum + allUnprotected, 0)
    : 0;
  const threshold = [
    { x: -1, y: 12 },
    { x: 2, y: 12 },
  ];

  const chartData = [
    {
      color: 0,
      x: 0,
      y: isLoading ? 0 : (totalPII / totalRows) * 100,
    },
    {
      color: 1,
      x: 1,
      y: isLoading ? 0 : (totalUnprotected / totalRows) * 100,
    },
  ];
  return (
    <Box style={{ width: "15%", height: "100%" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <FlexibleXYPlot yDomain={[0, 100]} margin={{ left: 0, right: 0 }}>
          <VerticalBarSeries
            colorRange={["#219653", "#EB5757"]}
            data={chartData}
          />
          <LineSeries
            data={threshold}
            style={styles.line}
            strokeWidth={1}
            color={"#8c1616"}
            strokeStyle="dashed"
          />
          <XAxis style={styles.axis} tickValues={[]} />
          <Typography
            style={{
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#4f4f4f",
            }}
          >
            AVERAGE
          </Typography>
        </FlexibleXYPlot>
      )}
    </Box>
  );
};

export default ScansTrend;
