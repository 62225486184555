import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import CustomButton from "components/Button";
import { validateRepositoryURL } from "utils/scanValidations";
import useParentStyles from "./styles.js";
import TokenModal from "components/KeyGenerator/RepositoryScanner/CreateRepositoryScanner/TokenModal";
import { useRepoProvider } from "hooks/useRepoProvider";
import BranchSelectorModal from "../components/BranchSelectorModal.js";
import CloseIcon from "@material-ui/icons/Close.js";

const providers = ["Github", "Gitlab", "Bitbucket"];
export const NewRepositoryScannerView = () => {
  const customClasses = useParentStyles();
  const history = useHistory();
  const [withAuth, setWithAuth] = useState("no");
  const [repoUrl, setRepoUrl] = useState("");
  const [platform, setPlatform] = useState(null);
  const [urlError, setUrlError] = useState("");
  const [githubToken, setGithubToken] = useState("");
  const [open, setOpen] = useState(false);
  const [provider, setProvider] = useState("Github");
  const { data, loading, error, triggerFetch } = useRepoProvider();
  const invalidUrlMessage = `Available formats for Github/Gitlab or Bitbucket: \n\nhttps://github.com/username/repo \n\nhttps://gitlab.com/username/repo \n\nhttps://bitbucket.org/user/repo`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const [errorDeleted, setErrorDeleted] = useState(false);

  const handleRepoUrl = useCallback(
    (e) => {
      setRepoUrl(e.target.value);
      const platform = validateRepositoryURL(e.target.value);
      setPlatform(platform);
      if (platform === null) {
        setUrlError(invalidUrlMessage);
      } else {
        setUrlError("");
      }
    },
    [invalidUrlMessage]
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setModalClosed(true);
  };
  // Open modal when data is fetched successfully
  useEffect(() => {
    if (!loading && data && !modalClosed && !error) {
      setErrorDeleted(true)
      setIsModalOpen(true);
    }
  }, [loading, data, modalClosed, error]);

  const showTokenFiled = useCallback((e) => {
    setWithAuth(e.target.value);
  }, []);

  const handleRepoToken = useCallback((e) => {
    setGithubToken(e.target.value);
  }, []);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleHistory = useCallback(() => {
    setErrorDeleted(false);
    setModalClosed(false); // Reset the manual close tracker
    triggerFetch(provider, repoUrl, githubToken);
  }, [githubToken, provider, repoUrl, triggerFetch]);

  const onHandleDelete = useCallback(() => {
    setErrorDeleted(true);
  }, []);

  const showError = () => {
    return (
      !loading &&
      !errorDeleted &&
      error && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
            backgroundColor: "#F9F9FA",
            border: "1px solid red",
            padding: 12,
            borderRadius: 8,
            fontFamily: "Inter",
            color: "red",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flex: 1,
              fontFamily: "Inter",
              fontSize: 14,
              color: "#FB2424",
            }}
          >
            Repository not verified. Please check the Access token and try
            again. -{error?.response?.status}
          </Typography>
          <CloseIcon style={{ color: "grey" }} onClick={onHandleDelete} />
        </Box>
      )
    );
  };
  return (
    <Box>
      <Box className={customClasses.formTitle}>
        {"Public & Private Repo monitoring"}
      </Box>
      <Box className={customClasses.formDescription}>
        {
          "Scan your repository to ensure no sensitive information is exposed, and you have a comprehensive understanding of all third-party libraries, generating a report."
        }
      </Box>
      {showError()}
      <FormControl>
        <Box className={customClasses.formContainer}>
          <FormControl className={customClasses.fieldContainer}>
            <Box className={customClasses.leftContainer}>
              <Typography component="label" className={customClasses.label}>
                Provider
              </Typography>
            </Box>
            <Box className={customClasses.rightContainer}>
              <FormControl fullWidth>
                <Select
                  labelId="provider-select-label"
                  id="provider-select"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                  variant="outlined"
                  inputProps={{
                    className: customClasses.selectInput,
                  }}
                  SelectDisplayProps={{
                    className: customClasses.selectDisplay,
                  }}
                >
                  {providers.map((provider) => (
                    <MenuItem key={provider} value={provider}>
                      {provider}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </FormControl>
          <FormControl className={customClasses.fieldContainer}>
            <Box className={customClasses.leftContainer}>
              <Typography component="label" className={customClasses.label}>
                Repository URL
              </Typography>
            </Box>
            <Box className={customClasses.rightContainer}>
              <TextField
                className={customClasses.textField}
                variant="outlined"
                required={true}
                label="url"
                placeholder="Type Repository URL"
                value={repoUrl}
                onChange={handleRepoUrl}
                error={!!urlError}
                helperText={urlError}
              />
            </Box>
          </FormControl>
          <FormControl className={customClasses.fieldContainer}>
            <Box className={customClasses.leftContainer}>
              <Typography component="label" className={customClasses.label}>
                Private Repository
              </Typography>
            </Box>
            <Box className={customClasses.rightContainer}>
              <RadioGroup value={withAuth} onChange={showTokenFiled}>
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="Public"
                  className={customClasses.radio}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Private"
                  className={customClasses.radio}
                />
              </RadioGroup>
            </Box>
          </FormControl>
          {withAuth === "yes" && platform ? (
            <FormControl className={customClasses.fieldContainer}>
              <Box className={customClasses.leftContainer}>
                <Typography component="label" className={customClasses.label}>
                  Token
                </Typography>
              </Box>
              <Box className={customClasses.rightContainer}>
                <TextField
                  className={customClasses.tokenField}
                  type="password"
                  variant="outlined"
                  placeholder={`Type your ${platform} token`}
                  value={githubToken}
                  onChange={handleRepoToken}
                />
                <Box>
                  <Button
                    className={customClasses.linkButton}
                    component="button"
                    onClick={handleOpen}
                  >
                    How to get my token?
                  </Button>
                  <TokenModal
                    open={open}
                    handleClose={handleClose}
                    platform={platform}
                  />
                </Box>
              </Box>
            </FormControl>
          ) : (
            ""
          )}
        </Box>
        <Box className={customClasses.submitContainer}>
          <CustomButton
            type="submit"
            className={customClasses.submit}
            disabled={!platform}
            onClick={handleHistory}
          >
            VERIFY
          </CustomButton>
        </Box>
      </FormControl>

      <BranchSelectorModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        repositoryData={{
          branches: data,
          repositoryUrl: repoUrl,
          provider: provider,
          token: githubToken,
        }}
      />
    </Box>
  );
};
