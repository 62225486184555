import React from "react";
import clsx from "clsx";

import Button from "components/Button";

import useStyles from "./styles";

const StepperDialogCongrats = ({ account = {}, closeHandler }) => {
  const classes = useStyles();
  return (
    <div className={classes.congrats}>
      <div className="container">
        <h1>Thank you!</h1>
        <p className={clsx(classes.description, "clear-background", "full")}>
          Your report is being processed.{" "}
          <strong>
            You will shortly be receiving an email with the link to access the
            report.
          </strong>
          <br />
          <br />
          {Object.keys(account).length === 0 &&
            "Feel free to view other privacy reports while you wait for us. We thank you in advance."}
        </p>
        <Button
          onClick={closeHandler}
          className={clsx(classes.submitButton, "back-to-scanner")}
          autoFocus
        >
          Back to homepage
        </Button>
      </div>
    </div>
  );
};

export default StepperDialogCongrats;
