import CONSTANTS from "./plans.constants";

export const setPlanData = (data) => ({
  type: CONSTANTS.SET_PLAN_DATA,
  data,
});

export const requestStart = () => ({
  type: CONSTANTS.REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.REQUEST_END,
});
