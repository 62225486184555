import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "normal",
    letterSpacing: "1px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("xs")]: {
      fontSize: "36px",
    },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: "40px",
    // },
  },
  infoIcon: {
    marginLeft: 6,
    "&:hover": { cursor: "pointer" },
  },
}));
