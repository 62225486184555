import React, { useCallback, useEffect } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import WebHookCard from "./components/hookSettings/WebHookCard";
import ScanButton from "../components/ScanButton";
import { useHistory } from "react-router-dom";
import { routePaths } from "router/paths";
import FirstTimeUseComponent from "../newRepositoryWebHook/components/FirstTimeUseComponent";
import { useFetchRepositoryDetailsById } from "hooks/useFetchRepositoryDetailsById";
import { Loading } from "../components/Loading";

const ADD_WEBHOOK_LABEL = "add webhook";

const WebHookListing = ({ repositoryId }) => {
  console.warn("repository id: " + repositoryId);
  const { data, loading, error, fetchData } = useFetchRepositoryDetailsById();
  const history = useHistory();
  const handleAddWebHook = useCallback(() => {
    history.push(`${routePaths.REPO_SCANNER_WEBHOOK_SETTINGS}`);
  }, [history]);
  useEffect(() => {
    fetchData(repositoryId);
  }, []);

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Header />
      {loading && <Loading />}
      {data?.webhook_url ? (
        <>
          <WebHookCard isActive={data.webhook_enabled} />
          <Divider />
          <Box>
            <Typography
              style={{ color: "#000000", fontFamily: "Inter", fontSize: 20 }}
            >
              Pipeline Automation Setup
            </Typography>

            <Typography style={{ color: "#868993", fontSize: "16px" }}>
              Unlock the full potential of continuous integration with seamless
              GitHub, Bitbucket, and GitLab pipeline setups. Automate your
              workflows, enhance code quality, and accelerate your development
              process.
            </Typography>
          </Box>
          <FirstTimeUseComponent
            values={{ provider: data.provider, webhookUrl: data.webhook_url }}
          />
        </>
      ) : (
        <EmptyScreen onAddWebHook={handleAddWebHook} />
      )}
    </Box>
  );
};

const Header = () => (
  <Box>
    <Typography style={{ color: "#000000", fontFamily: "Inter", fontSize: 20 }}>
      Webhook List
    </Typography>

    <Typography style={{ color: "#868993", fontSize: "16px" }}>
      Select the webhook to modify the settings.
    </Typography>
  </Box>
);

const EmptyScreen = ({ onAddWebHook }) => (
  <Box
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 30,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography
      style={{
        fontSize: 20,
        color: "#1C3687",
        fontWeight: 900,
        fontFamily: "Inter",
      }}
    >
      No WebHooks available yet
    </Typography>
    <ScanButton
      settings={{
        label: ADD_WEBHOOK_LABEL,
        backColor: "#1C3687",
        labelColor: "#FFFFFF",
      }}
      handleClick={onAddWebHook}
    />
  </Box>
);

export default WebHookListing;
