import React from "react";
import {
  assignFunctionalUse,
  createFunctionalUse,
} from "redux/_functionalUses/functionalUses.async.actions";
import {
  getFunctionalUsesData,
  getFunctionalUsesLoadingState,
} from "redux/_functionalUses/functionalUses.selectors";
import { connect } from "react-redux";

import CreatableSelect from "components/Inputs/CreatableSelect";

const FuncionalUsesCreatableSelect = (props) => <CreatableSelect {...props} />;

const mapStateToProps = (state) => ({
  loading: getFunctionalUsesLoadingState(state),
  options: getFunctionalUsesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  create: (name) => dispatch(createFunctionalUse(name)),
  update: (tableId, functionalUseId) =>
    dispatch(assignFunctionalUse(tableId, functionalUseId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuncionalUsesCreatableSelect);
