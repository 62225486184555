import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import clsx from "clsx";
import { FormAutoCompleteInput } from "components/FormComponents/Inputs";
import moment from "moment";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { RRule } from "rrule";
import { countries } from "utils/countries";
import { requiredCountry, validateSite } from "utils/scanValidations";
import useStyles from "./styles";

const ENDS = {
  never: "never",
  on: "on",
  after: "after",
};

const SchedulingForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();

  // Time related
  const [interval, setInterval] = useState(1);
  const [freq, setFreq] = useState(initialValues.freq || RRule.WEEKLY);
  const [days, setDays] = useState({
    [RRule.SU]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.SU.weekday),
    [RRule.MO]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.MO.weekday),
    [RRule.TU]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.TU.weekday),
    [RRule.WE]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.WE.weekday),
    [RRule.TH]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.TH.weekday),
    [RRule.FR]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.FR.weekday),
    [RRule.SA]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.SA.weekday),
  });
  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [ends, setEnds] = useState(
    (initialValues.until && ENDS.on) || ENDS.never
  );
  const [until, setUntil] = useState(initialValues.until);

  const handleInterval = (e) => {
    setInterval(e.target.value);
  };

  const handleFreq = (e) => {
    setFreq(e.target.value);
  };

  const handleDays = (e) => {
    setDays({ ...days, [e.target.name]: e.target.checked });
  };

  const handleDayOfMonth = (e) => {
    setDayOfMonth(e.target.value);
  };

  const handleEnds = (e) => {
    setEnds(e.target.value);
  };

  const handleUntil = (e) => {
    setUntil(e.target.value);
  };

  const preSubmit = (values) => {
    const { sitemap, ...rest } = values;

    if (sitemap) {
      // TODO(@jperozo): Right now, we only have support for one level
      // this should grow on the future
      rest.sitemapDepth = 1;
    }

    const parsedTimeValues = { interval, freq };

    if (freq === RRule.WEEKLY) {
      parsedTimeValues.byweekday = Object.keys(days)
        .filter((key) => days[key])
        .map((key) => RRule[key]);
    } else if (freq === RRule.MONTHLY) {
      parsedTimeValues.bymonthday = dayOfMonth;
    }

    if (ends !== ENDS.never) {
      let untilTo = until;
      if (ends === ENDS.after) {
        untilTo = moment(until).add(1, "days").format("LLLL");
      }
      parsedTimeValues.until = untilTo;
    }
    const rule = new RRule(parsedTimeValues);

    onSubmit({
      ...rest,
      id: initialValues.id,
      recurrence: rule.toString(),
    });
  };

  return (
    <Form onSubmit={preSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.root}>
            <FormControl className={classes.fieldContainer}>
              <Field
                name="site"
                initialValue={initialValues.site}
                validate={validateSite}
                render={({
                  input: { value, onChange },
                  meta: { touched, error, submitError },
                }) => (
                  <>
                    <Typography className={classes.label}>
                      Site to scan
                    </Typography>
                    <TextField
                      value={value}
                      className={classes.textField}
                      onChange={onChange}
                      variant="filled"
                      placeholder="type URL here"
                      helperText={
                        touched &&
                        (error || submitError) && (
                          <span style={{ color: "red" }}>
                            {error || submitError}
                          </span>
                        )
                      }
                    />
                  </>
                )}
              />
            </FormControl>
            <FormControl className={classes.fieldContainer}>
              <Field
                name="sitemap"
                initialValue={initialValues.sitemapDepth > 0 || false}
                render={({ input: { value, onChange } }) => (
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        name="sitemap"
                        color="primary"
                      />
                    }
                    label="search for sub-pages"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.fieldContainer}>
              <>
                <Typography className={classes.label}>Repeat every</Typography>
                <TextField
                  className={clsx(classes.textField, "mini")}
                  variant="filled"
                  value={interval || initialValues.interval}
                  onChange={handleInterval}
                />
                <Select
                  value={freq || initialValues.freq}
                  onChange={handleFreq}
                  variant="filled"
                  className={classes.selectField}
                >
                  <MenuItem value={RRule.DAILY}>Daily</MenuItem>
                  <MenuItem value={RRule.WEEKLY}>Weekly</MenuItem>
                  <MenuItem value={RRule.MONTHLY}>Monthly</MenuItem>
                </Select>
              </>
            </FormControl>
            <FormControl
              className={clsx(classes.fieldContainer, "-with-margin")}
            >
              <>
                <Typography className={classes.label}>Repeat on</Typography>
                {freq === RRule.DAILY && (
                  <Typography>
                    Every {interval > 1 ? interval : ""} day
                    {interval > 1 ? "s" : ""}
                  </Typography>
                )}
                {freq === RRule.WEEKLY && (
                  <FormGroup className={classes.week}>
                    <Checkbox
                      name={RRule.SU}
                      checked={days[RRule.SU]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>S</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>S</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.MO}
                      checked={days[RRule.MO]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>M</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>M</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.TU}
                      checked={days[RRule.TU]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>T</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>T</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.WE}
                      checked={days[RRule.WE]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>W</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>W</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.TH}
                      checked={days[RRule.TH]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>T</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>T</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.FR}
                      checked={days[RRule.FR]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>F</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>F</Avatar>
                      }
                    />
                    <Checkbox
                      name={RRule.SA}
                      checked={days[RRule.SA]}
                      onChange={handleDays}
                      icon={<Avatar className={classes.day}>S</Avatar>}
                      checkedIcon={
                        <Avatar className={classes.selectedDay}>S</Avatar>
                      }
                    />
                  </FormGroup>
                )}
                {freq === RRule.MONTHLY && (
                  <>
                    <Typography>Day</Typography>
                    <Select
                      value={dayOfMonth || initialValues.dayOfMonth}
                      onChange={handleDayOfMonth}
                      variant="filled"
                      className={clsx(classes.selectField, "mini")}
                    >
                      {Array(31)
                        .fill({})
                        .map((_, index) => (
                          <MenuItem
                            key={`day-of-month-${index}`}
                            value={index + 1}
                          >
                            {index + 1}
                          </MenuItem>
                        ))}
                    </Select>
                    <Typography>
                      of every {interval > 1 ? interval : ""} month
                      {interval > 1 ? "s" : ""}
                    </Typography>
                  </>
                )}
              </>
            </FormControl>
            <FormControl className={classes.fieldContainer}>
              <>
                <Typography className={classes.label}>Ends</Typography>
                <RadioGroup value={ends} onChange={handleEnds}>
                  <FormControlLabel
                    value={ENDS.never}
                    control={<Radio color="primary" />}
                    label="Never"
                  />
                  <Box>
                    <FormControlLabel
                      value={ENDS.on}
                      control={<Radio color="primary" />}
                      label="On"
                    />
                    <TextField
                      className={clsx(classes.textField, "ends")}
                      variant="filled"
                      placeholder="Ex. Jun 3, 2021"
                      disabled={ends === ENDS.never}
                      value={until}
                      onChange={handleUntil}
                    />
                  </Box>
                  <FormControlLabel
                    value={ENDS.after}
                    control={<Radio color="primary" />}
                    label="After"
                  />
                </RadioGroup>
              </>
            </FormControl>
            <FormControl
              className={clsx(
                classes.fieldContainer,
                "-with-margin",
                "-countries"
              )}
            >
              <Field
                name="locations"
                className={classes.countries}
                validate={requiredCountry}
                render={(props) => (
                  <>
                    <Typography className={classes.label}>Countries</Typography>
                    <FormAutoCompleteInput
                      options={countries}
                      {...props}
                      initialValues={initialValues.locations}
                    />
                  </>
                )}
              />
            </FormControl>
            <Box>
              <Button type="submit" className={classes.submit}>
                <UpdateIcon />
                Schedule scan
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default SchedulingForm;
