import React, { useState } from "react";
import "./styles.scss";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ScoreChart from "components/PrivacyReports/Report/components/ScoreChart";
import GaugeChart from "components/PrivacyReports/Report/components/GaugeChart";
import MobilePDFDownloadLink from "components/MobilePDFReport/Link";
import { getAccountData } from "redux/_account/account.selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "auto",
      paddingTop: "151px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      width: "45%",
    },
  },
  img: {
    [theme.breakpoints.up("xs")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  landing: {
    width: "65%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginTop: "30px",
    },
  },
  downloadButton: {
    fontSize: "15px",
    padding: "12px 22px",
    backgroundColor: "#6bd4cb",
    color: "#1a3586",
  },
  scanDetailItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "20px",
    margin: "26px 0",
  },
  description: {
    width: "100%",
  },
}));

const Landing = ({ content, data }) => {
  const [scoresPopoverOpen, setScoresPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [renderedPDF, renderPDF] = useState(null);
  const classes = useStyles();
  let ranking = content.security_score / 20;
  if (ranking % 1 !== 0) {
    ranking = Math.floor(ranking) + 0.5;
  }
  let riskName = "Medium risk";
  if (ranking >= 4) {
    riskName = "Low risk";
  } else if (ranking <= 2) {
    riskName = "High risk";
  }

  const userAccount = useSelector((state) => getAccountData(state));
  const branding = userAccount.logo;

  return (
    <div className={classes.container}>
      {content.icon && (
        <div className={classes.logo}>
          <img src={content.icon} alt="scanned" className={classes.img} />
        </div>
      )}
      <div className={clsx("landing", classes.landing)}>
        <div className="sinopsis">
          <div className="app-description">
            <h1 style={{ color: "#6BD4CA" }}>
              {content.title}
              {content.version && (
                <span style={{ font: "inherit", fontSize: "20px" }}>
                  &nbsp;({content.version})
                </span>
              )}
            </h1>
            {content.description && <span>{content.description}</span>}
            {content.scanned.map((scanned, idx) => (
              <div
                className={classes.scanDetailItem}
                key={`${scanned.title}-${idx}`}
              >
                <h2>{scanned.title}</h2>
                <span>{scanned.content}</span>
              </div>
            ))}
            <div className="pdf-button-container">
              {renderedPDF ? (
                <MobilePDFDownloadLink
                  branding={branding}
                  icon={content.icon}
                  data={data}
                  content={content}
                  ranking={ranking}
                  riskName={riskName}
                >
                  <Button
                    className="pdf-button"
                    color="primary"
                    variant="contained"
                  >
                    Download PDF
                  </Button>
                </MobilePDFDownloadLink>
              ) : (
                <Button
                  className="pdf-button"
                  color="primary"
                  variant="contained"
                  onClick={() => renderPDF(true)}
                >
                  Download PDF
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={clsx("scores")}>
          <div className="score">
            <ScoreChart value={content.security_score} />
            <div className="description">
              <span className="title">Security Score </span>
              <span className="total-score">{content.security_score}/100</span>
              {content.totalDiff !== null && (
                <div
                  className={`diff-score ${
                    content.totalDiff < 0 ? "red" : "green"
                  }`}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setScoresPopoverOpen(true);
                  }}
                >
                  {content.totalDiff < 0 ? "↓" : content.totalDiff !== 0 && "↑"}
                  {Math.abs(content.totalDiff)}
                </div>
              )}
            </div>
          </div>
          <div className={clsx("score", classes.description)}>
            <GaugeChart value={5 - ranking} />
            <div className="description">
              <span className="title">{riskName}</span>
              <span className="total-score">{5 - ranking}/5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
