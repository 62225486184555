import React from "react";

export default () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16713 15.7056H3.47457C3.40348 15.7056 3.35016 15.7589 3.35016 15.83V17.1452C3.35016 17.2163 3.40348 17.2696 3.47457 17.2696H4.52316V18.9047C4.52316 18.9047 4.28323 18.9847 3.63452 18.9847C2.87029 18.9847 1.79504 18.7003 1.79504 16.3454C1.79504 13.9817 2.91473 13.6706 3.96332 13.6706C4.86972 13.6706 5.26072 13.8306 5.50954 13.9106C5.58952 13.9372 5.66061 13.8572 5.66061 13.7862L5.96275 12.5154C5.96275 12.4799 5.95386 12.4443 5.91831 12.4177C5.81168 12.3466 5.19852 12 3.63452 12C1.83948 12 0 12.7642 0 16.4343C0 20.1044 2.10607 20.6553 3.88334 20.6553C5.35847 20.6553 6.24711 20.0244 6.24711 20.0244C6.28266 20.0066 6.29154 19.9533 6.29154 19.9266V15.83C6.29154 15.7589 6.23822 15.7056 6.16713 15.7056Z"
      fill="#100E0F"
    />
    <path
      d="M20.0302 12.4447C20.0302 12.3736 19.9769 12.3203 19.9058 12.3203H18.3952C18.3241 12.3203 18.2707 12.3736 18.2707 12.4447V15.3683H15.907V12.4447C15.907 12.3736 15.8536 12.3203 15.7826 12.3203H14.2719C14.2008 12.3203 14.1475 12.3736 14.1475 12.4447V20.3714C14.1475 20.4424 14.2008 20.4958 14.2719 20.4958H15.7826C15.8536 20.4958 15.907 20.4424 15.907 20.3714V16.9768H18.2707V20.3625C18.2707 20.4336 18.3241 20.4869 18.3952 20.4869H19.9147C19.9858 20.4869 20.0391 20.4336 20.0391 20.3625V12.4447H20.0302Z"
      fill="#100E0F"
    />
    <path
      d="M9.01893 13.4842C9.01893 12.9422 8.58361 12.498 8.04169 12.498C7.49977 12.498 7.06445 12.9422 7.06445 13.4842C7.06445 14.0261 7.49977 14.4703 8.04169 14.4703C8.58361 14.4703 9.01893 14.0261 9.01893 13.4842Z"
      fill="#100E0F"
    />
    <path
      d="M8.91292 18.6918C8.91292 18.4875 8.91292 15.0307 8.91292 15.0307C8.91292 14.9596 8.8596 14.9062 8.78851 14.9062H7.27783C7.20674 14.9062 7.14453 14.9773 7.14453 15.0484C7.14453 15.0484 7.14453 19.4472 7.14453 20.2914C7.14453 20.4424 7.24228 20.4869 7.36669 20.4869C7.36669 20.4869 8.01539 20.4869 8.7263 20.4869C8.87737 20.4869 8.91292 20.4158 8.91292 20.2825C8.91292 20.007 8.91292 18.9051 8.91292 18.6918Z"
      fill="#100E0F"
    />
    <path
      d="M25.7795 14.9219H24.2777C24.2066 14.9219 24.1533 14.9752 24.1533 15.0463V18.9296C24.1533 18.9296 23.7712 19.2051 23.2291 19.2051C22.687 19.2051 22.5448 18.9563 22.5448 18.432C22.5448 17.8988 22.5448 15.0463 22.5448 15.0463C22.5448 14.9752 22.4915 14.9219 22.4204 14.9219H20.892C20.8209 14.9219 20.7676 14.9752 20.7676 15.0463C20.7676 15.0463 20.7676 17.1168 20.7676 18.6897C20.7676 20.2626 21.6473 20.6536 22.8559 20.6536C23.8511 20.6536 24.642 20.1026 24.642 20.1026C24.642 20.1026 24.6776 20.387 24.6953 20.4225C24.7131 20.4581 24.7575 20.4936 24.802 20.4936H25.7706C25.8417 20.4936 25.895 20.4403 25.895 20.3692V15.0463C25.9039 14.9752 25.8506 14.9219 25.7795 14.9219Z"
      fill="#100E0F"
    />
    <path
      d="M29.8846 14.7463C29.0315 14.7463 28.4539 15.1284 28.4539 15.1284V12.4447C28.4539 12.3736 28.4006 12.3203 28.3295 12.3203H26.81C26.7389 12.3203 26.6855 12.3736 26.6855 12.4447V20.3714C26.6855 20.4424 26.7389 20.4958 26.81 20.4958H27.8674C27.9119 20.4958 27.9474 20.4691 27.9741 20.4247C28.0007 20.3802 28.0363 20.0603 28.0363 20.0603C28.0363 20.0603 28.6583 20.6468 29.8313 20.6468C31.2087 20.6468 31.9996 19.9448 31.9996 17.5011C32.0085 15.0662 30.7466 14.7463 29.8846 14.7463ZM29.2981 19.2072C28.7738 19.1895 28.4273 18.9584 28.4273 18.9584V16.4436C28.4273 16.4436 28.7738 16.2303 29.2004 16.1948C29.7425 16.1503 30.2668 16.3103 30.2668 17.5988C30.2579 18.9584 30.0268 19.2339 29.2981 19.2072Z"
      fill="#100E0F"
    />
    <path
      d="M13.267 14.9051H12.1296V13.4033C12.1296 13.35 12.1029 13.3145 12.0318 13.3145H10.4856C10.4234 13.3145 10.3967 13.3411 10.3967 13.3944V14.9495C10.3967 14.9495 9.62363 15.1362 9.57031 15.1539C9.51699 15.1717 9.48145 15.2161 9.48145 15.2783V16.2558C9.48145 16.3269 9.53476 16.3802 9.60585 16.3802H10.3967C10.3967 16.3802 10.3967 17.4022 10.3967 18.7262C10.3967 20.468 11.6142 20.6368 12.4406 20.6368C12.8138 20.6368 13.267 20.5124 13.3381 20.4857C13.3826 20.468 13.4092 20.4235 13.4092 20.3702V19.295C13.4092 19.2239 13.347 19.1706 13.2848 19.1706C13.2226 19.1706 13.0449 19.1972 12.876 19.1972C12.3162 19.1972 12.1296 18.9395 12.1296 18.6018C12.1296 18.2642 12.1296 16.3714 12.1296 16.3714H13.267C13.3381 16.3714 13.3914 16.318 13.3914 16.247V15.0295C13.3914 14.9673 13.3381 14.9051 13.267 14.9051Z"
      fill="#100E0F"
    />
  </svg>
);
