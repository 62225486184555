const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  high: {
    color: "color-mix(in srgb, #000000 60%, #D32F2F)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #D32F2F)",
  },
  warning: {
    color: "color-mix(in srgb, #000000 60%, #ED6C02)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #ED6C02)",
  },
  info: {
    color: "color-mix(in srgb, #000000 60%, #0288D1)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #0288D1)",
  },
  secure: {
    color: "color-mix(in srgb, #000000 60%, #2E7D32)",
    backgroundColor: "color-mix(in srgb, #ffffff 90%, #2E7D32)",
  },
}));

export default useStyles;
