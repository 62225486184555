import React from "react";
import { Button as MUIButton, Typography } from "@material-ui/core";
import useStyles from "./styles";
import SvgIcon from "components/svg-icon/svg-icon";
import { ArrowForward } from "@material-ui/icons";
import { withRouter } from "react-router";

const Button = ({ icon, text = "", link, history }) => {
  const classes = useStyles();
  return (
    <MUIButton
      classes={{ root: classes.button, label: classes.buttonContent }}
      onClick={(_) => history.push(link)}
    >
      <span style={{ display: "flex" }}>
        <SvgIcon icon={icon} />
        <Typography className={classes.text}>{text}</Typography>
      </span>
      <ArrowForward className={classes.icon} />
    </MUIButton>
  );
};

export default withRouter(Button);
