import {
  CONSTANTS,
  ENTITIES,
  TABLE_FILTERS,
} from "redux/_settings/settings.constants";
import isArray from "lodash.isarray";
import unionBy from "lodash.unionby";
import isNil from "lodash.isnil";

const initialState = {
  loading: false,
  [ENTITIES.DATASOURCE]: {
    selected: null,
    data: {},
  },
  [ENTITIES.DATABASE]: {
    selected: null,
    data: {},
    tables: [],
    tableFilters: {
      [TABLE_FILTERS.SEARCH]: "",
      [TABLE_FILTERS.HIDE_WITH_DEPARTMENTS]: false,
    },
  },
  [ENTITIES.ENVIRONMENT]: {
    selected: null,
    data: {},
  },
  initialSetup: {
    data: {
      datasources: false,
      databases: false,
      environments: false,
      scans: false,
      consents_config: false,
      consents_embed: false,
      requests_config: false,
      requests_embed: false,
      show_initial_setup: false,
    },
    loading: false,
  },
};

export const settings = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.SETTINGS_SELECT_ENTITY:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          selected: data,
        },
      };
    case CONSTANTS.SETTINGS_SAVE_DATA:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          data,
        },
      };
    case CONSTANTS.SETTINGS_SAVE_DB_TABLES_DATA:
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tables: isNil(data)
            ? []
            : isArray(data)
            ? data
            : unionBy([data], state[ENTITIES.DATABASE].tables, "id"),
        },
      };
    case CONSTANTS.SETTINGS_RESET_DB_TABLES_FILTERS:
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tableFilters: {
            [TABLE_FILTERS.SEARCH]: "",
            [TABLE_FILTERS.HIDE_WITH_DEPARTMENTS]: false,
          },
        },
      };
    case CONSTANTS.SETTINGS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.SETTINGS_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SETTINGS_ST_DB_TABLE_FID:
      const tableWithUpdatedFunctionalUses = state[
        ENTITIES.DATABASE
      ].tables.map((el) =>
        el.id === data.tableId
          ? { ...el, id_functional_use: data.functionalUseId }
          : el
      );
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tables: tableWithUpdatedFunctionalUses,
        },
      };

    case CONSTANTS.SETTINGS_ST_DB_TABLE_BUSINESS_UNIT_ID:
      const tableWithUpdatedBusinessUnits = state[
        ENTITIES.DATABASE
      ].tables.map((el) =>
        el.id === data.tableId
          ? { ...el, id_business_unit: data.businessUnitId }
          : el
      );
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tables: tableWithUpdatedBusinessUnits,
        },
      };

    case CONSTANTS.SETTINGS_ST_DB_TABLE_POLICY_ID:
      const tableWithUpdatedPolicies = state[
        ENTITIES.DATABASE
      ].tables.map((el) =>
        el.id === data.tableId ? { ...el, id_policy: data.policyId } : el
      );
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tables: tableWithUpdatedPolicies,
        },
      };

    case CONSTANTS.SETTINGS_SET_DB_TABLES_FILTERS:
      return {
        ...state,
        [ENTITIES.DATABASE]: {
          ...state[ENTITIES.DATABASE],
          tableFilters: {
            [dataType]: data,
          },
        },
      };
    case CONSTANTS.SETTINGS_SAVE_INITIAL_SETUP:
      return {
        ...state,
        initialSetup: {
          ...state.initialSetup,
          data: { ...state.initialSetup.data, ...data },
        },
      };
    case CONSTANTS.SETTINGS_INITIAL_SETUP_REQUEST_END:
      return {
        ...state,
        initialSetup: { ...state.initialSetup, loading: false },
      };
    case CONSTANTS.SETTINGS_INITIAL_SETUP_REQUEST_START:
      return {
        ...state,
        initialSetup: { ...state.initialSetup, loading: true },
      };
    default:
      return state;
  }
};
