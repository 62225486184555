import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "calc(100% - 100px)",
    display: "flex",
    padding: "0px 64px",
    alignItems: "center",
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rightContent: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    paddingLeft: "16px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.grey[200],
  },
  subtitle: {
    padding: "16px 0px 20px",
    fontSize: "18px",
    color: theme.palette.grey[300],
  },
  mapButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "1px",
    whiteSpace: "nowrap",
    height: "36px",
    flexShrink: 0,
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: theme.palette.blue[600],
    },
  },
}));
