import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";

export const ACTIVE = "active";

const KeyRow = ({ status, productKey, keyName }) => {
  const classes = useStyles();

  return (
    <tr className={classes.tr}>
      <td>
        <Box className={classes.nameContainer}>
          <Box>
            {productKey ? (
              <a href={productKey} download={keyName}>
                {keyName}
              </a>
            ) : (
              "--"
            )}
          </Box>
        </Box>
      </td>
      <td>
        <Box
          className={status === ACTIVE ? classes.statusActive : classes.status}
        >
          <Typography className={classes.statusText}>{status}</Typography>
        </Box>
      </td>
    </tr>
  );
};

export default KeyRow;
