import { makeStyles } from "@material-ui/styles";

import { HEADER_HEIGHT } from "../../constants";

export default makeStyles((theme) => ({
  page: {
    "& > .fp-tableCell": {
      verticalAlign: "bottom",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      "&.-full-height": {
        height: "100vh",
      },
    },
  },
  hidden: {
    opacity: 0,
    zIndex: 0,
  },
  noPadding: {
    paddingTop: 0,
  },
  content: {
    flex: 1,
    margin: "0 auto",
    maxWidth: "1332px",
    width: "100%",
    padding: `${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(
      5
    )}px`,
    "&.-trim-padding-top": {
      paddingTop: `${theme.spacing(5)}px`,
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 50px)",
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      padding: `${theme.spacing(5)}px ${theme.spacing(3)}px ${theme.spacing(
        0
      )}px ${theme.spacing(7)}px`,
      "&.blue-background": {
        borderLeft: "2px solid rgba(251, 251, 251, 0.2)",
        borderRight: "2px solid rgba(251, 251, 251, 0.2)",
      },
    },
  },
  gridItem: {
    width: "100%",
  },
  footer: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    margin: "0 auto",
    maxWidth: "1332px",
    width: "calc(100% - 50px)",
    height: "80px",
    backgroundColor: ({ backgroundColor }) => backgroundColor || "white",
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    "&.blue-background": {
      borderLeft: "2px solid rgba(251, 251, 251, 0.2)",
      borderRight: "2px solid rgba(251, 251, 251, 0.2)",
    },
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& svg": {
      width: "273px",
      height: "36px",
    },
  },
  headerContent: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    maxWidth: "1440px",
    padding: "25px",
    paddingLeft: "30px",
    margin: "0 auto",
    height: "100%",
  },

  headerSub: {
    fontFamily: "Aldrich",
    fontWeight: "normal",
    fontSize: "18px",
    color: theme.palette.aquamarine[100],
  },
  headerUrl: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#fff",
  },

  button: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: 1,
    textTransform: "uppercase",
    backgroundColor: theme.palette.blue["young"],
    width: 139,
    height: 40,
    padding: 0,
    //  marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: 6,
    "&:hover": {
      backgroundColor: theme.palette.blue["500"],
      color: theme.palette.common.white,
    },
    "&:disabled": {
      opacity: 0.6,
    },
    "&.footer": {
      width: 550,
      backgroundColor: ({ backgroundColor }) => backgroundColor || "#fff",
      color: theme.palette.aquamarine[100],
      border: `solid 2px ${theme.palette.aquamarine[100]}`,
    },
  },
  arrow: {
    marginRight: 10,
  },
}));
