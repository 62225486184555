import React, { useState, useRef, useEffect } from "react";
import { scaleLinear, scaleTime } from "d3-scale";
import { extent, max } from "d3-array";
import { timeParse } from "d3-time-format";
import Axis from "./Axis";
import Line from "./Line";
import { formatNumber } from "utils/format";
import Reference from "./Reference";

const d3 = {
  scaleLinear,
  scaleTime,
  extent,
  max,
  timeParse,
};

const Linechart = ({
  data: rawData,
  width,
  height,
  colors,
  labels,
  compact,
}) => {
  const margin = compact
    ? { top: 30, right: 50, bottom: 30, left: 70 }
    : { top: 30, right: 250, bottom: 50, left: 70 };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const data = rawData.map((d) => ({
    ...d,
    date: d3.timeParse("%Y-%m-%d")(d.date),
  }));

  const xMargin = data.length > 0 ? chartWidth / data.length : 0;

  const x = d3
    .scaleLinear()
    .domain([0, data.length - 1])
    .range([0, chartWidth - xMargin]);

  const maxY = d3.max(data, (d) =>
    d3.max(Object.values({ ...d, date: undefined }), (d2) => d2)
  );

  const y = d3
    .scaleLinear()
    .domain([0, maxY + maxY * 0.25])
    .range([chartHeight, 0]);

  const getDateLabel = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${day}/${month}`;
  };

  const xAxisTicks = x
    .ticks(data.length === 1 ? 1 : data.length - 1)
    .map((tick) => {
      const { date } = data[tick];
      return {
        value: tick,
        position: x(tick),
        label: getDateLabel(date),
      };
    });

  const yAxisTicks = y.ticks(compact ? 5 : 10).map((tick) => ({
    value: tick,
    position: y(tick),
    label: formatNumber(tick),
  }));

  const keys = data.reduce((prev, curr) => {
    const keys = Object.keys(curr).filter((key) => key !== "date");
    keys.forEach((key) => {
      if (prev.indexOf(key) === -1) {
        prev.push(key);
      }
    });
    return prev;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hovered, setHovered] = useState();
  const handleMouseOver = (tick) => setHovered(tick);

  // Hover and tooltip container for Portal
  const tooltipElement = useRef();
  const hoverableElement = useRef();

  const [hoverContainer, setHoverContainer] = useState();
  const [tooltipContainer, setTooltipContainer] = useState();

  useEffect(() => {
    if (tooltipElement) setTooltipContainer(tooltipElement);
    if (hoverableElement) setHoverContainer(hoverableElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sumData = data.reduce((prev, curr) => {
    keys.forEach((key) => {
      if (prev.hasOwnProperty(key)) {
        prev[key] += curr[key];
      } else {
        prev[key] = curr[key];
      }
    });
    return prev;
  }, {});

  const keyColors = keys.reduce(
    (prev, curr, i) => ({
      ...prev,
      [curr]: colors[i],
    }),
    {}
  );

  return (
    <g
      transform={`translate(${margin.left} ${margin.top})`}
      style={{ transition: "none" }}
    >
      <Axis
        type="y"
        ticks={yAxisTicks}
        width={chartWidth}
        height={height}
        label={compact ? "AMOUNT" : "AMOUNT IN THOUSANDS"}
        compact={compact}
        xMargin={xMargin}
      />

      <g transform={`translate(${xMargin / 2})`}>
        <Axis
          type="x"
          ticks={xAxisTicks}
          height={chartHeight}
          width={chartWidth}
          hovered={hovered}
          onMouseOver={handleMouseOver}
          hoverContainer={hoverContainer}
          label="DATE"
          xMargin={xMargin}
        />

        {keys.map((key, i) => (
          <Line
            compact={compact}
            key={key}
            keys={keys}
            data={data}
            property={key}
            x={x}
            y={y}
            color={keyColors[key]}
            hovered={hovered}
            tooltipContainer={tooltipContainer}
          />
        ))}

        <g ref={tooltipElement} />
        <g ref={hoverableElement} />
      </g>

      {!compact && (
        <Reference
          data={sumData}
          title="DECISION STATS"
          width={width}
          labels={labels}
          keys={keys}
          colors={keyColors}
        />
      )}
    </g>
  );
};

export default Linechart;
