import React from 'react';
import { Box, Typography } from '@material-ui/core';

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      minWidth: 'max-content',
    },
    valueText: {
      fontFamily: 'Aldrich',
      fontSize: 12,
      color: '#1B3586',
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      background: 'white',
      width: 'fit-content',
      borderRadius: 16,
      alignItems: 'center',
      paddingRight: 8,
      paddingLeft: 8,
      paddingTop: 4,
      paddingBottom: 4,
    },
    labelText: {
      color: '#622424',
      fontSize: 12,
    },
  };

export const SeverityBox = ({ label, value, icon }) => {
  return (
    <Box style={styles.container}>
      <Typography style={styles.valueText}>
        {value}
      </Typography>
      <Box style={styles.box}>
        <img id="icon" width="16" height="16" alt={label} src={icon} />
        <Typography style={styles.labelText}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

