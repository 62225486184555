import CONSTANTS from "./environment.constants";

export const requestStart = () => ({
  type: CONSTANTS.ENVIRONMENTS_GET_ALL_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.ENVIRONMENTS_GET_ALL_REQUEST_END,
});

export const clearEnv = () => ({
  type: CONSTANTS.ENVIRONMENTS_CLEAR_ENV,
});

export const saveEnvs = (envsData) => ({
  type: CONSTANTS.ENVIRONMENTS_SAVE_DATA,
  data: envsData,
});

export const selectEnv = (envId) => ({
  type: CONSTANTS.ENVIRONMENTS_SELECT_ENV,
  data: envId,
});

export const setScanning = (value) => ({
  type: CONSTANTS.ENVIRONMENTS_SET_IS_SCANNING,
  data: value,
});

export const updateEnvData = (value) => ({
  type: CONSTANTS.ENVIRONMENTS_UPDATE_ENV,
  data: value,
});

export const deleteData = (data) => ({
  type: CONSTANTS.ENVIRONMENTS_DELETE_ENV,
  data,
});
