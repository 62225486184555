import React, { useState, useContext, useRef } from "react";
import { DatePicker, MuiPickersContext } from "@material-ui/pickers";
import Text from "components/Inputs/Text";
import SvgIcon from "components/svg-icon/svg-icon";
import { InputAdornment } from "@material-ui/core";
import useStyles from "./styles";

const sortDates = (dates) => {
  if (!dates[0] || !dates[1]) return dates;
  return dates.sort((a, b) => a.diff(b));
};

function DateRangePicker({
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onClose = () => {},
  title,
  ...props
}) {
  const [begin, setBegin] = useState(value[0]);
  const [end, setEnd] = useState(value[1]);
  const [hover, setHover] = useState(null);
  const picker = useRef();
  const utils = useContext(MuiPickersContext);

  const min = Math.min(begin, end || hover);
  const max = Math.max(begin, end || hover);

  function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    const style = {
      margin: 0,
      width: "40px",
    };

    if (day >= min && day <= max) {
      style.backgroundColor = "#3f51b5";
      style.color = "white";
    }

    if (utils.isSameDay(day, min)) style.borderRadius = "50% 0 0 50%";
    else if (utils.isSameDay(day, max)) style.borderRadius = "0 50% 50% 0";
    else style.borderRadius = "0";

    return React.cloneElement(dayComponent, {
      onClick: (e) => {
        e.stopPropagation();
        if (!begin) setBegin(day);
        else if (!end) {
          const sortedDates = sortDates([day, begin]);
          setBegin(sortedDates[0]);
          setEnd(sortedDates[1]);
          if (autoOk) {
            onChange(sortDates([begin, end]));
            picker.current.close();
          }
        } else {
          setBegin(day);
          setEnd(null);
        }
      },
      onMouseEnter: (e) => setHover(day),
      style,
    });
  }

  const formatDate = (date) => utils.format(date, format || utils.dateFormat);
  const classes = useStyles();
  return (
    <DatePicker
      {...props}
      value={null}
      renderDay={renderDay}
      onClose={() => {
        onChange(sortDates([begin, end]));
        onClose();
      }}
      onChange={() => {}}
      ref={picker}
      TextFieldComponent={(props) => (
        <Text
          label={title}
          {...props}
          InputProps={{
            ...props.InputProps,
            classes: {
              root: classes.textFieldRoot,
              input: classes.input,
            },
            endAdornment: (
              <InputAdornment>
                <SvgIcon icon="calendar" />
              </InputAdornment>
            ),
          }}
        />
      )}
      labelFunc={(date, invalid) =>
        labelFunc
          ? labelFunc(sortDates([begin, end]), invalid)
          : begin && end
          ? `${formatDate(begin)} - ${formatDate(end)}`
          : emptyLabel
      }
    />
  );
}

export default DateRangePicker;
