// actions.js
export const SET_REPOSITORY_ID = "SET_REPOSITORY_ID";
export const SET_BRANCHES = "SET_BRANCHES";
export const SET_REPOSITORY_BRANCH_ID = "SET_REPOSITORY_BRANCH_ID";
export const SET_REPOSITORY_PROVIDER = "SET_REPOSITORY_PROVIDER";
export const SET_REPOSITORY_URL = "SET_REPOSITORY_URL";
export const SET_REPOSITORY_WEBHOOK_ENABLED = "SET_REPOSITORY_WEBHOOK_ENABLED";
export const SET_REPOSITORY_SCAN_SUMMARY = "SET_REPOSITORY_SCAN_SUMMARY";
export const SET_TAB_VIEW = 'SET_TAB_VIEW';

export const setRepositoryId = (repositoryId) => ({
  type: SET_REPOSITORY_ID,
  payload: repositoryId,
});

export const setBranches = (branches) => ({
  type: SET_BRANCHES,
  payload: branches,
});

export const setRepositoryBranchId = (id) => ({
  type: SET_REPOSITORY_BRANCH_ID,
  payload: id,
});

export const setRepostoryProvider = (provider) => ({
  type: SET_REPOSITORY_PROVIDER,
  payload: provider,
});

export const setRepositoryUrl = (url) => ({
  type: SET_REPOSITORY_URL,
  payload: url,
});

export const setRepositoryWebhookEnabled = (webhookEnabled) => ({
  type: SET_REPOSITORY_WEBHOOK_ENABLED,
  payload: webhookEnabled,
});

export const setRepositoryScanSummary = (summary) => ({
  type: SET_REPOSITORY_SCAN_SUMMARY,
  payload: summary,
});

export const setTabView = (view) => ({
  type: SET_TAB_VIEW,
  payload: view,
});
