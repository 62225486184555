import clsx from "clsx";
import {
    Card,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@material-ui/core";

import useParentStyles from "./styles.js";

export const CardsTags = ({
    cards,
    selectedCard,
    handleCardClick,
  }) => {
    const parentClasses = useParentStyles();
  
    return (
        <TableContainer component={Paper} className={parentClasses.leftTable}>
            <Table aria-label="cards table">
                <TableBody>
                    {cards.map((card, index) => (
                    <TableRow key={index} className={parentClasses.cardRow}>
                        <TableCell>
                            <Card
                                className={clsx(parentClasses.card, { [parentClasses.cardSelected]: selectedCard === index })}
                                elevation={16}
                                onClick={() => handleCardClick(index)}
                            >
                                <Typography variant="h2" className={parentClasses.cardTitle}>
                                {card.title}
                                </Typography>
                                <Typography className={parentClasses.cardText}>
                                {card.content}
                                </Typography>
                            </Card>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
  };