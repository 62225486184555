import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    border: `solid 2px ${fade(theme.palette.grey[300], 0.4)}`,
    position: "relative",
  },
  content: {
    padding: theme.spacing(4, 3, 2),
    height: "100%",
  },
  emptyContainer: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  imageContainer: {
    height: "50%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  emptyTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.grey[200],
  },
  emptySubtitle: {
    maxWidth: "334px",
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.grey[300],
    textAlign: "center",
  },
}));
