import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageReportsWrapper from "components/PageReportsWrapper";
import Button from "components/Button";
import CreateFileScanner from "./CreateFileScanner/CreateFileScanner";
import ListFileScans from "./ListFileScans/ListFileScans";
import { getAccountData } from "redux/_account/account.selectors";
import {
  listSchedulers,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";

const FileScanner = ({ account, fetchSchedulers }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line
  }, []);

  return (
    <PageReportsWrapper
      title="File Scanner"
      selected={selectedTab}
      setSelected={setSelectedTab}
      subpages={[
        {
          option: "Reports",
          title: "List of your file scans",
          Component: () => <ListFileScans account={account} />,
          extra: (
            <Button
              aria-controls="scan-menu"
              aria-haspopup="true"
              onClick={() => setSelectedTab(1)}
              size="small"
            >
              Create a New File scan
            </Button>
          ),
        },
        {
          sectionToMove: 1,
          option: "New Scan",
          title: "Public & Private file monitoring",
          description:
            "The File Scanner supports the following file types: PDF, JPG, PNG, CSV and TXT. Please ensure that the file you upload is in one of these formats. Additionally, the file size must be less than 2 MB for successful scanning.",
          Component: () => (
            <CreateFileScanner setSelectedTab={setSelectedTab} />
          ),
        },
      ]}
    ></PageReportsWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  chedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
  fetchSchedulers: (filters) => dispatch(listSchedulers(filters)),
});

const ConnectedSchedulingDataSourcePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileScanner);

export default withRouter(ConnectedSchedulingDataSourcePage);
