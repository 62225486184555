import React from "react";
import SelectInput from "components/Inputs/Select";
import useStyles from "../../styles";
import { regions } from "components/PageWrapper/components/FiltersBar/components/constants";
import { connect } from "react-redux";
import { getFilterRegion } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const CustomerRegion = (props) => {
  const classes = useStyles();

  const { region, setRegion } = props;

  return (
    <SelectInput
      label="Customer region"
      options={regions}
      className={classes.filtersInput}
      value={region}
      onChange={setRegion}
    />
  );
};

const mapStateToProps = (state) => ({
  region: getFilterRegion(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRegion: (id) => dispatch(selectData(TYPES.CUSTOMER_REGION, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegion);
