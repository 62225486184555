import consents from "./consents.svg";
import overview from "./overview.svg";
import pii_reports from "./pii_reports.svg";
import reports from "./reports.svg";
import scanner from "./scanner.svg";
import settings from "./settings.svg";
import trends from "./trends.svg";
import admin from "./admin.svg";

export default {
  consents,
  overview,
  pii_reports,
  reports,
  scanner,
  settings,
  trends,
  admin,
};
