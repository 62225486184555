import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    padding: theme.spacing(4, 0, 2),
  },
}));
