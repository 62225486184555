import { makeStyles } from "@material-ui/styles";
import useParentStyles from "./styles.js";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "min-content",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #B0BAC9",
    "&:not(&:last-child)": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: 0,
      maxHeight: theme.spacing(16),
      border: "none",
      "&:not(&:last-child)": {
        marginBottom: 0,
      },
    },
  },
  data: {
    padding: theme.spacing(2),
    verticalAlign: "baseline",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "26px",
    color: theme.palette.grey[700],
    borderBottom: "1px solid #E0E1E3",
    overflowY: "auto",
    wordBreak: "break-word",
    "&:last-child": {
      paddingRight: 0,
    },
    "& > ul": {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  domainInfo: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  popoverInfo: {
    width: "677px",
    "& .title": {
      padding: "20px",
      borderBottom: "solid 1px #DFE2E9",
      fontFamily: "Inter",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: 0.25,
      color: theme.palette.blue[500],
    },
    "& tspan": {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  },
  cookieName: {
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[900],
  },
  otherData: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "26px",
    color: theme.palette.grey[800],
    "& strong": {
      fontWeight: 600,
    },
  },
  tag: {
    border: "1px solid",
    borderColor: theme.palette.blue[400],
    margin: "2px 5px",
    padding: "2px 8px",
    display: "inline-block",
    borderRadius: "20px",
    fontSize: 12,
    cursor: "pointer",
    "&.-selected": {
      backgroundColor: theme.palette.blue[400],
      color: "white",
    },
  },
}));

const CellResponse = ({ columns, row, environment }) => {
  const classes = useStyles();
  const parentClasses = useParentStyles();
  return (
    <tr className={classes.row}>
      {columns.map((column, i) => (
        <td
          className={clsx(
            "scrollable-element",
            parentClasses.domain,
            classes.data,
            environment
          )}
        >
          {column?.render
            ? column.render(row[column.key], row)
            : (
              <Typography className={i === 0 && classes.cookieName}>{row[column.key]}</Typography>
            )
          }
        </td>
      ))}
    </tr>
  );
};

export default CellResponse;
