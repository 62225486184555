import {
  requestStart,
  requestEnd,
  saveData,
  updateData,
  deleteData,
} from "redux/_datasources/datasources.actions";
import {
  getDs,
  updateDs,
  deleteDs,
  createDs,
} from "redux/_datasources/datasources.service";
import isEmpty from "lodash.isempty";
import { getDatasourcesData } from "redux/_datasources/datasources.selectors";
import { getInitialSetupData } from "redux/_settings/settings.async.actions";

export const getDatasources = () => (dispatch, getState) => {
  const datasources = getDatasourcesData(getState());
  if (isEmpty(datasources)) {
    dispatch(requestStart());
    return getDs()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve(data);
      })
      .catch((errors) => {
        dispatch(requestEnd());
        return Promise.reject(errors);
      });
  }
};

export const updateDatasource = (data) => (dispatch) => {
  dispatch(requestStart());
  return updateDs(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(updateData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const deleteDatasource = (id) => (dispatch) => {
  dispatch(requestStart());
  return deleteDs(id)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(deleteData(id));
      dispatch(getInitialSetupData());
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const createDatasource = (data) => (dispatch) => {
  dispatch(requestStart());
  return createDs(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData([data]));
      dispatch(getInitialSetupData());
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};
