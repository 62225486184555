import { Text, View } from "@react-pdf/renderer";

const ApplicationPermissionsPage = ({ styles, data }) => (
  <View style={styles.section}>
    <View>
      <Text style={styles.title}>Application Permissions</Text>
      <Text style={styles.subTitle}>
        The goal of analyzing application permissions in APKs and IPAs via
        static code analysis.
      </Text>
    </View>
    <View>
      <View>
        <View style={styles.table}>
          <View style={styles.thead} fixed>
            <View style={styles.tr}>
              <View style={[styles.thCustomWidth, { width: "30%" }]}>
                <Text>Permissions</Text>
              </View>
              <View style={[styles.thCustomWidth, { width: "20%" }]}>
                <Text>Status</Text>
              </View>
              <View style={[styles.thCustomWidth, { width: "20%" }]}>
                <Text>Info</Text>
              </View>
              <View style={[styles.thCustomWidth, { width: "35%" }]}>
                <Text>Reason in Manifest</Text>
              </View>
            </View>
          </View>
          {Object.entries(data.permissions).map(([key, value]) => {
            const statusStyles = {
              dangerous: {
                color: "red",
                borderColor: "#F86565",
                borderRadius: 64,
              },
              normal: {
                color: "#1B3586",
                borderColor: "#A3E3FF",
                borderRadius: 64,
              },
              signature: {
                color: "#2E7D32",
                borderColor: "#97c69a",
                borderRadius: 64,
              },
              unknown: {
                color: "black",
                borderColor: "gray",
                borderRadius: 64,
              },
              normnal: {
                color: "black",
                borderColor: "gray",
                borderRadius: 64,
              },
            };

            const statusStyle = statusStyles[value.status] || {};

            return (
              <View style={styles.tr} key={key} wrap={false}>
                <View style={[styles.tdCustomWidth, { width: "30%" }]}>
                  <Text style={styles.text}>{key}</Text>
                </View>
                <View style={[styles.tdCustomWidth, { width: "20%" }]}>
                  <Text style={[statusStyle, styles.statusText]}>
                    {value.status}
                  </Text>
                </View>
                <View style={[styles.tdCustomWidth, { width: "20%" }]}>
                  <Text style={styles.text}>{value.info}</Text>
                </View>
                <View style={[styles.tdCustomWidth, { width: "35%" }]}>
                  <Text style={styles.text}>{value.description}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  </View>
);

export default ApplicationPermissionsPage;
