import React from "react";
import { Box, Button, Typography, Tooltip } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const PAGE_SIZES = [10, 20, 50, 100];

const Pagination = ({ page, pageSize, totalRows, setPagination }) => {
  const totalPages = Math.ceil(totalRows / pageSize);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize, page: 1 }));
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
        marginTop: 16,
      }}
    >
      <Tooltip title="Previous">
        <span>
          <Button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <ArrowBackIos />
          </Button>
        </span>
      </Tooltip>
      <Typography>
        Page {page} of {totalPages}
      </Typography>
      <Tooltip title="Next">
        <span>
          <Button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <ArrowForwardIos />
          </Button>
        </span>
      </Tooltip>
      <select
        value={pageSize}
        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
      >
        {PAGE_SIZES.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </Box>
  );
};

export default Pagination;
