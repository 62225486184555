import clsx from "clsx";
import React from "react";
import { Typography } from "@material-ui/core";
import { Form, Field } from "react-final-form";

import { validateEmail } from "utils/scanValidations";

import Button from "components/Button";
import { FormTextInput } from "components/FormComponents/Inputs";

import useStyles from "../styles";

const ConsumerReportEmailForm = ({ onSubmit, closeHandler }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          aria-labelledby="customer-flow-form-title"
          onSubmit={handleSubmit}
          className={classes.customerReportFlowForm}
        >
          <Typography
            id="customer-flow-form-title"
            component="h2"
            className={clsx(classes.title, "clear-background")}
          >
            Almost done. Enter your email for the report.
          </Typography>
          <Field
            label="Your Email*"
            name="email"
            validate={validateEmail}
            className={classes.input}
            placeholder="email@example.com"
            render={(props) => <FormTextInput {...props} />}
          />
          <Button
            type="submit"
            className={clsx(classes.action, classes.mainAction)}
          >
            Run the Scanner
          </Button>
          <Button
            variant="secondary"
            className={clsx(classes.action, classes.secondaryAction)}
            onClick={closeHandler}
          >
            Go Back
          </Button>
          <span className={classes.emailNotice}>
            * Only used to notify you when the report is ready.
          </span>
        </form>
      )}
    />
  );
};

export default ConsumerReportEmailForm;
