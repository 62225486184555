import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    height: "auto",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.grey[600],
    margin: "auto",
    left: "50%",
    transform: "translate(-50%)",
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[600],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  toggleButton: {
    borderColor: "white",
    width: "35px",
    height: "35px",
    padding: "0px",
    backgroundColor: theme.palette.background.default,
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.blue[200],
    },
    "&.Mui-disabled": {
      opacity: 0.4,
    },
  },
  toggleButtonLabel: {
    height: "100%",
  },
  tooltip: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.grey[200],
    borderRadius: "2px",
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
}));
