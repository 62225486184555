/* eslint-disable no-useless-escape */
const appFileRegex = /.*\.(apk|ipa)$/;
export const validateApp = (value) => {
  const returnValue =
    value && appFileRegex.test(value.name)
      ? undefined
      : "Please enter a valid .apk or .ipa file";
  return returnValue;
};

export const siteURLRegex = /^(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,18}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const httpRegex = /^https?:\/\//;
export const validateSite = (value) => {
  const errorMessage = "Please, enter a valid URL for the site.";

  if (!value) {
    return errorMessage;
  }

  const testValue = httpRegex.test(value) ? value : `https://${value}`;
  return siteURLRegex.test(testValue) ? undefined : errorMessage;
};

export const validateSiteRestriction = (scannedSite) => (value) => {
  const testValue = httpRegex.test(value) ? value : `https://${value}`;
  return scannedSite === null || testValue === scannedSite
    ? undefined
    : `You can only make scans for ${scannedSite} unless you upgrade. Go to your account settings to upgrade plan.`;
};

export const requiredCountry = (value) => {
  return !value || (value && value.length === 0)
    ? "Please select at least one jurisdiction / country."
    : undefined;
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const EMAIL_ERROR_MESSAGE = "Please insert a valid email address.";
export const validateEmail = (value) =>
  emailRegex.test(value) ? undefined : EMAIL_ERROR_MESSAGE;

export const isRequiredString = (value) => {
  return value === undefined || value === ""
    ? "This value is required."
    : undefined;
};

const platformRegexes = {
  bitbucket: /^https?:\/\/(www\.)?bitbucket\.org\/[\w-.]+\/[\w-.]+$/i,
  github: /^https?:\/\/(www\.)?github\.com\/[\w-.]+\/[\w-.]+$/i,
  gitlab: /^https?:\/\/(www\.)?gitlab\.com\/[\w-.]+\/[\w-.]+$/i,
};

export const validateRepositoryURL = (url) => {
  for (const [platform, regex] of Object.entries(platformRegexes)) {
    if (regex.test(url)) {
      return platform;
    }
  }
  return null;
};

