import { authService } from "redux/_authentication/authentication.service";
import isObject from "lodash.isobject";
import isArray from "lodash.isarray";
import { FORM_ERROR } from "final-form";
import { camelizeKeys } from "humps";

let displayError = () => {};

const initDisplayError = (callback) => (displayError = callback);

const displayErrorMessages = (errors) => {
  if (isObject(errors)) {
    if (errors.message) {
      displayError(errors.message);
    } else {
      Object.keys(errors).map((key) => displayErrorMessages(errors[key]));
    }
  } else if (isArray(errors)) errors.map((err) => displayErrorMessages(err));
};

const parseFormErrors = (errors) => {
  if (isObject(errors)) {
    if (errors.message) {
      return { [FORM_ERROR]: errors.message };
    } else {
      Object.keys(errors).map((key) => displayErrorMessages(errors[key]));
      return Object.keys(errors).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]:
            isArray(errors[curr]) &&
            errors[curr].length > 0 &&
            isObject(errors[curr][0])
              ? errors[curr][0].message
              : null,
        }),
        {}
      );
    }
  }
  return {};
};

const parseError = (error) => {
  if (isObject(error)) {
    if (error.message) {
      let message;
      try {
        message = JSON.parse(error.message);
      } catch {
        message = error.message;
      }

      return {
        title:
          typeof message !== "string" ? Object.values(message)[0][0] : message,
      };
    } else {
      Object.keys(error).map((key) => displayErrorMessages(error[key]));
      return Object.keys(error).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]:
            isArray(error[curr]) &&
            error[curr].length > 0 &&
            isObject(error[curr][0])
              ? error[curr][0].message
              : null,
        }),
        {}
      );
    }
  }
  return {};
};

const REFACTORED_ROUTES = [
  "news",
  "reports",
  "integratrions",
  "schedulers",
  "sitemap-scans",
  "scans",
];

const JSON_REPORT_DETAILS_URL_REGEX = /.*media\/scan\/report\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/;

function handleFetchResponse(response, alreadyLogedOut, hideErrorMessages, formatResponse = true ) {
  return response.json().then((result) => {
    if (!response.ok) {
      if (!alreadyLogedOut && response.status === 401) {
        authService.logout();
      } else if (response.status === 500) {
        const error = {
          status: response.status,
          source: undefined,
          title: response.statusText,
          detail: result,
        };

        return Promise.reject([error]);
      }

      if (result.error) {
        const error = result.error;
        // displayErrorMessages(errors);
        return Promise.reject(parseError(error));
      }
      if (result.errors) {
        const errors = result.errors;
        if (!hideErrorMessages) displayErrorMessages(errors);
        return Promise.reject(parseFormErrors(errors));
      }

      return Promise.reject(result);
    }
    // TODO: camelize everything
    const matchedRoute = response.url.split("/")[3];
    return REFACTORED_ROUTES.includes(matchedRoute) ||
      JSON_REPORT_DETAILS_URL_REGEX.test(response.url)
      ? (formatResponse ? camelizeKeys(result) : result)
      : result;
  });
}

export { handleFetchResponse, initDisplayError };
