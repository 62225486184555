import React, { useState, useEffect } from "react";

const Pagination = ({
  className,
  current,
  total,
  setCurrent,
  onPageChange,
  extraParams
}) => {
  const [prevButtonHidden, hidePrevButton] = useState(current === 0);
  const [nextButtonHidden, hideNextButton] = useState(current === total - 1);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    if (current === 0) hidePrevButton(true);
    else hidePrevButton(false);
    if (current === total - 1) hideNextButton(true);
    else hideNextButton(false);
  }, [current, total]);

  const goToPage = (x) => async () => {
    setButtonsDisabled(true);
    if (onPageChange) await onPageChange(x, extraParams);
    setCurrent(x);
    setButtonsDisabled(false);
  };

  const prevPage = goToPage(current - 1);
  const nextPage = goToPage(current + 1);

  return (
    <div className={className}>
      <div>
        {!prevButtonHidden && (
          <button
            className="before"
            onClick={prevPage}
            disabled={buttonsDisabled}
          >
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.13072 0.269475C9.42362 0.562368 9.42362 1.03724 9.13072 1.33013L2.46105 7.9998L9.13072 14.6695C9.42362 14.9624 9.42362 15.4372 9.13072 15.7301C8.83783 16.023 8.36295 16.023 8.07006 15.7301L0.870061 8.53013C0.729408 8.38948 0.650391 8.19872 0.650391 7.9998C0.650391 7.80089 0.729408 7.61013 0.870061 7.46947L8.07006 0.269475C8.36295 -0.0234186 8.83783 -0.0234186 9.13072 0.269475Z"
                fill="#686C78"
              />
            </svg>
          </button>
        )}
        <span className="pages">
          <span className="current">{current + 1}</span>
          <span className="separator">/</span>
          <span className="total">{total}</span>
        </span>
        {!nextButtonHidden && (
          <button
            className="after"
            onClick={nextPage}
            disabled={buttonsDisabled}
          >
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.870061 0.269475C0.577167 0.562368 0.577167 1.03724 0.870061 1.33013L7.53973 7.9998L0.870061 14.6695C0.577167 14.9624 0.577167 15.4372 0.870061 15.7301C1.16295 16.023 1.63783 16.023 1.93072 15.7301L9.13072 8.53013C9.27137 8.38948 9.35039 8.19872 9.35039 7.9998C9.35039 7.80089 9.27137 7.61013 9.13072 7.46947L1.93072 0.269475C1.63783 -0.0234186 1.16295 -0.0234186 0.870061 0.269475Z"
                fill="#686C78"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
