import React, { useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import NotSeeIcon from "@material-ui/icons/VisibilityOff";
import SeeIcon from "@material-ui/icons/Visibility";
import useStyles from "./styles";

const isString = (val) => typeof val === "string" || val instanceof String;

const TextInput = (props) => {
  const {
    label = "",
    placeholder,
    type,
    helperText,
    className = null,
    style = {},
    disabled = false,
    error = false,
    value,
    onChange,
    InputProps = {},
    ...restProps
  } = props;
  const classes = useStyles({ error });
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseUp = () => setShowPassword(false);
  const handleMouseDown = () => setShowPassword(true);

  return (
    <span className={clsx(classes.container, className)} style={style}>
      <label className={classes.inputContainer}>
        {isString(label) ? (
          <span className={classes.inputLabel}>{label}</span>
        ) : (
          label
        )}
        <TextField
          className={classes.textField}
          placeholder={placeholder}
          margin="normal"
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          variant="outlined"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          disabled={disabled}
          error={error}
          InputProps={{
            ...InputProps,
            ...(type === "password"
              ? {
                  endAdornment: Boolean(value) ? (
                    <InputAdornment
                      color="inherit"
                      fontSize="inherit"
                      position="end"
                      className={classes.addornment}
                    >
                      <IconButton
                        className={classes.addornIcon}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                      >
                        {showPassword ? (
                          <NotSeeIcon fontSize="inherit" color="inherit" />
                        ) : (
                          <SeeIcon fontSize="inherit" color="inherit" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
                  classes: { adornedEnd: classes.addornEnd },
                }
              : {}),
          }}
          {...restProps}
        />
      </label>
      {isString(helperText) ? (
        <p className={classes.helperText}>{helperText}</p>
      ) : (
        helperText
      )}
    </span>
  );
};

export default TextInput;
