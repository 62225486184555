import { countriesConstants } from "redux/_countries/countries.constants";

const initialState = {
  errors: {},
  data: [],
  loading: false,
};

export function countries(state = initialState, action) {
  switch (action.type) {
    case countriesConstants.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case countriesConstants.GET_COUNTRIES_SUCCESS:
      const { data } = action;
      return {
        ...state,
        loading: false,
        data: data.map(({ id, name }) => ({ value: id, label: name })),
      };
    case countriesConstants.GET_COUNTRIES_FAILURE:
      const { errors } = action;
      return {
        ...state,
        loading: false,
        errors,
      };
    default:
      return state;
  }
}
