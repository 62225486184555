import profile from "./profile-icon";
import company from "./company-icon";
import arrowTrenddown from "./arrow-trenddown";
import arrowTrendup from "./arrow-trendup";
import arrowdown from "./arrowdown-icon";
import arrowup from "./arrowup-icon";
import bell from "./bell-icon";
import caret from "./caret-icon";
import check from "./check-icon";
import close from "./close-icon";
import columns from "./columns-icon";
import compare from "./compare-icon";
import consent from "./consent-icon";
import database from "./database-icon";
import download from "./download-icon";
import dynamic from "./dynamic-icon";
import enviroment from "./enviroment-icon";
import facebook from "./fb-icon";
import fingerprintBig from "./fingerprint_big-icon";
import fingerprint from "./fingerprint-icon";
import hamburger from "./hamburger-icon";
import help from "./help-icon";
import linkedin from "./linkedin-icon";
import message from "./message-icon";
import piirecords from "./piirecords-icon";
import playscan from "./playscan-icon";
import plus from "./plus-icon";
import preferences from "./preferences-icon";
import scan from "./scan-icon";
import settings from "./settings-icon";
import share from "./share-icon";
import tables from "./tables-icon";
import twitter from "./twitter-icon";
import unprotected from "./unprotected-icon";
import envsScans from "./envs-scans.svg";
import copyclipboard from "./copyclipboard.svg";
import warning from "./warning.svg";
import warningOrange from "./warning-orange.svg";
import menu from "./Menu";
import consentsOverview from "./ConsentsOverview";
import overview from "./Overview";
import calendar from "./calendar-icon";
import scanDataOwner from "./scan-dataowner.svg";
import scanDataMap from "./scan-datamap.svg";
import error from "./error.svg";
import lock from "./lock";
import searchIcon from "./search-icon";
import chevronDownIcon from "./chevron-down-icon";
import menuThreePoints from "./menu-three-points";
import arrowRotate from "./arrow-rotate";
import goTop from "./go-top";
import arrowRedo from "./arrow-redo";
import card from "./card";
import login from "./login-icon";
import shield from "./shield-icon";
import california from "./california";
import plusOutlined from "./plus-outlined";
import chevronRight from "./chevron-right";
import email from "./email-icon";
import link from "./link-icon";
import arrowRight from "./arrow-right";
import chevronsRight from "./chevrons-right";
import logout from "./logout";
import menuDots from "./menu-dots";
import playOutlined from "./play-outlined";
import errorOutlined from "./error-outlined";
import checkOutlined from "./check-outlined";
import trash from "./trash-icon";
import circledForward from "./circled-forward";
import circledPlay from "./circled-play";
import clock from "./clock-icon";
import databaseConfig from "./database-config";
import infoIcon from "./info-icon";
import cookies from "./cookies-icon";
import consentsReports from "./ico-consents-reports";
import prvLinks from "./ico-prv-links";
import requestsIcon from "./ico-requests";
import userIcon from "./icon-user";
import visa from "./PaymentMethods/visa-icon";
import mastercard from "./PaymentMethods/mastercard-icon.svg";
import amex from "./PaymentMethods/amex-icon.svg";
import discover from "./PaymentMethods/discover-icon.png";
import jcb from "./PaymentMethods/jcb-icon.png";
import diners from "./PaymentMethods/diners-icon.png";
import unionpay from "./PaymentMethods/unionpay-icon.svg";

export default {
  profile,
  company,
  arrowTrenddown,
  arrowTrendup,
  arrowdown,
  arrowup,
  bell,
  caret,
  check,
  close,
  columns,
  compare,
  consent,
  database,
  download,
  dynamic,
  enviroment,
  facebook,
  fingerprintBig,
  fingerprint,
  hamburger,
  help,
  linkedin,
  message,
  piirecords,
  playscan,
  plus,
  preferences,
  scan,
  settings,
  share,
  tables,
  twitter,
  unprotected,
  envsScans,
  copyclipboard,
  warning,
  warningOrange,
  menu,
  consentsOverview,
  overview,
  calendar,
  scanDataOwner,
  scanDataMap,
  error,
  lock,
  searchIcon,
  chevronDownIcon,
  menuThreePoints,
  arrowRotate,
  goTop,
  arrowRedo,
  card,
  login,
  shield,
  california,
  plusOutlined,
  chevronRight,
  email,
  link,
  arrowRight,
  chevronsRight,
  logout,
  menuDots,
  playOutlined,
  errorOutlined,
  checkOutlined,
  trash,
  circledForward,
  circledPlay,
  clock,
  databaseConfig,
  infoIcon,
  cookies,
  consentsReports,
  prvLinks,
  requestsIcon,
  userIcon,
  visa,
  mastercard,
  amex,
  discover,
  jcb,
  diners,
  unionpay,
};
