import React from "react";
import { connect } from "react-redux";

import PageInfo from "components/page-info/page-info";
import getProperty from "utils/get-property";

function PageInfoContainer({ lastScan, exportScan }) {
  return (
    <PageInfo
      date={lastScan}
      onExport={exportScan}
      onShare={() => alert("implement share?")}
    />
  );
}

/**
 * State props
 */
function mapStateToProps(state, { envId }) {
  return {
    lastScan: getProperty(
      state,
      `environments.environmentList.${envId}.lastScan`
    ),
  };
}

/**
 * State actions
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    exportScan: () => alert("TODO: Handle time-consuming report generation"),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageInfoContainer);
