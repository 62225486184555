import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    header: {
      alignItems: "center",
      backgroundColor: theme.palette.blue["500"],
      padding: "31px 48px",
    },
    headerLogo: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
    },
    headerContent: {},
    headerText: {
      color: "#fff",
      fontFamily: theme.typography.titleFont,
      fontSize: theme.typography.htmlFontSize,
      width: "100%",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
    },
    headerUrl: {
      color: theme.palette.aquamarine[100],
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "100%",
      display: "block",
    },
    headerScore: {
      color: "white",
      textShadow: "1px 1px 1px rgb(0 0 0 / 10%)",
      borderRadius: theme.spacing(0.5),
      height: theme.spacing(5),
      width: 200,
      fontWeight: 700,
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(3),
        width: "100%",
      },
      "@media (max-width: 800px)": {
        padding: 9,
        height: 50,
      },
    },
    reportResultsWrapper: {
      width: "90%",
      maxWidth: 1400,
      margin: "auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    personalDataUsageSection: {
      display: "flex",
      width: "90%",
      maxWidth: 1400,
      margin: "0 auto",
      textAlign: "center",
      marginBottom: 41,
      "& #personalchartdiv": {
        height: 520,
      },
      "@media (max-width: 800px)": {
        flexDirection: "column",
      },
    },
    summary: {
      flex: 1,
      textAlign: "left",
      paddingRight: theme.spacing(3),
      "& h2": {
        color: theme.palette.primary.semidark,
        fontFamily: ["Aldrich", "Inter", "sans-serif"],
        fontSize: 39,
        fontWeight: "normal",
        marginBottom: 3,
      },
      "& p": {
        color: theme.palette.grey[800],
        fontSize: 16,
      },
    },
    complexityScore: {
      position: "relative",
      fontSize: 40,
      padding: "9px 0 14px 0",
      display: "block",
    },
    infoIcon: {
      position: "absolute",
      top: 25,
      left: 137,
      opacity: 0.5,
    },
    infoIconScore: {
      margin: "-3px 0 0 7px",
    },
    popover: {
      maxWidth: 330,
      padding: 15,
      margin: 0,
    },
    chart: { flex: 3 },
    pageTitle: {
      padding: theme.spacing(6, 4),
      textAlign: "center",
      color: theme.palette.primary.semidark,
    },
    loader: {
      margin: theme.spacing(10),
    },
    scoreCardContainer: {
      display: "flex",
      [theme.breakpoints.up("xs")]: {
        width: "100%",
        padding: theme.spacing(0, 0, 8, 0),
      },
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 2, 8, 2),
      },
      [theme.breakpoints.between("sm", "sm")]: {
        width: "50%",
        "&:nth-child(odd)": {
          paddingLeft: 0,
        },
        "&:nth-child(even)": {
          paddingRight: 0,
        },
      },
      [theme.breakpoints.between("md", "md")]: {
        width: "33%",
        "&:nth-child(3n)": {
          paddingRight: 0,
        },
        "&:nth-child(3n + 1)": {
          paddingLeft: 0,
        },
      },
      [theme.breakpoints.up("lg")]: {
        width: "25%",
        "&:nth-child(4n)": {
          paddingRight: 0,
        },
        "&:nth-child(4n + 1)": {
          paddingLeft: 0,
        },
      },
    },
    scoreCard: {
      width: "100%",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    scoreTitle: {
      textAlign: "center",
      margin: theme.spacing(4, 0),
      color: theme.palette.primary.semidark,
      lineHeight: 1,
    },
    scoreValue: {
      paddingTop: 6,
      width: 100,
      height: 100,
      borderRadius: "100%",
      color: "#fff",
      fontFamily: theme.typography.titleFont,
      textTransform: "uppercase",
      fontSize: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.16)`,
    },
    scoreValuePositive: {
      backgroundColor: theme.palette.green[100],
    },
    scoreValueNegative: {
      backgroundColor: theme.palette.red[500],
    },
    scoreDescription: {
      marginTop: theme.spacing(4),
      marginBottom: 0,
      fontFamily: theme.typography.titleFont,
      color: theme.palette.primary.semidark,
      fontSize: 18,
      textAlign: "center",
    },
    postSection: {
      padding: theme.spacing(2, 4, 4, 4),
    },
    postDescription: {
      maxWidth: 900,
      textAlign: "center",
      margin: theme.spacing(2, 4, 4, 4),
      fontSize: "1.3em",
    },
    searchSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 2,
      position: "relative",
    },
    searchSectionInitial: {
      padding: theme.spacing(16, 4),
    },
    searchSectionReport: {
      padding: theme.spacing(8, 4, 0),
    },
    searchSectionTitle: {
      textAlign: "center",
    },
    searchDescription: {
      maxWidth: 470,
      textAlign: "center",
      margin: theme.spacing(2, 0, 4),
    },
    blogLink: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "1.5em",
      fontWeight: 500,
      lineHeight: "30px",
      color: theme.palette.blue[500],
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      "& > svg": {
        color: theme.palette.aquamarine[100],
        marginLeft: "3px",
      },
      "&:hover": {
        textDecoration: "none",
        opacity: 0.9,
      },
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      backgroundColor: "white",
      padding: theme.spacing(2),
      textAlign: "center",
    },
    modalTitle: {
      fontWeight: 400,
    },
    recapcha: {
      display: "inline-block",
      margin: "9px auto 15px auto",
    },
    sendButton: {
      display: "block",
      margin: "0 auto",
    },
    reportButton: {
      margin: theme.spacing(1, 0, 8, 0),
    },
    tabs: {
      width: 960,
      margin: theme.spacing(2, "auto", 6, "auto"),
      "& .MuiTab-wrapper": {
        fontSize: 38,
        fontFamily: ["Aldrich", "Inter", "sans-serif"],
        "@media (max-width: 800px)": {
          fontSize: 23,
        },
      },
      "& .MuiTabs-indicator": {
        height: 3,
      },
      "@media (max-width: 800px)": {
        width: "auto",
      },
    },
  }),
  { name: "ConsumerReportResult" }
);
