import {
  requestStart,
  requestEnd,
  setTreeMapData,
  setTreeByCountryData,
  setOwnersData,
  setCustomersByCountryData,
  setLastScans,
  setFunctionalUses,
  setError,
} from "redux/_charts/charts.actions";
import { getMonth, getFilters } from "redux/_charts/charts.selectors";
import {
  getTreeMapData,
  getTreeByCountryData,
  getLastScansTreeByCountryData,
  getScansByMonth,
  getCustomersByCountryData,
  getLastScans,
  getOwnersData,
  getFunctionalUses,
} from "redux/_charts/charts.service";
import { saveScans } from "redux/_scans/scans.actions";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import CONSTANTS from "redux/_filters/filters.constants";
const { TYPES } = CONSTANTS;

export const getTreeMap = () => (dispatch, getState) => {
  const filters = getFilters(getState());
  const data = getState().filters.data;
  if (filters && filters[TYPES.SCAN]) {
    dispatch(requestStart());
    return getTreeMapData(filters, data)
      .then((data) => {
        dispatch(setTreeMapData(data));
        dispatch(requestEnd());
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(setError(true));
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};

export const getScans = () => (dispatch, getState) => {
  const month = getMonth(getState());
  const envId = getSelectedEnvId(getState());
  if (month) {
    dispatch(requestStart());
    return getScansByMonth(envId, month)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveScans(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(setError(true));
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};

export const getLastScansData = () => (dispatch, getState) => {
  const state = getState();
  const filters = getFilters(state);
  const envId = getSelectedEnvId(state);
  const data = state.filters.data;
  if (envId) {
    dispatch(requestStart());
    return getLastScans(envId, filters, data)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(setLastScans(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(setError(true));
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.reject();
  }
};

export const getTreeByCountry = (lastScans = false) => (dispatch, getState) => {
  const filters = getFilters(getState());
  if (filters && filters[TYPES.SCAN]) {
    const data = getState().filters.data;
    dispatch(requestStart());
    return getTreeByCountryData(filters, data)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(setTreeByCountryData(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else if (lastScans) {
    dispatch(requestStart());
    return getLastScansTreeByCountryData()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(setTreeByCountryData(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(setError(true));
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};

export const getOwners = () => (dispatch, getState) => {
  const filters = getFilters(getState());
  const data = getState().filters.data;
  if (filters && filters[TYPES.SCAN]) {
    dispatch(requestStart());
    return getOwnersData(filters, data)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(setOwnersData(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(setError(true));
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};

export const getCustomersByCountry = () => (dispatch, getState) => {
  dispatch(requestStart());
  return getCustomersByCountryData()
    .then((data) => {
      dispatch(requestEnd());
      dispatch(setCustomersByCountryData(data));
      return Promise.resolve(data);
    })
    .catch((ex) => {
      dispatch(requestEnd());
      dispatch(setError(true));
      return Promise.reject();
    });
};

export const getFunctionalUsesData = () => (dispatch, getState) => {
  const filters = getFilters(getState());
  const data = getState().filters.data;
  if (filters[TYPES.SCAN]) {
    dispatch(requestStart());
    return getFunctionalUses(filters, data)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(setFunctionalUses(data));
        return Promise.resolve(data);
      })
      .catch(() => {
        dispatch(requestEnd());
        dispatch(setError(true));
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};
