import { CONSTANTS } from "redux/_consents/_config/config.constants";

const initialState = {
  loading: false,
  data: {},
};

export const config = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.CONSENTS_CONFIG_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.CONSENTS_CONFIG_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.CONSENTS_CONFIG_SAVE_DATA:
      return {
        ...state,
        data,
      };
    case CONSTANTS.CONSENTS_CONFIG_SAVE_FILE:
      return {
        ...state,
        data: { ...state.data, branding: data.branding },
      };
    default:
      return state;
  }
};
