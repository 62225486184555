import { mapSectionNameToId } from "../helpers";
import BrowsableActiviesSection from "./BrowsableActiviesSection";
import NetworkSecuritySection from "./NetworkSecurity";
import ManifestAnalysisSection from "./ManifestAnalysis";
import BinaryAnalysisSection from "./BinaryAnalysis";
import NiapAnalysisSection from "./NiapAnalysis";
import CertificateInfoSection from "./CetificateInfo";
import CodeAnalysisSection from "./CodeAnalysis";
import APKIDAnalysisSection from "./APKIDAnalysis";
import EmailsSection from "./Emails";
import TrackersSection from "./Trackers";
import HardcodedSecretsSection from "./HardcodedSecrets";
import FirebaseURLSection from "./FirebaseURL";
import CustomURLSchemesSection from "./CustomURLSchemes";

import IPABinaryAnalysisSection from "./IPABinaryAnalysis";
import ATSAnalysisSection from "./ATSAnalysis";
import FindingSeveritySection from "./FindingSeverity";
import ApplicationPermissions from "./ApplicationPermissions";
import Domains from "./Domains";
import StoreInfo from "./StoreInfo";
import Landing from "./Landing";
import OpportunitiesSection from "./Opportunities";
import moment from "moment";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTION_ID, SECTIONS_CONTENT, ORDERED_ANCHORS } from "../constants";
import SDKs from "./SDK";

export const getSectionComponents = ({
  appType,
  goToNextSectionAction,
  current,
  data,
}) => {
  const sectionKeys = (ORDERED_ANCHORS[appType] || []).map(mapSectionNameToId);
  const COMPONENTS = {
    [SECTION_ID.landing]: (
      <Page
        key={SECTIONS_CONTENT.landing.key}
        footerBackgroundColor={SECTIONS_CONTENT.landing.backgroundColor}
        className="blue-background"
        footerText={
          <div className="privacy-tech">Check the application permissions</div>
        }
        goToNextSectionAction={goToNextSectionAction}
        trimPaddingTop
      >
        {/* this component calculate the values for testScoresItems */}
        <Landing
          current={current}
          data={data}
          content={{
            title: data?.app_name,
            version: data?.version_name || data?.app_version,
            //TODO: fix this app type
            icon:
              (data?.playstore_details || data?.appstore_details)?.icon || null,
            scanned: [
              {
                title: "File name:",
                content: data.file_name,
                className: "url-scanned",
              },
              data.app_type === "apk"
                ? {
                    title: "Package Name",
                    content: data.package_name,
                  }
                : {
                    title: "Identifier",
                    content: data.bundle_id,
                  },
              {
                title: "Scan date:",
                content: moment(data.scanDate).format("MM-DD-YYYY hh:mm"),
                className: "last-scanned",
              },
            ],
            resource: data.resource,
            security_score: data.appsec.security_score,
            totalDiff: null,
          }}
        />
      </Page>
    ),
    [SECTION_ID.findingSeverity]: (
      <FindingSeveritySection
        goToNextSectionAction={goToNextSectionAction}
        data={data}
        appType={appType}
      />
    ),
    [SECTION_ID.certificateInfo]: (
      <CertificateInfoSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.certificate_analysis}
      />
    ),
    //TODO: fix this app type
    [SECTION_ID.storeInfo]: (
      <StoreInfo goToNextSectionAction={goToNextSectionAction} data={data} />
    ),
    [SECTION_ID.applicationPermissions]: (
      <ApplicationPermissions
        goToNextSectionAction={goToNextSectionAction}
        data={data.permissions}
      />
    ),
    [SECTION_ID.domains]: (
      <Domains
        data={data.domains}
        goToNextSectionAction={goToNextSectionAction}
      />
    ),
    [SECTION_ID.browsableActivies]: Object.entries(
      data.browsable_activities || {}
    ).length ? (
      <BrowsableActiviesSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.browsable_activities}
      />
    ) : null,
    [SECTION_ID.networkSecurity]: (
      <NetworkSecuritySection
        goToNextSectionAction={goToNextSectionAction}
        data={data.network_security?.network_findings || []}
      />
    ),
    [SECTION_ID.atsAnalysis]: (
      <ATSAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.ats_analysis?.ats_findings}
      />
    ),
    [SECTION_ID.manifestAnalysis]: (
      <ManifestAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.manifest_analysis?.manifest_findings || []}
      />
    ),

    [SECTION_ID.binaryAnalysis]: data.binary_analysis?.length ? (
      <BinaryAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.binary_analysis}
      />
    ) : null,

    [SECTION_ID.ipaBinaryAnalysis]: (
      <IPABinaryAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.macho_analysis}
      />
    ),
    [SECTION_ID.niapAnalysis]: (
      <NiapAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.niap_analysis}
      />
    ),
    [SECTION_ID.codeAnalysis]: (
      <CodeAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={
          appType === "apk"
            ? data.code_analysis.findings
            : data.binary_analysis.findings
        }
        appType={appType}
      />
    ),
    [SECTION_ID.apkidAnalysis]: (
      <APKIDAnalysisSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.apkid}
      />
    ),
    [SECTION_ID.emails]: (
      <EmailsSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.emails}
      />
    ),
    [SECTION_ID.trackers]: (
      <TrackersSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.trackers.trackers}
      />
    ),
    [SECTION_ID.hardcodedSecrets]: (
      <HardcodedSecretsSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.secrets}
      />
    ),
    [SECTION_ID.firebaseURL]: (
      <FirebaseURLSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.firebase_urls}
      />
    ),
    [SECTION_ID.customURLSchemes]: (
      <CustomURLSchemesSection
        goToNextSectionAction={goToNextSectionAction}
        data={data.bundle_url_types}
      />
    ),
    [SECTION_ID.opportunities]: (
      <OpportunitiesSection
        goToNextSectionAction={goToNextSectionAction}
        data={data}
        sectionKeys={sectionKeys}
      />
    ),
    [SECTION_ID.sdks]: data.sdks.length && (
      <SDKs goToNextSectionAction={goToNextSectionAction} data={data.sdks} />
    ),
  };
  return sectionKeys.map((sectionId) => COMPONENTS[sectionId]);
};
