import React, { useMemo, useState } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import ExpandedPanel from "../FindingSeverity/ExpandedPanel";
import { BoxDetails } from "../FindingSeverity/InformationSection";
import SectionContent from "pages/AppReport/common/SectionContent";

const BrowsableActiviesSection = ({ goToNextSectionAction, data }) => {
  const formattedData = useMemo(
    () =>
      Object.entries(data).map((browsableActivity) => ({
        activity: browsableActivity[0],
        intent: { ...browsableActivity[1] },
      })),
    [data]
  );
  const [expanded, setExpanded] = useState();
  return (
    <Page
      key={SECTIONS_CONTENT.browsableActivies.key}
      footerBackgroundColor={SECTIONS_CONTENT.browsableActivies.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the network security</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.browsableActivies.description}>
        {SECTIONS_CONTENT.browsableActivies.title}
      </Title>
      <SectionContent>
        {formattedData.map((el) => (
          <ExpandedPanel
            title={el.activity}
            className="scrollable-element"
            simpleTitle
            key={el.activity}
            onChange={() =>
              setExpanded(expanded !== el.activity ? el.activity : "")
            }
            expanded={expanded === el.activity}
          >
            <BoxDetails
              data={[
                {
                  label: "Schemes",
                  value: el.intent.schemes.join(", "),
                },
                {
                  label: "Hosts",
                  value: el.intent.hosts.join(", "),
                },
              ]}
            />
          </ExpandedPanel>
        ))}
      </SectionContent>
    </Page>
  );
};

export default BrowsableActiviesSection;
