import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    minHeight: "58px",
    borderRadius: theme.spacing() / 2,
    border: `solid 1px ${theme.palette.grey[500]}`,
    alignItems: "stretch",
  },
  label: {
    width: "122px",
    flexShrink: 0,
    backgroundColor: theme.palette.grey[600],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.grey[200],
  },
  inputContainer: {
    alignItems: "center",
    padding: "0px 24px",
    flexDirection: "row",
    "& >div": {
      width: "100%",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  button: {
    textTransform: "none",
    color: theme.palette.blue["young"],
    fontSize: "14px",
    fontWeight: 500,
    width: "100px",
    borderRadius: "0px 4px 4px 0px",
  },
  error: {
    marginLeft: 122,
    color: theme.palette.red[500],
  },
}));
