import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DeleteButton from "components/Consents/Collector/Policies/components/deleteButton";
import {
  FormCheckInput,
  FormTextInput,
} from "components/SettingsSections/components/inputs";
import isNil from "lodash.isnil";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import {
  getConsentsConfig,
  getConsentsLanguages,
} from "redux/_consents/_config/config.selectors";
import {
  createPolicy,
  deletePolicy,
  updatePolicy,
} from "redux/_consents/_policies/policies.async.actions";
import { getPoliciesLoadingState } from "redux/_consents/_policies/policies.selectors";
import useStyles from "./policyFormStyles";

const PolicyForm = (props) => {
  const classes = useStyles();

  const {
    id,
    create,
    data,
    deletePolicyData,
    languages,
    loading,
    onCancel,
    update,
    widgetToken,
  } = props;

  const [language, setLanguage] = useState(languages[0]);

  const onSubmit = async ({ name, mandatory, description }) => {
    const values = { name, mandatory, description, widget_token: widgetToken };
    if (!id) {
      try {
        await create(values).then((_) => onCancel());
      } catch (e) {
        return e;
      }
    } else {
      try {
        await update(id, values).then((_) => onCancel());
      } catch (e) {
        return e;
      }
    }
  };

  const onDeleteClick = () => deletePolicyData(id).then((_) => onCancel());
  const identity = (value) => value;

  return (
    <Box className={classes.container}>
      <Box className={classes.boxHeader}>
        <Typography className={classes.requiredText}>
          All fields marked <span className={classes.asterisk}>*</span> are
          required.
        </Typography>
        <IconButton onClick={onCancel} className={classes.closeButton}>
          <Close />
        </IconButton>
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={data || {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.content}>
              <Box className={classes.leftFields}>
                <Field
                  name="name"
                  component={FormTextInput}
                  label="Name *"
                  disabled={loading}
                  placeholder="Name for the policy"
                  parse={identity}
                />
                <Field
                  name="mandatory"
                  component={FormCheckInput}
                  type="checkbox"
                  text="This is Mandatory and can’t be opted-out."
                  style={{ marginTop: 8 }}
                  disabled={loading}
                  parse={identity}
                />
              </Box>
              <Box className={classes.rightField}>
                <Field
                  name="description"
                  component={FormTextInput}
                  label="Description"
                  multiline={true}
                  rowsMax="3"
                  rows="3"
                  disabled={loading}
                  placeholder="Description of the policy"
                  parse={identity}
                />
              </Box>
            </Box>
            <Box className={classes.buttonsContainer}>
              {languages.length > 0 && (
                <ToggleButtonGroup
                  value={language}
                  exclusive
                  onChange={(e, val) => {
                    if (!isNil(val)) setLanguage(val);
                  }}
                  className={classes.toggleGroup}
                >
                  {languages.map((key, idx) => (
                    <ToggleButton
                      value={key}
                      key={idx}
                      classes={{
                        root: classes.toggleButton,
                        label: classes.toggleButtonLabel,
                      }}
                      disabled={loading}
                    >
                      {key}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
              {!isNil(id) && (
                <DeleteButton
                  disabled={loading}
                  name={data.name}
                  onClick={onDeleteClick}
                />
              )}
              <Button
                disabled={loading}
                className={classes.updateButton}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  languages: getConsentsLanguages(state),
  loading: getPoliciesLoadingState(state),
  widgetToken: getConsentsConfig(state).token,
});

const mapDispatchToProps = (dispatch) => ({
  create: (data) => dispatch(createPolicy(data)),
  update: (id, data) => dispatch(updatePolicy(id, data)),
  deletePolicyData: (id) => dispatch(deletePolicy(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyForm);
