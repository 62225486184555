import { Box, Button, Card, Grid, Modal, Typography } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import InfoIcon from "@material-ui/icons/Info";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import clsx from "clsx";
import ConsumerReportLayout from "components/ConsumerReportLayout";
import Loader from "components/Loader";
import { Tab, Tabs } from "components/PageWrapper";
import PersonalDataUsage from "components/PrivacyReports/Report/components/PersonalDataUsage";
import colormap from "components/PrivacyReports/Report/utils/colormap";
import { REPORT_VERSION } from "constants/reports";
import useQuery from "hooks/useQuery";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import {
  getConsumerReport as fetchConsumerReport,
  getReportJSON as fetchReportJSON,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import {
  getConsumerReportData as selectConsumerReportData,
  getCurrentReportJSON,
} from "redux/_reports/reports.selectors";
import { ReactComponent as ActivityLinkedIcon } from "./assets/activity-linked.svg";
import { ReactComponent as EasyToReadIcon } from "./assets/easy-to-read.svg";
import { ReactComponent as HasPrivacyPolicyIcon } from "./assets/has-privacy-policy.svg";
import { ReactComponent as MovesInfoAwayIcon } from "./assets/moves-info-away.svg";
import { ReactComponent as OptOutIcon } from "./assets/opt-out.svg";
import { ReactComponent as SecureToolsIcon } from "./assets/secure-tools.svg";
import { ReactComponent as SellsInfoIcon } from "./assets/sells-info.svg";
import { ReactComponent as ThirdPartyTrackersIcon } from "./assets/third-party-trackers.svg";
import { SCORE_DETAIL_ITEM } from "./constants";
import useStyles from "./styles";
import { getScoreDetailValueFor } from "./utils";
import { reportSite } from "redux/_admin/_users/users.service";

const TAB_SCORES = "TAB_SCORES";
const TAB_PRIVACY = "TAB_PRIVACY";

const Header = ({ url, totalScore }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Grid container component="header" classes={{ root: classes.header }}>
      <Grid item className={classes.headerLogo} md={7}>
        <NavLink to="/">
          <ZenDataLogo />
        </NavLink>
      </Grid>
      <Grid item container className={classes.headerContent} md={5}>
        <Grid item md={6} className={classes.headerText}>
          Results of your search:{" "}
          <span className={classes.headerUrl}>{url}</span>
        </Grid>
        <Grid item md={6}>
          <div
            className={classes.headerScore}
            style={{ backgroundColor: colormap(totalScore / 100 || 0) }}
          >
            Website privacy score: {totalScore}
            <InfoIcon
              id={"infoIcon"}
              className={classes.infoIconScore}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setPopoverOpen(true);
              }}
            />
          </div>
          <Popover
            id="InfoPopover"
            open={popoverOpen}
            onClose={() => {
              setPopoverOpen(false);
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <p className={classes.popover}>
              The score gets calculated using various attributes on your
              internet-facing assets, which could cause non-compliance and
              regulatory action in your selected jurisdiction. We check your
              site against 1000+ data collection mechanisms, your privacy policy
              (which we process using advanced Machine Learning and Natural
              Language methodologies), and emulate 2000 devices. Each check we
              perform results in a score aggregated to generate the final rating
              and represented on a 3-degree scale with thresholds for low,
              medium, and high-risk levels.
            </p>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ScoreCard = ({
  title,
  icon,
  value: { isPositive, yesOrNoValue, description },
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.scoreCard} elevation={16}>
      {icon}
      <Typography variant="h2" className={classes.scoreTitle}>
        {title}
      </Typography>
      <div
        className={clsx(
          classes.scoreValue,
          isPositive ? classes.scoreValuePositive : classes.scoreValueNegative
        )}
      >
        {yesOrNoValue}
      </div>
      <p className={classes.scoreDescription}>{description}</p>
    </Card>
  );
};

export const buildConsumerReportWith = (reportJSON, reportData) => {
  const scoreDetails = [];

  if (reportJSON?.scoreDetails?.sellsYourInfo !== null) {
    scoreDetails.push({
      title: "State that do not sell your information",
      icon: <SellsInfoIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.sellsYourInfo,
        reportJSON?.scoreDetails.sellsYourInfo
      ),
    });
  }
  if (reportJSON?.scoreDetails?.thirdPartyTrackers !== null) {
    scoreDetails.push({
      title: "Third-party Trackers",
      icon: <ThirdPartyTrackersIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.thirdPartyTrackers,
        reportJSON?.scoreDetails.thirdPartyTrackers
      ),
    });
  }
  if (reportJSON?.scoreDetails?.activityLinked !== null) {
    scoreDetails.push({
      title: "Activity linked across devices",
      icon: <ActivityLinkedIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.activityLinked,
        reportJSON?.scoreDetails.activityLinked
      ),
    });
  }
  if (reportJSON?.scoreDetails?.hasPrivacyPolicy !== null) {
    scoreDetails.push({
      title: "Has a Privacy Policy",
      icon: <HasPrivacyPolicyIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.hasPrivacyPolicy,
        reportJSON?.scoreDetails.hasPrivacyPolicy
      ),
    });
  }
  if (reportJSON?.scoreDetails?.easyToReadPrivacyPolicy !== null) {
    scoreDetails.push({
      title: "Easy-to-read privacy policy",
      icon: <EasyToReadIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.easyToReadPrivacyPolicy,
        reportJSON?.scoreDetails.easyToReadPrivacyPolicy
      ),
    });
  }
  if (reportJSON?.scoreDetails?.optOut !== null) {
    scoreDetails.push({
      title: "Opt-out of tracking/sale of data?",
      icon: <OptOutIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.optOut,
        reportJSON?.scoreDetails.optOut
      ),
    });
  }
  if (reportJSON?.scoreDetails?.movesInfoAway !== null) {
    scoreDetails.push({
      title: "Moves your information away from where you are located",
      icon: <MovesInfoAwayIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.movesInfoAway,
        reportJSON?.scoreDetails.movesInfoAway
      ),
    });
  }
  if (reportJSON?.scoreDetails?.usesSecureToolsToManageData !== null) {
    scoreDetails.push({
      title: "Uses secure tools to manage your data",
      icon: <SecureToolsIcon />,
      value: getScoreDetailValueFor(
        SCORE_DETAIL_ITEM.usesSecureToolsToManageData,
        reportJSON?.scoreDetails.usesSecureToolsToManageData
      ),
    });
  }

  return {
    url: reportJSON?.url,
    scanId: reportData.scan?.pk,
    totalScore: reportJSON?.totalScore,
    scoreDetails,
    personalData: reportJSON?.scoreDetails?.personalData,
  };
};

const Report = ({ report }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState(TAB_SCORES);
  const query = useQuery();
  const environment = query.get("c");
  const email = query.get("email");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pp = report.scoreDetails.filter(
    (x) => x.title === "Has a Privacy Policy"
  )[0];
  const hasPrivacyPolicy = pp ? pp.value.yesOrNoValue === "Yes" : false;

  const complexityScore = report.personalData
    ? Math.round(report.personalData.complexityScore.complexityScore)
    : 0;

  return (
    <>
      {report.personalData && hasPrivacyPolicy ? (
        <Tabs
          className={classes.tabs}
          variant="fullWidth"
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
        >
          <Tab value={TAB_SCORES} label="Score details" />
          <Tab value={TAB_PRIVACY} label="Privacy Policy Analysis" />
        </Tabs>
      ) : (
        <Typography className={classes.pageTitle} variant="h1">
          Score details:
        </Typography>
      )}
      {tab === TAB_SCORES && (
        <section className={classes.reportResultsWrapper}>
          {report.scoreDetails.map((score) => (
            <div key={score.title} className={classes.scoreCardContainer}>
              <ScoreCard {...score} />
            </div>
          ))}
        </section>
      )}
      {tab === TAB_PRIVACY && (
        <section className={classes.personalDataUsageSection}>
          <div className={classes.summary}>
            <h2>Complexity Score</h2>
            <span className={classes.complexityScore}>
              {complexityScore}/100
              <InfoIcon
                id={"infoIcon"}
                className={classes.infoIcon}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setPopoverOpen(true);
                }}
              />
            </span>
            <Popover
              id="InfoPopover"
              open={popoverOpen}
              onClose={() => {
                setPopoverOpen(false);
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <p className={classes.popover}>
                Websites with privacy policies that are "difficult to
                understand" were determined by a proprietary machine-learning
                model which takes into account privacy policy length, the
                structure of the website, description of data uses, readability
                of the page, sentence length, and lexical diversity.
              </p>
            </Popover>
            <p>
              We process your privacy policy using our Natural Language
              Processing engine to identify what information you say you collect
              from the user and its purpose. We use this classification to
              identify any gaps between your privacy policy and what is going on
              the site/app.
            </p>
          </div>
          <PersonalDataUsage
            scanId={report.scanId}
            className={classes.chart}
            email={email}
            environment={environment}
            data={report.personalData}
            loading={false}
          />
        </section>
      )}
      <section className={clsx(classes.searchSection, classes.postSection)}>
        <Typography className={classes.postDescription}>
          Did you find something concerning on how a site handles your data?
          Inform us of this site to verify it closely and take measures if
          necessary.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
          className={classes.reportButton}
        >
          Report Site
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={classes.modal}>
            <h2 className={classes.modalTitle}>
              Do you want to send a report for this site?
            </h2>
            <div className={classes.recapcha}>
              <ReCAPTCHA
                sitekey="6LcbJqceAAAAAL9T2-WAaak3YfWodginjjlh6GH4"
                onChange={setCaptcha}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              disabled={!captcha}
              onClick={() => {
                reportSite({
                  captcha,
                  reportUrl: global.location.href,
                  siteUrl: report.url,
                });
                handleClose();
                enqueueSnackbar("Report sent successfully!", {
                  variant: "success",
                });
              }}
              className={classes.sendButton}
            >
              Send Report
            </Button>
          </Box>
        </Modal>
        <Typography className={classes.postDescription}>
          What can you do next to protect your privacy? Taking the following 7
          steps will go a long way in protecting your data & privacy online.
        </Typography>
        <a
          className={classes.blogLink}
          rel="noreferrer"
          href="https://www.zendata.dev/post/consumers-7-things-to-do-to-protect-your-privacy-online"
          target="_blank"
        >
          Steps to protect your privacy online
          <ArrowForwardIcon />
        </a>
      </section>
      <section
        className={clsx(
          classes.searchSection,
          report ? classes.searchSectionReport : classes.searchSectionInitial
        )}
      >
        <Typography className={classes.searchSectionTitle} variant="h2">
          Take another look
        </Typography>
        <Typography className={classes.searchDescription}>
          Zendata shows you how much a site can be trusted, before and after our
          Privacy Protection is applied.
        </Typography>
      </section>
    </>
  );
};

const ConsumerReportResult = ({
  getConsumerReportData,
  getReportJSON,
  reportData,
  reportJSON,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  const id = query.get("r");
  const email = query.get("email");

  const [report, setReport] = useState();

  useEffect(() => {
    if (id && email) {
      getConsumerReportData(id, email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      !isEmpty(reportData) &&
      reportData.version !== REPORT_VERSION.customer
    ) {
      // TODO: refactor using notifications store
      enqueueSnackbar(
        "The requested report isn't a customer report, please verify that the URL is correct or re-generate the report.",
        { variant: "error", autoHideDuration: 7000 }
      );
    } else {
      if (reportData.data) {
        getReportJSON(reportData.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  useEffect(() => {
    if (!isEmpty(reportJSON)) {
      setReport(buildConsumerReportWith(reportJSON, reportData));
    }
  }, [reportJSON, reportData]);

  return (
    <ConsumerReportLayout
      customHeader={
        report ? (
          <Header url={report.url} totalScore={report.totalScore} />
        ) : null
      }
    >
      {report ? (
        <Report report={report} />
      ) : (
        <Loader className={classes.loader} />
      )}
    </ConsumerReportLayout>
  );
};

const mapStateToProps = (state) => ({
  reportData: selectConsumerReportData(state),
  reportJSON: getCurrentReportJSON(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConsumerReportData: (id, email) =>
    dispatch(fetchConsumerReport(id, email)),
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
  triggerReport: (values) => dispatch(postTriggerReport(values)),
});

const ConnectedConsumerReportResult = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerReportResult);

export default withRouter(ConnectedConsumerReportResult);
