import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    maxWidth: 900,
    margin: theme.spacing(0, "auto"),
  },
  brief: {
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[200],
    fontSize: 16,
  },
  notice: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[300],
    fontSize: 14,
    fontWeight: 500,
  },
  form: {
    "& .input-label": {
      width: 177,
      textAlign: "left",
      justifyContent: "flex-start",
      paddingLeft: 15,
    },
    "& span": {
      marginLeft: 15,
    },
  },
  btn: {
    backgroundColor: theme.palette.blue["young"],
    borderRadius: 4,
    color: "white",
    display: "inline-block",
    fontSize: 15,
    fontWeight: 600,
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 3),
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default styles;
