import React, { useMemo } from "react";
import { linkVertical } from "d3-shape";
import {
  BOX_WIDTH,
  BOX_WIDTH_WIDER,
  BOX_HEIGHT,
  BOX_BOTTOM_EXT,
} from "./consts";

const linkGenerator = linkVertical()
  .source((d, boxWidth) => {
    return {
      ...d.source,
      x: d.source.x + BOX_HEIGHT / 2,
      y: d.source.y + boxWidth + (d.source.selected ? BOX_BOTTOM_EXT : 0),
    };
  })
  .target((d) => {
    return {
      ...d.target,
      x: d.target.x + BOX_HEIGHT / 2,
      y: d.target.y - 5,
    };
  })
  .x((d) => d.x)
  .y((d) => d.y);

const Link = ({ link, draw, wider }) => {
  const arrow = useMemo(() => {
    const target = [link.target.x + BOX_HEIGHT / 2, link.target.y];
    return [
      ["M", target[0] + 5, target[1] - 5],
      ["L", target[0], target[1]],
      ["L", target[0] - 5, target[1] - 5],
      ["Z"],
    ]
      .map((x) => x[0] + x.slice(1).join(","))
      .join(" ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link.target.x, link.target.y]);

  const calculateOpacity = (target) => {
    const { depth, opacity } = target;
    if (opacity < 1 && depth >= 4) {
      return opacity / 4;
    } else {
      return opacity;
    }
  };

  const linkPath = useMemo(() => {
    return linkGenerator(link, wider ? BOX_WIDTH_WIDER : BOX_WIDTH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    link.x,
    link.y,
    link.target.x,
    link.target.y,
    link.source.x,
    link.source.y,
    link.source.selected,
  ]);

  if (!draw) return null;
  return (
    <g>
      <path
        d={linkPath} // `M ${points.map(x => x.join(',')).join(' ')}`}
        opacity={calculateOpacity(link.target)}
        fill={"transparent"}
        stroke={"black"}
        strokeWidth={0.5}
      />
      <path opacity={link.target.opacity} d={arrow} />
    </g>
  );
};

export default Link;
