import React, { useState } from "react";
import {
  ExpansionPanel as MuiExtensionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import SvgIcon from "components/svg-icon/svg-icon";
import { useStyles } from "./styles";
import clsx from "clsx";
import Title from "pages/AppReport/common/Title";
import Subtitle from "pages/SecurityReport/common/Subtitle";

const ExpandedPanel = ({
  children,
  title,
  expanded,
  onChange,
  simpleTitle,
  classes: classNames = {},
  tooltip,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <MuiExtensionPanel
      elevation={0}
      onChange={onChange}
      expanded={!!expanded}
      className={classNames.root}
      style={{
        margin: 0,
        boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)`,
        borderRadius: 4,
      }}
    >
      <ExpansionPanelSummary
        IconButtonProps={{ edge: false }}
        expandIcon={<SvgIcon icon="chevronDownIcon" width={40} height={40} />}
        classes={{
          root: clsx(classes.summary, classNames.header),
          expanded: classes.expanded,
          content: classes.content,
          expandIcon: classes.expandIcon,
        }}
      >
        {!simpleTitle ? (
          !title && subtitle ? (
            <Subtitle styles={{ marginBottom: 0 }}>{subtitle}</Subtitle>
          ) : (
            <Title styles={{ marginBottom: 0 }} tooltip={tooltip}>
              {title}
            </Title>
          )
        ) : (
          <Typography>{title}</Typography>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={clsx(classes.expansionDetail, "scrollable-element")}
      >
        {children}
      </ExpansionPanelDetails>
    </MuiExtensionPanel>
  );
};

export default ExpandedPanel;
