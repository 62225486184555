import React from "react";

const consentIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2305 6.92708C20.058 6.89202 22.8091 7.84543 25.0086 9.62269C25.1141 9.70845 25.2027 9.81316 25.2698 9.93144C25.3271 10.0519 25.3671 10.1798 25.3886 10.3114C25.4124 10.441 25.4124 10.5738 25.3886 10.7033C25.346 10.832 25.2818 10.9525 25.1986 11.0596C25.1161 11.1683 25.0107 11.2575 24.8898 11.3208C24.7715 11.3833 24.6427 11.4236 24.5098 11.4396C24.3762 11.4626 24.2397 11.4626 24.1061 11.4396C23.976 11.4003 23.8549 11.3357 23.7498 11.2496C21.9093 9.74397 19.5963 8.93652 17.2186 8.96957C15.1565 8.96957 13.1407 9.58106 11.4261 10.7267C9.71153 11.8723 8.37518 13.5007 7.58605 15.4058C6.79691 17.311 6.59044 19.4073 6.99274 21.4298C7.39503 23.4523 8.38803 25.3101 9.84616 26.7682C11.3043 28.2263 13.1621 29.2193 15.1845 29.6216C17.207 30.0239 19.3034 29.8175 21.2085 29.0283C23.1137 28.2392 24.742 26.9028 25.8877 25.1883C27.0333 23.4737 27.6448 21.4579 27.6448 19.3958V19.2889C27.6222 19.1548 27.6264 19.0177 27.6569 18.8852C27.6875 18.7528 27.7439 18.6276 27.8229 18.517C27.9009 18.4055 28.0022 18.3123 28.1198 18.2439C28.2318 18.1724 28.3569 18.1239 28.4879 18.1014C28.7545 18.0652 29.0252 18.1286 29.2479 18.2795C29.465 18.436 29.6137 18.6698 29.6635 18.9326C29.6839 19.0861 29.6919 19.241 29.6873 19.3958C29.6849 21.8585 28.9527 24.2652 27.583 26.3119C26.2133 28.3586 24.2677 29.9534 21.992 30.8948C19.7163 31.8361 17.2126 32.0818 14.7974 31.6008C12.3821 31.1197 10.1636 29.9336 8.42218 28.1922C6.68078 26.4508 5.49463 24.2323 5.0136 21.817C4.53257 19.4017 4.77826 16.8981 5.71961 14.6224C6.66097 12.3467 8.25574 10.4011 10.3024 9.03138C12.3491 7.66171 14.7559 6.92943 17.2186 6.92708H17.2305Z"
      fill="white"
      stroke="white"
      strokeWidth="0.25"
    />
    <path
      d="M31.8485 8.1977C31.9707 8.13935 32.105 8.11086 32.2404 8.11458C32.3718 8.11229 32.5019 8.14076 32.6204 8.1977C32.7458 8.24315 32.8594 8.3162 32.9529 8.41145C33.0456 8.50692 33.1183 8.61997 33.1666 8.74395C33.2237 8.86657 33.2521 9.00058 33.2497 9.13582C33.2509 9.27095 33.2226 9.4047 33.1666 9.5277C33.1183 9.65168 33.0456 9.76473 32.9529 9.8602L18.4417 24.4426C18.3481 24.5351 18.2397 24.6113 18.1211 24.6682C17.9954 24.7139 17.8629 24.738 17.7292 24.7395C17.5992 24.7399 17.4702 24.7157 17.3492 24.6682C17.2271 24.6112 17.1149 24.535 17.0167 24.4426L12.1717 19.5858C12.0695 19.4931 11.9872 19.3807 11.9296 19.2553C11.8721 19.1299 11.8405 18.9942 11.8369 18.8563C11.8332 18.7184 11.8576 18.5812 11.9084 18.4529C11.9592 18.3247 12.0355 18.2081 12.1327 18.1101C12.2298 18.0122 12.3458 17.9349 12.4736 17.883C12.6014 17.8312 12.7384 17.8057 12.8764 17.8082C13.0143 17.8107 13.1503 17.8412 13.2761 17.8977C13.402 17.9542 13.5151 18.0356 13.6086 18.137L17.7292 22.3051L31.516 8.41145C31.6115 8.31874 31.7245 8.24606 31.8485 8.1977Z"
      fill="white"
      stroke="white"
      strokeWidth="0.25"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="29"
          height="26"
          fill="white"
          transform="translate(4.75 6.33334)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default consentIcon;
