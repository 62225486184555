import { createSelector } from "reselect";
import isNil from "lodash.isnil";
import isEmpty from "lodash.isempty";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";

export const getSelectedDatabase = (state) => state.databases.selectedDb;

export const getDatabasesData = (state) => state.databases.data;

export const getDBLoadingState = (state) => state.databases.loading;

export const getSelectedDatabaseData = createSelector(
  [getSelectedDatabase, getDatabasesData],
  (dbId, data) =>
    isNil(dbId) || isEmpty(data)
      ? {}
      : data.find(({ id }) => parseInt(id) === parseInt(dbId))
);

export const getSelectedEnvDatabases = createSelector(
  [getSelectedEnvId, getDatabasesData],
  (envId, data) =>
    isNil(envId) || isEmpty(data)
      ? []
      : data.filter(
          ({ environment_id }) => parseInt(environment_id) === parseInt(envId)
        )
);
