import City from "assets/pii-icons/city.svg";
import Country from "assets/pii-icons/country.svg";
import DateBirth from "assets/pii-icons/datebirth.svg";
import DeviceId from "assets/pii-icons/deviceid.svg";
import Email from "assets/pii-icons/email.svg";
import GeoLocalization from "assets/pii-icons/geolocation.svg";
import IdPassport from "assets/pii-icons/idpassport.svg";
import IpAddress from "assets/pii-icons/ipaddress.svg";
import MacAddress from "assets/pii-icons/macaddress.svg";
import Organization from "assets/pii-icons/organization.svg";
import PersonFull from "assets/pii-icons/personfull.svg";
import PhoneNumber from "assets/pii-icons/phonenumber.svg";
import PostalCode from "assets/pii-icons/postalcode.svg";
import Race from "assets/pii-icons/race.svg";
import Region from "assets/pii-icons/region.svg";
import Sex from "assets/pii-icons/sex.svg";
import SexPref from "assets/pii-icons/sexpref.svg";
import SocialMedia from "assets/pii-icons/socialmedia.svg";
import StreetAddress from "assets/pii-icons/streetaddress.svg";
import Timestamp from "assets/pii-icons/timestamp.svg";

export default (id) => {
  switch (id) {
    case 1:
      return StreetAddress;
    case 2:
      return City;
    case 3:
      return Region;
    case 4:
      return PostalCode;
    case 5:
      return Country;
    case 6:
      return GeoLocalization;
    case 7:
      return PersonFull;
    case 8:
      // return PersonGiven
      return null;
    case 9:
      // return PersonFamily
      return null;
    case 10:
      return Organization;
    case 11:
      return IdPassport;
    case 12:
      // return IdEuNumber
      return null;
    case 13:
      // return IdUsNumber
      return null;
    case 14:
      return DateBirth;
    case 15:
      // return DateNonPi
      return null;
    case 16:
      return Timestamp;
    case 17:
      return DeviceId;
    case 18:
      return IpAddress;
    case 19:
      return MacAddress;
    case 20:
      return Email;
    case 21:
      return PhoneNumber;
    case 22:
      return SocialMedia;
    case 23:
      return Sex;
    case 24:
      return Race;
    case 25:
      return SexPref;
    default:
      return City;
  }
};
