import { callServiceMethod } from "utils/fetch";

import { setPlanData, requestStart, requestEnd } from "./plans.actions";
import { fetchPlanInfo } from "./plans.service";

export const getPlanInfoSuccess = (data, dispatch) =>
  dispatch(setPlanData(data));
export const getPlanInfo = () => (dispatch) => {
  return callServiceMethod(dispatch, requestStart, requestEnd)(
    fetchPlanInfo,
    [],
    getPlanInfoSuccess
  );
};
