import React from "react";
import { connect } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import EmptyIllustration from "assets/illustrations/empty_functional_uses.svg";
import useStyles from "../styles";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";

const NoScanFallback = ({ selectedEnv }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={5}>
        <Grid className={classes.leftContent} item xs={7}>
          <img src={EmptyIllustration} alt="" />
        </Grid>
        <Grid className={classes.rightContent} item xs={5}>
          <Typography className={classes.title}>Hi there!</Typography>
          <Typography className={classes.subtitle}>
            Please set some things up first.
          </Typography>
          <Button
            component="a"
            className={classes.mapButton}
            href={`environment/${selectedEnv}/scans`}
          >
            Go to Scans
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedEnv: getSelectedEnvId(state),
});

export default connect(mapStateToProps)(NoScanFallback);
