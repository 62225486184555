import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routeForKey } from "router";

import { authenticationActinon } from "redux/_authentication/authentication.actions";

import AuthenticationControl from "components/login/login.main";
import LoginForm from "components/login/login-form";
import Seo, { pages } from "components/Seo";

import { parseQuery } from "utils/queryString";
import { REPO_V2_URL } from "constants/RepoV2";

function LoginContainer({
  simple,
  loggingIn,
  dispatch,
  loginErrors,
  isAuthenticated,
  location,
}) {
  const [loginData, setData] = useState({});
  const { origin } = parseQuery(location.search);

  useEffect(() => {
    const handlePostMessage = (event) => {
      if (event.origin !== REPO_V2_URL) return; // Replace with your domain
      const { type, payload } = event.data;
      if (type === "LOGIN_SUCCESS") {
        HandleLoginFromExternalRepo(payload);
      }
    };

    window.addEventListener("message", handlePostMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  useEffect(() => {
    if (origin) {
      const url = `${routeForKey("privacyReport")}/${encodeURIComponent(
        origin
      )}`;
      dispatch(authenticationActinon.setAttemptedTransition(url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doLogin(e) {
    e.preventDefault();

    if (loginData.email && loginData.password) {
      dispatch(
        authenticationActinon.login(loginData.email, loginData.password)
      );
    }
  }

  const HandleLoginFromExternalRepo = (payload) => {
    dispatch(
      authenticationActinon.saveLogin(payload.session, payload.accountData)
    );
  };

  const handleChange = (name) => {
    return (value) => {
      setData({
        ...loginData,
        [name]: value,
      });
    };
  };

  return (
    <AuthenticationControl>
      <Seo {...pages.login} />

      <LoginForm
        simple={simple}
        isLoggingIn={loggingIn}
        email={loginData.email}
        password={loginData.password}
        updateEmail={handleChange("email")}
        updatePwd={handleChange("password")}
        submit={doLogin}
        errors={loginErrors}
      />
    </AuthenticationControl>
  );
}

function mapStateToProps(state) {
  const { loggingIn, loginErrors, isAuthenticated } = state.authentication;
  return { loggingIn, loginErrors, isAuthenticated };
}
const ConnectedLoginContainer = connect(mapStateToProps)(LoginContainer);
export default withRouter(ConnectedLoginContainer);
