import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import PieChartPiiCategories from "charts/PieCharts/PiiCategories";
import LineChartPiiCategories from "charts/LineCharts/PiiCategories";
import moment from "moment";
import isEmpty from "lodash.isempty";

const mapScans = (scans = []) => {
  let categories = [];
  scans.forEach(({ pii_groups, started_at, id }) => {
    pii_groups.forEach(({ pii_group_name, total_matches }) => {
      if (Object.keys(categories).includes(pii_group_name)) {
        categories = {
          ...categories,
          [pii_group_name]: [
            ...categories[pii_group_name],
            {
              x: id,
              y: total_matches,
              date: moment(started_at).format("DD/MM"),
            },
          ],
        };
      } else {
        categories = {
          ...categories,
          [pii_group_name]: [
            {
              x: id,
              y: total_matches,
              date: moment(started_at).format("DD/MM"),
            },
          ],
        };
      }
    });
  });
  return categories;
};

const getTotals = (categories) => {
  let categoriesTotals = {};
  Object.keys(categories).forEach((key) => {
    categoriesTotals = {
      ...categoriesTotals,
      [key]: categories[key].reduce((acum, { y }) => acum + y, 0),
    };
  });
  return categoriesTotals;
};

const piiGroupsReducer = (accumulator, currentValue) => {
  (currentValue.pii_groups || []).forEach((x) => {
    if (!accumulator[x.pii_group_name])
      accumulator[x.pii_group_name] = { label: x.pii_group_name, value: 0 };
    accumulator[x.pii_group_name].value += x.total_matches;
  });
  return accumulator;
};

const PiiCategories = (props) => {
  const classes = useStyles();
  const { scans } = props;
  const categories = useMemo(() => (!isEmpty(scans) ? mapScans(scans) : []), [
    scans,
  ]);
  const totals = useMemo(
    () => Object.values(!isEmpty(categories) ? getTotals(categories) : {}),
    [categories]
  );
  const piiGroups = useMemo(
    () => Object.values(scans.reduce(piiGroupsReducer, {})),
    [scans]
  );
  return (
    <Box className={classes.container}>
      <Typography variant="h4" display="inline">
        PII CATEGORIES
      </Typography>
      <Box className={classes.dataContainer}>
        <Box style={{ width: "40%" }}>
          <PieChartPiiCategories data={piiGroups} />
        </Box>
        <Box style={{ width: "60%" }}>
          <LineChartPiiCategories data={categories} totals={totals} />
        </Box>
      </Box>
    </Box>
  );
};

export default PiiCategories;
