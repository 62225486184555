import React, { useEffect, useMemo } from "react";
import SelectInput from "components/Inputs/Select";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { getFilterPurpose } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
import { getPoliciesData } from "redux/_consents/_policies/policies.async.actions";
import { getConsentsPoliciesData } from "redux/_consents/_policies/policies.selectors";
import { purposes } from "components/PageWrapper/components/FiltersBar/components/constants";

const { TYPES } = CONSTANTS;

const Purpose = (props) => {
  const classes = useStyles();

  const { purpose, setPurpose, policies, getPolicies } = props;

  useEffect(() => {
    getPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsedPolicies = useMemo(() => {
    return policies.map((policy) => ({ label: policy.name, value: policy.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);

  return (
    <SelectInput
      label="Purpose"
      options={[...purposes, ...parsedPolicies]}
      className={classes.filtersInput}
      value={purpose}
      onChange={setPurpose}
    />
  );
};

const mapStateToProps = (state) => ({
  policies: getConsentsPoliciesData(state),
  purpose: getFilterPurpose(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPurpose: (id) => dispatch(selectData(TYPES.PURPOSE, id)),
  getPolicies: () => dispatch(getPoliciesData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Purpose);
