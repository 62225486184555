import { Path, Svg, Text, View } from "@react-pdf/renderer";
import styles from "./styles";

const VULNERABILITY_SCORE = {
  1: "Low",
  2: "Medium",
  3: "Hight",
};

const check = (
  <Svg
    fill="none"
    height="20"
    viewBox="0 0 24 24"
    width="20"
    version="1.1"
    id="svg4"
  >
    <Path
      d="m12 8c.44 0 .8.36.8.8v3.2c0 .44-.36.8-.8.8s-.8-.36-.8-.8v-3.2c0-.44.36-.8.8-.8zm-.008-4c-4.416 0-7.992 3.584-7.992 8s3.576 8 7.992 8c4.424 0 8.008-3.584 8.008-8s-3.584-8-8.008-8zm.008 14.4c-3.536 0-6.4-2.864-6.4-6.4s2.864-6.4 6.4-6.4 6.4 2.864 6.4 6.4-2.864 6.4-6.4 6.4zm.8-2.4h-1.6v-1.6h1.6z"
      fill="#fbb25e"
      id="path2"
    />
  </Svg>
);

const SecurityPage = ({ styles: globalStyles, pentest = [] }) => {
  const recommendations = (Array.isArray(pentest) ? pentest : []).filter(
    (x) => x.recommendation && x.confirmed
  );

  if (!recommendations.length) return <></>;

  return (
    <View style={globalStyles.section}>
      <View>
        <Text style={globalStyles.title}>Security</Text>
        <Text>
          Here are recommendations to reduce your vulnerability risk level.
        </Text>
        <View style={{ ...globalStyles.row, ...styles.levelRow }}>
          <Text>Vulnerability risk level:</Text>
          {pentest.reduce && (
            <Text style={styles.level}>
              {
                VULNERABILITY_SCORE[
                  pentest.reduce(
                    (a, b) => (b.riskLevel > a ? b.riskLevel : a),
                    0
                  )
                ]
              }
            </Text>
          )}
        </View>
      </View>
      <View style={globalStyles.ul}>
        {recommendations.map(({ id: key, name: message }) => (
          <View style={globalStyles.li} key={key}>
            {check}
            <Text style={styles.liText}>{message}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default SecurityPage;
