import React, { useState, useEffect } from "react";
import {
  Menu,
  Typography,
  TextField,
  MenuItem,
  Box,
  IconButton,
} from "@material-ui/core";
import SVGIcon from "components/svg-icon/svg-icon";
import useStyles from "./styles";

const ScanDialog = ({
  anchorEl,
  handleClose,
  envs,
  selected: globalSelected,
  onClick,
  anchorFullWidth,
}) => {
  const [selected, setSelected] = useState();
  const handleChange = (e) => setSelected(e.target.value);

  useEffect(() => {
    setSelected(globalSelected);
    // eslint-disable-next-line
  }, [globalSelected]);

  const handleSubmit = () => {
    if (onClick) onClick(selected);
    setTimeout(() => {
      setSelected(globalSelected);
    }, 1000);
  };

  const classes = useStyles();
  return (
    <Menu
      classes={{ paper: classes.menu, list: classes.list }}
      onClose={handleClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={
        anchorFullWidth
          ? {
              vertical: "top",
              horizontal: "center",
            }
          : {
              vertical: "top",
              horizontal: "center",
            }
      }
      transformOrigin={
        anchorFullWidth
          ? {
              vertical: 13,
              horizontal: "center",
            }
          : {
              vertical: 39,
              horizontal: 25,
            }
      }
      marginThreshold={anchorFullWidth ? 16 : 13}
    >
      <Typography component="h4" className={classes.title}>
        Select Environment
      </Typography>
      <Box
        mt={1}
        width="100%"
        height={38}
        borderRadius={38}
        overflow="hidden"
        display="flex"
        className={classes.inputContainer}
      >
        <TextField
          value={selected}
          onChange={handleChange}
          select
          variant="outlined"
          className={classes.textfield}
          InputProps={{
            classes: {
              root: classes.input,
              notchedOutline: classes.notchedOutline,
            },
          }}
          SelectProps={{
            classes: { root: classes.select, icon: null },
            MenuProps: {
              classes: { paper: classes.selectMenu },
              getContentAnchorEl: null,
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
            },
          }}
        >
          {envs.map((env) => (
            <MenuItem key={env.id} value={env.id} className={classes.menuItem}>
              {env.name}
            </MenuItem>
          ))}
        </TextField>
        <IconButton onClick={handleSubmit} className={classes.button}>
          <SVGIcon icon="playOutlined" />
        </IconButton>
      </Box>
    </Menu>
  );
};

export default ScanDialog;
