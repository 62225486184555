import { Box, Button, TextField, Typography } from "@material-ui/core";
import CopyButton from "components/Consents/Collector/EmbedCode/components/copyButton";
import Loader from "components/Loader";
import PageWrapper from "components/PageWrapper";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { getAccountData } from "redux/_account/account.selectors";
import useStyles from "./styles";
import debounce from "lodash.debounce";
import useGetZenDataPiiMaskedText from "hooks/useGetZenDataPiiMaskedText";

const PiiAPIPage = ({ account }) => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const { data, loading, error, getPII } = useGetZenDataPiiMaskedText(
    account.pii_api_key
  );

  const handleSubmit = (inputValue) => {
    getPII([inputValue]).then(() => {});
  };

  const debounceFn = useCallback(debounce(handleSubmit, 1000), [account]);

  const handleChange = (event) => {
    // Remove all line breaks from the input
    const valueWithoutLineBreaks = event.target.value.replace(
      /(\r\n|\n|\r)/gm,
      ""
    );
    setValue(valueWithoutLineBreaks);

    // Call the debounce function with the modified value
    debounceFn(valueWithoutLineBreaks);
  };

  return (
    <PageWrapper title="DATA LAYER" subtitle="PII API">
      <Box className={classes.container}>
        <Box className={classes.content}>
          {!account?.pii_api_key ? (
            <Typography className={classes.primaryText}>
              Activate one of the plans to enable <b>Zendata PII API</b>
            </Typography>
          ) : (
            <>
              <Box className={classes.leftContent}>
                <Typography className={classes.primaryText}>
                  The <b>Zendata PII Classification API</b> is designed to
                  identify and classify Personally Identifiable Information
                  (PII) within a given text. This API is particularly useful for
                  privacy compliance, data protection, and information security
                  tasks.
                </Typography>
                <ul>
                  <li>
                    URL: <b>https://apis.zendata.xyz/pii</b>
                  </li>
                  <li>
                    Your API Key: <b>{account.pii_api_key}</b>
                  </li>
                </ul>
                <h2
                  style={{
                    color: "#1a3586",
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 16,
                  }}
                >
                  How the Endpoint Works
                </h2>
                <ol>
                  <li>
                    Request Submission: You send a text snippet to the API
                    endpoint https://apis.zendata.xyz/pii via a POST request.
                  </li>
                  <li>
                    API Processing: The API analyzes the text, identifying and
                    classifying PII such as names, addresses, email addresses,
                    and phone numbers.
                  </li>
                  <li>
                    Response Receipt: The API returns a JSON response with
                    details about the detected PII entities, including the type
                    of PII, its value, and its position in the input text.
                  </li>
                </ol>
                <Box className={classes.codeBlockContainer}>
                  <Typography
                    className={classes.codeText}
                    style={{ fontSize: 14 }}
                  >
                    curl --location --request POST{" "}
                    <b>'https://apis.zendata.xyz/pii'</b> \<br />
                    --header 'x-api-key: <b>YOUR_API_KEY</b> \<br />
                    --header 'Content-Type: application/json' \<br />
                    {`--data-raw '{
                      "texts": [
                        "Your sample text containing PII."
                      ]
                    }'`}
                  </Typography>
                </Box>
                <div
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6.4px 1px",
                    padding: 8,
                    fontSize: 14,
                    borderLeft: "5px solid rgb(26, 53, 134)",
                    color: "rgb(104, 108, 120)",
                  }}
                >
                  Test the API in your terminal replacing <b>YOUR_API_KEY</b>{" "}
                  with your actual API key and Your sample text containing PII.
                  with the text you want to analyze.
                </div>
                <h2
                  style={{
                    color: "#1a3586",
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 16,
                    marginTop: 16,
                  }}
                >
                  Try it here in Real-Time:
                </h2>
                <TextField
                  multiline
                  value={value}
                  rows={4}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.09)",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                  }}
                  InputProps={{
                    style: { padding: 10 },
                  }}
                  onChange={handleChange}
                  placeholder="Enter your text"
                />
                <Box
                  className={classes.codeBlockContainer}
                  style={{ minHeight: 120, marginTop: 20 }}
                >
                  <>
                    <Typography className={classes.codeText}>
                      <Typography
                        className={classes.primaryText}
                        style={{
                          fontSize: data ? 12 : 16,
                          paddingBottom: 0,
                        }}
                      >
                        Redacted PII:
                      </Typography>
                      {loading ? <Loader style={{ marginTop: 10 }} /> : data}
                    </Typography>
                    {data && !loading && <CopyButton text={`${data}`} />}
                  </>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

export default connect(mapStateToProps)(PiiAPIPage);
