import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import ReactHtmlParser from "react-html-parser";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import SeverityPanels from "pages/AppReport/common/SeverityPanels";

const ManifestAnalysisSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.manifestAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.manifestAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the app store information</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title>{SECTIONS_CONTENT.manifestAnalysis.title}</Title>
      <SeverityPanels
        data={data}
        emptyMessage="No Manifest Analysis for this severity"
        columns={[
          {
            key: "title",
            label: "Issue",
            width: "50%",
            render: (issue) => ReactHtmlParser(issue),
          },
          {
            key: "description",
            label: "Description",
            width: "50%",
          },
        ]}
      />
    </Page>
  );
};

export default ManifestAnalysisSection;
