import zIndex from "@material-ui/core/styles/zIndex";
import { Circle, Path, Svg, Text, View } from "@react-pdf/renderer";
import clsx from "clsx";
import Color from "color";
import React from "react";
import colormap from "../../utils/colormap";
import useStyles from "./styles";

export const getColor = (value, reverse = false) => {
  const colorValue = value !== undefined && reverse ? 100 - value : value;
  return colormap(colorValue / 100 || 0); // 'rgb(0, 0, 0)'
};

// based on https://stackoverflow.com/a/18473154/
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

// based on https://stackoverflow.com/a/18473154/
const describeArc = (x, y, radius, startAngle, endAngle) => {
  const fullCircle = endAngle - startAngle === 360;
  const start = polarToCartesian(x, y, radius, fullCircle ? 359.99 : endAngle);
  const end = polarToCartesian(x, y, radius, fullCircle ? 0 : startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

const ScoreChart = ({
  value = 40,
  angle = 30,
  reverse,
  className,
  onMouseEnter,
  onMouseLeave,
  pdf = false,
}) => {
  const classes = useStyles();
  const color = getColor(value, reverse);
  const borderColor = Color(color).lighten(0.2).rgb().string();

  if (pdf) {
    const centerXFor = (x) => {
      if (x === 100) return "0.38";
      if (x < 10) return "0.94";
      return "0.65";
    };
    return (
      <View>
        <Svg viewBox="0 0 2.4 2.4" width={60}>
          <Path
            fill="none"
            stroke={borderColor}
            strokeWidth="0.4"
            d={describeArc(1.2, 1.2, 1, angle, angle + (360 * value) / 100)}
          />
          <Circle cx="1.2" cy="1.2" r="1" fill={color} />
          <Text
            x={centerXFor(value)}
            y="1.6"
            style={{ fontSize: "1", fill: "white" }}
          >
            {value}
          </Text>
        </Svg>
      </View>
    );
  }

  return (
    <div
      className={clsx(classes.scoreChart, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={classes.value}>{value}</span>
      <svg viewBox="0 0 2.4 2.4" width="100%">
        <path
          fill="none"
          stroke={borderColor}
          strokeWidth="0.4"
          d={describeArc(1.2, 1.2, 1, angle, angle + (360 * value) / 100)}
        />
        <circle cx="1.2" cy="1.2" r="1" fill={color} />
      </svg>
    </div>
  );
};

export default ScoreChart;
