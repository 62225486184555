import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import PageWrapper from "components/PageWrapper";
import Form from "../../components/PrivacyReports/Scanner/adminForm";

import ReportList from "./reportList";
import ReportPreview from "./reportPreview";
import useReports from "hooks/useReports";

const pageSize = 10;

const ScansPage = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const reports = useReports();

  useEffect(
    () => {
      reports.all({ pageSize, page });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(
    () => {
      if (!selectedRow && reports.list.length) {
        setSelectedRow(reports.list[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reports.list]
  );

  const scan = (data) => console.log(data);

  const onChangePage = (_, newPage) => setPage(newPage);

  return (
    <PageWrapper title="HOME" subtitle="COOKIES SCANS">
      <Box className={classes.content}>
        <Box className={classes.searchBar}>
          <Form onSubmit={scan} />
        </Box>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={12}>
            <ReportList
              items={reports.list}
              meta={reports.meta}
              onChangePage={onChangePage}
              selected={selectedRow}
              onSelect={setSelectedRow}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportPreview selected={selectedRow} />
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default ScansPage;
