import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ContactsIcon from "@material-ui/icons/Contacts";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LockIcon from "@material-ui/icons/Lock";
import MicIcon from "@material-ui/icons/Mic";
import PhoneIcon from "@material-ui/icons/Phone";
import SettingsIcon from "@material-ui/icons/Settings";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import StorageIcon from "@material-ui/icons/Storage";
import SmsIcon from "@material-ui/icons/Sms";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import WifiIcon from "@material-ui/icons/Wifi";
import NfcIcon from "@material-ui/icons/Nfc";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FaceIcon from "@material-ui/icons/Face";
import NoteIcon from "@material-ui/icons/Note";
import HomeIcon from "@material-ui/icons/Home";

import React from "react";

export const PROTECTION_LEVELS = {
  pii: "PII",
  unknown: "UNKNOWN",
};

export const PII_POSSIBLE_PERMISSIONS = [
  "android.permission.ACCESS_COARSE_LOCATION",
  "android.permission.GET_ACCOUNTS",
  "android.permission.USE_CREDENTIALS",
  "android.permission.CAMERA",
  "android.permission.READ_CONTACTS",
  "android.permission.READ_PROFILE",
  "android.permission.ACCESS_FINE_LOCATION",
  "android.permission.RECORD_AUDIO",
  "android.permission.WRITE_EXTERNAL_STORAGE",
  "android.permission.ACCESS_MEDIA_LOCATION",
  "android.permission.READ_PHONE_STATE",
  "android.permission.USE_BIOMETRIC",
  "android.permission.USE_FINGERPRINT",
  "android.permission.READ_EXTERNAL_STORAGE",
  "LocationAlwaysUsage",
  "LocationWhenInUseUsage",
  "CameraUsage",
  "FaceIDUsage",
  "ContactsUsage",
  "HealthShareUsage",
  "HealthUpdateUsage",
  "PhotoLibraryUsage",
  "MicrophoneUsage",
];

export const SECURE_PERMISSIONS = [
  "android.permission.INTERNET",
  "android.permission.ACCESS_WIFI_STATE",
  "android.permission.ACCESS_NETWORK_STATE",
  "android.permission.WAKE_LOCK",
  "android.permission.RECEIVE_BOOT_COMPLETED",
  "android.permission.VIBRATE",
  "android.permission.FOREGROUND_SERVICE",
  "android.permission.USE_FULL_SCREEN_INTENT",
  "android.permission.MODIFY_AUDIO_SETTINGS",
  "android.launcher.permission.INSTALL_SHORTCUT",
  "android.launcher.permission.UNINSTALL_SHORTCUT",
  "android.launcher.permission.CHANGE_BADGE",
  "android.permission.UPDATE_APP_BADGE",
  "android.permission.CALL_PHONE",
  "android.permission.BLUETOOTH",
  "android.permission.BLUETOOTH_ADMIN",
  "android.permission.CHANGE_WIFI_STATE",
];

const ICON_CATEGORIES = {
  Audio: ["android.permission.MODIFY_AUDIO_SETTINGS", "AppleMusicUsage"],
  Bluetooth: [
    "android.permission.BLUETOOTH",
    "android.permission.BLUETOOTH_ADMIN",
    "BluetoothPeripheralUsage",
  ],
  Camera: ["android.permission.CAMERA", "CameraUsage"],
  Contacts: ["android.permission.READ_CONTACTS"],
  Device: ["android.permission.VIBRATE"],
  Location: [
    "android.permission.ACCESS_FINE_LOCATION",
    "android.permission.ACCESS_COARSE_LOCATION",
    "android.permission.ACCESS_MEDIA_LOCATION",
    "LocationAlwaysUsage",
    "LocationWhenInUseUsage",
  ],
  Lock: ["android.permission.WAKE_LOCK"],
  Microphone: ["android.permission.RECORD_AUDIO", "MicrophoneUsage"],
  Phone: [
    "android.permission.READ_PHONE_STATE",
    "android.permission.CALL_PHONE",
  ],
  Profile: [
    "android.permission.READ_PROFILE",
    "android.permission.GET_ACCOUNTS",
    "android.permission.USE_CREDENTIALS",
  ],
  "Physical Activity": ["HealthShareUsage", "HealthUpdateUsage"],
  Storage: [
    "android.permission.READ_EXTERNAL_STORAGE",
    "android.permission.WRITE_EXTERNAL_STORAGE",
    "PhotoLibraryAddUsage",
    "PhotoLibraryUsage",
  ],
  SMS: [],
  Internet: [
    "android.permission.CHANGE_WIFI_STATE",
    "android.permission.INTERNET",
    "android.permission.ACCESS_WIFI_STATE",
    "android.permission.ACCESS_NETWORK_STATE",
  ],
  Fingerprint: [
    "android.permission.USE_BIOMETRIC",
    "android.permission.USE_FINGERPRINT",
  ],
  Settings: [
    "android.permission.FOREGROUND_SERVICE",
    "android.permission.USE_FULL_SCREEN_INTENT",
    "android.launcher.permission.INSTALL_SHORTCUT",
    "android.launcher.permission.UNINSTALL_SHORTCUT",
    "android.launcher.permission.CHANGE_BADGE",
    "android.permission.UPDATE_APP_BADGE",
    "android.permission.RECEIVE_BOOT_COMPLETED",
  ],
  NFC: ["NFCReaderUsage"],
  Calendar: ["CalendarsUsage"],
  Face: ["FaceIDUsage"],
  Reminder: ["RemindersUsage"],
  HomeKit: ["HomeKitUsage"],
};

// TODO(@jperozo): Complete according the case
const ICONS = {
  Audio: <VolumeUpIcon />,
  Bluetooth: <BluetoothIcon />,
  Camera: <CameraAltIcon />,
  Contacts: <ContactsIcon />,
  Device: <StayCurrentPortraitIcon />,
  Location: <LocationOnIcon />,
  Lock: <LockIcon />,
  Microphone: <MicIcon />,
  Phone: <PhoneIcon />,
  "Physical Activity": <DirectionsRunIcon />,
  Profile: <AccountCircleIcon />,
  Storage: <StorageIcon />,
  SMS: <SmsIcon />,
  Internet: <WifiIcon />,
  Fingerprint: <FingerprintIcon />,
  Settings: <SettingsIcon />,
  NFC: <NfcIcon />,
  Calendar: <CalendarTodayIcon />,
  Face: <FaceIcon />,
  Reminder: <NoteIcon />,
  HomeKit: <HomeIcon />,
};

export const getIcon = (permissionName) => {
  const iconKey = Object.keys(ICON_CATEGORIES).filter((category) =>
    ICON_CATEGORIES[category].includes(permissionName)
  )[0];

  return ICONS[iconKey];
};

// TODO(@jperozo): Complete according the case
export const DESCRIPTION = {
  Camera: "Description for Camera",
  Contacts: "Description for Contacts",
  Location: "Description for Location",
  Microphone: "Description for Microphone",
  Phone: "Description for Phone",
  "Physical Activity": "Description for Physical Activity",
  Storage: "Description for Storage",
  SMS: "Description for SMS",
};

export const HUMAN_TEXT = {
  NFCReaderUsage: "Use the device’s NFC reader (NFCReaderUsage)",
  AppleMusicUsage: "Use the media library (AppleMusicUsage)",
  BluetoothPeripheralUsage: "Use Bluetooth (BluetoothPeripheralUsage)",
  CalendarsUsage: "Access to your calendars (CalendarsUsage)",
  CameraUsage: "Access the device’s camera (CameraUsage)",
  ContactsUsage: "Access to your contacts (ContactsUsage)",
  FaceIDUsage: "Use Face ID (FaceIDUsage)",
  HealthShareUsage: "Read the health data (HealthShareUsage)",
  HealthUpdateUsage: "Make changes to health data (HealthUpdateUsage)",
  HomeKitUsage: "Access to HomeKit configuration data (HomeKitUsage)",
  LocationAlwaysUsage:
    "Access to your location at all times (LocationAlwaysUsage)",
  LocationWhenInUseUsage:
    "Access to your location while your app is in use (LocationWhenInUseUsage)",
  MicrophoneUsage: "Access to the device’s microphones (MicrophoneUsage)",
  MotionUsage: "Access to the device’s accelerometer (MotionUsage)",
  PhotoLibraryAddUsage:
    "Get write-only access to your photo library (PhotoLibraryAddUsage)",
  PhotoLibraryUsage: "Access to your photo library (PhotoLibraryUsage)",
  RemindersUsage: "Access yo your reminders (RemindersUsage)",
  VideoSubscriberAccountUsage:
    "Access to the TV provider account (VideoSubscriberAccountUsage)",

  "android.permission.READ_EXTERNAL_STORAGE":
    "Reads from external storage (READ_EXTERNAL_STORAGE)",
  "android.permission.REORDER_TASKS":
    "Change the Z-order of tasks (REORDER_TASKS)",
  "android.c2dm.permission.RECEIVE":
    "Accept cloud to device messages sent by the app's service (RECEIVE)",
  "android.permission.CHANGE_WIFI_MULTICAST_STATE":
    "Enter Wi-Fi Multicast mode (CHANGE_WIFI_MULTICAST_STATE)",
  "android.permission.TRANSMIT_IR":
    "The device's IR transmitter, if available (TRANSMIT_IR)",
  "android.finsky.permission.BIND_GET_INSTALL_REFERRER_SERVICE":
    "Allows other apps to tell if their installation was launched from an ad in Privacy Browser Free (BIND_GET_INSTALL_REFERRER_SERVICE)",
  "android.permission.ACCESS_NETWORK_STATE":
    "Allows applications to access information about networks (ACCESS_NETWORK_STATE)",
  "android.permission.ACCESS_WIFI_STATE":
    "Allows applications to access information about Wi-Fi networks (ACCESS_WIFI_STATE)",
  "android.permission.INTERNET":
    "Allows applications to use internet. (INTERNET)",
  "android.permission.WAKE_LOCK":
    "Keep processor from sleeping or screen from dimming (WAKE_LOCK)",
  "android.permission.FOREGROUND_SERVICE":
    " Allows applications to starts foreground services (FOREGROUND_SERVICE)",
  "android.permission.MODIFY_AUDIO_SETTINGS":
    "Allows an application to modify global audio settings (MODIFY_AUDIO_SETTINGS)",
  "android.permission.RECEIVE_BOOT_COMPLETED":
    "Allows an application to receive the a message when the boot is complete (RECEIVE_BOOT_COMPLETED)",
  "android.permission.VIBRATE": "Allows access to the vibrator (VIBRATE)",
};

export const getHumanText = (permissionName) => {
  return HUMAN_TEXT[permissionName] || permissionName;
};
