import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { setInProgress } from "redux/_scans/scans.actions";
import { getScans, runScan } from "redux/_scans/scans.async.actions";
import { getInProgressScan } from "redux/_scans/scans.selectors";
import { setScanning } from "redux/_environments/environment.actions";
import {
  getEnvsData,
  getScanningState,
  getSelectedEnvData,
  getSelectedEnvId,
  someEnvScanning,
} from "redux/_environments/environment.selectors";

import Loader from "components/Loader";
import SvgIcon from "components/svg-icon/svg-icon";
import { formatNumber } from "utils/format";

import RunANewScan from "assets/illustrations/runanewscan.svg";

import useStyles from "./styles";

const CurrentWidget = (props) => {
  const classes = useStyles();

  const {
    startScan,
    envsData,
    envId,
    isScanning,
    setScanStatus,
    getLastScans,
    someEnvIsScanning,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(false);

  const [currentScan, setCurrentScan] = useState({});

  const [open, setOpen] = useState(false);

  const getLastScanInfo = useCallback(
    (interval) => {
      return getLastScans({ last: 1, completed: 0 })
        .then((data) => {
          setIsLoading(false);
          setError(false);
          if (isEmpty(data)) {
            setScanStatus(false);
          } else {
            setCurrentScan(data[0]);
          }
          return data;
        })
        .catch(() => {
          setError(true);
          window.clearInterval(interval);
          setIsLoading(false);
        });
    },
    [getLastScans, setScanStatus]
  );

  useEffect(() => {
    let interval;
    if (isScanning) {
      interval = window.setInterval(() => getLastScanInfo(interval), 5000);
    } else {
      window.clearInterval(interval);
    }
    return () => window.clearInterval(interval);
  }, [getLastScanInfo, isScanning]);

  useEffect(() => {
    if (isScanning) {
      setIsLoading(true);
      getLastScanInfo();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envId, envsData.length]);

  const runScanSubmit = () => {
    setOpen(false);
    setIsLoading(true);
    startScan(envId)
      .then((data) => {
        setScanStatus(false);
        setCurrentScan({});
        setScanStatus(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const dialogHandler = () => {
    if (someEnvIsScanning) {
      setOpen(!open);
    } else {
      runScanSubmit();
    }
  };

  const onRetryClick = () => {
    if (!isScanning) {
      runScanSubmit();
    }
  };

  const percentage = useMemo(() => {
    let currentPercentage = 0;
    if (currentScan.loading_percentage !== undefined) {
      currentPercentage = currentScan.loading_percentage * 100;
    }

    // Covering issue with decimals
    return currentPercentage < 100 ? currentPercentage : 100;
  }, [currentScan]);

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        {isLoading ? (
          <Box>
            <Typography
              component="h4"
              className={classes.contentTitle}
              style={{ lineHeight: "1.2", paddingBottom: "16px" }}
            >
              Analyzing Environment Structure
            </Typography>
            <Loader />
          </Box>
        ) : error ? (
          <Box className={classes.error}>
            <Typography component="h4" className={classes.contentTitle}>
              Hey, something went wrong!
            </Typography>
            <Typography component="h4" className={classes.contentBody}>
              Please, try again.
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className={classes.connectionsButton}
              onClick={onRetryClick}
            >
              Retry
            </Button>
          </Box>
        ) : isScanning ? (
          <Box
            className={classes.ongoingMain}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Box className={classes.ongoingProgress}>
              <strong>{`${percentage.toFixed(0)}%`}</strong>
              <CircularProgress
                size="140px"
                thickness={5}
                className={clsx(
                  percentage === 0 && classes.emptyProgress,
                  percentage !== 0 && classes.progress
                )}
                variant="static"
                value={percentage}
              />
            </Box>
            <Typography className={classes.scanIdTitle} display="inline">
              Scan ID:{" "}
              <span className={classes.scanIdValue}>{currentScan.name}</span>
            </Typography>
            <Grid container style={{ paddingTop: "8px" }}>
              <Grid item xs={4} className={classes.ongoingContainer}>
                <SvgIcon icon="database" />
                <Typography
                  component="h5"
                  className={clsx(
                    classes.ongoingTotalTitle,
                    classes.ongoingType
                  )}
                >
                  Databases
                </Typography>
                <Typography
                  component="h5"
                  className={classes.ongoingTotalTitle}
                >
                  {`${formatNumber(
                    currentScan.scanned_databases
                  )} / ${formatNumber(currentScan.databases)}`}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.ongoingContainer}>
                <SvgIcon icon="tables" />
                <Typography
                  component="h5"
                  className={clsx(
                    classes.ongoingTotalTitle,
                    classes.ongoingType
                  )}
                >
                  Tables
                </Typography>
                <Typography
                  component="h5"
                  className={classes.ongoingTotalTitle}
                >
                  {`${formatNumber(
                    currentScan.scanned_tables
                  )} / ${formatNumber(currentScan.tables)}`}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.ongoingContainer}>
                <SvgIcon icon="columns" />
                <Typography
                  component="h5"
                  className={clsx(
                    classes.ongoingTotalTitle,
                    classes.ongoingType
                  )}
                >
                  Columns
                </Typography>
                <Typography
                  component="h5"
                  className={classes.ongoingTotalTitle}
                >
                  {`${formatNumber(
                    currentScan.scanned_columns
                  )} / ${formatNumber(currentScan.columns)}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box className={classes.noScan}>
            <Dialog
              open={open}
              scroll="paper"
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent dividers={true} className={classes.dialogContent}>
                <Form onSubmit={runScanSubmit}>
                  {({ handleSubmit, values }) => (
                    <Box
                      component="form"
                      maxWidth={540}
                      onSubmit={handleSubmit}
                    >
                      <Typography
                        component="h2"
                        className={classes.titleDialog}
                      >
                        Are you sure you want to scan this environment?
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.descriptionDialog}
                      >
                        Do you have another environment already scanning. If you
                        start this one, this could delay all executing
                        processes. Do you want to continue?
                      </Typography>
                      <Box className={classes.dialogButtonContainer}>
                        <Button onClick={dialogHandler}>No</Button>
                        <Button
                          type="submit"
                          className={clsx(
                            classes.runScan,
                            classes.submitButtonDialog
                          )}
                        >
                          Yes, do it!
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Form>
              </DialogContent>
            </Dialog>
            <img src={RunANewScan} role="presentation" alt=" " />
            <Typography component="h2" className={classes.noScanTitle}>
              What{"'"}s the status?
            </Typography>
            <Button
              id="run-scan"
              type="Button"
              color="secondary"
              className={classes.runScan}
              onClick={dialogHandler}
              disabled={!envId}
            >
              Run Scan
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  envData: getSelectedEnvData(state),
  isScanning: getScanningState(state),
  someEnvIsScanning: someEnvScanning(state),
  inProgress: getInProgressScan(state),
  envId: getSelectedEnvId(state),
  envsData: getEnvsData(state),
});

const mapDispatchToProps = (dispatch) => ({
  startScan: () => dispatch(runScan()),
  setScanStatus: (value) => dispatch(setScanning(value)),
  getLastScans: (params) => dispatch(getScans(params, false)),
  setIsScanning: (isScanning) => dispatch(setInProgress(isScanning)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentWidget);
