import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import errorIllustration from "assets/illustrations/error_illustration.svg";

const ErrorState = ({ history, retry, entity }) => {
  const classes = useStyles();
  const handleGoBack = () => {
    history.go(-2);
  };
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <Box width="100%" height="calc(100% - 144px)" pt={7}>
      <Box width="100%" maxWidth={500} margin="auto" mb={5}>
        <Typography component="h3" className={classes.title}>
          Oh no! An error ocurred {entity === "scan" && "with this Scan"}
        </Typography>
        <Typography component="p" className={classes.description}>
          {entity === "scan" &&
            "There was an error while trying to retrieve the scan’s data."}
          Our engineers are working to identify the problem.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={retry ? retry : reloadPage}
            className={classes.mainButton}
          >
            Try Again
          </Button>
          <Button
            onClick={handleGoBack}
            className={classes.secondaryButton}
            variant="outlined"
          >
            Go Back
          </Button>
        </Box>
      </Box>
      <Box width="100%" textAlign="center">
        <img className={classes.image} src={errorIllustration} alt="" />
      </Box>
    </Box>
  );
};

export default withRouter(ErrorState);
