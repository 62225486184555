import { Image, View } from "@react-pdf/renderer";
import Empty from "assets/images/Report/empty.png";
import Filled from "assets/images/Report/filled.png";
import clsx from "clsx";
import React from "react";
import "./styles.scss";

const TestTubeChart = ({ environment, score, total }) => {
  if (environment === "pdf") {
    const children = [...Array(total).keys()].map((i) => (
      <Image
        key={i}
        src={i + 1 <= score ? Filled : Empty}
        alt="test-tube"
        style={{ height: 60, width: 12 }}
      />
    ));
    return <View style={{ flexDirection: "row" }}>{children}</View>;
  }

  const children = [...Array(total).keys()].map((i) => (
    <img key={i} src={i + 1 <= score ? Filled : Empty} alt="test-tube" />
  ));
  return <div className={clsx("test-tube-chart", environment)}>{children}</div>;
};

export default TestTubeChart;
