import { createSelector } from "reselect";
import get from "lodash.get";

export const getRequestsData = (state) => state.requests.data.data;

export const getRequestsMetadata = (state) => state.requests.data.meta;

export const getRequestsLoadingState = (state) => state.requests.data.loading;

export const getRequestsErrorState = (state) => state.requests.data.error;

export const getRequestsFilters = (state) =>
  state.requests.data.filters.selected;

export const getRequestFiltersData = (state) =>
  state.requests.data.filters.data;

export const getRequestsFilterData = (type) =>
  createSelector([getRequestFiltersData], (filtersData) =>
    get(filtersData, type)
  );

export const getRequestsFilterSelected = (type) =>
  createSelector([getRequestsFilters], (filtersSelected) =>
    get(filtersSelected, type)
  );
