import React, { useMemo } from "react";
import ReactDOM from "react-dom";

const Tooltip = ({
  isOdd,
  hovered,
  color,
  label,
  container,
  x,
  y,
  order,
  compact,
}) => {
  const odd = useMemo(() => isOdd(order), [isOdd, order]);
  return container && container.current
    ? ReactDOM.createPortal(
        <g transform={`translate(${x} ${y})`}>
          <g
            transform={`translate(${odd ? 26 : -26} ${compact ? -10 : -13})`}
            opacity={order === hovered ? 1 : 0}
          >
            <rect
              fill="white"
              stroke={color}
              x={compact ? -18 : -23}
              y={compact ? -11 : -13}
              width={compact ? 36 : 46}
              height={compact ? 18 : 24}
              rx="5"
              ry="5"
            />
            <text
              fill={color}
              fontWeight="bold"
              dominantBaseline="middle"
              fontFamily="Roboto Condensed, sans-serif"
              fontSize={compact ? ".8rem" : ".9rem"}
              textAnchor="middle"
            >
              {Number(label).toLocaleString()}
            </text>
          </g>
        </g>,
        container.current
      )
    : null;
};

export default Tooltip;
