import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import isArray from "lodash.isarray";

export const getAll = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/consents_collector/overview_staticts`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => (isArray(data) && data.length > 0 ? data[0] : data));
};

export const getKpis = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/consents_collector/tracks`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
