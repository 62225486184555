import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  advancedSettingsWrapper: {
    border: "1px solid",
    borderColor: theme.palette.blue[100],
    borderRadius: 6,

    marginBottom: theme.spacing(2),
  },
  advancedSettings: {
    color: theme.palette.blue[300],
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  advancedSettingsIcon: {
    marginLeft: "auto",
    "&, & *": {
      fill: theme.palette.blue[300],
    },
  },
  collapseContainer: {},
  rulesContainer: {
    marginTop: theme.spacing(3),
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeigfht: "48px",
    fontSize: "12px",
  },
  listItemIcon: {
    minWidth: "30px",
  },
  flag: {
    height: "18px",
    minWidth: "26px",
    marginRight: "5px",
    marginLeft: "-5px",
    border: "1px solid #f1f1f1",
  },
  smallText: {
    fontSize: "12px",
  },
}));
