import React, { useEffect } from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFetchRepositoryDetailsById } from "hooks/useFetchRepositoryDetailsById";
import { useRepositoryWebHook } from "hooks/useRepositoryWebHook";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import repoProviderUtils from "helpers/repoProviderUtils";
import WebHookBranchListDisplay from "./WebHookBranchListDisplay";
import { useSelector, useDispatch } from "react-redux";
import FirstTimeUseComponent from "./FirstTimeUseComponent";

const useStyles = makeStyles((theme) => ({
  pill: {
    borderRadius: "16px",
    backgroundColor: "#f7f7f7",
    border: "1px solid #d6d6d6",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "2px 9px",
    gap: "8px",
  },
  pillParent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    marginBottom: "24px",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 0",
  },
}));

const WebHookDetails = () => {
  const { data, loading, error, fetchData } = useFetchRepositoryDetailsById();
  const { patchData } = useRepositoryWebHook();
  const { repo_id } = useParams();
  const enabled = useSelector(
    (state) => state.scanner.repositoryWebhookEnabled
  );

  useEffect(() => {
    const sanitizedRepoId =
      repo_id.charAt(0) === ":" ? repo_id.slice(1) : repo_id;
    fetchData(sanitizedRepoId);
  }, []);

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Box
        style={{
          background: "#F7F7F7",
          borderRadius: 4,
          padding: 16,
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            style={{
              color: "#466DE8",
              fontFamily: "Inter",
              fontSize: 20,
              fontWeight: 900,
              flex: 1,
            }}
          >
            {repoProviderUtils.getRepositoryShortNameByUrl(data?.url)}
          </Typography>
          <Chip
            label={enabled ? "ACTIVE" : "INACTIVE"}
            style={{
              backgroundColor: enabled ? "#6FCF97" : "#F7B481",
              marginRight: 24,
            }}
          />
        </Box>

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.pill}>
            <div className={classes.iconWrapper}>
              <img
                className={classes.icon1}
                loading="lazy"
                alt=""
                src={repoProviderUtils.getProviderIcon(data?.provider)}
                style={{ width: "16px", height: "16px" }}
              />
            </div>
            <div className={classes.githubRepository}>
              {`${data?.provider} Repository`}
            </div>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            style={{ color: "#000000", fontFamily: "Inter", fontSize: 16 }}
          >
            Webhook URL:
          </Typography>
          <Typography
            style={{
              color: "#000000",
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            {data?.webhook_url}
          </Typography>
        </Box>
      </Box>
      <WebHookBranchListDisplay
        branches={data ? data?.branches_detail : []}
        patchData={patchData}
      />
    </Box>
  );
};

export default WebHookDetails;
