export const getProviderIcon = (providerId, serviceName) => {
  const providerIcons = {
    1: "aws",
  };

  if (serviceName !== "") {
    return serviceName;
  }

  const provider = providerIcons[providerId];
  return provider || "database";
};
