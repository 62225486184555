import { Box } from "@material-ui/core";
import Loader from "components/Loader";
import isEmpty from "lodash.isempty";
import { Component } from "react";
// import { makeVisFlexible, RadialChart } from 'react-vis';
import SVGContainer from "../SVGContainer/SVGContainer";
import PieChart from "./PieChart/PieChart";

// const FlexibleRadialChart = makeVisFlexible(RadialChart)

const colors = [
  "#041754",
  "#1a3586",
  "#7686b6",
  "#cad0e3",
  "#6bd4cb",
  "#2a94f4",
  "#e25c1d",
  "#a720C4",
];

const PiiCategories = (props) => {
  const { data, kpi, margin } = props;
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {isEmpty(data) && data.length ? (
        <Loader />
      ) : (
        <Box style={{ width: "100%", height: "100%", display: "flex" }}>
          <SVGContainer geometricPresicion disableScrolling>
            <PieChart data={data} colors={colors} margin={margin} />
            <Badge {...kpi} />
          </SVGContainer>
        </Box>
      )}
    </Box>
  );
};

class Badge extends Component {
  render() {
    const { width, height, label, value } = this.props;
    if (width && height)
      return (
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          <text
            fontSize={26}
            fontWeight={800}
            textAnchor={"middle"}
            fill="#0e1d60"
          >
            {value}
          </text>
          <text
            fontSize={14}
            fontWeight="bold"
            dy={16}
            textAnchor={"middle"}
            fill="#686c78"
            fontFamily="Roboto Condensed"
          >
            {label}
          </text>
        </g>
      );
    return null;
  }
}

export default PiiCategories;
