import { makeStyles } from "@material-ui/core";
import MastheadIllus from "assets/illustrations/masthead_illus.svg";
import MastheadBG from "assets/illustrations/masthead_bg.svg";
import PostMastheadLeftShape from "assets/images/postMastheadLeftShape.svg";
import PostMastheadRightShape from "assets/images/postMastheadRightShape.svg";
import Story3BG from "assets/images/storiesBG.svg";

export default makeStyles((theme) => ({
  masthead: {
    backgroundColor: theme.palette.blue["500"],
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      //backgroundSize: '444px 232px',
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${MastheadBG})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      backgroundSize: "666px 498px",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "888px 664px",
      backgroundPosition: "right bottom",
    },
    [theme.breakpoints.up("xl")]: {},
    "& h2": {
      fontFamily: "Aldrich,sans-serif",
      fontSize: "1.75rem",
      fontWeight: "normal",
      lineHeight: "1.2",
      letterSpacing: "1px",
      color: theme.palette.blue["100"],
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.875rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "2.5rem",
      },
    },
    "& p": {
      fontSize: "1rem",
      lineHeight: "1.4",
      letterSpacing: "1px",
      color: "#fff",
      marginBottom: theme.spacing(3),
      textShadow: "0 4px 4px rgba(0, 0, 0, 0.05)",
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.125rem",
        lineHeight: "1.89",
      },
      [theme.breakpoints.up("xl")]: {},
    },
  },
  mastheadHelper: {
    backgroundImage: `url(${MastheadIllus})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "30% 90%",
    backgroundSize: "310px 228px",
    padding: theme.spacing(0, 0, 36),
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "542px 396px",
      padding: theme.spacing(0, 0, 64),
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      backgroundSize: "660px 445px",
      backgroundPosition: "right center",
      padding: theme.spacing(14, 0),
    },
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "740px 500px",
      backgroundPosition: "95% center",
      padding: theme.spacing(16, 0),
    },
    [theme.breakpoints.up("xl")]: {
      backgroundSize: "825px 557px",
      backgroundPosition: "80% center",
    },
  },
  mastheadForm: {
    "& fieldset": {
      boxShadow: "0 4px 4px rgba(0, 0, 0, 0.05)",
    },
    "& p": {
      fontSize: "14px",
      lineHeight: "1",
      letterSpacing: "1px",
      color: theme.palette.yellow["100"],
      margin: theme.spacing(2, 0, 0, 2),
    },
  },
  mastheadFormInput: {
    backgroundColor: "#fff",
    border: "0 none",
    borderRadius: "6px 6px 0 0",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.05)",
    color: theme.palette.blue["500"],
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: theme.spacing(0, 2),
    textAlign: "center",
    width: "100%",
    height: "48px",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      borderRadius: "6px 0 0 6px",
      float: "left",
      maxWidth: "70%",
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      height: "56px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      maxWidth: "356px",
    },
    [theme.breakpoints.up("xl")]: {},
  },
  mastheadFormSubmit: {
    backgroundColor: theme.palette.aquamarine["100"],
    border: "0 none",
    borderRadius: "0 0 6px 6px",
    color: theme.palette.blue["young"],
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
    width: "100%",
    height: "48px",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0 6px 6px 0",
      maxWidth: "30%",
    },
    [theme.breakpoints.up("md")]: {
      height: "56px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      maxWidth: "184px",
    },
    [theme.breakpoints.up("xl")]: {},
  },
  postMasthead: {
    backgroundColor: theme.palette.blue["600"],
    backgroundImage: `url(${PostMastheadRightShape}), url(${PostMastheadLeftShape})`,
    backgroundPosition: "101% 0, 0 0",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(11, 0),
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {
      backgroundPosition: "107% 0, 0 0",
      backgroundSize: "44%, auto",
    },
  },
  postMastheadH2: {
    color: "#fff",
    fontFamily: "Aldrich,sans-serif",
    fontSize: "22px",
    fontWeight: "300",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  postMastheadSub: {
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "5px",
    color: theme.palette.aquamarine["100"],
    marginBottom: theme.spacing(2),
    textAlign: "center",
    textTransform: "uppercase",
  },
  storyFaqsContainer: {
    background: "#fff",
    padding: theme.spacing(6, 0),
  },
  storyNarrativeContainer: {
    background: `#fff url(${Story3BG}) no-repeat center bottom`,
    backgroundSize: "contain",
    marginBottom: "-1px",
    padding: theme.spacing(6, 0, 8),
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 0, 8),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 0, 10),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(10, 0, 12),
    },
    [theme.breakpoints.up("xl")]: {},
    "& h3": {
      color: theme.palette.blue["young"],
      fontFamily: "Aldrich, sans-serif",
      fontWeight: "normal",
      letterSpacing: "1px",
      fontSize: "1.5rem",
      lineHeight: "1.2",
      margin: theme.spacing(0),
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        fontSize: "2.25rem",
        lineHeight: "1.56",
      },
      [theme.breakpoints.up("xl")]: {},
    },
    "& p": {
      color: theme.palette.grey["200"],
      fontSize: "1rem",
      lineHeight: "1.2",
      margin: theme.spacing(0, 0, 2),
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "1.56",
        margin: theme.spacing(0),
      },
      [theme.breakpoints.up("lg")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
  },
  story1: {
    marginBottom: "100px !important",
  },
  story2: {
    marginBottom: theme.spacing(6),
  },
  story3: {
    marginBottom: theme.spacing(0),
  },
  inviter: {
    backgroundColor: theme.palette.purple["700"],
    padding: theme.spacing(3, 0, 0),
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
    "& h4": {
      color: theme.palette.yellow["100"],
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
      letterSpacing: "1px",
      margin: theme.spacing(0, 0, 2),
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("xl")]: {},
    },
    "& form": {
      margin: theme.spacing(0, 0, 4),
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0),
      },
      [theme.breakpoints.up("lg")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
  },
  preFooterLinks: {
    backgroundColor: "#fff",
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8, 0, 5),
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
    "& svg": {
      maxWidth: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "184px",
      },
    },
    "& p": {
      color: theme.palette.grey["200"],
      fontSize: "11px",
      margin: theme.spacing(2, 0),
      [theme.breakpoints.up("lg")]: {
        lineHeight: "2.5",
        margin: "0",
      },
    },
    "& h5": {
      color: theme.palette.blue["500"],
      fontSize: "18px",
      fontWeight: "600",
      margin: theme.spacing(0, 0, 1),
    },
    "& ul": {
      listStyle: "none",
      margin: "0 0 2rem",
      padding: "0",
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        margin: "0",
      },
      "& li": {
        fontSize: "0.875rem",
        lineHeight: "2.5",
        marginRight: theme.spacing(5),
      },
      "& a": {
        color: theme.palette.blue["400"],
      },
    },
  },
  sitenameWrap: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
}));
