import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageReportsWrapper from "components/PageReportsWrapper";
import ScanFlowForm from "components/Scans/Forms/Flow";
import Button from "components/Button";
import CreateRepositoryScanner from "./CreateRepositoryScanner/CreateRepositoryScanner";
import ListRepositoryScans from "./ListRepositoryScans/ListRepositoryScans";
import SingleRunRepositoryScansList from "./SingleRunRepositoryScans/SingleRunRepositoryScans";
import { getAccountData } from "redux/_account/account.selectors";
import {
  listSchedulers,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";

const RepositoryScanner = ({ account, fetchSchedulers }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line
  }, []);

  return (
    <PageReportsWrapper
      title="Repository Scanner"
      selected={selectedTab}
      setSelected={setSelectedTab}
      subpages={[
        {
          option: "Reports",
          title: "List of your repository scans",
          Component: () => <ListRepositoryScans account={account} />,
          extra: (
            <Button
              aria-controls="scan-menu"
              aria-haspopup="true"
              onClick={() => setSelectedTab(1)}
              size="small"
            >
              Create a New Repository scan
            </Button>
          ),
        },
        {
          sectionToMove: 1,
          option: "New Scan",
          title: "Public & Private Repo monitoring",
          description:
            "Scan your repository (both public and private) to ensure no sensitive information is exposed, and you have a comprehensive understanding of all third-party libraries, generating a report. Additionally, set up a webhook in your repository hosting platform (Bitbucket, GitHub or GitLab) to trigger automated scans in response to events using the provided URL. To execute scans directly from the platform's interface, configure a workflow utilizing the provided code.",
          Component: () => (
            <CreateRepositoryScanner setSelectedTab={setSelectedTab} />
          ),
        },
        {
          sectionToMove: 1,
          option: "Single-run Scans",
          title: "List of Single-run Scans",
          description: "Here are the latest scans you've run only once.",
          Component: () => (
            <SingleRunRepositoryScansList goToSection={setSelectedTab} />
          ),
        },
      ]}
    ></PageReportsWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  chedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
  fetchSchedulers: (filters) => dispatch(listSchedulers(filters)),
});

const ConnectedSchedulingRepositoryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepositoryScanner);
export default withRouter(ConnectedSchedulingRepositoryPage);
