import React, { useState } from "react";
import bitbucket from "assets/svgs-icons/Integrations/bitbucket";
import { useBitBucketBranchFetch } from "./useBitBucketBranchFetch";
import { useGithubBranchFetch } from "./useGithubBranchFetch";
import { useGitLabBranchFetch } from "./useGitLabBranchFetch";

export function useRepoProvider() {
  const [provider, setProvider] = useState(null);
  const [url, setUrl] = useState(null);
  const [privateToken, setPrivateToken] = useState(null);

  const {
    data: bitbucketData,
    loading: bitbucketLoading,
    error: bitbucketError,
    fetchData: fetchBitbucketData,
  } = useBitBucketBranchFetch();

  const {
    data: githubData,
    loading: githubLoading,
    error: githubError,
    fetchData: fetchGithubData,
  } = useGithubBranchFetch();

  const {
    data: gitlabData,
    loading: gitlabLoading,
    error: gitlabError,
    fetchData: fetchGitlabData,
  } = useGitLabBranchFetch();

  let data, loading, error;

  switch (provider) {
    case "Bitbucket":
      data = bitbucketData;
      loading = bitbucketLoading;
      error = bitbucketError;
      break;
    case "Github":
      data = githubData;
      loading = githubLoading;
      error = githubError;
      break;
    case "Gitlab":
      data = gitlabData;
      loading = gitlabLoading;
      error = gitlabError;
      break;
    default:
      data = githubData;
      loading = githubLoading;
      error = githubError;
      break;
  }

  const triggerFetch = (provider, url, privateToken) => {
    setProvider(provider);
    setUrl(url);
    setPrivateToken(privateToken);

    switch (provider) {
      case "Bitbucket":
        fetchBitbucketData(url, privateToken);
        break;
      case "Github":
        fetchGithubData(url, privateToken);
        break;
      case "Gitlab":
        fetchGitlabData(url, privateToken);
        break;
      default:
        fetchGithubData(url, privateToken);
        break;
    }
  };

  return { data, loading, error, triggerFetch };
}
