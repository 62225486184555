import Applications from "components/Consents/Collector/Applications";
import Configuration from "components/Consents/Collector/Configuration";
import EmbedCode from "components/Consents/Collector/EmbedCode";
import Policies from "components/Consents/Collector/Policies";
import PageWrapper, { Tab, Tabs } from "components/PageWrapper";
import useQuery from "hooks/useQuery";
import useReports from "hooks/useReports";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { getConfig } from "redux/_consents/_config/config.async.actions";
import { getConsentsConfig } from "redux/_consents/_config/config.selectors";
import { getReportJSON as fetchReportJSON } from "redux/_reports/reports.async.actions";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";

const necessary = "Strictly Necessary";
const functionality = "Functionality";
const tracking = "Tracking & Performance";
const performance = "Tracking & Performance";
const targeting = "Targeting & Advertising";
const advertising = "Targeting & Advertising";
export const POLICIES = [necessary, functionality, tracking, targeting];

const categoriesMap = {
  "Strictly Necessary": { name: necessary, relevance: 6 },
  "Social Network": { name: advertising, relevance: 5 },
  CDN: { name: necessary, relevance: 4 },
  "Action Pixels": { name: tracking, relevance: 4 },
  "Ad Motivated Tracking": { name: tracking, relevance: 4 },
  "Obscure Ownership": { name: tracking, relevance: 4 },
  "Session Replay": { name: tracking, relevance: 4 },
  "Unknown High Risk Behavior": { name: tracking, relevance: 4 },
  Malware: { name: tracking, relevance: 4 },
  SSO: { name: tracking, relevance: 4 },
  "Federated Login": { name: necessary, relevance: 3 },
  "Online Payment": { name: necessary, relevance: 3 },
  Performance: { name: performance, relevance: 3 },
  "Ad Fraud": { name: advertising, relevance: 2 },
  "Audience Measurement": { name: targeting, relevance: 2 },
  "Targeting/Advertising": { name: advertising, relevance: 2 },
  "Third-Party Analytics Marketing": { name: targeting, relevance: 2 },
  Advertising: { name: advertising, relevance: 2 },
  Analytics: { name: targeting, relevance: 2 },
  Functionality: { name: functionality, relevance: 2 },
  "Embedded Content": { name: functionality, relevance: 1 },
  "Non-Tracking": { name: functionality, relevance: 1 },
  "Social - Comment": { name: functionality, relevance: 1 },
  "Social - Share": { name: functionality, relevance: 1 },
  Badge: { name: functionality, relevance: 1 },
};

const ConsentsCollector = ({
  getConfigData,
  getReportJSON,
  currentReportJSON,
  widgetToken,
}) => {
  let history = useHistory();

  const query = useQuery();
  const { selected: report, ...reports } = useReports();
  const [value, setValue] = useState("configuration");
  const [foundApps, setFoundApps] = useState([]);

  const reportId = query.get("r");

  const onTabChange = (value) => {
    setValue(value);
    history.push(`/zen/consents/collector/${value}${window.location.search}`);
  };

  useEffect(() => {
    getConfigData(reportId);
  }, [getConfigData, reportId]);

  useEffect(() => {
    if (reportId) reports.get(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    if (report.data) getReportJSON(report.data);
  }, [report, getReportJSON]);

  useEffect(() => {
    if (!currentReportJSON) return;

    const descriptionFor = (owner, categories) => {
      const lastCat = categories.length > 1 ? ` and ${categories.pop()}` : "";
      return `This application is associated with ${owner} and is generally used for ${categories.join(
        ", "
      )}${lastCat}.`;
    };

    const policyFor = (categories) => {
      let new_policy = { name: functionality, relevance: 0 };
      categories.forEach((c) => {
        const match_policy = categoriesMap[c];
        if (match_policy && match_policy.relevance > new_policy.relevance)
          new_policy = match_policy;
      });
      return new_policy.name;
    };

    let fApps = [];
    if (currentReportJSON.details)
      // Try adding only apps with trackers if there are none then add all the records
      [0, -1].every((x) => {
        fApps = currentReportJSON.details
          .filter(
            ({ categories, trackers }) =>
              trackers.length > x &&
              !(categories.length === 1 && categories[0] === "CDN")
          )
          .map(({ domain, categories, owner, trackers: scripts }) => ({
            domain,
            owner,
            policy: policyFor(categories),
            description: descriptionFor(owner, [...categories]),
            scripts,
          }));
        if (fApps.length > 0) return false;
        return true;
      });
    setFoundApps(fApps);
  }, [currentReportJSON]);

  return (
    <PageWrapper
      title="CONSENTS"
      subtitle="COLLECTOR"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"configuration"} label="Configuration" />
          <Tab value={"policies"} label="Policies" />
          <Tab value={"applications"} label="Applications" />
          <Tab value={"embed-code"} label="Embed Code" />
        </Tabs>
      }
    >
      <Switch>
        <Route
          path={"/zen/consents/collector/configuration"}
          component={Configuration}
        />
        <Route path={`/zen/consents/collector/policies`}>
          {widgetToken && <Policies widgetToken={widgetToken} />}
        </Route>
        <Route path={"/zen/consents/collector/applications"}>
          {widgetToken && (
            <Applications foundApps={foundApps} widgetToken={widgetToken} />
          )}
        </Route>
        <Route
          path={"/zen/consents/collector/embed-code"}
          component={EmbedCode}
        />
      </Switch>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  widgetToken: getConsentsConfig(state).token,
  currentReportJSON: getCurrentReportJSON(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConfigData: (x) => dispatch(getConfig(x)),
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsCollector);
