/**
 * Given an array of object, it returns an object which keys are the original objects
 *
 * @methods utils/mapArrayToObject
 * @param {String} key key to use in result object
 * @param {Array} target array to transform
 * @returns {Object}
 */

export default function mapArrayToObject(key, target) {
  return target.reduce((acc, value) => {
    acc[value[key]] = value;
    return acc;
  }, {});
}
