import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  inputContainer: {
    alignItems: "center",
    padding: "0px 24px",
    flexDirection: "row",
    "& >div": {
      width: "100%",
    },
  },
}));
