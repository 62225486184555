import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Box, Typography, Grid, Backdrop } from "@material-ui/core";

import { isApp, isSitemap, getReportUrl } from "utils/reports";

import Card from "components/PrivacyCentral/components/Card";
import pointsBackground from "assets/images/points-bg.svg";
import Pagination from "./components/pagination";
import Loader from "components/Loader";
// import Filters from './components/filters';

import { getReports, deleteReport } from "redux/_reports/reports.async.actions";
import {
  getReportsData,
  getReportsErrorState,
  getReportsMeta,
  getReportLoadingState,
} from "redux/_reports/reports.selectors";
import {
  deleteSecurityScans,
  getSecurityData,
} from "redux/_scans/scans.service";

import useStyles from "./styles";

const pageSize = 6;

export const Reports = ({
  user,
  classes,
  data,
  withTitle = true,
  removeAction = () => {},
  size,
  deviceType,
}) => {
  // Function to extract the last part of the URL
  const extractLastPartFromUrl = (url) => {
    const parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    lastPart = lastPart.replace(".pdf", "");
    return lastPart;
  };

  return (
    <Grid container spacing={4} className={clsx(classes.cardContainer)}>
      {data && data.length > 0 ? (
        data.map((item, i) => {
          const lastPart = extractLastPartFromUrl(item.resource);
          const isAppValue = isApp(item.version);
          const cardProps = {
            image: item.screenshot,
            date: item.createdAt,
            href: getReportUrl(
              isAppValue ? item.pk : item.report || item.pk,
              item.version
            ),
            pdf: item.resource,
            document: item.document,
            name: lastPart,
            smallContent: true,
            isSitemap: isSitemap(item.version),
            isApp: isAppValue,
            user,
            deleteItem: removeAction(item.pk),
            deviceType: deviceType,
          };

          if (withTitle) {
            cardProps.title = item.resource;
          }

          return (
            <Grid key={i} item xs={12} sm={4}>
              <Card {...cardProps} />
            </Grid>
          );
        })
      ) : (
        <Box py={3} textAlign="center" width="100%">
          <Typography>No reports were found.</Typography>
        </Box>
      )}
    </Grid>
  );
};

const ReportList = ({
  account,
  data = [],
  error,
  loading,
  meta: { totalRows, pageCount },
  getData,
  showTitle = true,
  showPagination = true,
  className = "",
  removeReport,
  getSecurityList,
  deviceType,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [actions, addAction] = useState(0);
  const environment = deviceType === "web" ? deviceType : ["android", "ios"];

  // TODO: refactor to make sure that only the one needed request is made
  useEffect(() => {
    if (deviceType === "security")
      getSecurityList({
        pageSize,
        page,
        status: "successfully",
      });
    else {
      getData({
        pageSize,
        page,
        isDemo: true,
        status: "successfully",
        environment: environment,
      }); // TODO: fix typo in backend
      // eslint-disable-next-line
    }
  }, [actions]);

  const updatePage = async (pageNumber) => {
    if (deviceType === "security") {
      getSecurityList({
        pageSize,
        page: pageNumber,
        status: "successfully",
      });
    } else {
      await getData({
        pageSize,
        page: pageNumber,
        status: "successfully",
        environment: environment,
      });
    }
    setPage(pageNumber);
  };

  const confirmReportDeletion = (id) => async () => {
    deviceType === "security"
      ? deleteSecurityScans(id).then(() => {
          addAction(actions + 1);
        })
      : (await removeReport(id)) &&
        (await getData({
          pageSize,
          page,
          isDemo: true,
          status: "successfully",
          environment: environment,
        }));
  };

  // TODO(@jperozo): Find a better way
  const { id } = account;

  // Filter the data array based on deviceType and environment
  const filteredData =
    deviceType === "web"
      ? data.filter((item) => item.environment === "web")
      : deviceType === "mobile"
      ? data.filter(
          (item) => item.environment === "android" || item.environment === "ios"
        )
      : deviceType === "security"
      ? data
      : [];

  return (
    <section className={clsx(classes.container, className)}>
      <Box className={classes.titleContainer}>
        {showTitle && (
          <Typography className={classes.title} component="h4">
            Privacy Reports Archive:{" "}
            <span>{!id ? "Demo" : "Your"} reports</span>
          </Typography>
        )}
        {/* <Filters /> */}
        <img className={classes.pattern} src={pointsBackground} alt="" />
      </Box>
      <div
        className="container-fluid"
        style={{ position: "relative", zIndex: 2 }}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <Loader />
        </Backdrop>
        <Reports
          user={id}
          classes={classes}
          data={filteredData}
          removeAction={confirmReportDeletion}
          deviceType={deviceType}
        />
        {showPagination && (
          <Box
            textAlign="center"
            className={clsx("container", classes.paginationContainer)}
            mt={7}
          >
            <Pagination
              onPageChange={updatePage}
              totalRows={totalRows}
              pageCount={pageCount}
              page={page}
            />
          </Box>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  data: getReportsData(state),
  error: getReportsErrorState(state),
  loading: getReportLoadingState(state),
  meta: getReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (filters) => dispatch(getReports(filters)),
  getSecurityList: (filters) => dispatch(getSecurityData(filters)),
  removeReport: (id) => dispatch(deleteReport(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);
