import React, { useEffect } from "react";
import "./App.css";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./helpers/redux-store";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppRouter from "./router";
import { ThemeProvider } from "@material-ui/styles";
import SnackbarProvider from "components/Snackbar";
import { REPO_V2_URL } from "constants/RepoV2";
require("typeface-aldrich");
require("typeface-roboto-condensed");
require("typeface-roboto-mono");

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider>
          <CssBaseline />
          <AppRouter />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
