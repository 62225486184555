import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  radioButtonGroup: {
    flexDirection: "row",
    padding: "0px 24px",
  },
  radioButton: {
    margin: "0px 12px 0px 0px",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.grey[300],
  },
}));
