import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    alignItems: "center",
    padding: "0px 24px",
    width: "100%",
    display: "flex",
  },
  image: {
    maxWidth: 240,
    maxHeight: 56,
  },
  uploadButton: {
    textTransform: "none",
    color: theme.palette.blue["young"],
    fontSize: "14px",
    fontWeight: 500,
    width: "100px",
    borderRadius: "0px 4px 4px 0px",
    cursor: "pointer",
    marginBottom: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  hiddenInput: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
}));
