import { getData, updateData } from "redux/_requests/_config/config.service";
import {
  requestStart,
  requestEnd,
  saveData,
} from "redux/_requests/_config/config.actions";
import isEmpty from "lodash.isempty";
import { getRequestsConfig } from "redux/_requests/_config/config.selectors";

export const getConfig = () => (dispatch, getState) => {
  const config = getRequestsConfig(getState());
  if (isEmpty(config)) {
    dispatch(requestStart());
    return getData()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const updateConfig = (data, id) => (dispatch) => {
  dispatch(requestStart());
  return updateData(data, id)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};
