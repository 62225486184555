import React, { useState } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Severity from "pages/AppReport/common/Severety";
import { BoxDetails } from "../FindingSeverity/InformationSection";
import ExpandedPanel from "../FindingSeverity/ExpandedPanel";
import SectionContent from "pages/AppReport/common/SectionContent";

const CertificateInfoSection = ({ goToNextSectionAction, data }) => {
  const formattedData = data.certificate_findings.map((certificate) => ({
    severity: certificate[0],
    description: certificate[1],
    title: certificate[2],
  }));
  const [expanded, setExpanded] = useState(false);
  return (
    <Page
      key={SECTIONS_CONTENT.certificateInfo.key}
      footerBackgroundColor={SECTIONS_CONTENT.certificateInfo.backgroundColor}
      footerText={<div className="privacy-tech">Check the APKID Analysis</div>}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <SectionContent
        styles={{ display: "flex", flexDirection: "column", gap: 16 }}
        noTitle
      >
        <ExpandedPanel
          title={SECTIONS_CONTENT.certificateInfo.title}
          tooltip={SECTIONS_CONTENT.certificateInfo.description}
          onChange={() => setExpanded(!expanded)}
          expanded={expanded}
        >
          <BoxDetails
            data={`
            <b>${data.certificate_info.replaceAll("\n", "<br />")}</b>
          `}
          />
        </ExpandedPanel>
        <Table
          data={formattedData}
          limitPerPage={5}
          columns={[
            {
              key: "title",
              label: "Title",
              width: "30%",
            },
            {
              key: "severity",
              label: "Severity",
              width: "15%",
              render: (severity) => <Severity severity={severity} />,
            },
            {
              key: "description",
              label: "Description",
              width: "55%",
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default CertificateInfoSection;
