import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getAll = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/functional_uses`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const create = (functionalUseName) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ name: functionalUseName }),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/functional_uses`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const updateTableFunctionalUse = (dbId, tableId, functionalUseId) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      id_table: tableId,
      id_functional_use: functionalUseId,
    }),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/databases/${dbId}/tables`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
