import React, { Component } from "react";
import { BOX_WIDTH, BOX_HORIZONTAL_MARGIN } from "./consts";

const TOP_OFFSET = 50;

class TreeWrappre extends Component {
  getCounts = () => {
    const { selected } = this.props;
    if (selected)
      switch (selected.data.type) {
        case "datasource":
          return {
            datasources: selected.parent.children.length,
            databases: selected.children.length,
          };
        case "database":
          return {
            datasources: selected.parent.parent.children.length,
            databases: selected.parent.children.length,
            tables: selected.children.length,
          };
        case "table":
          return {
            datasources: selected.parent.parent.parent.children.length,
            databases: selected.parent.parent.children.length,
            tables: selected.parent.children.length,
            columns: selected.children.length,
          };
        case "column":
          return {
            datasources: selected.parent.parent.parent.parent.children.length,
            databases: selected.parent.parent.parent.children.length,
            tables: selected.parent.parent.children.length,
            columns: selected.parent.children.length,
          };
        default:
          return {};
      }
    return {};
  };

  calculatePosition = (i) => {
    const { width } = this.props;
    return Math.min(
      Math.max(
        (i - 1) * (BOX_WIDTH + BOX_HORIZONTAL_MARGIN) + BOX_WIDTH / 2,
        width / (2 * 4) + (width / 4) * (i - 1)
      ),
      (i - 1) * (BOX_WIDTH + BOX_HORIZONTAL_MARGIN + 16) + BOX_WIDTH / 2
    );
  };

  getCenterGroupValue() {
    const { width } = this.props;
    const groupWidth = this.calculatePosition(4) + BOX_WIDTH / 2;
    return Math.max(0, width / 2 - groupWidth / 2);
  }

  render() {
    const { width } = this.props;
    const { calculatePosition } = this;
    const counts = this.getCounts();
    if (!width) return null;
    return (
      <g fontFamily="Roboto Condensed" onClick={this.onHeaderClick}>
        <rect fill={"white"} width={width} height={65} />
        <g transform={`translate(${this.getCenterGroupValue()})`}>
          <text
            y={TOP_OFFSET}
            x={calculatePosition(1)}
            fill="#686c78"
            fontWeight={"bold"}
            textAnchor={"middle"}
          >
            DATASOURCES{" "}
            <tspan>{counts.datasources && `(${counts.datasources})`}</tspan>
          </text>
          <text
            y={TOP_OFFSET}
            x={calculatePosition(2)}
            fill="#686c78"
            fontWeight={"bold"}
            textAnchor={"middle"}
          >
            DATABASES{" "}
            <tspan>{counts.databases && `(${counts.databases})`}</tspan>
          </text>
          <text
            y={TOP_OFFSET}
            x={calculatePosition(3)}
            fill="#686c78"
            fontWeight={"bold"}
            textAnchor={"middle"}
          >
            TABLES <tspan>{counts.tables && `(${counts.tables})`}</tspan>
          </text>
          <text
            y={TOP_OFFSET}
            x={calculatePosition(4)}
            fill="#686c78"
            fontWeight={"bold"}
            textAnchor={"middle"}
          >
            COLUMNS <tspan>{counts.columns && `(${counts.columns})`}</tspan>
          </text>
        </g>
      </g>
    );
  }
}

export default TreeWrappre;
