import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  row: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  fieldSm: {
    width: 208,
    marginRight: theme.spacing(2),
  },
  fieldMd: {
    width: 336,
    marginRight: theme.spacing(2),
  },
  addButton: {
    width: "80px",
    height: "80px",
    backgroundColor: "white",
    border: `1px dashed ${theme.palette.blue[500]}`,
    borderRadius: "5px !important",
    marginRight: "16px",
    "&:hover": {
      backgroundColor: "rgba(207,223,248,0.3)",
      borderStyle: "solid",
      borderColor: theme.palette.blue["young"],
      "& >span:first-child": {
        color: theme.palette.blue["young"],
      },
    },
  },
  addButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
  },
  addIcon: {
    color: "#3f51b5",
    marginBottom: "10px",
  },
  entityButton: {
    overflow: "hidden",
    width: "80px",
    height: "80px",
    backgroundColor: "rgba(207, 223, 248, 0.2)",
    border: "1px solid #a4bef1 !important",
    borderRadius: "5px !important",
    marginRight: "16px",
    transition: "0.15s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.blue[100],
      "& >span:first-child": {
        color: theme.palette.blue[600],
      },
      "&>span": {
        "&>svg": {
          display: "inline-block",
        },
      },
    },
  },
  entityButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
    "& >svg": {
      marginBottom: theme.spacing(),
      "& >path": {
        fill: theme.palette.blue[500],
      },
    },
  },
  buttonTypography: {
    width: "62px",
    fontSize: "13px",
    fontWeight: 500,
  },
  deleteIcon: {
    backgroundColor: "rgba(207, 223, 248, 0.8)",
    width: 80,
    height: 80,
    padding: 9,
    display: "none",
    margin: "0",
    position: "absolute",
  },
  databasesWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 80px)",
    gridGap: "16px",
  },
}));
