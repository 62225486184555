import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";

import { Hidden, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { register } from "redux/_register/_register.async.actions";

import HomepageMenu from "components/HomepageMenu";
import PublicNav from "components/PublicComponents/Nav";
import Footer from "components/PublicComponents/Footer";
import useQuery from "hooks/useQuery";
import { routePaths } from "router/paths";

import RegistrationForm from "./Form";
import PlanList, { PLANS } from "./List";
import useStyles from "./styles";
import { BILLING_FRECUENCY } from "./constants";

export const BillingTypeButtonGroup = ({ selected, handlePrices }) => {
  const classes = useStyles();

  return (
    <ButtonGroup
      variant="contained"
      aria-label="outlined primary button group"
      className={classes.buttonGroup}
    >
      <Button
        onClick={handlePrices(BILLING_FRECUENCY.monthly)}
        className={clsx(BILLING_FRECUENCY.monthly === selected && "-selected")}
      >
        Per Month
      </Button>
      <Button
        onClick={handlePrices(BILLING_FRECUENCY.yearly)}
        className={clsx(BILLING_FRECUENCY.yearly === selected && "-selected")}
      >
        Per Year
        <span className={classes.inlineNote}>Discounted</span>
      </Button>
    </ButtonGroup>
  );
};

const Plans = ({ location, doRegister }) => {
  const query = useQuery();

  const classes = useStyles();
  const [selected, setSelected] = useState(BILLING_FRECUENCY.monthly);
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState(PLANS[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleClickOpen = (selectedPlan) => () => {
    setPlan(selectedPlan);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError();
  };

  const handlePrices = (frecuency) => () => {
    setSelected(frecuency);
  };

  const setErrorAction = (e) => {
    setError(e);
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    try {
      await doRegister(values, plan.id);
      // TODO: remove dead page if needed
      // history.push(routePaths.congrats);
    } catch (e) {
      setErrorAction(
        e
          ? e[0]
          : "There was an error when trying to register. Please try again later."
      );
    }
  };

  return (
    <>
      <div id="outer-container">
        <Hidden mdUp>
          <HomepageMenu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
        </Hidden>
        <div id="page-wrap" className={classes.root}>
          <PublicNav />
          <div className={classes.main}>
            <section className={classes.masthead}>
              <div className="title">
                <Typography variant="h1">Plans & Pricing</Typography>
                <br />
              </div>
              <BillingTypeButtonGroup
                selected={selected}
                handlePrices={handlePrices}
              />
            </section>
            <PlanList
              setSelectedPlan={handleClickOpen}
              selectedPlanCategory={selected}
            >
              <RegistrationForm
                billingFrequency={selected}
                open={open}
                handleClose={handleClose}
                {...plan}
                email={query.get("email")}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
                error={error}
                setError={setErrorAction}
              />
            </PlanList>
          </div>
          {location.pathname === routePaths.pricing && (
            <Footer backgroundColor="white" />
          )}
        </div>
      </div>
    </>
  );
};

const PlansWithRouter = withRouter(Plans);

const mapDispatchToProps = (dispatch) => ({
  doRegister: (data, planId) => dispatch(register(data, planId)),
});

export default connect(null, mapDispatchToProps)(PlansWithRouter);
