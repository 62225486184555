import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  iframe: {
    width: "100%",
    height: "100vh",
    border: "none",
    paddingTop: "1rem",
  },
}));
