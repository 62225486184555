const codeStringGithub = (apiUrl, webhookId) => `
on: 
  workflow_dispatch:

jobs:
  build:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout code
        uses: actions/checkout@v2

      - name: Get latest commit
        id: latest_commit
        run: |
          latest_commit=$(git log -1 --pretty=format:"%H")
          echo "COMMIT_ID=$latest_commit" >> $GITHUB_ENV

      - name: Make POST request
        run: |
          echo "Commit ID: $COMMIT_ID"
          curl -X POST \\
            -H "Content-Type: application/json" \\
            -d '{"head_commit":{"id": "'"$COMMIT_ID"'"} }' \\
            "${apiUrl}/scans/${webhookId}/callback/"
`;

const codeStringBitbucket = (apiUrl, webhookId) =>
  `
image: 'atlassian/default-image:3'
pipelines:
  default:
    - step:
        name: Build and Test
        script:
          - echo "$BITBUCKET_COMMIT"
          - >-
            curl -X POST \\
              -H "Content-Type:application/json" \\
              -d "{\\"head_commit\\":{ \\"id\\":\\"$` +
  `{BITBUCKET_COMMIT}\\" }}" \\
              "${apiUrl}/scans/${webhookId}/callback/"
`;

const codeStringGitlab = (apiUrl, webhookId) => `
stages:
  - scan

scan-job:
  stage: scan
  script:
    - latest_commit=$(git log -1 --pretty=format:"%H")
    - echo "Commit ID:$latest_commit"
    - >-
      curl -X POST \\
        -H "Content-Type:application/json" \\
        -d "{\\"head_commit\\":{\\"id\\":\\"$latest_commit\\"}}" \\
        "${apiUrl}/scans/${webhookId}/callback/"
  only:
    - branches
`;

export const platformInstructions = {
  github: {
    webhookSetup: {
      title: "Webhook Setup Instructions for Automated Scanning",
      instructions: [
        "1. Open your GitHub repository settings.",
        '2. Navigate to the "Webhooks" section in the sidebar.',
        '3. Click on "Add webhook" or "Create webhook."',
        "4. In the webhook creation form, do the following:",
        '- Paste the provided webhook URL in the "Payload URL" field:',
        "- Choose the content type to application/json.",
        "- Choose the events you want to trigger the webhook, or select 'Send me everything' to receive all events.",
        "5. Save your webhook settings.",
      ],
    },
    actionInstruction: {
      title: "Setting Up GitHub Action for Scanning",
      instructions: [
        "1. Open your repository on GitHub.",
        "2. In the repository navigation bar, click on the 'Actions' tab.",
        "3. Click on the 'New workflow' button in the top right corner.",
        "4. Select the option 'Set up a workflow yourself.'",
        "5. An editor will open where you can write the YAML code for your new action. Copy and paste the YAML code you want to use for the action.",
        "6. After pasting the code, click on the 'Start commit' button in the top right corner of the editor.",
        "7. Provide a name for your workflow file (e.g., post-request.yaml) and a commit message, then click 'Commit new file.'",
        "8. You should now see your new action on the Actions page of your repository.",
      ],
    },
    runAction: {
      title: "Running Your GitHub Action/Workflow",
      instructions: [
        '1. Go to the "Actions" tab in your GitHub repository.',
        '2. Locate the workflow you created (e.g., "post-request.yaml") under "All workflows" or "My workflows."',
        "3. Click on the workflow name to open it.",
        '4. On the workflow page, you\'ll see a "Run workflow" button. Click on it.',
        "5. GitHub will prompt you to enter the input values for your workflow. Enter the required value for commitId as per your needs.",
        '6. Click on the "Run workflow" button to trigger the execution of your GitHub Action.',
      ],
    },
    codeString: codeStringGithub,
    tokenInstructions: [
      {
        imageSrc: "/TokenSteps/Github1.png",
        description: "1. Go to the platform GitHub",
      },
      {
        imageSrc: "/TokenSteps/Github2.jpg",
        description:
          "2. Enter your profile menu and select the Settings option.",
      },
      {
        imageSrc: "/TokenSteps/Github3.jpg",
        description:
          "3. Select personal access (classic) token and click there.",
      },
      {
        imageSrc: "/TokenSteps/Github4.jpg",
        description: "4. Click generate classic token.",
      },
      {
        imageSrc: "/TokenSteps/Github5.jpg",
        description:
          "5. Now the system will ask you for your password for more security",
      },
      {
        imageSrc: "/TokenSteps/Github6.jpg",
        description:
          "6. Once you have entered you must put a note to this token that you want to generate and then select the expiration time.",
      },
      {
        imageSrc: "/TokenSteps/Github7.jpg",
        description: "7. Now select the permissions (we suggest full access).",
      },
      {
        imageSrc: "/TokenSteps/Github8.jpg",
        description:
          "8. Click generate token and then copy your token code and you're done!",
      },
    ],
  },
  bitbucket: {
    webhookSetup: {
      title: "Webhook Setup Instructions for Automated Scanning",
      instructions: [
        "1. Open your Bitbucket repository settings.",
        '2. Navigate to the "Webhooks" section in the sidebar.',
        '3. Click on "Add webhook" or "Create webhook."',
        "4. In the webhook creation form, do the following:",
        "- Add a title for your webhook.:",
        '- Paste the provided webhook URL in the "URL" field:',
        "- Choose the events you want to trigger the webhook. For automated scanning, select 'Repository push'.",
        "5. Save your webhook settings.",
      ],
    },
    actionInstruction: {
      title: "Setting Up Bitbucket Pipeline for Scanning",
      instructions: [
        "1. Open your Bitbucket repository.",
        "2. Navigate to the 'Repository settings' section.",
        "3. Click on 'Pipelines' in the left sidebar.",
        "4. Click on the 'Create pipeline' button.",
        "5. In the pipeline editor, copy and paste the provided YAML code for the Bitbucket pipeline.",
        "6. Commit the changes to create your new pipeline.",
      ],
    },
    runAction: {
      title: "Running Your Bitbucket Pipeline/Workflow",
      instructions: [
        "1. Open your Bitbucket repository.",
        "2. Navigate to the 'Pipelines' section.",
        "3. You should see your newly created pipeline listed.",
        "4. Click on the pipeline name to open its details.",
        "5. Click on the 'Run pipeline' button to trigger the execution of your pipeline.",
      ],
    },
    codeString: codeStringBitbucket,
    tokenInstructions: [
      {
        imageSrc: "/TokenSteps/Bitbucket1.png",
        description: "1. Go to the platform Bitbucket",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket2.png",
        description: "2. Navigate to the repository you want to access",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket3.png",
        description: "3. In the sidebar, click on Repository settings",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket4.png",
        description: "4. Select Access tokens from the sidebar options",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket5.png",
        description: "5. Click the Create Repository Access Token button",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket6.png",
        description:
          "6. Name your token and choose the necessary permissions (select push for this case)",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket7.png",
        description: "7. Click the Create button",
      },
      {
        imageSrc: "/TokenSteps/Bitbucket8.png",
        description: "8. Copy the generated token and save it securely",
      },
    ],
  },
  gitlab: {
    webhookSetup: {
      title: "Webhook Setup Instructions for Automated Scanning",
      instructions: [
        "1. Open your GitLab repository settings.",
        '2. Navigate to the "Webhooks" section in the sidebar.',
        '3. Click on "Add new webhook".',
        "4. In the webhook creation form, do the following:",
        '- Paste the provided webhook URL in the "URL" field.',
        "- Select the events you want to trigger the webhook, or select 'Push events' to receive only push events.",
        "5. Save your webhook settings.",
      ],
    },
    actionInstruction: {
      title: "Setting Up GitLab CI/CD Pipeline for Scanning",
      instructions: [
        "1. Open your GitLab repository.",
        "2. In the left sidebar, navigate to 'Build'.",
        "3. click on pipeline editor to create a new pipeline .gitlab-ci.yml file.",
        "4. In the pipeline editor, copy and paste the provided YAML code for the Gitlab pipeline.",
        "5. Scroll down and click on the 'Commit changes' button.",
        "6. Provide a commit message and click on 'Commit changes' again.",
        "7. Your new pipeline is now set up and will be triggered on code pushes or manual runs.",
      ],
    },
    runAction: {
      title: "Running Your GitLab CI/CD Pipeline",
      instructions: [
        "1. Open your GitLab repository.",
        "2. In the left sidebar, navigate to 'Build'.",
        "3. Click on 'Pipelines'.",
        "4. Click on the 'Run pipeline' button.",
        "5. If you want to run the pipeline for a specific branch, click on the dropdown next to the 'Run pipeline' button and select the branch.",
        "6. You don't need to enter any additional variables or input values.",
        "7. Click on the 'Run pipeline' button again to trigger the execution of your GitLab CI/CD pipeline.",
      ],
    },
    codeString: codeStringGitlab,
    tokenInstructions: [
      {
        imageSrc: "/TokenSteps/Gitlab1.png",
        description: "1. Go to the platform Gitlab",
      },
      {
        imageSrc: "/TokenSteps/Gitlab2.png",
        description: "2. Enter your profile menu in the sidebar",
      },
      {
        imageSrc: "/TokenSteps/Gitlab3.png",
        description: "3. select preferences",
      },
      {
        imageSrc: "/TokenSteps/Gitlab4.png",
        description: "4. Go to access tokens",
      },
      {
        imageSrc: "/TokenSteps/Gitlab5.png",
        description: "5. Click on the button to create a new access token",
      },
      {
        imageSrc: "/TokenSteps/Gitlab6.png",
        description: "6. Name your token.",
      },
      {
        imageSrc: "/TokenSteps/Gitlab7.png",
        description:
          "7. Select the scopes. We suggest read repository and click on create personal access token",
      },
      {
        imageSrc: "/TokenSteps/Gitlab8.png",
        description: "8. Copy your token code and you're done!",
      },
    ],
  },
};
