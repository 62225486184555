import React from "react";

const Logout = () => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0033 19.6901L1.18387 21.6386C0.964867 21.6717 0.75877 21.6081 0.575978 21.4647C0.415452 21.3207 0.319143 21.113 0.319143 20.946L0.319145 1.12308C0.319145 0.891791 0.406794 0.694415 0.567859 0.564921C0.716406 0.455214 0.89289 0.388795 1.05719 0.387664L1.06095 0.388213C1.06875 0.389434 1.07806 0.391246 1.09353 0.394317L1.09518 0.394643C1.10848 0.397288 1.12723 0.401013 1.14605 0.403964C1.15969 0.406102 1.1776 0.408543 1.19774 0.40977L12.9977 2.35521L13.0033 2.356C13.3616 2.40683 13.6191 2.71114 13.6191 3.07099L13.6191 6.64599C13.6191 7.05628 13.2943 7.3814 12.8768 7.3814C12.4594 7.3814 12.1345 7.05628 12.1345 6.64599L12.1345 3.68388L1.78068 1.97424L1.78068 20.0719L12.1345 18.3623L12.1345 15.4002C12.1345 14.9899 12.4594 14.6647 12.8768 14.6647C13.2943 14.6647 13.6191 14.9899 13.6191 15.4002L13.6191 18.9752C13.6191 19.335 13.3615 19.6392 13.0033 19.6901Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3256 6.44541C16.5933 6.16291 17.0547 6.15502 17.3526 6.45083L21.4602 10.5301L21.4657 10.5353C21.7492 10.8002 21.7572 11.2553 21.4603 11.5502L17.3526 15.6294C17.2201 15.761 17.0321 15.8318 16.8364 15.8318C16.6407 15.8318 16.4529 15.7609 16.3204 15.6293L16.3148 15.6241C16.0314 15.3593 16.0237 14.9041 16.3203 14.6092L19.2009 11.7755L8.85181 11.7755C8.43439 11.7755 8.10951 11.4504 8.10951 11.0401C8.10951 10.6298 8.4344 10.3047 8.85181 10.3047L19.1737 10.3047L16.3204 7.47097L16.3148 7.4658C16.0313 7.20083 16.0234 6.7458 16.3204 6.45091L16.3256 6.44541Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x={22}
          y={22}
          width={22}
          height={22}
          transform="rotate(-180 22 22)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Logout;
