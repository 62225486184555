import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
  },
  main: {
    width: "100%",
    minHeight: "100vh",
    maxHeight: "100%",
    backgroundColor: "white",
  },
  menuIcon: {
    position: "fixed",
    top: theme.spacing(1.5),
    left: theme.spacing(3),
    zIndex: 1002,
  },
}));
