import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import LearnMoreLink from "../../ftu/components/LearnMoreLink";
import LoadingSelect from "../components/LoadingSelect";

const PIISharingDetailsSummary = ({
  detections,
  selectedType,
  selectedCategory,
  types = [],
  categories = [],
  handleTypeChange,
  handleCategoryChange,
  loading
}) => {
  const detailsData = useSelector((state) => state.sharingDetails.details)?.detailsInfo;

  if (!detailsData) {
    return null;
  }

  return (
    <Box style={{ borderRadius: 4, padding: 16, background: "#F7F7F7", gap: 8, display: "flex", flexDirection: "column", marginTop: 20 }}>
      <Box style={{ display: "flex", flexDirection: "row", gap: 30 }}>
        <LoadingSelect
          label="Type"
          value={selectedType}
          onChange={handleTypeChange}
          items={types}
          loading={loading}
        />
        <LoadingSelect
          label="Category"
          value={selectedCategory}
          onChange={handleCategoryChange}
          items={categories}
          loading={loading}
        />
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography style={{ fontSize: 16, fontWeight: 900, color: "#1C3687", fontFamily: "Inter" }}>
          {detections} Detections
        </Typography>
        <LearnMoreLink />
      </Box>
    </Box>
  );
};

export default PIISharingDetailsSummary;
