import React from "react";

const Reference = ({ title, width, labels, keys, colors, data }) => {
  return (
    <g transform={`translate(${width - 240})`} style={{ transition: "none" }}>
      <text
        fill="#4f4f4f"
        fontFamily="Roboto Condensed, sans-serif"
        fontWeight="bold"
      >
        {title}
      </text>
      <line
        transform="translate(0 10)"
        stroke="lightgray"
        strokeWidth={0.5}
        x1={160}
      />
      {keys.map((key, i) => (
        <g key={key} transform={`translate(0 ${45 * (i + 1)})`}>
          <text
            fontSize=".8rem"
            fontWeight="light"
            fontFamily="Roboto Condensed, sans-serif"
            fill="gray"
          >
            {labels[key]}
          </text>
          <text
            fontSize=".8rem"
            fontFamily="Roboto Condensed, sans-serif"
            fontWeight="bold"
            textAnchor="end"
            x={160}
            fill="#4f4f4f"
          >
            {Number(data[key]).toLocaleString()}
          </text>
          <g transform="translate(0 12)">
            <line
              transform="translate(-1 -3)"
              stroke={colors[key]}
              strokeWidth={3}
              y1={6}
            />
            <line
              transform="translate(161 -3)"
              stroke={colors[key]}
              strokeWidth={3}
              y1={6}
            />
            <line stroke={colors[key]} strokeWidth={3} x1={160} />
          </g>
        </g>
      ))}
    </g>
  );
};

export default Reference;
