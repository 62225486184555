import { CONSTANTS } from "redux/_requests/_data/data.constants";

export const requestStart = () => ({
  type: CONSTANTS.REQUESTS_DATA_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.REQUESTS_DATA_REQUEST_END,
});

export const setError = () => ({
  type: CONSTANTS.REQUESTS_DATA_SET_ERROR,
});

export const saveData = (data) => ({
  type: CONSTANTS.REQUESTS_DATA_SAVE_DATA,
  data,
});

export const saveMetadata = (data) => ({
  type: CONSTANTS.REQUESTS_DATA_SAVE_META,
  data,
});

export const saveFilterData = (dataType, data) => ({
  type: CONSTANTS.REQUESTS_FILTERS_SAVE_DATA,
  dataType,
  data,
});

export const selectFilter = (dataType, data) => ({
  type: CONSTANTS.REQUESTS_FILTERS_SELECT_DATA,
  dataType,
  data,
});
