import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  tableWrapper: {
    overflow: "auto",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxHeight: "100%",
  },
  table: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    overflow: "hidden",
  },
  selected: {
    backgroundColor: theme.palette.blue[500],
    "&:hover": {
      "&>td": {
        backgroundColor: theme.palette.blue[500],
      },
    },
    "&>td": {
      color: "white!important",
      fontWeight: "bold",
      backgroundColor: theme.palette.blue[500],
    },
  },
  tableRowHeader: {
    height: 40,
    "& > th": {
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: "15px",
      fontWeight: "bold",
      color: theme.palette.grey[200],
      letterSpacing: "normal",
      border: "none",
    },
    "& > th:first-child": {
      paddingLeft: theme.spacing(3),
      width: 52,
    },
    "& > th:nth-child(3)": {
      width: 74,
    },
    "& > th:last-child": {
      paddingRight: theme.spacing(3),
      width: 100,
    },
  },
  tableRowBody: {
    height: 36,

    "&:nth-child(even)": {
      backgroundColor: fade("#E0E0E0", 0.2),
    },
    "& > td": {
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: 14,
      lineHeight: "16px",
      color: theme.palette.grey[800],
      letterSpacing: "normal",
      padding: 7,
    },
    "& > td:first-child": {
      color: "#333333",
      paddingLeft: theme.spacing(3),
    },
    "& > th:nth-child(2)": {
      fontSize: 13,
      lineHeight: "15px",
    },
    "& > td:last-child": {
      paddingRight: theme.spacing(3),
    },
  },
  footer: {
    backgroundColor: fade(theme.palette.blue[100], 0.3),
    color: theme.palette.blue[800],
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.blue[200]}`,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  showedRows: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "18px",
    letterSpacing: "normal",
  },
  navButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(-1, 0),
    "&:disabled": {
      opacity: 0.4,
    },
  },
  textContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    textAlign: "center",
  },
  text: {
    textShadow: "1px 1px 0 var(--white)",
    fontSize: 18,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "normal",
    color: theme.palette.blue[800],
  },
}));
