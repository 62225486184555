import { Text, View } from "@react-pdf/renderer";
import { useMemo } from "react";
import { ReactComponent as Land } from "../../../pages/AppReport/land.svg";
import { ReactComponent as HighIcon } from "../../../pages/AppReport/Information_tab.svg";
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import {
  SECTION_TITLE,
  SEVERITY_SECTION_NAME,
} from "pages/AppReport/constants";
import ReactHtmlParser from "react-html-parser";

export const severities = [
  {
    label: "HIGH",
    value: "high",
    icon: <HighIcon />,
  },
  {
    label: "WARNING",
    value: "warning",
    icon: <ReportProblemOutlined style={{ color: "#ED6C02" }} />,
  },
  {
    label: "INFO",
    value: "info",
    icon: <InfoOutlined />,
  },
  {
    label: "SECURE",
    value: "secure",
    icon: <CheckCircleOutlineOutlined />,
  },
  {
    label: "HOTSPOT",
    value: "hotspot",
    icon: <Land />,
  },
];

const OpportunitiesPage = ({ styles, data }) => {
  const formattedData = useMemo(() => {
    const groupedData = {
      high: [],
      warning: [],
      info: [],
      secure: [],
      hotspot: [],
    };
    severities.forEach((severity) => {
      data.appsec[severity.value] &&
        data.appsec[severity.value].forEach((item) => {
          const section = item.section;

          if (!groupedData[severity.value][section]) {
            groupedData[severity.value][section] = [];
          }

          groupedData[severity.value][section].push(item);
        });
    });
    return groupedData;
  }, [data]);
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>Opportunities</Text>
        <Text style={styles.subTitle}>
          Here are suggestions to improve your privacy score and reduce
          data/compliance risk.
        </Text>
      </View>
      <View style={styles.severities_body_content}>
        {severities.map((severity) => {
          return (
            <View>
              <View
                style={styles.severities_body_item[severity.value]}
                wrap={false}
              >
                <Text style={styles.severities_body_item.text}>
                  {severity.label}
                </Text>
              </View>
              {Object.entries(formattedData[severity.value]).map((details) => (
                <View style={{ marginBottom: 12 }}>
                  <View>
                    <Text
                      style={[styles.thead, { fontWeight: "700", padding: 5 }]}
                      wrap={false}
                    >
                      {SECTION_TITLE[SEVERITY_SECTION_NAME[details[0]]]}
                    </Text>
                    {details[1].map((el) => (
                      <View style={styles.borderBox}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                          wrap={false}
                        >
                          <Text style={{ marginHorizontal: 10 }}>•</Text>
                          <Text style={{ fontWeight: "700", width: "80%" }}>
                            {el.title}
                          </Text>
                        </View>
                        <View>
                          <Text
                            style={[
                              styles.text,
                              {
                                paddingHorizontal: 25,
                                paddingBottom: 5,
                                display: "flex",
                                flexDirection: "row",
                                maxWidth: "100%",
                              },
                            ]}
                          >
                            {ReactHtmlParser(
                              el.description.replaceAll("\n", "\n")
                            )}
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default OpportunitiesPage;
