import React, { useEffect, useState } from "react";

import {
  Typography,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import useParentStyles from "./styles.js";

const getColors = (percentage, color) => {
  const validPercentage = isNaN(percentage) ? 0 : percentage;

  return {
    textColor: percentage === 0 ? color :  '#fff',
    progressBarColor: color
  };
};

const useStyles = makeStyles({
  dynamicText: (props) => ({
    color: props.textColor,
  }),
  dynamicProgressBar: (props) => ({
    backgroundColor: props.progressBarColor,
  }),
  progressBarTextOutside: (props) => ({
    position: 'absolute',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingLeft: '5px',
    paddingBottom: '3px',
    fontSize: 12,
    top: 0,
    left: '10%', 
    color: props.progressBarColor 
  }),
});

export const LinearProgressBar = ({
  percentage,
  text,
  color,
}) => {
  const parentClasses = useParentStyles();
  const [colors, setColors] = useState(getColors(percentage, color));

  useEffect(() => {
    setColors(getColors(percentage, color));
  }, [percentage, color]);

  const classes = useStyles(colors);

  const isTextOutside = percentage < 10 && percentage !== 0; 

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={isNaN(percentage) ? 0 : percentage}
        classes={{
          root: parentClasses.root,
          progressBarColorPrimary: parentClasses.progressBarColorPrimary,
          bar: classes.dynamicProgressBar,
        }}
      />
      <Typography className={
          isTextOutside
            ? `${classes.progressBarTextOutside} `
            : `${parentClasses.progressBarText} ${classes.dynamicText}`
        }>
        {text}
      </Typography>
    </>
  );
};
