import { createMuiTheme } from "@material-ui/core/styles";
import palette from "./palette";
import typography from "./typography";
import shadows from "./shadows";
import overrides from "./overrides";
import sizing from "./sizing";

export default createMuiTheme({
  palette,
  typography,
  shadows,
  overrides,
  sizing,
});
