import React, { useRef } from "react";
import clsx from "clsx";
import { TextField, MenuItem } from "@material-ui/core";
import useStyles from "./styles";

// TODO(@jperozo): Keep DRY for all scenarios
export const SelectItem = ({ classes, option, ...otherProps }) => (
  <MenuItem
    className={classes.menuItem}
    key={option.value}
    value={option.value}
    {...otherProps}
  >
    {option.icon && <div className={classes.icon}>{option.icon}</div>}
    {option.label}
  </MenuItem>
);

const SelectInput = ({
  label,
  placeholder,
  type,
  helperText,
  light = false,
  className = null,
  style = {},
  disabled = false,
  options = [],
  value,
  onChange,
  SelectProps = {},
  error,
  ...restProps
}) => {
  const classes = useStyles({ error, light });
  const inputRef = useRef();

  const handleFocus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <label className={clsx(classes.inputContainer, className)} style={style}>
      <span className={classes.inputLabel} onClick={handleFocus}>
        {label}
      </span>
      <TextField
        id={`zd-${label}`}
        className={classes.textField}
        select
        placeholder={placeholder}
        margin="normal"
        type={type}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
        SelectProps={{
          MenuProps: { classes: { paper: classes.selectMenu } },
          ...SelectProps,
        }}
        InputProps={{
          inputRef: inputRef,
          className: classes.selectContainer,
        }}
        error={error}
      >
        {options.map((option) => (
          <MenuItem
            className={classes.menuItem}
            key={option.value}
            value={option.value}
          >
            {option.icon && <div className={classes.icon}>{option.icon}</div>}
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {helperText && <label className={classes.helperText}>{helperText}</label>}
    </label>
  );
};

export default SelectInput;
