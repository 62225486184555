import React, { useMemo, useState, useEffect } from "react";
import Link from "./LInk";
import { hierarchy, tree } from "d3-hierarchy";
import { min, max } from "d3-array";
import { scaleLinear } from "d3-scale";
import Level from "./Level";
import mergeByGroup from "utils/mergePiiGroups";
import ColorReference from "charts/TreeMap/ColorReference/ColorReference";
import { contrastColor } from "utils/colors";

const Dendrogram = ({
  width,
  height,
  data: rawData = [],
  colors,
  collapsed,
  setCollapsed,
  tooltip,
}) => {
  const [open, setOpen] = useState([]);
  const toggleOpen = (node) => {
    if (node.depth !== 1) return;
    const { name } = node.data;
    if (open.indexOf(name) !== -1) {
      setOpen(open.filter((elem) => elem !== name));
    } else {
      setOpen([...open, name]);
    }
  };

  const data = rawData.length ? rawData[0] : undefined;

  // Variables
  // const radius = width / 2;
  const margin = { top: 20, bottom: 20, left: 120, right: 120 };
  const minRadius =
    0.5 *
    min([
      width - (margin.left + margin.right),
      height - (margin.top + margin.bottom),
    ]);

  // Variables
  const treeGenerator = tree()
    .size([360, minRadius])
    .separation(
      (a, b) =>
        (a.parent === b.parent ? (a.depth === 2 ? 0.5 : 1) : 2) / a.depth
    );

  const root = useMemo(
    () =>
      hierarchy(data, (d) =>
        ["environment", "datasource"].includes(d.type) ? d.children : undefined
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, open]
  );

  root.children.forEach((child) => {
    if (open.indexOf(child.data.name) === -1) {
      child.childrenData = child.children;
      child.children = undefined;
      child.selected = false;
    } else {
      child.selected = true;
    }
  });

  const nodes = useMemo(() => treeGenerator(root), [treeGenerator, root]);

  const levelScale = (nodes, maxRadius) => {
    const maxValue = nodes.reduce((prev, curr) => prev + curr.data.matches, 0);

    return scaleLinear().range([8, maxRadius]).domain([0, maxValue]);
  };

  const domain = useMemo(() => {
    const minValue = Math.min(
      min(data.children, (d) => d.ratio),
      min(data.children, (d) =>
        min(
          d.children.filter((d) => !!d.name),
          (e) => e.ratio
        )
      )
    );

    const maxValue = Math.max(
      max(data.children, (d) => d.ratio),
      max(data.children, (d) =>
        max(
          d.children.filter((d) => !!d.name),
          (e) => e.ratio
        )
      )
    );

    const distance = maxValue - minValue;
    const segment = distance / (colors.length - 1);
    return new Array(colors.length)
      .fill(0)
      .map((_, i) => segment * i + minValue);
  }, [data, colors.length]);

  const minValue = domain[0];
  const maxValue = domain[domain.length - 1];

  const colorScale = scaleLinear().range(colors).domain(domain);

  const dsNames = useMemo(() => root.children.map((d) => d.data.name), [
    root.children,
  ]);

  useEffect(
    () => {
      setOpen(dsNames);
      setCollapsed(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(
    () => {
      if (collapsed) {
        setOpen([]);
      } else {
        setOpen(dsNames);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed]
  );

  const getNodeStyle = (nodes, parentRadius) => (node) => {
    const { ratio } = node.data;
    switch (node.depth) {
      case 0:
        return {
          radius: 60,
          circleFill: "#fff",
          textFill: "black",
          textAnchor: "middle",
          textX: "0",
          strokeWidth: 3,
          textTransform: "lowercase",
        };
      case 1:
        const fillColor = colorScale(ratio);
        return {
          radius: levelScale(nodes, 60)(node.data.matches),
          circleFill: fillColor,
          textFill: contrastColor(fillColor),
          textAnchor: "middle",
          textX: 0,
          strokeWidth: 0,
          textTransform: "capitalize",
        };
      case 2:
        const radius = levelScale(nodes, parentRadius || 50)(node.data.matches);
        return {
          radius,
          circleFill: colorScale(ratio),
          textFill: "#000",
          textAnchor: node.x < 180 ? "start" : "end",
          textX: (node.x < 180 ? 1 : -1) * (radius + 10),
          strokeWidth: 0,
          textTransform: "capitalize",
        };
      default:
        return {};
    }
  };

  const tooltipFn = ({ depth, data, children, childrenData }) => {
    if (depth === 2 && data.children) {
      return data;
    } else if (depth === 1 && (children || childrenData)) {
      return { ...data, children: mergeByGroup(data) };
    }
  };

  const descendants = nodes.descendants();
  const secondLevel = descendants.find((node) => node.depth === 2);
  return (
    <>
      <g
        transform={`translate(${width / 2}, ${height / 2})`}
        style={{ transition: "none" }}
      >
        {descendants.slice(1).map((node, i) => (
          <Link key={i} color="#BFC3D3" node={node} />
        ))}

        {secondLevel && (
          <circle
            fill="none"
            stroke="#BFC3D3"
            cx={0}
            cy={0}
            r={secondLevel.y}
          />
        )}

        <Level
          root={nodes}
          onClick={toggleOpen}
          styles={getNodeStyle}
          tooltipFn={tooltipFn}
          tooltip={tooltip}
          textInside
        >
          <Level />
        </Level>
      </g>
      <ColorReference
        colors={colors}
        position="top"
        width={width}
        min={Math.round(minValue * 100)}
        max={Math.round(maxValue * 100)}
      />
    </>
  );
};

export default Dendrogram;
