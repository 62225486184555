import { Route, Switch } from "react-router-dom";

import PageWrapper from "components/PageWrapper";
import FtuView from "./FtuView"

const FtuContainerView = () => {

  return (
    <PageWrapper subtitle={"Frequently Asked Questions"}>
      <Switch>
        <Route path={"/zen/ftu/:ftu_id"}>
                <FtuView/>
        </Route>
      </Switch>
    </PageWrapper>
  );
};
export default FtuContainerView