import React, { Component } from "react";
import { arc, pie } from "d3";

const MARGIN = 70;

class PieChart extends Component {
  constructor() {
    super();
    this.state = {
      others: false,
    };
  }

  onClickOthers = () => {
    this.setState((prevState) => ({ others: !prevState.others }));

    this.getRange();
  };

  parseData = () => {
    const { data, colors } = this.props;
    const { others } = this.state;

    const total = data.reduce((r, x) => (r += x.value), 0);
    let parsedData = [];
    data
      .sort((a, b) => (a.value < b.value ? 1 : -1))
      .map((x) => ({ ...x, value: (x.value * 100) / total }))
      .forEach((x, i, arr) => {
        if (i < 3 || (i === 3 && arr.length === 4))
          parsedData.push({
            ...x,
            color: colors[i],
            value: !others ? x.value : 0,
          });
        else {
          if (!parsedData[3]) {
            parsedData[3] = {
              ...x,
              label: "Others",
              color: colors[i],
              value: !others ? x.value : 0,
            };
          } else parsedData[3].value += !others ? x.value : 0;

          parsedData.push({
            ...x,
            color: colors[i - 3],
            value: others ? x.value : 0,
          });
        }
      });

    return parsedData;
  };

  draw = () => {
    const { width, height, margin } = this.props;
    const { others } = this.state;
    const data = this.parseData();

    const minSide = height / 2;
    const radius = (height - (margin || MARGIN)) / 2;
    const offsetX = width / 4;
    const offsetY = 0;
    const labelHeight = 40;

    const draw = pie().value((d) => d.value)(data);
    const pathsim = arc()
      .outerRadius(radius)
      .innerRadius(0.8 * radius);
    const pathlabels = arc().outerRadius(radius).innerRadius(radius);

    var closestTo = (goal, points) =>
      points.reduce(function (prev, curr) {
        return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
      });

    var closest = (x, y, range, replace) => {
      let result;
      if (x > 0 && y > 0) {
        result = closestTo(y, range.bottomRight);
        if (range.bottomRight.length > 1 && replace) {
          range.bottomRight.splice(range.bottomRight.indexOf(result), 1);
        }
      } else if (x <= 0 && y > 0) {
        result = closestTo(y, range.bottomLeft);
        if (range.bottomLeft.length > 1 && replace) {
          range.bottomLeft.splice(range.bottomLeft.indexOf(result), 1);
        }
      } else if (x > 0 && y <= 0) {
        result = closestTo(y, range.topRight);
        if (range.topRight.length > 1 && replace) {
          range.topRight.splice(range.topRight.indexOf(result), 1);
        }
      } else if (x <= 0 && y <= 0) {
        result = closestTo(y, range.topLeft);
        if (range.topLeft.length > 1 && replace) {
          range.topLeft.splice(range.topLeft.indexOf(result), 1);
        }
      }

      return result;
    };

    const getLabelProps = (v, range) => {
      const [cx, cy] = pathlabels.centroid(v);
      const newPosition = closest(cx, cy, range, v.data.value !== 0);

      return {
        v,
        transform: [cx > 0 ? offsetX : -offsetX, newPosition],
        anchor: cx > 0 ? "start" : "end",
        line: {
          x1: cx > 0 ? 0 : (-v.data.value.toFixed(0).length - 2) * 7,
          x2: cx > 0 ? (v.data.value.toFixed(0).length + 2) * 7 : 0,
          y1: 0,
          y2: 0,
        },
        arrow:
          cx > 0
            ? [
                [
                  [-20, 0],
                  [cx - offsetX + 20, 0],
                ],
                [
                  [cx - offsetX + 20, 0],
                  [
                    cx - offsetX,
                    cy > 0
                      ? -offsetY + (cy - newPosition)
                      : offsetY + (cy - newPosition),
                  ],
                ],
              ]
            : [
                [
                  [20, 0],
                  [cx + offsetX - 20, 0],
                ],
                [
                  [cx + offsetX - 20, 0],
                  [
                    cx + offsetX,
                    cy > 0
                      ? -offsetY + (cy - newPosition)
                      : offsetY + (cy - newPosition),
                  ],
                ],
              ],
      };
    };

    const getSideRange = (margin, negative = false) => {
      let index = minSide - margin;
      const sign = negative ? -1 : 1;
      const sideRange = [];

      while (index >= 0) {
        sideRange.push(index * sign);
        index = index - labelHeight;
      }
      return sideRange;
    };

    this.getRange = () => {
      this.range = {
        topLeft: getSideRange(30, true),
        topRight: getSideRange(30, true),
        bottomLeft: getSideRange(20),
        bottomRight: getSideRange(20),
      };
    };

    this.getRange();

    const labels = [];
    for (let i = 0; i < draw.length; i++) {
      const labelProps = getLabelProps(draw[i], this.range);
      labels.push(labelProps);
    }

    return {
      arcs: draw.map((v) => (
        <path
          key={`${v.data.label}_arc`}
          d={pathsim(v)}
          opacity={v.data.value > 0 ? 1 : 0}
          fill={v.data.color}
          cursor={v.data.label === "Others" || others ? "pointer" : "auto"}
          onClick={
            v.data.label === "Others" || others ? this.onClickOthers : null
          }
        />
      )),
      labels: labels.map((labelProps) => {
        return (
          <Label
            key={`${labelProps.v.data.label}_label`}
            v={labelProps.v}
            transform={labelProps.transform}
            labelProps={labelProps}
            others={others}
            onClickOthers={this.onClickOthers}
          />
        );
      }),
    };
  };

  render() {
    const { width, height } = this.props;
    if (width && height) {
      const draw = this.draw();

      return (
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {draw.arcs}
          {draw.labels}
        </g>
      );
    }
    return null;
  }
}

export default PieChart;

const Label = ({ v, transform, labelProps, onClickOthers, others }) => (
  <g
    transform={`translate(${transform})`}
    opacity={v.data.value > 0 ? 1 : 0}
    cursor={v.data.label === "Others" || others ? "pointer" : "auto"}
    onClick={v.data.label === "Others" || others ? onClickOthers : null}
    display={v.data.value > 0 ? "initial" : "none"}
  >
    <text
      fontWeight={"bold"}
      dy={-4}
      textAnchor={labelProps.anchor}
      fontSize="12"
    >
      {v.data.value.toFixed(0)}%
    </text>
    <line
      {...labelProps.line}
      style={{ stroke: v.data.color, strokeWidth: 2 }}
    />
    {getPhrases(v.data.label.toUpperCase(), 20).map((label, i) => (
      <text
        key={i}
        fontSize="12"
        fontFamily="Roboto Condensed"
        y={6}
        dy={12 * i + 6}
        textAnchor={labelProps.anchor}
        fill={v.data.label === "Others" ? "#3f51b5" : "#868993"}
        textDecoration={v.data.label === "Others" ? "underline" : undefined}
      >
        {label}
      </text>
    ))}
    {labelProps.arrow.map((a, i) => {
      return (
        <line
          key={i}
          opacity={0.4}
          x1={a[0][0]}
          x2={a[1][0]}
          y1={a[0][1]}
          y2={a[1][1]}
          style={{ stroke: "black", strokeWidth: 1 }}
        />
      );
    })}
  </g>
);

const getPhrases = (text, charsPerPhrase) => {
  const words = text.split(/\s+/);
  const result = [];

  for (let i = 0; i < words.length; i++) {
    const rIndex = result.length - 1;
    if (
      !result.length ||
      result[rIndex].length + words[i].length + 1 > charsPerPhrase
    ) {
      result.push(words[i]);
    } else {
      result[rIndex] = result[rIndex] + " " + words[i];
    }
  }
  return result;
};
