import { Svg, Circle } from "@react-pdf/renderer";

const Point = (color) => (
  <Svg
    width="8px"
    height="8px"
    viewBox="0 0 16.0 16.0"
    version="1.1"
    id="SVGRoot"
  >
    <Circle fill={color} id="path1192" cx="8" cy="8" r="8" />
  </Svg>
);

export default Point;
