import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeForKey } from "router";

import SectionContent from "pages/AppReport/common/SectionContent";
import ZenDataLogo from "assets/illustrations/brand.js";
import Button from "components/Button";

import { Reports } from "../../../ReportList";
import useStyles from "./styles.js";

const DemoReportList = ({ classes, reports }) => (
  <div className={classes.demoReports}>
    <h2 className={classes.demoReportsTitle}>Check how it looks like:</h2>
    <div className={classes.reportsContainer}>
      <Reports
        size="small"
        classes={classes}
        data={reports}
        withTitle={false}
        deviceType="web"
      />
    </div>
  </div>
);

const Complete = ({ flavor, email, origin, data = [] }) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (data.length > 0 && reports.length === 0) {
      setReports(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SectionContent noTitle className="scrollable-element" fullHeight={true}>
      <div className={classes.freeReportPitch}>
        <header className={classes.header}>
          <Link to={routeForKey("privacyScanner")}>
            <ZenDataLogo />
          </Link>
        </header>

        <Button
          href={`/plans?email=${email}&origin=${origin}`}
          className={classes.cta}
        >
          Upgrade to full platform
        </Button>

        <div className={classes.platformBenefits}>
          <h2 className={classes.benefitsTitle}>What's Included</h2>
          <ul className={classes.benefits}>
            <li className={classes.benefit}>
              Breakdown of the drivers of your Privacy Score
            </li>
            <li className={classes.benefit}>
              Analysis Privacy Policy vs. third party processes on your site
            </li>
            <li className={classes.benefit}>
              Privacy analysis at sub-page level
            </li>
            <li className={classes.benefit}>
              Tracker information/cookie info with functionality the fulfill,
              entities they belong to, and the entity's risk profile
            </li>
            <li className={classes.benefit}>
              Report scheduling feature for continuous monitoring
            </li>

            <li className={classes.benefit}>
              <strong>Many more features</strong> to help manage your privacy
              risk
            </li>
          </ul>
        </div>
        <DemoReportList classes={classes} reports={reports} />
      </div>
    </SectionContent>
  );
};

export default Complete;
