import React from "react";
import useStyles from "../../styles";
import ToggleInput from "components/Inputs/Toggle";
import { storages } from "components/PageWrapper/components/FiltersBar/components/constants";
import { connect } from "react-redux";
import { getFilterStorage } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const Storage = (props) => {
  const classes = useStyles();

  const { storage, setStorage } = props;

  return (
    <ToggleInput
      label="Storage"
      options={storages}
      className={classes.filtersInput}
      value={storage}
      onChange={setStorage}
    />
  );
};

const mapStateToProps = (state) => ({
  storage: getFilterStorage(state),
});

const mapDispatchToProps = (dispatch) => ({
  setStorage: (id) => dispatch(selectData(TYPES.STORAGE, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
