import React from "react";
import clsx from "clsx";

import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./styles";

const EmptyOverlay = ({
  children,
  show,
  height = "100%",
  text,
  link = "/zen/settings",
  onClick,
  ctaText = "Check your config",
  hideCta,
}) => {
  const classes = useStyles();

  return (
    <Box
      position="relative"
      height={height}
      className={clsx("empty-overlay-container", classes.overlayContainer)}
    >
      {!show ? (
        children
      ) : (
        <>
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            className={clsx("empty-overlay", classes.overlay)}
          >
            <Box py={3} px={4} className={classes.textContainer}>
              <Typography className={classes.text}>{text}</Typography>
              {!hideCta &&
                (onClick ? (
                  <button onClick={onClick} className={classes.anchor}>
                    {ctaText}
                  </button>
                ) : (
                  <Link className={classes.anchor} to={link}>
                    {ctaText}
                  </Link>
                ))}
            </Box>
          </Box>
          {children}
        </>
      )}
    </Box>
  );
};

export default EmptyOverlay;
