import React, { useCallback } from "react";
import { Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CallMadeIcon from "@material-ui/icons/CallMade";
import LowSeverity from "assets/pii-icons/signal_cellular_alt_1_bar.png";
import MediumSeverity from "assets/pii-icons/signal_cellular_alt_2_bar.png";
import Lekeage from "assets/pii-icons/signal_cellular_alt.svg";
import WebhookBadge from "./WebhookBadge";

const DATAFLOW_URL = "/zen/new-repository-branch-flow/";
function formatCreatedDate(scan) {
  if (!scan || !scan.created) {
    return null;
  }

  const date = new Date(scan.created);
  const formattedDate = date
    .toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(",", " at");

  return { ...scan, created: formattedDate };
}

const LastScanStatusRow = () => {
  const summary = useSelector((state) => state.scanner.repositoryScanSummary);
  const history = useHistory();
  const lastScanSummary = formatCreatedDate(summary?.lastScanSummary);

  const allValuesAreZero = lastScanSummary?.low_severity === 0 && lastScanSummary?.medium_severity === 0 && lastScanSummary?.leakage === 0;

  const handleScanDetails = useCallback(() => {
    if (!allValuesAreZero && lastScanSummary?.id) {
      lastScanSummary?.id && history.push(DATAFLOW_URL + lastScanSummary?.id);
    }
  }, [history, lastScanSummary?.id, allValuesAreZero]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        background: "#F0F0F0",
        padding: 16,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {lastScanSummary ? (
        <>
              <Box style={{ display: "flex", flexDirection: "row", gap: 24 }}>
          <Typography
            style={{
              color: "#466DE8",
              fontWeight: 900,
              fontFamily: "Inter",
              fontSize: 12,
              alignSelf: "center"
            }}
          >
            {lastScanSummary.created}
          </Typography>
          <WebhookBadge triggeredByWebhook={summary?.triggered_by_webhook} /> 
         </Box>
          <Box style={{ display: "flex", flexDirection: "row", gap: 24 }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Aldrich",
                  fontSize: 12,
                  color: "#1B3586",
                }}
              >
                {lastScanSummary.low_severity}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  background: "white",
                  width: "fit-content",
                  borderRadius: 16,
                  alignItems: "center",
                  margin: "0 auto",
                  paddingRight: 8,
                  paddingLeft: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <img
                  id="image1"
                  width="16"
                  height="16"
                  alt=""
                  src={LowSeverity}
                />
                <Typography style={{ color: "#622424", fontSize: 12 }}>
                  Low Severity
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Aldrich",
                  fontSize: 12,
                  color: "#1B3586",
                }}
              >
                {lastScanSummary.medium_severity}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  background: "white",
                  width: "fit-content",
                  borderRadius: 16,
                  alignItems: "center",
                  margin: "0 auto",
                  paddingRight: 8,
                  paddingLeft: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <img
                  id="image2"
                  width="16"
                  height="16"
                  alt=""
                  src={MediumSeverity}
                />
                <Typography style={{ color: "#622424", fontSize: 12 }}>
                  Medium Severity
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Aldrich",
                  fontSize: 12,
                  color: "#1B3586",
                }}
              >
                {lastScanSummary.leakage}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  background: "white",
                  width: "fit-content",
                  borderRadius: 16,
                  alignItems: "center",
                  margin: "0 auto",
                  paddingRight: 8,
                  paddingLeft: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <img id="image3" width="16" height="16" alt="" src={Lekeage} />
                <Typography style={{ color: "#622424", fontSize: 12 }}>
                  Leakage
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            onClick={handleScanDetails}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
              cursor: allValuesAreZero ? "not-allowed" : "pointer",
              opacity: allValuesAreZero ? 0.5 : 1,
            }}
          >
            <Typography
              style={{
                color: "#1B3586",
                fontWeight: 700,
                fontFamily: "Inter",
                fontSize: 14,
              }}
            >
              SCAN DETAILS
            </Typography>
            <CallMadeIcon style={{ color: "#1B3586" }} />
          </Box>
        </>
      ) : (
        <Typography
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            color: "#000000",
            textAlign: "center",
          }}
        >
          No last status information available. Run at least one scan
        </Typography>
      )}
    </Box>
  );
};

export default LastScanStatusRow;
