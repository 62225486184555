import React from "react";

const linkedinIcon = (props) =>
  props.outlined ? (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V20H20V4H4ZM13 9C12.4823 9 11.9353 9.15826 11.4521 9.45215L11 9H10V16H12V12C12 11.4243 12.594 11 13 11H14C14.406 11 15 11.4243 15 12V16H17V12C17 10.1472 15.394 9 14 9H13ZM8 8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8ZM7 9V16H9V9H7Z"
        fill="#3F51B5"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.18381 0C0.863556 0 0 0.855911 0 1.98146C0 3.08249 0.838572 3.96437 2.13259 3.96437H2.15837C3.50419 3.96437 4.34117 3.08265 4.34117 1.98146C4.31581 0.855911 3.50419 0 2.18381 0ZM17.9999 10.4228V17.0001H14.1419V10.8633C14.1419 9.32167 13.5839 8.26957 12.1875 8.26957C11.1214 8.26957 10.487 8.97861 10.208 9.66448C10.1062 9.90967 10.08 10.251 10.08 10.5942V17.0001H6.2207C6.2207 17.0001 6.27268 6.60635 6.2207 5.52949H10.0796V7.1554C10.0759 7.16114 10.0717 7.16709 10.0675 7.17302L10.0675 7.17304C10.0628 7.17966 10.0581 7.18624 10.0542 7.19246H10.0796V7.1554C10.5923 6.37493 11.5079 5.26001 13.5573 5.26001C16.0965 5.25997 17.9999 6.89945 17.9999 10.4228ZM4.08706 16.9999H0.229248V5.5293H4.08706V16.9999Z"
        fill="#3F51B5"
      />
    </svg>
  );

export default linkedinIcon;
