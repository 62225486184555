import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App.js";
import * as serviceWorker from "./serviceWorker";
import "./styles/commons.scss";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
