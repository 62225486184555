import React from "react";
import { StatusBubble, StatusIcon } from "../Status";
import useStyles from "./styles";
import clsx from "clsx";

const Subtitle = ({
  children,
  status,
  confirmed,
  styles,
  classes: parentClasses = {},
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.subtitleContainer, parentClasses.root)}
      style={styles}
    >
      {status && (
        <StatusIcon
          type={status}
          className={clsx(classes.icon, parentClasses.icon)}
        />
      )}
      <h3 className={clsx(classes.subtitle, parentClasses.subtitle)}>
        {children}
      </h3>
      {confirmed !== undefined && (
        <StatusBubble status={confirmed ? "confirmed" : "unconfirmed"} />
      )}
    </div>
  );
};

export default Subtitle;
