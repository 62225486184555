import React, { Component } from "react";

const BOX_SIZE = 20;
const BOX_SEPARATION = 2;
const TOP_OFFSET = 0;
const BOTTOM_OFFSET = 30;
const RIGHT_OFFSET = 50;

class ColorReference extends Component {
  containerProps = () => {
    const { colors, width, height, position } = this.props;
    let y = 0;
    let elWidth = colors.length * (BOX_SIZE + BOX_SEPARATION);

    if (position === "top") {
      y = TOP_OFFSET;
    } else {
      y = height - BOTTOM_OFFSET;
    }

    return {
      width: elWidth,
      height: BOX_SIZE,
      transform: `translate(${width - elWidth - RIGHT_OFFSET}, ${y})`,
    };
  };

  boxes = () => {
    const { colors } = this.props;
    return colors.map((color, idx) => (
      <rect
        key={idx}
        x={idx * (BOX_SIZE + BOX_SEPARATION)}
        width={BOX_SIZE}
        height={BOX_SIZE}
        fill={color}
      />
    ));
  };

  render() {
    const { width, min = 0, max = 100, disabled } = this.props;
    if (width) {
      const container = this.containerProps();
      return (
        <g {...container} opacity={disabled ? 0.25 : 1}>
          <text textAnchor="end" x={-10} dy={"1.1em"} fontWeight="500">
            {min || 0}%
          </text>
          {this.boxes()}
          <text
            textAnchor="start"
            x={container.width + 5}
            dy={"1.1em"}
            fontWeight="500"
          >
            {max || 100}%
          </text>
        </g>
      );
    }
    return null;
  }
}

export default ColorReference;
