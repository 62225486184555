import React from "react";

const PrvLinks = () => {
  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 36C4.125 28.337 10.337 22.125 18 22.125H27C28.0355 22.125 28.875 22.9645 28.875 24C28.875 25.0355 28.0355 25.875 27 25.875H18C12.4081 25.875 7.875 30.4081 7.875 36C7.875 41.5919 12.4081 46.125 18 46.125H27C28.0355 46.125 28.875 46.9645 28.875 48C28.875 49.0355 28.0355 49.875 27 49.875H18C10.337 49.875 4.125 43.663 4.125 36Z"
        fill="#122873"
      />
      <path
        d="M43.125 24C43.125 22.9645 43.9645 22.125 45 22.125H54C61.6629 22.125 67.875 28.337 67.875 36C67.875 43.663 61.6629 49.875 54 49.875H45C43.9645 49.875 43.125 49.0355 43.125 48C43.125 46.9645 43.9645 46.125 45 46.125H54C59.5919 46.125 64.125 41.5919 64.125 36C64.125 30.4081 59.5919 25.875 54 25.875H45C43.9645 25.875 43.125 25.0355 43.125 24Z"
        fill="#122873"
      />
      <path
        d="M22.125 36C22.125 34.9645 22.9645 34.125 24 34.125H48C49.0355 34.125 49.875 34.9645 49.875 36C49.875 37.0355 49.0355 37.875 48 37.875H24C22.9645 37.875 22.125 37.0355 22.125 36Z"
        fill="#122873"
      />
    </svg>
  );
};

export default PrvLinks;
