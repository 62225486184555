import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import isEmpty from "lodash.isempty";
import SvgIcon from "components/svg-icon/svg-icon";
import Loader from "components/Loader";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px 0px",
    height: "calc(100% - 50px)",
  },
  err: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.63,
    color: "red",
    paddingBottom: "8px",
    "&>img": {
      marginRight: "6px",
    },
  },
  noError: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.palette.grey[200],
    paddingBottom: "8px",
  },
}));

const Errors = ({ data, loading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {loading ? (
        <Loader />
      ) : isEmpty(data) ? (
        <Typography className={classes.noError}>
          No errors to report, yeah!{" "}
          <span role="img" aria-label="">
            👏
          </span>
        </Typography>
      ) : (
        data.map((err) => (
          <Typography className={classes.err}>
            <SvgIcon icon="error" />
            {err}
          </Typography>
        ))
      )}
    </Box>
  );
};

export default Errors;
