import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  avatar: {
    border: `2px solid ${theme.palette.white[100]}`,
    boxShadow: `0 0 2px 0 ${theme.palette.grey[200]}`,
    borderRadius: "100%",
    height: theme.spacing(5),
    width: theme.spacing(5),
    overflow: "hidden",
    "& > img": {
      width: "100%",
    },
  },
}));
