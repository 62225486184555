import colors from "./palette";

export default {
  MuiTableHead: {
    root: {
      backgroundColor: colors.grey[500],
      position: "sticky",
      top: 0,
    },
  },
  MuiTableRow: {
    head: {
      position: "sticky",
    },
  },
  MuiTableCell: {
    root: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#333333",
      fontFamily: "Roboto Condensed",
    },
    body: {
      border: "none",
    },
    head: {
      fontSize: "15px",
      fontWeight: 600,
      letterSpacing: "1px",
      color: "#333333",
    },
    sizeSmall: {
      padding: "8px",
    },
    stickyHeader: {
      zIndex: 1,
      backgroundColor: "#f2f2f2",
      fontFamily: "Roboto Condensed",
      fontWeight: "bold",
    },
  },
  MuiSwitch: {
    root: {
      padding: "8px",
      alignItems: "center",
      justifyContent: "center",
    },
    switchBase: {
      borderRadius: "50%",
    },
    colorPrimary: {
      "&.Mui-checked": {
        color: "white",
        "&+.MuiSwitch-track": {
          backgroundColor: "#1a3586",
          opacity: 1,
        },
      },
    },
    track: {
      borderRadius: "14px",
      backgroundColor: "#a4bef1",
      opacity: 1,
    },
  },
  MuiToggleButton: {
    root: {
      height: "auto",
      color: "#4766b6",
      borderColor: "#cfdff8",
      "&.Mui-selected": {
        backgroundColor: "#cfdff8",
        color: "#1a3586",
      },
    },
    label: {
      fontFamily: "Roboto Condensed",
      fontSize: "15px",
      lineHeight: "normal",
      textTransform: "none",
      letterSpacing: "normal",
    },
  },
  MuiToggleButtonGroup: {
    root: {
      width: "100%",
    },
    grouped: {
      padding: "8px",
    },
  },
  MuiFormControl: {
    root: {
      margin: "0px",
      width: "100%",
    },
    marginNormal: {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  MuiSelect: {
    selectMenu: {
      fontFamily: "Roboto Condensed",
      fontSize: "15px",
      color: "#4766b6",
    },
    icon: {
      color: "#7090d9",
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#cfdff8",
      borderRadius: "6px",
    },
    multiline: {
      padding: "8px 1rem",
    },
  },
  MuiAppBar: {
    colorSecondary: {
      backgroundColor: "white",
      color: "#828282",
    },
  },
  MuiTabs: {
    root: {
      minHeight: "0px",
    },
  },
  MuiTab: {
    root: {
      padding: "8px 16px",
    },
    wrapper: {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "normal",
    },
    textColorInherit: {
      minWidth: "0px",
      minHeight: "0px",
      outline: "none !important",
      "&.Mui-selected": {
        color: "#1a3586",
        outline: "none",
      },
    },
  },
  PrivateTabIndicator: {
    colorSecondary: {
      backgroundColor: "#1a3586",
    },
  },
};
