import { useEffect, useState } from "react";
import ScanList, { SCAN_TYPE } from "components/ScanList";
import { connect } from "react-redux";
import { deleteReport, getReports } from "redux/_reports/reports.async.actions";

import Loader from "components/Loader";

import {
  getReportsData as getSingleRunScans,
  getReportsMeta as getSingleRunScansMeta,
} from "redux/_reports/reports.selectors";

import {
  deleteSecurityScans,
  getSecurityData,
  getSecurityScans,
} from "redux/_scans/scans.service";

// TODO: refactor common elements into components/ScanList using
// https://material-ui.com/components/tables/ as inspiration/tool.

const SingleRunSecurityScansList = ({
  goToSection,
  singleRunScans,
  singleRunScansMeta,
  fetchSingleRunScans,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (singleRunScans && singleRunScans.length > 0) setIsLoading(false);
  }, [singleRunScans]);

  const scanListProps = {
    cta: "Create a new scan",
    emptyNotice: "You don't have any security scans yet.",
    headers: [
      { label: "App Name", className: "appName" },
      { label: "Status", className: "status" },
      { label: "Actions", className: "actions" },
    ],
    deleteItem: deleteSecurityScans,
    goToSection,
    data: singleRunScans,
    getItems: fetchSingleRunScans,
    scanType: SCAN_TYPE.security,
    totalPages: singleRunScansMeta?.totalPageCount || 0,
  };

  return isLoading ? <Loader /> : <ScanList {...scanListProps} />;
};

const mapStateToProps = (state) => ({
  singleRunScans: getSingleRunScans(state),
  singleRunScansMeta: getSingleRunScansMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeSingleRunScan: (id) => dispatch(deleteReport(id)),
  fetchSingleRunScans: (filters) => dispatch(getSecurityData(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleRunSecurityScansList);
