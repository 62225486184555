import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

const defaultPagination = {
  pageSize: 6,
  page: 0,
};

export const getReportsData = ({
  isDemo = false,
  ...extra
} = defaultPagination) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/`;
  let separator = "?";

  const queryObj = { isDemo, ...extra };

  url = Object.keys(queryObj).reduce((total, key) => {
    if (queryObj[key]) {
      const uri = `${total}${separator}${key}=${queryObj[key]}`;
      if (separator === "?") {
        separator = "&";
      }

      return uri;
    }

    return total;
  }, url);

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data, meta }) => [data, meta]);
};

export const privacyPolicyFallback = (data) => {
  let url = `${config.apiUrl}/scans/ppa-fallback/`;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetch(url, requestOptions).then(handleFetchResponse);
};

export const privacyPolicyRetry = (data) => {
  let url = `${config.apiUrl}/scans/privacy-policy-retry/`;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return fetch(url, requestOptions).then(handleFetchResponse);
};

export const triggerReport = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/scans/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const fetchReportJSON = (url, formatResponse = true ) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(url, requestOptions)
    .then((response) => handleFetchResponse(response, false, false, formatResponse))
    .then((resp) => (resp.data ? resp.data : resp));
};

export const removeReport = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/scans/${id}/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(() => id);
};

export const getSchedulersData = ({ ...extra }) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/schedulers/`;
  let separator = "?";

  // TODO: DRY this
  const queryObj = { ...extra };

  url = Object.keys(queryObj).reduce((total, key) => {
    if (queryObj[key]) {
      const uri = `${total}${separator}${key}=${queryObj[key]}`;
      if (separator === "?") {
        separator = "&";
      }

      return uri;
    }

    return total;
  }, url);

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then((rawData) => {
      const meta = { count: rawData.data.count };
      return [rawData.data.results, meta];
    });
};

export const scheduleReport = (data) => {
  const { id, resource, ...rest } = data;
  const requestOptions = {
    method: id ? "PUT" : "POST",
    // TODO: make backend accept resource instead of site to standardize with
    // /scans POST
    body: JSON.stringify({ ...rest, site: resource }),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const query = id ? `${id}/` : "";
  const url = `${config.apiUrl}/schedulers/${query}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const removeSchedule = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/schedulers/${id}/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(() => id);
};

export const getReportData = (id, email, extra, hideErrorMessages) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/reports/${id}/`;
  let separator = "?";

  const queryObj = { email, ...extra };

  url = Object.keys(queryObj).reduce((total, key) => {
    if (queryObj[key]) {
      const uri = `${total}${separator}${key}=${queryObj[key]}`;
      if (separator === "?") {
        separator = "&";
      }

      return uri;
    }

    return total;
  }, url);

  return fetch(url, requestOptions)
    .then((response) => handleFetchResponse(response, false, hideErrorMessages))
    .then(({ data }) => data);
};
