import React from "react";

const TrashIcon = () => (
  <svg
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2727 3H11.6364V2.25C11.6364 1.65326 11.4065 1.08097 10.9973 0.659009C10.5882 0.237053 10.0332 0 9.45455 0H6.54545C5.9668 0 5.41185 0.237053 5.00268 0.659009C4.59351 1.08097 4.36364 1.65326 4.36364 2.25V3H0.727273C0.534388 3 0.349404 3.07902 0.213013 3.21967C0.0766233 3.36032 0 3.55109 0 3.75C0 3.94891 0.0766233 4.13968 0.213013 4.28033C0.349404 4.42098 0.534388 4.5 0.727273 4.5H2.18182C2.18182 4.5 2.18182 4.53 2.18182 4.545L2.82909 15.8812C2.8616 16.4549 3.10574 16.9941 3.51144 17.3881C3.91714 17.782 4.45365 18.001 5.01091 18H10.9927C11.55 18.001 12.0865 17.782 12.4922 17.3881C12.8979 16.9941 13.142 16.4549 13.1745 15.8812L13.8182 4.545V4.5H15.2727C15.4656 4.5 15.6506 4.42098 15.787 4.28033C15.9234 4.13968 16 3.94891 16 3.75C16 3.55109 15.9234 3.36032 15.787 3.21967C15.6506 3.07902 15.4656 3 15.2727 3ZM5.81818 2.25C5.81818 2.05109 5.8948 1.86032 6.03119 1.71967C6.16758 1.57902 6.35257 1.5 6.54545 1.5H9.45455C9.64743 1.5 9.83242 1.57902 9.96881 1.71967C10.1052 1.86032 10.1818 2.05109 10.1818 2.25V3H5.81818V2.25ZM11.72 15.795C11.7089 15.986 11.6274 16.1654 11.4922 16.2965C11.357 16.4275 11.1783 16.5003 10.9927 16.5H5.00727C4.82172 16.5003 4.64305 16.4275 4.50785 16.2965C4.37264 16.1654 4.29113 15.986 4.28 15.795L3.63636 4.5H12.3636L11.72 15.795Z"
      fill="#780E18"
    />
  </svg>
);

export default TrashIcon;
