/**
 * Constants for the authentication funcionality
 *
 * @module authentication/constants
 */
export const authenticationConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  ATTEMPTED_TRANSITION: "ATTEMPTED_TRANSITION",
  PLAN_ID: "PLAN_ID",

  LOGOUT: "LOGOUT",

  SESSION: "session",

  LOGIN_ENDPOINT: "/token",
  WF_LOGIN_ENDPOINT: "/wf_login",
  LOGOUT_ENDPOINT: "/api/auth/logout/",
  SIGNUP_ENDPOINT: "/signup",

  RESET_PASSWORD: "/api/auth/password/reset/",
  RESET_CONFIRM_PASSWORD: "/api/auth/password/reset/confirm/",
};
