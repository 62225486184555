import { Text, View } from "@react-pdf/renderer";

const APKIDPage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>APKID Analysis</Text>
      </View>
      <View style={styles.section}>
        {Object.entries(data.apkid).map(([key, value]) => {
          // const anti_vm = Object.entries(value).map((vm) => vm.anti_vm);
          return (
            <View style={styles.dottedBox} key={key} wrap={false}>
              <Text style={styles.classes}>{key}</Text>
              {value.anti_vm && (
                <View>
                  <Text style={styles.antiCode}>Anti-VM Code</Text>
                  <Text style={styles.text}>{value.anti_vm.join("\n")}</Text>
                </View>
              )}
              {value.anti_debug && (
                <View>
                  <Text style={styles.antiCode}>Anti Debug Code</Text>
                  <Text style={styles.text}>{value.anti_debug.join("\n")}</Text>
                </View>
              )}
              {value.anti_disassembly && (
                <View>
                  <Text style={styles.antiCode}>Anti Disassembly Code</Text>
                  <Text style={styles.text}>
                    {value.anti_disassembly.join("\n")}
                  </Text>
                </View>
              )}
              {value.compiler && (
                <View>
                  <Text style={styles.compiler}>Compiler</Text>
                  <Text style={styles.text}>{value.compiler.join("\n")}</Text>
                </View>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default APKIDPage;
