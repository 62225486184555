import React, { useMemo, useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { FormSelectInput, FormTextInput } from "../../components/inputs";
import { Field } from "react-final-form";
import SectionWrapper from "../../components/sectionWrapper";
import DeleteButton from "components/SettingsSections/components/deleteButton";
import { handleFetchResponse } from "utils/handle-fetch-response";
import { useFormState } from "react-final-form";
import { useSnackbar } from "notistack";
import config from "config";
import { authHeader } from "helpers/auth-header";
import { roles } from "components/SettingsSections/Database/constants";
import useStyles from "./styles";

const testConnection = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  let url = `${config.apiUrl}/databases/test_connection`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

const Section1 = (props) => {
  const {
    loading,
    datasources,
    onDeleteClick,
    showDeleteButton,
    validate,
  } = props;

  const classes = useStyles();

  const { values: formData } = useFormState({ subscription: { values: true } });

  const [testing, setTesting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const testDBConnection = () => {
    setTesting(true);
    const { user, password, datasource_id, schema } = formData;
    testConnection({ user, password, datasource_id, schema })
      .then(({ connection_ok: connectionOk }) => {
        setTesting(false);
        if (connectionOk) {
          enqueueSnackbar("Successfully conected!", { variant: "success" });
        } else {
          enqueueSnackbar(
            "Could not connect to database. Please check your credentials.",
            {
              variant: "error",
            }
          );
        }
      })
      .catch(() => {
        setTesting(false);
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      });
  };

  const datasourcesOptions = useMemo(
    () => datasources.map(({ id, name }) => ({ value: id, label: name })),
    [datasources]
  );

  const required = (value) => !value && "Required";

  useEffect(() => {
    const isValid =
      !!formData &&
      !!formData.name &&
      !!formData.datasource_id &&
      !!formData.password &&
      !!formData.schema &&
      !!formData.user;

    validate(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <SectionWrapper
      title="Basic Information"
      subtitle="All fields are required."
      leftContent={showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
    >
      <Box className={classes.row}>
        <Field
          name="name"
          component={FormTextInput}
          label="Name"
          className={classes.fieldSm}
          helperText="Database's Name"
          disabled={loading}
          validate={required}
        />
        <Field
          name="datasource_id"
          label="Datasource"
          component={FormSelectInput}
          helperText="Database's Datasource"
          className={classes.fieldSm}
          disabled={loading}
          options={datasourcesOptions}
        />
        <Field
          name="schema"
          label="Schema"
          component={FormTextInput}
          helperText="Database's connection name"
          className={classes.fieldSm}
          disabled={loading}
        />
      </Box>
      <Box className={classes.row}>
        <Field
          name="user"
          label="Username"
          component={FormTextInput}
          helperText="The user that has access to the db"
          className={classes.fieldSm}
          disabled={loading}
          validate={required}
        />
        <Field
          name="password"
          label="Password"
          type="password"
          component={FormTextInput}
          helperText="Database’s password"
          className={classes.fieldSm}
          disabled={loading}
          validate={required}
        />
        <Button
          className={classes.testButton}
          color="primary"
          onClick={testDBConnection}
          disabled={testing}
        >
          <Typography className={classes.testButtonText}>
            Test Connection
          </Typography>
        </Button>
      </Box>
      <Box className={classes.row}>
        <Field
          name="role"
          label="Entity Role"
          component={FormSelectInput}
          className={classes.fieldSm}
          disabled={loading}
          options={roles}
        />
      </Box>
    </SectionWrapper>
  );
};

export default Section1;
