import clsx from "clsx";
import React from "react";
import { Typography } from "@material-ui/core";
import { Form, Field } from "react-final-form";

import { validateSite } from "utils/scanValidations";

import Button from "components/Button";
import { FormTextInput } from "components/FormComponents/Inputs";

import useStyles from "../styles";

const ConsumerReportPPForm = ({ onSubmit, formError }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          aria-labelledby="customer-flow-form-title"
          onSubmit={handleSubmit}
          className={classes.customerReportFlowForm}
        >
          <Typography
            id="customer-flow-form-title"
            component="h2"
            className={clsx(classes.title, "clear-background")}
          >
            Almost done. Enter the privacy policy link for the report.
          </Typography>
          <Field
            label="Your Privacy Policy URL*"
            name="url"
            validate={validateSite}
            className={classes.input}
            placeholder="example.com/privacy_policy"
            render={(props) => <FormTextInput {...props} />}
          />
          <Button
            type="submit"
            className={clsx(classes.action, classes.mainAction)}
          >
            Run the Scanner
          </Button>
        </form>
      )}
    />
  );
};

export default ConsumerReportPPForm;
