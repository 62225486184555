import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: (styles) => ({
    overflow: "auto",
    height: ({ noTitle }) => `calc(100vh - 308.5px${noTitle ? " + 40px" : ""})`,
    width: "100%",
    ...styles,
    [theme.breakpoints.up("xs")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: ({ noTitle }) =>
        `calc(100vh - 308.5px${noTitle ? " + 40px" : ""})`,
    },
  }),
}));

const SectionContent = ({ children, styles, noTitle = false }) => {
  const classes = useStyles({ ...styles, noTitle });
  return <div className={classes.container}>{children}</div>;
};

export default SectionContent;
