import {
  ORDERED_ANCHORS,
  SECTIONS_CONTENT,
  SECTION_ID,
  SECTION_NAME,
} from "../constants";

export const isHeaderHidden = (location) => {
  return (
    location.hash === `#${SECTION_NAME.complete}` ||
    location.hash === `#${SECTION_NAME.freeReportPitch}`
  );
};

export const extractLastPartFromUrl = (url) => {
  const parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  lastPart = lastPart.replace(".pdf", "");
  return lastPart;
};

export const mapSectionNameToId = (name) => {
  if (name === SECTION_NAME[SECTION_ID.freeReportPitch])
    return SECTION_ID.freeReportPitch;
  return name.charAt(0).toLowerCase() + name.slice(1);
};

export const getSectionsColorsFor = (reportVersion) => {
  const sectionKeys = ORDERED_ANCHORS[reportVersion] || [];
  return sectionKeys
    .map(mapSectionNameToId)
    .map((sectionId) => SECTIONS_CONTENT[sectionId].backgroundColor);
};
