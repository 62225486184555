import React from "react";

const enviroIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9 0H7.87625V2.43243H1.5V16.0541H2.44463V17.027H1.5V18H15.9V17.027H14.961V16.0541H15.9V0ZM7.87625 3.40541H2.44463V15.0811H7.87625V3.40541ZM3.38926 17.027H6.93162V16.0541H3.38926V17.027ZM7.87625 17.027H8.82089V16.0541H7.87625V17.027ZM14.961 0.972973H8.82089V15.0811H14.961V0.972973ZM9.76552 17.027H14.0164V16.0541H9.76552V17.027Z"
      fill="#3F51B5"
    />
    <path
      d="M6.93201 4.86469H3.38965V5.83767H6.93201V4.86469Z"
      fill="#3F51B5"
    />
    <path
      d="M6.93201 7.29737H3.38965V8.27034H6.93201V7.29737Z"
      fill="#3F51B5"
    />
    <path
      d="M6.93201 9.72971H3.38965V10.7027H6.93201V9.72971Z"
      fill="#3F51B5"
    />
    <path
      d="M3.86196 13.1353C4.12282 13.1353 4.33428 12.9175 4.33428 12.6488C4.33428 12.3802 4.12282 12.1624 3.86196 12.1624C3.60111 12.1624 3.38965 12.3802 3.38965 12.6488C3.38965 12.9175 3.60111 13.1353 3.86196 13.1353Z"
      fill="#3F51B5"
    />
    <path
      d="M5.51431 13.1353C5.77516 13.1353 5.98662 12.9175 5.98662 12.6488C5.98662 12.3802 5.77516 12.1624 5.51431 12.1624C5.25345 12.1624 5.04199 12.3802 5.04199 12.6488C5.04199 12.9175 5.25345 13.1353 5.51431 13.1353Z"
      fill="#3F51B5"
    />
    <path d="M14.017 2.43268H9.76611V3.40565H14.017V2.43268Z" fill="#3F51B5" />
    <path d="M14.017 4.86469H9.76611V5.83767H14.017V4.86469Z" fill="#3F51B5" />
    <path d="M14.017 7.29737H9.76611V8.27034H14.017V7.29737Z" fill="#3F51B5" />
    <path d="M14.017 9.72971H9.76611V10.7027H14.017V9.72971Z" fill="#3F51B5" />
    <path
      d="M10.2384 13.1353C10.4993 13.1353 10.7107 12.9175 10.7107 12.6488C10.7107 12.3802 10.4993 12.1624 10.2384 12.1624C9.97758 12.1624 9.76611 12.3802 9.76611 12.6488C9.76611 12.9175 9.97758 13.1353 10.2384 13.1353Z"
      fill="#3F51B5"
    />
    <path
      d="M11.8899 13.1353C12.1507 13.1353 12.3622 12.9175 12.3622 12.6488C12.3622 12.3802 12.1507 12.1624 11.8899 12.1624C11.629 12.1624 11.4176 12.3802 11.4176 12.6488C11.4176 12.9175 11.629 13.1353 11.8899 13.1353Z"
      fill="#3F51B5"
    />
    <path
      d="M13.544 13.1353C13.8049 13.1353 14.0163 12.9175 14.0163 12.6488C14.0163 12.3802 13.8049 12.1624 13.544 12.1624C13.2831 12.1624 13.0717 12.3802 13.0717 12.6488C13.0717 12.9175 13.2831 13.1353 13.544 13.1353Z"
      fill="#3F51B5"
    />
  </svg>
);

export default enviroIcon;
