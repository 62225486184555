import { useSelector } from "react-redux";
import {
  getReportLoadingState,
  getReportJsonsLoadingState,
} from "redux/_reports/reports.selectors";

export const useReportLoading = () => {
  const loading = useSelector(getReportLoadingState);
  const loadingJSONs = useSelector(getReportJsonsLoadingState);

  return loading || loadingJSONs;
};
