import { Hidden, Typography } from "@material-ui/core";
import Button from "components/Button";
import React from "react";

import { ReactComponent as FlashIcon } from "./assets/flash-icon.svg";

import useStyles from "./styles";

const ScansMaxedOutNotice = () => {
  const classes = useStyles();
  return (
    <div className={classes.scansMaxedOutNotice}>
      <Hidden smDown>
        <div className={classes.iconContainer}>
          <FlashIcon className={classes.icon} />
        </div>
      </Hidden>
      <Typography className={classes.message}>
        <strong>You’ve reached your limit of 4 scans this month</strong>.
        Upgrade your plan for unlimited scans with extra functionalities.
      </Typography>
      <Button className={classes.cta} href="/zen/account">
        Upgrade Plan
      </Button>
    </div>
  );
};

export default ScansMaxedOutNotice;
