import CONSTANTS from "redux/_news/news.constants";

const initialState = {
  count: 0,
  results: [],
  next: null,
};

export const news = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.NEWS_SUCCESS:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
