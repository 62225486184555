import React from "react";
import ToggleInput from "components/Inputs/Toggle";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
import { consentCategories } from "components/PageWrapper/components/FiltersBar/components/constants";
import { getFilterConsentCategory } from "redux/_filters/filters.selectors";

const { TYPES } = CONSTANTS;

const ConsentCategory = ({ category, setConsentCategory }) => {
  const classes = useStyles();

  return (
    <ToggleInput
      label="Category"
      options={consentCategories}
      className={classes.filtersInput}
      value={category}
      onChange={setConsentCategory}
    />
  );
};

const mapStateToProps = (state) => ({
  category: getFilterConsentCategory(state),
});

const mapDispatchToProps = (dispatch) => ({
  setConsentCategory: (id) => dispatch(selectData(TYPES.CONSENTS.CATEGORY, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentCategory);
