import React, { useState } from "react";
import clsx from "clsx";
import { Grid, Hidden, Popover } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import InfoIconButton from "components/InfoIconButton";
import ShowMoreText from "react-show-more-text";

import { REPORT_ENV } from "../../constants";
import ScoreChart from "../../components/ScoreChart";
import { bad, avg, good } from "../../utils/colormap";

import "./styles.scss";
import useStyles from "./styles";

const COLOR_LEGEND = [
  { color: good, meaning: "Your site or app passed all tests" },
  {
    color: avg,
    meaning: "Your site or app has some issues you might want to look into",
  },
  {
    color: bad,
    meaning:
      "Your site or app potentially has a lot of issues in this category",
  },
];

export const ColorLegendItems = () => {
  const classes = useStyles();
  return (
    <ul className={classes.colorLegendItems}>
      {COLOR_LEGEND.map(({ color, meaning }) => {
        return (
          <li className={classes.colorLegendItem} key={color}>
            <span
              className={classes.colorLegendItemDot}
              style={{ backgroundColor: color }}
            />
            {meaning}
          </li>
        );
      })}
    </ul>
  );
};

const Content = ({ text, onClick }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
    onClick();
  };

  const maxWordCount = 8;
  const words = text.split(" ");

  const visibleText = expanded
    ? words.join(" ")
    : words.slice(0, maxWordCount).join(" ");

  return (
    <div>
      <p>
        {visibleText}
        {words.length > maxWordCount && (
          <p style={{ cursor: "pointer" }} onClick={toggleExpansion}>
            {expanded ? "Less" : "...More"}
          </p>
        )}
      </p>
    </div>
  );
};

const Item = ({
  title,
  description,
  value,
  className = "",
  reverse = false,
  image = undefined,
  email,
  link,
}) => {
  const classes = useStyles();
  const [hiddenScore, setHiddenScore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "legend-popover" : undefined;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMoreAction = () => {
    setHiddenScore(!hiddenScore);
  };

  const scoreClassName = hiddenScore ? "hidden" : "displayed";

  return (
    <div className={clsx("item")}>
      <div className={`title ${className}`}>
        <span className="title-wrapper">
          {title} {link && <InfoIconButton targetBlank href={link} />}
        </span>
      </div>
      {image ? (
        image
      ) : (
        <>
          <ScoreChart
            aria-owns={open ? id : undefined}
            aria-haspopup="true"
            value={value}
            angle={150}
            className={scoreClassName}
            reverse={reverse}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          />
          <Hidden smDown>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              disableRestoreFocus
              classes={{
                paper: classes.legendPopover,
              }}
              style={{
                pointerEvents: "none",
              }}
            >
              <ColorLegendItems />
            </Popover>
          </Hidden>
        </>
      )}
      <div className={clsx("description", `${scoreClassName}-score`)}>
        <Content text={description} onClick={showMoreAction} />
      </div>
    </div>
  );
};

const TestScores = ({
  email,
  environment = REPORT_ENV.web,
  items,
  hasFooter,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        "test-scores",
        environment,
        !hasFooter && "-no-footer",
        email && "-logged"
      )}
    >
      <Hidden mdUp>
        <figcaption className={classes.colorLegend}>
          <h3 className={classes.colorLegendTitle}>
            <InfoIcon />
            Color meaning
          </h3>
          <ColorLegendItems />
        </figcaption>
      </Hidden>

      <Grid container spacing={4}>
        {items.map((item, index) => (
          <Grid item sm={4} md={3} key={`item${index}`}>
            <Item {...item} email={email} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TestScores;
