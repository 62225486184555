import { Document } from "@react-pdf/renderer";
import styles from "./styles";
import FrontPage from "./FrontPage";
import CustomPage from "./commons/Page";
import moment from "moment";
import ThirdPartyLibrariesPage from "./ThirdPartyLibrariesPage";
import PiiViolationsPage from "./PiiViolationsPage";
import SecretsPage from "./SecretsPage";

const RepositoryPDFReport = ({ branding, data }) => {
  return (
    <Document>
      <FrontPage
        url={data.resource}
        reportCreatedDate={data.version}
        branding={branding}
        content={data}
        styles={styles}
      />
      <CustomPage
        size="A4"
        style={styles.page}
        branding={branding}
        content={data}
        wrap
        title="Repository Scanner"
        subtitle="Report"
        reportLabel={`${data.resource} - ${moment(data.version).format(
          "MMM, YYYY"
        )}`}
      >
        <PiiViolationsPage data={data} styles={styles} />
        <SecretsPage data={data} styles={styles} />
        <ThirdPartyLibrariesPage data={data} styles={styles} />
      </CustomPage>
    </Document>
  );
};

export default RepositoryPDFReport;
