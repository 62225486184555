import React from "react";

const SeverityColor = {
  info: "#466DE8",
  warning: "#EF7D1F",
  medium: "#EF7D1F",
  high: "#D32F2F",
  secure: "#2E7D32",
  good: "#2E7D32",
  low: "#2E7D32",
};

const Severity = ({
  severity,
  label,
  bigger,
  bubbleFormat = true,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={
        bubbleFormat
          ? {
              color: "#ffffff",
              backgroundColor: SeverityColor[severity],
              height: bigger ? "auto" : "20px",
              padding: bigger ? "8px 22px" : "",
              width: bigger ? "auto" : "84px",
              borderRadius: bigger ? "64px" : "13px",
              fontSize: "14px",
              fontFamily: "Inter",
              fontWeight: 400,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: onClick ? "pointer" : "default",
              textTransform: "capitalize",
            }
          : {
              color: SeverityColor[severity],
              display: "inline",
            }
      }
    >
      {label || severity}
    </div>
  );
};

export default Severity;
