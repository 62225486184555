import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  dialog: {
    "&>div": {
      backgroundColor: "rgba(8, 20, 78, 0.5)",
      "&>div": {
        maxWidth: 1013,
        borderRadius: "6px",
        padding: theme.spacing(4),
      },
    },
  },
  sectionContainer: {
    display: "flex",
    width: "100%",
  },
  leftContainer: {
    width: "30%",
    paddingRight: theme.spacing(4),
    borderRight: "1px solid #E0E0E0",
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: 20,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: theme.palette.grey[200],
    padding: theme.spacing(1, 0),
  },
  subtitle: {
    fontFamily: "Inter, sans-serif",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: theme.palette.grey[300],
  },
  rightContainer: {
    width: "70%",
    paddingLeft: theme.spacing(4),
  },
  confirmButton: {
    width: 110,
    height: 40,
    alignSelf: "flex-end",
    backgroundColor: theme.palette.blue["young"],
    fontSize: 15,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.4,
    letterSpacing: 1,
    color: theme.palette.common.white,
    display: "block",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: theme.palette.blue[500],
    },
    "&:disabled": {
      color: theme.palette.common.white,
      opacity: 0.6,
    },
  },
  closeButton: {
    position: "absolute",
    right: 20,
    top: 20,
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  testButton: {
    textTransform: "none",
  },
  testButtonText: {
    fontSize: "15px",
    lineHeight: "22px",
    textDecoration: "underline",
  },
}));
