import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  summary: {
    marginTop: 20,
  },
  subTitle: {
    marginTop: -5,
  },
  legend: {
    fontSize: 11,
  },
  chartRow: {
    justifyContent: "space-between",
  },
});

export default styles;
