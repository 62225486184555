import React from "react";
import { connect } from "react-redux";
import {
  getConsentsSpotlightApplicationsData,
  getConsentsSpotlightApplicationsLoading,
} from "redux/_consents/_spotlight/spotlight.selectors";
import { getSpotlightApplicationsChartData } from "redux/_consents/_spotlight/spotlight.async.actions";
import SpotlightChart from "components/Consents/Analytics/Spotlight/components/spotlightCharts";

const Applications = ({
  applications = [],
  loading,
  colors,
  data,
  getApplicationsData,
  showUnconsents,
}) => {
  return (
    <SpotlightChart
      list={applications}
      data={data}
      getData={getApplicationsData}
      loading={loading}
      colors={colors}
      showUnconsents={showUnconsents}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsSpotlightApplicationsData(state),
  loading: getConsentsSpotlightApplicationsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationsData: (ids) =>
    dispatch(getSpotlightApplicationsChartData(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
