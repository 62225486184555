import React from "react";
import { connect } from "react-redux";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import useStyles from "./styles";
import EmptyIllustration from "assets/illustrations/empty_functional_uses.svg";
import { resetAllFilters } from "redux/_filters/filters.actions";

const EmptyState = ({
  resetFilters,
  customCta,
  minified,
  text = "There is no available data for your current search.",
  ctaText = "Reset filters",
}) => {
  const classes = useStyles();

  const fn = customCta ? customCta : resetFilters;

  if (minified)
    return (
      <Box
        mt={-2}
        mb={-1}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.subtitle}>{text}</Typography>
        <Button className={classes.mapButton} onClick={fn}>
          {ctaText}
        </Button>
      </Box>
    );

  return (
    <div className={classes.container}>
      <Grid container spacing={5}>
        <Grid className={classes.leftContent} item xs={7}>
          <img src={EmptyIllustration} alt="" />
        </Grid>
        <Grid className={classes.rightContent} item xs={5}>
          <Typography className={classes.title}>Hi there!</Typography>
          <Typography className={classes.subtitle}>{text}</Typography>
          <Button className={classes.mapButton} onClick={fn}>
            {ctaText}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => dispatch(resetAllFilters()),
});

export default connect(null, mapDispatchToProps)(EmptyState);
