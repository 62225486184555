import LoginLayout from "components/login/LoginLayout";
import Login from "components/login/login.container";
import React from "react";

const SignInSide = () => {
  return (
    <LoginLayout
      simple
      title="Welcome back"
      message="Complete the following form to log in."
      form={<Login simple />}
    />
  );
};

export default SignInSide;
