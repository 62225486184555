import React from "react";
import { Box, Checkbox as MUICheckbox } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import isEmpty from "lodash.isempty";

const Checkbox = (props) => {
  const {
    value,
    text,
    label = "",
    style = {},
    error,
    helperText,
    ...restprops
  } = props;
  const classes = useStyles({ error });

  return (
    <Box style={style}>
      {!isEmpty(label) && <span className={classes.inputLabel}>{label}</span>}
      <label style={{ marginBottom: 0 }}>
        <MUICheckbox
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          checked={value}
          error={error}
          {...restprops}
        />
        <span className={classes.inputText}>{text}</span>
      </label>
    </Box>
  );
};

export default Checkbox;
