import { makeStyles } from "@material-ui/core/styles";

const arrowsStyle = {
  border: "1px solid #B0BAC9",
  boxSizing: "border-box",
  borderRadius: "5px",
  width: "32px",
  padding: "7px",
};

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: ({ marginTop }) =>
      marginTop ? theme.spacing(marginTop) : theme.spacing(2),
    "& > div": {
      width: "120px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& > .before": {
        ...arrowsStyle,
      },
      "& > .pages": {
        width: "50%",
        display: "flex",
        justifyContent: "space-evenly",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.25px",
        color: "#686C78",
      },
      "& > .after": {
        ...arrowsStyle,
      },
    },
  },
}));

export default useStyles;
