import { createSelector } from "reselect";

export const getDatasourcesData = (state) => state.datasources.data;
export const getDatasourcesLoadingState = (state) => state.datasources.loading;

export const getFilteredDatasourcesData = createSelector(
  [getDatasourcesData],
  (datasourcesData) => {
    const dsFilterPrefix = /(ds-slack|ds-salesforce)/gi;
    return datasourcesData.filter(
      (ds) => ds.name.search(dsFilterPrefix) === -1
    );
  }
);
