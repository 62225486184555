import React from "react";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";

const ArrowSVG = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z"
      fill="#37DCCE"
    />
  </svg>
);

const Footer = ({
  title,
  backgroundColor,
  className,
  goToNextSectionAction,
}) => {
  const classes = useStyles({ backgroundColor });
  return (
    <div className={`${classes.footer} ${className}`}>
      <Button
        className={`${classes.button} footer`}
        variant="outlined"
        onClick={goToNextSectionAction}
      >
        <ArrowSVG className={classes.arrow} />
        {title || "continue"}
      </Button>
    </div>
  );
};

export default Footer;
