import React from "react";

export default () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.89665 25.1546C3.20042 25.1535 2.51716 24.9662 1.91771 24.612C1.31827 24.2579 0.82447 23.7499 0.487516 23.1406C0.150563 22.5313 -0.0172753 21.843 0.0014054 21.147C0.0200861 20.4511 0.224607 19.7727 0.593757 19.1824L7.61502 7.96959C8.16427 7.09254 9.03942 6.4696 10.048 6.23781C10.5473 6.12304 11.0644 6.10775 11.5697 6.19282C12.075 6.27789 12.5586 6.46165 12.9929 6.73361C13.4271 7.00557 13.8036 7.3604 14.1007 7.77784C14.3979 8.19529 14.6099 8.66717 14.7246 9.16655C14.8394 9.66593 14.8547 10.183 14.7696 10.6883C14.6846 11.1936 14.5008 11.6772 14.2288 12.1114L7.20597 23.3243C6.85502 23.8851 6.36709 24.3474 5.78814 24.6677C5.20919 24.9879 4.55826 25.1554 3.89665 25.1546V25.1546Z"
      fill="#FF3D57"
    />
    <path
      d="M15.9968 25.1547C15.3 25.1548 14.616 24.9683 14.0156 24.6146C13.4153 24.2609 12.9205 23.753 12.5828 23.1435C12.2451 22.5341 12.0767 21.8453 12.0951 21.1488C12.1135 20.4522 12.3181 19.7733 12.6875 19.1826L19.7136 7.9697C19.9741 7.51255 20.3244 7.11282 20.7434 6.79456C21.1624 6.4763 21.6415 6.24609 22.1517 6.11778C22.662 5.98946 23.193 5.9657 23.7127 6.04792C24.2324 6.13014 24.7301 6.31664 25.1759 6.5962C25.6216 6.87576 26.0062 7.2426 26.3065 7.67466C26.6068 8.10672 26.8166 8.59505 26.9233 9.1103C27.03 9.62554 27.0313 10.157 26.9273 10.6728C26.8232 11.1886 26.6159 11.678 26.3178 12.1116L19.3045 23.3244C18.9536 23.8853 18.4657 24.3476 17.8867 24.6678C17.3077 24.988 16.6568 25.1555 15.9952 25.1547H15.9968Z"
      fill="#FFCB00"
    />
    <path
      d="M28.0932 25.1528C30.2513 25.1528 32.0009 23.4471 32.0009 21.343C32.0009 19.2389 30.2513 17.5332 28.0932 17.5332C25.9351 17.5332 24.1855 19.2389 24.1855 21.343C24.1855 23.4471 25.9351 25.1528 28.0932 25.1528Z"
      fill="#00D647"
    />
  </svg>
);
