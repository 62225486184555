import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PiiGroupsTooltip from "charts/PieCharts/PiiGroupsTooltip";
import isEmpty from "lodash.isempty";
import MUITooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2),
    borderRadius: "10px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    minWidth: "205px",
    minHeight: "320px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
  },
  piiCategory: {
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    lineHeight: 1.83,
    color: theme.palette.grey[200],
  },
}));

const getPiiGroups = (data) => {
  if (!data) return;

  let groups = {};
  data.children.forEach(
    ({ name, matches }) => (groups = { ...groups, [name]: matches })
  );
  return groups;
};

const getPiiCategories = (data) => {
  if (!data) return;

  const piiGroups = data.children || [];

  let groupsCategories = [];

  if (!isEmpty(piiGroups)) {
    piiGroups.forEach(({ children }) =>
      children.forEach(({ name, matches }) =>
        groupsCategories.push({
          name,
          percentage: ((matches / data.matches) * 100).toFixed(2),
        })
      )
    );
  }

  return groupsCategories.sort(
    ({ percentage: p1 }, { percentage: p2 }) => p2 - p1
  );
};

const Tooltip = (props) => {
  const classes = useStyles();
  const { data, children } = props;

  const piiGroups = useMemo(() => getPiiGroups(data), [data]);

  const piiCategories = useMemo(() => getPiiCategories(data), [data]);

  return (
    <MUITooltip
      classes={{ tooltip: classes.root, touch: classes.touch }}
      placement="right"
      title={
        !data ? (
          ""
        ) : (
          <Box className={classes.container}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" className={classes.title}>
                {data.name}
              </Typography>
              <Typography variant="h4" className={classes.title}>
                PII COLUMNS {data.columns_pii} / {data.columns}
              </Typography>
            </Box>

            <Box className={classes.content}>
              <Typography className={classes.subtitle}>PII GROUPS</Typography>
              <PiiGroupsTooltip data={piiGroups} />
            </Box>
            <Box className={classes.content}>
              <Box
                className={classes.subtitleContainer}
                style={{ paddingBottom: "8px" }}
              >
                <Typography className={classes.subtitle} display="inline">
                  PII CATEGORIES
                </Typography>
                <Typography className={classes.subtitle} display="inline">
                  %
                </Typography>
              </Box>
              {piiCategories.map(({ name, percentage }) => (
                <Box key={name} className={classes.subtitleContainer}>
                  <Typography className={classes.piiCategory} display="inline">
                    {name}
                  </Typography>
                  <Typography className={classes.piiCategory} display="inline">
                    {percentage}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )
      }
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
