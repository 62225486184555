import { Link } from 'react-router-dom';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
  } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import useParentStyles from "./styles.js";

export const ArrowBackThirdPartyTrackers = ({
    reportId,
  }) => {
    const parentClasses = useParentStyles();
  
    return (
        <Box className={parentClasses.arrowCellContainer}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={parentClasses.arrowCell}>
                            <Link
                                className={parentClasses.link}
                                exact="true"
                                target="_top"
                                to={`/privacy-report?r=${reportId}&c=web#Trackers`}
                            >
                                <ArrowBack className={parentClasses.arrowBack} />
                                Third Party Trackers
                            </Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
  };