import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  main: {
    background: "#fff",
  },
  mainWrapper: {
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      width: "80%",
      margin: "auto",
      maxWidth: 1300,
    },
  },
}));
