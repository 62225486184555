const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  warning: {
    width: "22px",
    height: "22px",
    color: "#ED6C02",
  },
  confirm: {
    width: "26px",
    height: "26px",
    color: "#468C49",
  },
  bubble: {
    cursor: "default",
    width: "auto",
    height: "34px",
    padding: "8px 16px 8px 16px",
    borderRadius: "64px",
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },
  confirmed: {
    border: "2px solid #468C49",
    color: "#468C49",
  },
  unconfirmed: {
    border: "2px solid #868993",
    color: "#868993",
  },
}));

export default useStyles;
