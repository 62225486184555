import { useState, useEffect } from "react";
import axios from "axios";
import repoProviderUtils from "helpers/repoProviderUtils.js";

export function useGitLabBranchFetch() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleData = (rawData) => {
    return rawData?.map((value) => value.name) || [];
  };

  const getHeadersConfig = (token) => {
    if (token) {
      return {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      };
    } else {
      return {
        headers: {
          Accept: "application/json",
        },
      };
    }
  };
  const fetchData = (url, privateToken) => {
    setLoading(true);
    axios
      .get(
        repoProviderUtils.sanitizeGitLabUrl(url),
        getHeadersConfig(privateToken)
      )
      .then((response) => {
        const filteredData = handleData(response.data);
        setData(filteredData);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, fetchData };
}
