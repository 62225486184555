import React from "react";
import { Box, Typography, Chip } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { routePaths } from "router/paths";
const WebHookCard = (props) => {
  const { isActive } = props;
  const history = useHistory();
  const { repo_id } = useParams();

  const handleClick = () => {
    history.push(`${routePaths.REPO_SCANNER_WEBHOOK_SETTINGS}/${repo_id}`);
  };

  const renderChip = () => (
    <Chip
      label={isActive ? "ACTIVE" : "INACTIVE"}
      style={{
        backgroundColor: isActive ? "#6FCF97" : "#F7B481",
        position: "absolute",
        right: "16px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer"
      }}
    />
  );

  const renderTypography = (text, customStyles = {}) => (
    <Typography
      style={{
        color: "#000000",
        fontFamily: "Inter",
        fontSize: 16,
        ...customStyles,
      }}
    >
      {text}
    </Typography>
  );

  const renderBranchNames = () => (
    <Typography
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 200,
        color: "#000000",
        fontFamily: "Inter",
        fontSize: 16,
      }}
    >
      Main, Develop, Staging
    </Typography>
  );

  return (
    <div
      onClick={handleClick}
      style={{ cursor: "pointer", position: "relative" }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 10,
          background: "#F7F7F7",
          gap: 8,
          padding: 16,
          position: "relative",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Typography
            style={{
              color: "#1B3586",
              fontFamily: "Inter",
              fontSize: 16,
              fontWeight: 900,
              flex: 1,
            }}
          >
            Custom WebHook
          </Typography>
        </Box>
        {renderChip()}
        {/* <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
              flex: 1,
            }}
          >
            {renderTypography("Branches:")}
            {renderBranchNames()}
          </Box>
        </Box>*/}
      </Box>
    </div>
  );
};

export default WebHookCard;
