import React, { Component } from "react";
import { hierarchy, partition, arc } from "d3";
import BurstPath from "./BurstPath";

const SIDE_PROPORTION = 1;

const getNodeKey = (node) => {
  if (node.parent) return `${getNodeKey(node.parent)}>${node.data.name}`;
  return node.data.name;
};

class Sunburst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onNodeClick = (node) => {
    const { selected } = this.state;
    if (selected && selected.key === node.key) {
      if (node.depth === 1) this.setState({ selected: null });
      else this.setState({ selected: node.parent });
    } else if (node.depth <= 3) this.setState({ selected: node });
  };

  draw = () => {
    const { width, height, colors, owners } = this.props;
    const data = this.props.data.map((database) => {
      return {
        ...database,
        children: database.children.filter((user) =>
          owners.includes(user.name)
        ),
      };
    });
    const { selected } = this.state;
    const side = Math.min(width, height) * SIDE_PROPORTION;
    const radius = side / 2;

    const getPalette = (node) => {
      if (node.data.type === "table_owner") {
        const index = this.props.tableOwners.findIndex(
          (x) => x === node.data.name
        );
        return colors[index % colors.length];
      } else if (node.parent) return getPalette(node.parent);
      return [];
    };

    const getColor = (node) => {
      if (node.data.type === "database") return "#F2F2F2";
      const palette = getPalette(node);
      if (node.data.type === "table_owner") return palette[0];
      if (node.data.type === "table") return palette[1];
      if (node.data.type === "column") return palette[2];
      return "transparent";
    };

    const included_keys = selected
      ? selected.descendants().map((x) => x.key)
      : [];

    const root = hierarchy(
      {
        name: "root",
        children: data,
        type: "root",
      },
      function (d) {
        if (["root", "database", "table_owner", "table"].includes(d.type)) {
          return d.children;
        }
      }
    )
      .each((node) => {
        node.key = getNodeKey(node);
        node.color = getColor(node);
        if (node.data.type !== "column") {
          node.data.matches = undefined; //fix to partially covered data, only allow the last level to have a value
        }

        if (selected && node.key === selected.key) {
          node.selected = true;
        }
        if (selected && !included_keys.includes(node.key)) {
          node.data.value = 0;
          node.visible = false;
        } else {
          node.visible = true;
          node.data.value = node.data.matches;
        }
      })
      .sum((d) => {
        return d.value;
      })
      .sort((a, b) => (b.key > a.key ? 1 : -1));

    const setup = partition().size([2 * Math.PI, 1]);

    const points = setup(root).descendants();

    const radiusOffset = (-points[0].y1 * radius) / 4;

    const arcF = arc()
      .startAngle((d) => 0)
      .endAngle((d) => d.x1 - d.x0)
      .innerRadius(
        (d) =>
          d.y0 * radius +
          radiusOffset -
          (selected ? selected.depth * 0.2 * radius : 0)
      )
      .outerRadius(
        (d) =>
          d.y1 * radius +
          radiusOffset -
          (selected ? selected.depth * 0.2 * radius : 0)
      );

    const radToGrad = (rad) => {
      return (rad * 180) / Math.PI - 90;
    };

    points.forEach((node) => {
      node.t0 = radToGrad(node.x0);
      node.t1 = radToGrad(node.x1);

      node.labelInvert = node.x0 > Math.PI;
      node.labelRotation =
        -90 + (node.t1 - node.t0) / 2 + (node.labelInvert ? -2 : 2);
      node.labelOffset =
        (node.labelInvert ? node.y1 : node.y0) * radius +
        radiusOffset +
        (node.labelInvert ? -5 : 5) -
        (selected ? selected.depth * 0.2 * radius : 0);
      if (node.selected) node.labelOffset = 0;
      node.labelShow = node.x1 - node.x0 > Math.PI / 32;
      node.arc = arcF(node);

      node.opacity = node.visible ? 1 : 0;
    });

    return points
      .filter((node) => node.depth !== 0)
      .filter((node) => node.data.type !== "column" || node.data.matches > 0)
      .sort((a, b) => (a.key > b.key ? 1 : -1))
      .map((node) => (
        <BurstPath key={node.key} onClick={this.onNodeClick} node={node} />
      ));
  };

  shouldComponentUpdate(prevProps) {
    if (this.props.data) return true;
    if (prevProps.owners !== this.props.owners) return true;
    return false;
  }

  render() {
    const { width, height } = this.props;
    if (width && height)
      return (
        <>
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            {this.draw()}
          </g>
        </>
      );
    return null;
  }
}

export default Sunburst;
