import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  privacySection: {
    marginTop: 20,
  },
  title: {
    marginBottom: 0,
  },
});

export default styles;
