import { Text, View } from "@react-pdf/renderer";

const PiiViolationsPage = ({ styles, data }) => {
  const filteredPii = Object.keys(data.pii).reduce((obj, key) => {
    const filteredArray = data.pii[key].filter(
      (item) => item.type === "insensitive_pii" || item.type === "sensitive_pii"
    );
    if (filteredArray.length > 0) {
      obj[key] = filteredArray;
    }
    return obj;
  }, {});

  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.title}>PII VIOLATIONS</Text>
      </View>
      <View>
        <View>
          {filteredPii ? (
            <View>
              {Object.entries(filteredPii).map(([key, value]) => {
                return (
                  <View>
                    <View style={styles.thead} wrap={false}>
                      <View style={styles.tr}>
                        <View style={styles.thCustomWidth}>
                          <Text style={styles.text}>{key}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.table}>
                      <View style={styles.thead} fixed>
                        <View style={styles.tr}>
                          <View
                            style={[styles.thCustomWidth, { width: "15%" }]}
                          >
                            <Text style={styles.text}>KEYWORD</Text>
                          </View>
                          <View
                            style={[styles.thCustomWidth, { width: "10%" }]}
                          >
                            <Text style={styles.text}>PII</Text>
                          </View>
                          <View
                            style={[styles.thCustomWidth, { width: "10%" }]}
                          >
                            <Text style={styles.text}>LINE NUMBER</Text>
                          </View>
                          <View
                            style={[styles.thCustomWidth, { width: "65%" }]}
                          >
                            <Text style={styles.text}>OCCURRENCE</Text>
                          </View>
                        </View>
                      </View>
                      {Object.entries(value).map(([key, value]) => {
                        return (
                          <View>
                            <View style={styles.tr} key={key}>
                              <View
                                style={[styles.tdCustomWidth, { width: "15%" }]}
                              >
                                <Text style={styles.text}>{value.keyword}</Text>
                              </View>
                              <View
                                style={[styles.tdCustomWidth, { width: "10%" }]}
                              >
                                <Text style={styles.text}>
                                  {value.pii_name}
                                </Text>
                              </View>
                              <View
                                style={[styles.tdCustomWidth, { width: "10%" }]}
                              >
                                <Text style={styles.text}>
                                  {value.line_number}
                                </Text>
                              </View>
                              <View
                                style={[styles.tdCustomWidth, { width: "65%" }]}
                              >
                                <Text style={styles.text}>{value.context}</Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          ) : (
            <View style={[styles.tdCustomWidth]}>
              <Text style={styles.text}>No pii violations were found.</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default PiiViolationsPage;
