import CONSTANTS from "./plans.constants";

const initialState = {
  loading: false,
  data: {},
};

export const plans = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SET_PLAN_DATA:
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};
