import { Box, Button, CircularProgress } from "@material-ui/core";
import {
  FormSelectInput,
  FormTextInput,
} from "components/FormComponents/Inputs";
import React from "react";
import { Field, Form } from "react-final-form";
import countryCodes from "./countryCodes.json";
import useStyles from "./styles";

const RegisterForm = ({ onSubmit, loading, invitation }) => {
  const classes = useStyles();
  const isInvitation = !!invitation;
  const validatePasswords = ({ password, password2 }) =>
    password && password !== password2
      ? { password2: "Passwords do not match." }
      : undefined;
  return (
    <Form onSubmit={onSubmit} validate={validatePasswords}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" className={classes.field}>
            <Field
              name="first_name"
              component={FormTextInput}
              label="First Name *"
              style={{ marginRight: 16 }}
              placeholder="John"
              disabled={loading}
            />
            <Field
              name="last_name"
              component={FormTextInput}
              label="Last Name *"
              placeholder="Doe"
              disabled={loading}
            />
          </Box>
          <Box display="flex" className={classes.field}>
            <Field
              name="company"
              component={FormTextInput}
              label="Company Name"
              placeholder="Your Company"
              style={{ marginRight: 16 }}
              disabled={loading || isInvitation}
              initialValue={invitation && invitation.company}
            />
            <Field
              name="email"
              component={FormTextInput}
              label="Company Email *"
              placeholder="samplemail@mail.com"
              disabled={loading || isInvitation}
              initialValue={invitation && invitation.email}
            />
          </Box>
          <Box display="flex" className={classes.field}>
            <Field
              name="area_code"
              component={FormSelectInput}
              label="Area Code"
              style={{ marginRight: 16 }}
              options={countryCodes
                .filter((country) => !!country.dial_code)
                .map((country) => ({
                  value: country.dial_code,
                  label: country.dial_code,
                }))}
              disabled={loading}
            />
            <Field
              name="phone"
              component={FormTextInput}
              label="Phone"
              style={{ flexBasis: "100%" }}
              placeholder="(555) 555-1234"
              disabled={loading}
            />
          </Box>
          <Box display="flex" className={classes.field}>
            <Field
              name="password1"
              component={FormTextInput}
              label="Password *"
              type="password"
              style={{ marginRight: 16 }}
              placeholder="********"
              disabled={loading}
            />
            <Field
              name="password2"
              component={FormTextInput}
              label="Confirm Password *"
              type="password"
              placeholder="********"
              disabled={loading}
            />
          </Box>
          <Button disabled={loading} className={classes.button} type="submit">
            Sign Up
            {loading && (
              <CircularProgress
                color="inherit"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </form>
      )}
    </Form>
  );
};

export default RegisterForm;
