import React, { useState, useRef, useEffect } from "react";
import { TextField, Box, Typography } from "@material-ui/core";
import { Field } from "react-final-form";
import useStyles from "./styles";
import InputContainer from "components/Consents/Collector/Configuration/components/InputContainer";
import { SketchPicker } from "react-color";

const ColorField = ({
  input: { value, onChange },
  meta,
  label,
  loading,
  onChangeState,
  onChangeError,
  ...restProps
}) => {
  const classes = useStyles();
  const error =
    (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
    meta.touched &&
    String(meta.error || meta.submitError);
  const inputElement = useRef();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const onColorClick = () => setShowColorPicker(!showColorPicker);

  const onPickerChange = (color) => onChange(color.hex.replace("#", ""));

  const handleChange = (e) => {
    onChange(e.target.value.replace("#", ""));
  };

  useEffect(() => {
    if (onChangeState) onChangeState(meta.dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.dirty]);

  useEffect(() => {
    onChangeError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Box
        className={classes.colorShowcase}
        style={{ backgroundColor: `#${value}` }}
        onClick={onColorClick}
      />
      {showColorPicker ? (
        <div className={classes.pickerContainer}>
          <div className={classes.picker} onClick={onColorClick} />
          <SketchPicker color={value} onChange={onPickerChange} />
        </div>
      ) : null}
      <TextField
        onChange={handleChange}
        value={`#${value}`}
        error={error}
        classes={{ root: classes.inputContainer }}
        InputProps={{
          className: classes.inputText,
        }}
        inputProps={{
          maxLength: 7,
          className: classes.input,
          ref: inputElement,
        }}
        disabled={loading}
        {...restProps}
      />
    </>
  );
};

const ColorsInput = (props) => {
  const { label, loading, onSubmit } = props;
  const classes = useStyles();
  const [hasChanged, setHasChanged] = useState([false, false]);
  const [hasError, setHasError] = useState(false);

  return (
    <InputContainer
      label={label}
      buttonText="Save"
      disableButton={loading || !(hasChanged[0] || hasChanged[1])}
      onClick={onSubmit}
      error={hasError}
    >
      <Box className={classes.container}>
        <Box className={classes.colorContainer}>
          <Typography className={classes.text}>Main</Typography>
          <Field
            name="main"
            component={ColorField}
            loading={loading}
            parse={(value) => value}
            onChangeState={(dirty) => setHasChanged([dirty, hasChanged[1]])}
            onChangeError={setHasError}
          />
        </Box>
        <Box className={classes.colorContainer}>
          <Typography className={classes.text}>Links</Typography>
          <Field
            name="links"
            component={ColorField}
            loading={loading}
            parse={(value) => value}
            onChangeState={(dirty) => setHasChanged([hasChanged[0], dirty])}
            onChangeError={setHasError}
          />
        </Box>
      </Box>
    </InputContainer>
  );
};

export default ColorsInput;
