import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    flexDirection: ({ scanType }) =>
      scanType === "scheduled" ? "row" : "column",
  },
  menuButton: {
    width: 222,
  },
}));
