import React, { useState } from "react";
import {
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import SVGIcon from "components/svg-icon/svg-icon";
import { states } from "components/Admin/Users/constants";
import useStyles from "./styles";
import {
  resendInvitation,
  removeUser,
} from "redux/_admin/_users/users.async.actions";
import { getUserUpdateLoadingState } from "redux/_admin/_users/users.selectors";

const AlertDialog = ({ open, handleClose, handleSubmit }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Remove Invitation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You can resend this invitation whatever you want in the future.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserRow = ({
  id,
  name,
  email,
  state,
  role,
  you,
  profilePicture,
  update,
  resend,
  remove,
  loading,
  invitationKey,
}) => {
  const classes = useStyles({ state });

  const [open, setOpen] = useState(false);

  const getState = (value) => {
    const found = states.find((state) => state.value === value);
    if (found) return found.label;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResend = () => {
    resend(invitationKey);
  };

  const handleRemove = () => {
    handleClose();
    remove(invitationKey);
  };

  return (
    <tr className={classes.tr}>
      <td>
        <Box className={classes.nameContainer}>
          <Avatar src={profilePicture} className={classes.avatar}>
            {!profilePicture && "?"}
          </Avatar>
          <Box>
            {name && (
              <div className={classes.name}>
                {name}
                {you && <Box className={classes.badge}>YOU</Box>}
              </div>
            )}
            <div className={classes.email}>{email}</div>
          </Box>
        </Box>
      </td>
      <td>
        <Box className={classes.status}>
          <Typography className={classes.statusText}>
            {getState(state)}
          </Typography>
        </Box>
        {state === "P" && (
          <>
            <Button onClick={handleResend} className={classes.resendButton}>
              <SVGIcon icon="arrowRotate" style={{ marginRight: 3 }} />
              Resend
            </Button>
            <Button onClick={handleClickOpen} className={classes.removeButton}>
              <SVGIcon icon="trash" style={{ marginRight: 3 }} />
              Remove
            </Button>
            <AlertDialog
              open={open}
              handleClose={handleClose}
              handleSubmit={handleRemove}
            />
          </>
        )}
      </td>
      <td>
        {loading && (
          <CircularProgress color="primary" size={24} style={{ margin: 8 }} />
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state, props) => ({
  loading: getUserUpdateLoadingState(state, props.id),
});

const mapDispatchToProps = (dispatch) => ({
  resend: (id) => dispatch(resendInvitation(id)),
  remove: (id) => dispatch(removeUser(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserRow);
