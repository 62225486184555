import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import useStyles from "./styles";
import { getConfig } from "redux/_consents/_config/config.async.actions";
import { getConsentsConfig } from "redux/_consents/_config/config.selectors";
import useQuery from "hooks/useQuery";

const Preview = ({ initialPage = "home", data, config, getConfig }) => {
  const [renderId, setRenderId] = useState(0);
  const classes = useStyles();
  const query = useQuery();

  useEffect(() => {
    const reportId = query.get("r");
    getConfig(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRenderId(renderId + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initialPage]);

  return (
    <iframe
      title="Consents widget"
      key={renderId}
      className={classes.iframe}
      src={`/consent-collector/index.html?initial=${initialPage}&token=${config.token}`}
    ></iframe>
  );
};

const mapStateToProps = (state) => ({
  config: getConsentsConfig(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConfig: (x) => dispatch(getConfig(x)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
