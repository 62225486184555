/* eslint-disable jsx-a11y/anchor-is-valid */
import isObject from "lodash.isobject";
import isArray from "lodash.isarray";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { Box, CircularProgress } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";

import Button from "components/Button";
import TextInput from "components/Inputs/Text";

import { ErrorList, noOp } from "./login-form";
import useStyles from "./styles";

const ResetPasswordForm = ({
  isSending = false,
  email,
  updateEmail = noOp,
  submit = noOp,
  errors = [],
}) => {
  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.form}>
      {isArray(errors) && errors.length > 0 && <ErrorList errors={errors} />}

      <TextInput
        value={email}
        type="email"
        label="Email"
        placeholder="johndoe@zendata.dev"
        onChange={updateEmail}
        style={{ paddingBottom: "1.5rem" }}
        required
      />

      <Button
        type="submit"
        className={classes.submitButton}
        disabled={isSending}
      >
        Recover password
        {isSending && (
          <CircularProgress
            color="inherit"
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>

      {isObject(errors) && errors.error && (
        <Box color="error.main" textAlign="center">
          {errors.error}
        </Box>
      )}

      <p className={classes.actionLink}>
        Don't have an account?&nbsp;
        <Link to="/plans" className={classes.actionLinkAnchor}>
          <strong>Sign Up</strong>
        </Link>
        <ArrowForward className={classes.actionLinkIcon} />
      </p>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  isSending: PropTypes.bool,
  email: PropTypes.string,
  updateEmail: PropTypes.func,
  submit: PropTypes.func,
  errors: PropTypes.array,
};

export default ResetPasswordForm;
