import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

// import Checkbox from '../Scanner/components/checkbox';
import { COUNTRIES } from "./constants";
import SVGIcon from "components/svg-icon/svg-icon";
import { countriesActions } from "redux/_countries/countries.actions";
import Flags from "country-flag-icons/react/3x2";

import useStyles from "./styles";

const ScannerAdvancedSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(countriesActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collapseHandler = () => setOpen(!open);

  const columns = toColumns(COUNTRIES);

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  // #1a3586
  return (
    <>
      <div className={classes.advancedSettingsWrapper}>
        <Typography
          onClick={collapseHandler}
          className={classes.advancedSettings}
        >
          Location settings
          <SVGIcon
            icon="chevronDownIcon"
            className={classes.advancedSettingsIcon}
          />
        </Typography>
        <Collapse in={open} className={classes.collapseContainer}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <List className={classes.root}>
                {columns.column1.map(({ name, value }) => (
                  <Item
                    name={name}
                    value={value}
                    key={value}
                    onClick={handleToggle}
                    classes={classes}
                    checked={checked}
                  />
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <List className={classes.root}>
                {columns.column2.map(({ name, value }) => (
                  <Item
                    name={name}
                    value={value}
                    key={value}
                    onClick={handleToggle}
                    classes={classes}
                    checked={checked}
                  />
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <List className={classes.root}>
                {columns.column3.map(({ name, value }) => (
                  <Item
                    name={name}
                    value={value}
                    key={value}
                    onClick={handleToggle}
                    classes={classes}
                    checked={checked}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </Collapse>
      </div>
    </>
  );
};

export default ScannerAdvancedSettings;

const toColumns = (list) => {
  const firstLength = Math.ceil(list.length / 3);
  const midLength = Math.round(list.length / 3);

  const column1 = list.slice(0, firstLength);
  const column2 = list.slice(firstLength, firstLength + midLength);
  const column3 = list.slice(firstLength + midLength, list.length);

  return { column1, column2, column3 };
};

const Item = ({ name, value, onClick, classes, checked }) => {
  const Flag = Flags[value];
  return (
    <ListItem
      key={value}
      role={undefined}
      dense
      button
      onClick={onClick(value)}
      classes={{
        root: classes.listItemRoot,
      }}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Checkbox
          edge="start"
          checked={checked.indexOf(value) !== -1}
          tabIndex={-1}
          disableRipple
          color="primary"
          inputProps={{ "aria-labelledby": value }}
        />
      </ListItemIcon>
      <Flag title={name} className={classes.flag} />
      <ListItemText id={value} primary={name} className={classes.smallText} />
    </ListItem>
  );
};
