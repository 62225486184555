import { connect } from "react-redux";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { selectPlanInfo } from "redux/_plans/plans.selectors.js";

import AssetsMobileForm from "../AssetsApp";
import AssetsWebForm from "../AssetsWeb";
import SecurityScansForm from "../AssetsSecurity";

import { SCAN_TYPES } from "./constants";
import useStyles from "./styles";

const ScanFlow = ({
  history,
  location,
  onSubmit,
  index,
  setIndex,
  scanType,
  setScanType,
  deviceType,
  setDeviceType,
  setInitialValues,
  planInfo,
  getPlanInfo,
  ...rest
}) => {
  const classes = useStyles();

  useEffect(() => {
    getPlanInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    global.scrollTo({ top: 0 });
    setIndex(index - 1);
  };

  const setupForm =
    deviceType === "mobile" ? (
      <AssetsMobileForm {...rest} goBack={goBack} onSubmit={onSubmit} />
    ) : deviceType === "web" ? (
      <AssetsWebForm {...rest} goBack={goBack} onSubmit={onSubmit} />
    ) : (
      <SecurityScansForm {...rest} goBack={goBack} onSubmit={onSubmit} />
    );

  useEffect(() => {
    if (location.search === `?${SCAN_TYPES.schedule}`) {
      setScanType("scheduled");
      setIndex(1);
      // Removing `search` because is not need it
      history.replace(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={`${classes.root}  ${classes[`page${index}`]}`}>
      <div className={classes.slide}>{setupForm}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScanFlow));
