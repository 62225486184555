import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import OpenInNew from "@material-ui/icons/OpenInNew";
import clsx from "clsx";
import SidebarHeader from "components/Sidebar/components/Header";
import isArray from "lodash.isarray";
import isNil from "lodash.isnil";
import React, { createContext, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Route, withRouter } from "react-router-dom";
import { getData } from "redux/_account/account.async.actions";
import { getAccountData } from "redux/_account/account.selectors";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { getFilterScan } from "redux/_filters/filters.selectors";
import { selectPlanInfo } from "redux/_plans/plans.selectors.js";
import SvgIcon from "../svg-icon/svg-icon";
import { piiMenu, privacyMenu } from "./constants";
import useStyles from "./styles";
import SubItems from "./subitems";

export const SidebarContext = createContext();

const PRODUCT_ACCESS_TYPES = {
  reports: "reports",
  pii: "pii",
};

const productPii = () =>
  !!global.location.pathname.match(
    /database-scanner|data-map|pii-countries|data-owners|functional-uses|pii-trends|environment|integrations|settings\/initial-setup|datasources|databases|admin\/users/
  );

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

const Sidebar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const toggleSidebar = () => setOpen(!open);
  const {
    environment,
    selectedScan,
    children,
    accountData: {
      first_name: firstName,
      last_name: lastName,
      is_main_account: isMainAccount,
      product_access: productAccess,
    },
    planInfo: { metadata: planMeta },
    getAccountData,
    location,
    mobileDrawerOpen,
    setMobileDrawerOpen,
    logout,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedElement, setOpenedElement] = useState(null);
  const [menu, setMenu] = useState(null);

  const scanId = selectedScan ? selectedScan : "";

  const openElement = (id, ev) => {
    setOpenedElement(id);
    setAnchorEl(ev.currentTarget);
  };

  const closeElement = () => {
    setAnchorEl(null);
    setOpenedElement(null);
  };

  const handleMobilePageChangeClick = () => {
    if (mobileDrawerOpen) {
      setMobileDrawerOpen(false);
    }
  };

  const onItemContainerClick = (id, ev) => {
    if (openedElement !== id) {
      openElement(id, ev);
    } else {
      closeElement();
    }
  };

  useEffect(() => {
    if (!open) {
      closeElement();
    }
  }, [open]);

  useEffect(() => {
    getAccountData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productAccess) {
      const desiredMenu = productPii() ? piiMenu : privacyMenu;
      setMenu(desiredMenu);
    }
  }, [productAccess]);

  const name = firstName && lastName ? `${firstName} ${lastName}` : "";

  const drawerContent = (
    <>
      <SidebarHeader
        fullWidth={open}
        toggleSidebar={toggleSidebar}
        showPIIElements={productPii()}
      />
      <List
        className={classes.listContainer}
        onClick={() => handleMobilePageChangeClick()}
      >
        {menu &&
          menu.map(
            ({
              blank,
              exact = false,
              icon,
              link,
              name,
              onlyMainAccount = false,
              permission,
              subItems,
              text,
              topDivider,
            }) => {
              if (!planMeta) return null;
              if (permission && !Number(planMeta[permission])) return null;

              if (onlyMainAccount && !isMainAccount) {
                return <div key={name} />;
              }

              const hasSubItems = isArray(subItems);
              const filteredSubItems = hasSubItems
                ? subItems.filter((subItem) => {
                    if (
                      subItem.permission &&
                      !Number(planMeta[subItem.permission])
                    )
                      return null;
                    return subItem;
                  })
                : [];

              if (hasSubItems && !filteredSubItems.length) return null;

              return (
                <Fragment key={name}>
                  {topDivider && (
                    <Divider
                      className={clsx(
                        classes.divider,
                        productAccess === PRODUCT_ACCESS_TYPES.reports &&
                          "-full"
                      )}
                    />
                  )}
                  <Route
                    path={
                      filteredSubItems
                        ? filteredSubItems.map((subItem) =>
                            subItem.link(environment, scanId)
                          )
                        : undefined
                    }
                  >
                    {({ match }) => (
                      <ListItem
                        button
                        component={!hasSubItems ? ForwardNavLink : undefined}
                        exact={exact || undefined}
                        to={!hasSubItems ? link(environment, scanId) : ""}
                        className={clsx(
                          classes.listItem,
                          hasSubItems && match
                            ? classes.selectedItem
                            : undefined,
                          productAccess === PRODUCT_ACCESS_TYPES.reports &&
                            "-reports",
                          !hasSubItems &&
                            location.pathname === link(environment, scanId) &&
                            "-active"
                        )}
                        onClick={(ev) => {
                          if (blank) {
                            ev.preventDefault();
                            global.open(link(environment, scanId), "_blank");
                          }
                          if (hasSubItems) {
                            onItemContainerClick(name, ev);
                          } else {
                            closeElement();
                          }
                        }}
                        key={name}
                      >
                        <ListItemIcon
                          className={clsx(
                            classes.listItemIcon,
                            productAccess === PRODUCT_ACCESS_TYPES.reports &&
                              "-reports"
                          )}
                        >
                          {typeof icon === "string" ? (
                            <SvgIcon icon={icon} />
                          ) : (
                            icon
                          )}
                        </ListItemIcon>
                        {open && (
                          <ListItemText
                            className={classes.listItemText}
                            primary={
                              blank ? (
                                <div>
                                  {`${text} `}
                                  <span>
                                    <OpenInNew
                                      style={{
                                        color: "#ffffff75",
                                        width: "16px",
                                        display: "inline-block",
                                        marginLeft: 1,
                                      }}
                                    />
                                  </span>
                                </div>
                              ) : (
                                text
                              )
                            }
                          />
                        )}
                      </ListItem>
                    )}
                  </Route>
                  {hasSubItems &&
                    (open ? (
                      <Route
                        path={filteredSubItems.map((subItem) =>
                          subItem.link(environment, scanId)
                        )}
                      >
                        {({ match }) => (
                          <SubItems
                            subItems={filteredSubItems}
                            open={
                              !isNil(openedElement) && openedElement === name
                            }
                            sidebarOpen={open}
                            match={Boolean(match)}
                            env={environment}
                            scan={scanId}
                            classes={classes}
                            onItemContainerClick={(e) => openElement(name, {})}
                          />
                        )}
                      </Route>
                    ) : (
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={!isNil(openedElement) && openedElement === name}
                        onClose={closeElement}
                        classes={{ paper: classes.menu }}
                      >
                        {subItems.map(
                          ({ name: subName, text: subText, link: subLink }) => (
                            <MenuItem
                              button
                              component={ForwardNavLink}
                              to={subLink(environment, scanId) || ""}
                              onClick={closeElement}
                              className={classes.listItem}
                              {...{
                                anchorrigin: {
                                  vertical: "bottom",
                                  horizontal: "right",
                                },
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              classes={{ root: classes.menuItem }}
                              key={subName}
                            >
                              {subText}
                            </MenuItem>
                          )
                        )}
                      </Menu>
                    ))}
                </Fragment>
              );
            }
          )}
      </List>
      {
        // TODO: refactor footer
      }
      <Hidden mdUp>
        <footer className={classes.sidebarFooter}>
          <button className={classes.logoutButton} onClick={() => logout()}>
            <LogoutIcon className={classes.logoutButtonIcon} />
            Logout
          </button>
        </footer>
      </Hidden>
    </>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          className={clsx(
            classes.drawer,
            open && classes.drawerOpen,
            !open && classes.drawerClose
          )}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={clsx(
            classes.drawer,
            open && classes.drawerOpen,
            !open && classes.drawerClose
          )}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <SidebarContext.Provider value={{ open, setOpen }}>
        {children}
      </SidebarContext.Provider>
    </>
  );
};

const mapStateToProps = (state) => ({
  environment: getSelectedEnvId(state),
  selectedScan: getFilterScan(state),
  accountData: getAccountData(state),
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountData: () => dispatch(getData()),
  logout: () => dispatch(authenticationActinon.logout()),
});

const ConnectedSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export default withRouter(ConnectedSidebar);
