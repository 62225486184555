import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Modal,
  Typography,
  Button,
  Chip,
  OutlinedInput,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  CircularProgress,
  InputLabel
} from "@material-ui/core";
import useProviderPostHook from "hooks/useProviderPostHook";

const SCANNER_LISTS_URL = "/zen/repository-scans2";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      position: 'fixed',
      top: 'calc(100% + 8px)' // 8px de margen entre el selector y el menú desplegable
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const BranchSelectorModal = ({ isOpen, onRequestClose, repositoryData }) => {
  const [selectedBranches, setSelectedBranches] = useState([]);
  const { branches, repositoryUrl, provider, token } = repositoryData;
  const { loading, data, error, fetchData } = useProviderPostHook();
  const history = useHistory();

  const handleChange = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setSelectedBranches(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  }, []);

  const handleRepoSuccessFullyAdded = useCallback(() => {
    history.push(SCANNER_LISTS_URL);
  }, [history]);

  const onHandleContinue = useCallback(() => {
    fetchData({
      url: repositoryUrl,
      provider: provider,
      token: token,
      branches: selectedBranches,
    });
  }, [fetchData, provider, repositoryUrl, selectedBranches, token]);

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={onRequestClose}
        keepMounted
      >
        <Box
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 640,
            backgroundColor: "white",
            border: 2,
            borderColor: "red",
            boxShadow: 24,
            padding: 24,
          }}
        >
          {data ? (
            <>
              <Typography
                id="transition-modal-title"
                style={{ fontFamily: "Inter", fontSize: 20, color: "#000000" }}
              >
                Repository successfully added!
              </Typography>
              <Typography
                id="transition-modal-sub-title"
                style={{ fontFamily: "Inter", fontSize: 16, marginTop: 16 }}
              >
                Now you can set up your first scan.
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 48,
                  justifyContent: "end",
                  gap: 8,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleRepoSuccessFullyAdded}
                  style={{
                    fontFamily: "Inter",
                    color: "#1B3586",
                    backgroundColor: "#6BD4CA",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: 224,
                    cursor: "pointer",
                  }}
                >
                  OK
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography
                id="transition-modal-title"
                style={{ fontFamily: "Inter", fontSize: 20, color: "#000000" }}
              >
                Repository Verification
              </Typography>
              {!error && (
                <Chip
                  label=" Repository successfully verified. Please select your branches."
                  style={{
                    marginTop: "24px",
                    backgroundColor: "white",
                    border: "1px solid #468C49",
                    borderRadius: 8,
                    fontFamily: "Inter",
                    color: "#468C49",
                    paddingRight: 80,
                    paddingLeft: 80,
                    paddingTop: 12,
                    paddingBottom: 12,
                  }}
                />
              )}
              <Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 24,
                  }}
                >
                  <Typography style={{ width: 184 }}>Name</Typography>
                  <Typography style={{ color: "#466DE8", fontWeight: 900 }}>
                    {repositoryUrl}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 24,
                  }}
                >
                  <Typography style={{ width: 184 }}>Branches</Typography>
                  <Box>
                    <FormControl style={{ m: 1, width: 300 }}>
                    { selectedBranches.length === 0 &&  <InputLabel style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 14,
            pointerEvents: "none",
          }}>Select branches</InputLabel> }
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        variant="outlined"
                        value={selectedBranches}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {branches?.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              onMouseDown={(event) => event.preventDefault()}
                              checked={selectedBranches.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 48,
                    justifyContent: "end",
                    gap: 8,
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{
                      fontFamily: "Inter",
                      color: "#1B3586",
                      fontSize: "14px",
                      fontWeight: "700",
                      cursor: "pointer",
                      width: 224,
                      backgroundColor: "transparent",
                    }}
                    onClick={onRequestClose}
                  >
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      fontFamily: "Inter",
                      color:
                        selectedBranches.length === 0 ? "#D6D6D6" : "#1B3586",
                      backgroundColor:
                        selectedBranches.length === 0 ? "#868993" : "#6BD4CA",
                      fontSize: "14px",
                      fontWeight: "700",
                      width: 224,
                      cursor: "pointer",
                    }}
                    onClick={onHandleContinue}
                    disabled={selectedBranches.length === 0}
                  >
                    {loading ? (
                      <CircularProgress style={{ height: 22, width: 22 }} />
                    ) : (
                      "continue"
                    )}
                  </Button>
                </Box>
                {error && (
                  <Chip
                    variant="outlined"
                    label={error}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "24px",
                      backgroundColor: "white",
                      border: "1px solid red",
                      borderRadius: 8,
                      fontFamily: "Inter",
                      color: "red",
                      paddingTop: 12,
                      paddingBottom: 12,
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default BranchSelectorModal;
