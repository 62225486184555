import React, { useEffect, useMemo, useState } from "react";
import Seo, { pages } from "components/Seo";
import useStyles from "./styles";
import Header from "components/PrivacyReports/Report/components/Header";
import useQuery from "hooks/useQuery";
import { getAccountData } from "redux/_account/account.selectors";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";
import { selectPlanInfo } from "redux/_plans/plans.selectors";
import { fetchReportJSON } from "redux/_reports/reports.service";
import { connect } from "react-redux";
import FullPageComponent from "./FullPage";
import ReactFullpage from "@fullpage/react-fullpage";
import { getSectionsColorsFor, isHeaderHidden } from "./helpers";
import { getSectionComponents } from "./Sections";
import { authHeader } from "helpers/auth-header";
import Loader from "components/Loader";
import { ORDERED_ANCHORS, REPORT_VERSION } from "./constants";
import { FLAVORS } from "components/PrivacyReports/Report/constants";
import moment from "moment";
import config from "config";

const extractLastPartFromUrl = (url) => {
  const parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  lastPart = lastPart.replace(".pdf", "");
  return lastPart;
};

export const getAppReportData = (reportId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/scans/`;

  return fetch(`${url}${reportId}/`, requestOptions)
    .then((response) => response.json())
    .then(async (response) => {
      if (!response.data.results_json)
        return {
          ...response.data,
          jsonData: false,
          website: response.data.url,
          scanDate: response.data.created_at,
          file_name: extractLastPartFromUrl(response.data.resource),
        };
      return await fetch(response.data.results_json)
        .then((data) => data.json())
        .then((data) => ({
          ...data,
          jsonData: true,
          resource: response.data.results_pdf,
          scanDate: response.data.created_at,
        }));
    });
};

const AppReportPage = ({ location, report = {}, history = {} }) => {
  const [anchors, setAnchors] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();
  const query = useQuery();
  const reportId = query.get("r");
  const reportType = query.get("c");
  const [currentData, setCurrentData] = useState({});
  const [colors, setColors] = useState(
    getSectionsColorsFor(currentData.reportType || REPORT_VERSION.apk)
  );
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getAppReportData(reportId)
      .then((data) => {
        const reportVersion =
          data?.app_type === "apk" ? REPORT_VERSION.apk : REPORT_VERSION.ios;
        setCurrentData({ ...data, reportType: reportVersion });
        setColors(getSectionsColorsFor(reportVersion));
        setAnchors(ORDERED_ANCHORS[reportVersion]);
      })
      .catch((err) => console.log({ errorAPI: err }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />
      <div
        id="page-wrap"
        className={classes.reportPage}
        style={
          !currentData?.jsonData
            ? {
                overflow: "hidden",
                height: "100vh",
              }
            : {}
        }
      >
        <Header
          hidden={isHeaderHidden(location)}
          url={currentData.file_name}
          availableDates={
            !loading && [moment(currentData?.scanDate).format("YYYY-MM-DD")]
          }
          date={date}
          loading={loading}
          setDate={setDate}
          reportType={reportType}
          flavor={FLAVORS.full}
          history={history}
          previousRouteKey="zenAppScans"
        />
        {!loading ? (
          !currentData.jsonData ? (
            <object
              data={currentData.resource}
              type="application/pdf"
              width="100%"
              height="100%"
              style={{
                marginTop: "151px",
                height: "calc(100vh - 151px)",
                position: "absolute",
                top: "151px",
              }}
            >
              <p>
                Alternative text - include a link{" "}
                <a href={currentData.resource}>to the PDF!</a>
              </p>
            </object>
          ) : (
            <FullPageComponent
              sectionsColor={colors}
              anchors={anchors}
              render={({ state, fullpageApi }) => {
                const goToNextSectionAction = fullpageApi
                  ? () => fullpageApi.moveSectionDown()
                  : undefined;
                const moveToSection = fullpageApi && fullpageApi.moveTo;
                const section = fullpageApi && fullpageApi.getActiveSection();
                const current = section && section.anchor;

                return (
                  <ReactFullpage.Wrapper>
                    {getSectionComponents({
                      goToNextSectionAction,
                      data: currentData,
                      appType: currentData?.reportType,
                      reportType,
                      reportId,
                      loading,
                      current,
                      moveToSection,
                      classes,
                    })}
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          )
        ) : (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  isAuthenticated: state.authentication.isAuthenticated,
  currentReportJSON: getCurrentReportJSON(state),
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppReportPage);
