export default {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_APP_BUCKET,
      region: process.env.REACT_APP_AWS_APP_BUCKET_REGION_ID,
      customPrefix: {
        public: process.env.REACT_APP_AWS_APP_DIRECTORY,
      },
    },
  },
};
