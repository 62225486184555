import React, { useRef } from "react";
import { project } from "./utils";
import Tooltip from "./Tooltip";

const NodeElement = ({
  node,
  onClick,
  styles,
  tooltipFn,
  textInside,
  tooltip,
}) => {
  const toggleOpen = onClick ? () => onClick(node) : undefined;
  const { radius, circleFill, strokeWidth } = styles;

  let { textAnchor, textFill, textTransform, textX } = styles;

  const textElement = useRef();

  let isLessThanRadius;
  if (textElement.current) {
    isLessThanRadius = radius * 2 < textElement.current.getBBox().width;
  }

  if (textInside && isLessThanRadius) {
    textAnchor = node.x < 180 ? "start" : "end";
    textX = (node.x < 180 ? 1 : -1) * (radius + 5);
    textFill = "black";
  }

  const nodePath = (
    <g
      transform={`translate(${project(node.x, node.y)})`}
      onClick={toggleOpen}
      style={onClick ? { cursor: "pointer" } : {}}
    >
      <circle
        fill={node.data.name ? circleFill : "#fafafa"}
        strokeWidth={node.data.name ? strokeWidth : 1}
        stroke={node.data.name ? "#4767b8" : "#e0e0e0"}
        r={radius}
      />
      <text
        ref={textElement}
        fill={node.data.name ? textFill : "#868993"}
        fontFamily="Inter"
        dominantBaseline="middle"
        x={textX}
        textAnchor={textAnchor}
        style={{ textTransform: textTransform }}
      >
        {node.data.name || "Uncategorized"}
      </text>
    </g>
  );

  const tooltipData = tooltipFn(node);
  if (tooltipData && tooltip) {
    return (
      <Tooltip data={tooltipData} title={tooltip}>
        {nodePath}
      </Tooltip>
    );
  } else {
    return nodePath;
  }
};

export default NodeElement;
