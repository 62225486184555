import React, { Fragment } from "react";
import Tooltip from "./Tooltip";
import { line } from "d3-shape";
const d3 = { line };

const Line = ({
  data,
  property,
  x,
  y,
  color,
  hovered,
  keys,
  tooltipContainer,
  compact,
}) => {
  const lineGenerator = d3
    .line()
    .x((d, i) => x(i))
    .y((d) => y(d[property]));

  const line = lineGenerator(data);
  const points = data.map((point, i) => ({
    x: lineGenerator.x()(point, i),
    y: lineGenerator.y()(point),
    value: point[property],
  }));

  const isOdd = (order) => {
    const current = data[order];
    const ordered = keys.sort((a, b) => (current[a] > current[b] ? -1 : 1));
    const isOdd = ordered.indexOf(property) % 2;
    return isOdd;
  };

  return (
    <g>
      <path fill="none" stroke={color} d={line} strokeWidth="2" />
      {points.length &&
        points.map((point, i) => (
          <Fragment key={i}>
            <g transform={`translate(${point.x} ${point.y})`}>
              <circle key={i} cx={0} cy={0} r={2.5} fill={color} />
            </g>
            <Tooltip
              compact={compact}
              order={i}
              x={point.x}
              y={point.y}
              container={tooltipContainer}
              isOdd={isOdd}
              hovered={hovered}
              color={color}
              label={point.value}
            />
          </Fragment>
        ))}
    </g>
  );
};

export default Line;
