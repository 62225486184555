/* eslint-disable jsx-a11y/anchor-is-valid */
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import Hamb from "assets/svgs-icons/hamb.svg";
import Button from "components/Button";
import SVGIcon from "components/svg-icon/svg-icon";
import React from "react";
import { push as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { routeForKey } from "router";
import { menuStyles, useStyles } from "./styles";

const HomepageMenu = () => {
  const classes = useStyles();

  return (
    <Menu
      styles={menuStyles}
      right
      customBurgerIcon={<img src={Hamb} alt="Main menu" />}
      width={220}
    >
      <div className={classes.navLinks}>
        <NavLink
          exact
          target="_top"
          to={{ pathname: "https://www.zendata.dev" }}
        >
          Product
        </NavLink>
        <NavLink
          exact
          target="_top"
          to={{ pathname: "https://www.zendata.dev/pricing" }}
        >
          Plans & Pricing
        </NavLink>
        <NavLink to="/consumer-report">Consumers</NavLink>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionTitle}
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Learn
          </AccordionSummary>
          <AccordionDetails className={classes.accordionBody}>
            <NavLink
              exact
              to={{ pathname: "https://www.zendata.dev/blog" }}
              target="_top"
            >
              Blog
            </NavLink>
            <NavLink to={"/data-protection-form"}>Privacy Law Criteria</NavLink>
          </AccordionDetails>
        </Accordion>
        <NavLink className={classes.tryLink} to={routeForKey("demoReports")}>
          <Button>Show Sample Reports</Button>
        </NavLink>
        <NavLink to={routeForKey("login")}>
          <SVGIcon icon="login" className={classes.loginIcon} />
          Client Login
        </NavLink>
      </div>
      <div className="bm-item">
        <div className={classes.footer}>
          <NavLink
            exact
            target="_top"
            to={{ pathname: "https://www.zendata.dev" }}
          >
            <ZenDataLogo />
          </NavLink>
          <ul className="social">
            {/*
            <li className={classes.socialIco}>
              <a className="nav-link" href="#">
                <i className={classes.icoTwitter}>Twitter</i>
              </a>
            </li>
            <li className={classes.socialIco}>
              <a className="nav-link" href="#">
                <i className={classes.icoFacebook}>Facebook</i>
              </a>
            </li>
            */}
            <li className={classes.socialIco}>
              <a
                className="nav-link"
                href="https://www.linkedin.com/company/zendata-ai/"
              >
                <i className={classes.icoLinkedin}>LinkedIn</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Menu>
  );
};

export default HomepageMenu;
