import React, { useState, useCallback } from "react";
import TextInput from "components/Inputs/Text";
import debounce from "lodash.debounce";

const Search = ({ value, onChange, ...restProps }) => {
  const [inputValue, setInputValue] = useState(value || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(debounce(onChange, 1000), []);
  const handleSearchChange = (value) => {
    setInputValue(value);
    handleDebouncedSearch(value);
  };
  return (
    <TextInput
      {...restProps}
      onChange={handleSearchChange}
      value={inputValue}
    />
  );
};

export default Search;
