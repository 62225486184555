import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  addButton: {
    width: "80px",
    height: "80px",
    backgroundColor: "white",
    border: `1px dashed ${theme.palette.blue[500]}`,
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "rgba(207,223,248,0.3)",
      borderStyle: "solid",
      borderColor: theme.palette.blue["young"],
      "& >span:first-child": {
        color: theme.palette.blue["young"],
      },
    },
  },
  addButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
  },
  addIcon: {
    color: "#3f51b5",
    marginBottom: "10px",
    boxShadow: "0px 2px 20px rgba(63, 81, 181, 0.4)",
    borderRadius: "50%",
    "& path": {
      transform: "scale(1.2) translate(-2px, -2px)",
    },
  },
  entityButton: {
    width: "80px",
    height: "80px",
    backgroundColor: "rgba(207, 223, 248, 0.2)",
    border: "1px solid #a4bef1 !important",
    borderRadius: "5px !important",
    overflow: "hidden",
    transition: "0.15s all ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.blue[100],
      "& >span:first-child": {
        color: theme.palette.blue[600],
      },
    },
    "&.Mui-selected": {
      border: `solid 1px ${theme.palette.blue[500]} !important`,
      backgroundColor: theme.palette.blue[500],
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.blue[600],
        "& >span:first-child": {
          color: "white",
        },
      },
      "& >span": {
        "& >svg": {
          "& >path": {
            fill: "white",
          },
        },
      },
    },
  },
  entityButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
    height: 62,
    justifyContent: "flex-end",
    "& >svg": {
      margin: "auto",
      height: 26,
      width: "100%",
    },
  },
  buttonTypography: {
    width: "62px",
    fontSize: "13px",
    fontWeight: 500,
  },
  sidebarInput: {
    width: 238,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
      color: theme.palette.grey[800],
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[500],
    },
  },
  sidebarInputIcon: {
    fill: "#828282",
  },
}));
