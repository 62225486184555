import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import clsx from "clsx";
import useStyles from "../styles";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import {
  getReportsData,
  getReportsErrorState,
  getReportsMeta,
  getReportLoadingState,
} from "redux/_reports/reports.selectors";
import { getReports, deleteReport } from "redux/_reports/reports.async.actions";
import Dialog from "components/Dialog";
import Loader from "components/Loader";
import Status from "pages/AppReport/common/Status";
import {
  getDataSourceList,
  getProviders,
  removeDataSourceReport,
} from "redux/_datasources/datasources.service";
import { Delete } from "@material-ui/icons";


const environment = "repository";


const ListRepositoryScans = ({
  data = [],
  getData,
  loading,
  removeReport,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();
  const [dataSourceList, setDataSourceList] = useState([]);
  const [providers, setProviders] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [scanToRemove, setScanToRemove] = useState();
  const [totalRows, setTotalRows] = useState(0);

  const fetchScansList = async (pageNumber, pageSize) => {
    try {
      const { data, meta } = await getDataSourceList({ page: pageNumber, pageSize });
      setDataSourceList(data);
      setPage(pageNumber);
      setTotalRows(meta.totalRows || 0);
  
      // Recuperar los providers y formatearlos
      const providersData = await getProviders();
      const formattedProviders = providersData?.results?.reduce((acc, provider) => {
        acc[provider.id] = provider;
        return acc;
      }, {});
      setProviders(formattedProviders);
    } catch (error) {
      console.error("fetchScansList error: ", error);
    }
  };
  
  

  useEffect(() => {
    fetchScansList(page);
  }, [page]);

  const handleDisplayModal = () => {
    setDisplayDeleteModal(!displayDeleteModal);
  };

  const removeRepositoryScan = async () => {
    handleDisplayModal();
    try {
      await removeDataSourceReport(scanToRemove);
    } catch (error) {
      console.error("removeRepositoryScan error: ", error);
    }
    finally {
      fetchScansList(page, pageSize);
    }
  };

  const handleDeleteSelection = (scan) => {
    setScanToRemove(scan);
    handleDisplayModal();
  };

  const columns = [
    {
      field: "provider",
      headerName: "Data Sources",
      width: 200,
      renderCell: (params) => {
        const provider = providers[params.value];
        const status = params.data.status;
        const isLinkDisabled = status === "setting_up" || status === "failed";
        return isLinkDisabled ? (
          <Box className={clsx(classes.disabled)}>
            <Box className={classes.iconContainer}>
              <img className={classes.icon} src={provider?.icon} alt="" />
            </Box>
            {provider?.name}
          </Box>
        ) : (
          <a
            className={classes.dataSourceName}
            href={`/data-source-report?r=${params.data.id}`}
            target="_self"
          >
            <Box className={classes.iconContainer}>
              <img className={classes.icon} src={provider?.icon} alt="" />
            </Box>
            {provider?.name}
          </a>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Box className={clsx(classes.status)}>
          <Status
            status={params.value}
            isSelectable
            type="dataSource"
            onClick={() =>
              setSelectedStatus(params.value !== selectedStatus ? params.value : "")
            }
            selected={params.value === selectedStatus}
          />
         </Box>
      ),
    },
    {
      field: "created",
      headerName: "Last Scan",
      width: 150,
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "data_types",
      headerName: "Data Types",
      width: 200,
      valueGetter: (params) =>
        params.data.summary?.totalNonSensitiveEntities +
          params.data.summary?.totalSensitiveEntities || "-",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => handleDeleteSelection(params.data.id)}
          startIcon={<Delete />}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handlePageChange = (params) => {
    fetchScansList(params.page , pageSize);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>

      {dataSourceList?.length > 0 && providers ? (
        <Box height={500} width="100%">
          <DataGrid
            className={classes.root}
            rows={dataSourceList}
            columns={columns}
            pageSize={pageSize}
            pagination
            rowCount={totalRows}
            paginationMode="server"
            onPageChange={handlePageChange}
          
          />
        </Box>
      ) : (
        <Box py={3} textAlign="center" width="100%">
          <Typography>No reports were found.</Typography>
        </Box>
      )}

      <Dialog
        title="Delete Report"
        handleClose={handleDisplayModal}
        open={displayDeleteModal}
        customClasses={classes}
      >
        <DialogContent>
          Are you sure you want delete this data source? If you remove it, you wouldn{"'"}t be able to check these results again.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisplayModal} color="primary">
            No
          </Button>
          <Button onClick={removeRepositoryScan} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getReportsData(state),
  error: getReportsErrorState(state),
  loading: getReportLoadingState(state),
  meta: getReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (filters) => dispatch(getReports(filters)),
  removeReport: (id) => dispatch(deleteReport(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListRepositoryScans);
