export const columns = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "scannedDatabases",
    label: "DBs",
    align: "right",
  },
  {
    id: "scannedTables",
    label: "Tables",
    align: "right",
  },
  {
    id: "scannedColumns",
    label: "Pii Columns",
    align: "right",
  },
  {
    id: "endedAt",
    label: "Last Scanned",
    align: "right",
  },
  {
    id: "settings",
    label: "",
    align: "center",
  },
];
