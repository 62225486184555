import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 0, 3),
    zIndex: 0,
    position: "relative",
  },
  backgroundIcon: {
    position: "absolute",
    right: 0,
    bottom: 20,
    zIndex: -1,
  },
}));
