import React, { useState, useMemo, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Sunburst from "./Sunburst/Sunburst";
import SVGContainer from "../SVGContainer/SVGContainer";
import OwnersFilter from "./Sunburst/OwnersFilter";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "calc(100% - 10px)",
    height: "100%",
    position: "relative",
  },
}));

const DataStructure = ({ data, colors }) => {
  const classes = useStyles();

  const tableOwners = useMemo(() => {
    if (!data) return;
    const owners = data.reduce((prev, curr) => {
      return [...prev, ...curr.children.map((x) => x.name)];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return owners.filter((owner, index) => owners.indexOf(owner) === index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [owners, setOwners] = useState([]);

  useEffect(() => {
    setOwners(tableOwners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableOwners]);

  const toggleOwners = (owner) => {
    const index = owners.indexOf(owner);
    if (index !== -1) {
      setOwners(owners.filter((own) => own !== owner));
    } else {
      setOwners([...owners, owner]);
    }
  };

  if (!data) return null;
  return (
    <Box
      style={{
        width: "100%",
        height: "calc(100vh - 144px)",
        paddingTop: "24px",
      }}
    >
      <Box style={{ width: "100%", height: "100%", display: "flex" }}>
        <Box className={classes.chartContainer}>
          <SVGContainer geometricPresicion>
            <Sunburst
              tableOwners={tableOwners}
              owners={owners}
              data={data}
              colors={colors}
            />
          </SVGContainer>
          <OwnersFilter
            owners={tableOwners}
            onChange={toggleOwners}
            selected={owners}
            colors={colors}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataStructure;
