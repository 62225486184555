const interpolate = require("color-interpolate");

const bad = "#FB585A";
const avg = "#FBC65F";
const good = "#55CC9A";

const colormap = interpolate([bad, avg, good]);

export { bad, avg, good };

export default colormap;
