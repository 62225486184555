import React from "react";
import { connect } from "react-redux";
import useStyles from "../../styles";
import CONSTANTS from "redux/_filters/filters.constants";
import MomentUtils from "@date-io/moment";
import DateRangePicker from "components/Inputs/DateRangePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { selectData } from "redux/_filters/filters.actions";
import { getFilterScansDateRange } from "redux/_filters/filters.selectors";

const { TYPES } = CONSTANTS;

const ScansDateRange = ({ dateRange, setDateRange }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateRangePicker
        className={classes.filtersInput}
        title="Date"
        variant="inline"
        inputVariant="outlined"
        value={dateRange}
        onChange={setDateRange}
        format="MM/DD/YYYY"
        disableToolbar
      />
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  dateRange: getFilterScansDateRange(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDateRange: (dateRange) =>
    dispatch(selectData(TYPES.SCANS.DATE_RANGE, dateRange)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScansDateRange);
