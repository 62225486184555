import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  appBar: {
    bottom: "0px",
    display: "flex",
    top: "auto",
    flexDirection: "row",
    padding: "24px",
    paddingBottom: "36px",
    justifyContent: "flex-end",
    borderTop: "1px solid #ccc",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  updateButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    padding: `${theme.spacing()}px ${theme.spacing(3)}px`,
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "1px",
  },
  discardButton: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.blue["young"],
    backgroundColor: "white",
    textTransform: "none",
    marginRight: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(5, 0, 0),
  },
}));
