import { useEffect, useState } from "react";
import ScanList, { SCAN_TYPE } from "components/ScanList";
import { connect } from "react-redux";
import { deleteReport, getReports } from "redux/_reports/reports.async.actions";
import {
  getReportsData as getSingleRunScans,
  getReportsMeta as getSingleRunScansMeta,
} from "redux/_reports/reports.selectors";

import Loader from "components/Loader";

// TODO: refactor common elements into components/ScanList using
// https://material-ui.com/components/tables/ as inspiration/tool.

const SingleRunAppScansList = ({
  goToSection,
  singleRunScans,
  singleRunScansMeta,
  fetchSingleRunScans,
  removeSingleRunScan,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const filteredData = singleRunScans
    .filter((item) => item.environment !== "web")
    .map((item) => {
      const urlParts = item.resource.split("/");
      const lastPart = urlParts[urlParts.length - 1];
      const resourceName = lastPart.replace(".pdf", "");
      return { ...item, resource: resourceName };
    });

  useEffect(() => {
    if (filteredData && filteredData.length > 0) setIsLoading(false);
  }, [filteredData]);

  const scanListProps = {
    cta: "Create a new scan",
    emptyNotice: "You don't have any single-run scans yet.",
    headers: [
      { label: "App Name", className: "appName" },
      { label: "Status", className: "status" },
      { label: "Actions", className: "actions" },
    ],
    goToSection,
    deleteItem: removeSingleRunScan,
    data: filteredData,
    getItems: fetchSingleRunScans,
    scanType: SCAN_TYPE.singleRun,
    totalPages: singleRunScansMeta?.totalPageCount || 0,
  };

  return isLoading ? <Loader /> : <ScanList {...scanListProps} />;
};

const mapStateToProps = (state) => ({
  singleRunScans: getSingleRunScans(state),
  singleRunScansMeta: getSingleRunScansMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeSingleRunScan: (id) => dispatch(deleteReport(id)),
  fetchSingleRunScans: (filters) => dispatch(getReports(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleRunAppScansList);
