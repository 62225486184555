import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routeForKey } from "router";

import { authenticationActinon } from "redux/_authentication/authentication.actions";

import ResetPasswordConfirmForm from "components/login/reset-password-confirm.form";
import Seo, { pages } from "components/Seo";
import { parseQuery } from "utils/queryString";

const ResetPasswordConfirmContainer = ({ location, history, dispatch }) => {
  const [passwords, setPasswords] = useState({
    new_password1: undefined,
    new_password2: undefined,
  });
  const [extraData, setExtraData] = useState({
    uid: undefined,
    token: undefined,
    origin: undefined,
  });
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState();

  const handleChange = (fieldName) => (value) => {
    setPasswords({ ...passwords, [fieldName]: value });
  };

  const handleRedirection = (history, origin) => {
    let url = routeForKey("login");
    if (origin) {
      url = `${url}?origin=${origin}`;
    }
    history.push(url);
  };

  useEffect(() => {
    const { uid, token, origin } = parseQuery(location.search);
    if (uid && token) {
      setExtraData({ uid, token, origin });
    } else {
      handleRedirection(history, origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePassword = (e) => {
    e.preventDefault();
    const { new_password1: password, new_password2: password2 } = passwords;
    if (password && password !== password2) {
      setErrors({ error: "Passwords do not match." });
    } else {
      setErrors();
      setSending(true);
      const { origin } = extraData;
      dispatch(
        authenticationActinon.resetConfirmPassword({
          ...passwords,
          ...extraData,
        })
      )
        .then(() => {
          handleRedirection(history, origin);
        })
        .catch((error) => {
          setSending(false);
          setErrors({ error });
        });
    }
  };

  return (
    <>
      <Seo {...pages.resetPassword} />

      <ResetPasswordConfirmForm
        isSending={sending}
        password1={passwords.new_password1}
        password2={passwords.new_password2}
        updatePwd={handleChange}
        errors={errors}
        submit={changePassword}
      />
    </>
  );
};

const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps)(ResetPasswordConfirmContainer)
);
