import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import TextInput from "components/Inputs/Text";
import SelectInput from "components/Inputs/Select";
import { connect } from "react-redux";
import { providers } from "components/DatabaseModal/constants";
import CheckboxInput from "components/Inputs/Checkbox";
import { countriesActions } from "redux/_countries/countries.actions";

import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import { useSnackbar } from "notistack";
import { createDatabase } from "redux/_databases/databases.async.actions";

const gridItemProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

const testConnection = (envId, data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/environments/${envId}/databases/test`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

const Step3 = (props) => {
  const {
    nextCallback,
    backCallback,
    environment,
    loading,
    countries = [],
    getCountries,
    createDb,
  } = props;
  const classes = useStyles();

  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [encrypted, setEncrypted] = useState(false);
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [country, setCountry] = useState("");
  const [testing, setTesting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const generateData = () => ({
    name,
    provider,
    encrypted,
    ip,
    port,
    username,
    password,
    department,
    country,
  });

  const testDBConnection = () => {
    setTesting(true);
    testConnection(environment.id, {
      provider,
      encrypted,
      ip,
      port,
      username,
      password,
    })
      .then(() => {
        setTesting(false);
        enqueueSnackbar("Successfully conected!", { variant: "success" });
      })
      .catch(() => setTesting(false));
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>Add Database</Typography>
        <Typography className={classes.subtitle}>
          Complete the following Database details, they are all required.
        </Typography>
      </Box>
      <Box className={classes.rightContainer}>
        <Grid container spacing={1}>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Database name"
              style={{ maxWidth: "208px" }}
              helperText="Pretty straightforward"
              value={name}
              onChange={setName}
              disabled={loading}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Host ip"
              style={{ maxWidth: "208px" }}
              helperText="Database’s IP"
              value={ip}
              onChange={setIp}
              disabled={loading}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Port"
              helperText="Database’s port number"
              style={{ maxWidth: "208px" }}
              onChange={setPort}
              value={port}
              disabled={loading}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Username"
              style={{ maxWidth: "208px" }}
              helperText="The user that has access to the db"
              value={username}
              onChange={setUsername}
              disabled={loading}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Password"
              helperText="Database’s password"
              style={{ maxWidth: "208px" }}
              onChange={setPassword}
              value={password}
              disabled={loading}
              type="password"
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <SelectInput
              label="Provider"
              helperText="Lipsum"
              style={{ maxWidth: "208px" }}
              onChange={setProvider}
              value={provider}
              disabled={loading}
              options={providers}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <TextInput
              label="Department"
              style={{ maxWidth: "208px" }}
              helperText="The section does this db belong to"
              value={department}
              onChange={setDepartment}
              disabled={loading}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <SelectInput
              label="Country"
              helperText="Useful for better segmenting data"
              style={{ maxWidth: "208px" }}
              onChange={setCountry}
              value={country}
              disabled={loading}
              options={countries}
            />
          </Grid>
          <Grid item {...gridItemProps}>
            <CheckboxInput
              value={encrypted}
              onChange={() => setEncrypted(!encrypted)}
              label="Encrypted"
              text="This an encrypted DB"
            />
          </Grid>
          <Grid
            item
            {...gridItemProps}
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <Button
              className={classes.testButton}
              color="secondary"
              onClick={testDBConnection}
            >
              <Typography className={classes.testButtonText}>
                Test Connection
              </Typography>
            </Button>
            {testing && (
              <CircularProgress
                style={{ width: "22px", height: "22px", marginLeft: "24px" }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Button
        className={classes.backButton}
        color="secondary"
        onClick={backCallback}
      >
        <Typography className={classes.backButtonText}>
          {"< Go Back"}
        </Typography>
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={() => {
          createDb(generateData())
            .then(() => nextCallback())
            .catch();
        }}
        disabled={testing || loading}
      >
        NEXT
      </Button>
    </Box>
  );
};

const mapStateToProps = ({
  wizard: { loading, environment },
  countries: { data: countriesData },
}) => ({
  loading,
  environment,
  countries: countriesData,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(countriesActions.getAll()),
  createDb: (data) => dispatch(createDatabase(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
