import {
  getAll,
  updateEnv,
  deleteEnv,
  createEnv,
} from "redux/_environments/environment.service";
import isEmpty from "lodash.isempty";
import { getEnvsData } from "redux/_environments/environment.selectors";
import {
  requestEnd,
  requestStart,
  saveEnvs,
  updateEnvData,
  deleteData,
} from "./environment.actions";
import { getScans } from "redux/_filters/filters.async.actions";
import { getInitialSetupData } from "redux/_settings/settings.async.actions";

export const getAllEnvironments = (params = {}) => (dispatch, getState) => {
  const environmentList = getEnvsData(getState());
  const { force = false } = params;
  if (isEmpty(environmentList) || force) {
    dispatch(requestStart());
    return getAll()
      .then((envs) => {
        dispatch(requestEnd());
        dispatch(saveEnvs(envs));
        dispatch(getScans());
        return Promise.resolve(envs);
      })
      .catch(() => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  } else {
    return Promise.resolve();
  }
};

export const updateEnvironment = (envData) => (dispatch) => {
  dispatch(requestStart());
  return updateEnv(envData)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(updateEnvData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const deleteEnvironment = (id) => (dispatch) => {
  dispatch(requestStart());
  return deleteEnv(id)
    .then(async (_) => {
      dispatch(requestEnd());
      await dispatch(deleteData(id));
      dispatch(getScans());
      dispatch(getInitialSetupData());
      return Promise.resolve();
    })
    .catch(() => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const createEnvironment = (data) => (dispatch) => {
  dispatch(requestStart());
  return createEnv(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveEnvs([data]));
      dispatch(getInitialSetupData());
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};
