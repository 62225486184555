import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import MUITooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2),
    borderRadius: "10px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    minWidth: "205px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
    marginBottom: ".5rem",
  },
  permission: {
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    lineHeight: 1.83,
    color: theme.palette.grey[200],
    textTransform: "uppercase",
  },
}));

const Tooltip = (props) => {
  const classes = useStyles();
  const { data, children } = props;
  const { name, owners = [] } = data;
  const primaryOwner = owners.find((owner) => owner.primary_owner);
  const secondaryOwners = owners.filter((owner) => !owner.primary_owner);
  if (!owners.length) return children;
  return (
    <MUITooltip
      classes={{ tooltip: classes.root, touch: classes.touch }}
      placement="right"
      title={
        !data ? (
          ""
        ) : (
          <Box className={classes.container}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4" className={classes.title}>
                {name}
              </Typography>
              {data.columns_pii && data.columns && (
                <Typography variant="h4" className={classes.title}>
                  PII COLUMNS {data.columns_pii} / {data.columns}
                </Typography>
              )}
            </Box>

            {primaryOwner && (
              <Box className={classes.content}>
                <Typography className={classes.subtitle}>
                  PRIMARY OWNER
                </Typography>
                <Box className={classes.subtitleContainer}>
                  <Typography className={classes.permission} display="inline">
                    {primaryOwner.owner}
                  </Typography>
                </Box>
              </Box>
            )}

            {secondaryOwners.length && (
              <Box className={classes.content}>
                <Typography className={classes.subtitle}>
                  SECONDARY OWNERS
                </Typography>
                {secondaryOwners.map((owner) => (
                  <Box className={classes.subtitleContainer}>
                    <Typography className={classes.permission} display="inline">
                      {owner.owner}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )
      }
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
