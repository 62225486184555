import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import SeverityPanels from "pages/AppReport/common/SeverityPanels";

const NetworkSecuritySection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.networkSecurity.key}
      footerBackgroundColor={SECTIONS_CONTENT.networkSecurity.backgroundColor}
      footerText={<div className="privacy-tech">Check the SDKS</div>}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.networkSecurity.description}>
        {SECTIONS_CONTENT.networkSecurity.title}
      </Title>
      <SeverityPanels
        data={data}
        columns={[
          {
            key: "scope",
            label: "Scope",
            width: "50%",
            render: (scope) => <span>{scope.join("\n")}</span>,
          },
          {
            key: "description",
            label: "Description",
            width: "50%",
          },
        ]}
        emptyMessage="No Network Security for this severity"
      />
    </Page>
  );
};

export default NetworkSecuritySection;
