import { shallowEqual, useSelector } from "react-redux";
import {
  getReports,
  getReport,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import { resetData } from "redux/_reports/reports.actions";
import {
  getReportData,
  getReportsData,
  // getReportsErrorState,
  getReportsMeta,
  // getReportLoadingState,
} from "redux/_reports/reports.selectors";

// later
// data: getReportsData(state),
import { useDispatch } from "react-redux";

const useReports = () => {
  const dispatch = useDispatch();
  const all = (filters) => dispatch(getReports(filters));
  const get = (site, email, extra, hideErrorMessages) =>
    dispatch(getReport(site, email, extra, hideErrorMessages));

  const selected = useSelector(getReportData, shallowEqual);
  const meta = useSelector(getReportsMeta, shallowEqual);
  const list = useSelector(getReportsData, shallowEqual);
  const fetchReport = (values) => dispatch(postTriggerReport(values));
  const reset = () => dispatch(resetData("report"));

  return { meta, list, all, selected, get, reset, fetch: fetchReport };
};

export default useReports;
