import { makeStyles } from "@material-ui/core";

export const emailNoticeMixin = (theme) => ({
  emailNotice: {
    paddingTop: 40,
    fontStyle: "italic",
    "p.&": {
      fontSize: theme.typography.fontSizeSmall,
    },
  },
});

export const stepperDialogTitleMixin = (theme) => ({
  title: {
    fontFamily: "Aldrich, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: theme.spacing(1),
    color: theme.palette.aquamarine[100],
    "&.clear-background": {
      color: theme.palette.blue[300],
      "&.email": {
        width: "350px",
      },
    },
    [theme.breakpoints.up("xs")]: {
      width: "80%",
      paddingTop: "40px",
      fontSize: "2.1em",
      lineHeight: "1.2",
      "&.clear-background": {
        "&.country, &.first-step": {
          width: "80%",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "671px",
      paddingTop: 0,
      fontSize: "40px",
      lineHeight: "48px",
      "&.clear-background": {
        "&.country, &.first-step": {
          width: "400px",
        },
      },
    },
  },
});

export const stepperDialogContentMixin = (theme) => ({
  description: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "19px",
    color: theme.palette.common.white,
    marginBottom: theme.spacing(3),
    width: "68%",
    "&.dark-background": {
      textAlign: "center",
      height: "auto",
    },
    "&.clear-background": {
      color: theme.palette.grey[300],
      marginBottom: 0,
      textAlign: "left",
      height: "84px",
      "&.full": {
        marginTop: "45.5px",
        width: "100%",
      },
    },
    [theme.breakpoints.up("xs")]: {
      "&.dark-background": {
        marginBottom: "30px",
      },
    },
    [theme.breakpoints.up("md")]: {
      "&.dark-background": {
        marginBottom: "60px",
        width: "540px",
      },
    },
  },
  submitButton: {
    margin: theme.spacing(2, 0),
    "&.back-to-scanner": {
      marginTop: "79.5px",
    },
  },
});

export default makeStyles(
  (theme) => ({
    dialog: {
      "& > .MuiDialog-container": {
        "& > .MuiPaper-root": {
          overflow: "hidden",
        },
      },
    },
    dialogBackground: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& > .circle": {
        position: "absolute",
        borderRadius: "50%",
        "&.filled": {
          background: "rgba(48, 86, 174, 0.05)",
        },
        "&.empty": {
          background: "none",
          border: "solid 2px rgba(48, 86, 174, 0.05)",
        },
        "&.small": {
          width: "30px",
          height: "30px",
        },
        "&.upper": {
          top: "330px",
          marginLeft: "360px",
        },
        "&.lower": {
          top: "485px",
          /* In order to accomplish the orbits effect */
          marginLeft: "-550px",
        },
        "&.big": {
          width: "810px",
          height: "810px",
        },
        "&.bigger": {
          top: "-6px",
          width: "1100px",
          height: "1100px",
        },
        "&.bigger-1": {
          top: "-180px",
          width: "1500px",
          height: "1500px",
        },
      },
    },
    dialogContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#686C78",
      backgroundColor: "white",
      "& h1": {
        fontFamily: "Aldrich, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "40px",
        lineHeight: "48px",
        color: "#7090D9",
      },
      "& > p": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 18,
        lineHeight: "28px",
      },
      "& form": {
        width: "600px",
        maxWidth: "600px",
        height: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(48, 86, 174, 0.05)",
        borderRadius: "50%",
      },
      "& label": {
        textAlign: "left",
      },
    },
  }),
  { name: "StepperDialog" }
);
