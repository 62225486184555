import React from "react";
import KeyRow, { ACTIVE } from "components/KeyGenerator/Form/components/KeyRow";
import useStyles from "./styles";

const KeysTable = ({ productKey, keyName }) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <colgroup>
        <col className={classes.col1} />
        <col className={classes.col2} />
      </colgroup>
      <thead>
        <tr className={classes.tr}>
          <th>Key</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <KeyRow
          productKey={productKey}
          keyName={keyName}
          status={productKey ? ACTIVE : "inactive"}
        />
      </tbody>
    </table>
  );
};

export default KeysTable;
