import { authenticationConstants } from "redux/_authentication/authentication.constants";
import { authenticationActinon as authenticationActions } from "redux/_authentication/authentication.actions";
import { authService } from "redux/_authentication/authentication.service";

export const storeSession = (session, dispatch, planId) => {
  localStorage.setItem(
    authenticationConstants.SESSION,
    JSON.stringify(session)
  );
  if (planId) localStorage.setItem(authenticationConstants.PLAN_ID, planId);
  dispatch(authenticationActions._success(session));
  window.addEventListener("storage", authService.watchSession);
};
