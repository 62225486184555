import { CONSTANTS } from "redux/_register/_register.constants.js";

const initialState = {
  loading: false,
  error: false,
  plans: [],
};

export const register = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.REGISTER_REQUEST_START:
      return {
        loading: true,
        error: false,
      };
    case CONSTANTS.REGISTER_REQUEST_END:
      return {
        loading: false,
      };
    case CONSTANTS.REGISTER_SET_ERROR:
      return {
        error: data,
      };
    case CONSTANTS.RETRIEVE_PLANS:
      return {
        plans: data,
      };
    default:
      return state;
  }
};
