import { Button } from "@material-ui/core";
import CollectorItem from "components/Consents/Collector/components/Item";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createCookiePolicy,
  deleteCookiePolicy,
  updateCookiePolicy,
} from "redux/_admin/_company/company.async.actions";
import CookieForm from "./CookieForm";
import useStyles from "./styles";

const UNCATEGORIZED = "Uncategorized";
const MARKETING_COOKIES = "Marketing cookies";
const ESSENTIAL_TECHNICAL_COOKIES = "Essential technical cookies";
const STATISTICS_COOKIES = "Statistics cookies";

export const purposeToCategory = {
  Uncategorized: UNCATEGORIZED,
  "Targeting/Advertising": MARKETING_COOKIES,
  Performance: ESSENTIAL_TECHNICAL_COOKIES,
  "Strictly Necessary": ESSENTIAL_TECHNICAL_COOKIES,
  Functionality: ESSENTIAL_TECHNICAL_COOKIES,
};

export const categories = [
  {
    value: UNCATEGORIZED,
    label: UNCATEGORIZED,
  },
  {
    value: MARKETING_COOKIES,
    label: MARKETING_COOKIES,
  },
  {
    value: ESSENTIAL_TECHNICAL_COOKIES,
    label: ESSENTIAL_TECHNICAL_COOKIES,
  },
  {
    value: STATISTICS_COOKIES,
    label: STATISTICS_COOKIES,
  },
];

const Cookies = ({
  cookies: propCookies,
  createCookie,
  deleteCookie,
  updateCookie,
}) => {
  const classes = useStyles();
  const [creating, setCreating] = useState(false);
  const [cookies, setCookies] = useState([]);
  const query = useQuery();
  const reportId = query.get("r");

  useEffect(() => {
    if (!propCookies) return;
    setCookies(
      propCookies.map((x) => ({
        ...x,
        purpose: purposeToCategory[x.purpose] || x.purpose,
      }))
    );
  }, [propCookies]);

  return (
    <div className={classes.container}>
      <p className={classes.brief}>
        Cookies enable web servers to store stateful information on the user's
        device or to track the user's browsing activity. They can also be used
        to save for subsequent use information that the user previously entered
        into form fields, such as names, addresses, passwords, and payment card
        numbers.
      </p>
      <div className={classes.actions}>
        <p className={classes.notice}>
          You can create as many cookies as you need, by default we load the
          ones present on your site according to your scan.
        </p>
        <Button
          disabled={false}
          onClick={() => setCreating(true)}
          className={classes.addButton}
          variant="contained"
        >
          ADD NEW
        </Button>
      </div>
      {creating && (
        <CookieForm
          onCancel={() => setCreating(false)}
          onSubmit={(x) => {
            const purpose = x.purpose || UNCATEGORIZED;
            createCookie({ report: reportId, purpose, ...x });
          }}
        />
      )}
      {cookies.map((data) => (
        <CollectorItem
          key={data.id}
          title={`${data.name} | ${data.purpose} | ${
            data.expiration || "No expiration"
          }`}
          formComponent={
            <CookieForm
              id={data.id}
              data={data}
              onSubmit={updateCookie}
              onDelete={deleteCookie}
            />
          }
        />
      ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCookie: (values) => dispatch(createCookiePolicy(values)),
  updateCookie: (values) => dispatch(updateCookiePolicy(values)),
  deleteCookie: (values) => dispatch(deleteCookiePolicy(values)),
});

const mapStateToProps = ({ admin }) => ({
  cookies: admin.company ? admin.company.cookies : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Cookies);
