import React from "react";

const caretIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.672861 0H9.32714C9.92584 0 10.2252 0.764898 9.80139 1.21316L5.47593 5.79188C5.21358 6.06937 4.78642 6.06937 4.52407 5.79188L0.198608 1.21316C-0.225193 0.764898 0.0741586 0 0.672861 0Z"
      fill="white"
    />
  </svg>
);

export default caretIcon;
