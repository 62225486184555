import React from "react";

const Link = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15V17H6C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7H10V9H6C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15H10ZM14 9V7H18C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17H14V15H18C19.6569 15 21 13.6569 21 12C21 10.3431 19.6569 9 18 9H14ZM17 11H7V13H17V11Z"
      fill="#3F51B5"
    />
  </svg>
);

export default Link;
