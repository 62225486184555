import CONSTANTS from "./filters.constants";

export const requestStart = (dataType) => ({
  type: CONSTANTS.FILTERS_REQUEST_START,
  dataType,
});

export const requestEnd = (dataType) => ({
  type: CONSTANTS.FILTERS_REQUEST_END,
  dataType,
});

export const saveData = (dataType, data) => ({
  type: CONSTANTS.FILTERS_SAVE_DATA,
  dataType,
  data,
});

export const selectData = (dataType, data) => ({
  type: CONSTANTS.FILTERS_SELECT_DATA,
  dataType,
  data,
});

export const resetAllFilters = () => ({
  type: CONSTANTS.FILTERS_RESET_ALL,
});
