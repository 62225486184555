import { useState, useEffect } from "react";

const useFetchJson = (importFunction) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!importFunction) {
      setLoading(false);
      return;
    }

    const fetchJson = async () => {
      try {
        const { default: jsonData } = await importFunction();
        setData(jsonData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchJson();
  }, [importFunction]);

  return { data, loading, error };
};

export default useFetchJson;
