import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    lineHeight: "24px",
    marginBottom: 0,
  },
  inputContainer: {
    display: "block",
    marginBottom: 0,
    "&>div": {
      margin: 0,
      width: "100%",
      borderColor: ({ error }) =>
        error ? theme.palette.red[500] : theme.palette.blue[100],
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
    },
  },
  helperText: {
    lineHeight: "22px",
    fontSize: "12px",
    color: ({ error }) =>
      error ? theme.palette.red[500] : theme.palette.grey[800],
    fontWeight: 500,
    marginTop: 3,
    marginBottom: 0,
  },
  textField: {
    borderRadius: "6px",
    height: "34px",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&>div": {
      height: "34px",
      "&:before": {
        content: "none",
      },
      "&:after": {
        content: "none",
      },
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: `${theme.palette.grey[200]}`,
      margin: "0.5rem 0 0",
    },
    "& input": {
      color: theme.palette.blue[400],
      fontSize: "15px",
      padding: "0 1rem",
      fontFamily: "Roboto Condensed",
    },
    "& fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.blue[100]}`,
      color: theme.palette.blue[400],
      height: "38px",
    },
    "& fieldset:hover": {
      border: `solid 1px ${theme.palette.blue[600]}`,
    },
    "&::placeholder": {
      fontStyle: "italic",
    },
    "&::-webkit-placeholder": {
      fontStyle: "italic",
    },
  },
}));
