import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  nav: ({ transparent, backgroundImage }) => ({
    position: "relative",
    zIndex: 100,
    backgroundColor: transparent ? "transparent" : theme.palette.blue.main,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
    backgroundSize: "cover",
    marginBottom: transparent ? -126 : 0,
    padding: "31px 48px",
    "& ul": {
      listStyle: "none",
      margin: "0",
      padding: "0",
      "& li": {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
          // marginLeft: `${theme.spacing(4)} !important`
        },
      },
      "& a": {
        transition: "all .5s ease",
        lineHeight: "46px",
        color: "#fff",
        fontSize: "0.85rem",
        [theme.breakpoints.up("lg")]: {
          fontSize: "1rem !important",
        },
        "&.strong": {
          fontWeight: 600,
        },
        "&.active": {
          color: theme.palette.aquamarine[100],
          fontWeight: 600,
        },
      },
    },
  }),
  loginIcon: {
    marginRight: theme.spacing(1),
  },
  logoContainer: {
    [theme.breakpoints.up("xs")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  logo: {
    margin: "0",
    lineHeight: "46px",
    [theme.breakpoints.up("lg")]: {
      transform: "translate(56px, 0) scale(1.7)",
    },
  },
  divider: {
    padding: "0 24px",
  },
  button: {
    width: ({ id }) => (id !== undefined ? 222 : 335),
    textDecoration: "none",
  },
  userMenuButton: {
    color: ({ isDashboard }) => (isDashboard ? "black" : "white"),
    textTransform: "lowercase",
    fontWeight: ({ isDashboard }) => (isDashboard ? 400 : 300),
  },
  buttonMenuItem: {
    color: theme.palette.grey[900],
  },
  buttonAvatar: {
    marginLeft: theme.spacing(1),
  },
  loggedMenu: {
    "& > .MuiMenu-paper": {
      top: ({ isPublicNav, isDashboard }) => {
        if (isPublicNav) {
          return "70px !important";
        }

        if (isDashboard) {
          return "53px !important";
        }

        return "39px !important";
      },
      width: "166px",
      display: "flex",
      justifyContent: "center",
    },
  },
  blogLink: {
    padding: "6px 16px",
  },
  menuWrapper: {
    "&:hover .menu-content": {
      height: "125px",
      opacity: 1,
      overflow: "visible",
    },
    "& .menu-content": {
      height: 0,
      overflow: "hidden",
      opacity: 0,
      transition: "all 0.3s ease-in-out",
      border: `1px solid ${theme.palette.blue.main}`,
      position: "absolute",
      backgroundColor: theme.palette.blue.main,
      "& a": {
        height: "40px",
        color: "white",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
  menuTitle: {
    fontSize: "1rem",
    color: "white",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
