import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "50vw",
    height: "50vh",
    backgroundColor: "white",
    border: "solid 1px #cfdff8",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    lineHeight: 1.43,
    color: theme.palette.blue[500],
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
  },
  subtitle: {
    fontSize: "18px",
    lineHeight: 1.22,
    color: "#828282",
    maxWidth: "600px",
    textAlign: "center",
  },
  button: {
    width: "180px",
    borderRadius: "4px",
    padding: "8px 40px",
    margin: theme.spacing(3),
  },
}));
