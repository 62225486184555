import {
  fetchRequestsData,
  updateRequest,
  fetchIndividualRights,
} from "redux/_requests/_data/data.service";
import {
  requestStart,
  requestEnd,
  saveData,
  saveMetadata,
  selectFilter,
  setError,
} from "redux/_requests/_data/data.actions";
import isEmpty from "lodash.isempty";
import {
  getRequestsData,
  getRequestsFilters,
  getRequestsFilterSelected,
} from "redux/_requests/_data/data.selectors";
import { CONSTANTS } from "redux/_requests/_data/data.constants";

export const getData = (force = false, pagination = {}) => (
  dispatch,
  getState
) => {
  const filters = getRequestsFilters(getState());
  const requests = getRequestsData(getState());
  if (isEmpty(requests) || force) {
    dispatch(requestStart());
    return fetchRequestsData(filters, pagination)
      .then(([data, meta]) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        dispatch(saveMetadata(meta));
        return Promise.resolve();
      })
      .catch((_) => {
        dispatch(setError());
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const update = (data) => (dispatch) => {
  dispatch(requestStart());
  return updateRequest(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const getIndividualRights = (force = false) => (dispatch, getState) => {
  const regulation = getRequestsFilterSelected(
    CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION
  )(getState());
  return fetchIndividualRights({ regulation })
    .then((data) => {
      const parsedData = data.map((item) => ({
        label: item.name,
        value: item.id,
        checked: false,
      }));
      dispatch(
        selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.INCLUDES, parsedData)
      );
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};
