import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  popoverContainer: { width: "600px" },
  chartContainer: { width: "100%", height: "390px", margin: "0 auto" },
  popover: {
    "& .MuiPaper-root": {
      marginTop: -55,
    },
  },
  tab: {
    paddingBottom: 0,
  },
  dateFilter: {
    display: "none",
  },
  green: {
    fontSize: 14,
    fontWeight: 400,
    color: "green",
  },
  red: {
    fontSize: 14,
    fontWeight: 400,
    color: "red",
  },
}));
