import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { routeForKey } from "router";

import useStyles from "./styles";

const GoBackButton = ({
  parentReportId,
  parentReportCategory,
  isAuthenticated,
  previousRouteKey,
}) => {
  const classes = useStyles();
  const BACK_LINK = {
    domain: {
      key: "domain",
      text: "domain site analysis",
      link: `/privacy-report?r=${parentReportId}&c=${parentReportCategory}#SiteAnalysis`,
    },
    dashboard: {
      key: "dashboard",
      text: "dashboard",
      link: routeForKey(previousRouteKey),
    },
    homepage: {
      key: "homepage",
      text: "homepage",
      link: { pathname: "https://www.zendata.dev" },
    },
  };

  const [hasParentReport, setHasParentReport] = useState();
  const [backLinkKey, setBackLinkKey] = useState();
  const [backLink, setBackLink] = useState("");
  const [backLinkText, setBackLinkText] = useState();

  useEffect(() => {
    const initial = !!parentReportId
      ? { ...BACK_LINK.domain }
      : isAuthenticated
      ? { ...BACK_LINK.dashboard }
      : { ...BACK_LINK.homepage };

    setHasParentReport(!!parentReportId);
    setBackLinkKey(initial.key);
    setBackLink(initial.link);
    setBackLinkText(initial.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentReportId]);

  const handleBackRefChange = ({ target: { value } }) => {
    setBackLinkKey(BACK_LINK[value].key);
    setBackLink(BACK_LINK[value].link);
    setBackLinkText(BACK_LINK[value].text);
  };

  return (
    <button className={classes.goBackButton}>
      <Link className={classes.link} exact="true" target="_top" to={backLink}>
        <ArrowBack className={classes.arrowBack} />
        Go back to{" "}
      </Link>
      {hasParentReport ? (
        <Select
          value={backLinkKey}
          onChange={handleBackRefChange}
          classes={{ select: classes.select }}
          disableUnderline
        >
          <MenuItem value={BACK_LINK.domain.key}>
            {BACK_LINK.domain.text}
          </MenuItem>
          <MenuItem
            value={
              isAuthenticated ? BACK_LINK.dashboard.key : BACK_LINK.homepage.key
            }
          >
            {isAuthenticated
              ? BACK_LINK.dashboard.text
              : BACK_LINK.homepage.text}
          </MenuItem>
        </Select>
      ) : (
        backLinkText
      )}
    </button>
  );
};

export default GoBackButton;
