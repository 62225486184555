import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginTop: "16px",
  },
  commentContainer: {
    backgroundColor: "#f3f7fe",
    width: "calc(100% - 110px)",
    padding: "18px 20px 20px",
    borderRadius: "16px 16px 0px 16px",
    marginLeft: "62px",
  },
  commentText: {
    fontSize: "15px",
    fontStyle: "italic",
    lineHeight: 1.47,
    color: "#1a3586",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#4766b6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 600,
  },
  infoContent: {
    display: "flex",
    marginRight: "8px",
    width: "calc(100% - 110px)",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#868993",
  },
}));

const RightComment = ({ author: { fullName }, message, timestamp }) => {
  const classes = useStyles();
  const [name, lastName] = fullName.split(" ");
  return (
    <Box className={classes.container}>
      <Box className={classes.commentContainer}>
        <Typography className={classes.commentText}>{message}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Box className={classes.infoContent}>
          <Typography className={classes.content}>{timestamp}</Typography>
          <Typography className={classes.content}>{name}</Typography>
        </Box>
        <Box className={classes.avatar}>{`${name[0]}${lastName[0]}`}</Box>
      </Box>
    </Box>
  );
};

export default RightComment;
