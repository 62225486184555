import config from "config";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getConnectedApps = () => {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl}/integrations/salesforce/connected_apps/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ results, ...meta }) => [results, meta]);
};
