import React from "react";

const LoginIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={18}
      height={22}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5847 0.343874C17.3539 0.160541 17.077 0.0688739 16.777 0.114707L4.96162 2.06262C4.47701 2.13137 4.13086 2.54388 4.13086 3.02513V6.60012C4.13086 7.15012 4.56932 7.58554 5.12316 7.58554C5.67701 7.58554 6.11547 7.15012 6.11547 6.60012V3.85012L15.9693 2.22304V19.7314L6.11547 18.1043V15.3543C6.11547 14.8043 5.67701 14.3689 5.12316 14.3689C4.56932 14.3689 4.13086 14.8043 4.13086 15.3543V18.9293C4.13086 19.4105 4.47701 19.8231 4.96162 19.8918L16.777 21.8397C16.8232 21.8397 16.8924 21.8626 16.9386 21.8626C17.1693 21.8626 17.4001 21.771 17.5847 21.6335C17.8155 21.4501 17.9309 21.1751 17.9309 20.8772V1.05429C17.9309 0.802202 17.7924 0.527207 17.5847 0.343874Z"
        fill="#A4BEF1"
      />
      <path
        d="M8.35396 14.369C7.96165 14.7586 7.96165 15.3773 8.35396 15.744C8.53857 15.9273 8.79242 16.019 9.04626 16.019C9.30011 16.019 9.55396 15.9273 9.73857 15.744L13.8463 11.6648C14.2386 11.2752 14.2386 10.6565 13.8463 10.2898L9.73857 6.21064C9.34626 5.82106 8.72319 5.82106 8.35396 6.21064C7.96165 6.60023 7.96165 7.21898 8.35396 7.58564L10.777 9.99189H1.06164C0.507795 9.99189 0.0693359 10.4273 0.0693359 10.9773C0.0693359 11.5273 0.507795 11.9627 1.06164 11.9627H10.8001L8.35396 14.369Z"
        fill="#A4BEF1"
      />
    </svg>
  );
};

export default LoginIcon;
