import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  cookieCount: {
    marginTop: theme.spacing(-1.5),
  },
  tabs: {
    padding: "4px",
    marginBottom: "40px",
  },
}));
