import PageReportsWrapper from "components/PageReportsWrapper";
import { connect } from "react-redux";
import { getAccountData } from "redux/_account/account.selectors";
import { selectPlanInfo } from "redux/_plans/plans.selectors.js";
import AccountGeneral from "./components/General";

import useStyles from "./styles";
import { useEffect } from "react";
import { REPO_V2_URL } from "constants/RepoV2";

const AccountPage = (props) => {
  const classes = useStyles();

  return (
    <PageReportsWrapper
      title="Account"
      containerCustomClasses={classes}
      subpages={[
        {
          option: "General",
          title: "Basics",
          description: "Your basic information",
          Component: () => <AccountGeneral {...props} />,
        },
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  planInfo: selectPlanInfo(state),
});

export default connect(mapStateToProps)(AccountPage);
