import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { makeVisFlexible, RadialChart } from "react-vis";
import isEmpty from "lodash.isempty";
import Loader from "components/Loader";

const FlexibleRadialChart = makeVisFlexible(RadialChart);

const palette = [
  "#dd6666",
  "#2d7d75",
  "#73baac",
  "#c8c7aa",
  "#506b82",
  "#a78d79",
  "#d99d4a",
];

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    minWidth: "64px",
    minHeight: "64px",
  },
  refsContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  refsDataContainer: {
    width: "100%",
    height: "50%",
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  refContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  refPercentage: {
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: "bold",
    marginLeft: "4px",
    color: theme.palette.grey[200],
  },
  refText: {
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "22px",
    color: theme.palette.grey[200],
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    margin: theme.spacing(),
  },
}));

const PiiGroupsTooltip = (props) => {
  const classes = useStyles();
  const { data } = props;
  const total = isEmpty(data)
    ? 0
    : Object.keys(data).reduce((acum, key) => acum + data[key], 0);
  const parsedData = isEmpty(data)
    ? []
    : Object.keys(data)
        .sort((k1, k2) => data[k2] - data[k1])
        .map((key, idx) => ({
          theta: data[key],
          label: key,
          percentage: ((data[key] / total) * 100).toFixed(2),
          color: palette[idx],
        }));
  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {isEmpty(parsedData) ? (
        <Loader />
      ) : (
        <Box style={{ width: "100%", height: "100%", display: "flex" }}>
          <Box className={classes.chartContainer}>
            <FlexibleRadialChart
              innerRadius={20}
              radius={30}
              getAngle={(d) => d.theta}
              colorRange={palette}
              data={parsedData}
              labelsRadiusMultiplier={1.6}
              colorType="literal"
              labelsStyle={{
                fontSize: 12,
                color: "#4f4f4f",
                lineHeight: "16px",
                textTransform: "uppercase",
              }}
            />
          </Box>
          <Box className={classes.refsContainer}>
            <Box className={classes.refsDataContainer}>
              {parsedData.map(({ label, percentage, color }, idx) => (
                <Box key={idx} className={classes.refContainer}>
                  <Box
                    style={{ backgroundColor: color }}
                    className={classes.dot}
                  />
                  <Typography className={classes.refText} disaplay="inline">
                    {label}
                  </Typography>
                  <Typography
                    className={classes.refPercentage}
                    disaplay="inline"
                  >{` (${percentage}%)`}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PiiGroupsTooltip;
