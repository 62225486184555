import React, { useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { TextField, Button, Typography } from "@material-ui/core";

import inviterIllus from "assets/illustrations/illus_find.svg";

import useStyles from "./styles";

const Inviter = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (error) setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return setError("Please enter your email.");
    history.push({
      pathname: "/request-a-demo",
      search: `?e=${window.btoa(unescape(encodeURIComponent(email)))}`,
    });
  };

  return (
    <section className={clsx("inviter-section", classes.inviter)}>
      <div className="container">
        <div className="row no-gutters align-items-center">
          <div className="col-md-6 order-md-6">
            <Typography className={classes.text}>
              Interested in learning how to better manage your customers
              personal and sensitive data?
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                value={email}
                onChange={handleChange}
                className={classes.textField}
                variant="outlined"
                placeholder="Tell us your email"
                helperText={<span style={{ color: "#fff" }}>{error}</span>}
                InputProps={{
                  error: !!error,
                  className: classes.inputBase,
                  classes: {
                    notchedOutline: classes.inputOutline,
                  },
                }}
                inputProps={{
                  className: classes.input,
                }}
              />
              <Button
                className={classes.submit}
                variant="contained"
                type="submit"
              >
                Request a Demo
              </Button>
            </form>
          </div>
          <div
            className="col-md-4 order-md-4 offset-md-1"
            style={{ textAlign: "center", margin: "30px 30px 0 30px" }}
          >
            <img src={inviterIllus} alt=" " role="presentation" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Inviter);
