import { Box } from "@material-ui/core";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import React, { useMemo } from "react";
import { Field, FormSpy } from "react-final-form";
import { useSelector } from "react-redux";

import { FormTimeInput, FormSelectInput } from "../../components/inputs";
import SectionWrapper from "../../components/sectionWrapper";
import { days, executionTypes, timezones } from "../constants";

import { getEnvironmentSettingsSelected } from "redux/_settings/settings.selectors";

import useStyles from "./styles";

const Section3 = (props) => {
  const envSelected = useSelector((state) =>
    getEnvironmentSettingsSelected(state)
  );

  const classes = useStyles();
  const { databases, loading, selectedEnv } = props;
  const identity = (value) => value;

  const envDatabases = useMemo(
    () =>
      !isNil(databases) && !isEmpty(databases) && !isNil(selectedEnv)
        ? databases.filter(
            ({ environment_id }) =>
              parseInt(environment_id) === parseInt(selectedEnv)
          )
        : [],
    [databases, selectedEnv]
  );

  const isFieldDisabled =
    loading || isNil(envSelected) || envDatabases.length === 0;

  return (
    <SectionWrapper
      title="Schedule Scans"
      subtitle={
        envDatabases.length > 0
          ? "Set a window of time for when a scan may be performed. We recommend at least 4hs increments."
          : "You should add at least one database first in order to schedule an environment scan"
      }
    >
      <Box>
        <Box className={classes.row}>
          <Field
            name="execute"
            label="Execute"
            component={FormSelectInput}
            className={classes.fieldSm}
            disabled={isFieldDisabled}
            options={executionTypes}
            parse={identity}
            style={{ opacity: isFieldDisabled ? 0.6 : 1 }}
          />
          <FormSpy subscription={{ values: true }}>
            {({ values: { execute } }) => (
              <Field
                name="timezone"
                label="Timezone"
                helperText="Select a GMT timezone"
                component={FormSelectInput}
                className={classes.fieldSm}
                disabled={isFieldDisabled || execute === 3}
                options={timezones}
                parse={identity}
                style={{ opacity: isFieldDisabled || execute === 3 ? 0.6 : 1 }}
              />
            )}
          </FormSpy>
        </Box>
        <FormSpy subscription={{ values: true }}>
          {({ values: { execute } }) => (
            <>
              <Box className={classes.row}>
                <Field
                  name="fromDay"
                  label="From"
                  component={FormSelectInput}
                  className={classes.fieldSm}
                  disabled={isFieldDisabled || execute === 3}
                  helperText="Select date"
                  options={days}
                  parse={identity}
                  style={{
                    opacity: isFieldDisabled || execute === 3 ? 0.6 : 1,
                  }}
                />
                <Field
                  name="fromHour"
                  label="Hours"
                  helperText="Select hour"
                  component={FormTimeInput}
                  className={classes.fieldSm}
                  disabled={isFieldDisabled || execute === 3}
                  parse={identity}
                  style={{
                    opacity: isFieldDisabled || execute === 3 ? 0.6 : 1,
                  }}
                />
              </Box>
              <Box className={classes.row}>
                <Field
                  name="toDay"
                  label="To"
                  component={FormSelectInput}
                  className={classes.fieldSm}
                  disabled={isFieldDisabled || execute === 3}
                  helperText="Select date"
                  options={days}
                  parse={identity}
                  style={{
                    opacity: isFieldDisabled || execute === 3 ? 0.6 : 1,
                  }}
                />
                <Field
                  name="toHour"
                  label="Hours"
                  component={FormTimeInput}
                  className={classes.fieldSm}
                  disabled={isFieldDisabled || execute === 3}
                  helperText="Select hour"
                  parse={identity}
                  style={{
                    opacity: isFieldDisabled || execute === 3 ? 0.6 : 1,
                  }}
                />
              </Box>
            </>
          )}
        </FormSpy>
      </Box>
    </SectionWrapper>
  );
};

export default Section3;
