import React from "react";

const twitterIcon = (props) =>
  props.outlined ? (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1195 4.50827L22.5343 4.67349L21.8983 5.948C21.5882 6.56953 21.2778 7.19105 20.967 7.81258C20.9302 7.94422 20.8654 8.05962 20.7697 8.20987C20.7296 8.27265 20.5929 8.47236 20.5865 8.48194C20.5504 8.53608 20.5237 8.57878 20.5045 8.61299V11.0015C20.5045 17.1135 14.5895 20.9974 9.00354 20.9974C7.86051 20.9974 6.99207 20.9427 5.99765 20.7257C4.36115 20.3685 3.14327 19.6587 2.58597 18.418L2.01221 17.1407L3.40659 17.0124C4.66801 16.8964 5.76169 16.6561 6.60159 16.3343C4.29577 15.9635 3.0036 14.9508 3.0036 13.0489V12.0489H4.0036C4.22331 12.0489 4.42143 12.0311 4.59854 11.9983C2.868 10.9636 2.00122 9.30379 2.00122 7.00152C2.00103 6.9034 2.00103 6.90339 2.00044 6.79847C1.99394 5.63803 2.05627 5.01797 2.37395 4.22659C2.57754 3.71941 2.87183 3.24988 3.2679 2.81967L4.02251 2L4.75617 2.83847C7.17394 5.60161 9.56395 7.27795 12.0042 7.48072C12.0146 4.93105 13.9415 3.00152 16.5043 3.00152C17.6991 3.00152 18.7828 3.45501 19.6345 4.27273C20.1006 4.36851 20.5957 4.44709 21.1195 4.50827ZM18.9086 6.16202L18.6021 6.0926L18.3904 5.86028C17.8785 5.29855 17.2359 5.00152 16.5043 5.00152C15.0414 5.00152 14.0041 6.04391 14.0041 7.50152C14.0041 7.73974 13.998 7.88942 13.9683 8.08615C13.8499 8.87116 13.4096 9.50152 12.5041 9.50152C9.50607 9.50152 6.80136 7.89542 4.16389 5.15228C4.02792 5.56561 3.99595 5.99047 4.00041 6.78727C4.00101 6.89384 4.00101 6.89384 4.00122 7.00152C4.00122 9.04953 4.83093 10.1698 6.79547 10.7942L7.49255 11.0158V11.7472C7.49255 12.6342 6.65222 13.4691 5.42268 13.8431C5.98631 14.2708 7.139 14.5015 9.00389 14.5015H10.0039V15.5015C10.0039 16.9343 8.35762 18.0561 5.87075 18.6419C6.68178 18.8903 7.76166 18.9974 9.00354 18.9974C13.618 18.9974 18.5045 15.7888 18.5045 11.0015V8.50152C18.5045 8.20774 18.5897 7.95273 18.7311 7.68759C18.7865 7.58393 18.8474 7.48509 18.9225 7.37237C18.9367 7.35115 18.9892 7.27426 19.0309 7.21279L19.1101 7.05429C19.2386 6.79745 19.3669 6.54061 19.4952 6.28377C19.2958 6.24599 19.1003 6.20541 18.9086 6.16202Z"
        fill="#3F51B5"
      />
    </svg>
  ) : (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 1.90668C20.2281 2.22828 19.395 2.44652 18.5251 2.53841C19.4195 2.03302 20.0933 1.25197 20.4242 0.298636C19.591 0.758076 18.6721 1.10266 17.6919 1.27495C16.9078 0.493898 15.7806 0 14.5432 0C12.1663 0 10.2305 1.81479 10.2305 4.04307C10.2305 4.36468 10.2672 4.66332 10.3407 4.96195C6.76313 4.78966 3.5776 3.18162 1.45799 0.735104C1.09043 1.33238 0.869895 2.02154 0.869895 2.76813C0.869895 4.16942 1.62952 5.40991 2.79347 6.13352C2.08285 6.11055 1.42124 5.92678 0.845391 5.62814C0.845391 5.63963 0.845391 5.6626 0.845391 5.67408C0.845391 7.62671 2.32789 9.2692 4.30047 9.63675C3.93291 9.72864 3.55309 9.77459 3.16103 9.77459C2.87923 9.77459 2.60968 9.75162 2.35239 9.70567C2.90373 11.3137 4.4965 12.4853 6.38331 12.5083C4.91307 13.5879 3.05076 14.2426 1.02917 14.2426C0.686114 14.2426 0.343057 14.2197 0 14.1852C1.91132 15.3338 4.17795 16 6.60385 16C14.5309 16 18.8681 9.8435 18.8681 4.50251C18.8681 4.33022 18.8681 4.15793 18.8559 3.97416C19.689 3.43431 20.4242 2.72218 21 1.90668Z"
        fill="#3F51B5"
      />
    </svg>
  );

export default twitterIcon;
