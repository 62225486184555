import CONSTANTS from "./wizard.constants";

const initialState = {
  open: false,
  loading: false,
  environment: {
    id: null,
    name: "",
    unprotected: "",
  },
};

export const wizard = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.OPEN_WIZARD:
      return {
        ...state,
        open: true,
      };
    case CONSTANTS.CLOSE_WIZARD:
      return {
        ...state,
        open: false,
      };
    case CONSTANTS.SET_ENV_DATA:
      return {
        ...state,
        environment: action.data,
      };
    case CONSTANTS.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};
