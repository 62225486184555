import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  infoButton: {
    cursor: "pointer",
    display: "inline-block",
    transition: "all ease-in-out 250ms",
    position: "relative",
    background: "transparent",
    border: "none",
    borderRadius: "100%",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "1.8em",
      height: "1.8em",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "100%",
      opacity: 0,
    },
    "&:hover": {
      "&::after": {
        opacity: 0.3,
      },
    },
  },
  infoButtonClear: {
    color: theme.palette.white[200],
    "&::after": {
      background: theme.palette.white[200],
    },
    "&:hover": {
      color: theme.palette.white[200],
    },
  },
  infoButtonDark: {
    color: theme.palette.blue["young"],
    "&::after": {
      background: theme.palette.blue["young"],
    },
    "&:hover": {
      color: theme.palette.blue["young"],
    },
  },
}));
