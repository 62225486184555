import React from "react";

const databaseIcon = (props) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5539 26C18.2461 26 24.0063 24.7248 24.0063 22.2857V3.71429C24.0063 1.27524 18.2461 0 12.5539 0C6.8618 0 1.10156 1.27524 1.10156 3.71429V22.2857C1.10156 24.7248 6.8618 26 12.5539 26ZM12.5539 1.2381C19.2613 1.2381 22.7682 2.85071 22.7682 3.71429C22.7682 4.57786 19.2613 6.19048 12.5539 6.19048C5.84656 6.19048 2.33966 4.57786 2.33966 3.71429C2.33966 2.85071 5.84656 1.2381 12.5539 1.2381ZM2.33966 5.49405C4.33609 6.76929 8.46513 7.42857 12.5539 7.42857C16.6428 7.42857 20.7718 6.76929 22.7682 5.49405V9.90476C22.7682 10.7683 19.2613 12.381 12.5539 12.381C5.84656 12.381 2.33966 10.7683 2.33966 9.90476V5.49405ZM2.33966 11.6845C4.33609 12.9598 8.46513 13.619 12.5539 13.619C16.6428 13.619 20.7718 12.9598 22.7682 11.6845V16.0952C22.7682 16.9588 19.2613 18.5714 12.5539 18.5714C5.84656 18.5714 2.33966 16.9588 2.33966 16.0952V11.6845ZM2.33966 17.875C4.33609 19.1502 8.46513 19.8095 12.5539 19.8095C16.6428 19.8095 20.7718 19.1502 22.7682 17.875V22.2857C22.7682 23.1493 19.2613 24.7619 12.5539 24.7619C5.84656 24.7619 2.33966 23.1493 2.33966 22.2857V17.875Z"
      fill="#828282"
    />
    <path
      d="M19.3636 22.2857C19.8765 22.2857 20.2922 21.87 20.2922 21.3572C20.2922 20.8443 19.8765 20.4286 19.3636 20.4286C18.8508 20.4286 18.4351 20.8443 18.4351 21.3572C18.4351 21.87 18.8508 22.2857 19.3636 22.2857Z"
      fill="#828282"
    />
    <path
      d="M19.3636 16.0953C19.8765 16.0953 20.2922 15.6795 20.2922 15.1667C20.2922 14.6538 19.8765 14.2381 19.3636 14.2381C18.8508 14.2381 18.4351 14.6538 18.4351 15.1667C18.4351 15.6795 18.8508 16.0953 19.3636 16.0953Z"
      fill="#828282"
    />
    <path
      d="M19.3636 9.90476C19.8765 9.90476 20.2922 9.48902 20.2922 8.97618C20.2922 8.46335 19.8765 8.04761 19.3636 8.04761C18.8508 8.04761 18.4351 8.46335 18.4351 8.97618C18.4351 9.48902 18.8508 9.90476 19.3636 9.90476Z"
      fill="#828282"
    />
  </svg>
);

export default databaseIcon;
