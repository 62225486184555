import React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import Form from "components/FormComponents/Form";
import SVGIcon from "components/svg-icon/svg-icon";

import { updateBillingData as updateBillingDataAction } from "redux/_admin/_billing/billing.async.actions";

import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import useStyles from "./styles";

export const editBillingInfo = (enqueueSnackbar, updateBillingData) => async (
  data
) => {
  try {
    await updateBillingData(data);
    enqueueSnackbar("Billing data successfully updated!", {
      variant: "success",
    });
  } catch {
    enqueueSnackbar(
      "An error ocurred updating your billing data. Please try later.",
      {
        variant: "error",
      }
    );
  }
};

const BillingInfo = ({ account, updateBillingData }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { payment_method: paymentMethod } = account.data;
  const disabled = !!!paymentMethod;

  const onSubmit = editBillingInfo(enqueueSnackbar, updateBillingData);

  return (
    <div className={classes.container}>
      <div className={classes.backgroundIcon}>
        <SVGIcon icon="lock" />
      </div>
      <Form onSubmit={onSubmit}>
        <Section2 {...account} disabled={disabled} />
        <Section3 {...account} disabled={disabled} />
      </Form>
    </div>
  );
};

const mapStateToProps = ({ account }) => ({ account });
const mapDispatchToProps = (dispatch) => ({
  updateBillingData: (data) => dispatch(updateBillingDataAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
