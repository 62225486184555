import { CONSTANTS } from "redux/_consents/_overview/overview.constants";

export const requestStart = () => ({
  type: CONSTANTS.CONSENTS_OVERVIEW_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.CONSENTS_OVERVIEW_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.CONSENTS_OVERVIEW_SAVE_DATA,
  data,
});

export const saveKpis = (data) => ({
  type: CONSTANTS.CONSENTS_OVERVIEW_SAVE_KPIS,
  data,
});
