import PageWrapper, { Tab, Tabs } from "components/PageWrapper";
import Configuration from "components/PolicyGenerator/Configuration";
import Cookies from "components/PolicyGenerator/Cookies";
import Policy from "components/PolicyGenerator/Policy";
import useQuery from "hooks/useQuery";
import useReports from "hooks/useReports";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  createCookiePolicy,
  getCookiePolicies,
} from "redux/_admin/_company/company.async.actions";
import { getConfig } from "redux/_consents/_config/config.async.actions";
import { getReportJSON as fetchReportJSON } from "redux/_reports/reports.async.actions";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";

const ConsentsCollector = ({
  account,
  cookies,
  createCookie,
  currentReportJSON,
  getConfigData,
  getCookies,
  getReportJSON,
}) => {
  let history = useHistory();
  const [value, setValue] = useState(global.location.pathname.split("/").pop());

  const { selected: report, ...reports } = useReports();
  const query = useQuery();
  const reportId = query.get("r");

  const onTabChange = (value) => {
    setValue(value);
    history.push(
      `/zen/consents/policy-generator/${value}${window.location.search}`
    );
  };

  useEffect(() => {
    if (!currentReportJSON.records) return;
    if (!reportId) return;
    getCookies(reportId).then(({ data: cookies }) => {
      if (cookies && cookies.length > 0) return;
      currentReportJSON.records.forEach((record) => {
        if (record.cookies)
          record.cookies.forEach(({ name, expires }) => {
            createCookie({
              company: account.company.id,
              report: reportId,
              name,
              provider: "",
              expiration: expires,
              purpose: record.category,
            });
          });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportJSON]);

  useEffect(() => {
    getConfigData(reportId);
  }, [getConfigData, reportId]);

  useEffect(() => {
    if (reportId) reports.get(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    if (report.data) getReportJSON(report.data);
  }, [report, getReportJSON]);

  return (
    <PageWrapper
      title=""
      subtitle="COOKIE POLICY GENERATOR"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"configuration"} label="Configuration" />
          <Tab value={"cookies"} label="Cookies" />
          <Tab value={"policy"} label="Policy" />
        </Tabs>
      }
    >
      <Switch>
        <Route path={`/zen/consents/policy-generator/configuration`}>
          <Configuration />
        </Route>
        <Route path={`/zen/consents/policy-generator/cookies`}>
          <Cookies />
        </Route>
        <Route path={`/zen/consents/policy-generator/policy`}>
          <Policy />
        </Route>
      </Switch>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.data,
  cookies: state.admin.company ? state.admin.company.cookies : [],
  currentReportJSON: getCurrentReportJSON(state),
});

const mapDispatchToProps = (dispatch) => ({
  createCookie: (values) => dispatch(createCookiePolicy(values)),
  getConfigData: (x) => dispatch(getConfig(x)),
  getCookies: (values) => dispatch(getCookiePolicies(values)),
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsCollector);
