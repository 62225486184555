import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { rrulestr } from "rrule";

import {
  createScheduler,
  deleteSchedulers,
  listSchedulers,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";

import PageReportsWrapper from "components/PageReportsWrapper";
import SchedulingForm from "components/Scheduling/Form";
import SchedulingList from "components/Scheduling/WebList";

const SchedulingPage = ({
  createSchedule,
  fetchSchedulers,
  removeSchedule,
  schedulers,
}) => {
  const [initialValues, setInitialValues] = useState({});

  const setCurrentSchedule = (id) => {
    const current = schedulers.filter((scheduler) => scheduler.id === id)[0];
    const rrule = rrulestr(current.recurrence);
    const {
      options: { interval, freq, bymonthday, byweekday, until },
    } = rrule;
    setInitialValues({
      ...current,
      interval,
      freq,
      dayOfMonth: bymonthday[0],
      byweekday,
      until: String(until).substr(4, 11).replace(" 2", ", 2"),
    });
  };

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageReportsWrapper
      title="Scans"
      subpages={[
        {
          option: "Create new",
          title:
            "Select a scan select. We recommend at least 4-hour increments.",
          Component: SchedulingForm,
          props: { initialValues, onSubmit: createSchedule },
        },
        {
          sectionToMove: 0,
          option: "Scheduled Scans",
          title: "List of Scheduled scans",
          description: "Here are the last scan you scheduled",
          Component: SchedulingList,
          props: {
            data: schedulers,
            removeSchedule,
            selectSchedule: setCurrentSchedule,
          },
        },
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  schedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  createSchedule: (data) => dispatch(createScheduler(data)),
  removeSchedule: (id) => dispatch(deleteSchedulers(id)),
  fetchSchedulers: () => dispatch(listSchedulers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingPage);
