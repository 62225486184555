import { SET_SHARING_DETAILS_INFO } from "./sharingDetails.actions";

const initialState = {
    details: null,
};

export const sharingDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHARING_DETAILS_INFO:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};