import React from "react";
import "./page-info.scss";

import SvgIcon from "components/svg-icon/svg-icon";

function PageInfo({ date, onExport, onShare }) {
  return (
    <div className="page-info">
      <p className="page-info__lastscan">
        Last scan made on: {new Date().toDateString()}
      </p>

      <div className="page-info__actions">
        <button
          className="page-info__button bt page-info__button--export"
          onClick={onExport}
        >
          <SvgIcon icon="download" />
          Export
        </button>
        <button
          className="page-info__button bt page-info__button--share"
          onClick={onShare}
        >
          <SvgIcon icon="share" />
          Share
        </button>
      </div>
    </div>
  );
}

export default PageInfo;
