import { REPORT_CATEGORIES, REPORT_VERSION } from "constants/reports";

export const isApp = (reportVersion) =>
  reportVersion === REPORT_VERSION.app ||
  reportVersion === REPORT_VERSION.freeApp ||
  reportVersion === REPORT_VERSION.demoApp;

export const isSitemap = (reportVersion) =>
  reportVersion === REPORT_VERSION.sitemap ||
  reportVersion === REPORT_VERSION.freeSitemap ||
  reportVersion === REPORT_VERSION.demoSitemap;

export const isPage = (reportVersion) =>
  reportVersion === REPORT_VERSION.page ||
  reportVersion === REPORT_VERSION.freePage ||
  reportVersion === REPORT_VERSION.demoPage;

export const getReportUrl = (reportId, reportVersion) => {
  const reportCategory = isApp(reportVersion)
    ? REPORT_CATEGORIES.app
    : reportVersion === "repository"
    ? REPORT_CATEGORIES.repository
    : !reportVersion
    ? REPORT_CATEGORIES.security
    : (isPage(reportVersion) || reportVersion === REPORT_CATEGORIES.web) &&
      REPORT_CATEGORIES.web;

  if (reportCategory === REPORT_CATEGORIES.app) {
    return `/app-report?r=${reportId}&c=${reportCategory}`;
  }
  if (reportCategory === REPORT_CATEGORIES.security) {
    return `/security-report?r=${reportId}&c=${REPORT_CATEGORIES.security}`;
  }
  if (reportCategory === REPORT_CATEGORIES.repository) {
    return `/respository-results?r=${reportId}&c=${REPORT_CATEGORIES.repository}`;
  }
  return `/privacy-report?r=${reportId}&c=${reportCategory}`;
};

const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/;

export const getJSONUUIDFrom = (jsonUrl) => {
  return UUID_REGEX.exec(jsonUrl);
};
