import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Page, View } from "@react-pdf/renderer";

const PageContent = ({ children, ...props }) => {
  return (
    <View style={{ padding: "0 1cm" }} {...props}>
      {children}
    </View>
  );
};

const CustomPage = ({
  style: pageStyles,
  children,
  title,
  subtitle,
  reportLabel,
  branding,
  content,
  ...props
}) => {
  return (
    <Page size="A4" style={[pageStyles, { position: "relative" }]} {...props}>
      <Header
        title={title}
        subtitle={subtitle}
        reportLabel={reportLabel}
        logo={branding}
      />
      <PageContent>{children}</PageContent>
      <Footer logo={branding} content={content} />
    </Page>
  );
};

export default CustomPage;
