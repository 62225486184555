import React from "react";
import { Hidden, Typography } from "@material-ui/core";

import HomepageMenu from "components/HomepageMenu";
import PublicNav from "components/PublicComponents/Nav";
import Footer from "components/PublicComponents/Footer";

import ZendataEmail from "assets/images/zendata-email.png";

import useStyles from "./styles.js";

const Congrats = () => {
  const classes = useStyles();
  return (
    <>
      <div id="outer-container">
        <Hidden mdUp>
          <HomepageMenu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
        </Hidden>
        <div id="page-wrap" className={classes.root}>
          <PublicNav />
          <div className={classes.content}>
            <div className={classes.elements}>
              <Typography variant="h2">Congratulations, you did it!</Typography>
              <Typography variant="body1">
                Please check your email for your temporary login credentials.
                Depending upon your email provider it may take a few minutes.
              </Typography>
              <img src={ZendataEmail} alt="zendata-email" />
            </div>
          </div>
        </div>
        <Footer backgroundColor="white" />
      </div>
    </>
  );
};

export default Congrats;
