import { Route, Switch } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import  PIISharingDetailsView  from "./PIISharingDetailsView";


const PIISharingDetails = () => {
  
  return (
    <PageWrapper subtitle={"PII details"}>
      <Switch>
        <Route path={"/zen/pii-sharing-details/:scan_id/"}>
          <PIISharingDetailsView/>
        </Route>
      </Switch>
    </PageWrapper>
  );
};

export default PIISharingDetails;