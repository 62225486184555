export const CONSTANTS = {
  SETTINGS_SELECT_ENTITY: "SETTINGS_SELECT_ENTITY",
  SETTINGS_SAVE_DATA: "SETTINGS_SAVE_DATA",
  SETTINGS_REQUEST_START: "SETTINGS_REQUEST_START",
  SETTINGS_REQUEST_END: "SETTINGS_REQUEST_END",
  SETTINGS_SAVE_DB_TABLES_DATA: "SETTINGS_SAVE_DB_TABLES_DATA",
  SETTINGS_GET_DB_TABLES_DATA: "SETTINGS_GET_DB_TABLES_DATA",
  SETTINGS_SET_DB_TABLES_FILTERS: "SETTINGS_SET_DB_TABLES_FILTERS",
  SETTINGS_RESET_DB_TABLES_FILTERS: "SETTINGS_RESET_DB_TABLES_FILTERS",
  SETTINGS_ST_DB_TABLE_FID: "SETTINGS_ST_DB_TABLE_FID",
  SETTINGS_ST_DB_TABLE_BUSINESS_UNIT_ID:
    "SETTINGS_ST_DB_TABLE_BUSINESS_UNIT_ID",
  SETTINGS_ST_DB_TABLE_POLICY_ID: "SETTINGS_ST_DB_TABLE_POLICY_ID",
  SETTINGS_SAVE_INITIAL_SETUP: "SETTINGS_SAVE_INITIAL_SETUP",
  SETTINGS_INITIAL_SETUP_REQUEST_START: "SETTINGS_INITIAL_SETUP_REQUEST_START",
  SETTINGS_INITIAL_SETUP_REQUEST_END: "SETTINGS_INITIAL_SETUP_REQUEST_END",
};

export const ENTITIES = {
  ENVIRONMENT: "ENVIRONMENT",
  DATASOURCE: "DATASOURCE",
  DATABASE: "DATABASE",
};

export const TABLE_FILTERS = {
  SEARCH: "SEARCH",
  HIDE_WITH_DEPARTMENTS: "HIDE_WITH_DEPARTMENTS",
};
