import { camelize } from "humps";
import React, { useState } from "react";
import clsx from "clsx";

import Pagination from "components/Pagination";
import usePaginationStyles from "components/Pagination/styles";
import Tracker from "./Tracker";
import { AmountTagsCard } from "./AmountTagsCard";
import { AmountLoadTimeTagsCard } from "./AmountLoadTimeTagsCard";
import { StatusCodeTagsCard } from "./StatusCodeTagsCard";
import useStyles from "./styles";

const LIMIT_TRACKERS = 3;

const getPercentage = (amount, total) => { 
  return amount / total * 100;
}

const getStatisticsFromTags = (trackers) => {
  let amountOfTags = 0;
  let amountOfLoadTimeLessThan500= 0;
  let amountOfLoadTimeLessThan999= 0;
  let amountOfLoadTimeLessThan1999= 0;
  let amountOfLoadTimeMoreThan2000= 0;
  let amountOfSuccess = 0;
  let amountOfFailures = 0;
  let amountOfRedirects = 0;
  let uniqueTrackers = [];

  trackers.forEach(function (tracker) {
    tracker.trackersDetails?.forEach(function (tracker) {
      const trackerUrl = tracker.url.replace(/(\?|\=).*/g, "");
      if (!uniqueTrackers.includes(trackerUrl))
        uniqueTrackers.push(trackerUrl);
      amountOfTags++;
      const statusCode = tracker.response.status;
      switch (statusCode) {
        case 200:
          amountOfSuccess++;
          break;
        case 500:
          amountOfFailures++;
          break;
        default:
          amountOfRedirects++;
      }

      const timeResponse = tracker.response.timeResponse;
      switch (true) {
        case (timeResponse < 500):
          amountOfLoadTimeLessThan500++;
          break;
        case (timeResponse < 999):
          amountOfLoadTimeLessThan999++;
          break;
        case (timeResponse < 1999):
          amountOfLoadTimeLessThan1999++;
          break;
        default:
          amountOfLoadTimeMoreThan2000++;
          break;
      }
    });
  });

  return {
    totalOfTags: amountOfTags,
    uniqueTags: uniqueTrackers.length,
    loadTimeLessThan500: {
      totalOfLoadTimeLessThan500: amountOfLoadTimeLessThan500,
      percentage: getPercentage(amountOfLoadTimeLessThan500, amountOfTags),
    },
    loadTimeLessThan999: {
      totalOfLoadTimeLessThan999: amountOfLoadTimeLessThan999,
      percentage: getPercentage(amountOfLoadTimeLessThan999, amountOfTags),
    },
    loadTimeLessThan1999: {
      totalOfLoadTimeLessThan1999: amountOfLoadTimeLessThan1999,
      percentage: getPercentage(amountOfLoadTimeLessThan1999, amountOfTags),
    },
    loadTimeMoreThan2000: {
      totalOfLoadTimeMoreThan2000: amountOfLoadTimeMoreThan2000,
      percentage: getPercentage(amountOfLoadTimeMoreThan2000, amountOfTags),
    },
    success: {
      totalOfSuccess: amountOfSuccess,
      percentage: getPercentage(amountOfSuccess, amountOfTags),
    },
    failures: {
      totalOfFailures: amountOfFailures,
      percentage: getPercentage(amountOfFailures, amountOfTags),
    },
    redirects: {
      totalOfRedirects: amountOfRedirects,
      percentage: getPercentage(amountOfRedirects, amountOfTags),
    }
  };
};

const Trackers = ({
  title,
  data,
  environment,
  onFilter,
  email,
  domainsMetadata = {},
  reportId,
}) => {
  const paginationClasses = usePaginationStyles();
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const [filtered, setFiltered] = useState([]);

  const trackers = data || [];

  const total = Math.ceil(trackers.length / LIMIT_TRACKERS);
  const start = current * LIMIT_TRACKERS;
  const end = start + LIMIT_TRACKERS;
  const statistics = getStatisticsFromTags(trackers);


  const handleCategoryClick = (event) => {
    const category = event.target.innerHTML;
    const index = filtered.indexOf(category);
    let newFiltered = filtered;
    if (index > -1) {
      newFiltered.splice(index, 1);
    } else {
      newFiltered.push(category);
    }

    setFiltered(newFiltered);
    onFilter(newFiltered);
  };

  return (
    <div className={classes.trackers}>
      <table className={classes.xsTable}>
        <tbody>
          {trackers.slice(start, end).map((tracker, index) => (
            <Tracker
              key={`${tracker.domain}-${index}`}
              domainMetadata={
                !!domainsMetadata
                  ? domainsMetadata[camelize(tracker.owner)]
                  : {}
              }
              {...tracker}
              environment={environment}
              filtered={filtered}
              handleCategoryClick={handleCategoryClick}
              reportId={reportId}
            />
          ))}
        </tbody>
      </table>
      <table>
        <tbody>
          <tr className={classes.cardTr}>
            <td className={classes.cardTd}>
              <AmountTagsCard
                titleAmountRequests={"TOTAL TAG REQUESTS"}
                amountRequests={statistics.totalOfTags}
                titleUniqueTags={"UNIQUE TAGS"}
                amountUniqueTags={statistics.uniqueTags}
              />
            </td>
            <td className={classes.cardTd}>
              <AmountLoadTimeTagsCard statistics={statistics}/>
            </td>
            <td className={classes.cardTd}>
              <StatusCodeTagsCard statistics={statistics} />
            </td>
          </tr>
        </tbody>
      </table>
      <table className={clsx(classes.table, classes.mdTable)}>
        <thead className={classes.head}>
          <tr className={classes.tr}>
            <th className={clsx(classes.th, classes.domain, environment)}>
              Domain
            </th>
            <th className={clsx(classes.th, classes.provider, environment)}>
              Provider
            </th>
            <th className={clsx(classes.th, classes.categories, environment)}>
              Categories
            </th>
            {environment === "web" && (
              <th className={clsx(classes.th, classes.tags)}>Tags</th>
            )}
          </tr>
        </thead>
        <tbody>
          {trackers.slice(start, end).map((tracker, index) => (
            <Tracker
              key={`${tracker.domain}-${index}`}
              domainMetadata={
                !!domainsMetadata
                  ? domainsMetadata[camelize(tracker.owner)]
                  : {}
              }
              {...tracker}
              environment={environment}
              filtered={filtered}
              handleCategoryClick={handleCategoryClick}
              reportId={reportId}
            />
          ))}
        </tbody>
      </table>
      {end > 1 && (
        <Pagination
          className={paginationClasses.pagination}
          current={current}
          total={total}
          setCurrent={setCurrent}
        />
      )}
    </div>
  );
};

export default Trackers;
