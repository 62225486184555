import { Box } from "@material-ui/core";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import Form from "components/FormComponents/Form";
import EntitySelector from "components/SettingsSections/EntitySelector";
import Section1 from "components/SettingsSections/Environment/sections/section1";
import Section2 from "components/SettingsSections/Environment/sections/section2";
import Section3 from "components/SettingsSections/Environment/sections/section3";

import {
  createEnvironment,
  deleteEnvironment,
  updateEnvironment,
} from "redux/_environments/environment.async.actions";
import {
  getFilteredEnvsData,
  getEnvsLoadingState,
} from "redux/_environments/environment.selectors";
import { saveData, selectData } from "redux/_settings/settings.actions";
import { ENTITIES } from "redux/_settings/settings.constants";
import {
  getEnvironmentSettingsData,
  getEnvironmentSettingsSelected,
  getSettingsLoadingState,
} from "redux/_settings/settings.selectors";
import { updateDatabase } from "redux/_databases/databases.async.actions";
import { getDatabasesData } from "redux/_databases/databases.selectors";

import useStyles from "./styles";

const EnvironmentSettings = (props) => {
  const classes = useStyles();

  const {
    envList,
    selected,
    setSelected,
    match,
    location,
    history,
    setData,
    data,
    update,
    deleteEntity,
    dbList,
    create,
    updateDatabases,
    envLoading,
  } = props;

  useEffect(() => {
    const env_id = location.pathname.replace(match.path, "").split("/")[1];
    if (env_id && selected !== env_id) setSelected(env_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (selected) {
        history.replace(`/zen/settings/environments/${selected}`);
        setData(envList.find(({ id }) => parseInt(id) === parseInt(selected)));
      } else {
        history.replace(`/zen/settings/environments/`);
        setData({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  useEffect(
    () => {
      if (!isNil(selected) && isEmpty(data))
        setData(envList.find(({ id }) => parseInt(id) === parseInt(selected)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [envList]
  );

  const onSubmit = async (values) => {
    if (selected) {
      try {
        const newData = await update(values);
        setData(newData);
      } catch (errors) {
        return errors;
      }
    } else {
      try {
        await create(values);
      } catch (errors) {
        return errors;
      }
    }
  };

  const onDelete = () =>
    deleteEntity(selected).then((_) => {
      const restEnvs = envList.filter((env) => env.id !== selected);
      setSelected(restEnvs.length > 0 ? restEnvs[0].id : null);
    });

  const getTimezone = () => {
    return -(new Date().getTimezoneOffset() / 60) + 12;
  };

  const initialValues = {
    execute: 3,
    fromHour: "12:00",
    toHour: "12:00",
    timezone: getTimezone(),
    ...data,
  };

  return (
    <Box className={classes.container}>
      <EntitySelector
        entityName="Environment"
        entities={envList.map((env) => ({
          ...env,
          icon: "enviroment",
        }))}
        selected={selected}
        onSelect={setSelected}
        newLabel="New Env"
      />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        showSubmit={!selected}
        submitLabel={!selected ? "Create" : "Update"}
        loading={envLoading}
      >
        <Section1
          showDeleteButton={!isNil(selected)}
          onDeleteClick={onDelete}
        />
        <Section2
          databases={dbList}
          selectedEnv={selected}
          updateEnvDatabases={updateDatabases}
        />
        <Section3 databases={dbList} selectedEnv={selected} />
      </Form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  envList: getFilteredEnvsData(state),
  selected: getEnvironmentSettingsSelected(state),
  data: getEnvironmentSettingsData(state),
  loading: getSettingsLoadingState(state),
  dbList: getDatabasesData(state),
  envLoading: getEnvsLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelected: (id) => dispatch(selectData(ENTITIES.ENVIRONMENT, id)),
  setData: (data) => dispatch(saveData(ENTITIES.ENVIRONMENT, data)),
  update: (data) => dispatch(updateEnvironment(data)),
  deleteEntity: (id) => dispatch(deleteEnvironment(id)),
  create: (data) => dispatch(createEnvironment(data)),
  updateDatabases: (data) => dispatch(updateDatabase(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentSettings);
