import React from "react";
import PropTypes from "prop-types";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { Tooltip, Typography, Box } from "@material-ui/core";
import useStyles from "../styles";

const OpenRepository = ({ text, onClick }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Open modal">
      <Box
        className={classes.clickableContainer}
        style={{ maxWidth: 400 }}
        onClick={onClick}
      >
        <Typography
          variant="body2"
          className={classes.clickableText}
          noWrap={true}
          style={{
            textOverflow: "ellipsis",
          }}
          fontWeight="bold"
        >
          {text}
        </Typography>
        <CallMadeIcon />
      </Box>
    </Tooltip>
  );
};

OpenRepository.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OpenRepository;
