import { Text, View, Svg, Path } from "@react-pdf/renderer";
import { ReactComponent as HighIcon } from "../../../pages/AppReport/Information_tab.svg";
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";

export const severities = [
  {
    label: "HIGH",
    value: "high",
    icon: <HighIcon />,
  },
  {
    label: "MEDIUM",
    value: "medium",
    icon: <ReportProblemOutlined style={{ color: "#ED6C02" }} />,
  },
  {
    label: "INFO",
    value: "info",
    icon: <InfoOutlined />,
  },
  {
    label: "SECURE",
    value: "secure",
    icon: <CheckCircleOutlineOutlined />,
  },
];

const SharedLibraryBinaryAnalysisPage = ({ styles, data, appType }) => {
  return (
    <View style={styles.section}>
      <View style={styles.section}>
        <Text style={styles.title}>Shared Library Binary Analysis</Text>
        <Text style={styles.subTitle}>
          When it comes to static code analysis and privacy concerns, here's how
          shared library binaries are relevant:
        </Text>
        <Text style={styles.text}>
          - Third-party Integrations: Apps often use shared libraries for
          functionalities that are commonly required, such as networking, image
          processing, data storage, etc. If these libraries have vulnerabilities
          or malicious code, it can compromise user data.
        </Text>
        <Text style={styles.text}>
          - Unwanted Data Collection: Some shared libraries, especially those
          related to analytics, advertising, or telemetry, might collect user
          data. Analyzing these binaries can reveal if they access or send data
          that might not be obvious at the application layer, leading to
          potential privacy breaches.
        </Text>
        <Text style={styles.text}>
          - Unwanted Data Collection: Some shared libraries, especially those
          related to analytics, advertising, or telemetry, might collect user
          data. Analyzing these binaries can reveal if they access or send data
          that might not be obvious at the application layer, leading to
          potential privacy breaches.
        </Text>
        <Text style={styles.text}>
          - Native Code Execution: Given that these libraries typically contain
          native code, they can execute functions outside the standard app
          sandbox. If not securely implemented, this can lead to potential
          privacy and security issues, such as buffer overflows or unauthorized
          data access.
        </Text>
        <Text style={styles.text}>
          - Data Encryption and Handling: Libraries used for cryptography or
          data storage need thorough inspection. A weak encryption method or
          vulnerability in a data-handling library can expose user data.{" "}
        </Text>
        <Text style={styles.text}>
          - Obfuscation and Concealment: Malicious apps sometimes use shared
          library binaries to hide malicious functionality, assuming that many
          analysis tools focus more on the bytecode or high-level code. By
          diving into shared libraries, hidden behaviors that might compromise
          privacy can be detected.
        </Text>
        <Text style={styles.text}>
          - Reverse Engineering: Although shared libraries are precompiled,
          tools like IDA Pro, Ghidra, or Radare2 can reverse-engineer them to
          inspect their behavior. This allows for a deeper inspection of what
          the library is doing, especially regarding data handling and
          communication.
        </Text>
      </View>
      {appType !== "apk" ? (
        <View>
          <View>
            <View style={styles.table}>
              <View style={styles.thead} fixed>
                <View style={styles.tr}>
                  <View style={[styles.thCustomWidth, { width: "15%" }]}>
                    <Text>PROTECTION</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "10%" }]}>
                    <Text>STATUS</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "15%" }]}>
                    <Text>SEVERITY</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "60%" }]}>
                    <Text>DESCRIPTION</Text>
                  </View>
                </View>
              </View>
              {Object.entries(data.macho_analysis).map(([key, value]) => {
                const statusStyles = {
                  info: {
                    backgroundColor: "#466DE8",
                  },
                  warning: {
                    backgroundColor: "#EF7D1F",
                  },
                  high: {
                    backgroundColor: "#D32F2F",
                  },
                  secure: {
                    backgroundColor: "#2E7D32",
                  },
                  good: {
                    backgroundColor: "#2E7D32",
                  },
                };

                const statusStyle = statusStyles[value.severity] || {};

                const property = data.macho_analysis[key];
                const firstPropertyValue = property[Object.keys(property)[0]];

                return (
                  key !== "name" && (
                    <View style={styles.tr} key={key} wrap={false}>
                      <View style={[styles.tdCustomWidth, { width: "15%" }]}>
                        <Text style={styles.text}>{key}</Text>
                      </View>
                      <View style={[styles.tdCustomWidth, { width: "10%" }]}>
                        {firstPropertyValue === true ? (
                          <Svg viewBox="0 0 24 24" width="16px" weight="16px">
                            <Path
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                              fill="#468C49"
                            />
                          </Svg>
                        ) : (
                          <Svg viewBox="0 0 24 24" width="16px" weight="16px">
                            <Path
                              d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                              fill="#d32f2f"
                            />
                          </Svg>
                        )}
                      </View>
                      <View style={[styles.tdCustomWidth, { width: "15%" }]}>
                        <Text style={[statusStyle, styles.severityText]}>
                          {value.severity}
                        </Text>
                      </View>
                      <View style={[styles.tdCustomWidth, { width: "60%" }]}>
                        <Text style={styles.text}>{value.description}</Text>
                      </View>
                    </View>
                  )
                );
              })}
            </View>
          </View>
        </View>
      ) : (
        <View>
          {data.binary_analysis > 0 ? (
            <View style={styles.table}>
              <View style={styles.thead} fixed>
                <View style={styles.tr}>
                  <View style={[styles.thCustomWidth, { width: "20%" }]}>
                    <Text>SHARED OBJECTS</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "10%" }]}>
                    <Text>NX</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "20%" }]}>
                    <Text>STACK CANARY</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "14%" }]}>
                    <Text>RPATH</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "14%" }]}>
                    <Text>RUNPATH</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "14%" }]}>
                    <Text>FORTIFY</Text>
                  </View>
                  <View style={[styles.thCustomWidth, { width: "14%" }]}>
                    <Text>SYMBOL</Text>
                  </View>
                </View>
              </View>
              {data.binary_analysis.map((lib) => {
                return (
                  <View style={styles.tr}>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.name}</Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.nx.description}</Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>
                        {lib.stack_canary.description}
                      </Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.rpath.description}</Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.runpath.description}</Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.fortify.description}</Text>
                    </View>
                    <View style={styles.td}>
                      <Text style={styles.text}>{lib.symbol.description}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          ) : (
            <View
              style={[
                styles.tr,
                {
                  border: "1px solid #dfe2e9",
                  padding: 5,
                  marginBottom: 20,
                  display: "flex",
                  justifyContent: "center",
                },
              ]}
            >
              <Text style={styles.text}>
                No Shared Libraries found for this report
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default SharedLibraryBinaryAnalysisPage;
