import { Text, View } from "@react-pdf/renderer";
import { PIITablePage } from "components/PrivacyReports/Report/sections/PII/PIITable";

const PiiPage = ({ data, styles: globalStyles }) => (
  <View style={globalStyles.section}>
    <View>
      <Text style={globalStyles.title}>Third-party data flow map</Text>
      <Text>
        Understand PII being shared with live third-party integrations on your
        website/app
      </Text>
    </View>
    <View style={globalStyles.table}>
      <PIITablePage data={data} styles={globalStyles} />
    </View>
  </View>
);

export default PiiPage;
