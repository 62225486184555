import { CONSTANTS } from "redux/_consents/_applications/applications.constants";

export const requestStart = () => ({
  type: CONSTANTS.APPLICATIONS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.APPLICATIONS_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.APPLICATIONS_SAVE_DATA,
  data,
});

export const deleteData = (data) => ({
  type: CONSTANTS.APPLICATIONS_DELETE,
  data,
});
