import React from "react";
import Link from "@material-ui/core/Link";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const FTU_LEARN_MORE = "/zen/ftu/PII-FAQS";

const LearnMoreLink = () => {
  return (
    <Link
      href={FTU_LEARN_MORE}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        fontFamily: "Inter",
        fontSize: 14,
        color: "#466DE8",
        textDecoration: "underline",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 4,
      }}
    >
      Learn more
    </Link>
  );
};

export default LearnMoreLink;
