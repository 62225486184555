import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import SVGIcon from "components/svg-icon/svg-icon";

const useStyles = makeStyles((theme) => ({
  "@keyframes growBar": {
    from: { width: 0 },
  },
  barContainer: {
    backgroundColor: ({ dark }) =>
      dark ? theme.palette.blue[700] : fade(theme.palette.blue[500], 0.2),
    boxShadow: "2px 4px 8px rgba(26, 53, 134, 0.15)",
    borderRadius: 4,
  },
  bar: {
    background: "linear-gradient(180deg, #3AFF8E -26.03%, #2BA24C 100%)",
    boxShadow: "1px 1px 4px rgba(65, 231, 190, 0.3)",
    borderRadius: 4,
    transition: "width .5s ease-in",
    animationName: "$growBar",
    animationDuration: ".5s",
    animationTimingFunction: "ease-in",
  },
  text: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: ({ dark }) => (dark ? 15 : 18),
    lineHeight: ({ dark }) => (dark ? "18px" : "21px"),
    color: ({ dark }) =>
      dark ? theme.palette.common.white : theme.palette.grey[800],
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: ({ dark }) =>
        dark ? theme.palette.common.white : theme.palette.grey[800],
      textDecoration: "none",
    },
    "& svg": {
      width: 16,
      height: 16,
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));

const ProgressBar = ({
  progress = 0,
  dark,
  text = "Setup Completed",
  link,
}) => {
  const classes = useStyles({ dark });
  return (
    <>
      <Box mb={1} width="100%" height={24} className={classes.barContainer}>
        <Box width={`${progress}%`} height={24} className={classes.bar}></Box>
      </Box>
      {link ? (
        <Link className={classes.text} to={link}>
          {progress}% {text}
          <SVGIcon icon="chevronRight" />
        </Link>
      ) : (
        <Typography className={classes.text}>
          {progress}% {text}
        </Typography>
      )}
    </>
  );
};

export default ProgressBar;
