export const providers = [
  {
    value: 0,
    label: "Amazon",
  },
  {
    value: 1,
    label: "Microsoft Azure",
  },
  {
    value: 2,
    label: "My SQL",
  },
  {
    value: 3,
    label: "MongoDB",
  },
];
