import CONSTANTS from "./filters.constants";
import { createSelector } from "reselect";
import isNil from "lodash.isnil";
import isEmpty from "lodash.isempty";
import moment from "moment";

const { TYPES } = CONSTANTS;

export const getFilters = (state) => state.filters.selected;

export const getFilterScan = (state) => state.filters.selected[TYPES.SCAN];

export const getFiltersScansLoading = (state) =>
  state.filters.loading[TYPES.SCAN];

const getFilterScansData = (state) => state.filters.data[TYPES.SCAN];

export const getFilterScans = createSelector(
  [getFilterScansData],
  (scansData) =>
    scansData.map(({ id, ended_at }) => ({
      value: id,
      label: moment(ended_at).format("YYYYMMDD_HHMM"),
    }))
);

export const getFilterSelectedScanData = createSelector(
  [getFilterScan, getFilterScansData],
  (scanId, scansData) =>
    isNil(scanId) || isEmpty(scansData)
      ? {}
      : scansData.find(({ id }) => parseInt(scanId) === parseInt(id))
);

export const getFilterLastScanData = createSelector(
  [getFilterScansData],
  (scansData) => (isEmpty(scansData) ? {} : scansData[0])
);

export const getFilterSelectedLastScans = (state) =>
  state.filters.selected[TYPES.LAST_SCANS];

export const getFilterRole = (state) =>
  state.filters.selected[TYPES.ENTITY_ROLE];

export const getFilterStorage = (state) =>
  state.filters.selected[TYPES.STORAGE];

export const getFilterPurpose = (state) =>
  state.filters.selected[TYPES.PURPOSE];

export const getFilterBusinessUnit = (state) =>
  state.filters.selected[TYPES.BUSINESS_UNIT];

export const getFilterRegion = (state) =>
  state.filters.selected[TYPES.CUSTOMER_REGION];

export const getFilterCountry = (state) =>
  state.filters.selected[TYPES.COUNTRY];

export const getPiiCategories = (state) =>
  state.filters.data[TYPES.PII_CATEGORIES];

export const getFiltersPiiCategoriesLoading = (state) =>
  state.filters.loading[TYPES.PII_CATEGORIES];

export const getFilterPiiCategories = (state) =>
  state.filters.selected[TYPES.PII_CATEGORIES];

export const getFilterConsentCustomerVisibility = (state) =>
  state.filters.selected[TYPES.CONSENTS.CUSTOMER_VISIBILITY];

export const getFilterConsentCategory = (state) =>
  state.filters.selected[TYPES.CONSENTS.CATEGORY];

export const getFilterConsentDecision = (state) =>
  state.filters.selected[TYPES.CONSENTS.DECISION];

export const getFilterConsentDateRange = (state) =>
  state.filters.selected[TYPES.CONSENTS.DATE_RANGE];

export const getFilterScansDateRange = (state) =>
  state.filters.selected[TYPES.SCANS.DATE_RANGE];

export const getFilterScansVisibility = (state) =>
  state.filters.selected[TYPES.SCANS.VISIBILITY];

export const getFilterConsentsUnconsents = (state) =>
  state.filters.selected[TYPES.CONSENTS.UNCONSENTS];
