import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";

const RequestMessages = ({
  selectedRow: {
    individual_right: individualRight,
    submit: { intro_text: message },
  },
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.requestContainer}>
          <Typography className={classes.itemLabel}>Request</Typography>
          <Typography className={classes.requestElement}>
            {individualRight.name}
          </Typography>
        </Box>
        <Box className={classes.messageContainer}>
          <Typography className={classes.itemLabel}>Message</Typography>
          <Typography className={classes.messageValue}>{message}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default RequestMessages;
