import React from "react";

const ChevronsRight = ({ className, turn }) => (
  <svg
    width={14}
    height={16}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={turn ? { transform: "rotate(180deg)" } : {}}
  >
    <g id="24 / arrows / chevrons-right">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70718 1.29102L0.292969 2.70523L5.58586 7.99812L0.292969 13.291L1.70718 14.7052L8.41429 7.99812L1.70718 1.29102ZM6.70718 1.29102L5.29297 2.70523L10.5859 7.99812L5.29297 13.291L6.70718 14.7052L13.4143 7.99812L6.70718 1.29102Z"
        fill="#3F51B5"
      />
    </g>
  </svg>
);

export default ChevronsRight;
