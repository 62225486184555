import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  container: {
    width: "calc(100% - 48px)",
    position: "absolute",
    bottom: 24,
    height: "35px",
    display: "flex",
    alignItems: "center",
  },
  button: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "17px",
    textTransform: "uppercase",
    borderRadius: 6,
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  verify: {
    color: theme.palette.blue[400],
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
    borderColor: fade(theme.palette.blue[400], 0.5),
  },
  deny: {
    color: theme.palette.red[500],
    borderColor: fade(theme.palette.red[500], 0.5),
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
  },
  delete: {
    color: theme.palette.red[600],
    borderColor: fade(theme.palette.red[600], 0.5),
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(2),
  },
  approve: {
    color: theme.palette.common.white,
    width: 122,
    height: 35,
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.green[300],
    marginLeft: "auto",
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.green[200],
    },
  },
}));
