export default {
  text: {
    title: "#3f51b5",
    body: "#8794ba",
  },
  primary: {
    light: "#a4bef1",
    main: "#1a3586",
    semidark: "#162c6d",
    dark: "#08144e",
    contrastText: "#fff",
  },
  secondary: {
    light: "#f9a5f3",
    main: "#a720c4",
    dark: "#470a71",
    contrastText: "#fff",
  },
  background: {
    default: "rgba(207, 223, 248, 0.2)",
    solidDefault: "#f5f8fd",
  },
  aquamarine: {
    100: "#6bd4cb",
    200: "#36b4a9",
  },
  blue: {
    800: "#08144e",
    700: "#0e1d60",
    600: "#122873",
    500: "#1a3586",
    400: "#4766b6",
    300: "#7090d9",
    200: "#a4bef1",
    100: "#CFDFF8",
    young: "#3f51b5",
    main: "#085cd6",
  },
  purple: {
    900: "#32065d",
    800: "#470a71",
    700: "#64108c",
    600: "#8317a8",
    500: "#a720c4",
    400: "#ce51db",
    300: "#e976eb",
    200: "#f9a5f3",
    100: "#fbd1f5",
  },
  red: {
    700: "#8c1616",
    600: "#780E18",
    500: "#EB5757", // Main red
    100: "#FBDDDD",
  },
  grey: {
    900: "#3B3F4C",
    800: "#686c78",
    700: "#868993",
    600: "#f2f2f2",
    500: "#e0e0e0",
    400: "#fafafa",
    300: "#828282",
    200: "#4f4f4f",
    100: "#bdbdbd",
    bluish: "#dfe2e9",
    border: "#d2d2d2",
    light: "#eef2fd",
  },
  green: {
    300: "#219653", // dark green
    200: "#27ae60", // medium green
    100: "#6fcf97", // light green
  },
  orange: {
    900: "#E89300",
    700: "#EFAA46",
    500: "#F6C174",
    300: "#FBD6A2",
    100: "#FFECD0",
  },
  yellow: {
    100: "#fefaf2",
    500: "#f2c94c",
  },
  white: {
    100: "#ffffff",
    200: "#f0f0f0",
  },
};
