import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getDs = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/datasources?sort=name&pageSize=1000`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const updateDs = (data) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const { id } = data;

  const url = `${config.apiUrl}/datasources/${id}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const deleteDs = (id) => {
  const resuestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/datasources/${id}`;

  return fetch(url, resuestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const createDs = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  let url = `${config.apiUrl}/datasources`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const createDataSourceScan = async (payload) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };
  return await fetch(`${config.apiUrl}/data-scans/`, requestOptions);
};

export const getDataSourceList = async ({ page = 1, pageSize = 10 } = {}) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  
  const url = `${config.apiUrl}/data-scans/?type=provider&page=${page}&pageSize=${pageSize}`;
  
  const response = await fetch(url, requestOptions);
  const dataSources = await response.json();
  
  return {
    data: dataSources?.data || [],
    meta: dataSources?.meta || {}
  };
};


export const getFileScansList = async ({ page = 1, pageSize = 10 } = {}) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  
  const url = `${config.apiUrl}/data-scans/?type=file&page=${page}&pageSize=${pageSize}`;
  
  const response = await fetch(url, requestOptions);
  const dataSources = await response.json();
  
  return {
    data: dataSources?.data || [],
    meta: dataSources?.meta || {}
  };
};

export const sendFileScan = async (file) => {
  if (!file) {
    console.error("sendFileScan error: Please select a file first!");
    return;
  }

  const formData = new FormData();
  formData.append("original_file", file);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const fileScans = await fetch(
    `${config.apiUrl}/data-scans/`,
    requestOptions
  ).then((response) => response.json());

  return fileScans;
};

export const getProviders = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/providers/`;

  return fetch(url, requestOptions).then(handleFetchResponse);
};

export const removeDataSourceReport = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/data-scans/${id}/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(() => id);
};
