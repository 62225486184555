import { Box, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import LastScansFilter from "components/PageWrapper/components/LayoutHeader/components/lastScansFilter";
import ScanFilter from "components/PageWrapper/components/LayoutHeader/components/scanFilter";
import PlanStatus from "components/PlanStatus";
import { UserMenu } from "components/PublicComponents/Nav";
import ScansMaxedOutNotice from "components/ScansMaxedOutNotice";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CONSTANTS from "redux/_filters/filters.constants";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { selectPlanInfo } from "redux/_plans/plans.selectors.js";
import { openChargebeeCheckout } from "utils/chargebee";
import { isStarterPlanId } from "utils/plans";
import useStyles from "./styles";
import { PLAN_IDS } from "constants/plans";

const { TYPES } = CONSTANTS;

const shoulDisplayScansMaxedOutNotice = (scansLimit, scansDone) => {
  if (typeof scansLimit === Number && typeof scansDone === Number) {
    return scansLimit === scansDone;
  }
  return false;
};

const plans = [
  {
    key: "lite-monthly",
    plan: PLAN_IDS.liteMonthly,
  },
  {
    key: "lite-yearly",
    plan: PLAN_IDS.liteYearly,
  },
  {
    key: "advanced-monthly",
    plan: PLAN_IDS.advancedMonthly,
  },
  {
    key: "advanced-yearly",
    plan: PLAN_IDS.advancedYearly,
  },
  {
    key: "enterprice-monthly",
    plan: PLAN_IDS.enterpriceMonthly,
  },
  {
    key: "enterprice-yearly",
    plan: PLAN_IDS.enterpriceYearly,
  },
];

const LayoutHeader = ({
  title = "",
  subtitle = "",
  filters = [],
  children,
  error,
  className,
  account,
  handleLogout,
  getPlanInfo,
  planInfo,
}) => {
  const classes = useStyles();
  const [scansDone, setScansDone] = useState();
  const [scansLimit, setScansLimit] = useState();
  const [chargebeeModalOpened, chargebeeModalOpen] = useState(false);

  useEffect(() => {
    getPlanInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (planInfo && planInfo.planName === null && !chargebeeModalOpened) {
      chargebeeModalOpen(true);
      const selectedPlan = planInfo.selectedPlan || "";
      let plan = PLAN_IDS.advancedMonthly;
      plans.every(({ key: invitationKey, plan: planName }) => {
        if (selectedPlan.match(invitationKey)) {
          plan = planName;
          return false;
        }
        return true;
      });
      openChargebeeCheckout(plan, () => {
        getPlanInfo();
      });
    }
  }, [chargebeeModalOpened, planInfo, getPlanInfo]);

  useEffect(() => {
    if (
      !account.email ||
      !global.intercomSettings ||
      window.intercomSettings.email === account.email
    )
      return;

    if (window.intercomSettings.email)
      global.Intercom("update", {
        name: `${account.first_name} ${account.last_name}`,
        email: account.email,
        user_id: account.id,
      });
    else
      global.Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_USER_ID,
        name: `${account.first_name} ${account.last_name}`,
        email: account.email,
        user_id: account.id,
      });
  }, [account]);

  useEffect(() => {
    setScansDone(planInfo?.scansDone);
    setScansLimit(planInfo?.metadata?.singleUrlScans);
  }, [planInfo]);

  return (
    <Box className={clsx(classes.container, className)}>
      <Box
        className={clsx(
          classes.headerContainer,
          `${title}${subtitle}` === "HOMESETTINGS" ? classes.headerSettings : ""
        )}
      >
        <Box className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography variant="h4" className={classes.subtitle}>
              {title}
            </Typography>
            <h1 className={classes.headerTitle}>{subtitle}</h1>
          </div>
          {isStarterPlanId(planInfo?.planName) && (
            <div className={classes.headerRight}>
              {scansDone && (
                <PlanStatus scansDone={scansDone} scansLimit={scansLimit} />
              )}
            </div>
          )}
        </Box>
        {account.product_access === "reports" && (
          <Hidden smDown>
            <UserMenu
              account={account}
              handleLogout={handleLogout}
              isDashboard={true}
            />
          </Hidden>
        )}
      </Box>
      {shoulDisplayScansMaxedOutNotice(scansLimit, scansDone) && (
        <ScansMaxedOutNotice />
      )}
      {filters.includes(TYPES.SCAN) && <ScanFilter error={error} />}
      {filters.includes(TYPES.LAST_SCANS) && <LastScansFilter />}
      {children}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHeader);
