import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
    version="1.1"
  >
    <path
      d="M6 10c0-2.2 1.8-4 4-4h28c2.2 0 4 1.8 4 4v28c0 2.2-1.8 4-4 4H10c-2.2 0-4-1.8-4-4z"
      fill="#1E88E5"
    />
    <path
      d="M10 12.2c0-1.2 1-2.2 2.2-2.2h7.6c1.2 0 2.2 1 2.2 2.2v21.6c0 1.2-1 2.2-2.2 2.2h-7.6C11 36 10 35 10 33.8zm16 10.6c0 1.2 1 2.2 2.2 2.2h7.6c1.2 0 2.2-1 2.2-2.2V12.2c0-1.2-1-2.2-2.2-2.2h-7.6C27 10 26 11 26 12.2z"
      fill="#FFF"
    />
  </svg>
);
