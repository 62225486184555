export const getReportLoadingState = (state) => state.reports.loading;
export const getReportJsonsLoadingState = (state) =>
  state.reports.jsons.loading;
export const getReportData = (state) => state.reports.report.data;
export const getConsumerReportData = (state) =>
  state.reports.consumerReport.data;
export const getCurrentReportJSON = (state) => state.reports.jsons.current;
export const getCachedReportJSONs = (state) => state.reports.jsons.cached;
export const getReportErrorState = (state) => state.reports.report.error;
export const getReportsData = (state) => state.reports.reports.data;
export const getReportsErrorState = (state) => state.reports.reports.error;
export const getReportsMeta = (state) => state.reports.reports.meta;
export const getScheduledReportsMeta = (state) => state.reports.scheduled.meta;
export const getSchedulers = (state) => state.reports.scheduled.data;
