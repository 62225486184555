import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontFamily: "Roboto Condensed",
    "&:first-child > *": {
      paddingTop: 32,
      alignSelf: "flex-start",
    },
    "&:last-child > *": {
      paddingBottom: 32,
      alignSelf: "flex-end",
    },
  },
  barContainer: {
    flexGrow: 1,
    marginRight: 50,
    borderLeft: `2px solid ${theme.palette.grey[500]}`,
    padding: "12px 0",
  },
  barTitle: ({ isSelected, color, disabled }) => ({
    flex: "0 0 120px",
    alignSelf: "center",
    lineHeight: "30px",
    fontWeight: isSelected ? "bold" : "normal",
    color: isSelected ? color : theme.palette.grey[800],
    fontSize: isSelected ? "14px" : "12px",
    textTransform: "uppercase",
    transition: "all .3s ease",
    textAlign: disabled ? "right" : "left",
    paddingRight: theme.spacing(2),
  }),
  bar: {
    height: 32,
    backgroundColor: ({ color }) => color,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    position: "relative",
  },
  label: ({ isSelected, color, disabled }) => ({
    position: "absolute",
    top: "50%",
    fontFamily: "Roboto Condensed, sans-serif",
    transform: "translateY(-50%)",
    left: "calc(100% + .5rem)",
    fontWeight: isSelected || disabled ? "bold" : "normal",
    color: isSelected ? color : theme.palette.grey[300],
    fontSize: "16px",
    transition: "all .3s ease",
  }),
  labelIcon: {
    width: 8,
    height: 8,
    marginRight: ".4rem",
    display: ({ isSelected }) => (isSelected ? "unset" : "none"),
    marginBottom: 2,
    "& > *": {
      fill: ({ color }) => color,
    },
  },
}));

const Bar = ({ value, title, onClick, id, selected, color, disabled }) => {
  const isSelected = selected === id;
  const classes = useStyles({ isSelected, color, disabled });
  const handleClick = () => onClick && onClick(id);
  return (
    <div
      className={classes.container}
      onClick={handleClick}
      style={onClick ? { cursor: "pointer" } : {}}
    >
      <div className={classes.barTitle}>
        <svg className={classes.labelIcon} viewBox="0 0 10 10">
          <polygon points="0,0 6,5 0,10" fill="black" />
        </svg>
        {title}
      </div>
      <div className={classes.barContainer}>
        <div
          className={classes.bar}
          style={{
            width: value ? `${value}%` : 0,
          }}
        >
          <div className={classes.label}>{value}%</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;
