export const REPORT_CATEGORIES = {
  app: "app",
  web: "web",
  security: "security",
  repository: "repository",
};

export const REPORT_VERSION = {
  freeApp: "freeApp",
  freePage: "freePage",
  freeSitemap: "freeSitemap",
  demoApp: "demoApp",
  demoPage: "demoPage",
  demoSitemap: "demoSitemap",
  app: "app",
  page: "page",
  sitemap: "sitemap",
  customer: "customer",
};
