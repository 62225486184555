import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routeForKey } from "router";

const selectComponent = ({
  dispatch,
  component: Component,
  isAuthenticated,
}) => (props) => {
  if (!isAuthenticated) {
    return <Component {...props} />;
  } else {
    return <Redirect to={{ pathname: routeForKey("zenArea") }} />;
  }
};

const PublicRoute = ({ dispatch, component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={selectComponent({ dispatch, isAuthenticated, component })}
    />
  );
};

function mapStateToProps(state) {
  const { isAuthenticated } = state.authentication;
  return { isAuthenticated };
}

export default connect(mapStateToProps)(PublicRoute);
