import { Box, Typography } from "@material-ui/core";
import InputContainer from "components/Consents/Collector/Configuration/components/InputContainer";
import useQuery from "hooks/useQuery";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { uploadConfigFile } from "redux/_consents/_config/config.async.actions";
import useStyles from "./styles";

const BrandingInput = (props) => {
  const {
    input: { value },
    label,
    loading,
    uploadFile,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [error, setError] = useState(false);

  const query = useQuery();

  const submit = () => {
    const data = new FormData();
    const reportId = query.get("r");

    data.append("branding", selectedFile);
    setError(false);
    uploadFile(data, reportId)
      .then(() =>
        enqueueSnackbar("Image successfully uploaded!", { variant: "success" })
      )
      .catch((error) => {
        setImagePreviewUrl(null);
        const errorMsg =
          "There was an error when uploading the image. Please try again.";
        enqueueSnackbar(errorMsg, { variant: "error" });
        setError(errorMsg);
      });
  };

  useEffect(() => {
    if (selectedFile) submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const fileChangedHandler = (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setImagePreviewUrl(reader.result);
    reader.readAsDataURL(file);
  };

  return (
    <InputContainer
      label={label}
      tooltip="Only image files format supported (JPG, PNG, GIF)"
      disableButton={loading}
      customButton={
        <label className={classes.uploadButton}>
          Upload
          <input
            className={classes.hiddenInput}
            type="file"
            name="branding"
            onChange={fileChangedHandler}
          />
        </label>
      }
      error={error}
    >
      <Box className={classes.container}>
        {imagePreviewUrl ? (
          <img className={classes.image} src={imagePreviewUrl} alt="logo" />
        ) : value ? (
          <img className={classes.image} src={value} alt="logo" />
        ) : (
          <Typography>Please select an Image for Preview</Typography>
        )}
      </Box>
    </InputContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (data, id, reportId) =>
    dispatch(uploadConfigFile(data, id, reportId)),
});

export default connect(null, mapDispatchToProps)(BrandingInput);
