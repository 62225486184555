import React from "react";
import Switch from "components/Inputs/Switch";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { getFilterConsentCustomerVisibility } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
const { TYPES } = CONSTANTS;

const ConsentsCustomerVisibility = ({
  customerVisibility,
  setCustomerVisibility,
}) => {
  const classes = useStyles();

  return (
    <Switch
      label="Visibility"
      className={classes.filtersInput}
      value={customerVisibility}
      labelPlacement="start"
      text="Show Customers only."
      onChange={setCustomerVisibility}
    />
  );
};

const mapStateToProps = (state) => ({
  customerVisibility: getFilterConsentCustomerVisibility(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerVisibility: (id) =>
    dispatch(selectData(TYPES.CONSENTS.CUSTOMER_VISIBILITY, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentsCustomerVisibility);
