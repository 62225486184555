import { Pie } from "react-chartjs-2";
import useStyles from "./styles";
import { Box, Typography } from "@material-ui/core";

function PieChart({ chartData, title, colors, labels }) {
  const classes = useStyles();

  return (
    <div className={classes.chartLegendContainer}>
      <div className={classes.chartLegend}>
        <h2 style={{ fontSize: "20px", textAlign: "center" }}>{title}</h2>
      </div>
      {chartData.datasets[0].data.length > 0 ? (
        <div className={classes.chartContainer}>
          <div>
            {colors.map((color, index) => {
              return (
                <div className={classes.legendItem} key={index}>
                  <div
                    className={classes.legendColor}
                    style={{ backgroundColor: color }}
                  ></div>
                  <div className={classes.legendLabel}>{labels[index]}</div>
                </div>
              );
            })}
          </div>
          <div style={{}}>
            <Pie
              width="150"
              height="150"
              data={chartData}
              options={{
                tooltips: {
                  enabled: false,
                },
              }}
            />
          </div>
        </div>
      ) : (
        <Box py={3} textAlign="center" width="100%">
          <Typography>No data were found.</Typography>
        </Box>
      )}
    </div>
  );
}
export default PieChart;
