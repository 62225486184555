import React from "react";

const unprotectedIcon = (props) => (
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9301 1.0044C14.8056 1.01678 14.6874 1.06533 14.5903 1.14405C10.8643 4.22 6.55347 6.57513 1.83957 7.94711C1.71828 7.98165 1.60994 8.05121 1.52815 8.14706C1.44636 8.2429 1.39478 8.36074 1.37991 8.48576C1.29313 9.22475 1.25 9.97696 1.25 10.7401C1.25 19.4045 6.902 28.8781 14.8401 30.9797C14.945 31.0068 15.055 31.0068 15.1599 30.9797C23.098 28.8781 28.75 19.4045 28.75 10.7401C28.75 9.97695 28.7069 9.22474 28.6201 8.48576C28.6052 8.36074 28.5536 8.2429 28.4719 8.14706C28.3901 8.05121 28.2817 7.98165 28.1604 7.94711C23.4465 6.57513 19.1357 4.22 15.4097 1.14405C15.3432 1.08984 15.2665 1.04953 15.1841 1.02554C15.1017 1.00155 15.0153 0.994358 14.9301 1.0044ZM15 2.44082C18.645 5.36959 22.8193 7.63613 27.371 9.02442C27.4253 9.59059 27.4709 10.1588 27.4709 10.7401C27.4709 18.7789 22.0638 27.7201 15 29.7029C7.93622 27.7201 2.52907 18.7789 2.52907 10.7401C2.52907 10.1588 2.57475 9.59058 2.629 9.02442C7.18068 7.63613 11.355 5.36959 15 2.44082Z"
      fill="#828282"
      stroke="#3F51B5"
      strokeWidth="0.25"
    />
    <rect
      width="1.11099"
      height="13.3319"
      transform="matrix(0.707107 -0.707106 0.707107 0.707106 9.86697 11.6792)"
      fill="#828282"
      stroke="#3F51B5"
      strokeWidth="0.25"
    />
    <rect
      x="19.2941"
      y="10.8941"
      width="1.11099"
      height="13.3319"
      transform="rotate(45 19.2941 10.8941)"
      fill="#828282"
      stroke="#3F51B5"
      strokeWidth="0.25"
    />
  </svg>
);
export default unprotectedIcon;
