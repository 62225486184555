import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  headerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "&.reports": {
      justifyContent: "center",
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0",
    marginTop: "10px",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    "&>svg": {
      padding: "0",
      maxWidth: "139px",
    },
  },
  fabContainer: {
    margin: "16px auto",
  },
  fab: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.blue[500],
    boxShadow: `0 2px 4px ${theme.palette.blue[600]}`,
    maxWidth: "172px",
    textTransform: "none",
    borderRadius: "20px",
    transition: "all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    "&:hover": {
      backgroundColor: theme.palette.blue[300],
    },
  },
  fabClosed: {
    padding: "0",
    borderRadius: "24px",
    height: "48px",
    width: "48px",
  },
  fabOpened: {
    padding: `0 ${theme.spacing(3)}px !important`,
    fontSize: "14px",
    height: "40px",
    width: "188px!important",
    maxWidth: "unset",
  },
  fabExpanded: {
    height: "91px!important",
    borderRadius: "10px!important",
  },
  isScanning: {
    border: "3px solid #2ba24c",
    boxShadow: "1px 1px 4px rgba(65, 231, 190, 0.3)",
    background: "#1a2f71",
    "&:hover": {
      background: "#1a2f71",
    },
    "& svg": {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(0.5),
      "& path": {
        fill: theme.palette.common.white,
      },
    },
  },
}));
