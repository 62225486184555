import React from 'react'
import {Route, Switch } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import { routePaths } from "router/paths";
import WebHookDetailView from './WebHookDetailView';

const ScanWebHookSettingsView = () => {
  return (
    <PageWrapper subtitle={"Repository Scanner Detail"}>
    <Switch>
      <Route path={`${routePaths.REPO_SCANNER_WEBHOOK_SETTINGS}/:repo_id`}>
              <WebHookDetailView/>
      </Route>
    </Switch>
    </PageWrapper>
  )
}

export default ScanWebHookSettingsView

