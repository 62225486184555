import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  container: {
    fontFamily: "serif",
    fontSize: 16,
    margin: theme.spacing(0, "auto"),
    maxWidth: 900,
    paddingTop: theme.spacing(3),
    textSlign: "justify",
  },
  title: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  subTitle: {
    textTransform: "uppercase",
  },
  date: {
    textAlign: "center",
  },
  bold: {
    fontWeight: 600,
  },
  brief: {
    textAlign: "center",
    fontStyle: "italic",
  },
  index: {
    padding: theme.spacing(2, 4, 0, 4),
    backgroundColor: theme.palette.grey[600],
  },
  list: {
    paddingLeft: theme.spacing(2),
  },
  table: {
    width: "100%",
    marginBottom: theme.spacing(3),
    "& th": {
      backgroundColor: theme.palette.grey[600],
      padding: theme.spacing(1),
      border: "1px solid black",
    },
    "& td": {
      padding: theme.spacing(1),
      border: "1px solid black",
    },
  },
  contact: {
    padding: theme.spacing(0, 0, 8, 8),
  },
}));

export default styles;
