import clsx from "clsx";
import { Avatar, Fab, Hidden, makeStyles } from "@material-ui/core";
import { Create, Delete, GetApp } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RRule, rrulestr } from "rrule";

import { filterCountries } from "utils/countries";
import { getReportUrl } from "utils/reports";

import Button from "components/Button";
import Dialog from "components/Dialog";
import Pagination from "components/Pagination";
import usePaginationStyles from "components/Pagination/styles";
import { Positive } from "components/PrivacyReports/Report/components/LiveProcesses";

import useStyles from "components/Scheduling/WebList/styles";
import useScanListStyles from "./styles";

import { useSelector } from "react-redux";

const useCustomStyles = makeStyles((theme) => ({
  td: {
    flexGrow: "1",
  },
  customSite: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "200px",
    display: "inline-block",
  },
}));

export const DeleteConfirmationDialog = ({
  setOpen,
  open,
  cancelDelete,
  confirmDelete,
  message,
  id,
  deviceType
}) => {
  return (
    <Dialog
      handleClose={() => setOpen(false)}
      open={open}
      title="Confirm delete"
      actions={[
        <Button variant="secondary" onClick={() => cancelDelete()}>
          Cancel
        </Button>,
        <Button variant="secondary" onClick={() => confirmDelete(id, deviceType)}>
          Yes, delete
        </Button>,
      ]}
    >
      {message}
    </Dialog>
  );
};

// Ordered by its related value on rrule
export const FREQUENCIES = ["Monthly", "Weekly", "Daily"];

export const SCAN_TYPE = {
  singleRun: "singleRun",
  scheduled: "scheduled",
  security: "security",
  repository: "repository",
};

const DomainData = ({
  status,
  id,
  site,
  hasSitemap,
  scanType,
  scanReportRoute,
  deviceType,
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const reports = useSelector((state) => state.hrefs);

  let foundHref = null;
  for (const href of reports.hrefs) {
    if (href.includes(`r=${id}&`)) {
      foundHref = href;
      break;
    }
  }

  return (
    <div className={classes.domainData} title={site}>
      {scanType === SCAN_TYPE.scheduled ? (
        <span>{site}</span>
      ) : scanType === SCAN_TYPE.security && status === "successfully" ? (
        <Link to={foundHref}>{site}</Link>
      ) : deviceType !== "mobile" && status === "successfully" ? (
        <Link to={scanReportRoute}>{site}</Link>
      ) : deviceType === "mobile" && status === "successfully" ? (
        <Link to={foundHref}>{site}</Link>
      ) : (
        <span className={customClasses.customSite}>{site}</span>
      )}
      {hasSitemap && (
        <span className="element">
          <span className="title">Search for sub-pages:</span>
          <span className="value">
            <Positive />
          </span>
        </span>
      )}
    </div>
  );
};

const CountriesData = ({ countries }) => {
  const classes = useStyles();
  return (
    <ul className={classes.countriesList}>
      {countries.map((country) => (
        <li key={country.value} className="country">
          <span className="country-name">{country.label}</span>
          {country.icon}
        </li>
      ))}
    </ul>
  );
};

const FrequencyData = ({ freq, interval, until, bymonthday, byweekday }) => {
  const classes = useStyles();
  return (
    <div className={classes.frequencyData}>
      <span className="element">
        <span className="title">Repeat every</span>
        <span className="value">{FREQUENCIES[freq - 1]}</span>
      </span>
      <span className="element">
        <span className="title">Repeat on</span>
        <span className="value">
          {freq === RRule.MONTHLY &&
            `Day ${bymonthday[0]} of every ${
              interval > 1 ? interval : ""
            } month${interval > 1 ? "s" : ""}`}
          {freq === RRule.WEEKLY &&
            ["S", "M", "T", "W", "T", "F", "S"].map((day, index) => {
              // The library starts from monday, not sunday
              const currentDay = index === 0 ? RRule.SU.weekday : index - 1;
              return (
                <Avatar
                  className={clsx(
                    byweekday &&
                      byweekday.length > 0 &&
                      byweekday.includes(currentDay)
                      ? classes.blue
                      : classes.grey
                  )}
                >
                  {day}
                </Avatar>
              );
            })}
          {freq === RRule.DAILY &&
            `Every ${interval > 1 ? interval : ""} day${
              interval > 1 ? "s" : ""
            }`}
        </span>
      </span>
      {until && (
        <span className="element">
          <span className="title">Ends</span>
          <span className="value">{`${String(until)
            .substr(4, 11)
            .replace(" 2", ", 2")}`}</span>
        </span>
      )}
    </div>
  );
};

const ActionsData = ({
  onClickEdit,
  onClickDelete,
  id,
  scanType,
  pdf,
  deviceType,
  site,
  status,
}) => {
  const classes = useStyles();
  return (
    <>
      {scanType === SCAN_TYPE.scheduled && (
        <Fab
          className={classes.actionButton}
          size="small"
          color="primary"
          onClick={() => onClickEdit(id)}
        >
          <Create />
        </Fab>
      )}

      <Fab
        className={classes.actionButton}
        size="small"
        color="secondary"
        onClick={() => onClickDelete()}
      >
        <Delete />
      </Fab>
    </>
  );
};

const DownloadCsv = ({
  onClickDownload,
  resultsCsv,
}) => {
  const classes = useStyles();
  return (
    <Fab
        className={classes.actionButton}
        size="small"
        color="secondary"
        onClick={() => onClickDownload(resultsCsv)}
      >
        <GetApp />
    </Fab>
  );
};

const STATUS_MAP = {
  successfully: "Done",
  successfully: "Done", // TODO: fix typo in backend
  failed: "Failed",
  setting_up: "In Progress",
  on_hold: "On Hold",
};

export const formatSingleRunScanStatus = (status) => {
  return STATUS_MAP[status];
};

export const ScanListRow = ({
  // Common props
  id,
  document: pdf,
  site,
  locations = [],
  recurrence,
  deleteItem,
  editItem,
  deleteDialogMessage,
  scanType = SCAN_TYPE.scheduled,
  sitemapDepth,
  // Single run row
  status,
  report,
  version,
  deviceType,
  resultsCsv,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const filteredCountries = filterCountries(locations);
  const hasSitemap = sitemapDepth > 0;

  const scheduledSpecificData = {};
  const singleRunSpecificData = {};

  const reportID = report !== null ? report : id;
  const versionID = deviceType === "repository" ? "repository" : version;

  if (scanType === SCAN_TYPE.scheduled) {
    const frequencyRule = rrulestr(recurrence);
    const {
      options: { interval, freq, bymonthday, byweekday, until },
    } = frequencyRule;
    const onClickEdit = (id) => {
      editItem(id);
    };

    const aggregated = {
      interval,
      freq,
      bymonthday,
      byweekday,
      until,
      onClickEdit,
    };
    Object.entries(aggregated).forEach(([key, value]) => {
      scheduledSpecificData[key] = value;
    });
  } else if (scanType === SCAN_TYPE.singleRun) {
    const scanReportRoute = getReportUrl(reportID, versionID);

    const aggregated = {
      scanReportRoute,
    };
    Object.entries(aggregated).forEach(([key, value]) => {
      singleRunSpecificData[key] = value;
    });
  }

  const downloadCsv = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const onClickDelete = () => {
    setOpen(true);
  };

  const confirmDelete = (id, deviceType) => {
    deleteItem(id, deviceType);
    setOpen(false);
  };
  const cancelDelete = () => {
    setOpen(false);
  };

  const deleteDialogProps = {
    message: deleteDialogMessage,
    open,
    setOpen,
    confirmDelete,
    cancelDelete,
    id,
    deviceType
  };
  const domainDataProps = {
    status,
    site,
    hasSitemap,
    scanType,
    scanReportRoute: singleRunSpecificData.scanReportRoute,
    deviceType,
    id,
  };
  const frequencyDataProps = {
    freq: scheduledSpecificData.freq,
    interval: scheduledSpecificData.interval,
    until: scheduledSpecificData.until,
    bymonthday: scheduledSpecificData.bymonthday,
    byweekday: scheduledSpecificData.byweekday,
  };
  const countriesDataProps = {
    countries: filteredCountries,
    deviceType,
  };
  const actionsDataProps = {
    onClickEdit: scheduledSpecificData.onClickEdit,
    onClickDelete,
    id,
    scanType,
    pdf,
    deviceType,
    site,
    status,
  };

  const actionsDownloadProps = {
    onClickDownload: downloadCsv,
    resultsCsv,
  };
  
  const customClasses = useCustomStyles();

  return (
    <>
      <Hidden mdUp>
        <DeleteConfirmationDialog {...deleteDialogProps} />
        <table className={classes.mobileRow}>
          <tbody>
            <tr className={classes.mobileRowTr}>
              <th className={classes.mobileRowTh}>Sitemap</th>
              <td className={classes.mobileRowTd}>
                <DomainData {...domainDataProps} />
              </td>
            </tr>
            {deviceType == "web" && (
              <tr className={classes.mobileRowTr}>
                <th className={classes.mobileRowTh}>Countries</th>
                <td className={classes.mobileRowTd}>
                  <CountriesData {...countriesDataProps} />
                </td>
              </tr>
            )}
            <tr className={classes.mobileRowTr}>
              <th className={classes.mobileRowTh}>
                {scanType === SCAN_TYPE.scheduled ? "Frequency" : "Status"}
              </th>
              <td className={classes.mobileRowTd}>
                {scanType === SCAN_TYPE.scheduled ? (
                  <FrequencyData {...frequencyDataProps} />
                ) : (
                  formatSingleRunScanStatus(status)
                )}
              </td>
            </tr>
            <tr className={classes.mobileRowTr}>
              <th className={classes.mobileRowTh}>Actions</th>
              <td className={classes.mobileRowTd}>
                <ActionsData {...actionsDataProps} />
              </td>
            </tr>
          </tbody>
        </table>
      </Hidden>
      <Hidden smDown>
        <DeleteConfirmationDialog {...deleteDialogProps} />
        <tr className={clsx(classes.tr, classes.row)}>
          <td
            className={clsx(
              deviceType == "web" ? classes.td : customClasses.td,
              classes.domain,
              "-row",
              (scheduledSpecificData.hasSitemap ||
                singleRunSpecificData.hasSitemap) &&
                "-with-sitemap"
            )}
          >
            <DomainData {...domainDataProps} />
          </td>
          {deviceType == "web" && (
            <td className={clsx(classes.td, classes.countries, "-row")}>
              <CountriesData {...countriesDataProps} />
            </td>
          )}
          <td
            className={clsx(
              classes.td,
              scanType === SCAN_TYPE.scheduled && classes.frequency,
              scanType === SCAN_TYPE.singleRun && classes.status,
              scanType === SCAN_TYPE.security && classes.status,
              "-row"
            )}
          >
            {scanType === SCAN_TYPE.scheduled ? (
              <FrequencyData {...frequencyDataProps} />
            ) : (
              formatSingleRunScanStatus(status)
            )}
          </td>
          {deviceType == "repository" && (
            <td className={clsx(classes.td, classes.actions)}>
              <DownloadCsv {...actionsDownloadProps} />
            </td>
          )}
          <td className={clsx(classes.td, classes.actions)}>
            <ActionsData {...actionsDataProps} />
          </td>
        </tr>
      </Hidden>
    </>
  );
};

/**
 * Creates filter object depending on the scan type.
 *
 * @param {Number} pageNumber: starts at 1.
 */
const getFiltersFor = (scanType, itemsPerPage, pageNumber, deviceType) => {
  const environment =
    deviceType === "web" ||
    deviceType === "repository" ||
    deviceType === "security"
      ? deviceType
      : ["android", "ios"];
  if (scanType === SCAN_TYPE.scheduled) {
    return {
      limit: itemsPerPage,
      offset: (pageNumber - 1) * itemsPerPage,
      all: true,
    };
  } else if (
    scanType === SCAN_TYPE.singleRun ||
    scanType === SCAN_TYPE.security
  ) {
    return {
      pageSize: itemsPerPage,
      page: pageNumber,
      all: true,
      environment: environment,
    };
  }
};

const defaultRowGenerator = (scans = [], preRemoveScan, deviceType) => () =>
  scans.map((scan, index) => {
    const parsedScan = {
      ...scan,
      id: scan.pk,
      site: scan.resource,
      deviceType,
    };

    const hasCountry = scans.length && !!scans[0].locations;
    return (
      <ScanListRow
        {...parsedScan}
        scanType={hasCountry ? SCAN_TYPE.singleRun : SCAN_TYPE.security}
        key={`scan-${index}`}
        deleteItem={preRemoveScan}
        deleteDialogMessage="Are you sure you want to delete this report?"
        resultsCsv={scan.resultsCsv}
      />
    );
  });

const ScanList = ({
  cta,
  ctaIcon,
  emptyNotice,
  goToSection,
  headers,
  itemsPerPage = 3,
  data,
  deleteItem: rootDeleteItem,
  rowGenerator = defaultRowGenerator,
  rowGeneratorArgs = [],
  getItems = () => {},
  scanType,
  totalPages,
  deviceType: type,
}) => {
  const paginationClasses = usePaginationStyles();
  const classes = useScanListStyles();
  const listClasses = useStyles();

  const [tableRows, setTableRows] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const [deviceType, setDeviceType] = useState(type);

  // TODO: add loading state to prevent 0 component flash when loading for the first time
  useEffect(() => {
    if (deviceType)
      getItems(
        getFiltersFor(scanType, itemsPerPage, currentPage + 1, deviceType)
      );
    // eslint-disable-next-line
  }, []);

  // TODO: add loading state to prevent 0 component flash when loading for the first time
  useEffect(() => {
    if (deviceType)
      getItems(
        getFiltersFor(scanType, itemsPerPage, currentPage + 1, deviceType)
      );
    // eslint-disable-next-line
  }, [deviceType]);

  useEffect(() => {
    if (data && data.length > 0) {
      const firstScan = data[0];
      const environmentVariable = firstScan.environment
        ? firstScan.environment
        : "security";
      if (environmentVariable === "ios" || environmentVariable === "android")
        setDeviceType("mobile");
      else setDeviceType(environmentVariable);
    }
  }, [data]);

  const updateItems = async (pageNumber, deviceType) => {
    await getItems(
      getFiltersFor(scanType, itemsPerPage, pageNumber + 1, deviceType)
    );
    setCurrentPage(pageNumber);
  };

  const deleteItem = useCallback(
    (id, deviceType) => {
      rootDeleteItem(id).then(() => {
        updateItems(currentPage, deviceType);
      });
    },
    [currentPage]
  );

  useEffect(() => {
    setTableRows(
      rowGenerator(data, deleteItem, deviceType)(...rowGeneratorArgs)
    );
    if (data.length === 0 && currentPage > 0) updateItems(currentPage - 1);
    // eslint-disable-next-line
  }, [data]);

  const thereAreItems = data ? data.length > 0 : false;

  return (
    <div className={clsx(classes.schedules)}>
      {!thereAreItems ? (
        <div className={classes.empty}>
          <p>{emptyNotice}</p>
          <Button startIcon={ctaIcon} onClick={goToSection}>
            {cta}
          </Button>
        </div>
      ) : (
        <>
          <Hidden smDown>
            <div className={classes.tableContainer}>
              <table className={classes.table}>
                <thead className={classes.head}>
                  <tr className={classes.tr}>
                    {headers.map((header, i) => (
                      <th
                        key={`scanlist-th-${i}`}
                        className={clsx(
                          classes.th,
                          listClasses[header.className]
                        )}
                      >
                        {header.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={clsx(classes.tbody)}>{tableRows}</tbody>
              </table>
            </div>
          </Hidden>
          <Hidden mdUp>{tableRows}</Hidden>
        </>
      )}
      {totalPages > 1 && (
        <Pagination
          className={paginationClasses.pagination}
          onPageChange={updateItems}
          current={currentPage}
          total={totalPages}
          setCurrent={setCurrentPage}
          extraParams={ deviceType } 
        />
      )}
      {thereAreItems && deviceType === "repository" ? (
        <Button
          className={classes.mainCtaButton}
          startIcon={ctaIcon}
          onClick={() => goToSection(1)}
        >
          {cta}
        </Button>
      ) : (
        thereAreItems && (
          <Button
            className={classes.mainCtaButton}
            startIcon={ctaIcon}
            onClick={goToSection}
          >
            {cta}
          </Button>
        )
      )}
    </div>
  );
};

export default ScanList;
