import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {},
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&.-with-margin": {
      margin: "20px 0",
    },
    "&.-countries": {
      margin: "20px 0 40px",
    },
  },
  label: {
    width: 130,
  },
  error: {
    color: "red",
    fontSize: "0.75rem",
  },
  boxSiblingText: {
    paddingTop: theme.spacing(1),

    "&.right": {
      paddingRight: theme.spacing(1),
    },
  },
  textField: {
    width: 510,
    "& input": {
      paddingTop: 10,
    },
    "&.mini": {
      width: 40,
      marginRight: 10,
    },
    "&.ends": {
      width: 120,
      marginLeft: 40,
    },
    "& > .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  checkbox: {
    margin: "10px 0 10px 118px",
    color: "#666666",
  },
  selectField: {
    "& > .MuiSelect-select": {
      color: "rgba(0, 0, 0, 0.87)",
      paddingTop: 10,
    },
    "&.mini": {
      margin: "0 10px",
    },
    "& svg": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  week: {
    flexDirection: "row",
  },
  day: {
    color: "#666666",
    backgroundColor: "#f0f0f0",
  },
  selectedDay: {
    color: "white",
    backgroundColor: theme.palette.blue[500],
  },
  countries: {
    width: 510,
    height: 70,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#e8e8e8",
      borderBottom: "solid 1px !important",
      color: "rgba(0, 0, 0, 0.87)",
      borderRadius: 3,
    },
    "& p": {
      marginTop: 40,
    },
  },
  flag: {
    width: "1.8em",
    height: "1.2em",
    position: "relative",
    top: "-1px",
    "&.selected": {
      marginRight: "10px",
    },
  },
  datePickerInput: {
    paddingTop: "10px",
  },
  submit: {
    width: 510,
    marginLeft: 130,
    backgroundColor: theme.palette.aquamarine[100],
    border: "0 none",
    borderRadius: 6,
    color: theme.palette.blue["young"],
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
    height: "48px",
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
    "& span": {
      width: "45%",
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
}));
