import { getWorkspaces, getWorkspacesInfo } from "./slack.service";
import {
  requestStart,
  requestEnd,
  saveData,
  setError,
  setMeta,
} from "./slack.actions";

export const getSlackWorkspaces = () => (dispatch) => {
  dispatch(requestStart());
  return getWorkspaces()
    .then(([data, meta]) => {
      dispatch(requestEnd());
      dispatch(saveData(data, "workspaces"));
      dispatch(setMeta(meta, "workspaces"));
      dispatch(getSlackWorkspacesInfo(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      dispatch(setError(error, "workspaces"));
      return Promise.reject(error);
    });
};

export const getSlackWorkspacesInfo = (data) => (dispatch) => {
  dispatch(requestStart());
  return getWorkspacesInfo(data).then((info) => {
    dispatch(requestEnd());
    dispatch(saveData(info, "workspacesInfo"));
    return Promise.resolve();
  });
};
