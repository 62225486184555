export const roles = [
  {
    value: "A",
    label: "Admin",
  },
  {
    value: "N",
    label: "Normal",
  },
];

export const states = [
  {
    value: "P",
    label: "Pending",
  },
  {
    value: "A",
    label: "Active",
  },
];
