export default function qs(data = {}, encode = false) {
  return (
    "?" +
    Object.entries(data)
      .filter((entry) => entry[1] !== undefined)
      .map((entry) => {
        entry[0] = String(entry[0]);
        entry[1] = String(entry[1]);
        if (encode) {
          entry[1] = encodeURIComponent(entry[1]);
        }
        return entry.join("=");
      })
      .join("&")
  );
}

export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}
