import React from "react";
import { Helmet } from "react-helmet";
export { default as pages } from "./pages";

const Seo = ({ title, description, keywords = [], openGraph, twitter }) => {
  const ogAllowed = [
    "title",
    "url",
    "description",
    "image",
    "site_name",
    "type",
    "locale",
    "image:width",
    "image:height",
  ];

  const twAllowed = [
    "card",
    "site",
    "creator",
    "title",
    "description",
    "image",
    "url",
  ];

  return (
    <Helmet>
      {title && <title>{title}</title>}{" "}
      {description && <meta name="description" content={description} />}
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(",")} />
      )}
      {openGraph &&
        Object.keys(openGraph)
          .filter((key) => ogAllowed.includes(key))
          .map(
            (key) =>
              openGraph[key] && (
                <meta
                  key={key}
                  property={`og:${key}`}
                  content={openGraph[key]}
                />
              )
          )}
      {twitter &&
        Object.keys(twitter)
          .filter((key) => twAllowed.includes(key))
          .map(
            (key) =>
              twitter[key] && (
                <meta
                  key={key}
                  property={`twitter:${key}`}
                  content={twitter[key]}
                />
              )
          )}
      <link
        rel="shortcut icon"
        href={require("assets/favicon/favicon.ico").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={require("assets/favicon/apple-icon-57x57.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={require("assets/favicon/apple-icon-60x60.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={require("assets/favicon/apple-icon-72x72.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={require("assets/favicon/apple-icon-76x76.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={require("assets/favicon/apple-icon-114x114.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={require("assets/favicon/apple-icon-120x120.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={require("assets/favicon/apple-icon-144x144.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={require("assets/favicon/apple-icon-152x152.png").default}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={require("assets/favicon/apple-icon-180x180.png").default}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={require("assets/favicon/android-icon-192x192.png").default}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={require("assets/favicon/favicon-32x32.png").default}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={require("assets/favicon/favicon-96x96.png").default}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={require("assets/favicon/favicon-16x16.png").default}
      />
    </Helmet>
  );
};

export default Seo;
