import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import UpdateArrows from "assets/svgs-icons/update-arrows";
import { getMapChartData } from "redux/_consents/_map/map.async.actions";
import { getOverviewData } from "redux/_consents/_overview/overview.async.actions";
import {
  getConsentsOverviewData,
  getOverviewLoadingState,
} from "redux/_consents/_overview/overview.selectors";
import { getMapLoadingState } from "redux/_consents/_map/map.selectors";

const ConsentsKPIs = ({
  data: { policies = [], applications = [], partners = [] },
  dataLoading,
  mapchartLoading,
  reloadMapchart,
  getConsentsData,
}) => {
  const loadConsentsData = () => {
    reloadMapchart();
    getConsentsData(true);
  };

  useEffect(() => {
    getConsentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sum = [...policies, ...applications, ...partners].reduce(
    (prev, curr) => {
      prev.opted_in += curr.opted_in;
      prev.opted_out += curr.opted_out;
      return prev;
    },
    {
      opted_in: 0,
      opted_out: 0,
    }
  );
  const total = sum.opted_out + sum.opted_in;

  const optedIn = ((sum.opted_in / total) * 100).toFixed(1);
  const optedOut = ((sum.opted_out / total) * 100).toFixed(1);

  const classes = useStyles();
  const loading = dataLoading || mapchartLoading;
  return (
    <>
      <div className={classes.reloadButtonContainer}>
        <Button
          className={classes.reloadButton}
          size="large"
          variant="outlined"
          color="primary"
          onClick={loadConsentsData}
          disabled={loading}
        >
          <UpdateArrows className={classes.reloadIcon} />
          RELOAD STATS
          {loading && (
            <CircularProgress
              color="primary"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </Button>
      </div>

      <Grid container className={classes.kpisContainer}>
        <Grid item xs={12} md={6} style={{ paddingRight: "13px" }}>
          <Box>
            <Typography className={classes.itemValue}>
              {isNaN(optedIn) ? "0%" : `${optedIn}%`}
            </Typography>
            <Typography className={classes.itemTitle}>
              Users with <br /> Consent
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography className={classes.itemValue}>
              {isNaN(optedOut) ? "0%" : `${optedOut}%`}
            </Typography>
            <Typography className={classes.itemTitle}>
              Users without <br /> Consent
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsOverviewData(state),
  dataLoading: getOverviewLoadingState(state),
  mapchartLoading: getMapLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  reloadMapchart: () => dispatch(getMapChartData()),
  getConsentsData: (force) => dispatch(getOverviewData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsKPIs);
