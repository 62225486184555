import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  lastScanStats: {
    width: "100%",
    marginTop: "26px",
    paddingBottom: theme.spacing(2),
  },
  itemsContainer: {
    height: "100%",
    alignItems: "flex-end",
  },
  componentTitle: {
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "2px",
    color: "#333333",
    textTransform: "uppercase",
  },
  itemValueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  itemValue: {
    fontSize: "24px",
    fontWeight: "bold",
    marginLeft: "8px",
    marginRight: "8px",
    lineHeight: 1.25,
    color: theme.palette.blue[500],
  },
  itemTitle: {
    fontSize: "18px",
    color: "#828282",
    fontWeight: 600,
    textOverflow: "ellipsis",
  },
  variationContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0px 4px",
    borderRadius: "2px",
    "&>svg": {
      marginLeft: "4px",
    },
  },
  variation: {
    lineHeight: 1.83,
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Roboto Condensed",
  },
  item: {
    display: "flex",
    justifyContent: "center",
  },
}));
