import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Severity from "pages/AppReport/common/Severety";
import Title from "pages/AppReport/common/Title";
import { Tooltip } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import SectionContent from "pages/AppReport/common/SectionContent";

const labels = {
  nx: "NX",
  pie: "PIE",
  stack_canary: "STACK CANARY",
  arc: "ARC",
  rpath: "RPATH",
  code_signature: "CODE SIGNATURE",
  encrypted: "ENCRYPTED",
  symbol: "SYMBOL",
};

const isTrueKey = {
  nx: "has_nx",
  pie: "has_pie",
  stack_canary: "has_canary",
  arc: "has_arc",
  rpath: "has_rpath",
  code_signature: "has_code_signature",
  encrypted: "is_encrypted",
  symbol: "is_stripped",
};

const IPABinaryAnalysisSection = ({ goToNextSectionAction, data }) => {
  const { name, ...test } = data;
  const formattedData = Object.entries(test).map((binaryAnalysis) => ({
    protection: binaryAnalysis[0],
    ...binaryAnalysis[1],
  }));
  return (
    <Page
      key={SECTIONS_CONTENT.binaryAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.binaryAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the Store Information</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.binaryAnalysis.description}>
        {SECTIONS_CONTENT.binaryAnalysis.title}
      </Title>
      <SectionContent>
        <Table
          data={formattedData}
          limitPerPage={10}
          columns={[
            {
              key: "protection",
              label: "Protection",
              width: "10%",
              render: (protection) => labels[protection] || protection,
            },
            {
              key: "status",
              label: "Status",
              width: "10%",
              render: (_, el) =>
                isTrueKey[el?.protection] ? (
                  <Tooltip title="True">
                    <CheckCircle style={{ color: "#468C49" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="None">
                    <Cancel style={{ color: "#D32F2F" }} />
                  </Tooltip>
                ),
            },
            {
              key: "severity",
              label: "Severity",
              width: "10%",
              render: (severity) => <Severity severity={severity} />,
            },
            {
              key: "description",
              label: "Description",
              width: "70%",
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default IPABinaryAnalysisSection;
