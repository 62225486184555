import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import isArray from "lodash.isarray";

export const scanTables = (dbData) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(dbData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/databases/tables_scan`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getTables = (dbId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/databases/${dbId}/tables`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => {
      if (isArray(data)) {
        return data.map(({ id_table, ...el }) => ({ ...el, id: id_table }));
      } else {
        return data;
      }
    });
};

export const updateTables = (dbId, data) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/databases/${dbId}/tables`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getInitialSetup = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/initial_setup`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const setInitialSetupConfig = (show) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ show_initial_setup: show }),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/initial_setup`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getCodeScannerAssets = () => {
  return fetch(`${config.apiUrl}/products/code_scanner/`, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  })
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getDeviceScannerAssets = () => {
  return fetch(`${config.apiUrl}/products/device_scanner/`, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  })
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getRepositoryScannerAssets = () => {
  return fetch(`${config.apiUrl}/products/repository_scanner/`, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  })
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getDatabaseScannerAssets = () => {
  return fetch(`${config.apiUrl}/products/database_scanner/`, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  })
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
