import ReactFullpage from "@fullpage/react-fullpage";
import Loader from "components/Loader";
import Seo, { pages } from "components/Seo";
import { REPORT_VERSION } from "constants/reports";
import { useReportLoading } from "hooks/useLoading";
import useQuery from "hooks/useQuery";
import useReports from "hooks/useReports";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAccountData } from "redux/_account/account.selectors";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { selectPlanInfo } from "redux/_plans/plans.selectors.js";
import { getReportJSON as fetchReportJSON } from "redux/_reports/reports.async.actions";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";
import theme from "theme";
import { getReportUrl } from "utils/reports";
import { APP_TEST_SCORES_ITEMS } from "./AppReport";
import Consent from "./components/Consent";
import CookieCount from "./components/CookieCount";
import Header from "./components/Header";
import LiveProcesses from "./components/LiveProcesses";
import Page from "./components/Page";
import PersonalDataUsage from "./components/PersonalDataUsage";
import PrivacyLinks from "./components/PrivacyLinks";
import SideBar from "./components/SideBar";
import SiteAnalysis from "./components/SiteAnalysis";
import Trackers from "./components/Trackers";
import TrafficSources from "./components/TrafficSources";
import { FLAVORS } from "./constants";
import Complete from "./sections/Complete";
import FreeReportPitch from "./sections/FreeReportPitch";
import Landing from "./sections/Landing";
import PII from "./sections/PII";
import Recommendations from "./sections/Recommendations";
import Security from "./sections/Security";
import TestScores from "./sections/TestScores";
import useStyles from "./styles";
import { WEB_TEST_SCORES_ITEMS } from "./WebReport";

const SECTION_ID = {
  complete: "complete",
  consentPage: "consentPage",
  cookies: "cookies",
  freeReportPitch: "freeReportPitch",
  landing: "landing",
  personalData: "personalData",
  pii: "pii",
  policy: "policy",
  privacyLinks: "privacyLinks",
  recommendations: "recommendations",
  security: "security",
  siteAnalysis: "siteAnalysis",
  testScores: "testScores",
  trackers: "trackers",
  trafficSource: "trafficSource",
};

const SECTION_NAME = {
  [SECTION_ID.complete]: "Complete",
  [SECTION_ID.consentPage]: "ConsentPage",
  [SECTION_ID.cookies]: "Cookies",
  [SECTION_ID.freeReportPitch]: "CompleteFree",
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.personalData]: "PersonalData",
  [SECTION_ID.pii]: "pii",
  [SECTION_ID.policy]: "Policy",
  [SECTION_ID.privacyLinks]: "PrivacyLinks",
  [SECTION_ID.recommendations]: "Recommendations",
  [SECTION_ID.security]: "Security",
  [SECTION_ID.siteAnalysis]: "SiteAnalysis",
  [SECTION_ID.testScores]: "TestScores",
  [SECTION_ID.trackers]: "Trackers",
  [SECTION_ID.trafficSource]: "TrafficSource",
};

const VULNERABILITY_SCORE = {
  1: "Low",
  2: "Medium",
  3: "Hight",
};

const VULNERABILITY_SCORE_COLOR = {
  1: "#55cc9a",
  2: "#fbb25e",
  3: "#fb585a",
};

const mapSectionNameToId = (name) => {
  if (name === SECTION_NAME[SECTION_ID.freeReportPitch])
    return SECTION_ID.freeReportPitch;
  return name.charAt(0).toLowerCase() + name.slice(1);
};

const ORDERED_ANCHORS = {
  [REPORT_VERSION.freeApp]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    // SECTION_NAME.recommendations,
    SECTION_NAME.freeReportPitch,
  ],
  [REPORT_VERSION.freePage]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.personalData,
    SECTION_NAME.recommendations,
    SECTION_NAME.freeReportPitch,
  ],
  [REPORT_VERSION.freeSitemap]: [],
  [REPORT_VERSION.demoApp]: [],
  [REPORT_VERSION.demoSitemap]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.siteAnalysis,
    SECTION_NAME.personalData,
    SECTION_NAME.policy,
    SECTION_NAME.cookies,
    SECTION_NAME.trackers,
    SECTION_NAME.consentPage,
    SECTION_NAME.privacyLinks,
    SECTION_NAME.trafficSource,
    SECTION_NAME.recommendations,
    SECTION_NAME.complete,
  ],
  [REPORT_VERSION.page]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.personalData,
    SECTION_NAME.policy,
    SECTION_NAME.cookies,
    SECTION_NAME.trackers,
    SECTION_NAME.pii,
    SECTION_NAME.consentPage,
    SECTION_NAME.privacyLinks,
    SECTION_NAME.trafficSource,
    SECTION_NAME.security,
    SECTION_NAME.recommendations,
  ],
  [REPORT_VERSION.demoPage]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.personalData,
    SECTION_NAME.policy,
    SECTION_NAME.cookies,
    SECTION_NAME.trackers,
    SECTION_NAME.pii,
    SECTION_NAME.consentPage,
    SECTION_NAME.privacyLinks,
    SECTION_NAME.trafficSource,
    SECTION_NAME.security,
    SECTION_NAME.recommendations,
    SECTION_NAME.complete,
  ],
  [REPORT_VERSION.sitemap]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.siteAnalysis,
    SECTION_NAME.personalData,
    SECTION_NAME.policy,
    SECTION_NAME.cookies,
    SECTION_NAME.trackers,
    SECTION_NAME.pii,
    SECTION_NAME.consentPage,
    SECTION_NAME.privacyLinks,
    SECTION_NAME.trafficSource,
    SECTION_NAME.security,
    SECTION_NAME.recommendations,
  ],
  [REPORT_VERSION.app]: [
    SECTION_NAME.landing,
    SECTION_NAME.testScores,
    SECTION_NAME.personalData,
    SECTION_NAME.policy,
    SECTION_NAME.trackers,
  ],
};

export const complexityName = {
  complexityScore: "",
  dataPracticesDiversityScore: "Diversity Score",
  dataPracticesRedundancyScore: "Redundancy Score",
  fleschReadabilityScore: "Readability Score",
  hapaxRichnessScore: "Richness Score",
  lengthScore: "Length Score",
  selectedPpSubpage: "",
  subpagesCounts: "",
  subpagesSplitScore: "Subpages Split Score",
  webpageFormatScore: "Webpage Format Score",
};

export const complexityNameSorting = (keyA, keyB) => {
  const complexityNameValue = {
    complexityScore: 2,
    dataPracticesDiversityScore: 9,
    dataPracticesRedundancyScore: 6,
    fleschReadabilityScore: 8,
    hapaxRichnessScore: 5,
    lengthScore: 7,
    selectedPpSubpage: 1,
    subpagesCounts: 0,
    subpagesSplitScore: 4,
    webpageFormatScore: 3,
  };
  return complexityNameValue[keyB] - complexityNameValue[keyA];
};

export const calculateAmbiguous = (tpsCrossMapping) => {
  let total = 0;
  let unespecified = 0;
  Object.keys(tpsCrossMapping).forEach((x) => {
    Object.keys(tpsCrossMapping[x]).forEach(() => {
      total += 1;
      if (x === "unspecified") unespecified += 1;
    });
  });
  return Math.round((unespecified / total) * 100);
};

const SECTIONS_CONTENT = {
  [SECTION_ID.complete]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.complete,
    title: "",
    description: "",
  },
  [SECTION_ID.pii]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.pii,
    title: "Third-party data flow  map",
    description:
      "Understand PII being shared with live third-party integrations on your website/app",
  },
  [SECTION_ID.consentPage]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.consentPage,
    title: "Consent",
    description: "User Consent Checks",
  },
  [SECTION_ID.cookies]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.cookies,
    title: "Cookies",
    description: "Cookies that were placed during the web session",
  },
  [SECTION_ID.freeReportPitch]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.freeReportPitch,
    title: "",
    description: "",
  },
  [SECTION_ID.landing]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.landing,
    title: "",
    description: "",
  },
  [SECTION_ID.personalData]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.personalData,
    title: "Privacy Policy Analysis",
    description: (classes, { complexityScore, tpsCrossMapping }) => (
      <div>
        <p>
          We process your privacy using our proprietary Legal NLP engine to
          identify what information you say collect from the user and its
          purpose.
        </p>
        {complexityScore && complexityScore.complexityScore > 0 && (
          <>
            {tpsCrossMapping && (
              <p>
                {calculateAmbiguous(tpsCrossMapping)}% of your privacy policy is
                ambiguous
              </p>
            )}
            <h4>
              Privacy Policy Complexity Score: {complexityScore.complexityScore}
            </h4>
            <ul className={classes.complexityScoreList}>
              {Object.keys(complexityScore)
                .sort(complexityNameSorting)
                .map(
                  (key, i) =>
                    complexityName[key] && (
                      <li key={i}>
                        <span>{complexityName[key]}</span>:{" "}
                        {complexityScore[key]}
                      </li>
                    )
                )}
            </ul>
          </>
        )}
      </div>
    ),
  },
  [SECTION_ID.policy]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.policy,
    title: "Privacy Policy vs. Live Processes",
    description:
      "We used NLP/ML techniques to cross check your privacy policy against what is actually happening on your websites to identify potential mismatches.",
  },
  [SECTION_ID.privacyLinks]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.privacyLinks,
    title: "Privacy Links",
    description: "Privacy Policy Checks",
  },
  [SECTION_ID.siteAnalysis]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.siteAnalysis,
    title: "Site analysis",
    description:
      "Different results for all the pages you define on your sitemap.",
  },
  [SECTION_ID.testScores]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.testScores,
    title: "Test Scores",
    description: "",
  },
  [SECTION_ID.trafficSource]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.trafficSource,
    title: "Geographic location of your users",
    description:
      "Check the geographic location of your users to guide which regulations might apply to your business.",
  },
  [SECTION_ID.security]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.security,
    title: "Security",
    description: (classes, pentest) => (
      <div>
        <p>Here are recommendations to reduce your vulnerability risk level.</p>
        {pentest && pentest.data && pentest.data.reduce && (
          <>
            <h4 className={classes.title}>Vulnerability risk level:</h4>
            <div
              className={classes.box}
              style={{
                backgroundColor:
                  VULNERABILITY_SCORE_COLOR[
                    pentest.data.reduce(
                      (a, b) => (b.riskLevel > a ? b.riskLevel : a),
                      0
                    )
                  ],
              }}
            >
              {
                VULNERABILITY_SCORE[
                  pentest.data.reduce(
                    (a, b) => (b.riskLevel > a ? b.riskLevel : a),
                    0
                  )
                ]
              }
            </div>
          </>
        )}
      </div>
    ),
  },
  [SECTION_ID.recommendations]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.recommendations,
    title: "Opportunities",
    description:
      "Here are suggestions to improve your privacy score and reduce data/complaince risk.",
  },
  [SECTION_ID.trackers]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.trackers,
    title: "Third Party Trackers",
    description:
      "This module represents all third-party trackers you have on your site, the provider they belong to, the functionality they provide, and the tag itself for debugging if necessary.",
  },
};

const getSectionsColorsFor = (reportVersion) => {
  const sectionKeys = ORDERED_ANCHORS[reportVersion] || [];
  return sectionKeys
    .map(mapSectionNameToId)
    .map((sectionId) => SECTIONS_CONTENT[sectionId].backgroundColor);
};

const getSectionComponents = (
  goToNextSectionAction,
  site,
  report,
  reportType,
  testScoresItems,
  setTestScoresItems,
  version,
  testScoreText,
  testScoreButton,
  account,
  environment,
  isAuthenticated,
  sitemapAnalysis,
  reportId,
  loading,
  trackers,
  onTrackersFilter,
  current,
  assessmentSummary,
  moveToSection,
  personalData,
  email,
  classes,
  cookieCount,
  records,
  cookieMessageOnFirstLoad,
  adTrackers,
  hasCookieManager,
  flavor,
  demos,
  domainsMetadata,
  privacyChartRaw,
  setPrivacyChartRaw,
  geographicChartRaw,
  setGeographicChartRaw,
  tips,
  setTips,
  planInfo
) => {
  const {
    fingerprintingDetails,
    nonSecureTraffic,
    pentest,
    thirdPartyTrackersDetails,
    hasPrivacyLink,
  } = report.report;

  let showPersonalData = true;
  if (version === REPORT_VERSION.freePage) showPersonalData = !hasPrivacyLink;

  const COMPONENTS = {
    [SECTION_ID.landing]: (
      <Page
        key={SECTIONS_CONTENT.landing.key}
        footerBackgroundColor={SECTIONS_CONTENT.landing.backgroundColor}
        className="blue-background"
        footerText={
          <div className="privacy-tech">Check the test scores in detail</div>
        }
        goToNextSectionAction={goToNextSectionAction}
        trimPaddingTop
      >
        {/* this component calculate the values for testScoresItems */}
        <Landing
          logo={
            account &&
            account.company &&
            account.company.configuration &&
            account.company.configuration.branding
          }
          current={current}
          flavor={flavor}
          geographicChartRaw={geographicChartRaw}
          items={testScoresItems}
          privacyChartRaw={privacyChartRaw}
          report={report}
          reportType={reportType}
          setItems={setTestScoresItems}
          tips={tips}
          totalPages={report?.report?.total}
          url={site}
        />
      </Page>
    ),
    [SECTION_ID.testScores]: (
      <Page
        key={SECTIONS_CONTENT.testScores.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.testScores.title}
            description={testScoreText}
            hasFooter={!!testScoreButton}
          />
        }
        hasFooter={!!testScoreButton}
        footerText={<div className="privacy-tech">{testScoreButton}</div>}
        goToNextSectionAction={goToNextSectionAction}
      >
        <TestScores
          email={account && account.email}
          hasFooter={!!testScoreButton}
          items={testScoresItems}
          environment={environment}
        />
      </Page>
    ),
    [SECTION_ID.trafficSource]: report.report.trafficSources &&
      report.report.trafficSources.constructor === Array &&
      report.report.trafficSources.length && (
        <Page
          key={SECTIONS_CONTENT.trafficSource.key}
          sidebar={
            <SideBar
              title={SECTIONS_CONTENT.trafficSource.title}
              description={SECTIONS_CONTENT.trafficSource.description}
              hasFooter={!!testScoreButton}
            />
          }
          hasFooter={!!testScoreButton}
          footerText={
            <div className="privacy-tech">
              Check out some tips to improve your scores
            </div>
          }
          goToNextSectionAction={goToNextSectionAction}
        >
          <TrafficSources
            setGeographicChartRaw={setGeographicChartRaw}
            traffic={report.report && report.report.trafficSources}
          />
        </Page>
      ),
    [SECTION_ID.security]: (
      <Page
        key={SECTIONS_CONTENT.security.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.security.title}
            description={SECTIONS_CONTENT.security.description(
              classes,
              pentest
            )}
          />
        }
        hasFooter={!isAuthenticated}
        footerText={
          <div className="privacy-tech">Go further with your privacy tech</div>
        }
        goToNextSectionAction={goToNextSectionAction}
      >
        <Security pentest={pentest && pentest.data ? pentest.data : []} />
      </Page>
    ),
    [SECTION_ID.recommendations]: (
      <Page
        key={SECTIONS_CONTENT.recommendations.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.recommendations.title}
            description={SECTIONS_CONTENT.recommendations.description}
          />
        }
        hasFooter={false}
        goToNextSectionAction={goToNextSectionAction}
      >
        <Recommendations
          email={account && account.email}
          hasFooter={!!testScoreButton}
          scores={testScoresItems}
          nonSecureTraffic={nonSecureTraffic}
          thirdPartyTrackersDetails={thirdPartyTrackersDetails}
          fingerprintingDetails={fingerprintingDetails}
          assessmentSummary={assessmentSummary}
          environment={environment}
          tips={tips}
          setTips={setTips}
          planInfo={planInfo}
        />
      </Page>
    ),
    [SECTION_ID.siteAnalysis]: (
      <Page
        key={SECTIONS_CONTENT.siteAnalysis.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.siteAnalysis.title}
            description={SECTIONS_CONTENT.siteAnalysis.description}
          />
        }
        goToNextSectionAction={goToNextSectionAction}
        hasFooter={!isAuthenticated}
      >
        <SiteAnalysis
          sites={sitemapAnalysis}
          origin={{
            id: report.id,
            location: report.location,
          }}
        />
      </Page>
    ),
    [SECTION_ID.personalData]: showPersonalData && (
      <Page
        key={SECTIONS_CONTENT.personalData.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.personalData.title}
            description={SECTIONS_CONTENT.personalData.description(
              classes,
              personalData || {}
            )}
            downloadURI={
              version === REPORT_VERSION.freePage
                ? null
                : `download-xlsx/?id=${reportId}&section=personal-data`
            }
          />
        }
        footerText={
          <span className="privacy-tech">
            {version === REPORT_VERSION.freePage
              ? "Take a look at the opportunities"
              : "Take a look at privacy policy vs Zendata's Check"}
          </span>
        }
        goToNextSectionAction={goToNextSectionAction}
      >
        <PersonalDataUsage
          scanId={report.scan?.pk}
          email={account && account.email}
          environment={environment}
          data={report.report && report.report.personalData}
          loading={loading}
          setPrivacyChartRaw={setPrivacyChartRaw}
        />
      </Page>
    ),
    [SECTION_ID.trackers]: (
      <Page
        key={SECTIONS_CONTENT.trackers.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.trackers.title}
            description={SECTIONS_CONTENT.trackers.description}
            downloadURI={`download-xlsx/?id=${reportId}&section=trackers`}
          />
        }
        footerText={<div className="privacy-tech">Check PII</div>}
        goToNextSectionAction={goToNextSectionAction}
      >
        <Trackers
          title="Trackers"
          data={trackers}
          domainsMetadata={domainsMetadata}
          environment={environment}
          onFilter={onTrackersFilter}
          email={account && account.email}
          reportId={reportId}
        />
      </Page>
    ),
    [SECTION_ID.policy]: (
      <Page
        key={SECTIONS_CONTENT.policy.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.policy.title}
            description={SECTIONS_CONTENT.policy.description}
          />
        }
        footerText={<div className="privacy-tech">Take a look at cookies</div>}
        goToNextSectionAction={goToNextSectionAction}
      >
        <LiveProcesses
          isCurrent={current === SECTION_NAME.policy}
          data={assessmentSummary}
          moveToSection={moveToSection}
          personalData={personalData}
          trackers={trackers}
        />
      </Page>
    ),
    [SECTION_ID.cookies]: (
      <Page
        key={SECTIONS_CONTENT.cookies.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.cookies.title}
            description={SECTIONS_CONTENT.cookies.description}
            downloadURI={`download-xlsx/?id=${reportId}&section=cookies`}
          />
        }
        footerText={<div className="privacy-tech">Review the trackers</div>}
        goToNextSectionAction={goToNextSectionAction}
      >
        <CookieCount
          email={email}
          report={report}
          loading={loading}
          classes={classes}
          count={cookieCount}
          records={records}
        />
      </Page>
    ),
    [SECTION_ID.pii]: (
      <Page
        key={SECTIONS_CONTENT.pii.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.pii.title}
            description={SECTIONS_CONTENT.pii.description}
          />
        }
        footerText={<div className="privacy-tech">Check Consent</div>}
        goToNextSectionAction={goToNextSectionAction}
      >
        <PII
          activePage={
            current === SECTION_ID.pii ||
            global.location.hash === `#${SECTION_ID.pii}` // condition 1 fails when reload in pii page
          }
          data={personalData.scriptAnalysis}
        />
      </Page>
    ),
    [SECTION_ID.consentPage]: (
      <Page
        key={SECTIONS_CONTENT.consentPage.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.consentPage.title}
            description={SECTIONS_CONTENT.consentPage.description}
          />
        }
        footerText={
          <div className="privacy-tech">Review the privacy links</div>
        }
        goToNextSectionAction={goToNextSectionAction}
      >
        <Consent
          cookieMessageOnFirstLoad={cookieMessageOnFirstLoad}
          hasAdTrackers={adTrackers}
          hasCookieManager={hasCookieManager}
        />
      </Page>
    ),
    [SECTION_ID.privacyLinks]: (
      <Page
        key={SECTIONS_CONTENT.privacyLinks.key}
        sidebar={
          <SideBar
            title={SECTIONS_CONTENT.privacyLinks.title}
            description={SECTIONS_CONTENT.description}
          />
        }
        footerText={
          <div className="privacy-tech">
            Check the traffic source distribution
          </div>
        }
        goToNextSectionAction={goToNextSectionAction}
      >
        <PrivacyLinks report={report} />
      </Page>
    ),
    [SECTION_ID.complete]: (
      <Page
        key={SECTIONS_CONTENT.complete.key}
        className="blue-background"
        hasFooter={false}
        fullHeight
      >
        <Complete flavor={flavor} email={email} data={demos} origin={site} />
      </Page>
    ),
    [SECTION_ID.freeReportPitch]: (
      <Page key={SECTIONS_CONTENT.freeReportPitch.key} hasFooter={false}>
        <FreeReportPitch
          flavor={flavor}
          email={email}
          data={demos}
          origin={site}
        />
      </Page>
    ),
  };

  const sectionKeys = ORDERED_ANCHORS[version] || [];
  return sectionKeys
    .map(mapSectionNameToId)
    .map((sectionId) => COMPONENTS[sectionId]);
};

const parseLocationOptionsMeta = (locationsMap = {}) => {
  const locationOptionsMeta = {};

  Object.entries(locationsMap).forEach(([key, value]) => {
    locationOptionsMeta[key.toUpperCase()] = value;
  });

  return locationOptionsMeta;
};

const parseDateOptionsMeta = (datesMap = {}) => {
  const dateOptionsMeta = {};

  Object.entries(datesMap).forEach(([key, value]) => {
    const [firstReportId] = Object.values(value);
    dateOptionsMeta[key] = firstReportId;
  });

  return dateOptionsMeta;
};

const isHeaderHidden = (location) => {
  return (
    location.hash === `#${SECTION_NAME.complete}` ||
    location.hash === `#${SECTION_NAME.freeReportPitch}`
  );
};

/**
 * Checks that report (the one sent retrieved from our backend, NOT the JSON)
 * contains all the information needed to render the UI properly.
 *
 * @param {Object} report
 * @return {Boolean}
 */
const isOk = (report) => {
  return report.data !== null;
};

/**
 * Checks that report JSON (the one coming from Cloudfront) contains all the
 * information needed to render the UI properly.
 *
 * @param {Object} reportJson
 * @return {Boolean}
 * @note {1} assessmentSummary, hasPrivacyLink, hasTermsLink, and screenshotImage
 *    are ignored since the UI can render even if they're null.
 */
const isOkJson = (reportJson) => {
  return (
    !isEmpty(reportJson) &&
    reportJson.adTrackers !== null &&
    reportJson.cookieCount !== null &&
    reportJson.cookieMessageOnFirstLoad !== null &&
    reportJson.details !== null &&
    reportJson.domains !== null &&
    reportJson.domainsMetadata !== null &&
    reportJson.end !== null &&
    // reportJson.fingerprintingScore !== null &&
    reportJson.hasCookieManager !== null &&
    reportJson.records !== null &&
    reportJson.reliability !== null &&
    reportJson.scanTime !== null &&
    reportJson.scanUrl !== null &&
    reportJson.start !== null &&
    reportJson.technologies !== null &&
    reportJson.thirdPartyTrackersScore !== null &&
    reportJson.trackers !== null &&
    reportJson.traffic !== null
  );
};

const FAILED_REPORT_NOTIFICATION = [
  "Report data is incomplete. Re-run report to fix issues.",
  { variant: "error", persist: true },
];

const reportFailedReportJsonIfNeeded = (reportWithJSON, enqueueSnackbar) => {
  if (!isEmpty(reportWithJSON.report)) {
    if (!isOkJson(reportWithJSON.report)) {
      enqueueSnackbar(...FAILED_REPORT_NOTIFICATION);
    }
  }
};

const Report = ({
  account,
  currentReportJSON,
  getPlanInfo,
  getReportJSON,
  history,
  isAuthenticated,
  location,
  planInfo,
  ...props
}) => {
  const classes = useStyles();
  const loading = useReportLoading();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  const reportId = query.get("r");
  const reportType = query.get("c");
  const token = query.get("t");
  const email = query.get("email")
    ? decodeURIComponent(query.get("email"))
    : undefined;

  const [anchors, setAnchors] = useState();
  const [colors, setColors] = useState();
  const [country, setCountry] = useState();
  const [date, setDate] = useState();
  const [locationOptionsMeta, setLocationOptionsMeta] = useState({});
  const [dateOptionsMeta, setDateOptionsMeta] = useState({});
  const [testScoreText, setTestScoreText] = useState();
  const [testScoreButton, setTestScoreButton] = useState();
  const [testScoresItems, setTestScoresItems] = useState([]);
  const [trackers, setTrackers] = useState();
  const [privacyChartRaw, setPrivacyChartRaw] = useState("");
  const [geographicChartRaw, setGeographicChartRaw] = useState("");
  const [tips, setTips] = useState([]);

  const { selected: report, list: demos, ...reports } = useReports();
  // TODO: check the levels.....
  const {
    availableLocations,
    availableReports,
    location: reportLocation,
    locationsMap,
    reportsMap,
    resource: site,
    endedAt: reportDate,
    version,
  } = report;
  const { environment = "web", flavor } = report.scan ? report.scan : {};

  const reportWithJSON = { ...report, report: currentReportJSON };
  const {
    assesmentSummary: assessmentSummary,
    cookieCount,
    cookieMessageOnFirstLoad,
    adTrackers,
    hasCookieManager,
    records = [],
    personalData,
    details,
    sitemapAnalysis = [],
    domainsMetadata,
  } = reportWithJSON.report ? reportWithJSON.report : {};

  useEffect(
    () => {
      reports.get(reportId, email, { t: token }, true).catch(() => {
        history.push("/login");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportId]
  );

  useEffect(() => {
    if (
      locationOptionsMeta[country] &&
      Number(reportId) !== locationOptionsMeta[country] &&
      Object.values(locationOptionsMeta).includes(Number(reportId))
    ) {
      history.push(getReportUrl(locationOptionsMeta[country], reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (dateOptionsMeta[date] && reportId !== dateOptionsMeta[date]) {
      history.push(getReportUrl(dateOptionsMeta[date], reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (flavor && flavor !== FLAVORS.demo) {
      // TODO(@jperozo): Found a better way to show all the reports
      let filters = { pageSize: 3, page: 0 };
      if (flavor === FLAVORS.free) {
        filters.isDemo = true;
      }

      reports.all(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flavor]);

  useEffect(() => {
    setTrackers(details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    getPlanInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isOk(report)) {
        if (report.data) getReportJSON(report.data);

        if (environment) {
          let receivedItems;
          let text;
          let button;

          // TODO: refactor if-else block
          if (environment === "web") {
            if (flavor !== FLAVORS.free) {
              button = "Review the personal data usage";
            }
            if (flavor === FLAVORS.free) {
              button = "See what else we have to offer";
            }
            receivedItems = WEB_TEST_SCORES_ITEMS;
            text =
              "We cross check this collected data against your company's consent and privacy policies.";
          } else {
            if (flavor !== FLAVORS.free) {
              button = "Check Permissions";
            }
            receivedItems = APP_TEST_SCORES_ITEMS;
            text = "Broken down by risks.";
          }

          setTestScoresItems(receivedItems);
          setTestScoreText(text);
          setTestScoreButton(button);
          setTrackers(details);
        }

        setAnchors(ORDERED_ANCHORS[version]);
        setColors(getSectionsColorsFor(version));
        setCountry(reportLocation);
        setDate(reportDate);
        setLocationOptionsMeta(parseLocationOptionsMeta(locationsMap));
        setDateOptionsMeta(parseDateOptionsMeta(reportsMap));
      } else {
        enqueueSnackbar(...FAILED_REPORT_NOTIFICATION);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  const onTrackersFilter = (filtered) => {
    const newTrackers = filtered.length
      ? details.filter((tracker) => {
          const filteredCategories = tracker.categories.filter((category) =>
            filtered.includes(category)
          );
          return filteredCategories.length === filtered.length;
        })
      : details;
    setTrackers(newTrackers);
  };

  reportFailedReportJsonIfNeeded(reportWithJSON, enqueueSnackbar);

  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />
      <div id="page-wrap" className={classes.reportPage}>
        <Header
          hidden={isHeaderHidden(location)}
          url={site}
          availableLocations={availableLocations}
          availableDates={availableReports}
          location={report.location}
          date={date}
          loading={loading}
          setCountry={setCountry}
          setDate={setDate}
          reportType={reportType}
          flavor={flavor}
          history={history}
          previousRouteKey="zenArea"
        />
        {!loading &&
        isOkJson(reportWithJSON.report) &&
        anchors &&
        (!isAuthenticated || Object.keys(planInfo).length) ? (
          <ReactFullpage
            scrollingSpeed={800}
            touchSensitivity={10}
            responsiveWidth={theme.breakpoints.values.md}
            licenseKey={"YOUR_KEY_HERE"}
            scrollBar={false}
            navigation={true}
            normalScrollElements={".scrollable-element"}
            navigationPosition="left"
            sectionsColor={colors}
            anchors={anchors}
            render={({ state, fullpageApi }) => {
              const goToNextSectionAction = fullpageApi
                ? () => fullpageApi.moveSectionDown()
                : undefined;
              const moveToSection = fullpageApi && fullpageApi.moveTo;
              const section = fullpageApi && fullpageApi.getActiveSection();
              const current = section && section.anchor;

              return (
                <ReactFullpage.Wrapper>
                  {getSectionComponents(
                    goToNextSectionAction,
                    site,
                    reportWithJSON,
                    reportType,
                    testScoresItems,
                    setTestScoresItems,
                    version,
                    testScoreText,
                    testScoreButton,
                    account,
                    environment,
                    isAuthenticated,
                    sitemapAnalysis,
                    reportId,
                    loading,
                    trackers,
                    onTrackersFilter,
                    current,
                    assessmentSummary,
                    moveToSection,
                    personalData,
                    email,
                    classes,
                    cookieCount,
                    records,
                    cookieMessageOnFirstLoad,
                    adTrackers,
                    hasCookieManager,
                    flavor,
                    demos,
                    domainsMetadata,
                    privacyChartRaw,
                    setPrivacyChartRaw,
                    geographicChartRaw,
                    setGeographicChartRaw,
                    tips,
                    setTips,
                    planInfo
                  )}
                </ReactFullpage.Wrapper>
              );
            }}
          />
        ) : (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  isAuthenticated: state.authentication.isAuthenticated,
  currentReportJSON: getCurrentReportJSON(state),
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
