import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import SectionContent from "pages/AppReport/common/SectionContent";

const CustomURLSchemesSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.customURLSchemes.key}
      footerBackgroundColor={SECTIONS_CONTENT.customURLSchemes.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the App Transport security</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title>{SECTIONS_CONTENT.customURLSchemes.title}</Title>
      <SectionContent>
        <Table
          data={data}
          limitPerPage={5}
          columns={[
            {
              key: "CFBundleURLName",
              label: "URL Name",
              width: "40%",
              render: (_, scheme) => (
                <span>
                  {scheme?.CFBundleURLName ? (
                    <>
                      {scheme.CFBundleURLName}
                      <br />
                      {scheme.CFBundleTypeRole}
                    </>
                  ) : (
                    "-"
                  )}
                </span>
              ),
            },
            {
              key: "CFBundleURLSchemes",
              label: "Schemes",
              width: "60%",
              render: (schemes) => (
                <div>
                  {schemes && Array.isArray(schemes) ? schemes.join("\n") : "-"}
                </div>
              ),
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default CustomURLSchemesSection;
