import { Typography } from "@material-ui/core";
import useCustomStyles from "./CustomRepoScannerStyles.jsx";

const InstructionList = ({ title, instructions }) => {
  const customClasses = useCustomStyles();
  return (
    <div>
      <Typography variant="body1" gutterBottom>
        {title}
      </Typography>
      {instructions.map((instruction, index) => (
        <Typography
          key={`${instruction}-${index}`}
          variant="body2"
          gutterBottom
          className={
            instruction.startsWith("-") ? customClasses.indentedTwoSpaces : {}
          }
        >
          {instruction}
        </Typography>
      ))}
    </div>
  );
};

export default InstructionList;
