/**
 * Export operations for user sign in and sign out vía calls to API
 *
 * @module authentication/service
 */

import config from "config";
import { authenticationConstants } from "redux/_authentication/authentication.constants";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import isEmpty from "utils/is-empty";

export const authService = {
  login,
  webflowLogin,
  logout,
  signup,
  resetPassword,
  resetConfirmPassword,
  watchSession,
};

/**
 * SignIn user vía  POST call to the endpoint set in module constants
 *
 * @requires authentication/constants
 * @requires config
 * @param {string} email
 * @param {string} password
 * @returns {Promise}
 */
function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  };

  return fetch(
    `${config.apiUrl}${authenticationConstants.LOGIN_ENDPOINT}`,
    requestOptions
  )
    .then((response) => {
      return response.json().then(({ data, errors }) => {
        if (response.ok) {
          return Promise.resolve(data);
        } else {
          return Promise.reject(errors);
        }
      });
    })
    .then((session) => {
      // store session details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(
        authenticationConstants.SESSION,
        JSON.stringify(session)
      );

      return session;
    });
}

function webflowLogin(tmpToken) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${config.apiUrl}${authenticationConstants.WF_LOGIN_ENDPOINT}?t=${tmpToken}`,
    requestOptions
  )
    .then((response) => {
      return response.json().then(({ data, errors }) => {
        if (response.ok) {
          return Promise.resolve(data);
        } else {
          return Promise.reject(errors);
        }
      });
    })
    .then((session) => {
      localStorage.setItem(
        authenticationConstants.SESSION,
        JSON.stringify(session)
      );

      return session;
    });
}

function signup(email, password1, password2) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password1, password2 }),
  };

  return fetch(
    `${config.apiUrl}${authenticationConstants.SIGNUP_ENDPOINT}`,
    requestOptions
  ).then((response) => {
    return response.json().then(({ data, error }) => {
      if (response.ok) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(error.message);
      }
    });
  });
}

/**
 * Reset Password vía  POST call to the endpoint set in module constants
 *
 * @requires authentication/constants
 * @requires config
 * @param {string} email
 * @returns {Promise}
 */
function resetPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${config.apiUrl}${authenticationConstants.RESET_PASSWORD}`,
    requestOptions
  ).then((response) => {
    return response.json().then(({ data, errors }) => {
      if (response.ok) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(errors);
      }
    });
  });
}

/**
 * Confirm Password vía  POST call to the endpoint set in module constants
 *
 * @requires authentication/constants
 * @requires config
 * @param {object} body
 * @returns {Promise}
 */
function resetConfirmPassword(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(
    `${config.apiUrl}${authenticationConstants.RESET_CONFIRM_PASSWORD}`,
    requestOptions
  ).then((response) => {
    return response.json().then(({ data, error }) => {
      if (response.ok) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(error.message);
      }
    });
  });
}

/**
 * SignOut vía POST call to the endpoint se in module constants
 * Note: if signOut is successfull the application is realoaded to make sure
 * cached data are cleared
 *
 * @requires authentication/constants
 * @requires helpers/auth-header
 */
function logout() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  localStorage.removeItem(authenticationConstants.SESSION);
  window.parent.postMessage(
    { type: "LOGOUT" },
    `${process.env.REACT_APP_V2_REPO}`
  ); // Notify the parent window

  if (global.Intercom) global.Intercom("shutdown");
  return fetch(
    `${config.apiUrl}${authenticationConstants.LOGOUT_ENDPOINT}`,
    requestOptions
  )
    .then((response) => handleFetchResponse(response, true))
    .then(() => {
      window.location.reload(); // reload app to clear cached data
    });
}

/**
 * Identifies changes in section data in order to:
 * - clear cached data if toekn is deleted
 * - TODO: refresh token if it is near to expire
 */
function watchSession() {
  const session = window.localStorage.getItem(authenticationConstants.SESSION);
  if (isEmpty(session)) {
    window.location.reload();
  }
}
