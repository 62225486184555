import Popover from "@material-ui/core/Popover";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import Pagination from "components/Pagination";
import usePaginationStyles from "components/Pagination/styles";
import { REPORT_CATEGORIES } from "constants/reports";
import history from "helpers/history";
import useQuery from "hooks/useQuery";
import React, { useState } from "react";
import { getColor } from "../ScoreChart";
import useStyles from "./styles";

const switchToIndividualReport = (reportId, query) => {
  history.push({
    pathname: "privacy-report",
    search: `?r=${reportId}&c=${REPORT_CATEGORIES.web}`,
    state: {
      parentReportId: query.get("r"),
      parentReportCategory: query.get("c"),
    },
  });
};

const getFormatedSiteData = (
  thirdPartyTrackersScore,
  fingerprintingScore,
  httpsTraffic
) => {
  const formattedThirdPartyTrackers = thirdPartyTrackersScore * 100;
  const formattedFingerprintingScore = Math.round(
    (1 - fingerprintingScore) * 100
  );
  const formattedHTTPSTraffic = httpsTraffic === 1 ? "good" : "bad";
  const httpsColor = getColor(formattedHTTPSTraffic === "good" ? 100 : 0);

  return [
    formattedThirdPartyTrackers,
    formattedFingerprintingScore,
    formattedHTTPSTraffic,
    httpsColor,
  ];
};

const Site = ({
  classes,
  url,
  thirdPartyTrackersScore,
  httpsTraffic,
  fingerprintingScore,
  query,
  report,
}) => {
  const [
    formattedThirdPartyTrackers,
    formattedFingerprintingScore,
    formattedHTTPSTraffic,
    httpsColor,
  ] = getFormatedSiteData(
    thirdPartyTrackersScore,
    fingerprintingScore,
    httpsTraffic
  );

  const round = (x) => (Number(x) ? Math.round(x * 100) / 100 : x);

  return (
    <tr
      className={clsx(classes.tr, classes.row)}
      onClick={() => switchToIndividualReport(report, query)}
      title="Click to see page details"
    >
      <td className={clsx(classes.td, classes.domain, "no-transform")}>
        <span>{url}</span>
      </td>
      <td className={clsx(classes.td, classes.thirdPartyTrackers)}>
        <span
          className={classes.dot}
          style={{ backgroundColor: getColor(formattedThirdPartyTrackers) }}
        ></span>
        <span>{round(formattedThirdPartyTrackers)} / 100</span>
      </td>
      <td className={clsx(classes.td, classes.fingerprintingScore)}>
        <span
          className={classes.dot}
          style={{
            backgroundColor: getColor(formattedFingerprintingScore, true),
          }}
        ></span>
        <span>{round(formattedFingerprintingScore)} / 100</span>
      </td>
      <td className={clsx(classes.td, classes.httpsTraffic)}>
        <span
          className={classes.dot}
          style={{ backgroundColor: httpsColor }}
        ></span>
        <span style={{ color: httpsColor, textTransform: "capitalize" }}>
          {httpsTraffic !== 1 && `${Math.ceil(100 - httpsTraffic * 100)}% `}
          {formattedHTTPSTraffic}
        </span>
      </td>
    </tr>
  );
};

const LIMIT_SITES = 7;

const SitemapAnalysis = ({ sites = [], origin }) => {
  const paginationClasses = usePaginationStyles();
  const classes = useStyles();
  const query = useQuery();
  const [current, setCurrent] = useState(0);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const total = Math.ceil(sites.length / LIMIT_SITES);
  const start = current * LIMIT_SITES;
  const end = start + LIMIT_SITES;

  return (
    <div className={classes.sites}>
      <div className={classes.xsTable}>
        {sites
          .sort((a, b) => a.url.length - b.url.length)
          .slice(start, end)
          .map(
            ({
              thirdPartyTrackersScore,
              fingerprintingScore,
              httpsTraffic,
              url,
              report,
            }) => {
              const [
                formattedThirdPartyTrackers,
                formattedFingerprintingScore,
                formattedHTTPSTraffic,
                httpsColor,
              ] = getFormatedSiteData(
                thirdPartyTrackersScore,
                fingerprintingScore,
                httpsTraffic
              );
              return (
                <table className={classes.xsTableTable}>
                  <thead>
                    <tr
                      className={clsx(classes.tr, classes.row)}
                      onClick={() => switchToIndividualReport(report, query)}
                      title="Click to see page details"
                    >
                      <th colSpan="2">{url}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Third Party Trackers</th>
                      <td>
                        <span
                          className={classes.dot}
                          style={{
                            backgroundColor: getColor(
                              formattedThirdPartyTrackers
                            ),
                          }}
                        ></span>
                        <span>{formattedThirdPartyTrackers} / 100</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Fingerprinting Score</th>
                      <td>
                        <span
                          className={classes.dot}
                          style={{
                            backgroundColor: getColor(
                              formattedFingerprintingScore,
                              true
                            ),
                          }}
                        ></span>
                        <span>{formattedFingerprintingScore} / 100</span>
                      </td>
                    </tr>
                    <tr>
                      <th>HTTP Traffic</th>
                      <td>
                        <span
                          className={classes.dot}
                          style={{ backgroundColor: httpsColor }}
                        ></span>
                        <span
                          style={{
                            color: httpsColor,
                            textTransform: "capitalize",
                          }}
                        >
                          {formattedHTTPSTraffic}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            }
          )}
      </div>

      <table className={clsx(classes.table, classes.mdTable)}>
        <thead className={classes.head}>
          <tr className={clsx(classes.th, classes.title)}>
            <th scope="col" className={clsx(classes.domain, classes.th)}>
              URL
            </th>
            <th
              scope="col"
              className={clsx(classes.thirdPartyTrackers, classes.th)}
            >
              Third Party Trackers
            </th>
            <th
              scope="col"
              className={clsx(classes.fingerprintingScore, classes.th)}
            >
              Fingerprinting Score
            </th>
            <th scope="col" className={clsx(classes.https, classes.th)}>
              HTTPS Traffic
              <InfoIcon
                id={"infoIcon"}
                className={classes.infoIcon}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setPopoverOpen(true);
                }}
              />
              <Popover
                id="InfoPopover"
                open={popoverOpen}
                onClose={() => {
                  setPopoverOpen(false);
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <p className={classes.popover}>
                  In case not all requests are secure, this column shows the
                  percentage of requests sent without using the security
                  protocol SSL.
                </p>
              </Popover>
            </th>
          </tr>
        </thead>
        <tbody>
          {sites
            .sort((a, b) => a.url.length - b.url.length)
            .slice(start, end)
            .map((site) => (
              <Site classes={classes} {...site} origin={origin} query={query} />
            ))}
        </tbody>
      </table>
      {end > 1 && (
        <Pagination
          className={paginationClasses.pagination}
          current={current}
          total={total}
          setCurrent={setCurrent}
        />
      )}
    </div>
  );
};

export default SitemapAnalysis;
