import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "./styles";
import Illustration from "assets/illustrations/illus_readytogo.svg";
import { connect } from "react-redux";

import { resetStore } from "redux/_wizard/wizard.actions";

const Step5 = (props) => {
  const { dispatch } = props;
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <img src={Illustration} alt="" />
      <Typography className={classes.title}>Fantastic Job!</Typography>
      <Typography className={classes.subtitle}>
        You have just setted up your first environment and it’s ready to go.
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={() => dispatch(resetStore())}
      >
        Start scan
      </Button>
      <Button
        className={classes.skipButton}
        color="secondary"
        onClick={() => dispatch(resetStore())}
      >
        <Typography className={classes.skipButtonText}>
          or Skip for now
        </Typography>
      </Button>
    </Box>
  );
};

export default connect()(Step5);
