import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Tooltip from "components/Tooltip";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { selectData } from "redux/_filters/filters.actions";
import {
  getFilterPiiCategories,
  getPiiCategories,
} from "redux/_filters/filters.selectors";
import { fetchPiiCategories } from "redux/_filters/filters.async.actions";
import { connect } from "react-redux";
import CONSTANTS from "redux/_filters/filters.constants";
import getIconById from "./constants";
import useStyles from "./styles";

const { TYPES } = CONSTANTS;

const PiiCategoriesFilter = (props) => {
  const classes = useStyles();

  const {
    piiCategories,
    selectedPiiCategories,
    fetchCategories,
    setPiiCategories,
    disabled,
  } = props;

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      <ToggleButtonGroup
        value={selectedPiiCategories}
        onChange={(_, newArr) => setPiiCategories(newArr)}
        classes={{ root: classes.toggleGroup }}
      >
        {piiCategories.map(({ id, name }) =>
          getIconById(id) ? (
            <ToggleButton
              disabled={disabled}
              key={id}
              value={id}
              classes={{
                root: classes.toggleButton,
                label: classes.toggleButtonLabel,
              }}
            >
              <Tooltip title={name} classes={{ tooltip: classes.tooltip }}>
                <img src={getIconById(id)} alt="" />
              </Tooltip>
            </ToggleButton>
          ) : null
        )}
      </ToggleButtonGroup>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  piiCategories: getPiiCategories(state),
  selectedPiiCategories: getFilterPiiCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(fetchPiiCategories()),
  setPiiCategories: (cat) => dispatch(selectData(TYPES.PII_CATEGORIES, cat)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiiCategoriesFilter);
