import AuthenticationControl from "components/login/login.main";
import RegisterForm from "components/Register/simpleForm";
import Seo, { pages } from "components/Seo";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { authService } from "redux/_authentication/authentication.service";

function RegisterContainer({ login }) {
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email, password1, password2 }) => {
    if (email && password1 && password2) {
      setLoading(true);
      let error;
      await authService
        .signup(email, password1, password2)
        .then(() => {
          login(email, password1);
          setLoading(false);
        })
        .catch((err) => {
          error = err;
          setLoading(false);
        });
      return error ? { [FORM_ERROR]: error } : {};
    }
  };

  return (
    <AuthenticationControl>
      <Seo {...pages.login} />
      <RegisterForm onSubmit={onSubmit} loading={loading} />
    </AuthenticationControl>
  );
}

function mapStateToProps(state) {
  const { loggingIn, loginErrors, isAuthenticated } = state.authentication;
  return { loggingIn, loginErrors, isAuthenticated };
}

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    dispatch(authenticationActinon.login(email, password)),
});

const ConnectedRegisterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainer);
export default withRouter(ConnectedRegisterContainer);
