import React from "react";

const GoTop = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / arrows / arrow-top">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0001 5.41436V22.0002H11.0001V5.41436L4.70718 11.7073L3.29297 10.293L12.0001 1.58594L20.7072 10.293L19.293 11.7073L13.0001 5.41436Z"
        fill="white"
      />
    </g>
  </svg>
);

export default GoTop;
