import { makeStyles } from "@material-ui/core";

import {
  emailNoticeMixin,
  stepperDialogTitleMixin,
} from "components/StepperDialog/styles";

export default makeStyles(
  (theme) => ({
    input: {
      width: "70%",
      margin: theme.spacing(2, 0),
    },
    action: {
      width: "70%",
    },
    mainAction: {
      color: "white",
      margin: theme.spacing(2, 0),
    },
    secondaryAction: {
      color: theme.palette.blue.main,
    },
    ...stepperDialogTitleMixin(theme),
    ...emailNoticeMixin(theme),
  }),
  { name: "ConsumerReportFlowForm" }
);
