import { Text, View } from "@react-pdf/renderer";

const CustomURLSchemesPage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>Custom URL Schemes</Text>
      </View>
      <View>
        <View>
          <View style={styles.table}>
            <View style={styles.thead} fixed>
              <View style={styles.tr}>
                <View style={[styles.thCustomWidth, { width: "40%" }]}>
                  <Text>URL NAME</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "65%" }]}>
                  <Text>SCHEMES</Text>
                </View>
              </View>
            </View>
            {Object.entries(data["bundle_url_types"]).map(([key, value]) => {
              return (
                <View style={styles.tr} key={key} wrap={false}>
                  <View style={[styles.tdCustomWidth, { width: "40%" }]}>
                    <Text>{value.CFBundleURLName}</Text>
                  </View>
                  <View style={[styles.tdCustomWidth, { width: "65%" }]}>
                    <Text style={{ width: "60%", wordWrap: "break-word" }}>
                      {value.CFBundleURLSchemes.join(", ")}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default CustomURLSchemesPage;
