import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import EntitySelector from "components/SettingsSections/EntitySelector";
import Section1 from "components/SettingsSections/Tables/sections/section1";
import {
  getDatabaseSettingsData,
  getDatabaseSettingsSelected,
  getDatabaseTablesSettingsDataFormatted,
  getSettingsLoadingState,
} from "redux/_settings/settings.selectors";
import {
  saveData,
  saveDatabaseTables,
  selectData,
  resetTablesFilters,
} from "redux/_settings/settings.actions";
import { ENTITIES } from "redux/_settings/settings.constants";
import { connect } from "react-redux";
import { getDatabasesData } from "redux/_databases/databases.selectors";
import { getDatabaseTables } from "redux/_settings/settings.async.actions";
import { getProviderIcon } from "../components/icons";
import ProviderIcons from "assets/svgs-icons/Providers";
import EmptyState from "components/PageWrapper/components/EmptyState";

const TablesSettings = (props) => {
  const {
    dbList,
    selected,
    setSelected,
    match,
    location,
    history,
    setData,
    getTables,
    setTables,
    resetTablesFilters,
  } = props;
  const classes = useStyles();

  const integrationDBs = dbList.filter((db) => !!db.integration);

  const db_id = location.pathname.replace(match.path, "").split("/")[1];
  if (!isNaN(db_id) && selected !== db_id) setSelected(parseInt(db_id));

  const selectDB = (db) => history.push(`/zen/settings/integrations/${db}`);

  useEffect(
    () => {
      if (selected) {
        if (Number(db_id) !== selected) {
          history.replace(`/zen/settings/integrations/${selected}`);
        }
        // setData(tableDBs.find(({ id }) => parseInt(id) === parseInt(selected)));
        getTables();
        resetTablesFilters();
      } else {
        history.replace(`/zen/settings/integrations/`);
        setData({});
        setTables([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  useEffect(
    () => {
      const selectedDB = integrationDBs.find((db) => db.id === selected);
      if (!selectedDB && !!selected && integrationDBs.length) {
        history.replace(`/zen/settings/integrations/${integrationDBs[0].id}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [integrationDBs]
  );

  return (
    <Box className={classes.container}>
      {integrationDBs.length > 0 ? (
        <EntitySelector
          entityName="Integration Database"
          entities={integrationDBs.map((db) => ({
            ...db,
            icon: getProviderIcon(db.provider_id, db.service),
          }))}
          selected={selected}
          onSelect={selectDB}
          icons={ProviderIcons}
          withSearch
        />
      ) : (
        <Box minHeight="calc(100vh - 160px)" display="flex" alignItems="center">
          <EmptyState
            text="Please create a Database first in order to map its tables."
            ctaText="Create Database"
            customCta={() => history.push("/zen/settings/databases")}
          />
        </Box>
      )}
      {selected ? (
        <Section1 database={integrationDBs.find((db) => db.id === selected)} />
      ) : undefined}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dbList: getDatabasesData(state),
  selected: getDatabaseSettingsSelected(state),
  loading: getSettingsLoadingState(state),
  data: getDatabaseSettingsData(state),
  tables: getDatabaseTablesSettingsDataFormatted(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelected: (id) => dispatch(selectData(ENTITIES.DATABASE, id)),
  setData: (data) => dispatch(saveData(ENTITIES.DATABASE, data)),
  getTables: () => dispatch(getDatabaseTables()),
  setTables: (data) => dispatch(saveDatabaseTables(data)),
  resetTablesFilters: () => dispatch(resetTablesFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TablesSettings);
