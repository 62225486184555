import { Text, View } from "@react-pdf/renderer";

const BrowsableActivitiesPage = ({ styles, data }) => {
  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.title}>Browsable Activities</Text>
        <Text style={styles.subTitle}>
          Browsable activities in APKs (Android apps) and IPAs (iOS apps) refer
          to the application activities (or screens) that can be invoked or
          triggered from an external source, such as a web link. These
          activities can be an important area of interest during static code
          analysis because they expose an interface of the application to
          external inputs and can, therefore, be a potential vector for
          malicious attacks or unintended behavior.
        </Text>
      </View>
      <View style={styles.section}>
        {Object.entries(data.browsable_activities).map(([key, value]) => {
          return (
            <View key={key} wrap={false}>
              <Text style={styles.subTitle}>{key}:</Text>
              <View style={styles.dottedBox}>
                <Text style={styles.text}>
                  Schemes: {value.schemes.join(", ")}
                </Text>
                <Text style={styles.text}>Hosts: {value.hosts.join(", ")}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default BrowsableActivitiesPage;
