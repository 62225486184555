import { Text, View } from "@react-pdf/renderer";
import {
  DOES_NOT_CONSENT_MANAGER,
  DOES_NOT_HAVE_COOKIES_MESSAGE,
  DOES_NOT_HAVE_PRIVACY_POLICY,
  DOES_NOT_HAVE_TERMS,
  HAS_CONSENT_MANAGER,
  HAS_COOKIE_MESSAGE,
  HAS_PRIVACY_POLICY,
  HAS_TERMS,
} from "components/PrivacyReports/Report/components/constants";
import styles from "./styles";

import renderPoint from "../point";

const blue = "#1a3586";
const point = renderPoint(blue);

const ConsentAndLinksPage = ({
  styles: globalStyles,
  cookieMessageOnFirstLoad,
  hasCookieManager,
  hasPrivacyPolicy,
  hasTermsAndConditions,
}) => (
  <View style={globalStyles.section}>
    <View>
      <Text style={globalStyles.title}>Consent</Text>
      <Text>User Consent Checks</Text>
    </View>
    <View style={globalStyles.ul}>
      {cookieMessageOnFirstLoad ? (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{HAS_COOKIE_MESSAGE}</Text>
        </View>
      ) : (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>
            {DOES_NOT_HAVE_COOKIES_MESSAGE}
          </Text>
        </View>
      )}
      {hasCookieManager ? (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{HAS_CONSENT_MANAGER}</Text>
        </View>
      ) : (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{DOES_NOT_CONSENT_MANAGER}</Text>
        </View>
      )}
    </View>
    <View style={styles.privacySection}>
      <Text style={[globalStyles.title, styles.title]}>Privacy Links</Text>
    </View>
    <View style={globalStyles.ul}>
      {hasPrivacyPolicy ? (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{HAS_PRIVACY_POLICY}</Text>
        </View>
      ) : (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>
            {DOES_NOT_HAVE_PRIVACY_POLICY}
          </Text>
        </View>
      )}
      {hasTermsAndConditions ? (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{HAS_TERMS}</Text>
        </View>
      ) : (
        <View style={globalStyles.li}>
          {point}
          <Text style={globalStyles.liText}>{DOES_NOT_HAVE_TERMS}</Text>
        </View>
      )}
    </View>
  </View>
);

export default ConsentAndLinksPage;
