import config from "config";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const register = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.apiUrl}/register`, requestOptions).then((response) => {
    return response.json().then(({ data, errors }) => {
      if (response.ok) {
        return Promise.resolve(data);
      } else {
        return Promise.reject(errors);
      }
    });
  });
};

export const fetchPlans = () => {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl}/payments/plans/`;

  return fetch(url, requestOptions).then(handleFetchResponse);
};
