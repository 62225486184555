import ZenDataLogo from "assets/illustrations/brandapp.js";
import Chrome from "assets/illustrations/chrome.svg";
import React from "react";
import useStyles from "./styles";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authenticationActinon } from "redux/_authentication/authentication.actions";

const ReportsPage = ({ logout }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <div className={classes.container}>
          <span className={classes.logo}>
            <ZenDataLogo width="273" height="36" />
          </span>
        </div>
      </div>
      <div className={classes.container}>
        <button className={classes.logoutButton} onClick={logout}>
          <LogoutIcon className={classes.logoutButtonIcon} />
          Logout
        </button>
        <div className={classes.body}>
          <div className={classes.box}>
            <img src={Chrome} alt="chrome logo" width="150" />
            <a
              className={classes.storeLink}
              target="_blank"
              rel="noreferrer"
              href="https://chrome.google.com/webstore/detail/zendata/#"
            >
              Get the Chrome extension here.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authenticationActinon.logout()),
});

const ConnectedSidebar = connect(null, mapDispatchToProps)(ReportsPage);

export default withRouter(ConnectedSidebar);
