import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "32px 24px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 0",
    },
  },
  content: {
    maxWidth: 1600,
    width: "100%",
    height: "100%",
  },
  dataContainer: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(5, 0, 0),
    flexDirection: "column",
  },
  dataContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(6),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  graphContainer: {
    width: "60%",
  },
  tableContainer: {
    width: "40%",
  },
}));
