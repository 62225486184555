import React from "react";

const Box = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.9854 22.8945C31.3756 23.4376 31.2976 24.1358 30.8293 24.5236C30.2829 24.9115 29.5024 24.8339 29.1122 24.3685L26.3805 20.8776L23.7268 24.2909C23.3366 24.8339 22.5561 24.8339 22.0098 24.4461C21.4634 24.0582 21.3854 23.36 21.7756 22.817L24.8976 18.783L21.7756 14.7491C21.3854 14.2061 21.5415 13.4303 22.0098 13.0424C22.5561 12.6545 23.3366 12.8097 23.7268 13.2752L26.3805 16.7661L29.1122 13.4303C29.5024 12.8873 30.2049 12.8097 30.8293 13.1976C31.3756 13.5855 31.3756 14.3612 30.9854 14.9042L27.9415 18.8606L30.9854 22.8945V22.8945ZM16.7805 22.4291C14.7512 22.4291 13.1122 20.8776 13.1122 18.8606C13.1122 16.9212 14.7512 15.2921 16.7805 15.2921C18.8098 15.2921 20.4488 16.9212 20.4488 18.8606C20.3707 20.8776 18.7317 22.4291 16.7805 22.4291ZM6.00976 22.4291C3.98049 22.4291 2.34146 20.8776 2.34146 18.8606C2.34146 16.9212 3.98049 15.2921 6.00976 15.2921C8.03902 15.2921 9.67805 16.9212 9.67805 18.8606C9.67805 20.8776 8.03902 22.4291 6.00976 22.4291V22.4291ZM16.7805 12.9648C14.5171 12.9648 12.4878 14.2061 11.4732 16.0679C10.4585 14.2061 8.42927 12.9648 6.0878 12.9648C4.68293 12.9648 3.43415 13.4303 2.41951 14.1285V9.16364C2.41951 8.54303 1.87317 8 1.24878 8C0.546341 8 0 8.54303 0 9.16364V18.9382C0.0780488 22.1964 2.73171 24.7564 6.00976 24.7564C8.35122 24.7564 10.3805 23.4376 11.3951 21.5758C12.4098 23.4376 14.439 24.7564 16.7024 24.7564C20.0585 24.7564 22.7902 22.1188 22.7902 18.783C22.8683 15.6024 20.1366 12.9648 16.7805 12.9648V12.9648Z"
      fill="#0071F7"
    />
  </svg>
);

export default Box;
