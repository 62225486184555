import { Button, Typography, Box } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import AddDialog from "../../components/AddDialog";
import SectionWrapper from "../../components/sectionWrapper";
import SvgIcon from "components/svg-icon/svg-icon";

import { getEnvironmentSettingsSelected } from "redux/_settings/settings.selectors";

import useStyles from "./styles";

const Section2 = (props) => {
  const envSelected = useSelector((state) =>
    getEnvironmentSettingsSelected(state)
  );
  const { databases, selectedEnv, updateEnvDatabases } = props;
  const classes = useStyles();

  const envDatabases = useMemo(
    () =>
      !isNil(databases) && !isEmpty(databases) && !isNil(selectedEnv)
        ? databases.filter(
            ({ environment_id }) =>
              parseInt(environment_id) === parseInt(selectedEnv)
          )
        : [],
    [databases, selectedEnv]
  );

  const { enqueueSnackbar } = useSnackbar();

  const filterDatabases = (options) =>
    options.filter(({ environment_id }) => isNil(environment_id));

  const handleDialog = (dbIds) => {
    if (!isEmpty(dbIds)) {
      const newDatabasesData = databases
        .filter(({ id }) => dbIds.includes(parseInt(id)))
        .map((db) => ({ ...db, environment_id: selectedEnv }));
      Promise.all(
        newDatabasesData.map((el) => updateEnvDatabases(el))
      ).then((_) =>
        enqueueSnackbar("Databases successfully added!", { variant: "success" })
      );
    }
  };

  const onDeleteClick = (id) => {
    const newDatabaseData = {
      ...databases.find(({ id: dbId }) => parseInt(dbId) === parseInt(id)),
      environment_id: null,
    };
    updateEnvDatabases(newDatabaseData).then((_) =>
      enqueueSnackbar("Databases successfully removed!", { variant: "success" })
    );
  };

  return (
    <SectionWrapper
      title="Current Databases"
      subtitle={
        envSelected
          ? "That belong to this environment."
          : "You should create an environment first in order to add a database."
      }
    >
      <Box className={classes.databasesWrapper}>
        <AddDialog options={filterDatabases(databases)} onSubmit={handleDialog}>
          Add DB
        </AddDialog>
        {envDatabases.map(({ id, name, icon = "database" }, idx) => (
          <Button
            className={classes.entityButton}
            classes={{
              root: classes.entityButton,
              label: classes.entityButtonLabel,
              hover: classes.entityButtonHover,
            }}
            key={idx}
            value={id}
            onClick={(_) => onDeleteClick(id)}
          >
            <DeleteOutlined className={classes.deleteIcon} />
            <SvgIcon icon={icon} />
            <Typography noWrap className={classes.buttonTypography}>
              {name}
            </Typography>
          </Button>
        ))}
      </Box>
    </SectionWrapper>
  );
};

export default Section2;
