import React from "react";
import isNil from "lodash.isnil";
import { Box, Typography, CircularProgress } from "@material-ui/core";

import useStyles from "./styles";

const ReportPreview = ({ selected }) => {
  const classes = useStyles();
  return (
    <Box className={classes.previewContainer}>
      {!isNil(selected) ? (
        <Box className={classes.content}>
          <Box>
            <Typography className={classes.previewText}>
              Privacy Report for:
            </Typography>
            <Typography className={classes.previewSite}>
              {selected.site}
            </Typography>
          </Box>
          <img
            src={`data:image/png;base64,${selected.image}`}
            alt={selected.site + " screenshot"}
          />
        </Box>
      ) : (
        <Box className={classes.emptyContainer}>
          <CircularProgress
            className={classes.buttonLoader}
            color="primary"
            size={36}
          />
        </Box>
      )}
    </Box>
  );
};

export default ReportPreview;
