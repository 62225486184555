import React from "react";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import Button from "components/Button";
import { platformInstructions } from "./instructions";

const TokenModal = ({ open, handleClose, platform }) => {
  
  const instructions = platformInstructions[platform] || [];


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            maxHeight: "90%",
            width: "80%",
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <Typography
            style={{fontFamily:"Inter", fontSize:16,color:"#000000"}}
          >
            How to get my token
          </Typography>
          <Grid container spacing={2}>
            {instructions && instructions.tokenInstructions.map((card, index) => {
              const uniqueKey = `${index}-${card.imageSrc}`;
              return (
                <Grid item xs={3} key={uniqueKey}>
                  <div
                    style={{
                      backgroundColor: "#F5F5F5",
                      height: 200,
                      maxWidth: 241,
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          backgroundColor: "#F5F5F5",
                          height: 150,
                          objectFit: "contain",
                        }}
                        src={card.imageSrc}
                        alt="instruction-img"
                      />
                    </div>
                  </div>
                  <p
                    style={{
                      marginTop: 10,
                      color: "black",
                    }}
                  >
                    {card.description}
                  </p>
                </Grid>
              );
            })}
          </Grid>
            <Box style={{
              display:"flex",
              flexDirection:"row",
              gap:8,
              marginLeft:576,
            }}>
              <Button
                variant="outlined"
                style={{
                  fontFamily: "Inter",
                  color: "#1B3586",
                  fontSize: "14px",
                  fontWeight: "900",
                  cursor: "pointer",
                  backgroundColor: 'transparent'
                }}
                onClick={handleClose}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  fontFamily: "Inter",
                  color: "#1B3586",
                  backgroundColor:"#6BD4CA",
                  fontSize: "14px",
                  fontWeight: "900",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                continue
              </Button>
            </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TokenModal;
