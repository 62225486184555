import { countriesConstants } from "redux/_countries/countries.constants";
import { countriesService } from "redux/_countries/countries.service";
import isEmpty from "lodash.isempty";

export const countriesActions = {
  getAll,
};

function getAll(props = {}) {
  const { force = false } = props;

  return (dispatch, getState) => {
    const state = getState();
    const data =
      state.countries && state.countries.data ? state.countries.data : [];

    if (data && !isEmpty(data) && !force) {
      return Promise.resolve();
    }

    dispatch(_countriesRequest());
    return countriesService
      .getAll()
      .then((data) => {
        dispatch(_countriesSuccess(data));
        return data;
      })
      .catch((errors) => {
        dispatch(_countriesFailure(errors));
        return Promise.reject(errors);
      });
  };
}

const _countriesRequest = () => ({
  type: countriesConstants.GET_COUNTRIES_REQUEST,
});
const _countriesSuccess = (data) => ({
  type: countriesConstants.GET_COUNTRIES_SUCCESS,
  data,
});
const _countriesFailure = (errors) => ({
  type: countriesConstants.GET_COUNTRIES_FAILURE,
  errors,
});
