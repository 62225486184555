import { CONSTANTS } from "redux/_consents/_policies/policies.constants";
import unionBy from "lodash.unionby";
import isArray from "lodash.isarray";

const initialState = {
  loading: true,
  data: [],
};

export const policies = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.POLICIES_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.POLICIES_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.POLICIES_SAVE_DATA:
      return {
        ...state,
        data: isArray(data)
          ? unionBy(state.data, data, "id")
          : unionBy([data], state.data, "id"),
      };
    case CONSTANTS.POLICIES_DELETE:
      return {
        ...state,
        data: state.data.filter((policy) => policy.id !== data),
      };
    default:
      return state;
  }
};
