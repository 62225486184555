import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Popover from "@material-ui/core/Popover";
import { Tab, Tabs } from "components/PageWrapper";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { diff as diffUtils } from "../utils";

const SERIES = {
  total: "Total",
  trackers: "Trackers",
  fingerprinting: "Fingerprinting",
  traffic: "Traffic",
  cookies: "Cookies",
  terms: "Terms",
};

const tooltipLabels = {
  total: "Total Score",
  fingerprinting: "Fingerprinting",
  hasCookieManager: "Has Cookie Manager",
  hasCookieMessage: "Has Cookie Message On First Load",
  hasPrivacyLink: "Has Privacy Link",
  hasTermsLink: "Has Terms Link",
  httpsTraffic: "HTTPS Traffic",
  thirdPartyTrackers: "Third Party Trackers",
};

const ChartTab = ({ cls, title, diff }) => {
  const highNumber = Math.abs(diff) > 999;
  const arrow = diff < 0 ? "↓" : diff !== 0 && "↑";
  const color = diff < 0 ? cls.red : cls.green;

  return (
    <div>
      {title}
      <br />
      {highNumber ? (
        <span className={color}>
          {arrow}
          +999%
        </span>
      ) : (
        <span className={color}>
          {arrow}
          {Math.round(Math.abs(diff) * 100) / 100}%
        </span>
      )}
    </div>
  );
};

const ScoresPopover = ({
  report,
  scoresPopoverOpen,
  setScoresPopoverOpen,
  anchorEl,
}) => {
  const [series, setSeries] = useState(0);
  const [selectedTab, selectTab] = useState(SERIES.total);
  const [comparison, setComparison] = useState({
    [SERIES.total]: 0,
    [SERIES.trackers]: 0,
    [SERIES.fingerprinting]: 0,
    [SERIES.traffic]: 0,
    [SERIES.cookies]: 0,
    [SERIES.terms]: 0,
  });
  const classes = useStyles();

  useEffect(() => {
    const diff = diffUtils.bind(null, report.scores);
    if (diff === null) return;

    setComparison({
      [SERIES.total]: diff("total"),
      [SERIES.trackers]: diff("thirdPartyTrackers"),
      [SERIES.fingerprinting]: diff("fingerprinting"),
      [SERIES.traffic]: diff("httpsTraffic"),
      [SERIES.cookies]:
        (diff("hasCookieManager") + diff("hasCookieMessage")) / 2,
      [SERIES.terms]: (diff("hasTermsLink") + diff("hasPrivacyLink")) / 2,
    });
  }, [report]);

  const renderHistoricalScores = () => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create("historicalScores", am4charts.XYChart);
    chart.data = report.scores.map(
      ({
        created,
        total,
        fingerprinting,
        hasCookieManager,
        hasCookieMessage,
        hasPrivacyLink,
        hasTermsLink,
        httpsTraffic,
        thirdPartyTrackers,
      }) => ({
        created,
        total,
        fingerprinting,
        hasCookieManager,
        hasCookieMessage,
        hasPrivacyLink,
        hasTermsLink,
        httpsTraffic,
        thirdPartyTrackers,
      })
    );

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;

    const createSeries = (field, name) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "created";
      series.name = name;

      series.tooltipText = `${tooltipLabels[field]}: [bold]{${field}}[/]`;
      series.strokeWidth = 2;

      series.tooltip.background.cornerRadius = 15;
      series.tooltip.background.fill = am4core.color("#fff");
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.getFillFromObject = false;
      series.tooltip.label.fill = am4core.color("#00");
      series.tooltip.label.minHeight = 30;
      series.tooltip.label.minWidth = 30;
      series.tooltip.label.textAlign = "middle";
      series.tooltip.pointerOrientation = "vertical";

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color("#fff");
      bullet.circle.radius = 4;
      bullet.circle.strokeWidth = 2;

      const bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.3;

      return series;
    };

    const total = createSeries("total", "total");
    const fingerprinting = createSeries("fingerprinting", "fingerprinting");
    const cookies1 = createSeries("hasCookieManager", "hasCookieManager");
    const cookies2 = createSeries("hasCookieMessage", "hasCookieMessage");
    const terms1 = createSeries("hasPrivacyLink", "hasPrivacyLink");
    const terms2 = createSeries("hasTermsLink", "hasTermsLink");
    const traffic = createSeries("httpsTraffic", "httpsTraffic");
    const thirdPartyTrackers = createSeries(
      "thirdPartyTrackers",
      "thirdPartyTrackers"
    );

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = 0;

    fingerprinting.hidden = true;
    cookies1.hidden = true;
    cookies2.hidden = true;
    terms1.hidden = true;
    terms2.hidden = true;
    traffic.hidden = true;
    thirdPartyTrackers.hidden = true;

    setSeries({
      [SERIES.total]: total,
      [SERIES.fingerprinting]: fingerprinting,
      [`${SERIES.cookies}1`]: cookies1,
      [`${SERIES.cookies}2`]: cookies2,
      [`${SERIES.terms}1`]: terms1,
      [`${SERIES.terms}2`]: terms2,
      [SERIES.traffic]: traffic,
      [SERIES.trackers]: thirdPartyTrackers,
    });
  };

  const onTabChange = (tab) => {
    selectTab(tab);
    const showIf = (x) => (x ? "show" : "hide");
    series[SERIES.total][showIf(tab === SERIES.total)]();
    series[SERIES.fingerprinting][showIf(tab === SERIES.fingerprinting)]();
    series[`${SERIES.cookies}1`][showIf(tab === SERIES.cookies)]();
    series[`${SERIES.cookies}2`][showIf(tab === SERIES.cookies)]();
    series[`${SERIES.terms}1`][showIf(tab === SERIES.terms)]();
    series[`${SERIES.terms}2`][showIf(tab === SERIES.terms)]();
    series[SERIES.traffic][showIf(tab === SERIES.traffic)]();
    series[SERIES.trackers][showIf(tab === SERIES.trackers)]();
  };

  return (
    <Popover
      id="ScoresPopover"
      open={scoresPopoverOpen}
      className={classes.popover}
      onClose={() => {
        setScoresPopoverOpen(false);
      }}
      anchorEl={anchorEl}
      onEntered={renderHistoricalScores}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className={classes.popoverContainer}>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => onTabChange(newValue)}
        >
          <Tab
            value={SERIES.total}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.total}
                diff={comparison[SERIES.total]}
              />
            }
          />
          <Tab
            value={SERIES.trackers}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.trackers}
                diff={comparison[SERIES.trackers]}
              />
            }
          />
          <Tab
            value={SERIES.fingerprinting}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.fingerprinting}
                diff={comparison[SERIES.fingerprinting]}
              />
            }
          />
          <Tab
            value={SERIES.traffic}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.traffic}
                diff={comparison[SERIES.traffic]}
              />
            }
          />
          <Tab
            value={SERIES.cookies}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.cookies}
                diff={comparison[SERIES.cookies]}
              />
            }
          />
          <Tab
            value={SERIES.terms}
            className={classes.tab}
            label={
              <ChartTab
                cls={classes}
                title={SERIES.terms}
                diff={comparison[SERIES.terms]}
              />
            }
          />
        </Tabs>
        <div id="historicalScores" className={classes.chartContainer} />
      </div>
      <div className={classes.dateFilter}>
        <select>
          <option>Last 7 days</option>
        </select>
      </div>
    </Popover>
  );
};

export default ScoresPopover;
