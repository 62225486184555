import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import EntitySelector from "components/SettingsSections/EntitySelector";
import Form from "components/FormComponents/Form";
import Section1 from "components/SettingsSections/Database/sections/section1";
import {
  getDatabaseSettingsData,
  getDatabaseSettingsSelected,
  getSettingsLoadingState,
} from "redux/_settings/settings.selectors";
import { saveData, selectData } from "redux/_settings/settings.actions";
import { ENTITIES } from "redux/_settings/settings.constants";
import { connect } from "react-redux";
import isNil from "lodash.isnil";
import {
  createDatabase,
  deleteDatabase,
  updateDatabase,
} from "redux/_databases/databases.async.actions";
import {
  getDatabasesData,
  getDBLoadingState,
} from "redux/_databases/databases.selectors";
import { getDatasourcesData } from "redux/_datasources/datasources.selectors";
import isEmpty from "lodash.isempty";
import { getProviderIcon } from "../components/icons";
import ProviderIcons from "assets/svgs-icons/Providers";

const DatabaseSettings = (props) => {
  const {
    dbList,
    selected,
    setSelected,
    match,
    location,
    history,
    setData,
    data,
    update,
    dsList,
    deleteEntity,
    create,
    dbLoading,
  } = props;
  const classes = useStyles();
  const [isValid, validate] = useState(false);
  const tableDBs = dbList.filter((db) => !db.integration);

  useEffect(() => {
    const db_id = location.pathname.replace(match.path, "").split("/")[1];
    if (db_id && selected !== db_id) setSelected(parseInt(db_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (selected) {
        history.replace(`/zen/settings/databases/${selected}`);
        setData(tableDBs.find(({ id }) => parseInt(id) === parseInt(selected)));
      } else {
        history.replace(`/zen/settings/databases/`);
        setData({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const onSubmit = async (values) => {
    if (selected) {
      try {
        const newData = await update(values);
        setData(newData);
      } catch (e) {
        return e;
      }
    } else {
      try {
        await create(values);
      } catch (e) {
        return e;
      }
    }
  };

  useEffect(
    () => {
      if (!isNil(selected) && isEmpty(data))
        setData(dbList.find(({ id }) => parseInt(id) === parseInt(selected)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dbList]
  );

  const onDelete = () =>
    deleteEntity(selected).then((_) => {
      setSelected(null);
    });

  return (
    <Box className={classes.container}>
      <EntitySelector
        entityName="Database"
        entities={tableDBs
          .filter((db) => !db.integration)
          .map((db) => ({
            ...db,
            icon: getProviderIcon(db.provider_id, db.service),
          }))}
        selected={selected}
        onSelect={setSelected}
        newLabel="New DB"
        icons={ProviderIcons}
        withSearch
      />
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        showSubmit={!selected}
        submitLabel={!selected ? "Create" : "Update"}
        loading={dbLoading}
        invalid={!isValid}
      >
        <Section1
          showDeleteButton={!isNil(selected)}
          onDeleteClick={onDelete}
          datasources={dsList}
          loading={!!Object.keys(data).length} // disabled all the fields
          validate={validate}
        />
      </Form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dbList: getDatabasesData(state),
  dsList: getDatasourcesData(state),
  selected: getDatabaseSettingsSelected(state),
  loading: getSettingsLoadingState(state),
  data: getDatabaseSettingsData(state),
  dbLoading: getDBLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelected: (id) => dispatch(selectData(ENTITIES.DATABASE, id)),
  setData: (data) => dispatch(saveData(ENTITIES.DATABASE, data)),
  update: (data) => dispatch(updateDatabase(data)),
  deleteEntity: (id) => dispatch(deleteDatabase(id)),
  create: (data) => dispatch(createDatabase(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseSettings);
