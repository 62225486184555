import { TextField } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { SelectItem } from "../Select";
import useStyles from "../Select/styles";

// TODO(@jperozo): Make more changes in order to make it generic
const AutoCompleteSelectInput = ({
  onChange,
  value: fieldValue,
  placeholder,
  error,
  errorText: errorTextProp,
  wizardError,
  helperText,
  options,
  initialValues,
  reference,
  ...rest
}) => {
  const [innerError, setError] = useState(error);
  const classes = useStyles({
    quantity: fieldValue.length,
    error: innerError,
    light: true,
  });
  const otherProps = fieldValue.length === 0 ? { placeholder } : {};
  const errorText =
    errorTextProp || "Please select at least one jurisdiction / country.";
  const defaultValue = initialValues
    ? options.filter((option) => initialValues.includes(option.value))
    : [];

  useEffect(() => {
    if (!innerError && wizardError) {
      setError(wizardError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardError]);

  return (
    <Autocomplete
      {...rest}
      multiple
      options={options}
      defaultValue={defaultValue}
      disableClearable={true}
      ListboxComponent="div"
      onBlur={(e) => {
        // Setting fieldValue empty
        const isEmpty = !e.target.classList.contains(
          "MuiInputBase-inputAdornedStart"
        );
        if (isEmpty) {
          onChange([]);
        }

        setError(isEmpty);
      }}
      id="combo-box-demo"
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <SelectItem component="div" classes={classes} option={option} />
      )}
      renderTags={(value, getTagProps) => {
        const parsedValues = value.map((item) => item.value);
        if (value.length !== fieldValue.length) {
          onChange(parsedValues);
        }

        if (innerError) {
          setError(false);
        }

        return value.map((option, index) => (
          <Chip
            label={option.label}
            avatar={<Avatar alt={option.value}>{option.icon}</Avatar>}
            {...getTagProps({ index })}
          />
        ));
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            {...otherProps}
            ref={reference}
            className={clsx(
              classes.textField,
              "autocomplete",
              fieldValue.length > 2 && "multiple",
              innerError && "-error"
            )}
            margin="normal"
            variant="outlined"
          />
          {innerError && (
            <p className={clsx(classes.helperText, "autocomplete")}>
              {errorText}
            </p>
          )}
        </>
      )}
    />
  );
};

export default AutoCompleteSelectInput;
