import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, makeStyles, Typography, Box } from "@material-ui/core";
import Tooltip from "components/Tooltip";
import TextInput from "components/Inputs/Text";
import Checkbox from "components/Inputs/Checkbox";
import SectionWrapper from "../../components/sectionWrapper";
import SvgIcon from "components/svg-icon/svg-icon";
import ScansTable from "../../components/ScansTable";
import { getDatabaseTables } from "redux/_settings/settings.async.actions";
import { setTableFilters } from "redux/_settings/settings.actions";
import { TABLE_FILTERS } from "redux/_settings/settings.constants";
import Loader from "components/Loader";
import isNil from "lodash.isnil";

import {
  getDatabaseTablesSettingsData,
  getDatabaseTableSettingsFilters,
  getSettingsLoadingState,
} from "redux/_settings/settings.selectors";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  scansButton: {
    margin: "1rem 0",
    "&:disabled svg path": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  },
  scansButtonLabel: {
    fontSize: "15px",
    fontWeight: 600,
    marginLeft: ".5rem",
  },
  sidebarInput: {
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
      color: theme.palette.grey[800],
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[500],
    },
  },
  sidebarInputIcon: {
    fill: "#828282",
  },
  sidebarCheckbox: {
    marginTop: "1rem",
  },
}));

const Section1 = ({
  loading,
  tables = [],
  getTables,
  setTableFilters,
  tableFilters,
  database = {},
}) => {
  const classes = useStyles();

  const handleSearch = (value) => setTableFilters(TABLE_FILTERS.SEARCH, value);

  const handleVisibility = (e) =>
    setTableFilters(TABLE_FILTERS.HIDE_WITH_DEPARTMENTS, e.target.checked);

  const filteredTables = useMemo(() => {
    const regex = new RegExp(tableFilters[TABLE_FILTERS.SEARCH], "gi");
    const searchTables = tables.filter(
      (table) => String(table.table_name).search(regex) !== -1
    );
    return tableFilters[TABLE_FILTERS.HIDE_WITH_DEPARTMENTS]
      ? tables.filter((table) => isNil(table.id_functional_use))
      : searchTables;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables, tableFilters]);

  const hasEnvironment = Boolean(database) && Boolean(database.environment_id);
  return (
    <SectionWrapper
      title="Table Mapping"
      leftContent={
        <>
          <TextInput
            value={tableFilters[TABLE_FILTERS.SEARCH]}
            onChange={handleSearch}
            label="Search"
            placeholder="Table name"
            className={classes.sidebarInput}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search className={classes.sidebarInputIcon} />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.sidebarCheckbox}>
            <Checkbox
              onChange={handleVisibility}
              label="Visibility"
              text="Hide Tables with Departments"
              value={tableFilters[TABLE_FILTERS.HIDE_WITH_DEPARTMENTS]}
            />
          </div>
        </>
      }
    >
      {loading ? (
        <Loader />
      ) : tables.length === 0 ? (
        <>
          <Typography>
            There are no tables detected for this database yet.
          </Typography>
          <Tooltip
            placement="right"
            title={
              hasEnvironment
                ? ""
                : "You need to link this Database to an Environment first."
            }
          >
            <Box display="inline">
              <Button
                component={hasEnvironment ? Link : undefined}
                className={classes.scansButton}
                color="primary"
                variant="outlined"
                disabled={!hasEnvironment}
                to={
                  hasEnvironment
                    ? `/zen/environment/${database.environment_id}/scans`
                    : ""
                }
              >
                <SvgIcon icon="scan" color="#1a3586" />
                <Typography className={classes.scansButtonLabel}>
                  SCAN FOR TABLES
                </Typography>
              </Button>
            </Box>
          </Tooltip>
        </>
      ) : (
        <ScansTable tables={filteredTables} />
      )}
    </SectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  tables: getDatabaseTablesSettingsData(state),
  tableFilters: getDatabaseTableSettingsFilters(state),
  loading: getSettingsLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTables: () => dispatch(getDatabaseTables()),
  setTableFilters: (dataType, data) =>
    dispatch(setTableFilters(dataType, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Section1);
