import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PiiGroupsTooltip from "charts/PieCharts/PiiGroupsTooltip";
import isEmpty from "lodash.isempty";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
  title: {
    fontSize: "14px",
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    paddingBottom: "10px",
    textTransform: "uppercase",
  },
  content: {
    paddingTop: theme.spacing(2),
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    color: "#000000",
  },
  piiCategory: {
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    lineHeight: 1.83,
    color: theme.palette.grey[200],
  },
}));

const getPiiGroups = (data) => {
  let groups = {};
  data.children.forEach(
    ({ name, matches }) => (groups = { ...groups, [name]: matches })
  );
  return groups;
};

const getPiiCategories = (data) => {
  const piiGroups = data.children || [];

  let groupsCategories = [];

  if (!isEmpty(piiGroups)) {
    piiGroups.forEach(({ children }) =>
      children.forEach(({ name, matches }) =>
        groupsCategories.push({
          name,
          percentage: ((matches / data.matches) * 100).toFixed(2),
        })
      )
    );
  }

  return groupsCategories.sort(
    ({ percentage: p1 }, { percentage: p2 }) => p2 - p1
  );
};

const Tooltip = (props) => {
  const classes = useStyles();
  const { data } = props;

  const piiGroups = useMemo(() => getPiiGroups(data), [data]);

  const piiCategories = useMemo(() => getPiiCategories(data), [data]);

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {data.name.replace(/_/g, " ")}
      </Typography>
      <Box className={classes.content}>
        <Typography className={classes.subtitle}>PII GROUPS</Typography>
        <PiiGroupsTooltip data={piiGroups} />
      </Box>
      <Box className={classes.content}>
        <Box
          className={classes.subtitleContainer}
          style={{ paddingBottom: "8px" }}
        >
          <Typography className={classes.subtitle} display="inline">
            PII CATEGORIES
          </Typography>
          <Typography className={classes.subtitle} display="inline">
            %
          </Typography>
        </Box>
        {piiCategories.map(({ name, percentage }) => (
          <Box key={name} className={classes.subtitleContainer}>
            <Typography className={classes.piiCategory} display="inline">
              {name}
            </Typography>
            <Typography className={classes.piiCategory} display="inline">
              {percentage}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Tooltip;
