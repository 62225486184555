import {
  associatePaymentMethod,
  detachPaymentMethod,
  update,
} from "redux/_admin/_billing/billing.service";
import {
  requestStart,
  requestEnd,
} from "redux/_admin/_billing/billing.actions";
import { setPaymentData } from "redux/_account/account.actions";

export const updatePaymentMethod = (data) => (dispatch) => {
  dispatch(requestStart());
  return associatePaymentMethod(data)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(setPaymentData(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const removePaymentMethod = () => (dispatch) => {
  dispatch(requestStart());
  return detachPaymentMethod()
    .then((_) => {
      dispatch(requestEnd());
      dispatch(setPaymentData());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const updateBillingData = (data) => (dispatch) => {
  dispatch(requestStart());
  return update(data)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(setPaymentData(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

// export const getBillingData = () => dispatch => {
//   dispatch(requestStart());
//   return getAll()
//     .then(data => {
//       dispatch(saveData(data));
//       dispatch(requestEnd());
//       return Promise.resolve();
//     })
//     .catch(error => {
//       dispatch(requestEnd());
//       return Promise.reject();
//     });
// };

// export const updateCompany = data => dispatch => {
//   dispatch(requestStart());
//   return update(data)
//     .then(data => {
//       dispatch(saveData(data));
//       dispatch(requestEnd());
//       return Promise.resolve(data);
//     })
//     .catch(_ => {
//       dispatch(requestEnd());
//       return Promise.reject();
//     });
// };
