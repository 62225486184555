import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  overlayContainer: {
    height: "initial",
    width: "100%",
    position: "relative",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.65)",
    zIndex: 1000,
    border: "dashed 2px #c2c4c9",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    maxWidth: "80%",
    boxShadow: "0px 8px 16px rgba(26, 53, 134, 0.5)",
    borderRadius: 8,
  },
  text: {
    textShadow: "1px 1px 0 var(--white)",
    fontSize: 18,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "normal",
    color: theme.palette.blue[800],
  },
  anchor: {
    textShadow: "1px 1px 0 var(--white)",
    fontSize: 18,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "normal",
    color: theme.palette.blue["young"],
    textDecoration: "underline",
    border: "none",
  },
}));
