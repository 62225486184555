import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getAll = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/company/users`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data: active = [] }) => {
      return fetch(
        `${config.apiUrl}/company/users/invitations/`,
        requestOptions
      ).then((response) => {
        return handleFetchResponse(
          response
        ).then(({ data: invitations = [] }) => [...active, ...invitations]);
      });
    });
};

export const create = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/company/users/invitations/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const get = (key) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/company/users/invitations/${key}/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const resend = (key) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/company/users/invitations/${key}/resend/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const remove = (key) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/company/users/invitations/${key}/`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const reportSite = ({
  captcha,
  reportUrl: report_url,
  siteUrl: site_url,
}) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      captcha,
      report_url,
      site_url,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/report_site/`;

  return fetch(url, requestOptions);
};
