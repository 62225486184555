import React from "react";
import { Box, Button, TextField, Typography, makeStyles } from "@material-ui/core";
import useFormStyles from "../../../Scans/Forms/styles.js";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: 16,
    gap: 5,
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 1px 6.400000095367432px 1px #0000001A",
    borderRadius: 12,
  },
  description: {
    display: "flex",
    gap: 16,
  },
  icon: {
    width: 50,
    minWidth: 50,
    height: 50,
    borderRadius: "100%",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  contentName: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  contentDescription: {
    color: "#868993",
  },
  button: {
    height: "min-content",
    padding: 0,
    backgroundColor: theme.palette.aquamarine[100],
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
  }
}));

const ProviderBox = ({ onAdd, provider, showButton = true, actionConfig = { hide: true, label: "", onClick: () => {} } }) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Box className={styles.description}>
        {provider.icon ? (
          <Box className={styles.icon}>
            <img src={provider.icon} />
          </Box>
        ) : (
          <Box className={styles.icon}style={{ backgroundColor: "aliceblue" }}></Box>
        )}
        <Box className={styles.content}>
          <Typography className={styles.contentName}>{provider.name}</Typography>
          <Typography>{provider.description}</Typography>
        </Box>
      </Box>
      {!actionConfig.hide && (
        <Button
          color="secondary"
          onClick={actionConfig.onClick}
          variant="contained"
          className={styles.button}
        >
          {actionConfig.label}
        </Button>
      )}
    </Box>
  );
};

export default ProviderBox;
