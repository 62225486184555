import CONSTANTS from "./charts.constants";

const initialState = {
  scans: {
    error: false,
    loading: false,
    treeMap: undefined,
    treeByCountry: undefined,
    customers: [],
    owners: undefined,
    lastScans: undefined,
    functionalUses: undefined,
    filters: {
      monthId: null,
      scanId: null,
      visibility: {
        hideNonProblematic: false,
        hideNonUnprotected: false,
      },
      country: null,
      piiGroups: [],
    },
  },
};

export const charts = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.CHARTS_SCANS_REQUEST_START:
      return {
        ...state,
        scans: {
          ...state.scans,
          error: false,
          loading: true,
        },
      };
    case CONSTANTS.CHARTS_SCANS_REQUEST_END:
      return {
        ...state,
        scans: {
          ...state.scans,
          loading: false,
        },
      };
    case CONSTANTS.CHARTS_SCANS_SET_ERROR:
      return {
        ...state,
        scans: {
          ...state.scans,
          error: true,
        },
      };
    case CONSTANTS.CHARTS_SCANS_TREEMAP_SET_DATA:
      return {
        ...state,
        scans: {
          ...state.scans,
          treeMap: data,
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_SET_MONTH:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            monthId: data,
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_TREE_BY_COUNTRY_SET_DATA:
      return {
        ...state,
        scans: {
          ...state.scans,
          treeByCountry: data,
        },
      };
    case CONSTANTS.CHARTS_SCANS_OWNERS_SET_DATA:
      return {
        ...state,
        scans: {
          ...state.scans,
          owners: data,
        },
      };
    case CONSTANTS.CHARTS_SCANS_CUSTOMERS_BY_COUNTRY_SET_DATA:
      return {
        ...state,
        scans: {
          ...state.scans,
          customers: data,
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_SET_SCAN:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            scanId: data,
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_SET_COUNTRY:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            country: data,
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_SET_PII_GROUPS:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            piiGroups: data,
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_PROBLEMATIC:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            visibility: {
              ...state.scans.filters.visibility,
              hideNonProblematic: !state.scans.filters.visibility
                .hideNonProblematic,
            },
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_UNPROTECTED:
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            visibility: {
              ...state.scans.filters.visibility,
              hideNonUnprotected: !state.scans.filters.visibility
                .hideNonUnprotected,
            },
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_FILTER_TOGGLE_PII_GROUP:
      const piiGroups = [...state.scans.filters.piiGroups];
      piiGroups[data] = {
        ...state.scans.filters.piiGroups[data],
        value: !state.scans.filters.piiGroups[data].value,
      };
      return {
        ...state,
        scans: {
          ...state.scans,
          filters: {
            ...state.scans.filters,
            piiGroups,
          },
        },
      };
    case CONSTANTS.CHARTS_SCANS_TRENDS_SET_LAST:
      return {
        ...state,
        scans: {
          ...state.scans,
          lastScans: data,
        },
      };
    case CONSTANTS.CHARTS_SCANS_FUNCIONAL_USES:
      return {
        ...state,
        scans: {
          ...state.scans,
          functionalUses: data,
        },
      };
    default:
      return state;
  }
};
