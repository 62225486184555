export const SECTION_ID = {
  landing: "landing",
  findings: "findings",
  scanCoverageInformation: "scanCoverageInformation",
  insecureCookieSetting: "insecureCookieSetting",
  missingSecurityFlag: "missingSecurityFlag",
};

export const SECTION_TITLE = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.findings]: "Findings",
  [SECTION_ID.scanCoverageInformation]: "ScanCoverageInformation",
  [SECTION_ID.insecureCookieSetting]: "InsecureCookieSetting",
  [SECTION_ID.missingSecurityFlag]: "MissingSecurityFlag",
};

export const SECTION_NAME = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.findings]: "Findings",
  [SECTION_ID.scanCoverageInformation]: "ScanCoverageInformation",
  [SECTION_ID.insecureCookieSetting]: "InsecureCookieSetting",
  [SECTION_ID.missingSecurityFlag]: "MissingSecurityFlag",
};

export const SECTIONS_CONTENT = {
  [SECTION_ID.landing]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.landing,
    title: "",
    description: "",
  },
  [SECTION_ID.insecureCookieSetting]: {
    backgroundColor: "#FFFFFF",
    key: SECTION_NAME.insecureCookieSetting,
    title: "Findings",
    subtitle: "Insecure cookie setting: missing HttpOnly flag",
    description: "",
  },
  [SECTION_ID.findings]: {
    backgroundColor: "#FFFFFF",
    key: SECTION_NAME.findings,
    title: "Findings",
    description: "",
  },
  [SECTION_ID.scanCoverageInformation]: {
    backgroundColor: "#FFFFFF",
    key: SECTION_NAME.scanCoverageInformation,
    title: "Scan coverage information",
    description: "",
  },
};
