import React from "react";
import clsx from "clsx";
import Hidden from "@material-ui/core/Hidden";
// import inviterIllus from 'assets/illustrations/illus_find.svg';
import HomepageMenu from "components/HomepageMenu";
import Footer from "components/PublicComponents/Footer";
import Inviter from "components/PublicComponents/Inviter";
import PublicNav from "components/PublicComponents/Nav";
import Seo, { pages } from "components/Seo";

import useStyles from "./styles";

function CookieConsent() {
  const classes = useStyles();
  return (
    <>
      <Seo {...pages.faqs} />
      <div id="outer-container">
        <Hidden mdUp>
          <HomepageMenu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
        </Hidden>
        <div id="page-wrap" className="homepage">
          <PublicNav />
          <section className={classes.postMasthead}>
            <div className="container">
              <h2 className={classes.postMastheadH2}>
                How to add cookies with the user{"'"}s consent
              </h2>
            </div>
          </section>

          <section className={classes.storyNarrativeContainer}>
            <div className={clsx(classes.story1, classes.storyNarrative)}>
              <div className="container">
                <p>
                  Implement a cookie banner informing website visitor that you
                  use cookies, why, how and where their data is used in an
                  easy-to-understand way.
                </p>
                <p>
                  <ul>
                    <li>
                      Give them an opportunity to opt-in and opt-out of any type
                      of cookie.
                    </li>
                    <li>
                      Get their consent (usually in the form of a cookie banner)
                      before collecting the data.
                    </li>
                    <li>
                      Consent needs to be:
                      <ul>
                        <li>Affirmative and Unambiguous.</li>
                        <li>In an understandable language.</li>
                        <li>For a specific purpose</li>
                        <li>
                          Separation between consent for first party vs. partner
                          use.
                        </li>
                        <li>Freely given and easy to withdraw.</li>
                      </ul>
                    </li>
                    <li>Demonstrate Value of Participation.</li>
                    <li>Keep the records of all the collected consents.</li>
                    <li>
                      Allow visitors to withdraw their data that they have given
                      consent to be collected.
                    </li>
                    <li>Delete visitors’ data upon their request</li>
                  </ul>
                </p>
                <p>
                  Need more, we are help to help with design best practices,
                  reduce compliance risk and increase people’s willingness to
                  actually share their data. Please reach out:{" "}
                  <a href="mailto:team@zendata.dev">team@zendata.dev</a>
                </p>
              </div>
            </div>
          </section>

          {/* <section className={classes.inviter}>
            <div className="container">
              <div className="row no-gutters align-items-center">
                <div className="col-md-6 order-md-6">
                  <h4>It’s important to understand what sensitive data your company holds and it's associated risk. <br /><strong>Start discovering now.</strong></h4>
                  <form className={classes.mastheadForm}>
                    <fieldset>
                      <input
                        className={classes.mastheadFormInput}
                        type="text"
                        placeholder="Enter your email to start"
                      />
                      <input
                        className={classes.mastheadFormSubmit}
                        type="submit"
                        value="Request a Demo"
                      />
                    </fieldset>
                    <p>No credit card required.</p>
                  </form>
                </div>
                <div className="col-md-4 order-md-4 offset-md-1">
                  <img src={inviterIllus} alt=" " role="presentation" />
                </div>
              </div>
            </div>
          </section> */}

          <Inviter />

          <Footer />
        </div>
      </div>
    </>
  );
}

export default CookieConsent;
