import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { postTriggerReport } from "redux/_reports/reports.async.actions";
import { Form } from "react-final-form";
import { getAccountData } from "redux/_account/account.selectors";
import { useSnackbar } from "notistack";
import { sendScan } from "components/PrivacyReports/Scanner";
import useFormStyles from "../../Scans/Forms/styles.js";
import CustomButton from "components/Button";
import useCustomStyles from "./styles.js";
import Providers from "./Providers/index.jsx";
import ProviderForm from "./ProviderForm/index.jsx";
import { createDataSourceScan } from "redux/_datasources/datasources.service.js";

const CreateDataSourceScanner = ({
  account,
  triggerSingleRunScan,
  setSelectedTab,
}) => {
  const [loading, setLoading] = useState(false);
  const customClasses = useCustomStyles();
  const formStyles = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedProvider, setSelectedProvider] = useState(null);

  const preSubmit = async (values) => {
    const { formSchema, ...restProvider } = selectedProvider;
    const payload = {
      auth: { ...values },
      provider: restProvider.id
    };
    setLoading(true);

    try {
      await sendScan(createDataSourceScan)(payload);
      setLoading(false);
      enqueueSnackbar("Scan scheduled successfully", { variant: "success" });
      setSelectedTab(0);
      setSelectedProvider(null);
      debugger;
    } catch (error) {
      debugger;
      setLoading(false);
      enqueueSnackbar("An error has occurred", { variant: "error" });
    }
  };

  return (
    <Form onSubmit={preSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className={customClasses.container}>
          {!selectedProvider && (
            <Providers onSelectProvider={setSelectedProvider}/>
          )}
          {selectedProvider && (
            <>
              <ProviderForm provider={selectedProvider} onChange={setSelectedProvider} />
              <CustomButton type="submit" className={formStyles.submit}>
                Create scan
              </CustomButton>
            </>
          )}
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
});

const ConnectedCreateDataSourceScanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDataSourceScanner);
export default withRouter(ConnectedCreateDataSourceScanner);
