import { usePDF } from "@react-pdf/renderer";
import MobilePDFReport from "components/MobilePDFReport";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

const MobilePDFDownloadLink = ({ branding, children, data, icon, content }) => {
  const [rendered, setRendered] = useState(false);
  const [{ url, loading }, error] = usePDF({
    document: (
      <MobilePDFReport
        branding={branding}
        data={data}
        icon={icon}
        content={content}
      />
    ),
  });

  useEffect(() => {
    if (!rendered && !loading && url) {
      try {
        global.open(url);
        setRendered(true);
      } catch (error) {
        console.error("Error opening PDF:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onClick = () => {
    try {
      if (url) {
        global.open(url);
        setRendered(true);
      }
    } catch (error) {
      console.error("Error opening PDF:", error);
    }
  };

  return loading || !url ? (
    <Button className="pdf-button" color="primary" variant="contained">
      Opening...
    </Button>
  ) : (
    <span onClick={onClick}>{children}</span>
  );
};

export default MobilePDFDownloadLink;
