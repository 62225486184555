import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import isNil from "lodash.isnil";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 2,
    color: theme.palette.grey[200],
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: 1.38,
    color: theme.palette.grey[300],
    paddingBottom: "16px",
  },
}));

export default ({ title = null, subtitle = null }) => {
  const classes = useStyles();
  return (
    <Box>
      {!isNil(title) && (
        <Typography className={classes.title}>{title}</Typography>
      )}
      {!isNil(subtitle) && (
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      )}
    </Box>
  );
};
