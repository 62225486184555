import {
  Box,
  FormControl,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
  Avatar,
  Checkbox,
  FormGroup,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import clsx from "clsx";
import Button from "components/Button";
import { PageReportsWrapperContext } from "components/PageReportsWrapper";
import { useSnackbar } from "notistack";
import React, { useContext, useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import useStyles from "../styles";
import useFormStyles from "../styles";
import InfoIconButton from "components/InfoIconButton";
import { RRule } from "rrule";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SCAN_TYPES } from "components/Scans/Forms/Flow/constants";
import { createSecurityScans } from "redux/_scans/scans.service";
import { validateSite } from "utils/scanValidations";

const TITLE = "Security scanner monitoring";
const DESC = "Your report is being processed";

const ENDS = {
  never: "never",
  on: "on",
};

const RRuleExt = {
  NEVER: "never",
};

const appType = "security";

const addHttp = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
};

const setInitialWeekdayScheduleIfNeeded = (initialValues) => {
  if (!initialValues.byweekday || initialValues.byweekday.length === 0) {
    const today = new Date();
    switch (today.getDay()) {
      case 0:
        initialValues.byweekday = [RRule.SU.weekday];
        break;
      case 1:
        initialValues.byweekday = [RRule.MO.weekday];
        break;
      case 2:
        initialValues.byweekday = [RRule.TU.weekday];
        break;
      case 3:
        initialValues.byweekday = [RRule.WE.weekday];
        break;
      case 4:
        initialValues.byweekday = [RRule.TH.weekday];
        break;
      case 5:
        initialValues.byweekday = [RRule.FR.weekday];
        break;
      default:
        initialValues.byweekday = [RRule.SA.weekday];
        break;
    }
  }
};

const useCustomStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "20px 0",
  },
  rightContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  leftContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
  },
}));

const SecurityScansForm = ({ initialValues, onSubmit, goBack }) => {
  setInitialWeekdayScheduleIfNeeded(initialValues);
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [urlValue, setUrlValue] = useState("");
  const formClasses = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { moveToSection: moveToTab } = useContext(PageReportsWrapperContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [interval, setInterval] = useState(1);
  const [freq, setFreq] = useState(initialValues.freq || RRuleExt.NEVER);
  const [days, setDays] = useState({
    [RRule.SU]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.SU.weekday),
    [RRule.MO]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.MO.weekday),
    [RRule.TU]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.TU.weekday),
    [RRule.WE]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.WE.weekday),
    [RRule.TH]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.TH.weekday),
    [RRule.FR]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.FR.weekday),
    [RRule.SA]:
      initialValues.byweekday &&
      initialValues.byweekday.includes(RRule.SA.weekday),
  });

  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [ends, setEnds] = useState(
    (initialValues.until && ENDS.on) || ENDS.never
  );
  const [until, setUntil] = useState(initialValues.until);
  const [withAuth, setWithAuth] = useState("no");
  const [sitemapDepth, setSitemapDepth] = useState(0);
  const [dayError, setDayError] = React.useState(null);

  const handleRepeatInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRepeatInfoClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "login-info-popover" : undefined;

  useEffect(() => {
    const atLeastOneDaySelected = days
      ? Object.values(days).reduce((x, y) => x || y)
      : true;
    if (!atLeastOneDaySelected) {
      setDayError(true);
    } else setDayError(false);
  }, [days]);

  const handleInterval = (e) => {
    setInterval(e.target.value);
  };

  const handleFreq = (e) => {
    setFreq(e.target.value);
  };

  const handleDays = (e) => {
    setDays({ ...days, [e.target.name]: e.target.checked });
  };

  const handleDayOfMonth = (e) => {
    setDayOfMonth(e.target.value);
  };

  const handleEnds = (e) => {
    setEnds(e.target.value);
  };

  const handleUntil = (dateWrapper) => {
    setUntil(moment(dateWrapper._d).format("MMM D, YYYY"));
  };

  const preSubmit = (values) => {
    const { ...rest } = values;

    if (dayError) {
      return;
    }

    // Format authentication related values
    if (withAuth === "yes") {
      rest.resource = rest.url;
      rest.sitemapDepth = 0;
    } else {
      rest.sitemapDepth = sitemapDepth;
      rest.url = undefined;
      rest.user = undefined;
      rest.password = undefined;
    }

    if (freq === RRuleExt.NEVER) {
      createSecurityScans(addHttp(values.url)).then(() => {
        moveToTab(3);
      });
    } else {
      const parsedTimeValues = { interval, freq };
      if (freq === RRule.WEEKLY) {
        parsedTimeValues.byweekday = Object.keys(days)
          .filter((key) => days[key])
          .map((key) => RRule[key]);
      } else if (freq === RRule.MONTHLY) {
        parsedTimeValues.bymonthday = dayOfMonth;
      }
      if (ends !== ENDS.never) {
        parsedTimeValues.until = until;
      }
      const rule = new RRule(parsedTimeValues);

      onSubmit(SCAN_TYPES.schedule)({
        ...rest,
        locations: ["US"],
        recurrence: rule.toString(),
        resource: values.url,
        sitemap_depth: 0,
        environment: appType,
        id: initialValues.id,
        notifier: enqueueSnackbar,
      }).then(() => {
        moveToTab(2);
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Form onSubmit={preSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.root}>
              <Box className={classes.descriptionContainer}>
                <Box className={clsx(classes.description)}>
                  <Typography variant="h2">{TITLE}</Typography>
                  <Typography>{DESC}</Typography>
                </Box>
              </Box>

              <FormControl className={customClasses.fieldContainer}>
                <Field
                  name="url"
                  render={({
                    input: { urlValue, onChange, onBlur },
                    meta: { touched, error, submitError },
                  }) => (
                    <>
                      <div className={customClasses.leftContainer}>
                        <Typography
                          id="resource-label"
                          className={classes.label}
                        >
                          URL to Scan
                        </Typography>
                      </div>
                      <div className={customClasses.rightContainer}>
                        <TextField
                          inputProps={{
                            "aria-labelledby": "resource-label",
                          }}
                          value={urlValue}
                          onChange={(event) => {
                            const newValue = event.target.value;
                            setUrlValue(newValue);
                            onChange(newValue);
                          }}
                          className={classes.textField}
                          variant="filled"
                          placeholder="Type the URL here"
                          error={touched && (error || submitError)}
                          onBlur={(event) => onBlur(event)}
                          required
                          helperText={
                            touched &&
                            (error || submitError) && (
                              <span style={{ color: "red" }}>{error}</span>
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                  validate={validateSite}
                />
              </FormControl>
              <FormControl className={customClasses.fieldContainer}>
                <>
                  <div className={classes.leftContainer}>
                    <Typography className={classes.label}>
                      Repeats
                      <InfoIconButton onClick={handleRepeatInfoClick} />
                    </Typography>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleRepeatInfoClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      classes={{ paper: classes.popover }}
                    >
                      <Typography>
                        For one time scan select option "Never".
                      </Typography>
                    </Popover>
                  </div>
                  <div className={classes.rightContainer}>
                    {freq !== RRuleExt.NEVER && (
                      <>
                        <Typography
                          className={clsx(formClasses.boxSiblingText, "right")}
                        >
                          Every
                        </Typography>
                        <TextField
                          className={clsx(classes.textField, "mini")}
                          variant="filled"
                          value={interval || initialValues.interval}
                          onChange={handleInterval}
                        />
                      </>
                    )}
                    <Select
                      value={freq || initialValues.freq}
                      onChange={handleFreq}
                      variant="filled"
                      className={classes.selectField}
                    >
                      <MenuItem value={RRuleExt.NEVER}>
                        Never (scan only once)
                      </MenuItem>
                      <MenuItem value={RRule.DAILY}>Days</MenuItem>
                      <MenuItem value={RRule.WEEKLY}>Weeks</MenuItem>
                      <MenuItem value={RRule.MONTHLY}>Months</MenuItem>
                    </Select>
                  </div>
                </>
              </FormControl>
              {freq !== RRuleExt.NEVER && (
                <>
                  <FormControl
                    className={clsx(classes.fieldContainer, "-with-margin")}
                  >
                    <>
                      <div className={classes.leftContainer}>
                        <Typography className={classes.label}>
                          Repeat on
                        </Typography>
                      </div>
                      <div className={classes.rightContainer}>
                        {freq === RRule.DAILY && (
                          <Typography className={formClasses.boxSiblingText}>
                            Every {interval > 1 ? interval : ""} day
                            {interval > 1 ? "s" : ""}
                          </Typography>
                        )}
                        {freq === RRule.WEEKLY && (
                          <FormGroup className={classes.week}>
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.SU}
                              checked={days[RRule.SU]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>S</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  S
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.MO}
                              checked={days[RRule.MO]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>M</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  M
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.TU}
                              checked={days[RRule.TU]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>T</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  T
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.WE}
                              checked={days[RRule.WE]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>W</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  W
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.TH}
                              checked={days[RRule.TH]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>T</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  T
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.FR}
                              checked={days[RRule.FR]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>F</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  F
                                </Avatar>
                              }
                            />
                            <Checkbox
                              className={classes.smallCheck}
                              name={RRule.SA}
                              checked={days[RRule.SA]}
                              onChange={handleDays}
                              icon={<Avatar className={classes.day}>S</Avatar>}
                              checkedIcon={
                                <Avatar className={classes.selectedDay}>
                                  S
                                </Avatar>
                              }
                            />
                            {dayError && (
                              <p className={formClasses.error}>
                                Please select at least one day of the week
                              </p>
                            )}
                          </FormGroup>
                        )}
                        {freq === RRule.MONTHLY && (
                          <>
                            <Typography className={formClasses.boxSiblingText}>
                              Day
                            </Typography>
                            <Select
                              value={dayOfMonth || initialValues.dayOfMonth}
                              onChange={handleDayOfMonth}
                              variant="filled"
                              className={clsx(classes.selectField, "mini")}
                            >
                              {Array(31)
                                .fill({})
                                .map((_, index) => (
                                  <MenuItem
                                    key={`day-of-month-${index}`}
                                    value={index + 1}
                                  >
                                    {index + 1}
                                  </MenuItem>
                                ))}
                            </Select>
                            <Typography className={formClasses.boxSiblingText}>
                              of every {interval > 1 ? interval : ""} month
                              {interval > 1 ? "s" : ""}
                            </Typography>
                          </>
                        )}
                      </div>
                    </>
                  </FormControl>
                  <FormControl className={classes.fieldContainer}>
                    <>
                      <div className={classes.leftContainer}>
                        <Typography className={classes.label}>Ends</Typography>
                      </div>
                      <div className={classes.rightContainer}>
                        <RadioGroup value={ends} onChange={handleEnds}>
                          <FormControlLabel
                            value={ENDS.never}
                            control={<Radio color="primary" />}
                            label="Never"
                          />
                          <Box>
                            <FormControlLabel
                              value={ENDS.on}
                              control={<Radio color="primary" />}
                              label="On"
                            />
                            {ends === ENDS.on && (
                              <DatePicker
                                onChange={handleUntil}
                                format="MMM D, YYYY"
                                value={until}
                                minDate={new Date()}
                                inputVariant="filled"
                                InputProps={{
                                  classes: {
                                    input: formClasses.datePickerInput,
                                  },
                                }}
                              />
                            )}
                          </Box>
                        </RadioGroup>
                      </div>
                    </>
                  </FormControl>
                </>
              )}
              <Box>
                <Button
                  type="submit"
                  className={classes.submit}
                  disabled={!urlValue}
                >
                  Create scan
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
      <Button
        variant="secondary"
        className={classes.goBackButton}
        onClick={goBack}
      >
        Go Back
      </Button>
    </MuiPickersUtilsProvider>
  );
};

export default SecurityScansForm;
