import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  subTitle: {
    marginTop: 25,
    marginBottom: 0,
  },
  providerColumn: {
    minWidth: "200px",
  },
  borderRight: {
    borderRight: "1px solid #dfe2e9",
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    minWidth: "200px",
    padding: 0,
    flexDirection: "column !important",
  },
  cell: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cellValue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
    width: "100%",
  },
  table: {
    width: "100%",
    display: "flex",
    marginTop: 5,
  },
  fontSizeEight: {
    fontSize: 8,
  },
});

export default styles;
