import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  scoreChart: {
    position: "relative",
    "&.hidden": {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: theme.spacing(16),
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(14),
    },
  },
  value: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: theme.typography.fontFamily,
    fontSize: "200%",
    color: "#fff",
    fontWeight: 700,
  },
}));
