import { Text, View } from "@react-pdf/renderer";
import useStyles, { pdfStyles as styles } from "./styles";

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

const filter = (data) => {
  const filteredData = {};
  Object.keys(data).forEach((pageUrl) => {
    let subPages = {};
    let add = false;
    Object.keys(data[pageUrl]).forEach((loadedLibrary) => {
      if (
        data[pageUrl][loadedLibrary].pIIs.length +
          data[pageUrl][loadedLibrary].sensitivePIIs.length >
        0
      ) {
        subPages[loadedLibrary] = {
          pIIs: data[pageUrl][loadedLibrary].pIIs,
          sensitivePIIs: data[pageUrl][loadedLibrary].sensitivePIIs,
        };
        add = true;
      }
    });
    if (add) filteredData[pageUrl] = subPages;
  });

  return filteredData;
};

const PIITable = ({ data }) => {
  const classes = useStyles();
  const filteredData = filter(data);
  return (
    <div>
      {Object.keys(filteredData).map((pageUrl) => (
        <>
          <div key={pageUrl} className={classes.level1}>
            {pageUrl}
          </div>
          {Object.keys(filteredData[pageUrl]).map((loadedLibrary) => (
            <div key={pageUrl + loadedLibrary} className={classes.piiList}>
              <div className={classes.level2}>{loadedLibrary}</div>
              {filteredData[pageUrl][loadedLibrary].sensitivePIIs.map((pii) => (
                <div
                  key={pageUrl + loadedLibrary + pii}
                  className={classes.level3}
                >
                  {titleCase(pii)}
                </div>
              ))}
              {filteredData[pageUrl][loadedLibrary].pIIs.map((pii) => (
                <div
                  key={pageUrl + loadedLibrary + pii}
                  className={classes.level3}
                >
                  {titleCase(pii)}
                </div>
              ))}
            </div>
          ))}
        </>
      ))}
    </div>
  );
};

export const PIITablePage = ({ data, styles: globalStyles }) => {
  const filteredData = filter(data);

  return (
    <>
      <View style={globalStyles.thead} fixed wrap={false}>
        <View style={globalStyles.tr}>
          <View style={globalStyles.th}>
            <Text>Web Page</Text>
          </View>
          <View style={globalStyles.th}>
            <Text>Tag</Text>
          </View>
          <View style={globalStyles.th}>
            <Text>Data Access</Text>
          </View>
        </View>
      </View>
      <View style={globalStyles.tbody}>
        {Object.keys(filteredData).map((pageUrl, i) => ( 
          <View style={[globalStyles.tr, { borderRight: "none" }]} key={pageUrl + i}>
            <View key={pageUrl} style={[globalStyles.td, styles.pageUrl]}>
              <Text>{pageUrl}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "column", width: 355 }}>
              {Object.keys(filteredData[pageUrl]).map((loadedLibrary, j) => (
                <View
                  wrap={false}
                  style={[globalStyles.tr, { display: "flex", flexDirection: "row" }]}
                  key={`${pageUrl}${loadedLibrary}-line`}>
                  <View style={[globalStyles.td, styles.tagCell]}>
                    <Text
                      style={styles.loadedLibrary}
                    >
                      {loadedLibrary}
                    </Text>
                  </View>
                  <View style={[globalStyles.td, { display: "inline" }]} key={`${pageUrl}${loadedLibrary}-sensitivePIIs`}>
                    {filteredData[pageUrl][loadedLibrary].sensitivePIIs.map((pii, idx) => (
                      <Text key={pageUrl + loadedLibrary + pii}>
                        {titleCase(pii)}
                        {idx < filteredData[pageUrl][loadedLibrary].sensitivePIIs.length -1
                          || filteredData[pageUrl][loadedLibrary].pIIs.length
                            ? "; "
                            : "" }
                      </Text>
                    ))}
                    {filteredData[pageUrl][loadedLibrary].pIIs.map((pii, idx) => (
                      <Text key={pageUrl + loadedLibrary + pii}>
                        {titleCase(pii)}
                        {idx < filteredData[pageUrl][loadedLibrary].pIIs.length -1 ? "; " : ""}
                      </Text>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
export const PIITreePage = ({ data }) => {
  const filteredData = filter(data);

  return Object.keys(filteredData).map((pageUrl) => (
    <>
      <View key={pageUrl} style={styles.level1}>
        <Text>{pageUrl}</Text>
      </View>
      {Object.keys(filteredData[pageUrl]).map((loadedLibrary) => (
        <View key={pageUrl + loadedLibrary} style={styles.piiList}>
          <View style={styles.level2}>
            <Text>{loadedLibrary}</Text>
          </View>
          {filteredData[pageUrl][loadedLibrary].sensitivePIIs.map((pii) => (
            <View key={pageUrl + loadedLibrary + pii} style={styles.level3}>
              <Text>{titleCase(pii)}</Text>
            </View>
          ))}
          {filteredData[pageUrl][loadedLibrary].pIIs.map((pii) => (
            <View key={pageUrl + loadedLibrary + pii} style={styles.level3}>
              <Text>{titleCase(pii)}</Text>
            </View>
          ))}
        </View>
      ))}
    </>
  ));
};

export default PIITable;
