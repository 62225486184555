/**get the formated date like 05/20/2024 at 03:45 PM or null  */
const getFormatedDateWithYearAndTime = (dateCreated) => {
  if (!dateCreated) {
    return null;
  }

  const date = new Date(dateCreated);
  const formattedDate = date
    .toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(",", " at");

  return formattedDate;
};

export default { getFormatedDateWithYearAndTime };
