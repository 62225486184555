import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getData = (reportId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const r = reportId ? `/${reportId}` : "";
  const url = `${config.apiUrl}/consents_collector/config${r}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const updateData = (configData, reportId) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(configData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const r = reportId ? `/${reportId}` : "";
  const url = `${config.apiUrl}/consents_collector/config${r}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

async function readResponseStream(response) {
  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let result = "";

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value);
    result += chunk;
  }

  return result;
}

export const getCollectorLogsData = (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
    },
  };
  const url = `${config.apiUrl}/consents_collector/logs/${token}`;
  return fetch(url, requestOptions)
    .then((response) => readResponseStream(response))
    .then((content) => {
      const blob = new Blob([content], { type: "text/csv" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
      URL.revokeObjectURL(blobUrl);
    });
};

export const uploadFile = (data, reportId) => {
  const requestOptions = {
    method: "PATCH",
    body: data,
    headers: {
      ...authHeader(),
    },
  };

  const r = reportId ? `/${reportId}` : "";
  const url = `${config.apiUrl}/consents_collector/config/upload${r}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
