import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    position: "relative",
    zIndex: 0,
    height: "calc(100% - 100px)",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "40px 0px 17px",
    boxSizing: "border-box",
    gap: "48px",
    maxWidth: "calc(100% - 125px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "24px",
      paddingTop: "26px",
      paddingBottom: "20px",
    },
  },
  gridContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    lineHeight: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      boxSizing: "border-box",
    },
  },
  repositoryHeaderParent: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "flex-start",
    gap: "24px",
    textAlign: "left",
    flexDirection: "column",
    justifyContent: "flex-start",
    maxWidth: "100%",
    fontSize: "24px",
    color: "#1c3687",
    fontFamily: "Inter",
  },
  skeleton: {
    margin: 0,
    borderRadius: "28px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    fontSize: "36px",
    color: "#1b3586",
    fontFamily: "Aldrich",
  },
  card: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
    minWidth: "160px",
    width: "238px",
    marginRight: "20px",
  },
  cardElementsCardContent: {
    alignSelf: "stretch",
    padding: "16px",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  blogPostsPlaceholderImage: {
    alignSelf: "stretch",
    height: "168px",
    overflow: "hidden",
    flexShrink: 0,
    display: "none",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  placeholderImage: {
    alignSelf: "stretch",
    height: "200px",
    position: "relative",
    backgroundColor: "rgba(0, 0, 0, 0.97)",
  },
  photoOutlined: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 31.5px)",
    width: "64px",
    height: "64px",
    overflow: "hidden",
  },
  a: {
    textDecoration: "none",
    position: "relative",
    color: "inherit",
    display: "inline-block",
    minWidth: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  repositories: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: "16px",
    lineHeight: "150%",
    fontFamily: "Inter",
    color: "#000",
    textAlign: "center",
  },
  highSeverityIssues: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: "16px",
    lineHeight: "150%",
    fontFamily: "Inter",
    color: "#000",
    textAlign: "center",
  },
  sharingPiiWith: {
    ...theme.typography.body1,
    alignSelf: "stretch",
    position: "relative",
    fontSize: "16px",
    lineHeight: "150%",
    fontFamily: "Inter",
    color: "#000",
    textAlign: "center",
  },
  correctPiiHandling: {
    alignSelf: "stretch",
    position: "relative",
    fontSize: "16px",
    lineHeight: "150%",
    fontFamily: "Inter",
    color: "#000",
    textAlign: "center",
  },
  myRepositories: {
    color: "#1B3586",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  filterOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  filterLabel: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    height: "48px",
    width: "128px",
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#000",
  },
  avatar3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    borderRadius: "64px",
    backgroundColor: "#bdbdbd",
    overflow: "hidden",
    flexDirection: "column",
    zIndex: 0,
  },
  op: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    height: "24px",
    position: "relative",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "166%",
    fontFamily: "Roboto",
    color: "#fff",
    textAlign: "center",
  },
  badge1: {
    position: "absolute",
    height: "50%",
    width: "50%",
    top: "25%",
    right: "25%",
    bottom: "25%",
    left: "25%",
    borderRadius: "8px",
    backgroundColor: "#2e7d32",
    overflow: "hidden",
  },
  border1: {
    width: "8px",
    height: "8px",
    position: "absolute",
    margin: "0 !important",
    right: "-2px",
    bottom: "-2px",
    borderRadius: "64px",
    backgroundColor: "#fff",
    display: "none",
    zIndex: 1,
  },
  avatar2: {
    height: "18px",
    backgroundColor: "transparent",
    overflow: "hidden",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chip: {
    alignSelf: "stretch",
    fontSize: "13px",
    letterSpacing: "0.16px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
    display: "inline-block",
  },
  typography: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 6px",
  },
  cancelfilledIcon2: {
    height: "16px",
    width: "16px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
  },
  chipfilled: {
    borderRadius: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  container1: {
    display: "flex",
    justifyContent: "flex-start",
    width: "63px",
    borderRadius: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    flexDirection: "row",
    alignItems: "center",
    padding: "3px 4px",
    boxSizing: "border-box",
  },
  avatar5: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    borderRadius: "64px",
    backgroundColor: "#bdbdbd",
    overflow: "hidden",
    flexDirection: "column",
    zIndex: 0,
  },
  op1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    height: "24px",
    position: "relative",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "166%",
    fontFamily: "Roboto",
    color: "#fff",
    textAlign: "center",
  },
  badge2: {
    position: "absolute",
    height: "50%",
    width: "50%",
    top: "25%",
    right: "25%",
    bottom: "25%",
    left: "25%",
    borderRadius: "8px",
    backgroundColor: "#2e7d32",
    overflow: "hidden",
  },
  border2: {
    width: "8px",
    height: "8px",
    position: "absolute",
    margin: "0 !important",
    right: "-2px",
    bottom: "-2px",
    borderRadius: "64px",
    backgroundColor: "#fff",
    display: "none",
    zIndex: 1,
  },
  avatar4: {
    height: "18px",
    backgroundColor: "transparent",
    overflow: "hidden",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chip1: {
    alignSelf: "stretch",
    fontSize: "13px",
    letterSpacing: "0.16px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
    display: "inline-block",
  },
  typography1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 6px",
  },
  cancelfilledIcon3: {
    height: "16px",
    width: "16px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
  },
  container2: {
    width: "63px",
    borderRadius: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 4px",
    boxSizing: "border-box",
  },
  autocompletetag: {
    height: "32px",
    display: "none",
    flexDirection: "row",
    padding: "0 16px 0 0",
    boxSizing: "border-box",
    gap: "8px",
  },
  chipfilled1: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderRadius: "100px",
    display: "flex",
    flexDirection: "column",
  },
  removeredeyefilledIcon: {
    height: "24px",
    width: "24px",
    position: "relative",
    overflow: "hidden",
    flexShrink: 0,
  },
  adornStartContainer: {
    height: "1px",
    width: "24px",
    display: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  adornmentstart: {
    display: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  pii: {
    position: "relative",
    fontSize: "16px",
    textAlign: "left",
    lineHeight: "150%",
    fontFamily: "Inter",
    color: "#000",
  },
  placeholder: {
    position: "relative",
    fontSize: "16px",
    textAlign: "left",
    alignSelf: "stretch",
    width: "94px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.38)",
    display: "none",
  },
  arrowdropupfilledIcon: {
    height: "24px",
    width: "24px",
    position: "relative",
    overflow: "hidden",
    flexShrink: 0,
    objectFit: "contain",
    minHeight: "24px",
  },
  container: {
    alignSelf: "stretch",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  typography2: {
    fontSize: "13px",
    textAlign: "left",
    letterSpacing: "0.16px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    alignSelf: "stretch",
    display: "inline-block",
  },
  container3: {
    width: "63px",
    borderRadius: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 4px",
    boxSizing: "border-box",
  },
  removecirclefilledIcon: {
    height: "16px",
    width: "16px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
  },
  cancelfilledIcon: {
    height: "16px",
    width: "16px",
    position: "relative",
    overflow: "hidden",
    flexShrink: 0,
    borderRadius: "48px",
  },
  chipoutlined: {
    borderRadius: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  container4: {
    borderRadius: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 4px",
    boxSizing: "border-box",
  },
  avatar6: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    borderRadius: "64px",
    backgroundColor: "#bdbdbd",
    overflow: "hidden",
    flexDirection: "column",
    zIndex: 0,
  },
  op2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    height: "24px",
    position: "relative",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "166%",
    fontFamily: "Roboto",
    color: "#fff",
    textAlign: "center",
  },
  badge3: {
    position: "absolute",
    height: "50%",
    width: "50%",
    top: "25%",
    right: "25%",
    bottom: "25%",
    left: "25%",
    borderRadius: "8px",
    backgroundColor: "#2e7d32",
    overflow: "hidden",
  },
  border3: {
    width: "8px",
    height: "8px",
    position: "absolute",
    margin: "0 !important",
    right: "-2px",
    bottom: "-2px",
    borderRadius: "64px",
    backgroundColor: "#fff",
    display: "none",
    zIndex: 1,
  },
  avatar7: {
    height: "18px",
    backgroundColor: "transparent",
    overflow: "hidden",
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chip2: {
    alignSelf: "stretch",
    fontSize: "13px",
    letterSpacing: "0.16px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
    display: "inline-block",
  },
  typography3: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 6px",
  },
  container5: {
    display: "flex",
    justifyContent: "flex-start",
    width: "63px",
    borderRadius: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    flexDirection: "row",
    alignItems: "center",
    padding: "3px 4px",
    boxSizing: "border-box",
  },
  chip3: {
    alignSelf: "stretch",
    fontSize: "13px",
    letterSpacing: "0.16px",
    lineHeight: "18px",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
    display: "inline-block",
  },
  typography4: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 6px",
  },
  cancelfilledIcon1: {
    height: "16px",
    width: "16px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
  },
  column: {
    width: "calc(50% - 10px)",
    marginBottom: "20px",
  },
  gridContainerCard: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button4: {
    textTransform: "none",
    color: "#1B3586",
    fontSize: "16px",
    background: "#6BD4CA",
    borderRadius: "4px",
    "&:hover": { background: "#6BD4CA" },
    width: 224,
    height: 48,
  },
  icon1: {
    height: "14px",
    width: "20px",
    position: "relative",
    overflow: "hidden",
    flexShrink: 0,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "3px 0",
  },
  githubRepository: {
    position: "relative",
    display: "inline-block",
    minWidth: "103px",
  },
  cancelfilledIcon: {
    height: "24px",
    width: "24px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
    display: "none",
  },
  pill: {
    borderRadius: "16px",
    backgroundColor: "#f7f7f7",
    border: "1px solid #d6d6d6",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "2px 9px",
    gap: "8px",
  },
  clockclockwiseIcon: {
    height: "20px",
    width: "20px",
    position: "relative",
  },
  runWeekly: {
    position: "relative",
    display: "inline-block",
    minWidth: "66px",
  },
  cancelfilledIcon1: {
    height: "24px",
    width: "24px",
    position: "relative",
    borderRadius: "48px",
    overflow: "hidden",
    flexShrink: 0,
    display: "none",
  },
  pill1: {
    borderRadius: "16px",
    backgroundColor: "#f7f7f7",
    border: "1px solid #d6d6d6",
    padding: "2px 9px",
    gap: "8px",
  },
  cardElementsCardContentInner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pillParent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "16px",
    marginBottom: "24px",
  },
  cardElementsCardContentInnerStretch: {
    alignSelf: "stretch",
  },
  nemoBackend: {
    position: "relative",
    fontWeight: 700,
  },
  arrowOutward: {
    margin: 0,
    height: "24px",
    width: "24px",
    position: "relative",
    minHeight: "24px",
  },
  nemoBackendParent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 287px 0 0",
    gap: "8px",
    fontSize: "20px",
    color: "#466de8",
    marginBottom: "24px",
  },
  lastScanOn: {
    alignSelf: "stretch",
    position: "relative",
    lineHeight: "150%",
    whiteSpace: "nowrap",
  },
  leakage: {
    position: "relative",
    display: "inline-block",
    minWidth: "74px",
  },
  lastScanOnParent: {
    alignSelf: "stretch",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "8px",
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.7)",
    marginBottom: "24px",
  },
  low23Parent: {
    display: "flex",
    flexDirection: "row",
    padding: "0 148px 0 0",
    gap: "16px",
    fontSize: "14px",
  },
  contentCopy: {
    margin: 0,
    height: "24px",
    width: "24px",
    position: "relative",
  },
  maskedIcon: {
    height: "20px",
    width: "16px",
    display: "none",
  },
  button: {
    position: "relative",
  },
  maskedIcon1: {
    height: "20px",
    width: "16px",
    display: "none",
  },
  base: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px 8px",
    gap: "8px",
  },
  buttonText: {
    borderRadius: "4px",
  },
  buttonTextWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignSelf: "stretch",
    alignItems: "flex-end",
    fontSize: "14px",
    color: "#1b3586",
  },
  cardElementsCardContent4: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#f7f7f7",
    alignItems: "flex-start",
    padding: "16px",
    boxSizing: "border-box",
    gap: "24px",
    maxWidth: "100%",
    textAlign: "left",
    fontSize: "12px",
    color: "#000",
    fontFamily: "Inter",
  },
  [`@media screen and (max-width: 675px)`]: {
    low23Parent: {
      paddingRight: "74px",
      boxSizing: "border-box",
    },
    nemoBackendParent: {
      paddingRight: "143px",
      boxSizing: "border-box",
    },
  },
  [`@media screen and (max-width: 450px)`]: {
    nemoBackend: {
      fontSize: "16px",
    },
    low23Parent: {
      flexWrap: "wrap",
    },
  },
  copyContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    borderRadius: "4px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  copyText: {
    marginLeft: "8px",
    fontSize: "14px",
    color: "#1b3586",
    fontWeight: "bold",
  },
  smallIcon: {
    fontSize: "20px",
  },
  clickableContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
    borderRadius: "4px",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  clickableText: {
    marginRight: "8px",
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

export default useStyles;
