import React, { Fragment } from "react";
import NodeElement from "./NodeElement";

const Level = ({
  root,
  children,
  parent,
  onClick,
  styles,
  tooltipFn,
  tooltip,
  parentRadius,
}) => {
  const parentNode = root || parent;
  const levelStyles = parentNode.children
    ? styles(parentNode.children, parentRadius)
    : undefined;
  return (
    <>
      {root && (
        <NodeElement
          node={root}
          styles={levelStyles(root)}
          tooltipFn={tooltipFn}
          tooltip={tooltip}
        />
      )}

      {parentNode.children &&
        parentNode.children.map((element, i) => {
          const elemStyles = levelStyles(element);
          return (
            <Fragment key={i}>
              <NodeElement
                node={element}
                onClick={onClick}
                styles={elemStyles}
                tooltipFn={tooltipFn}
                parentRadius={parentRadius}
                tooltip={tooltip}
                textInside
              />
              {children &&
                element.children &&
                React.Children.map(children, (child) =>
                  React.cloneElement(child, {
                    parent: element,
                    tooltip,
                    styles,
                    tooltipFn,
                    parentRadius: elemStyles.radius,
                  })
                )}
            </Fragment>
          );
        })}
    </>
  );
};

export default Level;
