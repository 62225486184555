import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "block",
    marginBottom: 0,
  },
  inputLabel: {
    color: theme.palette.grey[800],
    display: "block",
    fontWeight: 700,
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    position: "relative",
    width: "100%",
    margin: 0,
    "&>div": {
      margin: "0",
      width: "100%",
    },
  },
  helperText: {
    lineHeight: "22px",
    fontSize: "12px",
    fontWeight: 500,
    marginTop: 3,
    marginBottom: 0,
    color: ({ error }) =>
      error ? theme.palette.red[500] : theme.palette.grey[800],
  },
  textField: {
    background: "#fff",
    borderRadius: 8,
    minHeight: 48,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&>div": {
      minHeight: 48,
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.grey[200],
      margin: "0.5rem 0 0",
    },
    "& input": {
      color: theme.palette.grey[800],
      padding: "0 1rem",
    },
    "& fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.grey.light}`,
      color: theme.palette.grey[800],
      minHeight: 48,
    },
    "& textarea": {
      color: theme.palette.grey[800],
    },
    "& fieldset:hover": {
      border: `solid 1px ${theme.palette.blue[600]}`,
    },
    "&::placeholder": {
      fontStyle: "italic",
    },
    "&::-webkit-placeholder": {
      fontStyle: "italic",
    },
  },
  addornEnd: {
    padding: 0,
  },
  addornIcon: {
    color: theme.palette.blue[300],
    fontSize: 16,
    background: theme.palette.common.white,
  },
  addornment: {
    position: "absolute",
    right: 0,
  },
}));
