import React from "react";
import { Route, Switch } from "react-router-dom";

import Report from "components/PrivacyReports/Report";
import CookieConsent from "components/PrivacyReports/Info/CookieConsent";
import PDFExample from "components/PDFReport/example";

const PrivacyReportPage = () => {
  return (
    <Switch>
      <Route path="/privacy-report/ohevrddly/pdf" component={PDFExample} />
      <Route path="/privacy-report/consent" component={CookieConsent} />
      <Route path="/privacy-report" component={Report} />
    </Switch>
  );
};

export default PrivacyReportPage;
