import React, { useState, useEffect } from "react";
import { Box, Collapse, Button } from "@material-ui/core";
import Checkbox from "components/Inputs/Checkbox";
import useStyles from "./styles";
import { getRequestsFilterSelected } from "redux/_requests/_data/data.selectors";
import { CONSTANTS } from "redux/_requests/_data/data.constants";
import { selectFilter } from "redux/_requests/_data/data.actions";
import { connect } from "react-redux";
import SVGIcon from "components/svg-icon/svg-icon";
import { getIndividualRights } from "redux/_requests/_data/data.async.actions";

const ReqIncludes = ({
  includes,
  setIncludes,
  getIndividualRightsData,
  regulation,
}) => {
  const classes = useStyles();
  // const [, , , , ...hiddenElements] = includes;
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    getIndividualRightsData();
    // eslint-disable-next-line
  }, [regulation]);

  const onCheckboxClicked = (key) =>
    setIncludes(
      includes.map((element) =>
        element.value === key
          ? { ...element, checked: !element.checked }
          : element
      )
    );

  const renderCheckBox = ({ value, label, checked }) => (
    <Checkbox
      text={label}
      key={value}
      value={checked}
      onChange={(_) => onCheckboxClicked(value)}
    />
  );

  return (
    <Box width="100%" mx="auto">
      <label className={classes.inputLabel}>Req. includes:</label>
      {includes.slice(0, 4).map(renderCheckBox)}
      {includes && includes.length > 4 && (
        <>
          <Collapse in={showMore} timeout="auto" unmountOnExit>
            {includes.slice(4).map(renderCheckBox)}
          </Collapse>
          <Button
            className={classes.reqButton}
            onClick={(_) => setShowMore(!showMore)}
            variant="text"
            style={{ width: "100%" }}
          >
            {showMore ? (
              <>
                Show less
                <SVGIcon icon="chevronDownIcon" turn />
              </>
            ) : (
              <>
                Show all
                <SVGIcon icon="chevronDownIcon" />
              </>
            )}
          </Button>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  includes: getRequestsFilterSelected(
    CONSTANTS.REQUESTS_FILTERS_TYPES.INCLUDES
  )(state),
  regulation: getRequestsFilterSelected(
    CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION
  )(state),
});

const mapDispatchToProps = (dispatch) => ({
  getIndividualRightsData: () => dispatch(getIndividualRights()),
  setIncludes: (value) =>
    dispatch(selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.INCLUDES, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReqIncludes);
