import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import moment from "moment";
import isEmpty from "lodash.isempty";
import CONSTANTS from "redux/_filters/filters.constants";
import qs from "utils/queryString";

const { TYPES } = CONSTANTS;

export const getTreeMapData = (filters, data) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/${filters[TYPES.SCAN]}/recordtree?`;

  const filtersToAdd = [];

  if (filters[TYPES.ENTITY_ROLE] !== "all")
    filtersToAdd.push(`role=${filters[TYPES.ENTITY_ROLE]}`);
  if (filters[TYPES.STORAGE] !== "all")
    filtersToAdd.push(`storage=${filters[TYPES.STORAGE]}`);
  if (filters[TYPES.PURPOSE] !== "all")
    filtersToAdd.push(`purpose_id=${filters[TYPES.PURPOSE]}`);
  if (filters[TYPES.BUSINESS_UNIT] !== "all")
    filtersToAdd.push(`business_unit_id=${filters[TYPES.BUSINESS_UNIT]}`);
  if (filters[TYPES.COUNTRY] !== "all")
    filtersToAdd.push(`country_id=${filters[TYPES.COUNTRY]}`);

  const filtered = data[TYPES.PII_CATEGORIES]
    .map(({ id }) => id)
    .filter((el) => !filters[TYPES.PII_CATEGORIES].includes(el));

  if (!isEmpty(filtered))
    filtersToAdd.push(`exclude_pii_categories_ids=${filtered}`);

  url += filtersToAdd.join("&");

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getScansByMonth = (envId, month) => {
  const from = moment(month, "M").startOf("month").format();
  const to = moment(month, "M").endOf("month").format();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/environments/${envId}/scans?from=${from}&to=${to}`,
    requestOptions
  )
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getTreeByCountryData = (filters, data) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/${
    filters[TYPES.SCAN]
  }/recordtree_bycountry?`;

  const filtersToAdd = [];

  if (filters[TYPES.ENTITY_ROLE] !== "all")
    filtersToAdd.push(`role=${filters[TYPES.ENTITY_ROLE]}`);
  if (filters[TYPES.STORAGE] !== "all")
    filtersToAdd.push(`storage=${filters[TYPES.STORAGE]}`);
  if (filters[TYPES.PURPOSE] !== "all")
    filtersToAdd.push(`purpose_id=${filters[TYPES.PURPOSE]}`);
  if (filters[TYPES.BUSINESS_UNIT] !== "all")
    filtersToAdd.push(`business_unit_id=${filters[TYPES.BUSINESS_UNIT]}`);
  if (filters[TYPES.COUNTRY] !== "all")
    filtersToAdd.push(`country_id=${filters[TYPES.COUNTRY]}`);

  const filtered = data[TYPES.PII_CATEGORIES]
    .map(({ id }) => id)
    .filter((el) => !filters[TYPES.PII_CATEGORIES].includes(el));

  if (!isEmpty(filtered))
    filtersToAdd.push(`exclude_pii_categories_ids=${filtered}`);

  url += filtersToAdd.join("&");

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getLastScansTreeByCountryData = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/last_scans/recordtree_bycountry`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getCustomersByCountryData = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/customers`, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => {
      return data;
    });
};

export const getOwnersData = (filters, data) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/${filters[TYPES.SCAN]}/owners?`;

  const filtersToAdd = [];

  if (filters[TYPES.ENTITY_ROLE] !== "all")
    filtersToAdd.push(`role=${filters[TYPES.ENTITY_ROLE]}`);
  if (filters[TYPES.STORAGE] !== "all")
    filtersToAdd.push(`storage=${filters[TYPES.STORAGE]}`);
  if (filters[TYPES.PURPOSE] !== "all")
    filtersToAdd.push(`purpose_id=${filters[TYPES.PURPOSE]}`);
  if (filters[TYPES.BUSINESS_UNIT] !== "all")
    filtersToAdd.push(`business_unit_id=${filters[TYPES.BUSINESS_UNIT]}`);
  if (filters[TYPES.COUNTRY] !== "all")
    filtersToAdd.push(`country_id=${filters[TYPES.COUNTRY]}`);

  const filtered = data[TYPES.PII_CATEGORIES]
    .map(({ id }) => id)
    .filter((el) => !filters[TYPES.PII_CATEGORIES].includes(el));

  if (!isEmpty(filtered))
    filtersToAdd.push(`exclude_pii_categories_ids=${filtered}`);

  url += filtersToAdd.join("&");

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getLastScans = (envId, filters, data) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/environments/${envId}/recordtrend`;

  const filtersParams = {
    last: filters[TYPES.LAST_SCANS],
  };

  if (filters[TYPES.ENTITY_ROLE] !== "all")
    filtersParams.role = filters[TYPES.ENTITY_ROLE];
  if (filters[TYPES.PURPOSE] !== "all")
    filtersParams.purpose_id = filters[TYPES.PURPOSE];
  if (filters[TYPES.BUSINESS_UNIT] !== "all")
    filtersParams.business_unit_id = filters[TYPES.BUSINESS_UNIT];
  if (filters[TYPES.CUSTOMER_REGION] !== "all")
    filtersParams.customerRegion = filters[TYPES.CUSTOMER_REGION];
  if (filters[TYPES.COUNTRY] !== "all")
    filtersParams.country_id = filters[TYPES.COUNTRY];

  const filtered = data[TYPES.PII_CATEGORIES]
    .map(({ id }) => id)
    .filter((el) => !filters[TYPES.PII_CATEGORIES].includes(el));
  if (!isEmpty(filtered)) filtersParams.exclude_pii_categories_ids = filtered;

  url += qs(filtersParams);

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getFunctionalUses = (filters, data) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/${filters[TYPES.SCAN]}/functional_uses`;

  const filtersParams = {};

  if (filters[TYPES.ENTITY_ROLE] !== "all")
    filtersParams.role = filters[TYPES.ENTITY_ROLE];
  if (filters[TYPES.STORAGE] !== "all")
    filtersParams.storage = filters[TYPES.STORAGE];
  if (filters[TYPES.PURPOSE] !== "all")
    filtersParams.purpose_id = filters[TYPES.PURPOSE];
  if (filters[TYPES.BUSINESS_UNIT] !== "all")
    filtersParams.business_unit_id = filters[TYPES.BUSINESS_UNIT];
  if (filters[TYPES.CUSTOMER_REGION] !== "all")
    filtersParams.customerRegion = filters[TYPES.CUSTOMER_REGION];
  if (filters[TYPES.COUNTRY] !== "all")
    filtersParams.country_id = filters[TYPES.COUNTRY];

  const filtered = data[TYPES.PII_CATEGORIES]
    .map(({ id }) => id)
    .filter((el) => !filters[TYPES.PII_CATEGORIES].includes(el));
  if (!isEmpty(filtered)) filtersParams.exclude_pii_categories_ids = filtered;

  url += qs(filtersParams);

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
