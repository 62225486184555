import React from "react";
import Switch from "components/Inputs/Switch";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
import { getFilterConsentsUnconsents } from "redux/_filters/filters.selectors";

const { TYPES } = CONSTANTS;

const Unconsents = ({ unconsent, setUnconsent }) => {
  const classes = useStyles();

  return (
    <Switch
      label="Unconsents"
      className={classes.filtersInput}
      labelPlacement="start"
      text="Show Unconsents only"
      value={unconsent}
      onChange={setUnconsent}
    />
  );
};

const mapStateToProps = (state) => ({
  unconsent: getFilterConsentsUnconsents(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUnconsent: (unconsent) =>
    dispatch(selectData(TYPES.CONSENTS.UNCONSENTS, unconsent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unconsents);
