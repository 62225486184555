import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useSelector } from "react-redux";
import moment from "moment";
import { Typography, Box, Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const SHARING_LABELS = {
  api: { label: "Api", color: "#1F77B4" },
  database: { label: "Database", color: "#FF7F0E" },
  files: { label: "File", color: "#9467BD" },
  log: { label: "Log", color: "#D62728" },
  print: { label: "Print", color: "#2CA02C" },
  environment: { label: "Environment", color: "#8C564B" },
  hardcoded: { label: "Hardcoded", color: "#E377C2" },
  cookie: { label: "Cookie", color: "#7F7F7F" },
};

const SHARING_SEVERITY_LABELS = {
  leakage: { label: "Leakage", color: "#F94144" },
  medium_severity: { label: "Medium Severity", color: "#F3722C" },
  low_severity: { label: "Low Severity", color: "#6794dc" },
};

const ChartSummary = ({ order }) => {
  const summary = useSelector((state) => state.scanner.repositoryScanSummary);
  const xyLineChartDiv = useRef(null);
  const stackColumnChartDiv = useRef(null);

  const sanitizeChartData = (data) => {
    const sanitizedData = data?.map((item) => {
      const formattedDate = moment(item.created).format(
        "MM/DD/YYYY hh:mm:ss A"
      );
      return { ...item, created: formattedDate };
    });

    if (sanitizedData) {
      if (order === "asc") {
        return sanitizedData.sort(
          (a, b) => new Date(a.created) - new Date(b.created)
        );
      } else if (order === "desc") {
        return sanitizedData.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
      }
    }

    return sanitizedData;
  };

  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    let xyLineChart = am4core.create(xyLineChartDiv.current, am4charts.XYChart);
    let stackColumnChart = am4core.create(
      stackColumnChartDiv.current,
      am4charts.XYChart
    );

    const createCategoryAxis = (chart, field) => {
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = field;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.rotation = 45; // Rotate labels
      categoryAxis.renderer.labels.template.horizontalCenter = "right"; // Align rotation
      categoryAxis.renderer.labels.template.verticalCenter = "middle"; // Align rotation
      categoryAxis.renderer.labels.template.maxWidth = 100; // Set max width
      categoryAxis.renderer.labels.template.wrap = true; // Enable wrapping
      categoryAxis.renderer.labels.template.fontSize = 10; // Set font size
      categoryAxis.renderer.labels.template.adapter.add(
        "textOutput",
        function (text) {
          return text.replace(" ", "\n");
        }
      );
      return categoryAxis;
    };

    const createValueAxis = (chart) => {
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.strictMinMax = false;
      valueAxis.numberFormatter.numberFormat = "#"; // Ensure only integer values
      return valueAxis;
    };

    const createXYSeries = (chart, field, name, color, hidden) => {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "created";
      series.name = name;
      series.strokeWidth = 2;
      series.stroke = am4core.color(color);
      series.hidden = hidden || false;

      let bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color(color); // Set bullet color

      series.tooltipText = "[bold]{name} [bold]: {valueY}[/]"; //\n[bold]{categoryX}: {valueY}[/]
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(color); // Set tooltip background color
      series.tooltip.label.fill = am4core.color("#FFFFFF"); // Set tooltip text color to white for better readability
      series.legendSettings.valueText = "{valueY}";
      series.visible = true;
      series.hiddenState.properties.opacity = 0;
      series.hiddenState.properties.visible = false;

      let hoverState = series.states.create("hover");
      hoverState.properties.strokeWidth = 3;
      hoverState.properties.stroke = am4core.color(color); // Maintain color on hover

      let bulletHoverState = bullet.states.create("hover");
      bulletHoverState.properties.scale = 1.5;
      bulletHoverState.properties.fill = am4core.color(color); // Maintain color on hover
    };

    const createStackColumnSeries = (chart, field, name, color, hidden) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "created";
      series.name = name;
      series.tooltipText = "[bold]{name} [bold]: {valueY}[/]"; //\n[bold]{categoryX}: {valueY}[/]
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(color); // Set tooltip background color
      series.tooltip.label.fill = am4core.color("#FFFFFF"); // Set tooltip text color to white for better readability
      series.stacked = true;
      series.columns.template.width = am4core.percent(16);
      series.columns.template.fill = am4core.color(color);
      series.columns.template.stroke = am4core.color("transparent"); // Make border transparent
      series.columns.template.strokeWidth = 0; // Remove border
      series.hidden = hidden || false;

      let hoverState = series.columns.template.states.create("hover");
      hoverState.properties.fill = am4core.color(color).lighten(-0.2); // Darken color on hover
    };

    const createCursor = (chart) => {
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.opacity = 0;
      chart.cursor.lineX.strokeDasharray = "5,4";
    };

    const createScrollbar = (chart) => {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.marginBottom = 20;
    };

    const createXYLineChart = (chart, data) => {
      chart.data = data;
      chart.logo.disabled = true;

      createCategoryAxis(chart, "created");
      createValueAxis(chart);

      Object.keys(SHARING_LABELS).forEach((key) => {
        const sharingValue = SHARING_LABELS[key];
        createXYSeries(
          chart,
          key,
          sharingValue.label,
          sharingValue.color,
          SHARING_SEVERITY_LABELS[key]?.hidden
        );
      });

      //createLegend(chart, SHARING_LABELS); // Use SHARING_LABELS for legend
      createCursor(chart);
      createScrollbar(chart);
    };

    const createStackColumnChart = (chart, data) => {
      chart.data = data;
      chart.logo.disabled = true;

      createCategoryAxis(chart, "created");
      createValueAxis(chart);

      Object.keys(SHARING_SEVERITY_LABELS).forEach((key) => {
        const severityValue = SHARING_SEVERITY_LABELS[key];
        createStackColumnSeries(
          chart,
          key,
          severityValue.label,
          severityValue.color,
          SHARING_SEVERITY_LABELS[key]?.hidden
        );
      });

      // No legend creation for stackColumnChart
      createCursor(chart);
      createScrollbar(chart);
    };

    if (summary) {
      createXYLineChart(
        xyLineChart,
        sanitizeChartData(summary.sharingCategoriesSummary)
      );
      createStackColumnChart(
        stackColumnChart,
        sanitizeChartData(summary.sharingSeveritySummary)
      );
    }

    return () => {
      if (xyLineChart) {
        xyLineChart.dispose();
      }
      if (stackColumnChart) {
        stackColumnChart.dispose();
      }
    };
  }, [summary, order]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 24,
      }}
    >
      <Box
        style={{
          backgroundColor: "#F7F7F7",
          width: "100%",
          height: "fit-content",
          borderRadius: 10,
        }}
      >
        <Typography
          style={{
            color: "#1B3586",
            fontFamily: "Inter",
            fontSize: 20,
            fontWeight: 900,
            padding: 16,
          }}
        >
          PII Sharing Category
        </Typography>
        <Box
          id="xyLineChartDiv"
          style={{ width: "100%", minHeight: 500 }}
          ref={xyLineChartDiv}
        />
        <Grid container spacing={2} style={{ marginLeft: 24 }}>
          {Object.values(SHARING_LABELS).map((item, index) => (
            <Grid key={index} item xs={4}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FiberManualRecordIcon style={{ color: item.color }} />
                </Grid>
                <Grid item>
                  <Typography>{item.label}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#F7F7F7",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Typography
          style={{
            color: "#1B3586",
            fontFamily: "Inter",
            fontSize: 20,
            fontWeight: 900,
            padding: 16,
          }}
        >
          PII Sharing Severity
        </Typography>
        <Box
          id="stackColumnChartDiv"
          style={{ width: "100%", minHeight: 500 }}
          ref={stackColumnChartDiv}
        />
        <Grid container spacing={2}>
          {Object.values(SHARING_SEVERITY_LABELS).map((item, index) => (
            <Grid key={index} item xs={4}>
              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{ marginLeft: 24 }}
              >
                <Grid item>
                  <FiberManualRecordIcon style={{ color: item.color }} />
                </Grid>
                <Grid item>
                  <Typography>{item.label}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ChartSummary;
