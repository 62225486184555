import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  kpisContainer: {
    padding: "2rem 0px",
  },
  kpisTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[400],
    textTransform: "uppercase",
    width: "100%",
    textAlign: "right",
    paddingBottom: "4px",
    borderBottom: "1px solid #cfdff8",
    marginBottom: "8px",
  },
  itemValueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  itemValue: {
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: 1.36,
    color: theme.palette.blue[500],
  },
  itemTitle: {
    fontSize: "14px",
    color: "#828282",
    fontWeight: 600,
  },
  filtersTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[400],
    textTransform: "uppercase",
    textAlign: "right",
    paddingBottom: "4px",
  },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  filtersInput: {
    marginTop: theme.spacing(),
    width: "100%",
    "& label": {
      width: "100%",
      justifyContent: "space-between",
      "& span:first-child": {
        margin: 0,
      },
    },
  },
  selectText: {
    color: theme.palette.blue[600],
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 0.9,
    letterSpacing: "1px",
    padding: 0,
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
  },
  selectMenu: {
    backgroundColor: "#f1f6fd",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #cfdff8",
    width: "194px",
    top: "70px !important",
  },
  selectMenuItem: {
    color: theme.palette.blue[500],
    fontSize: "0.9375rem",
    minHeight: "40px",
    paddingTop: "0",
    paddingBottom: "0",
    "&:hover": {
      backgroundColor: theme.palette.blue[100],
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[600],
      fontWeight: "700",
      "&:hover": {
        backgroundColor: theme.palette.blue[200],
      },
    },
  },
  selectContainer: {
    //borderRadius: '4px',
    padding: "4px 0px 13px",
    width: "100%",
    borderBottom: "1px solid #cfdff8",
  },
  formControl: {
    backgroundColor: "white",
    width: "100%",
  },
  selectTitle: {
    padding: 0,
    lineHeight: 1.29,
    color: theme.palette.blue[300],
    fontFamily: "Roboto Condensed",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  selectedText: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 0.9,
    letterSpacing: "1px",
    color: theme.palette.blue[600],
  },
  selectIcon: {
    width: "32px",
    height: "32px",
    top: "calc(50% - 16px)",
  },
  reloadButtonContainer: {
    width: "100%",
    height: "85px",
    borderBottom: "1px solid #cfdff8",
  },
  reloadButton: {
    width: "100%",
  },
  reloadIcon: {
    width: 15,
    height: 15,
    fill: theme.palette.blue[400],
    marginRight: theme.spacing(),
  },
  kpiTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  kpiIcon: {
    height: 26,
    marginRight: 8,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  resetButton: {
    fontSize: 18,
    marginBottom: -12,
    marginTop: -14,
    width: 42,
    height: 42,
    marginRight: 4,
    marginLeft: -6,
    color: theme.palette.blue[400],
  },
  titleContainer: {
    borderBottom: "1px solid #cfdff8",
  },
}));
