import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FormControl,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close.js";
import ChartSummary from "./ChartSummary.js";
import ScanDetailRow from "../components/detail/ScanDetailRow";
import LastScanStatusRow from "../components/detail/LastScanStatusRow";
import repoProviderUtils from "helpers/repoProviderUtils.js";
import { useFetchRepositoryDetailsById } from "hooks/useFetchRepositoryDetailsById";
import {
  setRepositoryId,
  setBranches,
  setRepositoryBranchId,
  setRepositoryUrl,
  setRepostoryProvider,
  setRepositoryScanSummary,
  setRepositoryWebhookEnabled,
  setTabView,
} from "redux/_scanner/scanner.actions.js";
import RunNewScanModal from "./RunNewScanModal.js";
import DetailTab from "./components/DetailTab.js";
import NewRepositoryScanList from "./NewRepositoryScanList.js";
import LearnMoreLink from "../ftu/components/LearnMoreLink.js";
import WebHookListing from "./WebHookListing.js";
import RepositoryChip from "./components/repositoryChip.jsx";

const NewRepositoryDetailView = () => {
  const dispatch = useDispatch();
  const { repo_id } = useParams();
  const [errorDeleted, setErrorDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const branches = useSelector((state) => state.scanner.branches);
  const provider = useSelector((state) => state.scanner.repositoryProvider);
  const view = useSelector((state) => state.scanner.tabView);
  const url = useSelector((state) => state.scanner.repositoryUrl);
  const OrderTypePII = "asc";
  const currentRepositoryBranchId = useSelector(
    (state) => state.scanner.currentBranchId
  );
  const {
    data,
    branchSummary,
    loading,
    error,
    fetchData,
    fetchBranchSummary,
  } = useFetchRepositoryDetailsById();

  const sanitizedRepoId = () => {
    return repo_id.charAt(0) === ":" ? repo_id.slice(1) : repo_id;
  };
  useEffect(() => {
    dispatch(setRepositoryBranchId(""));

    fetchData(sanitizedRepoId());
  }, []);

  const handleRunScanModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSwitchToScanList = useCallback(() => {
    dispatch(setTabView(1));
  }, [dispatch]);

  if (data && data.branches_detail) {
    const branches = data.branches_detail;
    dispatch(setRepositoryId(data.url));
    if (currentRepositoryBranchId === "") {
      //this is a workRound only for first loading
      dispatch(setRepositoryBranchId(branches[0].id));
    }
    dispatch(setBranches(branches));
    dispatch(setRepostoryProvider(data.provider));
    dispatch(setRepositoryUrl(data.url));
    dispatch(setRepositoryWebhookEnabled(data.webhook_enabled));
  }
  if (branchSummary) {
    dispatch(
      setRepositoryScanSummary({
        scans: branchSummary.scans,
        newLekeages: branchSummary.new_leakages,
        newSharingPii: branchSummary.new_sharing_pii,
        sharingCategoriesSummary: branchSummary.sharing_categories_summary,
        sharingSeveritySummary: branchSummary.sharing_severity_summary,
        lastScanSummary: branchSummary.last_scan,
      })
    );
  }

  useEffect(() => {
    if (currentRepositoryBranchId) {
      setErrorDeleted(false);
      fetchBranchSummary(currentRepositoryBranchId);
    }
  }, [currentRepositoryBranchId]);

  const handleBranchChange = useCallback(
    (event) => {
      dispatch(setRepositoryBranchId(event.target.value));
    },
    [dispatch]
  );
  const handleScanRepo = useCallback((event) => {
    setIsModalOpen(true);
  }, []);

  const onHandleDelete = useCallback(() => {
    setErrorDeleted(true);
  }, []);

  const showError = () => {
    return (
      !loading &&
      error &&
      !errorDeleted && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
            backgroundColor: "#F9F9FA",
            border: "1px solid red",
            padding: 12,
            borderRadius: 8,
            fontFamily: "Inter",
            color: "red",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flex: 1,
              fontFamily: "Inter",
              fontSize: 14,
              color: "#FB2424",
            }}
          >
            {error}
          </Typography>
          <CloseIcon style={{ color: "grey" }} onClick={onHandleDelete} />
        </Box>
      )
    );
  };

  const showTabSelection = () => {
    switch (view) {
      case 0:
        return (
          <>
            <ScanDetailRow />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                marginTop: 48,
                marginBottom: 48,
              }}
            >
              <Typography>Last Scan Status PII manipulation</Typography>
              <LastScanStatusRow />
            </Box>
            <ChartSummary order={OrderTypePII} />
          </>
        );
      case 1:
        return <NewRepositoryScanList />;

      case 2:
        return <WebHookListing repositoryId={sanitizedRepoId()} />;
      default:
        <></>;
    }
  };

  const drawForm = () => {
    if (branches.length > 0) {
      return (
        <FormControl
          variant="outlined"
          style={{ width: 244, borderColor: "#0000000" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Branch</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currentRepositoryBranchId}
            onChange={handleBranchChange}
            label="Branch"
          >
            {branches.map((branch, index) => (
              <MenuItem
                style={{ background: "white" }}
                key={branch.id}
                value={branch.id}
              >
                {branch.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const isWebHookActive = () => {
    if (!data) return false;
    const currentBranch = data.branches_detail.find(
      (branch) => branch.id === currentRepositoryBranchId
    );
    return currentBranch ? currentBranch.webhook_enabled : false;
  };

  return (
    <>
      {showError()}
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ width: 40, height: 40 }} />
        </Box>
      ) : (
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 24,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 24,
                flex: 1,
              }}
            >
              <Box
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RepositoryChip provider={data?.provider} />
                <Typography
                  style={{
                    color: "#466DE8",
                    fontWeight: 900,
                    fontFamily: "Inter",
                  }}
                >
                  {url
                    ? repoProviderUtils.getRepositoryShortNameByProvider(
                        url,
                        provider
                      )
                    : ""}
                </Typography>
              </Box>
              {drawForm()}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Button
                variant="contained"
                style={{
                  fontFamily: "Inter",
                  color: "#1B3586",
                  backgroundColor: "#6BD4CA",
                  fontSize: "14px",
                  fontWeight: "700",
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 224,
                  cursor: "pointer",
                }}
                onClick={handleScanRepo}
              >
                run new scan
              </Button>
              <LearnMoreLink />
            </Box>
          </Box>
          <Box style={{ marginTop: 24, marginBottom: 24 }}>
            <DetailTab />
          </Box>
          {showTabSelection()}
          <RunNewScanModal
            isOpen={isModalOpen}
            onRequestClose={handleRunScanModalClose}
            initialBranchId={currentRepositoryBranchId}
            onSwitchToScanList={handleSwitchToScanList}
          />
        </Box>
      )}
    </>
  );
};

export default NewRepositoryDetailView;
