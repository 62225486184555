import React, { useEffect, useContext } from "react";
import DataStructureTreeMap from "charts/TreeMap/DataStructure";
import { connect } from "react-redux";
import {
  getLoading,
  getTreeMapDataFormatted,
  getError,
} from "redux/_charts/charts.selectors";
import { getTreeMap } from "redux/_charts/charts.async.actions";
import { getFilters } from "redux/_filters/filters.selectors";
import PageWrapper from "components/PageWrapper";
import isNil from "lodash.isnil";
import CONSTANTS from "redux/_filters/filters.constants";
import { SidebarContext } from "components/Sidebar";
import isEmpty from "lodash.isempty";
import { data as mockedData } from "./mocked.json";

const { TYPES } = CONSTANTS;

const DataMap = ({ filters, getTreeData, treeMapData, loading, error }) => {
  useEffect(() => {
    if (!isNil(filters[TYPES.SCAN])) getTreeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const sidebar = useContext(SidebarContext);
  useEffect(() => {
    if (window.innerWidth < 1380) {
      sidebar.setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      error={error}
      retryFn={getTreeData}
      showKpis
      title="PII REPORTS"
      subtitle="DATA MAP"
      filters={[
        TYPES.ENV,
        TYPES.ENTITY_ROLE,
        TYPES.STORAGE,
        TYPES.BUSINESS_UNIT,
        TYPES.PURPOSE,
        TYPES.COUNTRY,
        TYPES.SCAN,
        TYPES.PII_CATEGORIES,
      ]}
      loading={loading}
      empty={isEmpty(treeMapData)}
    >
      <DataStructureTreeMap
        data={!isEmpty(treeMapData) ? treeMapData : mockedData}
        colors={["#dae9ff", "#a2c1ff", "#4767b8", "#12286b"]}
        disableScrolling={isEmpty(treeMapData)}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  treeMapData: getTreeMapDataFormatted(state),
  loading: getLoading(state),
  filters: getFilters(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTreeData: () => dispatch(getTreeMap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataMap);
