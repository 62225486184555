import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ZenDataLogo from "assets/illustrations/brand.js";

import useStyles from "./styles";

const LoginLayout = ({ title, message, form, simple }) => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={12} sm={12} md={6} className={classes.formContainer}>
        {!simple && (
          <Link
            className={classes.goBack}
            exact="true"
            target="_top"
            to={{ pathname: "https://www.zendata.dev" }}
          >
            <ArrowBackIcon /> Back to homepage
          </Link>
        )}

        <div className={classes.formWrapper}>
          <Link
            className={classes.logo}
            exact="true"
            target="_top"
            to={{ pathname: "https://www.zendata.dev" }}
          >
            <ZenDataLogo />
          </Link>
          <Typography variant="h1" className={classes.h1}>
            {title}
          </Typography>
          <Typography className={classes.message} component="p">
            {message}
          </Typography>

          {form}

          <footer className={classes.footer}>
            <Typography variant="body2" color="textSecondary">
              {"Copyright © "}
              <Link
                className={classes.linkToHome}
                exact="true"
                target="_top"
                to={{ pathname: "https://www.zendata.dev" }}
              >
                Zendata
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </footer>
        </div>
      </Grid>

      <Grid item xs={false} sm={false} md={6} className={classes.image} />
    </Grid>
  );
};

export default LoginLayout;
