import React from "react";

const checkIcon = ({ outlined, fill = "#3F51B5", ...restProps }) =>
  outlined ? (
    <svg
      width={32}
      height={30}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.25 25.4375C9.75 25.9167 10.25 26.1562 11 26.1562C11.75 26.1562 12.25 25.9167 12.75 25.4375L30.25 8.66667C31.25 7.70833 31.25 6.27083 30.25 5.3125C29.25 4.35417 27.75 4.35417 26.75 5.3125L11 20.4063L5.25 14.8958C4.25 13.9375 2.75 13.9375 1.75 14.8958C0.75 15.8542 0.75 17.2917 1.75 18.25L9.25 25.4375Z"
        stroke={fill}
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.95 13.3C5.25 13.6 5.55 13.75 6 13.75C6.45 13.75 6.75 13.6 7.05 13.3L17.55 2.8C18.15 2.2 18.15 1.3 17.55 0.7C16.95 0.1 16.05 0.1 15.45 0.7L6 10.15L2.55 6.7C1.95 6.1 1.05 6.1 0.45 6.7C-0.15 7.3 -0.15 8.2 0.45 8.8L4.95 13.3Z"
        fill={fill}
      />
    </svg>
  );

export default checkIcon;
