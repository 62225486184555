import { useState, useEffect } from "react";
import axios from "axios";
import { authHeader } from "helpers/auth-header";
import config from "config";

export const useFetchAvailableFilters = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.apiUrl}/repo-scans/available-filters/`, getHeadersConfig());
        setData(response.data);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};
