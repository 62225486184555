import React, { useMemo, useState } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Title from "pages/AppReport/common/Title";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Table from "pages/AppReport/common/Table";
import { Cancel, CheckCircle, LocationOn } from "@material-ui/icons";
import { ClickAwayListener, Tooltip, withStyles } from "@material-ui/core";
import SectionContent from "pages/AppReport/common/SectionContent";

const GeolocationInfo = ({
  ip,
  country_long,
  city,
  latitude,
  longitude,
  view,
}) => (
  <>
    <p style={{ margin: 0 }}>
      <b>Ip:</b> {ip}
    </p>
    <p style={{ margin: 0 }}>
      <b>Country:</b> {country_long}
    </p>
    <p style={{ margin: 0 }}>
      <b>City:</b> {city}
    </p>
    <p style={{ margin: 0 }}>
      <b>Latitude:</b> {latitude}
    </p>
    <p style={{ margin: 0 }}>
      <b>Longitude:</b> {longitude}
    </p>
    <p style={{ margin: 0 }}>
      <b>View:</b>&nbsp;
      {/* <a
        href={`http://maps.google.com/maps?q=${latitude},${longitude}`}
        style={{ cursor: "pointer" }}
        target="_blank"
      > */}
      Google Map
      {/* </a> */}
    </p>
  </>
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    padding: "8px 24px",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 440,
    fontSize: "14px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ViewButton = ({ geolocation }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    // setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <HtmlTooltip
        title={<GeolocationInfo {...geolocation} />}
        // PopperProps={{
        //   disablePortal: true,
        // }}
        // onClose={handleClose}
        // open={open}
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        placement="bottom"
      >
        <a
          style={{ cursor: "pointer", textDecoration: "underline" }}
          // onClick={handleOpen}
          target="_blank"
          href={`http://maps.google.com/maps?q=${geolocation.latitude},${geolocation.longitude}`}
        >
          View
        </a>
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

const Domains = ({ data, goToNextSectionAction }) => {
  const formattedData = useMemo(
    () =>
      Object.entries(data).map((domain) => ({
        domain: domain[0],
        ...domain[1],
      })),
    [data]
  );
  return (
    <Page
      key={SECTIONS_CONTENT.domains.key}
      footerBackgroundColor={SECTIONS_CONTENT.domains.backgroundColor}
      footerText={<div className="privacy-tech">Check the app information</div>}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.domains.description}>
        {SECTIONS_CONTENT.domains.title}
      </Title>
      <SectionContent>
        <Table
          data={formattedData}
          limitPerPage={10}
          columns={[
            {
              key: "domain",
              label: "Domain",
              width: "45%",
            },
            {
              key: "bad",
              label: "Status",
              width: "20%",
              render: (bad) =>
                bad === "no" ? (
                  <Tooltip title="Ok">
                    <CheckCircle style={{ color: "#468C49" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Bad">
                    <Cancel style={{ color: "#D32F2F" }} />
                  </Tooltip>
                ),
            },
            {
              key: "geolocation",
              label: "geolocation",
              render: (geolocation) => (
                <div>
                  <LocationOn />
                  {geolocation ? (
                    <ViewButton geolocation={geolocation} />
                  ) : (
                    <span>No Geolocation information available.</span>
                  )}
                </div>
              ),
              width: "35%",
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default Domains;
