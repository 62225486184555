import React from "react";
import {
  CheckCircleOutlineOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import useStyles from "./styles";

const StatusList = {
  warning: ReportProblemOutlined,
  confirm: CheckCircleOutlineOutlined,
};

const StatusIcon = ({ type, styles, className }) => {
  const classes = useStyles();
  const Icon = StatusList[type] || StatusList.warning;
  return (
    <Icon
      classes={{ root: classes[type] }}
      style={styles}
      className={className}
    />
  );
};

export default StatusIcon;
