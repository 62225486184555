import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  liText: {
    marginTop: 2,
    paddingLeft: 5,
  },
  levelRow: {
    marginTop: 5,
  },
  level: {
    fontWeight: 600,
    paddingLeft: 3,
  },
});

export default styles;
