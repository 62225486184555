import { Document } from "@react-pdf/renderer";
import { calculateTermsAndConditions } from "components/PrivacyReports/Report/utils";
import AssessmentSummaryyPage from "./AssessmentSummaryyPage";
import ConsentAndLinksPage from "./ConsentAndLinksPage";
import CookiesPage from "./CookiesPage";
import GeographicPage from "./GeographicPage";
import OpportunitiesPage from "./OpportunitiesPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import ScoreDetailsPage from "./ScoreDetailsPage";
import SecurityPage from "./SecurityPage";
import PiiPage from "./PiiPage";
import styles from "./styles";
import SummaryPage from "./SummaryPage";
import ThirdPartyTrackersPage from "./ThirdPartyTrackersPage";
import FrontPage from "./FrontPage";
import CustomPage from "./commons/Page";
import moment from "moment";

const PDFReport = ({
  branding,
  logo,
  isApp,
  geographicChart,
  screenshot,
  privacyChart,
  report,
  risk,
  scores,
  testsResult,
  tips,
  totalScore,
}) => {
  const {
    canonical: name,
    createdAt: created,
    serviceTerms,
    report: {
      assesmentSummary: assessmentSummary,
      cookieMessageOnFirstLoad,
      details: trackers,
      hasCookieManager,
      hasPrivacyLink,
      hasTermsLink,
      pentest,
      personalData,
      personalData: { complexityScore, tpsCrossMapping },
      records,
      domainsMetadata,
    },
  } = report;
  const {
    hasPrivacyPolicy,
    hasTermsAndConditions,
  } = calculateTermsAndConditions(
    serviceTerms,
    hasPrivacyLink,
    hasTermsLink,
    personalData
  );

  return (
    <Document>
      <FrontPage
        url={name}
        reportCreatedDate={created}
        logo={logo}
        branding={branding}
      />
      <CustomPage
        size="A4"
        style={styles.page}
        logo={logo}
        branding={branding}
        wrap
        title="Privacy Scanner"
        subtitle="Report"
        reportLabel={`${name} - ${moment(created).format("MMM, YYYY")}`}
      >
        <SummaryPage
          logo={logo}
          branding={branding}
          risk={risk}
          styles={styles}
          screenshot={screenshot}
          testPassed={testsResult.passed}
          totalScore={totalScore}
          totalTests={testsResult.total}
        />
        <ScoreDetailsPage isApp={isApp} styles={styles} scores={scores} />
        {!!privacyChart && (
          <PrivacyPolicyPage
            styles={styles}
            chart={privacyChart}
            complexityScore={complexityScore}
            tpsCrossMapping={tpsCrossMapping}
          />
        )}
        <AssessmentSummaryyPage styles={styles} data={assessmentSummary} />
        {!isApp && <CookiesPage styles={styles} records={records} />}
        <ThirdPartyTrackersPage
          styles={styles}
          trackers={trackers}
          domainsMetadata={domainsMetadata}
        />
        {!isApp && (
          <ConsentAndLinksPage
            styles={styles}
            cookieMessageOnFirstLoad={cookieMessageOnFirstLoad}
            hasCookieManager={hasCookieManager}
            hasPrivacyPolicy={hasPrivacyPolicy}
            hasTermsAndConditions={hasTermsAndConditions}
          />
        )}
        {!!geographicChart && geographicChart.length && (
          <GeographicPage styles={styles} chart={geographicChart} />
        )}
        {!isApp && personalData.scriptAnalysis && (
          <PiiPage styles={styles} data={personalData.scriptAnalysis} />
        )}
        {!isApp && (
          <SecurityPage
            styles={styles}
            pentest={pentest && pentest.data ? pentest.data : []}
          />
        )}
        {!isApp && tips && tips.length && (
          <OpportunitiesPage styles={styles} tips={tips} />
        )}
      </CustomPage>
    </Document>
  );
};

export default PDFReport;
