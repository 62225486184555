import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import SelectInput from "components/Inputs/Select";
import {
  getFilterScans,
  getFilterSelectedLastScans,
} from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import { getScans } from "redux/_filters/filters.async.actions";
import { connect } from "react-redux";
import useStyles from "../styles";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { withRouter } from "react-router-dom";

import CONSTANTS from "redux/_filters/filters.constants";
import isEmpty from "lodash.isempty";

const { TYPES } = CONSTANTS;

const LastScansFilter = ({
  envId,
  scans = [],
  getFilterScans,
  numberOfScans,
  selectNumberOfScans,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getFilterScans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envId]);

  useEffect(() => {
    if (scans.length > 0)
      selectNumberOfScans(scans.length > 10 ? 10 : scans.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scans]);

  const generateOptions = (number) =>
    [...Array(number)].map((_, i) => {
      const value = i + 1;
      return {
        value,
        label: value > 1 ? `${i + 1} Scans` : `Last Scan`,
      };
    });
  const options =
    scans.length > 1
      ? generateOptions(scans.length).filter(
          (scan) => scan.label !== "Last Scan"
        )
      : generateOptions(scans.length);
  return (
    <Box className={classes.selectContainer}>
      <label className={classes.inputLabel}>VIEWING LAST</label>
      <SelectInput
        onChange={selectNumberOfScans}
        value={numberOfScans}
        options={options}
        className={classes.select}
        disabled={isEmpty(options)}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  envId: getSelectedEnvId(state),
  scans: getFilterScans(state),
  numberOfScans: getFilterSelectedLastScans(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFilterScans: () => dispatch(getScans()),
  selectNumberOfScans: (numberOfScans) =>
    dispatch(selectData(TYPES.LAST_SCANS, numberOfScans)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LastScansFilter));
