import React, { useState } from "react";
import {
  FlexibleXYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  LineMarkSeries,
  Hint,
} from "react-vis";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import isEmpty from "lodash.isempty";

import "./styles.scss";

const styles = {
  line: {
    fill: "none",
  },
  gridLine: {
    stroke: "rgba(119, 119, 119, 0.08)",
    strokeWidth: 0.5,
  },
  axis: {
    line: {
      stroke: "#000",
      strokeWidth: 0.5,
    },
    text: {
      stroke: "none",
      fill: "#4F4F4F",
      lineHeight: "22px",
      fontSize: "14px",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& >div": {
      "& >div": {
        position: "relative",
      },
    },
  },
  hintContainer: {
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: theme.spacing(),
    borderRadius: "4px",
  },
  refContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    margin: theme.spacing(),
  },
  hintText: {
    fontSize: "12px",
    lineHeight: "22px",
    width: "calc(100% - 26px)",
    color: "white",
    fontWeight: 500,
  },
}));

const formatValue = (value) => (value ? `${value.toFixed(2)}%` : "");

const ScansTrend = (props) => {
  const classes = useStyles([]);
  const { data, style = {} } = props;
  const allPII = data.length
    ? data.map(({ key, piiPercent }) => ({ x: key, y: piiPercent }))
    : [];
  const unprotected = data.length
    ? data.map(({ key, unprotected }) => ({ x: key, y: unprotected }))
    : [];
  const threshold = data.length
    ? data.map(({ key }) => ({ x: key, y: 12 }))
    : [];
  const [value, setValue] = useState({});

  const rememberValue = (val) => {
    if (val.x !== undefined && val.x !== value.x) {
      const piiValue = allPII.find(({ x }) => x === val.x).y;
      const unprotectedValue = unprotected.find(({ x }) => x === val.x).y;
      setValue({ ...val, piiVal: piiValue, unprotectedVal: unprotectedValue });
    }
  };

  const forgetValue = async () => setValue({});

  return (
    <Box className={classes.chartContainer} style={style}>
      {data.length ? (
        <FlexibleXYPlot
          yDomain={[0, 100]}
          margin={{ left: 60 }}
          strokeStyle="solid"
          xType="ordinal"
          onMouseLeave={forgetValue}
        >
          <LineMarkSeries
            data={allPII}
            lineStyle={styles.line}
            strokeWidth={2}
            color={"#219653"}
            fill={"#219653"}
            size={4}
            onNearestXY={rememberValue}
          />
          <LineMarkSeries
            data={unprotected}
            lineStyle={styles.line}
            strokeWidth={2}
            fill={"#EB5757"}
            color={"#EB5757"}
            size={4}
            onNearestXY={rememberValue}
          />
          <LineSeries
            data={threshold}
            style={styles.line}
            strokeWidth={1}
            color={"#8c1616"}
            strokeStyle="dashed"
          />
          <VerticalGridLines style={styles.gridLine} />
          <HorizontalGridLines style={styles.gridLine} />
          <XAxis
            tickFormat={(val) => (data[val] ? data[val].date : "00/00")}
            style={styles.axis}
          />
          <YAxis style={styles.axis} />
          {!isEmpty(value) && (
            <Hint value={value}>
              <Box className={classes.hintContainer}>
                <Box className={classes.refContainer}>
                  <Box
                    style={{ backgroundColor: "#219653" }}
                    className={classes.dot}
                  />
                  <Typography className={classes.hintText}>
                    {formatValue(value.piiVal)}
                  </Typography>
                </Box>
                <Box className={classes.refContainer}>
                  <Box
                    style={{ backgroundColor: "#EB5757" }}
                    className={classes.dot}
                  />
                  <Typography className={classes.hintText}>
                    {formatValue(value.unprotectedVal)}
                  </Typography>
                </Box>
              </Box>
            </Hint>
          )}
        </FlexibleXYPlot>
      ) : (
        <LinearProgress style={{ width: "75%" }} />
      )}
    </Box>
  );
};

export default ScansTrend;
