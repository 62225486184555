import RepositoryFilter from "./components/RepositoryFilter";
import { Box } from "@material-ui/core";

export const RepositoryScannerView = () => {
  return (
    <Box>
      <RepositoryFilter />
    </Box>
  );
};
