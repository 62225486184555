import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { Box } from "@material-ui/core";

// import Form from 'components/FormComponents/Form';
import { FormTextInput } from "components/SettingsSections/components/inputs";
import SectionWrapper from "components/SettingsSections/components/sectionWrapper";
import useStyles from "./styles";

const BillingAddress = ({ data, errors, loading, disabled }) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({});
  const { payment_method } = data;

  useEffect(() => {
    if (payment_method) {
      const {
        billing_details: { address },
      } = payment_method;
      setInitialValues(address);
    }
  }, [payment_method]);

  return (
    <SectionWrapper
      title="Billing Address"
      subtitle="Add, edit or remove your payment information."
    >
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Field
            name="address.city"
            component={FormTextInput}
            label="City"
            className={classes.fieldSm}
            initialValue={initialValues.city}
            disabled={disabled}
          />
          <Field
            name="address.country"
            label="Country"
            component={FormTextInput}
            className={classes.fieldSm}
            initialValue={initialValues.country}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.row}>
          <Field
            name="address.line1"
            component={FormTextInput}
            label="Line 1"
            className={classes.fieldSm}
            initialValue={initialValues.line1}
            disabled={disabled}
          />
          <Field
            name="address.line2"
            component={FormTextInput}
            label="Line 2"
            className={classes.fieldSm}
            initialValue={initialValues.line2}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.row}>
          <Field
            name="address.postal_code"
            component={FormTextInput}
            label="Postal Code"
            className={classes.fieldSm}
            initialValue={initialValues.postal_code}
            disabled={disabled}
          />
        </Box>
      </Box>
    </SectionWrapper>
  );
};

export default BillingAddress;
