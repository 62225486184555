import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  tableWrapper: {
    overflow: "auto",
    width: "100%",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
    height: "100%",
    "& .MuiTable-stickyHeader": {
      "& thead tr th": {
        border: 0,
      },
      "& tbody tr:nth-child(even)": {
        backgroundColor: "rgba(224, 224, 224, 0.2)",
        "&:hover": {
          backgroundColor: "rgba(224, 224, 224, 0.5)",
        },
      },
      "& tbody tr:nth-child(odd)": {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "rgba(224, 224, 224, 0.5)",
        },
      },
    },
  },
  componentTitle: {
    color: "#333",
    fontSize: "18px",
    fontWeight: "600",
    letterSpacing: "2px",
    marginBottom: "16px",
    textTransform: "uppercase",
  },
  InfoOutlinedScan: {
    marginLeft: "8px",
    padding: "0",
    "&>span>svg": {
      fill: "#7090d9",
    },
  },
  row: {
    cursor: "pointer",
    "&>td": {
      fontFamily: "Roboto Condensed",
      fontSize: "14px",
      fontColor: theme.palette.grey[800],
    },
  },
  selected: {
    backgroundColor: `${theme.palette.blue[500]} !important`,
    "&:hover": {
      backgroundColor: theme.palette.blue[600],
      "&>td": {
        backgroundColor: theme.palette.blue[500],
      },
    },
    "&>td": {
      color: "white",
      fontWeight: "bold",
    },
  },
}));
