import React from "react";
import Icons from "assets/svgs-icons/icons";
import get from "lodash.get";

const isComponent = (el) => typeof el === "function";

const SvgIcon = ({ icon, className, source, ...props }) => {
  const Component = get(source ? source : Icons, icon);

  return isComponent(Component) ? (
    <Component className={className} {...props} />
  ) : (
    <img className={className} alt={icon} src={Component || icon} />
  );
};

export default SvgIcon;
