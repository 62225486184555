import React from "react";

const facebookIcon = (props) =>
  props.outlined ? (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2ZM4 4V20H20V4H4ZM11.1331 12.0044H13V18H15V12.0044H16.9824V10.0044H15V9C15 8.44772 15.4477 8 16 8H17V6H16C14.3431 6 13 7.34315 13 9V10.0044H11.1331V12.0044Z"
        fill="#3F51B5"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 0.708333V16.2917C18 16.5732 17.5549 16.9936 17.25 17H12.75V10.625H15V7.79167H12.75V6.375C12.4226 5.46497 12.6384 4.96178 13.5 4.95833H15V2.83333C14.9584 2.63694 14.1087 2.56688 12.75 2.83333C11.0333 2.56688 9.61709 3.76433 9.75 5.66667V7.79167H7.5V10.625H9.75V17H0.75C0.445111 17 0 16.5796 0 16.2917V0.708333C0 0.420382 0.445111 0 0.75 0H17.25C17.5549 0 18 0.414013 18 0.708333Z"
        fill="#3F51B5"
      />
    </svg>
  );

export default facebookIcon;
