import React from "react";
import isNil from "lodash.isnil";
import useStyles from "./styles";
import { Box, Typography } from "@material-ui/core";
import EmptyScan from "assets/illustrations/emptyscan.svg";
import RequestInformation from "components/Requests/Manage/RequestDetails/components/RequestInformation";
import RequestMessages from "components/Requests/Manage/RequestDetails/components/RequestMessages";
import RequestActions from "components/Requests/Manage/RequestDetails/components/RequestActions";
import RequestButtons from "components/Requests/Manage/RequestDetails/components/RequestButtons";

const RequestDetails = ({ selectedRow }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {!isNil(selectedRow) ? (
        <Box className={classes.content}>
          <RequestInformation selectedRow={selectedRow} />
          <RequestMessages selectedRow={selectedRow} />
          <RequestActions selectedRow={selectedRow} />
          <RequestButtons selectedRow={selectedRow} />
        </Box>
      ) : (
        <Box className={classes.emptyContainer}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.emptyTitle}>
              Select a Request
            </Typography>
            <Typography className={classes.emptySubtitle}>
              To see detailed information about it.
            </Typography>
          </Box>
          <Box className={classes.imageContainer}>
            <img src={EmptyScan} alt="" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RequestDetails;
