import { Text, View, Image } from "@react-pdf/renderer";

const GeographicPage = ({ styles, chart }) => (
  <View style={styles.section}>
    <View>
      <Text style={styles.title}>Geographic location of your users</Text>
      <Text style={styles.paragraph}>
        Check the geographic location of your users to guide which regulations
        might apply to your business.
      </Text>
    </View>
    <View>
      <Image src={chart} />
    </View>
  </View>
);

export default GeographicPage;
