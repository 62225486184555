import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import ProgressBar from "./ProgressBar";
import Checklist from "./Checklist";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "40px",
    color: theme.palette.blue[500],
    textTransform: "uppercase",
    marginTop: theme.spacing(2),
  },
}));

const ContentBox = ({ title, tasks = [], icon, loading }) => {
  const classes = useStyles();
  const progress =
    tasks.length > 0
      ? Math.round(
          (tasks.reduce((prev, curr) => (curr.done ? prev + 1 : prev), 0) /
            tasks.length) *
            100
        )
      : 0;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth={320}
      flexGrow={1}
      margin="auto"
    >
      {icon && <img src={icon} alt="" />}
      <Typography className={classes.title}>{title}</Typography>
      <Box mt={3} mb={5} textAlign="center" alignSelf="stretch">
        <ProgressBar progress={progress} />
      </Box>
      <Checklist loading={loading} tasks={tasks} />
    </Box>
  );
};

export default ContentBox;
