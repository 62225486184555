import React from "react";
import RequestsWrapper from "components/Requests/Widget/components/Wrapper";
import TextInput from "./components/TextInput";
import { Field, Form } from "react-final-form";
import ColorsInput from "components/Consents/Collector/Configuration/components/ColorsInput";
import {
  getRequestsConfig,
  getRequestsConfigLoadingState,
} from "redux/_requests/_config/config.selectors";
import { connect } from "react-redux";
import { updateConfig } from "redux/_requests/_config/config.async.actions";

const Configuration = ({ data, loading, update }) => {
  const handleUpdate = async (values) => {
    if (!update) return;
    try {
      await update(values, data.id);
    } catch (e) {
      return e;
    }
  };

  const identity = (value) => value;

  return (
    <RequestsWrapper
      primaryText="Complete the following fields to set up the Widget. In the right side there is a preview of how it will look like on your website."
      secondaryText="All fields are required."
      showAddButton={false}
      loading={loading}
      previewPage="home"
      previewData={data}
    >
      <Form
        onSubmit={handleUpdate}
        initialValues={data}
        render={({ handleSubmit }) => (
          <form>
            <Field
              name="title"
              component={TextInput}
              label="Title"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            <Field
              name="intro_text"
              component={TextInput}
              label="Intro Text"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            <Field
              name="domain"
              component={TextInput}
              label="Domain"
              loading={loading}
              onSubmit={handleSubmit}
              parse={identity}
            />
            <ColorsInput
              label="Colors"
              loading={loading}
              onSubmit={handleSubmit}
            />
            {/**
             <Field
             name="languages"
             component={LanguagesInput}
             label="Languages"
             loading={loading}
             />
             */}
          </form>
        )}
      />
    </RequestsWrapper>
  );
};

const mapStateToProps = (state) => ({
  data: getRequestsConfig(state),
  loading: getRequestsConfigLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  update: (data, id) => dispatch(updateConfig(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
