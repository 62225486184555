import React from "react";

const ArrowRight = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / arrows / arrow-right">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5858 13.0001H3V11.0001H17.5858L11.2929 4.70718L12.7071 3.29297L21.4142 12.0001L12.7071 20.7072L11.2929 19.293L17.5858 13.0001Z"
        fill="#3F51B5"
      />
    </g>
  </svg>
);

export default ArrowRight;
