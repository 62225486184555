import React, { useEffect } from "react";
import { connect } from "react-redux";
import Linechart from "charts/Consents/Linechart";
import Box from "@material-ui/core/Box";
import Loader from "components/Loader";
import { getTimelineChartData } from "redux/_consents/_timeline/timeline.async.actions";
import {
  getConsentsTimelineData,
  getTimelineLoadingState,
} from "redux/_consents/_timeline/timeline.selectors";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { getFilters } from "redux/_filters/filters.selectors";
import { data as mockedData } from "./mocked.json";
import isEmpty from "lodash.isempty";
import EmptyOverlay from "components/EmptyOverlay";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "calc(100% - 82px)!important",
  },
  text: {
    margin: "1rem 0",
  },
});

const Timeline = ({ data, loading, getData, filters, ...props }) => {
  const colors = ["#6bd4cb", "#2a94f4", "#e25c1d", "#1a3586", "#a720C4"];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const classes = useStyles();

  const labels = {
    viewed_form: "VIEWED FORM",
    full_consent: "FULL CONSENT",
    any_consent: "ANY CONSENT",
    full_unconsent: "FULL UNCONSENT",
    no_action: "NO ACTION",
  };

  if (!data) return null;
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        Evolution of consents over time.
      </Typography>

      <Box display="flex" alignItems="center" width="100%" height="100%">
        {loading ? (
          <Loader />
        ) : (
          <EmptyOverlay
            text="No Consents collected."
            link="/zen/consents/collector"
            show={isEmpty(data)}
          >
            <Box height="80%">
              <Linechart
                data={!isEmpty(data) ? data : mockedData}
                colors={colors}
                labels={labels}
              />
            </Box>
          </EmptyOverlay>
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsTimelineData(state),
  loading: getTimelineLoadingState(state),
  filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getTimelineChartData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
