import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import useStyles from "./styles";
import cyberSecurity from "assets/images/cyber-security.jpg";
import { useHistory } from "react-router-dom";
import { CloudQueue } from "@material-ui/icons";

export const RepositoryScannerCard = ({
  cardImage = cyberSecurity,
  name,
  scan,
  deleteOnClick,
}) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Grid key={1} item xs={12} sm={4}>
      <Card className={classes.card} elevation={0}>
        <a href={`/respository-results?r=${scan}`}>
          <CardMedia className={classes.media} image={cardImage} />
        </a>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              columnGap: "4px",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <CloudQueue style={{ fill: "#a4a3a3" }} />
            <span
              style={{
                fontSize: "0.625rem",
                color: "#a4a3a3",
              }}
            >
              Repository
            </span>
          </div>

          <Typography
            className={classes.title}
            gutterBottom
            variant="h5"
            component="h4"
          >
            {name}
          </Typography>
          <div className={classes.footerCard}>
            <div onClick={() => history.push(`/respository-results?r=${scan}`)}>
              <Typography className={classes.option}>See results</Typography>
            </div>

            <Typography
              className={classes.option}
              onClick={() => deleteOnClick(scan)}
            >
              Delete
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
