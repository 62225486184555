import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import PageInfo from "components/page-info/page-info.coontainer";
import { selectEnv } from "redux/_environments/environment.actions";
import { getOverview } from "redux/_scans/scans.async.actions";
import getProperty from "utils/get-property";
import { Box } from "@material-ui/core";
import EnvironmentHeader from "components/EnvironmentHeader";
import PiiTrends from "components/PiiTrends";
import PiiCategories from "components/PiiCategories";

const DEFAULT_SCANS = 8;

function EnvironmentPage({
  dispatch,
  scans,
  initialized,
  isNewSearch,
  loading,
  from,
  to,
  envId,
}) {
  useEffect(() => {
    dispatch(selectEnv(envId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (!initialized || (isNewSearch && !loading)) {
      // dispatch(getOverview({envId, pageSize: DEFAULT_SCANS, sort: '-ended_at', from, to}));
      dispatch(
        getOverview({ envId, last: DEFAULT_SCANS, from, to, completed: 1 })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewSearch, initialized]);
  return (
    <React.Fragment>
      <PageInfo
        date={new Date()}
        onExport={() => alert("Export Main Dashoboard to pdf report")}
        onShare={() => alert("Share link or pdf")}
        style={{ padding: "16px 0px" }}
      />
      <Box
        style={{
          width: "100%",
          height: "calc(100% - 41px)",
          backgroundColor: "white",
        }}
      >
        <EnvironmentHeader />
        <Box
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100% - 148px)",
            flexDirection: "column",
            padding: "24px 32px",
          }}
        >
          <PiiTrends scans={scans} />
          <PiiCategories scans={scans} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = (state, { location, match }) => {
  const envId = match.params.env_id;
  const scansHash = getProperty(state, `scansOverview.${envId}.scansHash`);
  const initialized = !!scansHash;

  const { from: storedFrom, to: storedTo } =
    getProperty(state, `scansOverview.${envId}`) || {};
  const { from, to } = queryString.parse(location.search);

  let isNewSearch = false;
  if (!!from && !!to) {
    isNewSearch = storedFrom !== from || storedTo !== to;
  }

  return {
    envScore: getProperty(state, `environments.environmentList.${envId}.score`),
    loading: getProperty(state, `scansOverview.${envId}.loading`),
    envId,
    initialized,
    isNewSearch,
    from,
    to,
    scans: Object.values(scansHash || {}),
  };
};

export default connect(mapStateToProps)(EnvironmentPage);
