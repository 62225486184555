import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Button } from "@material-ui/core";

import { PLAN_IDS } from "constants/plans";
import { getAccountData } from "redux/_account/account.selectors";
// TODO: add hardcoded data to plan metadata on Chargebee
// import { retrievePlans } from "redux/_register/_register.async.actions";
// import { getPlanList } from "redux/_register/_register.selectors";

import useStyles from "../styles";

const PLAN_TYPES = {
  starter: "starter",
  // lite: "lite",
  pro: "pro",
};

const COMMON_PLAN_DATA = {
  //[PLAN_TYPES.starter]: {
  //  type: PLAN_TYPES.lite,
  //  name: "Zendata Starter",
  //  description:
  //    "Generate 4 reports per month and stay protected from the latest privacy vulnerabilities.",
  //  features: [
  //    "Combination of one URL/app",
  //    "One-time scan (per asset across all jurisdictions)",
  //  ],
  //  cta: "Buy starter",
  //},
  // [PLAN_TYPES.lite]: {
  //   type: PLAN_TYPES.lite,
  //   name: "Zendata Lite",
  //   description:
  //     "One time report of privacy and data risks associated with internet-facing assets.",
  //   features: [
  //     "Combination of one URL/app",
  //     "One-time scan (per asset across all jurisdictions)",
  //   ],
  //   cta: "Start 14-day free trial",
  //   downgradeCTA: "Downgrade to lite",
  // },
  [PLAN_TYPES.pro]: {
    highlight: true,
    type: PLAN_TYPES.pro,
    name: "Zendata Pro",
    description:
      "Continuously monitor and secure known and unknown internet-facing assets. Stay protected from the latest privacy vulnerabilities and mismatch between what you say in your policy, what the user sees, and what the law's mandate.",
    features: [
      "Combination of one URL/app",
      "Identify privacy issues in minutes rather than days or months",
      "Get critical findings with advanced NLP, Machine learning, crawling, and fuzzing",
      "Support across desktop/apps and user countries/jurisdictions",
    ],
    cta: "Start 14-day free trial",
    downgradeCTA: "Downgrade to pro",
  },
};

export const PLAN_CATEGORY = {
  monthly: "monthly",
  yearly: "yearly",
};

export const PLANS = [
  //{
  //  id: PLAN_IDS.starterMonthly,
  //  price: 149,
  //  ...COMMON_PLAN_DATA.starter,
  //  category: PLAN_CATEGORY.monthly,
  //},
  //{
  //  id: PLAN_IDS.starterYearly,
  //  price: 1788,
  //  ...COMMON_PLAN_DATA.starter,
  //  category: PLAN_CATEGORY.yearly,
  //},
  // {
  //   id: PLAN_IDS.liteMonthly,
  //   price: 295,
  //   ...COMMON_PLAN_DATA.lite,
  //   category: PLAN_CATEGORY.monthly,
  // },
  // {
  //   id: PLAN_IDS.liteYearly,
  //   price: 2950,
  //   ...COMMON_PLAN_DATA.lite,
  //   category: PLAN_CATEGORY.yearly,
  //  },
  {
    price: 149,
    id: PLAN_IDS.proMonthly,
    ...COMMON_PLAN_DATA.pro,
    category: PLAN_CATEGORY.monthly,
  },
  {
    price: 1490,
    id: PLAN_IDS.proYearly,
    ...COMMON_PLAN_DATA.pro,
    category: PLAN_CATEGORY.yearly,
  },
];

const Plan = ({
  selected,
  className,
  price,
  currentPlanPrice,
  name,
  description,
  features,
  cta,
  downgradeCTA,
  highlight = false,
  onClick,
}) => {
  return (
    <div className={clsx(className, highlight && "-highlight")}>
      <div className="summary">
        <div className="price">
          ${price.toLocaleString("en-US")}
          <span>/{selected === PLAN_CATEGORY.monthly ? "month" : "year"}</span>
        </div>
        <div className="title">{name}</div>
        <div className="description">{description}</div>
        <Button onClick={onClick}>{cta}</Button>
      </div>
      <ul className={clsx("features", highlight && "-highlight")}>
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

const Plans = ({
  account,
  getPlans,
  setSelectedPlan,
  selectedPlanCategory,
  children,
  currentPlan = undefined,
}) => {
  const classes = useStyles({ showAll: true });
  return (
    <section className={classes.plans}>
      {children}
      {PLANS.filter((plan) => plan.category === selectedPlanCategory).map(
        (plan) => (
          <Plan
            key={plan.id}
            price={plan.price}
            selected={selectedPlanCategory}
            currentPlanPrice={account.current_plan_price}
            className={classes.plan}
            onClick={setSelectedPlan(plan)}
            {...plan}
          />
        )
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  // receivedPlans: getPlanList(state),
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  // getPlans: () => dispatch(retrievePlans()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
