import { useState } from "react";
import axios from "axios";
import { authHeader } from "helpers/auth-header";
import config from "config";

export const useFetchRepositoryDetailsById = () => {
  const [data, setData] = useState(null);
  const [branchSummary, setBranchSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  const fetchData = (repositoryId) => {
    setLoading(true);
    axios
      .get(`${config.apiUrl}/repositories/${repositoryId}/`, getHeadersConfig())
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.error(err.response);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBranchSummary = (branchId) => {
    setLoading(true);
    axios
      .get(`${config.apiUrl}/branches/${branchId}/summary/`, getHeadersConfig())
      .then((response) => {
        setBranchSummary(response.data);
      })
      .catch((err) => {
        console.error(err.response);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, branchSummary, loading, error, fetchData, fetchBranchSummary };
};
