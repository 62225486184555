import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { useLayoutEffect } from "react";
import useStyles from "./styles";

const CONTAINER_ID = "trafficSourcesId";

const TrafficSources = ({ traffic: chartData, setGeographicChartRaw }) => {
  const classes = useStyles();

  useLayoutEffect(() => {
    if (chartData) {
      const chart = am4core.create(CONTAINER_ID, am4charts.TreeMap);
      chart.data = chartData;

      chart.colors.step = 2;

      chart.dataFields.value = "value";
      chart.dataFields.name = "name";
      chart.dataFields.children = "children";

      const level1 = chart.seriesTemplates.create("0");
      const level2 = chart.seriesTemplates.create("1");

      const level1_column = level1.columns.template;
      const level2_column = level2.columns.template;

      const level2_bullet = level2.bullets.push(new am4charts.LabelBullet());

      level1_column.fillOpacity = 0;
      level1_column.strokeOpacity = 0;

      level2_column.fillOpacity = 0.8;
      level2_column.stroke = am4core.color("#fff");
      level2_column.tooltipText = "{parentName} {name}: {percentage}%";

      level2_bullet.locationY = 0.5;
      level2_bullet.locationX = 0.5;
      level2_bullet.label.text = "{code}";
      level2_bullet.label.fill = am4core.color("#fff");

      setGeographicChartRaw(chart.exporting.getImage("png"));

      return () => {
        chart.dispose();
      };
    }
  }, [chartData]);

  return (
    <>
      {chartData.length ? (
        <>
          <div className={classes.chartContainer} id={CONTAINER_ID} />
          <figcaption className={classes.caption}>
            *This data represents the traffic of the last month
          </figcaption>
        </>
      ) : (
        <div className={classes.notFound}>
          <h3 className={classes.title}>Sorry!</h3>
          <p className={classes.paragraph}>
            We couldn't find enough information to estimate your site's traffic
            sources.
          </p>
        </div>
      )}
    </>
  );
};

export default TrafficSources;
