import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";

import {
  createEnvironment,
  updateEnvironment,
} from "redux/_wizard/wizard.async.actions";
import useStyles from "./styles";
import TextInput from "components/Inputs/Text";

const Step2 = (props) => {
  const { nextCallback, loading, environment, dispatch } = props;
  const classes = useStyles();
  const [name, setName] = useState("");
  const [unprotected, setUnprotected] = useState("");

  useEffect(() => {
    setName(environment.name);
    setUnprotected(environment.unprotected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment]);

  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>Create Environment</Typography>
        <Typography className={classes.subtitle}>
          An Environment is a group of Databases that are related to each other.
          Let’s give it a name.
        </Typography>
      </Box>
      <Box className={classes.rightContainer}>
        <TextInput
          label="Environment name"
          style={{ maxWidth: "400px", paddingBottom: "16px" }}
          helperText="Try to be as descriptive as possible"
          value={name}
          onChange={setName}
          disabled={loading}
        />
        <TextInput
          label="% of unprotected Piis"
          helperText="Define maximum threshold %"
          style={{ maxWidth: "208px" }}
          value={unprotected}
          onChange={setUnprotected}
          disabled={loading}
        />
      </Box>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={() => {
          if (environment.id) {
            dispatch(updateEnvironment(environment.id, { name, unprotected }))
              .then(() => nextCallback())
              .catch();
          } else {
            dispatch(createEnvironment({ name, unprotected }))
              .then(() => nextCallback())
              .catch();
          }
        }}
        disabled={loading}
      >
        NEXT
      </Button>
    </Box>
  );
};

const mapStateToProps = ({ wizard: { loading, environment } }) => ({
  loading,
  environment,
});

export default connect(mapStateToProps)(Step2);
