export const getAdminUsersData = (state) => state.admin.users.data;

export const getAdminUsersLoadingState = (state) =>
  state.admin.users.loading.fetch;

export const getUserCreationLoadingState = (state) =>
  state.admin.users.loading.create;

export const getUserUpdateLoadingState = (state, id) =>
  state.admin.users.loading.keys.indexOf(id) !== -1;
