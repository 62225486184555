import { Box, CircularProgress } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import React from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { simpleFormStyle as useStyles } from "./styles";
import Button from "components/Button";
import TextInput from "components/Inputs/Text";

const RegisterForm = ({ onSubmit, loading }) => {
  const classes = useStyles();

  const validate = (values) => {
    const errors = {};
    if (!values.email) errors.email = "Required";
    if (!values.password1) errors.password1 = "Required";
    if (!values.password2) errors.password2 = "Required";
    else if (values.password1 !== values.password2) {
      errors.password2 = "Passwords do not match.";
    }
    return errors;
  };
  return (
    <>
      <Form onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, submitError }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.field}>
              <Field name="email">
                {({ input, meta }) => (
                  <div>
                    <TextInput
                      {...input}
                      label="Email"
                      type="text"
                      disabled={loading}
                      placeholder="samplemail@mail.com"
                      helperText={
                        (meta.error || meta.submitError) && meta.touched
                          ? meta.error
                          : ""
                      }
                      error={(meta.error || meta.submitError) && meta.touched}
                    />
                  </div>
                )}
              </Field>
            </Box>
            <Box className={classes.field}>
              <Field name="password1">
                {({ input, meta }) => (
                  <div>
                    <TextInput
                      {...input}
                      type="password"
                      label="Password"
                      placeholder="********"
                      disabled={loading}
                      helperText={
                        (meta.error || meta.submitError) && meta.touched
                          ? meta.error
                          : ""
                      }
                      error={(meta.error || meta.submitError) && meta.touched}
                    />
                  </div>
                )}
              </Field>
            </Box>
            <Box className={classes.field}>
              <Field name="password2">
                {({ input, meta }) => (
                  <div>
                    <TextInput
                      {...input}
                      type="password"
                      label="Confirm Password"
                      placeholder="********"
                      disabled={loading}
                      helperText={
                        (meta.error || meta.submitError) && meta.touched
                          ? meta.error
                          : ""
                      }
                      error={(meta.error || meta.submitError) && meta.touched}
                    />
                  </div>
                )}
              </Field>
            </Box>
            {submitError && <div className={classes.error}>{submitError}</div>}
            <Button disabled={loading} className={classes.button} type="submit">
              Sign Up
              {loading && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </form>
        )}
      </Form>
      <p className={classes.actionLink}>
        Don't have an account?&nbsp;
        <Link to={"/extension/login"} className={classes.actionLinkAnchor}>
          <strong>Log In</strong>
        </Link>
        <ArrowForward className={classes.actionLinkIcon} />
      </p>
    </>
  );
};

export default RegisterForm;
