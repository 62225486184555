import { SECTION_NAME } from "../constants";

export const isHeaderHidden = (location) => {
  return (
    location.hash === `#${SECTION_NAME.complete}` ||
    location.hash === `#${SECTION_NAME.freeReportPitch}`
  );
};

export const extractLastPartFromUrl = (url) => {
  const parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  lastPart = lastPart.replace(".pdf", "");
  return lastPart;
};

export const mapSectionNameToId = (name) => {
  return name.charAt(0).toLowerCase() + name.slice(1);
};
