import { useState } from "react";
import axios from "axios";
import { authHeader } from "helpers/auth-header";
import config from "config";

export const useRunNewScanByBranch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  const fetchData = (branchId) => {
    setLoading(true);
    axios
      .post(
        `${config.apiUrl}/repo-scans/`,
        { branch: branchId },
        getHeadersConfig()
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.error(err.response);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetData = () => {
    setData(null);
  };

  return { data, loading, error, fetchData, resetData };
};
