import { makeStyles } from "@material-ui/core";

class Level extends String {
  constructor(value, name) {
    super(name);
    this.value = value;
  }
}

export const levels = {
  LOW: new Level(0, "low"),
  MEDIUM: new Level(1, "medium"),
  HIGH: new Level(2, "high"),
};

export default makeStyles(
  (theme) => ({
    el: {
      border: `solid 1px  ${theme.palette.grey.bluish}`,
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "-1px",
      padding: theme.spacing(1.5, 0),
    },
    [`i-${levels.LOW}`]: {
      border: `solid 2px ${theme.palette.grey[100]}`,
      display: "block",
      height: "11px",
      width: "11px",
      borderRadius: "5px",
      margin: theme.spacing(0.6, "auto"),
    },
    [`i-${levels.MEDIUM}`]: {
      backgroundColor: theme.palette.orange[700],
      display: "block",
      height: "10px",
      width: "10px",
      margin: theme.spacing(0.6, "auto"),
    },
    [`i-${levels.HIGH}`]: {
      display: "block",
      margin: theme.spacing(0.6, "auto"),
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderBottom: `11px solid ${theme.palette.red[500]}`,
    },
    iconBox: {
      width: "30px",
    },
    messageBox: {
      paddingTop: 2,
      width: "calc(100% - 60px)",
      color: theme.palette.grey[800],
    },
    chevron: {
      margin: theme.spacing(0, "auto"),
      fontSize: "2em",
      opacity: "0.3",
      marginTop: theme.spacing(-1),
      transition: "transform 0.5s",
    },
    upChevron: {
      transform: "rotateX(180deg)",
    },
    details: {
      maxHeight: 0,
      transition: "max-height 0.3s ease-in-out;",
      overflow: "hidden",
    },
    content: {
      display: "block",
      color: theme.palette.grey[800],
      padding: theme.spacing(1.5, 0, 3, 1),
      overflowY: "auto",
    },
    expanded: {
      maxHeight: "600px",
    },
    tipList: {
      listStyle: "none",
      padding: theme.spacing(0, 0, 0, 2),
    },
    tipEl: {
      padding: theme.spacing(0.5, 0),
      display: "flex",
    },
    liBox: {
      flex: 1,
    },
    liTitle: {
      marginLeft: theme.spacing(-2),
      marginBottom: "3px",
      opacity: 0.6,
    },
    scoreBar: {
      margin: theme.spacing(0, 0, 0, "auto"),
      height: "10px",
      backgroundColor: theme.palette.blue[100],
    },
    underline: {
      textDecoration: "underline",
    },
    cap: {
      textTransform: "capitalize",
    },
    empty: {
      textAlign: "center",
      marginTop: 60,
      fontSize: 16,
      color: theme.palette.grey[800],
    },
  }),
  { name: "TestScores" }
);
