import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    borderTop: `solid 1px ${theme.palette.grey[500]}`,
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    padding: "16px 0px",
  },
  requestContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grey[300],
    paddingRight: theme.spacing(2),
    width: "86px",
    flexShrink: 0,
  },
  requestElement: {
    fontFamily: "Roboto Condensed",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.blue[600],
    borderRadius: "30px",
    backgroundColor: theme.palette.blue[100],
    padding: "5px 20px",
    marginRight: theme.spacing(2),
    lineHeight: "14px",
  },
  messageContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
  },
  messageValue: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.grey[200],
  },
}));
