import { CONSTANTS } from "redux/_consents/_spotlight/spotlight.constants";

export const requestStart = (dataType) => ({
  type: CONSTANTS.SPOTLIGHT_REQUEST_START,
  dataType,
});

export const requestEnd = (dataType) => ({
  type: CONSTANTS.SPOTLIGHT_REQUEST_END,
  dataType,
});

export const saveData = (dataType, data) => ({
  type: CONSTANTS.SPOTLIGHT_SAVE_DATA,
  dataType,
  data,
});
