import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  mainContainer: {
    height: "calc(90% - 2rem)",
    margin: "1rem 0",
  },
  chartsContainer: {
    margin: "1rem 0 2rem",
    [theme.breakpoints.up("lg")]: {
      height: 160,
    },
  },
  radialchartContainer: {
    height: 160,
  },
  linechartContainer: {
    height: 200,
    "& > div": {
      alignItems: "flex-start",
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
      },
    },
  },
  title: {
    fontWeight: 600,
    fontFamily: "Roboto Condensed",
    letterSpacing: "1px",
    color: theme.palette.grey[200],
    textTransform: "uppercase",
  },
}));
