import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { connect } from "react-redux";

import ContentBox from "components/SettingsSections/InitialSetup/components/ContentBox";
import Switch from "components/Inputs/Switch";

import {
  getInitialSetupData,
  setShowStatusBar,
} from "redux/_settings/settings.async.actions";
import {
  getInitialSetup,
  getInitialSetupLoadingState,
} from "redux/_settings/settings.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";

import useStyles from "./styles";
import dataDiscoveryIcon from "assets/images/data-discovery.svg";
import consentManagerIcon from "assets/images/consent-manager.svg";
import requestManagerIcon from "assets/images/request-manager.svg";

const InitialSetup = ({
  getData,
  data = {},
  loading,
  setShowStatusBar,
  selectedEnv,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleShowStatusBar = (show) => {
    setShowStatusBar(!show);
  };

  const { show_initial_setup: showInitialSetup = false } = data;

  return (
    <Box py={5}>
      <Typography className={classes.title}>
        In order to make the best use of your Zendata account, you will need to
        set up each module first.
      </Typography>

      <Box px={5}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} className={classes.divider}>
            <ContentBox
              title="Data Discovery"
              icon={dataDiscoveryIcon}
              loading={loading}
              tasks={[
                {
                  name: "Add Datasource(s)",
                  done: data.datasources,
                  link: "/zen/settings/datasources",
                },
                {
                  name: "Add Database(s)",
                  done: data.databases,
                  link: "/zen/settings/databases",
                },
                {
                  name: "Create Environment(s)",
                  done: data.environments,
                  link: "/zen/settings/environments",
                },
                {
                  name: "Run a Scan",
                  link: selectedEnv
                    ? `/zen/environment/${selectedEnv}/scans`
                    : undefined,
                  done: data.scans,
                  linkTooltip: "Create an Environment first.",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.divider}>
            <ContentBox
              title="Consents Manager"
              icon={consentManagerIcon}
              loading={loading}
              tasks={[
                {
                  name: "Complete Configuration Fields",
                  done: data.consents_config,
                  link: "/zen/consents/collector/configuration",
                },
                {
                  name: "Embed Code",
                  done: data.consents_embed,
                  link: "/zen/consents/collector/embed-code",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContentBox
              title="Requests Manager"
              icon={requestManagerIcon}
              loading={loading}
              tasks={[
                {
                  name: "Complete Configuration Fields",
                  done: data.requests_config,
                  link: "/zen/requests/widget/configuration",
                },
                {
                  name: "Embed Code",
                  done: data.requests_embed,
                  link: "/zen/requests/widget/embed",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={8}>
        <Switch
          value={!Boolean(showInitialSetup)}
          onChange={handleShowStatusBar}
          labelPlacement="start"
          text="Do not show the Initial Setup progress bar in the Navigation."
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: getInitialSetup(state),
  loading: getInitialSetupLoadingState(state),
  selectedEnv: getSelectedEnvId(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getInitialSetupData(true)),
  setShowStatusBar: (show) => dispatch(setShowStatusBar(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitialSetup);
