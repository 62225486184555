import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {},
  formContainer: {
    gap: 16,
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  descriptionContainer: {
    display: "flex",
    width: "100%",
    maxWidth: 1022,
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 0, 4),
  },
  description: {
    // ...sectionDescriptionMixin(theme),
  },
  descriptionParagraph: {
    marginBottom: theme.spacing(2),
  },
  loginInfoFields: {
    marginBottom: theme.spacing(8),
  },
  legend: {
    ...theme.typography.body1,
    textAlign: "center",
    background: theme.palette.grey[400],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  popover: {
    maxWidth: 400,
    padding: theme.spacing(2),
    width: "80%",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "&.-with-margin": {
      margin: "20px 0",
    },
    "&.-countries": {
      margin: "20px 0 40px",
    },
  },
  label: {
    lineHeight: "42px",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      lineHeight: "inherit",
      padding: theme.spacing(1, 2, 0, 0),
    },
  },
  rightContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    gap: 5,
    [theme.breakpoints.up("sm")]: {
      width: 365,
      // width: 328,
    },
  },
  leftContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 135,
    },
  },
  genericField: {
    backgroundColor: "beige",
    "&>div>div": {
      height: 42,
    },
  },

  textField: {
    "& input": {
      paddingTop: 10,
    },
    "&.mini": {
      width: 40,
      marginRight: 10,
    },
    "&.ends": {
      width: 120,
      marginLeft: 40,
    },
    "& > .MuiFormHelperText-contained": {
      marginLeft: 0,
    },
  },
  checkbox: {
    margin: theme.spacing(1, 0, 2),
    color: theme.palette.grey[800],
    [theme.breakpoints.up("sm")]: {
      marginLeft: 123,
    },
  },
  selectField: {
    "& > .MuiSelect-select": {
      color: "rgba(0, 0, 0, 0.87)",
      paddingTop: 10,
    },
    "&.mini": {
      margin: "0 10px",
    },
    "& svg": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  week: {
    flexDirection: "row",
    marginLeft: "-4px",
    marginRight: "-4px",
    justifyContent: "space-between",
  },
  smallCheck: {
    padding: "4px",
  },
  day: {
    color: theme.palette.grey[800],
    backgroundColor: "#f0f0f0",
    width: 39,
    height: 39,
  },
  selectedDay: {
    color: "white",
    backgroundColor: theme.palette.blue[500],
  },

  submit: {
    marginTop: theme.spacing(4),
  },

  goBackButton: {
    marginTop: theme.spacing(2),
  },

  uploadButton: {
    width: "100%",
    backgroundColor: "#F0F0F0",
    "& span": {
      color: theme.palette.grey[800],
    },
  },
  endIcon: {
    position: "absolute",
    right: 16,
    top: 8,
  },
  button: {
    // height: "min-content",
    // padding: 0,
    backgroundColor: theme.palette.aquamarine[100],
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
  },
}));
