import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Title from "./title";
import isNil from "lodash.isnil";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    width: "100%",
    "&:nth-child(1) > *": {
      paddingTop: 0,
    },
  },
  leftContainer: {
    width: "30%",
    maxWidth: "272px",
    paddingRight: theme.spacing(4),
    borderRight: "1px solid #E0E0E0",
    paddingBottom: theme.spacing(6),
  },
  rightContainer: {
    width: "70%",
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
}));

export default ({ leftContent, title = null, subtitle = null, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        {(!isNil(title) || !isNil(subtitle)) && (
          <Title title={title} subtitle={subtitle} />
        )}
        {!isNil(leftContent) && leftContent}
      </Box>
      <Box className={classes.rightContainer}>{children}</Box>
    </Box>
  );
};
