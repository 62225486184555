import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  form: {
    position: "relative",
    zIndex: 0,
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));
