import { Hidden } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

import HomepageMenu from "components/HomepageMenu";
import Footer from "components/PublicComponents/Footer";
import PublicNav from "components/PublicComponents/Nav";
import ReportList from "components/PrivacyReports/ReportList";
import Seo, { pages } from "components/Seo";

import { getAccountData } from "redux/_account/account.selectors";

import useStyles from "./styles";

const PrivacyScanner = ({ account }) => {
  const classes = useStyles();
  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />

      <Hidden mdUp>
        <HomepageMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </Hidden>
      <PublicNav />
      <main className={classes.main}>
        <div className={classes.mainWrapper}>
          <ReportList account={account} showPagination={false} />
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

export default connect(mapStateToProps)(PrivacyScanner);
