import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { BranchScanSummary } from "./components/BranchScanSummary";
import { DataFlowChart } from "./components/DataFlowChart";
import { useFetchRepoScanDataFlowInfo } from "hooks/useFetchRepoScanDataFlowInfo";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPIIChartInfo } from "redux/_dataFlow/dataFlow.actions";
import { Loading } from "../components/Loading";

const NewRepositoryBranchFlowView = () => {
  const { scan_id } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    data,
    error,
    fetchScanDataFlow,
  } = useFetchRepoScanDataFlowInfo();

  useEffect(() => {
    fetchScanDataFlow(scan_id);
  }, []);

  if (data) {
    const chartInfo = {
      id: scan_id,
      created: data?.created,
      branchName: data?.branch_name,
      repository: data?.repository_url,
      sharing: data?.category_summary?.sharing,
      collection: data?.category_summary?.collection,
      destination: data?.category_summary?.destination,
      leakage: data?.leakage,
      mediumSeverity: data?.medium_severity,
      lowSeverity: data?.low_severity,
      resultsCSV: data?.results_csv,
      triggeredByWebhook: data?.triggered_by_webhook
    };

    dispatch(setPIIChartInfo({ chartInfo }));
  }

  const showError = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "24px",
          backgroundColor: "#F9F9FA",
          border: "1px solid red",
          padding: 12,
          borderRadius: 8,
          fontFamily: "Inter",
          color: "red",
        }}
      >
        <Typography
          style={{
            display: "flex",
            flex: 1,
            fontFamily: "Inter",
            fontSize: 14,
            color: "#FB2424",
          }}
        >
          {error?.response?.status}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        paddingTop: 24,
        paddingBottom: 24,
      }}
    >
      {loading ? (
        <Loading />
      ) : error ? (
        showError()
      ) : (
        <>
          <BranchScanSummary />
          <DataFlowChart />
        </>
      )}
    </Box>
  );
};

export default NewRepositoryBranchFlowView;
