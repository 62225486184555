import { CONSTANTS } from "redux/_consents/_map/map.constants";

export const requestStart = () => ({
  type: CONSTANTS.MAP_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.MAP_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.MAP_SAVE_DATA,
  data,
});

export const setError = () => ({
  type: CONSTANTS.MAP_SET_ERROR,
});
