import { createSelector } from "reselect";

const getScans = (state) => state.scans.data;

const getSelectedEnv = (state) => state.environments.selectedId;

export const getInProgressScan = (state) => state.scans.inProgress;

export const getScansByEnvId = createSelector(
  [getSelectedEnv, getScans],
  (envId, scans) =>
    scans.filter(({ environment_id }) => environment_id === envId)
);

export const getScanList = (state) => state.scans.scanList;

export const getScanById = (state, scan_id) =>
  getScanList(state).find((scan) => scan.id === scan_id);
