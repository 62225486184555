import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getData = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/requests/config`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data[0]);
};

export const updateData = (configData) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(configData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  //TODO remove when user is validated by token

  const url = `${config.apiUrl}/requests/config`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
