import React from "react";
import { Box, Button } from "@material-ui/core";
import Tooltip from "components/Tooltip";
import useStyles from "./styles";

const InputContainer = (props) => {
  const {
    children,
    label,
    buttonText,
    onClick,
    disableButton = false,
    showButton = true,
    customButton = null,
    error,
    tooltip,
  } = props;
  const classes = useStyles();
  return (
    <Box mb={3}>
      <Box mb={1} className={classes.container}>
        {tooltip ? (
          <Tooltip title={tooltip} placement="right">
            <label className={`${classes.label} input-label`}>{label}</label>
          </Tooltip>
        ) : (
          <label className={`${classes.label} input-label`}>{label}</label>
        )}
        {children}
        {showButton &&
          (customButton ? (
            React.cloneElement(customButton)
          ) : (
            <Button
              className={classes.button}
              variant="text"
              disabled={disableButton}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          ))}
      </Box>
      {Boolean(error) && <span className={classes.error}>{error}</span>}
    </Box>
  );
};

export default InputContainer;
