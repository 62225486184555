import { createSelector } from "reselect";
import isEmpty from "lodash.isempty";

export const getConsentsConfig = (state) => state.consents.config.data;

export const getConsentsConfigLoadingState = (state) =>
  state.consents.config.loading;

const getLanguages = createSelector([getConsentsConfig], (config) =>
  isEmpty(config) ? [] : config.languages
);

export const getConsentsLanguages = createSelector(
  [getLanguages],
  (languages) =>
    isEmpty(languages)
      ? []
      : languages.sort((el1, el2) => (el1 < el2 ? -1 : el1 > el2 ? 1 : 0))
);
