import { getData } from "redux/_consents/_map/map.service";
import {
  requestStart,
  requestEnd,
  saveData,
  setError,
} from "redux/_consents/_map/map.actions";
import { getFilters } from "redux/_charts/charts.selectors";

export const getMapChartData = (disableFilters) => (dispatch, getState) => {
  const state = getState();
  const filters = getFilters(state);
  dispatch(requestStart());
  return getData(disableFilters ? {} : filters)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(setError());
      dispatch(requestEnd());
      return Promise.reject();
    });
};
