export default {
  FILTERS_REQUEST_START: "FILTERS_REQUEST_START",
  FILTERS_REQUEST_END: "FILTERS_REQUEST_END",
  FILTERS_SAVE_DATA: "FILTERS_SAVE_DATA",
  FILTERS_SELECT_DATA: "FILTERS_SELECT_DATA",
  FILTERS_RESET_ALL: "FILTERS_RESET_ALL",
  TYPES: {
    ENV: "ENV",
    SCAN: "SCAN",
    LAST_SCANS: "LAST_SCANS",
    ENTITY_ROLE: "ENTITY_ROLE",
    STORAGE: "STORAGE",
    PURPOSE: "PURPOSE",
    BUSINESS_UNIT: "BUSINESS_UNIT",
    CUSTOMER_REGION: "CUSTOMER_REGION",
    COUNTRY: "COUNTRY",
    PII_CATEGORIES: "PII_CATEGORIES",
    CONSENTS: {
      CUSTOMER_VISIBILITY: "CONSENTS_CUSTOMER_VISIBILITY",
      CATEGORY: "CONSENTS_CATEGORY",
      DECISION: "CONSENTS_DECISION",
      DATE_RANGE: "CONSENTS_DATE_RANGE",
      UNCONSENTS: "CONSENTS_UNCONSENTS",
    },
    SCANS: {
      DATE_RANGE: "SCANS_DATE_RANGE",
      VISIBILITY: "SCANS_VISIBILITY",
    },
  },
};
