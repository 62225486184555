import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/Error";
import CallMadeIcon from "@material-ui/icons/CallMade";
import LowSeverityIcon from "assets/pii-icons/signal_cellular_alt_1_bar.png";
import MediumSeverityIcon from "assets/pii-icons/signal_cellular_alt_2_bar.png";
import LeakageIcon from "assets/pii-icons/signal_cellular_alt.svg";
import { useFetchUserLastScansByBranch } from "hooks/useFetchUserLastScansByBranch";
import Tooltip from "../../../../Tooltip";
import { routePaths } from "router/paths";
import dateParsingUtils from "helpers/dateParsingUtils";
import WebhookBadge from "./WebhookBadge";
import Pagination from "../Pagination";
import CloseIcon from "@material-ui/icons/Close.js";
import { ScanStatusDetail } from "./ScanStatusDetail";
import { SeverityBox } from "../../components/SeverityBox"


const LastScanStatusRowListing = () => {
  const [errorDeleted, setErrorDeleted] = useState(false);

  const {
    loading,
    error,
    data,
    fetchLastScanRows,
    pagination,
    setPagination,
  } = useFetchUserLastScansByBranch();
  const history = useHistory();
  const currentRepositoryBranchId = useSelector(
    (state) => state.scanner.currentBranchId
  );

  const handleScanDetails = useCallback(
    (id) => {
      history.push(`${routePaths.DATAFLOW_URL}${id}`);
    },
    [history]
  );

  useEffect(() => {
    if (currentRepositoryBranchId) {
      fetchLastScanRows(currentRepositoryBranchId);
    }
  }, [currentRepositoryBranchId, pagination.page, pagination.pageSize]);

  const sanitizeData = (results) => {
    if (!results || results.length === 0) {
      return (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            background: "#F0F0F0",
            padding: 16,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: 16,
              color: "#000000",
              textAlign: "center",
            }}
          >
            No last status information available. Run at least one scan
          </Typography>
        </Box>
      );
    }

    const views = results.map((value) => (
      <LastScanStatusRow
        key={value.id}
        value={value}
        handleScanDetails={handleScanDetails}
      />
    ));

    return views;
  };

  const onHandleDelete = useCallback(() => {
    setErrorDeleted(true);
  }, []);

  const showError = () => {
    return (
      !loading &&
      error &&
      !errorDeleted && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
            backgroundColor: "#F9F9FA",
            border: "1px solid red",
            padding: 12,
            borderRadius: 8,
            fontFamily: "Inter",
            color: "red",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flex: 1,
              fontFamily: "Inter",
              fontSize: 14,
              color: "#FB2424",
            }}
          >
            {error}-{error?.response?.status}
          </Typography>
          <CloseIcon style={{ color: "grey" }} onClick={onHandleDelete} />
        </Box>
      )
    );
  };

  return (
    <>
      {showError()}
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ width: 40, height: 40 }} />
        </Box>
      ) : (
        <Box
          style={{
            gap: 8,
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
          }}
        >
          {data && sanitizeData(data.data)}
          { data?.data?.length > 0   && <Pagination
             page={pagination.page}
             pageSize={pagination.pageSize}
             totalRows={pagination.totalRows}
             setPagination={setPagination} /> }
        </Box>
      )}
    </>
  );
};


const LastScanStatusRow = ({ value, handleScanDetails }) => {
  const summary = value;

  const allValuesAreZero =
    summary?.low_severity === 0 &&
    summary?.medium_severity === 0 &&
    summary?.leakage === 0;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        background: "#F0F0F0",
        padding: 16,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          color: "#466DE8",
          fontWeight: 900,
          fontFamily: "Inter",
          fontSize: 12,
          flex: "0 0 auto",
          marginRight:20
        }}
      >
        {dateParsingUtils.getFormatedDateWithYearAndTime(value.created)}
      </Typography>
      
        <WebhookBadge triggeredByWebhook={summary?.triggered_by_webhook} />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 24,
          flex: "1 1 auto",
        }}
      >
        <SeverityBox
          label="Low Severity"
          value={summary?.low_severity || 0}
          icon={LowSeverityIcon}
        />
        <SeverityBox
          label="Medium Severity"
          value={summary?.medium_severity || 0}
          icon={MediumSeverityIcon}
        />
        <SeverityBox
          label="Leakage"
          value={summary?.leakage || 0}
          icon={LeakageIcon}
        />
      </Box>
      <ScanStatusDetail
        status={value.status}
        handleScanDetails={() => handleScanDetails(value.id)}
        allValuesAreZero={allValuesAreZero}
      />
    </Box>
  );
};
export default LastScanStatusRowListing;
