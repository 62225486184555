import React from "react";

export default () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15435 17.6357C3.20494 17.6357 3.61445 16.9976 3.61445 15.9058C3.61445 14.814 3.1355 14.0529 2.18534 14.0529H1.12031V17.6357H2.15445H2.15435ZM1.01615e-05 13.0996H2.32425C3.7998 13.0996 4.75 14.2837 4.75 15.9058C4.75 16.7977 4.4487 17.5818 3.86929 18.0662C3.45985 18.4045 2.92681 18.5967 2.23156 18.5967H0V13.0996L1.01615e-05 13.0996Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.94966 16.6284C7.94966 15.8596 7.58659 15.3368 6.9454 15.3368C6.29649 15.3368 5.94114 15.8596 5.94114 16.6284C5.94114 17.3972 6.29649 17.9123 6.9454 17.9123C7.58659 17.9123 7.94966 17.3972 7.94966 16.6284ZM4.875 16.6284C4.875 15.4521 5.71706 14.5449 6.9454 14.5449C8.17372 14.5449 9.0157 15.4521 9.0157 16.6284C9.0157 17.8047 8.17364 18.712 6.9454 18.712C5.71708 18.712 4.875 17.8047 4.875 16.6284"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18947 16.6284C9.18947 15.4521 9.96199 14.5449 11.1671 14.5449C12.1946 14.5449 12.8281 15.1369 12.9671 15.9903H11.9397C11.8702 15.6444 11.5998 15.383 11.2135 15.383C10.5801 15.383 10.2556 15.8673 10.2556 16.6285C10.2556 17.3743 10.5569 17.874 11.1981 17.874C11.623 17.874 11.9243 17.651 11.9938 17.2051H13.0058C12.9363 18.0431 12.2796 18.712 11.2212 18.712C9.96975 18.712 9.18945 17.8047 9.18945 16.6284"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7419 18.5965V18.1352H15.7188C15.4484 18.4965 15.1394 18.7041 14.5832 18.7041C13.7025 18.7041 13.208 18.1429 13.208 17.2818V14.6524H14.2509V17.105C14.2509 17.5662 14.4595 17.8199 14.9075 17.8199C15.402 17.8199 15.7032 17.4509 15.7032 16.9281V14.6523H16.7539V18.5965H15.7419L15.7419 18.5965Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8711 18.5981H22.9217V14.654H21.8711V18.5981ZM21.8711 14.1026H22.9217V13.125H21.8711V14.1026Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1308 16.4517C26.1308 15.8751 25.8219 15.383 25.1884 15.383C24.6554 15.383 24.3 15.7982 24.3 16.4594C24.3 17.1283 24.6554 17.5204 25.1961 17.5204C25.8605 17.5204 26.1308 17.036 26.1308 16.4517ZM23.3575 18.712H24.4003C24.4853 18.958 24.7094 19.1502 25.196 19.1502C25.7909 19.1502 26.0767 18.8657 26.0767 18.3276V17.897H26.0536C25.8218 18.1584 25.5205 18.3583 25.0184 18.3583C24.1377 18.3583 23.2725 17.6663 23.2725 16.4747C23.2725 15.2984 23.9832 14.5449 24.9874 14.5449C25.4819 14.5449 25.8449 14.7371 26.0843 15.0677H26.0998V14.6525H27.1118V18.2968C27.1118 18.8503 26.9341 19.227 26.6406 19.4961C26.3084 19.8036 25.7986 19.9421 25.2115 19.9421C24.13 19.9421 23.4656 19.4807 23.3574 18.7119"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5909 15.1908H28.6141C28.8845 14.7679 29.2476 14.5449 29.7883 14.5449C30.6072 14.5449 31.1556 15.16 31.1556 16.0211V18.5966H30.105V16.1748C30.105 15.7519 29.8578 15.4521 29.4175 15.4521C28.954 15.4521 28.6141 15.8211 28.6141 16.3593V18.5966H27.5635V14.6526H28.5909V15.1908L28.5909 15.1908Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.478 15.2834C18.6591 15.1066 18.2729 14.9759 18.2729 14.53C18.2729 14.1379 18.6668 13.8842 19.2694 13.8842C19.8192 13.8842 20.2394 14.1409 20.3257 14.653H21.4032C21.2935 13.5851 20.4975 13 19.2772 13C18.0488 13 17.1604 13.5612 17.1604 14.6453C17.1604 15.7908 18.0643 16.0523 19.0531 16.2752C19.8952 16.4674 20.4204 16.575 20.4204 17.1132C20.4204 17.6206 19.9183 17.8205 19.3467 17.8205C18.5586 17.8205 18.1801 17.5591 18.1029 16.8979H17.0059C17.0445 18.0588 17.8942 18.7199 19.393 18.7199C20.6368 18.7199 21.5406 18.0973 21.5406 16.9825C21.5406 15.7984 20.6136 15.5294 19.478 15.2833"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5828 14.614C31.3885 14.614 31.2305 14.7712 31.2305 14.9646C31.2305 15.1579 31.3885 15.3152 31.5828 15.3152C31.777 15.3152 31.9351 15.1579 31.9351 14.9646C31.9351 14.7712 31.777 14.614 31.5828 14.614V14.614ZM31.5828 15.3803C31.3524 15.3803 31.165 15.1938 31.165 14.9646C31.165 14.7353 31.3524 14.5488 31.5828 14.5488C31.8131 14.5488 32.0005 14.7353 32.0005 14.9646C32.0005 15.1938 31.8131 15.3803 31.5828 15.3803Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5177 14.8206V14.9537H31.5455C31.5728 14.9537 31.5831 14.9531 31.5982 14.9524C31.6157 14.9519 31.6732 14.9488 31.6732 14.8869C31.6732 14.8778 31.6726 14.8465 31.6393 14.8296C31.6224 14.8212 31.6018 14.8206 31.5546 14.8206H31.5177V14.8206ZM31.4385 14.752H31.5746C31.6315 14.7525 31.6696 14.7525 31.7071 14.7827C31.7337 14.8043 31.7543 14.8381 31.7543 14.8874C31.7543 14.9687 31.6944 14.997 31.6702 15.0043L31.7651 15.177H31.6738L31.5891 15.0193H31.5177V15.177H31.4385V14.752L31.4385 14.752Z"
      fill="black"
    />
  </svg>
);
