import React from "react";
import "./styles.scss";
import { bad, avg, good } from "../../utils/colormap";

const RADIAL_ADJUSTMENT = -45;

const GaugeChart = ({ value = 0 }) => {
  // 0-180 deg range, where 0/5 => 0deg (high score means low risk, but `value`
  // is its 5-complement) and 5/5 => 180deg (low score means high risk, but
  // `value` is its 5-complement).
  const angle = RADIAL_ADJUSTMENT + (value / 5) * 180;

  const background = `linear-gradient(
    -90deg, ${bad} 0%, ${avg} 40%, ${avg} 60%, ${good} 100%
  )`;

  return (
    <div className="gauge-chart">
      <div className="border-wrapper">
        <div className="border" style={{ background }}>
          <div className="center" />
        </div>
      </div>
      <div
        className="arrow-wrapper"
        style={{ transform: `rotate(${angle}deg)` }}
      >
        <div className="arrow" />
        <div className="circle">
          <div className="inner-circle" />
        </div>
      </div>
    </div>
  );
};

export default GaugeChart;
