import { CONSTANTS } from "redux/_admin/_company/company.constants";

const initialState = {
  data: {},
  loading: false,
};

export const company = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.ADMIN_COMPANY_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.ADMIN_COMPANY_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.ADMIN_COMPANY_SAVE_DATA:
      return {
        ...state,
        data,
      };
    case CONSTANTS.SET_COMPANY_COOKIE:
      let newEl = true;
      const cookies = state.cookies.map((el) => {
        if (el.id === data.id) {
          newEl = false;
          return data;
        }
        return el;
      });
      if (newEl) cookies.push(data);
      return {
        ...state,
        cookies,
      };
    case CONSTANTS.REMOVE_COMPANY_COOKIE:
      return {
        ...state,
        cookies: state.cookies.filter((x) => x.id !== data),
      };
    case CONSTANTS.SET_COMPANY_COOKIES:
      return {
        ...state,
        cookies: data,
      };
    default:
      return state;
  }
};
