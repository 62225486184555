import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { getAccountData } from "redux/_account/account.selectors";
import {
  listSchedulers,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";
import PageWrapper from "components/PageWrapper";
import  NewRepositoryDetailView  from "./NewRepositoryDetailView";


const NewRepositoryDetail = ({ account, fetchSchedulers }) => {

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapper subtitle={"Repository Scanner Detail"}>
      <Switch>
        <Route path={"/zen/new-repository-scanner-details/:repo_id"}>
                <NewRepositoryDetailView/>
        </Route>
      </Switch>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  chedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
  fetchSchedulers: (filters) => dispatch(listSchedulers(filters)),
});

const ConnectedSchedulingRepositoryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRepositoryDetail);
export default withRouter(ConnectedSchedulingRepositoryPage);