import CongratsPage from "components/Congrats";
import WFLogin from "components/login/webflow";
import PrivacyScanner from "components/PrivacyReports/Scanner";
import PrivateRoute from "components/private-route/private-route";
import PublicRoute from "components/public-route/public-route";
import NotFound from "components/PublicComponents/NotFound";
import history from "helpers/history";
import { useSnackbar } from "notistack";
import ChromeExtension from "pages/ChromeExtension";
import ConsumerReport from "pages/ConsumerReport";
import ConsumerReportResult from "pages/ConsumerReportResult";
import DemoReportsPage from "pages/DemoReports";
import LoginPage from "pages/login";
import Plans from "pages/Plans";
import PrivacyReportPage from "pages/privacyReport";
import AppReportPage from "pages/AppReport";
import RegisterPage from "pages/register";
import RequestADemoPage from "pages/requestADemo";
import ResetPasswordPage from "pages/resetPassword";
import ResetPasswordConfirmPage from "pages/resetPasswordConfirm";
import SignupPage from "pages/signup";
import SimpleLoginPage from "pages/simpleLogin";
import ZenPage from "pages/zen";
import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { initDisplayError } from "utils/handle-fetch-response";
import { routePaths } from "./paths";
import SecurityReport from "pages/SecurityReport";
import RepositoryResults from "pages/RepositoryResults";
import DataSourceReport from "pages/DataSourceReport";
import VariableInventoryPage from "pages/variableInventory";
import { REPO_V2_URL } from "constants/RepoV2";

const routeForKey = (routeKey) => routePaths[routeKey];

const GoTo = (to) => () => {
  window.location.href = to;
  return null;
};

function AppRouter() {
  const { enqueueSnackbar } = useSnackbar();
  window.Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
  });
  useEffect(() => {
    initDisplayError((text) => enqueueSnackbar(text, { variant: "error" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path={"/wf-login"} exact component={WFLogin} />
        <PublicRoute path={routeForKey("login")} component={LoginPage} />
        <PublicRoute
          path={routeForKey("simpleLogin")}
          component={SimpleLoginPage}
        />
        <PublicRoute path={routeForKey("signup")} component={SignupPage} />
        <PublicRoute
          path={routeForKey("resetPassword")}
          component={ResetPasswordPage}
        />
        <Route
          path={routeForKey("confirmPassword")}
          component={ResetPasswordConfirmPage}
        />
        {/* Temporally hidden meanwhile we work on the other flow related with PII */}
        {/*<Route path={routeForKey('register')} component={RegisterPage} />*/}
        <PublicRoute path={routeForKey("congrats")} component={CongratsPage} />
        <PublicRoute
          path={routeForKey("acceptInvite")}
          component={RegisterPage}
        />
        <PublicRoute
          path={routeForKey("privacyScanner")}
          component={PrivacyScanner}
        />
        <Route
          path={routeForKey("privacyReport")}
          component={PrivacyReportPage}
        />
        <Route path={routeForKey("appReport")} component={AppReportPage} />
        <Route
          path={routeForKey("securityReport")}
          component={SecurityReport}
        />
        <Route
          path={routeForKey("dataSourceReport")}
          component={DataSourceReport}
        />
        <Route path={routeForKey("fileReport")} component={DataSourceReport} />
        <Route
          path={routeForKey("repositoryResults")}
          component={RepositoryResults}
        />
        <PublicRoute
          path={routeForKey("requestADemo")}
          component={RequestADemoPage}
        />
        <Route
          path={routeForKey("privacyCentral")}
          component={GoTo("https://www.zendata.dev/press")}
        />
        <PublicRoute path={routeForKey("plans")} component={Plans} />
        <PublicRoute
          path={routeForKey("demoReports")}
          component={DemoReportsPage}
        />
        <PublicRoute
          path={routeForKey("pricing")}
          component={GoTo("https://www.zendata.dev/pricing")}
        />
        <PublicRoute
          path={routeForKey("dataProtectionForm")}
          component={GoTo("https://www.zendata.dev/data-protection-form")}
        />
        <Route
          path={routeForKey("terms")}
          component={GoTo("https://www.zendata.dev/terms")}
        />
        <PrivateRoute
          extensionPage
          path={routeForKey("extension")}
          component={ChromeExtension}
        />
        <Route
          path={routeForKey("privacy")}
          component={GoTo("https://www.zendata.dev/privacy-policy")}
        />
        <PrivateRoute path={routeForKey("zenArea")} component={ZenPage} />
        <Route path={routeForKey("notFound")} component={NotFound} />
        <Route path="/consumer-report" component={ConsumerReport} exact />
        <Route
          path="/consumer-report-result"
          component={ConsumerReportResult}
          exact
        />
        <Route
          path={routeForKey("variableInventory")}
          component={VariableInventoryPage}
        />
        <Redirect from={routeForKey("home")} to={routeForKey("login")} />
        <Redirect to={routeForKey("notFound")} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
export { routeForKey };
