import {
  SET_REPOSITORY_ID,
  SET_BRANCHES,
  SET_REPOSITORY_BRANCH_ID,
  SET_REPOSITORY_PROVIDER,
  SET_REPOSITORY_URL,
  SET_REPOSITORY_WEBHOOK_ENABLED,
  SET_REPOSITORY_SCAN_SUMMARY,
  SET_TAB_VIEW
} from "./scanner.actions";

const initialState = {
  repositoryId: "",
  branches: [],
  currentBranchId: "",
  repositoryUrl: "",
  repositoryWebhookEnabled: false,
  repositoryProvider: "",
  repositoryScanSummary: {},
  tabView: 0,
};

export const scanner = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPOSITORY_ID:
      return {
        ...state,
        repositoryId: action.payload,
      };
    case SET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };

    case SET_REPOSITORY_BRANCH_ID:
      return {
        ...state,
        currentBranchId: action.payload,
      };
    case SET_REPOSITORY_PROVIDER:
      return {
        ...state,
        repositoryProvider: action.payload,
      };
    case SET_REPOSITORY_URL:
      return {
        ...state,
        repositoryUrl: action.payload,
      };
    case SET_REPOSITORY_SCAN_SUMMARY:
      return {
        ...state,
        repositoryScanSummary: action.payload,
      };
    case SET_REPOSITORY_WEBHOOK_ENABLED:
      return {
        ...state,
        repositoryWebhookEnabled: action.payload,
      };
    case SET_TAB_VIEW:
      return {
        ...state,
        tabView: action.payload,
      };
    default:
      return state;
  }
};
