import useStyles from "./styles";

const Table = ({ assets }) => {
  const classes = useStyles();
  return (
    <div>
      {assets.os_versions && (
        <div className={classes.table}>
          {["windows", "osx", "ubuntu"].map(
            (platform) =>
              assets.os_versions[platform] &&
              assets.os_versions[platform].map((x) => (
                <div key={`${platform}${x.version}`} className={classes.row}>
                  <div className={classes.col1}>
                    <span className={classes[platform]}>{platform}</span>{" "}
                    {x.version}
                  </div>
                  <div className={classes.col2}>
                    <a href={x.url}>{x.name}</a>
                  </div>
                </div>
              ))
          )}
        </div>
      )}
      {assets.guide && (
        <div className={classes.userGuide}>
          {assets.guide && (
            <>
              <a href={assets.guide_simple} target="blank">
                User Guide (simple)
              </a>
              <br />
            </>
          )}
          <a href={assets.guide} target="blank">
            User Guide (complete)
          </a>
        </div>
      )}
    </div>
  );
};

export default Table;
