import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import PIISharingDetailsSummary from "./components/PIISharingDetailsSummary";
import PIISharingDetailsList from "./components/PIISharingDetailsList";
import { useFetchPIISharingDetails } from "hooks/useFetchPIISharingDetails";
import { useFetchAvailableFilters } from "hooks/useFetchAvailableFilters";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSharingDetailInfo } from "redux/_sharingDetails/sharingDetails.actions";
import { Loading } from "../components/Loading";

const ErrorMessage = ({ error }) => (
  <Box
    style={{
      display: "flex",
      justifyContent: "center",
      marginTop: "24px",
      backgroundColor: "#F9F9FA",
      border: "1px solid red",
      padding: 12,
      borderRadius: 8,
      fontFamily: "Inter",
      color: "red",
    }}
  >
    <Typography
      style={{
        display: "flex",
        flex: 1,
        fontFamily: "Inter",
        fontSize: 14,
        color: "#FB2424",
      }}
    >
      {error?.response?.status}
    </Typography>
  </Box>
);

const mapDetailsData = (data) =>
  data?.data.map((item) => ({
    path: item.path,
    scanId: item.scan_id,
    context: item.context,
    contextIndex: item.context_index,
    type: item.type,
    piiName: item.pii,
    pii: item.pii,
    rowText: item.row_text,
    classification1: item.classification_1,
    classification2: item.classification_2,
    classification2Label: item.classification_2_label,
    reasoning: item.reasoning,
    severity: item.severity,
    startPosition: item.start_position,
    endPosition: item.end_position,
    lineNumber: item.line_number,
  }));

const PIISharingDetailsView = () => {
  const { scan_id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const urlClassification1 = queryParams.get("classification_1") || "";
  const urlClassification2 = queryParams.get("classification_2") || "";

  const [selectedType, setSelectedType] = useState(urlClassification1);
  const [selectedCategory, setSelectedCategory] = useState(urlClassification2);

  const {
    loading,
    data,
    error,
    fetchPIISharingDetails,
    setPagination,
    pagination,
  } = useFetchPIISharingDetails();
  const {
    data: filtersData,
    loading: filtersLoading,
    error: filtersError,
  } = useFetchAvailableFilters();

  useEffect(() => {
    if (scan_id) {
      fetchPIISharingDetails(scan_id, selectedType, selectedCategory);
    }
  }, [scan_id, selectedType, selectedCategory, pagination.page]);

  useEffect(() => {
    if (data) {
      const detailsInfo = mapDetailsData(data);
      dispatch(setSharingDetailInfo({ detailsInfo }));
    }
  }, [data, dispatch]);

  const updateUrlParams = useCallback(() => {
    const params = new URLSearchParams();
    if (selectedType) params.set("classification_1", selectedType);
    if (selectedCategory) params.set("classification_2", selectedCategory);
    history.replace({ search: params.toString() });
  }, [selectedType, selectedCategory, history]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage > 0) {
        setPagination((prev) => ({ ...prev, page: newPage }));
      }
    },
    [setPagination]
  );

  const handleTypeChange = useCallback(
    (event) => {
      const newType = event.target.value;
      setSelectedType(newType);
      updateUrlParams();
    },
    [updateUrlParams]
  );

  const handleCategoryChange = useCallback(
    (event) => {
      const newCategory = event.target.value;
      setSelectedCategory(newCategory);
      updateUrlParams();
    },
    [updateUrlParams]
  );

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      {loading || filtersLoading ? (
        <Loading />
      ) : error || filtersError ? (
        <ErrorMessage error={error || filtersError} />
      ) : (
        <>
          <PIISharingDetailsSummary
            detections={pagination.totalRows}
            selectedType={selectedType}
            selectedCategory={selectedCategory}
            types={filtersData?.classification_1 || []}
            categories={filtersData?.classification_2_label || []}
            handleTypeChange={handleTypeChange}
            handleCategoryChange={handleCategoryChange}
            loading={filtersLoading}
          />
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 900,
              color: "#1C3687",
              fontFamily: "Inter",
            }}
          >
            List of PIIs
          </Typography>
          <PIISharingDetailsList
            onPageChange={handlePageChange}
            pagination={pagination}
          />
        </>
      )}
    </Box>
  );
};

export default PIISharingDetailsView;
