import React from "react";

const ChevronRight = ({ className, turn, ...props }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={turn ? { transform: "rotate(180deg)" } : {}}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5859 12.0001L7.29297 4.70718L8.70718 3.29297L17.4143 12.0001L8.70718 20.7072L7.29297 19.293L14.5859 12.0001Z"
      fill="#3F51B5"
    />
  </svg>
);

export default ChevronRight;
