import moment from "moment";

export const parseData = (data) =>
  data.reduce((prev, curr) => {
    const scans = curr.children.map((child) => ({
      [curr.name]: child.matches,
      id: child.name,
      date: moment(child.scan_date),
    }));
    scans.forEach((scan) => {
      const index = prev.findIndex((elem) => elem.id === scan.id);
      if (index !== -1) {
        prev[index] = {
          ...prev[index],
          ...scan,
        };
      } else {
        prev.push(scan);
      }
    });
    return prev;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

export const getUniqueKeys = (data) =>
  data
    .reduce((prev, curr) => {
      Object.keys(curr).forEach((key) => {
        const index = prev.indexOf(key);
        if (index === -1) {
          prev.push(key);
        }
      });
      return prev;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    .filter((elem) => !["id", "date"].includes(elem));
