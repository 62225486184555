import { CONSTANTS } from "redux/_admin/_users/users.constants";

export const requestStart = (data = "fetch") => ({
  type: CONSTANTS.ADMIN_USERS_REQUEST_START,
  data,
});

export const requestEnd = (data = "fetch") => ({
  type: CONSTANTS.ADMIN_USERS_REQUEST_END,
  data,
});

export const requestStartKey = (data) => ({
  type: CONSTANTS.ADMIN_USERS_REQUEST_START_KEY,
  data,
});

export const requestEndKey = (data) => ({
  type: CONSTANTS.ADMIN_USERS_REQUEST_END_KEY,
  data,
});

export const saveData = (data) => ({
  type: CONSTANTS.ADMIN_USERS_SAVE_DATA,
  data,
});

export const removeUser = (data) => ({
  type: CONSTANTS.ADMIN_USERS_REMOVE,
  data,
});
