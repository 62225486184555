import React, { useState, useEffect, useRef } from "react";
import { parseData, getUniqueKeys } from "./utils";

import { stack, area } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { range, max } from "d3-array";

import { formatNumber } from "utils/format";

const StackAreaChart = ({ data = [], height }) => {
  // State declarations
  const [width, setWidth] = useState(0);

  // DOM Elements
  const svgElement = useRef();

  // Local variables
  const margins = {
    top: 50,
    bottom: 75,
    left: 75,
    right: 200,
  };

  // Functions
  const setSvgElementWidth = () => {
    if (svgElement.current) {
      const elementData = svgElement.current.getBoundingClientRect();
      setWidth(elementData.width);
    }
  };

  const color = scaleLinear()
    .domain([0, 0.5, 1])
    .range(["#0e1d60", "#4766b6", "#d0dff8"]);

  // Calculate
  const draw = (data) => {
    const elements = {};

    const processedData = parseData(data);
    const parsedData =
      processedData.length > 0
        ? [
            processedData[0],
            ...processedData,
            processedData[processedData.length - 1],
          ]
        : [];
    elements.parsedData = parsedData;
    const keys = getUniqueKeys(parsedData);
    const stackGenerator = stack().keys(keys);

    const series = stackGenerator(parsedData);

    const maxValue = max(series, (data) => max(data, (d) => d[1]));
    const x = scaleLinear().range([0, width - margins.left - margins.right]);
    const y = scaleLinear()
      .domain([0, maxValue])
      .range([height - margins.top - margins.bottom, 0]);

    const xDistance = 1 / (parsedData.length - 1);

    const areaGenerator = area()
      .x((d, i) => x(xDistance * i))
      .y0((d) => y(d[1]))
      .y1(height - margins.top - margins.bottom);

    elements.areas = series.map((serie) => areaGenerator(serie));

    elements.legends = keys.reverse().map((key, i) => ({
      name: key,
      color: color(i / elements.areas.length),
    }));

    const axis = [
      range(0, parsedData.length * xDistance, xDistance).map(x),
      y
        .ticks()
        .map((tick) => ({ value: formatNumber(tick), position: y(tick) })),
    ];

    elements.axis = axis;
    return elements;
  };

  //   // Effects
  useEffect(() => {
    setSvgElementWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const elements = draw(data);
  return (
    <svg
      ref={svgElement}
      width="100%"
      height={height}
      style={{ margin: "1rem 0" }}
    >
      <g
        className="areas"
        transform={`translate(${margins.left}, ${margins.top})`}
      >
        {elements.areas.reverse().map((area, i) => (
          <path
            d={area}
            stroke="white"
            strokeWidth="2"
            fill={color(i / elements.areas.length)}
            key={i}
          />
        ))}
      </g>

      <g className="x-axis">
        <text
          className="x-axis-label"
          x="10"
          y={height / 2}
          fontFamily="Roboto Condensed"
          transform={`rotate(-90 10,${height / 2})`}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          # OF PII
        </text>
        <g
          className="x-axis-ticks"
          transform={`translate(${margins.left}, ${height - 50})`}
        >
          {elements.axis[0].map((tick, i) =>
            i !== 0 && i !== elements.axis[0].length - 1 ? (
              <text
                fill="#828282"
                fontSize={14}
                fontFamily="Roboto Condensed"
                textAnchor="middle"
                alignmentBaseline="central"
                transform={`translate(${tick})`}
              >
                {elements.parsedData[i]
                  ? elements.parsedData[i].date.format("DD/MM")
                  : null}
              </text>
            ) : null
          )}
        </g>
      </g>

      <g className="y-axis">
        <text
          className="y-axis__label"
          x={width / 2}
          y={height - 10}
          fontFamily="Roboto Condensed"
          textAnchor="middle"
          alignmentBaseline="central"
        >
          SCANS DATE
        </text>
        <g className="y-axis-ticks" transform={`translate(40, ${margins.top})`}>
          {elements.axis[1].map((tick) => (
            <text
              fill="#828282"
              fontSize={14}
              fontFamily="Roboto Condensed"
              transform={`translate(25, ${tick.position})`}
              textAnchor="end"
            >
              {tick.value}
            </text>
          ))}
        </g>
      </g>

      <g
        className="legend"
        transform={`translate(${width - 175} ${margins.top})`}
      >
        <rect
          width="180"
          height={elements.legends.length * 20 + 40}
          fill="#F5F5F5"
          transform="translate(-15, -20)"
        />
        <text
          fill="#4f4f4f"
          fontSize={11}
          fontWeight="bold"
          fontFamily="Roboto Condensed"
          textAnchor="left"
          alignmentBaseline="central"
          transform="translate(-5)"
        >
          PII GROUPS
        </text>
        {elements.legends.map((legend, i) => (
          <g transform={`translate(0 ${(i + 1) * 20})`}>
            <circle
              fill={legend.color}
              r={5}
              transform="translate(0, 1)"
            ></circle>
            <text
              fontSize={11}
              fill="#4f4f4f"
              fontFamily="Roboto Condensed"
              textAnchor="left"
              alignmentBaseline="central"
              transform="translate(12)"
            >
              {String(legend.name).toUpperCase()}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};

export default StackAreaChart;
