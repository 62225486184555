import React, { useEffect } from "react";
import { Box, Paper, Grid, Typography } from "@material-ui/core";
import EnvironmentHeader from "components/EnvironmentHeader";
import { connect } from "react-redux";
import useStyles from "./styles";
import Filters from "components/ScansFilters";
import ScanSelector from "components/PageWrapper/components/LayoutHeader";
import DataStructureTreeMap from "charts/TreeMap/DataStructure";
import { selectEnv } from "redux/_environments/environment.actions";
import {
  getLoading,
  getScan,
  getTreeMapDataFormatted,
} from "redux/_charts/charts.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { generatePath } from "react-router-dom";
import { setFilterScan } from "redux/_charts/charts.actions";

const DataStructure = (props) => {
  const {
    match,
    history,
    selectEnvironment,
    treeMapData,
    selectedEnv,
    loading,
    selectedScan,
    selectScan,
  } = props;

  useEffect(() => {
    if (selectedEnv !== match.params.env_id)
      selectEnvironment(match.params.env_id);
    if (selectedScan !== match.params.scan_id) selectScan(match.params.scan_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const onChangeScan = (scanId) =>
    history.push({
      pathname: generatePath(match.path, {
        env_id: match.params.env_id,
        scan_id: scanId,
      }),
    });

  return (
    <Box component={Paper} elevation={1} rounded className={classes.container}>
      <EnvironmentHeader
        rightComponent={<ScanSelector onScanChange={onChangeScan} />}
      />
      <Box className={classes.content}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={9}>
            <Box>
              <Typography variant="h4" display="inline">
                DATA STRUCTURE
              </Typography>
            </Box>
            <DataStructureTreeMap
              data={treeMapData}
              loading={loading}
              colors={["#9dc6e0", "#7aa6c2", "#5886a5", "#346888", "#004c6d"]}
            />
          </Grid>
          <Grid item xs={3}>
            <Filters />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  treeMapData: getTreeMapDataFormatted(state),
  selectedEnv: getSelectedEnvId(state),
  loading: getLoading(state),
  selectedScan: getScan(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectEnvironment: (envId) => dispatch(selectEnv(envId)),
  selectScan: (scanId) => dispatch(setFilterScan(scanId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataStructure);
