import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(4, 0),
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 1600,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  leftContent: {
    height: "100%",
    flex: 1,
    minWidth: 698,
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.spacing(3),
    },
  },
  rightContent: {
    height: "100%",
    flexBasis: "100vh",
    minWidth: 376,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      paddingTop: 0,
      paddingLeft: theme.spacing(3),
    },
  },
  primaryText: {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.grey[200],
    paddingBottom: theme.spacing(2),
  },
  secondaryTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(4),
  },
  secondaryText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.grey[300],
    paddingRight: "1rem",
  },
  addButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "1px",
    whiteSpace: "nowrap",
    height: "32px",
    maxWidth: "122px",
    flexShrink: 0,
  },
  rightTitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "1px",
    color: theme.palette.grey[200],
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
    textTransform: "uppercase",
  },
}));
