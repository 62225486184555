import { StyleSheet, Font } from "@react-pdf/renderer";
import Aldrich from "./Aldrich.ttf";
import Inter from "./Inter.otf";
import InterBold from "./Inter-Medium.ttf";

Font.register({
  family: "Aldrich",
  src: Aldrich,
});

Font.register({
  family: "Inter",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

const blue = "#1a3586";
const gray = "#686c78";
const grayLight = "#dfe2e9";

const styles = StyleSheet.create({
  page: {
    color: gray,
    flexDirection: "column",
    fontFamily: "Inter",
    fontSize: 12,
    paddingBottom: "2cm",
  },
  section: {
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
  },
  ul: {
    marginTop: 10,
  },
  li: {
    flexDirection: "row",
    marginTop: 10,
  },
  liText: {
    paddingLeft: 5,
    marginTop: -3,
  },
  paragraph: {
    marginBottom: 20,
  },
  box50: {
    width: "50%",
  },
  box33: {
    width: "33%",
  },
  title: {
    fontSize: 18,
    color: blue,
    fontFamily: "Aldrich",
    marginBottom: 10,
  },
  subTitle: {
    marginBottom: 10,
    fontWeight: 600,
  },
  text: {
    fontSize: 10,
  },
  highlightedText: {
    fontSize: 10,
    color: "#6A6A6A",
    fontWeight: 700,
    marginLeft: 10,
  },
  infoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  classes: {
    color: "#1976D2",
    fontSize: "16px",
    fontWeight: 700,
  },
  compiler: {
    color: "#74bde5",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: 10,
  },
  antiCode: {
    color: "#ef7d1f",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: 10,
  },
  standOut: {
    marginTop: 12,
    marginBottom: 3,
    fontSize: 16,
    color: gray,
    fontWeight: 600,
  },
  label: {
    color: gray,
    fontWeight: 800,
  },
  center: {
    alignItems: "center",
  },
  tableBox: {
    border: `1px solid ${grayLight}`,
  },
  table: {
    display: "table",
    fleFlow: "column nowrap",
    borderWidth: 0,
    marginBottom: 20,
    borderLeft: `1px solid ${grayLight}`,
    borderRight: `1px solid ${grayLight}`,
  },
  thead: {
    width: "100%",
    backgroundColor: grayLight,
  },
  tbody: {
    width: "100%",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    margin: "auto",
    width: "100%",
  },
  th: {
    color: gray,
    flexBasis: 0,
    flexGrow: 1,
    fontSize: 10,
    fontWeight: 700,
    justifyContent: "center",
    margin: 0,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  thCustomWidth: {
    color: gray,
    flexGrow: 1,
    fontSize: 10,
    fontWeight: 700,
    justifyContent: "center",
    margin: 0,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  td: {
    alignItems: "center",
    borderBottom: `1px solid ${grayLight}`,
    color: gray,
    flexBasis: 0,
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    fontSize: 10,
    paddingBottom: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 8,
  },
  tdCustomWidth: {
    alignItems: "center",
    borderBottom: `1px solid ${grayLight}`,
    color: gray,
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "wrap",
    fontSize: 10,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
  },
  tdHorizontal: {},

  dottedBox: {
    display: "flex",
    flexDirection: "column",
    border: "1px dashed rgba(0, 0, 0, 0.12)",
    padding: "8px 16px",
    marginBottom: 10,
    borderRadius: 4,
    overflowWrap: "break-word",
    width: "100%",
    color: "rgba(0, 0, 0, 0.6)",
  },
  statusText: {
    borderWidth: "2px",
    padding: "4px 8px",
    textTransform: "capitalize",
  },
  severityText: {
    color: "#FFFFFF",
    borderRadius: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
  },
  severities_body_content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
  },
  severities_body_item: {
    high: {
      backgroundColor: "#FBEAE9",
      height: 40,
    },
    warning: {
      backgroundColor: "#FDF0E6",
      height: 40,
    },
    medium: {
      backgroundColor: "#FDF0E6",
      height: 40,
    },
    info: {
      backgroundColor: "#E5F3FB",
      height: 40,
    },
    secure: {
      backgroundColor: "#EAF2EA",
      height: 40,
    },
    hotspot: {
      backgroundColor: "#D6D6D6",
      height: 40,
    },
    text: {
      marginLeft: 5,
      marginTop: 12,
    },
  },
  borderBox: {
    paddingVertical: 10,
    borderLeft: `1px solid ${grayLight}`,
    borderRight: `1px solid ${grayLight}`,
    borderBottom: `1px solid ${grayLight}`,
  },
});

export default styles;
