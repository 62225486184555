import { Button } from "@material-ui/core";
import cyberSecurity from "assets/images/cyber-security.jpg";
import clsx from "clsx";
import InfoIconButton from "components/InfoIconButton";
import PDFDownloadLink from "components/PDFReport/Link";
import moment from "moment";
import { useEffect, useState } from "react";
import { WEB_REPORT_TYPES } from "../../WebReport";
import GaugeChart from "../../components/GaugeChart";
import ScoreChart from "../../components/ScoreChart";
import TestTubeChart from "../../components/TestTubeChart";
import { REPORT_ENV } from "../../constants";
import ScoresPopover from "./ScoresPopover";
import "./styles.scss";
import { calculateTestTubeScoreForApp } from "./utils/app.js";
import { calculateTestTubeScoreForWeb } from "./utils/web.js";
import { Link } from "react-router-dom";
import { getAccountData } from "redux/_account/account.selectors";
import { useSelector } from "react-redux";

const Landing = ({
  logo,
  current,
  geographicChartRaw,
  items,
  privacyChartRaw,
  report,
  reportType,
  setItems,
  tips,
  totalPages,
  url,
  flavor,
}) => {
  const [content, setContent] = useState({
    description: "Here's an overview of our assessment of your website.",
    scanned: {
      title: "URL scanned:",
      content: url,
    },
  });
  const [testTubeScore, setTestTubeScore] = useState(0);
  const [totalScoreApp, setTotalScore] = useState(0);
  const [scoresPopoverOpen, setScoresPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isApp, setIsApp] = useState(null);
  const [renderedPDF, renderPDF] = useState(false);
  const [screenshotBlob, setScreenshotBlob] = useState(null);

  const { createdAt, endedAt, environment, serviceTerms, screenshot } = report;
  const detailedReport = report.report || {};
  const { source, privacyScore } = detailedReport;
  const totalScore = privacyScore ? privacyScore.totalPoints : 0;

  const lastScanned = moment(endedAt || createdAt).format("MM-DD-YYYY hh:mm");
  // In order to set it in a 0-5 scale
  let ranking = totalScore / 20;
  if (ranking % 1 !== 0) {
    ranking = Math.floor(ranking) + 0.5;
  }
  let riskName = "Moderate risk";
  if (ranking >= 4) {
    riskName = "Low risk";
  } else if (ranking <= 2) {
    riskName = "High risk";
  }

  const userAccount = useSelector((state) => getAccountData(state));
  const branding = userAccount.logo;

  useEffect(() => {
    setScoresPopoverOpen(false);
  }, [current]);

  useEffect(() => {
    const title = "Domain scanned:";
    if (
      reportType === WEB_REPORT_TYPES.sitemap &&
      content.scanned.title !== title
    ) {
      setContent({
        description: "Here's an overview of our assessment of your domain.",
        scanned: { ...content.scanned, title },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  useEffect(() => {
    if (environment) {
      if (environment === REPORT_ENV.web) {
        setIsApp(false);
        calculateTestTubeScoreForWeb(
          reportType,
          detailedReport,
          serviceTerms,
          items,
          setItems,
          setTestTubeScore
        );
      } else {
        setIsApp(true);
        setContent({
          description: "Here's an overview of our assessment of your app.",
          scanned: {
            title: "App scanned:",
            content: url,
          },
        });
        // TODO(@jperozo): This is a work in progres.
        // Should change in the future.
        calculateTestTubeScoreForApp(
          detailedReport,
          serviceTerms,
          items,
          setItems,
          setTestTubeScore,
          setTotalScore
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, endedAt, createdAt]);

  useEffect(() => {
    (async () => {
      const response = await fetch(report.screenshot, {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      const blob = await response.blob();
      const reader = new FileReader();
      await new Promise((resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      setScreenshotBlob(reader.result);
    })();
  }, [report]);

  const screenshotSrc = screenshot || cyberSecurity;
  let totalDiff = null;
  if (report && report.scores && report.scores.length >= 1) {
    totalDiff =
      report.scores.length === 1
        ? report.scores[0].total
        : report.scores[report.scores.length - 1].total -
          report.scores[report.scores.length - 2].total;
  }

  return (
    <div className="landing">
      <div className="background">
        <span className="circle filled small upper"></span>
        <span className="circle filled small lower"></span>
        <span className="circle filled big"></span>
        <span className="circle empty bigger"></span>
        <span className="circle empty bigger-2"></span>
        <span className="circle empty bigger-3"></span>
      </div>
      <div className="sinopsis">
        <div className="description">
          <h1>Privacy report</h1>
          <span>{content.description}</span>
          <div className="url-scanned">
            <h2>{content.scanned.title}</h2>
            <span>{content.scanned.content}</span>
          </div>
          {totalPages === undefined ? (
            <div className="last-scanned">
              <h2>Last scanned:</h2>
              <span>{lastScanned}</span>
            </div>
          ) : (
            <div className="last-scanned">
              <h2>Total scanned pages:</h2>
              <span>{totalPages}</span>
            </div>
          )}
          {environment === REPORT_ENV.app && source && (
            <div className="app-source">
              <h2>Source:</h2>
              <span>{source}</span>
            </div>
          )}
          {flavor !== "free" && (
            <div className="pdf-button-container">
              {renderedPDF ? (
                <PDFDownloadLink
                  logo={logo}
                  branding={branding}
                  screenshot={screenshotBlob}
                  isApp={isApp}
                  report={report}
                  testTubeScore={testTubeScore}
                  items={items}
                  totalScoreApp={totalScoreApp}
                  totalScore={totalScore}
                  privacyChartRaw={privacyChartRaw}
                  geographicChartRaw={geographicChartRaw}
                  ranking={ranking}
                  riskName={riskName}
                  tips={tips}
                >
                  <Button
                    className="pdf-button"
                    color="primary"
                    variant="contained"
                  >
                    Download PDF
                  </Button>
                </PDFDownloadLink>
              ) : (
                <Button
                  className="pdf-button"
                  color="primary"
                  variant="contained"
                  onClick={() => renderPDF(true)}
                >
                  Download PDF
                </Button>
              )}
            </div>
          )}
        </div>
        <div
          className={clsx(
            "screenshot",
            environment,
            totalPages !== undefined && "-sitemap"
          )}
        >
          <img src={screenshotSrc} alt="scanned" />
          {totalPages !== undefined && (
            <div className="last-scanned">
              <h2>Last scanned:</h2>
              <span>{lastScanned}</span>
            </div>
          )}
        </div>
        <div className="hidden" />
      </div>
      <div className={clsx("scores")}>
        <div className="score">
          <ScoreChart value={isApp ? totalScoreApp : totalScore} />
          <div className="description">
            <span className="title">
              Total Score{" "}
              <InfoIconButton
                href="https://www.zendata.dev/how-we-score"
                variant="clear"
                targetBlank
              />
              <ScoresPopover
                report={report}
                anchorEl={anchorEl}
                scoresPopoverOpen={scoresPopoverOpen}
                setScoresPopoverOpen={setScoresPopoverOpen}
              />
            </span>
            <span className="total-score">
              {isApp ? totalScoreApp : totalScore}/100
            </span>
            {totalDiff !== null && (
              <div
                className={`diff-score ${totalDiff < 0 ? "red" : "green"}`}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setScoresPopoverOpen(true);
                }}
              >
                {totalDiff < 0 ? "↓" : totalDiff !== 0 && "↑"}
                {Math.abs(totalDiff)}
              </div>
            )}
          </div>
        </div>
        <div className="score">
          <TestTubeChart
            score={testTubeScore}
            total={items.length}
            environment={environment}
          />
          <div className="description">
            <span className="title">Tests Passed</span>
            <span className="total-score">
              {testTubeScore}/{items.length}
            </span>
          </div>
        </div>
        {environment === REPORT_ENV.web && (
          <div className="score">
            <GaugeChart value={5 - ranking} />
            <div className="description">
              <span className="title">{riskName}</span>
              <span className="total-score">{5 - ranking}/5</span>
            </div>
          </div>
        )}
      </div>
      <div className="other-products-container">
        <Link
          className="cookie-manager-button"
          to={`/zen/consents/collector/configuration${window.location.search}`}
        >
          Try our cookie consent tooltip generator
        </Link>
        <Link
          className="cookie-manager-button"
          to={`/zen/consents/policy-generator/configuration${window.location.search}`}
        >
          Try our cookie policy generator
        </Link>
      </div>
    </div>
  );
};

export default Landing;
