import {
  PII_POSSIBLE_PERMISSIONS,
  SECURE_PERMISSIONS,
} from "../../../components/Permissions/utils";

const colors = {
  GOOD: "#6FCF97",
  MEDIUM: "#F2C94C",
  BAD: "#EB5757",
};

// TODO(@jperozo): Change to be more accurate
const getColor = (percentage) => {
  if (percentage > 25 && percentage < 85) {
    return colors["MEDIUM"];
  }

  return percentage >= 85 ? colors["GOOD"] : colors["BAD"];
};

const calculatePermissionScore = (permissions) => {
  let foundDangerousPermissions = permissions.filter((permission) =>
    PII_POSSIBLE_PERMISSIONS.includes(permission)
  );
  if (foundDangerousPermissions.length > 0) {
    return 0;
  }

  foundDangerousPermissions = permissions.filter(
    (permission) => !SECURE_PERMISSIONS.includes(permission)
  );
  if (foundDangerousPermissions.length > 1) {
    return 0.5;
  }

  return 1;
};

export const calculateTestTubeScoreForApp = (
  detailedReport,
  serviceTerms,
  items,
  setItems,
  setTestTubeScore,
  setTotalScore
) => {
  // TODO(jperozo): Adding values here for development process
  const {
    // thirdPartyTrackersScore = 1,
    traffic = { hasInsecureTraffic: false, hasMixedContent: false },
    fingerprintingScore = 0.5,
    permissions = [
      "Camera",
      "Contacts",
      "Location",
      "Phone",
      "Microphone",
      "Storage",
    ],
  } = detailedReport;

  const { hasInsecureTraffic, hasMixedContent } = traffic;

  const permissionScore = calculatePermissionScore(permissions);
  const httpsTrafficScore = !hasInsecureTraffic && !hasMixedContent ? 100 : 0;

  const totalColors = [
    getColor(permissionScore * 100),
    getColor(fingerprintingScore * 100),
    getColor(httpsTrafficScore),
  ];

  items[0].image = items[0].getImage(totalColors[0]);
  items[1].image = items[1].getImage(totalColors[1]);
  items[2].image = items[2].getImage(totalColors[2]);

  const score = items.reduce((accumulator, item, index) => {
    // const passed = index !== 0 ? item.value === 100 : thirdPartyTrackersScore > 0.8;
    // TODO(@jperozo: Temporally) score with colors
    const passed = totalColors[index] !== "#EB5757";
    return accumulator + (passed ? 1 : 0);
  }, 0);
  const total = Math.round((score * 100) / items.length);
  setItems(items);
  setTestTubeScore(score);
  setTotalScore(Math.ceil(total / 10) * 10);
};
