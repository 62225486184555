import CONSTANTS from "redux/_databases/databases.constants";
import unionBy from "lodash.unionby";

const initialState = {
  loading: false,
  data: [],
  selectedDb: null,
};

export const databases = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.DATABASES_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.DATABASES_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.DATABASES_SAVE_DATA:
      return {
        ...state,
        loading: false,
        data: unionBy(state.data, data, "id"),
      };
    case CONSTANTS.DATABASES_UPDATE_DATA:
      return {
        ...state,
        loading: false,
        data: state.data.map((el) => (parseInt(el.id) === data.id ? data : el)),
      };
    case CONSTANTS.DATABASES_RESET_STORE:
      return {
        ...state,
        loading: false,
        data: [],
        selectedDb: null,
      };
    case CONSTANTS.DATABASES_SELECT_DB:
      return {
        ...state,
        selectedDb: data,
      };
    case CONSTANTS.DATABASES_DELETE_DB:
      return {
        ...state,
        data: state.data.filter((db) => parseInt(db.id) !== parseInt(data)),
      };
    default:
      return state;
  }
};
