import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";
import { Box } from "@material-ui/core";

import { FormTextInput } from "components/SettingsSections/components/inputs";
import SectionWrapper from "components/SettingsSections/components/sectionWrapper";
import useStyles from "./styles";

const BillingDetails = ({ data, errors, loading, disabled }) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({});
  const { payment_method } = data;

  useEffect(() => {
    if (payment_method) {
      const {
        billing_details: { address, ...rest },
      } = payment_method;
      setInitialValues(rest);
    }
  }, [payment_method]);

  return (
    <SectionWrapper
      title="Billing Details"
      subtitle="Add, edit or remove your payment information."
    >
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Field
            name="name"
            component={FormTextInput}
            label="Name"
            className={classes.fieldSm}
            initialValue={initialValues.name}
            disabled={disabled}
          />
          <Field
            name="email"
            label="Email"
            component={FormTextInput}
            className={classes.fieldSm}
            initialValue={initialValues.email}
            disabled={disabled}
          />
        </Box>
        <Box className={classes.row}>
          <Field
            name="phone"
            component={FormTextInput}
            label="Phone"
            className={classes.fieldSm}
            initialValue={initialValues.phone}
            disabled={disabled}
          />
        </Box>
      </Box>
    </SectionWrapper>
  );
};

export default BillingDetails;
