import React, { useEffect } from "react";
import DataOwnersSunBurst from "charts/TreeMap/DataOwners";
import { connect } from "react-redux";
import {
  getLoading,
  getOwnersTreeFormatted,
  getError,
} from "redux/_charts/charts.selectors";
import { getOwners } from "redux/_charts/charts.async.actions";
import { getFilters } from "redux/_filters/filters.selectors";
import PageWrapper from "components/PageWrapper";
import CONSTANTS from "redux/_filters/filters.constants";
import { formatNumber } from "utils/format";
import colors from "./colors";
import isEmpty from "lodash.isempty";

import { data as mockedData } from "./mocked.json";

const { TYPES } = CONSTANTS;

const sumKpis = (ownersData) => {
  const kpis = {
    tables: 0,
    piiColumns: 0,
  };
  if (!ownersData) return kpis;

  ownersData.forEach((database) => {
    database.children.forEach((owner) => {
      kpis.tables += owner.children.length;
      owner.children.forEach((table) => {
        kpis.piiColumns += table.columns_pii;
      });
    });
  });
  return kpis;
};

const DataOwners = ({ filters, getOwnersData, ownersData, loading, error }) => {
  useEffect(() => {
    getOwnersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const kpis = sumKpis(ownersData);

  return (
    <PageWrapper
      error={error}
      retryFn={getOwnersData}
      title="PII REPORTS"
      subtitle="DATA OWNERS"
      customKpis={[
        {
          value: kpis.tables,
          label: "Total Tables",
          icon: "overview.piicols",
        },
        {
          value: formatNumber(kpis.piiColumns),
          label: "PII Columns",
          icon: "overview.unprotected",
        },
      ]}
      filters={[
        TYPES.ENV,
        TYPES.ENTITY_ROLE,
        TYPES.STORAGE,
        TYPES.BUSINESS_UNIT,
        TYPES.PURPOSE,
        TYPES.COUNTRY,
        TYPES.SCAN,
        TYPES.PII_CATEGORIES,
      ]}
      loading={loading}
      empty={isEmpty(ownersData)}
    >
      <DataOwnersSunBurst
        data={!isEmpty(ownersData) ? ownersData : mockedData}
        colors={[...colors, ...colors]}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  ownersData: getOwnersTreeFormatted(state),
  loading: getLoading(state),
  filters: getFilters(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  getOwnersData: () => dispatch(getOwners()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataOwners);
