import {
  getScansById,
  getAllEnvScans,
  runScanByEnvId,
  fetchPiiGroups,
} from "redux/_scans/scans.service";
import {
  lastTwoRequest,
  lastTwoSuccess,
  getOverviewSuccess,
  getOverviewRequest,
  requestStart,
  requestEnd,
  saveScans,
  setLast,
} from "redux/_scans/scans.actions";
import getProperty from "utils/get-property";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { setScanning } from "redux/_environments/environment.actions";
import {
  getFilterScansDateRange,
  getFilterScansVisibility,
} from "redux/_filters/filters.selectors";
import { getInitialSetupData } from "redux/_settings/settings.async.actions";

export const getLastTwo = ({ envId, force = false }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const lastScans = getProperty(state, `lastTwoScans.${envId}.scansHash`);
  if (!!lastScans && !force) return Promise.resolve();

  dispatch(lastTwoRequest(envId));
  return getScansById({ envId, last: 2, completed: 1 })
    .then((scans) => {
      dispatch(lastTwoSuccess(envId, scans));
      return scans;
    })
    .catch((errors) => Promise.reject(errors));
};

export const getOverview = ({ envId, ...query }) => (dispatch, getState) => {
  dispatch(getOverviewRequest(envId));
  return getScansById({ envId, ...query })
    .then((scans) => {
      dispatch(getOverviewSuccess(envId, scans, query));
      return Promise.resolve(scans);
    })
    .catch((errors) => Promise.reject(errors));
};

export const getScans = (params = {}, withFilters = true) => (
  dispatch,
  getState
) => {
  const state = getState();
  const dateFilter = getFilterScansDateRange(state);
  const visibilityFilter = getFilterScansVisibility(state);
  if (Object.keys(params).includes("lastPerEnv"))
    dispatch(setLast(params.last));
  const envId = getSelectedEnvId(getState());
  if (!envId) return Promise.resolve();
  dispatch(requestStart());
  return getAllEnvScans(
    withFilters
      ? { envId, ...params, dateRange: dateFilter, error: !visibilityFilter }
      : { envId, ...params }
  )
    .then((scans) => {
      dispatch(requestEnd());
      dispatch(saveScans(scans));
      return Promise.resolve(scans);
    })
    .catch((errors) => {
      requestEnd();
      return Promise.reject();
    });
};

export const runScan = (id) => (dispatch, getState) => {
  dispatch(requestStart());
  dispatch(setScanning(true));
  const envId = id || getSelectedEnvId(getState());
  return runScanByEnvId(envId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(getInitialSetupData());
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const getPiiGroupsByScanId = (scanId) => (dispatch, getState) => {
  dispatch(requestStart());
  return fetchPiiGroups(scanId)
    .then((data) => {
      dispatch(requestEnd());
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
