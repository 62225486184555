import React, { useState } from "react";
import "./styles.scss";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import SensitivePiiChart from "components/RepositoryResultsView/SensitiveChart";
import InsensitivePiiChart from "components/RepositoryResultsView/InsensitiveChart";
import ThirdPartyPiiChart from "components/RepositoryResultsView/ThirdPartyChart";
import moment from "moment";
import RepositoryPDFDownloadLink from "components/RepositoryPDFReport/Link";
import { getAccountData } from "redux/_account/account.selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "auto",
      paddingTop: "151px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      width: "45%",
    },
  },
  img: {
    [theme.breakpoints.up("xs")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  landing: {
    width: "65%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginTop: "30px",
    },
  },
  downloadButton: {
    fontSize: "15px",
    padding: "12px 22px",
    backgroundColor: "#6bd4cb",
    color: "#1a3586",
  },
  scanDetailItem: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    fontSize: "20px",
    margin: "26px 0",
  },
  description: {
    width: "100%",
  },
}));
const Landing = ({ content }) => {
  const classes = useStyles();
  let ranking = content.security_score / 20;
  if (ranking % 1 !== 0) {
    ranking = Math.floor(ranking) + 0.5;
  }
  let riskName = "Medium risk";
  if (ranking >= 4) {
    riskName = "Low risk";
  } else if (ranking <= 2) {
    riskName = "High risk";
  }

  const [renderedPDF, renderPDF] = useState(null);

  const userAccount = useSelector((state) => getAccountData(state));
  const branding = userAccount.logo;

  return (
    <div className={classes.container}>
      <div className={clsx("landing", classes.landing)}>
        <div className="sinopsis">
          <div className="app-description">
            <h1 style={{ color: "#6BD4CA" }}>Repository Scan Results</h1>
            {/* {content.description && <span>{content.description}</span>} */}
            <div className={classes.scanDetailItem} key={"url-scanned"}>
              <h2 style={{ fontSize: "1.25rem" }}>URL Scanned:</h2>
              <span style={{ fontSize: "1rem" }}>{content.data.resource}</span>
            </div>
            <div className={classes.scanDetailItem} key={"last-scanned"}>
              <h2 style={{ fontSize: "1.25rem" }}>Last Scanned:</h2>
              <span style={{ fontSize: "1rem" }}>
                {moment(content.data.scanDate).format("MM-DD-YYYY HH:mm")}
              </span>
            </div>
            {/* WIP */}
            <div className="pdf-button-container">
              {renderedPDF ? (
                <RepositoryPDFDownloadLink
                  branding={branding}
                  data={content.data}
                  ranking={ranking}
                  riskName={riskName}
                >
                  <Button
                    className="pdf-button"
                    color="primary"
                    variant="contained"
                  >
                    Download PDF
                  </Button>
                </RepositoryPDFDownloadLink>
              ) : (
                <Button
                  className="pdf-button"
                  color="primary"
                  variant="contained"
                  onClick={() => renderPDF(true)}
                >
                  Download PDF
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={clsx("scores-repo")}>
          <div className="score-repo">
            <SensitivePiiChart data={content.data}></SensitivePiiChart>
            <InsensitivePiiChart data={content.data}></InsensitivePiiChart>
            <ThirdPartyPiiChart data={content.data}></ThirdPartyPiiChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
