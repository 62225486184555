import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getFilters } from "redux/_filters/filters.selectors";
import { resetAllFilters } from "redux/_filters/filters.actions";
import PageWrapper from "components/PageWrapper";
import CONSTANTS from "redux/_filters/filters.constants";
import FunctionalStructure from "charts/TreeMap/FunctionalStructure";
import {
  getFunctionalUses,
  getLoading,
  getError,
} from "redux/_charts/charts.selectors";
import { getFunctionalUsesData } from "redux/_charts/charts.async.actions";
import FunctionalUsesEmptyPage from "components/PiiReports/FunctionalUses/components/EmptyPage";
import isEmpty from "lodash.isempty";

import Tooltip from "./Tooltip";

import { data as mockedData } from "./mocked.json";

const { TYPES } = CONSTANTS;

const FunctionalUses = ({
  filters,
  functionalUsesData: data,
  getFunctionalUsesData,
  loading,
  resetFilters,
  error,
}) => {
  useEffect(
    () => {
      getFunctionalUsesData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const needsToMap = useMemo(() => {
    if (!data) return false;
    return data.every((d) =>
      d.children.every((e) => e.children.every((f) => !f.name))
    );
  }, [data]);

  const getRatios = (treeNode) => {
    if (treeNode.children) {
      return {
        ...treeNode,
        ratio: treeNode.columnsPii / treeNode.columns,
        children: treeNode.children.map((child) => getRatios(child)),
      };
    } else {
      return {
        ...treeNode,
        ratio: treeNode.columnsPii / treeNode.columns,
      };
    }
  };

  const tree = !isEmpty(data) ? data : mockedData;
  const treeData = [getRatios(tree[0])];

  return (
    <PageWrapper
      error={error}
      retryFn={getFunctionalUsesData}
      showKpis
      title="PII REPORTS"
      subtitle="FUNCTIONAL USES"
      filters={[
        TYPES.ENV,
        TYPES.ENTITY_ROLE,
        TYPES.STORAGE,
        TYPES.BUSINESS_UNIT,
        TYPES.PURPOSE,
        TYPES.COUNTRY,
        TYPES.SCAN,
        TYPES.PII_CATEGORIES,
      ]}
      loading={loading}
      empty={isEmpty(data)}
    >
      {needsToMap ? (
        <FunctionalUsesEmptyPage
          hasFilters={
            filters[TYPES.PURPOSE] !== "all" ||
            filters[TYPES.BUSINESS_UNIT] !== "all"
          }
          reset={resetFilters}
        />
      ) : (
        <FunctionalStructure
          data={treeData}
          loading={loading}
          colors={["#dae9ff", "#a2c1ff", "#4767b8", "#12286b"]}
          tooltip={Tooltip}
        />
      )}
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  functionalUsesData: getFunctionalUses(state),
  loading: getLoading(state),
  filters: getFilters(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFunctionalUsesData: () => dispatch(getFunctionalUsesData()),
  resetFilters: () => dispatch(resetAllFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalUses);
