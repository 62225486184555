import React, { Fragment } from "react";
import HoverableArea from "./HoverableArea";

const Axis = ({
  height,
  width,
  type,
  ticks,
  onMouseOver,
  hoverContainer,
  hovered,
  label,
  compact,
  xMargin,
}) => {
  const isX = type === "x";
  const isY = type === "y";

  const handleMouseOver = (tick) => onMouseOver && onMouseOver(tick);
  return (
    <>
      <g
        transform={
          isX
            ? `translate(${width / 2 - xMargin / 2} ${height + 50})`
            : `translate(${compact ? -45 : -50} ${height / 2})`
        }
      >
        <text
          fontSize=".8rem"
          fontWeight="bold"
          fill="#4f4f4f"
          fontFamily="Roboto Condensed, sans-serif"
          transform={isY ? "rotate(-90)" : ""}
          textAnchor={isY ? undefined : "middle"}
        >
          {label}
        </text>
      </g>
      {isX && (
        <line
          stroke="black"
          strokeWidth={1}
          y1={height}
          strokeDasharray={0}
          transform={`translate(${-xMargin / 2})`}
        />
      )}
      {ticks.map((tick, i) => {
        const { position, value, label } = tick;
        const axisPosition = isX ? [position, 0] : [0, position];
        return (
          <Fragment key={value}>
            <g
              style={{ transition: "none" }}
              transform={`translate(${axisPosition})`}
            >
              <line
                stroke={
                  i === 0 && isY
                    ? "black"
                    : i === hovered
                    ? "blue"
                    : "lightgray"
                }
                strokeWidth={i === 0 && isY ? 1.5 : 0.5}
                y1={isX ? height : undefined}
                x1={isY ? width : undefined}
                strokeDasharray={i === 0 && isY ? 0 : 5}
              />
              <text
                fontFamily="Roboto Condensed, sans-serif"
                textAnchor={isX ? "center" : "end"}
                fontSize=".8rem"
                transform={
                  isX ? `translate(-12 ${height + 20})` : "translate(-14 0)"
                }
              >
                {label}
              </text>
            </g>
            {isX && (
              <HoverableArea
                position={axisPosition}
                container={hoverContainer}
                value={value}
                width={width / ticks.length}
                height={height}
                onMouseOver={handleMouseOver}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Axis;
