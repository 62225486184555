import palette from "./palette";

const FONT_FAMILY =
  '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
const TITLE_FONT = '"Aldrich", "Inter", sans-serif';

export default {
  htmlFontSize: 16,
  fontFamily: FONT_FAMILY,
  titleFont: TITLE_FONT,
  fontSize: 14,
  fontSizeSmall: "0.75rem",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  "@media (min-width:600px)": {
    fontSize: 15,
  },
  "@media (min-width:992px)": {
    fontSize: 16,
  },
  h1: {
    lineHeight: 1.3,
    fontFamily: TITLE_FONT,
    fontSize: 40,
    fontWeight: 400,
    color: palette.text.title,
  },
  h2: {
    fontWeight: 400,
    fontSize: 32,
    fontFamily: TITLE_FONT,
    lineHeight: 1.3,
    letterSpacing: "0",
    color: palette.text.title,
  },
  h3: {
    fontWeight: 600,
    fontSize: "2.5rem",
    lineHeight: 1.2,
    letterSpacing: "0",
  },
  h4: {
    fontWeight: "bold",
    fontSize: "1.125rem",
    lineHeight: "1.29",
    color: "#828282",
    fontFamily: "Roboto Condensed",
  },
  h5: {
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: 1.2,
    letterSpacing: "0",
  },
  h6: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: 1.4,
    letterSpacing: "0",
    textTransform: "uppercase",
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    // color: "#8794ba",
    color: "#686c78",
  },
  li: {
    fontFamily: FONT_FAMILY,
    fontSize: "1rem",
    color: "#8794ba",
  },
};
