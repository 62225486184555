import CONSTANTS from "redux/_environments/environment.constants";
import isEmpty from "lodash.isempty";
import isNull from "lodash.isnil";
import unionBy from "lodash.unionby";

const initialState = {
  loading: false,
  selectedId: null,
  isScanning: false,
  data: [],
};

export function environments(state = initialState, { type, data }) {
  switch (type) {
    case CONSTANTS.ENVIRONMENTS_GET_ALL_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.ENVIRONMENTS_GET_ALL_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.ENVIRONMENTS_SAVE_DATA:
      const selectedEnv = state.selectedId || data[0].id;
      return {
        ...state,
        data: unionBy(state.data, data, "id"),
        selectedId: selectedEnv,
      };
    case CONSTANTS.ENVIRONMENTS_SELECT_ENV:
      const scanningState =
        isNull(data) || isNull(state.data)
          ? false
          : isEmpty(state.data)
          ? state.isScanning
          : state.data.find(({ id }) => parseInt(id) === parseInt(data))
              .is_scanning;
      return {
        ...state,
        selectedId: data,
        isScanning: scanningState,
      };
    case CONSTANTS.ENVIRONMENTS_SET_IS_SCANNING:
      const { selectedId, data: stateData } = state;
      const envData = !isNull(selectedId)
        ? stateData.find(({ id }) => parseInt(id) === parseInt(selectedId))
        : {};
      return {
        ...state,
        data: stateData.map((el) =>
          parseInt(el.id) === parseInt(envData.id)
            ? { ...envData, is_scanning: data }
            : el
        ),
        isScanning: data,
      };
    case CONSTANTS.ENVIRONMENTS_CLEAR_ENV:
      return {
        ...state,
        selectedId: null,
      };
    case CONSTANTS.ENVIRONMENTS_UPDATE_ENV:
      return {
        ...state,
        data: state.data.map((env) =>
          parseInt(env.id) === parseInt(data.id) ? data : env
        ),
      };
    case CONSTANTS.ENVIRONMENTS_DELETE_ENV:
      const newEnvs = state.data.filter(
        (env) => parseInt(env.id) !== parseInt(data)
      );
      return {
        ...state,
        data: newEnvs,
        selectedId:
          parseInt(state.selectedId) === parseInt(data) && newEnvs.length > 0
            ? newEnvs[0].id
            : null,
      };
    default:
      return state;
  }
}
