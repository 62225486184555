import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import isArray from "lodash.isarray";
import getCollectorRandomStatistics from "utils/getCollectorRandomStatistics";

export const getAll = (widgetToken) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const q = widgetToken
    ? `?${new URLSearchParams({ widget_token: widgetToken })}`
    : "";
  const url = `${config.apiUrl}/consents_collector/policies${q}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) =>
      isArray(data)
        ? data.map((el) => ({
            ...el,
            statistics: getCollectorRandomStatistics(),
          }))
        : data
    );
};

export const create = (policyData) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(policyData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/consents_collector/policies`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => ({
      ...data,
      statistics: getCollectorRandomStatistics(),
    }));
};

export const update = (id, policyData) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(policyData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/consents_collector/policies/${id}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => ({
      ...data,
      statistics: getCollectorRandomStatistics(),
    }));
};

export const deletePolicyData = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/consents_collector/policies/${id}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => ({
      ...data,
      statistics: getCollectorRandomStatistics(),
    }));
};

export const updateTablePolicy = (dbId, tableId, policyId) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      id_table: tableId,
      id_policy: policyId,
    }),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/databases/${dbId}/tables`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
