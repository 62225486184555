import { TagCard } from "./TagCard";
import useParentStyles from "./styles.js";

export const AmountLoadTimeTagsCard = ({
    statistics
  }) => {
    const parentClasses = useParentStyles();

    const listOfProgressBar = [
        {
            color: '#468C49',
            text: statistics.loadTimeLessThan500.totalOfLoadTimeLessThan500,
            percentage: statistics.loadTimeLessThan500.percentage
        },
        {
            color: '#CBAD0F',
            text: statistics.loadTimeLessThan999.totalOfLoadTimeLessThan999,
            percentage: statistics.loadTimeLessThan999.percentage
        },
        {
            color: '#EF7D1F',
            text: statistics.loadTimeLessThan1999.totalOfLoadTimeLessThan1999,
            percentage: statistics.loadTimeLessThan1999.percentage
        },
        {
            color: '#D32F2F',
            text: statistics.loadTimeMoreThan2000.totalOfLoadTimeMoreThan2000,
            percentage: statistics.loadTimeMoreThan2000.percentage
        }
    ];

    const listOfReferences = [
        {
            style: parentClasses.gridTr,
            components: [
                {
                    bodyContent: "Below 500",
                    colour: parentClasses.referenceSquareGreen,
                },
                {
                    bodyContent: "500 - 999",
                    colour: parentClasses.referenceSquareYellow,
                },
            ]
        },
        {
            style: parentClasses.cardTr,
            components: [
                {
                    bodyContent: "1000 - 1999",
                    colour: parentClasses.referenceSquareOrange,
                },
                {
                    bodyContent: "2000+",
                    colour: parentClasses.referenceSquareRed,
                },
            ]
        }
    ];
  
    return (
        <TagCard
            title="TAG LOAD TIMES (MS)"
            bodyComponentsList={listOfProgressBar}
            footerComponentsList={listOfReferences}
        />
    );
  };