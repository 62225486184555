import { CONSTANTS } from "redux/_businessUnits/businessUnits.constants";

export const requestStart = () => ({
  type: CONSTANTS.BUSINESS_UNITS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.BUSINESS_UNITS_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.BUSINESS_UNITS_SAVE_DATA,
  data,
});
