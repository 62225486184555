import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "32px",
    color: theme.palette.grey[200],
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  description: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "26px",
    textAlign: "center",
    color: theme.palette.grey[300],
    marginBottom: theme.spacing(3),
  },
  mainButton: {
    background: theme.palette.blue["young"],
    borderRadius: 4,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: 1,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
      background: theme.palette.blue[500],
      color: theme.palette.common.white,
    },
  },
  secondaryButton: {
    border: `2px solid ${theme.palette.blue["young"]}`,
    borderRadius: 4,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: 1,
    color: theme.palette.blue["young"],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  image: {
    margin: "auto",
  },
}));
