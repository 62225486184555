import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
export const Loading = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <CircularProgress style={{ width: 40, height: 40 }} />
    </Box>
  );
};
