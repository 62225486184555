import { Hidden } from "@material-ui/core";
import DataStructureTreeMap from "charts/TreeMap/DataStructure";
import { data as mockedData } from "pages/DataMap/mocked.json";
import PIITable from "./PIITable";
import useStyles from "./styles";

const processData = (data) => {
  const processedData = {
    name: "PII",
    type: "environment",
    matches: 300,
    problematic: 0,
    unprotected: 300,
    totalRows: 100,
    scannedRows: 100,
    tables: 1,
    tablesPii: 1,
    columns: 20,
    columnsPii: 6,
    children: [],
  };
  Object.keys(data).forEach((pageUrl) => {
    let add = false;
    const root = {
      name: pageUrl,
      type: "datasource",
      matches: 300,
      problematic: 0,
      unprotected: 300,
      totalRows: 100,
      scannedRows: 100,
      tables: 1,
      tablesPii: 1,
      columns: 20,
      columnsPii: 6,
      children: [],
    };
    Object.keys(data[pageUrl]).forEach((loadedLibrary) => {
      let withPii = false;
      const libraryChildren = {
        name: loadedLibrary,
        type: "database",
        matches: 300,
        problematic: 0,
        unprotected: 300,
        totalRows: 100,
        scannedRows: 100,
        tables: 1,
        tablesPii: 1,
        columns: 20,
        columnsPii: 6,
        children: [],
      };
      data[pageUrl][loadedLibrary].sensitivePIIs.forEach((pii) => {
        withPii = true;
        add = true;
        libraryChildren.children.push({
          name: pii,
          type: "pii_category",
          matches: 1,
          problematic: 0,
          unprotected: 1,
          totalRows: 100,
          scannedRows: 100,
          tables: 1,
          tablesPii: 1,
          columns: 1,
          columnsPii: 1,
          children: [],
        });
      });
      data[pageUrl][loadedLibrary].pIIs.forEach((pii) => {
        withPii = true;
        add = true;
        libraryChildren.children.push({
          name: pii,
          type: "pii_group",
          matches: 1,
          problematic: 0,
          unprotected: 1,
          totalRows: 100,
          scannedRows: 100,
          tables: 1,
          tablesPii: 1,
          columns: 1,
          columnsPii: 1,
          children: [],
        });
      });
      if (withPii) root.children.push(libraryChildren);
    });
    if (add) processedData.children.push(root);
  });
  return processedData;
};

const PII = ({ activePage, data }) => {
  const processedData = data ? processData(data) : {};
  const classes = useStyles();
  return data ? (
    <>
      <Hidden smDown>
        <DataStructureTreeMap
          data={processedData || mockedData}
          colors={["#dae9ff", "#a2c1ff", "#4767b8", "#12286b"]}
          disableScrolling={!activePage}
          wider
        />
      </Hidden>
      <Hidden mdUp>
        <PIITable data={data} />
      </Hidden>
    </>
  ) : (
    <div className={classes.sorry}>
      <h3 className={classes.title}>Sorry!</h3>
      <p className={classes.paragraph}>
        We couldn't find your privacy policy link, please enter it manually to
        re-run our natural language processor engine and give you the complete
        policy analysis.
      </p>
    </div>
  );
};

export default PII;
