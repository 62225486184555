import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";

import useParentStyles from "./styles.js";

export const VariablesTable = ({
    trackerTitle,
    filteredVariables,
    variableInventory,
    currentReportJSON,
    limitPerPage,
    start,
    endItems,
  }) => {
    const parentClasses = useParentStyles();
  
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="variable table" className={parentClasses.table}>
                    <TableHead className={parentClasses.tableVariableHead}>
                    <TableRow className={parentClasses.tableVariableRow}>
                        <TableCell className={parentClasses.tableVariableHeaderCell}>{trackerTitle}</TableCell>
                    </TableRow>
                    <TableRow className={parentClasses.tableVariableRow}>
                        <TableCell className={parentClasses.tableVariableCell}>{filteredVariables.length} variables</TableCell>
                    </TableRow>
                    </TableHead>
                </Table>
                </TableContainer>
                <Box>
                <Table>
                    <TableHead className={parentClasses.tableHead}>
                    <TableRow className={parentClasses.tableHeadRow}>
                        <TableCell className={parentClasses.headerCellName}>Variable</TableCell>
                        <TableCell className={parentClasses.headerCell}>Unique Values</TableCell>
                        <TableCell className={parentClasses.headerCell}>Pages Where Is Set</TableCell>
                        <TableCell className={parentClasses.headerCell}>Pages Where is Not Set</TableCell>
                    </TableRow>
                    </TableHead>
                </Table>
                </Box>
                <TableContainer component={Paper} className={parentClasses.tableContainer}>
                <Table aria-label="variable inventory table">
                    <TableBody>
                    {((limitPerPage) ? filteredVariables.slice(start, endItems) : filteredVariables).map((variable, index) => (
                        <TableRow key={index}>
                        <TableCell className={`${parentClasses.tableCellName} ${parentClasses.boldCell}`}>{variable}</TableCell>
                        <TableCell className={parentClasses.tableCell}>{variableInventory[variable].unique_values.length}</TableCell>
                        <TableCell className={parentClasses.tableCell}>{variableInventory[variable].tags.length}</TableCell>
                        <TableCell className={parentClasses.tableCell}>{currentReportJSON.trackers.length - variableInventory[variable].tags.length}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
  };