import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  inputLabel: {
    color: ({ light }) =>
      light ? theme.palette.common.white : theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    lineHeight: "24px",
    marginBottom: 0,
  },
  icon: {
    marginRight: "10px",
  },
  inputContainer: {
    width: "100%",
    display: "block",
    marginBottom: 0,
  },
  helperText: {
    lineHeight: "22px",
    fontSize: "12px",
    color: ({ light, error }) => {
      if (light) {
        return error ? theme.palette.red[500] : theme.palette.common.white;
      }
      return error ? theme.palette.red[500] : theme.palette.grey[800];
    },
    fontWeight: 500,
    marginTop: 3,
    marginBottom: 0,
    "&.autocomplete": {
      color: "red",
    },
  },
  textField: {
    backgroundColor: "#f6f9fe",
    borderRadius: "6px",
    "&.autocomplete": {
      height: "45px !important",
      "&.-error": {
        "& > div": {
          borderColor: theme.palette.red[500],
          "& > .MuiAutocomplete-endAdornment": {
            "& > button > span": {
              color: theme.palette.red[500],
            },
          },
        },
      },
      "&.multiple": {
        height: "87px !important",
        "& > div": {
          overflowY: ({ quantity }) => (quantity > 3 ? "auto" : "hidden"),
          height: "87px !important",
        },
      },
      "& > div": {
        borderColor: "#cfdff8",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "6px",
        padding: "2px 2px 40px",
        "&.Mui-focused": {
          borderWidth: "2px",
          borderColor: theme.palette.blue[600],
          "& > fieldset": {
            borderWidth: 0,
          },
        },
        "& > .MuiInputBase-input": {
          "&::placeholder": {
            color: theme.palette.blue[300],
            opacity: 1,
            fontStyle: "italic",
            fontSize: "16px",
          },
        },
        "& > .MuiAutocomplete-endAdornment": {
          padding: 0,
        },
        "& > .MuiAutocomplete-tag": {
          padding: 0,
          backgroundColor: theme.palette.blue[600],
          "& > .MuiChip-avatar": {
            backgroundColor: theme.palette.blue[600],
          },
          "& > svg": {
            color: "#6bd4cb",
          },
        },
        "& > fieldset": {
          borderWidth: 0,
        },
      },
    },
    "& > div": {
      color: `${theme.palette.blue[600]}`,
      fontSize: "15px",
      backgroundColor: theme.palette.common.white,
      "& > div": {
        padding: "10px 26px 10px 14px",
      },
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.grey[200],
      margin: "0.5rem 0 0",
    },
    "& fieldset:hover": {
      border: `solid 1px ${theme.palette.blue[600]}`,
    },
    "&::placeholder": {
      fontStyle: "italic",
    },
    "&::-webkit-placeholder": {
      fontStyle: "italic",
    },
  },
  selectContainer: {
    height: 34,
    backgroundColor: ({ light }) =>
      light ? "transparent !important" : "inherit",
  },
  selectMenu: {
    backgroundColor: "#f6f9fe",
    borderRadius: "6px",
    color: theme.palette.blue[600],
    border: `solid 1px ${theme.palette.blue[200]}`,
  },
  menuItem: {
    fontSize: "15px",
  },
}));
