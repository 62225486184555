import React from "react";
import {
  makeStyles,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    "&$checked": {},
    "&.Mui-disabled": {},
  },
  label: {
    color: "white",
    marginBottom: theme.spacing(2),
    "& > .MuiButtonBase-root": {
      "& > .MuiIconButton-label": {
        color: "white",
      },
    },
  },
  labelText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "19px",
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  optional: {
    color: theme.palette.aquamarine[100],
    fontStyle: "italic",
  },
}));

const Checkbox = ({ label, optional, classes = {}, ...restProps }) => {
  const defaultClasses = useStyles(classes);
  return (
    <FormControlLabel
      classes={{
        root: defaultClasses.label,
        label: defaultClasses.labelText,
      }}
      control={
        <MuiCheckbox
          color="default"
          className={classes.checkbox}
          {...restProps}
        />
      }
      label={
        <>
          {label} {optional && <span className={classes.optional}>(Opt.)</span>}
        </>
      }
    />
  );
};
export default Checkbox;
