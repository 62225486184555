import config from "config";
import qs from "utils/queryString";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

export const getData = (filters) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const appliedFilters = {};
  if (filters[TYPES.CONSENTS.CUSTOMER_VISIBILITY]) {
    appliedFilters.customer = 1;
  }

  appliedFilters.decision = filters[TYPES.CONSENTS.DECISION];

  const url = `${config.apiUrl}/consents_collector/mapchart${qs(
    appliedFilters
  )}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
