import { makeStyles } from "@material-ui/core";

import { sectionDescriptionMixin } from "components/PageReportsWrapper/styles";

export default makeStyles((theme) => ({
  description: {
    ...sectionDescriptionMixin(theme),
    marginTop: theme.spacing(10),
  },
}));
