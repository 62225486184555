import React from "react";

const MicTeams = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.32 12.1621H30.5857C31.3666 12.1621 31.9997 12.7952 31.9997 13.5761V21.105C31.9997 23.975 29.6731 26.3016 26.803 26.3016H26.7785C23.9084 26.302 21.5815 23.9758 21.5811 21.1057C21.5811 21.1055 21.5811 21.1052 21.5811 21.105V12.9011C21.5811 12.493 21.9119 12.1621 22.32 12.1621Z"
      fill="#5059C9"
    />
    <path
      d="M27.9065 10.6762C29.756 10.6762 31.2553 9.17687 31.2553 7.32735C31.2553 5.47784 29.756 3.97852 27.9065 3.97852C26.0569 3.97852 24.5576 5.47784 24.5576 7.32735C24.5576 9.17687 26.0569 10.6762 27.9065 10.6762Z"
      fill="#5059C9"
    />
    <path
      d="M17.4886 10.6744C20.1601 10.6744 22.3258 8.50874 22.3258 5.83721C22.3258 3.16569 20.1601 1 17.4886 1C14.8171 1 12.6514 3.16569 12.6514 5.83721C12.6514 8.50874 14.8171 10.6744 17.4886 10.6744Z"
      fill="#7B83EB"
    />
    <path
      d="M23.9381 12.1621H10.2941C9.52253 12.1812 8.91215 12.8216 8.93005 13.5932V22.1804C8.8223 26.8109 12.4857 30.6535 17.1161 30.7668C21.7465 30.6535 25.4099 26.8109 25.3022 22.1804V13.5932C25.32 12.8216 24.7097 12.1812 23.9381 12.1621Z"
      fill="#7B83EB"
    />
    <path
      opacity="0.1"
      d="M17.8603 12.1621V24.1956C17.8566 24.7474 17.5222 25.2432 17.0119 25.4533C16.8495 25.522 16.6749 25.5574 16.4985 25.5575H9.58496C9.48822 25.3119 9.39892 25.0663 9.32449 24.8133C9.064 23.9593 8.93108 23.0716 8.93007 22.1789V13.5909C8.91218 12.8205 9.52156 12.1812 10.2919 12.1621H17.8603Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M17.1161 12.1621V24.9398C17.1161 25.1162 17.0807 25.2908 17.0119 25.4533C16.8018 25.9635 16.3061 26.298 15.7543 26.3016H9.93474C9.80822 26.0561 9.68916 25.8105 9.58496 25.5575C9.48077 25.3044 9.39892 25.0663 9.32449 24.8133C9.064 23.9594 8.93108 23.0716 8.93007 22.1789V13.5909C8.91218 12.8205 9.52156 12.1812 10.2919 12.1621H17.1161Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M17.1161 12.1621V23.4514C17.1105 24.2012 16.504 24.8076 15.7543 24.8133H9.32449C9.064 23.9594 8.93108 23.0716 8.93007 22.1789V13.5909C8.91218 12.8205 9.52156 12.1812 10.2919 12.1621H17.1161Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M16.3721 12.1621V23.4514C16.3664 24.2012 15.76 24.8076 15.0102 24.8133H9.3245C9.064 23.9594 8.93108 23.0716 8.93007 22.1789V13.5909C8.91218 12.8205 9.52157 12.1812 10.292 12.1621H16.3721Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M17.8605 8.31499V10.6592C17.734 10.6666 17.6149 10.6741 17.4884 10.6741C17.3619 10.6741 17.2428 10.6666 17.1163 10.6592C16.8651 10.6425 16.616 10.6027 16.3721 10.5401C14.8652 10.1832 13.6201 9.12637 13.0233 7.69731C12.9206 7.45733 12.8409 7.20814 12.7852 6.95312H16.4986C17.2496 6.95597 17.8577 7.56402 17.8605 8.31499Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M17.1166 9.05909V10.6591C16.8654 10.6424 16.6163 10.6026 16.3724 10.54C14.8654 10.1831 13.6203 9.12629 13.0234 7.69727H15.7547C16.5057 7.70011 17.1138 8.30816 17.1166 9.05909Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M17.1166 9.05909V10.6591C16.8654 10.6424 16.6163 10.6026 16.3724 10.54C14.8654 10.1831 13.6203 9.12629 13.0234 7.69727H15.7547C16.5057 7.70011 17.1138 8.30816 17.1166 9.05909Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M16.3723 9.05913V10.5401C14.8653 10.1832 13.6203 9.1263 13.0234 7.69727H15.0104C15.7614 7.70012 16.3694 8.30818 16.3723 9.05913Z"
      fill="black"
    />
    <path
      d="M1.36409 7.69727H15.008C15.7614 7.69727 16.3721 8.308 16.3721 9.06135V22.7053C16.3721 23.4586 15.7613 24.0693 15.008 24.0693H1.36409C0.610716 24.0693 0 23.4586 0 22.7053V9.06135C0 8.308 0.61073 7.69727 1.36409 7.69727Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M11.7756 12.8919H9.04818V20.3189H7.31051V12.8919H4.5957V11.4512H11.7756V12.8919Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2.84416"
        y1="6.63139"
        x2="13.5279"
        y2="25.1352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset="0.5" stopColor="#4D55BD" />
        <stop offset={1} stopColor="#3940AB" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicTeams;
