import React from "react";

const helpIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.42585 0.627337C2.96185 0.929737 1.07185 2.74414 0.623846 5.13454C0.0638457 8.17294 1.98185 11.1393 4.90785 11.7585C5.06185 11.7873 6.64384 11.9457 7.83384 11.5425C9.84984 10.7361 11.0818 9.25294 11.4458 7.03534C11.4598 6.99214 11.6418 5.17774 10.9138 3.70894C9.94784 1.67854 7.81984 0.324937 5.42585 0.627337ZM5.91584 9.65614C5.46784 9.65614 5.13184 9.31054 5.13184 8.86414C5.13184 8.37454 5.45385 8.02894 5.91584 8.02894C6.37784 8.01454 6.72784 8.37454 6.72784 8.84974C6.72784 9.31054 6.39184 9.65614 5.91584 9.65614ZM7.65184 5.89774C7.45584 6.07054 7.25984 6.22894 7.04984 6.37294C6.74184 6.58894 6.55984 6.92014 6.55984 7.35214C6.15384 7.35214 5.71984 7.35214 5.29984 7.35214C5.24384 6.89134 5.27184 6.37294 5.67785 6.04174C5.91584 5.85454 6.16784 5.69614 6.36384 5.49454C6.54584 5.29294 6.72784 5.04814 6.81184 4.80334C6.90984 4.48654 6.67184 4.11214 6.40585 4.01134C6.01384 3.86734 5.53784 3.98254 5.36984 4.31374C5.27184 4.50094 5.21584 4.73134 5.13184 4.96174C4.73984 4.96174 4.34785 4.96174 3.95585 4.96174C3.82985 4.31374 4.17985 3.52174 4.76785 3.13294C5.57985 2.61454 6.96584 2.72974 7.67984 3.39214C8.39384 4.04014 8.37984 5.24974 7.65184 5.89774Z"
      fill="#1A3586"
    />
  </svg>
);

export default helpIcon;
