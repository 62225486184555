import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  // LinearProgress
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import isEmpty from "lodash.isempty";
import useStyles from "./styles";
import { columns } from "./constants";
import { formatNumber } from "utils/format";
import SvgIcon from "components/svg-icon/svg-icon";
import moment from "moment";

const Status = (props) => {
  const classes = useStyles();

  const { environments = [], totals, scans } = props;

  return (
    <Box className={classes.tableWrapper}>
      <Table stickyHeader size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  textTransform: "uppercase",
                }}
                classes={{ root: classes.tableCell }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {environments && !isEmpty(environments) && !isEmpty(scans) ? (
            environments.map((row, idx) => {
              const isEven = !((idx + 1) & 1);
              const lastScanStats = isEmpty(scans)
                ? {}
                : scans.find(
                    ({ environmentId }) =>
                      parseInt(environmentId) === parseInt(row.id)
                  );
              const status = lastScanStats ? lastScanStats.status : "NO_SCANS";
              const error =
                status !== "SUCCESS" &&
                status !== "IN_PROGRESS" &&
                status !== "NO_SCANS";
              const thereAreScans = status !== "NO_SCANS";
              return (
                <TableRow
                  hover
                  key={row.id}
                  style={{
                    backgroundColor: isEven
                      ? "rgba(224, 224, 224, 0.2)"
                      : "white",
                  }}
                  tabIndex={-1}
                >
                  <TableCell
                    classes={{ root: classes.tableCell }}
                    key={columns[0].id}
                    align={columns[0].align}
                  >
                    {row[columns[0].id]}
                  </TableCell>
                  {error ? (
                    <TableCell colSpan={4} style={{ padding: "2px" }}>
                      <Box className={classes.errorCell}>
                        <Box className={classes.errorContainer}>
                          <SvgIcon icon="warning" />
                          <Typography className={classes.errorText}>
                            Last scan could not be completed.
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  ) : thereAreScans ? (
                    <>
                      <TableCell key={columns[1].id} align={columns[1].align}>
                        {formatNumber(lastScanStats[columns[1].id])}
                      </TableCell>
                      <TableCell key={columns[2].id} align={columns[2].align}>
                        {formatNumber(lastScanStats[columns[2].id])}
                      </TableCell>
                      <TableCell key={columns[3].id} align={columns[3].align}>
                        {formatNumber(lastScanStats[columns[3].id])}
                      </TableCell>
                      <TableCell key={columns[4].id} align={columns[4].align}>
                        {status === "SUCCESS"
                          ? moment(lastScanStats[columns[4].id]).format(
                              "MM/DD/YYYY HH:mm"
                            )
                          : status === "IN_PROGRESS"
                          ? "In Progress"
                          : ""}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell colSpan={4} style={{ padding: "2px" }} />
                  )}
                  <TableCell key={columns[5].id} align={columns[5].align}>
                    <NavLink to={`/zen/environment/${row.id}/scans`}>
                      <SvgIcon
                        className={classes.reportIcon}
                        icon="envsScans"
                        color={"rgba(26, 53, 134, 0.5)"}
                      />
                    </NavLink>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Box my={6} width="100%" textAlign="center">
                  No data to show.
                </Box>
              </TableCell>
            </TableRow>
          )}
          <TableRow hover key={"total"}>
            <TableCell
              classes={{ root: classes.tableCell }}
              key={0}
              className={classes.footer}
            >
              TOTALS
            </TableCell>
            <TableCell
              key={1}
              className={classes.footer}
              align={columns[2].align}
            >
              {formatNumber(totals[columns[1].id])}
            </TableCell>
            <TableCell
              key={2}
              className={classes.footer}
              align={columns[3].align}
            >
              {formatNumber(totals[columns[2].id])}
            </TableCell>
            <TableCell
              key={3}
              className={classes.footer}
              align={columns[4].align}
            >
              {formatNumber(totals[columns[3].id])}
            </TableCell>
            <TableCell key={4} className={classes.footer} />
            <TableCell key={5} className={classes.footer} />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default Status;

/*



 */
