import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import SeverityPanels from "pages/AppReport/common/SeverityPanels";

const ATSAnalysisSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.atsAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.atsAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">
          Check the Shared Library Binary Analysis
        </div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title>{SECTIONS_CONTENT.atsAnalysis.title}</Title>
      <SeverityPanels
        data={data}
        columns={[
          {
            key: "issue",
            label: "Issue",
            width: "35%",
          },
          {
            key: "description",
            label: "Description",
            width: "65%",
          },
        ]}
        emptyMessage="No App Transport Security for this severity"
      />
    </Page>
  );
};

export default ATSAnalysisSection;
