import React, { useState, useEffect } from "react";
import Page from "components/PrivacyReports/Report/components/Page";

import PiiViolations from "../PiiViolations";
import ThirdPartyLibraries from "../ThirdPartyLibraries";
import Secrets from "../Secrets";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { Box, Typography } from "@material-ui/core";
import { SECTIONS_CONTENT } from "pages/RepositoryResults/constants";

const MainSection = ({ data, goToNextSectionAction }) => {
  const [filteredSecretData, setFilteredSecretData] = useState([]);
  const [filteredPiiViolationsData, setFilteredPiiViolationsData] = useState(
    []
  );
  const [thirdPartyData, setThirdPartyData] = useState([]);

  const formattedData = (data) => {
    const flattenedArray = [];

    for (const key in data.pii) {
      if (Array.isArray(data.pii[key])) {
        flattenedArray.push(...data.pii[key]);
      }
    }
    const third_partyPIIArray = flattenedArray.filter(
      (obj) => obj.type === "third_party"
    );

    const formattedData = third_partyPIIArray.reduce((result, currentItem) => {
      const ownerName = currentItem.owner;
      const existingOwnerEntry = result.find(
        (entry) => entry.name === ownerName
      );
      if (!existingOwnerEntry) {
        const newOwnerEntry = {
          name: ownerName,
          elements: [currentItem],
        };
        result.push(newOwnerEntry);
      } else {
        existingOwnerEntry.elements.push(currentItem);
      }

      return result;
    }, []);

    return formattedData;
  };

  const removeDuplicates = (arr) => {
    const seen = new Set();
    return arr.filter((obj) => {
      const key = `${obj.Date}_${obj.File}_${obj.RuleID}`;
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  };

  const piiViolationsData = (data) => {
    return Object.entries(data.pii)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .filter((item) => item.value.length > 0);
  };

  useEffect(() => {
    if (data) {
      const dataFormated = formattedData(data);
      setThirdPartyData(dataFormated);
      setFilteredSecretData(removeDuplicates(data.secrets));
      setFilteredPiiViolationsData(piiViolationsData(data));
    }
  }, [data]);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      key={SECTIONS_CONTENT.piiViolations.key}
      footerBackgroundColor={SECTIONS_CONTENT.piiViolations.backgroundColor}
      hasFooter={false}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <TabContext value={value}>
        <AppBar
          style={{
            color: " #1a3586",
            backgroundColor: "#fff",
            boxShadow: "none",
          }}
          position="static"
          className="scrollable-element"
        >
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="PII VIOLATIONS" value="1" />
            <Tab label="SECRETS" value="2" />
            <Tab label="THIRD PARTY LIBRARIES" value="3" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          {filteredPiiViolationsData.length > 0 ? (
            <PiiViolations data={filteredPiiViolationsData}></PiiViolations>
          ) : (
            <Box py={3} textAlign="center" width="100%">
              <Typography>No pii violations were found.</Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="2">
          {filteredSecretData.length > 0 ? (
            <Secrets data={filteredSecretData}></Secrets>
          ) : (
            <Box py={3} textAlign="center" width="100%">
              <Typography>No secrets were found.</Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="3">
          {thirdPartyData.length > 0 ? (
            <ThirdPartyLibraries data={thirdPartyData}></ThirdPartyLibraries>
          ) : (
            <Box py={3} textAlign="center" width="100%">
              <Typography>No third party libraries were found.</Typography>
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Page>
  );
};

export default MainSection;
