import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  fieldSet: {
    width: 370,
    margin: "0 auto",
  },
  field: {
    textAlign: "left",
    marginBottom: 30,
  },
  label: {
    margin: `${theme.spacing(1)}px 0`,
    color: theme.palette.grey[800],
    paddingTop: theme.spacing(1),
    alignSelf: "flex-start",
    fontWeight: "bold",
    lineHeight: 1.25,
    fontSize: 24,
    textTransform: "capitalize",
    "&.-confirmation": {
      fontSize: 20,
      textAlign: "center",
      textTransform: "none",
    },
  },
  input: {
    "& input": {
      padding: 14,
    },
  },
  buttonContainer: {
    width: "100%",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  buttonLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
