import React from "react";
import useStyles from "./styles";

const Header = ({ title, description, children }) => {
  const classes = useStyles();
  return (
    <div className="container">
      <div className={classes.container}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.description}>{description}</p>
        {children}
      </div>
    </div>
  );
};

export default Header;
