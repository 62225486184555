import { authenticationConstants } from "redux/_authentication/authentication.constants";

export function authHeader() {
  // return authorization header with jwt token
  let session = JSON.parse(
    localStorage.getItem(authenticationConstants.SESSION)
  );

  if (session && session.access_token) {
    return {
      Authorization: "Token " + session.access_token,
    };
  } else {
    return {};
  }
}
