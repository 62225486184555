import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import useStyles from './styles';

const DetailItem = ({ label, value, style }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.detailItem} style={style}>
      {label}: {value}
    </Typography>
  );
};

const PIICodeSnippetView = ({ file_path, pii_usage_output, context }) => {
  const classes = useStyles();
  const { pii, classifciation_1, classifciation_2, reasoning } = pii_usage_output;
  const { code_snippet, start_position, end_position, contextIndex, lineNumber } = context;

  function updateHighlightedCode(code, contextIndex, start, end, lineNumber) {
    const lines = code.split('\n');
    const lineText = lines[contextIndex];

    const highlightedText = lineText.substring(start, end);
    const highlightedLine = `${lineText.substring(0, start)}<span class="${classes.highlightedSpan}">${highlightedText}</span>${lineText.substring(end)}`;

    lines[contextIndex] = `<span class="${classes.highlightedText}">${highlightedLine}</span>`;

    return lines.map((line, index) => {
      const currentLineNumber = lineNumber - contextIndex + index;
      return `<span class="${classes.lineNumber}">${currentLineNumber}</span> ${line}`;
    }).join('\n');
  }

  function renderDetailItems() {
    return (
      <>
        <DetailItem label="File" value={file_path} style={{fontWeight:900}} />
        <br />
        <DetailItem label="PII" value={pii} />
        <br />
        <DetailItem label="Type" value={classifciation_1} />
        <DetailItem label="Category" value={classifciation_2}  />
        <DetailItem label="Explanation" value={reasoning}  />
      </>
    );
  }

  function renderCodeBox() {
    const highlightedCode = updateHighlightedCode(code_snippet, contextIndex, start_position, end_position, lineNumber);
    return (
      <Box marginTop={4}>
        <Paper elevation={3} className={classes.codeBoxPaper}>
          <Box marginTop={4}>
            <pre className={classes.codeBoxPre}>
              <code dangerouslySetInnerHTML={{ __html: highlightedCode }} />
            </pre>
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Box style={{ borderRadius: 4, padding: 16, background: "#F0F0F0", gap: 8 }}>
        {renderDetailItems()}
      </Box>
      {renderCodeBox()}
    </Box>
  );
};

export default PIICodeSnippetView;
