import React from "react";
import Flags from "country-flag-icons/react/3x2";
import countryList from "../config/LambdaTestCountries";

const style = {
  width: "1.8em",
  height: "1.2em",
  position: "relative",
  top: "-1px",
  "&.selected": {
    marginRight: "10px",
  },
};

const formattedCountries = (countries) =>
  countries.map((country) => {
    const Flag = Flags[country.country || country.value];
    return {
      ...country,
      icon: Flag ? <Flag style={style} /> : null,
    };
  });

const filterCountries = (countries) => {
  let filteredCountries = [];
  if (Array.isArray(countries)) {
    filteredCountries = countryList.filter((country) =>
      countries.includes(country.value)
    );
  }
  return formattedCountries(filteredCountries);
};

const countries = formattedCountries(countryList);

export { countries, filterCountries };
