import PageWrapper, { Tab, Tabs } from "components/PageWrapper";
import Configuration from "components/Requests/Widget/Configuration";
import Embed from "components/Requests/Widget/Embed";
import Options from "components/Requests/Widget/Options";
import useQuery from "hooks/useQuery";
import isNil from "lodash.isnil";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getConfig } from "redux/_requests/_config/config.async.actions";

const RequestsWidget = (props) => {
  const { match, location, history, getConfigData } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    [location.pathname, match.path]
  );

  const [value, setValue] = useState(pathValue || "configuration");
  const query = useQuery();

  useEffect(() => {
    if (isNil(pathValue)) history.push(`/zen/requests/widget/${value}`);
  });

  useEffect(() => {
    history.push(`/zen/requests/widget/${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onTabChange = (value) => setValue(value);

  useEffect(() => {
    const reportId = query.get("r");
    getConfigData(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      title="REQUESTS"
      subtitle="WIDGET"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"configuration"} label="Configuration" />
          <Tab value={"options"} label="Options" />
          <Tab value={"embed"} label="Embed" />
        </Tabs>
      }
    >
      <Switch>
        <Route
          path={"/zen/requests/widget/configuration"}
          component={Configuration}
        />
        <Route path={"/zen/requests/widget/options"} component={Options} />
        <Route path={"/zen/requests/widget/embed"} component={Embed} />
      </Switch>
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getConfigData: (x) => dispatch(getConfig(x)),
});

export default connect(null, mapDispatchToProps)(RequestsWidget);
