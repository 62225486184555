import React from "react";
import UserRow from "components/Admin/Users/components/UserRow";
import Loader from "components/Loader";
import useStyles from "./styles";

const UsersTable = ({ users = [], filter, loading }) => {
  const classes = useStyles();
  const handleFilter = (user) => {
    if (!filter) return true;

    const regex = new RegExp(filter, "gi");
    return (
      (user.name && user.name.search(regex) !== -1) ||
      (user.email && user.email.search(regex) !== -1)
    );
  };

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "40%" }} />
        <col style={{ width: "45%" }} />
        <col style={{ width: "15%" }} />
      </colgroup>
      <tr className={classes.tr}>
        <th>Name</th>
        <th>State</th>
        <th></th>
      </tr>

      {loading ? (
        <tr>
          <td colSpan={4} className={classes.loadingRow}>
            <Loader />
          </td>
        </tr>
      ) : (
        users
          .filter(handleFilter)
          .map((user) => (
            <UserRow
              key={user.id}
              id={user.id}
              invitationKey={user.key}
              name={
                user.first_name ? `${user.first_name} ${user.last_name}` : ""
              }
              state={!user.key ? "A" : "P"}
              role={user.is_main_account ? "A" : "N"}
              email={user.email}
              profilePicture={user.profile_picture}
              you={user.is_main_account}
            />
          ))
      )}
    </table>
  );
};

export default UsersTable;
