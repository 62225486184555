import { Document } from "@react-pdf/renderer";
import ApplicationPermissionsPage from "./ApplicationPermissionsPage";
import CodeAnalysisPage from "./CodeAnalysisPage";
import IpaBinaryCodePage from "./IPABinaryCode";
import styles from "./styles";
import FrontPage from "./FrontPage";
import CustomPage from "./commons/Page";
import moment from "moment";
import BrowsableActivitiesPage from "./BrowsableActivitiesPage";
import OpportunitiesPage from "./OpportunitiesPage";
import StorePage from "./StorePage";
import NetworkSecurityPage from "./NetworkSecurityPage";
import SdksPage from "./SdksPage";
import DomainMalwareCheckPage from "./DomainMalwareCheckPage";
import FileAppInfoComponentsPage from "./FileAppInfoComponentsPage";
import CustomURLSchemesPage from "./CustomURLSchemesPage";
import CertificateInfoPage from "./CertificateInfoPage";
import APKIDPage from "./APKIDPage";
import AppTransportSecurityPage from "./AppTransportSecurityPage";
import SharedLibraryBinaryAnalysisPage from "./SharedLibraryBinaryAnalysis";
import NIAPAnalysisPage from "./NIAPAnalysisPage";
import ManifestAnalysisPage from "./ManifestAnalysisPage";

const MobilePDFReport = ({ branding, data, content }) => {
  const appType = data.app_type;

  try {
    return (
      <Document>
        <FrontPage
          url={data.app_name}
          reportCreatedDate={data.scanDate}
          branding={branding}
          content={content}
        />
        <CustomPage
          size="A4"
          style={styles.page}
          branding={branding}
          content={content}
          wrap
          title="Mobile App Scanner"
          subtitle="Report"
          reportLabel={`${data.app_name} - ${moment(data.scanDate).format(
            "MMM, YYYY"
          )}`}
        >
          <ApplicationPermissionsPage styles={styles} data={data} />
          {appType === "apk" && (
            <BrowsableActivitiesPage styles={styles} data={data} />
          )}
          {appType === "apk" && (
            <NetworkSecurityPage styles={styles} data={data} />
          )}
          <SdksPage styles={styles} data={data} />
          {appType === "apk" ? (
            <CodeAnalysisPage styles={styles} data={data} />
          ) : (
            <IpaBinaryCodePage styles={styles} data={data} />
          )}
          <DomainMalwareCheckPage styles={styles} data={data} />
          <FileAppInfoComponentsPage
            styles={styles}
            data={data}
            appType={appType}
          />
          {appType !== "apk" && (
            <CustomURLSchemesPage styles={styles} data={data} />
          )}
          {appType !== "apk" && (
            <AppTransportSecurityPage styles={styles} data={data} />
          )}
          {appType === "apk" && (
            <CertificateInfoPage styles={styles} data={data} />
          )}
          {appType === "apk" && <APKIDPage styles={styles} data={data} />}
          <SharedLibraryBinaryAnalysisPage
            styles={styles}
            data={data}
            appType={appType}
          />
          {appType === "apk" && (
            <NIAPAnalysisPage styles={styles} data={data} />
          )}
          {appType === "apk" && (
            <ManifestAnalysisPage styles={styles} data={data} />
          )}
          {data.isObjectiveC === false && (
            <StorePage styles={styles} data={data} appType={appType} />
          )}
          <OpportunitiesPage styles={styles} data={data} />
        </CustomPage>
      </Document>
    );
  } catch (error) {
    console.error("Error rendering MobilePDFReport:", error);
    return null; // Return null to prevent rendering on error
  }
};

export default MobilePDFReport;
