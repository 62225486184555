import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  },
  card: {
    display: "flex",
    width: 320,
    height: 49,
    padding: "6px 16px",
    alignItems: "center",
    borderRadius: 4,
    flexShrink: 0,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: 320,
    },
  },
  iconContainer: {
    marginRight: "12px",
    maxWidth: 40,
    display: "flex",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));
