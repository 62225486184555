import { makeStyles } from "@material-ui/core";

const drawerWidth = 226;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    border: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(3),
    backgroundColor: theme.palette.blue[100],
  },
  stackedDrawer: {
    left: "auto !important",
    borderRight: "1px solid #cfdff8 !important",
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px 16px 0px",
  },
  listContainer: {
    width: "100%",
  },
  activeButton: {
    backgroundColor: "green",
  },
  secondList: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
  },
  menu: {
    backgroundColor: "#1a3586",
    left: "75px !important",
  },
  menuItem: {
    fontSize: "0.9375rem",
    lineHeight: "24px",
    fontWeight: 400,
  },
  buttonContainer: {
    backgroundColor: theme.palette.common.white,
    marginTop: "auto",
  },
  button: {
    height: 30,
    padding: 0,
    width: "calc(100% - 32px)",
    borderRadius: 0,
    borderTop: "1px solid #cfdff8",
    backgroundColor: theme.palette.common.white,
    display: "block",
    margin: "auto",
    "&:hover, :focus": {
      backgroundColor: "transparent",
    },
  },
  buttonText: {
    lineHeight: "15px",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[200],
    justifyContent: "flex-end",
    transition: "all 0.15s ease-in-out",
    textAlign: "right",
    "&:hover": {
      color: theme.palette.blue[300],
    },
  },
  iconButton: {
    borderRadius: 0,
    height: "100%",
    color: theme.palette.blue[500],
  },
  buttonIcon: {
    width: "15px",
    height: "15px",
    marginRight: "4px",
  },
}));
