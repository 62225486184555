import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: theme.spacing(8),
    borderRadius: "4px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  title: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: 1.22,
    color: theme.palette.grey[200],
  },
  button: {
    textTransform: "none",
    color: theme.palette.blue["young"],
    fontSize: "14px",
    fontWeight: 500,
  },
}));
