import React, { useState } from "react";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

const ListField = ({ field, value, onChange, classes, onBlur, touched, error, submitError, }) => {
  const [fieldValue, setFieldValue] = useState();
  const [list, setList] = useState([]);
  const [errorList, setErrorList] = useState(null);

  const onAddElementToList = () => {
    if (!fieldValue) return;
    if (list.find((el) => el === fieldValue)) {
      setErrorList("It's already added");
      return;
    }
    const nextList = [...list, fieldValue];
    setErrorList(null);
    setList(nextList);
    setFieldValue("");
    onChange(nextList);
  };

  const remove = (el) => {
    const nextList = list.filter((element) => el !== element);
    setList(nextList);
    onChange(nextList);
  }
  return (
    <div className={classes.rightContainer} style={{ flexDirection: "column" }}>
      <div style={{ display: "flex", width: "100%", gap: 5 }}>
        <TextField
          inputProps={{
            "aria-labelledby": "resource-label",
          }}
          value={fieldValue}
          onChange={(event) => {
            const newValue = event.target.value;
            setFieldValue(newValue);
          }}
          className={classes.textField}
          variant="filled"
          error={touched && (error || submitError)}
          onBlur={() => {
            onAddElementToList("");
            onBlur();
          }}
          helperText={
            (touched &&
            (error || submitError)) || errorList && (
              <span style={{ color: "red" }}>{error || errorList}</span>
            )
          }
        />
        {field.type === "list" && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={onAddElementToList}
            style={{
              minWidth: 38,
              width: 41,
              borderRadius: "50%"
            }}>
            <Add />
          </Button>
        )}
      </div>
      <div style={{
        padding: 8,
        gap: 5,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}>
        {list.length > 0 && (
          list.map((el) => (
            <div style={{ widthcons: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Tooltip title={el}>
                <span style={{
                  width: "93%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>{el}</span>
              </Tooltip>
              <Delete style={{ color: "#1a3586", cursor: "pointer", width: "7%" }} onClick={() => remove(el)} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ListField;
