import React from "react";

const downloadIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.8397 15L15.8397 17.4343C15.8397 17.5844 15.7801 17.7282 15.674 17.8343C15.5679 17.9404 15.424 18 15.274 18L0.56589 18C0.415858 18 0.271972 17.9404 0.165884 17.8343C0.0597944 17.7282 0.000195169 17.5843 0.000195327 17.4343L0.000195932 15.75L1.13159 15L1.13159 16.8686L14.7083 16.8686L14.7083 15L15.8397 15Z"
        fill="#0E1D60"
      />
      <path
        d="M15.8397 15L15.8397 13.3157C15.8397 13.1657 15.7804 13.0218 15.6742 12.9157C15.568 12.8096 15.4244 12.75 15.2743 12.75C15.1241 12.75 14.9798 12.8096 14.8744 12.9157C14.7682 13.0218 14.7081 13.1657 14.7081 13.3157L14.7083 15L15.8397 15Z"
        fill="#0E1D60"
      />
      <path
        d="M1.13159 15.7566L1.13159 13.3223C1.13159 13.1723 1.07226 13.0284 0.966064 12.9223C0.859863 12.8162 0.716308 12.7566 0.566162 12.7566C0.416015 12.7566 0.271728 12.8162 0.166259 12.9223C0.0600583 13.0284 -4.09398e-07 13.1723 -5.54862e-07 13.3223L0.000195926 15.7566L1.13159 15.7566Z"
        fill="#0E1D60"
      />
      <path
        d="M10.9167 6.95523L8.48708 9.38206L8.48709 0.565691C8.48709 0.415659 8.42749 0.271772 8.32141 0.165683C8.21532 0.0595944 8.07143 -4.6886e-06 7.9214 -4.83508e-06C7.77137 -4.98155e-06 7.62748 0.0595938 7.52139 0.165682C7.4153 0.271771 7.3557 0.415658 7.3557 0.56569L7.35569 9.38206L4.92603 6.95239C4.81989 6.84624 4.67592 6.78661 4.5258 6.78661C4.37569 6.78661 4.23172 6.84624 4.12558 6.95239C4.01943 7.05854 3.9598 7.20251 3.95979 7.35262C3.95979 7.50274 4.01943 7.6467 4.12557 7.75285L7.51974 11.147C7.54655 11.1735 7.57592 11.1971 7.60743 11.2177L7.63854 11.2432L7.69228 11.2715L7.76299 11.2885L7.81108 11.2885C7.8839 11.3029 7.95887 11.3029 8.0317 11.2885L8.07978 11.2885L8.13635 11.2715L8.20423 11.2432L8.24949 11.2177C8.281 11.1971 8.31037 11.1735 8.33717 11.147L11.7313 7.75286C11.8375 7.64671 11.8971 7.50274 11.8971 7.35263C11.8971 7.20251 11.8375 7.05855 11.7313 6.9524C11.6252 6.84625 11.4812 6.78662 11.3311 6.78662C11.181 6.78662 11.037 6.84625 10.9309 6.9524L10.9167 6.95523Z"
        fill="#0E1D60"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16.5007" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default downloadIcon;
