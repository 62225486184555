import React from "react";

const SearchIcon = ({ className, color = "#BDBDBD" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    viewBox="0 0 15 16"
  >
    <path
      fill={color}
      d="M0 6.216a6.22 6.22 0 0 0 6.216 6.217 6.148 6.148 0 0 0 3.57-1.128l3.775 3.776a.878.878 0 0 0 .599.253.878.878 0 0 0 .598-.253.862.862 0 0 0 0-1.198l-3.73-3.73a6.225 6.225 0 0 0 1.405-3.937A6.22 6.22 0 0 0 6.216 0 6.22 6.22 0 0 0 0 6.216zm1.358 0c0-2.67 2.188-4.858 4.858-4.858a4.859 4.859 0 0 1 0 9.716c-2.67 0-4.858-2.187-4.858-4.858z"
    />
  </svg>
);

export default SearchIcon;
