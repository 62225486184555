import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Title from "pages/AppReport/common/Title";
import Subtitle from "pages/SecurityReport/common/Subtitle";
import { SECTIONS_CONTENT } from "pages/SecurityReport/constants";
import SectionContent from "pages/AppReport/common/SectionContent";

const TextFindingSection = ({ goToNextSectionAction, settings }) => {
  return (
    <Page
      key={"TEXT-SETTINGS"}
      footerBackgroundColor={SECTIONS_CONTENT.findings.backgroundColor}
      footerText={
        <div className="privacy-tech">SEE THE Scan coverage information</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.findings.description}>
        {SECTIONS_CONTENT.findings.title}
      </Title>
      <SectionContent className="scrollable-element">
        {settings.map((setting) => (
          <Subtitle status="confirm">{setting}</Subtitle>
        ))}
      </SectionContent>
    </Page>
  );
};

export default TextFindingSection;
