import { usePDF } from "@react-pdf/renderer";
import PDFReport from "components/PDFReport";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

const PDFDownloadLink = ({
  branding,
  logo,
  children,
  geographicChartRaw,
  isApp,
  items,
  privacyChartRaw,
  ranking,
  report,
  riskName,
  screenshot,
  testTubeScore,
  tips,
  totalScore,
  totalScoreApp,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ url, loading }] = usePDF({
    document: (
      <PDFReport
        branding={branding}
        logo={logo}
        isApp={isApp}
        report={report}
        screenshot={screenshot}
        testsResult={{
          passed: testTubeScore,
          total: items.length,
        }}
        totalScore={isApp ? totalScoreApp : totalScore}
        privacyChart={privacyChartRaw}
        geographicChart={geographicChartRaw}
        tips={tips}
        risk={{
          name: riskName,
          value: 5 - ranking,
        }}
        scores={items}
      />
    ),
  });

  useEffect(() => {
    if (!rendered && !loading && url) {
      global.open(url);
      setRendered(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onClick = () => {
    if (url) {
      global.open(url);
      setRendered(true);
    }
  };

  return loading || !url ? (
    <Button className="pdf-button" color="primary" variant="contained">
      Opening...
    </Button>
  ) : (
    <span onClick={onClick}>{children}</span>
  );
};

export default PDFDownloadLink;
