import {
  Box,
  CircularProgress,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import DesktopIcon from "@material-ui/icons/DesktopWindowsRounded";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import clsx from "clsx";
import Button from "components/Button";
import {
  FormAutoCompleteInput,
  FormSelectInput,
} from "components/FormComponents/Inputs";
import React, { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { countries } from "utils/countries";
import { requiredCountry } from "utils/scanValidations";
import { SCAN_TYPE } from "./constants";
import useStyles from "./styles";

const UploadProgress = ({ value }) => {
  return value !== 100 ? (
    <LinearProgress
      variant="determinate"
      value={value}
      style={{ borderRadius: 2, width: "50%" }}
    />
  ) : (
    <LinearProgress style={{ borderRadius: 2, width: "50%" }} />
  );
};

const STEP_NAME = {
  locations: "locations",
  device: "device",
  email: "email",
  appDataUpload: "appDataUpload",
};

const FLOW = {
  [SCAN_TYPE.site]: {
    order: [STEP_NAME.locations, STEP_NAME.email, STEP_NAME.siteDataUpload],
    submitStep: 1,
    totalSteps: 3,
  },
  [SCAN_TYPE.app]: {
    order: [STEP_NAME.locations, STEP_NAME.email, STEP_NAME.appDataUpload],
    submitStep: 1,
    totalSteps: 3,
  },
};

const getStepDataForScanType = (scanType, context) => {
  const {
    appUploadProgress,
    classes,
    countries,
    email,
    loading,
    locationRef,
    requiredCountry,
    serverError,
  } = context;
  const STEPS = {
    [STEP_NAME.locations]: {
      name: [STEP_NAME.locations],
      content: (
        <Field
          name="locations"
          className={clsx(classes.headerTextField, "clear-background")}
          style={{ marginTop: 23 }}
          placeholder="Select a jurisdiction / country"
          options={countries}
          component={FormAutoCompleteInput}
          validate={requiredCountry}
          reference={locationRef}
        />
      ),
      title: "Jurisdiction / Country, you would like to evaluate your site for",
    },
    [STEP_NAME.device]: {
      name: [STEP_NAME.device],
      content: (
        <>
          <span className="label">Select Device</span>
          <Field
            name="device"
            className={clsx(classes.headerTextField, "clear-background")}
            style={{ marginTop: 8 }}
            defaultValue="app"
            options={[
              {
                label: "App",
                value: "app",
                icon: <SmartphoneIcon />,
              },
              {
                label: "Web",
                value: "web",
                icon: <DesktopIcon />,
              },
            ]}
            component={FormSelectInput}
          />
        </>
      ),
      title: "",
    },
    [STEP_NAME.email]: {
      name: [STEP_NAME.email],
      content: (
        <>
          <span className="label">Your email*</span>
          <Field
            name="email"
            validate={requiredEmail}
            render={({
              input: { value, onChange },
              meta: { touched, error },
            }) => (
              <TextField
                value={value || email}
                onChange={onChange}
                className={clsx(classes.headerTextField, "clear-background")}
                variant="outlined"
                placeholder="Enter your email"
                disabled={loading}
                helperText={
                  touched &&
                  error && <span style={{ color: "red" }}>{error}</span>
                }
                InputProps={{
                  error: Boolean(touched && error),
                  className: classes.headerInputBase,
                  classes: {
                    notchedOutline: classes.headerInputOutline,
                  },
                }}
                inputProps={{
                  className: classes.headerInput,
                }}
              />
            )}
          />
          {serverError && <span style={{ color: "red" }}>{serverError}</span>}
        </>
      ),
      title: "Almost Done. Enter your email for the report.",
      note: "* Only used to notify you when the report is ready.",
    },
    [STEP_NAME.appDataUpload]: {
      name: [STEP_NAME.appDataUpload],
      title: "Your information is being uploaded to create the report...",
      content: <UploadProgress value={appUploadProgress} />,
      hideNavButtons: true,
    },
    [STEP_NAME.siteDataUpload]: {
      name: [STEP_NAME.appDataUpload],
      title: "Your information is being uploaded to create the report...",
      content: <LinearProgress style={{ borderRadius: 2, width: "50%" }} />,
      hideNavButtons: true,
    },
  };
  const steps = [];
  FLOW[scanType].order.forEach((stepName) => steps.push(STEPS[stepName]));
  steps[FLOW[scanType].submitStep].isSubmitStep = true;

  return steps;
};

const requiredEmail = (value) => {
  const email_pattern = /^[A-Za-z0-9._%+-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return email_pattern.test(value) ? false : "Please, enter a valid email.";
};

const ScannerForm = ({
  appUploadProgress,
  closeHandler,
  email,
  loading,
  location,
  onSubmit,
  scanType,
  serverError,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(location ? 1 : 0);
  const locationRef = useRef(null);

  const goBack = () => {
    if (step === 0) {
      closeHandler();
    } else {
      setStep(step - 1);
    }
  };

  const next = (values) => {
    if (step === FLOW[scanType].submitStep) {
      onSubmit(values);
    }
    setStep(step + 1);
  };

  return (
    <Form
      onSubmit={next}
      initialValues={{ locations: location ? location.value : null }}
    >
      {({ handleSubmit, values }) => (
        <Box
          component="form"
          maxWidth={540}
          onSubmit={(e) => {
            e.preventDefault();
            if (step === 0) {
              locationRef.current.click();
              document.activeElement.blur();
            }
            handleSubmit(e);
          }}
        >
          {getStepDataForScanType(scanType, {
            appUploadProgress,
            classes,
            countries,
            email,
            loading,
            locationRef,
            requiredCountry,
            serverError,
          })
            .filter((stepData, i) => i === step)
            .map(
              ({
                title,
                content,
                name,
                isSubmitStep,
                note,
                hideNavButtons,
              }) => {
                return (
                  <>
                    <Typography
                      component="h2"
                      className={clsx(classes.title, "clear-background", name)}
                    >
                      {title}
                    </Typography>
                    {content}
                    {!hideNavButtons && (
                      <div className={clsx(classes.formFields, name)}>
                        <Box
                          className={clsx(
                            classes.headerTextField,
                            "clear-background",
                            "auto-height",
                            name === STEP_NAME.locations && "country"
                          )}
                        >
                          <Button
                            type="submit"
                            disabled={loading}
                            className={classes.submitButton}
                          >
                            {isSubmitStep ? "Run the Scanner" : "Continue"}
                            {loading && (
                              <CircularProgress
                                className={classes.buttonLoader}
                                color="primary"
                                size={24}
                              />
                            )}
                          </Button>
                          {!location && (
                            <Button
                              variant="secondary"
                              className={classes.buttonSecondary}
                              onClick={() => goBack()}
                            >
                              Go Back
                            </Button>
                          )}
                        </Box>
                      </div>
                    )}
                    {note && (
                      <Typography
                        component="p"
                        className={clsx(
                          classes.description,
                          "clear-background",
                          classes.emailNotice
                        )}
                      >
                        {note}
                      </Typography>
                    )}
                  </>
                );
              }
            )}
        </Box>
      )}
    </Form>
  );
};

export default ScannerForm;
