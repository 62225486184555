import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import EntitySelector from "components/SettingsSections/EntitySelector";
import IntegrationsIcons from "assets/svgs-icons/Integrations";

import { getAccountData } from "redux/_account/account.selectors";

import useSalesforce from "hooks/useSalesforce";

const SalesForce = () => {
  const { getConnectedApps, connectedApps } = useSalesforce();
  const userAccount = useSelector((state) => getAccountData(state));

  useEffect(() => {
    getConnectedApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = (index) => {
    if (!index) {
      const authUrl = `${process.env.REACT_APP_SALESFORCE_AUTH_URL}`;
      const clientId = `${process.env.REACT_APP_SALESFORCE_CLIENT_ID}`;
      const redirectUrl = `${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}`;
      const companyId = userAccount.company.id;

      window.location.href = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${companyId}`;
    }
  };

  return (
    <>
      <EntitySelector
        entityName="Salesforce App"
        entities={connectedApps.map((app) => ({
          ...app,
          name: app.user_nickname,
          icon: "salesforce",
        }))}
        icons={IntegrationsIcons}
        newLabel="New"
        onSelect={select}
      />
    </>
  );
};

export default SalesForce;
