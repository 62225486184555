import React from "react";

const columnsIcon = () => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M26.0184 12.3461H24.958V12.7702H26.0184V12.3461Z"
        fill="#828282"
      />
      <path
        d="M26.0184 11.4978H24.958V11.9219H26.0184V11.4978Z"
        fill="#828282"
      />
      <path
        d="M26.0184 17.4357H24.958V17.8599H26.0184V17.4357Z"
        fill="#828282"
      />
      <path
        d="M26.0184 10.6495H24.958V11.0736H26.0184V10.6495Z"
        fill="#828282"
      />
      <path
        d="M26.0184 15.7392H24.958V16.1633H26.0184V15.7392Z"
        fill="#828282"
      />
      <path
        d="M26.0184 16.5875H24.958V17.0116H26.0184V16.5875Z"
        fill="#828282"
      />
      <path
        d="M26.0184 14.0426H24.958V14.4667H26.0184V14.0426Z"
        fill="#828282"
      />
      <path
        d="M26.0184 9.80118H24.958V10.2253H26.0184V9.80118Z"
        fill="#828282"
      />
      <path
        d="M26.0184 14.8909H24.958V15.315H26.0184V14.8909Z"
        fill="#828282"
      />
      <path
        d="M26.0184 13.1944H24.958V13.6185H26.0184V13.1944Z"
        fill="#828282"
      />
      <path
        d="M26.0184 3.86313H24.958V4.28727H26.0184V3.86313Z"
        fill="#828282"
      />
      <path
        d="M26.0184 3.01492H24.958V3.43907H26.0184V3.01492Z"
        fill="#828282"
      />
      <path
        d="M26.0184 2.16663H24.958V2.59077H26.0184V2.16663Z"
        fill="#828282"
      />
      <path
        d="M26.0184 4.71146H24.958V5.1356H26.0184V4.71146Z"
        fill="#828282"
      />
      <path
        d="M26.0184 5.55984H24.958V5.98399H26.0184V5.55984Z"
        fill="#828282"
      />
      <path
        d="M26.0184 8.95288H24.958V9.37702H26.0184V8.95288Z"
        fill="#828282"
      />
      <path
        d="M26.0184 8.10464H24.958V8.52879H26.0184V8.10464Z"
        fill="#828282"
      />
      <path
        d="M26.0184 6.40805H24.958V6.83219H26.0184V6.40805Z"
        fill="#828282"
      />
      <path
        d="M26.0184 7.25635H24.958V7.68048H26.0184V7.25635Z"
        fill="#828282"
      />
      <path
        d="M26.0184 21.6772H24.958V22.1014H26.0184V21.6772Z"
        fill="#828282"
      />
      <path
        d="M26.0184 22.5255H24.958V22.9496H26.0184V22.5255Z"
        fill="#828282"
      />
      <path
        d="M26.0184 23.3738H24.958V23.798H26.0184V23.3738Z"
        fill="#828282"
      />
      <path
        d="M26.0184 19.1324H24.958V19.5566H26.0184V19.1324Z"
        fill="#828282"
      />
      <path
        d="M26.0184 20.8289H24.958V21.2531H26.0184V20.8289Z"
        fill="#828282"
      />
      <path
        d="M26.0184 19.9806H24.958V20.4048H26.0184V19.9806Z"
        fill="#828282"
      />
      <path
        d="M26.0184 18.2841H24.958V18.7083H26.0184V18.2841Z"
        fill="#828282"
      />
      <path
        d="M17.7474 16.1209H17.3232V17.1813H17.7474V16.1209Z"
        fill="#828282"
      />
      <path
        d="M16.0509 16.1209H15.6267V17.1813H16.0509V16.1209Z"
        fill="#828282"
      />
      <path
        d="M18.5958 16.1209H18.1716V17.1813H18.5958V16.1209Z"
        fill="#828282"
      />
      <path
        d="M15.2027 16.1209H14.7786V17.1813H15.2027V16.1209Z"
        fill="#828282"
      />
      <path
        d="M20.2923 16.1209H19.8682V17.1813H20.2923V16.1209Z"
        fill="#828282"
      />
      <path
        d="M23.6854 16.1209H23.2612V17.1813H23.6854V16.1209Z"
        fill="#828282"
      />
      <path
        d="M24.5338 16.1209H24.1096V17.1813H24.5338V16.1209Z"
        fill="#828282"
      />
      <path
        d="M21.1407 16.1209H20.7166V17.1813H21.1407V16.1209Z"
        fill="#828282"
      />
      <path
        d="M14.3543 16.1209H13.9302V17.1813H14.3543V16.1209Z"
        fill="#828282"
      />
      <path
        d="M19.4439 16.1209H19.0198V17.1813H19.4439V16.1209Z"
        fill="#828282"
      />
      <path
        d="M22.8372 16.1209H22.4131V17.1813H22.8372V16.1209Z"
        fill="#828282"
      />
      <path
        d="M21.9888 16.1209H21.5647V17.1813H21.9888V16.1209Z"
        fill="#828282"
      />
      <path
        d="M16.899 16.1209H16.4749V17.1813H16.899V16.1209Z"
        fill="#828282"
      />
      <path
        d="M3.51741 16.1209H3.09326V17.1813H3.51741V16.1209Z"
        fill="#828282"
      />
      <path
        d="M4.36579 16.1209H3.94165V17.1813H4.36579V16.1209Z"
        fill="#828282"
      />
      <path
        d="M2.66926 16.1209H2.24512V17.1813H2.66926V16.1209Z"
        fill="#828282"
      />
      <path
        d="M1.82087 16.1209H1.39673V17.1813H1.82087V16.1209Z"
        fill="#828282"
      />
      <path
        d="M7.75886 16.1209H7.33472V17.1813H7.75886V16.1209Z"
        fill="#828282"
      />
      <path
        d="M10.3038 16.1209H9.87964V17.1813H10.3038V16.1209Z"
        fill="#828282"
      />
      <path
        d="M5.21394 16.1209H4.78979V17.1813H5.21394V16.1209Z"
        fill="#828282"
      />
      <path
        d="M11.1522 16.1209H10.728V17.1813H11.1522V16.1209Z"
        fill="#828282"
      />
      <path
        d="M9.45539 16.1209H9.03125V17.1813H9.45539V16.1209Z"
        fill="#828282"
      />
      <path
        d="M6.91072 16.1209H6.48657V17.1813H6.91072V16.1209Z"
        fill="#828282"
      />
      <path
        d="M12.0003 16.1209H11.5762V17.1813H12.0003V16.1209Z"
        fill="#828282"
      />
      <path
        d="M8.60725 16.1209H8.18311V17.1813H8.60725V16.1209Z"
        fill="#828282"
      />
      <path
        d="M6.06233 16.1209H5.63818V17.1813H6.06233V16.1209Z"
        fill="#828282"
      />
      <path
        d="M13.5061 2.18784H0.0183105V23.904H13.5273V2.18784H13.5061ZM12.4457 22.8224H1.07867V3.24819H12.4669V22.8224H12.4457Z"
        fill="#828282"
      />
      <path
        d="M17.7474 9.12256H17.3232V10.1829H17.7474V9.12256Z"
        fill="#828282"
      />
      <path
        d="M24.5338 9.12256H24.1096V10.1829H24.5338V9.12256Z"
        fill="#828282"
      />
      <path
        d="M14.3543 9.12256H13.9302V10.1829H14.3543V9.12256Z"
        fill="#828282"
      />
      <path
        d="M19.4439 9.12256H19.0198V10.1829H19.4439V9.12256Z"
        fill="#828282"
      />
      <path
        d="M21.1407 9.12256H20.7166V10.1829H21.1407V9.12256Z"
        fill="#828282"
      />
      <path
        d="M20.2923 9.12256H19.8682V10.1829H20.2923V9.12256Z"
        fill="#828282"
      />
      <path
        d="M18.5958 9.12256H18.1716V10.1829H18.5958V9.12256Z"
        fill="#828282"
      />
      <path
        d="M15.2027 9.12256H14.7786V10.1829H15.2027V9.12256Z"
        fill="#828282"
      />
      <path
        d="M16.0509 9.12256H15.6267V10.1829H16.0509V9.12256Z"
        fill="#828282"
      />
      <path
        d="M23.6854 9.12256H23.2612V10.1829H23.6854V9.12256Z"
        fill="#828282"
      />
      <path
        d="M22.8372 9.12256H22.4131V10.1829H22.8372V9.12256Z"
        fill="#828282"
      />
      <path
        d="M16.899 9.12256H16.4749V10.1829H16.899V9.12256Z"
        fill="#828282"
      />
      <path
        d="M21.9888 9.12256H21.5647V10.1829H21.9888V9.12256Z"
        fill="#828282"
      />
      <path
        d="M9.45539 9.12256H9.03125V10.1829H9.45539V9.12256Z"
        fill="#828282"
      />
      <path
        d="M3.51741 9.12256H3.09326V10.1829H3.51741V9.12256Z"
        fill="#828282"
      />
      <path
        d="M12.0003 9.12256H11.5762V10.1829H12.0003V9.12256Z"
        fill="#828282"
      />
      <path
        d="M2.66926 9.12256H2.24512V10.1829H2.66926V9.12256Z"
        fill="#828282"
      />
      <path
        d="M10.3038 9.12256H9.87964V10.1829H10.3038V9.12256Z"
        fill="#828282"
      />
      <path
        d="M11.1522 9.12256H10.728V10.1829H11.1522V9.12256Z"
        fill="#828282"
      />
      <path
        d="M6.06233 9.12256H5.63818V10.1829H6.06233V9.12256Z"
        fill="#828282"
      />
      <path
        d="M5.21394 9.12256H4.78979V10.1829H5.21394V9.12256Z"
        fill="#828282"
      />
      <path
        d="M6.91072 9.12256H6.48657V10.1829H6.91072V9.12256Z"
        fill="#828282"
      />
      <path
        d="M8.60725 9.12256H8.18311V10.1829H8.60725V9.12256Z"
        fill="#828282"
      />
      <path
        d="M7.75886 9.12256H7.33472V10.1829H7.75886V9.12256Z"
        fill="#828282"
      />
      <path
        d="M4.36579 9.12256H3.94165V10.1829H4.36579V9.12256Z"
        fill="#828282"
      />
      <path
        d="M1.82087 9.12256H1.39673V10.1829H1.82087V9.12256Z"
        fill="#828282"
      />
      <path
        d="M19.4439 22.8224H19.0198V23.8827H19.4439V22.8224Z"
        fill="#828282"
      />
      <path
        d="M15.2027 22.8224H14.7786V23.8827H15.2027V22.8224Z"
        fill="#828282"
      />
      <path
        d="M21.9888 22.8224H21.5647V23.8827H21.9888V22.8224Z"
        fill="#828282"
      />
      <path
        d="M17.7474 22.8224H17.3232V23.8827H17.7474V22.8224Z"
        fill="#828282"
      />
      <path
        d="M23.6854 22.8224H23.2612V23.8827H23.6854V22.8224Z"
        fill="#828282"
      />
      <path
        d="M22.8372 22.8224H22.4131V23.8827H22.8372V22.8224Z"
        fill="#828282"
      />
      <path
        d="M14.3543 22.8224H13.9302V23.8827H14.3543V22.8224Z"
        fill="#828282"
      />
      <path
        d="M16.899 22.8224H16.4749V23.8827H16.899V22.8224Z"
        fill="#828282"
      />
      <path
        d="M16.0509 22.8224H15.6267V23.8827H16.0509V22.8224Z"
        fill="#828282"
      />
      <path
        d="M21.1407 22.8224H20.7166V23.8827H21.1407V22.8224Z"
        fill="#828282"
      />
      <path
        d="M18.5958 22.8224H18.1716V23.8827H18.5958V22.8224Z"
        fill="#828282"
      />
      <path
        d="M24.5338 22.8224H24.1096V23.8827H24.5338V22.8224Z"
        fill="#828282"
      />
      <path
        d="M20.2923 22.8224H19.8682V23.8827H20.2923V22.8224Z"
        fill="#828282"
      />
      <path
        d="M18.5958 2.18784H18.1716V3.2482H18.5958V2.18784Z"
        fill="#828282"
      />
      <path
        d="M23.6854 2.18784H23.2612V3.2482H23.6854V2.18784Z"
        fill="#828282"
      />
      <path
        d="M20.2923 2.18784H19.8682V3.2482H20.2923V2.18784Z"
        fill="#828282"
      />
      <path
        d="M19.4439 2.18784H19.0198V3.2482H19.4439V2.18784Z"
        fill="#828282"
      />
      <path
        d="M24.5338 2.18784H24.1096V3.2482H24.5338V2.18784Z"
        fill="#828282"
      />
      <path
        d="M14.3543 2.18784H13.9302V3.2482H14.3543V2.18784Z"
        fill="#828282"
      />
      <path
        d="M22.8372 2.18784H22.4131V3.2482H22.8372V2.18784Z"
        fill="#828282"
      />
      <path
        d="M21.1407 2.18784H20.7166V3.2482H21.1407V2.18784Z"
        fill="#828282"
      />
      <path d="M16.899 2.18784H16.4749V3.2482H16.899V2.18784Z" fill="#828282" />
      <path
        d="M21.9888 2.18784H21.5647V3.2482H21.9888V2.18784Z"
        fill="#828282"
      />
      <path
        d="M17.7474 2.18784H17.3232V3.2482H17.7474V2.18784Z"
        fill="#828282"
      />
      <path
        d="M16.0509 2.18784H15.6267V3.2482H16.0509V2.18784Z"
        fill="#828282"
      />
      <path
        d="M15.2027 2.18784H14.7786V3.2482H15.2027V2.18784Z"
        fill="#828282"
      />
    </g>
  </svg>
);

export default columnsIcon;
