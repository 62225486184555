import { callServiceMethod } from "utils/fetch";
import {
  requestStart,
  requestEnd,
  setAccountData,
  setCompanyData,
} from "./account.actions";
import {
  getInfo,
  updateInfo,
  uploadLogo,
} from "redux/_account/account.service";
import isEmpty from "lodash.isempty";
import { getAccountData } from "redux/_account/account.selectors";
import api from "utils/fetch";

export const getDataSuccess = (data, dispatch) =>
  dispatch(setAccountData(data));

export const getData = (params = {}) => (dispatch, getState) => {
  const { force = false } = params;
  const data = getAccountData(getState());
  if (data && !isEmpty(data) && !force) {
    return Promise.resolve(data);
  } else {
    return callServiceMethod(dispatch, requestStart, requestEnd)(
      getInfo,
      [],
      getDataSuccess
    );
  }
};

export const updateDataSuccess = (data, dispatch) =>
  dispatch ? dispatch(setAccountData(data)) : "";

export const updateData = (newData, callbackSuccess) => (
  dispatch,
  getState
) => {
  return callServiceMethod(dispatch, requestStart, requestEnd)(
    updateInfo,
    [newData],
    () => {
      const accountData = getAccountData(getState());
      dispatch(setAccountData({ ...accountData, ...newData }));
      if (callbackSuccess) callbackSuccess();
      updateDataSuccess();
    }
  );
};

export const updateCompany = ({ id, ...data }) => (dispatch) => {
  api
    .patch(`company/${id}/`, JSON.stringify(data))
    .then((x) => dispatch(setCompanyData(x)));
};
