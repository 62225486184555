import { Text, View } from "@react-pdf/renderer";

const CertificateInfoPage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>Certificate Information</Text>
        <Text style={styles.subTitle}>
          Certificates confirm the authenticity of the app developer and ensure
          that the app has not been tampered with since its signing. If an app's
          certificate cannot be validated or if it's signed with a non-trusted
          certificate, the app might be a repackaged or malicious version that
          can compromise user data.
        </Text>
      </View>
      <View>
        <View>
          <View style={styles.table}>
            <View style={styles.thead}>
              <View style={styles.tr}>
                <View style={[styles.thCustomWidth, { width: "40%" }]}>
                  <Text>TITLE</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "20%" }]}>
                  <Text>SEVERITY</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "50%" }]}>
                  <Text>DESCRIPTION</Text>
                </View>
              </View>
            </View>
            {Object.entries(data.certificate_analysis.certificate_findings).map(
              ([key, value]) => {
                const statusStyles = {
                  info: {
                    backgroundColor: "#466DE8",
                  },
                  warning: {
                    backgroundColor: "#EF7D1F",
                  },
                  high: {
                    backgroundColor: "#D32F2F",
                  },
                  secure: {
                    backgroundColor: "#2E7D32",
                  },
                  good: {
                    backgroundColor: "#2E7D32",
                  },
                };

                const statusStyle = statusStyles[value[0]] || {};

                return (
                  <View style={styles.tr} key={key} wrap={false}>
                    <View style={[styles.tdCustomWidth, { width: "40%" }]}>
                      <Text>{value[2]}</Text>
                    </View>
                    <View style={[styles.tdCustomWidth, { width: "20%" }]}>
                      <Text style={[statusStyle, styles.severityText]}>
                        {value[0]}
                      </Text>
                    </View>
                    <View style={[styles.tdCustomWidth, { width: "50%" }]}>
                      <Text>{value[1]}</Text>
                    </View>
                  </View>
                );
              }
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default CertificateInfoPage;
