import React from "react";

const playScanIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99975 -4.37114e-07C7.32882 -4.37114e-07 4.81757 1.04031 2.92913 2.92937C1.04007 4.81812 -0.000246368 7.32937 -0.000246485 10.0003C-0.000246602 12.6712 1.04007 15.1825 2.92882 17.0712C4.81757 18.9603 7.32882 20.0006 9.99975 20.0006C12.6707 20.0006 15.1819 18.9603 17.0707 17.0712C18.9594 15.1825 19.9998 12.6712 19.9998 10.0003C19.9998 7.32937 18.9594 4.81812 17.0704 2.92937C15.1819 1.04031 12.6707 -4.37114e-07 9.99975 -4.37114e-07ZM16.1869 16.1875C14.5341 17.8403 12.3369 18.7506 9.99975 18.7506C7.66257 18.7506 5.46538 17.8403 3.81288 16.1875C2.16007 14.5347 1.24975 12.3375 1.24975 10.0003C1.24975 7.66312 2.16007 5.46594 3.81288 3.81312C5.46538 2.16031 7.66257 1.25 9.99975 1.25C12.3369 1.25 14.5341 2.16031 16.1869 3.81312C17.8398 5.46594 18.7498 7.66312 18.7498 10.0003C18.7498 12.3375 17.8398 14.5347 16.1869 16.1875Z"
      fill="#1A3586"
    />
    <path
      d="M13.1985 9.50881L8.82351 6.07131C8.63538 5.9235 8.37944 5.896 8.16413 6.00038C7.94882 6.10506 7.81225 6.32319 7.81225 6.56256L7.81226 13.4376C7.81226 13.6769 7.94882 13.8951 8.16382 13.9997C8.25069 14.0419 8.34413 14.0626 8.43725 14.0626C8.57476 14.0626 8.71132 14.0169 8.8235 13.9288L13.1985 10.4913C13.3491 10.3729 13.4373 10.1916 13.4373 10.0001C13.4373 9.8085 13.3491 9.62725 13.1985 9.50881ZM9.06225 12.1516L9.06225 7.8485L11.8007 10.0001L9.06225 12.1516Z"
      fill="#1A3586"
    />
  </svg>
);

export default playScanIcon;
