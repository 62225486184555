import slack from "./slack";
import salesforce from "./salesforce";
import sendgrid from "./sendgrid";
import micTeams from "./mic-teams";
import intercom from "./intercom";
import mailchimp from "./mailchimp";
import dropbox from "./dropbox";
import zendesk from "./zendesk";
import box from "./box";
import googleDrive from "./google-drive";
import confluence from "./confluence";
import jira from "./jira";
import micExchange from "./mic-exchange";
import trello from "./trello";
import asana from "./asana";
import monday from "./monday";
import oneDrive from "./one-drive";
import bitbucket from "./bitbucket";
import github from "./github";
import gitlab from "./gitlab";
import livechat from "./livechat";
import docusign from "./docusign";
import hellosign from "./hellosign";

export default {
  slack,
  salesforce,
  sendgrid,
  micTeams,
  intercom,
  mailchimp,
  dropbox,
  zendesk,
  box,
  googleDrive,
  confluence,
  jira,
  micExchange,
  trello,
  asana,
  monday,
  oneDrive,
  bitbucket,
  github,
  gitlab,
  livechat,
  docusign,
  hellosign,
};
