import { Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
import styles from "./styles";
import { negative, positive } from "../AssessmentSummaryyPage";

const getIcon = (thirdParty) => (thirdParty ? positive : negative);

const getSecureIcon = (secure) => (secure ? positive : negative);

const CookiesPage = ({ styles: globalStyles, records }) => {
  return (
    <View style={globalStyles.section}>
      <View>
        <Text style={globalStyles.title}>Cookies</Text>
        <Text>Cookies that were placed during the web session</Text>
      </View>
      <View>
        {records.map(({ category, cookies }, i) => (
          <Fragment key={i}>
            <Text style={{ ...globalStyles.subTitle, ...styles.subTitle }}>
              {category}
            </Text>
            <View style={globalStyles.table}>
              <View style={globalStyles.thead} fixed>
                <View style={globalStyles.tr}>
                  <View style={[globalStyles.th, { maxWidth: "100%" }]}>
                    <Text>Cookies</Text>
                  </View>
                </View>
              </View>
              <View style={globalStyles.tbody}>
                {cookies.map(
                  (
                    {
                      domain,
                      name,
                      preConsent,
                      thirdParty,
                      purpose,
                      subCategory,
                      duration,
                      informationStored,
                      secure,
                      canonicals,
                    },
                    j
                  ) => (
                    <View
                      key={`cookie-${i}-${j}`}
                      style={globalStyles.tr}
                      break={j === cookies.length - 1}
                      wrap={false}
                    >
                      <View style={[globalStyles.td, styles.cellContainer]}>
                        <View style={styles.cell}>
                          <View style={styles.cellValue}>
                            <Text style={{ fontWeight: "bold" }}>
                              <b>Cookie Name: </b>
                              {name}{" "}
                            </Text>
                            <Text style={{ wordBreak: "break-word" }}>
                              ({preConsent ? "Pre consent" : "Post consent"})
                            </Text>
                          </View>
                          <View style={[globalStyles.table, styles.table]}>
                            <View style={globalStyles.thead}>
                              <View style={globalStyles.tr}>
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>Provider</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>Category</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>Purpose</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>Duration</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>Information Stored</Text>
                                </View>
                                {/* <View style={[globalStyles.th, styles.fontSizeEight]}>
                              <Text>Consent</Text>
                            </View> */}
                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                    { maxWidth: 60 },
                                  ]}
                                >
                                  <Text>Third Party</Text>
                                </View>

                                <View
                                  style={[
                                    globalStyles.th,
                                    styles.fontSizeEight,
                                    { maxWidth: 60 },
                                  ]}
                                >
                                  <Text>Secure</Text>
                                </View>

                                {canonicals && (
                                  <View
                                    style={[
                                      globalStyles.th,
                                      styles.fontSizeEight,
                                      { maxWidth: 60 },
                                    ]}
                                  >
                                    <Text>Present on # pages</Text>
                                  </View>
                                )}
                              </View>
                            </View>
                            <View style={globalStyles.tbody}>
                              <View
                                style={[globalStyles.tr, { border: "none" }]}
                              >
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text
                                    style={{
                                      flex: 1,
                                      whiteSpace: "normal",
                                      maxWidth: 80,
                                      wordBreak: "break-all !important",
                                      display: "flex",
                                      flexDirection: "row",
                                      overflowWrap: "anywhere",
                                    }}
                                  >
                                    {domain}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>{subCategory}</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>{purpose}</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>{duration}</Text>
                                </View>
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                  ]}
                                >
                                  <Text>{informationStored}</Text>
                                </View>
                                {/* <View style={[globalStyles.td, styles.borderRight, styles.fontSizeEight]}>
                              <Text style={{ wordBreak: "break-word",}}>{preConsent ? "Pre consent" : "Post consent"}</Text>
                            </View> */}
                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                    { maxWidth: 60 },
                                  ]}
                                >
                                  {getIcon(thirdParty)}
                                </View>

                                <View
                                  style={[
                                    globalStyles.td,
                                    styles.borderRight,
                                    styles.fontSizeEight,
                                    { maxWidth: 60 },
                                  ]}
                                >
                                  {getSecureIcon(secure)}
                                </View>

                                {canonicals && (
                                  <View
                                    style={[
                                      globalStyles.td,
                                      styles.borderRight,
                                      styles.fontSizeEight,
                                    ]}
                                  >
                                    <Text>
                                      {canonicals ? canonicals.length : ""}
                                    </Text>
                                  </View>
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                )}
              </View>
            </View>
          </Fragment>
        ))}
      </View>
    </View>
  );
};

export default CookiesPage;
