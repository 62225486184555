import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import useStyles from "./styles";
import illustration from "assets/illustrations/park_illustration.svg";
import PageWrapper from "components/PageWrapper";
import Cloud from "./cloud";

const NotFound = ({ history }) => {
  const classes = useStyles();
  const handleGoBack = () => {
    history.go(-2);
  };
  return (
    <PageWrapper title="404" subtitle="Error">
      <Box
        width="100%"
        height="calc(100% - 144px)"
        pt={7}
        position="relative"
        overflow="hidden"
        zIndex={0}
      >
        <Cloud top={45} delay={5} />
        <Cloud top={149} delay={7} />
        <Cloud top={297} delay={9} />
        <Cloud top={102} delay={24} />
        <Cloud top={216} delay={22} />
        <Cloud top={320} delay={20} />
        <Box width="100%" maxWidth={500} margin="auto" mb={5}>
          <Typography component="h3" className={classes.title}>
            Oh no! Where did it go?
          </Typography>
          <Typography component="p" className={classes.description}>
            We couldn’t find what you were looking for. Our engineers are
            working to identify the problem.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button component={Link} to="/zen" className={classes.mainButton}>
              Go to Overview
            </Button>
            <Button
              onClick={handleGoBack}
              className={classes.secondaryButton}
              variant="outlined"
            >
              Go Back
            </Button>
          </Box>
        </Box>
        <Box width="100%" textAlign="center">
          <img className={classes.image} src={illustration} alt="" />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default withRouter(NotFound);
