/* eslint-disable jsx-a11y/anchor-is-valid */
import isObject from "lodash.isobject";
import isArray from "lodash.isarray";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { Box, CircularProgress } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";

import TextInput from "components/Inputs/Text";
import Button from "components/Button";

import useStyles from "./styles";

export const noOp = function () {};

export function ErrorList({ errors }) {
  return (
    <>
      {errors.map((error) => (
        <p>
          {error.title} - <small>{error.detail}</small>
        </p>
      ))}
    </>
  );
}

function LoginForm({
  simple,
  isLoggingIn = false,
  email,
  password,
  updateEmail = noOp,
  updatePwd = noOp,
  submit = noOp,
  errors = [],
}) {
  const classes = useStyles();
  return (
    <form onSubmit={submit} className={classes.form}>
      {isArray(errors) && errors.length > 0 && <ErrorList errors={errors} />}

      <TextInput
        value={email}
        type="email"
        label="Email"
        placeholder="johndoe@zendata.dev"
        onChange={updateEmail}
        style={{ paddingBottom: "1.5rem" }}
        required
      />
      <TextInput
        value={password}
        type="password"
        label="Password"
        placeholder="********"
        onChange={updatePwd}
        style={{ paddingBottom: "1.5rem" }}
        required
      />

      <Button
        type="submit"
        className={classes.submitButton}
        disabled={isLoggingIn}
      >
        Sign In
        {isLoggingIn && (
          <CircularProgress
            color="inherit"
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>

      {isObject(errors) && errors.error && (
        <Box color="error.main" textAlign="center">
          {errors.error}
        </Box>
      )}

      <p className={classes.actionLink}>
        <a className={classes.actionLinkAnchor} href="/reset-password">
          Forgot Password
        </a>
        <ArrowForward className={classes.actionLinkIcon} />
      </p>
      <p className={classes.actionLink}>
        Don't have an account?&nbsp;
        {simple ? (
          <Link to={"/signup"} className={classes.actionLinkAnchor}>
            <strong>Sign Up</strong>
          </Link>
        ) : (
          <Link
            exact
            target="_top"
            to={{ pathname: "https://www.zendata.dev/pricing" }}
            className={classes.actionLinkAnchor}
          >
            <strong>Sign Up</strong>
          </Link>
        )}
        <ArrowForward className={classes.actionLinkIcon} />
      </p>
    </form>
  );
}

LoginForm.propTypes = {
  isLoggingIn: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  updateEmail: PropTypes.func,
  updatePwd: PropTypes.func,
  submit: PropTypes.func,
  errors: PropTypes.array,
};

export default LoginForm;
