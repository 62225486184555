import {
  getAll,
  create,
  updateTableBusinessUnit,
} from "redux/_businessUnits/businessUnits.service";
import {
  requestStart,
  requestEnd,
  saveData,
} from "redux/_businessUnits/businessUnits.actions";
import { getBusinessUnitsData } from "redux/_businessUnits/businessUnits.selectors";
import isEmpty from "lodash.isempty";
import { getDatabaseSettingsSelected } from "redux/_settings/settings.selectors";
import { setTableBusinessUnit } from "redux/_settings/settings.actions";

export const getBusinessUnits = () => (dispatch, getState) => {
  const businessUnits = getBusinessUnitsData(getState());
  if (isEmpty(businessUnits)) {
    dispatch(requestStart());
    return getAll()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const createBusinessUnit = (name) => (dispatch) => {
  dispatch(requestStart());
  return create(name)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const assignBusinessUnit = (tableId, businessUnitId) => (
  dispatch,
  getState
) => {
  const dbId = getDatabaseSettingsSelected(getState());
  dispatch(requestStart());
  return updateTableBusinessUnit(dbId, tableId, businessUnitId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(setTableBusinessUnit(tableId, businessUnitId));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
