import { makeStyles } from "@material-ui/core";
import { StyleSheet } from "@react-pdf/renderer";

const grayLight = "#dfe2e9";

export const pdfStyles = StyleSheet.create({
  level1: {
    padding: "1px 0",
    fontWeight: "bold",
  },
  level2: {
    paddingLeft: 40,
    color: "#686c78",
  },
  level3: {
    paddingLeft: 80,
  },
  piiList: {
    marginBottom: 10,
  },
  list: {
    marginTop: 10,
  },
  pageUrl: {
    height: "auto",
    width: 195,
    maxWidth: 195,
    alignItems: "flex-start",
  },
  tagCell: {
    height: "auto",
    maxWidth: 200,
    width: 200,
    wordWrap: "break-word",
    display: "flex",
    flexDirection: "row",
    overflowWrap: "break-word",
    wordBreak: "break-all",
    borderRight: `1px solid ${grayLight}`,
  },
  loadedLibrary: {
    flex: 1,
    wordWrap: "break-word",
    maxWidth: 200,
    width: 200,
    flexWrap: "wrap",
    overflow: "hidden",
  },
});

export default makeStyles((theme) => ({
  level1: {
    padding: "1px 0",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  level2: {
    paddingLeft: 40,
    color: theme.palette.grey[800],
    wordBreak: "break-all",
  },
  level3: {
    paddingLeft: 80,
    wordBreak: "break-all",
  },
  piiList: {
    marginBottom: 10,
  },
}));
