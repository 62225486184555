import { Route, Switch } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import  NewRepositoryBranchFlowView  from "./NewRepositoryBranchFlowView";


const NewRepositoryBranchFlow = () => {


  return (
    <PageWrapper subtitle={"Branch Data Flow"}>
      <Switch>
        <Route path={"/zen/new-repository-branch-flow/:scan_id"}>
          <NewRepositoryBranchFlowView/>
        </Route>
      </Switch>
    </PageWrapper>
  );
};

export default NewRepositoryBranchFlow;