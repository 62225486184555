import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  liveProcesses: {},
  tableTitle: {
    color: "#1a3586",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  title: {
    color: "#1A3586",
    fontWeight: "bold",
    padding: theme.spacing(2),
    letterSpacing: "0.25px",
  },
  popoverContent: {
    maxWidth: "820px",
    "& > *": {
      width: "100%",
      "&:first-child": {
        borderBottom: "solid 1px #DFE2E9",
        [theme.breakpoints.up("md")]: {
          borderBottom: "none",
        },
      },
    },
    "& > * > .content": {
      height: 260,
      margin: "0 auto",
      "& > .action": {
        margin: "20px 0",
        display: "flex",
        justifyContent: "center",
        color: "#007bff",
        "& > a": {
          cursor: "pointer",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      "& > .privacy": {
        borderRight: "solid 1px #DFE2E9",
      },
    },
  },
  privacy: {
    color: "#868993",
    fontStyle: "italic",
    padding: "0 16px",
    height: 200,
    overflow: "auto",
    "& > li": {
      marginBottom: "20px",
    },
  },
  trackers: {
    width: "95%",
    wordBreak: "break-word",
    color: "#868993",
    fontSize: 14,
    height: 200,
    overflow: "auto",
  },
  table: {
    marginTop: "16px",
    fontSize: "14px",
    border: "solid 1px #dfe2e9",
    width: "100%",
    "& tr": {
      border: "solid 1px #dfe2e9",
      "& th, & td": {
        textAlign: "center",
        "& > .tooltip": {
          width: "100%",
          display: "inline-block",
          cursor: "help",
        },
      },
    },
    "& th": {
      "&:first-child": {
        textAlign: "left",
        paddingLeft: "16px",
      },
      "&:last-child": {
        paddingRight: "16px",
      },
    },
    "& td": {
      padding: "12px 16px",
    },
  },
  xsTable: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  smTable: {
    display: "none",
    "& tr": {
      "& th": {
        "&:first-child": {
          [theme.breakpoints.up("sm")]: {
            width: "35%",
          },
          [theme.breakpoints.up("md")]: {
            width: "45%",
          },
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      display: "table",
    },
  },
  tableHeaderCell: {
    display: "table-cell",
    verticalAlign: "middle",
    fontWeight: "600",
    lineHeight: "1.1",
    textTransform: "uppercase",
    color: "#686c78",
    backgroundColor: "#dfe2e9",
    height: "48px",
    padding: theme.spacing(0, 1),
  },
  rowHeaderCell: {
    padding: theme.spacing(1.5, 0, 1.5, 2),
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#686c78",
  },
  clickable: {
    "& svg": {
      cursor: "pointer",
      color: theme.palette.grey[700],
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
}));
