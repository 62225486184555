import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import useStyles from "./styles";
import { columns } from "./constants";
import isNil from "lodash.isnil";
import clsx from "clsx";
import {
  getRequestsData,
  getRequestsFilters,
  getRequestsLoadingState,
  getRequestsMetadata,
} from "redux/_requests/_data/data.selectors";
import { getData } from "redux/_requests/_data/data.async.actions";
import SVGIcon from "components/svg-icon/svg-icon";
import get from "lodash.get";

const pageSize = 20;

const TableHistory = ({
  selectedRow,
  setSelectedRow,
  requests = [],
  filters,
  loading,
  getRequests,
  metadata: { page = null, totalRows, pageCount },
}) => {
  const classes = useStyles();

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFirstPage = () => getRequests({ pageSize, page: 0 });
  const handlePreviousPage = () => getRequests({ pageSize, page: page - 1 });
  const handleNextPage = () => getRequests({ pageSize, page: page + 1 });
  const handleLastPage = () => getRequests({ pageSize, page: pageCount - 1 });

  return (
    <Box height="100%" position="relative">
      <Box height="calc(100% - 35px)" className={classes.tableWrapper}>
        <Table className={classes.table} stickyHeader size="small">
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((row, idx) => {
              const selected = !isNil(selectedRow) && selectedRow.id === row.id;
              return (
                <TableRow
                  hover
                  key={row.id}
                  style={{ cursor: "pointer" }}
                  className={clsx(
                    classes.tableRowBody,
                    selected && classes.selected
                  )}
                  onClick={(_) => setSelectedRow(row)}
                  role="checkbox"
                >
                  <TableCell key={columns[0].id} align={columns[0].align}>
                    {get(row, columns[0].id)}
                  </TableCell>
                  <TableCell key={columns[1].id} align={columns[1].align}>
                    {get(row, columns[1].id)}
                  </TableCell>
                  <TableCell key={columns[2].id} align={columns[2].align}>
                    {`${row.individual_right_days_left} (${row.individual_right.limit_reply_days})`}
                  </TableCell>
                  <TableCell key={columns[3].id} align={columns[3].align}>
                    {get(row, columns[3].id)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {(loading || requests.length === 0) && (
        <Box
          position="absolute"
          left="0"
          right="0"
          top={40}
          bottom={35}
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255,255,255,.7)"
        >
          {loading && <LinearProgress style={{ width: "75%" }} />}
          {requests.length === 0 && (
            <Box py={3} px={4} className={classes.textContainer}>
              <Typography className={classes.text}>
                No requests found.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Box
        className={classes.footer}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box my={-2}>
          <IconButton
            disabled={page === 0}
            onClick={handleFirstPage}
            className={classes.navButton}
          >
            <SVGIcon icon="circledForward" turn />
          </IconButton>
          <IconButton
            disabled={page === 0}
            onClick={handlePreviousPage}
            className={classes.navButton}
          >
            <SVGIcon icon="circledPlay" turn />
          </IconButton>
          <IconButton
            disabled
            className={classes.showedRows}
            style={{ fontWeight: "bold" }}
          >
            {page + 1}
          </IconButton>
          <IconButton
            disabled={page === pageCount - 1}
            onClick={handleNextPage}
            className={classes.navButton}
          >
            <SVGIcon icon="circledPlay" />
          </IconButton>
          <IconButton
            disabled={page === pageCount - 1}
            onClick={handleLastPage}
            className={classes.navButton}
          >
            <SVGIcon icon="circledForward" />
          </IconButton>
        </Box>
        <Box className={classes.showedRows}>
          {requests.length} OUT OF {totalRows} REQS
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  requests: getRequestsData(state),
  filters: getRequestsFilters(state),
  loading: getRequestsLoadingState(state),
  metadata: getRequestsMetadata(state),
});

const mapDispatchToProps = (dispatch) => ({
  getRequests: (page) => dispatch(getData(true, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHistory);
