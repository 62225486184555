import Amplify, { Storage } from "aws-amplify";

import AWS_CONFIG from "../config/aws-config";

Amplify.configure(AWS_CONFIG);

export const uploadFile = async (file, progressCallback = null) => {
  const { name, type: contentType } = file;
  const { key: uploaded } = await Storage.put(name, file, {
    contentType,
    progressCallback: (progress) => {
      progressCallback
        ? progressCallback(progress)
        : console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
    },
  });

  return uploaded;
};
