import { Text, View } from "@react-pdf/renderer";
import { useMemo } from "react";
import { ReactComponent as HighIcon } from "../../../pages/AppReport/Information_tab.svg";
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";

export const severities = [
  {
    label: "HIGH",
    value: "high",
    icon: <HighIcon />,
  },
  {
    label: "MEDIUM",
    value: "medium",
    icon: <ReportProblemOutlined style={{ color: "#ED6C02" }} />,
  },
  {
    label: "INFO",
    value: "info",
    icon: <InfoOutlined />,
  },
  {
    label: "SECURE",
    value: "secure",
    icon: <CheckCircleOutlineOutlined />,
  },
];

const AppTransportSecurityPage = ({ styles, data }) => {
  const formattedData = useMemo(() => {
    const nextData = {};

    severities.forEach((severity) => {
      nextData[severity.value] = data.ats_analysis?.ats_findings.filter(
        (el) => el.severity === severity.value
      );
    });

    return nextData;
  }, [data]);

  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>App Transport Security (ATS)</Text>
      </View>
      <View style={styles.severities_body_content}>
        {severities.map((severity) => {
          return (
            <View wrap={false}>
              <View style={styles.severities_body_item[severity.value]}>
                <Text style={styles.severities_body_item.text}>
                  {severity.label}
                </Text>
              </View>

              {formattedData[severity.value][0] ? (
                <View style={styles.table}>
                  <View style={styles.thead}>
                    <View style={styles.tr}>
                      <View style={styles.th}>
                        <Text>ISSUE</Text>
                      </View>
                      <View style={styles.th}>
                        <Text>DESCRIPTION</Text>
                      </View>
                    </View>
                  </View>
                  {Object.entries(formattedData[severity.value]).map(
                    ([key, value]) => {
                      return (
                        <View style={styles.tr} key={key}>
                          <View style={styles.td}>
                            <Text>{value.issue}</Text>
                          </View>
                          <View style={styles.td}>
                            <Text>{value.description}</Text>
                          </View>
                        </View>
                      );
                    }
                  )}
                </View>
              ) : (
                <View
                  style={[
                    styles.tr,
                    {
                      border: "1px solid #dfe2e9",
                      padding: 5,
                      marginBottom: 20,
                    },
                  ]}
                >
                  <Text style={styles.text}>
                    No Network Security for this severity
                  </Text>
                </View>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default AppTransportSecurityPage;
