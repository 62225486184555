import React, { useState } from "react";
import useStyles from "./styles";
import SectionContent from "../SectionContent";
import ExpandedPanel from "../ExpandedPanel";
import Table from "pages/AppReport/common/Table";

const Secrets = ({ data }) => {
  const [expanded, setExpanded] = useState();
  const classes = useStyles();

  return (
    <>
      <div className={classes.headerFilter}></div>
      <SectionContent>
        {data.map((secret, index) => {
          const keys = Object.keys(secret);
          const formattedObjArray = keys.map((key) => ({
            key: key,
            value: secret[key],
          }));
          return (
            <ExpandedPanel
              title={
                <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                  <div style={{ color: "grey", "font-weight": "bold" }}>
                    DATE:
                  </div>
                  <div>{secret.Date}</div>
                  <div style={{ color: "grey", "font-weight": "bold" }}>
                    RULE:
                  </div>
                  <div>{secret.RuleID}</div>
                  <div style={{ color: "grey", "font-weight": "bold" }}>
                    FILE:
                  </div>
                  <div>{secret.File}</div>
                </div>
              }
              classes={{
                root: "scrollable-element",
                header: classes[secret.address],
              }}
              simpleTitle
              key={`${secret.RuleID}_${secret.Date}_${index}`}
              onChange={() =>
                setExpanded(expanded !== secret.File ? secret.File : "")
              }
              expanded={expanded === secret.File}
            >
              {secret.RuleID.length > 0 ? (
                <Table
                  data={formattedObjArray}
                  limitByPage={10}
                  columns={[
                    {
                      key: "key",
                      width: "50%",
                    },
                    {
                      key: "value",
                      width: "50%",
                    },
                  ]}
                />
              ) : (
                <span style={{ color: "#868993" }}>{"emptyMessage"}</span>
              )}
            </ExpandedPanel>
          );
        })}
      </SectionContent>
    </>
  );
};

export default Secrets;
