import React from "react";
import clsx from "clsx";
import { Tooltip as MuiTooltip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
    background: theme.palette.grey[800],
    maxWidth: 150,
    textAlign: "center",
  },
}));

const Tooltip = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      {...props}
      classes={{ tooltip: clsx(classes.tooltip, className) }}
    />
  );
};

export default Tooltip;
