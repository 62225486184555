import React, { useState } from "react";
import { Box, Fab, makeStyles } from "@material-ui/core";
import Dendogram from "charts/TreeMap/Dendogram";
import SVGContainer from "charts/SVGContainer/SVGContainer";
import isEmpty from "lodash.isempty";
import Loader from "components/Loader";
import UnfoldLess from "@material-ui/icons/UnfoldLess";
import UnfoldMore from "@material-ui/icons/UnfoldMore";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    height: "100%",
    paddingTop: "1rem",
    paddingBottom: 83,
    overflow: "hidden",
    "& svg": {
      overflow: "visible",
    },
  },
  button: {
    width: 40,
    height: 40,
    marginLeft: theme.spacing(1),
    "& svg": {
      transform: "rotate(45deg)",
    },
  },
}));

const FunctionalStructure = ({
  data,
  loading,
  colors,
  defaultCollapsed,
  tooltip,
}) => {
  const [collapsed, setCollapsed] = useState(!!defaultCollapsed);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const classes = useStyles();
  return loading ? (
    <Loader />
  ) : (
    <Box display="flex" width="100%" height="100%" position="relative">
      <Box className={classes.chartContainer}>
        {!isEmpty(data) && (
          <SVGContainer geometricPresicion disableScrolling>
            <Dendogram
              data={data}
              colors={colors}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              tooltip={tooltip}
            />
          </SVGContainer>
        )}
      </Box>
      <Box clone position="absolute" left={0} bottom={16}>
        <Fab
          onClick={toggleCollapsed}
          title={collapsed ? "Expand Datasources" : "Collapse Datasources"}
          className={classes.button}
          color="primary"
          disabled={loading}
        >
          {collapsed ? <UnfoldMore /> : <UnfoldLess />}
        </Fab>
      </Box>
    </Box>
  );
};

export default FunctionalStructure;
