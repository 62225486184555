import {
    Card,
    Typography,
    Box,
    Grid,
  } from "@material-ui/core";

import { LinearProgressBar } from "./LinearProgressBar";
import { ReferenceSquare } from "./ReferenceSquare";
import useParentStyles from "./styles.js";

export const TagCard = ({
    title,
    bodyComponentsList,
    footerComponentsList,
  }) => {
    const parentClasses = useParentStyles();
    return (
        <Card className={parentClasses.card} elevation={16}>
            <Typography className={parentClasses.cardTitle}>
                {title}
            </Typography>
            {bodyComponentsList.map((progressBar, index) => (
                <Box className={parentClasses.progressBarContainer}>
                    <LinearProgressBar
                        percentage={progressBar.percentage}
                        text={progressBar.text}
                        color={progressBar.color}
                    />
                </Box>
            ))}
            <Box className={parentClasses.referenceBarContainer}>
                <Grid container direction="column" spacing={2}>
                {footerComponentsList.map((reference, index) => (
                    <Grid item container className={reference.style}>
                        {reference.components.map((component, index) => (
                            <Grid item className={parentClasses.cardTd}>
                            <ReferenceSquare
                                bodyContent={component.bodyContent}
                                classColour={component.colour}
                            />
                        </Grid>
                        ))}
                    </Grid>
                ))}
                </Grid>
            </Box>
        </Card>
    );
  };