import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(
  (theme) => ({
    mobileRow: {
      display: "block",
      width: "100%",
      color: theme.palette.grey[700],
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: theme.spacing(1),
      overflow: "hidden",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    mobileRowTr: {
      display: "flex",
      width: "100%",
      borderBottom: `1px solid ${theme.palette.grey[500]}`,

      "&:last-child": {
        borderBottom: "none",
      },
    },
    mobileRowTh: {
      padding: theme.spacing(2, 1),
      // minWidth: 106,
      minWidth: 90,
    },
    mobileRowTd: {
      width: "100%",
      padding: theme.spacing(2, 1),
      overflowX: "auto",
    },
    tr: {
      width: "100%",
      display: "flex",
      color: theme.palette.grey[700],
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "40px",
      textTransform: "uppercase",
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
    td: {
      padding: theme.spacing(0, 2),
    },
    domain: {
      width: "20%",
    },
    appName: {
      width: "40%",
    },
    domainData: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "&.-with-sitemap": {
        justifyContent: "space-between",
      },
      textTransform: "lowercase",
      "& > .element": {
        display: "flex",
        alignItems: "center",
        "& > .title": {
          fontWeight: "bold",
          marginRight: 6,
          textTransform: "none",
        },
      },
      "& span": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      "& a": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
    countries: {
      width: "20%",
      "&.-row": {
        height: 150,
        display: "flex",
        alignItems: "center",
      },
    },
    countriesList: {
      listStyle: "none",
      overflowY: "auto",
      padding: "5px 5px 5px 0",
      maxHeight: "100%",
      margin: 0,
      "& > .country": {
        padding: theme.spacing(1, 0),
        "&:first-child": {
          paddingTop: 0,
        },
        "&:last-child": {
          paddingBottom: 0,
        },
        "& > .country-name": {
          marginRight: 10,
        },
      },
    },
    status: {
      width: "20%",
    },
    row: {
      height: 150,
      display: "flex",
      alignItems: "center",
      fontFamily: "Inter",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    frequency: {
      width: "40%",
    },
    frequencyData: {
      display: "flex",
      flexDirection: "column",
      textTransform: "none",
      "& > .element": {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        "&:last-child": {
          marginBottom: 0,
        },
        "& > .title": {
          fontWeight: "bold",
          width: 94,
          marginRight: theme.spacing(2),
        },
        "& > .value": {
          display: "flex",
          width: 210,
          justifyContent: "space-between",
          "& > .MuiAvatar-root": {
            width: 25,
            height: 25,
            fontSize: "1rem",
          },
        },
      },
    },
    grey: {
      color: "#666666",
      backgroundColor: "#f0f0f0",
    },
    blue: {
      color: "white",
      backgroundColor: theme.palette.blue[500],
    },
    actions: {
      width: "20%",
    },
    actionButton: {
      backgroundColor: theme.palette.white[100],
      color: theme.palette.primary.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.white[200],
      },
    },
  }),
  { name: "List" }
);

export default styles;
