import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
  },
  content: {
    display: "flex",
    width: "100%",
    height: "calc(100% - 148px)",
    flexDirection: "column",
    padding: "24px 32px",
  },
  inputLabel: {
    color: theme.palette.blue[500],
    display: "block",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
}));
