import React from "react";

const bigFingerIcon = (props) => (
  <svg
    width="75"
    height="72"
    viewBox="0 0 75 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.1892 43.6896C71.9525 43.6427 71.7393 43.5438 71.5572 43.4084C71.0865 43.057 70.8316 42.453 70.9538 41.8386C73.3543 29.7354 68.5195 17.1583 58.6367 9.79588C57.9397 9.2778 57.7966 8.29111 58.3142 7.5934C58.8317 6.89569 59.8174 6.7499 60.5144 7.27058C65.963 11.3293 70.0956 16.8225 72.4649 23.1618C74.7744 29.3318 75.3179 36.0017 74.0383 42.453C73.8693 43.3043 73.0423 43.8588 72.1892 43.6896Z"
      fill="#BDBDBD"
    />
    <path
      d="M69.4429 47.0792C69.7992 46.2852 70.7303 45.9337 71.5235 46.2904C72.3167 46.6471 72.6678 47.5791 72.3115 48.3731C69.1932 55.3034 65.9137 59.3855 63.742 62.0879C63.2739 62.671 62.8682 63.1761 62.5405 63.6135C62.0229 64.3112 61.0373 64.457 60.3403 63.9363C59.6432 63.4182 59.4976 62.4315 60.0177 61.7338C60.3792 61.247 60.8006 60.7211 61.2895 60.1145C63.3597 57.5371 66.4884 53.6424 69.4429 47.0792Z"
      fill="#BDBDBD"
    />
    <path
      d="M47.8592 1.30091C50.629 1.97779 53.313 2.99573 55.8383 4.32346C56.6082 4.7322 56.902 5.67984 56.4989 6.45044C56.0958 7.22105 55.1439 7.51524 54.3741 7.11171C52.075 5.90113 49.6303 4.97692 47.1128 4.35991C34.5563 1.2905 21.4589 6.01047 13.7451 16.3902C9.42785 22.1958 3.07421 33.3098 3.26146 40.1203C3.28487 40.9898 2.60087 41.7136 1.73222 41.737C1.36551 41.7474 1.02482 41.6303 0.751743 41.4272C0.379837 41.1486 0.132763 40.7087 0.117161 40.2062C-0.109108 31.9977 7.28224 19.8085 11.2198 14.508C19.6904 3.11288 34.0726 -2.0705 47.8592 1.30091Z"
      fill="#BDBDBD"
    />
    <path
      d="M59.6431 49.1645C62.6002 45.3374 64.738 42.5726 64.5221 34.6322C64.4987 33.7627 65.1827 33.039 66.0513 33.0155C66.92 32.9921 67.643 33.6768 67.6664 34.5463C67.9135 43.6114 65.2321 47.0765 62.132 51.091C59.1515 54.9466 55.7732 59.3177 53.3987 70.2364C53.214 71.0851 52.3766 71.624 51.5261 71.4392C51.2999 71.3897 51.097 71.2934 50.9228 71.1658C50.4416 70.8092 50.1894 70.1895 50.3246 69.5673C52.8525 57.9509 56.6184 53.08 59.6431 49.1645Z"
      fill="#BDBDBD"
    />
    <path
      d="M14.7879 25.4396C14.6943 25.3953 14.6085 25.3433 14.5304 25.2834C13.9375 24.8434 13.7216 24.0285 14.0545 23.3386C14.8347 21.7193 15.7762 20.1625 16.8555 18.7072C26.1402 6.21864 43.8436 3.62044 56.3194 12.912C61.058 16.4422 64.517 21.2741 66.3219 26.8845C66.5872 27.7123 66.1346 28.5975 65.3076 28.8657C64.4805 29.1312 63.5937 28.6782 63.3284 27.8503C61.7237 22.8674 58.6523 18.5744 54.4416 15.4399C43.3572 7.1819 27.6278 9.49112 19.3783 20.5868C18.416 21.8807 17.5785 23.2657 16.8867 24.7028C16.5096 25.4864 15.5707 25.8171 14.7879 25.4396Z"
      fill="#BDBDBD"
    />
    <path
      d="M30.2807 16.4787C31.0713 16.1168 32.005 16.4631 32.3665 17.2519C32.728 18.0433 32.3821 18.978 31.594 19.3398C22.7697 23.3959 13.1729 39.2559 13.511 55.5298C13.5292 56.3993 12.84 57.1178 11.9713 57.1361C11.6072 57.1439 11.2717 57.0267 11.0012 56.8263C10.6241 56.5451 10.3771 56.0999 10.3667 55.5949C9.99736 37.8396 20.364 21.0347 30.2807 16.4787Z"
      fill="#BDBDBD"
    />
    <path
      d="M38.6759 17.6372C37.8098 17.6685 37.079 16.9864 37.0504 16.1169C37.0218 15.2473 37.7006 14.5184 38.5693 14.4897C43.3676 14.3231 48.1348 15.8175 51.9943 18.6916C56.967 22.3963 60.0359 27.5562 60.6341 33.2264C61.2868 39.4121 58.9514 45.7384 53.8799 51.5205C53.3363 52.1428 52.4105 52.2313 51.7603 51.747C51.7265 51.721 51.6927 51.695 51.6588 51.6663C51.0061 51.0936 50.941 50.0991 51.5158 49.4456C55.9917 44.3404 58.0619 38.8472 57.5053 33.5597C57.0008 28.7746 54.3766 24.3931 50.1166 21.2195C46.7798 18.7333 42.8241 17.4941 38.6759 17.6372Z"
      fill="#BDBDBD"
    />
    <path
      d="M53.313 37.1577C53.1258 40.0318 52.0777 42.7472 50.2805 45.0095C49.75 45.676 48.7903 45.7984 48.1115 45.2933C48.0985 45.2829 48.0855 45.2725 48.0699 45.2621C47.3911 44.7206 47.2767 43.7313 47.8176 43.0492C49.2142 41.2919 50.0283 39.1831 50.1739 36.952C50.2311 36.0851 50.9801 35.4264 51.8462 35.4837C52.7148 35.5409 53.3702 36.2907 53.313 37.1577Z"
      fill="#BDBDBD"
    />
    <path
      d="M49.8799 32.1045C49.1725 29.9619 47.8383 28.1161 46.023 26.7623C41.1752 23.1514 34.2988 24.1615 30.6916 29.0142C30.174 29.7119 29.1883 29.8551 28.4913 29.3371C27.7943 28.819 27.6487 27.8323 28.1688 27.1346C32.8112 20.889 41.6615 19.5899 47.9008 24.237C50.2362 25.9787 51.9553 28.3556 52.8656 31.1152C53.1387 31.9405 52.6914 32.8308 51.8669 33.1042C51.0425 33.3775 50.153 32.9298 49.8799 32.1045Z"
      fill="#BDBDBD"
    />
    <path
      d="M32.9699 61.4707C32.7697 47.2978 39.2663 41.1121 43.1467 37.4179L43.2351 37.3346C43.9737 36.6316 44.0829 35.7257 44.0439 35.0878C43.9685 33.8278 43.2481 32.5131 42.2078 31.7372C40.3769 30.3731 37.7787 30.7558 36.4159 32.5886C35.8984 33.2863 34.9127 33.4321 34.2157 32.9114C33.5187 32.3933 33.373 31.4066 33.8932 30.7089C36.2911 27.4833 40.8632 26.8116 44.0856 29.2119C45.8645 30.5371 47.0504 32.7161 47.183 34.8978C47.2949 36.741 46.6629 38.4176 45.4015 39.6151L45.3131 39.6985C41.8047 43.0386 35.9322 48.6281 36.1142 61.4265C36.1272 62.296 35.4328 63.0093 34.5642 63.0224C34.2053 63.0276 33.8724 62.9104 33.6045 62.7126C33.2248 62.4288 32.9751 61.9784 32.9699 61.4707Z"
      fill="#BDBDBD"
    />
    <path
      d="M30.2651 51.7757C30.0155 51.7314 29.7918 51.6299 29.6019 51.4893C29.139 51.1456 28.8841 50.5573 28.9908 49.9507C30.8763 39.347 38.3119 34.4422 38.6292 34.2391C39.3574 33.7705 40.3275 33.9814 40.7982 34.7077C41.2689 35.4367 41.0635 36.4104 40.3353 36.8842C40.2702 36.9284 33.7241 41.2996 32.0883 50.5026C31.9374 51.3565 31.1208 51.9267 30.2651 51.7757Z"
      fill="#BDBDBD"
    />
    <path
      d="M21.9816 59.5183C22.8971 40.7166 30.3327 33.9347 30.6474 33.6535C31.2975 33.0782 32.291 33.1381 32.8658 33.7915C33.4406 34.4424 33.3781 35.4369 32.728 36.0122C32.6603 36.0721 25.9712 42.1823 25.1208 59.6693C25.0791 60.5389 24.3405 61.208 23.4745 61.1663C23.152 61.1507 22.8555 61.0387 22.6136 60.8591C22.2079 60.5571 21.9556 60.0625 21.9816 59.5183Z"
      fill="#BDBDBD"
    />
    <path
      d="M25.0556 30.7871C25.6772 30.1805 26.6733 30.1909 27.2793 30.8132C27.8853 31.4354 27.8749 32.4325 27.2533 33.0391C27.1596 33.1302 17.914 42.4269 19.2482 63.1761C19.3028 64.043 18.6474 64.7928 17.7787 64.8474C17.3912 64.8735 17.0271 64.7537 16.7384 64.5402C16.3821 64.2747 16.1377 63.8582 16.1065 63.3765C14.6838 41.2033 24.6317 31.2011 25.0556 30.7871Z"
      fill="#BDBDBD"
    />
    <path
      d="M44.6187 46.4517C45.3729 46.0195 46.3326 46.2825 46.7643 47.0375C47.196 47.7925 46.9334 48.7531 46.1791 49.1853C46.1245 49.2165 40.7253 52.7754 42.0413 68.9269C42.1115 69.7938 41.4666 70.554 40.6031 70.6243C40.2078 70.6555 39.8333 70.5384 39.5368 70.3197C39.1857 70.0594 38.9464 69.6532 38.9074 69.1846C37.4302 51.104 43.8775 46.8735 44.6187 46.4517Z"
      fill="#BDBDBD"
    />
    <path
      d="M29.8332 52.7938C30.6758 53.002 31.1908 53.856 30.9827 54.6995C30.9671 54.7672 29.3183 61.5646 30.7981 66.8157C31.0348 67.6514 30.5484 68.5235 29.711 68.7578C29.222 68.8958 28.7227 68.7865 28.343 68.5053C28.0751 68.3049 27.867 68.0185 27.7682 67.6696C26.0647 61.6245 27.8489 54.2543 27.9269 53.9419C28.1375 53.101 28.988 52.5855 29.8332 52.7938Z"
      fill="#BDBDBD"
    />
    <path
      d="M13.251 27.4079C13.9844 27.8817 14.195 28.8502 13.7243 29.5844C13.6671 29.6729 8.00524 38.6182 7.8648 48.4096C7.8518 49.2791 7.13919 49.9742 6.27054 49.9612C5.92724 49.956 5.61254 49.8414 5.35507 49.6514C4.96496 49.3598 4.71268 48.8912 4.72048 48.3653C4.87393 37.6835 10.8245 28.2827 11.0793 27.8869C11.5475 27.1528 12.5202 26.9393 13.251 27.4079Z"
      fill="#BDBDBD"
    />
    <path
      d="M49.3259 54.176C49.9137 54.5847 50.153 55.3397 49.9033 56.0114C49.2531 57.7687 48.0802 61.9029 47.3026 70.2859C47.2219 71.1528 46.4547 71.788 45.5913 71.7073C45.2792 71.6787 44.9983 71.5615 44.7694 71.3819C44.3611 71.0617 44.1192 70.5488 44.1712 69.9943C44.9593 61.5098 46.1504 57.0762 46.9567 54.9127C47.313 53.9521 48.4833 53.5876 49.3259 54.176Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default bigFingerIcon;
