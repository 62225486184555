import {
  getPoliciesData,
  getApplicationsData,
  getPartnersData,
} from "redux/_consents/_spotlight/spotlight.service";
import {
  requestStart,
  requestEnd,
  saveData,
} from "redux/_consents/_spotlight/spotlight.actions";
import { getFilters } from "redux/_filters/filters.selectors";

export const getSpotlightPoliciesChartData = (selected) => (
  dispatch,
  getState
) => {
  const dataType = "policies";
  const state = getState();
  const filters = getFilters(state);
  dispatch(requestStart(dataType));
  return getPoliciesData(filters, selected)
    .then((data) => {
      dispatch(requestEnd(dataType));
      dispatch(saveData(dataType, data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd(dataType));
      return Promise.reject();
    });
};

export const getSpotlightApplicationsChartData = (selected) => (
  dispatch,
  getState
) => {
  const dataType = "applications";
  const state = getState();
  const filters = getFilters(state);
  dispatch(requestStart(dataType));
  return getApplicationsData(filters, selected)
    .then((data) => {
      dispatch(requestEnd(dataType));
      dispatch(saveData(dataType, data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd(dataType));
      return Promise.reject();
    });
};

export const getSpotlightPartnersChartData = (selected) => (
  dispatch,
  getState
) => {
  const dataType = "partners";
  const state = getState();
  const filters = getFilters(state);
  dispatch(requestStart(dataType));
  return getPartnersData(filters, selected)
    .then((data) => {
      dispatch(requestEnd(dataType));
      dispatch(saveData(dataType, data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd(dataType));
      return Promise.reject();
    });
};
