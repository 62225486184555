import {
  getAll,
  create,
  update,
  deleteApplicationData,
} from "redux/_consents/_applications/applications.service";
import {
  requestStart,
  requestEnd,
  saveData,
  deleteData,
} from "redux/_consents/_applications/applications.actions";
import isEmpty from "lodash.isempty";
import { getConsentsApplicationsData } from "redux/_consents/_applications/applications.selectors";

export const getApplicationsData = (force, widgetToken) => (
  dispatch,
  getState
) => {
  const applications = getConsentsApplicationsData(getState());
  if (force || isEmpty(applications)) {
    dispatch(requestStart());
    return getAll(widgetToken)
      .then((data) => {
        dispatch(saveData(data));
        dispatch(requestEnd());
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const createApplication = (data) => (dispatch) => {
  dispatch(requestStart());
  return create(data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const updateApplication = (id, data) => (dispatch) => {
  dispatch(requestStart());
  return update(id, data)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const deleteApplication = (id) => (dispatch) => {
  dispatch(requestStart());
  return deleteApplicationData(id)
    .then((_) => {
      dispatch(requestEnd());
      dispatch(deleteData(id));
      return Promise.resolve();
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
