import React from "react";

const bellNotifIcon = (props) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    className={props.className}
    onClick={props.onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4029 16.3482C16.1798 15.3884 16.0952 11.2685 16.0936 11.1645V8.4005C16.0936 5.74029 14.3933 3.45279 11.9771 2.4788C11.9719 1.11112 10.8067 0 9.37507 0C7.9434 0 6.77828 1.11118 6.77305 2.47874C4.35695 3.45273 2.6566 5.74022 2.6566 8.40043V11.1644C2.65503 11.2684 2.57039 15.3883 0.347186 16.3482C0.0939262 16.4576 -0.0452657 16.7211 0.0132892 16.9809C0.0717758 17.2406 0.312046 17.4262 0.589985 17.4262H6.22603C6.34156 18.0338 6.6421 18.5991 7.10175 19.057C7.71225 19.6651 8.51961 20 9.37497 20C10.2304 20 11.0378 19.6651 11.6482 19.057C12.1079 18.5991 12.4084 18.0337 12.5239 17.4262H18.16C18.4379 17.4262 18.6781 17.2407 18.7367 16.9809C18.7953 16.7211 18.6562 16.4576 18.4029 16.3482ZM15.4744 14.3745C15.7081 15.0519 16.039 15.7322 16.5083 16.2983H2.24182C2.71101 15.7323 3.04194 15.052 3.27564 14.3745H15.4744ZM10.7416 2.11101C10.5703 1.54394 10.0228 1.12793 9.37518 1.12793C8.72762 1.12793 8.18009 1.54398 8.00873 2.11101C8.4501 2.02346 8.90712 1.97735 9.37518 1.97735C9.84324 1.97735 10.3003 2.02349 10.7416 2.11101ZM3.8365 11.1679V8.40041C3.8365 5.48069 6.32111 3.10532 9.37516 3.10532C12.4292 3.10532 14.9138 5.48069 14.9138 8.40041V11.1725C14.9146 11.2573 14.9284 12.149 15.1634 13.2465H3.58673C3.82191 12.1479 3.83579 11.2547 3.8365 11.1679ZM7.43652 17.4262C7.68876 18.2515 8.47458 18.8722 9.37499 18.8722C10.2755 18.8722 11.0612 18.2515 11.3134 17.4262H7.43652Z"
      fill="#CFDFF8"
    />
  </svg>
);

export default bellNotifIcon;
