import scansConstants from "redux/_scans/scans.constants";
import mapArrayToObject from "utils/map-array-to-object";
import getProperty from "utils/get-property";

const initialState = {};

export const lastTwoScans = (state = initialState, action) => {
  const { envId } = action;
  const envScans = getProperty(state, `${envId}`) || {};

  switch (action.type) {
    case scansConstants.GETLASTTWO_REQUEST:
      envScans.loading = true;

      return {
        ...state,
        [envId]: {
          ...envScans,
        },
      };
    case scansConstants.GET_LAST_TWO_SUCCESS:
      const { lastScans } = action; // full scan objects received from server
      const scansHash = mapArrayToObject("id", lastScans);
      envScans.loading = false;

      return {
        ...state,
        [envId]: {
          ...envScans,
          scansHash,
        },
      };
    case scansConstants.GET_LAST_TWO_FAILURE:
      envScans.loading = false;
      const { errors } = action;

      return {
        ...state,
        [envId]: {
          ...envScans,
          errors,
        },
      };
    default:
      return state;
  }
};
