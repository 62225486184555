import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  leftContainer: {
    width: "30%",
    paddingRight: theme.spacing(4),
    borderRight: "1px solid #E0E0E0",
  },
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    lineHeight: "40px",
    color: theme.palette.blue[500],
  },
  subtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    color: "#828282",
  },
  rightContainer: {
    width: "70%",
    paddingLeft: theme.spacing(4),
  },
  testButton: {
    textTransform: "none",
  },
  testButtonText: {
    fontSize: "15px",
    lineHeight: "22px",
    textDecoration: "underline",
  },
  backButton: {
    textTransform: "none",
    position: "absolute",
    left: "72px",
    bottom: "72px",
  },
  backButtonText: {
    fontSize: "15px",
    lineHeight: "22px",
    textDecoration: "underline",
  },
  button: {
    width: "110px",
    height: "40px",
    borderRadius: "4px",
    position: "absolute",
    right: "72px",
    bottom: "72px",
  },
}));
