import { SET_PII_CHART_INFO } from "./dataFlow.actions";

const initialState = {
  chartFlow: null,
};

export const dataFlow = (state = initialState, action) => {
  switch (action.type) {
    case SET_PII_CHART_INFO:
      return {
        ...state,
        chartFlow: action.payload,
      };
    default:
      return state;
  }
};
