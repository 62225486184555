import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    marginBottom: "0px",
  },
}));
