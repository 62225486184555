import { TagCard } from "./TagCard";
import useParentStyles from "./styles.js";

export const StatusCodeTagsCard = ({
    statistics
  }) => {
    const parentClasses = useParentStyles();

    const listOfProgressBar = [
        {
            color: '#468C49',
            text: statistics.success.totalOfSuccess,
            percentage: statistics.success.percentage
        },
        {
            color: '#CBAD0F',
            text: statistics.redirects.totalOfRedirects,
            percentage: statistics.redirects.percentage
        },
        {
            color: '#D32F2F',
            text: statistics.failures.totalOfFailures,
            percentage: statistics.failures.percentage
        }
    ];

    const listOfReferences = [
        {
            style: parentClasses.gridTr,
            components: [
                {
                    bodyContent: "Successes",
                    colour: parentClasses.referenceSquareGreen,
                },
                {
                    bodyContent: "Redirects",
                    colour: parentClasses.referenceSquareYellow,
                },
            ]
        },
        {
            style: parentClasses.cardTr,
            components: [
                {
                    bodyContent: "Broken",
                    colour: parentClasses.referenceSquareRed,
                },
            ]
        }
    ];
  
    return (
        <TagCard
            title="TAG STATUS CODE"
            bodyComponentsList={listOfProgressBar}
            footerComponentsList={listOfReferences}
        />
    );
  };