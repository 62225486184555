/* eslint-disable no-unreachable */
import React, { useState, useEffect } from "react";
import { Tab, Tabs, Box } from "@material-ui/core";
import useStyles from "./styles";
import Logs from "./logs";
import Errors from "./errors";
import Comments from "./comments";
import {
  createComment,
  getComments,
  getErrors,
  getLogs,
} from "components/Scans/ScanDetails/components/ScanTabs/services";

const ScanTabs = ({ selectedScan }) => {
  // TODO: Comment next line to implement scans, errors and comments.
  return null;
  const classes = useStyles();
  const [value, setValue] = useState("logs");
  const onTabChange = (value) => setValue(value);

  const [logs, setLogs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  const getScanData = async () => {
    const { id } = selectedScan;
    setLogs(await getLogs(id));
    setErrors(await getErrors(id));
    setComments(await getComments(id));
  };

  useEffect(() => {
    setLoading(true);
    setLogs([]);
    setErrors([]);
    setComments([]);
    getScanData().then((_) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScan]);

  const onCommentSubmit = async (data) => {
    setLoading(true);
    const newComment = await createComment(selectedScan.id, data);
    setComments([...comments, newComment]);
    setLoading(false);
  };

  return (
    <Box className={classes.tabsContainer}>
      <Tabs
        value={value}
        onChange={(_, newValue) => onTabChange(newValue)}
        classes={{ flexContainer: classes.tabsHeader }}
      >
        <Tab value={"logs"} label="Scan Log" />
        <Tab value={"errors"} label="Errors" />
        {/* <Tab value={'comments'} label="Comments"/> */}
      </Tabs>
      {value === "logs" && <Logs loading={loading} data={logs} />}
      {value === "errors" && <Errors loading={loading} data={errors} />}
      {value === "comments" && (
        <Comments
          loading={loading}
          data={comments}
          onCommentSubmit={onCommentSubmit}
        />
      )}
    </Box>
  );
};

export default ScanTabs;
