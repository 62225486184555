const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  subtitleContainer: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subtitle: {
    flex: "auto",
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "400",
    letterSpacing: "1px",
    margin: 0,
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.up("xs")]: {
      fontSize: "24px",
    },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: "40px",
    // },
  },
  warning: {
    width: "22px",
    height: "22px",
    color: "#ED6C02",
  },
  bubble: {
    cursor: "default",
    width: "auto",
    height: "34px",
    padding: "8px 16px 8px 16px",
    borderRadius: "64px",
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },
  confirmed: {
    border: "2px solid #468C49",
    color: "#468C49",
  },
  unconfirmed: {
    border: "2px solid #868993",
    color: "#868993",
  },
}));

export default useStyles;
