import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  addButton: ({ envSelected }) => ({
    width: "80px",
    height: "80px",
    backgroundColor: "white",
    border: `1px dashed ${
      envSelected ? theme.palette.blue[500] : theme.palette.grey[500]
    }`,
    borderRadius: "5px !important",
    marginRight: "16px",
  }),
  addButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
  },
  addIcon: ({ envSelected }) => ({
    color: envSelected ? theme.palette.blue[500] : theme.palette.grey[500],
    marginBottom: "10px",
    boxShadow: "0px 2px 20px rgba(63, 81, 181, 0.4)",
    borderRadius: "50%",
    "& path": {
      transform: "scale(1.2) translate(-2px, -2px)",
    },
  }),
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogWrap: {
    padding: "32px",
  },
  dialogTitleWrap: {
    padding: "0",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    color: theme.palette.grey[200],
    textTransform: "none",
  },
  dialogContent: {
    margin: "1.5rem 0 0",
    padding: "0",
  },
  dialogFooter: {
    margin: "2.5rem 0 0",
    padding: "0",
  },
  actionText: {
    margin: "0 16px",
    fontWeight: "bold",
    fontStretch: "condensed",
    fontSize: "14px",
    fontFamily: "Roboto Condensed",
    color: theme.palette.grey[200],
  },
  submitButton: {
    fontSize: "15px",
    fontWeight: 600,
  },
  entityName: {
    alignSelf: "flex-start",
  },
  entityButton: {
    width: "80px",
    height: "80px",
    border: "1px solid #a4bef1 !important",
    borderRadius: "5px !important",
    marginRight: "16px",
    "&:hover": {
      backgroundColor: theme.palette.blue[100],
      "& >span:first-child": {
        color: theme.palette.blue[600],
      },
    },
    "&.Mui-selected": {
      border: `solid 1px ${theme.palette.blue[500]} !important`,
      backgroundColor: theme.palette.blue[500],
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.blue[600],
        "& >span:first-child": {
          color: "white",
        },
      },
      "& >span": {
        "& >svg": {
          "& >path": {
            fill: "white",
          },
        },
      },
    },
    "& > span:first-child": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  entityButtonSelected: {
    border: `solid 3px ${theme.palette.blue[500]} !important`,
  },
  entityButtonLabel: {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: 1.62,
    "& >svg": {
      marginBottom: theme.spacing(),
      "& >path": {
        fill: theme.palette.blue[500],
      },
    },
  },
}));
