import {
  People,
  PeopleWave,
  Brain,
  Cart,
  Wrench,
  Waveform,
  PersonCheckmark,
} from "pages/AppReport/assets";
import React from "react";

const SDKCategoryIcons = {
  Communication: <PeopleWave />,
  Social: <People />,
  "Data Intelligence": <Brain />,
  Commerce: <Cart />,
  "Development Tool": <Wrench />,
  Analytics: <Waveform />,
  Attribution: <PersonCheckmark />,
  default: <Wrench />,
};

const SDKCategory = ({ category, selected, isSelectable = true, onClick }) => {
  return (
    <div
      style={{
        color: selected ? "#ffffff" : "#868993",
        border: `2px solid #1B3586`,
        backgroundColor: selected ? "#1B3586" : "#ffffff",
        padding: "2px 6px",
        borderRadius: 64,
        lineHeigth: "18.15px",
        verticalAlign: "text-top",
        display: "inline-block",
        margin: "2px 5px",
        cursor: isSelectable ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <span
        style={{ display: "flex", alignItems: "center", wordBreak: "keep-all" }}
      >
        <span
          style={{
            color: selected ? "#ffffff" : "#1B3586",
            marginRight: "6px",
            display: "flex",
          }}
        >
          {SDKCategoryIcons[category] || SDKCategoryIcons.default}
        </span>
        {category}
      </span>
    </div>
  );
};

export default SDKCategory;
