import React, { useEffect, useState } from "react";
import { Fab, IconButton } from "@material-ui/core";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import HamburgerIcon from "assets/svgs-icons/hamburger-icon.js";
import InitialSetup from "components/Sidebar/components/InitialSetupBar";
import clsx from "clsx";
import { connect } from "react-redux";
import { selectEnv } from "redux/_environments/environment.actions";
import { getAllEnvironments } from "redux/_environments/environment.async.actions";
import { Link, withRouter } from "react-router-dom";
import { routeForKey } from "router";
import { openWizard } from "redux/_wizard/wizard.actions";
import SvgIcon from "components/svg-icon/svg-icon";
import {
  getEnvsData,
  getEnvsLoadingState,
  someEnvScanning,
  getSelectedEnvData,
  getSelectedEnvId,
} from "redux/_environments/environment.selectors";
import { runScan } from "redux/_scans/scans.async.actions";
import ScanDialog from "components/Sidebar/components/ScanDialog";
import { useSnackbar } from "notistack";
import { getInitialSetupData } from "redux/_settings/settings.async.actions";
import { getInitialSetup } from "redux/_settings/settings.selectors";
import useStyles from "./styles";

const SidebarHeader = ({
  fullWidth,
  toggleSidebar,
  selected,
  startScan,
  getAllEnvs,
  isScanning,
  environments,
  getInitialSetup,
  initialSetup,
  history,
  showPIIElements,
}) => {
  useEffect(() => {
    getAllEnvs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const runEnvScan = (envId) => {
    startScan(envId);
    const envData = environments.find((env) => env.id === envId);
    const successMessage = envData
      ? `Scanning of environment ${envData.name} has begun.`
      : `Scanning has begun`;
    enqueueSnackbar(successMessage, { variant: "success" });
    handleClose();
  };

  const [initialSetupLoaded, setInitialSetupLoaded] = useState(false);
  useEffect(() => {
    getInitialSetup().then(() => {
      setInitialSetupLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles({ fabExpanded: Boolean(anchorEl) });
  let headerMinHeight = !fullWidth ? "120px" : "180px";
  if (!showPIIElements) {
    headerMinHeight = "100px";
  }

  return (
    <div
      className={clsx(classes.headerContainer, !showPIIElements && "reports")}
      style={{ minHeight: headerMinHeight }}
    >
      <div className={classes.logoContainer}>
        {showPIIElements && (
          <IconButton onClick={toggleSidebar}>
            <HamburgerIcon />
          </IconButton>
        )}
        {fullWidth && (
          <Link to={"/"} className={classes.logo}>
            <ZenDataLogo />
          </Link>
        )}
      </div>
      {showPIIElements && (
        <div className={classes.fabContainer}>
          <ScanDialog
            envs={environments}
            selected={selected}
            anchorEl={anchorEl}
            handleClose={handleClose}
            onClick={runEnvScan}
            anchorFullWidth={fullWidth}
          />
          {selected ? (
            <Fab
              size="medium"
              variant={fullWidth ? "extended" : "round"}
              aria-label="create-environment"
              className={clsx(
                classes.fab,
                fullWidth && classes.fabOpened,
                !fullWidth && classes.fabClosed,
                Boolean(anchorEl) && classes.fabExpanded,
                isScanning && classes.isScanning
              )}
              onClick={
                isScanning
                  ? () => history.push(`/zen/environment/${selected}/scans`)
                  : handleOpen
              }
            >
              {!isScanning && <SvgIcon icon="playscan" />}
              {fullWidth && (
                <p
                  style={{
                    paddingLeft: "8px",
                    margin: "0px",
                    color: isScanning ? "white" : "#1a3586",
                  }}
                >
                  {isScanning ? (
                    <>
                      Scan in Progress
                      <SvgIcon icon="chevronRight" />
                    </>
                  ) : (
                    "SCANNER"
                  )}
                </p>
              )}
            </Fab>
          ) : (
            <Fab
              size="medium"
              variant={fullWidth ? "extended" : "round"}
              aria-label="create-environment"
              className={clsx(
                classes.fab,
                fullWidth && classes.fabOpened,
                !fullWidth && classes.fabClosed
              )}
              component="a"
              href="/zen/settings"
            >
              <SvgIcon icon={"plus"} color={"#1a3586"} />
              {fullWidth && <p style={{ margin: "0px" }}>Create Environment</p>}
            </Fab>
          )}
        </div>
      )}
      <InitialSetup
        hide={!fullWidth || !showPIIElements}
        firstLoaded={initialSetupLoaded}
        setup={initialSetup}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  environments: getEnvsData(state),
  loading: getEnvsLoadingState(state),
  selected: getSelectedEnvId(state),
  environmentData: getSelectedEnvData(state),
  isScanning: someEnvScanning(state),
  initialSetup: getInitialSetup(state),
});

const mapDispatchToProps = (dispatch) => ({
  openWizardModal: () => dispatch(openWizard()),
  startScan: (envId) => dispatch(runScan(envId)),
  getAllEnvs: () => dispatch(getAllEnvironments()),
  selectEnvById: (envId) => dispatch(selectEnv(envId)),
  getInitialSetup: () => dispatch(getInitialSetupData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarHeader));
