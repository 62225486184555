import React from "react";
import LeakChart from "assets/pii-icons/signal_cellular_alt.svg";
import SeverityChart from "assets/pii-icons/signal_cellular_alt_2_bar.png";
import { Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";

const ScanDetailRow = () => {
  const summary = useSelector((state) => state.scanner.repositoryScanSummary);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 24,
        textAlign: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background: "#F0F0F0",
          height: 155,
          borderRadius: 10,
          width: "100%",
          gap: 8,
        }}
      >
        <Typography
          style={{ fontFamily: "Aldrich", fontSize: 36, color: "#1B3586" }}
        >
          {summary ? summary.scans || 0 : 0}
        </Typography>
        <Typography style={{ fontFamily: "Inter", fontSize: 16 }}>
          Successful scans
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background: "#DCEAD7",
          height: 155,
          borderRadius: 10,
          width: "100%",
          textAlign: "center",
          gap: 8,
        }}
      >
        <Typography
          style={{ fontFamily: "Aldrich", fontSize: 36, color: "#1B3586" }}
        >
          {summary ? summary.newLekeages || 0 : 0}
        </Typography>
        <Typography style={{ fontFamily: "Inter", fontSize: 16 }}>
          Leakages corrected since last scan
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background: "#E7DCD8",
          height: 155,
          borderRadius: 10,
          width: "100%",
          gap: 8,
        }}
      >
        <Typography
          style={{ fontFamily: "Aldrich", fontSize: 36, color: "#1B3586" }}
        >
          {summary ? summary.newSharingPii || 0 : 0}
        </Typography>

        <Typography style={{ fontFamily: "Inter", fontSize: 16 }}>
          New PII sharing detected in the last scan
        </Typography>
      </Box>
    </Box>
  );
};

export default ScanDetailRow;
