import api from "utils/fetch";

const doNotHandleResponses = true;

export const retrieveKey = (product) => {
  return api.get(
    `certificates/check-out/?product=${product}`,
    doNotHandleResponses
  );
};
