import { CONSTANTS } from "redux/_admin/_users/users.constants";
import unionBy from "lodash.unionby";
import isArray from "lodash.isarray";

const initialState = {
  data: [],
  loading: {
    fetch: false,
    create: false,
    remove: false,
    keys: [],
  },
};

export const users = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.ADMIN_USERS_REQUEST_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          [data]: true,
        },
      };
    case CONSTANTS.ADMIN_USERS_REQUEST_END:
      return {
        ...state,
        loading: {
          ...state.loading,
          [data]: false,
        },
      };

    case CONSTANTS.ADMIN_USERS_REQUEST_START_KEY:
      return {
        ...state,
        loading: {
          ...state.loading,
          keys: [...state.loading.keys, data],
        },
      };

    case CONSTANTS.ADMIN_USERS_REQUEST_END_KEY:
      return {
        ...state,
        loading: {
          ...state.loading,
          keys: state.loading.keys.filter((key) => key !== data),
        },
      };

    case CONSTANTS.ADMIN_USERS_SAVE_DATA:
      return {
        ...state,
        data: isArray(data)
          ? unionBy(state.data, data, "id")
          : unionBy(state.data, [data], "id"),
      };
    case CONSTANTS.ADMIN_USERS_REMOVE:
      const newData = state.data.filter((element) => element.key !== data);
      return {
        ...state,
        data: newData,
      };
    default:
      return state;
  }
};
