import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import EntitySelector from "components/SettingsSections/EntitySelector";

import { getAccountData } from "redux/_account/account.selectors";

import useSlack from "hooks/useSlack";

const Slack = () => {
  const { getWorkSpaces, workSpaces } = useSlack();
  const userAccount = useSelector((state) => getAccountData(state));

  useEffect(() => {
    getWorkSpaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = (index) => {
    if (!index) {
      const authUrl = `${process.env.REACT_APP_SLACK_AUTH_URL}`;
      const clientId = `${process.env.REACT_APP_SLACK_CLIENT_ID}`;
      const scope = `${process.env.REACT_APP_SLACK_SCOPE}`;
      const redirectUrl = `${process.env.REACT_APP_SLACK_REDIRECT_URL}`;
      const companyId = userAccount.company.id;

      window.location.href = `${authUrl}?client_id=${clientId}&scope=${scope}&state=${companyId}&redirect_uri=${redirectUrl}`;
    }
  };

  return (
    <>
      <EntitySelector
        entityName="Slack Workspace"
        entities={workSpaces.map((w) => ({ ...w, icon: w.icon.image_34 }))}
        newLabel="New"
        onSelect={select}
      />
    </>
  );
};

export default Slack;
