import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  header: {
    height: "auto",
    borderBottom: "1px solid #e0e0e0",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "0 24px",
  },
  content: {
    height: "auto",
    padding: "32px 24px",
  },
  columnContainer: {
    height: "100%",
  },
  boxContent: {
    height: "calc(50% - 74px)",
  },
  midHeight: {
    height: "50%",
  },
  boxTitle: {
    marginBottom: "16px",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: "2px",
    color: "#828282",
  },
  quarterHeight: {
    maxHeight: "25%",
  },
  piiGroups: {
    height: 225,
  },
}));
