import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import { SnackbarProvider, useSnackbar } from "notistack";
import useStyles from "./styles";

const DismissButton = ({ id, className }) => {
  const { closeSnackbar } = useSnackbar();
  const handleClose = () => closeSnackbar(id);

  return (
    <Button
      aria-label="close"
      className={clsx("close-button", className)}
      onClick={handleClose}
    >
      Dismiss
    </Button>
  );
};

const Snackbar = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={(key) => (
        <DismissButton className={classes.closeButton} id={key} />
      )}
      classes={{
        root: classes.snackbar,
        variantError: classes.error,
        variantSuccess: classes.success,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Snackbar;
