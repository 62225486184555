import { CONSTANTS } from "redux/_functionalUses/functionalUses.constants";

export const requestStart = () => ({
  type: CONSTANTS.FUNCTIONAL_USES_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.FUNCTIONAL_USES_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.FUNCTIONAL_USES_SAVE_DATA,
  data,
});
