import React from "react";
import { connect } from "react-redux";
import { Update } from "@material-ui/icons";

import {
  deleteSchedulers,
  listSchedulers,
} from "redux/_reports/reports.async.actions";
import {
  getSchedulers,
  getScheduledReportsMeta,
} from "redux/_reports/reports.selectors";

import ScanList, { ScanListRow, SCAN_TYPE } from "components/ScanList";

import useStyles from "./styles";

const rowGenerator = (schedules = [], preRemoveSchedule) => (editSchedule) =>
  schedules.map((schedule, index) => (
    <ScanListRow
      key={`schedule-${index}`}
      {...schedule}
      deviceType={schedule.environment}
      deleteItem={preRemoveSchedule}
      editItem={editSchedule}
      deleteDialogMessage="Are you sure you want to delete this scheduled report?"
    />
  ));

const SchedulingList = ({
  selectSchedule,
  goToSection,
  schedules: data,
  schedulesMeta,
  fetchSchedules,
  setIndex,
  removeSchedule,
}) => {
  const classes = useStyles();

  const editSchedule = (id) => {
    selectSchedule(id);
    goToSection();
    setIndex(1);
  };

  const scanListProps = {
    cta: "Create a new scheduled scan",
    ctaIcon: <Update />,
    emptyNotice: "You don't have any scheduled scans yet.",
    headers: [
      { label: "URL", className: "domain" },
      { label: "Countries", className: "countries" },
      { label: "Frequency", className: "frequency" },
      { label: "Actions", className: "actions" },
    ],
    goToSection,
    rowGenerator,
    rowGeneratorArgs: [editSchedule],
    deleteItem: removeSchedule,
    getItems: fetchSchedules,
    data: data,
    scanType: SCAN_TYPE.scheduled,
    itemsPerPage: 3,
    totalPages: schedulesMeta?.count ? Math.ceil(schedulesMeta.count / 3) : 0, // 3 itemsPerPage
  };

  return <ScanList {...scanListProps} />;
};

const mapStateToProps = (state) => ({
  schedules: getSchedulers(state),
  schedulesMeta: getScheduledReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeSchedule: (id) => dispatch(deleteSchedulers(id)),
  fetchSchedules: (filters) =>
    dispatch(listSchedulers({ ...filters, environment: "web" })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingList);
