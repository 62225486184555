import React, { useEffect } from "react";
import { connect } from "react-redux";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import {
  getFilterConsentDateRange,
  getFilterConsentCustomerVisibility,
  getFilterConsentsUnconsents,
} from "redux/_filters/filters.selectors";
import Loader from "components/Loader";
import { getOverviewData } from "redux/_consents/_overview/overview.async.actions";
import {
  getConsentsOverviewData,
  getOverviewLoadingState,
} from "redux/_consents/_overview/overview.selectors";

import Policies from "components/Consents/Analytics/Spotlight/components/policies";
import Applications from "components/Consents/Analytics/Spotlight/components/applications";
import Partners from "components/Consents/Analytics/Spotlight/components/partners";

const Spotlight = ({
  dateFilter,
  visibilityFilter,
  unconsentsFilter,
  generalData: { policies, applications, partners },
  getGeneralData,
  generalDataLoading,
}) => {
  useEffect(() => {
    getGeneralData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, visibilityFilter]);

  const colors = {
    policies: ["#1a3586", "#7686b6", "#cad0e3"],
    applications: ["#1a3586", "#7686b6", "#cad0e3"],
    partners: ["#1a3586", "#7686b6", "#cad0e3"],
  };

  const classes = useStyles();

  if (generalDataLoading)
    return (
      <div className={classes.mainContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={classes.mainContainer}>
      <div>
        <Typography className={classes.title}>Policies</Typography>
        <Policies
          policies={policies}
          colors={colors.policies}
          showUnconsents={unconsentsFilter}
        />
      </div>
      <div>
        <Typography className={classes.title}>Applications</Typography>
        <Applications
          applications={applications}
          colors={colors.applications}
          showUnconsents={unconsentsFilter}
        />
      </div>
      <div>
        <Typography className={classes.title}>Partners</Typography>
        <Partners
          partners={partners}
          colors={colors.applications}
          showUnconsents={unconsentsFilter}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  generalData: getConsentsOverviewData(state),
  generalDataLoading: getOverviewLoadingState(state),
  dateFilter: getFilterConsentDateRange(state),
  visibilityFilter: getFilterConsentCustomerVisibility(state),
  unconsentsFilter: getFilterConsentsUnconsents(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralData: (force) => dispatch(getOverviewData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Spotlight);
