import { CONSTANTS } from "redux/_settings/settings.constants";

export const requestStart = () => ({
  type: CONSTANTS.SETTINGS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.SETTINGS_REQUEST_END,
});

export const saveData = (dataType, data) => ({
  type: CONSTANTS.SETTINGS_SAVE_DATA,
  dataType,
  data,
});

export const selectData = (dataType, data) => ({
  type: CONSTANTS.SETTINGS_SELECT_ENTITY,
  dataType,
  data,
});

export const saveDatabaseTables = (data) => ({
  type: CONSTANTS.SETTINGS_SAVE_DB_TABLES_DATA,
  data,
});

export const setTableFilters = (dataType, data) => ({
  type: CONSTANTS.SETTINGS_SET_DB_TABLES_FILTERS,
  dataType,
  data,
});

export const resetTablesFilters = () => ({
  type: CONSTANTS.SETTINGS_RESET_DB_TABLES_FILTERS,
});

export const setTableFunctionalUse = (tableId, functionalUseId) => ({
  type: CONSTANTS.SETTINGS_ST_DB_TABLE_FID,
  data: {
    tableId,
    functionalUseId,
  },
});

export const setTableBusinessUnit = (tableId, businessUnitId) => ({
  type: CONSTANTS.SETTINGS_ST_DB_TABLE_BUSINESS_UNIT_ID,
  data: {
    tableId,
    businessUnitId,
  },
});

export const setTablePolicy = (tableId, policyId) => ({
  type: CONSTANTS.SETTINGS_ST_DB_TABLE_POLICY_ID,
  data: {
    tableId,
    policyId,
  },
});

export const saveInitialSetup = (data) => ({
  type: CONSTANTS.SETTINGS_SAVE_INITIAL_SETUP,
  data,
});

export const initialSetupRequestStart = () => ({
  type: CONSTANTS.SETTINGS_INITIAL_SETUP_REQUEST_START,
});

export const initialSetupRequestEnd = () => ({
  type: CONSTANTS.SETTINGS_INITIAL_SETUP_REQUEST_END,
});
