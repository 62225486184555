import React from "react";
import { Box, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.22,
    letterSpacing: "normal",
    color: theme.palette.grey[200],
    userSelect: "none",
  },
  checkbox: {
    color: theme.palette.blue["young"],
    "&$checked": {
      color: theme.palette.blue["young"],
    },
  },
}));

const CustomCheckbox = ({ label, ...restProps }) => {
  const classes = useStyles();
  return (
    <Box clone pl={3}>
      <FormControlLabel
        {...restProps}
        classes={{ label: classes.label }}
        control={
          <Checkbox
            color="default"
            className={classes.checkbox}
            icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
            checkedIcon={<CheckBoxIcon fontSize="large" />}
          />
        }
        label={label}
      />
    </Box>
  );
};

export default CustomCheckbox;
