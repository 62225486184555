import React from "react";

const CircledPlay = ({ turn, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    style={turn ? { transform: "rotate(180deg)" } : {}}
    {...restProps}
  >
    <path
      fill="#3F51B5"
      fillRule="evenodd"
      d="M10 19.167A9.167 9.167 0 1 1 10 .833a9.167 9.167 0 0 1 0 18.334zm0-1.667a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zM8.923 6.077L7.744 7.256 10.488 10l-2.744 2.744 1.179 1.178L12.845 10 8.923 6.077z"
      clipRule="evenodd"
    />
  </svg>
);

export default CircledPlay;
