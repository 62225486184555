import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isNil from "lodash.isnil";
import { Box, Typography } from "@material-ui/core";

import { getScanById, getScanList } from "redux/_scans/scans.selectors";

import ScanDetailsHeader from "./components/ScanInformation";
import ScanInsights from "components/Scans/ScanDetails/components/ScanInsights";
import ScanTabs from "components/Scans/ScanDetails/components/ScanTabs";
import EmptyScan from "assets/illustrations/emptyscan.svg";

import useStyles from "./styles";

const ScanDetails = ({ getCurrentScan, scans, selectedRow }) => {
  const classes = useStyles();

  const [scan, setScan] = useState(null);

  useEffect(() => {
    if (selectedRow) {
      setScan(getCurrentScan(selectedRow.id));
    }
  }, [selectedRow, getCurrentScan]);

  useEffect(() => {
    if (scans.length && !selectedRow) {
      setScan(null);
    }
  }, [scans]);

  return (
    <Box className={classes.container}>
      {!isNil(scan) ? (
        <Box className={classes.content}>
          <ScanDetailsHeader selectedRow={scan} />
          <ScanInsights selectedRow={scan} />
          <ScanTabs selectedScan={scan} />
        </Box>
      ) : (
        <Box className={classes.emptyContainer}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.emptyTitle}>
              Select a Scan
            </Typography>
            <Typography className={classes.emptySubtitle}>
              To see information about it and access different visualizations.
            </Typography>
          </Box>
          <Box className={classes.imageContainer}>
            <img src={EmptyScan} alt="" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  scans: getScanList(state),
  getCurrentScan: (scanId) => getScanById(state, scanId),
});

export default connect(mapStateToProps)(ScanDetails);
