import React from "react";

const RequestsIcon = () => {
  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 21.5625C38.4838 21.5625 39.2812 20.765 39.2812 19.7812C39.2812 18.7975 38.4838 18 37.5 18C36.5162 18 35.7188 18.7975 35.7188 19.7812C35.7188 20.765 36.5162 21.5625 37.5 21.5625Z"
        fill="#122873"
      />
      <path
        d="M37.5 39.2812C36.5167 39.2812 35.7188 38.485 35.7188 37.5V26.1562C35.7188 25.173 36.5167 24.375 37.5 24.375C38.4832 24.375 39.2812 25.173 39.2812 26.1562V37.5C39.2812 38.485 38.4832 39.2812 37.5 39.2812Z"
        fill="#122873"
      />
      <path
        d="M20.8008 63C20.5033 63 20.2041 62.9252 19.9333 62.7738C19.2903 62.4157 18.934 61.6979 19.0391 60.9658L20.8952 47.9734C13.4211 43.6253 9 36.6713 9 29.1562C9 16.3883 21.7858 6 37.5 6C53.2142 6 66 16.3883 66 29.1562C66 41.9243 53.2142 52.3125 37.5 52.3125C36.5452 52.3125 35.5638 52.2644 34.4523 52.1611L21.9408 62.5885C21.6148 62.8611 21.2087 63 20.8008 63ZM37.5 9.5625C23.7488 9.5625 12.5625 18.353 12.5625 29.1562C12.5625 35.6881 16.711 41.7728 23.6615 45.4369C24.3277 45.7878 24.6999 46.5181 24.5931 47.2644L23.2197 56.8832L32.7476 48.9442C33.1181 48.6342 33.5991 48.4846 34.08 48.5416C35.3803 48.6841 36.4651 48.75 37.5 48.75C51.2513 48.75 62.4375 39.9595 62.4375 29.1562C62.4375 18.353 51.2513 9.5625 37.5 9.5625Z"
        fill="#122873"
      />
    </svg>
  );
};

export default RequestsIcon;
