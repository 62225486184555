import React, { useCallback, useEffect } from "react";
import Table from "pages/AppReport/common/Table";
import { SECTIONS_CONTENT } from "pages/DataSourceReport/constants";
import Page from "components/PrivacyReports/Report/components/Page";
import { useState } from "react";
import { Button, Icon } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import SectionContent from "../SectionContent";
import SensitivePiiChart from "components/RepositoryResultsView/SensitiveChart";
import InsensitivePiiChart from "components/RepositoryResultsView/InsensitiveChart";
import useStyles from "./styles";
import clsx from "clsx";
import { formatChart } from "../Landing";
import config from "config";
import { authHeader } from "helpers/auth-header";
import Loader from "components/Loader";
import Resumen from "./Resumen";

export const getDataSourceReportDetailsData = (reportId, currentPage, limitByPage = 10) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/data-scans/`;

  return fetch(`${url}${reportId}/items/?pageSize=${limitByPage}&page=${currentPage}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log({ data });
      return data;
    });
};

const DataSourceResume = ({ goToNextSectionAction, data }) => {
  const classes = useStyles();
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [limitByPage, setLimitByPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataSourceData, setDataSourceData] = useState([]);

  const formatSensitiveChart = useCallback(
    (summary) =>
      formatChart(
        summary.sensitiveEntitiesCount,
        summary.totalSensitiveEntities
      ),
    []
  );
  const formatNonSensitiveChart = useCallback(
    (summary) =>
      formatChart(
        summary.nonSensitiveEntitiesCount,
        summary.totalNonSensitiveEntities
      ),
    []
  );

  useEffect(() => {
    setLoading(true);
    getDataSourceReportDetailsData(data?.id, currentPage, limitByPage)
      .then((data) => {
        setTotalRows(data?.meta?.totalRows || data?.data?.length)
        setDataSourceData(data);
      })
      .catch((err) => console.log("ERRORRRR", { errorAPI: err }))
      .finally(() => setLoading(false));
  }, [limitByPage, currentPage]);

  return (
    <Page
      key={SECTIONS_CONTENT.dataSource.key}
      footerBackgroundColor={SECTIONS_CONTENT.dataSource.backgroundColor}
      hasFooter={false}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          marginBottom: 30,
        }}
      >
        {selectedDataSource && (
          <Button
            onClick={() => setSelectedDataSource(null)}
            style={{ minWidth: 20, padding: "10px 8px", paddingLeft: 16 }}
          >
            <ArrowBackIos className={classes.backButton} />
          </Button>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              color: "#1B3586",
              fontSize: 36,
              fontFamily: "Aldrich",
              fontWeight: 500,
              margin: 0,
            }}
          >
            {data.provider?.name}
          </h2>
          {selectedDataSource?.itemName && (
            <span
              style={{
                display: "block",
                fontSize: 18,
                color: "#1B3586" /* marginBottom: 28 */,
              }}
            >
              File: {selectedDataSource.itemName}
            </span>
          )}
        </div>
      </div>
      {!selectedDataSource ? (
        loading ? (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <Resumen
            setSelectedDataSource={setSelectedDataSource}
            dataSourceData={dataSourceData}
            limitByPage={limitByPage}
            currentPage={currentPage}
            setLimitByPage={setLimitByPage}
            setCurrentPage={setCurrentPage}
            totalItems={totalRows}
          />
        )
      ) : (
        <SectionContent className="scrollable-element" styles={{ padding: 5 }}>
          <div className={clsx(classes.cardList, "scrollable-element")}>
            <div className={classes.cardChart}>
              <SensitivePiiChart
                data={selectedDataSource}
                label="HIGHLY SENSITIVE PII OVERVIEW"
                formatData={formatSensitiveChart}
              ></SensitivePiiChart>
            </div>
            <div className={classes.cardChart}>
              <InsensitivePiiChart
                data={selectedDataSource}
                label="MODERATELY SENSITIVE PII OVERVIEW"
                formatData={formatNonSensitiveChart}
              ></InsensitivePiiChart>
            </div>
          </div>
          {selectedDataSource?.entities && (
            <Table
              className="scrollable-element"
              data={selectedDataSource?.entities || []}
              limitByPage={10}
              variant="primary"
              columns={[
                {
                  key: "label",
                  label: "Type",
                  width: "40%",
                  render: (label, el) => <b>{label || el.type}</b>,
                },
                {
                  key: "start",
                  label: "Start",
                  width: "30%",
                },
                {
                  key: "end",
                  label: "End",
                  width: "30%",
                },
              ]}
            />
          )}
        </SectionContent>
      )}
    </Page>
  );
};

export default DataSourceResume;
