import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import Radialchart from "charts/Consents/Radialchart";
import Grid from "@material-ui/core/Grid";
import EmptyOverlay from "components/EmptyOverlay";

const isEmpty = (arr) =>
  arr &&
  arr.every(
    ({ full_consent, opted_in, opted_out }) =>
      !full_consent && !opted_in && !opted_out
  );

const MostConsented = ({ loading, policies, applications, partners }) => {
  const classes = useStyles();
  const colors = {
    policies: ["#1a3586", "#7686b6", "#cad0e3"],
    applications: ["#1a3586", "#7686b6", "#cad0e3"],
    partners: ["#1a3586", "#7686b6", "#cad0e3"],
    // policies: ['#2b94f4', '#5eb7f8', '#7ecdfc'],
    // applications: ['#ac5dc5', '#d06cdb', '#e876eb'],
    // partners: ['#e25d2f', '#ed8e52', '#f6af75']
  };

  const isEmptyData = useMemo(
    () => isEmpty(policies) && isEmpty(applications) && isEmpty(partners),
    [policies, applications, partners]
  );

  return (
    <Box style={{ width: "100%" }}>
      <Typography className={classes.title}>TOP 3 MOST CONSENTED</Typography>
      <EmptyOverlay
        show={isEmptyData}
        height="auto"
        text="No Consents collected."
        link="/zen/consents/collector"
      >
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={4} className={classes.gridItem}>
            <Radialchart
              loading={loading}
              title="Policies"
              data={!isEmptyData ? policies : []}
              colors={colors.policies}
            />
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Radialchart
              loading={loading}
              title="Applications"
              data={!isEmptyData ? applications : []}
              colors={colors.applications}
            />
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <Radialchart
              loading={loading}
              title="Partners"
              data={!isEmptyData ? partners : []}
              colors={colors.partners}
            />
          </Grid>
        </Grid>
      </EmptyOverlay>
    </Box>
  );
};

export default MostConsented;
