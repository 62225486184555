import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import useStyles from "./styles";
import { columns } from "./constants";
import { formatNumber } from "utils/format";
import {
  getScanningState,
  getSelectedEnvId,
  getEnvsData,
  someEnvScanning,
} from "redux/_environments/environment.selectors";
import { setList } from "redux/_scans/scans.actions";
import { getScansByEnvId } from "redux/_scans/scans.selectors";
import { getScansById } from "redux/_scans/scans.service";
import isNil from "lodash.isnil";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
import clsx from "clsx";

import {
  getFilterScansDateRange,
  getFilterScansVisibility,
} from "redux/_filters/filters.selectors";
import EmptyOverlay from "components/EmptyOverlay";
import isEmpty from "lodash.isempty";

const { TYPES } = CONSTANTS;

const TableHistory = ({
  envId,
  envs = [],
  isScanning,
  selectedRow,
  setSelectedRow,
  visibility,
  dateRange,
  setScanList,
  someEnvIsScanning,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [scans, setScans] = useState([]);

  const getScans = (shouldGetUncompleted = false) => {
    const [from, to] = dateRange;
    if (!isNil(envId)) {
      setIsLoading(true);
      getScansById(
        {
          envId,
          from: from.format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"),
          ...(visibility ? {} : { failed: 0 }),
          ...(shouldGetUncompleted ? { completed: 0 } : {}),
        },
        true
      )
        .then(({ data }) => {
          setScanList(data);
          setScans(data);
          setIsLoading(false);
        })
        .catch((_) => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (!isScanning) getScans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanning]);

  useEffect(() => {
    if (!isNil(envId)) {
      setIsLoading(true);
      setScans([]);
      setSelectedRow(null);
      getScans(someEnvIsScanning);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envId, dateRange, visibility, someEnvIsScanning]);

  useEffect(() => {
    if (
      scans.length > 0 &&
      isScanning &&
      ((selectedRow && selectedRow.id !== scans[0].id) || !selectedRow)
    ) {
      setSelectedRow(scans[0]);
    }
  }, [scans, selectedRow, isScanning, setSelectedRow]);

  const runScan = () => {
    const runScanButton = document.querySelector("#run-scan");
    if (runScanButton) runScanButton.click();
  };

  return (
    <EmptyOverlay
      show={isEmpty(scans)}
      onClick={envs.length > 0 ? runScan : undefined}
      ctaText={envs.length > 0 ? "Run Scan" : undefined}
      hideCta={isScanning}
      text={
        envs.length === 0
          ? "No Environments created."
          : "No Scans were run in the selected period of time."
      }
    >
      <Box className={classes.tableWrapper}>
        <Box
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100% - 43px)",
            display: "flex",
          }}
        >
          {!isLoading ? (
            <Box style={{ width: "100%", height: "100%" }}>
              <Table stickyHeader size="small">
                <TableHead classes={classes.tableHead}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        classes={classes.tableHeadCell}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scans.map((row, idx) => {
                    const selected =
                      !isNil(selectedRow) && selectedRow.id === row.id;
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        style={{ cursor: "pointer" }}
                        className={clsx(
                          classes.row,
                          selected && classes.selected
                        )}
                        onClick={(_) => setSelectedRow(row)}
                        role="checkbox"
                      >
                        <TableCell key={columns[0].id} align={columns[0].align}>
                          {row.id}
                        </TableCell>
                        <TableCell key={columns[1].id} align={columns[1].align}>
                          {row[columns[1].id]}
                        </TableCell>
                        <TableCell key={columns[2].id} align={columns[2].align}>
                          {formatNumber(row[columns[2].id])}
                        </TableCell>
                        <TableCell key={columns[3].id} align={columns[3].align}>
                          {formatNumber(row[columns[3].id])}
                        </TableCell>
                        <TableCell key={columns[4].id} align={columns[4].align}>
                          {formatNumber(row[columns[4].id])}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <LinearProgress style={{ width: "75%" }} />
          )}
        </Box>
      </Box>
    </EmptyOverlay>
  );
};

const mapStateToProps = (state) => ({
  envId: getSelectedEnvId(state),
  envs: getEnvsData(state),
  scans: getScansByEnvId(state),
  isScanning: getScanningState(state),
  visibility: getFilterScansVisibility(state),
  dateRange: getFilterScansDateRange(state),
  someEnvIsScanning: someEnvScanning(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectScan: (scanId) => dispatch(selectData(TYPES.SCAN, scanId)),
  setScanList: (scans) => dispatch(setList(scans)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHistory);
