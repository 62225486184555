import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 0),
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "48px",
    color: theme.palette.blue[500],
    textTransform: "none",
    margin: 0,
  },
  tableContainer: {
    borderBottom: "1px solid #E0E1E3",
    width: "100%",
  },
  head: {
    display: "flex",
    color: "#868993",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
    borderBottom: "1px solid #E0E1E3",
  },
  tr: {
    borderBottom: "1px solid #E0E1E3",
    width: "100%",
    display: "flex",
  },
  cookieName: {
    width: "40%",
  },
  provider: {
    width: "60%",
  },
  summary: {
    wordBreak: "break-word",
    padding: 16,
    minHeight: "unset!important",
  },
  expanded: {
    margin: "0!important",
  },
  content: {
    margin: "0!important",
  },
  titleSimple: {
    width: "100%!important",
  },
  expansionDetail: {
    // padding: 0,
    padding: 16,
  },
  expandIcon: {
    borderRadius: 6,
    padding: theme.spacing(0.5),
    "&$expanded": {
      transform: "none",
      "& svg": {
        transform: "rotate(-180deg)",
      },
    },
    "& svg": {
      width: 20,
      height: 20,
      transition: "transform .3s ease",
      "& path": {
        fill: "#1B3586",
        color: "#1B3586",
      },
    },
  },
  expandText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "40px",
    color: theme.palette.blue[400],
    marginRight: "10px",
    textTransform: "uppercase",
  },
  cookies: {
    height: "calc(100vh - 640px)",
    minHeight: 80,
    overflowY: "auto",
  },
}));
