import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  tabs: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.6)",
  },
  tab: {
    // color: "#466DE8",
    color: "#1B3586",
  },
  indicator: {
    // backgroundColor: "#466DE8",
  },
}));

export default useStyles;
