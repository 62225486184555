import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

import PublicNav from "components/PublicComponents/Nav";

import useStyles from "./styles";

const StepperDialog = ({ open, onClose, children }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullScreen={true}
      fullWidth={true}
      className={classes.dialog}
    >
      <PublicNav />
      <div className={classes.dialogBackground}>
        <span className="circle filled small upper"></span>
        <span className="circle filled small lower"></span>
        <span className="circle empty big"></span>
        <span className="circle empty bigger"></span>
        <span className="circle empty bigger-1"></span>
      </div>
      <DialogContent dividers={true} className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default StepperDialog;
