import { CONSTANTS } from "redux/_reports/reports.constants";

export const requestStart = (dataType) => ({
  type: CONSTANTS.REPORTS_REQUEST_START,
  dataType,
});

export const requestEnd = (dataType) => ({
  type: CONSTANTS.REPORTS_REQUEST_END,
  dataType,
});

export const saveData = (data, dataType) => ({
  type: CONSTANTS.REPORTS_SAVE_DATA,
  data,
  dataType,
});

export const saveReportsData = (data) => ({
  type: CONSTANTS.REPORTS_SAVE_REPORTS_DATA,
  data,
});

export const saveJSON = (data) => ({
  type: CONSTANTS.REPORTS_SAVE_JSON,
  data,
});

export const setMeta = (data, dataType) => ({
  type: CONSTANTS.REPORTS_SET_META,
  data,
  dataType,
});

export const resetData = (data, dataType) => ({
  type: CONSTANTS.REPORTS_RESET_DATA,
  data,
  dataType,
});

export const saveSchedule = (data, id) => ({
  type: CONSTANTS.REPORTS_SCHEDULE_SAVE,
  data,
  id,
});

export const saveScheduleList = (data) => ({
  type: CONSTANTS.REPORTS_SCHEDULE_LIST,
  data,
});

export const dropSchedule = (id) => ({
  type: CONSTANTS.REPORTS_SCHEDULE_DELETE,
  data: id,
});

export const dropSingleRunReport = (id) => ({
  type: CONSTANTS.REPORTS_SINGLE_RUN_DELETE,
  data: id,
});

export const setHref = (hrefs) => ({
  type: "ADD_HREF",
  payload: hrefs,
});
