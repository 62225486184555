import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";

const EmailsSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.emails.key}
      footerBackgroundColor={SECTIONS_CONTENT.emails.backgroundColor}
      footerText={
        <div className="privacy-tech">
          {/* Check the test scores in detail */}
        </div>
      }
      sidebar={
        <SideBar
          title={SECTIONS_CONTENT.emails.title}
          description={SECTIONS_CONTENT.emails.description}
        />
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Table
        data={data}
        limitPerPage={5}
        showEnumColumn
        columns={[
          {
            key: "emails",
            label: "Email",
            width: "20%",
            render: (emails) => <div>{emails.join("\n")}</div>,
          },
          {
            key: "path",
            label: "File",
            width: "80%",
          },
        ]}
      />
    </Page>
  );
};

export default EmailsSection;
