import React, { useState, useEffect, useRef } from "react";
import { select } from "d3-selection";
import { easeCubicInOut } from "d3-ease";
import { interpolate } from "d3-interpolate";
import "d3-transition";

const d3 = { select, interpolate };

// https://swizec.com/blog/declarative-d3-transitions-react/swizec/8323
const AnimatedArc = ({ generator, index, color, value }) => {
  const [valueBeforeAnimation, setValueBeforeAnimation] = useState(0); // Preserve a change in value BEFORE animation
  const [valueAfterAnaimation, setValueAfterAnaimation] = useState(0); /// Preserve a change in value AFTER animation

  const domElement = useRef();
  useEffect(() => {
    const arcTween = () => {
      const interpolate = d3.interpolate(0, value);
      return (t) => generator(interpolate(t), index);
    };
    if (value !== valueBeforeAnimation) {
      setValueBeforeAnimation(value);

      d3.select(domElement.current)
        .transition()
        .duration(800)
        .ease(easeCubicInOut)
        .attrTween("d", arcTween)
        .on("end", () => setValueAfterAnaimation(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, generator, index]);

  const arc = generator(valueAfterAnaimation, index);
  const fullArc = generator(100, index);
  return (
    <g>
      <path fill={color} d={fullArc} opacity={0.25} />
      <path fill={color} d={arc} ref={domElement} />
    </g>
  );
};

export default AnimatedArc;
