import config from "config";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const getWorkspaces = () => {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl}/integrations/slack/workspaces`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ results, ...meta }) => [results, meta]);
};

export const getWorkspacesInfo = (teams) => {
  const requestOptions = {
    method: "GET",
  };
  const urls = teams.map(
    (team) => `${config.apiUrl}/integrations/slack/info/${team.team_id}/`
  );

  return Promise.all(
    urls.map((url) => fetch(url, requestOptions))
  ).then((responses) => Promise.all(responses.map(handleFetchResponse)));
};
