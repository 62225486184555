import React from "react";

import ResetPasswordConfirm from "components/login/reset-password-confirm.container";
import LoginLayout from "components/login/LoginLayout";

const ResetPasswordConfirmSide = () => {
  return (
    <LoginLayout
      title="Create New Password"
      message="Enter your new password."
      form={<ResetPasswordConfirm />}
    />
  );
};

export default ResetPasswordConfirmSide;
