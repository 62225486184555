import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  header: {
    overflow: "hidden",
    position: "fixed",
    zIndex: 9,
    width: "100%",
    opacity: 1,
    transition: "all 0.5s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 4.51506px 9.03012px rgba(176, 190, 197, 0.24)",
    height: 151,
  },
  hidden: {
    opacity: 0,
    zIndex: 0,
  },
  noPadding: {
    paddingTop: 0,
  },
  upperBar: {
    width: "100%",
    backgroundColor: theme.palette.primary.semidark,
    "& > .container": {
      padding: 7,
      maxWidth: "1440px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: ({ isAuthenticated }) =>
      isAuthenticated ? "center" : "space-between",
    height: "min-content",
    "& > a > svg": {
      width: "212px",
      height: "28px",
    },
  },
  headerContent: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    maxWidth: "1440px",
    padding: ({ isAuthenticated }) => (isAuthenticated ? "18px 25px" : "25px"),
    paddingLeft: "30px",
    margin: "0 auto",
  },
  headerSub: {
    fontFamily: "Aldrich",
    fontWeight: "normal",
    fontSize: "18px",
    color: "#37DCCE",
    display: "flex",
    alignItems: "baseline",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(3),
    // justifyContent: ({ isAuthenticated }) => isAuthenticated ? 'space-between' : 'flex-start',
    "& > span": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    marginTop: 15,
  },
  headerUrl: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "16px",
    color: "white",
  },
  controllers: {
    display: "none",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      "&.demo, &.free": {
        justifyContent: "flex-end",
      },
    },
  },
  headerFilter: {
    marginLeft: theme.spacing(2),
  },
  filterSelect: {
    "&.url": {
      "& > .MuiFormControl-marginNormal": {
        "& > .MuiInputBase-formControl": {
          "& > .MuiSelect-selectMenu": {
            backgroundColor: "#1a3586",
            color: "#6bd4cb",
            fontWeight: "bold",
          },
          "& > svg": {
            color: "#6bd4cb",
          },
          "& > fieldset": {
            paddingLeft: "8px",
            borderStyle: "none",
            borderBottom: "solid 1px #37DCCE",
            borderRadius: 0,
          },
        },
      },
    },
    "& > .MuiFormControl-marginNormal": {
      width: 240,
      "& > .MuiInputBase-formControl": {
        borderRadius: "10%",
        "& > .MuiSelect-selectMenu": {
          display: "flex",
        },
      },
    },
  },
  button: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: "#37DCCE",
    color: theme.palette.blue["young"],
    width: 220,
    height: 43,
    padding: 0,
    fontSize: "0.875rem",
    //  marginRight: theme.spacing(2),
    borderRadius: 6,
    "&:hover": {
      backgroundColor: "#37DCCE",
      color: theme.palette.blue["young"],
    },
    "&:disabled": {
      opacity: 0.6,
    },
    alignSelf: "flex-end",
    marginBottom: 4,
    justifyContent: "inherit",
  },
  download: {
    marginRight: -15, // Hack in order to fix style issue with icon in button
  },
  formControl: {
    maxWidth: "224px",
  },
  label: {
    color: "white",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: 0,
    marginBottom: "9px",
  },
  flag: {
    width: "1.8em",
    height: "1.2em",
    position: "relative",
    top: "-1px",
  },
}));
