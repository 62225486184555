import { CONSTANTS } from "redux/_consents/_spotlight/spotlight.constants";

const initialState = {
  policies: {
    loading: false,
    data: undefined,
  },
  applications: {
    loading: false,
    data: undefined,
  },
  partners: {
    loading: false,
    data: undefined,
  },
};

export const spotlight = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.SPOTLIGHT_REQUEST_START:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          loading: true,
        },
      };
    case CONSTANTS.SPOTLIGHT_REQUEST_END:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          loading: false,
        },
      };
    case CONSTANTS.SPOTLIGHT_SAVE_DATA:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          data,
        },
      };
    default:
      return state;
  }
};
