import { makeStyles } from "@material-ui/core";

export default (color) =>
  makeStyles((theme) => ({
    inputTitle: {
      color: theme.palette.grey[200],
      display: "block",
      fontSize: "12px",
      fontWeight: 700,
      letterSpacing: "1px",
      textTransform: "uppercase",
      marginBottom: "0px",
      fontFamily: "Roboto Condensed",
    },
    inputLabel: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#4f4f4f",
      marginBottom: "0px",
      fontFamily: "Roboto Condensed",
    },
    label: {
      margin: 0,
    },
    [color]: {
      "& .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track": {
        backgroundColor: color,
      },
      "& .MuiSwitch-colorPrimary+.MuiSwitch-track": {
        backgroundColor: "#90929f",
      },
    },
  }));
