import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  detailItem: {
    color: "#000000",
    fontFamily: "Inter",
    fontSize: 16,
  },
  codeBoxPaper: {
    padding: 16,
    backgroundColor: '#282c34',
    color: '#ffffff',
  },
  codeBoxPre: {
    backgroundColor: '#282c34',
    color: '#ffffff',
    padding: '16px',
    borderRadius: '4px',
    position: 'relative',
    overflowX: 'auto',
    lineHeight: '1.5',
  },
  highlightedText: {
    backgroundColor: '#e0e0e059',
  },
  highlightedSpan: {
    backgroundColor: '#ffff00',
    borderRadius: '3px',
    padding: '1px',
    color: '#000',
  },
  lineNumber: {
    marginRight: '10px',
    color: '#6b7280',
  },
}));

export default useStyles;
