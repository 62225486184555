import React from "react";

const Brand = (props) => (
  <svg
    className={props.className || ""}
    width={props.width || "154"}
    height={props.height || "20"}
    viewBox="0 0 154 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Zendata logo</title>
    <path
      d="M13.3365 1.37695L13.1578 1.65002H11.1531L11.1593 1.64062H0V0H13.3365V1.37695Z"
      fill="white"
    />
    <path
      d="M10.8026 4.99001H8.79996L0.228377 18.3633H2.22516L10.8026 4.99001Z"
      fill="white"
    />
    <path
      d="M13.4341 20H0.0585788V18.623L0.228377 18.3633H5.99848V18.3594H13.4341V20Z"
      fill="white"
    />
    <path d="M35.6152 20H23.8213V18.3594H35.6152V20Z" fill="white" />
    <path d="M23.8213 1.64062V0H35.42V1.64062H23.8213Z" fill="white" />
    <path
      d="M61.6943 0H59.8979V16.7676H59.8948L59.9174 20H61.6943V0Z"
      fill="white"
    />
    <path
      d="M51.5 5.08945L59.8964 17L59.9174 20L51.4531 8H51.5V5.08945Z"
      fill="white"
    />
    <path
      d="M47.9479 0.0829844L47.8892 0H46.1221V20H47.9478L47.9479 0.0829844Z"
      fill="white"
    />
    <path
      d="M77.6496 20C83.6637 20 87.0906 16.2402 87.1003 9.95117C87.1003 3.73047 83.7223 0 78.0011 0H71.7917V1.65002H73.6174V1.64062H77.8937C82.746 1.64062 85.3234 4.77539 85.3332 9.95117C85.3332 15.1758 82.6971 18.3594 77.5422 18.3594H73.6174V4.99001H71.7917V20H77.6496Z"
      fill="white"
    />
    <path
      d="M96.5918 20H94.6782L101.952 0H103.904L111.178 20H109.264L107.126 13.9746H107.125L106.246 11.5H106.244L102.996 2.37305H102.86L99.6124 11.5H99.6114L98.7309 13.9746H98.7299L96.5918 20Z"
      fill="white"
    />
    <path d="M117.035 0V1.64062H131.533V0H117.035Z" fill="white" />
    <path d="M123.371 4.99001V20H125.197V4.99001H123.371Z" fill="white" />
    <path
      d="M139.314 20L141.452 13.9746H141.455L142.511 11H142.512L145.582 2.37305H145.718L148.788 11H148.793L150.392 15.5H150.39L151.986 20H153.9L146.626 0H144.674L137.4 20H139.314Z"
      fill="white"
    />
    <path d="M23.8213 10.7609V9.12025H30.6386V10.7609H23.8213Z" fill="white" />
    <path d="M23.8 4.99001H25.55V20H23.8V4.99001Z" fill="white" />
  </svg>
);

export default Brand;
