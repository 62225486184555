import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "50%",
    maxHeight: "325px",
  },
  dataContainer: {
    display: "flex",
    width: "100%",
    height: "calc(100% - 18px)",
    flexDirection: "row",
    padding: `${theme.spacing(3)}px 0px`,
  },
}));
