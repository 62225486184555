import React from "react";

const arrowUpIcon = () => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.225641 7.14831L6.9581 0.415177C7.25785 0.115718 7.74333 0.115718 8.04274 0.415177L14.7759 7.14831C15.0753 7.44787 15.0753 7.93331 14.7759 8.23286L14.2336 8.77516C13.9342 9.07491 13.4483 9.07491 13.1487 8.77516L7.50049 3.12667L1.8521 8.77516C1.55274 9.07491 1.06706 9.07491 0.767552 8.77516L0.225251 8.23286C-0.0745009 7.93296 -0.0745009 7.44787 0.225641 7.14831Z"
      fill="#3F51B5"
    />
  </svg>
);

export default arrowUpIcon;
