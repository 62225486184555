import TextInput from "components/Consents/Collector/Configuration/components/TextInput";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateCompany as updateCompanyAction } from "redux/_account/account.async.actions";
import useStyles from "./styles";

const required = (value) => (!value ? "This field is required" : undefined);

const Configuration = ({ account, updateCompany }) => {
  const classes = useStyles();
  const company = account.company || {};
  const query = useQuery();
  const [reportId, setReportId] = useState();

  useEffect(() => {
    const r = query.get("r");
    setReportId(r);
  }, [query]);

  return (
    <div className={classes.container}>
      <p className={classes.brief}>
        Complete the following fields to generate the Cookie Policy.
      </p>
      <p className={classes.notice}>All fields are required.</p>
      <Form
        onSubmit={(values) => {
          updateCompany({ id: company.id, ...values });
        }}
        initialValues={{
          name: company.name,
          address: company.address,
          email: company.email || account.email,
        }}
        render={({ handleSubmit }) => (
          <form className={classes.form}>
            <Field
              name="name"
              component={TextInput}
              label="Company Name"
              onSubmit={handleSubmit}
              validate={required}
            />
            <Field
              name="address"
              component={TextInput}
              label="Company Address"
              onSubmit={handleSubmit}
              validate={required}
            />
            <Field
              name="email"
              component={TextInput}
              label="Contact Email"
              onSubmit={handleSubmit}
              validate={required}
            />
          </form>
        )}
      />
      {reportId && (
        <Link
          to={`/privacy-report?r=${reportId}&c=web`}
          className={classes.btn}
        >
          Go Back To Report
        </Link>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateCompany: (values) => dispatch(updateCompanyAction(values)),
});

const mapStateToProps = ({ account }) => ({ account: account.data });

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
