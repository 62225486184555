import { Route, Switch, useLocation } from "react-router-dom";
import PageWrapper from "components/PageWrapper";
import PIICodeSnippetView from "./PIICodeSnippetView";

const PIICodeSnippet = () => {
  const location = useLocation();
  const { file_path, pii_usage_output, context } = location.state || {};
  
  return (
    <PageWrapper subtitle={"PII code snippet"}>
      <Switch>
        <Route path={"/zen/pii-code-snippet/"}>
          {file_path && pii_usage_output && context ? (
            <PIICodeSnippetView
              file_path={file_path}
              pii_usage_output={pii_usage_output}
              context={context}
            />
          ) : <></>}
        </Route>
      </Switch>
    </PageWrapper>
  );
};

export default PIICodeSnippet;
