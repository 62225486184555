import clsx from "clsx";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";

import useStyles from "./styles.js";

const VARIANT = { clear: "clear", dark: "dark" };

const InfoIconButton = ({
  variant = VARIANT.dark,
  targetBlank = false,
  title = "Click to learn more",
  href,
  onClick,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const commonProps = {
    className: clsx(
      classes.infoButton,
      variant === VARIANT.clear && classes.infoButtonClear,
      variant === VARIANT.dark && classes.infoButtonDark,
      className
    ),
    title,
    ...rest,
  };
  const anchorProps = {
    href,
    target: targetBlank ? "_blank" : "_self",
  };
  const buttonProps = { onClick };
  return href ? (
    <a {...commonProps} {...anchorProps}>
      <InfoIcon />
    </a>
  ) : (
    <button type="button" {...commonProps} {...buttonProps}>
      <InfoIcon />
    </button>
  );
};

export default InfoIconButton;
