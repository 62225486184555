import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  tableWrapper: {
    height: 220,
    overflow: "auto",
    width: "100%",
    borderRadius: "4px",
    display: "inline-block",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    paddingTop: 133,
  },
  footer: {
    fontWeight: "bold",
    bottom: 0,
    left: 0,
    zIndex: 1,
    position: "sticky",
    color: "#7090d9",
    backgroundColor: "#F2F5FC",
    letterSpacing: "1px",
    border: 0,
  },
  errorCell: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "28px",
  },
  errorContainer: {
    //width: '100%',
    width: "auto",
    backgroundColor: "rgba(235, 87, 87, 0.05)",
    border: "solid 1px rgba(235, 87, 87, 0.5)",
    borderRadius: "4px",
    //maxWidth: '250px',
    display: "flex",
    alignItems: "center",
    padding: "4px 16px",
  },
  errorText: {
    fontFamily: "Roboto Condensed",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#dd6666",
    letterSpacing: "0",
    textTransform: "none",
    "&:first-child": {
      color: "#4766b6",
    },
  },
  tableHead: {
    "& .MuiTableCell-root": {
      border: 0,
      color: "#4f4f4f",
      fontSize: "18px",
      fontVariant: "all-small-caps",
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      color: "#686c78",
      fontSize: "14px",
      fontVariantNumeric: "lining-nums",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
    },
  },
  tableCell: {
    color: "#4766B6",
    fontSize: "14px",
    fontVariantNumeric: "lining-nums",
    letterSpacing: "0.5px",
    "&:first-child": {
      paddingLeft: 16,
    },
    "&:last-child": {
      paddingRight: 16,
    },
  },
  reportIcon: {
    opacity: "0.7",
    transitio: "all ease-in-out 0.15s",
    "&:hover": {
      opacity: "1",
    },
  },
}));
