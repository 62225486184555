import CollectorItem from "components/Consents/Collector/components/Item";
import CollectorWrapper from "components/Consents/Collector/components/Wrapper";
import PolicyForm from "components/Consents/Collector/Policies/components/policyForm";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getConsentsLanguages } from "redux/_consents/_config/config.selectors";
import {
  createPolicy,
  getPoliciesData,
} from "redux/_consents/_policies/policies.async.actions";
import {
  getConsentsPoliciesData,
  getPoliciesLoadingState,
} from "redux/_consents/_policies/policies.selectors";
// import POLICIES from "pages/Consents/Collector";

const POLICIES = [
  "Strictly Necessary",
  "Functionality",
  "Tracking & Performance",
  "Targeting & Advertising",
];

const creationQueue = {};

const Policies = (props) => {
  const { createPolicy, getData, loading, policies, widgetToken } = props;

  useEffect(() => {
    getData(true, widgetToken);
  }, [getData, widgetToken]);

  useEffect(() => {
    if (loading === true) return;
    if (policies.length === 0) {
      POLICIES.forEach((name) => {
        if (creationQueue[name]) return;
        creationQueue[name] = true;
        createPolicy({
          name,
          mandatory: name === "Strictly Necessary",
          widget_token: widgetToken,
        });
      });
    }
  }, [policies, loading, createPolicy, widgetToken]);

  const [creating, setCreating] = useState(false);

  return (
    <CollectorWrapper
      primaryText="A Policy is an agreement between you and your users as to how your organization uses their data. These can be used for anything from Cookies to (at a later date) fleshing out a modular privacy policy and feature-walling until you have consent to use data in a specific way."
      secondaryText="You can create as many Policies as you want, by default we present you with a list of the 4 most used one to help you get started."
      loading={loading}
      buttonDisabled={creating}
      previewPage="policies"
      previewData={policies}
      onButtonClick={() => setCreating(true)}
    >
      {creating && <PolicyForm data={{}} onCancel={() => setCreating(false)} />}
      {policies.map((data) => (
        <CollectorItem
          key={data.id}
          title={data.name}
          formComponent={<PolicyForm id={data.id} data={data} />}
        />
      ))}
    </CollectorWrapper>
  );
};

const mapStateToProps = (state) => ({
  policies: getConsentsPoliciesData(state),
  languages: getConsentsLanguages(state),
  loading: getPoliciesLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  createPolicy: (data) => dispatch(createPolicy(data)),
  getData: (force, widgetToken) =>
    dispatch(getPoliciesData(force, widgetToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
