import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
    position: "sticky",
    top: "0",
    zIndex: "1001",
    background: "white",
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "190px",
  },
  inputLabel: {
    color: theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    lineHeight: "24px",
    marginBottom: "0px",
    width: "100px",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    //maxWidth: 1120,
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: theme.spacing(3),
    height: 72,
  },
  headerLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerRight: {
    marginLeft: theme.spacing(2),
  },
  headerTitle: {
    color: theme.palette.primary.main,
    fontSize: 24,
    paddingLeft: theme.spacing(8),
    margin: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
    },
  },
  subtitle: {
    fontSize: "0.875rem",
  },
  error: {
    color: theme.palette.red[500],
  },
}));
