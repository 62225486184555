import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Loader from "components/Loader";

// const logs = [
//   'The scan began 09/25/2019 15:43 and lasted for 2hs.',
//   'Found 5 Databases, 2984 Tables, 17543 Columns and 8735 PII Columns.',
// ]

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px 0px",
    height: "calc(100% - 50px)",
  },
  log: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.palette.grey[200],
    paddingBottom: "8px",
  },
}));

const Logs = ({ data, loading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {loading ? (
        <Loader />
      ) : (
        data.map((log) => (
          <Typography className={classes.log}>{`- ${log}`}</Typography>
        ))
      )}
    </Box>
  );
};

export default Logs;
