import React from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import SVGIcon from "components/svg-icon/svg-icon";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: 190,
  },
  inputBase: {
    height: 36,
    padding: 0,
  },
  select: {
    height: 36,
    padding: theme.spacing(0, 2),
    fontFamily: "Inter, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.4,
    letterSpacing: "normal",
    color: theme.palette.blue["500"],
    borderRadius: "6px!important",
    position: "relative",
    left: theme.spacing(-5),
    paddingLeft: theme.spacing(5),
  },
  notchedOutline: {
    border: "none",
  },
  selectIcon: {
    right: theme.spacing(6),
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();
  return (
    <TextField
      value={2}
      select
      variant="outlined"
      className={classes.textfield}
      InputProps={{
        classes: {
          root: classes.inputBase,
          notchedOutline: classes.notchedOutline,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SVGIcon icon="menuDots" />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        classes: {
          root: classes.select,
          icon: classes.selectIcon,
        },
        IconComponent: (props) => <SVGIcon icon="chevronDownIcon" {...props} />,
      }}
      {...props}
    >
      <MenuItem value={2}>View 2 rows</MenuItem>
      <MenuItem value={3}>View 3 rows</MenuItem>
      <MenuItem value={4}>View 4 rows</MenuItem>
    </TextField>
  );
};

export default CustomSelect;
