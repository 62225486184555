import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PageWrapper from "components/PageWrapper";
import { selectEnv } from "redux/_environments/environment.actions";
import { getLoading, getError } from "redux/_charts/charts.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { getFilters } from "redux/_filters/filters.selectors";
import { getTreeByCountry } from "redux/_charts/charts.async.actions";
import { getTreeByCountry as selectorTreeByCountry } from "redux/_charts/charts.selectors";
import CONSTANTS from "redux/_filters/filters.constants";
import GeoDistributionChart from "charts/TreeMap/GeoDistribution";
import { Box, makeStyles } from "@material-ui/core";
import isEmpty from "lodash.isempty";

import { data as mockedData } from "./mocked.json";

const { TYPES } = CONSTANTS;

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    padding: "1rem 0 64px",
    height: "100%",
  },
}));

const PiiCountries = ({
  match,
  filters,
  loading,
  selectedEnv,
  selectEnvironment,
  getPiiCountriesData,
  piiCountriesData,
  error,
}) => {
  useEffect(() => {
    const { env_id: envId } = match.params;
    if (selectedEnv !== envId) {
      selectEnvironment(envId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPiiCountriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const data = useMemo(() => {
    if (!piiCountriesData) return;
    return piiCountriesData.map((d) => ({
      ...d,
      value: (d.columns_pii / d.columns) * 100,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [piiCountriesData]);

  const classes = useStyles();
  return (
    <PageWrapper
      error={error}
      retryFn={getPiiCountriesData}
      showKpis
      title="PII REPORTS"
      subtitle="GEO DISTRIBUTION"
      filters={[
        TYPES.ENV,
        TYPES.ENTITY_ROLE,
        TYPES.STORAGE,
        TYPES.BUSINESS_UNIT,
        TYPES.PURPOSE,
        TYPES.COUNTRY,
        TYPES.SCAN,
        TYPES.PII_CATEGORIES,
      ]}
      loading={loading}
      empty={isEmpty(data)}
    >
      <Box className={classes.chartContainer}>
        <GeoDistributionChart
          relative
          tooltip
          data={!isEmpty(data) ? data : mockedData}
          colors={["#dae9ff", "#a2c1ff", "#4767b8", "#12286b"]}
        />
      </Box>
    </PageWrapper>
  );
};
const mapStateToProps = (state) => ({
  piiCountriesData: selectorTreeByCountry(state),
  selectedEnv: getSelectedEnvId(state),
  loading: getLoading(state),
  filters: getFilters(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectEnvironment: (envId) => dispatch(selectEnv(envId)),
  getPiiCountriesData: () => dispatch(getTreeByCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PiiCountries);
