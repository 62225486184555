import clsx from "clsx";
import React from "react";

import MuiButton from "@material-ui/core/Button";
import useStyles from "./styles";

const VARIANT = {
  main: "main",
  secondary: "secondary",
};

const SIZE = {
  s: "small",
  m: "medium",
};

const Button = ({
  children,
  className = "",
  variant = VARIANT.main,
  size = SIZE.m,
  ...rest
}) => {
  const classes = useStyles();
  const props = {
    classes: {
      root: clsx(
        classes.root,
        variant === VARIANT.main && classes.main,
        variant === VARIANT.secondary && classes.secondary,
        size === SIZE.s && classes.s,
        size === SIZE.m && classes.m,
        className
      ),
      label: classes.label,
      disabled: classes.disabled,
    },
    ...rest,
  };
  return <MuiButton {...props}>{children}</MuiButton>;
};

export default Button;
