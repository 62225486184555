import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import LowChart from "assets/pii-icons/signal_cellular_alt_1_bar.png";
import SeverityChart from "assets/pii-icons/signal_cellular_alt_2_bar.png";
import LeakChart from "assets/pii-icons/signal_cellular_alt.svg";
import {capitalizeFirstLetter} from 'utils/texts'

const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-colCellCenter': {
      justifyContent: 'left',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellTitle': {
      display: 'flex',
      justifyContent: 'left',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
    },
    '&[role="grid"]': {
      border: "none",
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  header: {
    justifyContent: 'left',
    textAlign: 'left',
  },
  cell: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
  },
});

const SeverityCell = ({ value }) => {
  const badgeStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 6,
    width: "fit-content",
    borderRadius: 16,
    alignItems: "center",
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
  };

  const getBadgeProps = (severity) => {
    switch (severity) {
      case 'Low':
        return { background: "#a4bef15c", imageSrc: LowChart, text: "Low Severity" };
      case 'Middle':
        return { background: "#f1e7e0", imageSrc: SeverityChart, text: "Medium Severity" };
      case 'High':
      case 'Leakage':
        return { background: "#f6eaea", imageSrc: LeakChart, text: "Leakage" };
      default:
        return { text: severity };
    }
  };

  const { background, imageSrc, text } = getBadgeProps(value);
  return (
    <BadgeBox style={{ ...badgeStyle, background }} imageSrc={imageSrc} text={text} />
  );
};

const BadgeBox = ({ style, imageSrc, text }) => (
  <Box style={{ ...style, background: style.background }}>
    {imageSrc && <img id="image1" width="16" height="16" alt="" src={imageSrc} />}
    <Typography style={{ color: "#622424", fontSize: 13 }}>
      {text}
    </Typography>
  </Box>
);

const navigateToCodeSnippet = (history, detailsData, selectedIndex) => {
  const selectedDetail = detailsData[selectedIndex];
  if (selectedDetail) {
    history.push({
      pathname: '/zen/pii-code-snippet/',
      state: {
        file_path: selectedDetail.path,
        pii_usage_output: {
          pii: selectedDetail.pii,
          classifciation_1: selectedDetail.classification1,
          classifciation_2: selectedDetail.classification2,
          reasoning: selectedDetail.reasoning
        },
        context: {
          code_snippet: selectedDetail.context,
          start_position: selectedDetail.startPosition,
          end_position: selectedDetail.endPosition,
          contextIndex: selectedDetail.contextIndex,
          lineNumber: selectedDetail.lineNumber
        }
      }
    });
  }
};

const PIISharingDetailsList = ({ onPageChange, pagination }) => {
  const detailsData = useSelector((state) => state.sharingDetails.details)?.detailsInfo || [];
  const classes = useStyles();
  const history = useHistory();
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById('dataGridContainer');
      setContainerWidth(container ? container.clientWidth : 0);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoToCode = useCallback((params) => {
    params && navigateToCodeSnippet(history, detailsData, params.rowIndex);
  }, [detailsData, history]);

  const numberOfColumns = 5;
  const columnWidth = containerWidth / numberOfColumns;

  const drawHeader = (text) => {
    return <Typography style={{ fontSize: 16, fontFamily: "Inter", fontWeight: 900, color: "#466DE8", alignSelf: "center", textTransform: "uppercase" }}>{text}</Typography>;
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'pii',
      headerName: 'Name',
      width: columnWidth,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => drawHeader("Name"),
      renderCell: (params) => (
        <Typography variant="body2" className={classes.cell} style={{ fontWeight: 'bold', fontSize: 14 }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'severity',
      headerName: 'Severity',
      width: columnWidth,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => drawHeader("Severity"),
      renderCell: (params) => <SeverityCell value={params.value} />,
    },
    {
      field: 'classification1',
      headerName: 'Type',
      width: columnWidth,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => drawHeader("Type"),
      renderCell: (params) => (
        <Typography variant="body2" className={classes.cell} style={{ fontWeight: 'bold', fontSize: 14 }}>
          {capitalizeFirstLetter(params.value)}
        </Typography>
      ),
    },
    {
      field: 'classification2Label',
      headerName: 'Category',
      width: columnWidth,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => drawHeader("Category"),
      renderCell: (params) => (
        <Typography variant="body2" className={classes.cell} style={{ fontWeight: 'bold', fontSize: 14 }}>
          {capitalizeFirstLetter(params.value)}
        </Typography>
      ),
    },
    {
      field: 'goToCode',
      headerName: 'Code Snippet',
      width: columnWidth,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => drawHeader("Code Snippet"),
      renderCell: (params) => (
        <Button
          style={{
            fontSize: 12,
            fontFamily: "Inter",
            fontWeight: 700,
            color: "#466DE8",
          }}
          onClick={() => handleGoToCode(params)}
        >
          GO TO CODE
        </Button>
      ),
    },
  ];

  return (
    <Box id="dataGridContainer" style={{ height: 400, width: '100%' }}>
      <DataGrid
        autoHeight
        className={classes.root}
        rows={detailsData}
        columns={columns}
        pageSize={pagination.pageSize > 0 ? pagination.pageSize - 1 : pagination.pageSize}
        pagination
        rowCount={pagination.totalRows}
        page={pagination.page}
        onPageChange={(params) => onPageChange(params.page)}
        paginationMode="server"
        hideFooterPagination={detailsData.length === 0}
      />
    </Box>
  );
};

export default PIISharingDetailsList;
