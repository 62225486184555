const { StyleSheet } = require("@react-pdf/renderer");

const blue = "#1a3586";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 10,
    borderRadius: 6,
    paddingLeft: 40,
    backgroundColor: "#d8e2ff",
    position: "relative",
  },
  title: {
    fontFamily: "Inter",
    color: blue,
    fontWeight: 600,
  },
  label: {
    height: 40,
    width: 60,
    backgroundColor: blue,
    position: "absolute",
    left: "-1cm",
    top: 20,
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 5,
  },
  icon: {
    width: 25,
    height: 25,
    color: "#ffffff",
  },
});

export default styles;
