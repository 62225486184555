import React from "react";

export default () => (
  <svg
    width={32}
    height={30}
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0093 29.4858L21.8926 11.3789H10.126L16.0093 29.4858Z"
      fill="#E24329"
    />
    <path
      d="M16.0095 29.4858L10.1262 11.3789H1.88086L16.0095 29.4858Z"
      fill="#FC6D26"
    />
    <path
      d="M1.88075 11.3789L0.0929098 16.8814C-0.0701608 17.3833 0.10844 17.9331 0.535378 18.2432L16.0094 29.4858L1.88075 11.3789Z"
      fill="#FCA326"
    />
    <path
      d="M1.88086 11.3792H10.1262L6.58265 0.4736C6.4004 -0.0876304 5.60651 -0.0874781 5.42426 0.4736L1.88086 11.3792Z"
      fill="#E24329"
    />
    <path
      d="M16.0098 29.4858L21.8931 11.3789H30.1384L16.0098 29.4858Z"
      fill="#FC6D26"
    />
    <path
      d="M30.1384 11.3789L31.9263 16.8814C32.0893 17.3833 31.9107 17.9331 31.4838 18.2432L16.0098 29.4858L30.1384 11.3789Z"
      fill="#FCA326"
    />
    <path
      d="M30.1379 11.3792H21.8926L25.4361 0.4736C25.6184 -0.0876304 26.4123 -0.0874781 26.5945 0.4736L30.1379 11.3792Z"
      fill="#E24329"
    />
  </svg>
);
