import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { Negative, Positive } from "./LiveProcesses";
import Tooltip from "components/Tooltip";

const useStyles = makeStyles((theme) => ({
  constainer: {
    display: "flex",
    padding: theme.spacing(3, 2, 3, 0),
    color: theme.palette.grey[700],
    borderBottom: "1px solid #E0E1E3",
    wordBreak: "break-word",
  },
  cookieName: {
    width: 1200,
    textAlign: "center",
    "& > .name": {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.grey[900],
    },
    "& > .other-data": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "26px",
      color: theme.palette.grey[800],
      "& strong": {
        fontWeight: 600,
      },
    },
  },
  provider: {
    width: 1200,
    textAlign: "center",
    padding: "0 4px",
  },
  thirdParty: {
    width: 1200,
    textAlign: "center",
  },
}));

const Cookie = ({
  name,
  domain,
  thirdParty,
  purpose,
  duration,
  informationStored,
  subCategory,
  secure,
  canonicals,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.constainer}>
      <div className={classes.cookieName}>
        <Typography className="name">{name}</Typography>
        <Box mt={2} className="other-data"></Box>
      </div>
      <span className={classes.provider}>{domain}</span>
      <span className={classes.provider}>{purpose}</span>
      <span className={classes.provider}>{duration}</span>
      <span className={classes.provider}>{informationStored}</span>
      <span className={classes.provider}>{subCategory}</span>

      <div className={classes.thirdParty}>
        <Tooltip title={thirdParty ? "True" : "False"} placement="top">
          <div style={{ display: "inline-block" }}>
            {thirdParty ? <Positive /> : <Negative />}
          </div>
        </Tooltip>
      </div>

      <div className={classes.thirdParty}>
        <Tooltip title={secure ? "True" : "False"} placement="top">
          <div style={{ display: "inline-block" }}>
            {secure ? <Positive /> : <Negative />}
          </div>
        </Tooltip>
      </div>

      {canonicals && (
        <span className={classes.thirdParty}>
          {canonicals ? canonicals.length : ""}
        </span>
      )}
    </div>
  );
};

export default Cookie;
