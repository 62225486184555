import constants from "./wizard.constants";

export const openWizard = () => ({
  type: constants.OPEN_WIZARD,
});

export const closeWizard = () => ({
  type: constants.CLOSE_WIZARD,
});

export const setEnvData = (data) => ({
  type: constants.SET_ENV_DATA,
  data,
});

export const requestStart = () => ({
  type: constants.REQUEST_START,
});

export const requestEnd = () => ({
  type: constants.REQUEST_END,
});

export const resetStore = () => ({
  type: constants.RESET_STORE,
});
