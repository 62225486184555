import CodeScannerGuide from "components/KeyGenerator/CodeScanner/Guide";
import DBScannerGuide from "components/KeyGenerator/DBScanner/Guide";
import DeviceScannerGuide from "components/KeyGenerator/DeviceScanner/Guide";
import KeyGeneratorForm from "components/KeyGenerator/Form";
import RepositoryScannerGuide from "components/KeyGenerator/RepositoryScanner/ListRepositoryScans/ListRepositoryScans";
import PageWrapper from "components/PageWrapper";
import isNil from "lodash.isnil";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { retrieveKey } from "redux/_keys/keys.service";

const KeyGeneratorPage = ({ history, match, location }) => {
  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    [location.pathname, match.path]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [codeKey, setCodeKey] = useState("");
  const [deviceKey, setDeviceKey] = useState("");
  const [repoKey, setRepoKey] = useState("");
  const [DBKey, setDBKey] = useState("");

  const retrieveKeyFor = (product, setter) => {
    retrieveKey(product)
      .then((response) => {
        if (response.status !== 200) throw new Error("Invalid key");
        return response.blob();
      })
      .then((blob) => {
        const url = global.URL.createObjectURL(blob);
        setter(url);
      })
      .catch((e) => {
        enqueueSnackbar("There was an error retrieving the key.", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (isNil(pathValue)) history.push("/zen/keygen/code-scanner");
    if (pathValue === "code-scanner")
      retrieveKeyFor("code_scanner", setCodeKey);
    else if (pathValue === "device-scanner")
      retrieveKeyFor("device_scanner", setDeviceKey);
    else if (pathValue === "local-db-scanner")
      retrieveKeyFor("database_scanner", setDBKey);
    else retrieveKeyFor("repository_scanner", setRepoKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathValue]);

  let subtitle = "Code Scanner";
  if (pathValue === "repository-scans") subtitle = "Repository Scanner";
  if (pathValue === "device-scanner") subtitle = "Device Scanner";
  if (pathValue === "local-db-scanner") subtitle = "Local DB Scanner";

  return (
    <PageWrapper title="HOME" subtitle={subtitle}>
      <Switch>
        <Route path={"/zen/keygen/code-scanner"}>
          <KeyGeneratorForm
            productKey={codeKey}
            keyName={"zendata-pii-scs-license.lic"}
            instructions={<CodeScannerGuide />}
          />
        </Route>
        <Route path={"/zen/keygen/device-scanner"}>
          <KeyGeneratorForm
            productKey={deviceKey}
            keyName={"zendata-pii-license.lic"}
            instructions={<DeviceScannerGuide />}
          />
        </Route>
        <Route path={"/zen/keygen/repository-scans"}>
          <KeyGeneratorForm
            productKey={repoKey}
            keyName={"zendata-github-scanner-license.lic"}
            instructions={<RepositoryScannerGuide />}
          />
        </Route>
        <Route path={"/zen/keygen/local-db-scanner"}>
          <KeyGeneratorForm
            productKey={DBKey}
            keyName={"zendata-db-scanner-license.lic"}
            instructions={<DBScannerGuide />}
          />
        </Route>
      </Switch>
    </PageWrapper>
  );
};

export default KeyGeneratorPage;
