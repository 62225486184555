import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  chartContainer: {
    height: 500,
  },
  notFound: {
    marginTop: 80,
    textAlign: "center",
  },
  paragraph: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    lineHeight: "29px",
    textIndent: 32,
    color: "#868993",
  },
  title: {
    fontSize: 25,
  },
  caption: {
    paddingLeft: 13,
  },
}));

export default styles;
