import React from "react";

const prefeIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.73439 21C5.5531 21 5.37924 20.928 5.25105 20.7998C5.12286 20.6716 5.05084 20.4977 5.05084 20.3165V14.3924C5.05084 14.2111 5.12286 14.0373 5.25105 13.9091C5.37924 13.7809 5.5531 13.7089 5.73439 13.7089C5.91567 13.7089 6.08954 13.7809 6.21773 13.9091C6.34591 14.0373 6.41793 14.2111 6.41793 14.3924V20.3165C6.41793 20.4977 6.34591 20.6716 6.21773 20.7998C6.08954 20.928 5.91567 21 5.73439 21Z"
      fill="#CFDFF8"
    />
    <path
      d="M5.73439 8.46835C5.5531 8.46835 5.37924 8.39634 5.25105 8.26815C5.12286 8.13996 5.05084 7.9661 5.05084 7.78481V3.68354C5.05084 3.50226 5.12286 3.32839 5.25105 3.20021C5.37924 3.07202 5.5531 3 5.73439 3C5.91567 3 6.08954 3.07202 6.21773 3.20021C6.34591 3.32839 6.41793 3.50226 6.41793 3.68354V7.78481C6.41793 7.9661 6.34591 8.13996 6.21773 8.26815C6.08954 8.39634 5.91567 8.46835 5.73439 8.46835Z"
      fill="#CFDFF8"
    />
    <path
      d="M11.6582 21C11.4769 21 11.3031 20.928 11.1749 20.7998C11.0467 20.6716 10.9747 20.4977 10.9747 20.3164V18.4937C10.9747 18.3124 11.0467 18.1385 11.1749 18.0103C11.3031 17.8821 11.4769 17.8101 11.6582 17.8101C11.8395 17.8101 12.0134 17.8821 12.1416 18.0103C12.2697 18.1385 12.3418 18.3124 12.3418 18.4937V20.3164C12.3418 20.4977 12.2697 20.6716 12.1416 20.7998C12.0134 20.928 11.8395 21 11.6582 21Z"
      fill="#CFDFF8"
    />
    <path
      d="M11.6582 12.5696C11.4769 12.5696 11.3031 12.4976 11.1749 12.3694C11.0467 12.2412 10.9747 12.0674 10.9747 11.8861V3.68354C10.9747 3.50226 11.0467 3.32839 11.1749 3.20021C11.3031 3.07202 11.4769 3 11.6582 3C11.8395 3 12.0134 3.07202 12.1416 3.20021C12.2697 3.32839 12.3418 3.50226 12.3418 3.68354V11.8861C12.3418 12.0674 12.2697 12.2412 12.1416 12.3694C12.0134 12.4976 11.8395 12.5696 11.6582 12.5696Z"
      fill="#CFDFF8"
    />
    <path
      d="M17.582 21C17.4008 21 17.2269 20.928 17.0987 20.7998C16.9705 20.6716 16.8985 20.4978 16.8985 20.3165V12.1139C16.8985 11.9326 16.9705 11.7588 17.0987 11.6306C17.2269 11.5024 17.4008 11.4304 17.582 11.4304C17.7633 11.4304 17.9372 11.5024 18.0654 11.6306C18.1936 11.7588 18.2656 11.9326 18.2656 12.1139V20.3165C18.2656 20.4978 18.1936 20.6716 18.0654 20.7998C17.9372 20.928 17.7633 21 17.582 21Z"
      fill="#CFDFF8"
    />
    <path
      d="M17.582 6.18987C17.4008 6.18987 17.2269 6.11786 17.0987 5.98967C16.9705 5.86148 16.8985 5.68762 16.8985 5.50633V3.68354C16.8985 3.50226 16.9705 3.32839 17.0987 3.20021C17.2269 3.07202 17.4008 3 17.582 3C17.7633 3 17.9372 3.07202 18.0654 3.20021C18.1936 3.32839 18.2656 3.50226 18.2656 3.68354V5.50633C18.2656 5.68762 18.1936 5.86148 18.0654 5.98967C17.9372 6.11786 17.7633 6.18987 17.582 6.18987Z"
      fill="#CFDFF8"
    />
    <path
      d="M17.5826 10.519C17.0419 10.519 16.5132 10.3586 16.0636 10.0582C15.614 9.75776 15.2635 9.33074 15.0566 8.83113C14.8496 8.33153 14.7955 7.78177 14.901 7.2514C15.0065 6.72102 15.2669 6.23383 15.6493 5.85145C16.0317 5.46907 16.5188 5.20867 17.0492 5.10317C17.5796 4.99767 18.1293 5.05181 18.629 5.25876C19.1286 5.4657 19.5556 5.81615 19.856 6.26578C20.1564 6.71541 20.3168 7.24404 20.3168 7.78481C20.3168 8.50996 20.0287 9.2054 19.516 9.71816C19.0032 10.2309 18.3078 10.519 17.5826 10.519ZM17.5826 6.41772C17.3122 6.41772 17.0479 6.4979 16.8231 6.64811C16.5983 6.79833 16.4231 7.01184 16.3196 7.26164C16.2161 7.51145 16.1891 7.78632 16.2418 8.05151C16.2946 8.3167 16.4248 8.56029 16.616 8.75148C16.8071 8.94268 17.0507 9.07288 17.3159 9.12563C17.5811 9.17838 17.856 9.1513 18.1058 9.04783C18.3556 8.94436 18.5691 8.76914 18.7193 8.54432C18.8695 8.3195 18.9497 8.05519 18.9497 7.78481C18.9497 7.42223 18.8057 7.07451 18.5493 6.81813C18.2929 6.56175 17.9452 6.41772 17.5826 6.41772Z"
      fill="#CFDFF8"
    />
    <path
      d="M11.6581 16.8987C11.1173 16.8987 10.5887 16.7384 10.139 16.438C9.68941 16.1375 9.33896 15.7105 9.13202 15.2109C8.92507 14.7113 8.87093 14.1615 8.97643 13.6312C9.08193 13.1008 9.34233 12.6136 9.72471 12.2312C10.1071 11.8488 10.5943 11.5884 11.1247 11.4829C11.655 11.3774 12.2048 11.4316 12.7044 11.6385C13.204 11.8455 13.631 12.1959 13.9315 12.6455C14.2319 13.0952 14.3922 13.6238 14.3922 14.1646C14.3922 14.8897 14.1042 15.5852 13.5914 16.0979C13.0787 16.6107 12.3832 16.8987 11.6581 16.8987ZM11.6581 12.7975C11.3877 12.7975 11.1234 12.8777 10.8986 13.0279C10.6737 13.1781 10.4985 13.3916 10.395 13.6414C10.2916 13.8912 10.2645 14.1661 10.3172 14.4313C10.37 14.6965 10.5002 14.9401 10.6914 15.1312C10.8826 15.3224 11.1262 15.4526 11.3914 15.5054C11.6566 15.5581 11.9314 15.5311 12.1812 15.4276C12.431 15.3241 12.6445 15.1489 12.7948 14.9241C12.945 14.6993 13.0252 14.435 13.0252 14.1646C13.0252 13.802 12.8811 13.4543 12.6247 13.1979C12.3684 12.9415 12.0206 12.7975 11.6581 12.7975Z"
      fill="#CFDFF8"
    />
    <path
      d="M5.73424 12.7975C5.19347 12.7975 4.66484 12.6371 4.21521 12.3367C3.76558 12.0362 3.41513 11.6092 3.20819 11.1096C3.00125 10.61 2.9471 10.0602 3.0526 9.52987C3.1581 8.99949 3.4185 8.51231 3.80088 8.12992C4.18327 7.74754 4.67045 7.48714 5.20083 7.38164C5.73121 7.27614 6.28096 7.33029 6.78056 7.53723C7.28017 7.74417 7.70719 8.09462 8.00762 8.54425C8.30806 8.99389 8.46842 9.52251 8.46842 10.0633C8.46842 10.7884 8.18035 11.4839 7.66759 11.9966C7.15484 12.5094 6.45939 12.7975 5.73424 12.7975ZM5.73424 8.69619C5.46386 8.69619 5.19954 8.77637 4.97473 8.92659C4.74991 9.0768 4.57469 9.29031 4.47121 9.54012C4.36774 9.78992 4.34067 10.0648 4.39342 10.33C4.44617 10.5952 4.57637 10.8388 4.76756 11.03C4.95875 11.2211 5.20234 11.3514 5.46753 11.4041C5.73272 11.4568 6.0076 11.4298 6.2574 11.3263C6.5072 11.2228 6.72071 11.0476 6.87093 10.8228C7.02115 10.598 7.10133 10.3337 7.10133 10.0633C7.10133 9.70071 6.9573 9.35298 6.70092 9.0966C6.44454 8.84022 6.09681 8.69619 5.73424 8.69619Z"
      fill="#CFDFF8"
    />
  </svg>
);

export default prefeIcon;
