const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    boxShadow: "0px 4px 4px 1px rgba(14, 14, 14, 0.24)",
    marginTop: "26px",
  },
  titleRoot: {
    paddingBottom: 0,
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

export default useStyles;
