import React, { useState } from "react";
import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import useStyles from "./styles";
import TextInput from "components/Inputs/Text";
import SvgIcon from "components/svg-icon/svg-icon";
import { useSnackbar } from "notistack";

const ChangePassword = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { open, onClose = () => {}, onConfirm = () => {} } = props;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeated, setRepeated] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const reset = () => {
    setOldPassword("");
    setNewPassword("");
    setRepeated("");
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} className={classes.dialog} fullWidth maxWidth="lg">
      <Box className={classes.sectionContainer}>
        <Box className={classes.leftContainer}>
          <Typography className={classes.title}>Change Password</Typography>
          <Typography className={classes.subtitle}>
            Don’t tell anyone!
          </Typography>
        </Box>
        <Box className={classes.rightContainer}>
          <Box>
            <TextInput
              type="password"
              label="Old Password"
              style={{
                maxWidth: "208px",
                paddingBottom: "16px",
                marginRight: 16,
              }}
              value={oldPassword}
              onChange={setOldPassword}
              disabled={loading}
            />
          </Box>
          <Box display="flex">
            <TextInput
              label="New Password"
              type="password"
              style={{
                maxWidth: "208px",
                paddingBottom: "16px",
                marginRight: 16,
              }}
              helperText="Only 1-9,a-z,!@#$ allowed."
              value={newPassword}
              onChange={setNewPassword}
              disabled={loading}
            />
            <TextInput
              type="password"
              label="Repeat new password"
              style={{ maxWidth: "208px", paddingBottom: "16px" }}
              helperText="Only 1-9,a-z,!@#$ allowed."
              value={repeated}
              onChange={setRepeated}
              disabled={loading}
            />
          </Box>
        </Box>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <SvgIcon icon="close" />
        </IconButton>
      </Box>
      <Button
        disabled={loading || !newPassword}
        className={classes.confirmButton}
        color="secondary"
        onClick={(e) => {
          e.preventDefault();
          if (newPassword && newPassword === repeated) {
            setLoading(true);
            onConfirm({ oldPassword, newPassword })
              .then(() => {
                setLoading(false);
                reset();
              })
              .catch(() => {
                setLoading(false);
                reset();
              });
          } else {
            enqueueSnackbar("Passwords don't match", { variant: "error" });
          }
        }}
      >
        Save
      </Button>
    </Dialog>
  );
};

export default ChangePassword;
