import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import LineChart from "./Linechart";
import Loader from "components/Loader";
import isEmpty from "lodash.isempty";
import SVGContainer from "charts/SVGContainer/SVGContainer";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "calc(100% - 10px)",
    height: "100%",
  },
}));

const ConsentsLineChart = (props) => {
  const classes = useStyles();

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {isEmpty(props.data) || props.loading ? (
        <Loader />
      ) : (
        <Box style={{ width: "100%", height: "100%", display: "flex" }}>
          <Box className={classes.chartContainer}>
            <SVGContainer
              disableScrolling
              geometricPresicion
              transition={false}
            >
              <LineChart
                data={props.data}
                colors={props.colors}
                labels={props.labels}
                compact={props.compact}
              />
            </SVGContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConsentsLineChart;
