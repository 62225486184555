export default [
  ["#E25C1D", "#EC7936", "#F69451", "#FFAD6D"], // Naranja
  ["#2F80ED", "#3D9DF6", "#60B8FC", "#8AD2FF"], // Celeste
  ["#470A71", "#6C2D99", "#934EC2", "#BB70ED"], // Violeta
  ["#219653", "#62B37B", "#95CFA4", "#C6ECCF"], // Verde
  ["#973748", "#BB6B75", "#DE9FA6", "#E9C1C5"], // Rosa
  ["#E8164E", "#F45F71", "#FC8E96", "#FFB9BC"], // Rojo
  ["#b3b300", "#bdbd0a", "#c7c714", "#d1d11e"], // Yellow
  ["#E95D0D", "#F38142", "#FBA170", "#FFC19E"],
  ["#E25C1D", "#EC7936", "#F69451", "#FFAD6D"],
  ["#3834A7", "#7A63C4", "#B097E1", "#E2CEFF"],
];
