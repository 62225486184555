import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  snackbar: {
    "& .MuiSnackbarContent-root": {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    "& .MuiSnackbarContent-message": {
      padding: 0,
      alignSelf: "flex-start",
    },
    "& .MuiSnackbarContent-action": {
      marginLeft: "auto",
    },
    "& svg": {
      alignSelf: "flex-start",
      marginTop: 4,
    },
  },
  error: {
    background: "#F5D6D7",
    border: "1px solid #E49099",
    borderRadius: "6px",
    zIndex: 9999,
    color: "#A92531",
    fontSize: "16px",
    lineHeight: "24px",
    maxWidth: "360px",
    "& .close-button": {
      color: "#A92531",
    },
  },
  success: {
    backgroundColor: "#d6ecdb",
    border: "solid 1px #bacfbf",
    borderRadius: "6px",
    zIndex: 9999,
    color: "#1c7630",
    fontSize: "16px",
    lineHeight: "24px",
    maxWidth: "360px",
    "& .close-button": {
      color: "#1c7630",
    },
  },
  closeButton: {
    fontFamily: "Roboto Condensed",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "12px",
    textDecorationLine: "underline",
    textTransform: "none",
    padding: 0,
    "&:hover": {
      textDecorationLine: "underline",
      background: "transparent",
    },
  },
}));
