import React, { useState } from "react";
import axios from "axios";

const useGetZenDataPiiMaskedText = (apiKey) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const getPII = async (texts) => {
    const url = "https://apis.zendata.xyz/pii";
    const requestData = { texts, includeMaskedText: true };

    setLoading(true);
    try {
      const response = await axios.post(url, requestData, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      });
      setData(response.data[0]?.masked_text_type);
    } catch (err) {
      setError(err);
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, getPII };
};

export default useGetZenDataPiiMaskedText;
