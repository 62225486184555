import { createSelector } from "reselect";

export const getAccountLoadingState = (state) => state.account.loading;

export const getAccountData = (state) => state.account.data;

export const getAccountName = createSelector(
  [getAccountData],
  (data) => data.name || ""
);
