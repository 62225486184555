export const getSlackLoadingState = (state) => state.slack.loading;
export const getSlackWorkspaceData = (state) => state.slack.workspace.data;
export const getSlackWorkspaceErrorState = (state) =>
  state.slack.workspace.error;
export const getSlackWorkspacesData = (state) => state.slack.workspaces.data;
export const getSlackWorkspacesErrorState = (state) =>
  state.slack.workspaces.error;
export const getSlackWorkspacesMeta = (state) => state.slack.reports.meta;
export const getSlackWorkspacesInfoData = (state) =>
  state.slack.workspacesInfo.data;
