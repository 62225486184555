import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routeForKey } from "router";

import { authenticationActinon } from "redux/_authentication/authentication.actions";

import { getAccountData } from "redux/_account/account.selectors";
import { getData } from "redux/_account/account.async.actions";

const AuthenticationControl = ({
  account,
  isAuthenticated,
  getProfileData,
  setAttemptedTransition,
  children,
}) => {
  const [uri, setUri] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (account && Object.keys(account).length > 0) {
      setUri("zenArea");
    }
  }, [account]);

  if (uri) return <Redirect to={routeForKey(uri)} />;

  return children;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProfileData: () => dispatch(getData()),
  setAttemptedTransition: (url) =>
    dispatch(authenticationActinon.setAttemptedTransition(url)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationControl);
