import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "50%",
    maxHeight: "325px",
  },
  subtitle: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#828282",
  },
  dataContainer: {
    display: "flex",
    width: "100%",
    height: "calc(100% - 18px)",
    flexDirection: "row",
    padding: `${theme.spacing(3)}px 0px`,
  },
  refsContainer: {
    width: "10%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  refsDataContainer: {
    width: "100%",
    height: "50%",
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  refContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  refText: {
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "22px",
    width: "calc(100% - 26px)",
    color: theme.palette.grey[200],
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    margin: theme.spacing(),
  },
}));
