import React, { useState } from "react";

import ResetPassword from "components/login/reset-password.container";
import LoginLayout from "components/login/LoginLayout";

const ResetPasswordSide = () => {
  const [sent, setSent] = useState(false);

  return (
    <LoginLayout
      title="Reset password"
      message={
        !sent
          ? "Please enter an email in order to reset your password."
          : "If you have an account, an email with instructions was sent to you in order to reset your password."
      }
      form={!sent && <ResetPassword setSent={setSent} />}
    />
  );
};

export default ResetPasswordSide;
