export const SECTION_ID = {
  landing: "landing",
  dataSource: "dataSource",
};

export const SECTION_TITLE = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.dataSource]: "DataSource",
};

export const SECTION_NAME = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.dataSource]: "DataSource",
};

export const REPORT_VERSION = {
  app: "app",
  apk: "apk",
  Swift: "Swift",
};

export const ORDERED_ANCHORS = [SECTION_NAME.landing, SECTION_NAME.dataSource];

export const SECTIONS_CONTENT = {
  [SECTION_ID.landing]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.landing,
    title: "",
    description: "",
  },
  [SECTION_ID.dataSource]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.dataSource,
    title: "Pii Violations",
    description: "",
  },
};
