/* eslint-disable react-hooks/exhaustive-deps */
import { Field } from "react-final-form";
import { Typography, TextField, CircularProgress } from "@material-ui/core";
import useStyles from "./styles.js";
import useStylesForms from "../../../components/Scans/Forms/styles.js";
import { useEffect, useRef, useState } from "react";

export const SearchDropdown = ({
  search,
  searchList,
  onChangeSearch,
  onClickDropdownItem,
  searchLoading,
  initialValue = "",
  placeholder,
  labelText,
}) => {
  const classes = useStyles();
  const formClasses = useStylesForms();
  const [showDropdown, setShowDropdwon] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDropdwon(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleDropdownDisplay = () => {
    setShowDropdwon(!showDropdown);
  };

  return (
    <Field
      name="search"
      initialValue={initialValue}
      render={({
        input: { value, onChange, onBlur },
        meta: { touched, error, submitError },
      }) => (
        <>
          <div className={classes.leftContainer}>
            <Typography id="resource-label" className={formClasses.label}>
              {labelText}
            </Typography>
          </div>
          <div className={classes.rightContainer}>
            <TextField
              className={formClasses.textField}
              onClick={handleDropdownDisplay}
              inputProps={{
                "aria-labelledby": "resource-label",
              }}
              value={search}
              onChange={async (event) => {
                onChangeSearch(event.target.value);
              }}
              variant="filled"
              placeholder={placeholder}
              error={touched && (error || submitError)}
              onBlur={(event) => onBlur(event)}
              helperText={
                touched &&
                (error || submitError) && (
                  <span style={{ color: "red" }}>{error}</span>
                )
              }
              InputProps={{
                type: "search",
                endAdornment: (
                  <>
                    {searchLoading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        className={classes.searchLoading}
                      />
                    ) : null}
                  </>
                ),
              }}
            />
            {showDropdown && (
              <div className={classes.dropdown} ref={ref}>
                {search?.length >= 3 &&
                  !searchLoading &&
                  searchList.length > 0 &&
                  searchList.map((searchedValue, index) => {
                    return (
                      <div
                        className={classes.searchedItem}
                        onClick={() => {
                          onClickDropdownItem(searchedValue);
                          handleDropdownDisplay();
                        }}
                        key={`${searchedValue}-${index}`}
                      >
                        {searchedValue}
                      </div>
                    );
                  })}
                {search?.length < 3 && "Write something for search..."}
                {search?.length >= 3 && searchLoading && "Loading..."}
                {searchList.length === 0 &&
                  search?.length >= 3 &&
                  !searchLoading &&
                  "Not results..."}
              </div>
            )}
          </div>
        </>
      )}
    />
  );
};
