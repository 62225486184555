export const columns = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "name",
    label: "Scan ID",
  },
  {
    id: "databases",
    label: "DBs",
    align: "right",
  },
  {
    id: "tables",
    label: "Tables",
    align: "right",
  },
  {
    id: "columns",
    label: "Cols",
    align: "right",
  },
];
