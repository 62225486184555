import React, { useState } from "react";
import { TextField, Box, Button, makeStyles } from "@material-ui/core";
import isEmpty from "lodash.isempty";

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "8px",
  },
  textArea: {
    padding: "8px 88px 8px 1rem",
  },
  container: {
    position: "relative",
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
    boxShadow: "none",
    borderRadius: "4px 0px 8px 0px",
    padding: "8px 24px",
    backgroundColor: theme.palette.blue["young"],
    color: "white",
    fontWeight: 600,
  },
}));

const CommentInput = ({ loading, onSubmit }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const empty = isEmpty(value);
  return (
    <Box className={classes.container}>
      <TextField
        rowsMax="3"
        rows="3"
        multiline
        variant="outlined"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          classes: { input: classes.textArea, notchedOutline: classes.input },
        }}
        disabled={loading}
      />
      <Button
        onClick={() => {
          onSubmit(value);
          setValue("");
        }}
        disabled={loading || empty}
        variant="contained"
        elevation={0}
        classes={{ root: classes.button }}
      >
        Send
      </Button>
    </Box>
  );
};

export default CommentInput;
