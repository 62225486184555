import { Grid, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import HomepageMenu from "components/HomepageMenu";
import ScannerFlowForm from "components/PrivacyReports/Scanner/emailForm";
import ScannerTypeForm from "components/PrivacyReports/Scanner/form";
import { DEFAULT_COUNTRY } from "components/PrivacyReports/ScannerAdvancedSettings/constants";
import Footer from "components/PublicComponents/Footer";
import PublicNav from "components/PublicComponents/Nav";
import Seo, { pages } from "components/Seo";
import StepperDialog from "components/StepperDialog";
import StepperDialogCongrats from "components/StepperDialogCongrats";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAccountData } from "redux/_account/account.selectors";
import { resetData } from "redux/_reports/reports.actions";
import { postTriggerReport } from "redux/_reports/reports.async.actions";
import { uploadFile } from "../../../utils/aws";
import { SCAN_TYPE } from "./constants";
import useStyles from "./styles";

const parseError = (error) =>
  Array.isArray(error) ? error[0].title : error.title;

const sleep = (seconds) =>
  new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });

export const getStore = (linkOrApp) => {
  if (
    linkOrApp.startsWith("https://play.google.com/") ||
    linkOrApp.includes(".apk")
  ) {
    return "android";
  }

  if (
    linkOrApp.startsWith("https://apps.apple.com/") ||
    linkOrApp.includes(".ipa")
  ) {
    return "ios";
  }
};

export const manageApp = async (scanApp, callback = null) => {
  let uploaded;
  try {
    uploaded = await uploadFile(scanApp, callback);
  } catch (e) {
    uploaded = false;
  }

  return uploaded;
};

// TODO: decouple components that make use of this function
export const sendScan = (triggerReport) => async (data) => {
  try {
    const response = await triggerReport(data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

const PrivacyScanner = ({
  loading,
  triggerReport,
  error,
  reset,
  account,
  getProfileData,
}) => {
  const [open, setOpen] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);

  const [sent, setSent] = useState(false);
  const [scanType, setScanType] = useState();
  const [scanSite, setScanSite] = useState("");
  const [scanApp, setScanApp] = useState();
  const [uploadAppError, setUploadAppError] = useState();
  const [appUploadProgress, setAppUploadProgress] = useState(0);
  const [location, setLocation] = useState(null);

  const query = useQuery();

  const classes = useStyles();
  const openHandler = () => setOpen(true);
  const closeHandler = () => {
    setOpen(false);
    if (sent) {
      global.location.href = "https://www.zendata.dev";
    }
  };

  useEffect(() => {
    const url = query.get("url");
    if (url) {
      setLocation([DEFAULT_COUNTRY]);
      setScanSite(url);
      setScanType(SCAN_TYPE.site);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSiteOrAppToScan = ({ site, app }) => {
    openHandler();
    if (site) {
      setScanSite(site);
      setScanType(SCAN_TYPE.site);
    } else {
      setScanApp(app);
      setScanType(SCAN_TYPE.app);
    }
  };

  const handleAppUploadProgress = (progress) => {
    const progressPercentage = (progress.loaded / progress.total) * 100;
    setAppUploadProgress(progressPercentage);
  };

  const handleSubmit = async (values) => {
    setFakeLoading(true);

    const { locations, device, ...rest } = values;
    // TODO(@jperozo): This could change with the flow related change
    const environment = device || (scanSite !== "" ? "web" : "android");
    let site, app, os, uploaded;

    if (environment === "web") {
      site = !(
        scanSite.startsWith("http://") || scanSite.startsWith("https://")
      )
        ? `https://${scanSite}`
        : scanSite;
    } else {
      // Check if an app or a link to the store
      if (scanSite) {
        site = scanSite;
      } else {
        uploaded = await manageApp(scanApp, handleAppUploadProgress);
      }
      app = uploaded;
      os = getStore(site || app);
    }

    const shouldSetSent = uploaded !== false;
    if (shouldSetSent) {
      sendScan(triggerReport)({
        ...rest,
        locations,
        environment,
        resource: site || app,
        sitemapDepth: 0,
        app,
        os,
      });
    } else {
      setUploadAppError(
        "We got an error uploading the file. Please try later."
      );
    }

    // Could be removed in the future
    if (shouldSetSent) {
      await sleep(parseInt(3 + Math.random() * 5, 10));
    }

    setSent(shouldSetSent);
    setFakeLoading(false);
  };

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />

      <Hidden mdUp>
        <HomepageMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </Hidden>
      <div
        id="page-wrap"
        className="homepage"
        style={{ backgroundColor: "#fff" }}
      >
        <PublicNav />
        <section className={classes.header}>
          <div className="custom-container">
            <div className="elements">
              <Typography component="h2" className={classes.title}>
                Evaluate the Privacy of your website in 3 easy steps
              </Typography>
              <Typography
                component="p"
                className={clsx(classes.description, "dark-background")}
              >
                Understand <strong>trackers, cookies and risks</strong>{" "}
                associated with third-party technologies by region and device
                type.
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <ScannerTypeForm
                    serverError={error}
                    onSubmit={setSiteOrAppToScan}
                    loading={fakeLoading}
                    title="Enter a URL or upload an App to Scan"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </section>

        <StepperDialog open={open} onClose={closeHandler}>
          {!sent ? (
            <ScannerFlowForm
              location={location}
              scanType={scanType}
              appUploadProgress={appUploadProgress}
              scanSite={scanSite}
              serverError={error || uploadAppError}
              onSubmit={handleSubmit}
              loading={fakeLoading}
              email={account && account.email}
              closeHandler={closeHandler}
            />
          ) : (
            <StepperDialogCongrats
              account={account}
              closeHandler={closeHandler}
            />
          )}
        </StepperDialog>

        <Footer backgroundColor="#f5f8fd" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerReport: (values) => dispatch(postTriggerReport(values)),
  reset: () => dispatch(resetData("report")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyScanner);
