import { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import clsx from "clsx";
import useStyles from "../styles";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import {
  getReportsData,
  getReportsErrorState,
  getReportsMeta,
  getReportLoadingState,
} from "redux/_reports/reports.selectors";
import { getReports, deleteReport } from "redux/_reports/reports.async.actions";
import Dialog from "components/Dialog";
import Loader from "components/Loader";
import Status from "pages/AppReport/common/Status";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  getFileScansList,
  removeDataSourceReport,
} from "redux/_datasources/datasources.service";
import { Delete } from "@material-ui/icons";

const ListRepositoryScans = ({
  loading,
  removeReport,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();
  const [fileList, setFileList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [scanToRemove, setScanToRemove] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  const fetchScansList = async (pageNumber = 1, pageSize = 10) => {
    try {
      const { data, meta } = await getFileScansList({ page: pageNumber, pageSize });
      setFileList(data);
      setTotalRows(meta.totalRows || 0);
    } catch (error) {
      console.error("fetchScansList error: ", error);
    }
  };

  useEffect(() => {
    fetchScansList(page, pageSize);
  }, [page, pageSize]);

  const handleDisplayModal = () => setDisplayDeleteModal(!displayDeleteModal);

  const removeRepositoryScan = async () => {
    handleDisplayModal();
    try {
      await removeDataSourceReport(scanToRemove);
    } catch (error) {
      console.error("removeRepositoryScan error: ", error);
    }
    finally {
      fetchScansList(page, pageSize);
    }
  };

  const handleDeleteSelection = (scan) => {
    setScanToRemove(scan);
    handleDisplayModal();
  };

    // Extract file name from url like this: "https://develop-reports.zendata.xyz/media/data_scans/originals/test_qj6Qor6.txt"
    const extractFileName = (url) => {
      const parts = url.split("/");
      return parts[parts.length - 1];
    };
  
    // Function to get processed file from the list with original_file property
    const getProcessedFile = (original_file) => {
      return fileList.find((file) => file.original_file === original_file)
        ?.processed_file;
    };

  const columns = [
   
    {
      field: "original_file",
      headerName: "File",
      width: 200,
      renderCell: (params) => {
        const status = params.data.status;
        const isLinkDisabled = status === "setting_up" || status === "failed";

        return isLinkDisabled ? (
          <Box className={clsx(classes.dataSourceName, classes.disabled)}>
            <span style={{ fontWeight: 600 }}>
              {extractFileName(params.data.original_file)}
            </span>
          </Box>
        ) : (
          <a
            className={classes.dataSourceName}
            href={`/file-report?r=${params.data?.id}`}
            target="_self"
          >
            <span style={{ fontWeight: 600 }}>
              {extractFileName(params.data.original_file)}
            </span>
          </a>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Box className={clsx(classes.status)}>
        <Status
          status={params.data.status}
          isSelectable
          type="dataSource"
          onClick={() =>
            setSelectedStatus(params.data.status !== selectedStatus ? params.data.status : "")
          }
          selected={params.data.status === selectedStatus}
          summary={params.data.summary}
        />
         </Box>
      ),
    },
    {
      field: "created",
      headerName: "Last Scan",
      width: 150,
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      field: "processed_file",
      headerName: "Regenerated File",
      width: 200,
      renderCell: (params) =>
        params.data.processed_file ? (
          <a
            href={params.data.processed_file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GetAppIcon />
          </a>
        ) : (
          "-"
        ),
    },
    {
      field: "data_types",
      headerName: "Data Types",
      width: 200,
      valueGetter: (params) =>
        params.data.summary?.totalNonSensitiveEntities +
        params.data.summary?.totalSensitiveEntities || "-",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => handleDeleteSelection(params.data.id)}
          startIcon={<Delete />}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>

      {fileList?.length > 0 ? (
        <Box height={650} width="100%">
          <DataGrid
            className={classes.root}
            rows={fileList}
            columns={columns}
            pageSize={pageSize}
            pagination
            rowCount={totalRows}
            paginationMode="server"
            onPageChange={(params) => setPage(params.page)}
            onPageSizeChange={(params) => setPageSize(params.pageSize)}
          />
        </Box>
      ) : (
        <Box py={3} textAlign="center" width="100%">
          <Typography>No reports were found.</Typography>
        </Box>
      )}

      <Dialog
        title="Delete Report"
        handleClose={handleDisplayModal}
        open={displayDeleteModal}
        customClasses={classes}
      >
        <DialogContent>
          Are you sure you want delete this data source? If you remove it, you won{"'"}t be able to check these results again.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisplayModal} color="primary">
            No
          </Button>
          <Button onClick={removeRepositoryScan} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: getReportsData(state),
  error: getReportsErrorState(state),
  loading: getReportLoadingState(state),
  meta: getReportsMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: (filters) => dispatch(getReports(filters)),
  removeReport: (id) => dispatch(deleteReport(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListRepositoryScans);
