import queryString from "query-string";
import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import scansConstants from "redux/_scans/scans.constants";
import moment from "moment";
import {
  requestEnd,
  requestStart,
  saveData,
  setMeta,
} from "redux/_reports/reports.actions";

export const getScansById = (
  { envId, ...queryParams },
  getFullResult = false
) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/environments/${envId}/${scansConstants.SCAN_NAMESPACE}`;

  // TODO add query params once supported by backend
  if (Object.keys(queryParams).length)
    url += `?${queryString.stringify(queryParams)}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then((res) =>
      getFullResult
        ? {
            ...res,
            data: res.data.map(_scanDeserializer),
          }
        : res.data.map(_scanDeserializer)
    );
};

export const getAllEnvScans = ({ envId, ...queryParams }) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/environments/${envId}/${scansConstants.SCAN_NAMESPACE}`;

  if (Object.keys(queryParams).length)
    url += `?${queryString.stringify(queryParams)}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data: scans }) => scans.map(_scanDeserializer));
};

export const getScansStats = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/last_stats`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getLastScansPerEnv = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl}/scans/last_scans`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const runScanByEnvId = (envId) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let url = `${config.apiUrl}/environments/${envId}/scans`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const fetchPiiGroups = (scanId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/scans/${scanId}/pii_groups`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

//TODO Remove mocked values once they are implemented from backend side.

export const _scanDeserializer = (scan) => {
  return {
    ...scan,
    environment_id: !!scan.environment_id
      ? parseFloat(scan.environment_id)
      : "",
    started_at: new Date(scan.started_at),
    ended_at: new Date(scan.ended_at),
    databases: parseInt(scan.databases),
    scanned_databases: parseInt(scan.scanned_databases),
    tables: parseInt(scan.tables),
    scanned_tables: parseInt(scan.scanned_tables),
    columns: parseInt(scan.columns),
    scanned_columns: parseInt(scan.scanned_columns),
    total_rows: parseInt(scan.total_rows),
    total_matches: parseInt(scan.total_matches),
    total_unprotected: parseInt(scan.total_unprotected),
    total_problematic: parseInt(scan.total_problematic),
    pii_groups: scan.pii_groups.map(_scanCategoryDesearealizer),
    name: !scan.ended_at
      ? scan.started_at
        ? moment(scan.started_at).format("YYYYMMDD_HHMM")
        : ""
      : moment(scan.ended_at).format("YYYYMMDD_HHMM"),
    ..._computedValues(scan),
  };
};

const _scanCategoryDesearealizer = (category) => ({
  ...category,
  total_matches: parseInt(category.total_matches),
  total_unprotected: parseInt(category.total_unprotected),
  total_problematic: parseInt(category.total_problematic),
});

const _computedValues = (scan) => ({
  _problematicPercent: (scan.total_problematic / scan.total_rows) * 100,
  _unprotectedPercent: (scan.total_unprotected / scan.total_rows) * 100,
  _piiPercent: (scan.total_matches / scan.total_rows) * 100,
});

export const createSecurityScans = (url) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url }),
  };

  return fetch(`${config.apiUrl}/security-scans/`, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getSecurityScans = ({
  pageSize = 6,
  page = 1,
  status = null,
} = {}) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${
    config.apiUrl
  }/security-scans/?pageSize=${pageSize}&page=${page}${
    status ? `&status=${status}` : ""
  }`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then((data) => data);
};

export const getSecurityData = ({ pageSize, page, status = null }) => (
  dispatch
) => {
  dispatch(requestStart());
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${
    config.apiUrl
  }/security-scans/?pageSize=${pageSize}&page=${page}${
    status ? `&status=${status}` : ""
  }`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data, meta } = {}) => {
      const formattedData = data.map(
        ({ id, url, status, created, document }) => ({
          pk: id,
          resource: url,
          status,
          document,
          createdAt: created,
          deviceType: "security",
        })
      );
      dispatch(requestEnd());
      dispatch(saveData(formattedData, "reports"));
      dispatch(setMeta(meta, "reports"));
      return { data };
    });
};

export const deleteSecurityScans = (uuid) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/security-scans/${uuid}/`, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
