import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";

const RequestActions = ({ selectedRow: { responses } }) => {
  const classes = useStyles();

  // TODO: Uncomment this section when there is a response method implemented
  return null;
  // eslint-disable-next-line no-unreachable
  return (
    <>
      <Box className={classes.messageContainer}>
        <Typography className={classes.itemLabel}>Admin</Typography>
        <Box className={classes.response}>
          {responses.map((response) => (
            <Typography className={classes.messageValue}>{response}</Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default RequestActions;
