import { Text, View } from "@react-pdf/renderer";

const NIAPAnalysisPage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>NIAP Analysis 1.3</Text>
        <Text style={styles.subTitle}>
          The National Information Assurance Partnership (NIAP) is a U.S.
          government initiative that oversees the evaluation of commercial IT
          products for use in national security systems. NIAP operates under the
          Common Criteria Recognition Arrangement (CCRA), which is an
          international agreement for mutual recognition of secure IT products.
        </Text>
      </View>
      <View style={styles.section}>
        {Object.entries(data.niap_analysis).map(([key, value]) => {
          return (
            <View style={styles.dottedBox} key={key} wrap={false}>
              <Text style={styles.classes}>{key}</Text>

              <View>
                <Text style={styles.standOut}>Requirement</Text>
                <Text style={styles.text}>{value.class}</Text>
              </View>
              <View>
                <Text style={styles.standOut}>Feature</Text>
                <Text style={styles.text}>{value.description}</Text>
              </View>
              <View>
                <Text style={styles.standOut}>Description</Text>
                <Text style={styles.text}>{value.choice}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default NIAPAnalysisPage;
