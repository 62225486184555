import React from "react";
import clsx from "clsx";
import isEmpty from "lodash.isempty";
import useStyles from "./styles";
import { Box, Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Toggle = (props) => {
  const {
    value,
    text,
    label = "",
    onChange = () => {},
    color,
    classes: customClasses = {},
    className,
    labelPlacement = "end",
    ...restProps
  } = props;

  const classes = useStyles(color)({ color });
  return (
    <Box className={clsx(className)}>
      {!isEmpty(label) && (
        <h6 className={clsx(classes.inputTitle, customClasses.title)}>
          {label}
        </h6>
      )}
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <Switch
            className={clsx(color ? classes[color] : "", customClasses.switch)}
            checked={value}
            value={value}
            label={text}
            color="primary"
            onChange={(e) => onChange(e.target.checked)}
            {...restProps}
          />
        }
        label={text}
        classes={{
          root: classes.label,
          label: clsx(classes.inputLabel, customClasses.label),
        }}
      />
    </Box>
  );
};

export default Toggle;
