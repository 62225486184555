import React from "react";

const shareIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0238 7.2518H12.0639C11.9139 7.2518 11.77 7.3114 11.6639 7.41749C11.5578 7.52358 11.4982 7.66747 11.4982 7.8175C11.4982 7.96753 11.5578 8.11142 11.6639 8.21751C11.77 8.32359 11.9139 8.38319 12.0639 8.38319H15.4581V16.8686H1.88139V8.38319H5.27557C5.4256 8.38319 5.56949 8.32359 5.67558 8.21751C5.78167 8.11142 5.84127 7.96753 5.84127 7.8175C5.84127 7.66747 5.78167 7.52358 5.67558 7.41749C5.56949 7.3114 5.4256 7.2518 5.27557 7.2518H1.3157C1.16566 7.2518 1.02178 7.3114 0.915689 7.41749C0.8096 7.52358 0.75 7.66747 0.75 7.8175L0.75 17.4343C0.75 17.5844 0.8096 17.7283 0.915689 17.8344C1.02178 17.9404 1.16566 18 1.3157 18H16.0238C16.1738 18 16.3177 17.9404 16.4238 17.8344C16.5299 17.7283 16.5895 17.5844 16.5895 17.4343V7.8175C16.5895 7.66747 16.5299 7.52358 16.4238 7.41749C16.3177 7.3114 16.1738 7.2518 16.0238 7.2518Z"
      fill="#0E1D60"
    />
    <path
      d="M5.67405 4.34409L8.10371 1.91726V10.7336C8.10371 10.8837 8.16331 11.0276 8.2694 11.1336C8.37549 11.2397 8.51938 11.2993 8.66941 11.2993C8.81944 11.2993 8.96333 11.2397 9.06942 11.1336C9.17551 11.0276 9.23511 10.8837 9.23511 10.7336V1.91726L11.6648 4.34692C11.7709 4.45307 11.9149 4.5127 12.065 4.5127C12.2151 4.5127 12.3591 4.45307 12.4652 4.34692C12.5714 4.24077 12.631 4.09681 12.631 3.94669C12.631 3.79658 12.5714 3.65261 12.4652 3.54646L9.07106 0.152284C9.04425 0.125854 9.01488 0.102166 8.98337 0.0815716L8.95226 0.0561152L8.89852 0.0278304L8.82781 0.0108595H8.77972C8.70689 -0.00361985 8.63193 -0.00361985 8.5591 0.0108595H8.51102L8.45445 0.0278304L8.38656 0.0561152L8.34131 0.0815716C8.3098 0.102166 8.28043 0.125854 8.25362 0.152284L4.85945 3.54646C4.7533 3.65261 4.69366 3.79658 4.69366 3.94669C4.69366 4.09681 4.7533 4.24077 4.85945 4.34692C4.96559 4.45307 5.10956 4.5127 5.25968 4.5127C5.40979 4.5127 5.55376 4.45307 5.65991 4.34692L5.67405 4.34409Z"
      fill="#0E1D60"
    />
  </svg>
);

export default shareIcon;
