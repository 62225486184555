import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 0, 0),
    position: "relative",
    zIndex: 0,
    height: "calc(100% - 100px)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 2,
    color: theme.palette.grey[200],
  },
  groupInput: {
    marginLeft: "auto",
    maxWidth: 500,
  },
  backgroundIcon: {
    position: "absolute",
    right: 0,
    bottom: -20,
    zIndex: -1,
  },
}));
