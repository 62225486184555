import { Text, View } from "@react-pdf/renderer";

const FileAppInfoComponentsPage = ({ styles, data, appType }) => {
  return (
    <View break>
      <View style={styles.section} wrap={false}>
        <View>
          <Text style={styles.title}>File Information</Text>
        </View>
        <View style={styles.dottedBox}>
          <Text style={styles.text}>File Name: {data["file_name"]}</Text>
          <Text style={styles.text}>Size: {data.size}</Text>
          <Text style={styles.text}>MD5: {data.md5}</Text>
          <Text style={styles.text}>SHA1: {data.sha1}</Text>
          <Text style={styles.text}>SHA256: {data.sha256}</Text>
        </View>
      </View>
      <View style={styles.section} wrap={false}>
        <View>
          <Text style={styles.title}>App Information</Text>
        </View>
        <View style={styles.dottedBox}>
          <Text style={styles.text}>App Name: {data["app_name"]}</Text>
          {appType !== "apk" && (
            <Text style={styles.text}>App Type: {data["app_type"]}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>Identifier: {data["bundle_id"]}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>SDK Name: {data["sdk_name"]}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>Version: {data.version}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>Build: {data.build}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>Platform Version: {data.platform}</Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>
              Min OS Version: {data["min_os_version"]}
            </Text>
          )}
          {appType !== "apk" && (
            <Text style={styles.text}>
              Supported Platform: {data["bundle_supported_platforms"]}
            </Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>
              Package Name: {data["package_name"]}
            </Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>
              Main Activity: {data["main_activity"]}
            </Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>Target SDK: {data["target_sdk"]}</Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>Min SDK: {data["min_sdk"]}</Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>Max SDK: {data["max_sdk"]}</Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>
              Android Version Name: {data["version_name"]}
            </Text>
          )}
          {appType === "apk" && (
            <Text style={styles.text}>
              Android Version Code: {data["version_code"]}
            </Text>
          )}
        </View>
      </View>

      {appType !== "apk" && (
        <View style={styles.section} wrap={false}>
          <View>
            <Text style={styles.title}>Binary Information</Text>
          </View>
          <View style={styles.dottedBox}>
            <Text style={styles.text}>Arch: {data["binary_info"].arch}</Text>
            <Text style={styles.text}>
              Sub Arch: {data["binary_info"].subarch}
            </Text>
            <Text style={styles.text}>Bit: {data["binary_info"].bit}</Text>
            <Text style={styles.text}>
              Endian: {data["binary_info"].endian}
            </Text>
          </View>
        </View>
      )}
      {appType === "apk" && (
        <View style={styles.section} wrap={false}>
          <View>
            <Text style={styles.title}>App Components</Text>
          </View>
          <View style={styles.dottedBox}>
            <Text style={styles.text}>
              Activities: {data.activities.length}
            </Text>
            <Text style={styles.text}>Services: {data.services.length}</Text>
            <Text style={styles.text}>Receivers: {data.receivers.length}</Text>
            <Text style={styles.text}>Providers: {data.providers.length}</Text>
            <Text style={styles.text}>
              Exported Activities:{" "}
              {data["exported_count"]["exported_activities"]}
            </Text>
            <Text style={styles.text}>
              Exported Services: {data["exported_count"]["exported_services"]}
            </Text>
            <Text style={styles.text}>
              Exported Receivers: {data["exported_count"]["exported_receivers"]}
            </Text>
            <Text style={styles.text}>
              Exported Providers: {data["exported_count"]["exported_providers"]}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default FileAppInfoComponentsPage;
