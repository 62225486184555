import { Font, StyleSheet } from "@react-pdf/renderer";
import Aldrich from "../Aldrich.ttf";
import InterBold from "../Inter-Medium.ttf";
import Inter from "../Inter.otf";

Font.register({
  family: "Aldrich",
  src: Aldrich,
});

Font.register({
  family: "Inter",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  icon: {
    width: "150px",
    height: "150px",
  },
  footerIcon: {
    width: "12px",
    height: "12px",
  },
  brand: {
    position: "absolute",
    top: "1cm",
    right: "1cm",
    width: "auto",
    height: 50,
  },
  brandSize: {
    width: 50,
    height: 50,
  },
  appContentContainer: {
    position: "absolute",
    top: "40%",
    right: "1cm",
    display: "flex",
    alignItems: "flex-start",
  },
  appDetailsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    marginRight: "auto",
    paddingTop: 10,
  },
  detailsContainer: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
  appDetailsTitle: {
    color: "#6BD4CA",
    fontSize: 20,
    fontFamily: "Aldrich",
  },
  appDetailsText: {
    fontSize: 14,
    fontFamily: "Inter",
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  footerContent: {
    color: "white",
  },
  titleContainer: {},

  title: {
    fontSize: 40,
    fontFamily: "Aldrich",
    fontWeight: 800,
    color: "#ffffff",
  },
  subtitle: {
    fontSize: 30,
    fontFamily: "Inter",
    color: "rgba(255, 255, 255, 0.8)",
  },
  backgroundContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    backgroundColor: "#273e8c",
  },
  background: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "500px",
  },
  circle: {
    position: "absolute",
    borderRadius: "50%",
  },
  filled: {
    // background: "rgba(255, 255, 255, 0.05)"
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  empty: {
    background: "none",
  },
  small: {
    width: "30px",
    height: "30px",
  },
  upper: {
    top: "330px",
    marginLeft: "403px",
  },
  lower: {
    top: "485px",
    /* In order to accomplish the orbits effect */
    marginLeft: "-470px",
  },
  big: {
    top: "-200px",
    width: "700px",
    height: "700px",
  },
  bigger: {
    top: "-300px",
    width: "900px",
    height: "900px",
  },
  bigger2: {
    top: "-470px",
    width: "1200px",
    height: "1200px",
  },
  bigger3: {
    top: "-640px",
    width: "1500px",
    height: "1500px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    paddingBottom: ".8cm",
    width: "100%",
    paddingLeft: "1cm",
    flexDirection: "column",
    alignItems: "flex-start",
    fontFamily: "Inter",
  },
  logoContainer: {
    marginTop: 10,
    width: "auto",
  },
  logo: {
    width: 180,
    height: 50,
    transform: "translate(-65, 0)",
  },
  customBrandLogo: {
    width: "auto",
    height: 30,
  },
});

export default styles;
