import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useStyles from "./styles";
import { updateProfileFunctions } from "components/Profile/Account";
import Setting from "components/SettingReportsOption";
import ChargebeeSetting from "components/SettingReportsOptionChargebee";
import Avatar from "@material-ui/core/Avatar";
import { handleFetchResponse } from "utils/handle-fetch-response";
import config from "config";
import { authHeader } from "helpers/auth-header";
import Loader from "components/Loader";
import { makeStyles } from "@material-ui/core";

const useCustomStyles = makeStyles((theme) => ({
  logoDescriptionContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  logoDescription: {
    position: "absolute",
    width: "500px",
    top: 25,
    textAlign: "end",
    fontStyle: "italic",
    fontSize: "12px",
    color: "#1a3586",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  actionButton: {
    position: "absolute",
    right: 10,
    backgroundColor: theme.palette.white[100],
    color: theme.palette.primary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.white[200],
    },
  },
}));

const notEmpty = (value) =>
  value === undefined || value.replace(/\s+/, "") === ""
    ? "Required field"
    : "";

const AccountGeneral = ({ account, dispatch, planInfo }) => {
  const [name, setName] = useState();
  const [popupNameOpen, setPopupNameOpen] = useState(false);
  const [popupPasswordOpen, setPopupPasswordOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { updateName, changePassword } = updateProfileFunctions(
    dispatch,
    enqueueSnackbar,
    {
      setOpenPassword: setPopupPasswordOpen,
    }
  );

  let appsumoPlan = "";
  if (planInfo && planInfo.metadata) {
    const scans = parseInt(planInfo.metadata.schedulerAlive);
    if (scans >= 30) appsumoPlan = "Zendata - Tier 3";
    else if (scans >= 5) appsumoPlan = "Zendata - Tier 2";
    else appsumoPlan = "Zendata - Tier 1";
  }

  const handleUpdateName = ({ name = "" }) => {
    if (name !== "") {
      const [first_name, last_name] = name
        .replace(/^\s+/, "")
        .replace(/\s+/, "☂")
        .split("☂");
      updateName({ first_name, last_name });
    } else {
      updateName({ first_name: "", last_name: "" });
    }
    setPopupNameOpen(false);
  };

  const submit = () => {
    setIsLoading(true);
    const data = new FormData();
    data.append("logo", logo === null ? "" : logo);

    fetch(`${config.apiUrl}/account`, {
      method: "PATCH",
      body: data,
      headers: {
        ...authHeader(),
      },
    })
      .then(handleFetchResponse)
      .then(() => {
        if (logo === null) {
          enqueueSnackbar("Logo successfully deleted!", { variant: "success" });
        } else {
          enqueueSnackbar("Logo successfully uploaded!", {
            variant: "success",
          });
        }
      })
      .catch(() => {
        setImagePreviewUrl(null);
        enqueueSnackbar(
          "There was an error when uploading the image. Please try again.",
          {
            variant: "error",
          }
        );
      });
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (account.logo) {
      setImagePreviewUrl(account.logo);
    }
    setIsLoading(false);
  }, [account.logo]);

  useEffect(() => {
    if (logo) submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const fileChangedHandler = (e) => {
    setIsLoading(true);
    setLogo(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => setImagePreviewUrl(reader.result);
    reader.readAsDataURL(e.target.files[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (account) {
      const newName = `${account.first_name || ""} ${account.last_name || ""}`;
      if (newName !== " ") {
        setName(newName);
      }
    }
  }, [account]);

  const customClasses = useCustomStyles();

  const clearLogo = () => {
    setLogo(null);
    setImagePreviewUrl(null);
    submit();
  };

  return (
    <>
      <Setting
        name="Name"
        value={name}
        popupManager={{
          setOpen: setPopupNameOpen,
          open: popupNameOpen,
        }}
        options={[{ text: "Edit", title: "Change Name" }]}
        form={{
          onSubmit: handleUpdateName,
          action: "Save",
          fields: [
            {
              label: "Name",
              name: "name",
              placeholder: "Your name.",
              initialValue: name,
              validate: notEmpty,
            },
          ],
        }}
      />
      <Setting
        name="Password"
        value="******************"
        popupManager={{
          setOpen: setPopupPasswordOpen,
          open: popupPasswordOpen,
        }}
        options={[{ text: "Edit", title: "Change Password" }]}
        form={{
          onSubmit: changePassword,
          action: "Change",
          fields: [
            {
              label: "Old Password",
              name: "oldPassword",
              type: "password",
              placeholder: "Your current password.",
              validate: notEmpty,
            },
            {
              label: "New Password",
              name: "newPassword",
              type: "password",
              placeholder: "Your new password.",
              validate: notEmpty,
            },
          ],
        }}
      />
      <Setting name="Personal Email" value={account.email} readOnly />
      {account.company && account.company.name === "appsumo" ? (
        <div>
          <Setting
            name="Manage your AppSumo plan"
            value={appsumoPlan}
            popupManager={{
              setOpen: () => {
                window.open("https://appsumo.com/account/products/", "_blank");
              },
            }}
            options={[{ text: "Edit", title: "" }]}
            form={{}}
          />
        </div>
      ) : (
        <ChargebeeSetting
          name="Billing, Subscription and Addons"
          value=""
          options={[{ text: "Edit" }]}
        />
      )}
      <Setting
        name="Branding"
        value={
          isLoading ? (
            <Loader />
          ) : imagePreviewUrl ? (
            <div className={customClasses.container}>
              <Avatar
                className={classes.image}
                src={imagePreviewUrl}
                alt="logo-img"
                style={{
                  width: 150,
                  height: 50,
                }}
                variant="square"
              />
            </div>
          ) : (
            <div className={customClasses.logoDescriptionContainer}>
              <span>No logo selected</span>
              <span className={customClasses.logoDescription}>
                Recommendation: use a logo with 18:6 ratio. E.g. 236x84px
              </span>
            </div>
          )
        }
        popupManager={{
          setOpen: () => {
            if (imagePreviewUrl) {
              clearLogo();
            } else {
              const fileInput = document.createElement("input");
              fileInput.type = "file";
              fileInput.accept = "image/*";
              fileInput.onchange = fileChangedHandler;
              fileInput.click();
            }
          },
          open: false,
        }}
        options={[
          {
            text: imagePreviewUrl ? "Delete" : "Upload",
            title: "Upload your branding",
          },
        ]}
        form={{
          onSubmit: fileChangedHandler,
          action: "Save",
          enctype: "multipart/form-data",
          fields: [
            {
              label: "Logo",
              name: "logo",
              placeholder: "Your branding.",
              initialValue: logo,
              validate: notEmpty,
            },
          ],
        }}
      />
    </>
  );
};

export default AccountGeneral;
