import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";
import ReactHtmlParser from "react-html-parser";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Severity from "pages/AppReport/common/Severety";
import Title from "pages/AppReport/common/Title";
import { makeStyles } from "@material-ui/core";
import SectionContent from "pages/AppReport/common/SectionContent";

const useStyles = makeStyles(() => ({
  container: {
    display: "block",
    boxShadow: "0px 4px 4px 1px rgba(14, 14, 14, 0.24)",
    minWidth: "368px",
    height: "auto",
    borderRadius: "4px",
    padding: "16px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#1976D2",
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#868993",
  },
  content: {
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

export const TableCard = ({ data, sections }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        width: "auto",
        gap: "31px",
        overflow: "auto",
        padding: "5px",
      }}
      className="scrollable-element"
    >
      {data.map((element) => (
        <div className={classes.container}>
          <span className={classes.title}>{element[sections.titleKey]}</span>
          <br />
          {sections.details.map((el) => (
            <>
              <span className={classes.subtitle}>
                {sections.labelRender
                  ? sections.labelRender(el.label)
                  : el.label}
              </span>
              <br />
              <span className={classes.content}>
                {sections.valueRender
                  ? sections.valueRender(element.details[el.key])
                  : element.details[el.key]}
              </span>
              <br />
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

const NiapAnalysisSection = ({ goToNextSectionAction, data }) => {
  const formattedData = Object.entries(data).map((niapAnalysis) => ({
    identifier: niapAnalysis[0],
    details: { ...niapAnalysis[1] },
  }));
  return (
    <Page
      key={SECTIONS_CONTENT.niapAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.niapAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the Manifest Analysis</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.niapAnalysis.description}>
        {SECTIONS_CONTENT.niapAnalysis.title}
      </Title>
      <SectionContent>
        <TableCard
          data={formattedData}
          sections={{
            titleKey: "identifier",
            labelRender: (label) => <span>{label}</span>,
            details: [
              {
                key: "class",
                label: "Requirement",
              },
              {
                key: "description",
                label: "Feature",
              },
              {
                key: "choice",
                label: "Description",
              },
            ],
          }}
        />
      </SectionContent>
    </Page>
  );
};

export default NiapAnalysisSection;
