import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    borderRadius: "4px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  updateButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
    padding: `${theme.spacing()}px ${theme.spacing(3)}px`,
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "1px",
  },
  discardButton: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.blue["young"],
    backgroundColor: "white",
    marginRight: theme.spacing(2),
  },
  closeButton: {
    margin: -12,
    "&:focus": {
      outline: "none",
    },
  },
  form: {
    width: "100%",
    height: "100%",
  },
  formContent: {
    padding: "0px 24px 24px",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
  },
  leftFields: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    minWidth: "291px",
    paddingRight: "16px",
    height: "100%",
  },
  rightField: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "8px 32px",
    borderTop: "solid 1px #e0e0e0",
    borderRadius: "4px",
  },
  toggleGroup: {
    display: "grid",
    gridTemplateRows: "auto",
    position: "absolute",
    top: "24px",
    right: 0,
    width: "40px",
    height: "calc(100% - 24px)",
  },
  toggleButton: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.grey[300]} !important`,
    color: theme.palette.grey[300],
    padding: 0,
    margin: "0px !important",
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey[300],
      color: "white",
    },
  },
  toggleButtonLabel: {
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "1px",
  },
  requiredText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.grey[300],
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "normal",
    padding: "16px 24px",
  },
  asterisk: {
    fontWeight: "bold",
    color: theme.palette.blue[800],
  },
}));
