import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "3rem 0",
  },
  title: {
    color: "#1a3586",
    fontSize: "18px",
    fontWeight: "600",
    letterSpacing: "2px",
    margin: "0 0 16px",
    textTransform: "uppercase",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noScan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    width: "100%",
  },
  noScanTitle: {
    color: theme.palette.grey["700"],
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "1.2",
    marginTop: "1.5rem",
    marginBottom: "1rem",
    whiteSpace: "normal",
    overflowWrap: "break-word",
    textAlign: "center",
    width: "100%",
    "& strong": {
      fontWeight: "700",
    },
  },
  error: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  contentTitle: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "1.44",
    color: "#1a3586",
    width: "100%",
    whiteSpace: "normal",
    overflowWrap: "break-word",
    textAlign: "center",
    paddingBottom: "8px",
  },
  contentBody: {
    backgroundColor: "transparent",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.38",
    color: "hsl(0, 0%, 51%)",
    paddingBottom: "16px",
  },
  connectionsButton: {
    borderColor: "inherit",
    color: theme.palette.blue["young"],
    maxWidth: "112px",
    "&:hover": {
      backgroundColor: "inherit",
      borderColor: "inherit",
      color: theme.palette.blue["500"],
      opacity: 1,
    },
  },
  runScan: {
    backgroundColor: theme.palette.blue["young"],
    color: "#fff",
    fontSize: "15px",
    fontWeight: "600",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "0.25rem 1rem",
    maxWidth: "140px",
    "&:hover": {
      backgroundColor: theme.palette.blue["500"],
      opacity: 1,
    },
    "&:disabled": {
      color: "#fff",
      opacity: 0.6,
    },
  },
  ongoingMain: {
    width: "100%",
  },
  ongoingProgress: {
    height: "148px",
    marginBottom: "14px",
    position: "relative",
    "&>strong": {
      color: "#258e74",
      display: "block",
      fontSize: "2.5rem",
      fontWeight: "700",
      letterSpacing: "-0.96px",
      lineHeight: "1",
      maxWidth: "148px",
      position: "absolute",
      top: "36%",
      left: "0",
      right: "0",
      textAlign: "center",
      "&>span": {
        fontSize: "18px",
      },
    },
  },
  progress: {
    maxWidth: "148px",
    "&>svg>circle": {
      boxShadow: "1px 1px 4px 0 hsl(165, 78%, 58%)",
      filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))",
      stroke: "hsl(137, 58%, 40%)",
    },
  },
  emptyProgress: {
    maxWidth: "148px",
    "&>svg>circle": {
      boxShadow: "1px 1px 4px 0 hsl(165, 78%, 58%)",
      filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))",
      stroke: "hsla(217, 100%, 88%,0.75)",
    },
  },
  ongoingInfo: {
    height: "100%",
    "&>span": {
      color: "#4766b6",
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "1px",
    },
    "&>h4": {
      color: "#122873",
      fontSize: "17px",
      fontWeight: "500",
      marginBottom: "14px",
    },
    "&>h4>strong": {
      display: "block",
      fontWeight: "700",
    },
  },
  ongoingCurrent: {
    color: "#0e1d60",
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "condensed",
    lineHeight: "1.2",
    letterSpacing: "normal",
  },
  ongoingTotalTitle: {
    color: "#1a3586",
    fontSize: "14px",
    lineHeight: "1",
    fontFamily: "Roboto Condensed",
  },
  ongoingTotal: {
    color: "#122873",
    fontFamily: "Roboto Condensed",
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "condensed",
    lineHeight: "1.69",
    letterSpacing: "normal",
  },
  ongoingStat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&>div>p>svg": {
      margin: "0 8px 8px 0",
      "&>path": {
        fill: "#7090d9",
      },
      "&>g>path": {
        fill: "#7090d9",
      },
    },
  },
  pauseScans: {
    backgroundColor: "#a720c4",
    color: "#fff",
    fontSize: "15px",
    fontWeight: "600",
    letterSpacing: "1px",
    margin: "0",
    padding: "2px 12px",
  },
  PauseCircleOutlineIcon: {
    color: "#fff",
    height: "18px",
    marginRight: "8px",
    width: "18px",
  },
  pauseCircleOneScan: {
    marginLeft: "8px",
    padding: "0",
    "&>span>svg": {
      fill: "#a720c4",
    },
  },
  filtersTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[400],
    textTransform: "uppercase",
    width: "100%",
    textAlign: "right",
    paddingBottom: "4px",
    borderBottom: "1px solid #cfdff8",
    marginBottom: "24px",
  },
  scanIdTitle: {
    width: "100%",
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    color: "#122873",
    textAlign: "center",
    fontWeight: "bold",
  },
  scanIdValue: {
    display: "inline",
    width: "100%",
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: "normal",
    color: "#122873",
    textAlign: "center",
  },
  ongoingType: {
    paddingTop: "8px",
    paddingBottom: "4px",
  },
  ongoingContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  titleDialog: {
    color: "#1a3586",
    fontSize: "30px",
    fontWeight: "600",
    margin: "0 0 16px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  descriptionDialog: {
    fontSize: "18px",
    margin: "0 auto",
    textAlign: "center",
  },
  dialogButtonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "27px 0 0",
  },
  submitButtonDialog: {
    margin: "0",
  },
}));
