import { Text, View, Svg, Path, Link } from "@react-pdf/renderer";

const DomainMalwareCheckPage = ({ styles, data }) => (
  <View style={styles.section} break>
    <View>
      <Text style={styles.title}>Domain Malware Check</Text>
      <Text style={styles.subTitle}>
        Domain malware check involves examining the domains the app interacts
        with (e.g., for data transfer, analytics, and third-party services) and
        cross-referencing them with databases of known malicious or suspicious
        domains.
      </Text>
    </View>
    <View>
      <View>
        <View style={styles.table}>
          <View style={styles.thead} fixed>
            <View style={styles.tr}>
              <View style={[styles.thCustomWidth, { width: "30%" }]}>
                <Text>Domain</Text>
              </View>
              <View style={[styles.thCustomWidth, { width: "20%" }]}>
                <Text>Status</Text>
              </View>
              <View style={[styles.thCustomWidth, { width: "30%" }]}>
                <Text>Geolocation</Text>
              </View>
            </View>
          </View>
          {Object.entries(data.domains).map(([key, value]) => {
            return (
              <View style={styles.tr} key={key} wrap={false}>
                <View style={[styles.tdCustomWidth, { width: "30%" }]}>
                  <Text>{key}</Text>
                </View>
                <View style={[styles.tdCustomWidth, { width: "20%" }]}>
                  {value.bad === "no" ? (
                    <Svg viewBox="0 0 24 24" width="16px" weight="16px">
                      <Path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                        fill="#468C49"
                      />
                    </Svg>
                  ) : (
                    <Svg viewBox="0 0 24 24" width="16px" weight="16px">
                      <Path
                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                        fill="#d32f2f"
                      />
                    </Svg>
                  )}
                </View>
                <View style={[styles.tdCustomWidth, { width: "30%" }]}>
                  {value.geolocation !== null ? (
                    <Link
                      src={`http://maps.google.com/maps?q=${value.geolocation.latitude},${value.geolocation.longitude}`}
                    >
                      View
                    </Link>
                  ) : (
                    <Text>No Geolocation information available.</Text>
                  )}
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  </View>
);

export default DomainMalwareCheckPage;
