import CONSTANTS from "./salesforce.constants";

export const requestStart = () => ({
  type: CONSTANTS.SALESFORCE_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.SALESFORCE_REQUEST_END,
});

export const saveData = (data, dataType) => ({
  type: CONSTANTS.SALESFORCE_SAVE_DATA,
  data,
  dataType,
});

export const setError = (data, dataType) => ({
  type: CONSTANTS.SALESFORCE_SET_ERROR,
  data,
  dataType,
});

export const setMeta = (data, dataType) => ({
  type: CONSTANTS.SALESFORCE_SET_META,
  data,
  dataType,
});

export const resetData = (data, dataType) => ({
  type: CONSTANTS.SALESFORCE_RESET_DATA,
  data,
  dataType,
});
