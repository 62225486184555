import { CONSTANTS } from "redux/_admin/_billing/billing.constants";

export const requestStart = () => ({
  type: CONSTANTS.ADMIN_BILLING_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.ADMIN_BILLING_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.ADMIN_BILLING_SAVE_DATA,
  data,
});
