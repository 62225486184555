import React, { useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import theme from "theme";

const FullPageComponent = (props) => {
  return (
    <ReactFullpage
      scrollingSpeed={800}
      touchSensitivity={10}
      responsiveWidth={theme.breakpoints.values.md}
      licenseKey={"YOUR_KEY_HERE"}
      scrollBar={false}
      navigation={true}
      normalScrollElements={".scrollable-element"}
      navigationPosition="left"
      {...props}
    />
  );
};

export default FullPageComponent;
