import React, { useEffect } from "react";
import { FormSpy } from "react-final-form";
import { Box } from "@material-ui/core";
import EntitySelector from "components/SettingsSections/EntitySelector";
import Section1 from "components/Integrations/sections/section1";
import Section3 from "components/Integrations/sections/section3";
import SalesForce from "components/Integrations/SalesForce";
import Slack from "components/Integrations/Slack";
import useStyles from "./styles";
import {
  getIntegrationsData,
  getIntegrationSelected,
} from "redux/_integrations/integrations.selectors";
import {
  select,
  saveIntegration,
  deleteIntegration,
} from "redux/_integrations/integrations.actions";
import { connect } from "react-redux";
import isNil from "lodash.isnil";
import Form from "components/FormComponents/Form";
import IntegrationsIcons from "assets/svgs-icons/Integrations";
import { services } from "./constants";

const EnvironmentSettings = (props) => {
  const classes = useStyles();

  const {
    list,
    selected,
    setSelected,
    match,
    location,
    history,
    update,
    deleteEntity,
    create,
    envLoading,
  } = props;

  useEffect(() => {
    const env_id = location.pathname.replace(match.path, "").split("/")[1];
    if (env_id && selected !== env_id) setSelected(Number(env_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (selected) {
        history.replace(`/zen/integrations/${selected}`);
      } else {
        history.replace(`/zen/integrations/`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const onSubmit = async (values) => {
    if (selected) {
      try {
        await update(values);
      } catch (errors) {
        return errors;
      }
    } else {
      try {
        const id = list.reduce(
          (prev, curr) => (curr.id > prev ? curr.id + 1 : prev + 1),
          1
        );
        await create({ id, ...values });
      } catch (errors) {
        return errors;
      }
    }
  };

  const onDelete = () => deleteEntity(selected);

  const data = list.find((item) => item.id === selected);
  const getIconId = ({ service_id: serviceId }) => {
    const elem = services.find((service) => service.value === serviceId);
    if (elem) return elem.id;
  };

  const SelectedIntegration = (sel) => {
    switch (sel) {
      case 1: {
        return <Slack />;
      }
      case 2: {
        return <SalesForce />;
      }
      default: {
        return (
          <>
            <Section1 showDeleteButton={!isNil(sel)} onDeleteClick={onDelete} />
            <FormSpy subscription={{ values: true }}>
              {({ values: { service_id } = {} }) => (
                <Section3 service={service_id} />
              )}
            </FormSpy>
          </>
        );
      }
    }
  };

  return (
    <Box className={classes.container}>
      <EntitySelector
        entityName="Integration"
        entities={list.map((env) => ({
          ...env,
          icon: getIconId(env),
        }))}
        selected={selected}
        onSelect={setSelected}
        newLabel="New"
        icons={IntegrationsIcons}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={data ? data : {}}
        showSubmit={!selected}
        submitLabel={!selected ? "Create" : "Update"}
        loading={envLoading}
      >
        {SelectedIntegration(selected)}
      </Form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  list: getIntegrationsData(state),
  selected: getIntegrationSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelected: (id) => dispatch(select(id)),
  update: (values) => dispatch(saveIntegration([values])),
  deleteEntity: (id) => dispatch(deleteIntegration(id)),
  create: (values) => dispatch(saveIntegration([values])),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentSettings);
