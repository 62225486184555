import React from "react";
import { useStyles } from "./styles";
import { Tooltip, withStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    padding: "8px 24px",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "50%",
    fontSize: "14px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Title = ({ children, styles, tooltip }) => {
  const classes = useStyles();
  return (
    <h2 className={classes.title} style={styles}>
      {children}
      {tooltip && (
        <HtmlTooltip title={tooltip} placement="bottom">
          <Info className={classes.infoIcon} />
        </HtmlTooltip>
      )}
    </h2>
  );
};

export default Title;
