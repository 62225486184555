import CONSTANTS from "./integrations.constants";

export const saveIntegration = (integration) => ({
  type: CONSTANTS.INTEGRATIONS_SAVE_DATA,
  data: integration,
});

export const select = (integrationId) => ({
  type: CONSTANTS.INTEGRATIONS_SELECT,
  data: integrationId,
});

export const deleteIntegration = (integrationId) => ({
  type: CONSTANTS.INTEGRATIONS_DELETE,
  data: integrationId,
});
