import { Text, View } from "@react-pdf/renderer";

const ThirdPartyLibrariesPage = ({ styles, data }) => {
  const formattedData = (data) => {
    const flattenedArray = [];

    for (const key in data.pii) {
      if (Array.isArray(data.pii[key])) {
        flattenedArray.push(...data.pii[key]);
      }
    }
    const third_partyPIIArray = flattenedArray.filter(
      (obj) => obj.type === "third_party"
    );

    const formattedData = third_partyPIIArray.reduce((result, currentItem) => {
      const ownerName = currentItem.owner;
      const existingOwnerEntry = result.find(
        (entry) => entry.name === ownerName
      );
      if (!existingOwnerEntry) {
        const newOwnerEntry = {
          name: ownerName,
          elements: [currentItem],
        };
        result.push(newOwnerEntry);
      } else {
        existingOwnerEntry.elements.push(currentItem);
      }

      return result;
    }, []);

    return formattedData;
  };

  const thirdPartyData = formattedData(data);

  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.title}>Third Party Libraries</Text>
      </View>
      <View>
        <View>
          {thirdPartyData.length > 0 ? (
            <View>
              {thirdPartyData.map((value) => {
                return (
                  <View>
                    <View style={styles.thead}>
                      <View style={styles.tr}>
                        <View style={styles.thCustomWidth}>
                          <Text style={styles.text}>{value.name}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.table}>
                      <View style={styles.thead} fixed>
                        <View style={styles.tr}>
                          <View
                            style={[styles.thCustomWidth, { width: "15%" }]}
                          >
                            <Text style={styles.text}>KEYWORD</Text>
                          </View>
                          <View
                            style={[styles.thCustomWidth, { width: "15%" }]}
                          >
                            <Text style={styles.text}>LINE NUMBER</Text>
                          </View>
                          <View
                            style={[styles.thCustomWidth, { width: "70%" }]}
                          >
                            <Text style={styles.text}>CONTEXT</Text>
                          </View>
                        </View>
                      </View>
                      {Object.entries(value.elements).map(([key, value]) => {
                        return (
                          <View>
                            <View style={styles.tr} key={key} wrap={false}>
                              <View
                                style={[styles.tdCustomWidth, { width: "15%" }]}
                              >
                                <Text style={styles.text}>{value.keyword}</Text>
                              </View>

                              <View
                                style={[styles.tdCustomWidth, { width: "15%" }]}
                              >
                                <Text style={styles.text}>
                                  {value.line_number}
                                </Text>
                              </View>
                              <View
                                style={[styles.tdCustomWidth, { width: "70%" }]}
                              >
                                <Text style={styles.text}>{value.context}</Text>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          ) : (
            <View style={[styles.tdCustomWidth]}>
              <Text style={styles.text}>
                No third party libraries were found.
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default ThirdPartyLibrariesPage;
