import { Grid } from "@material-ui/core";
import IconCookieManager from "assets/svgs-icons/Report/cookiemanager.svg";
import IconCookieMessage from "assets/svgs-icons/Report/cookiemessage1.svg";
import React from "react";
import {
  DOES_NOT_CONSENT_MANAGER,
  DOES_NOT_HAVE_COOKIES_MESSAGE,
  HAS_CONSENT_MANAGER,
  HAS_COOKIE_MESSAGE,
} from "../constants";
import useStyles from "./styles";

const Consent = ({
  cookieMessageOnFirstLoad,
  hasAdTrackers,
  hasCookieManager,
}) => {
  const cookieMessage = cookieMessageOnFirstLoad
    ? HAS_COOKIE_MESSAGE
    : DOES_NOT_HAVE_COOKIES_MESSAGE;
  const cookieManager = hasCookieManager
    ? HAS_CONSENT_MANAGER
    : DOES_NOT_CONSENT_MANAGER;

  const classes = useStyles();

  const elements = [
    {
      id: 10,
      icon: IconCookieMessage,
      message: cookieMessage,
      showLink: !cookieMessageOnFirstLoad, // Show link only if cookieMessageOnFirstLoad is false
      linkText: "Learn more about this and how to fix it",
      linkUrl: "https://getsitecontrol.com/usecase/cookie-consent-message/",
    },
    {
      id: 11,
      icon: IconCookieManager,
      message: cookieManager,
      showLink: !hasCookieManager, // Show link only if hasCookieManager is false
      linkText: "Learn more about this",
      linkUrl: "https://www.privacypolicies.com/blog/cookie-consent-examples/",
    },
  ];

  return (
    <Grid container classes={{ root: classes.consents }}>
      {elements.map((element) => {
        const { id, icon, message, showLink, linkText, linkUrl } = element;
        return (
          <Grid
            key={id}
            item
            container
            classes={{ root: classes.consent }}
            md={6}
          >
            <Grid item classes={{ root: classes.consentImage }} sm={4} md={12}>
              <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <rect width="100" height="100" fill={`url(#pattern${id})`} />
                <defs>
                  <pattern
                    id={`pattern${id}`}
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                  >
                    <use xlinkHref={`#image${id}`} transform="scale(0.01)" />
                  </pattern>
                  <image
                    id={`image${id}`}
                    width="100"
                    height="100"
                    xlinkHref={icon}
                  />
                </defs>
              </svg>
            </Grid>
            <Grid
              item
              classes={{ root: classes.consentContent }}
              sm={8}
              md={12}
            >
              <p className={classes.consentText}>{message}</p>
              {showLink && (
                <a
                  href={linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.consentLink}
                >
                  {linkText}
                </a>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Consent;
