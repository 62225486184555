import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  reportSidebar: {
    // margin: "135px 0 0 20px",
    "&.-no-footer": {
      marginTop: 216,
    },
  },
  title: {
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "normal",
    letterSpacing: "1px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("xs")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
    },
  },
  description: {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(4),
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    width: "100%",
    maxWidth: "230px",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontFamily: theme.typography.fontFamily,
    "& .MuiButton-label": {
      "& svg": {
        marginRight: "5px",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
    },
  },
}));
