import React, { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import PageWrapper from "components/PageWrapper";
import TableHistory from "components/Requests/Manage/History";
import RequestDetails from "components/Requests/Manage/RequestDetails";
import Filters from "components/Requests/Manage/Filters";
import { getRequestsErrorState } from "redux/_requests/_data/data.selectors";

const RequestsManagePage = ({ error }) => {
  const classes = useStyles();

  const [selectedRow, setSelectedRow] = useState(null);

  const selectRow = (id) => {
    if (id === selectedRow) {
      setSelectedRow(null);
    } else {
      setSelectedRow(id);
    }
  };

  return (
    <PageWrapper
      title="HOME"
      subtitle="REQUESTS MANAGER"
      filtersBarContent={!error ? <Filters /> : undefined}
      error={error}
      errorEntity={null}
    >
      <Box className={classes.content}>
        <Grid container spacing={3} style={{ height: "100%" }}>
          <Grid item xs={5}>
            <TableHistory
              selectedRow={selectedRow}
              setSelectedRow={selectRow}
            />
          </Grid>
          <Grid item xs={7}>
            <RequestDetails selectedRow={selectedRow} />
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  error: getRequestsErrorState(state),
});

export default connect(mapStateToProps)(RequestsManagePage);
