import { makeStyles, withTheme } from "@material-ui/core";
import Background from "./assets/background.svg";

const searchWidgetMixin = () => ({
  width: "80%",
  margin: "auto",
  maxWidth: 570,
});

export default makeStyles(
  (theme) => ({
    customerReport: {},
    main: {},
    mainWithBackground: {
      backgroundImage: `url(${Background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      backgroundSize: "100%",
      paddingBottom: "20%",
    },
    searchWidget: {
      ...searchWidgetMixin(),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "top",
      },
    },
    searchInput: {
      marginBottom: theme.spacing(1),
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
    },
    searchButton: {
      [theme.breakpoints.up("sm")]: {
        width: "min-content",
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: 48,
        color: "white",
      },
    },
    searchInputText: {
      ...searchWidgetMixin(),
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
  }),
  { name: "ConsumerReportLayout" }
);
