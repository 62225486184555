import React, { useMemo, useState } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import Status from "pages/AppReport/common/Status";
import Table from "pages/AppReport/common/Table";
import Title from "pages/AppReport/common/Title";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import SectionContent from "pages/AppReport/common/SectionContent";

const ApplicationPermissions = ({ data, goToNextSectionAction }) => {
  const [selectedStatus, setSelectedStatus] = useState();
  const formattedData = useMemo(
    () =>
      Object.entries(data).map((permission) => ({
        permission: permission[0],
        ...permission[1],
      })),
    [data]
  );

  const filteredData = useMemo(() => {
    return selectedStatus
      ? formattedData.filter((el) => el.status === selectedStatus)
      : formattedData;
  }, [selectedStatus]);

  return (
    <Page
      key={SECTIONS_CONTENT.applicationPermissions.key}
      footerBackgroundColor={
        SECTIONS_CONTENT.applicationPermissions.backgroundColor
      }
      footerText={
        <div className="privacy-tech">Check the Browsable Activities</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.applicationPermissions.description}>
        {SECTIONS_CONTENT.applicationPermissions.title}
      </Title>
      <SectionContent>
        <Table
          data={filteredData}
          limitPerPage={10}
          columns={[
            {
              key: "permission",
              label: "Permissions",
              width: "30%",
            },
            {
              key: "status",
              label: "Status",
              width: "15%",
              render: (status) => (
                <Status
                  status={status}
                  isSelectable
                  onClick={() =>
                    setSelectedStatus(status !== selectedStatus ? status : "")
                  }
                  selected={status === selectedStatus}
                />
              ),
            },
            {
              key: "info",
              label: "Info",
              width: "20%",
            },
            {
              key: "description",
              label: "Reason in manifest",
              width: "35%",
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default ApplicationPermissions;
