import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

const Loader = ({ style = {}, ...restProps }) => {
  const classes = useStyles();

  return (
    <Box style={style} className={classes.loaderContainer}>
      <CircularProgress {...restProps} />
    </Box>
  );
};

export default Loader;
