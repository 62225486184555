import { createSelector } from "reselect";
import isNil from "lodash.isnil";
import isEmpty from "lodash.isempty";

export const getEnvsData = (state) => state.environments.data;
export const getSelectedEnvId = (state) => state.environments.selectedId;
export const getEnvsLoadingState = (state) => state.environments.loading;

export const getSelectedEnvData = createSelector(
  [getSelectedEnvId, getEnvsData],
  (envId, envsData) =>
    !isNil(envId)
      ? envsData.find(({ id }) => parseInt(id) === parseInt(envId))
      : {}
);

export const getScanningState = createSelector(
  [getSelectedEnvData],
  (envData) => (!isEmpty(envData) ? envData.is_scanning : false)
);

export const someEnvScanning = createSelector([getEnvsData], (envsData) =>
  envsData.find((env) => env.is_scanning)
);

export const getFilteredEnvsData = createSelector([getEnvsData], (envsData) => {
  const envsFilterPrefix = /(env-slack|env-salesforce)/gi;
  return envsData.filter((env) => env.name.search(envsFilterPrefix) === -1);
});
