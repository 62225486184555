import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";

import useStyles from "./styles";

const PlanStatus = ({ scansDone, scansLimit }) => {
  const classes = useStyles();
  return (
    <div className={classes.planStatus}>
      <InfoOutlinedIcon />
      <span className={classes.status}>
        {scansDone}/{scansLimit} Scans
      </span>
      {scansDone === scansLimit && (
        <Link to="/zen/account" className={classes.cta}>
          Upgrade
        </Link>
      )}
    </div>
  );
};

export default PlanStatus;
