import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { getAllCountries } from "redux/_countries/environment.selectors";
import { countriesActions } from "redux/_countries/countries.actions";
import { connect } from "react-redux";
import useStyles from "./styles";
import {
  getTreeByCountry,
  getTreeMap,
} from "redux/_charts/charts.async.actions";
import {
  getCountry,
  getProblematic,
  getUnprotected,
  getPiiGroups,
} from "redux/_charts/charts.selectors";

const Filters = (props) => {
  const classes = useStyles();

  const { getCountries, fetchTreeMapData, fetchRecordsData } = props;

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { country } = props;
  const { problematic } = props;
  const { unprotected } = props;
  const { piiGroups } = props;

  useEffect(() => {
    fetchTreeMapData();
    fetchRecordsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, problematic, unprotected, piiGroups]);

  return <Box className={classes.container}></Box>;
};

const mapStateToProps = (state) => ({
  countries: getAllCountries(state),
  country: getCountry(state),
  problematic: getProblematic(state),
  unprotected: getUnprotected(state),
  piiGroups: getPiiGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(countriesActions.getAll()),
  fetchTreeMapData: () => dispatch(getTreeMap()),
  fetchRecordsData: () => dispatch(getTreeByCountry()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
