import GitHubRepositoryIcon from "assets/svgs-icons/social-github-icon.svg";
import GitLabRepositoryIcon from "assets/svgs-icons/social-gitlab-icon.svg";
import BitbucketRepositoryIcon from "assets/svgs-icons/social-bitbucket-icon.png";

const GITLAB_URL_PATTERN = "https://gitlab.com/";
const GITHUB_URL_PATTERN = "https://github.com/";
const BITBUCKET_URL_PATTERN = "https://bitbucket.org/";
const GITLAB_API_URL = "https://gitlab.com/api/v4/projects/";
const GITLAB_API_URL_REPO_BRANCH_PATTERN = "/repository/branches/";
const GITHUB_API_URL = "https://api.github.com/repos/";
const GITHUB_API_URL_BRANCH_PATTERN = "/branches";
const BITBUCKET_API_URL = "https://api.bitbucket.org/2.0/repositories/";
const BITBUCKET_API_URL_BRANCH_PATTERN = "/refs/branches";
const GITHUB_PROVIDER = "Github";
const BITBUCKET_PROVIDER = "Bitbucket";
const GITLAB_PROVIDER = "Gitlab";

const getRepositoryShortNameByProvider = (url, provider) => {
  switch (provider) {
    case GITHUB_PROVIDER:
      return url.replace(GITHUB_URL_PATTERN, "");
    case BITBUCKET_PROVIDER:
      return url.replace(BITBUCKET_URL_PATTERN, "");
    case GITLAB_PROVIDER:
      return url.replace(GITLAB_URL_PATTERN, "");
    default:
      console.error("no matches to trim url for :" + url);
      return url;
  }
};

const getRepositoryShortNameByUrl = (url) => {
  if (!url) {
    return url;
  }

  const lowerCaseUrl = url.toLowerCase();

  if (lowerCaseUrl.includes(GITHUB_URL_PATTERN.toLowerCase())) {
    return url.replace(new RegExp(GITHUB_URL_PATTERN, "i"), "");
  } else if (lowerCaseUrl.includes(BITBUCKET_URL_PATTERN.toLowerCase())) {
    return url.replace(new RegExp(BITBUCKET_URL_PATTERN, "i"), "");
  } else if (lowerCaseUrl.includes(GITLAB_URL_PATTERN.toLowerCase())) {
    return url.replace(new RegExp(GITLAB_URL_PATTERN, "i"), "");
  } else {
    console.error("No matches to trim URL for: " + url);
    return url;
  }
};

const getProviderIcon = (provider) => {
  switch (provider) {
    case "Github":
      return GitHubRepositoryIcon;
    case "Gitlab":
      return GitLabRepositoryIcon;
    case "Bitbucket":
      return BitbucketRepositoryIcon;
    default:
      return null;
  }
};

const encodeUrl = (url) => {
  const encodedUriPath = encodeURIComponent(url);
  return encodedUriPath;
};

const sanitizeGitLabUrl = (url) => {
  const sanitizedUrl = removeText(url, GITLAB_URL_PATTERN, "");
  const encodeProjectPattern = encodeUrl(sanitizedUrl);
  const apiUrl =
    GITLAB_API_URL + encodeProjectPattern + GITLAB_API_URL_REPO_BRANCH_PATTERN;

  return apiUrl;
};

const sanitizeGithubUrl = (url) => {
  const sanitizedUrl = removeText(url, GITHUB_URL_PATTERN, "");
  const apiUrl = GITHUB_API_URL + sanitizedUrl + GITHUB_API_URL_BRANCH_PATTERN;

  return apiUrl;
};

const sanitizeBitBucketUrl = (url) => {
  const sanitizedUrl = removeText(url, BITBUCKET_URL_PATTERN, "");
  const apiUrl =
    BITBUCKET_API_URL + sanitizedUrl + BITBUCKET_API_URL_BRANCH_PATTERN;

  return apiUrl;
};

const removeText = (originalText, regexPattern, replaceString) => {
  // Replace method with regEx
  let newText = originalText.replace(regexPattern, replaceString);
  // Display output
  return newText;
};

export default {
  sanitizeGitLabUrl,
  sanitizeBitBucketUrl,
  sanitizeGithubUrl,
  getRepositoryShortNameByProvider,
  getRepositoryShortNameByUrl,
  getProviderIcon,
};
