import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import queryString from "utils/queryString";
import { CONSTANTS } from "redux/_requests/_data/data.constants";

export const fetchRequestsData = (filters, pagination = {}) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const queryParams = {};
  if (filters[CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION] !== "all") {
    queryParams.individual_right__regulation_id =
      filters[CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION];
  }

  if (filters[CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS] !== "all") {
    queryParams.status = filters[CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS];
  }

  if (filters[CONSTANTS.REQUESTS_FILTERS_TYPES.SEARCH] !== "") {
    queryParams["submit__email__icontains"] =
      filters[CONSTANTS.REQUESTS_FILTERS_TYPES.SEARCH];
  }

  queryParams["days_left[lte]"] =
    filters[CONSTANTS.REQUESTS_FILTERS_TYPES.DAYS_LEFT] + 1;

  const filterIncludes = filters[CONSTANTS.REQUESTS_FILTERS_TYPES.INCLUDES]
    .filter(({ checked }) => checked)
    .map(({ value }) => value);

  if (filterIncludes.length > 0) {
    queryParams["individual_right[in]"] = filterIncludes.join(",");
  }

  const filterValues = queryString({
    ...queryParams,
    ...pagination,
    depth: 2,
  });

  const url = `${config.apiUrl}/requests/request${filterValues}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data, meta }) => new Promise((res) => res([data, meta])));
};

export const updateRequest = (requestData) => {
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(requestData),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiUrl}/requests/config/${requestData.id}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const fetchIndividualRights = (filters) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const queryParams = {};
  if (filters.regulation !== "all") {
    queryParams.regulation_id = filters.regulation;
  }

  const url = `${config.apiUrl}/requests/individual_right${queryString(
    queryParams
  )}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
