import CONSTANTS from "redux/_scans/scans.constants";

export const lastTwoRequest = (envId) => ({
  type: CONSTANTS.GETLASTTWO_REQUEST,
  envId,
});

export const lastTwoSuccess = (envId, lastScans) => ({
  type: CONSTANTS.GET_LAST_TWO_SUCCESS,
  envId,
  lastScans,
});

export const getOverviewRequest = (envId) => ({
  type: CONSTANTS.GET_OVERVIEW_REQUEST,
  envId,
});

export const setLast = (last) => ({
  type: CONSTANTS.SCANS_SET_LAST,
  data: last,
});

export const setList = (list) => ({
  type: CONSTANTS.SCANS_SET_LIST,
  data: list,
});

export const getOverviewSuccess = (envId, scans, query) => ({
  type: CONSTANTS.GET_OVERVIEW_SUCCESS,
  envId,
  scans,
  query,
});

export const saveScans = (scans) => ({
  type: CONSTANTS.SCANS_SAVE,
  data: scans,
});

export const requestStart = () => ({
  type: CONSTANTS.SCANS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.SCANS_REQUEST_END,
});

export const setInProgress = (data) => ({
  type: CONSTANTS.SCANS_SET_IN_PROGRESS,
  data,
});
