const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  tabs: {
    padding: "4px",
    marginBottom: "40px",
  },
  cell: {
    "& a": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& img": {
        display: "flex",
        alignItems: "center",
        height: "20px",
        width: "auto",
      },
    },
  },
}));

export default useStyles;
