import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { ListItem, ListItemText, Collapse } from "@material-ui/core";
import OpenInNew from "@material-ui/icons/OpenInNew";

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

const SubItem = ({
  subItems,
  open,
  match,
  env,
  scan,
  setOpenedElement,
  classes,
  sidebarOpen,
  onItemContainerClick,
}) => {
  useEffect(
    () => {
      if (match) onItemContainerClick();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [match]
  );

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {subItems.map(
        ({ name: subName, text: subText, blank, link: subLink, exact }) => (
          <ListItem
            button
            exact={exact}
            component={ForwardNavLink}
            to={!blank ? subLink(env, scan) : ""}
            className={clsx(classes.listItem, classes.subItem)}
            {...(!blank ? { activeClassName: classes.selectedItem } : {})}
            key={subName}
            onClick={(ev) => {
              if (blank) {
                ev.preventDefault();
                global.open(subLink(env, scan), "_blank");
              }
            }}
          >
            {sidebarOpen && (
              <ListItemText
                className={classes.listItemText}
                primary={
                  blank ? (
                    <div>
                      {`${subText} `}
                      <span>
                        <OpenInNew
                          style={{
                            color: "#ffffff75",
                            width: "16px",
                            display: "inline-block",
                            marginLeft: 1,
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    subText
                  )
                }
              />
            )}
          </ListItem>
        )
      )}
    </Collapse>
  );
};

export default SubItem;
