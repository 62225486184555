import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  title: {
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "2px",
    color: theme.palette.grey[300],
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },
}));
