import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import StatisticsTables from "components/Consents/Overview/components/ConsentsGranted/components/StatisticsTables";
import TypeSelector from "components/Consents/Overview/components/ConsentsGranted/components/TypeSelector";

const ConsentsGranted = ({ policies, applications, partners, loading }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState();

  return (
    <>
      <Box style={{ width: "35%" }}>
        <Typography className={classes.title} style={{ padding: 0 }}>
          CONSENTS GRANTED
        </Typography>
        <Box height={224}>
          <TypeSelector
            loading={loading}
            policies={policies}
            applications={applications}
            partners={partners}
            selected={selected}
            onChange={setSelected}
            colors={{
              policies: "#1a3586",
              applications: "#7686b6",
              partners: "#cad0e3",
            }}
          />
        </Box>
      </Box>
      <Box style={{ width: "62%" }}>
        <Typography className={classes.title}>
          Statistics for <span style={{ color: "#333333" }}>{selected}</span>
        </Typography>
        <StatisticsTables
          selected={selected}
          policies={policies}
          applications={applications}
          partners={partners}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default ConsentsGranted;
