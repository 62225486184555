import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import { getAccountData } from "redux/_account/account.selectors";
import {
  listSchedulers,
  postTriggerReport,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";
import { Box } from "@material-ui/core";

import PageWrapper from "components/PageWrapper";
import useStyles from "./styles";
import { NewRepositoryScannerView } from "./NewRepositoryScannerView";

const NewRepositoryScanner = ({ account, fetchSchedulers }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapper subtitle={" New Repository"}>
      <Switch>
        <Route path={"/zen/new-repository-scanner"}>
            <Box className={classes.viewContainer}>
                <NewRepositoryScannerView />
            </Box>
        </Route>
      </Switch>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  chedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
  fetchSchedulers: (filters) => dispatch(listSchedulers(filters)),
});

const ConnectedSchedulingRepositoryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRepositoryScanner);
export default withRouter(ConnectedSchedulingRepositoryPage);
