import {
  scanTables,
  getTables,
  updateTables,
  getInitialSetup,
  setInitialSetupConfig,
} from "redux/_settings/settings.service";
import {
  getDatabaseSettingsSelected,
  getDatabaseTablesSettingsData,
} from "./settings.selectors";
import {
  saveDatabaseTables,
  requestStart,
  requestEnd,
  initialSetupRequestEnd,
  initialSetupRequestStart,
  saveInitialSetup,
} from "./settings.actions";

export const getDatabaseTables = () => (dispatch, getState) => {
  const dbId = getDatabaseSettingsSelected(getState());
  dispatch(requestStart());
  return getTables(dbId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveDatabaseTables(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const updateTablesData = () => (dispatch, getState) => {
  const dbId = getDatabaseSettingsSelected(getState());
  const tablesData = getDatabaseTablesSettingsData(getState());
  dispatch(requestStart());
  return updateTables(dbId, tablesData)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveDatabaseTables(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const scanDatabaseTables = (dbData) => (dispatch) => {
  dispatch(requestStart());
  return scanTables(dbData)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveDatabaseTables(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const getInitialSetupData = () => (dispatch) => {
  dispatch(initialSetupRequestStart());
  return getInitialSetup()
    .then((data) => {
      dispatch(initialSetupRequestEnd());
      dispatch(saveInitialSetup(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(initialSetupRequestEnd());
      return Promise.reject();
    });
};

export const setShowStatusBar = (show) => (dispatch) => {
  return setInitialSetupConfig(show)
    .then((data) => {
      const { show_initial_setup } = data;
      dispatch(saveInitialSetup({ show_initial_setup }));
      return Promise.resolve(data);
    })
    .catch((_) => {
      return Promise.reject();
    });
};
