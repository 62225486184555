import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import CopyButton from "components/Consents/Collector/EmbedCode/components/copyButton";
import Preview from "components/Consents/Collector/Preview";
import Loader from "components/Loader";
import {
  getConsentsConfig,
  getConsentsConfigLoadingState,
} from "redux/_consents/_config/config.selectors";
import { updateConfig } from "redux/_consents/_config/config.async.actions";

const EmbedCode = ({ data, loading }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.leftContent}>
          <Typography className={classes.primaryText}>
            To use the <b>Collector</b> on your website, simply download{" "}
            <a
              download="zencollector.js"
              href="/consent-collector/zencollector.js"
            >
              zencollector.js
            </a>{" "}
            and then include the file in your website like this:
          </Typography>
          <Box className={classes.codeBlockContainer}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Typography className={classes.codeText}>
                  {`<script`} defer type="text/javascript"
                </Typography>
                <Typography
                  className={classes.codeText}
                  style={{ marginLeft: "3rem" }}
                >
                  src="{<b>zencollector.js</b>}"
                </Typography>
                <Typography
                  className={classes.codeText}
                  style={{ marginLeft: "3rem" }}
                >
                  data-api-key="{data.token}" {"/>"}
                </Typography>
                <CopyButton
                  text={`<script defer type="text/javascript" data-api-key="${data.token}" src="zencollector.js"/>`}
                />
              </>
            )}
          </Box>
          <Typography className={classes.primaryText}>
            Do not forget to change your existing apps/trackers as outlined in
            the next section as well so that {<b>Collector</b>} can manage them.{" "}
            {<b>Collector</b>} will automatically open on page load.
          </Typography>
          <Typography className={classes.primaryText}>
            If you wish to open the Consent Manager manually on user interaction
            (for example through a link in your Privacy Policy page), you can
            use the {<b>zencollector.show()</b>} global. Example:
          </Typography>
          <Box className={classes.codeBlockContainer}>
            <Typography className={classes.codeText}>
              {`<a`} class="button is-success" onclick="return{" "}
              {<b>zencollector.show();</b>}">Change consent settings{`</a>`}
            </Typography>
            <CopyButton
              text={`<a class="button is-success" onclick="return zencollector.show();">Change consent settings</a>`}
            />
          </Box>
          <Typography className={classes.subtitle}>
            MANAGE THIRD-PARTY APPS & TRACKERS
          </Typography>
          <Typography className={classes.primaryText}>
            To manage third-party scripts and ensure they only run if the user
            consents with their use, you simply replace the {<b>src</b>}{" "}
            attribute with {<b>data-src</b>}, change the type attribute to{" "}
            {<b>opt-in</b>} and add a {<b>data-type</b>} attribute with the
            original type, and add a {<b>data-name</b>} field that matches the
            name of the app as given in your config file. Example:
          </Typography>
          <Box className={classes.codeBlockContainer}>
            <Typography className={classes.codeText}>
              {`<script`} {<b>type</b>}="opt-in"
            </Typography>
            <Typography
              className={classes.codeText}
              style={{ marginLeft: "3rem" }}
            >
              {<b>data-type</b>}="text/javascript"
            </Typography>
            <Typography
              className={classes.codeText}
              style={{ marginLeft: "3rem" }}
            >
              {<b>data-name</b>}="optimizely"
            </Typography>
            <Typography
              className={classes.codeText}
              style={{ marginLeft: "3rem" }}
            >
              {<b>data-src</b>}="https://cdn.optimizely.com/js/10196010078.js"/>
            </Typography>
            <CopyButton
              text={`<script type="opt-in" data-type="text/javascript" data-name="optimizely" data-src="https://cdn.optimizely.com/js/10196010078.js"/>`}
            />
          </Box>
          <Typography className={classes.primaryText}>
            {<b>Collector</b>} will then take care of executing the scripts if
            consent was given (you can chose to execute them before getting
            explicit consent as well).
          </Typography>
          <Typography className={classes.primaryText}>
            The same method also works for images, stylesheets and other
            elements with a src or type attribute.
          </Typography>
        </Box>
        <Box className={classes.rightContent}>
          <Typography className={classes.rightTitle}>Preview</Typography>
          <Preview initialPage="home" />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsConfig(state),
  loading: getConsentsConfigLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbedCode);
