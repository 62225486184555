import React, { useEffect } from "react";
import SelectInput from "components/Inputs/Select";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { getFilterCountry } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";
import { getAllCountries } from "redux/_countries/environment.selectors";
import { countriesActions } from "redux/_countries/countries.actions";
const { TYPES } = CONSTANTS;

const Country = (props) => {
  const classes = useStyles();

  const { countries = [], getCountries, country, setCountry } = props;

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectInput
      label="Region"
      options={[{ value: "all", label: "All" }, ...countries]}
      className={classes.filtersInput}
      value={country}
      onChange={setCountry}
    />
  );
};

const mapStateToProps = (state) => ({
  countries: getAllCountries(state),
  country: getFilterCountry(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(countriesActions.getAll()),
  setCountry: (id) => dispatch(selectData(TYPES.COUNTRY, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Country);
