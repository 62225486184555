import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0px",
    borderBottom: "2px solid #e0e0e0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: theme.spacing(0, 5, 2),
  },
  itemsContainer: {
    height: "100%",
    alignItems: "flex-end",
  },
  itemValueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  itemValue: {
    fontSize: "24px",
    fontWeight: "bold",
    marginLeft: "8px",
    marginRight: "8px",
    lineHeight: 1.25,
    color: theme.palette.blue[500],
  },
  itemTitle: {
    fontSize: "18px",
    color: "#828282",
    fontWeight: 600,
    textOverflow: "ellipsis",
  },
  variationContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0px 4px",
    borderRadius: "2px",
    height: 20,
    "&>svg": {
      marginLeft: "4px",
    },
  },
  variation: {
    lineHeight: 1.69,
    fontSize: "13px",
    fontWeight: "bold",
    fontFamily: "Roboto Condensed",
  },
  item: {
    display: "flex",
    justifyContent: "center",
  },
  skeleton1: {
    margin: "3px 0",
  },
  skeleton2: {
    margin: "2px 0",
  },
}));
