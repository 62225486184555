import { CONSTANTS } from "redux/_requests/_options/options.constants";

export const requestStart = () => ({
  type: CONSTANTS.OPTIONS_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.OPTIONS_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.OPTIONS_SAVE_DATA,
  data,
});
