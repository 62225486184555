export const roles = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "P",
    label: "Processor",
  },
  {
    value: "C",
    label: "Controller",
  },
];

export const storages = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "I",
    label: "Internal",
  },
  {
    value: "E",
    label: "External",
  },
];

export const purposes = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "-1",
    label: "Uncategorized",
  },
];

export const units = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "-1",
    label: "Uncategorized",
  },
];

export const regions = [
  {
    value: "all",
    label: "All",
  },
];

export const consentCategories = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "POL",
    label: "Policy",
  },
  {
    value: "APP",
    label: "Apps",
  },
  {
    value: "PAR",
    label: "Partner",
  },
];

export const consentDecision = [
  {
    value: "ac",
    label: "Any Consent",
  },
  {
    value: "fc",
    label: "Full Consent",
  },
  {
    value: "vf",
    label: "Viewed Form",
  },
  {
    value: "fu",
    label: "Full Unconsent",
  },
  {
    value: "na",
    label: "No Action",
  },
];
