import React from "react";
import { Box, Typography } from "@material-ui/core";

import CONSTANTS from "redux/_scans/scans.constants";

import SvgIcon from "components/svg-icon/svg-icon";
import Button from "./button";

import { formatNumber } from "utils/format";

import useStyles from "./styles";

const ScanInsights = ({
  selectedRow: { pii_columns, pii_groups, id, environment_id, status },
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>Insights</Typography>
      <Box className={classes.container}>
        <Box className={classes.statistic}>
          <Box className={classes.insightContent}>
            <SvgIcon icon="overview.piicols" />
            <Typography className={classes.insightValue}>
              {formatNumber(pii_columns)}
            </Typography>
          </Box>
          <Typography className={classes.insightTitle}>Pii Columns</Typography>
        </Box>
        <Box className={classes.statistic}>
          <Box className={classes.insightContent}>
            <SvgIcon icon="overview.environments" />
            <Typography className={classes.insightValue}>
              {formatNumber(pii_groups.length)}
            </Typography>
          </Box>
          <Typography className={classes.insightTitle}>Pii groups</Typography>
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button
            icon="scanDataMap"
            text="Data Map"
            disabled={status === CONSTANTS.SCAN_IN_PROGRESS_STATUS}
            link={`/zen/environment/${environment_id}/scans/${id}/data-map`}
          />
          <Button
            icon="scanDataOwner"
            text="Data Owners"
            disabled={status === CONSTANTS.SCAN_IN_PROGRESS_STATUS}
            link={`/zen/environment/${environment_id}/scans/${id}/data-owners`}
          />
        </Box>
      </Box>
    </>
  );
};

export default ScanInsights;
