import { makeStyles } from "@material-ui/core/styles";

const arrowsStyle = {
  border: "none",
  backgroundColor: "inherit",
  boxSizing: "border-box",
  borderRadius: "5px",
  padding: "12px 16px",
};

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: ({ marginTop }) =>
      marginTop ? theme.spacing(marginTop) : theme.spacing(2),
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& > .before": {
        ...arrowsStyle,
      },
      "& > .pages": {
        width: "50%",
        display: "flex",
        justifyContent: "space-evenly",
        lineHeight: "20px",
        letterSpacing: "0.25px",
        color: "#686C78",
      },
      "& > .after": {
        ...arrowsStyle,
      },
    },
  },
}));

export default useStyles;
