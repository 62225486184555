import { CONSTANTS } from "redux/_consents/_timeline/timeline.constants";

const initialState = {
  loading: false,
  data: undefined,
  error: false,
};

export const timeline = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.TIMELINE_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CONSTANTS.TIMELINE_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.TIMELINE_SET_ERROR:
      return {
        ...state,
        error: true,
      };
    case CONSTANTS.TIMELINE_SAVE_DATA:
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};
