import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  LinearProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { columns } from "./constants";
import EmptyOverlay from "components/EmptyOverlay";

const isEmpty = (arr) =>
  arr &&
  arr.every(
    ({ full_consent, opted_in, opted_out }) =>
      !full_consent && !opted_in && !opted_out
  );

const StatisticsTables = (props) => {
  const classes = useStyles();

  const { selected, policies, applications, partners, loading } = props;

  const data = useMemo(() => {
    switch (selected) {
      case "policies":
        return policies;
      case "applications":
        return applications;
      case "partners":
        return partners;
      default:
        return policies;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, policies, applications, partners]);

  const isEmptyData = useMemo(
    () => isEmpty(policies) && isEmpty(applications) && isEmpty(partners),
    [policies, applications, partners]
  );

  return (
    <EmptyOverlay
      show={isEmptyData}
      height="auto"
      text="No Consents collected."
      link="/zen/consents/collector"
    >
      <Box className={classes.tableWrapper}>
        {data && !loading ? (
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow className={classes.tableRowHeader}>
                {columns.map(({ id, align, minWidth, label }) => (
                  <TableCell
                    key={id}
                    align={align}
                    style={{ minWidth: minWidth }}
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idx) => {
                const isEven = !((idx + 1) & 1);
                return (
                  <TableRow
                    hover
                    className={classes.tableRowBody}
                    key={row.id}
                    style={{
                      backgroundColor: isEven
                        ? "rgba(224, 224, 224, 0.2)"
                        : "white",
                    }}
                    tabIndex={-1}
                  >
                    {columns.map(({ id, align, color }) => {
                      let d = row[id];
                      if (id !== "name") {
                        if (id === "full_consent") {
                          d = row["opted_in"]
                            ? `${((d / row["opted_in"]) * 100).toFixed(0)}%`
                            : "-";
                        } else {
                          d = d.toFixed(0);
                        }
                      }
                      return (
                        <TableCell
                          style={{
                            color,
                            fontWeight: id === "name" ? "normal" : "bold",
                          }}
                          key={id}
                          align={align}
                        >
                          {d}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <LinearProgress className={classes.loader} />
        )}
      </Box>
    </EmptyOverlay>
  );
};

export default StatisticsTables;
