import React, { useEffect, useState, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import isNil from "lodash.isnil";

import Users from "components/Admin/Users";
import BillingInfo from "components/Admin/BillingInfo";
import PageWrapper, { Tab, Tabs } from "components/PageWrapper";

const AdminPage = ({ history, match, location }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    [location.pathname, match.path]
  );
  const [value, setValue] = useState(pathValue || "users");

  useEffect(() => {
    if (isNil(pathValue)) history.push(`/zen/admin/${value}`);
  });

  useEffect(() => {
    history.push(`/zen/admin/${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onTabChange = (value) => setValue(value);

  return (
    <PageWrapper
      title="HOME"
      subtitle="ADMIN"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"users"} label="Users" />
        </Tabs>
      }
    >
      <Switch>
        <Route path={"/zen/admin/users"} component={Users} />
      </Switch>
    </PageWrapper>
  );
};

export default AdminPage;
