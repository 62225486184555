import React from "react";
import useStyles from "../../styles";
import ToggleInput from "components/Inputs/Toggle";
import { roles } from "components/PageWrapper/components/FiltersBar/components/constants";
import { connect } from "react-redux";
import { getFilterRole } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const EntityRole = (props) => {
  const classes = useStyles();

  const { role, setRole } = props;

  return (
    <ToggleInput
      label="Entity role"
      options={roles}
      className={classes.filtersInput}
      value={role}
      onChange={setRole}
    />
  );
};

const mapStateToProps = (state) => ({
  role: getFilterRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  setRole: (id) => dispatch(selectData(TYPES.ENTITY_ROLE, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityRole);
