import { CONSTANTS } from "redux/_requests/_options/options.constants";
import unionBy from "lodash.unionby";
import isArray from "lodash.isarray";
import _ from "lodash";

const initialState = {
  loading: false,
  data: [],
};

export const options = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.OPTIONS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.OPTIONS_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.OPTIONS_SAVE_DATA:
      return {
        ...state,
        data: isArray(data)
          ? unionBy(state.data, data, "id")
          : _.values(_.merge(_.keyBy(state.data, "id"), _.keyBy([data], "id"))),
      };
    default:
      return state;
  }
};
