import React from "react";

const Lock = ({ className }) => (
  <svg
    className={className}
    width={373}
    height={417}
    viewBox="0 0 373 417"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.02">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.625 121.625V173.75H104.25C85.6874 173.75 69.5 187.24 69.5 205.604V350.396C69.5 368.76 85.6874 382.25 104.25 382.25H312.75C331.313 382.25 347.5 368.76 347.5 350.396V205.604C347.5 187.24 331.313 173.75 312.75 173.75H295.375V121.625C295.375 73.6453 256.48 34.75 208.5 34.75C160.52 34.75 121.625 73.6453 121.625 121.625ZM260.625 121.625V173.75H156.375V121.625C156.375 92.8372 179.712 69.5 208.5 69.5C237.288 69.5 260.625 92.8372 260.625 121.625ZM104.25 347.5V208.5H312.75V347.5H104.25ZM225.875 278C225.875 287.596 218.096 295.375 208.5 295.375C198.904 295.375 191.125 287.596 191.125 278C191.125 268.404 198.904 260.625 208.5 260.625C218.096 260.625 225.875 268.404 225.875 278Z"
        fill="black"
      />
    </g>
  </svg>
);

export default Lock;
