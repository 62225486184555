import React from "react";
import { Button } from "@material-ui/core";

const ScanButton = ({ settings, handleClick, style = {} }) => {
  const { label, labelColor = "#1B3586", backColor = "#6BD4CA" } = settings;

  const buttonStyle = {
    fontFamily: "Inter",
    color: labelColor,
    backgroundColor: backColor,
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: 8,
    paddingBottom: 8,
    cursor: "pointer",
    ...style,
  };

  return (
    <Button variant="contained" style={buttonStyle} onClick={handleClick}>
      {label}
    </Button>
  );
};

export default ScanButton;
