const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  cardList: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 50,
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  cardChart: {
    width: "567px",
    height: "272px",
    padding: "20px 0",
    color: "#1B3586",
    top: "360px",
    left: "99px",
    borderRadius: "15px",
    boxShadow: "0px 1px 5.400000095367432px 0px #00000040",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      width: "567px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "567px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  backButton: {
    color: "#1B3586",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default useStyles;
