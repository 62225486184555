import React from "react";

const PlusOutlinedIcon = () => (
  <svg
    width={124}
    height={124}
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.0268 8.13839C53.0268 4.74825 55.775 2 59.1652 2H64.8348C68.225 2 70.9732 4.74825 70.9732 8.13839V53.0268H115.862C119.252 53.0268 122 55.775 122 59.1652V64.8348C122 68.225 119.252 70.9732 115.862 70.9732H70.9732V115.862C70.9732 119.252 68.225 122 64.8348 122H59.1652C55.775 122 53.0268 119.252 53.0268 115.862V70.9732H8.13839C4.74825 70.9732 2 68.225 2 64.8348V59.1652C2 55.775 4.74825 53.0268 8.13839 53.0268H53.0268V8.13839Z"
      stroke="#7fbff8"
      strokeWidth={3}
    />
  </svg>
);

export default PlusOutlinedIcon;
