import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardElementsCardContent from "./CardElementsCardContent";
import PropTypes from "prop-types";
import { StatisticsComponent } from "../components/StatisticsComponent";
import { useFetchUserRepositories } from "hooks/useFetchUserRepositories";
import CloseIcon from "@material-ui/icons/Close.js";
import Pagination from "./Pagination";

import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import useStyles from "../styles";

const RepositoryFilter = ({ className = "" }) => {
  const classes = useStyles();
  const history = useHistory();
  const [errorDeleted, setErrorDeleted] = useState(false);
  const { loading, data, error, fetchData, pagination, setPagination } = useFetchUserRepositories();
  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.pageSize]);

  const handleHistory = useCallback(() => {
    history.push("/zen/new-repository-scanner");
  }, [history]);

  const onHandleDelete = useCallback(() => {
    setErrorDeleted(true);
  }, []);

  const showEmptyResults = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 24,
          textAlign: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Typography
          style={{
            color: "#000000",
            fontFamily: "Inter",
            fontWeight: 900,
            fontSize: 20,
          }}
        >
          Scan your repository to analyze how it is handling personal/sensitive information.
        </Typography>
        <Typography
          style={{ color: "#000000", fontFamily: "Inter", fontSize: 16 }}
        >
          Get started by clicking the button “New repository”
        </Typography>
      </Box>
    );
  };

  const showGridResults = (results) => {
    console.log(results);
    return (
      <Grid container spacing={2}>
        {results.map((repo) => (
          <Grid item xs={12} sm={6} key={repo.id} style={{ display: "flex" }}>
            <CardElementsCardContent repo={repo} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const showData = () => {
    if (data) {
      const results = data?.repositories?.data;
      if (!results || results.length === 0) {
        return showEmptyResults();
      }
      return showGridResults(results);
    }
  };

  const showError = () => {
    return (
      !loading &&
      error &&
      !errorDeleted && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "24px",
            backgroundColor: "#F9F9FA",
            border: "1px solid red",
            padding: 12,
            borderRadius: 8,
            fontFamily: "Inter",
            color: "red",
          }}
        >
          <Typography
            style={{
              display: "flex",
              flex: 1,
              fontFamily: "Inter",
              fontSize: 14,
              color: "#FB2424",
            }}
          >
            {error}
          </Typography>
          <CloseIcon style={{ color: "grey" }} onClick={onHandleDelete} />
        </Box>
      )
    );
  };

  return (
    <>
      {showError()}
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ width: 40, height: 40 }} />
        </Box>
      ) : (
        <Box>
          <Typography
            style={{
              color: "#868993",
              fontFamily: "Inter",
              fontSize: 16,
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            View your repositories summary based on the aggregated values of the last scans.
          </Typography>
          <StatisticsComponent summary={data?.summary} />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              marginTop: 48,
              marginBottom: 24,
            }}
          >
            <Typography
              className={classes.myRepositories}
              style={{ display: "flex", flex: 1 }}
            >
              My repositories
            </Typography>

            <Button
              className={classes.button4}
              variant="contained"
              onClick={handleHistory}
              style={{ alignSelf: "flex-end", fontWeight: "bold" }}
            >
              NEW REPOSITORY
            </Button>
          </Box>
          {showData()}
          {  data?.repositories?.data && 
          <Pagination
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalRows={pagination.totalRows}
            setPagination={setPagination}
          /> }
          <section className={`repository-filter ${className}`}></section>
        </Box>
      )}
    </>
  );
};

RepositoryFilter.propTypes = {
  className: PropTypes.string,
};

export default RepositoryFilter;
