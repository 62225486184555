import humps from "humps";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import moment from "moment";
import { createSelector } from "reselect";

import { getScansByEnvId } from "redux/_scans/scans.selectors";

export const getFilters = (state) => state.filters.selected;

export const getTreeMapData = (state) => state.charts.scans.treeMap;

export const getOwnersTree = (state) => state.charts.scans.owners;

export const getLoading = (state) => state.charts.scans.loading;

export const getError = (state) => state.charts.scans.error;

export const getMonth = (state) => state.charts.scans.filters.monthId;

export const getTreeByCountry = (state) => state.charts.scans.treeByCountry;

export const getCustomersByCountry = (state) => state.charts.scans.customers;

export const getScan = (state) => state.charts.scans.filters.scanId;

export const getCountry = (state) => state.charts.scans.filters.country;

export const getProblematic = (state) =>
  state.charts.scans.filters.visibility.hideNonProblematic;

export const getUnprotected = (state) =>
  state.charts.scans.filters.visibility.hideNonUnprotected;

export const getPiiGroups = (state) => state.charts.scans.filters.piiGroups;

const getSelectedEnv = (state) => state.environments.selectedId;

const getLastTwoScansState = (state) => state.lastTwoScans;

export const getFilteredLastScansData = (state) => state.charts.scans.lastScans;

export const getFunctionalUses = (state) => state.charts.scans.functionalUses;

const getLastScans = createSelector(
  [getSelectedEnv, getLastTwoScansState],
  (envId, lastTwoScans) =>
    !!lastTwoScans && !!lastTwoScans[envId]
      ? lastTwoScans[envId].scansHash || {}
      : {}
);

export const getLastScan = createSelector([getLastScans], (lastScans) => {
  const sortedScans = Object.values(lastScans).sort((scan1, scan2) =>
    scan1.ended_at === scan2.ended_at
      ? 0
      : scan1.ended_at > scan2.ended_at
      ? -1
      : 1
  );
  return !isEmpty(sortedScans) ? sortedScans[0] : {};
});

export const generatePiiGroups = createSelector(
  [getScan, getScansByEnvId],
  (scanId, scans) =>
    !isNil(scanId) && !isEmpty(scans)
      ? scans
          .find(({ id }) => parseInt(scanId) === parseInt(id))
          .pii_groups.map(({ pii_group_name }, idx) => ({
            id: idx,
            name: pii_group_name,
            value: true,
          }))
      : []
);

export const getScansByMonthId = createSelector(
  [getMonth, getScansByEnvId],
  (month, scans) =>
    scans.filter((scan) => moment(scan.ended_at).month() + 1 === month)
);

export const getTreeByCountryData = createSelector(
  [getTreeByCountry, getFilters],
  (data, filters) => data
);

export const getOwnersTreeData = createSelector(
  [getOwnersTree, getFilters],
  (data, filters) => data
);

export const getCustomersByCountryData = createSelector(
  [getCustomersByCountry, getFilters],
  (data, filters) => data
);

export const getTreeMapDataFormatted = createSelector(
  [getTreeMapData],
  (tree) => {
    // Get tables representation (Channels for Slack integration)
    const channels =
      tree &&
      tree.children &&
      tree.children[0] &&
      tree.children[0].children[0] &&
      tree.children[0].children[0].children;

    // Set cleaned channels name to owners chart for Slack integration
    if (channels) {
      const slackPrefix = "__slack__";

      channels.forEach((channel) => {
        if (channel.name && channel.name.startsWith(slackPrefix)) {
          channel.name =
            "# " +
            humps.decamelize(channel.name.replace(slackPrefix, ""), {
              separator: " ",
            });
        }
      });
    }

    return tree;
  }
);

export const getOwnersTreeFormatted = createSelector(
  [getOwnersTree],
  (tree) => {
    // Get database representation
    const database = tree && tree.length > 0 && tree[0];
    // Get tables representation (Channels for Slack integration)
    const channels =
      tree &&
      tree.length > 0 &&
      tree[0] &&
      tree[0].children[0] &&
      tree[0].children[0].children;

    // Set cleaned database name to owners chart
    if (database) {
      database.name = database.name
        .replace(/(ds-|db-|\d+)/gi, "")
        .replace(/(_|-)/g, " ")
        .toLowerCase();
    }

    // Set cleaned channels name to owners chart for Slack integration
    if (channels) {
      const slackPrefix = "__slack__";

      channels.forEach((channel) => {
        if (channel.name && channel.name.startsWith(slackPrefix)) {
          channel.name =
            "# " +
            humps.decamelize(channel.name.replace(slackPrefix, ""), {
              separator: " ",
            });
        }
      });
    }

    return tree;
  }
);
