import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import SVGIcon from "components/svg-icon/svg-icon";
import Cookie from "./Cookie";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 0),
  },
  disabled: {
    opacity: "1 !important",
    backgroundColor: "transparent !important",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "48px",
    color: theme.palette.blue[500],
    textTransform: "none",
    margin: 0,
  },
  tableContainer: {
    borderBottom: "1px solid #E0E1E3",
    width: "100%",
  },
  head: {
    display: "flex",
    color: "#868993",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
    borderBottom: "1px solid #E0E1E3",
  },
  tr: {
    borderBottom: "1px solid #E0E1E3",
    width: "100%",
    display: "flex",
  },
  cookieName: {
    width: 1200,
    textAlign: "center",
  },
  provider: {
    width: 1200,
    textAlign: "center",
  },
  thirdParty: {
    width: 1200,
    textAlign: "center",
  },
  summary: {
    padding: 0,
    minHeight: "unset!important",
  },
  expanded: {
    margin: "0!important",
  },
  content: {
    margin: "0!important",
  },
  expansionDetail: {
    padding: 0,
  },
  expandIcon: {
    borderRadius: 6,
    padding: theme.spacing(0.5),
    "&$expanded": {
      transform: "none",
      "& svg": {
        transform: "rotate(-180deg)",
      },
    },
    "& svg": {
      width: 20,
      height: 20,
      transition: "transform .3s ease",
    },
  },
  expandText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "40px",
    color: theme.palette.blue[400],
    marginRight: "10px",
    textTransform: "uppercase",
  },
  cookies: {
    height: "calc(100vh - 640px)",
    minHeight: 80,
    overflowY: "auto",
  },
}));

const CookieCategory = ({ title, cookies = [], onChange, expanded }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ExpansionPanel
        elevation={0}
        disabled={!cookies.length}
        onChange={onChange(title.replace(" ", ""))}
        expanded={!cookies.length ? false : !!expanded}
        classes={{
          disabled: classes.disabled,
        }}
      >
        <ExpansionPanelSummary
          IconButtonProps={{ edge: false }}
          expandIcon={
            cookies.length > 0 && (
              <>
                <Typography className={classes.expandText}>
                  {expanded ? "Collapse" : "See details"}
                </Typography>
                <SVGIcon icon="chevronDownIcon" />
              </>
            )
          }
          classes={{
            root: classes.summary,
            expanded: classes.expanded,
            content: classes.content,
            disabled: classes.disabled,
            expandIcon: classes.expandIcon,
          }}
        >
          <Typography className={classes.title} variant="h6" component="h4">
            {title} ({cookies.length})
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={clsx(classes.expansionDetail, "scrollable-element")}
        >
          <div className={classes.tableContainer}>
            <div className={classes.head}>
              <div className={classes.tr}>
                <span className={classes.cookieName}>Cookie Name</span>
                <span className={classes.provider}>Provider</span>
                <span className={classes.provider}>Purpose</span>
                <span className={classes.provider}>Duration</span>
                <span className={classes.provider}>Information Stored</span>
                <span className={classes.provider}>Sub-Category</span>
                <span className={classes.thirdParty}>Third Party</span>
                <span className={classes.thirdParty}>Secure</span>

                {cookies?.[0]?.canonicals && (
                  <span className={classes.thirdParty}>Present on # pages</span>
                )}
              </div>
            </div>

            <div className={clsx("scrollable-element", classes.cookies)}>
              {cookies.map((cookie, i) => (
                <Cookie
                  key={i}
                  {...cookie}
                  name={cookie.name}
                  path={cookie.path}
                  domain={cookie.domain}
                  expires={cookie.rxpires}
                  maxAge={cookie.maxAge}
                  expiry={cookie.expiry}
                  secure={cookie.secure}
                  thirdParty={cookie.thirdParty}
                  httpOnly={cookie.httpOnly}
                  usedRequests={cookie.usedRequests}
                  category={cookie.category}
                  description={cookie.description}
                  url={cookie.uRL}
                  remoteAddr={cookie.remoteAddr}
                  status={cookie.status}
                  mimeType={cookie.mimeType}
                  initiator={cookie.initiator}
                  source={cookie.source}
                  lineNo={cookie.lineNo}
                  pages={cookie?.canonicals}
                />
              ))}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default CookieCategory;
