import React, { useEffect } from "react";
import clsx from "clsx";
import { Box, Paper, Tabs as UnstyledTabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import useStyles from "./styles";
import LayoutHeader from "components/PageWrapper/components/LayoutHeader";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { getAccountData } from "redux/_account/account.selectors";
import { selectEnv } from "redux/_environments/environment.actions";
import { getScan } from "redux/_charts/charts.selectors";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import { setFilterScan } from "redux/_charts/charts.actions";
import FiltersBar from "components/PageWrapper/components/FiltersBar";
import isNil from "lodash.isnil";
import isEmpty from "lodash.isempty";
import { withRouter } from "react-router-dom";
import PiiCategoriesFilter from "components/PageWrapper/components/PIICatFilter";
import CONSTANTS from "redux/_filters/filters.constants";
import Loader from "components/Loader";
import EmptyOverlay from "components/EmptyOverlay";
import ErrorState from "components/PageWrapper/components/ErrorState";
import ErrorBoundary from "components/PageWrapper/components/ErrorState/boundary";

const { TYPES } = CONSTANTS;

const PageWrapper = (props) => {
  const {
    account,
    logout,
    filters = [],
    title,
    subtitle,
    selectedEnv,
    selectEnvironment,
    selectedScan,
    selectScan,
    match,
    children,
    showKpis,
    kpisTitle,
    header,
    customKpis,
    filtersBarContent,
    loading,
    empty,
    error,
    errorEntity = "scan",
    retryFn,
    className,
  } = props;

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const {
      params: { env_id, scan_id },
    } = match;
    if (!isNil(env_id) && selectedEnv !== env_id && !isNaN(env_id))
      selectEnvironment(env_id);
    if (!isNil(scan_id) && selectedScan !== scan_id && !isNaN(scan_id))
      selectScan(scan_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <Box
      component={Paper}
      elevation={0}
      className={clsx(classes.container, className)}
    >
      {((!isNil(filters) && !isEmpty(filters)) ||
        !isNil(filtersBarContent)) && (
        <FiltersBar
          filters={filters}
          showKpis={showKpis}
          kpisTitle={kpisTitle}
          customKpis={customKpis}
          customContent={filtersBarContent}
        />
      )}
      <Box className={classes.content}>
        <LayoutHeader
          account={account}
          handleLogout={handleLogout}
          error={error}
          title={title}
          subtitle={subtitle}
          filters={filters}
          className={className}
        >
          {header}
        </LayoutHeader>

        <ErrorBoundary>
          {error ? (
            <ErrorState entity={errorEntity} retry={retryFn} />
          ) : loading ? (
            <div style={{ width: "100%", height: "calc(100% - 144px)" }}>
              <Loader />
            </div>
          ) : (
            <EmptyOverlay
              text="No Scans run."
              link={
                selectedEnv
                  ? `/zen/environment/${selectedEnv}/scans`
                  : "/zen/settings"
              }
              height="calc(100% - 82px)!important"
              show={empty}
            >
              {children}
            </EmptyOverlay>
          )}
          {!isNil(filters) &&
            !isEmpty(filters) &&
            !error &&
            filters.includes(TYPES.PII_CATEGORIES) && (
              <PiiCategoriesFilter disabled={loading || empty} />
            )}
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  selectedEnv: getSelectedEnvId(state),
  selectedScan: getScan(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectEnvironment: (envId) => dispatch(selectEnv(envId)),
  selectScan: (scanId) => dispatch(setFilterScan(scanId)),
  logout: () => dispatch(authenticationActinon.logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageWrapper));

export const Tabs = withStyles({
  root: {
    "& .MuiTabs-flexContainer": {
      height: "80px",
    },
  },
})(UnstyledTabs);

export { Tab };
