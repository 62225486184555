import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  statistic: {
    width: "100%",
    height: "100px",
    backgroundColor: theme.palette.grey[400],
    borderRadius: "4px",
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding: "20px 8px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      width: "calc(50% - 16px)",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  insightContent: {
    display: "flex",
  },
  insightTitle: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.grey[300],
  },
  insightValue: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.blue[500],
    paddingLeft: "4px",
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: theme.palette.blue[500],
    textTransform: "uppercase",
    paddingBottom: "16px",
  },
  buttonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    "& > button:first-child": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 16px)",
      height: "45px",
      "& > button:first-child": {
        marginBottom: 0,
      },
    },
  },
  button: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    border: `solid 2px ${theme.palette.blue["young"]}`,
    [theme.breakpoints.up("lg")]: {
      width: "calc(50% - 8px)",
    },
  },
  buttonContent: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.blue["young"],
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
  text: {
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.blue["young"],
    paddingLeft: "8px",
  },
}));
