import React, { useMemo } from "react";
import { Tooltip } from "@material-ui/core";

const darkBlue = "#1B3586";
const lightBlue = "#A3E3FF";
const blue = "#74BDE5";

const lightRed = "#F86565";

const green = "#2E7D32";
const lightGreen = "color-mix(in srgb, #ffffff 90%, #2E7D32)";

const black = "#0E0E0E";
const lightBack = "color-mix(in srgb, #ffffff 84%, #000000)";

const orange = "#EF7D1F";

const StatusColor = {
  normal: {
    bubbleColor: blue,
    letterColor: darkBlue,
  },
  dangerous: {
    bubbleColor: lightRed,
    letterColor: lightRed,
  },
  signature: {
    bubbleColor: lightGreen,
    letterColor: green,
  },
  unknown: {
    bubbleColor: lightBack,
    letterColor: black,
  },
};

const StatusDataSourceColor = {
  successful: {
    bubbleColor: blue,
    letterColor: darkBlue,
  },
  failed: {
    bubbleColor: lightRed,
    letterColor: lightRed,
  },
  signature: {
    bubbleColor: lightGreen,
    letterColor: green,
  },
  unknown: {
    bubbleColor: lightBack,
    letterColor: black,
  },
  setting_up: {
    bubbleColor: orange,
    letterColor: orange,
  },
};

const StatusLabel = {
  normal: "Normal",
  dangerous: "Dangerous",
  signature: "Signature",
  unknown: "Unknown",
};

const StatusDataSourceLabel = {
  setting_up: "Setting Up",
  failed: "Failed",
  successful: "Successful",
};

const Status = ({
  status,
  selected,
  isSelectable = true,
  onClick,
  type,
  summary,
}) => {
  const colors = useMemo(
    () =>
      type === "dataSource"
        ? StatusDataSourceColor[status] || StatusDataSourceColor.setting_up
        : StatusColor[status] || StatusColor.normal,
    [status]
  );

  return (
    <Tooltip
      title={
        summary?.status === "setting_up"
          ? "Setting up in progress"
          : summary?.status === "successfully"
          ? "Successfully scanned"
          : summary?.summary?.message
      }
    >
      <div
        style={{
          color: selected ? "#ffffff" : colors.letterColor,
          border: `2px solid ${colors.bubbleColor}`,
          backgroundColor: selected ? colors.bubbleColor : "#ffffff",
          padding: "8px 16px",
          borderRadius: 64,
          lineHeigth: "18.15px",
          verticalAlign: "text-top",
          cursor: isSelectable ? "pointer" : "default",
          display: "inline-block",
        }}
        onClick={onClick}
      >
        <span style={{ wordBreak: "keep-all", display: "flex" }}>
          {(type === "dataSource"
            ? StatusDataSourceLabel[status]
            : StatusLabel[status]) || status}
        </span>
      </div>
    </Tooltip>
  );
};

export default Status;
