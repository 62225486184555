import React from "react";
import { Typography, Box } from "@material-ui/core";
import SvgIcon from "components/svg-icon/svg-icon";
import useStyles from "./styles.js";
import { formatNumber } from "utils/format";

const getColor = (variation) =>
  variation === 0 ? "#4766b6" : variation < 0 ? "#71b4a7" : "#dd6666";

const getBgColor = (variation) =>
  variation === 0
    ? "rgba(242, 242, 242, 0.2)"
    : variation < 0
    ? "#f8fcfb"
    : "#fef8f8";

const StatItem = (props) => {
  const classes = useStyles();
  const {
    value,
    variation,
    name,
    icon = "database",
    percentage = false,
    positive = true,
  } = props;

  const parsedVariation = positive ? variation : -variation;

  const color = getColor(parsedVariation);

  const bgColor = getBgColor(parsedVariation);

  const diff = Math.abs(parsedVariation);

  return (
    <Box>
      <Box className={classes.itemValueContainer}>
        <SvgIcon icon={icon} />
        <Typography className={classes.itemValue}>
          {" "}
          {percentage ? `${value.toFixed(2)}%` : formatNumber(value)}
        </Typography>
        <Box
          className={classes.variationContainer}
          style={{ backgroundColor: bgColor, border: `1px solid ${color}` }}
        >
          <Typography style={{ color: color }} className={classes.variation}>
            {variation !== 0
              ? percentage
                ? `${diff.toFixed(2)}%`
                : formatNumber(diff)
              : "Unchanged"}
          </Typography>
          {variation !== 0 && (
            <SvgIcon
              icon={parsedVariation > 0 ? "arrowTrendup" : "arrowTrenddown"}
              color={color}
            />
          )}
        </Box>
      </Box>
      <Typography className={classes.itemTitle} display="inline" noWrap>
        {" "}
        {name}
      </Typography>
    </Box>
  );
};

export default StatItem;
