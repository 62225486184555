import consented from "./consented.svg";
import environments from "./environments.svg";
import erasure from "./erasure.svg";
import piicols from "./piicols.svg";
import portability from "./portability.svg";
import unprotected from "./unprotected.svg";

export default {
  consented,
  environments,
  erasure,
  piicols,
  portability,
  unprotected,
};
