import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "./styles";

const Box = ({ title, styles: globalStyles, children, icon }) => {
  return (
    <View style={[styles.container, !icon ? { paddingLeft: 20 } : {} ,globalStyles ? globalStyles.container : {}]}>
      <Text style={styles.title}>
        {title}
      </Text>
      <View>
        {children}
      </View>
      {icon && (
        <View style={styles.label}>
          <View style={styles.icon}>
            {icon}
          </View>
        </View>
      )}
    </View>
  );
};

export default Box;
