import {
    Box,
    Typography,
  } from "@material-ui/core";

import useParentStyles from "./styles.js";

export const ReferenceSquare = ({
    bodyContent,
    classColour,
  }) => {
    const parentClasses = useParentStyles();
  
    return (
        <div className={parentClasses.referenceContainer}>
            <Box className={classColour}/>
            <Typography className={parentClasses.referenceText}>{bodyContent}</Typography>
        </div>
    );
  };