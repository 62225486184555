import React from "react";
import Switch from "components/Inputs/Switch";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 176,
    fontFamily: "Roboto Condensed, sans-serif",
  },
  title: {
    width: "100%",
    borderBottom: "1px solid #f5f5f5",
    textTransform: "uppercase",
    fontSize: "12.5px",
    paddingBottom: 4,
    color: "#4f4f4f",
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    textTransform: "uppercase",
    fontSize: "12.5px",
    margin: "6px 0",
    height: 28,
  },
}));

const OwnersFilter = ({
  owners = [],
  onChange,
  selected = [],
  colors = [],
}) => {
  const classes = useStyles();

  if (!owners.length) return null;
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>OWNERS</h3>
      {owners.map((owner, i) => (
        <label key={`${owner}-${i}`} className={classes.label}>
          {owner || "(Unknown user)"}
          <Switch
            color={colors[i % colors.length][0]}
            value={selected.includes(owner)}
            onChange={() => onChange(owner)}
          />
        </label>
      ))}
    </div>
  );
};

export default OwnersFilter;
