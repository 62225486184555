import React, { useState } from "react";
import { connect } from "react-redux";

import { authenticationActinon } from "redux/_authentication/authentication.actions";
import AuthenticationControl from "components/login/login.main";
import ResetPasswordForm from "components/login/reset-password-form";
import Seo, { pages } from "components/Seo";

const ResetPasswordContainer = ({ setSent, dispatch, isAuthenticated }) => {
  const [email, setEmail] = useState();
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState();

  const resetPassword = (e) => {
    e.preventDefault();

    if (email) {
      setSending(true);
      dispatch(authenticationActinon.resetPassword(email))
        .then(() => {
          setSent(true);
        })
        .catch((errors) => {
          setErrors(errors);
        });
    }
  };

  const handleChange = (value) => {
    setEmail(value);
  };

  return (
    <AuthenticationControl>
      <Seo {...pages.resetPassword} />

      <ResetPasswordForm
        isSending={sending}
        email={email}
        updateEmail={handleChange}
        errors={errors}
        submit={resetPassword}
      />
    </AuthenticationControl>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
});

export default connect(mapStateToProps)(ResetPasswordContainer);
