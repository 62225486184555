import React from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import useStyles from "./styles";
import EmptyIllustration from "assets/illustrations/empty_functional_uses.svg";
import { withRouter } from "react-router-dom";

const FunctionalUsesEmptyPage = ({ history, hasFilters, reset = () => {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid className={classes.leftContent} item xs={6}>
          <img src={EmptyIllustration} alt="" />
        </Grid>
        <Grid className={classes.rightContent} item xs={6}>
          <Typography className={classes.title}>Hi there!</Typography>
          <Typography className={classes.title}>
            We need to set some things up first.
          </Typography>
          <Typography className={classes.subtitle}>
            {hasFilters ? (
              <>
                We couldn't find any data with the current filters. Please reset
                them or map the remaining tables with their corresponding
                Functional Uses.
              </>
            ) : (
              <>
                In order to begin displaying data here, we need you to map the
                tables in your DBs to a particular Functional Use.
              </>
            )}
          </Typography>
          <Box display="flex">
            <Button
              className={classes.mapButton}
              onClick={(_) => history.push("/zen/settings/tables")}
            >
              Map Now
            </Button>
            {hasFilters && (
              <Button
                onClick={reset}
                className={classes.outlinedButton}
                variant="outlined"
              >
                Reset Filters
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(FunctionalUsesEmptyPage);
