import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";

const Issue = ({
  anchorText,
  textAfterLink = null,
  link = "#",
  margin = true,
  target = "_self",
  text,
}) => {
  const classes = useStyles({ margin });
  return (
    <Box className={classes.container}>
      <Typography component="span" className={classes.text}>
        {text}
      </Typography>
      {anchorText && (
        <a className={classes.anchor} href={link} target={target}>
          {anchorText}
        </a>
      )}
      {textAfterLink && (
        <Typography component="span" className={classes.textAfterLink}>
          {textAfterLink}
        </Typography>
      )}
    </Box>
  );
};

export default Issue;
