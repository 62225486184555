import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@keyframes cloud": {
    from: { left: -190 },
    to: { left: "100%" },
  },

  cloud: {
    position: "absolute",
    top: ({ top }) => top,
    width: ({ width }) => width,
    height: 33,
    backgroundColor: theme.palette.blue[100],
    opacity: 0.5,
    animationName: "$cloud",
    animationDuration: ({ duration }) => `${duration}s`,
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    borderRadius: 33,
    animationDelay: ({ delay }) => `-${delay}s`,
    zIndex: -1,
  },
}));

const Cloud = ({ top, delay }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const duration = useCallback(Math.floor(Math.random() * (36 - 25)) + 25, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const width = useCallback(Math.floor(Math.random() * (210 - 170)) + 170, []);
  const classes = useStyles({ top, delay, duration, width });
  return <div className={classes.cloud}></div>;
};

export default Cloud;
