import CONSTANTS from "redux/_datasources/datasources.constants";
import unionBy from "lodash.unionby";

const initialState = {
  loading: false,
  data: [],
};

export const datasources = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.DATASOURCES_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.DATASOURCES_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.DATASOURCES_SAVE_DATA:
      return {
        ...state,
        loading: false,
        data: unionBy(state.data, data, "id"),
      };
    case CONSTANTS.DATASOURCES_UPDATE_DATA:
      return {
        ...state,
        loading: false,
        data: state.data.map((el) => (parseInt(el.id) === data.id ? data : el)),
      };
    case CONSTANTS.DATASOURCES_DELETE_DATA:
      return {
        ...state,
        data: state.data.filter((ds) => parseInt(ds.id) !== parseInt(data)),
      };
    default:
      return state;
  }
};
