import { Grid } from "@material-ui/core";
import IconPrivacy from "assets/svgs-icons/Report/privacy.svg";
import IconTerms from "assets/svgs-icons/Report/terms.svg";
import React from "react";
import { calculateTermsAndConditions } from "../../utils.js";
import {
  DOES_NOT_HAVE_PRIVACY_POLICY,
  DOES_NOT_HAVE_TERMS,
  HAS_PRIVACY_POLICY,
  HAS_TERMS,
} from "../constants";
import useStyles from "./styles";

const PrivacyLinks = ({ report }) => {
  const { serviceTerms } = report || {};

  const { hasPrivacyLink, hasTermsLink, personalData } = report.report || {};

  const {
    hasPrivacyPolicy,
    hasTermsAndConditions,
  } = calculateTermsAndConditions(
    serviceTerms,
    hasPrivacyLink,
    hasTermsLink,
    personalData
  );
  const classes = useStyles();

  return (
    <Grid container className={classes.privacyLinks}>
      <Grid item md={6} className={classes.link}>
        <Grid item container sm={4} md={12} className={classes.linkImage}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="100" height="100" fill="url(#pattern2)" />
            <defs>
              <pattern
                id="pattern2"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlinkHref="#image2" transform="scale(0.01)" />
              </pattern>
              <image
                id="image2"
                width="100"
                height="100"
                xlinkHref={IconPrivacy}
              />
            </defs>
          </svg>
        </Grid>
        <Grid item sm={8} md={12} className={classes.linkText}>
          {hasPrivacyPolicy ? HAS_PRIVACY_POLICY : DOES_NOT_HAVE_PRIVACY_POLICY}
        </Grid>
      </Grid>
      <Grid item container md={6} className={classes.link}>
        <Grid item sm={4} md={12} className={classes.linkImage}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="100" height="100" fill="url(#pattern4)" />
            <defs>
              <pattern
                id="pattern4"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlinkHref="#image4" transform="scale(0.01)" />
              </pattern>
              <image
                id="image4"
                width="100"
                height="100"
                xlinkHref={IconTerms}
              />
            </defs>
          </svg>
        </Grid>
        <Grid item sm={8} md={12} className={classes.linkText}>
          {hasTermsAndConditions ? HAS_TERMS : DOES_NOT_HAVE_TERMS}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyLinks;
