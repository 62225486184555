import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    margin: 0,
    paddingBottom: 5,
    paddingLeft: 20,
  },
  description: {
    paddingLeft: 20,
    paddingRight: 85,
    fontSize: 12,
    paddingTop: 0,
  },
  scoreBox: {
    marginTop: 30,
  },
});

export default styles;
