import { makeStyles } from "@material-ui/core";

import {
  stepperDialogContentMixin,
  stepperDialogTitleMixin,
  emailNoticeMixin,
} from "components/StepperDialog/styles";
import headerBackground from "assets/images/bg_scan.svg";

export default makeStyles((theme) => ({
  form: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.16)",
    borderRadius: "5px",
    color: "#686C78",
    [theme.breakpoints.up("xs")]: {
      padding: 0,
      width: "90%",
      margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
      padding: "80px 0",
      width: "600px",
      margin: 0,
    },
  },
  header: {
    overflowX: "hidden",
    backgroundColor: "#f5f8fd",
    "& > .custom-container": {
      backgroundImage: `url(${headerBackground})`,
      backgroundRepeat: "repeat-x",
      backgroundPosition: "bottom",
      height: "610px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "& > .elements": {
        width: "100%",
        maxWidth: 540,
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > .MuiGrid-container": {
          width: "auto",
        },
      },
    },
    [theme.breakpoints.up("xs")]: {
      padding: 0,
      "& > .custom-container": {
        padding: 0,
      },
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 0 300px",
      "& > .custom-container": {
        padding: "50px 0 450px",
      },
    },
  },
  buttonContainer: {
    width: "100%",
  },
  headerTextField: {
    width: "80%",
    marginBottom: theme.spacing(2),
    "&.clear-background": {
      width: "100%",
      height: "90px",
      marginBottom: 0,
      "&.auto-height": {
        paddingTop: "10px",
        height: "auto",
      },
      "&.country": {
        marginTop: "8px",
        height: "50px",
      },
    },
    "& > .MuiFormControl-marginNormal": {
      height: "53px",
      "& > .MuiInputBase-formControl": {
        height: "45px",
        "& > .MuiInputBase-inputSelect, & > .MuiSelect-selectMenu": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
  headerInputBase: {
    height: 48,
    borderRadius: 6,
    backgroundColor: theme.palette.common.white,
  },
  headerInputOutline: {
    borderRadius: 6,
  },
  headerInputIcon: {
    width: 24,
    height: 24,
    marginLeft: 2,
    marginRight: theme.spacing(1),
    "& > path": {
      fill: theme.palette.blue["young"],
    },
  },
  headerInput: {
    "&::placeholder": {
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      fontStyle: "italic",
      color: theme.palette.blue[300],
      opacity: 1,
    },
  },
  formFields: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
    "& > .label": {
      paddingTop: "10px",
      textTransform: "uppercase",
      fontWeight: "bold",
      alignSelf: "flex-start",
      fontSize: 24,
      lineHeight: "40px",
    },
    "&.first-step": {
      width: "80%",
      "& > .MuiFormControl-root": {
        width: "100%",
      },
    },
    "&.country": {
      height: "210px",
      "& > .label": {
        paddingTop: "17px",
      },
    },
    "&.email": {
      height: "215px",
      "& > .label": {
        paddingTop: "20px",
      },
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: "210px",
      "&.first-step": {
        paddingTop: 20,
      },
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "230px",
      "&.first-step": {
        paddingTop: 40,
      },
    },
  },
  widgetManager: {
    width: "50%",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: 25,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 50,
    },
  },
  scanTypePicker: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  goBack: {
    marginTop: theme.spacing(2),
  },
  buttonLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  headerImage: {
    position: "absolute",
    minWidth: "100%",
    marginTop: "-64px",
  },
  checkIcon: {
    margin: "1rem",
    border: `2px solid ${theme.palette.blue[500]}`,
    borderRadius: "100%",
    padding: "1rem",
    width: "72px",
    height: "72px",
  },
  flag: {
    width: "1.8em",
    height: "1.2em",
    position: "relative",
    top: "-1px",
    "&.selected": {
      marginRight: "10px",
    },
  },
  separator: {
    marginRight: "10px",
  },
  ...stepperDialogContentMixin(theme),
  ...stepperDialogTitleMixin(theme),
  ...emailNoticeMixin(theme),
}));
