const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
