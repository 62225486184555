import React from "react";

const Dropbox = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99966 2L0 7.1028L7.99966 12.2056L16.0007 7.1028L7.99966 2Z"
      fill="#0061FF"
    />
    <path
      d="M23.9997 2L16 7.1028L23.9997 12.2056L31.9993 7.1028L23.9997 2Z"
      fill="#0061FF"
    />
    <path
      d="M0 17.3079L7.99966 22.4107L16.0007 17.3079L7.99966 12.2051L0 17.3079Z"
      fill="#0061FF"
    />
    <path
      d="M23.9997 12.2051L16 17.3079L23.9997 22.4107L31.9993 17.3079L23.9997 12.2051Z"
      fill="#0061FF"
    />
    <path
      d="M7.99902 24.1126L16 29.2154L23.9997 24.1126L16 19.0098L7.99902 24.1126Z"
      fill="#0061FF"
    />
  </svg>
);

export default Dropbox;
