import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import PageWrapper from "components/PageWrapper";

import useStyles from "./styles";
import { REPO_V2_URL } from "constants/RepoV2";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const PageReportsWrapperContext = React.createContext();

const PageReportsWrapper = ({
  containerCustomClasses = {},
  selected,
  setSelected,
  subpages,
  title,
  isBeta,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handlePostMessage = (event) => {
      if (event.origin !== REPO_V2_URL) return; // Replace with your domain
      const { type, payload } = event.data;
      if (type === "LOGOUT") {
        dispatch(authenticationActinon.logout());
        history.push("/privacy-scanner");
      }
    };

    window.addEventListener("message", handlePostMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);
  const classes = useStyles();
  const {
    title: subtitle,
    description,
    Component,
    props = {},
    sectionToMove = undefined,
    subSection = undefined,
    extra = undefined,
  } = subpages[selected || 0];

  const handleOption = (option) => () => {
    setSelected(option);
  };

  const moveToSection = (section) => {
    setSelected(section);
  };

  let rest =
    sectionToMove !== undefined
      ? { goToSection: handleOption(sectionToMove) }
      : {};

  return (
    <PageReportsWrapperContext.Provider value={{ moveToSection }}>
      <PageWrapper title={isBeta} subtitle={title} className={classes.wrapper}>
        <Box className={clsx(classes.content, containerCustomClasses.content)}>
          <Grid
            container
            className={clsx(
              classes.container,
              containerCustomClasses.container
            )}
          >
            <Box
              className={clsx(classes.options, containerCustomClasses.options)}
            >
              <Box
                className={clsx(
                  classes.optionContainer,
                  containerCustomClasses.optionContainer
                )}
              >
                {subpages.map((subpage, index) => (
                  <button
                    key={`header-${index}`}
                    className={clsx(
                      classes.tab,
                      selected === index && "-selected"
                    )}
                    onClick={handleOption(index)}
                  >
                    {subpage.option}
                  </button>
                ))}
              </Box>
            </Box>
            {!!subtitle && (
              <Box className={classes.descriptionContainer}>
                <Box
                  className={clsx(
                    classes.description,
                    containerCustomClasses.description
                  )}
                >
                  <h2>{subtitle}</h2>
                  <Typography>{description}</Typography>
                </Box>
                {extra && (
                  <Box className={classes.descriptionExtra}>{extra}</Box>
                )}
              </Box>
            )}
            <Box className={clsx(classes.body, containerCustomClasses.body)}>
              <Component {...props} {...rest} />
            </Box>
            {subSection && (
              <>
                <Box
                  className={clsx(
                    classes.description,
                    classes.subSection,
                    containerCustomClasses.description
                  )}
                >
                  <h2>{subSection.subtitle}</h2>
                  <Typography>{subSection.description}</Typography>
                </Box>
                <Box
                  className={clsx(classes.body, containerCustomClasses.body)}
                >
                  <subSection.Component />
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </PageWrapper>
    </PageReportsWrapperContext.Provider>
  );
};

export default PageReportsWrapper;
