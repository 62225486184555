import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import SVGIcon from "components/svg-icon/svg-icon";
import GroupedInput from "components/Admin/Users/components/GroupedInput";
import UsersTable from "components/Admin/Users/components/UsersTable";
import {
  getAdminUsersData,
  getAdminUsersLoadingState,
} from "redux/_admin/_users/users.selectors";
import { getUsersData } from "redux/_admin/_users/users.async.actions";

const Users = ({ users, getUsers, loading }) => {
  const classes = useStyles();

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <Typography className={classes.title}>Manage your Team</Typography>
        <GroupedInput className={classes.groupInput} loading={loading} />
      </Box>
      <Box>
        <UsersTable users={users} loading={loading} />
      </Box>
      <div className={classes.backgroundIcon}>
        <SVGIcon icon="lock" />
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  users: getAdminUsersData(state),
  loading: getAdminUsersLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsersData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
