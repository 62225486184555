import Page from "components/PrivacyReports/Report/components/Page";
import {
  ORDERED_ANCHORS,
  SECTIONS_CONTENT,
  SECTION_ID,
  SECTION_NAME,
} from "../constants";
import { mapSectionNameToId } from "../helpers";
import {
  MissingHttpOnlyFlag,
  MissingSecurityFlag,
} from "./InsecureCookieSetting";
import Landing from "./Landing";
import Status from "pages/AppReport/common/Status";
import Severity from "pages/AppReport/common/Severety";
import TextFindingSection from "./TextSettings";
import ScanCoverageInformation from "./ScanCoverageInformation";
import GaugeChart from "components/PrivacyReports/Report/components/GaugeChart";

const RiskBar = ({ risk, maxRisk = 8, label, color }) => {
  const maxWidth = "222.52px";
  const width =
    risk !== maxRisk
      ? risk === 0
        ? "auto"
        : (risk * maxRisk) / 100 + 30
      : maxWidth;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "14px",
        cursor: "default",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width,
          height: "25px",
          padding: "3px 7px",
          marginRight: "7px",
          fontWeight: "bold",
        }}
      >
        {risk}
      </div>
      {label}
    </div>
  );
};
const riskKeys = ["high", "info", "low", "medium"];
const RiskValues = {
  high: 5,
  info: 0,
  low: 0.5,
  medium: 2.7,
};
function getMaxValueKey(risks) {
  return Object.keys(risks).reduce((a, b) =>
    risks[a] < risks[b] && risks[a] > 0 ? a : b
  );
}

const RiskBarChart = ({ risks = {} }) => {
  const maxValue = Math.max(...riskKeys.map((key) => risks[key] || 0));
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "6.5px",
      }}
    >
      <RiskBar
        maxRisk={maxValue}
        label="High"
        risk={risks.high}
        color="#D32F2F"
      />
      <RiskBar
        maxRisk={maxValue}
        label="Medium"
        risk={risks.medium}
        color="#ED6C02"
      />
      <RiskBar
        maxRisk={maxValue}
        label="Low"
        risk={risks.low}
        color="#2E7D32"
      />
      <RiskBar
        maxRisk={maxValue}
        label="Info"
        risk={risks.info}
        color="#0288D1"
      />
    </div>
  );
};

const statusRisk = {
  finished: "good",
  "connection error": "high",
  warning: "warning",
};
export const getSectionComponents = ({
  appType,
  goToNextSectionAction,
  current,
  data,
}) => {
  const risk = getMaxValueKey(data?.scan_info?.output_summary || {});
  const COMPONENTS = {
    [SECTION_ID.landing]: (
      <Page
        key={SECTIONS_CONTENT.landing.key}
        footerBackgroundColor={SECTIONS_CONTENT.landing.backgroundColor}
        className="blue-background"
        footerText={<div className="privacy-tech">Check the findings</div>}
        goToNextSectionAction={goToNextSectionAction}
        trimPaddingTop
      >
        {/* this component calculate the values for testScoresItems */}
        <Landing
          current={current}
          content={{
            title: "Scan Information",
            scanned: [
              {
                title: "Start time: ",
                content: data?.scan_info?.start_time,
              },
              {
                title: "Finish time: ",
                content: "2023-08-28 18:47:03 UTC+03",
                content: data?.scan_info?.end_time,
              },
              {
                title: "Scan duration: ",
                content:
                  data?.scan_info?.duration > 0
                    ? `
                    ${
                      parseInt(data?.scan_info?.duration / 3600) > 0
                        ? `${parseInt(data?.scan_info?.duration / 3600)} hrs, `
                        : ""
                    }
                    ${
                      parseInt((data?.scan_info?.duration % 3600) / 60) > 0
                        ? `${parseInt(
                            (data?.scan_info?.duration % 3600) / 60
                          )} min, `
                        : ""
                    }
                    ${
                      data?.scan_info?.duration % 60 > 0
                        ? `${data?.scan_info?.duration % 60} secs`
                        : ""
                    }
                  `
                    : 0,
              },
              {
                title: "Scan Status: ",
                content: (
                  <Severity
                    label={data?.scan_status}
                    bigger
                    severity={
                      statusRisk[data?.scan_status] || statusRisk.warning
                    }
                  />
                ),
              },
            ],
            charts: [
              {
                title: "Overall risk level:",
                content: (
                  <div
                    className="score"
                    style={{
                      width: "100%",
                      justifyContent: "flex-start",
                      gap: "20px",
                      height: "auto",
                    }}
                  >
                    <GaugeChart value={RiskValues[risk]} />
                    <div className="description">
                      <span className="title">{risk} risk</span>
                    </div>
                  </div>
                ),
              },
              // {
              //   title: "Tests performed:",
              //   content: (
              //     <svg width="162" height="81" viewBox="0 0 162 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              //       <path d="M0.357544 73.2968C4.23848 32.1768 38.8605 0 80.9964 0C125.737 0 162 36.2618 162 81H134.053C134.053 51.6973 110.3 27.9459 80.9964 27.9459C53.56 27.9459 30.9892 48.7722 28.2244 75.4779L0.357544 73.2968Z" fill="#407BFF"/>
              //       <path d="M28.2316 75.4196C28.042 77.2359 27.9471 79.0742 27.9471 80.9416H0C0 78.3447 0.124015 75.7697 0.36475 73.2385L28.2389 75.4269L28.2316 75.4196Z" fill="#407BFF"/>
              //     </svg>
              //   ),
              // },
              {
                title: "Risk ratings:",
                content: (
                  <RiskBarChart risks={data?.scan_info?.output_summary} />
                ),
              },
            ],
            resource: data.resource,
          }}
        />
      </Page>
    ),
  };
  console.log("ELLL", data);
  const textSecurity = [];
  data?.scan_output?.output_json?.forEach((scan) => {
    if (scan.vuln_evidence.type === "table") {
      COMPONENTS[scan?.name.replaceAll(" ", "_")] = (
        <MissingSecurityFlag
          data={scan}
          goToNextSectionAction={goToNextSectionAction}
        />
      );
    } else {
      textSecurity.push(scan.name);
    }
  });

  if (textSecurity.length) {
    COMPONENTS["text"] = (
      <TextFindingSection
        settings={textSecurity}
        goToNextSectionAction={goToNextSectionAction}
      />
    );
  }
  COMPONENTS[SECTION_ID.scanCoverageInformation] = (
    <ScanCoverageInformation
      data={data}
      goToNextSectionAction={goToNextSectionAction}
    />
  );

  console.log({ COMPONENTS, t: Object.entries(COMPONENTS).map((el) => el[1]) });
  return Object.entries(COMPONENTS).map((el) => el[1]);
};

export default getSectionComponents;
