import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  trackers: {},
  table: {
    marginTop: '10px',
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  xsTable: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mdTable: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  tr: {
    display: "flex",
    color: "#868993",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
    borderBottom: "1px solid #E0E1E3",
  },
  th: {
    padding: theme.spacing(0, 1),
  },
  domain: {
    width: "25%",
    "&.app": {
      width: "30%",
    },
  },
  provider: {
    width: "15%",
    "&.app": {
      width: "30%",
    },
  },
  categories: {
    width: "35%",
    "&.app": {
      width: "40%",
    },
  },
  tags: {
    width: "25%",
  },
  root: {
    height: 15,
    borderRadius: 5,
    marginBottom: theme.spacing(0.5),
    backgroundColor: 'inherit', 
  },
  progressBarColorPrimary: {
    backgroundColor: 'inherit', 
  },
  progressBarContainer: {
    position: 'relative',
    width: '100%',
  },
  progressBarText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'left',
    top: 0,
    fontWeight: 'bold',
    paddingLeft: '5px',
    paddingBottom: '3px',
    fontSize: 12,
  },
  card: {
    width: "300px",
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "12px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
  },
  cardText: {
    ...theme.typography.h1,
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  cardTitle: {
    ...theme.typography.h2,
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "1px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  },
  cardTr: {
    paddingLeft: "2px",
    display: "flex",
    color: "#868993",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
  },
  cardTd: {
    paddingRight: "15px"
  },
  referenceBarContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '5px',
  },
  referenceContainer: {
    display: "flex",
    alignItems: "center",
  },
  referenceText: {
    fontSize: 14,
  },
  referenceSquareGreen: {
    width: 12,
    height: 12,
    backgroundColor: "#468C49",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: "3px",
    marginRight: theme.spacing(1),
  },
  referenceSquareYellow: {
    width: 12,
    height: 12,
    backgroundColor: "#CBAD0F",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: "3px",
    marginRight: theme.spacing(1),
  },
  referenceSquareOrange: {
    width: 12,
    height: 12,
    backgroundColor: "#EF7D1F",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: "3px",
    marginRight: theme.spacing(1),
  },
  referenceSquareRed: {
    width: 12,
    height: 12,
    backgroundColor: "#D32F2F",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: "3px",
    marginRight: theme.spacing(1),
  },
  gridTr: {
    marginBottom: "-8px"
  },
  gridTd: {
    paddingRight: "15px"
  },
}));

export default styles;
