import CONSTANTS from "redux/_datasources/datasources.constants";

export const requestStart = () => ({
  type: CONSTANTS.DATASOURCES_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.DATASOURCES_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.DATASOURCES_SAVE_DATA,
  data,
});

export const updateData = (data) => ({
  type: CONSTANTS.DATASOURCES_UPDATE_DATA,
  data,
});

export const deleteData = (data) => ({
  type: CONSTANTS.DATASOURCES_DELETE_DATA,
  data,
});
