import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";

const TrackersSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.trackers.key}
      footerBackgroundColor={SECTIONS_CONTENT.trackers.backgroundColor}
      footerText={
        <div className="privacy-tech">
          {/* Check the test scores in detail */}
        </div>
      }
      sidebar={
        <SideBar
          title={SECTIONS_CONTENT.trackers.title}
          description={SECTIONS_CONTENT.trackers.description}
        />
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Table
        data={data}
        limitPerPage={5}
        showEnumColumn
        columns={[
          {
            key: "tracker",
            label: "Tracker",
            width: "25%",
          },
          {
            key: "categories",
            label: "Categories",
            width: "25%",
          },
          {
            key: "url",
            label: "URL",
            width: "50%",
          },
        ]}
      />
    </Page>
  );
};

export default TrackersSection;
