import React, { useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import WebHookDetails from "./components/WebHookDetails";
import WebHookBranchListDisplay from "./components/WebHookBranchListDisplay";
import { useRepositoryWebHook } from "../../../../hooks/useRepositoryWebHook";
import ScanButton from "../components/ScanButton";
import { useHistory } from "react-router-dom";

const FTU_LEARN_MORE = "/zen/ftu/WEBHOOK-FAQS";

const WebHookDetailView = () => {
  const history = useHistory();
  const handleHowItWorks = useCallback(
    (event) => {
      history.push(FTU_LEARN_MORE);
    },
    [history]
  );

  return (
    <Box
      style={{
        paddingTop: 24,
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        <Typography
          style={{ color: "#000000", fontSize: 20, fontFamily: "Inter" }}
        >
          Trigger automatic scans with a custom webhook
        </Typography>
        <Typography
          style={{ fontFamily: "Inter", fontSize: 16, color: "#868993" }}
        >
          Scan your repository to ensure no sensitive information is exposed,
          and you have a comprehensive understanding of all third-party
          libraries, generating a report.
        </Typography>
      </Box>
      <ScanButton
        settings={{
          label: "HOW IT WORKS",
          backColor: "#1C3687",
          labelColor: "#FFFFFF",
        }}
        style={{
          width: "fit-content",
          textAllign: "right",
          alignSelf: "flex-end",
        }}
        handleClick={handleHowItWorks}
      />
      <WebHookDetails />
    </Box>
  );
};

export default WebHookDetailView;
