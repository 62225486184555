const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  subtitleContainer: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subtitle: {
    flex: "auto",
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "400",
    letterSpacing: "1px",
    margin: 0,
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.up("xs")]: {
      fontSize: "24px",
    },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: "40px",
    // },
  },
  icon: {
    alignSelf: "center",
    marginRight: "7px",
  },
}));

export default useStyles;
