import React from "react";

const Shield = ({
  fill = "#6FCF97",
  opacity = 1,
  className,
  width = 144,
  height = 160,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 144 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
    className={className}
  >
    <path
      d="M71.6337 0.023469C70.9818 0.0894831 70.3633 0.348441 69.8547 0.76828C50.3441 17.1733 27.7709 29.734 3.08721 37.0512C2.45211 37.2354 1.88476 37.6065 1.45648 38.1176C1.02821 38.6288 0.758143 39.2573 0.680233 39.9241C0.22582 43.8653 0 47.8771 0 51.9475C0 98.1576 29.5959 148.683 71.1628 159.892C71.7119 160.036 72.2881 160.036 72.8372 159.892C114.404 148.683 144 98.1576 144 51.9475C144 47.8771 143.774 43.8653 143.32 39.9241C143.242 39.2573 142.972 38.6288 142.544 38.1176C142.115 37.6065 141.548 37.2354 140.913 37.0512C116.229 29.734 93.6558 17.1733 74.1453 0.76828C73.7973 0.479127 73.3955 0.264169 72.964 0.136209C72.5325 0.00824924 72.0801 -0.0300931 71.6337 0.023469ZM72 7.68438C91.0867 23.3045 112.945 35.3927 136.779 42.7969C137.063 45.8165 137.302 48.8472 137.302 51.9475C137.302 94.8208 108.988 142.507 72 153.082C35.0115 142.507 6.69767 94.8208 6.69767 51.9475C6.69767 48.8471 6.93687 45.8164 7.22093 42.7969C31.0552 35.3927 52.9133 23.3045 72 7.68438Z"
      fill={fill}
    />
  </svg>
);

export default Shield;
