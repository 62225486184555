import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./root-reducer";

const middlewares = [thunkMiddleware];

if (
  process.env.NODE_ENV === `development` &&
  process.env.REACT_APP_REDUX_LOGGER
) {
  const { createLogger } = require(`redux-logger`);
  const loggerMiddleware = createLogger();

  middlewares.push(loggerMiddleware);
}

const initialState = {};

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
);
