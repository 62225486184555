import React from "react";

const closeIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="2.1756"
      height="26.1072"
      rx="1.0878"
      transform="matrix(0.707107 -0.707106 0.707107 0.707106 0 1.53838)"
      fill="#7090D9"
    />
    <rect
      x="18.4609"
      y="0.000991821"
      width="2.1756"
      height="26.1073"
      rx="1.0878"
      transform="rotate(45 18.4609 0.000991821)"
      fill="#7090D9"
    />
  </svg>
);

export default closeIcon;
