export const DOES_NOT_CONSENT_MANAGER =
  "We couldn't detect any Consent Manager.";
export const DOES_NOT_HAVE_COOKIES_MESSAGE =
  "We couldn't detect any cookie message on first load.";
export const DOES_NOT_HAVE_PRIVACY_POLICY =
  "We couldn't find a link to the Privacy Policy page.";
export const DOES_NOT_HAVE_TERMS =
  "We couldn't find a link to the Terms and Conditions page.";
export const HAS_CONSENT_MANAGER = "A Consent Manager was detected.";
export const HAS_COOKIE_MESSAGE = "We detected a cookie message on first load.";
export const HAS_PRIVACY_POLICY = "We found a link to the Privacy Policy page.";
export const HAS_TERMS = "We found a link to the Terms and Conditions page.";
