import { Image, Path, Svg, Text, View, } from "@react-pdf/renderer";
import ScoreChart from "components/PrivacyReports/Report/components/ScoreChart";
import TestTubeChart from "components/PrivacyReports/Report/components/TestTubeChart";
import gaugeLow from "./gauge_1.png";
import gaugeMidLow from "./gauge_3.png";
import gaugeMid from "./gauge_5.png";
import gaugeMidHigh from "./gauge_7.png";
import gaugeHigh from "./gauge_9.png";
import moment from "moment";
import styles from "./styles";
import CustomPage from "../commons/Page";
import Box from "../commons/Box";

const InfoIcon = (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" fill="#ffffff">
    <Path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></Path>
  </Svg>
);

const getGaugeFor = (x) => {
  if (x < 0.75) return gaugeLow;
  if (x < 2.25) return gaugeMidLow;
  if (x < 2.75) return gaugeMid;
  if (x < 3.75) return gaugeMidHigh;
  return gaugeHigh;
};

const SummaryPage = ({
  logo,
  risk,
  screenshot,
  styles: globalStyles,
  testPassed,
  totalScore,
  totalTests,
}) => (
  <>
    <View>
      <Text>Here's an overview of our assessment of your website.</Text>
      <View style={globalStyles.center}>
        <Image style={styles.screenshot} src={screenshot} />
      </View>
    </View>
    <View
      style={{
        ...globalStyles.row,
        ...styles.scoreCharts,
      }}
    >
      <View style={{ ...globalStyles.box33, ...globalStyles.center }}>
        <ScoreChart value={totalScore} pdf />
      </View>
      <View style={{ ...globalStyles.box33, ...globalStyles.center }}>
        <TestTubeChart
          score={testPassed}
          total={totalTests}
          environment={"pdf"}
        />
      </View>
      <View style={{ ...globalStyles.box33, ...globalStyles.center }}>
        <Image style={styles.gauge} src={getGaugeFor(risk.value)} />
      </View>
    </View>
    <View
      style={{ ...globalStyles.row, ...styles.scores }}
    >
      <View
        style={{
          ...globalStyles.box33,
          ...globalStyles.center,
        }}
      >
        <Text style={globalStyles.subTitle}>Total Score</Text>
        <Text>{totalScore}/100</Text>
      </View>
      <View style={{ ...globalStyles.box33, ...globalStyles.center }}>
        <Text style={globalStyles.subTitle}>Tests Passed</Text>
        <Text>
          {testPassed}/{totalTests}
        </Text>
      </View>
      <View style={{ ...globalStyles.box33, ...globalStyles.center }}>
        <Text style={globalStyles.subTitle}>{risk.name}</Text>
        <Text>{risk.value}/5</Text>
      </View>
    </View>
    <Box
      title="How to interpret your Privacy Score?"
      styles={{ container: { marginTop: 10 }}}
      icon={InfoIcon}
    >
      <Text style={{ fontSize: 10, marginTop: 10 }}>
        Our goal is to make privacy risk clear and easy. We represent privacy risk in two ways: First,on a hundred-point scale and second in terms of a risk assessment (High, Medium, Low).

        We calculate and present this information on your dashboard's landing page and update it every time your scheduled report runs. Each score and assessment is dynamic and changes with the updates to your website/apps, so it captures new fixes, bugs, and gaps to prevent regulatory action and minimize financial exposure.

        Each Privacy Score and Risk gives you an instant privacy level ranking.
      </Text>
    </Box>
  </>
);

export default SummaryPage;
