import { CONSTANTS, DATA_TYPES } from "redux/_reports/reports.constants";

const initialState = {
  loading: false,
  reports: {
    data: [],
    meta: {},
  },
  jsons: {
    loading: false,
    current: {},
    cached: {},
  },
  report: {
    data: {},
  },
  consumerReport: {
    data: {},
  },
  scheduled: {
    data: [],
    meta: {},
  },
  hrefs: [],
};

export const hrefReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_HREF":
      return { ...state, hrefs: [...state.hrefs, action.payload] };
    default:
      return state;
  }
};

export const reports = (state = initialState, { type, data, dataType, id }) => {
  switch (type) {
    case CONSTANTS.REPORTS_REQUEST_START: {
      const jsons =
        dataType === DATA_TYPES.jsons
          ? { ...state.jsons, loading: true }
          : { ...state.jsons };
      return {
        ...state,
        loading: true,
        reports: {
          ...state.reports,
        },
        report: {
          ...state.report,
        },
        jsons,
      };
    }
    case CONSTANTS.REPORTS_REQUEST_END: {
      return {
        ...state,
        loading: false,
        jsons:
          dataType === DATA_TYPES.jsons
            ? { ...state.jsons, loading: false }
            : { ...state.jsons },
      };
    }
    case CONSTANTS.REPORTS_SAVE_REPORTS_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: [data, ...state.reports.data],
        },
      };
    case CONSTANTS.REPORTS_SAVE_DATA:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          data,
        },
      };
    case CONSTANTS.REPORTS_SAVE_JSON:
      return {
        ...state,
        jsons: {
          loading: false,
          current: data,
          cached: {
            ...state.jsons.cached,
            [data.id]: data,
          },
        },
      };
    case CONSTANTS.REPORTS_SET_META:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          meta: data,
        },
      };
    case CONSTANTS.REPORTS_SET_ERROR:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
        },
      };
    case CONSTANTS.REPORTS_RESET_DATA:
      return {
        ...state,
        [dataType]: initialState[dataType],
      };
    case CONSTANTS.REPORTS_SCHEDULE_SAVE:
      let received;
      if (id) {
        received = state.scheduled.data.map((schedule) => {
          if (schedule.id === id) {
            return data;
          }

          return schedule;
        });
      } else {
        received = [data, ...state.scheduled.data];
      }
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          data: received,
        },
      };
    case CONSTANTS.REPORTS_SCHEDULE_LIST:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          data: data,
        },
      };
    case CONSTANTS.REPORTS_SCHEDULE_DELETE:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          data: state.scheduled.data.filter((schedule) => schedule.id !== data),
        },
      };
    case CONSTANTS.REPORTS_SINGLE_RUN_DELETE:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: state.reports.data.filter((report) => report.pk !== data),
        },
      };
    default:
      return state;
  }
};
