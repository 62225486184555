import { makeStyles } from "@material-ui/core";

import { HEADER_HEIGHT } from "../../constants";

export default makeStyles((theme) => {
  const SUM_OF_ALL_SECTION_SPACING = 210;
  return {
    chart: {
      height: `calc(100vh - ${HEADER_HEIGHT + SUM_OF_ALL_SECTION_SPACING}px)`,
      maxHeight: 650,
      minHeight: 200,
    },
    notFound: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: 370,
      "& h3": {
        fontSize: 25,
      },
    },
    paragraph: { maxWidth: 740 },
    siteUrl: {
      display: "block",
      margin: "22px auto",
      padding: "2px 7px",
      maxWidth: 450,
      width: "90%",
    },
    reSubmitForm: {
      textAlign: "center",
    },
    reSubmitTarea: {
      width: "100%",
      height: "50%",
      margin: "15px 0 10px 0",
    },
  };
});
