/**
 *
 * @method getProperty
 * @static
 * @param {Object} obj Value to get from
 * @param {String|Array} keyName Path-like property accessor
 */
export default function getProperty(obj, keyName) {
  let type = typeof obj;

  let isObject = type === "object";
  let isFunction = type === "function";
  let isObjectLike = isObject || isFunction;

  if (isPath(keyName)) {
    return isObjectLike ? _getPath(obj, keyName) : undefined;
  }

  return obj[keyName];
}

function _getPath(obj, path) {
  let parts = typeof path === "string" ? path.split(".") : path;

  for (let i = 0; i < parts.length; i++) {
    if (obj === undefined || obj === null) {
      return undefined;
    }

    obj = getProperty(obj, parts[i]);
  }

  return obj;
}

function isPath(path) {
  return (
    Array.isArray(path) ||
    (typeof path === "string" && path.indexOf(".") !== -1)
  );
}
