import { useEffect, useState } from "react";
import ScanList, { SCAN_TYPE } from "components/ScanList";
import { connect } from "react-redux";
import { deleteReport, getReports } from "redux/_reports/reports.async.actions";
import {
  getReportsData as getSingleRunScans,
  getReportsMeta as getSingleRunScansMeta,
} from "redux/_reports/reports.selectors";
import Loader from "components/Loader";

const SingleRunRepositoryScansList = ({
  goToSection,
  singleRunScans,
  singleRunScansMeta,
  fetchSingleRunScans,
  removeSingleRunScan,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const filteredData = singleRunScans.filter(
    (item) => item.environment === "repository"
  );

  useEffect(() => {
    let isDataLoaded = true;
    const timer = setTimeout(() => {
      if (!isDataLoaded) {
        setIsLoading(false);
      }
    }, 2000);
    if (filteredData && filteredData.length > 0) setIsLoading(false);

    return () => {
      clearTimeout(timer);
    };
  }, [filteredData]);

  const scanListProps = {
    cta: "Create a new scan",
    emptyNotice: "You don't have any single-run scans yet.",
    headers: [
      { label: "URL", className: "appName" },
      { label: "Status", className: "status" },
      { label: "CSV File", className: "actions" },
      { label: "Actions", className: "actions" },
    ],
    goToSection,
    deleteItem: removeSingleRunScan,
    data: filteredData,
    getItems: fetchSingleRunScans,
    scanType: SCAN_TYPE.singleRun,
    totalPages: singleRunScansMeta?.totalPageCount || 0,
    deviceType: "repository"
  };

  return isLoading ? <Loader /> : <ScanList {...scanListProps} />;
};

const mapStateToProps = (state) => ({
  singleRunScans: getSingleRunScans(state),
  singleRunScansMeta: getSingleRunScansMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  removeSingleRunScan: (id) => dispatch(deleteReport(id)),
  fetchSingleRunScans: (filters) => dispatch(getReports(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleRunRepositoryScansList);