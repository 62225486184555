import isArray from "lodash.isarray";

import { storeSession } from "utils/auth";

import {
  requestStart,
  requestEnd,
  setError,
  setPlans,
} from "redux/_register/_register.actions";
import {
  register as doRegister,
  fetchPlans,
} from "redux/_register/_register.service";

export const registerSuccess = (dispatch, session, planId) => {
  dispatch(requestEnd());
  storeSession(session, dispatch, planId);
};
export const register = (data, planId) => (dispatch) => {
  dispatch(requestStart());
  return doRegister(data)
    .then((session) => {
      const { email } = data;
      if (global.gtag) global.gtag("event", "registration", { email });
      registerSuccess(dispatch, session, planId);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      const { error } = errors;
      const errorList = error && !isArray(errors) ? error : errors;
      dispatch(setError(errorList.length > 0 ? errorList[0] : true));
      return Promise.reject(error);
    });
};

export const retrievePlans = () => (dispatch, getState) => {
  dispatch(requestStart());
  return fetchPlans()
    .then((received) => {
      dispatch(setPlans(received.data.results));
      dispatch(requestEnd());
      return Promise.resolve(received);
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
