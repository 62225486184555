import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";

export const CardsContainer = ({
  container,
  spacing = 4,
  children,
  isEmpty,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container={container}
      spacing={spacing}
      className={clsx(classes.cardContainer)}
    >
      {!isEmpty ? (
        children
      ) : (
        <Box py={3} textAlign="center" width="100%">
          <Typography>No reports were found.</Typography>
        </Box>
      )}
      {}
    </Grid>
  );
};
