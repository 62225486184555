import React from "react";
import MUITooltip from "@material-ui/core/Tooltip";
import Tooltip from "./Tooltip";
import { makeStyles } from "@material-ui/core";

import {
  BOX_WIDTH,
  BOX_WIDTH_WIDER,
  BOX_HEIGHT,
  BOX_BOTTOM_EXT,
  BOX_RIGHT_EXT,
  TEXT_BOX_LENGTH,
  TEXT_BOX_LENGTH_WIDER,
} from "./consts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    padding: theme.spacing(2),
    borderRadius: "10px",
    border: `solid 1px ${theme.palette.grey[500]}`,
    minWidth: "205px",
    minHeight: "320px",
  },
}));

const isColumn = (node) => node.data.type === "column";

const shorten = (text, max) => {
  return text && text.length > max
    ? text.slice(0, max) // .split(" ").slice(0, -1).join(" ")
    : text;
};

const SvgText = ({ dy = 0, text, textAnchor = null, x = 0 }) => (
  <text
    fill="#686c78"
    x={x}
    dy={dy}
    fontSize={14}
    fontFamily="Roboto Condensed"
    textAnchor={textAnchor}
    style={{ transform: `rotate(90deg)` }}
  >
    {text}
  </text>
);

const formattedText = (text, textWidth) => {
  const textCleaned = text
    // .slice(0, 20)
    .replace(/(ds-|db-|\d+)/gi, "")
    .replace(/(_|-)/g, " ")
    .toLowerCase();

  if (textCleaned.length >= textWidth) {
    const firstLine = shorten(textCleaned, textWidth);
    const secondLine = shorten(
      textCleaned.slice(firstLine.length + 1),
      textWidth
    );

    return (
      <>
        <SvgText dy="-23" text={firstLine} />
        <SvgText dy="-7" text={secondLine} />
      </>
    );
  }

  return <SvgText dy="-16" text={textCleaned} />;
};

const Rectangle = (props) => {
  const { node, onClick, draw, wider } = props;
  const boxWidth = wider ? BOX_WIDTH_WIDER : BOX_WIDTH;

  const classes = useStyles();

  const onRectangleClick = () => {
    if (onClick) onClick(node);
  };

  if (!draw) return null;

  const BaseRectangle = (
    <g
      transform={`translate(${node.x}, ${node.y})`}
      onClick={onRectangleClick}
      style={{
        opacity: node.opacity,
        cursor: "pointer",
      }}
    >
      <rect
        fill={node.color}
        width={BOX_HEIGHT + BOX_RIGHT_EXT}
        height={boxWidth + (node.selected ? BOX_BOTTOM_EXT : 0)}
        rx="4"
        ry="4"
        x={-BOX_RIGHT_EXT}
        stroke={node.color}
      />
      <g
        fill="#0E1D60"
        transform={`rotate(90) translate(${
          node.selected ? boxWidth + 2 : boxWidth - 22
        } -22)`}
      >
        {node.selected && (
          <>
            <path d="M18 6H12V7H18V6Z" />
            <path d="M16.4308 8H12V9H16.4308V8Z" />
            <path d="M17 10H12V11H17V10Z" />
            <path d="M15 12H12V13H15V12Z" />
            <path d="M8.807 9.75719C9.1713 9.33326 9.40324 8.81771 9.47567 8.2709C9.5481 7.72409 9.45803 7.16863 9.21599 6.66953C8.97396 6.17044 8.58997 5.74836 8.10898 5.45269C7.62799 5.15702 7.06989 5 6.5 5C5.93011 5 5.37201 5.15702 4.89102 5.45269C4.41003 5.74836 4.02604 6.17044 3.78401 6.66953C3.54197 7.16863 3.4519 7.72409 3.52433 8.2709C3.59676 8.81771 3.8287 9.33326 4.193 9.75719C3.56272 9.92748 3.00729 10.2924 2.61137 10.7965C2.21544 11.3005 2.00075 11.9159 2 12.549V14H11V12.549C10.9993 11.9159 10.7846 11.3005 10.3886 10.7965C9.99271 10.2924 9.43728 9.92748 8.807 9.75719V9.75719ZM6.5 6.16445C6.85601 6.16445 7.20402 6.26657 7.50003 6.4579C7.79603 6.64923 8.02675 6.92117 8.16298 7.23934C8.29922 7.55751 8.33487 7.90761 8.26541 8.24538C8.19596 8.58315 8.02453 8.8934 7.77279 9.13692C7.52106 9.38044 7.20033 9.54627 6.85116 9.61346C6.502 9.68064 6.14008 9.64616 5.81117 9.51437C5.48226 9.38258 5.20114 9.1594 5.00335 8.87306C4.80557 8.58671 4.7 8.25006 4.7 7.90568C4.7 7.44388 4.88964 7.00099 5.22721 6.67444C5.56477 6.3479 6.02261 6.16445 6.5 6.16445V6.16445ZM9.8 12.8392H3.2V12.549C3.2 12.0872 3.38964 11.6443 3.72721 11.3177C4.06477 10.9912 4.52261 10.8077 5 10.8077H8C8.47739 10.8077 8.93523 10.9912 9.27279 11.3177C9.61036 11.6443 9.8 12.0872 9.8 12.549V12.8392Z" />
          </>
        )}
      </g>
      <rect
        fill={"white"}
        width={BOX_HEIGHT}
        height={boxWidth}
        stroke={node.color}
      />
      <svg y={10} x={0} width={BOX_HEIGHT} height={boxWidth}>
        {formattedText(
          node.data.name,
          wider ? TEXT_BOX_LENGTH_WIDER : TEXT_BOX_LENGTH
        )}
        {!wider && (
          <SvgText
            dy="-16"
            x={boxWidth - 20}
            textAnchor="end"
            text={`${Math.round(node.percentage * 100)}%`}
          />
        )}
      </svg>
    </g>
  );

  return isColumn(node) ? (
    <MUITooltip
      title={<Tooltip data={node.data} />}
      placement="right"
      classes={{ tooltip: classes.root, touch: classes.touch }}
    >
      {BaseRectangle}
    </MUITooltip>
  ) : (
    BaseRectangle
  );
};

export default Rectangle;
