import { CONSTANTS } from "redux/_consents/_policies/policies.constants";

export const requestStart = () => ({
  type: CONSTANTS.POLICIES_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.POLICIES_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.POLICIES_SAVE_DATA,
  data,
});

export const deleteData = (data) => ({
  type: CONSTANTS.POLICIES_DELETE,
  data,
});
