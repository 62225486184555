import React from "react";
import { Form, Field } from "react-final-form";

import {
  TextField,
  Collapse,
  Box,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import Checkbox from "components/PrivacyReports/Scanner/components/checkbox";
import ScannerAdvancedSettings from "../ScannerAdvancedSettings";

import useStyles from "./styles";

const ScannerForm = ({
  onSubmit,
  loading,
  title,
  description,
  children,
  serverError,
}) => {
  const classes = useStyles();

  const requiredEmail = (value, values) =>
    (values.send_to_email || values.subscribe) && !value
      ? "Please enter your email."
      : undefined;
  const validateSite = (value = "") =>
    !value
      ? "Please complete this field."
      : !(value.startsWith("http://") || value.startsWith("https://"))
      ? "The site must start with http:// or https://"
      : undefined;

  const sube = (data) => {
    console.log(data, onSubmit);
  };
  return (
    <Form onSubmit={sube}>
      {({ handleSubmit, values }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12} md={6}>
              <Field
                name="site"
                validate={validateSite}
                render={({
                  input: { value, onChange },
                  meta: { touched, error, submitError },
                }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    className={classes.headerTextField}
                    variant="outlined"
                    placeholder="Enter your site to start"
                    disabled={loading}
                    helperText={
                      touched &&
                      (error || submitError) && (
                        <span style={{ color: "red" }}>
                          {error || submitError}
                        </span>
                      )
                    }
                    InputProps={{
                      error: Boolean(touched && error),
                      className: classes.headerInputBase,
                      classes: {
                        notchedOutline: classes.headerInputOutline,
                      },
                    }}
                    inputProps={{
                      className: classes.headerInput,
                    }}
                  />
                )}
              />
              <Field
                name="send_to_email"
                type="checkbox"
                render={({ input: { value, onChange } }) => (
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    disabled={loading}
                    label="I would like to have the report sent to my email."
                    color="primary"
                    classes={checkboxStyles}
                    optional="(Opt.)"
                  />
                )}
              />

              <Field
                name="subscribe"
                type="checkbox"
                render={({ input: { value, onChange } }) => (
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    disabled={loading}
                    color="primary"
                    label="I would like to receive Privacy-related News in my inbox."
                    optional="(Opt.)"
                    classes={checkboxStyles}
                  />
                )}
              />
              <Collapse in={values.send_to_email || values.subscribe}>
                <Field
                  name="email"
                  validate={requiredEmail}
                  render={({
                    input: { value, onChange },
                    meta: { touched, error },
                  }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      className={classes.headerTextField}
                      variant="outlined"
                      placeholder="Enter your email"
                      disabled={loading}
                      helperText={
                        touched &&
                        error && <span style={{ color: "red" }}>{error}</span>
                      }
                      InputProps={{
                        error: Boolean(touched && error),
                        className: classes.headerInputBase,
                        classes: {
                          notchedOutline: classes.headerInputOutline,
                        },
                      }}
                      inputProps={{
                        className: classes.headerInput,
                      }}
                    />
                  )}
                />
              </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
              <ScannerAdvancedSettings />
              <Box className={classes.submitBox}>
                <Button
                  disabled={loading}
                  type="submit"
                  className={classes.submit2}
                >
                  Scan Site
                  {loading && (
                    <CircularProgress
                      className={classes.buttonLoader}
                      color="primary"
                      size={24}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {children}
        </Box>
      )}
    </Form>
  );
};

export default ScannerForm;

const checkboxStyles = {
  labelText: {
    color: "#828282",
  },
  label: {
    marginBottom: 0,
  },
};
