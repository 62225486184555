import React from "react";

export default () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.77629 16.6846V15.9039H4.26959V16.6846H0.77629ZM3.52759 12.7808H1.51797L2.52294 14.1797L3.52759 12.7808ZM5.04588 12L2.52294 15.5135L0 12H5.04588Z"
      fill="#00B3E6"
    />
    <path
      d="M15.8644 18.6412C15.8289 18.6897 15.783 18.7296 15.7302 18.758C15.6769 18.7875 15.617 18.8029 15.5562 18.8025C15.4819 18.8039 15.4088 18.7838 15.3456 18.7446C15.2825 18.7035 15.2325 18.645 15.2017 18.5761C15.1678 18.4953 15.1504 18.4085 15.1504 18.3209C15.1504 18.2332 15.1678 18.1464 15.2017 18.0657C15.2325 17.9965 15.2825 17.9377 15.3456 17.8962C15.4087 17.8557 15.4823 17.8348 15.5571 17.836C15.6168 17.8358 15.6756 17.8505 15.7282 17.8789C15.7823 17.9076 15.8291 17.9482 15.8654 17.9977V17.8588H16.0071V18.7771H15.8654L15.8644 18.6412ZM15.8644 18.1083C15.8352 18.0632 15.7953 18.0261 15.7483 18.0003C15.7004 17.9719 15.646 17.9568 15.5904 17.9564C15.5493 17.9551 15.5084 17.9637 15.4712 17.9815C15.434 17.9994 15.4016 18.0259 15.3766 18.0588C15.325 18.1361 15.2974 18.2271 15.2974 18.3202C15.2974 18.4133 15.325 18.5044 15.3766 18.5816C15.4017 18.6144 15.4342 18.6406 15.4714 18.6582C15.5086 18.6758 15.5494 18.6842 15.5904 18.6828C15.6445 18.6818 15.6976 18.6678 15.7452 18.6421C15.7929 18.6163 15.8337 18.5796 15.8644 18.5348V18.1083Z"
      fill="black"
    />
    <path
      d="M16.7832 17.5079H17.2141C17.3321 17.5057 17.4488 17.5341 17.5527 17.5906C17.648 17.6435 17.7265 17.7224 17.7791 17.8183C17.8334 17.9179 17.8611 18.03 17.8594 18.1436C17.8613 18.2572 17.8337 18.3694 17.7791 18.4689C17.7262 18.5642 17.6478 18.6425 17.5527 18.695C17.4488 18.7516 17.3321 18.7801 17.2141 18.7777H16.7832V17.5079ZM17.2141 18.6466C17.3048 18.6488 17.3944 18.6256 17.4728 18.5796C17.5447 18.5362 17.6027 18.4731 17.64 18.3977C17.6792 18.3185 17.699 18.2311 17.6979 18.1426C17.6992 18.0538 17.6797 17.9659 17.641 17.886C17.6042 17.8105 17.5465 17.7473 17.4748 17.7041C17.3957 17.6576 17.3053 17.6343 17.2137 17.6368H16.9398V18.6453L17.2141 18.6466Z"
      fill="black"
    />
    <path
      d="M18.0859 17.8587H18.2276V18.0071C18.2653 17.9577 18.3118 17.9157 18.3648 17.8834C18.4151 17.8524 18.4729 17.8359 18.532 17.8359V17.9709C18.5134 17.9671 18.4944 17.9652 18.4754 17.9654C18.4253 17.9676 18.3768 17.9837 18.3353 18.0119C18.2914 18.0365 18.2544 18.0717 18.2276 18.1144V18.7771H18.0859V17.8587Z"
      fill="black"
    />
    <path
      d="M18.8203 18.7371C18.7526 18.6944 18.698 18.6339 18.6624 18.562C18.6256 18.486 18.6064 18.4026 18.6064 18.3181C18.6064 18.2335 18.6256 18.1501 18.6624 18.0741C18.6979 18.0023 18.7525 17.9419 18.8203 17.8997C18.8928 17.8579 18.9749 17.8359 19.0585 17.8359C19.1421 17.8359 19.2242 17.8579 19.2967 17.8997C19.3644 17.942 19.419 18.0024 19.4546 18.0741C19.4912 18.1501 19.5102 18.2335 19.5102 18.3181C19.5102 18.4026 19.4912 18.486 19.4546 18.562C19.4252 18.6163 19.3853 18.6641 19.3372 18.7025C19.289 18.7409 19.2337 18.7691 19.1745 18.7854C19.1152 18.8018 19.0533 18.8059 18.9925 18.7976C18.9316 18.7893 18.873 18.7687 18.8203 18.7371ZM19.221 18.6326C19.2671 18.5984 19.303 18.5522 19.3248 18.4989C19.3491 18.4418 19.3613 18.3802 19.3608 18.3181C19.3614 18.2561 19.3491 18.1947 19.3248 18.1378C19.3027 18.0851 19.2669 18.0393 19.221 18.0054C19.1738 17.9718 19.1171 17.9543 19.0593 17.9556C19.0015 17.9546 18.9449 17.972 18.8976 18.0054C18.8511 18.039 18.8147 18.0848 18.7924 18.1378C18.7682 18.1947 18.7559 18.2561 18.7565 18.3181C18.756 18.3802 18.7682 18.4418 18.7924 18.4989C18.8144 18.5525 18.8508 18.5988 18.8976 18.6326C18.945 18.6658 19.0016 18.6831 19.0593 18.6821C19.1171 18.6834 19.1737 18.666 19.221 18.6326V18.6326Z"
      fill="black"
    />
    <path
      d="M19.7168 19.1275V17.8588H19.8585V17.9957C19.8929 17.9472 19.9382 17.9075 19.9908 17.8799C20.0448 17.8507 20.1053 17.8356 20.1667 17.836C20.2413 17.8347 20.3147 17.855 20.3783 17.8942C20.4415 17.9347 20.4915 17.993 20.5222 18.0618C20.5557 18.143 20.573 18.2301 20.573 18.3181C20.573 18.4061 20.5557 18.4932 20.5222 18.5745C20.4921 18.6437 20.4422 18.7023 20.3788 18.7429C20.3154 18.7834 20.2415 18.8041 20.1664 18.8022C20.1062 18.8027 20.0468 18.7884 19.9933 18.7606C19.9393 18.7322 19.893 18.6911 19.8581 18.6408V19.1288L19.7168 19.1275ZM20.3443 18.0562C20.3193 18.0235 20.2869 17.9972 20.2497 17.9796C20.2126 17.9619 20.1719 17.9534 20.1308 17.9547C20.0768 17.9562 20.024 17.9705 19.9766 17.9966C19.9293 18.0227 19.8888 18.0598 19.8585 18.1047V18.5293C19.8865 18.5749 19.926 18.6122 19.973 18.6376C20.0209 18.6658 20.0753 18.6808 20.1308 18.6812C20.1719 18.6824 20.2127 18.6738 20.2498 18.6561C20.287 18.6383 20.3193 18.6119 20.3443 18.579C20.3997 18.5034 20.4278 18.411 20.4239 18.3171C20.4279 18.2236 20.3998 18.1314 20.3443 18.0562V18.0562Z"
      fill="black"
    />
    <path
      d="M20.7835 17.5078H20.9252V17.9958C20.9612 17.9465 21.0078 17.9059 21.0614 17.8771C21.1142 17.8485 21.1734 17.8337 21.2334 17.8341C21.308 17.8328 21.3813 17.8538 21.444 17.8943C21.5069 17.9361 21.5568 17.9948 21.588 18.0638C21.6223 18.1444 21.64 18.2313 21.64 18.319C21.64 18.4067 21.6223 18.4936 21.588 18.5742C21.5578 18.6432 21.5078 18.7016 21.4444 18.7419C21.381 18.7821 21.3071 18.8023 21.2322 18.8C21.1713 18.8003 21.1114 18.785 21.0581 18.7554C21.0053 18.727 20.9594 18.6871 20.9239 18.6386V18.7753H20.7822L20.7835 17.5078ZM21.0397 18.638C21.0877 18.6659 21.1421 18.6807 21.1975 18.6809C21.2389 18.6825 21.2799 18.6741 21.3174 18.6565C21.3549 18.6389 21.3876 18.6126 21.413 18.5798C21.4648 18.5026 21.4925 18.4115 21.4925 18.3184C21.4925 18.2252 21.4648 18.1342 21.413 18.057C21.3878 18.0238 21.3552 17.9971 21.3177 17.9793C21.2802 17.9615 21.239 17.9529 21.1975 17.9545C21.142 17.9548 21.0875 17.9699 21.0397 17.9984C20.9928 18.0237 20.9533 18.061 20.9252 18.1064V18.5329C20.9535 18.5775 20.993 18.6137 21.0397 18.638V18.638Z"
      fill="black"
    />
    <path
      d="M22.0172 18.7371C21.9497 18.6943 21.8951 18.6338 21.8594 18.562C21.8227 18.486 21.8037 18.4026 21.8037 18.3181C21.8037 18.2335 21.8227 18.1501 21.8594 18.0741C21.8949 18.0024 21.9495 17.942 22.0172 17.8997C22.0897 17.8579 22.1718 17.8359 22.2554 17.8359C22.339 17.8359 22.4211 17.8579 22.4936 17.8997C22.5613 17.942 22.6159 18.0024 22.6515 18.0741C22.6881 18.1501 22.7071 18.2335 22.7071 18.3181C22.7071 18.4026 22.6881 18.486 22.6515 18.562C22.6222 18.6163 22.5822 18.6641 22.5341 18.7025C22.486 18.7409 22.4306 18.7691 22.3714 18.7854C22.3122 18.8018 22.2503 18.8059 22.1894 18.7976C22.1285 18.7893 22.07 18.7687 22.0172 18.7371ZM22.418 18.6326C22.4641 18.5984 22.5002 18.5522 22.5221 18.4989C22.5463 18.4418 22.5586 18.3802 22.558 18.3181C22.5586 18.2561 22.5464 18.1947 22.5221 18.1378C22.4999 18.0851 22.4639 18.0393 22.418 18.0054C22.3708 17.9718 22.3141 17.9543 22.2562 17.9556C22.1984 17.9546 22.1418 17.972 22.0945 18.0054C22.0482 18.0391 22.012 18.0849 21.9897 18.1378C21.9652 18.1947 21.9528 18.2561 21.9535 18.3181C21.9529 18.3802 21.9652 18.4418 21.9897 18.4989C22.0117 18.5524 22.048 18.5987 22.0945 18.6326C22.1419 18.6658 22.1985 18.6831 22.2562 18.6821C22.314 18.6834 22.3707 18.666 22.418 18.6326V18.6326Z"
      fill="black"
    />
    <path
      d="M23.1968 18.3994L22.9225 18.7758H22.7607L23.1107 18.3044L22.7798 17.8574H22.9441L23.1977 18.2075L23.451 17.8574H23.6153L23.2844 18.3044L23.6363 18.7758H23.4717L23.1968 18.3994Z"
      fill="black"
    />
    <path
      d="M24.5582 18.7172C24.4606 18.6627 24.3801 18.5819 24.3256 18.4839C24.2696 18.3789 24.2402 18.2616 24.2402 18.1424C24.2402 18.0231 24.2696 17.9058 24.3256 17.8008C24.3801 17.7028 24.4606 17.622 24.5582 17.5675C24.6571 17.5122 24.7685 17.4836 24.8816 17.4845C24.9795 17.4822 25.0764 17.5035 25.1643 17.5467C25.2449 17.5884 25.3139 17.6494 25.3655 17.7243L25.2332 17.7985C25.1953 17.7427 25.1445 17.697 25.0851 17.6654C25.0227 17.6313 24.9527 17.6136 24.8816 17.614C24.7957 17.6131 24.7113 17.6363 24.6378 17.681C24.5641 17.7267 24.5043 17.7917 24.4647 17.8691C24.4212 17.9532 24.399 18.0469 24.4 18.1417C24.3989 18.2362 24.4211 18.3296 24.4647 18.4133C24.5046 18.4905 24.5644 18.5554 24.6378 18.6014C24.7112 18.6479 24.7964 18.6724 24.8833 18.672C24.9542 18.6725 25.0241 18.6551 25.0867 18.6215C25.1466 18.5902 25.1975 18.5441 25.2349 18.4875L25.3691 18.5617C25.3135 18.6381 25.2403 18.6998 25.1558 18.7415C25.0713 18.7832 24.978 18.8036 24.8839 18.8011C24.7699 18.8022 24.6576 18.7732 24.5582 18.7172V18.7172Z"
      fill="black"
    />
    <path
      d="M25.7027 18.7371C25.6352 18.6943 25.5806 18.6338 25.5449 18.562C25.5083 18.486 25.4893 18.4026 25.4893 18.3181C25.4893 18.2335 25.5083 18.1501 25.5449 18.0741C25.5804 18.0024 25.6351 17.942 25.7027 17.8997C25.7753 17.8579 25.8574 17.8359 25.941 17.8359C26.0245 17.8359 26.1067 17.8579 26.1792 17.8997C26.2469 17.9419 26.3016 18.0023 26.337 18.0741C26.3739 18.1501 26.393 18.2335 26.393 18.3181C26.393 18.4026 26.3739 18.486 26.337 18.562C26.3077 18.6163 26.2678 18.6641 26.2197 18.7025C26.1715 18.7409 26.1162 18.7691 26.057 18.7854C25.9977 18.8018 25.9358 18.8059 25.8749 18.7976C25.8141 18.7893 25.7555 18.7687 25.7027 18.7371ZM26.1035 18.6326C26.1498 18.5985 26.1858 18.5523 26.2077 18.4989C26.2319 18.4418 26.2441 18.3802 26.2436 18.3181C26.2442 18.2561 26.2319 18.1947 26.2077 18.1378C26.1855 18.085 26.1495 18.0392 26.1035 18.0054C26.0563 17.9717 25.9996 17.9543 25.9418 17.9556C25.8833 17.9542 25.826 17.9716 25.7781 18.0054C25.7318 18.0391 25.6956 18.0849 25.6733 18.1378C25.649 18.1947 25.6368 18.2561 25.6374 18.3181C25.6369 18.3802 25.6491 18.4418 25.6733 18.4989C25.6953 18.5524 25.7316 18.5987 25.7781 18.6326C25.8261 18.6662 25.8834 18.6835 25.9418 18.6821C25.9996 18.6834 26.0562 18.6661 26.1035 18.6326Z"
      fill="black"
    />
    <path
      d="M27.7003 18.1472C27.7033 18.0968 27.6886 18.0469 27.6586 18.0063C27.6428 17.9886 27.6232 17.9748 27.6013 17.9659C27.5794 17.9571 27.5557 17.9534 27.5321 17.9553C27.4837 17.9563 27.4365 17.9709 27.396 17.9976C27.3538 18.0227 27.3176 18.0568 27.2899 18.0974V18.7767H27.1505V18.1472C27.1505 18.0192 27.0944 17.9553 26.9823 17.9553C26.9348 17.9564 26.8885 17.971 26.849 17.9976C26.8066 18.0234 26.7701 18.0581 26.7423 18.0994V18.7767H26.6006V17.8583H26.7426V17.9904C26.7739 17.947 26.8146 17.9113 26.8616 17.886C26.9143 17.8529 26.9751 17.8351 27.0372 17.8346C27.0949 17.8322 27.1517 17.8488 27.199 17.8821C27.2397 17.9127 27.269 17.9563 27.2821 18.0057C27.3142 17.9561 27.3577 17.915 27.4089 17.886C27.4629 17.8525 27.525 17.8347 27.5884 17.8346C27.6232 17.8322 27.6582 17.8372 27.691 17.8493C27.7237 17.8614 27.7536 17.8803 27.7786 17.9048C27.8217 17.9519 27.8433 18.021 27.8433 18.1121V18.7757H27.7003V18.1472Z"
      fill="black"
    />
    <path
      d="M28.0996 19.1275V17.8588H28.2413V17.9957C28.2757 17.9472 28.321 17.9075 28.3736 17.8799C28.4276 17.8507 28.4882 17.8356 28.5495 17.836C28.6241 17.8347 28.6975 17.855 28.7611 17.8942C28.8243 17.9347 28.8744 17.993 28.905 18.0618C28.9385 18.143 28.9558 18.2301 28.9558 18.3181C28.9558 18.4061 28.9385 18.4932 28.905 18.5745C28.8749 18.6437 28.825 18.7023 28.7616 18.7429C28.6982 18.7834 28.6243 18.8041 28.5492 18.8022C28.489 18.8027 28.4296 18.7884 28.3762 18.7606C28.3222 18.7322 28.2758 18.6911 28.241 18.6408V19.1288L28.0996 19.1275ZM28.7271 18.0562C28.7021 18.0235 28.6697 17.9972 28.6326 17.9796C28.5954 17.9619 28.5547 17.9534 28.5136 17.9547C28.4597 17.9562 28.4068 17.9705 28.3594 17.9966C28.3121 18.0227 28.2716 18.0598 28.2413 18.1047V18.5293C28.2693 18.5749 28.3088 18.6122 28.3558 18.6376C28.4037 18.6658 28.4581 18.6808 28.5136 18.6812C28.5547 18.6824 28.5955 18.6738 28.6326 18.6561C28.6698 18.6383 28.7022 18.6119 28.7271 18.579C28.7825 18.5034 28.8106 18.411 28.8067 18.3171C28.8107 18.2236 28.7826 18.1314 28.7271 18.0562V18.0562Z"
      fill="black"
    />
    <path
      d="M29.8367 18.6405C29.8011 18.6889 29.7553 18.7288 29.7025 18.7573C29.6492 18.7869 29.5893 18.8022 29.5285 18.8019C29.4535 18.8044 29.3796 18.7842 29.3161 18.7439C29.2527 18.7037 29.2027 18.6452 29.1727 18.5761C29.1386 18.4954 29.1211 18.4086 29.1211 18.3209C29.1211 18.2332 29.1386 18.1464 29.1727 18.0657C29.2034 17.9965 29.2532 17.9376 29.3163 17.8962C29.3795 17.8557 29.4532 17.8348 29.5282 17.836C29.5879 17.8358 29.6467 17.8506 29.6993 17.8789C29.7533 17.9076 29.8002 17.9482 29.8364 17.9977V17.8588H29.9781V18.7771H29.8364L29.8367 18.6405ZM29.8367 18.1076C29.8075 18.0624 29.7675 18.0253 29.7203 17.9996C29.6725 17.9712 29.618 17.956 29.5624 17.9557C29.5213 17.9543 29.4804 17.9629 29.4433 17.9807C29.4061 17.9986 29.3738 18.0251 29.349 18.0582C29.2971 18.1354 29.2694 18.2264 29.2694 18.3196C29.2694 18.4127 29.2971 18.5038 29.349 18.581C29.374 18.6137 29.4065 18.6399 29.4436 18.6575C29.4807 18.6751 29.5214 18.6835 29.5624 18.6822C29.6166 18.6811 29.6696 18.6671 29.7173 18.6414C29.765 18.6156 29.8059 18.5789 29.8367 18.5341V18.1076Z"
      fill="black"
    />
    <path
      d="M30.8509 18.1697C30.8509 18.0916 30.8332 18.0363 30.7978 18.0038C30.7554 17.9687 30.7013 17.9511 30.6465 17.9547C30.5924 17.9551 30.5393 17.9697 30.4925 17.997C30.4466 18.0217 30.4069 18.0565 30.3764 18.0988V18.7761H30.2344V17.8577H30.3764V17.9898C30.4162 17.9446 30.4643 17.9075 30.518 17.8805C30.5745 17.8502 30.6375 17.8343 30.7014 17.834C30.8955 17.834 30.9926 17.9329 30.9926 18.1307V18.7751H30.8509V18.1697Z"
      fill="black"
    />
    <path
      d="M31.3033 18.9033C31.3351 18.9444 31.3767 18.9768 31.4242 18.9974C31.4737 19.0191 31.5271 19.0302 31.5811 19.0299C31.6182 19.0321 31.6554 19.0267 31.6904 19.014C31.7253 19.0013 31.7574 18.9816 31.7846 18.956C31.8099 18.9281 31.8294 18.8953 31.8419 18.8597C31.8544 18.824 31.8597 18.7862 31.8573 18.7485V18.6249C31.817 18.6697 31.7686 18.7065 31.7147 18.7332C31.6582 18.7621 31.5957 18.7771 31.5323 18.7768C31.3382 18.7768 31.2412 18.6792 31.2412 18.484V17.8574H31.3832V18.445C31.3832 18.5224 31.4004 18.5766 31.4349 18.6076C31.478 18.642 31.5324 18.659 31.5873 18.6551C31.6411 18.6549 31.694 18.6411 31.7412 18.6151C31.7871 18.5921 31.8269 18.5587 31.8577 18.5175V17.8574H31.9993V18.7511C31.9993 18.8812 31.96 18.9801 31.8813 19.0478C31.7969 19.117 31.6903 19.1529 31.5814 19.1486C31.5153 19.1503 31.4494 19.1392 31.3874 19.1161C31.3293 19.0918 31.2766 19.056 31.2324 19.011L31.3033 18.9033Z"
      fill="black"
    />
    <path
      d="M8.12416 15.903V14.6499H6.92932V15.903H6.20898V12.7799H6.92932V14.0022H8.12416V12.7793H8.84449V15.9024L8.12416 15.903Z"
      fill="black"
    />
    <path
      d="M9.75391 15.9043V12.7812H11.7215V13.4452H10.4742V14.0035H11.6099V14.6343H10.4742V15.2436H11.7215V15.9043H9.75391Z"
      fill="black"
    />
    <path
      d="M12.5801 15.9043V12.7812H13.3001V15.2443H14.546V15.905L12.5801 15.9043Z"
      fill="black"
    />
    <path
      d="M15.1504 15.9043V12.7812H15.8717V15.2443H17.1173V15.905L15.1504 15.9043Z"
      fill="black"
    />
    <path
      d="M19.1485 15.9547C18.1865 15.9547 17.5723 15.2602 17.5723 14.3437C17.5723 13.4273 18.2163 12.7285 19.1782 12.7285C20.1402 12.7285 20.7544 13.4234 20.7544 14.3437C20.7544 15.2641 20.1062 15.9547 19.1485 15.9547ZM19.1604 13.3766C18.6691 13.3766 18.3385 13.7813 18.3385 14.3437C18.3385 14.9062 18.6691 15.3113 19.1604 15.3113C19.6518 15.3113 19.9826 14.902 19.9826 14.3437C19.9826 13.7855 19.6527 13.3766 19.1604 13.3766Z"
      fill="black"
    />
    <path
      d="M22.312 15.9547C21.9604 15.9547 21.5357 15.8142 21.2656 15.6349L21.4646 14.9788C21.7146 15.1619 22.0409 15.2813 22.3333 15.2813C22.5876 15.2813 22.6849 15.1619 22.6849 15.0087C22.6849 14.846 22.6044 14.7358 22.1299 14.5441C21.5833 14.3226 21.3934 14.0028 21.3934 13.6277C21.3934 13.1723 21.7618 12.7285 22.4863 12.7285C22.8253 12.7285 23.173 12.8479 23.3551 12.9715L23.1724 13.6192C22.9942 13.4956 22.7063 13.3635 22.4857 13.3635C22.2231 13.3635 22.1173 13.4872 22.1173 13.615C22.1173 13.8196 22.2738 13.888 22.6639 14.0539C23.2442 14.3011 23.4179 14.5825 23.4179 14.983C23.4179 15.4817 23.0579 15.9547 22.312 15.9547Z"
      fill="black"
    />
    <path d="M24.1895 15.9043V12.7812H24.9098V15.9043H24.1895Z" fill="black" />
    <path
      d="M28.5006 14.1479V15.6395C28.1445 15.8521 27.7367 15.9612 27.3226 15.9547C26.4117 15.9547 25.6914 15.2986 25.6914 14.3437C25.6914 13.3889 26.4202 12.7285 27.3608 12.7285C27.7677 12.7285 28.0937 12.8352 28.4029 12.9972L28.1021 13.5809C27.8882 13.4676 27.6504 13.4077 27.4086 13.4062C26.7899 13.4062 26.4596 13.7939 26.4596 14.3437C26.4596 14.9404 26.8578 15.3113 27.3653 15.3113C27.594 15.3113 27.7085 15.2856 27.823 15.226V14.7657H27.1997V14.1476L28.5006 14.1479Z"
      fill="black"
    />
    <path
      d="M31.9222 15.9638L30.0494 14.2722V15.9043H29.3291V12.7379L29.4261 12.7207L31.2737 14.3997V12.7806H31.994V15.9512L31.9222 15.9638Z"
      fill="black"
    />
  </svg>
);
