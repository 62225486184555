import React from "react";
import { Link } from "react-router-dom";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Tooltip from "components/Tooltip";
import SVGIcon from "components/svg-icon/svg-icon";
import Loader from "components/Loader";

const useStyles = makeStyles((theme) => ({
  text: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "30px",
    color: theme.palette.grey[200],
    marginLeft: theme.spacing(2),
  },
  anchor: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "30px",
    color: theme.palette.blue["young"],
    marginLeft: theme.spacing(2),
    textDecoration: "underline",
  },
}));

const Checklist = ({ tasks = [], loading }) => {
  const classes = useStyles();
  return (
    <Box alignSelf="stretch">
      {tasks.map((task, i) => (
        <Box key={i} display="flex" alignItems="center" mb={4.5}>
          {!loading ? (
            <SVGIcon
              icon="check"
              outlined={!Boolean(task.done)}
              fill="#2BA24C"
              width={30}
              height={30}
            />
          ) : (
            <Loader style={{ width: 30, height: 30 }} size={30} />
          )}
          {!task.done ? (
            task.link ? (
              <Link className={classes.anchor} to={task.link}>
                {task.name}
              </Link>
            ) : (
              <Tooltip title={task.linkTooltip} placement="right">
                <Typography className={classes.text}>{task.name}</Typography>
              </Tooltip>
            )
          ) : (
            <Typography className={classes.text}>{task.name}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Checklist;
