import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import Bar from "./Bar";
import Loader from "components/Loader";
import EmptyOverlay from "components/EmptyOverlay";

const TypeSelector = ({
  onChange,
  selected,
  policies,
  applications,
  partners,
  loading,
  colors = {},
  allSelected,
  disabled,
}) => {
  const sumData = (data) => {
    const initial = {
      opted_in: 0,
      opted_out: 0,
    };

    if (!data) return initial;

    const values = data.reduce((prev, curr) => {
      prev.opted_in += curr.opted_in;
      prev.opted_out += curr.opted_out;
      return prev;
    }, initial);

    return {
      ...values,
      value:
        values.opted_in + values.opted_out !== 0
          ? Math.round(
              (values.opted_in / (values.opted_in + values.opted_out)) * 100
            )
          : 0,
    };
  };
  const data = [
    {
      name: "policies",
      ...sumData(policies),
    },
    {
      name: "applications",
      ...sumData(applications),
    },
    {
      name: "partners",
      ...sumData(partners),
    },
  ];

  useEffect(() => {
    if (onChange) onChange(data[0].name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyData = data.every((d) => d.value === 0);

  return loading ? (
    <Loader />
  ) : (
    <EmptyOverlay
      show={isEmptyData}
      text="No Consents collected."
      link="/zen/consents/collector"
    >
      <Box height="100%" display="flex" alignItems="center">
        <Box width="100%" my={1}>
          {data.map(({ color, name, value }) => (
            <Bar
              key={name}
              id={name}
              title={name}
              value={!isEmptyData ? value : Math.floor(Math.random() * 50) + 50}
              selected={allSelected ? name : selected}
              onClick={onChange ? onChange : undefined}
              color={colors[name] || color}
              disabled={disabled}
            />
          ))}
        </Box>
      </Box>
    </EmptyOverlay>
  );
};

export default TypeSelector;
