import React from "react";
import { Box, Typography } from "@material-ui/core";

import Title from "./title";
import isNil from "lodash.isnil";
import AvatarBig from "assets/images/avatarBig.png";

import useStyles from "./styles";

export default ({
  leftContent,
  title = null,
  subtitle = null,
  children,
  name,
  plan = "Demo plan",
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        <Box className={classes.userProfileWrapper} mb={6}>
          <Box display="flex" alignItems="center">
            <img
              src={AvatarBig}
              className={classes.avatar}
              alt={name ? `Profile of ${name}` : ""}
            />
            <Box ml={2} className={classes.userProfileInfo}>
              {name && (
                <Typography variant="h1" className={classes.userProfileName}>
                  {name}
                </Typography>
              )}
              <Typography variant="h2" className={classes.userProfilePlan}>
                {plan}
              </Typography>
            </Box>
          </Box>
          {/* <Box clone mt={2}>
            <Typography component="p" className={classes.profileWarning}>
              PLEASE VERIFY YOUR EMAIL
            </Typography>
          </Box> */}
        </Box>
        {(!isNil(title) || !isNil(subtitle)) && (
          <Title title={title} subtitle={subtitle} />
        )}
        {!isNil(leftContent) && leftContent}
      </Box>
      <Box className={classes.rightContainer}>
        <Box className={classes.inAppPricing} borderRadius={6} mb={6}>
          <Typography variant="h4">
            Upgrade now to unlock more features and create more environments!
          </Typography>
          {/* <Button variant="contained" color="primary" className={classes.seePlansBT}>
            See Plans
          </Button> */}
        </Box>
        {children}
      </Box>
    </Box>
  );
};
