import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontFamily: "Aldrich, sans-serif",
    fontWeight: "normal",
    letterSpacing: "1px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("xs")]: {
      fontSize: "36px",
    },
  },
  content: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    columnGap: 90,
    [theme.breakpoints.up("xs")]: {
      gridTemplateColumns: "100%",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "60% 40%",
    },
  },
}));
