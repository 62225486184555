import { calculateTermsAndConditions } from "../../../utils.js";
import { passed } from ".";

export const calculateTestTubeScoreForWeb = (
  reportType,
  detailedReport,
  serviceTerms,
  items,
  setItems,
  setTestTubeScore
) => {
  const {
    personalData,
    thirdPartyTrackersScore,
    cookieMessageOnFirstLoad,
    hasCookieManager,
    hasPrivacyLink,
    hasTermsLink,
    traffic,
    fingerprintingScore,
  } = detailedReport;

  const policyAndTerms = calculateTermsAndConditions(
    serviceTerms,
    hasPrivacyLink,
    hasTermsLink,
    personalData
  );
  const { hasPrivacyPolicy, hasTermsAndConditions } = policyAndTerms;
  const httpsTrafficScore = traffic?.httpsTraffic;
  const cookieManagerScore = hasCookieManager ? 1 : 0;
  const privacyScore = hasPrivacyPolicy ? 1 : 0;
  const serviceTermsScore = hasTermsAndConditions ? 1 : 0;
  const cookieMessageScore = cookieMessageOnFirstLoad ? 1 : 0;

  items[0].value = Math.round(100 * thirdPartyTrackersScore);
  items[1].value = Math.round((1 - fingerprintingScore) * 100);
  items[2].value = Math.round(httpsTrafficScore * 100);
  items[3].value = 100 * cookieManagerScore;
  items[4].value = 100 * privacyScore;
  items[5].value = 100 * serviceTermsScore;
  items[6].value = 100 * cookieMessageScore;

  const p = passed(items);
  setItems(items);
  setTestTubeScore(p);
};
