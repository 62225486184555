import React from "react";
import clsx from "clsx";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "./styles";

const ToggleInput = (props) => {
  const {
    label,
    helperText,
    className = null,
    style = {},
    onChange = () => {},
    value,
    options = [],
    ...restProps
  } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.inputContainer, className)} style={style}>
      <label className={classes.inputLabel}>{label}</label>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={(e, val) => onChange(val)}
        {...restProps}
      >
        {options.map(({ value, label }, idx) => (
          <ToggleButton value={value} key={idx}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {helperText && <label className={classes.helperText}>{helperText}</label>}
    </div>
  );
};

export default ToggleInput;
