import config from "config";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";

export const createEnv = (data) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  let url = `${config.apiUrl}/environments`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const updateEnv = (envId, data) => {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  };

  let url = `${config.apiUrl}/environments/${envId}/settings`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
