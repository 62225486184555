import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    colorLegend: {
      fontSize: "0.85rem",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      color: theme.palette.grey[800],
      border: `1px solid ${theme.palette.grey.border}`,
      marginBottom: theme.spacing(6),
    },
    colorLegendTitle: {
      fontWeight: 600,
      fontSize: "0.85rem",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      borderBottom: `1px solid ${theme.palette.grey.border}`,
      paddingBottom: theme.spacing(0.75),
      "& > svg": {
        fontSize: 16,
        marginRight: theme.spacing(1),
      },
    },
    colorLegendItems: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
    },
    colorLegendItem: {
      marginBottom: theme.spacing(1),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    colorLegendItemDot: {
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: "100%",
      marginRight: theme.spacing(1),
      display: "inline-block",
    },
    legendPopover: {
      padding: theme.spacing(2),
      color: theme.palette.grey[800],
    },
  }),
  { name: "TestScores" }
);
