import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import { clearEnv } from "redux/_environments/environment.actions";
import {
  getEnvsData,
  getSelectedEnvId,
} from "redux/_environments/environment.selectors";
import { getLoading } from "redux/_charts/charts.selectors";
import { Box, Grid, Typography } from "@material-ui/core";
import PiiCategories from "charts/PieCharts/PiiCategories";
import Status from "pages/Overview/components/Status";
import useStyles from "pages/Overview/styles";
import { getLastScansPerEnv } from "redux/_scans/scans.service";
// import EnvironmentHeader from 'components/EnvironmentHeader';
import { getTreeByCountry } from "redux/_charts/charts.async.actions";
import { getTreeByCountry as selectorTreeByCountry } from "redux/_charts/charts.selectors";

import { formatValue } from "../../format";
import PageWrapper from "components/PageWrapper";
// import TypeSelector from "components/Consents/Overview/components/ConsentsGranted/components/TypeSelector";
import {
  getConsentsOverviewData,
  getOverviewLoadingState,
} from "redux/_consents/_overview/overview.selectors";
import { getOverviewData } from "redux/_consents/_overview/overview.async.actions";
import CONSTANTS from "redux/_scans/scans.constants";

// import GeoDistribution from "components/Consents/Analytics/GeoDistribution";

import piiGroupsMockedData from "./overview-piigroups.json";
import envMockedData from "./overview-environments.json";
import isEmpty from "lodash.isempty";

import EmptyOverlay from "components/EmptyOverlay";

// const DEFAULT_SCANS = 8

const environmentsReducer = (accumulator, currentValue) => {
  if (currentValue.status !== CONSTANTS.SCANS_FAILED_STATUS) {
    accumulator.scannedColumns += currentValue.scannedColumns;
    accumulator.scannedDatabases += currentValue.scannedDatabases;
    accumulator.scannedTables += currentValue.scannedTables;
    accumulator.piiColumns += currentValue.piiColumns;
  }

  return accumulator;
};

const piiGroupsReducer = (accumulator, currentValue) => {
  if (!currentValue.piiGroups) return {};
  currentValue.piiGroups.forEach((x) => {
    if (!accumulator[x.piiGroupName])
      accumulator[x.piiGroupName] = { label: x.piiGroupName, value: 0 };
    accumulator[x.piiGroupName].value += x.totalMatches;
  });
  return accumulator;
};

const DashboardPage = (props) => {
  const classes = useStyles();
  const {
    environments = [],
    clearEnvironment,
    getOverviewData,
    // consentsOverviewData: { policies, applications, partners },
    // consentsOverviewLoadingState,
    selectedEnv,
  } = props;

  const [lastScans, setLastScans] = useState([]);
  const [emptyLastScans, setEmptyLastScans] = useState(false);

  useEffect(() => {
    getLastScansPerEnv()
      .then((data) => {
        if (isEmpty(data)) {
          setLastScans(piiGroupsMockedData);
          setEmptyLastScans(true);
        } else {
          setLastScans(data);
          setEmptyLastScans(false);
        }
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totals = useMemo(
    () =>
      lastScans.reduce(environmentsReducer, {
        scannedColumns: 0,
        scannedDatabases: 0,
        scannedTables: 0,
        piiColumns: 0,
      }),
    [lastScans]
  );

  const piiGroups = useMemo(
    () => Object.values(lastScans.reduce(piiGroupsReducer, {})),
    [lastScans]
  );

  useEffect(() => {
    clearEnvironment();
    getOverviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper title="HOME" subtitle="OVERVIEW">
      <Grid container className={classes.content} spacing={4}>
        <Grid className={classes.columnContainer} item xs={12} lg={6}>
          <Typography variant="h4" className={classes.boxTitle}>
            PII GROUPS
          </Typography>
          <EmptyOverlay
            text="No PIIs detected."
            link="/zen/settings"
            show={emptyLastScans}
          >
            <Box className={clsx(classes.boxContent, classes.piiGroups)}>
              <PiiCategories
                kpi={{
                  label: "PII COLUMNS",
                  value: formatValue(totals.piiColumns),
                }}
                data={piiGroups}
              />
            </Box>
          </EmptyOverlay>
        </Grid>

        <Grid className={classes.columnContainer} item xs={12} lg={6}>
          <Typography variant="h4" className={classes.boxTitle}>
            SCANS STATUS
          </Typography>
          <EmptyOverlay
            text="No Scans run."
            link={
              selectedEnv
                ? `/zen/environment/${selectedEnv}/scans`
                : "/zen/settings"
            }
            show={emptyLastScans || isEmpty(environments)}
          >
            <Box className={classes.boxContent}>
              <Status
                environments={
                  isEmpty(environments) ? envMockedData : environments
                }
                totals={totals}
                scans={lastScans}
              />
            </Box>
          </EmptyOverlay>
        </Grid>
        {/*
        <Grid className={classes.columnContainer} item xs={12} lg={6}>
          <Typography
            variant="h4"
            className={classes.boxTitle}
            style={{ marginTop: 48, marginBottom: 16 }}
          >
            CONSENTS STATUS
          </Typography>
          <Box className={classes.boxContent} style={{ height: 266 }}>
            <TypeSelector
              loading={consentsOverviewLoadingState}
              policies={policies}
              applications={applications}
              partners={partners}
              colors={{
                policies: "#1a3586",
                applications: "#7686b6",
                partners: "#cad0e3",
              }}
              disabled
            />
          </Box>
        </Grid>

        <Grid className={classes.columnContainer} item xs={12} lg={6}>
          <Typography
            variant="h4"
            className={classes.boxTitle}
            style={{ marginTop: "48px" }}
          >
            CONSENTS DISTRIBUTION
          </Typography>
          <Box className={classes.boxContent} style={{ height: 266 }}>
            <GeoDistribution disabledFilters onlyMap />
          </Box>
        </Grid>
      */}
      </Grid>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  environments: getEnvsData(state),
  piiCountriesData: selectorTreeByCountry(state),
  piiCountrisLoading: getLoading(state),
  consentsOverviewData: getConsentsOverviewData(state),
  consentsOverviewLoadingState: getOverviewLoadingState(state),
  selectedEnv: getSelectedEnvId(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPiiCountriesData: () => dispatch(getTreeByCountry(true)),
  clearEnvironment: () => clearEnv(),
  getOverviewData: () => dispatch(getOverviewData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
