import { makeStyles } from "@material-ui/core";

import { settingOptionMixin } from "components/SettingReportsOption/styles";

export default makeStyles(
  (theme) => ({
    ...settingOptionMixin(theme),
  }),
  { name: "SettingOptionChargebee" }
);
