/**
 * Reducer to handle actions related to sign in/out of the application
 * as well as maintain the session state.
 * Initialize with LocalStorage data allowing session to be maintained
 * between refreshes.
 *
 * @module authentication/reducer
 */
import { authenticationConstants } from "redux/_authentication/authentication.constants";
import { authService } from "redux/_authentication/authentication.service";
import isEmpty from "utils/is-empty";

let session;
try {
  session = JSON.parse(localStorage.getItem(authenticationConstants.SESSION));
} catch (e) {
  localStorage.setItem(authenticationConstants.SESSION, "");
}
const emptySession = {
  isAuthenticated: false,
  token: null,
  loginErrors: [],
  currentUser: null,
};

let initialState = emptySession;
if (!isEmpty(session)) {
  initialState = {
    isAuthenticated: true,
    token: session.access_token,
    loginErrors: [],
    currentUser: null,
  };
  window.addEventListener("storage", authService.watchSession);
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loginErrors: [],
      };
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.session.access_token,
        loginErrors: [],
        loggingIn: false,
      };
    case authenticationConstants.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        loginErrors: action.errors,
        loggingIn: false,
      };
    case authenticationConstants.LOGOUT:
      return { ...emptySession };
    case authenticationConstants.ATTEMPTED_TRANSITION:
      return {
        ...state,
        attemptedTransition: action.location,
      };
    default:
      return state;
  }
}
