import React from "react";
import PageWrapper from "components/PageWrapper";
import Integrations from "components/Integrations";

const IntegrationsPage = (props) => {
  return (
    <PageWrapper title="HOME" subtitle="INTEGRATIONS">
      <Integrations {...props} />
    </PageWrapper>
  );
};

export default IntegrationsPage;
