import React, { useState } from "react";
import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import useStyles from "./styles";
import TextInput from "components/Inputs/Text";
import SvgIcon from "components/svg-icon/svg-icon";
import { useSnackbar } from "notistack";

const ChangeEmail = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose = () => {}, onConfirm = () => {} } = props;
  const [newEmail, setNewEmail] = useState("");
  const [repeated, setRepeated] = useState("");
  const [loading, setLoading] = useState(false);
  const reset = () => {
    setNewEmail("");
    setRepeated("");
  };
  return (
    <Dialog open={open} className={classes.dialog} fullWidth maxWidth="lg">
      <Box className={classes.sectionContainer}>
        <Box className={classes.leftContainer}>
          <Typography className={classes.title}>Change email</Typography>
        </Box>
        <Box className={classes.rightContainer}>
          <Box display="flex">
            <TextInput
              label="New email"
              style={{ maxWidth: 208, paddingBottom: 16, marginRight: 16 }}
              helperText="Your new email"
              value={newEmail}
              onChange={setNewEmail}
              type="email"
              disabled={loading}
            />
            <TextInput
              label="Repeat new email"
              style={{ maxWidth: 208, paddingBottom: 16 }}
              helperText="Repeat your new email"
              value={repeated}
              onChange={setRepeated}
              type="email"
              disabled={loading}
            />
          </Box>
        </Box>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <SvgIcon icon="close" />
        </IconButton>
      </Box>
      <Button
        disabled={loading || !newEmail}
        className={classes.confirmButton}
        color="secondary"
        onClick={(e) => {
          e.preventDefault();
          if (newEmail === repeated) {
            setLoading(true);
            onConfirm(newEmail)
              .then(() => {
                setLoading(false);
                reset();
              })
              .catch(() => {
                setLoading(false);
                reset();
              });
          } else {
            enqueueSnackbar("Emails don't match", { variant: "error" });
          }
        }}
      >
        Save
      </Button>
    </Dialog>
  );
};

export default ChangeEmail;
