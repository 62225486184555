import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  kpisContainer: {
    padding: "2rem 0px",
  },
  kpisTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[400],
    textTransform: "uppercase",
    width: "100%",
    textAlign: "right",
    paddingBottom: "4px",
    borderBottom: "1px solid #cfdff8",
    marginBottom: "8px",
  },
  itemValueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  itemValue: {
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: 1.36,
    color: theme.palette.blue[500],
  },
  itemTitle: {
    fontSize: "14px",
    color: "#828282",
    fontWeight: 600,
    lineHeight: 1.2,
    whiteSpace: "normal",
  },
  filtersTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "2px",
    color: theme.palette.blue[400],
    textTransform: "uppercase",
    width: "100%",
    textAlign: "right",
    paddingBottom: "4px",
    borderBottom: "1px solid #cfdff8",
    marginBottom: theme.spacing(2),
  },
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  filtersInput: {
    width: "100%",
  },
  filterButton: {
    width: "33.33%",
  },
  selectText: {
    color: theme.palette.blue[600],
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 0.9,
    letterSpacing: "1px",
    padding: 0,
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
  },
  selectMenu: {
    backgroundColor: theme.palette.blue[600],
    width: "194px",
    top: "63px !important",
  },
  selectMenuItem: {
    color: "white",
    fontSize: "0.9375rem",
    minHeight: "40px",
    paddingTop: "0",
    paddingBottom: "0",
  },
  selectContainer: {
    borderRadius: "4px",
    padding: "4px 0px 13px",
    width: "100%",
    borderBottom: "1px solid #cfdff8",
  },
  formControl: {
    backgroundColor: "white",
    width: "100%",
  },
  selectTitle: {
    padding: 0,
    lineHeight: 1.29,
    color: theme.palette.blue[300],
    fontFamily: "Roboto Condensed",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  selectedText: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 0.9,
    letterSpacing: "1px",
    color: theme.palette.blue[600],
  },
  selectIcon: {
    width: "32px",
    height: "32px",
    top: "calc(50% - 16px)",
  },
  reloadButtonContainer: {
    width: "100%",
    height: "85px",
    borderBottom: "1px solid #cfdff8",
  },
  reloadButton: {
    width: "100%",
  },
  reloadIcon: {
    width: 15,
    height: 15,
    fill: theme.palette.blue[400],
    marginRight: theme.spacing(),
  },
  kpiTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  kpiIcon: {
    height: 26,
    marginRight: 8,
  },
  inputLabel: {
    color: theme.palette.grey[200],
    display: "block",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    marginBottom: "0px",
  },
  reqButton: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textTransform: "none",
    color: theme.palette.blue[300],
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(0.5, 0),
    borderTop: `1px solid ${theme.palette.blue[100]}`,
    "& > span": {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      "& svg": {
        marginLeft: 4,
      },
    },
  },
}));
