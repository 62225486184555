import { Button as MuiButton, Hidden, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import clsx from "clsx";
import Avatar from "components/Avatar";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getData } from "redux/_account/account.async.actions";
import { getAccountData } from "redux/_account/account.selectors";
import { authenticationActinon } from "redux/_authentication/authentication.actions";
import { authenticationConstants } from "redux/_authentication/authentication.constants";
import { routeForKey } from "router";
import useStyles from "./styles";
import "./bootstrap-grid.min.css";
import "./bootstrap-reboot.min.css";

import backgroundImage from "assets/images/bgheaderg.png";

export const UserMenu = ({
  account,
  handleLogout,
  hideAvatar = false,
  isPublicNav = true,
  isDashboard = false,
}) => {
  const classes = useStyles({ isPublicNav, isDashboard });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MuiButton
        className={classes.userMenuButton}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ArrowDropDown /> {account.email}{" "}
        {!hideAvatar && <Avatar className={classes.buttonAvatar} />}
      </MuiButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.loggedMenu}
      >
        <MenuItem className={classes.buttonMenuItem} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

const PublicNav = ({
  account,
  transparent,
  // backgroundImage,
  getProfileData,
  logout,
}) => {
  const { id } = account || {};
  const classes = useStyles({ transparent, backgroundImage, id: id });

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const session = JSON.parse(
      localStorage.getItem(authenticationConstants.SESSION)
    );
    if (session) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className={classes.nav}>
      <div className="container-fluid d-flex flex-column flex-md-row justify-content-between">
        <NavLink
          className={classes.logoContainer}
          exact
          target="_top"
          to={{ pathname: "https://www.zendata.dev" }}
        >
          <h1 className={classes.logo}>
            <ZenDataLogo />
          </h1>
        </NavLink>
        <Hidden smDown>
          <ul
            className={clsx(
              "container d-flex flex-column flex-md-row justify-content-end align-items-center",
              classes.divider
            )}
          >
            {!id && (
              <li className={classes.menuWrapper}>
                <NavLink
                  exact
                  className={classes.menuTitle}
                  aria-haspopup="true"
                  target="_top"
                  to={{ pathname: "https://www.zendata.dev" }}
                >
                  Solutions
                </NavLink>
                <div className="menu-content">
                  <MenuItem
                    component={Link}
                    exact="true"
                    target="_top"
                    to={{ pathname: "https://www.zendata.dev/website-scanner" }}
                  >
                    Website Scanner
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    exact="true"
                    target="_top"
                    to={{ pathname: "https://www.zendata.dev/app-scanner" }}
                  >
                    App Scanner
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    exact="true"
                    target="_top"
                    to={{ pathname: "https://www.zendata.dev/privacy-mapper" }}
                  >
                    Privacy Mapper
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    exact="true"
                    target="_top"
                    to={{ pathname: "https://app.zendata.dev/consumer-report" }}
                  >
                    Consumer
                  </MenuItem>
                </div>
              </li>
            )}
            {!id && (
              <li>
                <NavLink
                  className="d-none d-md-inline-block"
                  exact
                  target="_top"
                  to={{ pathname: "https://www.zendata.dev/pricing" }}
                >
                  Plans & Pricing
                </NavLink>
              </li>
            )}
            <li className={classes.menuWrapper}>
              <span className={classes.menuTitle} aria-haspopup="true">
                Learn
              </span>
              <div className="menu-content">
                <MenuItem
                  component={Link}
                  exact="true"
                  target="_blank"
                  to={{ pathname: "https://www.zendata.dev/blog" }}
                >
                  Blog
                </MenuItem>
                <MenuItem component={Link} to="/data-protection-form">
                  Privacy Law Criteria
                </MenuItem>
              </div>
            </li>
            <li className="d-none d-md-inline-block">
              {!id ? (
                <NavLink className={classes.login} to={routeForKey("login")}>
                  Client Login
                </NavLink>
              ) : (
                <UserMenu account={account} handleLogout={handleLogout} />
              )}
            </li>
          </ul>
        </Hidden>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProfileData: () => dispatch(getData()),
  logout: () => dispatch(authenticationActinon.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNav);
