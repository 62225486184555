import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[700], // Darker gray color for false state
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const WebHookBranchRow = ({ branch, isLoading, onToggle }) => {
  const handleChange = (event) => {
    onToggle(branch.id, event.target.checked);
  };

  return (
    <Box
      key={branch.id}
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        background: "#FFFFFF",
        padding: 20,
        flexDirection: "row",
      }}
    >
      <Typography
        style={{
          color: "#000000",
          fontWeight: 700,
          fontFamily: "Inter",
          flex: 1,
        }}
      >
        {branch.name}
      </Typography>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <IOSSwitch
          checked={branch.webhook_enabled}
          onChange={handleChange}
          name={branch.name}
        />
      )}
    </Box>
  );
};

export default WebHookBranchRow;
