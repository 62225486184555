import React from "react";
import { project } from "./utils";

const DendogramLink = ({ node, color }) => {
  const curveLinkPath = (node) => {
    const source = project(node.parent.x, node.parent.y);
    const target = project(node.x, node.y);

    if (node.depth === 1) {
      const dx = source[0] - target[0];
      const dy = source[1] - target[1];
      const dr = Math.sqrt(dx * dx + dy * dy);
      return `M ${source[0]}, ${source[1]} A ${dr}, ${dr} 0 0,1 ${target[0]}, ${target[1]}`;
    } else {
      return `M ${target[0]} ${target[1]} Q ${project(node.x, node.y / 2)} ${
        source[0]
      } ${source[1]}`;
    }
  };
  return (
    <path
      key={`${node.parent ? node.parent.data.name : ""}_${node.data.name}`}
      stroke={color}
      fill="none"
      d={curveLinkPath(node)}
    />
  );
};

export default DendogramLink;
