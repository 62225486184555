import { useState } from "react";
import axios from "axios";
import { authHeader } from "helpers/auth-header";
import config from "config";

export const useFetchPIISharingDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalRows: 0,
  });

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  const createConfig = () => {
    return {
      ...getHeadersConfig(),
    };
  };

  const fetchPIISharingDetails = (scanId, classification_1 , classification_2_label) => {
    setLoading(true);
    axios
      .get(`${config.apiUrl}/repo-scans/${scanId}/items/`, {
        params: {
          classification_1: classification_1,
          classification_2_label: classification_2_label,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
        ...createConfig(),
      })
      .then((response) => {
        setData(response.data);
        setPagination((prev) => ({
          ...prev,
          totalRows: response.data.meta.totalRows,
        }));
      })
      .catch((err) => {
        console.error(err.response);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, pagination, fetchPIISharingDetails, setPagination };
};
