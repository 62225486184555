import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Severity from "pages/AppReport/common/Severety";
import Title from "pages/AppReport/common/Title";
import SectionContent from "pages/AppReport/common/SectionContent";

const SharedObjectCell = ({ isTrue, severity, description }) => {
  return (
    <div>
      {isTrue ? "True" : "None"}&nbsp;
      <Severity severity={severity} bubbleFormat={false} />
      <br />
      {description}
    </div>
  );
};

const BinaryAnalysisSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.binaryAnalysis.key}
      footerBackgroundColor={SECTIONS_CONTENT.binaryAnalysis.backgroundColor}
      footerText={
        <div className="privacy-tech">Check the NIAP Analysis v1.3</div>
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title tooltip={SECTIONS_CONTENT.binaryAnalysis.description}>
        {SECTIONS_CONTENT.binaryAnalysis.title}
      </Title>
      <SectionContent>
        <Table
          data={data}
          limitPerPage={5}
          columns={[
            {
              key: "name",
              label: "Shared Object",
              width: "15%",
            },
            {
              key: "nx",
              label: "NX",
              width: "20%",
              render: (nx) => <SharedObjectCell isTrue={nx.is_nx} {...nx} />,
            },
            {
              key: "stack_canary",
              label: "Stack Canary",
              width: "20%",
              render: (stack_canary) => (
                <SharedObjectCell
                  isTrue={stack_canary.has_canary}
                  {...stack_canary}
                />
              ),
            },
            {
              key: "rpath",
              label: "RPATH",
              width: "10%",
              render: (rpath) => (
                <SharedObjectCell isTrue={rpath.rpath} {...rpath} />
              ),
            },
            {
              key: "runpath",
              label: "RUNPATH",
              width: "10%",
              render: (runpath) => (
                <SharedObjectCell isTrue={runpath.runpath} {...runpath} />
              ),
            },
            {
              key: "fortify",
              label: "FORTIFY",
              width: "15%",
              render: (fortify) => (
                <SharedObjectCell isTrue={fortify.is_stripped} {...fortify} />
              ),
            },
            {
              key: "symbol",
              label: "SYMBOL",
              width: "10%",
              render: (symbol) => (
                <SharedObjectCell isTrue={symbol.is_fortified} {...symbol} />
              ),
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default BinaryAnalysisSection;
