import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    scansMaxedOutNotice: {
      background: theme.palette.grey[400],
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    iconContainer: {},
    icon: {},
    message: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      },
    },
    cta: {
      [theme.breakpoints.up("md")]: {
        height: "min-content",
        minWidth: 200,
        maxWidth: 200,
      },
    },
  }),
  { name: "ScansMaxedOutNotice" }
);
