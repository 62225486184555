export const SCORE_DETAIL_ITEM = {
  activityLinked: "activityLinked",
  easyToReadPrivacyPolicy: "easyToReadPrivacyPolicy",
  hasPrivacyPolicy: "hasPrivacyPolicy",
  movesInfoAway: "movesInfoAway",
  optOut: "optOut",
  sellsYourInfo: "sellsYourInfo",
  thirdPartyTrackers: "thirdPartyTrackers",
  usesSecureToolsToManageData: "usesSecureToolsToManageData",
};

export const SCORE_DETAIL_WHETHER_POSITIVE_OR_NEGATIVE = {
  [SCORE_DETAIL_ITEM.activityLinked]: {
    true: "negative",
    false: "positive",
  },
  [SCORE_DETAIL_ITEM.easyToReadPrivacyPolicy]: {
    true: "positive",
    false: "negative",
  },
  [SCORE_DETAIL_ITEM.hasPrivacyPolicy]: {
    true: "positive",
    false: "negative",
  },
  [SCORE_DETAIL_ITEM.movesInfoAway]: {
    true: "negative",
    false: "positive",
  },
  [SCORE_DETAIL_ITEM.optOut]: {
    true: "positive",
    false: "negative",
  },
  [SCORE_DETAIL_ITEM.sellsYourInfo]: {
    true: "negative",
    false: "positive",
  },
  [SCORE_DETAIL_ITEM.thirdPartyTrackers]: {
    true: "negative",
    false: "positive",
  },
  [SCORE_DETAIL_ITEM.usesSecureToolsToManageData]: {
    true: "positive",
    false: "negative",
  },
};

export const SCORE_DETAIL_VALUE = {
  [SCORE_DETAIL_ITEM.activityLinked]: {
    positive: {
      yesOrNoValue: "No",
      description:
        "Our system found that your activity cannot be linked across multiple devices",
    },
    negative: {
      yesOrNoValue: "Yes",
      description:
        "Our system found that your activity can be linked across multiple devices",
    },
  },
  [SCORE_DETAIL_ITEM.easyToReadPrivacyPolicy]: {
    positive: {
      yesOrNoValue: "Yes",
      description: "This site has an easy to read privacy policy",
    },
    negative: {
      yesOrNoValue: "No",
      description: "This site does not have an easy to read privacy policy",
    },
  },
  [SCORE_DETAIL_ITEM.hasPrivacyPolicy]: {
    positive: {
      yesOrNoValue: "Yes",
      description: "We found a privacy policy in this site",
    },
    negative: {
      yesOrNoValue: "No",
      description: "We cannot find a privacy policy in this site",
    },
  },
  [SCORE_DETAIL_ITEM.movesInfoAway]: {
    positive: {
      yesOrNoValue: "No",
      description:
        "This site is not moving your information away from where you are located",
    },
    negative: {
      yesOrNoValue: "Yes",
      description:
        "This site is moving your information away from where you are located",
    },
  },
  [SCORE_DETAIL_ITEM.optOut]: {
    positive: {
      yesOrNoValue: "Yes",
      description: "This site allows you to opt-out of tracking/sale of data",
    },
    negative: {
      yesOrNoValue: "No",
      description:
        "This site does not allow you to opt-out of tracking/sale of data",
    },
  },
  [SCORE_DETAIL_ITEM.sellsYourInfo]: {
    positive: {
      yesOrNoValue: "Yes",
      description:
        "This site explicitly states that they do not sell your data",
    },
    negative: {
      yesOrNoValue: "No",
      description:
        "This site does not explicitly state that they do not sell your data",
    },
  },
  [SCORE_DETAIL_ITEM.thirdPartyTrackers]: {
    positive: {
      yesOrNoValue: "No",
      description: "Our system did not find third-party trackers on this site",
    },
    negative: {
      yesOrNoValue: "Yes",
      description: "Our system found third-party trackers on this site",
    },
  },
  [SCORE_DETAIL_ITEM.usesSecureToolsToManageData]: {
    positive: {
      yesOrNoValue: "Yes",
      description:
        "This site is using tools like Zendata to manage data collection and use",
    },
    negative: {
      yesOrNoValue: "No",
      description:
        "This site is using tools like Zendata to manage data collection and useThis site is using tools like Zendata to manage data collection and use",
    },
  },
};
