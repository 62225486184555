import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  table: {
    width: "100%",
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
    "& th": {
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      lineHeight: 2.5,
      color: theme.palette.grey[300],
      textTransform: "uppercase",
      fontWeight: "normal",
    },
  },
  tr: {
    borderBottom: "1px solid #e0e1e3",
  },
  loadingRow: {
    height: "calc(100vh - 274px)",
  },
  col1: { width: "45%" },
  col2: { width: "55%" },
}));
