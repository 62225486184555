import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import FunctionalUsesCreatableSelect from "./components/functionalUses";
import BusinessUnitCreatableSelect from "./components/businessUnit";
import PurposesCreatableSelect from "./components/purpose";
import { connect } from "react-redux";
import {
  assignFunctionalUse,
  getFunctionalUses,
} from "redux/_functionalUses/functionalUses.async.actions";
import {
  assignBusinessUnit,
  getBusinessUnits,
} from "redux/_businessUnits/businessUnits.async.actions";
import {
  assignPolicy,
  getPoliciesData as getPolicies,
} from "redux/_consents/_policies/policies.async.actions";
import useStyles from "./styles";

const ScansTable = ({
  tables = [],
  getFunctionalUsesData,
  updateFunctionalUse,
  getBusinessUnitsData,
  updateBusinessUnit,
  getPoliciesData,
  updatePolicy,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getFunctionalUsesData();
    getBusinessUnitsData();
    getPoliciesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.tableWrapper}>
      <Table className={classes.table} size="small" aria-label="scans table">
        <TableHead>
          <TableRow className={classes.tableRowHeader}>
            <TableCell>Tables</TableCell>
            <TableCell>Functional Use</TableCell>
            <TableCell>Business Unit</TableCell>
            <TableCell>Purpose</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tables.map((table, idx) => (
            <TableRow className={classes.tableRowBody} key={`table-row-${idx}`}>
              <TableCell>{table.table_name}</TableCell>

              {/* Functional uses */}
              <TableCell>
                {/* {idx === 0 && (
                  <Button
                    size="small"
                    className={classes.applyToAll}
                    color="primary"
                    onClick={_ =>
                      tables.forEach(({ id }) => updateFunctionalUse(id, table.id_functional_use))
                    }
                  >
                    Apply to all
                  </Button>
                )} */}
                <FunctionalUsesCreatableSelect
                  placeholder="Assign a department"
                  value={table.id_functional_use}
                  id={table.id}
                />
              </TableCell>

              {/* Business units */}
              <TableCell>
                {/* {idx === 0 && (
                  <Button
                    size="small"
                    className={classes.applyToAll}
                    color="primary"
                    onClick={_ =>
                      tables.forEach(({ id }) => updateBusinessUnit(id, table.id_business_unit))
                    }
                  >
                    Apply to all
                  </Button>
                )} */}
                <BusinessUnitCreatableSelect
                  placeholder="Assign a business unit"
                  value={table.id_business_unit}
                  id={table.id}
                />
              </TableCell>

              {/* Purposes */}
              <TableCell>
                {/* {idx === 0 && (
                  <Button
                    size="small"
                    className={classes.applyToAll}
                    color="primary"
                    onClick={_ => tables.forEach(({ id }) => updatePolicy(id, table.id_policy))}
                  >
                    Apply to all
                  </Button>
                )} */}
                <PurposesCreatableSelect
                  placeholder="Assign a purpose"
                  value={table.id_policy}
                  id={table.id}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateFunctionalUse: (tableId, functionalUseId) =>
    dispatch(assignFunctionalUse(tableId, functionalUseId)),
  updateBusinessUnit: (tableId, businessUnitId) =>
    dispatch(assignBusinessUnit(tableId, businessUnitId)),
  updatePolicy: (tableId, policyId) =>
    dispatch(assignPolicy(tableId, policyId)),
  getFunctionalUsesData: () => dispatch(getFunctionalUses()),
  getBusinessUnitsData: () => dispatch(getBusinessUnits()),
  getPoliciesData: () => dispatch(getPolicies()),
});

export default connect(null, mapDispatchToProps)(ScansTable);
