import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  tabsContainer: {
    paddingTop: theme.spacing(4),
    height: "calc(100% - 309px)",
  },
  tabsHeader: {
    height: "50px",
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
  },
}));
