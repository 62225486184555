import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import {
  Hidden,
  Box,
  Typography,
  Button,
  // TextField,
  // InputAdornment
} from "@material-ui/core";
import illustration from "assets/illustrations/notfound-illus.svg";
import HomepageMenu from "components/HomepageMenu";
import PublicNav from "components/PublicComponents/Nav";
import Footer from "components/PublicComponents/Footer";
import Inviter from "components/PublicComponents/Inviter";
// import SVGIcon from 'components/svg-icon/svg-icon';

function NotFound({ history }) {
  const classes = useStyles();
  const handleGoBack = () => {
    history.go(-2);
  };

  return (
    <div id="outer-container">
      <Hidden mdUp>
        <HomepageMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </Hidden>
      <div
        id="page-wrap"
        className="homepage"
        style={{ backgroundColor: "#fff" }}
      >
        <PublicNav />
        <section className={classes.container}>
          <div className="container">
            <Typography component="h2" className={classes.title}>
              Oh no! Where did it go?
            </Typography>
            <Box display="flex" className={classes.content}>
              <Box flexGrow={1} textAlign="center">
                <img src={illustration} alt="" />
              </Box>
              <Box maxWidth={409} mt={3} mb={15.5}>
                <Typography className={classes.description}>
                  We couldn’t find what you were looking for. Our engineers are
                  working to identify the problem.
                </Typography>
                <Box display="flex" mt={2} mb={3}>
                  <Button
                    component={Link}
                    to="/"
                    className={classes.mainButton}
                  >
                    Take Me Home
                  </Button>
                  <Button
                    onClick={handleGoBack}
                    className={classes.secondaryButton}
                    variant="outlined"
                  >
                    Go Back
                  </Button>
                </Box>
                {/* <Typography className={classes.description}>Or try searching:</Typography>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  placeholder="Search for keywords"
                  InputProps={{
                    className: classes.inputBase,
                    classes: {
                      notchedOutline: classes.inputOutline,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SVGIcon className={classes.inputIcon} icon="searchIcon" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    className: classes.input,
                  }}
                /> */}
              </Box>
            </Box>
          </div>
        </section>

        <Inviter />
        <Footer />
      </div>
    </div>
  );
}

export default NotFound;
