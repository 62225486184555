import React, { useState } from "react";
import ExpandedPanel from "../ExpandedPanel";
import { makeStyles } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px dashed rgba(0, 0, 0, 0.12)",
    padding: "8px 16px",
    borderRadius: 4,
    overflowWrap: "break-word",
    width: "100%",
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

export const BoxDetails = ({ data = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {Array.isArray(data)
        ? data.map((el) => (
            <span>
              {el.label && (
                <>
                  <b>{el.label}:</b>&nbsp;
                </>
              )}
              {el?.render ? el.render(el.value) : el.value}
            </span>
          ))
        : ReactHtmlParser(data)}
    </div>
  );
};

const getAppInfoDetails = (data, appType) => {
  return appType === "apk"
    ? [
        {
          label: "App Name",
          value: data.app_name,
        },
        {
          label: "Package Name",
          value: data.package_name,
        },
        {
          label: "Main Activity",
          value: data.main_activity,
        },
        {
          label: "Target SDK",
          value: data.target_sdk,
        },
        {
          label: "Min SDK",
          value: data.min_sdk,
        },
        {
          label: "Max SDK",
          value: data.max_sdk,
        },
        {
          label: "Android Version Name",
          value: data.version_name,
        },
        {
          label: "Android Version Code",
          value: data.version_code,
        },
      ]
    : [
        {
          label: "App Name",
          value: data.app_name,
        },
        {
          label: "App Type",
          value: data.app_type,
        },
        {
          label: "Identifier",
          value: data.bundle_id,
        },
        {
          label: "SDK Name",
          value: data.sdk_name,
        },
        {
          label: "Version",
          value: data.app_version,
        },
        {
          label: "Build",
          value: data.build,
        },
        {
          label: "Platform Version",
          value: data.platform,
        },
        {
          label: "Min OS Version",
          value: data.min_os_version,
        },
        {
          label: "Min OS Version",
          value: (data?.bundle_supported_platforms || []).join(", "),
        },
      ];
};
const useInfoStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    rowGap: 30,
    flexDirection: "column",
  },
}));

const InformationSection = ({
  /* expanded = false */ fileInfo,
  AppInfo,
  AppComponents,
  data,
  appType,
}) => {
  const classes = useInfoStyles();
  const [expanded, setExpanded] = useState();
  return (
    <div className={classes.container}>
      <ExpandedPanel
        title="File Information"
        onChange={() => setExpanded(expanded !== "fileInfo" ? "fileInfo" : "")}
        expanded={expanded === "fileInfo"}
      >
        <BoxDetails
          data={[
            {
              label: "File Name",
              value: data.file_name,
            },
            {
              label: "Size",
              value: data.size,
            },
            {
              label: "MD5",
              value: data.md5,
            },
            {
              label: "SHA1",
              value: data.sha1,
            },
            {
              label: "SHA256",
              value: data.sha256,
            },
          ]}
        />
      </ExpandedPanel>
      <ExpandedPanel
        title="App Information"
        onChange={() => setExpanded(expanded !== "appInfo" ? "appInfo" : "")}
        expanded={expanded === "appInfo"}
      >
        <BoxDetails data={getAppInfoDetails(data, appType)} />
      </ExpandedPanel>
      {appType === "apk" && (
        <ExpandedPanel
          title="App Components"
          onChange={() =>
            setExpanded(expanded !== "appComponent" ? "appComponent" : "")
          }
          expanded={expanded === "appComponent"}
        >
          <BoxDetails
            data={[
              {
                label: "Activities",
                value: data.activities.length,
              },
              {
                label: "Services",
                value: data.services.length,
              },
              {
                label: "Receivers",
                value: data.receivers.length,
              },
              {
                label: "Providers",
                value: data.providers.length,
              },
              {
                label: "Exported Activities",
                value: data.exported_count.exported_activities,
                render: (val) => <span style={{ color: "red" }}>{val}</span>,
              },
              {
                label: "Exported Services",
                value: data.exported_count.exported_services,
                render: (val) => <span style={{ color: "red" }}>{val}</span>,
              },
              {
                label: "Exported Receivers",
                value: data.exported_count.exported_receivers,
                render: (val) => <span style={{ color: "red" }}>{val}</span>,
              },
              {
                label: "Exported Providers",
                value: data.exported_count.exported_providers,
                render: (val) => <span style={{ color: "red" }}>{val}</span>,
              },
            ]}
          />
        </ExpandedPanel>
      )}
      {data.binary_info && (
        <ExpandedPanel
          title="Binary Information"
          onChange={() =>
            setExpanded(expanded !== "binaryInfo" ? "binaryInfo" : "")
          }
          expanded={expanded === "binaryInfo"}
        >
          <BoxDetails
            data={[
              {
                label: "Arch",
                value: data.binary_info.arch,
              },
              {
                label: "Sub Arch",
                value: data.binary_info.subarch,
              },
              {
                label: "Bit",
                value: data.binary_info.bit,
              },
              {
                label: "Endian",
                value: data.binary_info.endian,
              },
            ]}
          />
        </ExpandedPanel>
      )}
    </div>
  );
};

export default InformationSection;
