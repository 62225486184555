import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  content: {
    padding: "0 24px",
    width: "100%",
    position: "relative",
  },
}));
