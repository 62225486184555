import { CONSTANTS } from "redux/_businessUnits/businessUnits.constants";
import unionBy from "lodash.unionby";
import isArray from "lodash.isarray";

const initialState = {
  loading: false,
  data: [],
};

export const businessUnits = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.BUSINESS_UNITS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.BUSINESS_UNITS_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.BUSINESS_UNITS_SAVE_DATA:
      return {
        ...state,
        data: isArray(data)
          ? unionBy(state.data, data, "id")
          : unionBy(state.data, [data], "id"),
      };
    default:
      return state;
  }
};
