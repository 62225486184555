import React, { useCallback, useEffect, useMemo, useState } from "react";
import Seo, { pages } from "components/Seo";
import useStyles from "./styles";
import Header from "components/PrivacyReports/Report/components/Header";
import useQuery from "hooks/useQuery";
import { getAccountData } from "redux/_account/account.selectors";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";
import { selectPlanInfo } from "redux/_plans/plans.selectors";
import { fetchReportJSON } from "redux/_reports/reports.service";
import { connect } from "react-redux";
import ReactFullpage from "@fullpage/react-fullpage";
import { /* getSectionsColorsFor, */ isHeaderHidden } from "./helpers";
import { getSectionComponents } from "./Sections";
import { authHeader } from "helpers/auth-header";
import Loader from "components/Loader";
import { FLAVORS } from "components/PrivacyReports/Report/constants";
import moment from "moment";
import config from "config";
import FullPageComponent from "pages/AppReport/FullPage";

export const getSecurityReportData = (reportId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/security-scans`;

  return fetch(`${url}/${reportId}/`, requestOptions)
    .then((response) => response.json())
    .then(async (response) => {
      if (!response.data.results_json)
        return {
          ...response.data,
          resource: response.data.document,
          jsonData: false,
          website: response.data.url,
        };
      return await fetch(response.data.results_json)
        .then((data) => data.json())
        .then((data) => ({
          ...data,
          resource: response.data.document,
          scanDate: response.data.created_at,
          website: response.data.url,
          jsonData: true,
        }));
    });
};

const SecurityReportPage = ({ location, report = {}, history = {} }) => {
  const [anchors, setAnchors] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();
  const query = useQuery();
  const reportId = query.get("r");
  const reportType = query.get("c");
  const [currentData, setCurrentData] = useState({});
  // const [colors, setColors] = useState(
  //   getSectionsColorsFor(currentData.app_type || "apk")
  // );
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getSecurityReportData(reportId)
      .then((data) => {
        setCurrentData(data);
        // setColors(getSectionsColorsFor(data.app_type));
        // setAnchors(ORDERED_ANCHORS[data.app_type]);
      })
      .catch((err) => console.log({ errorAPI: err }))
      .finally(() => setLoading(false));
  }, [reportId]);

  const appType = useMemo(() => currentData?.app_type || "apk", [currentData]);
  const sections = useCallback(
    ({ ...props }) => {
      return getSectionComponents({
        data: currentData,
        ...props,
        // // data: {},
        // goToNextSectionAction,
        // appType,
        // reportType,
        // reportId,
        // loading,
        // current,
        // moveToSection,
        // classes,
      });
    },
    [currentData]
  );
  const anchorList = useMemo(() => {
    const anchorElements = currentData?.scan_output?.output_json
      ?.filter((scan) => scan.vuln_evidence.type === "table")
      .map((scan) => scan?.name.replaceAll(" ", "_"));
    return anchorElements
      ? ["landing", ...anchorElements, "text", "ScanCoverageInformation"]
      : [];
  }, [currentData]);
  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />
      <div
        id="page-wrap"
        style={
          !currentData?.jsonData
            ? {
                overflow: "hidden",
                height: "100vh",
              }
            : {}
        }
      >
        <Header
          hidden={isHeaderHidden(location)}
          url={currentData?.website}
          availableDates={
            !loading && [moment(currentData?.scanDate).format("YYYY-MM-DD")]
          }
          previousRouteKey="zenSecurityScans"
          loading={loading}
          setDate={setDate}
          reportType={reportType}
          flavor={FLAVORS.full}
          history={history}
        />
        {!loading ? (
          !currentData.jsonData ? (
            <object
              data={currentData.resource}
              type="application/pdf"
              width="100%"
              height="100%"
              style={{
                marginTop: "151px",
                height: "calc(100vh - 151px)",
              }}
            >
              <p>
                Alternative text - include a link{" "}
                <a href={currentData.resource}>to the PDF!</a>
              </p>
            </object>
          ) : (
            <FullPageComponent
              sectionsColor={[
                "#1a3586",
                ...(anchorList.length > 0
                  ? Array(anchorList.length - 1).fill("#ffffff")
                  : []),
                "#ffffff",
              ]}
              anchors={anchorList}
              render={({ state, fullpageApi }) => {
                const goToNextSectionAction = fullpageApi
                  ? () => fullpageApi.moveSectionDown()
                  : undefined;
                const moveToSection = fullpageApi && fullpageApi.moveTo;
                const section = fullpageApi && fullpageApi.getActiveSection();
                const current = section && section.anchor;

                return (
                  <ReactFullpage.Wrapper>
                    {sections({
                      goToNextSectionAction,
                      appType,
                      reportType,
                      reportId,
                      loading,
                      current,
                      moveToSection,
                      classes,
                    })}
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          )
        ) : (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  isAuthenticated: state.authentication.isAuthenticated,
  currentReportJSON: getCurrentReportJSON(state),
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  // getReportJSON: (url) => dispatch(fetchReportJSON(url)),
  // getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityReportPage);
