import React from "react";
import { Box } from "@material-ui/core";
import { Field } from "react-final-form";
import SectionWrapper from "components/FormComponents/SectionWrapper";
import {
  FormTextInput,
  FormSelectInput,
} from "components/FormComponents/Inputs";
import DeleteButton from "components/SettingsSections/components/deleteButton";
import { services } from "../constants";
import useStyles from "./styles";

const Section1 = (props) => {
  const { loading, onDeleteClick, showDeleteButton } = props;
  const classes = useStyles();
  const identity = (value) => value;
  return (
    <SectionWrapper
      title="Basic Information"
      subtitle="All fields are required."
      leftContent={showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
    >
      <Box style={{ display: "flex" }}>
        <Field
          name="name"
          component={FormTextInput}
          label="Name"
          className={classes.fieldSm}
          helperText="Set integration name"
          disabled={loading}
          parse={identity}
        />
        <Field
          name="service_id"
          label="Service"
          component={FormSelectInput}
          className={classes.fieldMd}
          disabled={loading}
          parse={identity}
          options={services}
          helperText="Choose a service from the list"
        />
      </Box>
    </SectionWrapper>
  );
};

export default Section1;
