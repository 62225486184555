import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-colCellCenter': {
      justifyContent: 'left',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellTitle': {
      display: 'flex',
      justifyContent: 'left',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
    },
    '&[role="grid"]': {
      border: `1px solid ${theme.palette.grey[500]}`,
      height: '700px'
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 2,
    color: theme.palette.grey[200],
  },
  table: {
    marginTop: 40,
    border: `1px solid ${theme.palette.grey[500]}`,
    width: 500,
    borderTop: "none",
  },
  col1: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: "none",
    borderLeft: "none",
    padding: 5,
    textTransform: "capitalize",
    width: "30%",
  },
  col2: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderBottom: "none",
    borderLeft: "none",
    borderRight: "none",
    padding: 5,
    width: "70%",
  },
  userGuide: {
    marginTop: 20,
  },
  paginationContainer: {
    paddingBottom: theme.spacing(5),
  },
  backdrop: {
    position: "absolute",
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  container: {
    overflow: "hidden",
    width: "100%",
  },
  checkbox: {
    margin: theme.spacing(1, 0, 2),
    color: theme.palette.grey[800],
    [theme.breakpoints.up("sm")]: {
      marginLeft: 123,
    },
  },
  dialog: {
    "& > .MuiDialog-scrollPaper": {
      "& > .MuiDialog-paper": {
        width: 370,
        minWidth: "0px !important",
      },
    },
  },
  content: {
    marginBottom: "10px !important",
  },
  iconContainer: {
    width: 30,
    height: 30,
    boxShadow: "1px 2px 11px -1px rgba(0, 0, 0, 0.25)",
    borderRadius: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: 20,
  },
  dataSourceName: {
    padding: 4,
    display: "flex",
    alignItems: "center",
    gap: 10,
    color: "#007bff",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  status:{
    lineHeight:  "normal",
  },
  disabled: {
    padding: 4,
    gap: 10,
    display: "flex",
    pointerEvents: "none",
    color: theme.palette.text.disabled,
    textDecoration: "none",
    cursor: "default",
  },
}));
