import { WEB_REPORT_TYPES } from "../../../WebReport";

export const passed = (items) =>
  items.reduce((accumulator, item, index) => {
    const booleanCondition = !item.reverse
      ? item.value === 100
      : item.value === 0;
    const percentageCondition = !item.reverse
      ? item.value > 85
      : item.value < 25;
    let patternMatching = index !== 0;
    if (WEB_REPORT_TYPES.sitemap) {
      patternMatching = ![0, 1, 2].includes(index);
    }

    const passed = patternMatching ? booleanCondition : percentageCondition;
    return accumulator + (passed ? 1 : 0);
  }, 0);

export const diff = (scores, key) => {
  if (scores.length < 2) return null;
  const lastRecord = scores[scores.length - 1];
  const penultimateRecord = scores[scores.length - 2];

  // replacing 0 preventing Infinity and NaN values and the difference is negligible
  const last = lastRecord[key] === 0 ? 0.00001 : lastRecord[key];
  const pent = penultimateRecord[key] === 0 ? 0.00001 : penultimateRecord[key];
  return (last * 100) / pent - 100;
};
