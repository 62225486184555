import React, { useEffect, useMemo, useState } from "react";
import Seo, { pages } from "components/Seo";
import useStyles from "./styles";
import Header from "components/PrivacyReports/Report/components/Header";
import useQuery from "hooks/useQuery";
import { getAccountData } from "redux/_account/account.selectors";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";
import { getCurrentReportJSON } from "redux/_reports/reports.selectors";
import { selectPlanInfo } from "redux/_plans/plans.selectors";
import { fetchReportJSON } from "redux/_reports/reports.service";
import { connect } from "react-redux";
import FullPageComponent from "./FullPage";
import ReactFullpage from "@fullpage/react-fullpage";
import { getSectionsColorsFor, isHeaderHidden } from "./helpers";
import { getSectionComponents } from "./Sections";
import { authHeader } from "helpers/auth-header";
import Loader from "components/Loader";
import { ORDERED_ANCHORS } from "./constants";
import { FLAVORS } from "components/PrivacyReports/Report/constants";
import config from "config";
import { getProviders } from "redux/_datasources/datasources.service";
import moment from "moment";

export const getDataSourceReportData = (reportId) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/data-scans/`;

  return fetch(`${url}${reportId}/`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log({ data });
      return data;
    });
};

const DataSourceReport = ({ location, report = {}, history = {} }) => {
  const [anchors, setAnchors] = useState(ORDERED_ANCHORS);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();
  const query = useQuery();
  const reportId = query.get("r");
  const [currentData, setCurrentData] = useState({});
  const [colors, setColors] = useState(getSectionsColorsFor());
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getDataSourceReportData(reportId)
      .then((data) => {
        getProviders()
          .then((providers) => {
            data.provider = (providers?.results || []).find(
              (provider) => provider.id === data.provider
            );
            setCurrentData(data);
          })
          .finally(() => setLoading(false));
        // setColors(getSectionsColorsFor());
        // setAnchors(ORDERED_ANCHORS);
      })
      .catch((err) => console.log("ERRORRRR", { errorAPI: err }));
  }, []);

  return (
    <div id="outer-container">
      <Seo {...pages.privacyScanner} />
      <div id="page-wrap" className={classes.reportPage}>
        <Header
          hidden={isHeaderHidden(location)}
          url={currentData.resource}
          availableDates={
            !loading && [moment(currentData?.created).format("YYYY-MM-DD")]
          }
          // date={date}
          loading={loading}
          setDate={setDate}
          reportType={"c"}
          flavor={FLAVORS.full}
          history={history}
          previousRouteKey="zenDataFileScans"
        />
        {!loading ? (
          <FullPageComponent
            sectionsColor={colors}
            anchors={anchors}
            render={({ state, fullpageApi }) => {
              const goToNextSectionAction = fullpageApi
                ? () => fullpageApi.moveSectionDown()
                : undefined;
              const moveToSection = fullpageApi && fullpageApi.moveTo;
              const section = fullpageApi && fullpageApi.getActiveSection();
              const current = section && section.anchor;

              return (
                <ReactFullpage.Wrapper>
                  {getSectionComponents({
                    goToNextSectionAction,
                    data: currentData,
                    reportType: "c",
                    reportId,
                    loading,
                    current,
                    moveToSection,
                    classes,
                  })}
                </ReactFullpage.Wrapper>
              );
            }}
          />
        ) : (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  isAuthenticated: state.authentication.isAuthenticated,
  currentReportJSON: getCurrentReportJSON(state),
  planInfo: selectPlanInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReportJSON: (url) => dispatch(fetchReportJSON(url)),
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceReport);

// export default AppReportPage;
