import CONSTANTS from "./account.constants";

export const setAccountData = (data) => ({
  type: CONSTANTS.SET_ACCOUNT_DATA,
  data,
});

export const setCompanyData = (data) => ({
  type: CONSTANTS.SET_COMPANY_DATA,
  data,
});

export const setPaymentData = (data) => ({
  type: CONSTANTS.SET_PAYMENT_METHOD,
  data,
});

export const requestStart = () => ({
  type: CONSTANTS.REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.REQUEST_END,
});
