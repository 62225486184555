import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "./styles";

const CollectorItem = (props) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const { title, formComponent } = props;

  return editing ? (
    React.cloneElement(formComponent, { onCancel: () => setEditing(false) })
  ) : (
    <Box className={classes.container}>
      <Typography>{title}</Typography>
      <Button
        variant="text"
        onClick={() => setEditing(true)}
        classes={{ label: classes.button }}
      >
        Customize
      </Button>
    </Box>
  );
};

export default CollectorItem;
