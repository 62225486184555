import CheckmarkIcon from "assets/svgs-icons/checkmark.svg";
import CheckmarkIconTurquoise from "assets/svgs-icons/checkmark-turquoise.svg";
import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  main: {
    margin: "0 auto",
    "& > section": {
      position: "relative",
      width: "80%",
      margin: `${theme.spacing(4)}px auto`,
    },
    "& h2, & h3": {
      fontFamily: "Aldrich",
      fontWeight: 400,
      lineHeight: "40px",
      letterSpacing: "0.25px",
      color: theme.palette.blue[500],
    },
    "& h3": {
      fontSize: "30px",
    },
    "& p": {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "25px",
      letterSpacing: "0em",
      color: "#92969D",
      padding: "10px 0",
    },
    [theme.breakpoints.up("xs")]: {
      "& h2, & h3": {
        fontSize: "2rem",
      },
      "& h3": {
        fontSize: "25px",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& h2, & h3": {
        fontSize: "2.75rem",
      },
      "& h3": {
        fontSize: "30px",
      },
    },
  },
  masthead: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "& > .title": {
      marginTop: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
    },
    "& h1": {
      fontFamily: "Aldrich",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textAlign: "center",
      color: theme.palette.blue[500],
      textTransform: "capitalize",
    },
    "& p": {
      color: "686C78",
      margin: `${theme.spacing(2)}px auto`,
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "center",
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: 30,
      "& h1": {},
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 80,
      "& h1": {
        fontSize: "50px",
      },
    },
  },
  plans: {
    zIndex: 1,
    margin: "60px auto",
    display: "flex",
    width: ({ showAll }) => (showAll ? 800 : 540),
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100% !important",
      margin: 0,
    },
    [theme.breakpoints.up("md")]: {
      //alignItems: "stretch",
      flexDirection: "column",
      maxWidth: ({ showAll }) => (showAll ? 800 : 540),
      margin: "60px auto",
      justifyContent: "space-between",
    },
  },
  plan: {
    border: "solid 1px #EBECED",
    borderRadius: "16px",
    padding: 35,
    backgroundColor: theme.palette.white[100],
    [theme.breakpoints.down("md")]: {
      padding: "35px 20px",
    },
    "&.-highlight": {
      border: `2px solid ${theme.palette.aquamarine[100]}`,
      "& button": {
        backgroundColor: theme.palette.aquamarine[100],
        "&:hover": {
          backgroundColor: theme.palette.aquamarine[200],
        },
      },
    },
    "& > .summary": {
      paddingBottom: theme.spacing(3),
      borderBottom: `2px solid ${theme.palette.grey[100]}`,
      fontFamily: "Aldrich",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "25px",
      letterSpacing: "0em",
      "& div": {
        marginBottom: "15px",
      },
      "& > .price": {
        fontSize: 40,
        letterSpacing: "0.03em",
        textAlign: "left",
        "& span": {
          fontFamily: "Inter",
          fontSize: "16px",
          color: "#A5ADBB",
          position: "relative",
          paddingLeft: 2,
        },
      },
      "& > .title": {
        fontSize: "24px",
        color: theme.palette.blue[500],
        [theme.breakpoints.up("md")]: {
          height: 50,
        },
        [theme.breakpoints.up("lg")]: {
          height: "auto",
        },
      },
      "& > .description": {
        fontFamily: "Inter",
        fontSize: "16px",
        color: "#92969D",
      },
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginBottom: "30px",
      "& > .summary": {
        "& > .description": {
          height: "150px",
        },
      },
      "& > .features": {
        height: "360px !important",
      },
      "&.-highlight": {
        "& > .summary": {
          "& > .description": {
            height: "150px",
          },
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      "& > .summary": {
        "& > .description": {
          height: "150px !important",
        },
      },
      "& > .features": {
        height: "260px !important",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "48%",
      marginBottom: 0,
      "& > .summary": {
        "& > .description": {
          height: "150px !important",
        },
      },
      "& > .features": {
        height: "260px !important",
      },
    },
    "& > .features": {
      listStyleImage: `url(${CheckmarkIcon})`,
      paddingTop: theme.spacing(2),
      paddingLeft: 28,
      "&.-highlight": {
        listStyleImage: `url(${CheckmarkIconTurquoise})`,
      },
      "& li": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "25px",
        letterSpacing: "0em",
        color: "#6E798C",
        marginBottom: "15px",
      },
    },
    "& button": {
      width: "100%",
      color: theme.palette.blue[500],
      border: `solid 1px ${theme.palette.aquamarine[100]}`,
      borderRadius: "8px",
      padding: 10,
      margin: "30px 0 0",
      zIndex: 3,
      fontWeight: 600,
    },
  },
  middle: {
    minWidth: "100%",
    padding: `${theme.spacing(48)}px auto`,
    display: "flex",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      margin: "50px auto",
      "& > .elements": {
        flexDirection: "column",
        "& > .logos": {
          height: 270,
          "& li": {
            width: "90px",
            height: "90px",
            "&.smaller-image": {
              "& > img": {
                width: 50,
              },
            },
            "& > img": {
              maxWidth: 60,
            },
          },
        },
        "& > .content": {
          width: "100%",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      margin: "220px auto",
      "& > .elements": {
        flexDirection: "row",
        "& > .logos": {
          width: "40%",
        },
        "& > .content": {
          width: "45%",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& > .elements": {
        "& > .logos": {
          marginBottom: theme.spacing(10),
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& > .elements": {
        "& > .logos": {
          width: 520,
          height: 420,
          "& li": {
            width: "140px",
            height: "140px",
            "& > img": {
              maxWidth: 100,
            },
          },
        },
      },
    },
    "& > .elements": {
      display: "flex",
      maxWidth: "1531px",
      zIndex: 1,
      justifyContent: "space-around",
      alignItems: "center",
      width: "80%",
      padding: "120px 0",
      margin: "0 auto",
      "& > .content": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      "& > .logos": {
        listStyleType: "none",
        paddingInlineStart: 0,
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        columnGap: 30,
        rowGap: 30,
        height: "min-content",
        width: "min-content",
        "& li": {
          backgroundColor: theme.palette.white[100],
          border: "solid 1px #F4F5FB",
          borderRadius: "10px",
          boxShadow: "0px 10px 20px rgba(41, 41, 42, 0.07)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: theme.palette.blue[500],
          "&.up": {
            position: "relative",
            top: `-${theme.spacing(4)}px`,
          },
          "& > span": {
            fontFamily: "Aldrich",
            fontWeight: 400,
            paddingBottom: 5,
          },
        },
      },
    },
  },
  middleBackground: {
    width: "100%",
    height: "50vw",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  faqs: {
    zIndex: 1,
    display: "flex",
    margin: "0 auto",
    justifyContent: "space-around",
    fontStyle: "normal",
    maxWidth: "1531px",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      "& > .overview": {
        width: "100%",
      },
      "& > .questions": {
        width: "100%",
        marginTop: "40px",
        "& > .question": {
          marginBottom: 40,
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      "& > .overview": {
        width: "35%",
      },
      "& > .questions": {
        width: "45%",
        marginTop: 0,
        "& > .question": {
          marginBottom: 40,
        },
      },
    },
  },
  buttonGroup: {
    zIndex: 3,
    boxShadow: "none",
    "& > button": {
      width: "50%",
      padding: "10px 0",
      backgroundColor: "#DFE2E9",
      color: theme.palette.blue[500],
      "&:hover, &.-selected": {
        backgroundColor: "#8794BA",
        fontWeight: "bold",
      },
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      width: "500px",
      marginBottom: 0,
    },
  },
  inlineNote: {
    fontSize: 10,
    textTransform: "uppercase",
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
  },
}));
