import { makeStyles } from "@material-ui/core";
import LoginIllus from "assets/illustrations/illus_login.svg";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${LoginIllus})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  h1: {
    color: theme.palette.grey[900],
  },
  message: {
    marginBottom: theme.spacing(4),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.solidDefault,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "80%",
    maxWidth: 440,
  },
  footer: {
    marginTop: theme.spacing(5),
  },
  linkToHome: {
    color: theme.palette.blue.main,
    "&:hover": {
      color: `${theme.palette.blue[600]}`,
    },
  },
  goBack: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.blue.main,
    "&:hover": {
      color: `${theme.palette.blue[600]}`,
    },
  },
}));
