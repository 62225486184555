import { Font, StyleSheet } from "@react-pdf/renderer";
import Inter from "../../Inter.otf";
import InterBold from "../../Inter-Medium.ttf";

Font.register({
  family: "Inter",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    padding: "0 1cm .8cm 1cm",
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  brand: {
    width: 180,
    height: 50,
    transform: "translate(-65, 30)",
  },
  customBrandIcon: {
    display: "flex",
    width: "auto",
    height: 30,
  },
  pageNumber: {
    fontFamily: "Inter",
    fontSize: 10,
  },
});

export default styles;
