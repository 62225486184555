import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { resetData } from "redux/_integrations/salesforce/salesforce.actions";
import { getSalesforceConnectedApps } from "redux/_integrations/salesforce/salesforce.async.actions";
import { getSalesforceConnectedAppsData } from "redux/_integrations/salesforce/salesforce.selectors";

const useSalesforce = () => {
  const dispatch = useDispatch();
  const getConnectedApps = () => dispatch(getSalesforceConnectedApps());

  const connectedApps = useSelector(
    getSalesforceConnectedAppsData,
    shallowEqual
  );
  const reset = () => dispatch(resetData("salesforce"));

  return { getConnectedApps, connectedApps, reset };
};

export default useSalesforce;
