import humps from "humps";
import { createSelector } from "reselect";
import { ENTITIES } from "./settings.constants";

export const getEnvironmentSettingsSelected = (state) =>
  state.settings[ENTITIES.ENVIRONMENT].selected;

export const getEnvironmentSettingsData = (state) =>
  state.settings[ENTITIES.ENVIRONMENT].data;

export const getDatasourceSettingsSelected = (state) =>
  state.settings[ENTITIES.DATASOURCE].selected;

export const getDatasourceSettingsData = (state) =>
  state.settings[ENTITIES.DATASOURCE].data;

export const getDatabaseSettingsSelected = (state) =>
  state.settings[ENTITIES.DATABASE].selected;

export const getDatabaseSettingsData = (state) =>
  state.settings[ENTITIES.DATABASE].data;

export const getDatabaseTablesSettingsData = (state) =>
  state.settings[ENTITIES.DATABASE].tables;

export const getSettingsLoadingState = (state) => state.settings.loading;

export const getDatabaseTableSettingsFilters = (state) =>
  state.settings[ENTITIES.DATABASE].tableFilters;

export const getInitialSetup = (state) => state.settings.initialSetup.data;

export const getInitialSetupLoadingState = (state) =>
  state.settings.initialSetup.loading;

export const getDatabaseTablesSettingsDataFormatted = createSelector(
  [getDatabaseTablesSettingsData],
  (tables) => {
    const slackPrefix = "__slack__";

    tables.forEach((table) => {
      if (table.table_name && table.table_name.startsWith(slackPrefix)) {
        table.table_name =
          "#" +
          humps.decamelize(table.table_name.replace(slackPrefix, ""), {
            separator: "-",
          });
      }
    });

    return tables;
  }
);
