export const SECTION_ID = {
  landing: "landing",
  piiViolations: "piiViolations",
};

export const SECTION_TITLE = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.piiViolations]: "PiiViolations",
};

export const SECTION_NAME = {
  [SECTION_ID.landing]: "Landing",
  [SECTION_ID.piiViolations]: "PiiViolations",
};

export const REPORT_VERSION = {
  app: "app",
  apk: "apk",
  Swift: "Swift",
};

export const ORDERED_ANCHORS = [
  SECTION_NAME.landing,
  SECTION_NAME.piiViolations,
];

export const SECTIONS_CONTENT = {
  [SECTION_ID.landing]: {
    backgroundColor: "#1A3586",
    key: SECTION_NAME.landing,
    title: "",
    description: "",
  },
  [SECTION_ID.piiViolations]: {
    backgroundColor: "#ffffff",
    key: SECTION_NAME.piiViolations,
    title: "Pii Violations",
    description: "",
  },
};
