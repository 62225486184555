import { CONSTANTS } from "redux/_register/_register.constants.js";

export const requestStart = () => ({
  type: CONSTANTS.REGISTER_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.REGISTER_REQUEST_END,
});

export const setError = (data) => ({
  type: CONSTANTS.REGISTER_SET_ERROR,
  data,
});

export const setPlans = (data) => ({
  type: CONSTANTS.RETRIEVE_PLANS,
  data,
});
