import { CONSTANTS } from "redux/_requests/_config/config.constants";

export const requestStart = () => ({
  type: CONSTANTS.REQUESTS_CONFIG_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.REQUESTS_CONFIG_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.REQUESTS_CONFIG_SAVE_DATA,
  data,
});
