import { makeStyles } from "@material-ui/core";

const CHARGEBEE_THEME = {
  main: "#1a3586",
  background: "#f2f3f9",
  textMedium: "#565757",
  textDark: "#212128",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const MUI_FIRST_CHILD_COMPENSATION = -8;

export default makeStyles((theme) => ({
  root: {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: CHARGEBEE_THEME.background,
    width: 400,
    fontFamily: CHARGEBEE_THEME.fontFamily,
    paddingBottom: 12,
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(1.5, 4.5),
    fontWeight: 700,
    color: CHARGEBEE_THEME.main,
    background: "#fff",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
  },
  content: {
    padding: theme.spacing(1.5, 4.5),
    marginTop: MUI_FIRST_CHILD_COMPENSATION,
  },
  planMiniCard: {
    margin: "12px 0",
    backgroundColor: "#fff",
    padding: "9px 15px",
    borderRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 7px 1px",
  },
  planMiniCardTitle: {
    margin: "3px 0",
    fontWeight: 600,
    fontSize: 14,
  },
  planMiniCardInfo: {
    color: CHARGEBEE_THEME.textMedium,
    margin: "3px 0",
    fontSize: 14,
    "& > strong": {
      fontSize: 16,
      fontWeight: 600,
      color: CHARGEBEE_THEME.textDark,
    },
  },
  fields: {
    margin: "12px 0",
    "& .MuiFormControl-root": {
      "&.-error": {
        "& fieldset": {
          borderColor: "red",
        },
      },
    },
  },
  field: {
    backgroundColor: "#fff",
    borderRadius: 0,
    marginBottom: 2,
    padding: "22px 15px 8px",
  },
  label: {
    top: 5,
    left: 15,
    fontSize: 11,
    color: CHARGEBEE_THEME.textMedium,
    marginBottom: 0,
  },
  labelShrink: {
    transform: "translate(0, 1.5px)",
  },
  labelFocused: {
    "$label&": {
      color: CHARGEBEE_THEME.textMedium,
    },
  },
  inputRoot: {
    fontFamily: CHARGEBEE_THEME.fontFamily,
    fontSize: 14,
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
    "&:hover:not(.Mui-disabled)::before": {
      display: "none",
    },
    "&.override": {
      margin: 0,
    },
  },
  input: {
    padding: 0,
  },
  actions: {
    flexDirection: "column",
    padding: 0,
    marginTop: 12,
    marginBottom: 18,
  },
  submitButton: {
    background: CHARGEBEE_THEME.main,
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    fontSize: 14,
    height: 40,
    width: 200,
    borderRadius: 2,
    "&:hover": {
      background: CHARGEBEE_THEME.main,
      boxShadow: "0 3px 2px 0 rgba(0,0,0,.1)",
    },
    "&:focus": {
      boxShadow: `0 2px 2px 0 rgba(0,0,0,.1),0 0 7px 1px ${CHARGEBEE_THEME.main}`,
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#fff",
  },
  errorContainer: {
    width: "80%",
    margin: "10px auto",
    display: "flex",
    justifyContent: "center",
    visibility: "hidden",
    color: "red",
    "&.-error": {
      visibility: "visible",
    },
  },
}));
