import React, { useMemo } from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import FindingSeverityPanels from "../FindingSeverity/FindingSeverityPanels";

const OpportunitiesSection = ({ goToNextSectionAction, data, sectionKeys }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.opportunities.key}
      footerBackgroundColor={SECTIONS_CONTENT.opportunities.backgroundColor}
      hasFooter={false}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Title>{SECTIONS_CONTENT.opportunities.title}</Title>
      <FindingSeverityPanels data={data} />
    </Page>
  );
};

export default OpportunitiesSection;
