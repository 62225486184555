import { getData } from "redux/_consents/_timeline/timeline.service";
import {
  requestStart,
  requestEnd,
  saveData,
  setError,
} from "redux/_consents/_timeline/timeline.actions";
import { getFilters } from "redux/_filters/filters.selectors";

export const getTimelineChartData = () => (dispatch, getState) => {
  const state = getState();
  const filters = getFilters(state);
  dispatch(requestStart());
  return getData(filters)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(setError());
      dispatch(requestEnd());
      return Promise.reject();
    });
};
