import React from "react";
import { connect } from "react-redux";
import { Typography, Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "components/Tooltip";
import useStyles from "../styles";
import CONSTANTS from "redux/_filters/filters.constants";
import EntityRole from "components/PageWrapper/components/FiltersBar/components/Filters/components/entityRole";
import Storage from "components/PageWrapper/components/FiltersBar/components/Filters/components/storage";
import Purpose from "components/PageWrapper/components/FiltersBar/components/Filters/components/purpose";
import BusinessUnit from "components/PageWrapper/components/FiltersBar/components/Filters/components/businessUnit";
import CustomerRegion from "components/PageWrapper/components/FiltersBar/components/Filters/components/customerRegion";
import Country from "components/PageWrapper/components/FiltersBar/components/Filters/components/country";
import ConsentsCustomerVisibility from "components/PageWrapper/components/FiltersBar/components/Filters/components/consentsCustomerVisibility";
import DateRange from "components/PageWrapper/components/FiltersBar/components/Filters/components/dateRange";
import ConsentCategories from "components/PageWrapper/components/FiltersBar/components/Filters/components/consentCategory";
import ConsentsDecision from "components/PageWrapper/components/FiltersBar/components/Filters/components/consentsDecision";
import ScansDateRange from "components/PageWrapper/components/FiltersBar/components/Filters/components/scansDateRange";
import ScansVisibility from "components/PageWrapper/components/FiltersBar/components/Filters/components/scansVisibility";
import Unconsents from "components/PageWrapper/components/FiltersBar/components/Filters/components/unconsents";
import { resetAllFilters } from "redux/_filters/filters.actions";

const { TYPES } = CONSTANTS;

const Filters = (props) => {
  const classes = useStyles();

  const { filters, resetFilters } = props;

  return (
    <Box className={classes.filtersContainer}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        className={classes.titleContainer}
      >
        <Tooltip title="Reset filters" placement="right">
          <IconButton className={classes.resetButton} onClick={resetFilters}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Typography className={classes.filtersTitle}>Filters</Typography>
      </Box>
      {filters.includes(TYPES.ENTITY_ROLE) && <EntityRole />}
      {filters.includes(TYPES.STORAGE) && <Storage />}
      {filters.includes(TYPES.PURPOSE) && <Purpose />}
      {filters.includes(TYPES.BUSINESS_UNIT) && <BusinessUnit />}
      {filters.includes(TYPES.CUSTOMER_REGION) && <CustomerRegion />}
      {filters.includes(TYPES.COUNTRY) && <Country />}
      {filters.includes(TYPES.CONSENTS.DATE_RANGE) && <DateRange />}
      {filters.includes(TYPES.CONSENTS.CUSTOMER_VISIBILITY) && (
        <ConsentsCustomerVisibility />
      )}
      {filters.includes(TYPES.CONSENTS.CATEGORY) && <ConsentCategories />}
      {filters.includes(TYPES.CONSENTS.DECISION) && <ConsentsDecision />}
      {filters.includes(TYPES.CONSENTS.UNCONSENTS) && <Unconsents />}
      {filters.includes(TYPES.SCANS.DATE_RANGE) && <ScansDateRange />}
      {filters.includes(TYPES.SCANS.VISIBILITY) && <ScansVisibility />}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => dispatch(resetAllFilters()),
});

export default connect(null, mapDispatchToProps)(Filters);
