import { makeStyles } from "@material-ui/core";
import { SIZE } from "./constants";

export default makeStyles((theme) => ({
  card: ({ related }) => ({
    backgroundColor: related ? "#F9F9FA" : "transparent",
    marginTop: related ? theme.spacing(3) : 0,
    borderRadius: 10,
    "& > a": {
      textDecoration: "none",
    },
  }),
  cardContent: ({ related }) => ({
    padding: `${theme.spacing(related ? 2 : 3)}px!important`,
    backgroundColor: theme.palette.common.white,
    height: 300,
  }),
  smallContent: ({ related }) => ({
    padding: `${theme.spacing(related ? 2 : 3)}px!important`,
    backgroundColor: theme.palette.common.white,
  }),
  media: ({ size, related }) => ({
    height: size === SIZE.small ? 140 : related ? 160 : 190,
    filter: related ? "grayscale(1)" : "none",
  }),
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "18px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    color: theme.palette.blue["young"],
    marginBottom: ({ related }) => theme.spacing(related ? 0.5 : 1),
  },
  type: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    color: theme.palette.grey[300],
    marginBottom: ({ related }) => theme.spacing(related ? 0.5 : 1),
    textTransform: "capitalize",
  },
  description: ({ related }) => ({
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    color: theme.palette.grey[800],
    marginTop: theme.spacing(related ? 0.5 : 1),
    textOverflow: "ellipsis",
    height: "100px",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    ...(related
      ? {
          "-webkit-line-clamp": 2,
        }
      : {
          "-webkit-line-clamp": 5,
        }),
  }),
  options: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
  },
  option: {
    fontSize: 12,
    color: "#007bff",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dialog: {
    "& > .MuiDialog-scrollPaper": {
      "& > .MuiDialog-paper": {
        width: 370,
        minWidth: "0px !important",
      },
    },
  },
  content: {
    marginBottom: "10px !important",
  },
}));
