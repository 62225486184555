import React from "react";

const CookiesIcon = () => (
  <svg
    width={72}
    height={72}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M24.75 72C11.1015 72 0 60.894 0 47.25C0 33.6015 11.1015 22.5 24.75 22.5C38.394 22.5 49.5 33.6015 49.5 47.25C49.5 60.894 38.394 72 24.75 72ZM24.75 27C13.5855 27 4.5 36.0855 4.5 47.25C4.5 58.4145 13.5855 67.5 24.75 67.5C35.9145 67.5 45 58.4145 45 47.25C45 36.0855 35.9145 27 24.75 27Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M56.1595 47.7C55.2955 47.7 54.463 47.196 54.094 46.3455C53.6035 45.207 54.1255 43.8795 55.264 43.3845C62.698 40.1535 67.4995 32.8365 67.4995 24.75C67.4995 13.5855 58.414 4.5 47.2495 4.5C39.163 4.5 31.846 9.3015 28.6105 16.74C28.1155 17.874 26.788 18.405 25.6495 17.9055C24.511 17.4105 23.9845 16.083 24.484 14.9445C28.435 5.8635 37.372 0 47.2495 0C60.8935 0 71.9995 11.1015 71.9995 24.75C71.9995 34.632 66.1315 43.5645 57.0595 47.5155C56.767 47.637 56.461 47.7 56.1595 47.7Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M29.25 63C25.5285 63 22.5 59.9715 22.5 56.25C22.5 52.5285 25.5285 49.5 29.25 49.5C32.9715 49.5 36 52.5285 36 56.25C36 59.9715 32.9715 63 29.25 63ZM29.25 54C28.008 54 27 55.0125 27 56.25C27 57.4875 28.008 58.5 29.25 58.5C30.492 58.5 31.5 57.4875 31.5 56.25C31.5 55.0125 30.492 54 29.25 54Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M15.75 49.5C12.0285 49.5 9 46.4715 9 42.75C9 39.0285 12.0285 36 15.75 36C19.4715 36 22.5 39.0285 22.5 42.75C22.5 46.4715 19.4715 49.5 15.75 49.5ZM15.75 40.5C14.508 40.5 13.5 41.5125 13.5 42.75C13.5 43.9875 14.508 45 15.75 45C16.992 45 18 43.9875 18 42.75C18 41.5125 16.992 40.5 15.75 40.5Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M29.3396 40.5C28.0976 40.5 27.0671 39.492 27.0671 38.25C27.0671 37.008 28.0526 36 29.2946 36H29.3396C30.5816 36 31.5896 37.008 31.5896 38.25C31.5896 39.492 30.5816 40.5 29.3396 40.5Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M38.3396 49.5C37.1021 49.5 36.0671 48.492 36.0671 47.25C36.0671 46.008 37.0481 45 38.2946 45H38.3396C39.5816 45 40.5896 46.008 40.5896 47.25C40.5896 48.492 39.5816 49.5 38.3396 49.5Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M47.25 22.5C43.5285 22.5 40.5 19.4715 40.5 15.75C40.5 12.0285 43.5285 9 47.25 9C50.9715 9 54 12.0285 54 15.75C54 19.4715 50.9715 22.5 47.25 22.5ZM47.25 13.5C46.0125 13.5 45 14.508 45 15.75C45 16.992 46.0125 18 47.25 18C48.4875 18 49.5 16.992 49.5 15.75C49.5 14.508 48.4875 13.5 47.25 13.5Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
      <path
        d="M56.3396 31.5C55.1021 31.5 54.0671 30.492 54.0671 29.25C54.0671 28.008 55.0481 27 56.2946 27H56.3396C57.5816 27 58.5896 28.008 58.5896 29.25C58.5896 30.492 57.5816 31.5 56.3396 31.5Z"
        fill="#122873"
        stroke="#F2F2F2"
        strokeWidth="0.75"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={72} height={72} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CookiesIcon;
