import { Image, Page, Text, View } from "@react-pdf/renderer";
import {
  calculateAmbiguous,
  complexityName,
} from "components/PrivacyReports/Report";
import { Fragment } from "react";
import styles from "./styles";


const PrivacyPolicyPage = ({
  chart,
  complexityNameSorting,
  complexityScore,
  styles: globalStyles,
  tpsCrossMapping,
}) => (
  <View style={globalStyles.section}>
    <View>
      <Text style={globalStyles.title}>Privacy Policy Analysis</Text>
      <Text style={globalStyles.paragraph}>
        We process your privacy using our proprietary Legal NLP engine to
        identify what information you say collect from the user and its
        purpose.
      </Text>
    </View>
    <View>
      <View style={{ ...globalStyles.row, ...styles.chartRow }}>
        <Text style={styles.legend}>INFORMATION COLLECTED*</Text>
        <Text style={styles.legend}>COLLECTION REASONS*</Text>
      </View>
      <Image src={chart} />
      <Text style={styles.legend}>
        *Size represents number of occurrences in Privacy Policy
      </Text>
    </View>
    <View>
      <Text style={{ ...globalStyles.paragraph, ...styles.summary }}>
        {calculateAmbiguous(tpsCrossMapping)}% of your privacy policy is
        ambiguous
      </Text>
      {complexityScore && complexityScore!== ""  && <>
        <Text style={{ ...globalStyles.subTitle, ...styles.subTitle }}>
        Privacy Policy Complexity Score: {complexityScore.complexityScore}
      </Text>
      <View style={globalStyles.ul}>
        {Object.keys(complexityScore)
          .sort(complexityNameSorting)
          .map((key, i) =>
            complexityName[key] ? (
              <Text key={i}>
                {complexityName[key]}: {complexityScore[key]}
              </Text>
            ) : (
              <Fragment key={i}></Fragment>
            )
          )}
      </View></>}
    
    </View>
  </View>
);

export default PrivacyPolicyPage;
