import React from "react";
import clsx from "clsx";
import { Box, Button, Typography } from "@material-ui/core";

import { openChargebeePortal } from "utils/chargebee";

import useStyles from "./styles";

const SettingOptionChargebee = ({
  name,
  value,
  options,
  classes: customClasses = {},
  readOnly = true,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={clsx(classes.setting, customClasses.setting)}>
        <Typography className={clsx(classes.name, customClasses.name)}>
          {name}
        </Typography>
        <Box className={clsx(classes.valueRelated, customClasses.valueRelated)}>
          {value && (
            <Typography
              className={clsx(
                classes.value,
                customClasses.value,
                readOnly && classes.valueReadOnly
              )}
            >
              {value}
            </Typography>
          )}
          <Box className={clsx(classes.options, customClasses.options)}>
            {options.map((element) => (
              <Button
                key={element.text}
                onClick={() => openChargebeePortal()}
                className={classes.option}
              >
                {element.text}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SettingOptionChargebee;
