import React from "react";

const ChartLabel = ({ data, width, height, show }) => {
  if (!show) return <></>;
  const sortedData = data
    .sort((a, b) => (a.total > b.total ? -1 : 1))
    .slice(0, 10);

  const getName = (name) =>
    !name || name.length <= 24 ? name : name.substring(0, 24) + "...";
  return (
    <g
      transform={`translate(${width - 200} ${height / 2 - 160})`}
      fontFamily="Roboto Condensed, sans-serif"
      fontSize=".8rem"
      style={{ textTransform: "uppercase", transition: "none" }}
    >
      <text fontWeight="bold" fill="#4f4f4f">
        COUNTRIES TOP 10
      </text>
      <line
        transform="translate(0 10)"
        stroke="lightgray"
        strokeWidth=".5"
        x1="200"
      />
      {sortedData.map((countryData, i) => (
        <g key={countryData.id} transform={`translate(0 ${30 * (i + 1)})`}>
          <text fill="#4f4f4f" fontWeight="light">
            {getName(countryData.full_name)}
          </text>
          <text
            fill="#4f4f4f"
            transform="translate(200)"
            textAnchor="end"
            fontWeight="bold"
          >
            {countryData.total}
          </text>
        </g>
      ))}

      {(!data || data.length === 0) && (
        <g transform={`translate(0 30)`}>
          <text fill="#4f4f4f" fontWeight="light">
            There is no data to show.
          </text>
        </g>
      )}
    </g>
  );
};

export default ChartLabel;
