import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  chartLegendContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartLegend: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "0.75rem",
  },
  legendColor: {
    width: "10px",
    height: "10px",
    marginRight: theme.spacing(1),
    borderRadius: "50%",
  },
  legendLabel: {
    fontSize: "0.875rem",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  chartTitle: {},
}));
