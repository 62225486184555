import React from "react";

const plusIcon = (props) => {
  const { color = "#a720c4" } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85714 0.761905C6.85714 0.341116 7.19826 0 7.61905 0H8.38095C8.80174 0 9.14286 0.341116 9.14286 0.761905V15.2381C9.14286 15.6589 8.80174 16 8.38095 16H7.61905C7.19826 16 6.85714 15.6589 6.85714 15.2381V0.761905Z"
        fill={color}
      />
      <path
        d="M0 7.61905C0 7.19826 0.341116 6.85714 0.761905 6.85714H15.2381C15.6589 6.85714 16 7.19826 16 7.61905V8.38095C16 8.80174 15.6589 9.14286 15.2381 9.14286H0.761905C0.341116 9.14286 0 8.80174 0 8.38095V7.61905Z"
        fill={color}
      />
    </svg>
  );
};

export default plusIcon;
