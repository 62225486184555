import {
  getAll,
  create,
  updateTableFunctionalUse,
} from "redux/_functionalUses/functionalUses.service";
import {
  requestStart,
  requestEnd,
  saveData,
} from "redux/_functionalUses/functionalUses.actions";
import { getFunctionalUsesData } from "redux/_functionalUses/functionalUses.selectors";
import isEmpty from "lodash.isempty";
import { getDatabaseSettingsSelected } from "redux/_settings/settings.selectors";
import { setTableFunctionalUse } from "redux/_settings/settings.actions";

export const getFunctionalUses = () => (dispatch, getState) => {
  const functionalUses = getFunctionalUsesData(getState());
  if (isEmpty(functionalUses)) {
    dispatch(requestStart());
    return getAll()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const createFunctionalUse = (name) => (dispatch) => {
  dispatch(requestStart());
  return create(name)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const assignFunctionalUse = (tableId, functionalUseId) => (
  dispatch,
  getState
) => {
  const dbId = getDatabaseSettingsSelected(getState());
  dispatch(requestStart());
  return updateTableFunctionalUse(dbId, tableId, functionalUseId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(setTableFunctionalUse(tableId, functionalUseId));
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
