import React from "react";

const MicrosoftExchange = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.755 4H21.6515C21.4223 3.99992 21.1954 4.04501 20.9836 4.13269C20.7719 4.22037 20.5795 4.34892 20.4175 4.511L6.011 18.9175C5.84892 19.0795 5.72037 19.2719 5.63269 19.4836C5.54501 19.6954 5.49992 19.9223 5.5 20.1515V26.255C5.5 26.4842 5.54514 26.7111 5.63283 26.9228C5.72052 27.1345 5.84906 27.3269 6.0111 27.4889C6.17314 27.6509 6.3655 27.7795 6.57722 27.8672C6.78893 27.9549 7.01584 28 7.245 28H13.3485C13.5777 28.0001 13.8046 27.955 14.0164 27.8673C14.2281 27.7796 14.4205 27.6511 14.5825 27.489L28.989 13.0825C29.151 12.9204 29.2796 12.7281 29.3672 12.5163C29.4549 12.3046 29.5 12.0777 29.5 11.8485V5.745C29.5 5.2822 29.3162 4.83835 28.9889 4.5111C28.6617 4.18385 28.2178 4 27.755 4Z"
      fill="#28A8EA"
    />
    <path
      d="M27.755 28H21.6515C21.4223 28 21.1954 27.9549 20.9837 27.8672C20.7719 27.7796 20.5796 27.651 20.4175 27.489L17.5 24.5715V19.12C17.5 18.2925 17.8287 17.4989 18.4138 16.9138C18.9989 16.3287 19.7925 16 20.62 16H26.0715L28.989 18.9175C29.151 19.0796 29.2796 19.2719 29.3672 19.4837C29.4549 19.6954 29.5 19.9223 29.5 20.1515V26.255C29.5 26.7178 29.3162 27.1617 28.9889 27.4889C28.6617 27.8162 28.2178 28 27.755 28V28Z"
      fill="#0078D4"
    />
    <path
      d="M7.245 4H13.3485C13.5777 3.99997 13.8046 4.04509 14.0163 4.13277C14.2281 4.22044 14.4204 4.34897 14.5825 4.511L17.5 7.4285V12.88C17.5 13.7075 17.1713 14.5011 16.5862 15.0862C16.0011 15.6713 15.2075 16 14.38 16H8.9285L6.011 13.0825C5.84897 12.9204 5.72044 12.7281 5.63277 12.5163C5.54509 12.3046 5.49997 12.0777 5.5 11.8485V5.745C5.5 5.2822 5.68385 4.83835 6.0111 4.5111C6.33835 4.18385 6.7822 4 7.245 4V4Z"
      fill="#50D9FF"
    />
    <path
      opacity="0.2"
      d="M16.5 10.165V23.335C16.5011 23.4022 16.4944 23.4693 16.48 23.535C16.4345 23.8055 16.2944 24.051 16.0847 24.2277C15.8749 24.4045 15.6093 24.501 15.335 24.5H5.5V9H15.335C15.4881 8.99974 15.6397 9.02969 15.7811 9.08815C15.9226 9.1466 16.0511 9.2324 16.1594 9.34064C16.2676 9.44887 16.3534 9.5774 16.4119 9.71886C16.4703 9.86033 16.5003 10.0119 16.5 10.165Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M17 10.165V22.335C16.9958 22.7753 16.8191 23.1964 16.5077 23.5077C16.1964 23.8191 15.7753 23.9958 15.335 24H5.5V8.5H15.335C15.7761 8.50158 16.1987 8.67751 16.5106 8.98941C16.8225 9.30132 16.9984 9.7239 17 10.165V10.165Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M16.5 10.165V22.335C16.5003 22.4881 16.4703 22.6397 16.4119 22.7811C16.3534 22.9226 16.2676 23.0511 16.1594 23.1594C16.0511 23.2676 15.9226 23.3534 15.7811 23.4119C15.6397 23.4703 15.4881 23.5003 15.335 23.5H5.5V9H15.335C15.4881 8.99974 15.6397 9.02969 15.7811 9.08815C15.9226 9.1466 16.0511 9.2324 16.1594 9.34064C16.2676 9.44887 16.3534 9.5774 16.4119 9.71886C16.4703 9.86033 16.5003 10.0119 16.5 10.165Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M16 10.165V22.335C16.0003 22.4881 15.9703 22.6397 15.9119 22.7811C15.8534 22.9226 15.7676 23.0511 15.6594 23.1594C15.5511 23.2676 15.4226 23.3534 15.2811 23.4119C15.1397 23.4703 14.9881 23.5003 14.835 23.5H5.5V9H14.835C14.9881 8.99974 15.1397 9.02969 15.2811 9.08815C15.4226 9.1466 15.5511 9.2324 15.6594 9.34064C15.7676 9.44887 15.8534 9.5774 15.9119 9.71886C15.9703 9.86033 16.0003 10.0119 16 10.165V10.165Z"
      fill="black"
    />
    <path
      d="M14.8335 9H3.1665C2.52226 9 2 9.52226 2 10.1665V21.8335C2 22.4777 2.52226 23 3.1665 23H14.8335C15.4777 23 16 22.4777 16 21.8335V10.1665C16 9.52226 15.4777 9 14.8335 9Z"
      fill="#0078D4"
    />
    <path
      d="M11.2927 13.441H8.0192V15.3555H11.0917V16.5825H8.0192V18.5705H11.4672V19.792H6.5332V12.209H11.2932L11.2927 13.441Z"
      fill="white"
    />
  </svg>
);

export default MicrosoftExchange;
