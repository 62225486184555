import React from "react";

const Visa = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <image
      id="image0"
      width="24"
      height="24"
      x="0"
      y="0"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA51BMVEUAAAAVZcEVZcAVZr8SZL8VZcAVZsAUZsIWZcAVZMAUZr8UZb8VZcAWZcAVZcAVZcAWZcAVZcAVZcAVZcAVZcAVZcAVZcAVZcAVZMAVZMAUZMAWZcAVZsAVZcAZY70VZcAVZcAWZLwcccYUZcAVZcAWZb8VZcChwea5q2n7wC3BrV+fvNmIsN8vdsedt8qxqnThtj/Kr1RKiM5tn9ids72fnm/tuzYea8KZvONTjtGIk3aiq5h/q9wycK7Rr0i2qWg4fMmHsN8ja7eQtuGWr7tbk9Ohp4onccViiaB2pdqZvORkmdX///8lpQKmAAAAJnRSTlMAb95sHNfTGcnBZFjMvfLu+fr7/P3+9PPLxmZdzsIf2dAXCXLkaJnZQhMAAAABYktHREz3bxDzAAAAB3RJTUUH5AwdBQ4qZXG3xAAAAXBJREFUWMPt1OdOwzAUBWBT9t57lOlQIAUSaBLooA2zef/3oXacSm6uqe6NVFTJ55d9ZX2KjyIzZmNjM9pMlDg6pckcMzWNZ0RmZgegOZrD+bzuLFAdzhc1aIkOLWvQCh1a1aA1OXOQSaVxg64raW5us8mdPOJioer9Qyo9ZhNPHvGHQOvA1Z6ea0Kqqm0gTgQhAXIi/tKjXtONL094wzoCoTrnjWallW7e5Ik2CRK36TRrchnnqwahDRAS/Xaa72L5ka8aAcleGp9i+ZWvGgGFcvrdW/0AVSMg1XA3+4naZChRzXShqkFoE4ZidaMEqhoDqY4dF6oaBUXpp4BVo6C6nAdg1SC0ZXqPgv6T6jqFIK8P+cUgP3PyVeOgMIM8pxikfm6oahDaNkKJsWokFBurRkLwA0KBIlPVIMTN0B8ZI4gWC/0XtEN3djVojw7ta9ABHTrUIHZEdY51h52Uac7p2QDEzi8u8Uz5itnY2Iw2v9NCr+k+wDaoAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTI5VDA1OjE0OjQyKzAzOjAwwCK5yAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0yOVQwNToxNDo0MiswMzowMLF/AXQAAAAASUVORK5CYII="
    />
  </svg>
);

export default Visa;
