import SVGIcon from "components/svg-icon/svg-icon";
import React, { Fragment, useState } from "react";
import useStyles from "./styles";

const Securitys = ({ pentest }) => {
  const classes = useStyles();
  const [expanded, expand] = useState({});

  return (
    <div>
      {pentest && pentest.length ? (
        pentest
          .filter((x) => x.recommendation && x.confirmed)
          .map(
            ({
              id: key,
              name: message,
              riskDescription,
              recommendation: details,
            }) => (
              <Fragment key={key}>
                <div
                  className={classes.el}
                  onClick={() => {
                    if (expanded[key]) expand({});
                    else expand({ [key]: true });
                  }}
                >
                  <div className={classes.iconBox}>
                    <SVGIcon icon="warningOrange" />
                  </div>
                  <div className={classes.messageBox}>{message}</div>
                  <div
                    className={`${classes.chevron} ${
                      expanded[key] ? classes.upChevron : ""
                    }`}
                  >
                    <SVGIcon icon="chevronDownIcon" />
                  </div>
                </div>
                <div
                  className={`${classes.details} ${
                    expanded[key] ? classes.expanded : ""
                  }`}
                >
                  <span className={`${classes.content} scrollable-element`}>
                    <p>{riskDescription}</p>
                    <p>{details}</p>
                  </span>
                </div>
              </Fragment>
            )
          )
      ) : (
        <div className={classes.empty}>
          We were unable yo find any exposed vulnerabilities in the public part
          of your website
        </div>
      )}
    </div>
  );
};

export default Securitys;
