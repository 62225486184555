import React, { useContext, useState } from "react";
import clsx from "clsx";
import { Form, Field } from "react-final-form";

import {
  TextField,
  Box,
  CircularProgress,
  Typography,
  Input,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InsertLinkIcon from "@material-ui/icons/InsertLink";

import Button from "components/Button";
import { validateSite, validateApp } from "utils/scanValidations";

import useStyles from "./styles";

const SCAN_FORM_WIDGET_TYPE = {
  app: "app",
  main: "main",
  site: "site",
};

const ScannerTypeFormContext = React.createContext();

const SourceWidgetField = ({ classes, loading }) => {
  const { widget } = useContext(ScannerTypeFormContext);

  const validate = (value = "") =>
    widget === SCAN_FORM_WIDGET_TYPE.site
      ? validateSite(value)
      : validateApp(value);

  return (
    <Field
      name={widget}
      validate={validate}
      render={({
        input: { value, onChange, onBlur },
        meta: { touched, error, submitError },
      }) => (
        <>
          {widget === SCAN_FORM_WIDGET_TYPE.site ? (
            <TextField
              value={value}
              onChange={onChange}
              onBlur={(event) => onBlur(event)}
              className={classes.headerTextField}
              variant="outlined"
              placeholder="Example yoursite.com"
              disabled={loading}
              helperText={
                touched &&
                (error || submitError) && (
                  <span style={{ color: "red" }}>{error || submitError}</span>
                )
              }
              InputProps={{
                error: Boolean(touched && error),
                className: classes.headerInputBase,
                classes: {
                  notchedOutline: classes.headerInputOutline,
                },
              }}
              inputProps={{
                className: classes.headerInput,
              }}
            />
          ) : (
            <>
              <Input
                onChange={(event) => {
                  onChange(event.target.files[0]);
                }}
                type="file"
                onBlur={(event) => onBlur(event)}
                accept="application/vnd.android.package-archive"
              />
              {touched && (error || submitError) && (
                <span style={{ color: "red" }}>{error || submitError}</span>
              )}
            </>
          )}
        </>
      )}
    />
  );
};

const FormWidgetManager = ({ classes, loading }) => {
  const { widget, updateWidget } = useContext(ScannerTypeFormContext);
  const { widgetManager, ...remainClasses } = classes;

  return (
    <>
      {widget === SCAN_FORM_WIDGET_TYPE.main ? (
        <div className={classes.scanTypePicker}>
          <Button
            onClick={updateWidget(SCAN_FORM_WIDGET_TYPE.site)}
            startIcon={<InsertLinkIcon />}
          >
            Enter a URL
          </Button>
          <Button
            onClick={updateWidget(SCAN_FORM_WIDGET_TYPE.app)}
            startIcon={<AttachFileIcon />}
          >
            Upload an app
          </Button>
        </div>
      ) : (
        <SourceWidgetField
          classes={remainClasses}
          loading={loading}
          widget={widget}
        />
      )}
    </>
  );
};

const ScannerTypeForm = ({ onSubmit, loading, title, serverError }) => {
  const classes = useStyles();

  const [widget, setWidget] = useState(SCAN_FORM_WIDGET_TYPE.main);

  const updateWidget = (desiredWidget) => () => {
    setWidget(desiredWidget);
  };

  const scannerSubmit = (values) => {
    const { site, app } = values;
    if (site || app) {
      return onSubmit(values);
    }
  };

  return (
    <ScannerTypeFormContext.Provider value={{ widget, updateWidget }}>
      <Form onSubmit={scannerSubmit}>
        {({ handleSubmit, values }) => (
          <Box
            component="form"
            maxWidth={540}
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Typography
              component="h2"
              className={clsx(classes.title, "clear-background", "first-step")}
            >
              {title}
            </Typography>
            <div className={clsx(classes.formFields, "first-step")}>
              <FormWidgetManager classes={classes} loading={loading} />
              <Box className={classes.buttonContainer}>
                {widget !== SCAN_FORM_WIDGET_TYPE.main && (
                  <>
                    <Button
                      disabled={loading}
                      type="submit"
                      className={classes.submit}
                    >
                      Continue
                      {loading && (
                        <CircularProgress
                          className={classes.buttonLoader}
                          color="primary"
                          size={24}
                        />
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      className={classes.goBack}
                      onClick={updateWidget(SCAN_FORM_WIDGET_TYPE.main)}
                    >
                      Go Back
                    </Button>
                  </>
                )}
              </Box>
            </div>
          </Box>
        )}
      </Form>
    </ScannerTypeFormContext.Provider>
  );
};

export default ScannerTypeForm;
