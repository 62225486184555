import { combineReducers } from "redux";

import { authentication } from "redux/_authentication/authentication.reducer";
import { environments } from "redux/_environments/environment.reducer";
import { scans } from "redux/_scans/scans.reducer";
import { lastTwoScans } from "redux/_scans/last-two.reducer";
import { scansOverview } from "redux/_scans/scans-overview.reducer";
import { settings } from "redux/_settings/settings.reducer";
import { databases } from "redux/_databases/databases.reducer";
import { countries } from "redux/_countries/countries.reducer";
import { account } from "redux/_account/account.reducer";
import { wizard } from "redux/_wizard/wizard.reducer";
import { charts } from "redux/_charts/charts.reducer";
import { widget } from "redux/_widget/widget.reducer";
import { filters } from "redux/_filters/filters.reducer";
import { datasources } from "redux/_datasources/datasources.reducer";
import { functionalUses } from "redux/_functionalUses/functionalUses.reducer";
import { policies } from "redux/_consents/_policies/policies.reducer";
import { businessUnits } from "redux/_businessUnits/businessUnits.reducer";
import { applications } from "redux/_consents/_applications/applications.reducer";
import { partners } from "redux/_consents/_partners/partners.reducer";
import { config } from "redux/_consents/_config/config.reducer";
import { timeline } from "redux/_consents/_timeline/timeline.reducer";
import { map } from "redux/_consents/_map/map.reducer";
import { spotlight } from "redux/_consents/_spotlight/spotlight.reducer";
import { overview } from "redux/_consents/_overview/overview.reducer";
import { config as requestsConfig } from "redux/_requests/_config/config.reducer";
import { options } from "redux/_requests/_options/options.reducer";
import { data } from "redux/_requests/_data/data.reducer";
import { company } from "redux/_admin/_company/company.reducer";
import { users } from "redux/_admin/_users/users.reducer";
import { billing } from "redux/_admin/_billing/billing.reducer";
import { register } from "redux/_register/_register.reducer";
import { reports } from "redux/_reports/reports.reducer";
import { integrations } from "redux/_integrations/integrations.reducer";
import { slack } from "redux/_integrations/slack/slack.reducer";
import { salesforce } from "redux/_integrations/salesforce/salesforce.reducer";
import { news } from "redux/_news/news.reducer";
import { plans } from "redux/_plans/plans.reducer";
import { hrefReducer } from "redux/_reports/reports.reducer";
import { scanner } from "redux/_scanner/scanner.reducer";
import { tab } from "redux/_tabDetail/tab.reducer";
import { dataFlow } from "redux/_dataFlow/dataFlow.reducer";
import { sharingDetails } from "redux/_sharingDetails/sharingDetails.reducer";
const rootReducer = combineReducers({
  account,
  authentication,
  charts,
  consents: combineReducers({
    applications,
    policies,
    partners,
    config,
    timeline,
    map,
    spotlight,
    overview,
  }),
  countries,
  databases,
  datasources,
  environments,
  filters,
  functionalUses,
  businessUnits,
  lastTwoScans,
  scansOverview,
  settings,
  scans,
  widget,
  wizard,
  requests: combineReducers({
    config: requestsConfig,
    options,
    data,
  }),
  admin: combineReducers({
    company,
    users,
    billing,
  }),
  register,
  reports,
  hrefs: hrefReducer,
  integrations,
  news,
  slack,
  salesforce,
  plans,
  scanner,
  tab,
  dataFlow,
  sharingDetails,
});

export default rootReducer;
