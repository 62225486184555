import { makeStyles } from "@material-ui/core";

export const sectionDescriptionMixin = (theme) => ({
  margin: 0,
  color: theme.palette.grey[800],
  fontFamily: "Inter",
  "& > h2": {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  "& > p": {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.2,
  },
});

export default makeStyles((theme) => ({
  wrapper: {
    borderBottom: 0,
    "& > div": {
      maxWidth: "1082px !important",
    },
    "& h1": {
      textTransform: "none",
      letterSpacing: 0,
    },
  },
  content: {},
  container: {
    margin: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  options: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    marginBottom: theme.spacing(2),
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  tab: {
    background: "transparent",
    border: "none",
    padding: theme.spacing(1),
    fontSize: theme.typography.htmlFontSize,
    "&.-selected": {
      color: theme.palette.primary.main,
      borderBottom: "2px solid",
      cursor: "initial",
    },
  },
  descriptionContainer: {
    display: "flex",
    width: "100%",
    maxWidth: 1022,
    justifyContent: "space-between",
    height: 122,
    alignItems: "center",
  },
  description: {
    ...sectionDescriptionMixin(theme),
  },
  descriptionExtra: {},
  body: {
    width: "100%",
  },
  subSection: {
    margin: "5px 0 30px",
  },
}));
