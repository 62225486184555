import config from "config";
import qs from "utils/queryString";
import { authHeader } from "helpers/auth-header";
import { handleFetchResponse } from "utils/handle-fetch-response";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

export const getPoliciesData = (filters, selected = []) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const appliedFilters = {};
  if (filters[TYPES.CONSENTS.CUSTOMER_VISIBILITY]) {
    appliedFilters.customer = 1;
  }

  if (filters[TYPES.CONSENTS.DATE_RANGE]) {
    appliedFilters.froms = filters[TYPES.CONSENTS.DATE_RANGE][0].format(
      "YYYY-MM-DD"
    );
    appliedFilters.to = filters[TYPES.CONSENTS.DATE_RANGE][1].format(
      "YYYY-MM-DD"
    );
  }

  if (selected.length > 0) {
    appliedFilters.policies_ids = selected.join(",");
  }

  const url = `${config.apiUrl}/consents_collector/spotpolicychart${qs(
    appliedFilters
  )}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getApplicationsData = (filters, selected = []) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const appliedFilters = {};
  if (filters[TYPES.CONSENTS.CUSTOMER_VISIBILITY]) {
    appliedFilters.customer = 1;
  }

  if (filters[TYPES.CONSENTS.DATE_RANGE]) {
    appliedFilters.froms = filters[TYPES.CONSENTS.DATE_RANGE][0].format(
      "YYYY-MM-DD"
    );
    appliedFilters.to = filters[TYPES.CONSENTS.DATE_RANGE][1].format(
      "YYYY-MM-DD"
    );
  }

  if (selected.length > 0) {
    appliedFilters.applications_ids = selected.join(",");
  }

  const url = `${config.apiUrl}/consents_collector/spotappchart${qs(
    appliedFilters
  )}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};

export const getPartnersData = (filters, selected = []) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const appliedFilters = {};
  if (filters[TYPES.CONSENTS.CUSTOMER_VISIBILITY]) {
    appliedFilters.customer = 1;
  }

  if (filters[TYPES.CONSENTS.DATE_RANGE]) {
    appliedFilters.froms = filters[TYPES.CONSENTS.DATE_RANGE][0].format(
      "YYYY-MM-DD"
    );
    appliedFilters.to = filters[TYPES.CONSENTS.DATE_RANGE][1].format(
      "YYYY-MM-DD"
    );
  }

  if (selected.length > 0) {
    appliedFilters.partners_ids = selected.join(",");
  }

  const url = `${config.apiUrl}/consents_collector/spotpartnerchart${qs(
    appliedFilters
  )}`;

  return fetch(url, requestOptions)
    .then(handleFetchResponse)
    .then(({ data }) => data);
};
