const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  subtitleRoot: {
    marginBottom: "1px",
  },
  subtitle: {
    fontSize: "14px",
    color: "#000000",
  },
  icon: {
    width: "13px",
    height: "13px",
  },
  content: {
    padding: "4px",
    gap: "22px",
    display: "flex",
    flexDirection: "column",
    width: "60%",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
}));

export default useStyles;
