import React from "react";
import { Box } from "@material-ui/core";
import { Field } from "react-final-form";
import SectionWrapper from "components/FormComponents/SectionWrapper";
import { FormTextInput } from "components/FormComponents/Inputs";
import useStyles from "./styles";
import { services } from "../constants";

const Section3 = (props) => {
  const { loading, service } = props;
  const identity = (value) => value;
  const classes = useStyles();
  if (!service) return null;

  const serviceData = services.find((serv) => serv.value === service);
  return (
    <SectionWrapper
      title="Service Configuration"
      subtitle="This is required for the integration to work."
    >
      <Box>
        <Box className={classes.row}>
          <Field
            name="domain"
            label="Web API Endpoint"
            component={FormTextInput}
            className={classes.fieldSm}
            disabled={loading}
            parse={identity}
            helperText={serviceData ? `example.${serviceData.domain}` : ""}
          />
          <Field
            name="access_token"
            label="Access Token"
            component={FormTextInput}
            className={classes.fieldMd}
            disabled={loading}
            parse={identity}
            helperText={
              serviceData
                ? `You can find this information in your ${serviceData.label}'s preferences`
                : ""
            }
          />
        </Box>
      </Box>
    </SectionWrapper>
  );
};

export default Section3;
