import React from "react";

const ChevronDownIcon = ({ turn, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    style={turn ? { transform: "rotate(180deg)" } : {}}
    {...restProps}
  >
    <path
      fill="#4766B6"
      fillRule="evenodd"
      d="M12.862 4.862l.943.943L8 11.609 2.195 5.805l.943-.943L8 9.724l4.862-4.862z"
      clipRule="evenodd"
    />
  </svg>
);

export default ChevronDownIcon;
