import useStyles from "./styles";

import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    position: "relative",
    padding: theme.spacing(7, 2),
    marginTop: theme.spacing(4),
    zIndex: 0,

    "&:before": {
      content: "''",
      position: "absolute",
      backgroundColor: "#f9f9fa",
      right: "100%",
      top: 0,
      bottom: 0,
      width: "100%",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundColor: "#f9f9fa",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: -1,
      borderTopRightRadius: 32,
      borderBottomRightRadius: 32,
    },
  },
}));
