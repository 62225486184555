import { Text, View } from "@react-pdf/renderer";
import renderPoint from "../point";

const orange = "#EFAA46";
const red = "#EB5757";
const orangePoint = renderPoint(orange);
const redPoint = renderPoint(red);

const LOW = "low";
const MEDIUM = "medium";

const OpportunitiesPage = ({ styles, tips }) => (
  <View style={styles.section}>
    <View>
      <Text style={styles.title}>Opportunities</Text>
      <Text>
        Here are suggestions to improve your privacy score and reduce
        data/complaince risk.
      </Text>
    </View>
    <View style={styles.ul}>
      {tips
        .filter((x) => String(x.level) !== LOW)
        .map(({ key, level, message }) => (
          <View key={key} style={styles.li}>
            {String(level) === MEDIUM ? orangePoint : redPoint}
            <Text style={styles.liText}>{message}</Text>
          </View>
        ))}
    </View>
  </View>
);

export default OpportunitiesPage;
