import React from "react";
import { Box, Typography } from "@material-ui/core";
import WebhookIcon from '@mui/icons-material/WebhookOutlined';
import TouchAppIcon from '@material-ui/icons/TouchAppOutlined.js';

const WebhookBadge = ({ triggeredByWebhook }) => {
  const icon = triggeredByWebhook ? <WebhookIcon style={{ color: "#808080",  width:"16", height:"16" }} /> : <TouchAppIcon style={{ color: "#808080",  width:"16", height:"16" }} />;
  const label = triggeredByWebhook ? "Webhook" : "On demand";

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "max-content",
      }}
    >
      <Box
        style={{
            display: "flex",
            flexDirection: "row",
            gap: 6,
            background: "white",
            width: "fit-content",
            borderRadius: 16,
            alignItems: "center",
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 4,
            paddingBottom: 4,
        }}
      >
        {icon}
        <Typography style={{ color: "#622424", fontSize: 12 }}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default WebhookBadge;
