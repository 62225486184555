import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.blue["young"],
    backgroundColor: "white",
    marginRight: theme.spacing(2),
    "&:focus": {
      outline: "none",
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    color: theme.palette.grey[200],
    textTransform: "none",
  },
  confirmButton: {
    fontSize: "15px",
    fontWeight: 600,
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
  },
  cancelButton: {
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.blue["young"],
    textTransform: "none",
  },
}));

const DeleteButton = ({ onClick, name, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    onClick();
    setOpen(false);
  };

  return (
    <>
      <Button
        classes={{ root: classes.root }}
        onClick={(_) => setOpen(true)}
        {...props}
      >
        Delete
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogTitle disableTypography id="dialog-title" onClose={handleClose}>
          <Typography variant="h6" className={classes.dialogTitle}>
            Confirm Delete
          </Typography>
        </DialogTitle>
        <DialogContent>{`Please, confirm if you want to delete "${name}"`}</DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            elevation={0}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteButton;
