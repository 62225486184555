import { Page, Text, View } from "@react-pdf/renderer";
import ScoreChart from "components/PrivacyReports/Report/components/ScoreChart";
import styles from "./styles";

const titleCase = (sentence) => {
  return sentence.replace(
    /\w+/g,
    (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  );
};

const ScoreDetailsPage = ({ styles: globalStyles, scores, isApp }) => (
  <View style={globalStyles.section}>
    <View>
      <Text style={globalStyles.title}>Test Scores</Text>
      <Text>Broken down by risks.</Text>
    </View>
    {scores.map(({ title, description, value }) => (
      <View key={title} style={styles.scoreBox}>
        <View style={globalStyles.row}>
          {!isApp && <ScoreChart value={value} pdf />}
          <View>
            <Text style={{ ...globalStyles.subTitle, ...styles.title }}>
              {titleCase(title)}
            </Text>
            <Text style={styles.description}>{description}</Text>
          </View>
        </View>
      </View>
    ))}
  </View>
);

export default ScoreDetailsPage;
