import React, { useEffect, useState, useMemo } from "react";
import PageWrapper, { Tabs } from "components/PageWrapper";
import { Route, Switch } from "react-router-dom";
import isNil from "lodash.isnil";
import Account from "components/Profile/Account";
import EmailPreferences from "components/Profile/EmailPreferences";

const ProfilePage = ({ history, match, location }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    [location.pathname, match.path]
  );

  const [value, setValue] = useState(pathValue || "account");

  useEffect(() => {
    if (isNil(pathValue)) history.push(`/zen/profile/${value}`);
  });

  useEffect(() => {
    history.push(`/zen/profile/${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onTabChange = (value) => setValue(value);

  return (
    <PageWrapper
      title="HOME"
      subtitle="PROFILE"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          {/* <Tab value={'account'} label="Account"/> */}
          {/* <Tab value={'email-preferences'} label="Email Preferences"/> */}
        </Tabs>
      }
    >
      <Switch>
        <Route path={"/zen/profile/account"} component={Account} />
        <Route
          path={"/zen/profile/email-preferences"}
          component={EmailPreferences}
        />
      </Switch>
    </PageWrapper>
  );
};

export default ProfilePage;
