import React from 'react';
import { Box, Typography, CircularProgress, Tooltip } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ErrorIcon from '@mui/icons-material/Error';

const statusStyles = {
  failed: { icon: <ErrorIcon style={{ color: "#FB2424", fontSize: 16 }} />, text: "FAILED", textColor: "#FB2424" },
  successfully: { icon: <CallMadeIcon style={{ color: "#1b3586", fontSize: 16 }} />, text: "SCAN DETAILS", textColor: "#1b3586" },
  setting_up: { icon: <CircularProgress style={{ width: 16, height: 16, color: "#466DE8" }} />, text: "IN PROGRESS", textColor: "#1B3586" },
  default: { icon: null, text: "", textColor: "#1B3586" }
};

const getStatusProps = (status, allValuesAreZero) => {
  if (allValuesAreZero && status === 'successfully') {
    return { icon: <CallMadeIcon style={{ color: "1b3586", fontSize: 16 }} />, text: "SCAN DETAILS", textColor: "#1B3586" };
  }
  return statusStyles[status] || statusStyles.default;
};

export const ScanStatusDetail = ({ status, handleScanDetails, allValuesAreZero }) => {
  const { icon, text, textColor } = getStatusProps(status, allValuesAreZero);
  const isClickable = status === 'successfully' && !allValuesAreZero;
  
  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    minWidth: "110px",
    cursor: isClickable ? "pointer" : "default",
    opacity: 1
  };

  return (
    <Box style={boxStyle} onClick={isClickable ? handleScanDetails : undefined}>
      <Tooltip title={isClickable ? "Go to Scan Details" : ""} placement="right">
        <Box style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <Typography style={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14, color: textColor }}>
            {text}
          </Typography>
          {icon}
        </Box>
      </Tooltip>
    </Box>
  );
};
