import React from "react";
import { Box, IconButton, Button, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { FormTextInput } from "components/SettingsSections/components/inputs";
import { Close } from "@material-ui/icons";
import useStyles from "./optionFormStyles";

const OptionForm = ({ id, data, onCancel, update, loading }) => {
  const classes = useStyles();

  const onSubmit = (values) => {
    const value = values;
    update(id, value).then((_) => onCancel());
  };

  const required = (value) => (value ? undefined : "This field is required.");

  return (
    <Box className={classes.container}>
      <IconButton onClick={onCancel} className={classes.closeButton}>
        <Close />
      </IconButton>
      <Form
        onSubmit={onSubmit}
        initialValues={data || {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.content}>
              <Box className={classes.leftFields}>
                <Typography className={classes.name}>{data.name}</Typography>
                <Field
                  name={`description`}
                  component={FormTextInput}
                  label="Description *"
                  multiline={true}
                  rowsMax="3"
                  rows="3"
                  disabled={loading}
                  placeholder="Description of the policy"
                  validate={required}
                />
              </Box>
            </Box>
            <Box className={classes.buttonsContainer}>
              <Button
                disabled={loading}
                className={classes.updateButton}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default OptionForm;
