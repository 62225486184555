import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  dropdown: {
    width: "100%",
    padding: "1rem",
    zIndex: "5",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    position: "absolute",
    top: "100%",
    maxHeight: "254px",
    overflowY: "scroll",
  },

  searchedItem: {
    width: "100%",
    wordBreak: "break-all",
    padding: "12px 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },

  rightContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    position: "relative",
  },

  leftContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 180,
    },
  },

  searchLoading: {
    marginLeft: "6px",
  },
}));
