import clsx from "clsx";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Fab, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { getAccountLoadingState } from "redux/_account/account.selectors";

import MainWithDrawer from "components/Sidebar";
import Wizard from "components/Wizard";

import useStyles from "./styles";

const Layout = ({ children, loading }) => {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  return (
    <div className={classes.container}>
      <MainWithDrawer
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
      >
        <Hidden mdUp>
          {!loading && (
            <Fab
              color="primary"
              size="medium"
              className={clsx(classes.menuIcon)}
              onClick={() => setMobileDrawerOpen(true)}
            >
              <MenuIcon />
            </Fab>
          )}
        </Hidden>
        <Wizard />
        <main className={classes.main}>{children}</main>
      </MainWithDrawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: getAccountLoadingState(state),
});
const ConnectedLayout = connect(mapStateToProps)(Layout);

export default ConnectedLayout;
