import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import useStyles from "./styles";
import InputContainer from "components/Consents/Collector/Configuration/components/InputContainer";

const TextInput = (props) => {
  const {
    input: { value, onChange },
    meta,
    label,
    loading,
    onSubmit,
    ...restProps
  } = props;
  const classes = useStyles();
  const error =
    (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
    meta.touched &&
    String(meta.error || meta.submitError);

  const [isEditing, setIsEditing] = useState(false);
  const handleClick = () => {
    if (onSubmit && isEditing) onSubmit(value);
    setIsEditing(!isEditing);
  };
  useEffect(() => {
    if (loading && !error) setIsEditing(false);
    if (error) setIsEditing(true);
  }, [loading, error]);

  return (
    <InputContainer
      label={label}
      buttonText={isEditing ? "Save" : "Edit"}
      onClick={handleClick}
      disableButton={loading}
      error={error}
    >
      <TextField
        onChange={(e) => onChange(e.target.value)}
        value={value}
        error={Boolean(error)}
        classes={{ root: classes.inputContainer }}
        disabled={!isEditing || loading}
        InputProps={{
          disableUnderline: true,
        }}
        {...restProps}
      />
    </InputContainer>
  );
};

export default TextInput;
