import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import KPIs from "components/Consents/Overview/components/KPIs";
import useStyles from "./styles";
import ConsentsGranted from "components/Consents/Overview/components/ConsentsGranted";
import MostConsented from "components/Consents/Overview/components/MostConsented";
import {
  getConsentsOverviewData,
  getOverviewLoadingState,
} from "redux/_consents/_overview/overview.selectors";
import { getOverviewData } from "redux/_consents/_overview/overview.async.actions";
import EmptyOverlay from "components/EmptyOverlay";
import isEmpty from "lodash.isempty";

const Overview = ({ data, getData, loading }) => {
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const thereIsNoData =
    !loading &&
    isEmpty(data.policies) &&
    isEmpty(data.applications) &&
    isEmpty(data.partners);

  const policies = thereIsNoData ? [] : data.policies;
  const applications = thereIsNoData ? [] : data.applications;
  const partners = thereIsNoData ? [] : data.partners;

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <KPIs />
        <Box className={classes.dataContainer}>
          <EmptyOverlay
            show={thereIsNoData}
            text="No Consents collected."
            link="/zen/consents/collector"
          >
            <Box className={classes.dataContent}>
              <ConsentsGranted
                policies={policies}
                applications={applications}
                partners={partners}
                loading={loading}
              />
            </Box>
          </EmptyOverlay>
          <Box className={classes.dataContent} mt={2}>
            <EmptyOverlay
              show={thereIsNoData}
              text="No Consents collected."
              link="/zen/consents/collector"
            >
              <MostConsented
                loading={loading}
                policies={policies}
                applications={applications}
                partners={partners}
              />
            </EmptyOverlay>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsOverviewData(state),
  loading: getOverviewLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getOverviewData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
