import React, { useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import CommentInput from "components/Scans/ScanDetails/components/ScanTabs/commentInput";
import LeftComment from "components/Scans/ScanDetails/components/ScanTabs/leftComment";
import RightComment from "components/Scans/ScanDetails/components/ScanTabs/rightComment";
import { getAccountData } from "redux/_account/account.selectors";
import { getData } from "redux/_account/account.async.actions";
import { connect } from "react-redux";
import isEmpty from "utils/is-empty";
import Loader from "components/Loader";

const useStyles = makeStyles((theme) => ({
  noComments: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.palette.grey[200],
    paddingTop: "24px",
  },
  container: {
    height: "120px",
    paddingBottom: "16px",
    overflowY: "scroll",
  },
}));

const Comments = ({
  account,
  fetchAccountData,
  data,
  loading,
  onCommentSubmit,
}) => {
  const classes = useStyles();
  useEffect(() => {
    fetchAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box className={classes.container}>
        {loading ? (
          <Loader />
        ) : isEmpty(data) ? (
          <Typography className={classes.noComments}>
            No comments about this scan yet.{" "}
            <span role="img" aria-label="">
              👏
            </span>
          </Typography>
        ) : (
          data.map((comment) =>
            account &&
            account.id &&
            parseInt(account.id) === parseInt(comment.author.id) ? (
              <RightComment {...comment} />
            ) : (
              <LeftComment {...comment} />
            )
          )
        )}
      </Box>
      <CommentInput loading={loading} onSubmit={onCommentSubmit} />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountData: (_) => dispatch(getData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
