export const WEB_TEST_SCORES_ITEMS = [
  {
    title: "third-party trackers",
    value: 0,
    link: "https://www.consumer.ftc.gov/articles/0042-online-tracking",
    description:
      "We analyze third party trackers you have on the site, check the entity they belong to, the function they serve, and their standing in the market to come up with a consolidated risk score.",
  },
  {
    title: "fingerprinting",
    value: 0,
    link: "https://clearcode.cc/blog/device-fingerprinting/",
    description:
      "This score represents the level of fingerprinting on your assets. Although most privacy laws currently don't mandate disclosure regarding fingerprinting, the objective of the score is to provide an overview of fingerprinting level.",
    reverse: true,
  },
  {
    title: "https traffic",
    link: "https://ahrefs.com/blog/what-is-https/",
    value: 0,
    description:
      "We check the SSL certificate's validity and all the requests made from your site to identify any non-secure communications. 100 score means all requests are secure, 0 means none are.",
  },
  {
    title: "has cookie manager",
    link: "https://www.privacypolicies.com/blog/cookie-consent-examples",
    value: 0,
    description:
      "Cookie Manager is an option for end users to easily edit cookies the permission during the session. This is a mandate required by most privacy regulations currently in place.",
  },
  {
    title: "has privacy link",
    link: "https://piwik.pro/blog/elements-gdpr-compliant-privacy-policy/",
    value: 0,
    description:
      "A Privacy Policy is a statement or a legal document that states how a company or website collects, handles and processes data of its customers and visitors. It explicitly describes whether that information is kept confidential, or is shared with or sold to third parties.",
  },
  {
    title: "has terms link",
    link:
      "https://privacyterms.io/privacy/privacy-policy-vs-terms-and-conditions/",
    value: 0,
    description:
      "Terms and conditions agreement (T&C) is a page on a website that sets out the rights and responsibilities of anyone using the site. It effectively forms a contract between the site and the user.",
  },
  {
    title: "has cookie message on first load",
    value: 0,
    description:
      "If your website uses cookies, you may need to be providing cookies notification messages. These simple pop-up boxes are important for legal compliance.",
  },
];

export const WEB_REPORT_TYPES = {
  page: "page",
  sitemap: "sitemap",
};
