import img from "assets/images/metadata-img.png";

const image = `http://zendata.xyz${img}`;

const ogCommon = {
  image,
  site_name: "Zendata",
  type: "website",
  locale: "en_US",
  "image:width": 1200,
  "image:height": 628,
};

const twitterCommon = {
  card: "summary_large_image",
  site: "@zendataxyz",
  creator: "@zendataxyz",
  image,
};

export default {
  home: {
    title: "ZENDATA: Frictionless privacy compliance and data management.",
    description:
      "Zendata takes care of your privacy compliance needs from start to finish so you can focus on your business.",
    openGraph: {
      title: "ZENDATA: Frictionless privacy compliance and data management.",
      url: "http://zendata.xyz/",
      description:
        "Zendata takes care of your privacy compliance needs from start to finish so you can focus on your business.",
      ...ogCommon,
    },
    twitter: {
      title: "ZENDATA: Frictionless privacy compliance and data management.",
      description:
        "Zendata takes care of your privacy compliance needs from start to finish so you can focus on your business.",
      url: "http://zendata.xyz/",
      ...twitterCommon,
    },
  },
  faqs: {
    title: "FAQs - Zendata",
    description: "Frequently Asked Questions (FAQs) about Zendata",
    openGraph: {
      title: "FAQs - Zendata",
      url: "http://zendata.xyz/faqs",
      description: "Frequently Asked Questions (FAQs) about Zendata",
      ...ogCommon,
    },
    twitter: {
      title: "FAQs - Zendata",
      description: "Frequently Asked Questions (FAQs) about Zendata",
      url: "http://zendata.xyz/faqs",
      ...twitterCommon,
    },
  },
  privacyScanner: {
    title: "Privacy Scanner - Zendata",
    description:
      "Understand the cookies being used by your website and how the privacy laws’ impact on them.",
    openGraph: {
      title: "Privacy Scanner - Zendata",
      url: "http://zendata.xyz/privacy-scanner",
      description:
        "Understand the cookies being used by your website and how the privacy laws’ impact on them.",
      ...ogCommon,
    },
    twitter: {
      title: "Privacy Scanner - Zendata",
      description:
        "Understand the cookies being used by your website and how the privacy laws’ impact on them.",
      url: "http://zendata.xyz/privacy-scanner",
      ...twitterCommon,
    },
  },
  upgradePlan: {
    title: "Upgrade Plan - Zendata",
    description: `Bring automated privacy solutions to your company. Let's talk!`,
    openGraph: {
      title: "Upgrade Plan - Zendata",
      url: "http://zendata.xyz/upgrade-plan",
      description: `Bring automated privacy solutions to your company. Let's talk!`,
      ...ogCommon,
    },
    twitter: {
      title: "Upgrade Plan - Zendata",
      description: `Bring automated privacy solutions to your company. Let's talk!`,
      url: "http://zendata.xyz/upgrade-plan",
      ...twitterCommon,
    },
  },
  plans: {
    title: "Plans & Pricing - Zendata",
    description: `Bring automated privacy solutions to your company. Let's talk!`,
    openGraph: {
      title: "Plans & Pricing - Zendata",
      url: "http://zendata.xyz/upgrade-plan",
      description: `Bring automated privacy solutions to your company. Let's talk!`,
      ...ogCommon,
    },
    twitter: {
      title: "Plans & Pricing - Zendata",
      description: `Bring automated privacy solutions to your company. Let's talk!`,
      url: "http://zendata.xyz/upgrade-plan",
      ...twitterCommon,
    },
  },
  login: {
    title: "Sign In - Zendata",
    description:
      "Sign into Zendata to take care of your privacy compliance needs.",
    openGraph: {
      title: "Sign In - Zendata",
      url: "http://zendata.xyz/login",
      description:
        "Sign into Zendata to take care of your privacy compliance needs.",
      ...ogCommon,
    },
    twitter: {
      title: "Sign In - Zendata",
      description:
        "Sign into Zendata to take care of your privacy compliance needs.",
      url: "http://zendata.xyz/login",
      ...twitterCommon,
    },
  },
  resetPassword: {
    title: "Reset Password - Zendata",
    description:
      "Sign into Zendata to take care of your privacy compliance needs.",
    openGraph: {
      title: "Reset Password - Zendata",
      url: "http://zendata.xyz/forgot-password",
      description:
        "Sign into Zendata to take care of your privacy compliance needs.",
      ...ogCommon,
    },
    twitter: {
      title: "Reset Password - Zendata",
      description:
        "Sign into Zendata to take care of your privacy compliance needs.",
      url: "http://zendata.xyz/forgot-password",
      ...twitterCommon,
    },
  },
  register: {
    title: "Sign Up - Zendata",
    description:
      "Sign up for a Zendata account to begin improving your business privacy.",
    openGraph: {
      title: "Sign Up - Zendata",
      url: "http://zendata.xyz/register",
      description:
        "Sign up for a Zendata account to begin improving your business privacy.",
      ...ogCommon,
    },
    twitter: {
      title: "Sign Up - Zendata",
      description:
        "Sign up for a Zendata account to begin improving your business privacy.",
      url: "http://zendata.xyz/register",
      ...twitterCommon,
    },
  },
  privacyCentral: {
    title: "Zendata Press Appearances",
    description:
      "Zendata Press Appearances is our information Hub on All-things Privacy. You will find a Glossary of Worldwide Regulations and more.",
    openGraph: {
      title: "Zendata Press Appearances",
      url: "http://zendata.xyz/press-appearances",
      description:
        "Zendata Press Appearances is our information Hub on All-things Privacy. You will find a Glossary of Worldwide Regulations and more.",
      ...ogCommon,
    },
    twitter: {
      title: "Zendata Press Appearances",
      description:
        "Zendata Press Appearances is our information Hub on All-things Privacy. You will find a Glossary of Worldwide Regulations and more.",
      url: "http://zendata.xyz/press-appearances",
      ...twitterCommon,
    },
  },
  privacyCentralCcpa: {
    title: `CCPA's Knowledge Base - Privacy Central - Zendata`,
    description: `Welcome to our CCPA's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
    openGraph: {
      title: `CCPA's Knowledge Base - Privacy Central - Zendata`,
      url: "http://zendata.xyz/press-appearances/ccpa",
      description: `Welcome to our CCPA's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
      ...ogCommon,
    },
    twitter: {
      title: `CCPA's Knowledge Base - Privacy Central - Zendata`,
      description: `Welcome to our CCPA's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
      url: "http://zendata.xyz/press-appearances/ccpa",
      ...twitterCommon,
    },
  },
  privacyCentralGdpr: {
    title: `GDPR's Knowledge Base - Privacy Central - Zendata`,
    description: `Welcome to our GDPR's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
    openGraph: {
      title: `GDPR's Knowledge Base - Privacy Central - Zendata`,
      url: "http://zendata.xyz/press-appearances/gdpr",
      description: `Welcome to our GDPR's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
      ...ogCommon,
    },
    twitter: {
      title: `GDPR's Knowledge Base - Privacy Central - Zendata`,
      description: `Welcome to our GDPR's Knowledge Base home page. This section is a resource for Privacy Officers, Site Administrators and Developers.`,
      url: "http://zendata.xyz/press-appearances/gdpr",
      ...twitterCommon,
    },
  },
  blog: {
    title: "News - Privacy Central - Zendata",
    description: `All the latest breaking news on Privacy. Browse Zendata's collection of articles and commentary on Privacy & Security.`,
    openGraph: {
      title: "News - Privacy Central - Zendata",
      url: "http://zendata.xyz/press-appearances/news",
      description: `All the latest breaking news on Privacy. Browse Zendata's collection of articles and commentary on Privacy & Security.`,
      ...ogCommon,
    },
    twitter: {
      title: "News - Privacy Central - Zendata",
      description: `All the latest breaking news on Privacy. Browse Zendata's collection of articles and commentary on Privacy & Security.`,
      url: "http://zendata.xyz/press-appearances/news",
      ...twitterCommon,
    },
  },
  blogArticle: {
    title: "Privacy Central - Zendata",
    description: "",
    openGraph: {
      title: "Privacy Central - Zendata",
      url: "http://zendata.xyz/press-appearances/",
      description: "",
      ...ogCommon,
    },
    twitter: {
      title: "Privacy Central - Zendata",
      description: "",
      url: "http://zendata.xyz/press-appearances/",
      ...twitterCommon,
    },
  },
};
