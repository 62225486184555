import React from "react";

const MenuThreePoints = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#C2C4C9"
      fillRule="evenodd"
      d="M10 5a2 2 0 1 0 4 0 2 2 0 0 0-4 0zm2 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      clipRule="evenodd"
    />
  </svg>
);

export default MenuThreePoints;
