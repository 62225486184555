import React, { useEffect, useState } from "react";
import SvgIcon from "components/svg-icon/svg-icon";
import SectionWrapper from "../components/sectionWrapper";
import useStyles from "./styles";
import { ToggleButton } from "@material-ui/lab";
import { AddCircle } from "@material-ui/icons";
import { Button, Typography, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import isEmpty from "lodash.isempty";
import PaginatedBox from "components/SettingsSections/EntitySelector/components/paginatedBox";
import isNil from "lodash.isnil";
import TextInput from "components/Inputs/Text";
import Search from "@material-ui/icons/Search";

const EntitySelector = ({
  newLabel,
  entityName,
  entities,
  selected = null,
  onSelect,
  withSearch,
  icons,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(entities) && isNil(selected)) onSelect(entities[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities.length]);

  const [rows, setRows] = useState(2);
  const handleRowsChange = (e) => setRows(e.target.value);

  const [search, setSearch] = useState("");
  const regex = new RegExp(search, "gi");
  const filteredEntities = entities.filter(
    (entity) => String(entity.name).search(regex) !== -1
  );

  const buttons = filteredEntities.map(
    ({ id, name, icon = "databaseConfig" }, idx) => (
      <ToggleButton
        className={classes.entityButton}
        classes={{
          root: classes.entityButton,
          label: classes.entityButtonLabel,
        }}
        key={idx}
        value={id}
      >
        <SvgIcon source={icons} icon={icon} />
        <Typography noWrap className={classes.buttonTypography}>
          {name}
        </Typography>
      </ToggleButton>
    )
  );

  if (entities.length === 0) return null;

  return (
    <SectionWrapper
      title={`${entityName}s List`}
      subtitle="Select one to edit its information."
      leftContent={
        withSearch ? (
          <TextInput
            value={search}
            onChange={setSearch}
            label="Search"
            placeholder={`${entityName} name`}
            className={classes.sidebarInput}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search className={classes.sidebarInputIcon} />
                </InputAdornment>
              ),
            }}
          />
        ) : null
      }
      hideDivider
    >
      <PaginatedBox
        rows={rows}
        onSelect={onSelect}
        value={selected}
        onRowsChange={handleRowsChange}
        entityName={entityName}
        search={search}
        total={entities.length}
        fixed={
          newLabel && (
            <Button
              onClick={(_) => onSelect(null)}
              classes={{
                root: classes.addButton,
                label: classes.addButtonLabel,
              }}
            >
              <AddCircle className={classes.addIcon} />
              {newLabel}
            </Button>
          )
        }
      >
        {buttons}
      </PaginatedBox>
    </SectionWrapper>
  );
};

export default withRouter(EntitySelector);
