import React from "react";
import useStyles from "./styles";

const FieldDetail = ({ field }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span className={classes.key}>{field.title}: </span>
      <span className={classes.value}>{field.value}</span>
    </div>
  );
};

export default FieldDetail;
