import { shallowEqual, useSelector } from "react-redux";
import { getSlackWorkspaces } from "redux/_integrations/slack/slack.async.actions";
import { resetData } from "redux/_integrations/slack/slack.actions";
import {
  // getSlackLoadingState,
  // getSlackWorkspaceData,
  // getSlackWorkspaceErrorState,
  // getSlackWorkspacesData,
  // getSlackWorkspacesErrorState,
  // getSlackWorkspacesMeta,
  getSlackWorkspacesInfoData,
} from "redux/_integrations/slack/slack.selectors";

// later
// data: getReportsData(state),
import { useDispatch } from "react-redux";

const useSlack = () => {
  const dispatch = useDispatch();
  const getWorkSpaces = () => dispatch(getSlackWorkspaces());

  const workSpaces = useSelector(getSlackWorkspacesInfoData, shallowEqual);
  const reset = () => dispatch(resetData("slack"));

  return { getWorkSpaces, workSpaces, reset };
};

export default useSlack;
