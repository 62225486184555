import clsx from "clsx";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";

import Button from "components/Button";

import useStyles from "./styles";

const BaseForm = ({
  form: { action, fields, onSubmit, isConfirmation = false },
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const preSubmit = (values) => {
    setLoading(true);
    const p = onSubmit(values);
    if (p && p.then)
      p.then(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={preSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.fieldSet}>
            {fields.map((field) => {
              const {
                label,
                name,
                validate,
                initialValue,
                placeholder,
                type,
                disabled,
                component,
              } = field;

              return (
                <Box key={label} className={classes.field}>
                  <Typography
                    className={clsx(
                      classes.label,
                      isConfirmation && "-confirmation"
                    )}
                    variant="subtitle1"
                  >
                    {label}
                  </Typography>
                  <Field
                    name={name}
                    validate={validate}
                    initialValue={initialValue}
                    render={({
                      input: { value, onChange, onBlur },
                      meta: { touched, error, submitError },
                    }) => (
                      <>
                        {component || (
                          <TextField
                            type={type}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            variant="outlined"
                            placeholder={placeholder}
                            disabled={loading || disabled}
                            helperText={
                              touched &&
                              (error || submitError) && (
                                <span style={{ color: "red" }}>
                                  {error || submitError}
                                </span>
                              )
                            }
                            InputProps={{
                              error: Boolean(touched && error),
                              className: classes.input,
                            }}
                          />
                        )}
                      </>
                    )}
                  />
                </Box>
              );
            })}
            <Box className={classes.buttonContainer}>
              <Button
                disabled={loading}
                type="submit"
                className={classes.submit}
              >
                {action}
                {loading && (
                  <CircularProgress
                    className={classes.buttonLoader}
                    color="primary"
                    size={24}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default BaseForm;
