import { makeStyles } from "@material-ui/core";

import IconFacebook from "assets/svgs-icons/icon-fb.svg";
import IconTwitter from "assets/svgs-icons/icon-twitter.svg";
import IconLinkedin from "assets/svgs-icons/icon-linkedin.svg";

export default makeStyles((theme) => ({
  footer: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: ({ backgroundColor }) => backgroundColor || "#fff",
    height: "648px",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
    "& svg": {
      maxWidth: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "184px",
      },
    },
    "& p": {
      color: theme.palette.grey["200"],
      fontSize: "11px",
      margin: theme.spacing(2, 0),
      [theme.breakpoints.up("lg")]: {
        lineHeight: "2.5",
        margin: "0",
      },
    },
    "& h5": {
      color: theme.palette.blue["500"],
      fontSize: "18px",
      fontWeight: "600",
      margin: theme.spacing(0, 0, 1),
    },
    "& ul": {
      listStyle: "none",
      margin: "0 0 2rem",
      padding: "0",
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        margin: "0",
      },
      "& li": {
        lineHeight: "2.5",
        marginRight: theme.spacing(5),
      },
      "& a": {
        color: theme.palette.blue["400"],
        whiteSpace: "nowrap",
      },
    },
    "& > .background": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      justifyContent: "center",
      "& > .circle": {
        zIndex: 1,
        position: "absolute",
        borderRadius: "50%",
        "&.filled": {
          background: "rgba(251, 251, 251, 0.05)",
        },
        "&.empty": {
          background: "none",
          border: "solid 2px rgba(251, 251, 251, 0.05)",
        },
      },
      [theme.breakpoints.up("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        "& > .circle": {
          "&.small": {
            width: "30px",
            height: "30px",
            "&.upper": {
              /* In order to accomplish the orbits effect */
              marginTop: "179px",
              marginLeft: "403px",
            },
            "&.lower": {
              /* In order to accomplish the orbits effect */
              marginTop: "221px",
              /* Using negative value in order to add the circle via html/css on the footer */
              marginLeft: "-486px",
            },
          },
          "&.big": {
            width: "400px",
            height: "400px",
            /* Using negative value in order to add the circle via html/css on the footer */
            marginTop: "-222px",
          },
          "&.bigger-1": {
            width: "880",
            height: "880",
            /* Using negative value in order to add the circle via html/css on the footer */
            marginTop: "-497px",
          },
          "&.bigger-2": {
            display: "none",
          },
        },
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        "& > .circle": {
          "&.small": {
            width: "30px",
            height: "30px",
            "&.upper": {
              /* In order to accomplish the orbits effect */
              marginTop: "179px",
              marginLeft: "403px",
            },
            "&.lower": {
              /* In order to accomplish the orbits effect */
              marginTop: "221px",
              /* Using negative value in order to add the circle via html/css on the footer */
              marginLeft: "-486px",
            },
          },
          "&.big": {
            width: "850px",
            height: "850px",
            /* Using negative value in order to add the circle via html/css on the footer */
            marginTop: "-467px",
          },
          "&.bigger-1": {
            width: "925px",
            height: "925px",
            /* Using negative value in order to add the circle via html/css on the footer */
            marginTop: "-497px",
          },
          "&.bigger-2": {
            width: "1000px",
            height: "1000px",
            /* Using negative value in order to add the circle via html/css on the footer */
            marginTop: "-530px",
          },
        },
      },
    },
    "& > .top": {
      backgroundColor: theme.palette.blue["500"],
      height: "15%",
      borderTop: "5px solid #37DCCE",
      transform: "skewY(178deg)",
      transformOrigin: "right",
    },
    "& > .children": {
      backgroundColor: theme.palette.blue["500"],
      height: "85%",
      [theme.breakpoints.up("xs")]: {
        height: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        height: "85%",
      },
      "& > .container": {
        paddingTop: "30px",
        [theme.breakpoints.up("lg")]: {
          paddingTop: "50px",
        },
        maxWidth: "780px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > .content": {
          zIndex: 2,
          width: "100%",
          maxWidth: "750px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.up("xs")]: {
            "& h3": {
              width: "100%",
              fontSize: "2.1em",
            },
          },
        },
      },
    },
  },
  footerNav: {
    zIndex: 1,
    width: "100%",
    display: "flex",
    color: "white",
    justifyContent: "space-between",
    "& ul": {
      listStyle: "none",
      margin: "0",
      padding: "0",
      "& a": {
        color: theme.palette.white[100],
        fontSize: "0.875rem",
        fontWeight: "500",
      },
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      height: "100px",
      marginTop: "40px",
      "& ul": {
        "&.nav": {
          width: "80%",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "center",
          margin: "5px",
        },
        "&.social": {
          width: "180px",
          justifyContent: "space-evenly",
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& ul": {
        "&.nav": {
          flexDirection: "row",
          margin: "20px",
          "&.social": {
            width: "auto",
          },
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "80px",
      "& ul": {
        "&.nav": {
          width: "auto",
        },
        "& li": {
          marginRight: theme.spacing(5),
        },
      },
    },
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
  footerNavSocial: {
    "& ul": {
      display: "flex",
      "& li": {
        marginRight: theme.spacing(0),
        lineHeight: "24px",
      },
      "& a": {
        fontWeight: "600",
      },
      [theme.breakpoints.up("xs")]: {
        "& li": {
          marginLeft: theme.spacing(0),
        },
      },
      [theme.breakpoints.up("md")]: {
        "& li": {
          marginLeft: theme.spacing(2),
        },
      },
    },
  },
  title: {
    color: "white",
    textAlign: "center",
    width: "780px",
    fontFamily: "Aldrich",
    fontWeight: "normal",
  },
  button: {
    margin: "50px 0 20px",
    width: 315,
    "&:hover": {
      textDecoration: "none",
    },
    textDecoration: "none",
  },
  link: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "30px",
    color: "white",
    width: "190px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& > svg": {
      color: "#37DCCE",
    },
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  navLink: {
    fontWeight: "500 !important",
    fontFamily: "Inter",
  },
  socialIco: {
    textAlign: "left",
    "& i": {
      display: "inline-block",
      height: "24px",
      width: "24px",
      opacity: "0.7",
      textIndent: "-9999px",
      transition: "all 0.15s ease-in-out",
    },
    "& i:hover": {
      opacity: "1",
    },
  },
  icoTwitter: {
    background: `url(${IconTwitter}) no-repeat center center`,
  },
  icoFacebook: {
    background: `url(${IconFacebook}) no-repeat center center`,
  },
  icoLinkedin: {
    background: `url(${IconLinkedin}) no-repeat center center`,
  },
  alignRight: {
    textAlign: "right",
  },
}));
