import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#780e18",
  },
  root: {
    borderRadius: "3px",
    border: "solid 1px #780e18",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
      border: "solid 1px #A01320",
      "& > span": {
        color: "#A01320",
      },
    },
  },
  dialogWrap: {
    padding: "32px",
  },
  dialogTitleWrap: {
    padding: "0",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    color: theme.palette.grey[200],
    textTransform: "none",
  },
  dialogContent: {
    margin: "1.5rem 0 0",
    padding: "0",
  },
  dialogFooter: {
    margin: "2.5rem 0 0",
    padding: "0",
  },
  confirmButton: {
    fontSize: "15px",
    fontWeight: 600,
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: "white",
  },
  cancelButton: {
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.blue["young"],
    textTransform: "none",
  },
}));

const DeleteButton = ({ onClick, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    onClick();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        classes={{ root: classes.root, label: classes.label }}
        onClick={(_) => setOpen(true)}
        {...props}
      >
        <DeleteOutlined />
        Delete
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => setOpen(false)}
        open={open}
        classes={{ paperFullWidth: classes.dialogWrap }}
      >
        <DialogTitle
          disableTypography
          id="dialog-title"
          onClose={handleClose}
          className={classes.dialogTitleWrap}
        >
          <Typography variant="h6" className={classes.dialogTitle}>
            Confirm Delete
          </Typography>
        </DialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          Please, confirm if you want to delete.
        </MuiDialogContent>
        <MuiDialogActions className={classes.dialogFooter}>
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            elevation={0}
          >
            DELETE
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default DeleteButton;
