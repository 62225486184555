import React from "react";

const piiRecIcon = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.2955 9.43175H22.5V6.70448H20.4545C19.9121 6.70448 19.3918 6.48897 19.0082 6.10538C18.6246 5.72178 18.4091 5.20151 18.4091 4.65902C18.4091 3.75488 18.0499 2.88776 17.4106 2.24843C16.7713 1.6091 15.9041 1.24993 15 1.24993C14.0959 1.24993 13.2287 1.6091 12.5894 2.24843C11.9501 2.88776 11.5909 3.75488 11.5909 4.65902C11.5909 5.20151 11.3754 5.72178 10.9918 6.10538C10.6082 6.48897 10.0879 6.70448 9.54545 6.70448H7.5V9.43175H1.70455C1.25247 9.43175 0.818914 9.61133 0.49925 9.931C0.179586 10.2507 0 10.6842 0 11.1363V26.1363C0 26.5884 0.179586 27.0219 0.49925 27.3416C0.818914 27.6613 1.25247 27.8408 1.70455 27.8408H28.2955C28.7475 27.8408 29.1811 27.6613 29.5008 27.3416C29.8204 27.0219 30 26.5884 30 26.1363V11.1363C30 10.6842 29.8204 10.2507 29.5008 9.931C29.1811 9.61133 28.7475 9.43175 28.2955 9.43175ZM8.86364 8.06811H9.54545C10.4496 8.06811 11.3167 7.70894 11.956 7.06961C12.5954 6.43028 12.9545 5.56317 12.9545 4.65902C12.9545 4.11653 13.17 3.59626 13.5536 3.21267C13.9372 2.82907 14.4575 2.61357 15 2.61357C15.5425 2.61357 16.0628 2.82907 16.4464 3.21267C16.83 3.59626 17.0455 4.11653 17.0455 4.65902C17.0455 5.56317 17.4046 6.43028 18.044 7.06961C18.6833 7.70894 19.5504 8.06811 20.4545 8.06811H21.1364V9.43175H8.86364V8.06811ZM28.6364 26.1363C28.6364 26.2267 28.6004 26.3134 28.5365 26.3774C28.4726 26.4413 28.3859 26.4772 28.2955 26.4772H1.70455C1.61413 26.4772 1.52742 26.4413 1.46349 26.3774C1.39955 26.3134 1.36364 26.2267 1.36364 26.1363V11.1363C1.36364 11.0459 1.39955 10.9592 1.46349 10.8952C1.52742 10.8313 1.61413 10.7954 1.70455 10.7954H28.2955C28.3859 10.7954 28.4726 10.8313 28.5365 10.8952C28.6004 10.9592 28.6364 11.0459 28.6364 11.1363V26.1363Z"
      fill="#828282"
    />
    <path
      d="M11.4852 19.1066C11.8992 18.6086 12.1628 18.003 12.2451 17.3606C12.3274 16.7183 12.225 16.0658 11.95 15.4795C11.675 14.8932 11.2386 14.3973 10.692 14.05C10.1454 13.7027 9.51124 13.5182 8.86364 13.5182C8.21604 13.5182 7.58183 13.7027 7.03525 14.05C6.48867 14.3973 6.05232 14.8932 5.77728 15.4795C5.50224 16.0658 5.39988 16.7183 5.48219 17.3606C5.5645 18.003 5.82807 18.6086 6.24205 19.1066C5.52582 19.3066 4.89465 19.7354 4.44474 20.3274C3.99482 20.9195 3.75085 21.6425 3.75 22.3861V24.0907H13.9773V22.3861C13.9764 21.6425 13.7325 20.9195 13.2825 20.3274C12.8326 19.7354 12.2015 19.3066 11.4852 19.1066ZM8.86364 14.8861C9.26819 14.8861 9.66366 15.0061 10 15.2308C10.3364 15.4556 10.5986 15.7751 10.7534 16.1488C10.9082 16.5226 10.9487 16.9338 10.8698 17.3306C10.7909 17.7274 10.5961 18.0919 10.31 18.3779C10.0239 18.664 9.65946 18.8588 9.26269 18.9377C8.86591 19.0167 8.45463 18.9762 8.08087 18.8213C7.70712 18.6665 7.38766 18.4043 7.1629 18.068C6.93815 17.7316 6.81818 17.3361 6.81818 16.9316C6.81818 16.3891 7.03368 15.8688 7.41728 15.4852C7.80088 15.1016 8.32115 14.8861 8.86364 14.8861ZM12.6136 22.727H5.11364V22.3861C5.11364 21.8436 5.32914 21.3234 5.71274 20.9398C6.09633 20.5562 6.6166 20.3407 7.15909 20.3407H10.5682C11.1107 20.3407 11.6309 20.5562 12.0145 20.9398C12.3981 21.3234 12.6136 21.8436 12.6136 22.3861V22.727Z"
      fill="#828282"
    />
    <path
      d="M25.9091 13.5228H16.3636V14.8864H25.9091V13.5228Z"
      fill="#828282"
    />
    <path
      d="M21.8182 16.5907H16.3636V17.9544H21.8182V16.5907Z"
      fill="#828282"
    />
    <path
      d="M24.2045 19.6591H16.3636V21.0228H24.2045V19.6591Z"
      fill="#828282"
    />
    <path
      d="M22.8409 22.7271H16.3636V24.0907H22.8409V22.7271Z"
      fill="#828282"
    />
    <path
      d="M14.9999 5.3407C15.3765 5.3407 15.6817 5.03544 15.6817 4.65889C15.6817 4.28233 15.3765 3.97707 14.9999 3.97707C14.6233 3.97707 14.3181 4.28233 14.3181 4.65889C14.3181 5.03544 14.6233 5.3407 14.9999 5.3407Z"
      fill="#828282"
    />
  </svg>
);

export default piiRecIcon;
