import React from "react";

const CompareIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.inverted ? "#fff" : "#333"}
    className={props.className}
    onClick={props.onClick}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1986 9.07116C12.8006 8.65043 12.8006 7.97118 13.1986 7.55044L16.5648 3.99128C16.7217 3.82545 16.7208 3.55746 16.563 3.3927C16.4051 3.22795 16.15 3.22882 15.9931 3.39465L12.0626 7.55044C11.6646 7.97118 11.6646 8.65043 12.0626 9.07116L15.9931 13.227C16.15 13.3928 16.4051 13.3937 16.563 13.2289C16.7208 13.0641 16.7217 12.7962 16.5648 12.6303L13.1986 9.07116Z"
        fill="#CFDFF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9114 8.31081C11.9114 8.07705 12.0918 7.88755 12.3144 7.88755H21.3403C21.5628 7.88755 21.7432 8.07705 21.7432 8.31081C21.7432 8.54457 21.5628 8.73407 21.3403 8.73407H12.3144C12.0918 8.73407 11.9114 8.54457 11.9114 8.31081Z"
        fill="#CFDFF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.811 3.20456C16.0678 2.93306 16.4855 2.93163 16.7439 3.20137C17.0024 3.47111 17.0038 3.90987 16.747 4.18137L13.4968 7.61784H21.3403C21.7046 7.61784 22 7.92809 22 8.31081C22 8.69352 21.7046 9.00378 21.3403 9.00378H13.4968L16.747 12.4402C17.0038 12.7117 17.0024 13.1505 16.7439 13.4202C16.4855 13.69 16.0678 13.6885 15.811 13.417L11.8804 9.26125C11.383 8.73534 11.383 7.88627 11.8804 7.36035L15.811 3.20456ZM13.1708 8.46436H21.3403C21.421 8.46436 21.4865 8.39561 21.4865 8.31081C21.4865 8.226 21.421 8.15725 21.3403 8.15725H13.1708C13.1522 8.25862 13.1522 8.363 13.1708 8.46436ZM12.6517 8.15725C12.6406 8.25926 12.6406 8.36235 12.6517 8.46436H12.3144C12.2336 8.46436 12.1682 8.39561 12.1682 8.31081C12.1682 8.226 12.2336 8.15725 12.3144 8.15725H12.6517ZM12.8255 7.61784C12.8778 7.52615 12.9415 7.43958 13.0164 7.36035L16.3827 3.8012C16.4396 3.74103 16.4393 3.64381 16.382 3.58404C16.3247 3.52427 16.2322 3.52458 16.1753 3.58474L12.3608 7.61784H12.8255ZM12.3608 9.00378H12.8255C12.8778 9.09546 12.9415 9.18202 13.0164 9.26125L16.3827 12.8204C16.4396 12.8806 16.4393 12.9778 16.382 13.0376C16.3247 13.0973 16.2322 13.097 16.1753 13.0369L12.3608 9.00378ZM10.4383 16.3821L7.18664 19.8185C6.9298 20.09 6.93106 20.5287 7.18947 20.7985C7.44788 21.0683 7.86557 21.067 8.12242 20.7955L12.0547 16.6398C12.5524 16.1138 12.5524 15.2644 12.0547 14.7384L8.12242 10.5827C7.86557 10.3112 7.44788 10.3099 7.18947 10.5797C6.93106 10.8495 6.9298 11.2883 7.18664 11.5597L10.4383 14.9961H2.6597C2.29536 14.9961 2 15.3064 2 15.6891C2 16.0718 2.29536 16.3821 2.6597 16.3821H10.4383Z"
        fill="#CFDFF8"
      />
    </g>
  </svg>
);

export default CompareIcon;
