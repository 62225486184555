import React from "react";
import { Route } from "react-router-dom";

import Datamap from "pages/DataMap";
import PiiCountries from "pages/PiiCountries";
import Owners from "pages/DataOwners";
import FunctionalUses from "pages/FunctionalUses";
import PiiTrends from "pages/PiiTrends";

const EmptyCharts = () => {
  return (
    <>
      <Route path="/zen/empty/data-map" component={Datamap} />
      <Route path="/zen/empty/pii-countries" component={PiiCountries} />
      <Route path="/zen/empty/data-owners" component={Owners} />
      <Route path="/zen/empty/functional-uses" component={FunctionalUses} />
      <Route path="/zen/empty/pii-trends" component={PiiTrends} />
    </>
  );
};

export default EmptyCharts;
