import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import useStyles from "./styles.js";
import StatItem from "components/EnvironmentHeader/StatItem";
import Loader from "components/Loader";
import { getScansStats } from "redux/_scans/scans.service";
import isEmpty from "lodash.isempty";

const LastScanStats = (props) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    getScansStats()
      .then((fetchData) => setData(fetchData))
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.lastScanStats}>
      <Grid className={classes.itemsContainer} container spacing={1}>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.environments"
              name={data[0].label}
              value={data[0].value}
              variation={data[0].variation}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.piicols"
              name={data[1].label}
              value={data[1].value}
              variation={data[1].variation}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.unprotected"
              name={data[2].label}
              value={data[2].value}
              variation={data[2].variation}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.consented"
              name={data[3].label}
              value={data[3].value}
              variation={data[3].variation}
              percentage
              positive={false}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.erasure"
              name={data[4].label}
              value={data[4].value}
              variation={data[4].variation}
            />
          ) : (
            <Loader />
          )}
        </Grid>
        <Grid item xs={4} lg={2} className={classes.item}>
          {!isEmpty(data) ? (
            <StatItem
              icon="overview.portability"
              name={data[5].label}
              value={data[5].value}
              variation={data[5].variation}
            />
          ) : (
            <Loader />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LastScanStats;
