import FindingSection from "pages/SecurityReport/common/FindingSection";
import { SECTIONS_CONTENT } from "pages/SecurityReport/constants";
import React from "react";

const MissingSecurityFlag = ({ goToNextSectionAction, data }) => {
  console.log({ data });
  return (
    <FindingSection
      goToNextSectionAction={goToNextSectionAction}
      sectionContent={SECTIONS_CONTENT.findings}
      data={data}
    />
  );
};

export default MissingSecurityFlag;
