import React, { useCallback, useState } from "react";
import "./styles.scss";
import { Box, Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import SensitivePiiChart from "components/RepositoryResultsView/SensitiveChart";
import InsensitivePiiChart from "components/RepositoryResultsView/InsensitiveChart";
import ThirdPartyPiiChart from "components/RepositoryResultsView/ThirdPartyChart";
import moment from "moment";
import { getAccountData } from "redux/_account/account.selectors";
import { useSelector } from "react-redux";

const getTitle = (el, totalObjects) => {
  const percentage = (el.count / totalObjects) * 100;
  return `${el.label}: ${percentage.toFixed(2)}%`;
};

export const formatChart = (data, total) => [
  data.map((el) => getTitle(el, total)),
  data.map((el) => el.count),
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  iconContainer: {
    width: 67,
    height: 67,
    backgroundColor: "#ffffff",
    boxShadow: "1px 2px 11px -1px rgba(0, 0, 0, 0.25)",
    borderRadius: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 41,
    height: 41,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: "auto",
      paddingTop: "151px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      width: "45%",
    },
  },
  img: {
    [theme.breakpoints.up("xs")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  landing: {
    width: "65%",
    paddingTop: 0,
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginTop: "30px",
    },
  },
  downloadButton: {
    fontSize: "15px",
    padding: "12px 22px",
    backgroundColor: "#6bd4cb",
    color: "#1a3586",
  },
  scanDetailItem: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    fontSize: "20px",
    margin: "26px 0",
  },
  description: {
    width: "100%",
  },
}));
const Landing = ({ content = {} }) => {
  const classes = useStyles();

  const formatSensitiveChart = useCallback((summary) =>
    formatChart(summary.sensitiveEntitiesCount, summary.totalSensitiveEntities)
  );
  const formatNonSensitiveChart = useCallback((summary) =>
    formatChart(
      summary.nonSensitiveEntitiesCount,
      summary.totalNonSensitiveEntities
    )
  );

  const userAccount = useSelector((state) => getAccountData(state));

  return (
    <div className={classes.container}>
      <div className={clsx("landing", classes.landing)}>
        <div className="sinopsis">
          <div className="app-description-data-source">
            {content.data.provider?.icon ? (
              <Box className={classes.iconContainer}>
                <img
                  className={classes.icon}
                  src={content.data.provider?.icon}
                />
              </Box>
            ) : (
              <Box
                className={classes.icon}
                style={{ backgroundColor: "transparent" }}
              ></Box>
            )}
            <div>
              <h1 style={{ color: "#6BD4CA" }}>{content.data.provider?.name}</h1>
              {content.data.provider?.description && (
                <span>{content.data.provider.description}</span>
              )}
            </div>
            <div className="pdf-button-container"></div>
          </div>
        </div>
        <div className="scores-data-source">
          <SensitivePiiChart
            data={content.data?.summary}
            formatData={formatSensitiveChart}
          ></SensitivePiiChart>
          <InsensitivePiiChart
            data={content.data?.summary}
            formatData={formatNonSensitiveChart}
          ></InsensitivePiiChart>
        </div>
      </div>
    </div>
  );
};

export default Landing;
