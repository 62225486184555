import { CONSTANTS } from "redux/_consents/_overview/overview.constants";

const initialState = {
  data: {},
  loading: false,
  kpis: {},
};

export const overview = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.CONSENTS_OVERVIEW_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.CONSENTS_OVERVIEW_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.CONSENTS_OVERVIEW_SAVE_DATA:
      return {
        ...state,
        data,
      };
    case CONSTANTS.CONSENTS_OVERVIEW_SAVE_KPIS:
      return {
        ...state,
        kpis: data,
      };
    default:
      return state;
  }
};
