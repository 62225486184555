import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  header: {
    overflow: "hidden",
    position: "static",
    zIndex: 9,
    width: "100%",
    opacity: 1,
    transition: "all 0.5s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 4.51506px 9.03012px rgba(176, 190, 197, 0.24)",
    height: 151,
  },
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    heigh: '100%',
  },
  tableContainerWrapper: {
    flex: 1,
    display: 'flex',
    padding: '15px',
    alignItems: 'flex-start',
  },
  leftSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  arrowCellContainer: {
    marginBottom: theme.spacing(1),
  },
  leftTable: {
    width: "400px",
    boxShadow: 'none',
    fontFamily: 'Inter, sans-serif',
    height: 445,
  },
  tableContainer: {
    width: 695,
    height: 370,
    overflowY: 'auto',
    fontFamily: 'Inter, sans-serif',
    boxShadow: 'none',
    position: 'relative',
  },
  tableWithPaginationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  paginationWrapper: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 10,
  },
  tableCell: {
    fontSize: '0.875rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontFamily: 'inherit',
    paddingLeft: '30px',
  },
  tableCellName: {
    fontSize: '0.875rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontFamily: 'inherit',
    width: '290px',
  },
  headerCell: {
    fontSize: '14px',
    fontWeight: 'bold',
    borderTop: `1px solid ${theme.palette.divider}`,
    color: 'gray',
    textTransform: 'uppercase',
    fontFamily: 'inherit',
    height: '56px',
    padding: '0px',
    paddingLeft: '15px',
    width: '120px',
  },
  boldCell: {
    fontWeight: 'bold',
  },
  tableHead: {
    backgroundColor: 'transparent',
  },
  tableHeadRow: {
    backgroundColor: 'transparent',
    height: '56px',
  },
  tableVariableHeaderCell: {
    ...theme.typography.body1,
    letterSpacing: 'normal',
    color: 'black',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderBottom: '0px',
    paddingBottom: theme.spacing(0.5),
  },
  tableVariableCell: {
    fontWeight: 'normal',
    borderBottom: '0px',
    color: 'gray',
    fontFamily: 'inherit',
    paddingTop: theme.spacing(0.5),
  },
  headerCellName: {
    fontWeight: 'bold',
    borderTop: `1px solid ${theme.palette.divider}`,
    color: 'gray',
    textTransform: 'uppercase',
    fontFamily: 'inherit',
    height: '56px',
    padding: '0px',
    paddingLeft: '15px',
    width: '275px',
  },
  tableVariableHead: {
    backgroundColor: 'transparent',
  },
  tableVariableRow: {
    backgroundColor: 'transparent',
  },
  card: {
    width: "340px",
    height: "93px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "12px",
    boxShadow: "0 0px 2px 0 rgba(0, 0, 0, 0.25)",
    backgroundColor: '#f2f2f2',
    cursor: 'pointer',
    border: '2px solid transparent',
  },
  cardText: {
    ...theme.typography.body2,
    fontWeight: 'normal',
    borderBottom: '0px',
    color: 'gray',
    textAlign: 'left',
    width: '100%', 
  },
  cardTitle: {
    ...theme.typography.body1,
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'left',
    width: '100%', 
  },
  cardSelected: {
    backgroundColor: 'white',
    border: '2px solid blue',
  },
  link: {
    ...theme.typography.body1,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: "0 6px 0 18px",
  },
  arrowBack: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  arrowCell: {
    padding: '0px',
    paddingTop: '15px',
  },
  cardRow: {
    display: 'block',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(-2),
  },
}));
