import React from "react";
import clsx from "clsx";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import BaseForm from "components/SettingReportsOption/components/Form";

import useStyles from "./styles";

const Dialog = ({
  actions,
  open,
  handleClose,
  title,
  children,
  form = undefined,
  customClasses = {},
}) => {
  const classes = useStyles();

  const { onSubmit } = form || {};

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="user-configuration-action"
      maxWidth={"md"}
      className={clsx(classes.dialog, customClasses.dialog)}
      classes={{ paper: classes.dialog }}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="user-configuration-action-title"
        className={clsx(classes.title, customClasses.title)}
      >
        {title}
      </DialogTitle>
      <DialogContent className={clsx(classes.content, customClasses.content)}>
        {form ? <BaseForm onSubmit={onSubmit} form={form} /> : children}
      </DialogContent>
      {actions && (
        <DialogActions classes={{ root: classes.actions }}>
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
