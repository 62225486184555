import {
  getData,
  updateData,
  uploadFile,
  getCollectorLogsData,
} from "redux/_consents/_config/config.service";
import {
  requestStart,
  requestEnd,
  saveData,
  saveFile,
} from "redux/_consents/_config/config.actions";
import isEmpty from "lodash.isempty";
import { getConsentsConfig } from "redux/_consents/_config/config.selectors";

export const getConfig = (reportId) => (dispatch, getState) => {
  const config = getConsentsConfig(getState());
  if (isEmpty(config)) {
    dispatch(requestStart());
    return getData(reportId)
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const updateConfig = (data, reportId) => (dispatch) => {
  dispatch(requestStart());
  return updateData(data, reportId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveData(data));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};

export const getCollectorLogs = (token) => () => {
  return getCollectorLogsData(token);
};

export const uploadConfigFile = (fileData, id, reportId) => (dispatch) => {
  dispatch(requestStart());
  return uploadFile(fileData, id, reportId)
    .then((data) => {
      dispatch(requestEnd());
      dispatch(saveFile(data));
      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject(error);
    });
};
