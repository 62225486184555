import React, { useState, useEffect, lazy, Suspense } from "react";
import useStyles from "./styles";
import SectionContent from "../SectionContent";
import ExpandedPanel from "../ExpandedPanel";
import Table from "pages/AppReport/common/Table";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Loader from "components/Loader";
import clsx from "clsx";
const PanelContent = lazy(() => import("./PanelContent"));

const DEFAULT_PII_OPTION = "All PII";
const PiiViolations = ({ data, columns }) => {
  const [expanded, setExpanded] = useState();
  const formattedData = (data) => {
    const allValueObjects = data.reduce((result, currentItem) => {
      return result.concat(
        currentItem.value.filter((obj) => obj.type !== "third_party")
      );
    }, []);
    const piiNames = allValueObjects.map((obj) => obj.pii_name);
    const uniquePiiNames = [...new Set(piiNames)];
    return uniquePiiNames;
  };
  const filterPiiData = (objects) => {
    return objects.filter((obj) => {
      const hasTypeThird_party = obj.value.some(
        (item) => item.type === "third_party"
      );
      return !hasTypeThird_party;
    });
  };
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [piiFilters, setPiiFilters] = React.useState([DEFAULT_PII_OPTION]);
  const [filterOptions, setFilterOptions] = React.useState([
    DEFAULT_PII_OPTION,
  ]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [filterSelected, setFilterSelected] = React.useState([]);
  const filterObjectsByKeywords = (objects, keywords) => {
    return objects
      .map((obj) => {
        const filteredValue = obj.value.filter((item) =>
          keywords.includes(item.pii_name)
        );
        if (filteredValue.length > 0) {
          return { ...obj, value: filteredValue };
        }
        return null;
      })
      .filter((filteredObj) => filteredObj !== null);
  };
  useEffect(() => {
    if (data) {
      const uniquePiiNames = formattedData(data);
      setFilterOptions([DEFAULT_PII_OPTION, ...uniquePiiNames]);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      if (piiFilters.length && !piiFilters.includes(DEFAULT_PII_OPTION)) {
        const filteredResult = filterObjectsByKeywords(data, piiFilters);
        setFilteredData(filteredResult);
      } else {
        setFilteredData(filterPiiData(data));
      }
    }
  }, [piiFilters, data]);
  const handleChangePiiFilter = (event) => {
    setFilterSelected("");
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setPiiFilters([DEFAULT_PII_OPTION]);
      return;
    }
    if (value[value.length - 1] !== DEFAULT_PII_OPTION) {
      const newValues = value.filter(
        (filterValue) => filterValue !== DEFAULT_PII_OPTION
      );
      setPiiFilters(
        typeof value === "string" ? newValues.split(",") : newValues
      );
      setFilterSelected(
        typeof value === "string" ? newValues.split(",") : newValues
      );
      return;
    }
    setPiiFilters([DEFAULT_PII_OPTION]);
    setFilterSelected([DEFAULT_PII_OPTION]);
  };
  const handleUniqueFilter = (pii) => {
    if (pii === DEFAULT_PII_OPTION) {
      setPiiFilters([DEFAULT_PII_OPTION]);
    }
    if (filterSelected.includes(pii)) {
      const newValues = filterSelected.filter(
        (filterValue) => filterValue !== pii
      );
      if (newValues.length > 0) {
        setPiiFilters([...newValues]);
      } else {
        setPiiFilters([DEFAULT_PII_OPTION]);
      }
      setFilterSelected([...newValues]);
    } else {
      const newValues = filterSelected.filter(
        (filterValue) => filterValue !== DEFAULT_PII_OPTION
      );
      setPiiFilters([...newValues, pii]);
      setFilterSelected([...newValues, pii]);
    }
  };
  return (
    <div className="scrollable-element">
      <div className={clsx(classes.headerFilter, "scrollable-element")}>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          multiple
          value={piiFilters}
          onChange={handleChangePiiFilter}
          input={<Input />}
          MenuProps={MenuProps}
          className={clsx(classes.select, "scrollable-element")}
        >
          {filterOptions.map((name) => (
            <MenuItem key={name} value={name} className="scrollable-element">
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <SectionContent>
        {filteredData.map((pii) => (
          <ExpandedPanel
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  "justify-content": "space-between",
                }}
              >
                <div>{pii.key}</div>
                <div>{pii.value.length}</div>
              </div>
            }
            classes={{
              root: "scrollable-element",
              // header: classes[severity.address],
            }}
            simpleTitle
            key={pii.key}
            onChange={() => setExpanded(expanded !== pii.key ? pii.key : "")}
            expanded={expanded === pii.key}
          >
            {expanded === pii.key && (
              <Suspense fallback={<Loader />}>
                <PanelContent
                  pii={pii}
                  filterSelected={filterSelected}
                  handleUniqueFilter={handleUniqueFilter}
                />
              </Suspense>
            )}
          </ExpandedPanel>
        ))}
      </SectionContent>
    </div>
  );
};
export default PiiViolations;
