import React, { useEffect, useMemo } from "react";
import SelectInput from "components/Inputs/Select";
import useStyles from "../../styles";
import { connect } from "react-redux";
import { getFilterBusinessUnit } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import { getBusinessUnits } from "redux/_businessUnits/businessUnits.async.actions";
import { getBusinessUnitsData } from "redux/_businessUnits/businessUnits.selectors";
import { units as businessUnits } from "components/PageWrapper/components/FiltersBar/components/constants";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const BusinessUnit = (props) => {
  const classes = useStyles();

  const { unit, units, setUnit, getUnits } = props;

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsedUnits = useMemo(() => {
    return units.map((unit) => ({ label: unit.name, value: unit.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  return (
    <SelectInput
      label="Bussiness unit"
      options={[...businessUnits, ...parsedUnits]}
      className={classes.filtersInput}
      value={unit}
      onChange={setUnit}
    />
  );
};

const mapStateToProps = (state) => ({
  unit: getFilterBusinessUnit(state),
  units: getBusinessUnitsData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUnits: () => dispatch(getBusinessUnits()),
  setUnit: (id) => dispatch(selectData(TYPES.BUSINESS_UNIT, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUnit);
