import React from "react";
import { Box } from "@material-ui/core";
import LastScanStatusRowListing from "../components/detail/LastScanStatusRowListing";
const NewRepositoryScanList = () => {
  return (
    <Box>
      <LastScanStatusRowListing />
    </Box>
  );
};

export default NewRepositoryScanList;
