import {
  requestEnd,
  requestStart,
  saveData,
  setPolicyCookie,
  removePolicyCookie,
  setPolicyCookies,
} from "redux/_admin/_company/company.actions";
import { getAll, update } from "redux/_admin/_company/company.service";
import api from "utils/fetch";

export const getCompanyData = () => (dispatch) => {
  dispatch(requestStart());
  return getAll()
    .then((data) => {
      dispatch(saveData(data));
      dispatch(requestEnd());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const updateCompany = (data) => (dispatch) => {
  dispatch(requestStart());
  return update(data)
    .then((data) => {
      dispatch(saveData(data));
      dispatch(requestEnd());
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};

export const getCookiePolicies = (reportId) => (dispatch) =>
  api
    .get(`company/policy_cookies/?report=${reportId}`)
    .then((x) => dispatch(setPolicyCookies(x)));

export const createCookiePolicy = (data) => (dispatch) => {
  api
    .post("company/policy_cookies/", JSON.stringify(data))
    .then((x) => dispatch(setPolicyCookie(x)));
};

export const updateCookiePolicy = ({ id, ...data }) => (dispatch) => {
  api
    .patch(`company/policy_cookies/${id}/`, JSON.stringify(data))
    .then((x) => dispatch(setPolicyCookie(x)));
};

export const deleteCookiePolicy = (id) => (dispatch) => {
  api
    .delete(`company/policy_cookies/${id}/`)
    .then(() => dispatch(removePolicyCookie(id)));
};
