import React from "react";
import { useHistory } from "react-router-dom";
import { ArcherContainer, ArcherElement } from "react-archer";
import { Box, Typography } from "@material-ui/core";
import BublePII from "./BublePII";
import { useSelector } from "react-redux";
import repoProviderUtils from "helpers/repoProviderUtils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { routePaths } from "router/paths";
import { capitalizeFirstLetter } from "utils/texts"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "red",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "yellow",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "blue",
    },
  },
  bubbleHover: {
    cursor: "pointer",
  },
}));

const PII_SHARING_ARCH_RELATION = [
  {
    targetId: "root",
    targetAnchor: "right",
    sourceAnchor: "left",
    style: {
      endShape: {
        circle: {
          radius: 1,
          strokeWidth: 0,
          strokeColor: "#F0F0F0",
          fillColor: "#F0F0F0",
        },
      },
    },
  },
];
const PII_COLLECTION_ARCH_RELATION = [
  {
    targetId: "root",
    targetAnchor: "left",
    sourceAnchor: "right",

    style: {
      endShape: {
        circle: {
          radius: 1,
          strokeWidth: 0,
          strokeColor: "#F0F0F0",
          fillColor: "#F0F0F0",
        },
      },
    },
  },
];

const sanitizeData = (data) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).filter(
    ([, value]) => value.piis.length > 0 && value.count > 0
  );
};

const setUpPIICollectionBubles = (
  archRelation,
  sectionTitle,
  id,
  data,
  history,
  classes
) => {
  const sanitizedDataEntries = sanitizeData(data);

  const handleClick = (archRelation, label) => {
    const classificationType = archRelation === PII_COLLECTION_ARCH_RELATION ? 'Collection' : 'Sharing';
    
    history.push({
      pathname: `${routePaths.PII_SHARING_DETAILS}${id}`,
      search: `?classification_1=${classificationType}&classification_2=${capitalizeFirstLetter(label)}`,
    });
  };

  if (sanitizedDataEntries.length === 0) {
    return null;
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: 18 }}>
      <Typography
        style={{ color: "#1B3586", fontFamily: "Inter", fontWeight: 900 }}
      >
        {sectionTitle}
      </Typography>
      {sanitizedDataEntries.map(([key, value]) => (
        <ArcherElement
          key={sectionTitle + key}
          id={sectionTitle + key}
          relations={archRelation}
        >
          <div
            className={classes.bubbleHover}
            onClick={() => handleClick(archRelation, value.label)}
          >
            <BublePII
              config={{
                sectionTitle: sectionTitle,
                category: value.label,
                pii: value.piis,
                borderChipColor: "#1B3586",
              }}
            />
          </div>
        </ArcherElement>
      ))}
    </Box>
  );
};

export const DataFlowChart = () => {
  const classes = useStyles();
  const history = useHistory();
  const chartData = useSelector((state) => state.dataFlow.chartFlow);
  return (
    <Box
      style={{
        height: "auto",
        background: "#F0F0F0",
        padding: 24,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ArcherContainer strokeColor="#D6D6D6">
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center-start",
            }}
          >
            {setUpPIICollectionBubles(
              PII_COLLECTION_ARCH_RELATION,
              "PII collection",
              chartData?.chartInfo?.id,
              chartData?.chartInfo?.collection,
              history,
              classes
            )}
            <ArcherElement id="root">
              <Box
                style={{
                  background: "#1B3586",
                  borderRadius: 16,
                  padding: 16,
                  width: 185,
                  height: "fit-content",
                  alignSelf: "center",
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    fontFamily: "Inter",
                    fontSize: 16,
                    wordBreak: "break-word",
                    fontWeight: 900,
                    textAlign: "center",
                  }}
                >
                  {repoProviderUtils.getRepositoryShortNameByUrl(
                    chartData?.chartInfo?.repository
                  )}
                </Typography>
              </Box>
            </ArcherElement>
            {setUpPIICollectionBubles(
              PII_SHARING_ARCH_RELATION,
              "PII sharing",
              chartData?.chartInfo?.id,
              chartData?.chartInfo?.sharing,
              history,
              classes
            )}
          </Box>
        </ArcherContainer>
      </Box>
    </Box>
  );
};
