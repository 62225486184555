import React from "react";
import clsx from "clsx";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";

import CONSTANTS from "redux/_scans/scans.constants";

import useStyles from "./styles";

const DetailSection = ({ children, title }) => {
  const classes = useStyles();

  const message =
    children === CONSTANTS.SCAN_IN_PROGRESS_STATUS ? "IN PROGRESS" : children;

  return (
    <Grid item xs={12} lg={6} className={classes.itemContainer}>
      <Typography display="inline" className={classes.itemLabel}>
        {`${title} `}
        <span
          display="inline"
          className={clsx(
            classes.itemValue,
            children === CONSTANTS.SCANS_FAILED_STATUS &&
              classes.itemValueError,
            children === CONSTANTS.SCANS_SUCCESS_STATUS && classes.itemValueOk,
            children === CONSTANTS.SCAN_IN_PROGRESS_STATUS &&
              classes.itemValueProgress
          )}
        >
          {message}
        </span>
      </Typography>
    </Grid>
  );
};

const ScanDetailsHeader = ({
  selectedRow: { name, status, started_at, errors, type },
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>Information</Typography>
      <Grid container>
        <DetailSection title="ID:">{name}</DetailSection>
        <DetailSection title="Status:">{status}</DetailSection>
        <DetailSection title="Date:">
          {moment(started_at).format("DD/MM/YYYY HH:MM")}
        </DetailSection>
        {errors && <DetailSection title="Errors:">{errors}</DetailSection>}
        {type && <DetailSection title="Type:">{type}</DetailSection>}
      </Grid>
    </>
  );
};

export default ScanDetailsHeader;
