/**
 * Async redux actions to perform sign in/out operations
 *
 * @module authentication/actions
 * @requires redux-thunk
 * @requires history
 */

import { setAccountData } from "redux/_account/account.actions";
import { getInfo } from "redux/_account/account.service";
import { authenticationConstants } from "./authentication.constants";
import { authService } from "./authentication.service";
import isEmpty from "utils/is-empty";
import { routeForKey } from "router";
import history from "helpers/history";

export const authenticationActinon = {
  login,
  webflowLogin,
  logout,
  _success,
  resetPassword,
  resetConfirmPassword,
  setAttemptedTransition,
  saveLogin,
};

function webflowLogin(tmpToken) {
  return (dispatch, getState) => {
    dispatch({ type: authenticationConstants.LOGIN_REQUEST });

    return authService
      .webflowLogin(tmpToken)
      .then((session) => {
        dispatch(_success(session)); //store "autenticated" state
        getInfo().then((account) => {
          dispatch(setAccountData(account));
          const redirectAfterLogin = routeForKey("zen");
          const {
            authentication: { attemptedTransition },
          } = getState();
          const redirectTo = isEmpty(attemptedTransition)
            ? redirectAfterLogin
            : attemptedTransition;

          history.push(redirectTo);
          dispatch(setAttemptedTransition()); //clear redirection

          window.addEventListener("storage", authService.watchSession);
        });
      })
      .catch((errors) => {
        dispatch(_failure(errors));
        // dispatch(alertActions.error(error));
      });
  };
}

// This script should be added to your legacy project
function sendJWTToParent(jwt) {
  window.parent.postMessage(
    {
      type: "JWT_TOKEN",
      token: jwt,
    },
    `${process.env.REACT_APP_V2_REPO}` // Update this with the correct origin of your new Next.js project
  );
}

function login(email, password) {
  return (dispatch, getState) => {
    dispatch({ type: authenticationConstants.LOGIN_REQUEST });
    return authService
      .login(email, password)
      .then((session) => {
        //sendJWTToParent(session.access_token);
        dispatch(_success(session)); //store "autenticated" state
        getInfo().then((account) => {
          dispatch(setAccountData(account));
          const redirectAfterLogin = routeForKey("zen");
          const {
            authentication: { attemptedTransition },
          } = getState();
          const redirectTo = isEmpty(attemptedTransition)
            ? redirectAfterLogin
            : attemptedTransition;

          history.push(redirectTo);
          dispatch(setAttemptedTransition()); //clear redirection

          window.addEventListener("storage", authService.watchSession);
        });
      })
      .catch((errors) => {
        dispatch(_failure(errors));
        // dispatch(alertActions.error(error));
      });
  };
}

function saveLogin(session, account) {
  return (dispatch, getState) => {
    localStorage.setItem(
      authenticationConstants.SESSION,
      JSON.stringify(session)
    );
    dispatch(_success(session)); //store "authenticated" state
    dispatch(setAccountData(account));

    window.addEventListener("storage", authService.watchSession);
  };
}

function resetPassword(email) {
  return (dispatch, getState) => {
    return authService
      .resetPassword(email)
      .then((_) => {
        return Promise.resolve();
      })
      .catch((errors) => {
        return Promise.reject(errors);
      });
  };
}

function resetConfirmPassword(data) {
  return (dispatch, getState) => {
    return authService
      .resetConfirmPassword(data)
      .then((_) => {
        return Promise.resolve();
      })
      .catch((errors) => {
        const key = Object.keys(errors)[0];
        let errorMessage = errors[key][0];
        if (key === "uid" || key === "token") {
          errorMessage = `${key}: ${errorMessage}`;
        }
        return Promise.reject(errorMessage);
      });
  };
}

function _success(session) {
  return { type: authenticationConstants.LOGIN_SUCCESS, session };
}
function _failure(errors) {
  return { type: authenticationConstants.LOGIN_FAILURE, errors };
}

function logout() {
  authService.logout();
  return { type: authenticationConstants.LOGOUT };
}

function setAttemptedTransition(location) {
  return {
    type: authenticationConstants.ATTEMPTED_TRANSITION,
    location,
  };
}
