import React from "react";
import useStyles from "./styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { selectEnv } from "redux/_environments/environment.actions";
import history from "helpers/history";
import { openWizard } from "redux/_wizard/wizard.actions";
import {
  getEnvsData,
  getEnvsLoadingState,
  getScanningState,
  getSelectedEnvData,
  getSelectedEnvId,
} from "redux/_environments/environment.selectors";
import replace from "lodash.replace";
import { withRouter } from "react-router-dom";

const EnvSelector = (props) => {
  const { environments, loading, selected, selectEnvById, match } = props;
  const classes = useStyles();

  const selectEnvironment = (e) => {
    e.preventDefault();
    const id = e.target.value;
    const path = match.path;
    const envUrl = replace(path, ":env_id", id);
    const url = replace(envUrl, ":scan_id", 0);
    selectEnvById(id);
    history.replace(url);
  };

  return (
    <div className={classes.selectContainer}>
      {!loading && (
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.selectTitle}>
            Current Environment
          </InputLabel>
          <Select
            value={selected || 0}
            defaultValue={0}
            onChange={selectEnvironment}
            className={classes.selectText}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
            classes={{
              selectMenu: classes.selectedText,
              icon: classes.selectIcon,
            }}
            displayEmpty
            disabled={environments.length === 0}
          >
            {environments.map(({ id, name }, idx) => (
              <MenuItem key={idx} className={classes.selectMenuItem} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  environments: getEnvsData(state),
  loading: getEnvsLoadingState(state),
  selected: getSelectedEnvId(state),
  environmentData: getSelectedEnvData(state),
  isScanning: getScanningState(state),
});

const mapDispatchToProps = (dispatch) => ({
  openWizardModal: () => dispatch(openWizard()),
  selectEnvById: (envId) => dispatch(selectEnv(envId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EnvSelector));
