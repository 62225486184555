import { useState } from "react";
import axios from "axios";
import { authHeader } from "helpers/auth-header";
import config from "config";

export const useFetchRepoScanDataFlowInfo = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getHeadersConfig = () => {
    return {
      headers: authHeader(),
    };
  };

  const createConfig = () => {
    return {
      ...getHeadersConfig(),
    };
  };

  const fetchScanDataFlow = (scanId) => {
    setLoading(true);
    axios
      .get(`${config.apiUrl}/repo-scans/` + scanId + "/", createConfig())
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.error(err.response);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { data, loading, error, fetchScanDataFlow };
};
