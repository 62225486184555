import { Hidden, Typography } from "@material-ui/core";
import IconSearch from "@material-ui/icons/Search";
import clsx from "clsx";
import Button from "components/Button";
import { FormTextInput } from "components/FormComponents/Inputs";
import HomepageMenu from "components/HomepageMenu";
import Footer from "components/PublicComponents/Footer";
import PublicNav from "components/PublicComponents/Nav";
import StepperDialog from "components/StepperDialog";
import StepperDialogCongrats from "components/StepperDialogCongrats";
import useQuery from "hooks/useQuery";
import { useSnackbar } from "notistack";
import ConsumerReportEmailForm from "pages/ConsumerReport/components/ConsumerReportEmailForm";
import ConsumerReportPPForm from "pages/ConsumerReport/components/ConsumerReportPPForm";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { postTriggerReport } from "redux/_reports/reports.async.actions";
import { privacyPolicyRetry } from "redux/_reports/reports.service";
import palette from "theme/palette";
import { fullURL } from "utils/format";
import { validateSite } from "utils/scanValidations";
import useStyles from "./styles";

const SearchForm = ({ onSubmit }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.searchWidget}>
          <Field
            name="resource"
            validate={validateSite}
            className={classes.searchInput}
            placeholder="https://example.com"
            render={(props) => <FormTextInput {...props} />}
          />
          <Button
            type="submit"
            className={classes.searchButton}
            startIcon={<IconSearch />}
          >
            Search
          </Button>
        </form>
      )}
    />
  );
};

const ConsumerReportLayout = ({
  customHeader,
  mainWithBackground = false,
  withSearchInputText = false,
  children,
  triggerReport,
}) => {
  const classes = useStyles();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const scanId = query.get("s");
  const forms = { pp: "PRIVACY_POLICY_FORM", email: "EMAIL_FORM" };

  const [form, goToForm] = useState(scanId ? forms.pp : null);
  const [sent, setSent] = useState(false);
  const [scanFormValues, setScanFormValues] = useState({});

  const handleSearch = ({ resource }) => {
    setScanFormValues({ ...scanFormValues, resource: fullURL(resource) });
    goToForm(forms.email);
  };

  const closeHandler = () => {
    goToForm(null);
    if (sent) {
      global.location.href = "https://www.zendata.dev";
    }
  };

  const handleSubmit = (values) => {
    const actualValues = {
      ...scanFormValues,
      ...values,
      locations: ["US"],
      environment: "web",
      include_sitemap: false, // TODO: add snakecase conversion to fetch util
      flavor: "customer",
    };
    triggerReport({ ...actualValues, notifier: enqueueSnackbar });
    setSent(true);
  };

  const submitRetry = (values) => {
    privacyPolicyRetry({ scan_id: scanId, url: values.url }).then(() =>
      setSent(true)
    );
  };

  return (
    <div className={classes.customerReport}>
      <Hidden mdUp>
        <HomepageMenu />
      </Hidden>
      {customHeader ? customHeader : <PublicNav />}

      <main
        className={clsx(
          classes.main,
          mainWithBackground && classes.mainWithBackground
        )}
      >
        {children}
        <SearchForm onSubmit={handleSearch} />
        {withSearchInputText && (
          <Typography className={classes.searchInputText}>
            Input a URL you visit and understand how the company handles your
            personal information.
          </Typography>
        )}
        <StepperDialog open={form === forms.email} onClose={closeHandler}>
          {!sent ? (
            <ConsumerReportEmailForm
              onSubmit={handleSubmit}
              closeHandler={closeHandler}
            />
          ) : (
            <StepperDialogCongrats closeHandler={closeHandler} />
          )}
        </StepperDialog>
        <StepperDialog open={form === forms.pp} onClose={closeHandler}>
          {!sent ? (
            <ConsumerReportPPForm onSubmit={submitRetry} />
          ) : (
            <StepperDialogCongrats closeHandler={closeHandler} />
          )}
        </StepperDialog>
      </main>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  triggerReport: (values) => dispatch(postTriggerReport(values)),
});

export default connect(null, mapDispatchToProps)(ConsumerReportLayout);
