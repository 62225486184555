export const getConsentsSpotlightPoliciesData = (state) =>
  state.consents.spotlight.policies.data;

export const getConsentsSpotlightPoliciesLoading = (state) =>
  state.consents.spotlight.policies.loading;

export const getConsentsSpotlightApplicationsData = (state) =>
  state.consents.spotlight.applications.data;

export const getConsentsSpotlightApplicationsLoading = (state) =>
  state.consents.spotlight.applications.loading;

export const getConsentsSpotlightPartnersData = (state) =>
  state.consents.spotlight.partners.data;

export const getConsentsSpotlightPartnersLoading = (state) =>
  state.consents.spotlight.partners.loading;
