import React, { useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { postTriggerReport } from "redux/_reports/reports.async.actions";
import { Form } from "react-final-form";
import { getAccountData } from "redux/_account/account.selectors";
import {
  FormControl,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { sendScan } from "components/PrivacyReports/Scanner";
import Loader from "components/Loader";
import useFormStyles from "../../../Scans/Forms/styles.js";
import CustomButton from "components/Button";
import TokenModal from "./TokenModal";
import { RepoPlatformInstructions } from "./RepoPlatformInstructions.jsx";
import { validateRepositoryURL } from "utils/scanValidations";

import useCustomStyles from "./CustomRepoScannerStyles";
const CreateRepositoryScanner = ({ account, triggerSingleRunScan }) => {
  const [withAuth, setWithAuth] = useState("no");
  const [viewWebhook, setViewWebhook] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repoUrl, setRepoUrl] = useState("");
  const [commit, setCommit] = useState("");
  const [githubToken, setGithubToken] = useState("");
  const customClasses = useCustomStyles();
  const formStyles = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [webhookId, setWebhookId] = useState("");
  const [platform, setPlatform] = useState(null);
  const [urlError, setUrlError] = useState('');

  const showTokenFiled = (e) => {
    setWithAuth(e.target.value);
  };

  const invalidUrlMessage = `Available formats for Github or Bitbucket: \n\nhttps://github.com/username/repo \n\nhttps://bitbucket.org/user/repo`;    
  const handleRepoUrl = useCallback((e) => {
    setRepoUrl(e.target.value);
    const platform = validateRepositoryURL(e.target.value);
    setPlatform(platform);
    if (platform === null) {
      setUrlError(invalidUrlMessage);
    } else {
      setUrlError('');
    }
  }, [invalidUrlMessage]);

  const handleCommit = useCallback((e) => {
    setCommit(e.target.value);
  }, []);

  const handleRepoToken = useCallback((e) => {
    setGithubToken(e.target.value);
  }, []);

  const preSubmit = async () => {
    setLoading(true);
    let data = {
      email: account.username,
      environment: "repository",
      resource: repoUrl,
      commit: commit ? commit : "latest",
      notifier: enqueueSnackbar,
    };

    if (withAuth === "yes") {
      const login = {
        password: githubToken,
        user: account.email,
        url: repoUrl,
      };
      data.login = login;
    }

    try {
      const response = await sendScan(triggerSingleRunScan)(data);
      setWebhookId(response.pk);
      setLoading(false);
      // enqueueSnackbar("Scan scheduled successfully", { variant: "success" });
      debugger;
      setViewWebhook(true);
    } catch (error) {
      debugger;
      setLoading(false);
      // enqueueSnackbar("An error has occurred", { variant: "error" });
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return !viewWebhook ? (
    <Form onSubmit={preSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className={customClasses.container}>
          {loading ? (
            <Loader className={customClasses.loaderContainer} />
          ) : (
            <FormControl>
              <FormControl className={customClasses.fieldContainer}>
                <div className={customClasses.leftContainer}>
                  <Typography component="label" className={formStyles.label}>
                    Private Repository
                  </Typography>
                </div>

                <div className={customClasses.rightContainer}>
                  <RadioGroup value={withAuth} onChange={showTokenFiled}>
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                  </RadioGroup>
                </div>
              </FormControl>
              <FormControl className={customClasses.fieldContainer}>
                <div className={customClasses.leftContainer}>
                  <Typography component="label" className={formStyles.label}>
                    URL to scan
                  </Typography>
                </div>
                <div className={customClasses.rightContainer}>
                <TextField
                    className={formStyles.textField}
                    variant="filled"
                    required={true}
                    placeholder="Type Repository URL"
                    value={repoUrl}
                    onChange={handleRepoUrl}
                    error={!!urlError}
                    helperText={urlError}
                  />
                </div>
              </FormControl>
              {withAuth === "yes" && platform ? (
                <FormControl className={customClasses.fieldContainer}>
                  <div className={customClasses.leftContainer}>
                    <Typography component="label" className={formStyles.label}>
                      Token
                    </Typography>
                  </div>
                  <div className={customClasses.rightContainer}>
                    <TextField
                      className={formStyles.textField}
                      variant="filled"
                      placeholder={`Type your ${platform} token`}
                      value={githubToken}
                      onChange={handleRepoToken}
                    />
                    <div>
                      <Button
                        className={customClasses.linkButton}
                        component="button"
                        onClick={handleOpen}
                      >
                        How to get my token?
                      </Button>
                      <TokenModal
                        open={open}
                        handleClose={handleClose}
                        platform={platform}
                      />
                    </div>
                  </div>
                </FormControl>
              ) : (
                ""
              )}
              <FormControl className={customClasses.fieldContainer}>
                <div className={customClasses.leftContainer}>
                  <Typography component="label" className={formStyles.label}>
                    Commit Id
                  </Typography>
                </div>
                <div className={customClasses.rightContainer}>
                  <TextField
                    className={formStyles.textField}
                    variant="filled"
                    required={false}
                    placeholder="Commit ID or branch name."
                    value={commit}
                    onChange={handleCommit}
                  />
                  <div className={formStyles.textField}>
                    Add a commit ID or branch name for scanning, or leave it blank 
                    to scan the latest commit from the master branch.
                  </div>
                </div>
              </FormControl>
              <CustomButton
                type="submit"
                className={formStyles.submit}
                disabled={!platform}
              >
                Create scan
              </CustomButton>
            </FormControl>
          )}
        </form>
      )}
    </Form>
  ) : (
    <>
      {platform && (
        <RepoPlatformInstructions webhookId={webhookId} platform={platform} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
});

const ConnectedCreateRepositoryScanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRepositoryScanner);
export default withRouter(ConnectedCreateRepositoryScanner);
