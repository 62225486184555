import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  defaultHeight: {
    height: 40,
    fontSize: "16px",
    zIndex: 0,
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.blue[400],
      borderWidth: 1,
    },
  },
  inputContainer: {
    minWidth: 255,
  },
  input: {
    "&::placeholder": {
      color: theme.palette.blue[400],
      fontSize: "16px",
      fontStyle: "italic",
      fontFamily: "Inter, sans-serif",
    },
  },
  inputOutline: {
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  selectContainer: {
    flexGrow: 1,
    color: theme.palette.blue[400],
  },
  select: {
    fontSize: "16px",
    fontFamily: "Inter, sans-serif",
    paddingTop: 10,
    paddingBottom: 10,
  },
  selectOutline: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
  },
  selectIcon: {
    position: "absolute",
    right: 12,
    zIndex: -1,
  },
  button: {
    height: 40,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: "none",
    flexGrow: 1,
    minWidth: 110,
    backgroundColor: theme.palette.blue[200],
    color: theme.palette.blue[700],
    fontWeight: "bold",
    fontSize: "16px",
    letterSpacing: "1px",

    "&:hover": {
      backgroundColor: theme.palette.blue[300],
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.blue[300],
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
