import { CONSTANTS } from "redux/_consents/_config/config.constants";

export const requestStart = () => ({
  type: CONSTANTS.CONSENTS_CONFIG_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.CONSENTS_CONFIG_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.CONSENTS_CONFIG_SAVE_DATA,
  data,
});

export const saveFile = (data) => ({
  type: CONSTANTS.CONSENTS_CONFIG_SAVE_FILE,
  data,
});
