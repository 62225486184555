export const DEFAULT_COUNTRY = { name: "United Kingdom", value: "GB" };
export const DEFAULT_LANGUAGE = "EN";
export const DEFAULT_FREQUENCY = 3;

export const FREQUENCY = [
  { value: 0, label: "Daily" },
  { value: 1, label: "Weekly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Manual" },
];

export const LANGUAGES = [
  { value: "AB", label: "Abkhazian" },
  { value: "AA", label: "Afar" },
  { value: "AF", label: "Afrikanns" },
  { value: "SQ", label: "Albanian" },
  { value: "AM", label: "Amharic" },
  { value: "AR", label: "Arabic" },
  { value: "HY", label: "Armenian" },
  { value: "AS", label: "Assamese" },
  { value: "AY", label: "Aymara" },
  { value: "AZ", label: "Azerbaijani" },
  { value: "BA", label: "Bashkir" },
  { value: "EU", label: "Basque" },
  { value: "BN", label: "Bengali, Bangla" },
  { value: "DZ", label: "Bhutani" },
  { value: "BH", label: "Bihari" },
  { value: "BI", label: "Bislama" },
  { value: "BR", label: "Breton" },
  { value: "BG", label: "Bulgarian" },
  { value: "MY", label: "Burmese" },
  { value: "BE", label: "Byelorussian" },
  { value: "KM", label: "Cambodian" },
  { value: "CA", label: "Catalan" },
  { value: "ZH", label: "Chinese (Mandarin)" },
  { value: "CO", label: "Corsican" },
  { value: "HR", label: "Croation" },
  { value: "CS", label: "Czech" },
  { value: "DA", label: "Danish" },
  { value: "NL", label: "Dutch" },
  { value: "EN", label: "English, American" },
  { value: "EO", label: "Esperanto" },
  { value: "ET", label: "Estonian" },
  { value: "FO", label: "Faeroese" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finnish" },
  { value: "FR", label: "French" },
  { value: "FY", label: "Frisian" },
  { value: "GD", label: "Gaelic (Scots Gaelic)" },
  { value: "GL", label: "Galician" },
  { value: "KA", label: "Georgian" },
  { value: "DE", label: "German" },
  { value: "EL", label: "Greek" },
  { value: "KL", label: "Greenlandic" },
  { value: "GN", label: "Guarani" },
  { value: "GU", label: "Gujarati" },
  { value: "HA", label: "Hausa" },
  { value: "IW", label: "Hebrew" },
  { value: "HI", label: "Hindi" },
  { value: "HU", label: "Hungarian" },
  { value: "IS", label: "Icelandic" },
  { value: "IN", label: "Indonesian" },
  { value: "IA", label: "Interlingua" },
  { value: "IE", label: "Interlingue" },
  { value: "IK", label: "Inupiak" },
  { value: "GA", label: "Irish" },
  { value: "IT", label: "Italian" },
  { value: "JA", label: "Japanese" },
  { value: "JW", label: "Javanese" },
  { value: "KN", label: "Kannada" },
  { value: "KS", label: "Kashmiri" },
  { value: "KK", label: "Kazakh" },
  { value: "RW", label: "Kinyarwanda" },
  { value: "KY", label: "Kirghiz" },
  { value: "RN", label: "Kirundi" },
  { value: "KO", label: "Korean" },
  { value: "KU", label: "Kurdish" },
  { value: "LO", label: "Laothian" },
  { value: "LA", label: "Latin" },
  { value: "LV", label: "Latvian, Lettish" },
  { value: "LN", label: "Lingala" },
  { value: "LT", label: "Lithuanian" },
  { value: "MK", label: "Macedonian" },
  { value: "MG", label: "Malagasy" },
  { value: "MS", label: "Malay" },
  { value: "ML", label: "Malayalam" },
  { value: "MT", label: "Maltese" },
  { value: "MI", label: "Maori" },
  { value: "MR", label: "Marathi" },
  { value: "MO", label: "Moldavian" },
  { value: "MN", label: "Mongolian" },
  { value: "NA", label: "Nauru" },
  { value: "NE", label: "Nepali" },
  { value: "NO", label: "Norwegian" },
  { value: "OC", label: "Occitan" },
  { value: "OR", label: "Oriya" },
  { value: "OM", label: "Oromo, Afan" },
  { value: "PS", label: "Pashto, Pushto" },
  { value: "FA", label: "Persian" },
  { value: "PL", label: "Polish" },
  { value: "PT", label: "Portuguese" },
  { value: "PA", label: "Punjabi" },
  { value: "QU", label: "Quechua" },
  { value: "RM", label: "Rhaeto-Romance" },
  { value: "RO", label: "Romanian" },
  { value: "RU", label: "Russian" },
  { value: "SM", label: "Samoan" },
  { value: "SG", label: "Sangro" },
  { value: "SA", label: "Sanskrit" },
  { value: "SR", label: "Serbian" },
  { value: "SH", label: "Serbo-Croatian" },
  { value: "ST", label: "Sesotho" },
  { value: "TN", label: "Setswana" },
  { value: "SN", label: "Shona" },
  { value: "SD", label: "Sindhi" },
  { value: "SI", label: "Singhalese" },
  { value: "SS", label: "Siswati" },
  { value: "SK", label: "Slovak" },
  { value: "SL", label: "Slovenian" },
  { value: "SO", label: "Somali" },
  { value: "ES", label: "Spanish" },
  { value: "SU", label: "Sudanese" },
  { value: "SW", label: "Swahili" },
  { value: "SV", label: "Swedish" },
  { value: "TL", label: "Tagalog" },
  { value: "TG", label: "Tajik" },
  { value: "TA", label: "Tamil" },
  { value: "TT", label: "Tatar" },
  { value: "TE", label: "Telugu" },
  { value: "TH", label: "Thai" },
  { value: "BO", label: "Tibetan" },
  { value: "TI", label: "Tigrinya" },
  { value: "TO", label: "Tonga" },
  { value: "TS", label: "Tsonga" },
  { value: "TR", label: "Turkish" },
  { value: "TK", label: "Turkmen" },
  { value: "TW", label: "Twi" },
  { value: "UK", label: "Ukranian" },
  { value: "UR", label: "Urdu" },
  { value: "UZ", label: "Uzbek" },
  { value: "VI", label: "Vietnamese" },
  { value: "VO", label: "Volapuk" },
  { value: "CY", label: "Welsh" },
  { value: "WO", label: "Wolof" },
  { value: "XH", label: "Xhosa" },
  { value: "JI", label: "Yiddish" },
  { value: "YO", label: "Yoruba" },
  { value: "ZU", label: "Zulu" },
];

export const COUNTRIES = [
  { name: "Argentina", value: "AR" },
  { name: "Australia", value: "AU" },
  { name: "Austria", value: "AT" },
  { name: "Belgium", value: "BE" },
  { name: "Brazil", value: "BR" },
  { name: "Bulgaria", value: "BG" },
  { name: "Canada", value: "CA" },
  { name: "Chile", value: "CL" },
  { name: "China", value: "CN" },
  { name: "Croatia", value: "HR" },
  { name: "Czech Republic", value: "CZ" },
  { name: "Denmark", value: "DK" },
  { name: "Egypt", value: "EG" },
  { name: "Finland", value: "FI" },
  { name: "France", value: "FR" },
  { name: "Germany", value: "DE" },
  { name: "Greece", value: "GR" },
  { name: "Hong Kong", value: "HK" },
  { name: "Hungary", value: "HU" },
  { name: "Iceland", value: "IS" },
  { name: "India", value: "IN" },
  { name: "Indonesia", value: "ID" },
  { name: "Ireland", value: "IE" },
  { name: "Israel", value: "IL" },
  { name: "Italy", value: "IT" },
  { name: "Japan", value: "JP" },
  { name: "Jordan", value: "JO" },
  { name: "Malaysia", value: "MY" },
  { name: "Mexico", value: "MX" },
  { name: "Netherlands", value: "NL" },
  { name: "New Zealand", value: "NZ" },
  { name: "Norway", value: "NO" },
  { name: "Philippines", value: "PH" },
  { name: "Poland", value: "PL" },
  { name: "Portugal", value: "PT" },
  { name: "Russia", value: "RU" },
  { name: "Singapore", value: "SG" },
  { name: "South Africa", value: "ZA" },
  { name: "South Korea", value: "KR" },
  { name: "Spain", value: "ES" },
  { name: "Sweden", value: "SE" },
  { name: "Switzerland", value: "CH" },
  { name: "Taiwan", value: "TW" },
  { name: "Thailand", value: "TH" },
  { name: "Turkey", value: "TR" },
  { name: "Ukraine", value: "UA" },
  DEFAULT_COUNTRY, // { name: "United Kingdom", value: "GB" }
  { name: "United States", value: "US" },
  { name: "Vietnam", value: "VN" },
];
