import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const simpleFormStyle = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  field: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },
  button: {
    margin: theme.spacing(1, 0, 4, 0),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: theme.palette.common.white,
  },
  actionLink: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.htmlFontSize,
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.red[500],
    marginBottom: 9,
  },
}));

export default makeStyles((theme) => ({
  leftContainer: {
    backgroundColor: fade(theme.palette.blue[100], 0.2),
    position: "relative",
    boxShadow: "6px 0px 20px #152A69",
  },
  formContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 10px 10px rgba(8, 20, 78, 0.03)",
    borderRadius: 4,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: theme.palette.grey[800],
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.47,
    letterSpacing: "normal",
    color: theme.palette.grey[300],
    marginBottom: theme.spacing(2),
  },
  field: {
    margin: theme.spacing(2, 0),
  },
  button: {
    width: "100%",
    backgroundColor: theme.palette.blue["young"],
    padding: theme.spacing(1, 2),
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "21px",
    textAlign: "center",
    letterSpacing: 1,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.blue[500],
      color: theme.palette.common.white,
    },
    "&:disabled": {
      color: theme.palette.blue["young"],
    },
  },
  footer: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "22px",
    color: theme.palette.grey[700],
    "& > a": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "22px",
      textDecorationLine: "underline",
      color: theme.palette.blue["young"],
    },
  },
  imageContainer: {
    backgroundColor: theme.palette.blue[500],
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: theme.palette.common.white,
  },
  error: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "22px",
    color: theme.palette.red[500],
  },
}));
