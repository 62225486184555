import aws from "./aws";
import database from "../database-icon";
import salesforce from "../Integrations/salesforce";
import slack from "../Integrations/slack";

export default {
  aws,
  database,
  salesforce,
  slack,
};
