import history from "helpers/history";
import React from "react";

const Link = ({ to, className, children }) => (
  <a
    href={to}
    className={className}
    onClick={(e) => {
      e.preventDefault();
      global.scrollTo({ top: 0 });
      history.push(to);
    }}
  >
    {children}
  </a>
);

export default Link;
