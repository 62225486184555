import { CONSTANTS } from "redux/_admin/_company/company.constants";

export const requestStart = () => ({
  type: CONSTANTS.ADMIN_COMPANY_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.ADMIN_COMPANY_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.ADMIN_COMPANY_SAVE_DATA,
  data,
});

export const setPolicyCookies = (data) => ({
  type: CONSTANTS.SET_COMPANY_COOKIES,
  data,
});

export const setPolicyCookie = (data) => ({
  type: CONSTANTS.SET_COMPANY_COOKIE,
  data,
});

export const removePolicyCookie = (data) => ({
  type: CONSTANTS.REMOVE_COMPANY_COOKIE,
  data,
});
