import { CONSTANTS } from "redux/_requests/_config/config.constants";

const initialState = {
  loading: false,
  data: {},
};

export const config = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.REQUESTS_CONFIG_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.REQUESTS_CONFIG_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.REQUESTS_CONFIG_SAVE_DATA:
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};
