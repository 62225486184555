import React, { useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import { Drawer, Button, IconButton, Box } from "@material-ui/core";
import Tooltip from "components/Tooltip";
import EnvSelector from "./components/envSelector";
import KPIs from "components/PageWrapper/components/FiltersBar/components/kpis";
import CustomKPIs from "components/PageWrapper/components/FiltersBar/components/customKpis";
import ConsentsKPIs from "components/PageWrapper/components/FiltersBar/components/consentsKpis";
import Filters from "./components/Filters";
import { MoreVert, ArrowBack } from "@material-ui/icons";
import CONSTANTS from "redux/_filters/filters.constants";
import isNil from "lodash.isnil";
import isEmpty from "lodash.isempty";

const { TYPES } = CONSTANTS;

const FiltersBar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const toggleSidebar = () => setOpen(!open);

  const {
    filters = [],
    showKpis,
    kpisTitle,
    customKpis,
    customContent,
  } = props;

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: clsx(
          classes.drawer,
          open && classes.drawerOpen,
          !open && classes.drawerClose
        ),
        paperAnchorLeft: classes.stackedDrawer,
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      {open ? (
        <div className={classes.content}>
          {filters.includes(TYPES.ENV) && <EnvSelector fullWidth={open} />}
          {showKpis && (
            <KPIs
              title={kpisTitle}
              isFilteringByScan={filters.includes(TYPES.SCAN)}
            />
          )}
          {filters.some((r) => Object.values(TYPES.CONSENTS).includes(r)) && (
            <ConsentsKPIs />
          )}
          {customKpis && <CustomKPIs title={kpisTitle} kpis={customKpis} />}
          {!isNil(customContent) && React.cloneElement(customContent)}
          {!isEmpty(filters) &&
            (filters.length > 1 || filters[0] !== TYPES.ENV) && (
              <Filters filters={filters} />
            )}
          <Box
            className={classes.buttonContainer}
            width="calc(100% + 32px)"
            position="sticky"
            bottom={0}
          >
            <Button
              onClick={toggleSidebar}
              classes={{ root: classes.button, label: classes.buttonText }}
            >
              <ArrowBack classes={{ root: classes.buttonIcon }} />
              COLLAPSE
            </Button>
          </Box>
        </div>
      ) : (
        <Tooltip title="Expand" placement="right" aria-label="Expand">
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={toggleSidebar}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      )}
    </Drawer>
  );
};

export default FiltersBar;
