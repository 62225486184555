import React, { PureComponent } from "react";
import { scaleLinear, tree, hierarchy } from "d3";

import {
  BOX_WIDTH,
  BOX_WIDTH_WIDER,
  BOX_HEIGHT,
  BOX_HORIZONTAL_MARGIN,
  BOX_VERTICAL_MARGIN,
} from "./consts";
import Link from "./Link";
import Rectangle from "./Rectangle";
import isEqual from "lodash.isequal";

const getNodeKey = (node) => {
  if (node.parent) return `${getNodeKey(node.parent)}>${node.data.name}`;
  return node.data.name;
};

// const isSibling = (node, selected) => {
//   return selected.parent.children.filter(x => x.id === node.id).length > 0
// }

// const isAnsestorSibling = (node, selected) => {
//   if (node.depth <= selected.parent.depth) {
//     if (isSibling(node, selected.parent)) return true
//     return isAnsestorSibling(node, selected.parent)
//   }
//   return false
// }

const isAnsestor = (node, selected) => {
  if (node.depth < selected.depth) {
    if (selected.parent.depth === node.depth) {
      if (selected.parent.id !== node.id) return false;
      return true;
    }
    return isAnsestor(node, selected.parent);
  }
  return false;
};

// const filterSelected = (node, selected) => {
//   if (node.depth === 0) return false
//   else if (node.depth >= 5) return false
//   else if (!selected || node.depth === 1) return node.depth === 1
//   else if (node.id === selected.id) return true
//   else if (selected.children.filter(x => x.id === node.id).length) return true
//   else if (isAnsestor(node, selected)) return true
//   else if (isSibling(node, selected)) return true
//   else return isAnsestorSibling(node, selected)
// }

const weightedPercentage = (node) => {
  let weightedSum = 0;
  const totalMatchesByGroup = node.children.reduce(
    (prev, curr) => prev + curr.data.matches,
    0
  );
  node.children.forEach((child) => {
    weightedSum +=
      (child.data.matches / totalMatchesByGroup) * child.data.matches;
  });
  return weightedSum / node.data.matches;
};

const calcPercentage = (node) => {
  switch (node.data.type) {
    case "column":
      return weightedPercentage(node);
    case "pii_groups":
      return node.data.matches / node.data.scannedRows;
    default:
      return node.data.columnsPii / node.data.columns;
  }
};

class Tree extends PureComponent {
  constructor() {
    super();
    this.state = {
      drawcall: 0,
      points: [],
      selected: [],
      scrollLimit: 0,
    };
  }

  onRectangleClick = (node) => {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect((selected) => {
        if (selected && node.id === selected.id) return null;
        return node;
      });
    }
  };

  componentDidMount() {
    this.updatePoints();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.updatePoints();

      const { onSelect } = this.props;
      if (onSelect) onSelect(null);
    } else if (prevProps.selected !== this.props.selected)
      this.setState(() => {
        return {
          selected: [this.props.selected, prevProps.selected],
        };
      });

    if (prevState.scrollLimit !== this.state.scrollLimit) {
      this.props.onReportScrollLimit(this.state.scrollLimit);
    }
  }

  updatePoints = () => {
    const { data } = this.props;
    let idCount = 0;
    this.setState({
      nextSelected: null,
      points: hierarchy(data)
        .sum((d) => d.matches)
        .each((node) => {
          node.key = getNodeKey(node);
          node.id = idCount++;
          node.percentage = calcPercentage(node);
        }),
    });
  };

  getScales = () => {
    const { colors, width, wider } = this.props;
    const boxWidth = wider ? BOX_WIDTH_WIDER : BOX_WIDTH;

    const color = scaleLinear()
      .domain(
        new Array(colors.length - 1).fill(0).map((x, i) => 1 / colors.length)
      )
      .range(colors);

    let levelAccumulator = {};
    const x = (node) => {
      levelAccumulator[node.depth] = levelAccumulator[node.depth] + 1 || 1;
      let x =
        width -
        50 -
        levelAccumulator[node.depth] * (BOX_HEIGHT + BOX_VERTICAL_MARGIN); // - (node.parent && node.parent.children.findIndex(x => x.id === node.id) + 1) * (BOX_HEIGHT + BOX_VERTICAL_MARGIN) - 50// + scroll
      return x;
    };

    const calculatePosition = (i) =>
      (width - 26) / 8 + ((width - 26) / 4) * (i - 1);
    const minimalPosition = (node) =>
      (node.depth - 1) * (boxWidth + BOX_HORIZONTAL_MARGIN);
    const maxPosition = (node) =>
      (node.depth - 1) * (boxWidth + BOX_HORIZONTAL_MARGIN + 16);
    const y = (node) =>
      Math.min(
        Math.max(
          minimalPosition(node),
          calculatePosition(node.depth) - boxWidth / 2
        ),
        maxPosition(node)
      );

    return { x, y, color };
  };

  reportScrollLimit = (nodes) => {
    const { height } = this.props;
    const scrollLimit = Math.min(...nodes.map((node) => node.x)) - height;
    if (scrollLimit < 0) this.setState({ scrollLimit });
    else this.setState({ scrollLimit: 0 });
  };

  draw = () => {
    const { width, height, scroll, wider } = this.props; //scroll
    const { selected } = this.state;
    const isRelated = (node, nselected) => {
      if (node.id === nselected.id) return true; //self
      if (node.depth < nselected.depth) {
        return isAnsestor(node, nselected);
      } else {
        //child or sibling
        if (node.depth === nselected.depth) return false;
        //siblings go unselected
        else if (nselected.descendants().filter((x) => x.id === node.id).length)
          return true;
        return false;
      }
    };

    const points = this.state.points
      .each((node) => {
        if (selected.length && selected[0]) {
          node.selected = selected[0].id === node.id;
          node.ancestorSelected = isRelated(node, selected[0]);
          if (node.selected || node.ancestorSelected) node.opacity = 1;
          else node.opacity = 0.4;
        } else {
          node.selected = false;
          node.ancestorSelected = false;
          node.opacity = 1;
        }
      })
      .sort((a, b) => {
        if (a.selected === b.selected) {
          if (a.ancestorSelected === b.ancestorSelected) {
            return b.percentage - a.percentage;
          } else {
            return b.ancestorSelected > a.ancestorSelected ? 1 : -1;
          }
        } else {
          return b.selected > a.selected ? 1 : -1;
        }
      });

    const tr = tree().size([height, width - 30]);

    const shouldDrawNode = (node) => {
      if (!node.depth || node.depth >= 5) return false;
      return true;
    };

    const source = tr(points);
    const scales = this.getScales();

    source
      .sort((a, b) => a.selected > b.selected)
      .each((node) => {
        node.y = scales.y(node);
        node.x = scales.x(node);
        node.color = scales.color(node.percentage);
      });

    const links = source
      .links()
      .filter((link) => link.source.depth && shouldDrawNode(link.target));
    const nodes = source.descendants().filter((node) => shouldDrawNode(node));

    this.reportScrollLimit(nodes);

    links.forEach((link) => {
      link.key = link.source.key + link.target.key;
    });

    const isPointInsideViewBox = (node) => {
      const position = node.x - width;
      return position <= scroll - 50 && position >= -height + scroll - 100;
    };

    const sortedNodes = nodes.sort((a, b) => (a.key > b.key ? 1 : -1));

    return {
      nodes: sortedNodes.map((node) => (
        <Rectangle
          draw={shouldDrawNode(node) && isPointInsideViewBox(node)}
          key={node.key}
          node={node}
          onClick={this.onRectangleClick}
          x={node.x}
          y={node.y}
          color={node.color}
          wider={wider}
        />
      )),
      links: links
        .sort((a, b) => (a.key > b.key ? 1 : -1))
        .map((link) => (
          <Link
            draw={
              isPointInsideViewBox(link.target) ||
              isPointInsideViewBox(link.source)
            }
            key={link.key}
            link={link}
            wider={wider}
          />
        )),
    };
  };

  getCenterGroupValue() {
    const { width, wider } = this.props;
    const boxWidth = wider ? BOX_WIDTH_WIDER : BOX_WIDTH;
    const scales = this.getScales();
    const last = scales.y({ depth: 4 });
    return Math.max(0, width / 2 - (last + boxWidth) / 2);
  }

  render() {
    const { width, height } = this.props;
    if (width && height) {
      const draw = this.draw();
      return (
        <>
          <g
            transform={`rotate(-90) translate(${
              -width + 50
            } ${this.getCenterGroupValue()})`}
          >
            {draw.nodes}
            {draw.links}
          </g>
        </>
      );
    }
    return null;
  }
}

export default Tree;
