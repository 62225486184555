import { makeStyles } from "@material-ui/core";

import { stepperDialogContentMixin } from "components/StepperDialog/styles";

export default makeStyles(
  (theme) => ({
    congrats: {
      width: "600px",
      height: "600px",
      borderRadius: "50%",
      backgroundColor: "rgba(48, 86, 174, 0.05)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& > .container": {
        width: "70%",
        height: "600px",
        marginTop: "76px",
        padding: 0,
        "& > p": {
          margin: "30px 0 61px",
        },
      },
    },
    ...stepperDialogContentMixin(theme),
  }),
  { name: "StepperDialogCongrats" }
);
