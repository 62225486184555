import { makeStyles } from "@material-ui/core";
import headerBackground from "assets/images/masthead_bg.svg";

export default makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.blue["500"],
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(0, 0, 11),
    paddingTop: theme.spacing(5) + 126,
  },
  formSection: {
    padding: theme.spacing(0, 0, 12),
  },
  title: {
    fontFamily: "Aldrich, sans-serif",
    fontSize: "40px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: theme.palette.blue[400],
    textAlign: "center",
  },
  input: {
    flexGrow: 1,
    margin: theme.spacing(2),
    "& label span": {
      fontFamily: "Inter, sans-serif!important",
    },
    "& input, & textarea": {
      fontFamily: "Inter, sans-serif!important",
    },
    "& input": {
      height: 34,
    },
  },
  nameInput: {
    width: "50%",
  },
  submit: {
    backgroundColor: theme.palette.aquamarine[100],
    border: "0 none",
    borderRadius: 6,
    color: theme.palette.blue["young"],
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
    height: "48px",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: "relative",

    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
  },
  buttonLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
