export const columns = [
  {
    id: "individual_right.regulation.name",
    label: "Reg.",
  },
  {
    id: "submit.email",
    label: "Email",
  },
  {
    id: "individual_right_days_left",
    label: "Days Left",
  },
  {
    id: "status",
    label: "Status",
  },
];
