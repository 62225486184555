import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { Box } from '@material-ui/core';

import useReports from "hooks/useReports";
import useQuery from "hooks/useQuery";

import Header from '../../components/Header';
import Pagination from '../../../../../pages/AppReport/common/Pagination';
import usePaginationStyles from "../../../../../pages/AppReport/common/Pagination/styles";
import { ArrowBackThirdPartyTrackers } from "./ArrowBackThirdPartyTrackers";
import { CardsTags } from "./CardsTags";

import { getCurrentReportJSON } from "redux/_reports/reports.selectors";
import { getReportJSON as fetchReportJSON } from "redux/_reports/reports.async.actions";
import { getPlanInfo as getPlanInfoAction } from "redux/_plans/plans.async.actions.js";

import useStyles from "./styles";
import { VariablesTable } from './VariablesTable';

function filterVariableByTag(variables, tag) {
  const result = [];

  for (const key in variables) {
    if (variables[key].tags.includes(tag)) {
      result.push(key);
    }
  }

  return result;
}

const VariableInventory = ({
  account,
  currentReportJSON,
  getPlanInfo,
  getReportJSON,
  history,
  isAuthenticated,
  location,
  planInfo,
  ...props
}) => {
  const classes = useStyles();
  const paginationClasses = usePaginationStyles();
  const { selected: report, list: demos, ...reports } = useReports();
  const [selectedCard, setSelectedCard] = useState(null);
  const [cards, setCards] = useState([]);
  const query = useQuery();
  const variableInventory = currentReportJSON?.variable_inventory;
  const reportId = query.get("r");
  const token = query.get("t");
  const trackerSelected = query.get("tracker");
  const hostSelected = query.get("host");
  const email = query.get("email")
    ? decodeURIComponent(query.get("email"))
    : undefined;
  const [filteredVariables, setFilteredVariables] = useState([]);
  const [trackerTitle, setTrackerTitle] = useState(trackerSelected);
  const [current, setCurrent] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [endItems, setEndItems] = useState(0);
  const [total, setTotal] = useState(0);
  const [start, setStart] = useState(0);

  const {
    availableLocations,
    availableReports,
    location: reportLocation,
    resource: site,
    endedAt: reportDate,
  } = report;
  const { environment = "web", flavor } = report.scan ? report.scan : {};

  useEffect(
    () => {
      reports.get(reportId, email, { t: token }, true).catch(() => {
        history.push("/login");
      });
    },
    [reportId]
  );

  useEffect(
    () => {
      if (report) if (report.data) getReportJSON(report.data);
    },
    [report]
  );

  useEffect(() => {
    limitPageOnChange();
  }, [filteredVariables, endItems, start, total, limitPerPage, current]);

  useEffect(() => {
    const filterVariables = filterVariableByTag(variableInventory, trackerSelected);
    setFilteredVariables(filterVariables);
    setTotalItems(filterVariables.length);
    setTotal(Math.ceil((filterVariables.length) / limitPerPage));
    setStart(current * limitPerPage);
    const potentialEnd = start + limitPerPage;
    if (filterVariables) {
      if (potentialEnd < filterVariables.length) {
        setEndItems(potentialEnd);
      } else {
        setEndItems(start + (filterVariables.length - start));
      }
    }
    let cardIndex = 0;
    let cardsTrackers = [];
    let cardIsSelected = false;
    currentReportJSON?.trackers?.forEach(tracker => {
      if (tracker.host === hostSelected) {
        const trackerMapped = tracker.url.replace(/(\?|\=).*/g, "");
        if (trackerMapped === trackerSelected && !cardIsSelected)
        {
          cardIsSelected = true;
          setSelectedCard(cardIndex);
        }
        const tagExists = cardsTrackers.some(card => card.title === trackerMapped);
        if (!tagExists) {
          const variablesByTracker = filterVariableByTag(variableInventory, trackerMapped);
          cardsTrackers.push({
            title: trackerMapped,
            content: `${variablesByTracker.length} variables, ${currentReportJSON.trackers.length} pages`
          });
        }
        cardIndex++;
      }
    });
    setCards(cardsTrackers);
  }, [currentReportJSON, hostSelected]);

  const limitPageOnChange = () => {;
    setTotalItems(filteredVariables.length);
    setTotal(Math.ceil((filteredVariables.length) / limitPerPage));
    setStart(current * limitPerPage);
    const potentialEnd = start + limitPerPage;
    if (filteredVariables) {
      if (potentialEnd < filteredVariables.length) {
        setEndItems(potentialEnd);
      } else {
        setEndItems(start + (filteredVariables.length - start));
      }
    }
  };

  const handleCardClick = (index) => {
    const filterVariables = filterVariableByTag(variableInventory, cards[index].title);
    setSelectedCard(index);
    setFilteredVariables(filterVariables);
    setTotalItems(filterVariables.length);
    setTrackerTitle(cards[index].title);
    setTotal(Math.ceil((filterVariables.length) / limitPerPage));
    setStart(current * limitPerPage);
    const potentialEnd = start + limitPerPage;
    if (filterVariables) {
      if (potentialEnd < filterVariables.length) {
        setEndItems(potentialEnd);
      } else {
        setEndItems(start + (filterVariables.length - start));
      }
    }
  };

  return (
    <Box>
      <Header
          className={classes.header}
          hidden={false}
          url={site}
          availableDates={availableReports}
          availableLocations={availableLocations}
          date={reportDate}
          loading={false}
          location={report.location}
          reportType={"web"}
          flavor={flavor}
          previousRouteKey="zenArea"
        />
      <Box className={classes.root}>
        <Box className={classes.tableContainerWrapper}>
          <Box className={classes.leftSectionWrapper}>
            <ArrowBackThirdPartyTrackers reportId={reportId}/>
            <CardsTags
              cards={cards}
              selectedCard={selectedCard}
              handleCardClick={handleCardClick}
            />
          </Box>
          <Box className={classes.tableWithPaginationWrapper}>
            <VariablesTable
              trackerTitle={trackerTitle}
              filteredVariables={filteredVariables}
              variableInventory={variableInventory}
              currentReportJSON={currentReportJSON}
              limitPerPage={limitPerPage}
              start={start}
              endItems={endItems}
            />
            <Box className={classes.paginationWrapper}>
              <Pagination
                className={paginationClasses.pagination}
                current={current}
                total={total}
                setCurrent={setCurrent}
                backPaginated={undefined}
                end={endItems}
                start={start + 1}
                totalItems={totalItems}
                limitPerPage={limitPerPage}
                onChangeLimitPerPage={setLimitPerPage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  currentReportJSON: getCurrentReportJSON(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReportJSON: (url) => dispatch(fetchReportJSON(url, false)),
  getPlanInfo: () => dispatch(getPlanInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VariableInventory);
