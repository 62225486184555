export const steps = [
  {
    label: "Welcome",
    icon: "message",
  },
  {
    label: "Create Enviroment",
    icon: "company",
  },
  {
    label: "Add Databases",
    icon: "database",
  },
  {
    label: "Scan Preferences",
    icon: "preferences",
  },
  {
    label: "Completed Setup",
    icon: "check",
  },
];
