import React, { useState, useEffect } from "react";
import { scaleLinear } from "d3-scale";
import { arc } from "d3-shape";
import { max } from "d3-array";
import Arc from "./Arc";
import Arrows from "./Arrows";

const d3 = {
  scaleLinear,
  arc,
  max,
};

const Radialchart = ({
  data: rawData,
  // width: rawWidth,
  height: rawHeight,
  colors,
  title,
  onChange = () => {},
  pagination,
  countNegative,
}) => {
  const margins = {
    top: 0,
    bottom: title ? 30 : 0,
    left: 0,
    right: 0,
  };
  // const width = rawWidth - margins.left - margins.right;
  const height = rawHeight - margins.top - margins.bottom;
  const chartRadius = Math.min(height / 2);

  const [page, setPage] = useState(0);
  const handleBack = () => {
    setPage(page - 1);
    onChange(
      rawData.slice((page - 1) * 3, (page - 1) * 3 + 3).map((d) => d.id)
    );
  };
  const handleNext = () => {
    setPage(page + 1);
    onChange(
      rawData.slice((page + 1) * 3, (page + 1) * 3 + 3).map((d) => d.id)
    );
  };
  const data = rawData
    .map((d) => ({
      ...d,
      value:
        d.opted_in + d.opted_out !== 0
          ? Math.round(
              ((countNegative ? d.opted_out : d.opted_in) /
                (d.opted_in + d.opted_out)) *
                100
            )
          : 0,
    }))
    .sort((a, b) => (a.value > b.value ? -1 : 1))
    .slice(page * 3, page * 3 + 3)
    .map((d, i) => ({
      ...d,
      color: colors[i],
    }));

  useEffect(() => {
    onChange(data.map((d) => d.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, 2 * Math.PI]);

  // const keys = data.map(d => d.name);

  const arcMinRadius = chartRadius * 0.41875;
  const numArcs = 3;
  const arcWidth = 9;
  const arcPadding =
    (chartRadius - numArcs * arcWidth - arcMinRadius) / (numArcs - 1);

  const getInnerRadius = (index) =>
    arcMinRadius + (numArcs - index - 1) * (arcWidth + arcPadding);
  const getOuterRadius = (index) => getInnerRadius(index) + arcWidth;

  const arc = d3
    .arc()
    .innerRadius((d, i) => getInnerRadius(i))
    .outerRadius((d, i) => getOuterRadius(i))
    .startAngle(0)
    .endAngle((d) => scale(d));

  return (
    <>
      <g
        transform={`translate(${height / 2 + margins.left} ${
          height / 2 + margins.top
        })`}
      >
        {data.map((d, i) => (
          <Arc
            key={d.id}
            value={d.value}
            data={d}
            index={i}
            generator={arc}
            color={d.color}
          />
        ))}
      </g>
      <g
        transform={`translate(${height + 16} ${rawHeight / 2 - 40})`}
        fontFamily="Roboto Condensed, sans-serif"
        style={{ textTransform: "uppercase" }}
        fontSize="12px"
        fill="#3b3f4c"
      >
        {data.map((d, i) => (
          <g key={d.name} transform={`translate(0 ${i * 30})`}>
            <title>
              {d.name}: {d.value}%
            </title>
            <circle fill={d.color} r={5} cx={5} cy={-5} />
            <text transform="translate(20)">
              {d.name.length > 20 ? `${d.name.substring(0, 24)}...` : d.name}:{" "}
              <tspan fontWeight="bold">{d.value}%</tspan>
            </text>
          </g>
        ))}
        {pagination && (
          <Arrows
            onClickBack={handleBack}
            onClickNext={handleNext}
            disableBack={page <= 0}
            disableNext={page + 1 >= Math.ceil(rawData.length / 3)}
            y={data.length * 30}
          />
        )}
      </g>
      {title && (
        <text
          fontFamily="Roboto Condensed, sans-serif"
          fontWeight="bold"
          letterSpacing="1"
          style={{ textTransform: "uppercase" }}
          fill="#4f4f4f"
          transform={`translate(${height / 2} ${height + margins.bottom})`}
          textAnchor="middle"
          fontSize={12}
        >
          {title}
        </text>
      )}
    </>
  );
};

export default Radialchart;
