import React from "react";
import { Box, Typography } from "@material-ui/core";
const ChipDataFlow = ({
  image = "",
  backChipColor = "white",
  labelColor = "#868993",
  label = "",
  boderchipColor = '#868993',
}) => {
  return (
    <Box
      style={{
        border: `2px solid #1B3586`,
        borderRadius: 16,
        display: "flex",
        flexDirection: "row",
        borderColor: "#1B3586",
        border: boderchipColor ? "1px solid " + boderchipColor : "",
        background: backChipColor,
        padding: 6,
        justifyContent: "center",
      }}
    >
      <Typography style={{ fontSize: 10, color: labelColor }}>
        {label}
      </Typography>
    </Box>
  );
};

export default ChipDataFlow;
