import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  table: {
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  xsTable: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mdTable: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  primary: {
    boxShadow: "0px 1px 6.300000190734863px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 11,
  },
  cell: {
    fontSize: "14px",
  },
  tr: {
    display: "flex",
    color: "#868993",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
    borderBottom: "1px solid #E0E1E3",
  },
  th: {
    padding: ({ variant }) =>
      variant === "primary" ? theme.spacing(1, 2) : theme.spacing(0, 1),
    fontSize: ({ variant }) => (variant === "primary" ? 16 : "inherit"),
  },

  row: {
    height: "min-content",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #B0BAC9",
    "&:not(&:last-child)": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: 0,
      maxHeight: theme.spacing(16),
      border: "none",
      "&:not(&:last-child)": {
        marginBottom: 0,
      },
    },
  },
  data: {
    padding: theme.spacing(2),
    verticalAlign: "baseline",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "26px",
    color: theme.palette.grey[700],
    borderBottom: "1px solid #E0E1E3",
    overflowY: "auto",
    wordBreak: "break-word",
    "&:last-child": {
      paddingRight: 0,
    },
    "& > ul": {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: ({ variant }) =>
        variant === "primary" ? theme.spacing(2) : theme.spacing(1),
    },
  },
}));

export default styles;
