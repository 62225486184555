export const roles = [
  {
    value: "P",
    label: "Processor",
  },
  {
    value: "C",
    label: "Controller",
  },
];
