import { getConnectedApps } from "./salesforce.service";
import {
  requestStart,
  requestEnd,
  saveData,
  setError,
  setMeta,
} from "./salesforce.actions";

export const getSalesforceConnectedApps = () => (dispatch) => {
  dispatch(requestStart());
  return getConnectedApps()
    .then(([data, meta]) => {
      dispatch(requestEnd());
      dispatch(saveData(data, "connectedApps"));
      dispatch(setMeta(meta, "connectedApps"));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(requestEnd());
      dispatch(setError(error, "connectedApps"));
      return Promise.reject(error);
    });
};
