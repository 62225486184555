import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
import TextField from "components/Inputs/Text";
import useStyles from "./styles";
import { Hidden, Box, Button, CircularProgress } from "@material-ui/core";
import HomepageMenu from "components/HomepageMenu";
import PublicNav from "components/PublicComponents/Nav";
import Header from "components/PublicComponents/Header";
import Footer from "components/PublicComponents/Footer";
import { useSnackbar } from "notistack";
import { parseQuery } from "utils/queryString";
import config from "config";
import Seo, { pages } from "components/Seo";

function RequestADemo({ location, history }) {
  const [email, setEmail] = useState("");
  useEffect(() => {
    const queryParams = parseQuery(location.search);
    if (queryParams.e) {
      setEmail(decodeURIComponent(escape(window.atob(queryParams.e))));
      history.replace({ pathname: "/upgrade-plan" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const Input = ({ input, placeholder, meta, render, ...restProps }) => (
    <TextField
      {...input}
      label={placeholder}
      className={classes.input}
      error={meta.error && meta.submitFailed}
      disabled={loading}
      helperText={
        meta.error &&
        meta.submitFailed && <span style={{ color: "red" }}>{meta.error}</span>
      }
      {...restProps}
    />
  );

  const onSubmit = (values, form) => {
    setLoading(true);
    return fetch(`${config.apiUrl}/demorequest`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setLoading(false);
        setTimeout(form.reset);
        const { email } = values;
        if (global.gtag) global.gtag("event", "contact", { email });
        enqueueSnackbar("Message successfully sent!", { variant: "success" });
      })
      .catch(() => {
        setLoading(false);
        setTimeout(form.reset);
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      });
  };
  const required = (value) =>
    !value ? "Please complete this field." : undefined;
  return (
    <div id="outer-container">
      <Seo {...pages.upgradePlan} />
      <Hidden mdUp>
        <HomepageMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </Hidden>
      <div
        id="page-wrap"
        className="homepage"
        style={{ backgroundColor: "#fff" }}
      >
        <PublicNav transparent />

        <section className={classes.header}>
          <Header
            title="Any additional questions/feedback?"
            description="We would love to chat. Please let out the form below."
          />
        </section>

        <section className={classes.formSection}>
          <div className="container">
            <Form
              initialValues={{ email }}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <Box clone mx="auto" maxWidth={532} my={7}>
                  <form onSubmit={handleSubmit}>
                    <Box display="flex" mb={-2}>
                      <Field
                        name="first_name"
                        component={Input}
                        placeholder="First Name1 *"
                        validate={required}
                        className={`${classes.input} ${classes.nameInput}`}
                      />
                      <Field
                        name="last_name"
                        component={Input}
                        placeholder="Last Name"
                        className={`${classes.input} ${classes.nameInput}`}
                      />
                    </Box>
                    <Field
                      name="email"
                      type="email"
                      component={Input}
                      placeholder="Email *"
                      validate={required}
                    />
                    <Field
                      name="phone"
                      component={Input}
                      placeholder="Phone Number"
                    />
                    <Field
                      name="organization"
                      component={Input}
                      placeholder="Organization *"
                      validate={required}
                    />
                    <Field name="title" component={Input} placeholder="Title" />
                    <Field
                      name="description"
                      component={Input}
                      placeholder="How can we help you?"
                      multiline
                      rows="3"
                    />
                    <Box display="flex" mb={-2}>
                      <Button
                        disabled={loading}
                        type="submit"
                        className={classes.submit}
                      >
                        Submit
                        {loading && (
                          <CircularProgress
                            className={classes.buttonLoader}
                            color="primary"
                            size={24}
                          />
                        )}
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
            />
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default withRouter(RequestADemo);
