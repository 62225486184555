import { Path, Svg, Text, View } from "@react-pdf/renderer";
import {
  CATEGORIES,
  categoryKeysToData,
} from "components/PrivacyReports/Report/components/LiveProcesses";

export const positive = (
  <Svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0Z"
      fill="#6FCF97"
    />
    <Path
      d="M7.81353 13.1007L4.96873 10.302L4 11.2483L7.81353 15L16 6.94631L15.0381 6L7.81353 13.1007Z"
      fill="white"
    />
  </Svg>
);
export const negative = (
  <Svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0Z"
      fill="#EB5757"
    />
    <Path
      d="M15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
      fill="white"
    />
  </Svg>
);

const getIcon = (value) => {
  return value ? positive : negative;
};

const AssessmentSummaryyPage = ({ styles, data }) => (
  <View style={styles.section}>
    <View>
      <Text style={styles.title}>Privacy Policy vs. Live Processes</Text>
      <Text>
        We used NLP/ML techniques to cross check your privacy policy against
        what is actually happening on your websites to identify potential
        mismatches.
      </Text>
    </View>
    <View>
      <Text>Assessment summary</Text>
      <View>
        <View style={styles.table}>
          <View style={styles.thead}>
            <View style={styles.tr}>
              <View style={styles.th}>
                <Text>Tracking types</Text>
              </View>
              <View style={styles.th}>
                <Text>In privacy policy</Text>
              </View>
              <View style={styles.th}>
                <Text>Reality check</Text>
              </View>
              <View style={styles.th}>
                <Text>Status</Text>
              </View>
            </View>
          </View>
          {Object.keys(CATEGORIES)
            .map(categoryKeysToData(data))
            .map(([category, element, key]) => {
              return (
                <View key={key} style={styles.tr}>
                  <View style={styles.th}>
                    <Text>{category}</Text>
                  </View>
                  <View style={styles.td}>
                    {key !== "highRisk" ? (
                      getIcon(element.inPrivacyPolicy)
                    ) : (
                      <Text>-</Text>
                    )}
                  </View>
                  <View style={styles.td}>{getIcon(element.ourCheck)}</View>
                  <View style={styles.td}>{getIcon(element.summary)}</View>
                </View>
              );
            })}
        </View>
      </View>
    </View>
  </View>
);

export default AssessmentSummaryyPage;
