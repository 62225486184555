import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import SVGIcon from "components/svg-icon/svg-icon";
import KeysTable from "components/KeyGenerator/Form/components/KeysTable";

const KeyGeneratorForm = ({ productKey, keyName, instructions }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {instructions}
      <Box>
        <KeysTable productKey={productKey} keyName={keyName} />
      </Box>
      <div className={classes.backgroundIcon}>
        <SVGIcon icon="lock" />
      </div>
    </Box>
  );
};

export default KeyGeneratorForm;
