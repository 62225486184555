import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Loader from "components/Loader";

const CreatableSelect = ({
  id,
  options = [],
  value,
  create,
  loading,
  update,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [requesting, setRequesting] = useState(false);

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleOpen = () => {
    setIsOpen(true);
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    if (inputRef && inputRef.current) {
      inputRef.current.blur();
    }
    setInputValue("");
  };

  const handleClickOutside = (event) => {
    const element = wrapperRef.current;
    if (element && !element.contains(event.target)) {
      handleClose();
    }
  };

  const filterOptions = (option) => {
    const regex = new RegExp(inputValue, "gi");
    return String(option.name).search(regex) !== -1;
  };

  const handleOptionCreation = () => {
    if (inputValue) {
      setRequesting(true);
      create(inputValue);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside, true);
    return () =>
      window.removeEventListener("mousedown", handleClickOutside, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const selectedOption = options.find((option) => option.id === value);

  const handleSelect = (option) => {
    handleClose();
    setRequesting(true);
    update(id, option.id);
  };

  useEffect(() => {
    if (!loading) setRequesting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div ref={wrapperRef} className={classes.container}>
      <div onClick={handleOpen} className={classes.inputContainer}>
        {selectedOption && !isOpen && (
          <div className={classes.selectedOption}>{selectedOption.name}</div>
        )}
        <input
          style={selectedOption && !isOpen ? { display: "none" } : {}}
          ref={inputRef}
          type="text"
          placeholder={!isOpen ? placeholder : ""}
          className={classes.input}
          value={inputValue}
          onChange={handleInputChange}
          disabled={loading}
        />

        {isOpen ? (
          <Button
            size="small"
            className={classes.addButton}
            onClick={handleOptionCreation}
            disabled={loading || !inputValue}
          >
            <Add color="primary" />
          </Button>
        ) : (
          <ArrowDropDown color="primary" />
        )}

        {requesting && <Loader style={{ width: 24, height: 24 }} />}
      </div>
      {isOpen && (
        <div className={classes.optionsList}>
          {options.filter(filterOptions).map((option) => (
            <div
              className={classes.optionItem}
              key={option.id}
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CreatableSelect;
