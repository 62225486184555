import { getAll, update } from "redux/_requests/_options/options.service";
import {
  requestStart,
  requestEnd,
  saveData,
} from "redux/_requests/_options/options.actions";
import isEmpty from "lodash.isempty";
import { getRequestsOptionsData } from "redux/_requests/_options/options.selectors";

export const getOptionsData = (force) => (dispatch, getState) => {
  const options = getRequestsOptionsData(getState());
  if (force || isEmpty(options)) {
    dispatch(requestStart());
    return getAll()
      .then((data) => {
        dispatch(saveData(data));
        dispatch(requestEnd());
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const updateOption = (id, data) => (dispatch) => {
  dispatch(requestStart());
  return update(id, data)
    .then((data) => {
      dispatch(saveData(data));
      dispatch(requestEnd());
      return Promise.resolve(data);
    })
    .catch((_) => {
      dispatch(requestEnd());
      return Promise.reject();
    });
};
