import React from "react";

const PlayOutlined = () => (
  <svg
    width={10}
    height={14}
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57556 6.15342L1.79778 0.230259C1.46333 -0.024437 1.00833 -0.0718227 0.625554 0.108026C0.242776 0.288413 -2.9036e-08 0.664265 -4.70655e-08 1.07673L-5.64885e-07 12.9231C-5.82915e-07 13.3355 0.242776 13.7114 0.624999 13.8918C0.779443 13.9645 0.945554 14 1.11111 14C1.35555 14 1.59833 13.9214 1.79778 13.7695L9.57556 7.84637C9.84333 7.64229 10 7.32998 10 6.9999C10 6.66982 9.84333 6.3575 9.57556 6.15342ZM2.22222 10.7073L2.22222 3.29253L7.09055 6.9999L2.22222 10.7073Z"
      fill="#1A3586"
    />
  </svg>
);

export default PlayOutlined;
