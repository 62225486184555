import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  goBackButton: {
    background: "none",
    padding: 0,
    border: "none",
    display: "flex",
    alignItems: "center",
    color: "#fff",
  },
  link: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    padding: "0 6px 0 18px",
    "&:hover": {
      color: "#fff",
    },
  },
  arrowBack: {
    color: theme.palette.aquamarine[100],
    marginRight: theme.spacing(1),
  },
  select: {
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    position: "relative",
    top: 1,
  },
}));
