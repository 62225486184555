import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  content: {
    padding: "120px 0",
  },
  elements: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& h2": {
      fontFamily: "Aldrich",
      fontWeight: 400,
      lineHeight: "40px",
      letterSpacing: "0.25px",
      color: theme.palette.blue[500],
    },
    "& p": {
      padding: "10px 0",
      textAlign: "center",
    },
    [theme.breakpoints.up("xs")]: {
      "& h2, & h3": {
        fontSize: "1.7rem",
        textAlign: "center",
      },
      "& p": {
        width: "80%",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& h2, & h3": {
        fontSize: "2.75rem",
      },
      "& p": {
        width: "480px",
      },
    },
  },
}));
