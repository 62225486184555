import React from "react";

const Calendar = (props) => (
  <svg
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m6 6h2v-1h8v1h2v-1h2v3h-16v-3h2zm-2 4v10h16v-10zm4-7h8v-1h2v1h2c1.1046 0 2 .89543 2 2v15c0 1.1046-.8954 2-2 2h-16c-1.10457 0-2-.8954-2-2v-15c0-1.10457.89543-2 2-2h2v-1h2z"
      fill="#7090d9"
      fillRule="evenodd"
    />
  </svg>
);

export default Calendar;
