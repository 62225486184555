import React from "react";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import SVGIcon from "components/svg-icon/svg-icon";

const useStyles = makeStyles((theme) => ({
  pageNav: {
    borderRadius: 45,
    backgroundColor: theme.palette.grey[100],
  },
  iconButton: {
    width: 45,
    height: 45,
    padding: theme.spacing(1),
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 24,
    lineHeight: "29px",
    color: theme.palette.blue["young"],
    "&[active]": {
      fontWeight: 600,
      color: "#2A94F4",
    },
  },
}));

const Pagination = ({ onPageChange, totalRows, pageCount, page }) => {
  const classes = useStyles();
  const currentPage = page;
  return (
    <Box
      display="inline-flex"
      alignItems="flex-end"
      className={classes.pageNav}
    >
      {currentPage !== 1 && (
        <>
          <IconButton
            className={classes.iconButton}
            onClick={() => onPageChange(1)}
          >
            <SVGIcon icon="chevronsRight" turn />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => onPageChange(page - 1)}
          >
            <SVGIcon icon="chevronRight" turn />
          </IconButton>
        </>
      )}

      {currentPage - 2 > 0 && (
        <IconButton
          onClick={() => onPageChange(page - 2)}
          className={classes.iconButton}
        >
          {currentPage - 2}
        </IconButton>
      )}
      {currentPage - 1 > 0 && (
        <IconButton
          onClick={() => onPageChange(page - 1)}
          className={classes.iconButton}
        >
          {currentPage - 1}
        </IconButton>
      )}
      <IconButton disabled active="" className={classes.iconButton}>
        {currentPage}
      </IconButton>
      {currentPage < pageCount && (
        <IconButton
          onClick={() => onPageChange(page + 1)}
          className={classes.iconButton}
        >
          {currentPage + 1}
        </IconButton>
      )}
      {currentPage + 1 < pageCount && (
        <IconButton
          onClick={() => onPageChange(page + 2)}
          className={classes.iconButton}
        >
          {currentPage + 2}
        </IconButton>
      )}
      {currentPage < pageCount && (
        <IconButton
          onClick={() => onPageChange(page + 1)}
          className={classes.iconButton}
        >
          <SVGIcon icon="chevronRight" />
        </IconButton>
      )}
      {currentPage < pageCount && (
        <IconButton
          className={classes.iconButton}
          onClick={() => onPageChange(pageCount)}
        >
          <SVGIcon icon="chevronsRight" />
        </IconButton>
      )}
    </Box>
  );
};

export default Pagination;
