import { CONSTANTS } from "redux/_consents/_partners/partners.constants";
import unionBy from "lodash.unionby";
import isArray from "lodash.isarray";

const initialState = {
  loading: false,
  data: [],
  meta: {},
};

export const partners = (state = initialState, { type, data, meta }) => {
  switch (type) {
    case CONSTANTS.PARTNERS_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.PARTNERS_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.PARTNERS_SAVE_DATA:
      return {
        ...state,
        data: isArray(data) ? data : unionBy([data], state.data, "id"),
        meta,
      };
    case CONSTANTS.PARTNERS_DELETE:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== data),
      };
    default:
      return state;
  }
};
