import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  tableWrapper: {
    height: 224,
    overflow: "auto",
    width: "100%",
    borderRadius: "4px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    maxHeight: 224,
  },
  footer: {
    fontWeight: "bold",
    bottom: 0,
    left: 0,
    zIndex: 1,
    position: "sticky",
    backgroundColor: "#dae9ff",
    color: theme.palette.blue[400],
    fontFamily: "Inter",
    letterSpacing: "1px",
  },
  tableRowHeader: {
    height: 40,
    "& > th": {
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: "15px",
      fontWeight: "bold",
      color: theme.palette.grey[200],
      letterSpacing: "normal",
      border: "none",
    },
    "& > th:first-child": {
      paddingLeft: theme.spacing(3),
    },
    "& > th:nth-child(2)": {
      width: "15%",
    },
    "& > th:nth-child(3)": {
      width: "15%",
    },
    "& > th:last-child": {
      paddingRight: theme.spacing(3),
      width: "15%",
    },
  },
  tableRowBody: {
    height: 36,
    "& > td": {
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: "16px",
      color: theme.palette.grey[200],
      letterSpacing: "normal",
      padding: 7,
    },
    "& > td:first-child": {
      paddingLeft: theme.spacing(3),
    },
    "& > td:last-child": {
      fontSize: "14px",
      paddingRight: theme.spacing(3),
    },
    "& > td:nth-child(3)": {
      fontSize: "14px",
    },
  },
  loader: {
    width: "75%",
    margin: "auto",
  },
}));
