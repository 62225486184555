import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import Radialchart from "charts/Consents/Radialchart";
import Linechart from "charts/Consents/Linechart";
import useStyles from "../styles";
import isEmpty from "lodash.isempty";
import EmptyState from "components/PageWrapper/components/EmptyState";
import EmptyOverlay from "components/EmptyOverlay";
import { data as mockedData } from "../mocked.json";
import { data as mockedData1 } from "../mocked1.json";

const getLabels = (data) =>
  data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.name }), {});

const parseTimelineData = (data, showUnconsents) =>
  data.reduce((prev, curr) => {
    curr.timeline.forEach((t) => {
      const index = prev.findIndex((elem) => elem.date === t.date);
      if (index > -1) {
        prev[index] = {
          ...prev[index],
          [curr.id]: showUnconsents ? t.opted_out : t.opted_in,
        };
      } else {
        prev.push({
          date: t.date,
          [curr.id]: showUnconsents ? t.opted_out : t.opted_in,
        });
      }
    });
    return prev;
  }, []);

const isEmptyData = (arr) =>
  arr &&
  arr.every(
    ({ full_consent, opted_in, opted_out }) =>
      !full_consent && !opted_in && !opted_out
  );

const SpotlightCharts = ({
  list = [],
  loading,
  colors,
  data: rawData = [],
  getData,
  showUnconsents,
}) => {
  const [selected, setSelected] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (selected) getData(selected);
  }, [getData, selected]);

  const empty = isEmptyData(list) && isEmpty(rawData);

  const data = _(empty ? mockedData1 : rawData)
    .groupBy("id")
    .map((value, key) => ({
      id: Number(key),
      name: value[0].name,
      timeline: value,
    }))
    .value();

  return (
    <EmptyOverlay
      text="No Consents collected."
      link="/zen/consents/collector"
      show={empty}
    >
      <Grid container className={classes.chartsContainer}>
        <Grid item sm={12} lg={5} className={classes.radialchartContainer}>
          <Radialchart
            pagination
            data={!empty ? list : mockedData[0].policies}
            colors={colors}
            onChange={setSelected}
            countNegative={showUnconsents}
          />
        </Grid>
        <Grid item sm={12} lg={7} className={classes.linechartContainer}>
          {data !== undefined && isEmpty(data) ? (
            <EmptyState minified />
          ) : (
            <Linechart
              loading={loading}
              data={parseTimelineData(data, showUnconsents)}
              labels={getLabels(data)}
              colors={colors}
              compact
            />
          )}
        </Grid>
      </Grid>
    </EmptyOverlay>
  );
};

export default SpotlightCharts;
