import CONSTANTS from "./filters.constants";
import moment from "moment";

const { TYPES } = CONSTANTS;

const initialState = {
  loading: {
    [TYPES.SCAN]: false,
    [TYPES.ENTITY_ROLE]: false,
    [TYPES.STORAGE]: false,
    [TYPES.PURPOSE]: false,
    [TYPES.BUSINESS_UNIT]: false,
    [TYPES.CUSTOMER_REGION]: false,
    [TYPES.PII_CATEGORIES]: false,
  },
  data: {
    [TYPES.SCAN]: [],
    [TYPES.ENTITY_ROLE]: [],
    [TYPES.STORAGE]: [],
    [TYPES.PURPOSE]: [],
    [TYPES.BUSINESS_UNIT]: [],
    [TYPES.CUSTOMER_REGION]: [],
    [TYPES.PII_CATEGORIES]: [],
  },
  selected: {
    [TYPES.SCAN]: null,
    [TYPES.LAST_SCANS]: null,
    [TYPES.ENTITY_ROLE]: "all",
    [TYPES.STORAGE]: "all",
    [TYPES.PURPOSE]: "all",
    [TYPES.BUSINESS_UNIT]: "all",
    [TYPES.CUSTOMER_REGION]: "all",
    [TYPES.COUNTRY]: "all",
    [TYPES.PII_CATEGORIES]: [],
    [TYPES.CONSENTS.CUSTOMER_VISIBILITY]: false,
    [TYPES.CONSENTS.CATEGORY]: "all",
    [TYPES.CONSENTS.DECISION]: "ac",
    [TYPES.CONSENTS.DATE_RANGE]: [
      moment().subtract(1, "month"),
      moment().add(1, "day"),
    ],
    [TYPES.CONSENTS.UNCONSENTS]: false,
    [TYPES.SCANS.VISIBILITY]: true,
    [TYPES.SCANS.DATE_RANGE]: [moment().subtract(6, "month"), moment()],
  },
};

export const filters = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.FILTERS_REQUEST_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          [dataType]: true,
        },
      };
    case CONSTANTS.FILTERS_REQUEST_END:
      return {
        ...state,
        loading: {
          ...state.loading,
          [dataType]: false,
        },
      };
    case CONSTANTS.FILTERS_SAVE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [dataType]: data,
        },
      };
    case CONSTANTS.FILTERS_SELECT_DATA:
      return {
        ...state,
        selected: {
          ...state.selected,
          [dataType]: data,
        },
      };
    case CONSTANTS.FILTERS_RESET_ALL:
      return {
        ...state,
        selected: {
          ...initialState.selected,
          [TYPES.SCAN]: state.selected[TYPES.SCAN],
          [TYPES.LAST_SCANS]: state.selected[TYPES.LAST_SCANS],
          [TYPES.PII_CATEGORIES]: state.selected[TYPES.PII_CATEGORIES],
        },
      };
    default:
      return state;
  }
};
