import React from "react";
import PageWrapper from "components/PageWrapper";
import Image from "assets/images/requests/overview.png";

const ConsentsOverview = () => {
  return (
    <PageWrapper title="REQUESTS" subtitle="MANAGER'S OVERVIEW">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Image} alt="" />
      </div>
    </PageWrapper>
  );
};

export default ConsentsOverview;
