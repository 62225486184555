import React, { Component } from "react";
import { geoMercator, geoPath, scaleLinear, max, min } from "d3";
import { feature } from "topojson-client";
import Tooltip from "./Tooltip";
import ColorReference from "../ColorReference/ColorReference";
import topojson from "./world.json";

class Map extends Component {
  constructor() {
    super();

    this.state = {
      min: 0,
      max: 100,
    };
  }

  componentDidMount() {
    this.colorScale = this.getScale();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.colorScale = this.getScale();
    }
  }

  formatReference(val) {
    if (Math.floor(val) === 0) {
      return parseFloat(Math.round(val * 100) / 100).toFixed(1);
    } else {
      return Math.ceil(val);
    }
  }

  getScale = () => {
    const { colors, relative, data } = this.props;

    let domain;
    if (relative) {
      const maxVal = max(data, (d) => d.value);
      const minVal = min(data, (d) => d.value);
      const diff = (maxVal - minVal) / (colors.length - 1);
      domain = new Array(colors.length)
        .fill(0)
        .map((x, i) => diff * i + minVal);
      this.setState({
        min: this.formatReference(minVal),
        max: this.formatReference(maxVal),
      });
    } else {
      domain = new Array(colors.length)
        .fill(0)
        .map((x, i) => i / colors.length);
    }

    const color = scaleLinear().domain(domain).range(colors);
    return color;
  };

  draw = () => {
    const {
      width: rawWidth,
      height: rawHeight,
      data,
      tooltip = false,
      strokes = true,
      margin = {},
      CustomTooltip,
    } = this.props;
    const width = rawWidth - (margin.left || 0) - (margin.right || 0);
    const height = rawHeight - (margin.top || 0) - (margin.bottom || 0);
    if (this.colorScale && width && height) {
      const countries = feature(topojson, topojson.objects.units);

      const projection = geoMercator().fitExtent(
        [
          [(-width * 40) / 1000, 0],
          [width, height],
        ],
        countries
      );
      const path = geoPath().projection(projection);

      const colorScale = this.colorScale;

      const getColor = (feature) => {
        const filtered = data.find((x) => x.name === feature.properties.code);
        if (filtered) {
          return colorScale(filtered.value);
        }
        return "#E0E0E0";
      };

      return countries.features.map((feat, i) => {
        const featData = data.find((x) => x.name === feat.properties.code);
        const geopath = (
          <path
            key={feat.properties.name || i}
            d={path(feat)}
            fill={getColor(feat)}
            stroke={"white"}
            strokeWidth={1}
            strokeOpacity={strokes ? 1 : 0.1}
          />
        );

        if (CustomTooltip) {
          return (
            <CustomTooltip key={feat.properties.name || i} data={featData}>
              {geopath}
            </CustomTooltip>
          );
        }

        if (tooltip) {
          return (
            <Tooltip key={feat.properties.name || i} data={featData}>
              {geopath}
            </Tooltip>
          );
        }

        return geopath;
      });
    }
    return [];
  };

  render() {
    const { width, height, colors } = this.props;
    const { min, max } = this.state;
    const hideReference = isNaN(min) && isNaN(max);
    if (width && height)
      return (
        <>
          {this.draw()}
          <ColorReference
            min={min}
            max={max}
            width={width}
            height={height}
            position="top"
            colors={colors}
            disabled={hideReference}
          />
        </>
      );
    return null;
  }
}

export default Map;
