import React, { useMemo, useState } from "react";
import {
  AppBar,
  Box,
  Tabs as MUITabs,
  Tab,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";

const TabPanel = ({ children, value, show, id, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={!show}
      id={`simple-tabpanel-${id}`}
      aria-labelledby={`simple-tab-${id}`}
      {...other}
    >
      {show && children}
      {/* //   <Box p={3}>
      //     <Typography>{children}</Typography>
      //   </Box>
      // )} */}
    </div>
  );
};

const Tabs = ({ tabs = [], id, classes: parentClasses = {}, children }) => {
  const classes = useStyles();
  const [value, setValue] = useState(tabs[0].id);
  const filteredTabs = useMemo(() => tabs.filter((tab) => !tab.hide), [tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={clsx(classes.root, parentClasses.root)}>
      <AppBar position="static">
        <MUITabs
          value={value}
          indicatorColor="secondary"
          textColor="red"
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
        >
          {filteredTabs.map((tab) => (
            <Tab
              label={tab.label}
              value={tab.id}
              key={`${id}-tab-${tab.id}`}
              classes={{
                selected: classes.tab,
              }}
            />
          ))}
          {children}
        </MUITabs>
      </AppBar>
      {filteredTabs.map((tab) => (
        <TabPanel
          value={tab.id}
          id={tab.id}
          show={tab.id === value}
          key={`${id}-tab-panel-${tab.id}`}
        >
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
