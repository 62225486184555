import React, { useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import SelectInput from "components/Inputs/Select";
import {
  getFilterScan,
  getFilterScans,
} from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import { getScans } from "redux/_filters/filters.async.actions";
import { connect } from "react-redux";
import useStyles from "../styles";
import { getSelectedEnvId } from "redux/_environments/environment.selectors";
import history from "helpers/history";
import { withRouter } from "react-router-dom";
import replace from "lodash.replace";

import CONSTANTS from "redux/_filters/filters.constants";
import isEmpty from "lodash.isempty";

const { TYPES } = CONSTANTS;

const ScanFilter = (props) => {
  const classes = useStyles();

  const {
    envId,
    getFilterScans,
    scans,
    selectedScan,
    selectScan,
    match,
    error,
  } = props;

  useEffect(() => {
    const urlScan = Number(match.params.scan_id);
    if (urlScan) {
      getFilterScans(urlScan);
    } else {
      getFilterScans().then(([data, selected]) => {
        handleScanChange(selected);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envId]);

  const handleScanChange = useCallback(
    (id) => {
      selectScan(Number(id));
      const { params, path } = match;
      const envUrl = replace(path, ":env_id", params.env_id);
      const url = replace(envUrl, ":scan_id", id);
      history.replace(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [match]
  );

  return (
    <Box className={classes.selectContainer} opacity={isEmpty(scans) ? 0.5 : 1}>
      <label className={classes.inputLabel}>CURRENT SCAN</label>
      <SelectInput
        onChange={handleScanChange}
        value={selectedScan}
        disabled={isEmpty(scans)}
        options={scans}
        className={classes.select}
        SelectProps={error ? { classes: { root: classes.error } } : {}}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  envId: getSelectedEnvId(state),
  selectedScan: getFilterScan(state),
  scans: getFilterScans(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFilterScans: (id) => dispatch(getScans(id)),
  selectScan: (scanId) => dispatch(selectData(TYPES.SCAN, scanId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScanFilter));
