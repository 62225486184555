import React from "react";
import clsx from "clsx";
import { AppBar, Button } from "@material-ui/core";
import useStyles from "./styles";

const SubmitBar = ({
  reset,
  submitLabel = "Update",
  disabled,
  show,
  className,
  classes: customClasses = {},
}) => {
  const classes = useStyles({ show });
  return (
    <>
      <div className={classes.whiteSpace} />
      <AppBar
        position="fixed"
        elevation={0}
        color="secondary"
        className={clsx(classes.appBar, className, customClasses.root)}
      >
        <Button
          className={clsx(classes.discardButton, customClasses.discardButton)}
          onClick={reset}
        >
          Discard Changes
        </Button>
        <Button
          disabled={disabled}
          className={clsx(classes.updateButton, customClasses.submitButton)}
          type="submit"
          variant="contained"
        >
          {submitLabel}
        </Button>
      </AppBar>
    </>
  );
};

export default SubmitBar;
