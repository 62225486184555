import React from "react";

const Jira = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.8483 2.02734H15.3643C15.3643 3.64092 16.0052 5.18841 17.1462 6.32938C17.7112 6.89433 18.3819 7.34248 19.12 7.64823C19.8582 7.95398 20.6493 8.11134 21.4483 8.11134H23.9323V10.5113C23.9333 12.1215 24.5726 13.6655 25.71 14.8052C26.8474 15.9448 28.3901 16.5871 30.0003 16.5913V3.19934C30.0013 2.89163 29.8809 2.59592 29.6652 2.37647C29.4495 2.15702 29.1559 2.03156 28.8483 2.02734V2.02734Z"
      fill="#2684FF"
    />
    <path
      d="M22.1754 8.74414H8.69141C8.69141 10.3577 9.3324 11.9052 10.4734 13.0462C11.6143 14.1871 13.1618 14.8281 14.7754 14.8281H17.2594V17.2281C17.2605 18.8414 17.9018 20.3883 19.0425 21.529C20.1833 22.6697 21.7302 23.3111 23.3434 23.3121V9.91214C23.3434 9.75876 23.3132 9.60687 23.2545 9.46517C23.1958 9.32346 23.1098 9.1947 23.0013 9.08624C22.8928 8.97778 22.7641 8.89175 22.6224 8.83305C22.4807 8.77435 22.3288 8.74414 22.1754 8.74414V8.74414Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M15.5 15.459H2C2.00052 16.2595 2.15891 17.052 2.4661 17.7912C2.77328 18.5304 3.22324 19.2018 3.7902 19.7669C4.35717 20.332 5.03002 20.7798 5.77023 21.0845C6.51045 21.3893 7.3035 21.5451 8.104 21.543H10.588V23.943C10.588 25.5566 11.229 27.1041 12.37 28.245C13.5109 29.386 15.0584 30.027 16.672 30.027V16.631C16.672 16.3202 16.5485 16.022 16.3287 15.8023C16.1089 15.5825 15.8108 15.459 15.5 15.459Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="23.0594"
        y1="8.76814"
        x2="17.3234"
        y2="14.6801"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="616.904"
        y1="580.843"
        x2="375.472"
        y2="817.542"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Jira;
