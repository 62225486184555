import { Tooltip } from "@material-ui/core";
import SVGIcon from "components/svg-icon/svg-icon";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PolicyPopover from "./PolicyPopover";
import useStyles, { levels as lvl } from "./styles";

const domainOf = (x) => new URL(x).origin;

const cookieRecommendation =
  "Cookie Manager is an option for end users to easily edit cookies the permission during the session. This is a mandate required by most privacy regulations currently in place.";

const PolicyRecommendationDetail = ({
  about,
  classes,
  policyPopoverOpened,
  setPolicyPopoverOpen,
}) => (
  <>
    <p>
      Consult our policy clause model for {about} to adapt your terms and
      conditions to legal requirements.
    </p>
    <button
      className={classes.policyModelButton}
      onClick={() => {
        setPolicyPopoverOpen({ [about]: true });
      }}
    >
      <span className={classes.cap}>{about}</span> policy clause model
    </button>
    <PolicyPopover
      classes={classes}
      model={about}
      policyPopoverOpened={policyPopoverOpened[about]}
      setPolicyPopoverOpen={setPolicyPopoverOpen}
    />
  </>
);

const CookieManagerRecommendation = (msg, classes, planInfo) => (
  <p>
    {msg}
    {planInfo &&
      planInfo.metadata &&
      !!Number(planInfo.metadata.cookieConsent) && (
        <Link
          className={classes.cookieLink}
          to={`/zen/consents/collector/configuration${window.location.search}`}
        >
          Try our cookie consent tooltip generator
        </Link>
      )}
  </p>
);

const recommendations = {
  "third-party trackers": [
    {
      condition: (score) => score < 100,
      message: "Remove unreliable third-party trackers",
      details: ({ classes, thirdPartyTrackersDetails }) => {
        const totalScore = (thirdPartyTrackersDetails || []).reduce(
          (accum, { score }) => accum + score,
          0
        );
        const defaultMessage = (
          <p>
            We found some third-party trackers on your site, consider checking
            the entity they belong to and their purpose and if it is possible to
            do without any of them.
          </p>
        );
        if (!thirdPartyTrackersDetails) return defaultMessage;
        return !!thirdPartyTrackersDetails.length ? (
          <>
            <p>
              This is the list of third-party trackers that you are loading with
              a high risk score, they are ordered by risk from high to low,
              consider their purpose and if it is possible to do without any of
              them.
            </p>
            <ul className={classes.tipList}>
              {[...thirdPartyTrackersDetails]
                .sort((a, b) => b.score - a.score)
                .map(({ domain, score }) => (
                  <li key={domain} className={classes.tipEl}>
                    <div className={classes.liBox}>{domain}</div>
                    <div className={classes.liBox}>
                      <div
                        className={classes.scoreBar}
                        style={{ width: `${(score * 100) / totalScore}%` }}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </>
        ) : (
          defaultMessage
        );
      },
      level: lvl.MEDIUM,
    },
  ],
  fingerprinting: [
    {
      condition: (score) => score > 0,
      message: "Review trackers that are influencing your fingerprinting score",
      details: ({ classes, fingerprintingDetails }) => {
        const browserApiDef = `
Browser APIs (or web APIs) allow developers perform complex operations without dealing
with the sophisticated lower-level code. Browser API’s can be used to uniquely
identify a user and track their behavior over time.`;

        const duckDuckGoScores = {
          3: "Excessive use of browser API's, almost certainly for tracking purposes",
          2: "Use of many browser API's, possibly for tracking purposes",
          1: "Some use of browser API's, but not obviously for tracking purposes",
          0: "No use of browser API's",
        };

        const TrakerList = ({ score }) => {
          const filteredList = fingerprintingDetails.filter(
            ({ fingerprinting }) => fingerprinting === score
          );
          return (
            <>
              {!!filteredList.length && (
                <li className={classes.liTitle}>{duckDuckGoScores[score]}</li>
              )}
              {filteredList.map(({ domain }) => (
                <li key={domain} className={classes.tipEl}>
                  {domain}
                </li>
              ))}
            </>
          );
        };
        return fingerprintingDetails ? (
          <>
            <p>
              Here is a list of trackers on your website that are using high
              number of{" "}
              <Tooltip title={browserApiDef} placement="top">
                <span className={classes.underline}>browser API's</span>
              </Tooltip>{" "}
              for tracking users
            </p>
            <ul className={classes.tipList}>
              <TrakerList score={3} />
              <TrakerList score={2} />
            </ul>
          </>
        ) : null;
      },
      level: lvl.MEDIUM,
    },
  ],
  "https traffic": [
    {
      condition: (score) => score < 100,
      message: "Change requests to use HTTPS protocol",
      details: ({ classes, nonSecureTraffic }) =>
        nonSecureTraffic && nonSecureTraffic.length ? (
          <>
            <p>
              This is the list of domains you are querying without SSL
              encryption, consider switching to HTTPS connections if possible.
            </p>
            <ul className={classes.tipList}>
              {[...new Set(nonSecureTraffic.map(domainOf))].map((x) => (
                <li key={x} className={classes.tipEl}>
                  {x}
                </li>
              ))}
            </ul>
          </>
        ) : (
          "We check the SSL certificate's validity and all the requests made from your site and some of the requests are marked as non-secure."
        ),
      level: lvl.HIGH,
    },
  ],
  "has cookie manager": [
    {
      condition: (score) => score < 100,
      message: "Add the ability to configure cookie management on your site",
      level: lvl.MEDIUM,
      details: ({ classes, planInfo }) =>
        CookieManagerRecommendation(cookieRecommendation, classes, planInfo),
    },
  ],
  "has privacy link": [
    {
      condition: (score) => score < 100,
      message: "Add a Privacy Policy document link",
      level: lvl.HIGH,
    },
  ],
  "has terms link": [
    {
      condition: (score) => score < 100,
      message: "Add a Terms and conditions agreement (T&C) document link",
      level: lvl.HIGH,
    },
  ],
  "has cookie message on first load": [
    {
      condition: (score) => score < 100,
      message: "Add a cookie manager popup on first load",
      level: lvl.HIGH,
      details: ({ classes, planInfo }) =>
        CookieManagerRecommendation(
          "If your website uses cookies, you may need to be providing cookies notification messages. These simple pop-up boxes are important for legal compliance.",
          classes,
          planInfo
        ),
    },
  ],
  "advertising-assessment": [
    {
      condition: (score) => score < 100,
      message: "Review the advertising section of your privacy policy",
      level: lvl.LOW,
      details: (kwargs) => (
        <PolicyRecommendationDetail about="advertising" {...kwargs} />
      ),
    },
  ],
  "analytics-assessment": [
    {
      condition: (score) => score < 100,
      message: "Review the analytics section of your privacy policy",
      level: lvl.LOW,
      details: (kwargs) => (
        <PolicyRecommendationDetail about="analytics" {...kwargs} />
      ),
    },
  ],
  "behavior-assessment": [
    {
      condition: (score) => score < 100,
      message: "Review the behavior section of your privacy policy",
      level: lvl.LOW,
      details: (kwargs) => (
        <PolicyRecommendationDetail about="behavior" {...kwargs} />
      ),
    },
  ],
  "payment-assessment": [
    {
      condition: (score) => score < 100,
      message: "Review the payment section of your privacy policy",
      level: lvl.LOW,
      details: (kwargs) => (
        <PolicyRecommendationDetail about="payment" {...kwargs} />
      ),
    },
  ],
  "social-assessment": [
    {
      condition: (score) => score < 100,
      message: "Review the social section of your privacy policy",
      level: lvl.LOW,
      details: (kwargs) => (
        <PolicyRecommendationDetail about="social" {...kwargs} />
      ),
    },
  ],
};

const Recommendations = ({
  assessmentSummary,
  fingerprintingDetails,
  nonSecureTraffic,
  scores,
  thirdPartyTrackersDetails,
  planInfo,
  tips,
  setTips,
}) => {
  const classes = useStyles();
  const [expanded, expand] = useState({});
  const [policyPopoverOpened, setPolicyPopoverOpen] = useState({});

  useEffect(() => {
    let hasCookieManager = true;
    const t = []; // tips

    const assessmentScores = assessmentSummary
      ? Object.keys(assessmentSummary).map((key) => ({
          title: `${key}-assessment`,
          value: assessmentSummary[key].summary * 100,
        }))
      : [];

    scores.concat(assessmentScores).forEach((scoreDetails) => {
      if (!recommendations[scoreDetails.title]) return;
      recommendations[scoreDetails.title].forEach((recommendation, i) => {
        const details =
          recommendation.details instanceof Function
            ? recommendation.details({
                classes,
                nonSecureTraffic,
                thirdPartyTrackersDetails,
                fingerprintingDetails,
                policyPopoverOpened,
                setPolicyPopoverOpen,
                planInfo,
              })
            : recommendation.details;
        if (recommendation.condition(scoreDetails.value)) {
          hasCookieManager =
            hasCookieManager && scoreDetails.title !== "has cookie manager";
          t.push({
            key: `${scoreDetails.title}${i}`,
            details: details || scoreDetails.description,
            level: recommendation.level,
            message: recommendation.message,
          });
        }
      });
    });
    if (hasCookieManager)
      // if the page already has a cookie manager, anyway show a recommendation promoting our cookie manager generator
      t.push({
        key: `cookie-consent-tooltip-generator`,
        details: CookieManagerRecommendation(
          cookieRecommendation,
          classes,
          planInfo
        ),
        level: lvl.LOW,
        message: "Add a cookie manager popup on first load",
      });
    t.sort((a, b) => b.level.value - a.level.value);

    t.push({
      key: `cookie-policy-generator`,
      details: (
        <p>
          Your cookie policy should be accessible from the homepage of your
          website, try our cookie policy generator to have a detailed document
          about the use of cookies on your site.
          <Link
            className={classes.cookieLink}
            to={`/zen/consents/policy-generator/configuration${window.location.search}`}
          >
            Cookie policy generator
          </Link>
        </p>
      ),
      level: lvl.LOW,
      message:
        "Add detailed information about the types of cookies used in your website in a cookie policy document",
    });
    setTips(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores, policyPopoverOpened]);

  return (
    <div className={`scrollable-element ${classes.container}`}>
      {tips.map(({ key, message, details, level }) => (
        <Fragment key={key}>
          <div
            className={classes.el}
            onClick={() => {
              if (expanded[key]) expand({});
              else expand({ [key]: true });
            }}
          >
            <div className={classes.iconBox}>
              <span className={classes[`i-${level}`]} />
            </div>
            <div className={classes.messageBox}>{message}</div>
            <div
              className={`${classes.chevron} ${
                expanded[key] ? classes.upChevron : ""
              }`}
            >
              <SVGIcon icon="chevronDownIcon" />
            </div>
          </div>
          <div
            className={`${classes.details} ${
              expanded[key] ? classes.expanded : ""
            }`}
          >
            <span className={classes.content}>{details}</span>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Recommendations;
