import React from "react";

const ArrowTrendup = (props) => {
  const { color = "#4766b6" } = props;
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14699 0H2.84377C2.37265 0 1.99076 0.381892 1.99076 0.853009C1.99076 1.32413 2.37265 1.70602 2.84377 1.70602H7.08762L0.249857 8.54382C-0.0832856 8.87696 -0.0832856 9.41704 0.249857 9.75014C0.582957 10.0832 1.12304 10.0833 1.45618 9.75014L8.29398 2.91234V7.15619C8.29398 7.6273 8.67587 8.0092 9.14699 8.0092C9.61811 8.0092 10 7.6273 10 7.15619V0.853009C10 0.388759 9.62271 0 9.14699 0Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowTrendup;
