import { httpRegex } from "utils/scanValidations";

export const formatNumber = (num) => {
  const abs = Math.abs(num);

  if (Number.isNaN(abs)) {
    // return 0 in case of NaN
    return 0;
  }
  if (abs > 10 ** 9) {
    // billions
    return Math.round(num / 10 ** 8) / 10 + "b";
  }
  if (abs >= 10 ** 6) {
    // millions
    return Math.round(num / 10 ** 5) / 10 + "m";
  }
  if (abs > 10 ** 3) {
    // thousands
    return Math.round(num / 10 ** 2) / 10 + "k";
  }
  // numberWithCommas(x)
  return Math.trunc(num * 10) / 10.0;
};

export const fullURL = (url) => {
  return httpRegex.test(url) ? url : `https://${url}`;
};
