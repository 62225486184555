import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import styles from "./styles";
import zendataLogo from "../zendata-logo.png";

const Header = ({ title = "", subtitle = "", reportLabel = "", logo = "" }) => {
  const hasCustomLogo = logo !== null && !logo.endsWith("media/LOGO.png");
  return (
    <View style={styles.container} fixed>
      <View style={[styles.content, hasCustomLogo ? { width: "100%" } : {}]}>
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </View>
        <View style={styles.reportDetails}>
          <Text style={styles.reportDetailsText}>{reportLabel}</Text>
        </View>
      </View>
      {!hasCustomLogo && (
        <View style={styles.iconContainer}>
          <Image src={zendataLogo} style={styles.icon} />
        </View>
      )}
    </View>
  );
};

export default Header;
