import React from "react";
import { Route, Switch } from "react-router-dom";

import VariableInventory from "components/PrivacyReports/Report/components/VariableInventory"

const VariableInventoryPage = () => {
  return (
    <Switch>
      <Route path="/variable-inventory" component={VariableInventory} />
    </Switch>
  );
};

export default VariableInventoryPage;
