import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    lineHeight: "26px",
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(4),
  },
  divider: {
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },
}));
