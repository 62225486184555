import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import clsx from "clsx";

import config from "config";

import useStyles from "./styles";

const SideBar = ({
  title,
  description,
  className = "",
  downloadURI = undefined,
  hasFooter = true,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        `personal-data-sidebar ${className}`,
        classes.reportSidebar,
        !hasFooter && "-no-footer"
      )}
    >
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
      {downloadURI && (
        <Button
          href={`${config.apiUrl}/reports/${downloadURI}`}
          className={classes.actionButton}
        >
          <GetAppIcon />
          Download file
        </Button>
      )}
    </div>
  );
};

export default SideBar;
