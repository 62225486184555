import React from "react";
import { connect } from "react-redux";
import {
  getConsentsSpotlightPoliciesData,
  getConsentsSpotlightPoliciesLoading,
} from "redux/_consents/_spotlight/spotlight.selectors";
import { getSpotlightPoliciesChartData } from "redux/_consents/_spotlight/spotlight.async.actions";
import SpotlightChart from "components/Consents/Analytics/Spotlight/components/spotlightCharts";

const Policies = ({
  policies = [],
  loading,
  colors,
  data,
  getPoliciesData,
  showUnconsents,
}) => {
  return (
    <SpotlightChart
      list={policies}
      data={data}
      getData={getPoliciesData}
      loading={loading}
      colors={colors}
      showUnconsents={showUnconsents}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsSpotlightPoliciesData(state),
  loading: getConsentsSpotlightPoliciesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPoliciesData: (ids) => dispatch(getSpotlightPoliciesChartData(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
