import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  itemContainer: {
    marginBottom: 12,
    display: "flex",
  },
  itemLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.grey[300],
    lineHeight: "19px",
  },
  itemValue: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: theme.palette.grey[200],
  },
  regulationIcon: {
    color: theme.palette.blue[300],
  },
  regulation: {
    fontSize: "18px",
    fontWeight: 800,
    color: theme.palette.blue[500],
    display: "flex",
    lineHeight: "24px",
    "& svg": {
      marginRight: 4,
    },
  },
  email: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "19px",
    color: theme.palette.grey[300],
    marginLeft: theme.spacing(1),
    display: "inline-block",
  },
  daysLeft: {
    borderRadius: 4,
    backgroundColor: fade(theme.palette.grey[500], 0.5),
    fontFamily: "Roboto Condensed, sans-serif",
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: theme.palette.grey[300],
    padding: "6px 12px",
    marginLeft: theme.spacing(2),
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
}));
