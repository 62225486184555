import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import CopyButton from "components/Consents/Collector/EmbedCode/components/copyButton";
import {
  getConsentsConfig,
  getConsentsConfigLoadingState,
} from "redux/_consents/_config/config.selectors";
import { updateConfig } from "redux/_consents/_config/config.async.actions";
import Preview from "components/Requests/Widget/Preview";

const EmbedCode = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.leftContent}>
          <Typography className={classes.primaryText}>
            To use the <b>Request Widget</b> on your website, simply copy and
            paste the following code in your website:
          </Typography>
          <Box className={classes.codeBlockContainer}>
            <Typography
              className={classes.codeText}
            >{`<iframe width="656" height="696" src="https://www.zendata.io/reqembed/XhfjE7a95G63KyVYdPej" frameborder="0"></iframe>`}</Typography>
            <CopyButton
              text={`<iframe width="656" height="696" src="https://www.zendata.io/reqembed/XhfjE7a95G63KyVYdPej" frameborder="0"></iframe>`}
            />
          </Box>
          <Typography className={classes.infoText}>
            You can use it in a normal page or inside a modal window. It’s also
            responsive so it plays nice with mobile devices.
          </Typography>
        </Box>
        <Box className={classes.rightContent}>
          <Typography className={classes.rightTitle}>Preview</Typography>
          <Preview />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: getConsentsConfig(state),
  loading: getConsentsConfigLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbedCode);
