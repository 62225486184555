/**
  Verifies that a value is `null` or `undefined`, an empty string, or an empty
  array.
  Constrains the rules on `isNone` by returning true for empty strings and
  empty arrays.
  If the value is an object with a `size` property of type number, it is used
  to check emptiness.
  ```javascript
  isEmpty();                 // true
  isEmpty(null);             // true
  isEmpty(undefined);        // true
  isEmpty('');               // true
  isEmpty([]);               // true
  isEmpty({ size: 0});       // true
  isEmpty({});               // false
  isEmpty('Adam Hawkins');   // false
  isEmpty([0,1,2]);          // false
  isEmpty('\n\t');           // false
  isEmpty('  ');             // false
  isEmpty({ size: 1 })       // false
  isEmpty({ size: () => 0 }) // false
  ```
  @method isEmpty
  @static
  @for zendata/utils
  @param {Object} obj Value to test
  @return {Boolean}
  @public
*/
export default function isEmpty(obj) {
  const none = obj === null || obj === undefined;

  if (none) {
    return none;
  }

  if (typeof obj.size === "number") {
    return !obj.size;
  }

  const objectType = typeof obj;

  if (objectType === "object") {
    const { size } = obj;
    if (typeof size === "number") {
      return !size;
    }
  }

  if (typeof obj.length === "number" && objectType !== "function") {
    return !obj.length;
  }

  if (objectType === "object") {
    const { length } = obj;
    if (typeof length === "number") {
      return !length;
    }
  }

  return false;
}
