import { Text, View } from "@react-pdf/renderer";

const PiiViolationsPage = ({ styles, data }) => {
  const secretsWithLastTenCharacters = data.secrets.map((secret) => {
    if (secret.Secret.length > 20) {
      secret.Secret = "..." + secret.Secret.slice(-20);
    }
    if (secret.Match.length > 20) {
      secret.Match = "..." + secret.Match.slice(-20);
    }
    if (secret.File.length > 30) {
      secret.File = "..." + secret.File.slice(-30);
    }
    return secret;
  });

  return (
    <View style={styles.section}>
      <View>
        <Text style={styles.title}>SECRETS</Text>
      </View>
      <View>
        <View>
          {data.secrets.length > 0 ? (
            <View>
              {Object.entries(secretsWithLastTenCharacters).map(
                ([key, value]) => {
                  return (
                    <View style={styles.table} wrap={false}>
                      <View style={styles.thead}>
                        <View style={styles.tr}>
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "12px",
                            }}
                          >
                            <Text style={styles.text}>
                              <Text style={styles.highlightedText}> DATE:</Text>
                              {value.Date}
                            </Text>
                            <Text style={styles.text}>
                              <Text style={styles.highlightedText}> RULE:</Text>
                              {value.RuleID}
                            </Text>
                            <Text style={styles.text}>
                              <Text style={styles.highlightedText}> FILE:</Text>
                              {value.File}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View key={key} wrap={false}>
                        <View style={styles.tdCustomWidth}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            Description
                          </Text>
                          <Text style={[styles.text, { width: "50%" }]}>{value.Description}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            StartLine
                          </Text>
                          <Text style={styles.text}>{value.StartLine}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            EndLine
                          </Text>
                          <Text style={styles.text}>{value.EndLine}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            StartColumn
                          </Text>
                          <Text style={styles.text}>{value.StartColumn}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            EndColumn
                          </Text>
                          <Text style={styles.text}>{value.EndColumn}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            Match
                          </Text>
                          <Text style={styles.text}>{value.Match}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            Secret
                          </Text>
                          <Text style={styles.text}>{value.Secret}</Text>
                        </View>
                        <View style={[styles.td, { flexWrap: "nowrap" }]}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            File
                          </Text>
                          <Text style={styles.text}>{value.File}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            SymlinkFile
                          </Text>
                          <Text style={styles.text}>{value.SymlinkFile}</Text>
                        </View>
                        <View style={styles.td}>
                          <Text style={[styles.text, { width: "50%" }]}>
                            Commit
                          </Text>
                          <Text style={styles.text}>{value.Commit}</Text>
                        </View>
                      </View>
                    </View>
                  );
                }
              )}
            </View>
          ) : (
            <View style={[styles.tdCustomWidth]}>
              <Text style={styles.text}>No secrets were found.</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default PiiViolationsPage;
