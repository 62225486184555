import React from "react";

export default () => (
  <svg
    id="Logos"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    viewBox="0 0 80 80"
  >
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".cls-1{fill:none;}.cls-2{fill:#2684ff;}.cls-3{fill:url(#New_Gradient_Swatch_1);}",
        }}
      />
      <linearGradient
        id="New_Gradient_Swatch_1"
        x1="71.61"
        y1="36.15"
        x2="41.78"
        y2="59.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052cc" />
        <stop offset={1} stopColor="#2684ff" />
      </linearGradient>
    </defs>
    <title>bitbucket-icon-gradient-blue</title>
    <polygon
      className="cls-1"
      points="33.81 48.88 46.36 48.88 49.39 31.2 30.47 31.2 33.81 48.88"
    />
    <path
      className="cls-2"
      d="M12,13.06a1.92,1.92,0,0,0-1.9,2.23l8.14,49.56A2.62,2.62,0,0,0,20.78,67H60a1.93,1.93,0,0,0,1.9-1.62L70,15.3a1.92,1.92,0,0,0-1.9-2.23ZM46.36,48.88H33.81L30.47,31.2H49.39Z"
    />
    <path
      className="cls-3"
      d="M67.44,31.2h-18l-3,17.68H33.81L19.1,66.4a2.61,2.61,0,0,0,1.68.63H60a1.93,1.93,0,0,0,1.9-1.62Z"
    />
  </svg>
);
