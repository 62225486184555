import { usePDF } from "@react-pdf/renderer";
import RepositoryPDFReport from "components/RepositoryPDFReport";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

const RepositoryPDFDownloadLink = ({
  branding,
  children,
  data,
  ranking,
  riskName,
}) => {
  const [rendered, setRendered] = useState(false);
  const [{ url, loading }] = usePDF({
    document: <RepositoryPDFReport branding={branding} data={data} />,
  });

  useEffect(() => {
    if (!rendered && !loading && url) {
      global.open(url);
      setRendered(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const onClick = () => {
    if (url) {
      global.open(url);
      setRendered(true);
    }
  };

  return loading || !url ? (
    <Button className="pdf-button" color="primary" variant="contained">
      Opening...
    </Button>
  ) : (
    <span onClick={onClick}>{children}</span>
  );
};

export default RepositoryPDFDownloadLink;
