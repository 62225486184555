import React from "react";
import { Form, Field } from "react-final-form";
import clsx from "clsx";
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  withWidth,
} from "@material-ui/core";
import PhoneField from "material-ui-phone-number";

import useStyles from "./styles";

const required = (value) => (!value ? "Required" : undefined);

const FIELDSET = [
  {
    label: "Company",
    name: "company",
    placeholder: "ex. Zendata",
    validate: required,
  },
  {
    label: "Your name",
    name: "name",
    placeholder: "ex. John Smith",
    validate: required,
  },
  {
    label: "Phone number",
    name: "phone",
    validate: required,
    Component: PhoneField,
    defaultCountry: "us",
  },
];

const commonInputProps = (classes) => {
  return {
    InputLabelProps: {
      shrink: true,
      classes: {
        root: classes.label,
        focused: classes.labelFocused,
        shrink: classes.labelShrink,
      },
    },
    InputProps: {
      classes: {
        root: clsx(classes.inputRoot, "override"),
        input: classes.input,
        focused: classes.inputFocused,
      },
    },
  };
};

const RegisterField = ({
  name,
  label,
  placeholder,
  validate,
  Component = TextField,
  ...extra
}) => {
  const classes = useStyles();
  return (
    <Field
      key={name || label}
      name={name || label}
      validate={validate}
      render={({
        input: { value, onChange },
        meta: { touched, error, submitError },
      }) => (
        <Component
          label={label}
          value={value}
          onChange={onChange}
          id={label}
          placeholder={placeholder}
          className={clsx(
            classes.field,
            touched && (error || submitError) && "-error"
          )}
          helperText={
            touched &&
            (error || submitError) && (
              <span style={{ color: "red" }}>{error || submitError}</span>
            )
          }
          {...extra}
          {...commonInputProps(classes)}
        />
      )}
    />
  );
};

const RegistrationForm = ({
  type: plan,
  price,
  email,
  priceStripeId,
  open,
  handleClose,
  onSubmit,
  width,
  loading,
  setLoading,
  error,
  setError,
  billingFrequency,
}) => {
  const classes = useStyles();

  const onClose = () => {
    handleClose();
  };

  const handlePreSubmit = async (values) => {
    setLoading(true);

    onSubmit({
      ...values,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.root }}
      fullScreen={width === "xs"}
    >
      <DialogTitle className={classes.title} disableTypography>
        Add your account details
      </DialogTitle>

      <Form onSubmit={handlePreSubmit}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className={classes.content}>
              <div className={classes.planMiniCard}>
                <h3 className={classes.planMiniCardTitle}>
                  Zendata {plan} -{" "}
                  {billingFrequency.replace(/^\w/, (c) => c.toUpperCase())} Plan
                </h3>
                <p className={classes.planMiniCardInfo}>
                  <strong>${price.toLocaleString("en-US")}</strong> Billed{" "}
                  {billingFrequency} after free trial ends
                </p>
              </div>

              <Box className={clsx(classes.errorContainer, error && "-error")}>
                <Typography variant="body1">{error}</Typography>
              </Box>

              <Box className={classes.fields}>
                <Field
                  key="email"
                  name="email"
                  validate={required}
                  initialValue={email}
                  render={({
                    input: { value, onChange },
                    meta: { touched, error, submitError },
                  }) => (
                    <TextField
                      label="Email"
                      value={value}
                      onChange={onChange}
                      disabled={email}
                      placeholder="ex. jsmith@zendata.com"
                      className={clsx(
                        classes.field,
                        touched && (error || submitError) && "-error"
                      )}
                      helperText={
                        touched &&
                        (error || submitError) && (
                          <span style={{ color: "red" }}>
                            {error || submitError}
                          </span>
                        )
                      }
                      {...commonInputProps(classes)}
                    />
                  )}
                />
                {FIELDSET.map((field, index) => {
                  return <RegisterField key={field.label} {...field} />;
                })}
              </Box>
            </DialogContent>

            <DialogActions className={classes.actions}>
              <Button
                type="submit"
                disabled={loading}
                className={classes.submitButton}
                disableRipple
              >
                Next
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default withWidth()(RegistrationForm);
