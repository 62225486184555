import React from "react";
import AutoCompleteSelectInput from "components/Inputs/AutoComplete";
import Checkbox from "components/Inputs/Checkbox";
import SelectInput from "components/Inputs/Select";
import TextInput from "components/Inputs/Text";
import TimeInput from "components/Inputs/Time";

const FinalFormFieldHOC = (
  Component,
  withHelper = true,
  defaultErrorManagement
) => ({
  input: { value, onChange, type },
  meta,
  helperText,
  isEmptyErr,
  setIsEmptyErr,
  isTitle,
  ...restProps
}) => {
  const { onChange: customOnChange, ...otherProps } = restProps;

  const withErrrors = defaultErrorManagement
    ? meta.error && meta.touched
    : (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
      (!meta.pristine || meta.modified);

  return (
    <Component
      value={value}
      onChange={(value) => {
        onChange(value);
        if (customOnChange) customOnChange(value);
        if (isEmptyErr) setIsEmptyErr(false);
      }}
      helperText={
        isEmptyErr
          ? "The field is required"
          : !isTitle && withHelper
          ? withErrrors
            ? String(meta.error || meta.submitError)
            : helperText
          : undefined
      }
      error={(!isTitle && withErrrors) || isEmptyErr}
      type={type}
      {...otherProps}
    />
  );
};

const FinalFormSelectFieldHOC = (Component) => (props) => {
  const FinalSelectInput = FinalFormFieldHOC(SelectInput);
  const newProps = { ...props };
  if (newProps.SelectProps && newProps.SelectProps.multiple) {
    const { onChange } = props.SelectProps;
    newProps.SelectProps.onChange = (e) => {
      onChange(e);
      props.input.onChange(e);
    };
  }

  return <FinalSelectInput {...newProps} />;
};

export const FormSelectInput = FinalFormSelectFieldHOC(SelectInput);
export const FormAutoCompleteInput = FinalFormFieldHOC(AutoCompleteSelectInput);
export const FormTextInput = FinalFormFieldHOC(TextInput);
export const SimpleFormTextInput = FinalFormFieldHOC(TextInput, true, true);
export const FormTimeInput = FinalFormFieldHOC(TimeInput);
export const FormCheckInput = FinalFormFieldHOC(Checkbox, false);
