import { height } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-colCellCenter': {
      justifyContent: 'left',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellTitle': {
      display: 'flex',
      justifyContent: 'left',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
    },
    '&[role="grid"]': {
      border: `1px solid ${theme.palette.grey[500]}`,
      height: '700px'
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  header: {
    justifyContent: 'left',
    textAlign: 'left',
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: 2,
    color: theme.palette.grey[200], // Aplica el color de título del estilo antiguo
  },
  cell: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    padding: 5, // Aplica padding del estilo antiguo
    textTransform: "capitalize", // Aplica text-transform del estilo antiguo
  },
  container: {
    marginTop: 40, // Aplica margen superior del estilo antiguo
    borderTop: "none", // Aplica el estilo de la tabla anterior
    width: 500, // Ancho del estilo antiguo
  },
  iconContainer: {
    width: 30,
    height: 30,
    boxShadow: "1px 2px 11px -1px rgba(0, 0, 0, 0.25)",
    borderRadius: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    lineHeight: 30,
    margin: "auto",
  },
  icon: {
    width: 20,
    height: 20,
  },
  dataSourceName: {
    padding: 4,
    display: "flex",
    alignItems: "center",
    gap: 10,
    color: "#007bff",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  disabled: {
    padding: 4,
    gap: 10,
    display: "flex",
    pointerEvents: "none",
    color: theme.palette.text.disabled,
    textDecoration: "none",
    cursor: "default",
  },
  status:{
    lineHeight:  "normal",
  },
  paginationContainer: {
    paddingBottom: theme.spacing(5), // Aplica padding inferior del estilo antiguo
  },
  backdrop: {
    position: "absolute",
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

export default useStyles;
