import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  applyToAll: {
    textTransform: "none",
    display: "block",
    marginLeft: "auto",
  },
  tableWrapper: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  tableRowHeader: {
    height: 40,
    "& > th": {
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: "15px",
      fontWeight: "bold",
      color: theme.palette.grey[200],
      letterSpacing: "normal",
      border: "none",
      backgroundColor: "#f2f2f2",
    },
    "& > th:first-child": {
      paddingLeft: theme.spacing(3),
      width: 100,
    },
    "& > th:nth-child(2)": {
      width: "33%",
    },
    "& > th:nth-child(3)": {
      width: "33%",
    },
    "& > th:last-child": {
      paddingRight: theme.spacing(3),
      width: "33%",
    },
  },
  tableRowBody: {
    height: 32,

    "&:nth-of-type(even)": {
      backgroundColor: "#f9f9f9",
    },
    "&:first-child": {
      verticalAlign: "bottom",
      paddingBottom: 10,
    },

    "& > td": {
      verticalAlign: "middle",
      fontFamily: "Roboto Condensed, sans-serif",
      fontSize: 14,
      color: theme.palette.grey[200],
      letterSpacing: "normal",
      padding: 7,
    },
    "& > td:first-child": {
      paddingLeft: theme.spacing(3),
    },
    "& > td:last-child": {
      fontSize: "14px",
      paddingRight: theme.spacing(3),
    },
    "& > td:nth-child(3)": {
      fontSize: "14px",
    },
  },
}));
