import React from "react";

const Confluence = () => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.74818 22.7003C3.47618 23.1443 3.16818 23.6643 2.92418 24.0763C2.8173 24.2633 2.78635 24.4842 2.83771 24.6934C2.88908 24.9026 3.01884 25.084 3.20018 25.2003L8.66818 28.5763C8.76299 28.635 8.86854 28.6743 8.97869 28.6918C9.08884 28.7093 9.20137 28.7047 9.30972 28.6783C9.41807 28.6519 9.52007 28.6041 9.60977 28.5378C9.69948 28.4716 9.77508 28.3881 9.83218 28.2923C10.0522 27.9243 10.3322 27.4483 10.6322 26.9403C12.8002 23.3803 14.9962 23.8163 18.9202 25.6843L24.3442 28.2603C24.4458 28.3089 24.5561 28.3367 24.6686 28.3418C24.7812 28.3469 24.8936 28.3294 24.9992 28.2902C25.1048 28.251 25.2015 28.191 25.2834 28.1137C25.3653 28.0364 25.4309 27.9434 25.4762 27.8403L28.0802 21.9603C28.1687 21.7608 28.1764 21.5348 28.1018 21.3297C28.0273 21.1246 27.8761 20.9563 27.6802 20.8603C26.5362 20.3203 24.2602 19.2603 22.2202 18.2603C14.8002 14.6763 8.54018 14.9123 3.74818 22.7003Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M28.2681 9.33632C28.5441 8.89232 28.8521 8.37232 29.0921 7.96032C29.2036 7.77017 29.2354 7.54373 29.1807 7.33024C29.126 7.11675 28.9893 6.93349 28.8001 6.82032L23.3401 3.44432C23.2453 3.38557 23.1398 3.3463 23.0296 3.32878C22.9195 3.31127 22.807 3.31586 22.6986 3.3423C22.5903 3.36873 22.4883 3.41648 22.3986 3.48276C22.3089 3.54904 22.2332 3.63251 22.1761 3.72832C21.9601 4.09632 21.6761 4.57232 21.3761 5.08032C19.2001 8.65632 17.0241 8.22032 13.0961 6.35232L7.67615 3.77632C7.57577 3.72703 7.46656 3.69828 7.35493 3.69175C7.2433 3.68523 7.13148 3.70106 7.02605 3.73831C6.92061 3.77557 6.82368 3.8335 6.74093 3.90872C6.65817 3.98393 6.59128 4.07491 6.54415 4.17632L3.94015 10.0723C3.85045 10.2723 3.84204 10.4992 3.91669 10.7053C3.99134 10.9113 4.1432 11.0802 4.34015 11.1763C5.48815 11.7163 7.76415 12.7763 9.80015 13.7763C17.2001 17.3603 23.4801 17.1243 28.2681 9.33632Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="27.9162"
        y1="30.3243"
        x2="11.3722"
        y2="20.8163"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="0.92" stopColor="#2380FB" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={-350666}
        y1="-33081.5"
        x2={-350064}
        y2="-32390.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="0.92" stopColor="#2380FB" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Confluence;
