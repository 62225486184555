import React, { useState } from "react";
import SectionContent from "pages/AppReport/common/SectionContent";
import Table from "pages/AppReport/common/Table";
import Status from "pages/AppReport/common/Status";

const color = {
  letterColor: "#1a3586",
  bubbleColor: "#1a3586",
};

const Type = ({ children, onClick, selected, isSelectable = true }) => {
  return (
    <span
      style={{
        color: selected ? "#ffffff" : color.letterColor,
        border: `2px solid ${color.bubbleColor}`,
        backgroundColor: selected ? color.bubbleColor : "#ffffff",
        padding: "8px 16px",
        borderRadius: 64,
        lineHeigth: "18.15px",
        verticalAlign: "text-top",
        cursor: isSelectable ? "pointer" : "default",
        wordBreak: "keep-all",
      }}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

const Resumen = ({
  setSelectedDataSource,
  dataSourceData,
  limitByPage,
  currentPage,
  setLimitByPage,
  setCurrentPage,
  totalItems,
}) => {
  const [selectedType, setSelectedType] = useState(null);
  const [filteredData, setFilteredData] = useState(dataSourceData?.data || []);

  const onSelectType = (type) => {
    if (selectedType === type) {
      setSelectedType(null);
      setFilteredData(dataSourceData?.data);
      return;
    }
    setSelectedType(type);
    setFilteredData(dataSourceData?.data.filter((el) => type === el.itemType));
  };

  return (
    <SectionContent className="scrollable-element" styles={{ padding: 5 }}>
      <Table
        data={filteredData}
        limitByPage={limitByPage}
        currentPage={currentPage}
        setLimitByPage={setLimitByPage}
        setCurrentPage={setCurrentPage}
        totalItems={totalItems}
        variant="primary"
        columns={[
          {
            key: "itemName",
            label: "NAME",
            width: "35%",
            render: (name) => <b>{name}</b>,
          },
          {
            key: "itemTypeLabel",
            label: "TYPE",
            width: "10%",
            render: (type, el) => (
              <Type
                onClick={() => onSelectType(el.itemType)}
                selected={el.itemType === selectedType}
              >
                {type || el.itemType}
              </Type>
            ),
          },
          {
            key: "totalEntities",
            label: "TOTAL PII",
            width: "15%",
            render: (_, el) => el.totalSensitiveEntities + el.totalNonSensitiveEntities,
          },
          {
            key: "totalSensitiveEntities",
            label: "HIGHLY SENSITIVE",
            width: "15%",
          },
          {
            key: "totalNonSensitiveEntities",
            label: "MODERATELY SENSITIVE",
            width: "15%",
          },
          {
            key: "action",
            label: "ACTION",
            width: "10%",
            render: (_, el) => (
              <a
                onClick={() => setSelectedDataSource(el)}
                style={{
                  padding: 4,
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "#007bff",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                View more
              </a>
            ),
          },
        ]}
      />
    </SectionContent>
  );
};

export default Resumen;
