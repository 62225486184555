import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  sorry: {
    textAlign: "center",
  },
  title: {
    maxWidth: 740,
    marginTop: 20,
    fontSize: 25,
  },
  paragraph: {
    maxWidth: 740,
    color: "#686c78",
    fontSize: "1rem",
    lineHeight: 1.5,
  },
}));
