import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import {capitalizeFirstLetter} from 'utils/texts'

const LoadingSelect = ({ label, value, onChange, loading, items = [] }) => (
  <Box display="flex" alignItems="center" mb={2}>
    <FormControl variant="outlined" style={{ minWidth: 120, backgroundColor:"#fff" }}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} label={label}>
        {loading ? (
          <MenuItem value="">Loading...</MenuItem>
        ) : (
          items && items.length > 0 ? items.map((item) => (
            <MenuItem key={item} value={item}>
              {capitalizeFirstLetter(item)}
            </MenuItem>
          )) : (
            <MenuItem value="">No items available</MenuItem>
          )
        )}
      </Select>
    </FormControl>
  </Box>
);

export default LoadingSelect;
