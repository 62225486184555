import React, { useMemo, useState } from "react";
import SectionContent from "pages/AppReport/common/SectionContent";
import Page from "components/PrivacyReports/Report/components/Page";
import Table from "pages/AppReport/common/Table";
import { SECTIONS_CONTENT } from "pages/AppReport/constants";
import Title from "pages/AppReport/common/Title";
import SDKCategory from "pages/AppReport/common/SDKCategory";

const SDKs = ({ goToNextSectionAction, data = [] }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const formattedData = useMemo(
    () =>
      data.map((sdk) => ({
        ...sdk,
        categories: sdk.category.split(",").map((category) => category.trim()),
      })),
    [data]
  );

  const filteredData = useMemo(() => {
    return selectedCategory
      ? formattedData.filter((el) =>
          el.categories.find((category) => category === selectedCategory)
        )
      : formattedData;
  }, [selectedCategory]);
  return (
    <Page
      key={SECTIONS_CONTENT.sdks.key}
      footerBackgroundColor={SECTIONS_CONTENT.sdks.backgroundColor}
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
      footerText={<div className="privacy-tech">Check the Code Analysis</div>}
    >
      <Title>{SECTIONS_CONTENT.sdks.title}</Title>
      <SectionContent>
        <Table
          data={filteredData}
          limitPerPage={10}
          columns={[
            {
              key: "developer",
              label: "Developer",
              width: "20%",
            },
            {
              key: "categories",
              label: "Category",
              width: "40%",
              render: (categories) =>
                categories.map((category) => (
                  <SDKCategory
                    category={category}
                    isSelectable
                    onClick={() =>
                      setSelectedCategory(
                        category !== selectedCategory ? category : ""
                      )
                    }
                    selected={category === selectedCategory}
                  />
                )),
            },
            {
              key: "name",
              label: "SDK Name",
              width: "20%",
            },
            {
              key: "type",
              label: "Type",
              width: "20%",
              render: (type) => (type.includes("non") ? "Non-Native" : type),
            },
          ]}
        />
      </SectionContent>
    </Page>
  );
};

export default SDKs;
