import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  privacyLinks: {
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
  },
  link: {
    flexWrap: "wrap",
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "40px",
    border: "1px solid #DFE2E9",
    boxSizing: "border-box",
    boxShadow: "0px 4.51506px 9.03012px rgba(176, 190, 197, 0.24)",
    borderRadius: "9.03012px",
    justifyContent: "space-between",
    "&:nth-child(2)": {
      margin: theme.spacing(3, 0),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      flexWrap: "wrap",
      "&:nth-child(2)": {
        margin: theme.spacing(0, 2),
      },
    },
  },
  linkImage: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  linkText: {
    fontWeight: "bold",
    color: "#686C78",
    fontSize: "18px",
    lineHeight: "22px",
  },
}));
