import React from "react";

const Slack = ({ white }) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.72305 20.2209C6.72305 22.071 5.21167 23.5824 3.36153 23.5824C1.51138 23.5824 0 22.071 0 20.2209C0 18.3708 1.51138 16.8594 3.36153 16.8594H6.72305V20.2209Z"
      fill="#E01E5A"
    />
    <path
      d="M8.41699 20.2209C8.41699 18.3708 9.92838 16.8594 11.7785 16.8594C13.6287 16.8594 15.14 18.3708 15.14 20.2209V28.6377C15.14 30.4879 13.6287 31.9993 11.7785 31.9993C9.92838 31.9993 8.41699 30.4879 8.41699 28.6377V20.2209Z"
      fill="#E01E5A"
    />
    <path
      d="M11.7785 6.72305C9.92838 6.72305 8.41699 5.21167 8.41699 3.36153C8.41699 1.51138 9.92838 0 11.7785 0C13.6287 0 15.14 1.51138 15.14 3.36153V6.72305H11.7785Z"
      fill="#36C5F0"
    />
    <path
      d="M11.7784 8.41602C13.6285 8.41602 15.1399 9.9274 15.1399 11.7775C15.1399 13.6277 13.6285 15.1391 11.7784 15.1391H3.36153C1.51138 15.1391 0 13.6277 0 11.7775C0 9.9274 1.51138 8.41602 3.36153 8.41602H11.7784Z"
      fill="#36C5F0"
    />
    <path
      d="M25.2764 11.7775C25.2764 9.9274 26.7877 8.41602 28.6379 8.41602C30.488 8.41602 31.9994 9.9274 31.9994 11.7775C31.9994 13.6277 30.488 15.1391 28.6379 15.1391H25.2764V11.7775Z"
      fill="#2EB67D"
    />
    <path
      d="M23.5824 11.7784C23.5824 13.6285 22.071 15.1399 20.2209 15.1399C18.3708 15.1399 16.8594 13.6285 16.8594 11.7784V3.36153C16.8594 1.51138 18.3708 0 20.2209 0C22.071 0 23.5824 1.51138 23.5824 3.36153V11.7784Z"
      fill="#2EB67D"
    />
    <path
      d="M20.2209 25.2773C22.071 25.2773 23.5824 26.7887 23.5824 28.6389C23.5824 30.489 22.071 32.0004 20.2209 32.0004C18.3708 32.0004 16.8594 30.489 16.8594 28.6389V25.2773H20.2209Z"
      fill="#ECB22E"
    />
    <path
      d="M20.2209 23.5824C18.3708 23.5824 16.8594 22.071 16.8594 20.2209C16.8594 18.3708 18.3708 16.8594 20.2209 16.8594H28.6377C30.4879 16.8594 31.9993 18.3708 31.9993 20.2209C31.9993 22.071 30.4879 23.5824 28.6377 23.5824H20.2209Z"
      fill="#ECB22E"
    />
  </svg>
);

export default Slack;
