import clsx from "clsx";
import React from "react";

import DefaultAvatar from "assets/svgs-icons/default-avatar.svg";

import useStyles from "./styles";

const Avatar = ({ className: extraClass = "" }) => {
  const classes = useStyles();
  const src = null;
  return (
    <div className={clsx(classes.avatar, extraClass)}>
      <img src={src || DefaultAvatar} alt="Profile avatar" />
    </div>
  );
};

export default Avatar;
