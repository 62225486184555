import CONSTANTS from "redux/_databases/databases.constants";

export const requestStart = () => ({
  type: CONSTANTS.DATABASES_REQUEST_START,
});

export const requestEnd = () => ({
  type: CONSTANTS.DATABASES_REQUEST_END,
});

export const saveData = (data) => ({
  type: CONSTANTS.DATABASES_SAVE_DATA,
  data,
});

export const updateData = (data) => ({
  type: CONSTANTS.DATABASES_UPDATE_DATA,
  data,
});

export const resetStore = () => ({
  type: CONSTANTS.DATABASES_RESET_STORE,
});

export const selectDatabase = (dbId) => ({
  type: CONSTANTS.DATABASES_SELECT_DB,
  data: dbId,
});

export const deleteData = (data) => ({
  type: CONSTANTS.DATABASES_DELETE_DB,
  data,
});
