export default {
  SLACK_GET_WORKSPACES: "SLACK_GET_WORKSPACES",
  SLACK_SELECT_WORKSPACE: "SLACK_SELECT_WORKSPACE",
  SLACK_DELETE: "SLACK_DELETE",
  SLACK_REQUEST_START: "SLACK_REQUEST_START",
  SLACK_REQUEST_END: "SLACK_REQUEST_END",
  SLACK_SAVE_DATA: "SLACK_SAVE_DATA",
  SLACK_SET_ERROR: "SLACK_SET_ERROR",
  SLACK_SET_META: "SLACK_SET_META",
  SLACK_RESET_DATA: "SLACK_RESET_DATA",
};
