import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Dialog,
  Box,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import { useStyles, useStepStyles } from "./styles";
import clsx from "clsx";
import { connect } from "react-redux";

import ZenDataLogo from "assets/illustrations/brand.js";
import SvgIcon from "components/svg-icon/svg-icon";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import { steps } from "./constants";

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.blue["young"],
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.blue["young"],
    },
  },
  line: {
    margin: `0px ${theme.spacing(2)}px`,
    height: 3,
    border: 0,
    backgroundColor: "#cfdff8",
    borderRadius: 1,
  },
}))(StepConnector);

const StepIcon = (props) => {
  const { icon, active, completed } = props;
  const stepClasses = useStepStyles();
  return (
    <Box
      className={clsx(
        stepClasses.container,
        active && stepClasses.active,
        completed && stepClasses.completed
      )}
    >
      <SvgIcon icon={icon} />
    </Box>
  );
};

const Wizard = ({ open = false, accountData }) => {
  const classes = useStyles();

  const [step, setStep] = useState(0);

  const onNextClick = () => setStep(step + 1);
  const onBackClick = () => setStep(step - 1);

  const { first_name: firstName } = accountData;

  return (
    <Dialog open={open} className={classes.dialog} maxWidth={false}>
      <Box className={classes.container}>
        <Box className={classes.logoContainer}>
          <ZenDataLogo />
        </Box>
        <Stepper
          alternativeLabel
          activeStep={step}
          className={classes.stepperContainer}
          connector={<Connector />}
        >
          {steps.map(({ label, icon }, idx) => {
            return (
              <Step key={idx}>
                <StepLabel
                  StepIconProps={{ icon }}
                  StepIconComponent={StepIcon}
                  className={classes.stepLabel}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box className={classes.content}>
          {step === 0 && <Step1 name={firstName} nextCallback={onNextClick} />}
          {step === 1 && <Step2 nextCallback={onNextClick} />}
          {step === 2 && (
            <Step3 nextCallback={onNextClick} backCallback={onBackClick} />
          )}
          {step === 3 && <Step4 nextCallback={onNextClick} />}
          {step === 4 && <Step5 />}
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = ({
  wizard: { open },
  account: { data: accountData },
}) => ({
  open,
  accountData,
});

export default connect(mapStateToProps)(Wizard);
