import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  whiteSpace: {
    width: "100%",
    height: ({ show }) => (show ? 100 : 0),
    transition: "height .3s cubic-bezier(0.4, 0, 0.2, 1)",
  },
  appBar: {
    height: 100,
    bottom: ({ show }) => (show ? 0 : -100),
    top: "auto",
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(3),
    borderTop: "1px solid #ccc",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    transition: "bottom .3s cubic-bezier(0.4, 0, 0.2, 1)",
    paddingRight: theme.spacing(14),
  },
  updateButton: {
    backgroundColor: theme.palette.blue["young"],
    boxShadow: "none",
    color: theme.palette.common.white,
    padding: theme.spacing(1, 3),
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: 1,
    "&:hover": {
      backgroundColor: theme.palette.blue[500],
    },
  },
  discardButton: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.blue["young"],
    backgroundColor: theme.palette.common.white,
    textTransform: "none",
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    lineHeight: "21px",
  },
}));
