import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  row: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  fieldSm: {
    width: 208,
    marginRight: theme.spacing(2),
  },
  fieldMd: {
    width: 336,
    marginRight: theme.spacing(2),
  },
  testButton: {
    textTransform: "none",
    alignSelf: "center",
    marginTop: 6,
  },
  testButtonText: {
    fontSize: "15px",
    lineHeight: "22px",
    textDecoration: "underline",
    fontWeight: 500,
  },
}));
