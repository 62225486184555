import React from "react";
import { Route } from "react-router";

export const routePaths = {
  acceptInvite: "/accept-invite",
  componentsTest: "/components-test",
  confirmPassword: "/confirm-password",
  congrats: "/congrats",
  dataProtectionForm: "/data-protection-form",
  demoReports: "/demo-reports",
  extension: "/extension",
  faqs: "/faqs",
  home: "/",
  login: "/login",
  notFound: "/not-found",
  plans: "/plans",
  pricing: "/pricing",
  privacy: "/privacy-policy",
  privacyCentral: "/press-appearances",
  privacyReport: "/privacy-report",
  privacyReports: "/privacy-reports",
  appReport: "/app-report",
  securityReport: "/security-report",
  dataSourceReport: "/data-source-report",
  fileReport: "/file-report",
  repositoryResults: "/respository-results",
  privacyScanner: "/privacy-scanner",
  register: "/register",
  requestADemo: "/upgrade-plan",
  resetPassword: "/reset-password",
  signup: "/signup",
  simpleLogin: "/extension/login",
  terms: "/terms",
  zenArea: "/zen",
  zenAppScans: "/zen/app-scans",
  zenSecurityScans: "/zen/security-scans",
  zenRepositoryScans: "/zen/repository-scans",
  zenDataSourceScans: "/zen/data-source-scans",
  zenDataFileScans: "/zen/file-scans",
  variableInventory: "/variable-inventory",
  DATAFLOW_URL: "/zen/new-repository-branch-flow/",
  PII_SHARING_DETAILS: "/zen/pii-sharing-details/",
  PII_CODE_SNIPPET: "/zen/pii-code-snippet/",
  FTU_LEARN_MORE: "/zen/ftu",
  REPO_SCANNER_WEBHOOK_SETTINGS: "/zen/repo-scan/webhook-settings",
};

export default (
  <Route>
    {Object.values(routePaths).map((path) => (
      <Route path={path} key={path} />
    ))}
  </Route>
);
