import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import isNil from "lodash.isnil";
import { Switch, Route } from "react-router-dom";
import PageWrapper, { Tabs, Tab } from "components/PageWrapper";
import Timeline from "components/Consents/Analytics/Timeline";
import GeoDistribution from "components/Consents/Analytics/GeoDistribution";
import Spotlight from "components/Consents/Analytics/Spotlight";
import CONSTANTS from "redux/_filters/filters.constants";
import { getTimelineErrorState } from "redux/_consents/_timeline/timeline.selectors";
import { getMapErrorState } from "redux/_consents/_map/map.selectors";
const { TYPES } = CONSTANTS;

const ConsentsAnalytics = ({ match, location, history, error }) => {
  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const [value, setValue] = useState(pathValue || "geo-distribution");

  useEffect(() => {
    if (isNil(pathValue)) history.push(`/zen/consents/analytics/${value}`);
  });

  useEffect(() => {
    history.push(`/zen/consents/analytics/${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onTabChange = (value) => setValue(value);

  const filters = {
    timeline: [TYPES.CONSENTS.CUSTOMER_VISIBILITY, TYPES.CONSENTS.DATE_RANGE],
    "geo-distribution": [
      TYPES.CONSENTS.CUSTOMER_VISIBILITY,
      TYPES.CONSENTS.DECISION,
    ],
    spotlight: [
      TYPES.CONSENTS.CUSTOMER_VISIBILITY,
      TYPES.CONSENTS.DATE_RANGE,
      TYPES.CONSENTS.UNCONSENTS,
    ],
  };
  return (
    <PageWrapper
      title="CONSENTS"
      subtitle="ANALYTICS"
      filters={filters[value]}
      error={error[value]}
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"geo-distribution"} label="Geographical Distribution" />
          <Tab value={"timeline"} label="Timeline" />
          <Tab value={"spotlight"} label="Spotlight" />
        </Tabs>
      }
    >
      <Switch>
        <Route path={"/zen/consents/analytics/timeline"} component={Timeline} />
        <Route
          path={"/zen/consents/analytics/geo-distribution"}
          component={GeoDistribution}
        />
        <Route
          path={"/zen/consents/analytics/spotlight"}
          component={Spotlight}
        />
      </Switch>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  error: {
    timeline: getTimelineErrorState(state),
    "geo-distribution": getMapErrorState(state),
    spotlight: false,
  },
});

export default connect(mapStateToProps)(ConsentsAnalytics);
