export function formatValue(number) {
  const formatters = [
    { value: 10 ** 9, id: "B" },
    { value: 10 ** 6, id: "M" },
    { value: 10 ** 3, id: "K" },
  ];
  for (let i = 0; i < formatters.length; i++) {
    if (number / formatters[i].value > 1)
      return `${Math.round((number * 10) / formatters[i].value) / 10}${
        formatters[i].id
      }`;
  }
  return number;
}
