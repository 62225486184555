import { Text, View } from "@react-pdf/renderer";

const IpaBinaryCodePage = ({ styles, data }) => {
  return (
    <View style={styles.section} break>
      <View>
        <Text style={styles.title}>IPA Binary Code Analysis</Text>
      </View>
      <View>
        <View>
          <View style={styles.table}>
            <View style={styles.thead} fixed>
              <View style={styles.tr}>
                <View style={[styles.thCustomWidth, { width: "30%" }]}>
                  <Text>ISSUE</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "15%" }]}>
                  <Text>SEVERITY</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "20%" }]}>
                  <Text>STANDARDS</Text>
                </View>
                <View style={[styles.thCustomWidth, { width: "35%" }]}>
                  <Text>DETAILS</Text>
                </View>
              </View>
            </View>
            {Object.entries(data.binary_analysis).map(([key, value]) => {
              const statusStyles = {
                info: {
                  backgroundColor: "#466DE8",
                },
                warning: {
                  backgroundColor: "#EF7D1F",
                },
                high: {
                  backgroundColor: "#D32F2F",
                },
                secure: {
                  backgroundColor: "#2E7D32",
                },
                good: {
                  backgroundColor: "#2E7D32",
                },
              };

              const statusStyle = statusStyles[value.severity] || {};

              return (
                <View style={styles.tr} key={key} wrap={false}>
                  <View style={[styles.tdCustomWidth, { width: "30%" }]}>
                    <Text>{key}</Text>
                  </View>
                  <View style={[styles.tdCustomWidth, { width: "15%" }]}>
                    <Text style={[statusStyle, styles.severityText]}>
                      {value.severity}
                    </Text>
                  </View>
                  <View style={[styles.tdCustomWidth, { width: "20%" }]}>
                    <Text>CWE: {value.cwe}</Text>
                    <Text>OWASP MASVS: {value.masvs}</Text>
                    <Text>OWASP TOP 10: {value["owasp-mobile"]}</Text>
                  </View>
                  <View style={[styles.tdCustomWidth, { width: "35%" }]}>
                    <Text>{value["detailed_desc"]}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default IpaBinaryCodePage;
