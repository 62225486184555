import { getAll, getKpis } from "redux/_consents/_overview/overview.service";
import {
  requestStart,
  requestEnd,
  saveData,
  saveKpis,
} from "redux/_consents/_overview/overview.actions";
import {
  getConsentsOverviewData,
  getKpis as selectorKpis,
} from "./overview.selectors";
import isEmpty from "lodash.isempty";

export const getOverviewData = (force) => (dispatch, getState) => {
  const overviewData = getConsentsOverviewData(getState());
  if (force || isEmpty(overviewData)) {
    dispatch(requestStart());
    return getAll()
      .then((data) => {
        dispatch(requestEnd());
        dispatch(saveData(data));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(requestEnd());
        return Promise.reject();
      });
  }
};

export const getKpisData = (force) => (dispatch, getState) => {
  const kpisData = selectorKpis(getState());
  if (force || isEmpty(kpisData)) {
    return getKpis()
      .then((data) => {
        dispatch(saveKpis(data));
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject();
      });
  }
};
