import React from "react";
import SelectInput from "components/Inputs/Select";
import useStyles from "../../styles";
import { consentDecision } from "components/PageWrapper/components/FiltersBar/components/constants";
import { connect } from "react-redux";
import { getFilterConsentDecision } from "redux/_filters/filters.selectors";
import { selectData } from "redux/_filters/filters.actions";
import CONSTANTS from "redux/_filters/filters.constants";

const { TYPES } = CONSTANTS;

const ConsentsDecision = ({ decision, setConsentDecision }) => {
  const classes = useStyles();
  return (
    <SelectInput
      label="Decision"
      options={consentDecision}
      className={classes.filtersInput}
      value={decision}
      onChange={setConsentDecision}
    />
  );
};

const mapStateToProps = (state) => ({
  decision: getFilterConsentDecision(state),
});

const mapDispatchToProps = (dispatch) => ({
  setConsentDecision: (id) => dispatch(selectData(TYPES.CONSENTS.DECISION, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsDecision);
