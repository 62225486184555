import React from "react";

const ArrowRedo = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24 / arrows / arrow-redo">
      <path
        id="icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.3658V6H22V15H13V13H18.7316C18.1795 12.2824 17.3736 11.224 16.9508 10.7599C15.3323 8.98346 13.5912 8 11.5 8C6.39481 8 4 11.3972 4 16H2C2 10.4122 5.11035 6 11.5 6C14.2385 6 16.466 7.25818 18.4292 9.41293C18.8493 9.87402 19.5008 10.7119 20 11.3658Z"
        fill="white"
      />
    </g>
  </svg>
);

export default ArrowRedo;
