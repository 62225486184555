import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { postTriggerReport } from "redux/_reports/reports.async.actions";
import { Form } from "react-final-form";
import { getAccountData } from "redux/_account/account.selectors";
import { useSnackbar } from "notistack";

import CustomButton from "components/Button";
import useCustomStyles from "./styles.js";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Delete } from "@material-ui/icons";
import Loader from "components/Loader/index.js";
import { sendFileScan } from "redux/_datasources/datasources.service.js";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    padding: "70px 70px 0",
    gap: 20,
  },
  container: {
    display: "flex",
    padding: 16,
    gap: 5,
    cursor: "pointer",
    position: "relative",
    height: 220,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 1px 6.400000095367432px 1px #0000001A",
    borderRadius: 12,
  },
  fileContainer: {
    display: "flex",
    padding: 16,
    borderRadius: 12,
    boxShadow: "0px 1px 6.400000095367432px 1px #0000001A",
    fontSize: 20,
    fontWeight: 600,
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
  },
  description: {
    display: "flex",
    gap: 16,
    fontSize: 18,
    color: "rgba(0,0,0,0.5)",
    fontWeight: 500,
    flexDirection: "column",
    alignItems: "center",
  },
}));

const CreateFileScanner = ({ setSelectedTab }) => {
  const [downloadFile, setDownloadFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isBuildFile, setIsBuildFile] = useState(false);
  const customClasses = useCustomStyles();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const hiddenFileInput = useRef(null);

  const preSubmit = async (values) => {
    try {
      console.log("preSubmit file: ", file);
      setIsBuildFile(true);
      const resp = await sendFileScan(file[0]);

      if (resp) {
        console.log("preSubmit resp: ", resp);
        setIsBuildFile(false);
        setDownloadFile(true);
        enqueueSnackbar("Scan scheduled successfully", { variant: "success" });
        setSelectedTab(0);
      }
    } catch (error) {
      console.log("file preSubmit error: ", error);
      enqueueSnackbar("An error has occurred", { variant: "error" });
    }

    // const { formSchema, ...restProvider } = selectedProvider;
    // const payload = {
    //   auth: { ...values },
    //   provider: restProvider.id
    // };
    // setLoading(true);

    // try {
    //   await sendScan(createDataSourceScan)(payload);
    //   setLoading(false);
    //   enqueueSnackbar("Scan scheduled successfully", { variant: "success" });
    //   setSelectedTab(0);
    //   setSelectedProvider(null);
    //   debugger;
    // } catch (error) {
    //   debugger;
    //   setLoading(false);
    //   enqueueSnackbar("An error has occurred", { variant: "error" });
    // }
  };

  const addFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const selectedFile = event.target.files[0];
    const allowedTypes = ["application/pdf", "text/csv", "text/plain", "image/png", "image/jpeg"];
    const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
  
    // Validate file type
    if (!allowedTypes.includes(selectedFile.type)) {
      enqueueSnackbar("Invalid file type", { variant: "error" });
      return;
    }
  
    // Validate file size
    if (selectedFile.size > maxSize) {
      enqueueSnackbar("File size exceeds 2 MB limit.", { variant: "error" });
      return;
    }
  
    setDownloadFile(false);
    setFile([selectedFile]);
  };
  

  const removeFile = (event) => setFile();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <Form onSubmit={preSubmit}>
      {({ handleSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(customClasses.container, classes.form)}
        >
          {!file && (
            <Box className={classes.container}>
              <div>
                <Box className={classes.description}>
                  Drag and drop a file here, or click to select a file
                  <input
                    type="file"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                    ref={hiddenFileInput}
                    onClick={() => setFile()}
                    onChange={addFile}
                  />
                  <CustomButton
                    disabled={true}
                    onClick={handleClick}
                    style={{
                      maxWidth: 200,
                      lineHeight: "normal",
                      backgroundColor: "#1a3586",
                      color: "#ffffff",
                    }}
                  >
                    Upload
                  </CustomButton>
                </Box>
              </div>
            </Box>
          )}

          {file && (
            <>
              <Box className={classes.fileContainer}>
                {file?.[0].name}
                {downloadFile ? (
                  <CustomButton style={{ maxWidth: 200 }}>
                    Download
                  </CustomButton>
                ) : isBuildFile ? (
                  <Loader style={{ width: "auto" }} />
                ) : (
                  <IconButton onClick={removeFile}>
                    <Delete />
                  </IconButton>
                )}
              </Box>

              <CustomButton
                type="submit"
                disabled={!!downloadFile || isBuildFile}
              >
                Create scan
              </CustomButton>

              <CustomButton
                variant="secondary"
                className={classes.goBackButton}
                onClick={() => setSelectedTab(0)}
              >
                Go Back
              </CustomButton>
            </>
          )}
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
});

const mapDispatchToProps = (dispatch) => ({
  triggerSingleRunScan: (data) => dispatch(postTriggerReport(data)),
});

const ConnectedCreateFileScanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFileScanner);
export default withRouter(ConnectedCreateFileScanner);
