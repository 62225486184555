import React from "react";
import Page from "components/PrivacyReports/Report/components/Page";
import SideBar from "components/PrivacyReports/Report/components/SideBar";
import Table from "pages/AppReport/common/Table";

import { SECTIONS_CONTENT } from "pages/AppReport/constants";

const FirebaseURLSection = ({ goToNextSectionAction, data }) => {
  return (
    <Page
      key={SECTIONS_CONTENT.firebaseURL.key}
      footerBackgroundColor={SECTIONS_CONTENT.firebaseURL.backgroundColor}
      footerText={
        <div className="privacy-tech">
          {/* Check the test scores in detail */}
        </div>
      }
      sidebar={
        <SideBar
          title={SECTIONS_CONTENT.firebaseURL.title}
          description={SECTIONS_CONTENT.firebaseURL.description}
        />
      }
      goToNextSectionAction={goToNextSectionAction}
      trimPaddingTop
    >
      <Table
        data={data}
        limitPerPage={5}
        columns={[
          {
            key: "url",
            label: "Firebase URL",
            width: "70%",
          },
          {
            key: "open",
            label: "Details",
            width: "30%",
            render: (open) => (
              <>
                <span>info</span>
                <br />
                <span>
                  {open
                    ? "App talks to a Firebase Database."
                    : "App doesn't talk to a Firebase Database."}
                </span>
              </>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default FirebaseURLSection;
