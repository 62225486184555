import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Pagination from "../Pagination";
import usePaginationStyles from "../Pagination/styles";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import CellResponse from "./CellResponse";

const getColumnWidth = (width, isShowEnumColumn = false) => {
  if (!isShowEnumColumn) return width;

  const columnWidth = width.includes("%")
    ? parseInt(width.split("%")[0])
    : width;

  const newColumnWidth = columnWidth - columnWidth * 0.05;
  return width.includes("%") ? `${newColumnWidth}%` : newColumnWidth;
};

const Table = ({
  columns,
  data,
  environment = "web",
  tableKey,
  variant,
  limitByPage: limit = 10,
  showEnumColumn = false,
  currentPage,
  setLimitByPage,
  setCurrentPage,
  totalItems
}) => {
  const classes = useStyles({ variant });
  const [current, setCurrent] = useState(currentPage || 0);
  const [limitPerPage, setLimitPerPage] = useState(limit);
  const paginationClasses = usePaginationStyles();

  useEffect(() => {
    setCurrent(0);
  }, [data]);

  const total = Math.ceil((totalItems || data.length) / limitPerPage);
  const start = setLimitByPage ? ((currentPage - 1) * limit) : (current * limitPerPage);
  const end = setLimitByPage
    ? (currentPage * limit) < totalItems
      ? currentPage * limit
      : start + (totalItems - start)
    : start + limitPerPage < data.length
      ? start + limitPerPage
      : start + (data.length - start);
  return (
    <div /* className={classes.trackers} */ style={{ width: "100%" }}>
      <table className={classes.xsTable}>
        <tbody>
          {((limitPerPage && !setLimitByPage) ? data.slice(start, end) : data).map((element, i) => (
            <CellResponse key={`row-${i}`} row={element} columns={columns} />
          ))}
        </tbody>
      </table>

      <table className={clsx(classes.table, classes.mdTable, variant === "primary" ? classes.primary : "")}>
        <thead className={classes.head}>
          <tr className={classes.tr}>
            {showEnumColumn && (
              <th
                className={clsx(classes.th, classes.domain, environment)}
                style={{ width: "5%" }}
              >
                NO
              </th>
            )}
            {columns.map((column) => (
              <th
                className={clsx(classes.th, classes.domain, environment)}
                key={column.key}
                style={{
                  width:
                    getColumnWidth(column.width, showEnumColumn) ||
                    getColumnWidth(100, showEnumColumn) / column.length,
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {((limitPerPage && !setLimitByPage) ? data.slice(start, end) : data).map((element, i) => (
            <tr className={classes.row} key={`table-${tableKey}-row-${i}`}>
              {showEnumColumn && (
                <td
                  key={`table-${tableKey}-row-${i}-cell-No`}
                  className={clsx("scrollable-element", classes.data)}
                  style={{ width: "5%" }}
                >
                  {start + i + 1}
                </td>
              )}
              {columns.map((column) => (
                <td
                  key={`table-${tableKey}-row-${i}-cell-${column.key}`}
                  className={clsx("scrollable-element", classes.data)}
                  style={{ width: column.width || 100 / column.length }}
                >
                  {column?.render ? (
                    column.render(element[column.key], element)
                  ) : (
                    <Typography className={classes.cell}>
                      {element[column.key]}
                    </Typography>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {end > 1 && (
        <Pagination
          className={paginationClasses.pagination}
          current={setCurrentPage ? currentPage : current}
          total={total}
          setCurrent={setCurrentPage || setCurrent}
          backPaginated={!!setLimitByPage}
          end={end}
          start={start + 1}
          totalItems={totalItems || data.length}
          limitPerPage={setLimitByPage ? limit : limitPerPage}
          onChangeLimitPerPage={setLimitByPage || setLimitPerPage}
        />
      )}
    </div>
  );
};

export default Table;
