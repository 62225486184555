import CONSTANTS from "./slack.constants";

const initialState = {
  loading: false,
  workspaces: {
    data: [],
    meta: {},
    error: false,
  },
  workspace: {
    data: {},
    error: false,
  },
  workspacesInfo: {
    data: [],
    meta: {},
    error: false,
  },
};

export const slack = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.SLACK_REQUEST_START:
      return {
        loading: true,
        workspaces: {
          ...state.workspaces,
          error: false,
        },
        workspace: {
          ...state.workspace,
          error: false,
        },
        workspacesInfo: {
          ...state.workspacesInfo,
          error: false,
        },
      };
    case CONSTANTS.SLACK_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SLACK_SAVE_DATA:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          data: data,
        },
      };
    case CONSTANTS.SLACK_SET_META:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          meta: data,
        },
      };
    case CONSTANTS.SLACK_SET_ERROR:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          error: data || true,
        },
      };
    case CONSTANTS.SLACK_RESET_DATA:
      return {
        ...state,
        [dataType]: initialState[dataType],
      };
    default:
      return state;
  }
};
