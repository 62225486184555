/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { authHeader } from "../helpers/auth-header";

export const useSearch = (searchURL, extraBodyRequest) => {
  const [searchList, setSearchList] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDropdownSelect, setIsDropdownSelect] = useState(false);

  const updateIsDropdownSelect = (isDropdownSelectValue) => {
    setIsDropdownSelect(isDropdownSelectValue);
  };

  const updateSearchValue = (newSearchValue) => {
    setSearchValue(newSearchValue);
  };

  const fetchSearch = async () => {
    const { data } = await axios.post(
      searchURL,
      { search: searchValue, ...extraBodyRequest },
      {
        headers: authHeader(),
      }
    );
    const listOfApps = [...data.data];
    setSearchList([...listOfApps]);
    setSearchLoading(false);
  };

  useEffect(() => {
    if (isDropdownSelect) return;
    if (searchValue.length < 3) {
      setSearchLoading(false);
      setSearchList([]);
      return;
    }
    setSearchLoading(true);
    const timer = setTimeout(() => {
      fetchSearch();
    }, 800);
    return () => clearTimeout(timer);
  }, [searchValue]);

  return {
    searchValue,
    searchOpen,
    searchList,
    searchLoading,
    setSearchOpen,
    updateSearchValue,
    setSearchLoading,
    updateIsDropdownSelect,
  };
};
