import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  menu: {
    background: theme.palette.blue[200],
    borderRadius: 10,
    width: 188,
    height: 91,
    padding: theme.spacing(2),
  },
  list: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
  },
  title: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.blue[600],
    padding: 0,
  },
  inputContainer: {
    border: "1px solid #1A3586",
    background: theme.palette.common.white,
  },
  textfield: {
    width: 119,
  },
  input: {
    height: 38,
    "& svg path": {
      fill: theme.palette.blue[500],
    },
  },
  select: {
    height: 38,
    maxHeight: 38,
    padding: theme.spacing(0, 2),
    lineHeight: "34px",
  },
  selectMenu: {
    background: theme.palette.common.white,
    border: "1px solid #1A3586",
    boxSizing: "border-box",
    boxShadow: "0px 4px 8px #122873",
    borderRadius: 16,
    width: 156,
  },
  notchedOutline: {
    border: "none",
  },
  button: {
    width: 36,
    height: 36,
    borderRadius: 0,
    padding: 0,
    backgroundColor: theme.palette.blue[100],
    transitionDuration: "300ms",
    "&:hover": {
      backgroundColor: theme.palette.blue[500],
      "& svg path": {
        fill: theme.palette.common.white,
      },
    },
  },
  menuItem: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "18px",
    color: theme.palette.blue[400],
    minHeight: "unset",
    padding: theme.spacing(1, 2),
  },
}));
