import CollectorItem from "components/Consents/Collector/components/Item";
import Checkbox from "components/Requests/Widget/components/CustomCheckbox";
import RequestsWrapper from "components/Requests/Widget/components/Wrapper";
import OptionForm from "components/Requests/Widget/Options/components/optionForm";
import useQuery from "hooks/useQuery";
import groupBy from "lodash.groupby";
import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  getConfig,
  updateConfig,
} from "redux/_requests/_config/config.async.actions";
import { getRequestsConfig } from "redux/_requests/_config/config.selectors";
import {
  getOptionsData,
  updateOption,
} from "redux/_requests/_options/options.async.actions";
import {
  getOptionsLoadingState,
  getRequestsOptionsData,
} from "redux/_requests/_options/options.selectors";

const Options = ({
  options,
  loading,
  getData,
  updateData,
  getConfigData,
  updateConfigData,
  config: { gdpr_option: gdprOption = false, ccpa_option: ccpaOption = false },
}) => {
  const query = useQuery();

  useEffect(() => {
    const reportId = query.get("r");
    getData();
    getConfigData(reportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { GDPR = [], CCPA = [] } = useMemo(
    () => groupBy(options, (option) => option.regulation.name),
    [options]
  );

  const handleGdprCheckboxChange = (e) => {
    updateConfigData({ gdpr_option: e.target.checked });
  };
  const handleCcpaCheckboxChange = (e) => {
    updateConfigData({ ccpa_option: e.target.checked });
  };

  const data = useCallback(() => ({ options, gdprOption, ccpaOption }), [
    options,
    gdprOption,
    ccpaOption,
  ]);

  return (
    <RequestsWrapper
      primaryText="You can edit the options that users will see, we already set up a default copy but you are free to replace it with your own."
      showAddButton={false}
      loading={loading}
      previewPage="home"
      previewData={data}
    >
      <Checkbox
        label="GDPR Requests"
        checked={gdprOption}
        onChange={handleGdprCheckboxChange}
      />
      {GDPR.map((data) => (
        <CollectorItem
          key={data.id}
          title={data.name}
          formComponent={
            <OptionForm
              loading={loading}
              update={updateData}
              id={data.id}
              data={data}
            />
          }
        />
      ))}

      <Checkbox
        label="CCPA Requests"
        checked={ccpaOption}
        onChange={handleCcpaCheckboxChange}
      />
      {CCPA.map((data) => (
        <CollectorItem
          key={data.id}
          title={data.name}
          formComponent={
            <OptionForm
              loading={loading}
              update={updateData}
              id={data.id}
              data={data}
            />
          }
        />
      ))}
    </RequestsWrapper>
  );
};

const mapStateToProps = (state) => ({
  options: getRequestsOptionsData(state),
  loading: getOptionsLoadingState(state),
  config: getRequestsConfig(state),
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(getOptionsData()),
  updateData: (id, data) => dispatch(updateOption(id, data)),
  getConfigData: (x) => dispatch(getConfig(x)),
  updateConfigData: (data) => dispatch(updateConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
