import React from "react";

const arrowDownIcon = () => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7748 2.04227L8.04239 8.77541C7.74264 9.07486 7.25715 9.07486 6.95774 8.77541L0.224558 2.04227C-0.0748525 1.74271 -0.0748525 1.25728 0.224558 0.95772L0.766857 0.415421C1.06627 0.115669 1.55219 0.115669 1.8518 0.415421L7.49999 6.06391L13.1484 0.415421C13.4477 0.115669 13.9334 0.115669 14.2329 0.415421L14.7752 0.95772C15.075 1.25762 15.075 1.74271 14.7748 2.04227Z"
      fill="#3F51B5"
    />
  </svg>
);

export default arrowDownIcon;
