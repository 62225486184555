import CONSTANTS from "./salesforce.constants";

const initialState = {
  loading: false,
  connectedApps: {
    data: [],
    meta: {},
    error: false,
  },
};

export const salesforce = (state = initialState, { type, data, dataType }) => {
  switch (type) {
    case CONSTANTS.SALESFORCE_REQUEST_START:
      return {
        loading: true,
        connectedApps: {
          ...state.connectedApps,
          error: false,
        },
      };
    case CONSTANTS.SALESFORCE_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SALESFORCE_SAVE_DATA:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          data: data,
        },
      };
    case CONSTANTS.SALESFORCE_SET_META:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          meta: data,
        },
      };
    case CONSTANTS.SALESFORCE_SET_ERROR:
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          error: data || true,
        },
      };
    case CONSTANTS.SALESFORCE_RESET_DATA:
      return {
        ...state,
        [dataType]: initialState[dataType],
      };
    default:
      return state;
  }
};
