import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "./styles";
import SelectInput from "components/Inputs/Select";
import {
  days,
  executionTypes,
  timezones,
} from "components/SettingsSections/Environment/constants";
import TimeInput from "components/Inputs/Time";
import { updateEnvironment } from "redux/_wizard/wizard.async.actions";
import { connect } from "react-redux";

// const gridItemProps = {
//   xs: 12,
//   sm: 6,
//   md: 4,
//   lg: 4,
//   xl: 4
// }

const Step4 = (props) => {
  const { nextCallback, loading, environment, dispatch } = props;
  const classes = useStyles();

  const [timezone, setTimezone] = useState("");
  const [execute, setExecute] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [fromHour, setFromHour] = useState("");
  const [toDate, setToDate] = useState("");
  const [toHour, setToHour] = useState("");

  const generateData = () => ({
    timezone,
    execute,
    fromDate,
    fromHour,
    toDate,
    toHour,
    name: environment.name,
    unprotected: environment.unprotected,
  });

  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>Scan Preferences</Typography>
        <Typography className={classes.subtitle}>
          Set a window of time for when a Scan may be performed. Because scans
          can take some time to complete, 4hs increments are recommended.
        </Typography>
      </Box>
      <Box className={classes.rightContainer}>
        <Box style={{ display: "flex", width: "100%" }}>
          <Box>
            <SelectInput
              label="Timezone"
              style={{ width: "208px", paddingBottom: "16px" }}
              onChange={setTimezone}
              value={timezone}
              disabled={loading}
              options={timezones}
            />
            <SelectInput
              label="From"
              helperText="Select date"
              style={{ width: "208px", paddingBottom: "16px" }}
              onChange={setFromDate}
              value={fromDate}
              disabled={loading}
              options={days}
            />
            <SelectInput
              label="To"
              helperText="Select date"
              style={{ width: "208px", paddingBottom: "16px" }}
              onChange={setToDate}
              value={toDate}
              disabled={loading}
              options={days}
            />
          </Box>
          <Box style={{ paddingLeft: "16px" }}>
            <SelectInput
              label="Execute"
              style={{ width: "208px", paddingBottom: "16px" }}
              onChange={setExecute}
              value={execute}
              disabled={loading}
              options={executionTypes}
            />
            <TimeInput
              label="Hours"
              helperText="Select hour"
              style={{ width: "208px", paddingBottom: "16px" }}
              onChange={setFromHour}
              value={fromHour}
              disabled={loading}
            />
            <TimeInput
              label="Hours"
              helperText="Select hour"
              style={{ maxWidth: "208px" }}
              onChange={setToHour}
              value={toHour}
              disabled={loading}
            />
          </Box>
        </Box>
      </Box>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(updateEnvironment(environment.id, generateData()))
            .then(() => nextCallback())
            .catch();
        }}
        disabled={loading}
      >
        NEXT
      </Button>
    </Box>
  );
};

const mapStateToProps = ({ wizard: { loading, environment } }) => ({
  loading,
  environment,
});

export default connect(mapStateToProps)(Step4);
