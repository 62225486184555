import Code from "@material-ui/icons/Code";
import CloudQueue from "@material-ui/icons/CloudQueue";
import DescriptionIcon from "@material-ui/icons/Web";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SecurityIcon from "@material-ui/icons/Security";
import Devices from "@material-ui/icons/Devices";
import Storage from "@material-ui/icons/Storage";
import PersonIcon from "@material-ui/icons/Person";
import BadgeIcon from "@material-ui/icons/VerifiedUser";

const common = (icon) => [
  {
    name: "overview",
    icon: icon || "menu.overview",
    text: "Overview",
    exact: true,
    link: () => `/zen/database-scanner`,
  },
];

const privacyMenu = [
  {
    name: "reports",
    icon: <DescriptionIcon />,
    text: "Client Layer",
    subItems: [
      {
        name: "website-scans",
        icon: <DescriptionIcon />,
        text: "Website Scanner",
        link: () => `/zen/scans`,
        permission: null,
      },
      {
        name: "app-scans",
        icon: <PhoneIphoneIcon />,
        text: "App Scanner",
        link: () => `/zen/app-scans`,
        permission: null,
      },
      {
        name: "security-scans",
        icon: <SecurityIcon />,
        text: "Security Scanner",
        link: () => `/zen/security-scans`,
        permission: "securityScanner",
      },
    ],
  },
  {
    name: "app-layer",
    icon: <Code />,
    text: "App Layer",
    link: () => `/zen/keygen/code-scanner`,
    subItems: [
      {
        name: "code-scanner",
        icon: <Code />,
        text: "Code Scanner",
        link: () => `/zen/keygen/code-scanner`,
        permission: "codeScanner",
      },
      /* {
        name: "repository-scans",
        icon: <CloudQueue />,
        text: "Repository Scanner",
        link: () => `/zen/repository-scans`,
        permission: "codeScanner",
      },*/
      {
        name: "repository-scans2",
        icon: <CloudQueue />,
        text: "Repository Scanner",
        link: () => `/zen/repository-scans2`,
        permission: "codeScanner",
      },
    ],
  },
  {
    name: "data-layer",
    icon: <Storage />,
    text: "Data Layer",
    link: () => `/zen/keygen/code-scanner`,
    subItems: [
      {
        name: "data-source-scans",
        icon: <Storage />,
        text: "Data Source Scanner",
        link: () => `/zen/data-source-scans`,
        permission: "deviceScanner",
      },
      {
        name: "file-scans",
        icon: <Storage />,
        text: "File Scanner",
        link: () => `/zen/file-scans`,
        permission: "deviceScanner",
      },
      {
        name: "device-scanner",
        icon: <Devices />,
        text: "Device Scanner",
        link: () => `/zen/keygen/device-scanner`,
        permission: "deviceScanner",
      },
      {
        name: "local-db-scanner",
        icon: <Storage />,
        text: "Local DB Scanner",
        link: () => `/zen/keygen/local-db-scanner`,
        permission: "databaseScanner",
      },
      {
        name: "piiAPI",
        icon: <Code />,
        text: "Pii API",
        link: () => `/zen/pii-api`,
        permission: null,
      },
      {
        name: "database-scanner",
        icon: <Storage />,
        blank: true,
        text: "DB Scanner",
        link: () => `/zen/database-scanner`,
        permission: "databaseScanner",
      },
    ],
  },
  {
    name: "reports",
    icon: <BadgeIcon />,
    text: "Privacy Badges",
    link: () => `/zen/badges`,
    permission: null,
  },
  {
    name: "account",
    icon: <PersonIcon />,
    text: "Account",
    link: () => `/zen/account`,
    topDivider: true,
    permission: null,
  },
];

const piiMenu = [
  ...common(),
  {
    name: "data-discovery",
    icon: "menu.pii_reports",
    text: "Data Discovery",
    subItems: [
      {
        name: "data-map",
        text: "Data map",
        link: (envId, scanId) =>
          !envId || !scanId
            ? `/zen/empty/data-map`
            : `/zen/environment/${envId}/scans/${scanId}/data-map`,
      },
      {
        name: "geo-distribution",
        text: "Geo Distribution",
        link: (envId, scanId) =>
          !envId || !scanId
            ? `/zen/empty/pii-countries`
            : `/zen/environment/${envId}/scans/${scanId}/pii-countries`,
      },
      {
        name: "data-owners",
        text: "Data Owners",
        link: (envId, scanId) =>
          !envId || !scanId
            ? `/zen/empty/data-owners`
            : `/zen/environment/${envId}/scans/${scanId}/data-owners`,
      },
      {
        name: "functional-uses",
        text: "Functional Uses",
        link: (envId, scanId) =>
          !envId || !scanId
            ? `/zen/empty/functional-uses`
            : `/zen/environment/${envId}/scans/${scanId}/functional-uses`,
      },
      {
        name: "pii-trends",
        text: "PII Trends",
        link: (envId, scanId) =>
          !envId || !scanId
            ? `/zen/empty/pii-trends`
            : `/zen/environment/${envId}/scans/pii-trends`,
      },
      {
        name: "scans",
        text: "Scans",
        exact: true,
        link: (envId, _) => `/zen/environment/${envId}/scans`,
      },
      /*
      {
        name: "cookies",
        text: "Cookies",
        exact: true,
        link: (envId, _) => `/zen/cookies`,
      },
      {
        name: "integrations",
        text: "Integrations",
        link: () => "/zen/integrations",
      },
      */
    ],
  },
  /*
  {
    name: "consents",
    icon: "menu.consents",
    text: "Consents",
    subItems: [
      {
        name: "consents-overview",
        text: "Overview",
        link: () => `/zen/consents/overview`,
      },
      {
        name: "consents-statistics",
        text: "Analytics",
        link: () => `/zen/consents/analytics`,
      },
      {
        name: "collector",
        text: "Collector",
        link: () => `/zen/consents/collector`,
      },
    ],
  },
  {
    name: "requests",
    icon: "menu.reports",
    text: "Data Requests",
    subItems: [
      // {
      //   name: 'requests-overview',
      //   text: 'Overview',
      //   link: () => `/zen/requests/overview`
      // },
      {
        name: "requests-manage",
        text: "Manage",
        link: () => `/zen/requests/manage`,
      },
      // {
      //   name: 'requests-analytics',
      //   text: 'Analytics',
      //   link: () => `/zen/requests/analytics`
      // },
      {
        name: "requests-widget",
        text: "Widget",
        link: () => `/zen/requests/widget`,
      },
    ],
  },
  */
  {
    name: "settings",
    icon: "menu.settings",
    text: "Settings",
    link: () => `/zen/settings`,
  },
  {
    onlyMainAccount: true,
    name: "admin",
    icon: "menu.admin",
    text: "Admin",
    link: () => `/zen/admin`,
    topDivider: true,
  },
];

export { piiMenu, privacyMenu };
