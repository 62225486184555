import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { rrulestr } from "rrule";

import Button from "components/Button";
import PageReportsWrapper from "components/PageReportsWrapper";
import ReportList from "components/PrivacyReports/ReportList";
import ScanFlowForm from "components/Scans/Forms/Flow";
import { SCAN_TYPES } from "components/Scans/Forms/Flow/constants";
import SchedulingList from "components/Scheduling/SecurityList";
import SingleRunSecurityScansList from "components/SingleRunSecurityScansList";
import { getAccountData } from "redux/_account/account.selectors";
import {
  createScheduler,
  listSchedulers,
} from "redux/_reports/reports.async.actions";
import { getSchedulers } from "redux/_reports/reports.selectors";
import useStyles from "./styles";

const isLoginKey = (key) => {
  return ["url", "user", "password"].includes(key);
};

const SchedulingPage = ({
  account,
  schedulers,
  createSchedule,
  fetchSchedulers,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [index, setIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [scanType, setScanType] = useState("assets");
  const [deviceType, setDeviceType] = useState("security");

  const classes = useStyles({ scanType });

  useEffect(() => {
    fetchSchedulers();
    // eslint-disable-next-line
  }, []);

  const setCurrentSchedule = (id) => {
    const current = schedulers.filter((scheduler) => scheduler.id === id)[0];
    const rrule = rrulestr(current.recurrence);
    const {
      options: { interval, freq, bymonthday, byweekday, until },
    } = rrule;
    setInitialValues({
      ...current,
      interval,
      freq,
      dayOfMonth: bymonthday[0],
      byweekday,
      until: String(until).substr(4, 11).replace(" 2", ", 2"),
    });
  };

  const onSubmit = (type) => (values) => {
    const data = {};
    Object.entries(values).forEach(([key, value]) => {
      if (isLoginKey(key)) {
        if (value) {
          data.login = data.login
            ? { ...data.login, [key]: value }
            : { [key]: value };
        }
      } else {
        data[key] = value;
      }
    });

    if (type === SCAN_TYPES.schedule) {
      return createSchedule(data);
    }
  };

  return (
    <PageReportsWrapper
      title="Security Scanner"
      containerCustomClasses={classes}
      selected={selectedTab}
      setSelected={setSelectedTab}
      subpages={[
        {
          option: "Reports",
          title: "List of your reports",
          Component: () => (
            <ReportList
              account={account}
              showTitle={false}
              className={classes.reports}
              deviceType={"security"}
            />
          ),
          extra: (
            <Button
              className={classes.menuButton}
              aria-controls="scan-menu"
              aria-haspopup="true"
              onClick={() => setSelectedTab(1)}
              size="small"
            >
              Create a New Scan
            </Button>
          ),
        },
        {
          option: "New Scan",
          Component: ScanFlowForm,
          props: {
            initialValues,
            onSubmit,
            account,
            index,
            setIndex,
            scanType,
            setScanType,
            deviceType,
            setDeviceType,
          },
        },
        {
          sectionToMove: 1,
          option: "Scheduled Scans",
          title: "List of Scheduled Scans",
          description: "Here are the last scans you scheduled.",
          Component: SchedulingList,
          props: {
            selectSchedule: setCurrentSchedule,
            setIndex,
          },
        },
        {
          sectionToMove: 1,
          option: "Single-run Scans",
          title: "List of Single-run Scans",
          description:
            "Here are the latest security scans you've run only once.",
          Component: SingleRunSecurityScansList,
          props: {},
        },
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  account: getAccountData(state),
  schedulers: getSchedulers(state),
});

const mapDispatchToProps = (dispatch) => ({
  createSchedule: (data) => dispatch(createScheduler(data)),
  fetchSchedulers: (filters) => dispatch(listSchedulers(filters)),
});

const ConnectedSchedulingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulingPage);
export default withRouter(ConnectedSchedulingPage);
