import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  permissions: {
    margin: "40px",
    "&.-logged": {
      margin: "90px 40px",
    },
  },
  container: {
    margin: theme.spacing(6, 0),
    height: "310px",
  },
  tableContainer: {
    margin: theme.spacing(2, 0),
    width: "100%",
    height: "540px",
  },
  table: {
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tr: {
    display: "flex",
    color: "#686C78",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "40px",
    textTransform: "uppercase",
    borderBottom: "1px solid #E0E1E3",
  },
  row: {
    padding: "10px 0",
  },
  permission: {
    width: "80%",
    "&.no-transform": {
      "& > span": {
        marginLeft: "8px",
      },
      textTransform: "none",
    },
  },
  protectionLevel: {
    width: "20%",
    "& > span": {
      marginLeft: "5px",
    },
  },
}));

export default styles;
