import React from "react";

const Arrows = ({ onClickBack, onClickNext, y, disableBack, disableNext }) => {
  return (
    <g transform="translate(0 -10)">
      <g
        onClick={disableBack ? undefined : onClickBack}
        transform={`translate(0 ${y})`}
        style={{ cursor: "pointer" }}
      >
        <rect width="16" height="16" fill="white" />
        <path
          fill="#868993"
          d="M15.1083 18.7748L8.22945 12.0424C7.92352 11.7426 7.92352 11.2572 8.22945 10.9577L15.1083 4.22456C15.4143 3.92515 15.9103 3.92515 16.2163 4.22456L16.7703 4.76686C17.0766 5.06627 17.0766 5.55219 16.7703 5.8518L10.9996 11.5L16.7703 17.1484C17.0766 17.4477 17.0766 17.9334 16.7703 18.2329L16.2163 18.7752C15.9099 19.075 15.4143 19.075 15.1083 18.7748Z"
          opacity={disableBack ? 0.5 : 1}
        />
      </g>
      <g
        onClick={disableNext ? undefined : onClickNext}
        transform={`translate(20 ${y})`}
        style={{ cursor: "pointer" }}
      >
        <rect width="16" height="16" fill="white" />
        <path
          fill="#868993"
          opacity={disableNext ? 0.5 : 1}
          d="M8.89173 4.22516L15.7705 10.9576C16.0765 11.2574 16.0765 11.7428 15.7705 12.0423L8.89173 18.7754C8.58569 19.0749 8.08975 19.0749 7.78371 18.7754L7.22968 18.2331C6.92344 17.9337 6.92344 17.4478 7.22968 17.1482L13.0004 11.5L7.22968 5.85161C6.92344 5.55225 6.92344 5.06657 7.22968 4.76706L7.78371 4.22477C8.0901 3.92501 8.58569 3.92501 8.89173 4.22516Z"
        />
      </g>
    </g>
  );
};

export default Arrows;
