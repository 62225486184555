import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_patterns from "@amcharts/amcharts4/themes/patterns";
import { makeStyles, Typography } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

import InfoIconButton from "components/InfoIconButton";

import useParentStyles from "./styles.js";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #B0BAC9",
    "&:not(&:last-child)": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: 0,
      maxHeight: theme.spacing(16),
      border: "none",
      "&:not(&:last-child)": {
        marginBottom: 0,
      },
    },
  },
  data: {
    padding: theme.spacing(2),
    verticalAlign: "baseline",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "26px",
    color: theme.palette.grey[700],
    borderBottom: "1px solid #E0E1E3",
    overflowY: "auto",
    wordBreak: "break-word",
    "&:last-child": {
      paddingRight: 0,
    },
    "& > ul": {
      listStyleType: "none",
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  domainInfo: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
  },
  popoverInfo: {
    width: "677px",
    "& .title": {
      padding: "20px",
      borderBottom: "solid 1px #DFE2E9",
      fontFamily: "Inter",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: 0.25,
      color: theme.palette.blue[500],
    },
    "& tspan": {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  },
  cookieName: {
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[900],
  },
  otherData: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "26px",
    color: theme.palette.grey[800],
    "& strong": {
      fontWeight: 600,
    },
  },
  tag: {
    border: "1px solid",
    borderColor: theme.palette.blue[400],
    margin: "2px 5px",
    padding: "2px 8px",
    display: "inline-block",
    borderRadius: "20px",
    fontSize: 12,
    cursor: "pointer",
    "&.-selected": {
      backgroundColor: theme.palette.blue[400],
      color: "white",
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkItem: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
}));

const TEST_DATA = [
  {
    concern: "Data breaches in last 12 months",
    points: 100,
  },
  {
    concern: "Security Certifications",
    points: 100,
  },
  {
    concern: "IP Reputation",
    points: 100,
  },
  {
    concern: "Dns Health Check",
    points: 100,
  },
  {
    concern: "Open Ports",
    points: 100,
  },
];

/*
 * @param {
 *   cdn: {boolean},
 *   dataBreaches: {boolean},
 *   dnsErrors: {string},
 *   domainReputation: {
 *     isValid: {boolean},
 *     threat: {boolean}
 *   },
 *   openPorts: {
 *     count: {number},
 *     ports: {array}
 *   },
 *   validCertificates: {boolean}
 * } data
 **/
export const parseData = (data) => {
  return !!data
    ? [
        {
          concern: "Data breaches in last 12 months",
          points: data?.dataBreaches ? 100 : 0,
        },
        {
          concern: "Security Certifications",
          points: data?.validCertificates ? 100 : 0,
        },
        {
          concern: "IP Reputation",
          points: data?.domainReputation?.isValid ? 100 : 0,
        },
        {
          concern: "Dns Health Check",
          points: data?.dnsErrors === "" ? 100 : 0,
        },
        {
          concern: "Open Ports",
          points: data?.openPorts?.count > 0 ? 100 : 0,
        },
      ]
    : undefined;
};

const createGrid = (valueAxis, value, label) => {
  const range = valueAxis.axisRanges.create();
  range.value = value;
  range.label.text = label;
};

export const OwnerInfo = ({
  classes,
  selected,
  owner,
  data,
  handleClick,
  handleClose,
}) => {
  const open = selected === owner;

  const chartData = useMemo(() => parseData(data), [data]);

  const onEntered = () => {
    am4core.useTheme(am4themes_patterns);
    const chart = am4core.create("domainchartdiv", am4charts.RadarChart);
    chart.data = chartData || TEST_DATA;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "concern";

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;

    const valueAxisRenderer = valueAxis.renderer;
    valueAxisRenderer.grid.template.disabled = true;
    valueAxisRenderer.labels.template.disabled = true;
    createGrid(valueAxis, 0, "No");
    createGrid(valueAxis, 100, "Yes");

    const series = chart.series.push(new am4charts.RadarSeries());
    series.fill = chart.colors.getIndex(0);
    series.fillOpacity = 0.2;
    series.yAxis.renderer.gridType = "polygons";
    series.dataFields.valueY = "points";
    series.dataFields.categoryX = "concern";
    series.name = "Third-party assessment";
  };

  return (
    <div className={classes.domainInfo}>
      <Typography>{owner}</Typography>
      {chartData && (
        <InfoIconButton
          id={owner}
          onClick={handleClick}
          className={classes.infoIcon}
        />
      )}
      <Popover
        id={owner}
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 240, left: 650 }}
        onEntered={onEntered}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popoverInfo}>
          <Typography className="title">Third-Party Assessment</Typography>
          <div
            id="domainchartdiv"
            style={{ width: "100%", height: "390px", margin: "0 auto" }}
          />
        </div>
      </Popover>
    </div>
  );
};

const Tracker = ({
  owner,
  domain,
  categories,
  trackers = [],
  environment,
  filtered,
  handleCategoryClick,
  domainMetadata,
  reportId,
}) => {
  const parentClasses = useParentStyles();
  const classes = useStyles();
  const [selected, setSelected] = useState();

  const handleClick = (event) => {
    setSelected(event.currentTarget.id);
  };

  const handleClose = (event) => {
    setSelected(null);
  };

  // eslint-disable-next-line no-useless-escape
  const mappedTrackers = [...new Set(trackers.map((t) => t.replace(/(\?|\=).*/g, "")))];

  return (
    <tr className={classes.row}>
      <td
        className={clsx(
          "scrollable-element",
          parentClasses.domain,
          classes.data,
          environment
        )}
      >
        <Typography className={classes.cookieName}>{domain}</Typography>
      </td>
      <td
        className={clsx(
          "scrollable-element",
          parentClasses.provider,
          classes.data,
          environment
        )}
      >
        <OwnerInfo
          selected={selected}
          data={domainMetadata?.results}
          classes={classes}
          owner={owner}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      </td>
      <td
        className={clsx(
          "scrollable-element",
          parentClasses.categories,
          classes.data,
          environment
        )}
      >
        {(categories || []).map((category, index) => (
          <span
            className={clsx(
              classes.tag,
              filtered.includes(category) && "-selected"
            )}
            key={index}
            onClick={handleCategoryClick}
          >
            {category}
          </span>
        ))}
      </td>

      {environment === "web" && (
        <td
          className={clsx(
            "scrollable-element",
            parentClasses.tags,
            classes.data
          )}
        >
          <div className={classes.linkContainer}>
            {mappedTrackers.map((tracker, i) => (
              <Link key={i} className={clsx(classes.link, classes.linkItem)} exact="true" target="_top" to={`/variable-inventory?r=${reportId}&host=${domain}&tracker=${tracker}&c=web`}>
                {tracker}
              </Link>
            ))}
          </div>
        </td>
      )}
    </tr>
  );
};

export default Tracker;
