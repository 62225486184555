import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  inviter: {
    backgroundColor: theme.palette.purple["700"],
    padding: theme.spacing(3, 0, 0),
  },

  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& p": {
      marginBottom: "6px",
    },
  },

  textField: {},
  inputBase: {
    borderRadius: 6,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 48,
    },
  },
  inputOutline: {
    borderRadius: 6,
    border: "none",
  },
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: 18,
      lineHeight: "22px",
      opacity: 0.6,
      color: theme.palette.blue[500],
    },
  },

  submit: {
    backgroundColor: theme.palette.aquamarine[100],
    border: "0 none",
    borderRadius: 6,
    color: theme.palette.blue["young"],
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: "relative",

    "&:hover": {
      backgroundColor: theme.palette.aquamarine[200],
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minWidth: 200,
    },
    [theme.breakpoints.down("sm")]: {
      height: 48,
      width: "100%",
    },
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "30px",
    letterSpacing: 1,
    color: "#FEFAF2",
    marginBottom: theme.spacing(3),
  },
}));
