import { makeStyles } from "@material-ui/core";

export default makeStyles((_) => ({
  loaderContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
