import { CONSTANTS } from "redux/_admin/_billing/billing.constants";

const initialState = {
  data: [],
  loading: false,
};

export const billing = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.ADMIN_BILLING_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.ADMIN_BILLING_REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.ADMIN_BILLING_SAVE_DATA:
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};
