import isNil from "lodash.isnil";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AddCircle } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import SvgIcon from "components/svg-icon/svg-icon";
import { getEnvironmentSettingsSelected } from "redux/_settings/settings.selectors";

import useStyles from "./styles";

const AddDialog = ({ children, options, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const envSelected = useSelector((state) =>
    getEnvironmentSettingsSelected(state)
  );
  const classes = useStyles({ envSelected });

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };
  const toggleSelectDatabase = (id) => {
    const index = selected.indexOf(id);
    if (index !== -1) {
      setSelected(selected.filter((dbId) => dbId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const onDialogSubmit = () => {
    if (onSubmit) onSubmit(selected);
    handleClose();
  };

  return (
    <>
      <Button
        disabled={isNil(envSelected)}
        onClick={handleClickOpen}
        classes={{ root: classes.addButton, label: classes.addButtonLabel }}
      >
        <AddCircle className={classes.addIcon} />
        {children}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
        classes={{ paperFullWidth: classes.dialogWrap }}
      >
        <DialogTitle
          disableTypography
          id="dialog-title"
          onClose={handleClose}
          className={classes.dialogTitleWrap}
        >
          <Typography variant="h6" className={classes.dialogTitle}>
            Select Database(s) to Add
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          {options.map(({ id, name, icon = "database" }, idx) => {
            const isSelected = selected.indexOf(id) !== -1;
            return (
              <Button
                className={`${classes.entityButton} ${
                  isSelected && classes.entityButtonSelected
                }`}
                classes={{
                  root: classes.entityButton,
                  label: classes.entityButtonLabel,
                }}
                key={idx}
                onClick={() => toggleSelectDatabase(id)}
              >
                <SvgIcon icon={icon} />
                <span className={classes.entityName}>{name}</span>
              </Button>
            );
          })}
        </MuiDialogContent>
        <MuiDialogActions className={classes.dialogFooter}>
          <Typography className={classes.actionText}>
            {selected.length}{" "}
            {selected.length === 1 ? "SELECTED DATABASE" : "SELECTED DATABASES"}
          </Typography>
          <Button
            disabled={selected.length === 0}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            onClick={onDialogSubmit}
          >
            ADD
          </Button>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

export default AddDialog;
