import { StyleSheet, Font } from "@react-pdf/renderer";
import Inter from "../Inter.otf";
import InterBold from "../Inter-Medium.ttf";
import Aldrich from "../Aldrich.ttf";

Font.register({
  family: "Aldrich",
  src: Aldrich,
});

Font.register({
  family: "{Inter}",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

export const styles = StyleSheet.create({
  providerColumn: {
    minWidth: "200px",
  },
  borderRight: {
    borderRight: "1px solid #dfe2e9",
  },
  ownerCellContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    minWidth: "200px",
    padding: 0,
    flexDirection: "column !important",
  },
  ownerCell: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  ownerCellValue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
    width: "100%",
  },
  table: {
    width: "100%",
    display: "flex",
    marginTop: 5,
  },
  fontSizeEight: {
    fontSize: 8,
  },
});

export default styles;
