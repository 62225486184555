import { makeStyles } from "@material-ui/core";
import PrincingBackgroud from "assets/illustrations/bg_pricinginapp.svg";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    display: "flex",
    flexWrap: "wrap",
    margin: "1.5rem 1rem",
    padding: "1rem 0",
  },
  textField: {
    backgroundColor: "#f6f9fe",
    borderRadius: "6px",
    height: "42px",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&>div": {
      //margin: '0',
      height: "42px",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: `${theme.palette.grey[200]}`,
      margin: "0.5rem 0 0",
    },
    "& input": {
      color: `${theme.palette.blue[600]}`,
      fontSize: "15px",
      padding: "0 1rem",
    },
    "& fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.blue[200]}`,
      color: `${theme.palette.blue[600]}`,
      height: "46px",
    },
    "& fieldset:hover": {
      border: `solid 1px ${theme.palette.blue[600]}`,
    },
    "& fieldset::placeholder": {
      //opacity: '0.6',
      //fontStyle: 'italic',
      // padding: '0',
      // maxWidth:'139px',
    },
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    top: "100px !important",
    width: 200,
    "& ul": {
      backgroundColor: "#f6f9fe",
    },
  },
  button: {
    //backgroundColor: `${theme.palette.blue[200]}`,
    borderRadius: "4px",
    margin: theme.spacing(1),
  },
  inputLabel: {
    color: `${theme.palette.blue[500]}`,
    display: "block",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  inputContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&>div": {
      margin: "0",
      marginTop: theme.spacing(1),
    },
  },
  inputUpload: {
    display: "none",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  profileHeader: {
    borderBottom: "1px solid #DDDFE7",
    height: "150px",
    overflow: "hidden",
    //marginBottom: theme.spacing(4),
  },
  userProfileWrapper: {
    display: "flex",
  },
  userProfile: {
    height: "150px",
    padding: theme.spacing(3, 4),
  },
  profileHeaderAvatar: {
    width: "100px",
    height: "100px",
    borderRadius: "100%",
    boxShadow: "0 10px 10px 0 rgba(8, 20, 78, 0.15)",
    backgroundColor: "#000",
    marginRight: theme.spacing(2.5),
  },
  userProfileInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(1, 0),
  },
  userProfileName: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "1",
    color: theme.palette.blue[700],
  },
  userProfilePlan: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1",
    color: theme.palette.green[200],
  },
  profileWarning: {
    backgroundColor: theme.palette.red[100],
    border: `1px solid ${theme.palette.red[500]}`,
    borderRadius: "4px",
    color: theme.palette.red[500],
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "1.2",
    padding: "0.25rem 0.35rem",
    textAlign: "center",
  },
  inAppPricing: {
    backgroundColor: theme.palette.primary["main"],
    backgroundImage: `url(${PrincingBackgroud})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1rem 150px 1rem 2rem",
    textAlign: "center",
    width: "100%",
    height: "150px",
    marginBottom: theme.spacing(2),
    "&>h4": {
      fontSize: "17px",
      fontWeight: "600",
      lineHeight: "1.44",
      letterSpacing: "0",
      color: "white",
      margin: "0 0 12px",
    },
  },
  seePlansBT: {
    backgroundColor: "white",
    color: `${theme.palette.blue["young"]}`,
    fontWeight: "600",
    maxWidth: "130px",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: `${theme.palette.blue["100"]}`,
      color: `${theme.palette.blue[500]}`,
    },
  },
  sectionContainer: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(3),
  },
  leftContainer: {
    width: "30%",
    paddingRight: theme.spacing(4),
    borderRight: "1px solid #E0E0E0",
  },
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    lineHeight: "40px",
    color: theme.palette.blue[500],
  },
  subtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    color: "#828282",
  },
  rightContainer: {
    width: "70%",
    maxWidth: "520px",
    paddingLeft: theme.spacing(4),
  },
  helperButton: {
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.blue["young"],
    textTransform: "none",
    textDecoration: "underline",
    padding: 0,
    marginTop: 4,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));
