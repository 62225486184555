export default {
  ENVIRONMENTS_SELECT_ENV: "ENVIRONMENTS_SELECT_ENV",
  ENVIRONMENTS_CLEAR_ENV: "ENVIRONMENTS_CLEAR_ENV",
  ENVIRONMENTS_GET_ALL_REQUEST_START: "ENVIRONMENTS_GET_ALL_REQUEST_START",
  ENVIRONMENTS_GET_ALL_REQUEST_END: "ENVIRONMENTS_GET_ALL_REQUEST_END",
  ENVIRONMENTS_SAVE_DATA: "ENVIRONMENTS_SAVE_DATA",
  ENVIRONMENTS_SET_IS_SCANNING: "ENVIRONMENTS_SET_IS_SCANNING",
  ENVIRONMENTS_UPDATE_ENV: "ENVIRONMENTS_UPDATE_ENV",
  ENVIRONMENTS_DELETE_ENV: "ENVIRONMENTS_DELETE_ENV",
};
