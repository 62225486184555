import React from "react";
import clsx from "clsx";
import useStyles from "./styles";
import { TextField } from "@material-ui/core";

function TimeInput(props) {
  const {
    label,
    helperText,
    className = null,
    style = {},
    value,
    onChange,
    error,
    disabled,
  } = props;
  const classes = useStyles({ error });
  return (
    <label className={clsx(classes.inputContainer, className)} style={style}>
      <span className={classes.inputLabel}>{label}</span>
      <TextField
        className={classes.textField}
        type="time"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        error={error}
        disabled={disabled}
      />
      {helperText && <label className={classes.helperText}>{helperText}</label>}
    </label>
  );
}

export default TimeInput;
