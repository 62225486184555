import CONSTANTS from "./account.constants";

const initialState = {
  loading: false,
  data: {},
};

export const account = (state = initialState, { type, data }) => {
  switch (type) {
    case CONSTANTS.REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.REQUEST_END:
      return {
        ...state,
        loading: false,
      };
    case CONSTANTS.SET_ACCOUNT_DATA:
      return {
        ...state,
        data,
      };
    case CONSTANTS.SET_COMPANY_DATA:
      return {
        ...state,
        data: { ...state.data, company: data },
      };
    case CONSTANTS.SET_PAYMENT_METHOD: {
      const payment_method = data
        ? {
            ...state.data.payment_method,
            ...data,
          }
        : data;

      return {
        ...state,
        data: {
          ...state.data,
          payment_method,
        },
      };
    }
    default:
      return state;
  }
};
