import React from "react";

const ConsentsReport = () => {
  return (
    <svg
      width={57}
      height={49}
      viewBox="0 0 57 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.504 5.26854C36.1013 1.79611 30.5945 -0.0666837 24.9347 0.00182441H24.9109C19.9813 0.00641349 15.1637 1.43714 11.0669 4.11322C6.97005 6.78931 3.7778 10.5907 1.8935 15.0369C0.00920439 19.4832 -0.482586 24.3749 0.480289 29.0939C1.44315 33.8129 3.81746 38.1474 7.30321 41.5498C10.7889 44.9522 15.2297 47.2697 20.0643 48.2095C24.899 49.1494 29.9105 48.6693 34.4658 46.8301C39.021 44.9909 42.9155 41.875 45.6572 37.8761C48.3988 33.8773 49.8646 29.175 49.8693 24.3633C49.8785 24.0609 49.8626 23.7583 49.8218 23.4584C49.722 22.9448 49.4243 22.4881 48.9898 22.1824C48.5439 21.8874 48.0022 21.7635 47.4685 21.8343C47.2064 21.8783 46.9558 21.973 46.7317 22.1127C46.4963 22.2465 46.2935 22.4285 46.1374 22.6464C45.9793 22.8625 45.8664 23.107 45.8052 23.3657C45.744 23.6245 45.7358 23.8926 45.7809 24.1545V24.3633C45.7809 28.3922 44.5569 32.3307 42.2636 35.6807C39.9704 39.0307 36.711 41.6416 32.8975 43.1834C29.084 44.7253 24.8877 45.1287 20.8393 44.3427C16.791 43.5567 13.0723 41.6165 10.1536 38.7676C7.23485 35.9187 5.24718 32.289 4.4419 28.3374C3.63663 24.3859 4.04993 20.29 5.62952 16.5677C7.20913 12.8454 9.88408 9.66394 13.3161 7.42556C16.7482 5.18719 20.7832 3.99246 24.9109 3.99246C29.6702 3.92789 34.3001 5.50549 37.9843 8.44713C38.1947 8.61544 38.437 8.74159 38.6974 8.81835C38.9649 8.86338 39.2382 8.86338 39.5056 8.81835C39.7716 8.78714 40.0294 8.70849 40.2663 8.58634C40.5083 8.46271 40.7193 8.28841 40.8843 8.07591C41.0508 7.86678 41.1795 7.63137 41.2646 7.37986C41.3124 7.12678 41.3124 6.8673 41.2646 6.61422C41.2217 6.35703 41.1416 6.10707 41.0269 5.87177C40.8926 5.64068 40.7152 5.4361 40.504 5.26854Z"
        fill="#122873"
      />
      <path
        d="M54.1935 2.48335C54.4381 2.36935 54.7069 2.31368 54.9779 2.32094C55.2409 2.31647 55.5014 2.37208 55.7385 2.48335C55.9896 2.57214 56.2171 2.71486 56.4041 2.90098C56.5897 3.0875 56.7351 3.30838 56.8319 3.55061C56.9462 3.79019 57.0031 4.05202 56.9983 4.31626C57.0007 4.58027 56.9439 4.84159 56.8319 5.08191C56.7351 5.32414 56.5897 5.54503 56.4041 5.73155L27.3572 34.2228C27.1698 34.4035 26.9529 34.5525 26.7154 34.6637C26.4639 34.7529 26.1987 34.7999 25.931 34.8029C25.6708 34.8036 25.4127 34.7564 25.1704 34.6637C24.926 34.5521 24.7013 34.4033 24.5048 34.2228L14.8067 24.7335C14.6021 24.5524 14.4373 24.3328 14.3221 24.0878C14.2069 23.8429 14.1438 23.5777 14.1365 23.3082C14.1292 23.0388 14.1779 22.7707 14.2796 22.5201C14.3814 22.2696 14.5341 22.0417 14.7285 21.8504C14.923 21.659 15.1552 21.5081 15.411 21.4067C15.6669 21.3053 15.9411 21.2555 16.2172 21.2605C16.4933 21.2654 16.7655 21.3248 17.0174 21.4352C17.2693 21.5457 17.4957 21.7048 17.6829 21.9029L25.931 30.0466L53.5279 2.90098C53.719 2.71983 53.9453 2.57783 54.1935 2.48335Z"
        fill="#122873"
      />
    </svg>
  );
};

export default ConsentsReport;
