import { makeStyles } from "@material-ui/core";

// @note {1} These styles are used for several components in this directory.
// TODO (optional): refactor components to reutilize markup alongside styles.
export default makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginBottom: theme.spacing(4),
    color: "white",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: theme.palette.common.white,
  },
  actionLink: {
    color: theme.palette.blue.main,
    fontSize: theme.typography.htmlFontSize,
    marginBottom: theme.spacing(2),
  },
  actionLinkAnchor: {
    color: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
  actionLinkIcon: {
    color: theme.palette.blue.main,
    marginLeft: theme.spacing(1),
  },
}));
