import { makeStyles } from "@material-ui/core";

const drawerWidth = 223;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    //display: "none",
  },
  drawerOpen: {
    overflowX: "hidden",
    width: drawerWidth,
    border: "none",
    backgroundColor: theme.palette.blue[500],
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 400,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: 400,
    }),
    backgroundColor: theme.palette.blue[500],
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem 0.5rem",
    transform: "translateX(-5px) translateY(5px)",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    "&>svg": {
      padding: "0",
      maxWidth: "139px",
    },
  },
  fabContainer: {
    margin: "16px auto",
  },
  fab: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.blue[500],
    boxShadow: `0 2px 4px ${theme.palette.blue[600]}`,
    maxWidth: "172px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: theme.palette.blue[300],
    },
  },
  fabClosed: {
    padding: "0",
  },
  fabOpened: {
    padding: `0 ${theme.spacing(3)}px !important`,
    borderRadius: 40,
    fontSize: "14px",
    height: "40px",
    width: "188px!important",
    maxWidth: "unset",
    transition:
      "border-radius 156ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 156ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  fabExpanded: {
    height: "91px!important",
    borderRadius: "10px!important",
  },
  selectContainer: {
    backgroundColor: theme.palette.blue[600],
    borderRadius: "4px",
    margin: "1rem 0",
    maxWidth: "172px",
    width: "100%",
    "&:focus": {
      outline: theme.palette.blue[100],
    },
  },
  formControl: {
    backgroundColor: theme.palette.blue[600],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
  },
  selectTitle: {
    padding: `10px ${theme.spacing(2)}px`,
    lineHeight: 1.5,
    letterSpacing: "0.5px",
    color: theme.palette.blue[100],
    "&.Mui-focused": {
      color: "#fff",
    },
  },
  selectText: {
    color: `${theme.palette.primary.contrastText} !important`,
    fontSize: "1.125rem",
    fontWeight: "600",
    fontStyle: "italic",
    lineHeight: "1",
    letterSpacing: "1px",
    padding: `10px 30px 10px 12px`,
    "&>div": {
      padding: "10px 0 0",
    },
    "&>svg": {
      color: "inherit",
      right: "4px",
    },
    "&:before": {
      content: "none",
    },
    "&:after": {
      content: "none",
    },
  },
  selectMenu: {
    backgroundColor: theme.palette.blue[600],
    left: "195px !important",
    maxWidth: "220px",
    top: "150px !important",
  },
  selectMenuItem: {
    color: "white",
    fontSize: "0.9375rem",
    minHeight: "40px",
    paddingTop: "0",
    paddingBottom: "0",
    "&.Mui-selected": {
      backgroundColor: theme.palette.blue[800],
      fontWeight: 500,
    },
  },
  listContainer: {
    width: "100%",
    marginTop: theme.spacing(2),
    padding: 0,
  },
  activeButton: {
    backgroundColor: "green",
  },
  listItem: {
    color: theme.palette.blue[100],
    width: "100%",
    padding: "6px 12px",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(18, 40, 115, 0.3)",
      color: "white",
    },
    "&.-reports": {
      color: "white",
      "&:hover, &.-active": {
        color: theme.palette.aquamarine[100],
        borderLeft: "solid",
        "&>.-reports": {
          color: theme.palette.aquamarine[100],
        },
      },
    },
  },
  listItemText: {
    "&>span": {
      color: "#fff",
      fontSize: "0.9375rem",
      lineHeight: "24px",
    },
  },
  listItemTextUser: {
    margin: "0",
    transform: "translateY(-10px)",
    "&>span": {
      fontSize: "0.9375rem",
      lineHeight: "24px",
    },
  },
  listItemLogout: {
    color: theme.palette.blue[200],
    display: "block",
    position: "absolute",
    left: "68px",
    bottom: "18px",
    letterSpacing: 0,
    transition: "all 0.15s ease-in-out",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    fontSize: "14px",
    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    },
    "&>div": {
      margin: "0",
      "&>span": {
        fontSize: "0.75rem",
        lineHeight: "1",
      },
    },
  },
  subItem: {
    paddingLeft: "68px",
    fontSize: "14px",
    color: theme.palette.blue[200],
  },
  listItemSelected: {
    backgroundColor: "rgba(18, 40, 115, 0.6)",
    borderLeft: "1px solid white",
  },
  listItemIcon: {
    width: "48px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    "&.-reports": {
      color: "white",
    },
  },
  menu: {
    backgroundColor: "#1a3586",
    left: "75px !important",
  },
  menuItem: {
    fontSize: "0.9375rem",
    lineHeight: "24px",
    fontWeight: 400,
  },
  selectedItem: {
    backgroundColor: "rgba(18, 40, 115, 0.6)",
    color: "white",
    borderLeft: "1px solid white",
  },
  divider: {
    width: 40,
    backgroundColor: theme.palette.blue["young"],
    marginLeft: 20,
    marginTop: 6,
    marginBottom: 6,
    "&.-full": {
      width: "100%",
      marginLeft: 0,
    },
  },
  sidebarFooter: {
    borderTop: `1px solid ${theme.palette.blue.young}`,
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  logoutButton: {
    display: "flex",
    backgroundColor: theme.palette.primary.semidark,
    padding: theme.spacing(2, 1.5),
    border: "none",
    width: 220,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  logoutButtonIcon: {
    width: 56,
    textAlign: "center",
  },
}));
