import cloneDeep from "lodash.clonedeep";

/**
 * Receives an entity with PII groups and categories as children and group it by this entity
 */
export default (data) => {
  if (!data || !(data.children || data.childrenData)) return;
  const children = data.children
    ? cloneDeep(data.children)
    : cloneDeep(data.childrenData);
  return children.reduce((prev, current) => {
    if (!current || !current.children) return prev;
    current.children.forEach((elem) => {
      const elemIndex = prev.findIndex(
        (prevElem) => prevElem.name === elem.name
      );
      if (elemIndex !== -1) {
        prev[elemIndex].matches += elem.matches;
        if (elem.children && elem.children.length) {
          elem.children.forEach((elemChild) => {
            const catIndex = prev[elemIndex].children
              ? prev[elemIndex].children.findIndex(
                  (cat) => cat.name === elemChild.name
                )
              : -1;
            if (catIndex !== -1) {
              prev[elemIndex].children[catIndex].matches += elemChild.matches;
            } else {
              prev[elemIndex].children = [elemChild];
            }
          });
        }
      } else {
        prev.push({
          name: elem.name,
          matches: elem.matches,
          children: elem.children,
        });
      }
    });
    return prev;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
