import React, { useEffect, useMemo, useState } from "react";

import { Typography, Box, Select, Input, MenuItem } from "@material-ui/core";

import Pagination from "components/Pagination";
import usePaginationStyles from "components/Pagination/styles";
import CookieCategory from "components/PrivacyReports/Report/components/CookieCategory";
import Issue from "components/PrivacyReports/Report/components/Issue";

import useStyles from "./styles";
import { Tabs } from "pages/SecurityReport/common";

const MAX_CATEGORIES_PER_PAGE = 3;
const DEFAULT_COOKIES_CATEGORY_OPTION = "All Categories";

const CookieCount = ({ count, loading, records, report, email }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [categoriesManager, setCategoriesManager] = useState({});
  const [selectedCookiesCategories, setSelectedCookiesCategories] = useState([
    DEFAULT_COOKIES_CATEGORY_OPTION,
  ]);

  const filteredRecords = useMemo(() => {
    if (selectedCookiesCategories.includes(DEFAULT_COOKIES_CATEGORY_OPTION)) {
      return records;
    }

    const nextValues = [];
    records.forEach((record) => {
      const nextCookies = record.cookies.filter((item) =>
        selectedCookiesCategories.includes(item.subCategory)
      );
      nextValues.push({ ...record, cookies: nextCookies });
    });
    return nextValues;
  }, [records, selectedCookiesCategories]);

  const preConsentCount = useMemo(
    () =>
      (filteredRecords || []).reduce(
        (accumulator, record) =>
          accumulator +
          record.cookies.reduce(
            (cookieAccumulator, cookie) =>
              cookieAccumulator +
              (cookie.preConsent || cookie.preConsent === undefined ? 1 : 0),
            0
          ),
        0
      ),
    [filteredRecords]
  );

  const cookiesCategories = useMemo(() => {
    let myArray = [];
    (records || []).forEach((r) => {
      myArray = [...myArray, ...(r?.cookies.map((c) => c.subCategory) || [])];
    });

    return [DEFAULT_COOKIES_CATEGORY_OPTION, ...new Set(myArray)] || [];
  }, [records]);

  const postConsentCount = useMemo(
    () =>
      (filteredRecords || []).reduce(
        (accumulator, record) =>
          accumulator +
          record.cookies.reduce(
            (cookieAccumulator, cookie) =>
              cookieAccumulator +
              (cookie.preConsent !== undefined && !cookie.preConsent ? 1 : 0),
            0
          ),
        0
      ),
    [filteredRecords]
  );

  useEffect(() => {
    if (records.length) {
      setCategoriesManager({
        ...records.reduce((control, currentValue) => {
          const key = (currentValue.category || "Unclassified").replace(
            " ",
            ""
          );
          control[key] = false;

          return control;
        }, {}),
      });
    }
  }, [records]);

  const setCurrentCategory = (element) => (event, expanded) => {
    const previous = Object.keys(categoriesManager).find(
      (key) => categoriesManager[key]
    );
    if (previous !== undefined) {
      categoriesManager[previous] = false;
    }
    if (expanded) {
      categoriesManager[element] = true;
    }
    setCategoriesManager({ ...categoriesManager });
  };

  const handleChangeCookiesCategoriesFilter = (event) => {
    setSelectedCookiesCategories([]);
    const {
      target: { value },
    } = event;
    if (
      value.length === 0 ||
      (!value.includes(DEFAULT_COOKIES_CATEGORY_OPTION) &&
        value.length === cookiesCategories.length - 1)
    ) {
      setSelectedCookiesCategories([DEFAULT_COOKIES_CATEGORY_OPTION]);
      return;
    }
    if (value[value.length - 1] !== DEFAULT_COOKIES_CATEGORY_OPTION) {
      const newValues = value.filter(
        (filterValue) => filterValue !== DEFAULT_COOKIES_CATEGORY_OPTION
      );
      setSelectedCookiesCategories(
        typeof value === "string" ? newValues.split(",") : newValues
      );
      return;
    }
    setSelectedCookiesCategories([DEFAULT_COOKIES_CATEGORY_OPTION]);
  };

  const total = Math.ceil(records.length / MAX_CATEGORIES_PER_PAGE);
  const start = currentPage * MAX_CATEGORIES_PER_PAGE;
  const end = start + MAX_CATEGORIES_PER_PAGE;

  const paginationClasses = usePaginationStyles({ marginTop: 1 });

  return (
    <div className={classes.cookieCount}>
      <Tabs
        id={`cookies-consent-tabs`}
        tabs={[
          {
            id: "preConsent",
            label: `PRE CONSENT (${preConsentCount})`,
            hide: false,
            content: (
              <>
                {!!count && (
                  <>
                    <Issue
                      text="Cookies were set without user Consent."
                      anchorText="Learn more about this and how to fix it"
                      link="https://www.privacypolicies.com/blog/cookie-consent-examples/"
                      target="_blank"
                    />
                  </>
                )}
                {filteredRecords && filteredRecords.length > 0
                  ? filteredRecords
                      .slice(start, end)
                      .map((record, i) => (
                        <CookieCategory
                          key={record.category || "Unclassified"}
                          title={record.category || "Unclassified"}
                          description={record.description}
                          cookies={record.cookies.filter(
                            (cookie) =>
                              cookie.preConsent ||
                              cookie.preConsent === undefined
                          )}
                          onChange={setCurrentCategory}
                          expanded={
                            categoriesManager[
                              (record.category || "Unclassified").replace(
                                " ",
                                ""
                              )
                            ]
                          }
                        />
                      ))
                  : report.success && (
                      <Box textAlign="center" py={6}>
                        <Typography variant="h6" component="h3" gutterBottom>
                          No cookies detected
                        </Typography>
                      </Box>
                    )}
              </>
            ),
          },
          {
            id: "postConsent",
            label: `POST CONSENT (${postConsentCount})`,
            hide: false,
            content:
              filteredRecords && filteredRecords.length > 0
                ? filteredRecords
                    .slice(start, end)
                    .map((record, i) => (
                      <CookieCategory
                        key={record.category || "Unclassified"}
                        title={record.category || "Unclassified"}
                        description={record.description}
                        cookies={record.cookies.filter(
                          (cookie) =>
                            cookie.preConsent !== undefined &&
                            !cookie.preConsent
                        )}
                        onChange={setCurrentCategory}
                        expanded={
                          categoriesManager[
                            (record.category || "Unclassified").replace(" ", "")
                          ]
                        }
                      />
                    ))
                : report.success && (
                    <Box textAlign="center" py={6}>
                      <Typography variant="h6" component="h3" gutterBottom>
                        No cookies detected
                      </Typography>
                    </Box>
                  ),
          },
        ]}
      >
        <div
          style={{
            display: "flex",
            flex: "1 0 0",
            alignItems: "center",
            justifyContent: "right",
            marginRight: 10,
          }}
        >
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            multiple
            value={selectedCookiesCategories}
            onChange={handleChangeCookiesCategoriesFilter}
            input={<Input />}
            MenuProps={{
              PaperProps: {
                style: {
                  width: 250,
                },
              },
            }}
          >
            {cookiesCategories.map((name) => (
              <MenuItem key={name} value={name} className="scrollable-element">
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Tabs>

      {end > 1 && (
        <Pagination
          className={paginationClasses.pagination}
          current={currentPage}
          total={total}
          setCurrent={setCurrentPage}
        />
      )}
    </div>
  );
};

export default CookieCount;
