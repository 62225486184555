import { makeStyles } from "@material-ui/core";

import IconFacebook from "assets/svgs-icons/icon-fb.svg";
import IconTwitter from "assets/svgs-icons/icon-twitter.svg";
import IconLinkedin from "assets/svgs-icons/icon-linkedin.svg";

export const useStyles = makeStyles((theme) => ({
  loginIcon: {
    marginRight: theme.spacing(1),
  },
  navLinks: {
    textAlign: "center",
    "& > a": {
      color: "#fff",
      display: "block",
      marginBottom: theme.spacing(4),
    },
  },
  close: {
    color: "37DCCE",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    "& ul": {
      listStyle: "none",
      margin: "0 0 2rem",
      padding: "0",
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      marginTop: "20px",
    },
  },
  socialIco: {
    textAlign: "left",
    "& i": {
      display: "inline-block",
      height: "24px",
      width: "24px",
      opacity: "0.7",
      textIndent: "-9999px",
      transition: "all 0.15s ease-in-out",
    },
    "& i:hover": {
      opacity: "1",
    },
  },
  icoTwitter: {
    background: `url(${IconTwitter}) no-repeat center center`,
  },
  icoFacebook: {
    background: `url(${IconFacebook}) no-repeat center center`,
  },
  icoLinkedin: {
    background: `url(${IconLinkedin}) no-repeat center center`,
  },
  accordion: {
    backgroundColor: "transparent",
    color: "white",
    "&:before": {
      display: "none",
    },
  },
  accordionTitle: {
    display: "inline-flex",
  },
  accordionBody: {
    display: "flex",
    flexDirection: "column",
    "& a": {
      color: "white",
      padding: "12px 0",
    },
  },
  expandIcon: {
    position: "absolute",
    fill: "white",
  },
  tryLink: {
    marginTop: "32px",
  },
}));

export const menuStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "20px",
    right: "36px",
    top: "44px",
  },
  bmBurgerBars: {
    background: "#fff",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    right: "16px",
    height: "32px",
    width: "32px",
  },
  bmCross: {
    background: "#fff",
    height: "32px",
  },
  bmMenuWrap: {
    width: "230px",
    position: "fixed",
    height: "100vh",
  },
  bmMenu: {
    background: "#122873",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    overflow: "hidden",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    listStyle: "none",
    padding: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bmItem: {
    color: "#fff",
    fontSize: "1.0625rem",
    letterSpacing: "1px",
    listStyle: "none",
    margin: "1.5rem 0 0",
    padding: "0",
    "& > .button": {
      padding: "14px !important",
    },
  },
  bmOverlay: {
    background: "rgba(18, 40, 115, 0.65)",
  },
};
