import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card as MuiCard,
  CardMedia,
  CardContent,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { AccountTree, Smartphone, Web, Security } from "@material-ui/icons";

import Dialog from "components/Dialog";

import useStyles from "./styles";
import cyberSecurity from "assets/images/cyber-security.jpg";

import { useDispatch } from "react-redux";

const Card = ({
  image,
  href,
  pdf,
  document,
  name,
  title,
  description,
  date,
  target = "_self",
  related,
  size,
  smallContent,
  isSitemap = false,
  isApp = false,
  user,
  deleteItem,
  flavor,
  deviceType,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [reportResult, setReportResult] = useState(null);
  const [reportLabel, setReportLabel] = useState("See Results");

  const { content, dialog, ...classes } = useStyles({ related, size });
  const cardImage = !!image ? image : cyberSecurity;
  let type = isSitemap ? (
    <>
      <AccountTree /> Sitemap Analysis
    </>
  ) : deviceType === "web" ? (
    <>
      <Web /> Single page
    </>
  ) : deviceType === "mobile" ? (
    <>
      <Smartphone />
      App
    </>
  ) : (
    <>
      <Security />
      Security
    </>
  );

  const handleClose = (shouldRemove) => () => {
    if (shouldRemove) {
      deleteItem();
    }

    setOpen(false);
  };

  const postCard = flavor === "article";

  useEffect(() => {
    dispatch({ type: "ADD_HREF", payload: href });
  }, [dispatch, href]);

  useEffect(() => {
    if (
      deviceType === "web" ||
      deviceType === "mobile" ||
      deviceType === "security"
    ) {
      setReportResult(href);
      setReportLabel("See results");
    } else {
      setReportResult(document);
      setReportLabel("View Report");
    }
  }, [deviceType, document, href, pdf]);

  return (
    <MuiCard className={classes.card} elevation={0}>
      <a href={reportResult} target={target}>
        <CardMedia className={classes.media} image={cardImage} />
      </a>
      {!!title && (
        <CardContent
          className={!smallContent ? classes.cardContent : classes.smallContent}
        >
          {!postCard && (
            <Typography className={classes.type} color="textSecondary">
              {type}
            </Typography>
          )}
          <Typography
            className={classes.title}
            gutterBottom
            variant="h5"
            component="h4"
          >
            {deviceType === "mobile" ? name : title}
          </Typography>
          {!!description && (
            <Typography
              className={classes.description}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {description}
            </Typography>
          )}
          <Box className={classes.options}>
            <a
              className={classes.option}
              href={reportResult}
              target={postCard ? "_blank" : "_self"}
            >
              {postCard ? "Read more" : reportLabel}
            </a>
            {user && (
              <>
                <Dialog
                  title="Delete Report"
                  handleClose={handleClose(false)}
                  open={open}
                  customClasses={{ content, dialog }}
                >
                  <DialogContent>
                    Are you sure you want delete this report? If you remove it,
                    you wouldn{"'"}t be able to check these results again.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose(false)} color="primary">
                      No
                    </Button>
                    <Button onClick={handleClose(true)} color="primary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Typography
                  className={classes.option}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Delete
                </Typography>
              </>
            )}
          </Box>
        </CardContent>
      )}
    </MuiCard>
  );
};
export default Card;
