import React from "react";
import { Box, Typography } from "@material-ui/core";
// import { formatNumber } from 'utils/format';
import useStyles from "./styles";
import ToggleInput from "components/Inputs/Toggle";
import SelectInput from "components/Inputs/Select";
import SliderInput from "components/Inputs/Slider";
import ReqIncludes from "components/Requests/Manage/Filters/includes";
import {
  getRequestsFilterData,
  getRequestsFilterSelected,
} from "redux/_requests/_data/data.selectors";
import { CONSTANTS } from "redux/_requests/_data/data.constants";
import { connect } from "react-redux";
import { selectFilter } from "redux/_requests/_data/data.actions";
import Search from "components/Requests/Manage/Filters/search";

const Filters = ({
  regulations,
  setRegulation,
  days_left,
  setDaysLeft,
  statuses,
  setStatus,
  // verified_customer,
  // setVerifiedCustomer,
  search,
  setSearch,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Search
        value={search.selected}
        label="Find requests"
        onChange={setSearch}
      />
      {/* <Box display="flex" mt={5}>
        <Box>
          <Typography className={classes.itemValue}>
            {formatNumber(343659)}
          </Typography>
          <Typography className={classes.itemTitle}>Total Requests</Typography>
        </Box>
        <Box pl={2}>
          <Typography className={classes.itemValue}>
            {formatNumber(4563)}
          </Typography>
          <Typography className={classes.itemTitle}>New This Week</Typography>
        </Box>
      </Box> */}
      <Box mt={5} className={classes.filtersContainer}>
        <Typography className={classes.filtersTitle}>Filters</Typography>
        <Box width="100%" mb={2}>
          <ToggleInput
            label="Regulation"
            options={regulations.data}
            classes={{
              root: classes.filtersInput,
              grouped: classes.filterButton,
            }}
            value={regulations.selected}
            onChange={setRegulation}
          />
        </Box>
        <Box width="100%" mb={2}>
          <ReqIncludes regulation={regulations.selected} />
        </Box>
        <Box width="100%" mb={2} pl={0.375} pr={1.125}>
          <SliderInput
            label="Days Left"
            min={days_left.data[0]}
            max={days_left.data[1]}
            value={days_left.selected}
            onChangeCommitted={setDaysLeft}
            marks={[
              { value: 0, label: "0" },
              { value: 45, label: "45" },
            ]}
            defaultValue={45}
          />
        </Box>
        <Box width="100%" mb={2}>
          <SelectInput
            label="Status"
            options={statuses.data}
            className={classes.filtersInput}
            value={statuses.selected}
            onChange={setStatus}
          />
        </Box>
        {/* <Box width="100%" mb={2}>
          <SelectInput
            label="Verified customer"
            options={verified_customer.data}
            className={classes.filtersInput}
            value={verified_customer.selected}
            onChange={setVerifiedCustomer}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  search: {
    data: getRequestsFilterData(CONSTANTS.REQUESTS_FILTERS_TYPES.SEARCH)(state),
    selected: getRequestsFilterSelected(
      CONSTANTS.REQUESTS_FILTERS_TYPES.SEARCH
    )(state),
  },
  regulations: {
    data: getRequestsFilterData(CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION)(
      state
    ),
    selected: getRequestsFilterSelected(
      CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION
    )(state),
  },
  days_left: {
    data: getRequestsFilterData(CONSTANTS.REQUESTS_FILTERS_TYPES.DAYS_LEFT)(
      state
    ),
    selected: getRequestsFilterSelected(
      CONSTANTS.REQUESTS_FILTERS_TYPES.DAYS_LEFT
    )(state),
  },
  statuses: {
    data: getRequestsFilterData(CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS)(state),
    selected: getRequestsFilterSelected(
      CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS
    )(state),
  },
  verified_customer: {
    data: getRequestsFilterData(
      CONSTANTS.REQUESTS_FILTERS_TYPES.VERIFIED_CUSTOMER
    )(state),
    selected: getRequestsFilterSelected(
      CONSTANTS.REQUESTS_FILTERS_TYPES.VERIFIED_CUSTOMER
    )(state),
  },
});

const mapDispatchToProps = (dispatch) => ({
  setSearch: (value) =>
    dispatch(selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.SEARCH, value)),
  setRegulation: (value) =>
    dispatch(selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.REGULATION, value)),
  setDaysLeft: (value) =>
    dispatch(selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.DAYS_LEFT, value)),
  setStatus: (value) =>
    dispatch(selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.STATUS, value)),
  setVerifiedCustomer: (value) =>
    dispatch(
      selectFilter(CONSTANTS.REQUESTS_FILTERS_TYPES.VERIFIED_CUSTOMER, value)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
