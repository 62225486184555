import React from "react";
import {
  assignBusinessUnit,
  createBusinessUnit,
} from "redux/_businessUnits/businessUnits.async.actions";
import {
  getBusinessUnitsData,
  getBusinessUnitsLoadingState,
} from "redux/_businessUnits/businessUnits.selectors";
import { connect } from "react-redux";

import CreatableSelect from "components/Inputs/CreatableSelect";

const BusinessUnitCreatableSelect = (props) => <CreatableSelect {...props} />;

const mapStateToProps = (state) => ({
  loading: getBusinessUnitsLoadingState(state),
  options: getBusinessUnitsData(state),
});

const mapDispatchToProps = (dispatch) => ({
  create: (name) => dispatch(createBusinessUnit(name)),
  update: (tableId, functionalUseId) =>
    dispatch(assignBusinessUnit(tableId, functionalUseId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessUnitCreatableSelect);
