import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Title from "./title";
import isNil from "lodash.isnil";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    width: "100%",
  },
  leftContainer: (props) => ({
    width: "30%",
    maxWidth: 272,
    paddingRight: props.hideDivider ? 0 : theme.spacing(3),
    borderRight: props.hideDivider ? "none" : "1px solid #E0E0E0",
    paddingBottom: theme.spacing(6),
  }),
  rightContainer: {
    width: "70%",
    minWidth: "calc(100% - 272px)",
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
}));

export default ({
  leftContent,
  title = null,
  subtitle = null,
  children,
  hideDivider,
}) => {
  const classes = useStyles({ hideDivider });
  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.leftContainer}>
        {(!isNil(title) || !isNil(subtitle)) && (
          <Title title={title} subtitle={subtitle} />
        )}
        {!isNil(leftContent) && leftContent}
      </Box>
      <Box className={classes.rightContainer}>{children}</Box>
    </Box>
  );
};
