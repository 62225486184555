import CONSTANTS from "./integrations.constants";
import unionBy from "lodash.unionby";

const initialState = {
  selectedId: null,
  data: [
    {
      id: 1,
      name: "Slack",
      service_id: 1,
      domain: "zoe.slack.com",
      access_token: "3i1uh239rh97hg9gnfs009ht2409nfoi",
    },
    {
      id: 2,
      name: "Salesforce",
      service_id: 2,
      domain: "zoe.salesforce.com",
      access_token: "kjn5j34n64jk6n4kj6n45jk6546kn",
    },
    {
      id: 3,
      name: "Microsoft Teams",
      service_id: 3,
      domain: "zoe.microsoft.com",
      access_token: "j5n6j45ln67lj54n6lk4n64n5l6nlk",
    },
    {
      id: 4,
      name: "Microsoft Exchange",
      service_id: 4,
      domain: "zoe.microsoft.com",
      access_token: "j5n6j45ln67lj54n6lk4n64n5l6nlk",
    },
    {
      id: 5,
      name: "Jira",
      service_id: 5,
      domain: "zoe.atlassian.com",
      access_token: "lkn565kl6n45kl6n3k5n4lk5n4l5",
    },
    {
      id: 13,
      name: "Zendesk",
      service_id: 13,
      domain: "zoe.zendesk.com",
      access_token: "lkn565kl6n45kl6n3k5n4lk5n4l5",
    },
    {
      id: 14,
      name: "Trello",
      service_id: 14,
      domain: "zoe.trello.com",
      access_token: "4lkn5l6n3k6n3l4kn5l3k45n34kl5n",
    },
    {
      id: 15,
      name: "Asana",
      service_id: 15,
      domain: "zoe.asana.com",
      access_token: "4lkn5l6n3k6n3l4kn5l3k45n34kl5n",
    },
    {
      id: 16,
      name: "Monday.com",
      service_id: 16,
      domain: "zoe.monday.com",
      access_token: "4lkn23oi4njr9gis9v031ioe23iond",
    },
    {
      id: 6,
      name: "Confluence",
      service_id: 6,
      domain: "zoe.atlassian.com",
      access_token: "ncoidnv09j2h30r23hr082hf08n",
    },
    {
      id: 7,
      name: "Google Drive",
      service_id: 7,
      domain: "zoe.google.com",
      access_token: "5lk6kl5464opjgp9fjv90dfvdf9",
    },
    {
      id: 8,
      name: "Dropbox",
      service_id: 8,
      domain: "zoe.dropbox.com",
      access_token: "lk43n53lk4nongorjwoinr02nr023nr",
    },
    {
      id: 9,
      name: "Box",
      service_id: 9,
      domain: "zoe.box.com",
      access_token: "l3kn42lk3n42ifj90jv",
    },
    {
      id: 17,
      name: "OneDrive",
      service_id: 17,
      domain: "zoe.onedrive.com",
      access_token: "lk123neoi3ndfj0e09f093nn",
    },
    {
      id: 10,
      name: "Intercom",
      service_id: 10,
      domain: "zoe.intercom.com",
      access_token: "l3kn42lk3n42ifj90jv",
    },
    {
      id: 20,
      name: "LiveChat",
      service_id: 20,
      domain: "zoe.livechatinc.com",
      access_token: "knoiwf08ejf89hf923bnfoiewnflfn",
    },
    {
      id: 11,
      name: "MailChimp",
      service_id: 11,
      domain: "zoe.mailchimp.com",
      access_token: "l3kn42lk3n42ifj90jv",
    },
    {
      id: 12,
      name: "SendGrid",
      service_id: 12,
      domain: "zoe.sendgrid.com",
      access_token: "foinsiofnewifjq09fj32f09fj",
    },
    {
      id: 18,
      name: "Bitbucket",
      service_id: 18,
      domain: "zoe.atlassian.com",
      access_token: "l3kn42lk3n42ifj90jv",
    },
    {
      id: 19,
      name: "GitHub",
      service_id: 19,
      domain: "zoe.github.com",
      access_token: "cmsdoipvmdsovjf0g0249ng049ng",
    },
    {
      id: 23,
      name: "GitLab",
      service_id: 23,
      domain: "zoe.gitlab.com",
      access_token: "1mn2be1jh2ev1uyve186vd831vdyvd",
    },
    {
      id: 21,
      name: "DocuSign",
      service_id: 21,
      domain: "zoe.docusign.com",
      access_token: "fñmvdfpbmdfopbjdfb9f0b9me",
    },
    {
      id: 22,
      name: "HelloSign",
      service_id: 22,
      domain: "zoe.hellosign.com",
      access_token: "mckldacpqiwjh2489fhriuvbk",
    },
  ],
};

export function integrations(state = initialState, { type, data }) {
  switch (type) {
    case CONSTANTS.INTEGRATIONS_SELECT:
      return {
        ...state,
        selectedId: data,
      };
    case CONSTANTS.INTEGRATIONS_SAVE_DATA:
      const selectedEnv = state.selectedId || data[0].id;
      return {
        ...state,
        data: unionBy(data, state.data, "id"),
        selectedId: selectedEnv,
      };
    case CONSTANTS.INTEGRATIONS_DELETE:
      const newData = state.data.filter((item) => item.id !== data);
      return {
        ...state,
        data: newData,
        selectedId: newData.length > 0 ? newData[0].id : null,
      };

    default:
      return state;
  }
}
