import InstructionList from "./InstructionList";
import CopyTextButton from "components/Consents/Collector/EmbedCode/components/copyTextButton";
import config from "config";
import useFormStyles from "../../../Scans/Forms/styles.js";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Container,
  Box,
  ListItem,
  TextField,
  ListItemIcon,
  ListItemText,
  Card,
} from "@material-ui/core";

import useCustomStyles from "./CustomRepoScannerStyles";
import { platformInstructions } from "./instructions";

export const RepoPlatformInstructions = ({ webhookId, platform }) => {
  const customClasses = useCustomStyles();
  const formStyles = useFormStyles();

  const instructions = platformInstructions[platform] || [];


  return (
    <Container className={customClasses.instructionsContainer}>
      <Card padding={2}>
        <InstructionList
          title={instructions.webhookSetup.title}
          instructions={instructions.webhookSetup.instructions}
        />
        <ListItem>
          <ListItemText
            primary="Webhook URL"
            style={{ marginRight: "2em", whiteSpace: "nowrap" }}
          />
          <TextField
            style={{ marginRight: "1em" }}
            className={formStyles.textField}
            variant="filled"
            value={`${config.apiUrl}/scans/${webhookId}/callback/`}
            disabled
          />
          <ListItemIcon>
            <CopyTextButton
              tooltip="Copy Webhook URL"
              message="Webhook URL successfully copied!"
              text={`${config.apiUrl}/scans/${webhookId}/callback/`}
            />
          </ListItemIcon>
        </ListItem>
      </Card>
      <br />
      <Card padding={2}>
        <InstructionList
          title={instructions.actionInstruction.title}
          instructions={instructions.actionInstruction.instructions}
        />

        <Box style={{ position: "relative" }}>
          <CopyTextButton
            tooltip="Copy YAML Code"
            message="Code successfully copied!"
            text={instructions.codeString(config.apiUrl, webhookId)}
            style={{ position: "absolute", top: 0, right: 0 }}
          />
          <SyntaxHighlighter language="yml" style={solarizedlight}>
            {instructions.codeString(config.apiUrl, webhookId)}
          </SyntaxHighlighter>
        </Box>
      </Card>
      <br />
      <Card padding={2}>
        <InstructionList
          title={instructions.runAction.title}
          instructions={instructions.runAction.instructions}
        />
      </Card>
    </Container>
  );
};
