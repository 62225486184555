import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChipDataFlow from "../ChipDataFlow";

const useStyles = makeStyles((theme) => ({
  bubble: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 16,
    background: "white",
    width: 282,
    padding: 16,
    gap: 8,
   
    transition: "background-color 0.3s, color 0.3s",
    '&:hover': {
      backgroundColor: props => props.borderChipColor,
      color: "white",
    },
  },
  categoryText: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 900,
    color: "#868993",
    transition: "color 0.3s",
    '&:hover': {
      color: "white",
    },
  },
}));

const BublePII = ({ config }) => {
  const {
    sectionTitle = null,
    chipBackgroundColor = "white",
    chipImage = null,
    borderChipColor = null,
    pii = [],
    category = null,
  } = config;

  const classes = useStyles({ borderChipColor });

  return (
    <Box className={classes.bubble}>
      <Typography className={classes.categoryText}>
        {category}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          {pii.map((piiValue) => {
            return (
              <Grid item xs={4} key={sectionTitle + category + piiValue}>
                <ChipDataFlow
                  label={piiValue}
                  borderChipColor={borderChipColor}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default BublePII;
