import DoughnouChart from "../DoughnouChart";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";

export const colors = [
  "#21CFBE",
  "#469ECA",
  "#F2A945",
  "#0E57CD",
  "#83ABEC",
  "#1BAC9E",
  "#2985B3",
  "#EA941E",
  "#437EDF",
  "#A5C6FC",
];

function calculatePercentage(array) {
  const piiNameCounts = {};
  const totalObjects = array.length;

  for (const obj of array) {
    const piiName = `${obj.pii_name}`;
    piiNameCounts[piiName] = (piiNameCounts[piiName] || 0) + 1;
  }

  const piiNames = [];
  const percentages = [];

  for (const piiName in piiNameCounts) {
    const percentage = (piiNameCounts[piiName] / totalObjects) * 100;
    piiNames.push(`${piiName}: ${percentage.toFixed(2)}%`);
    percentages.push(percentage.toFixed(2));
  }

  return [piiNames, percentages];
}

const formattedData = (data) => {
  const flattenedArray = [];

  for (const key in data.pii) {
    if (Array.isArray(data.pii[key])) {
      flattenedArray.push(...data.pii[key]);
    }
  }
  const insensitivePIIArray = flattenedArray.filter(
    (obj) => obj.type === "insensitive_pii"
  );
  const [piiNames, percentageValues] = calculatePercentage(insensitivePIIArray);
  return [piiNames, percentageValues];
};

const NonSensitivePiiChart = ({
  data,
  label = "MODERATELY SENSITIVE PII",
  formatData = formattedData,
}) => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (data) {
      const [piiNames, percentageValues] = formatData(data);
      setLabels(piiNames);
      setValues(percentageValues);
    }
  }, [data]);

  const dataDoughnou = {
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverOffset: 0.5,
        borderWidth: 0,
        offset: 30,
        hoverBorderWidth: 2,
      },
    ],
  };

  return (
    <DoughnouChart
      chartData={dataDoughnou}
      title={label}
      colors={colors.slice(0, labels.length)}
      labels={labels}
    />
  );
};

export default NonSensitivePiiChart;
