import React, { useMemo } from "react";
import { Box, Collapse } from "@material-ui/core";
import ProgressBar from "components/SettingsSections/InitialSetup/components/ProgressBar";

const InitialSetupBar = ({ firstLoaded, hide, setup }) => {
  const [totalSetup, hasSetup] = useMemo(
    () =>
      Object.keys(setup)
        .filter((key) => key !== "show_initial_setup")
        .reduce(
          (prev, curr) => [prev[0] + 1, setup[curr] ? prev[1] + 1 : prev[1]],
          [0, 0]
        ),
    [setup]
  );

  return (
    <Collapse in={!hide && firstLoaded && setup.show_initial_setup}>
      <Box px={2} py={2} width="100%">
        <ProgressBar
          link="/zen/settings/initial-setup"
          progress={totalSetup ? Math.round((hasSetup / totalSetup) * 100) : 0}
          text="Initial Setup Completed"
          dark
        />
      </Box>
    </Collapse>
  );
};

export default InitialSetupBar;
