import { Font, StyleSheet } from "@react-pdf/renderer";
import Aldrich from "../../Aldrich.ttf";
import InterBold from "../../Inter-Medium.ttf";
import Inter from "../../Inter.otf";

Font.register({
  family: "Aldrich",
  fonts: [{ src: Aldrich }, { src: Aldrich, fontWeight: "bold " }],
});

Font.register({
  family: "Inter",
  fonts: [{ src: Inter }, { src: InterBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#1a3586",
    width: "100%",
    height: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1cm",
  },
  content: {
    padding: ".6cm",
    width: "calc(85% - (0.6cm *2))",
  },
  title: {
    fontSize: 20,
    fontFamily: "Aldrich",
    fontWeight: 800,
    color: "#ffffff",
  },
  subtitle: {
    fontSize: 16,
    fontFamily: "Inter",
    color: "rgba(255, 255, 255, 0.8)",
  },
  reportDetails: {
    marginTop: 10,
    paddingTop: 12,
    borderTop: "1px solid #ffffff",
  },
  reportDetailsText: {
    fontSize: 16,
    fontFamily: "Inter",
    color: "#f1f1f1",
  },
  iconContainer: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  icon: {
    width: 60,
    height: 60,
  },
});

export default styles;
