import { makeStyles } from "@material-ui/core";

export default makeStyles(
  (theme) => ({
    planStatus: {},
    status: {
      margin: theme.spacing(0, 0.5),
    },
    cta: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  }),
  { name: "PlanStatus" }
);
