import Grid from "@material-ui/core/Grid";
import ZenDataLogo from "assets/illustrations/brandapp.js";
import IconCatalog from "assets/svgs-icons/Report/catalog-data.svg";
import IconChecklist from "assets/svgs-icons/Report/checklist.svg";
import IconRobot from "assets/svgs-icons/Report/robot.svg";
import clsx from "clsx";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeForKey } from "router";
import { FLAVORS } from "../../constants";
import "./styles.scss";

const NotFreeComponents = () => (
  <>
    <Grid container spacing={3} classes={{ root: "description" }}>
      <Grid item sm={4} classes={{ root: "options" }}>
        <div className="image">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="100" height="100" fill="url(#pattern0)" />
            <defs>
              <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlinkHref="#image0" transform="scale(0.01)" />
              </pattern>
              <image
                id="image0"
                width="100"
                height="100"
                xlinkHref={IconCatalog}
              />
            </defs>
          </svg>
        </div>
        <p>
          Catalog the data elements being collected by your cookies and third
          party trackers.
        </p>
      </Grid>
      <Grid item sm={4} classes={{ root: "options" }}>
        <div className="image">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="100" height="100" fill="url(#pattern1)" />
            <defs>
              <pattern
                id="pattern1"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlinkHref="#image1" transform="scale(0.01)" />
              </pattern>
              <image
                id="image1"
                width="100"
                height="100"
                xlinkHref={IconChecklist}
              />
            </defs>
          </svg>
        </div>
        <p>
          Cross check this collected data against your company’s consent and
          privacy policies.
        </p>
      </Grid>
      <Grid item sm={4} classes={{ root: "options" }}>
        <div className="image">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <rect width="100" height="100" fill="url(#pattern2)" />
            <defs>
              <pattern
                id="pattern2"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use xlinkHref="#image2" transform="scale(0.01)" />
              </pattern>
              <image
                id="image2"
                width="100"
                height="100"
                xlinkHref={IconRobot}
              />
            </defs>
          </svg>
        </div>
        <p>
          Use our automated bots to check across mimic systems, devices, and
          user personas.
        </p>
      </Grid>
    </Grid>
  </>
);

const DemoReportComplete = ({ flavor, email, origin, data = [] }) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (data.length > 0 && reports.length === 0) {
      setReports(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="complete">
      <div className="top">
        <Link to={routeForKey("privacyScanner")}>
          <ZenDataLogo width="273" height="36" />
        </Link>
        {/*<Button className="download">
            <DownloadSVG />
            Download report
        </Button>*/}
      </div>
      <div className={clsx("header", flavor)}>
        <h1>This report is powered by top privacy technology</h1>
        <p>
          With ZenData, do all this and more to continuously audit and generate
          alerts with full automation.
        </p>
        {flavor !== FLAVORS.full && (
          <Button
            href={`/plans?email=${email}&origin=${origin}`}
            className={clsx("upgrade-plan", flavor)}
          >
            {flavor === FLAVORS.free
              ? "Upgrade to full platform"
              : "Try Zendata for free now"}
          </Button>
        )}
      </div>
      <NotFreeComponents />
    </div>
  );
};

export default DemoReportComplete;
