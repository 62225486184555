import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import useFirstTimeUseGuide from "hooks/useFirstTimeUseGuide"; // Adjust the path according to your project structure
import { JS } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#f9f9f9",
    borderRadius: theme.spacing(1),
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "24px",
    color: "#333",
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "18px",
    color: "#555",
  },
  text: {
    fontSize: "16px",
    color: "#666",
    lineHeight: "1.6",
    marginBottom: theme.spacing(1),
  },
  codeContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowX: "auto",
  },
}));

const FirstTimeUseComponent = ({ values }) => {
  const classes = useStyles();
  const { processedData, loading, error } = useFirstTimeUseGuide(values);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading content</Typography>;
  }

  return (
    <Box className={classes.container} style={{ marginTop: 20 }}>
      {processedData?.map((section, index) => (
        <React.Fragment key={index}>
          <Typography className={classes.title}>{section.title}</Typography>
          {section.texts.map((text, i) => (
            <Typography key={i} className={classes.text}>
              {text}
            </Typography>
          ))}
          {section.codeSnippet && (
            <Box className={classes.codeContainer}>
              <SyntaxHighlighter language="yaml" style={vs2015}>
                {section.codeSnippet}
              </SyntaxHighlighter>
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default FirstTimeUseComponent;
