import { makeStyles } from "@material-ui/styles";

const status = {
  display: "inline-block",
  width: 97,
  borderRadius: 3,
  textAlign: "center",
  padding: 2,
  userSelect: "none",
};

export default makeStyles((theme) => ({
  tr: {
    "& td": {
      padding: theme.spacing(2, 0),
    },
  },
  inactive: {
    color: theme.palette.grey[300],
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  email: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    color: "#868993",
    fontStyle: ({ state }) => (state === "P" ? "italic" : "normal"),
  },
  avatar: {
    width: 38,
    height: 38,
    boxShadow: "0 10px 10px 0 rgba(8, 20, 78, 0.15)",
    border: "solid 1px #fff",
    backgroundColor: "#cccccc",
    marginRight: theme.spacing(2),
    color: "#868993",
    fontWeight: "bolder",
    fontSize: "24px",
    alignSelf: "flex-start",
  },
  status: {
    ...status,
    backgroundColor: theme.palette.grey[500],
  },
  statusActive: {
    ...status,
    backgroundColor: theme.palette.green[300],
  },
  statusText: {
    fontFamily: "Roboto Condensed, sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "condensed",
    letterSpacing: "1px",
    color: ({ state }) =>
      state === "P" ? "#7d610d" : theme.palette.common.white,
    textTransform: "uppercase",
  },
  textField: {
    maxWidth: 100,
    zIndex: 0,
  },
  select: {
    fontSize: "16px",
    textTransform: "uppercase",
    color: ({ state }) => (state === "P" ? "#b1b3b9" : "#686c78"),
    paddingTop: 6,
    paddingBottom: 6,
    fontStyle: ({ state }) => (state === "P" ? "italic" : "normal"),
  },
  selectIcon: {
    display: ({ state }) => (state === "P" ? "none" : "block"),
    position: "absolute",
    right: 8,
    zIndex: -1,
    "& *": {
      fill: "#9DA0A8",
    },
  },
  notchedOutline: {
    border: "none",
  },
  defaultHeight: {
    height: 40,
  },
  resendButton: {
    color: theme.palette.blue["young"],
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Roboto Condensed, sans-serif",
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: -5,
    marginLeft: theme.spacing(2),
  },
  removeButton: {
    color: theme.palette.red[500],
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Roboto Condensed, sans-serif",
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: -5,
    marginLeft: theme.spacing(2),
    "& > .MuiButton-label > svg": {
      marginRight: 5,
    },
  },
  badge: {
    display: "inline",
    borderRadius: 2,
    background: theme.palette.blue[400],
    color: "#fff",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Inter, sans-serif",
    padding: "2px 5px",
    marginLeft: theme.spacing(1),
    userSelect: "none",
  },
}));
