import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import PageWrapper, { Tab, Tabs } from "components/PageWrapper";
import EnvironmentSettings from "components/SettingsSections/Environment";
import DatabaseSettings from "components/SettingsSections/Database";
import TablesSettings from "components/SettingsSections/Tables";
import IntegrationsSettings from "components/SettingsSections/Integrations";
import DatasourceSettings from "components/SettingsSections/Datasource";
import InitialSetupSettings from "components/SettingsSections/InitialSetup";
import { Route, Switch } from "react-router-dom";
import { getAllDatabases } from "redux/_databases/databases.async.actions";
import { getDatasources } from "redux/_datasources/datasources.async.actions";
import isNil from "lodash.isnil";

const SettingsPage = (props) => {
  const { history, match, location, getDatabasesData, getDsData } = props;

  useEffect(() => {
    getDatabasesData();
    getDsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pathValue = useMemo(
    () => location.pathname.replace(match.path, "").split("/")[1],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  const [value, setValue] = useState(pathValue || "environments");

  useEffect(() => {
    if (isNil(pathValue)) history.replace(`/zen/settings/${value}`);
  });

  useEffect(
    () => {
      if (pathValue) setValue(pathValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  useEffect(
    () => {
      history.replace(`/zen/settings/${value}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const onTabChange = (value) => setValue(value);

  useEffect(() => {
    getDatabasesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      title="HOME"
      subtitle="SETTINGS"
      header={
        <Tabs value={value} onChange={(_, newValue) => onTabChange(newValue)}>
          <Tab value={"initial-setup"} label="Initial Setup" />
          <Tab value={"environments"} label="Environments" />
          <Tab value={"datasources"} label="Datasources" />
          <Tab value={"databases"} label="Databases" />
          <Tab value={"tables"} label="Tables" />
          {/*
          <Tab value={"integrations"} label="Integrations" />
          */}
        </Tabs>
      }
    >
      <Switch>
        <Route
          path={"/zen/settings/initial-setup"}
          component={InitialSetupSettings}
        />
        <Route
          path={"/zen/settings/datasources"}
          component={DatasourceSettings}
        />
        <Route path={"/zen/settings/databases"} component={DatabaseSettings} />
        <Route path={"/zen/settings/tables"} component={TablesSettings} />
        <Route
          path={"/zen/settings/integrations"}
          component={IntegrationsSettings}
        />
        <Route
          path={"/zen/settings/environments"}
          component={EnvironmentSettings}
        />
      </Switch>
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDatabasesData: () => dispatch(getAllDatabases()),
  getDsData: () => dispatch(getDatasources()),
});

export default connect(null, mapDispatchToProps)(SettingsPage);
