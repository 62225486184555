import { createEnv, updateEnv } from "./wizard.service";
import { requestStart, requestEnd, setEnvData } from "./wizard.actions";

export const createEnvironment = (data) => (dispatch) => {
  dispatch(requestStart());
  return createEnv(data)
    .then((result) => {
      dispatch(requestEnd());
      dispatch(setEnvData(result));
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};

export const updateEnvironment = (envId, data) => (dispatch) => {
  dispatch(requestStart());
  return updateEnv(envId, data)
    .then(() => {
      dispatch(requestEnd());
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch(requestEnd());
      return Promise.reject(errors);
    });
};
