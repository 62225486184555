import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import useStyles from "./styles";

import { getConfig } from "redux/_requests/_config/config.async.actions";
import { getRequestsConfig } from "redux/_requests/_config/config.selectors";

const Preview = ({ data, config = {}, getConfig }) => {
  const [renderId, setRenderId] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRenderId(renderId + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <iframe
      title="Request Collector"
      key={renderId}
      className={classes.iframe}
      src={`/request/collector.html?token=${config.token}`}
    ></iframe>
  );
};

const mapStateToProps = (state) => ({
  config: getRequestsConfig(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
